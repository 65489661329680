import React from 'react';
import { asField } from 'informed';
import classnames from 'classnames';
import { map } from 'lodash';
import RawInputSummernote from './RawInputSummernote';

export default asField(({
  fieldState, fieldApi, field, label, col, required, ...props
}) => (
  <div className={classnames('text-input-form-state form-group', col, { required, 'has-error': fieldState.error })}>
    <label htmlFor={field}>{label}</label>
    <RawInputSummernote value={fieldState.value} onChange={fieldApi.setValue} {...props} />
    {map(fieldState.error, e => <p key={e} className="text-danger">{e}</p>)}
  </div>
));
