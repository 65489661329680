import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Favicon from 'react-favicon';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from 'styled-components';
import Loading from './components/Loading';
import PrivateRoute from './components/PrivateRoute';
import { API_HOST } from './consts';
import MainSection from './containers/global/MainSection';
import { ThemeContext } from './contexts/ThemeContext';
import StudentAnalysis from './pages/avalia/StudentAnalysis';
import DrePage from './pages/avalia/DrePage';
import HeadQuartersPage from './pages/avalia/HeadQuartersPage';
import ProficiencyScalePage from './pages/avalia/ProficiencyScalePage';
import RelatorioTurma from './pages/avalia/RelatorioTurma';
import ChangePassword from './pages/ChangePassword';
import ForgotPassword from './pages/ForgotPassword';
import Login from './pages/Login';
import LoginRedirect from './pages/LoginRedirect';
import TokenLogin from './pages/TokenLogin';
import defaultTheme from './theme/DefaultTheme';
import withTracker from './withTracker';
import ReportStudentSkillLevel from './pages/avalia/ReportStudentSkillLevel';
import FiltersPageRelatorio from './pages/avalia/FiltersPageRelatorio';
import FiltersPageAluno from './pages/avalia/FiltersPageAluno';
import FiltersPageHabilidade from './pages/avalia/FiltersPageHabilidade';
import FiltersClassReport from './pages/avalia/FiltersClassReport';
import FiltersPageDre from './pages/avalia/FiltersPageDre';
import ExternalAuthPage from './pages/ExternalAuthPage';

const App = () => {
  const [loadingTheme, setLoadingTheme] = useState(true);
  const [theme, setTheme] = useState({});
  const fetchTheme = async () => {
    try {
      const response = await axios.get(`${API_HOST}/themes/`);
      setTheme({
        ...defaultTheme,
        ...response.data.theme,
      });
      setLoadingTheme(false);
    } catch (e) {
      setLoadingTheme(false);
    }
  };

  useEffect(() => {
    fetchTheme();
  }, []);

  if (loadingTheme) {
    return <Loading />;
  }

  return (
    <BrowserRouter>
      <ThemeContext.Provider value={theme}>
        <ThemeProvider theme={theme}>
          <div>
            <Favicon url={theme.favicon} />
            <ToastContainer autoClose={10000} />
            <Switch>
              <Route
                path="/external-auth/:provider"
                component={ExternalAuthPage}
              />
              <Route path="/login" component={withTracker(Login)} />
              <Route
                path="/login-redirect"
                component={withTracker(LoginRedirect)}
              />
              <Route
                path="/aluno/:id"
                component={StudentAnalysis}
              />
              <Route
                path="/aluno-acertos/:id"
                component={ReportStudentSkillLevel}
              />
              <Route
                path="/nivel-habilidade/:disciplinaId/escola/:escolaId/etapa/:etapaId"
                component={HeadQuartersPage}
              />
              <Route path='/relatorio-dre/:codigoDre/etapa/:nr_etapa/disciplina/:cd_disciplina'
                    component={DrePage}
              />
              <Route
                path="/relatorio-turma/:id/etapa/:etapaId/disciplina/:disciplinaId"
                component={RelatorioTurma}
              />
              <Route
                path="/distribuicao-proficiencia-aluno/:escolaId/disciplina/:discId"
                component={ProficiencyScalePage}
              />
              <Route
                path="/token-login/:token"
                component={withTracker(TokenLogin)}
              />
              <Route
                path="/forgot-password"
                component={withTracker(ForgotPassword)}
              />
              <Route
                path="/password-reset/:token"
                component={withTracker(ChangePassword)}
              />
                  <Route
                path="/filters-distribuicao-proficiencia"
                component={FiltersPageRelatorio}
              />
              <Route path="/filters-aluno" component={FiltersPageAluno} />
              <Route
                path="/filters-nivel-habilidade"
                component={FiltersPageHabilidade}
              />
              <Route
                path="/filters-turma"
                component={FiltersClassReport}
              />
               <Route
                path="/filters-dre"
                component={FiltersPageDre}
              />

              <MainSection />
            </Switch>
          </div>
        </ThemeProvider>
      </ThemeContext.Provider>
    </BrowserRouter>
  );
};

export default App;
