import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-fa';
import { Control } from 'react-redux-form';

export default class Search extends React.Component {
  static propTypes = {
    onClick: PropTypes.func,
    model: PropTypes.string,
  };

  static defaultProps = {
    onClick: () => {},
    model: 'Search.general'
  };

  render() {
    return (
      <div className="wrap">
        <div className="search">
          <Control
            type="text"
            className="search-term"
            placeholder="Pesquisar..."
            model={this.props.model}
          />
          <button className="search-button" onClick={this.props.onClick}>
            <Icon name="search" />
          </button>
        </div>
      </div>
    );
  }
}
