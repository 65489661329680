import React from 'react';
import PropTypes from 'prop-types';

export default class Box extends React.Component {
  static propTypes = {
    number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    label: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    number: null,
    label: '',
    color: '#000',
  };

  render() {
    return (
      <div className="card-body box-card" style={{height:130}}>
        <h3 className="box-number" style={{ color: this.props.color }}>
          {this.props.number}
        </h3>
        <h6 className="box-label pt-2">
          {this.props.label}
        </h6>
      </div>
    );
  }
}
