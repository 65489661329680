import React from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import * as XLSX from 'xlsx';
import { get, map, upperCase } from 'lodash';
import axios from 'axios';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import withRouter from 'react-router-dom/withRouter';
import ModalItensElaborador from './ModalItensElaborador';
import FiltroAvancadoItens from './FiltroAvancadoItens';
import ElaboradoresTab from './ElaboradoresTab';
import PedidosTab from './PedidosTab';
import { API_HOST } from '../../consts';
import RevisoresTab from './RevisoresTab';
import EncomendasFluxoTab from './EncomendasFluxoTab';

class ListaEncomendaCardTabs extends React.Component {
  static defaultProps = {
    pedidos: []
  };

  state = {
    activeTab: 'pedidos',
    itensVinculados: [],
    ordering: null,
    metadata: []
  };

  metadataModels = ['matriz'];

  componentDidMount() {
    this.fetchMetadata();
  }

  mapMetadata(metadata) {
    const mapped = {};
    metadata &&
      metadata
        .filter((d) => d.is_active === true)
        .forEach((m) => {
          mapped[`${m.model}.${m.name}`] = m.default;
        });
    return mapped;
  }

  shouldDisplayDefault(field) {
    const { metadata } = this.state;
    return get(metadata, field);
  }

  fetchMetadata() {
    this.setState({ fetchingMetadata: true });
    return axios
      .get(`${API_HOST}/params/param?model=${this.metadataModels.join('|')}`)
      .then((response) => {
        this.setState({
          metadata: this.mapMetadata(response.data),
          fetchingMetadata: false
        });
      });
  }

  fetchElaboradores = () => {
    const { encomenda } = this.props;
    this.setState({ fetchingData: true });
    axios
      .get(`${API_HOST}/encomenda/itens_por_status/usuario`, {
        params: {
          encomenda
        }
      })
      .then((response) => {
        this.downloadExcel(response.data.usuarios);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  fetchRevisores = () => {
    const { encomenda } = this.props;
    return axios
      .get(`${API_HOST}/encomenda/revisor/tabela_excel`, {
        params: {
          encomenda
        }
      })
      .then((response) => {
        this.setState({
          dataRevisor: response.data.usuarios,
          fetchingData: false
        });
        this.downloadExcelRevisor(response.data.usuarios);
      });
  };

  get initialValuesBuscaAvancadaItem() {
    const { pedidoSelecionado } = this.state;

    if (!pedidoSelecionado) {
      return {};
    }

    return {
      matriz_referencia: get(pedidoSelecionado, 'matriz_referencia.id'),
      competencia: get(pedidoSelecionado, 'competencia.id'),
      habilidade: get(pedidoSelecionado, 'habilidade.id')
    };
  }

  get filtroModalItensElaborador() {
    const { selecionado } = this.state;
    return {
      criado_por: get(selecionado, 'usuario_id'),
      pedido: get(selecionado, 'pedido_id')
    };
  }

  get filtroModalItensRevisor() {
    const { selecionado } = this.state;
    return {
      revisado_por: get(selecionado, 'usuario_id'),
      encomenda_id: get(selecionado, 'encomenda_id')
    };
  }

  toggle(tab) {
    this.setState({ activeTab: tab });
  }

  toggleModal(name, extra = {}) {
    const { openModal } = this.state;
    this.setState({ openModal: openModal === name ? null : name, ...extra });
  }

  handleVincularError(error) {
    const status = get(error, 'response.status');
    if (status === 400) {
      const errors = [
        ...get(error, 'response.data.item', []),
        ...get(error, 'response.data.non_field_errors', [])
      ];
      toast.error(
        <ul>
          {errors.map((e) => (
            <li key={e}>{e}</li>
          ))}
        </ul>
      );
    } else {
      toast.error('Ocorreu um erro.');
    }
  }

  vincularItem = (item) => {
    const { pedidoSelecionado } = this.state;
    axios
      .post(`${API_HOST}/encomenda/elaborador_item`, {
        item: item.id,
        pedido: pedidoSelecionado.id
      })
      .then(() => {
        toast.success('Item vinculado com sucesso.');
        const { itensVinculados } = this.state;
        this.setState({ itensVinculados: [...itensVinculados, item.id] });
      })
      .catch((e) => {
        this.handleVincularError(e);
      });
  };

  vincularItemNovoFluxo = (item) => {
    const { encomendaSelecionada } = this.state;
    axios
      .post(`${API_HOST}/encomenda/elaborador_item/vincular_fluxo`, {
        item: item.id,
        encomenda: get(encomendaSelecionada, 'id')
      })
      .then((res) => {
        toast.success('Item vinculado com sucesso.');
        const { itensVinculados } = this.state;
        this.setState({ itensVinculados: [...itensVinculados, item.id] });
      })
      .catch((e) => {
        this.handleVincularError(e);
      });
  };

  desvincularItem = (item) => {
    const { pedidoSelecionado } = this.state;
    axios
      .delete(
        `${API_HOST}/encomenda/pedido/${pedidoSelecionado.id}/desvincular`,
        {
          params: {
            item: item.id
          }
        }
      )
      .then(() => {
        toast.success('Item desvinculado com sucesso.');
        const { itensVinculados } = this.state;
        this.setState({
          itensVinculados: itensVinculados.filter((d) => d !== item.id)
        });
      })
      .catch((e) => {
        this.handleVincularError(e);
      });
  };

  desvincularItemEncomenda = (item) => {
    const { encomendaSelecionada } = this.state;
    axios
      .delete(
        `${API_HOST}/encomenda/pedido/${encomendaSelecionada.id}/desvincular`,
        {
          params: {
            item: item.id
          }
        }
      )
      .then(() => {
        toast.success('Item desvinculado com sucesso.');
        const { itensVinculados } = this.state;
        this.setState({
          itensVinculados: itensVinculados.filter((d) => d !== item.id)
        });
      })
      .catch((e) => {
        this.handleVincularError(e);
      });
  };

  handleVincularItens = (pedido) => {
    this.setState({
      pedidoSelecionado: pedido,
      openModal: 'vincularItens'
    });
  };

  handleVincularItensEncomenda = (encomenda) => {
    this.setState({
      encomendaSelecionada: encomenda,
      openModal: 'vincularItens'
    });
  };

  filterTable = (list, search) => {
    const { ordering } = this.state;
    if (ordering) {
      const { index, desc } = ordering;
      list.sort((a, b) => {
        if (a[index] > b[index]) {
          return desc ? -1 : 1;
        }
        if (a[index] < b[index]) {
          return desc ? 1 : -1;
        }
        return 0;
      });
    }
    if (search) {
      return list.filter((e) => upperCase(e).includes(upperCase(search)));
    }

    return list;
  };

  handleVerMaisPedido = (row) => {
    const itensElaboradorProps = {
      nome: row.original.nome,
      id: row.original.id,
      filters: {
        elaborador: row.original.id
      }
    };
    this.toggleModal('itensElaborador', { itensElaboradorProps });
  };

  downloadExcel = (data) => {
    let jsonArray = [];

    data.map((d) => {
      let jsonFormated = {};

      if (JSON.stringify(d.itens) === JSON.stringify([])) {
        jsonFormated = {
          'Nome Elaborador': d.nome,
          'Código do item': 'Não possui itens criados',
          'Status do item': 'Não possui status',
          'Dificuldade Presumida': 'Não possui dificuldade',
          'Data do item': 'Não possui data',
          'Total de itens solicitados': d.quantidade,
          'Total de itens entregue': d.entregues
        };
        jsonArray.push(jsonFormated);
      }
      d.itens.map((it) => {
        jsonFormated = {
          'Nome Elaborador': it.criado_por,
          'Código do item': it.codigo_item,
          'Status do item': it.status,
          'Dificuldade Presumida': it.dificuldade_presumida,
          'Data de criação do item': it.criado_em,
          'Item enviado para revisão': it.revisao,
          'Total de itens solicitados': it.quantidade,
          'Total de itens entregue': it.entregues
        };
        jsonArray.push(jsonFormated);
      });
    });

    const worksheet = XLSX.utils.json_to_sheet(jsonArray);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      'Detalhe itens - Elaboradores'
    );
    XLSX.writeFile(
      workbook,
      `Elaboradores-${get(data, '[0].encomenda_nome')}.xlsx`
    );
  };

  downloadExcelRevisor = (data) => {
    let jsonArray = [];
    data.map((d) => {
      let jsonFormated = {};

      if (JSON.stringify(d.status_item) === JSON.stringify([])) {
        jsonFormated = {
          'Nome Revisor': d.nome,
          'Código do item': 'Não houve revisão',
          'Status do item': 'Não houve revisão',
          'Total de itens solicitados': d.solicitado,
          'Total de itens revisados': d.revisado,
          'Tipo de Revisão': 'Não houve revisão'
        };
        jsonArray.push(jsonFormated);
      }

      d.status_item.map((st) => {
        jsonFormated = {
          'Nome Revisor': st.criado_por,
          'Código do item': st.item_id,
          'Status do item': st.descricao,
          'Total de itens solicitados': st.quantidade,
          'Total de itens revisados': st.revisados,
          'Tipo de Revisão': st.tipo_revisao,
          'Finalizado em': st.finalizado_em
        };
        jsonArray.push(jsonFormated);
      });
    });

    const worksheet = XLSX.utils.json_to_sheet(jsonArray);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      'Detalhe itens - Revisores'
    );
    XLSX.writeFile(
      workbook,
      `Revisores-${get(data, '[0].encomenda_nome')}.xlsx`
    );
  };

  handleAddAll = (itemFilters) => {
    if (itemFilters.palavras_chave) {
      itemFilters.palavras_chave = get(itemFilters, 'palavras_chave.label');
    }
    const { encomenda } = this.props;
    axios
      .post(`${API_HOST}/encomenda/bulk_item`, {
        encomenda: encomenda,
        itens: itemFilters
      })
      .then(() => {
        this.props.fetchEncomenda();
        this.toggleModal(null);
      })
      .catch((err) => {
        console.error(err);
        toast.error('Ocorreu um erro ao vincular os itens a encomenda');
      });
  };

  render() {
    const { activeTab, itensVinculados, openModal, itensElaboradorProps } =
      this.state;
    const {
      tabs,
      encomenda,
      pedidos,
      encomenda_dados,
      view_revisor_permission,
      view_generate_report_permission
    } = this.props;

    return (
      <div className="card-item card-alone">
        {view_generate_report_permission ? (
          <div style={{ float: 'right', height: 0 }}>
            <button
              id="relatorio-revisor"
              key="3"
              className="btn btn-white mb-3"
              onClick={() => this.fetchRevisores()}
            >
              <i className="far fa-file-excel" />
              <span style={{ marginLeft: '5px' }}>
                Gerar relatório de Revisores
              </span>
            </button>
            <button
              id="relatorio-elaborador"
              key="3"
              className="btn btn-white mb-3"
              style={{ marginLeft: '10px' }}
              onClick={() => this.fetchElaboradores()}
            >
              <i className="far fa-file-excel" />
              <span style={{ marginLeft: '5px' }}>
                Gerar relatório de Elaboradores
              </span>
            </button>
          </div>
        ) : null}
        <Nav tabs>
          {tabs.find((d) => d === 'pedidos') && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 'pedidos' })}
                onClick={() => this.toggle('pedidos')}
              >
                Pedidos
              </NavLink>
            </NavItem>
          )}
          {tabs.find((d) => d === 'elaboradores') && (
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === 'elaboradores'
                })}
                onClick={() => this.toggle('elaboradores')}
              >
                Elaboradores
              </NavLink>
            </NavItem>
          )}
          {tabs.find((d) => d === 'revisores') && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 'revisores' })}
                onClick={() => this.toggle('revisores')}
              >
                Revisores
              </NavLink>
            </NavItem>
          )}
        </Nav>

        <TabContent activeTab={activeTab}>
          {tabs.find((d) => d === 'pedidos') && (
            <TabPane tabId="pedidos">
              <div className="card-body no-radius-top">
                {get(encomenda_dados, 'habilitar_fluxo') ? (
                  <EncomendasFluxoTab
                    handleVincularItens={() =>
                      this.handleVincularItensEncomenda(encomenda_dados)
                    }
                    getItensVinculados={(itens) =>
                      this.setState({ itensVinculados: itens })
                    }
                    handleVerMais={(row) =>
                      this.handleVerMaisPedido(row, encomenda)
                    }
                    encomenda={encomenda_dados}
                  />
                ) : (
                  <>
                    {map(pedidos, (pedido) => (
                      <PedidosTab
                        key={pedido.id}
                        startCollapsed={view_revisor_permission}
                        pedido={pedido}
                        handleVincularItens={() =>
                          this.handleVincularItens(pedido)
                        }
                        handleVerMais={(row) =>
                          this.handleVerMaisPedido(row, pedido.id)
                        }
                        getItensVinculados={(itens) =>
                          this.setState({ itensVinculados: itens })
                        }
                        labelCompetencia={
                          this.shouldDisplayDefault('matriz.competencia') ||
                          'Competência'
                        }
                        labelHabilidade={
                          this.shouldDisplayDefault('matriz.habilidade') ||
                          'Habilidade'
                        }
                      />
                    ))}
                  </>
                )}
              </div>
            </TabPane>
          )}
          {tabs.find((d) => d === 'revisores') && (
            <TabPane tabId="revisores">
              <div className="card-body no-radius-top">
                <RevisoresTab
                  encomenda={encomenda}
                  history={this.props.history}
                  shouldFetchData={activeTab === 'revisores'}
                />
              </div>
            </TabPane>
          )}
          {tabs.find((d) => d === 'elaboradores') && (
            <TabPane tabId="elaboradores">
              <div className="card-body no-radius-top">
                <ElaboradoresTab
                  encomenda={encomenda}
                  shouldFetchData={activeTab === 'elaboradores'}
                />
              </div>
            </TabPane>
          )}
        </TabContent>

        <FiltroAvancadoItens
          isOpen={openModal === 'vincularItens'}
          toggle={() => this.toggleModal('vincularItens')}
          filter={false}
          onAddAll={this.handleAddAll}
          onResultClick={
            get(encomenda_dados, 'habilitar_fluxo')
              ? this.vincularItemNovoFluxo
              : this.vincularItem
          }
          checked={itensVinculados}
          initialValues={this.initialValuesBuscaAvancadaItem}
          vincularItem={
            get(encomenda_dados, 'habilitar_fluxo')
              ? this.vincularItemNovoFluxo
              : this.vincularItem
          }
          desvincularItem={
            get(encomenda_dados, 'habilitar_fluxo')
              ? this.desvincularItemEncomenda
              : this.desvincularItem
          }
        />

        <ModalItensElaborador
          isOpen={openModal === 'itensElaborador'}
          toggle={() =>
            this.toggleModal('itensElaborador', { selecionado: null })
          }
          {...itensElaboradorProps}
        />
      </div>
    );
  }
}

export default withRouter(ListaEncomendaCardTabs);
