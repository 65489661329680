import React, { Fragment } from 'react';
import {
  Nav,
  NavItem,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { API_HOST, UserContext } from '../consts';
import Logo from '../assets/images/logos/Logos';
import UserImg from '../assets/images/dev/faceimg.jpg';
import { ThemeContext } from '../contexts/ThemeContext';

export default class NavHeader extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.toggleOptions = this.toggleOptions.bind(this);
    this.toggleConfig = this.toggleConfig.bind(this);
    this.state = {
      dropdownUser: false,
      dropdownOptions: false,
      toggleConfig: false,
    };
  }

  toggle() {
    this.setState({
      dropdownUser: !this.state.dropdownUser,
    });
  }
  toggleOptions() {
    this.setState({
      dropdownOptions: !this.state.dropdownOptions,
    });
  }

  toggleConfig() {
    this.setState({
      toggleConfig: !this.state.toggleConfig,
    });
  }

  toggleHelp = () => {
    this.setState({
      toggleHelp: !this.state.toggleHelp,
    });
  };

  logout() {
    localStorage.removeItem('token');
    window.location.href = '/login';
  }

  render() {
    const token = localStorage.getItem('token');
    return (
      <UserContext.Consumer>
        {({ user, permissions }) => (
          <div className="container">
            <div className="row header-row">
              <div className="col-sm-6 col-md-6">
                <Nav className="nav-logos">
                  <NavItem>
                    <figure>
                      <ThemeContext.Consumer>
                        {theme => (
                          <Link to="/"><img className={Logo.client ? 'logo-client' : 'logo-main'} src={theme.logoImg || Logo.client || Logo.main} alt={Logo.clientText || Logo.mainText} height="70" /></Link>
                        )}
                      </ThemeContext.Consumer>
                    </figure>
                  </NavItem>
                </Nav>
              </div>
              <div className="col-md-6 col-sm-6 user-align">
                <Nav className="nav-user">
                  <NavItem>
                    <Link to="/perfil">
                      <figure>
                        <img src={UserImg} className="img-user" alt="user" />
                      </figure>
                    </Link>
                  </NavItem>
                  <NavItem className="menu-config">
                    <Dropdown
                      isOpen={this.state.dropdownOptions}
                      toggle={this.toggleOptions}
                    >
                      <DropdownToggle className="dropdown-nav">
                        <span className="user-name">
                          {get(user, 'last_name')}
                        </span>
                      </DropdownToggle>
                      <DropdownMenu position="right">
                        <span className="dropdown-item divider-menu">
                          PERFIL
                        </span>
                        <Link
                          onClick={this.toggleOptions}
                          className="dropdown-item children-menu"
                          to="/perfil"
                        >
                          Alterar Senha
                        </Link>
                        <Link
                          onClick={this.logout}
                          className="dropdown-item children-menu"
                          to="/configuracoes/grupos"
                        >
                          Sair
                        </Link>
                      </DropdownMenu>
                    </Dropdown>
                  </NavItem>
                  <NavItem className="menu-config">
                    <Dropdown
                      isOpen={this.state.toggleHelp}
                      toggle={this.toggleHelp}
                    >
                      <DropdownToggle className="dropdown-nav">
                        <i
                          className="fas fa-question-circle"
                          style={{ fontSize: '20px', verticalAlign: 'middle' }}
                        />
                      </DropdownToggle>
                      <DropdownMenu position="left">
                        <span className="dropdown-item divider-menu">
                          AJUDA
                        </span>
                        <a
                          onClick={this.toggleHelp}
                          className="dropdown-item children-menu"
                          href="https://educat.freshdesk.com/support/login"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Abrir chamado <i className="fa fa-external-link" />
                        </a>
                      </DropdownMenu>
                    </Dropdown>
                  </NavItem>
                  <NavItem className="menu-config">
                    <Dropdown
                      isOpen={this.state.toggleConfig}
                      toggle={this.toggleConfig}
                    >
                      <DropdownToggle className="dropdown-nav">
                        <i
                          className="fas fa-cog"
                          style={{ fontSize: '20px', verticalAlign: 'middle' }}
                        />
                      </DropdownToggle>
                      <DropdownMenu position="left">
                        {get(permissions, '__all__') && (
                          <Fragment>
                            <span className="dropdown-item divider-menu">
                              Administração
                            </span>
                            <a
                              href={`${API_HOST}/login/admin-redirect/${token}`}
                              className="dropdown-item children-menu"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Painel <i className="fa fa-external-link" />
                            </a>
                          </Fragment>
                        )}
                        <span className="dropdown-item divider-menu">
                          Usuários
                        </span>
                        <Link
                          onClick={this.toggleConfig}
                          className="dropdown-item children-menu"
                          to="/usuarios"
                        >
                          Lista
                        </Link>
                        <span className="dropdown-item divider-menu">
                          Grupos
                        </span>
                        <button
                          type="button"
                          className="disabled dropdown-item children-menu"
                        >
                          Lista
                        </button>
                      </DropdownMenu>
                    </Dropdown>
                  </NavItem>
                  <NavItem className="menu-config">
                    <Dropdown
                      isOpen={this.state.dropdownUser}
                      toggle={this.toggle}
                    >
                      <DropdownToggle className="dropdown-nav">
                        <i
                          className="far fa-bell"
                          style={{ fontSize: '20px', verticalAlign: 'middle' }}
                        />
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem className="dropdown-item divider-menu ">
                          0 notificações
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </NavItem>
                </Nav>
              </div>
            </div>
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}
