// @ts-ignore
import React, { useEffect, useRef, useState } from 'react';
import {
  select,
  axisBottom,
  stack,
  max,
  scaleLinear,
  axisLeft,
  // @ts-ignore
  axisRight,
  // @ts-ignore
  stackOrderAscending,
  area,
  scalePoint,
  // @ts-ignore
  curveCardinal,
  // @ts-ignore
  svg,
  curveBasis,
  // @ts-ignore
  curveBasisClosed,
  // @ts-ignore
  selectAll,
  // @ts-ignore
  line,
  // @ts-ignore
  on,
  // @ts-ignore
  format,
  // @ts-ignore
  window,
  // @ts-ignore
  geoMercator
} from 'd3';
import 'd3';
import useResizeObserver from './useResizeObserver';
import { get } from 'lodash';
const profData = [
  [
    {
      year: '2ºEF',
      'Abaixo do básico': 0,
      Básico: 25,
      Adequado: 50,
      Avançado: 225,
      base: 100
    },
    {
      year: '3ºEF',
      'Abaixo do básico': 25,
      Básico: 50,
      Adequado: 50,
      Avançado: 175,
      base: 100
    },
    {
      year: '4ºEF',
      'Abaixo do básico': 35,
      Básico: 50,
      Adequado: 50,
      Avançado: 165,
      base: 100
    },
    {
      year: '5ºEF',
      'Abaixo do básico': 50,
      Básico: 50,
      Adequado: 50,
      Avançado: 150,
      base: 100
    },
    {
      year: '6ºEF',
      'Abaixo do básico': 65,
      Básico: 45,
      Adequado: 50,
      Avançado: 140,
      base: 100
    },
    {
      year: '7ºEF',
      'Abaixo do básico': 75,
      Básico: 50,
      Adequado: 50,
      Avançado: 125,
      base: 100
    },
    {
      year: '8ºEF',
      'Abaixo do básico': 100,
      Básico: 75,
      Adequado: 50,
      Avançado: 75,
      base: 100
    },
    {
      year: '9ºEF',
      'Abaixo do básico': 100,
      Básico: 75,
      Adequado: 50,
      Avançado: 75,
      base: 100
    },
    {
      year: '1ªEM',
      'Abaixo do básico': 115,
      Básico: 65,
      Adequado: 60,
      Avançado: 60,
      base: 100
    },
    {
      year: '2ªEM',
      'Abaixo do básico': 130,
      Básico: 60,
      Adequado: 60,
      Avançado: 50,
      base: 100
    },
    {
      year: '3ªEM',
      'Abaixo do básico': 150,
      Básico: 50,
      Adequado: 75,
      Avançado: 25,
      base: 100
    }
  ],
  [
    {
      year: '2ºEF',
      'Abaixo do básico': 25,
      Básico: 25,
      Adequado: 25,
      Avançado: 225,
      base: 100
    },
    {
      year: '3ºEF',
      'Abaixo do básico': 50,
      Básico: 50,
      Adequado: 50,
      Avançado: 150,
      base: 100
    },
    {
      year: '4ºEF',
      'Abaixo do básico': 60,
      Básico: 50,
      Adequado: 50,
      Avançado: 140,
      base: 100
    },
    {
      year: '5ºEF',
      'Abaixo do básico': 75,
      Básico: 50,
      Adequado: 50,
      Avançado: 125,
      base: 100
    },
    {
      year: '6ºEF',
      'Abaixo do básico': 85,
      Básico: 75,
      Adequado: 25,
      Avançado: 115,
      base: 100
    },
    {
      year: '7ºEF',
      'Abaixo do básico': 100,
      Básico: 50,
      Adequado: 50,
      Avançado: 100,
      base: 100
    },
    {
      year: '8ºEF',
      'Abaixo do básico': 115,
      Básico: 35,
      Adequado: 75,
      Avançado: 75,
      base: 100
    },
    {
      year: '9ºEF',
      'Abaixo do básico': 125,
      Básico: 75,
      Adequado: 50,
      Avançado: 50,
      base: 100
    },
    {
      year: '1ªEM',
      'Abaixo do básico': 135,
      Básico: 75,
      Adequado: 50,
      Avançado: 40,
      base: 100
    },
    {
      year: '2ªEM',
      'Abaixo do básico': 150,
      Básico: 75,
      Adequado: 50,
      Avançado: 25,
      base: 100
    },
    {
      year: '3ªEM',
      'Abaixo do básico': 175,
      Básico: 75,
      Adequado: 50,
      Avançado: 0,
      base: 100
    }
  ]
];
const allColors = {
  'Abaixo do básico': '#FFCECE',
  Básico: '#FFE74F',
  Adequado: '#BFEEA6',
  Avançado: '#ABD4EC',
  base: '#f7f7f7',
  Aluno: '#FFFFFF',
  Escola: '#47BEF3',
  Turma: '#737272',
  DRE: '#B972F1',
  Estado: '#001AFF'
};
const allKeys = ['Abaixo do básico', 'Básico', 'Adequado', 'Avançado'];
const nomeDisc = [
  'Linguagens, Códigos e suas Tecnologias',
  'Matemática e suas Tecnologias'
];

function StackedBarChart({ dataDot, pontos, useBase }) {
  /* Inicio da função que gera nosso gráfico de área */
  const etapa = [
    '2ºEF',
    '3ºEF',
    '4ºEF',
    '5ºEF',
    '6ºEF',
    '7ºEF',
    '8ºEF',
    '9ºEF',
    '1ªEM',
    '2ªEM',
    '3ªEM'
  ];
  const svgRef = useRef();
  const wrapperRef = useRef();
  const dimensions = useResizeObserver(wrapperRef);
  const id_disc = get(dataDot, 'CD_DISCIPLINA') - 1;
  const data = profData[id_disc];
  const titulo = nomeDisc[id_disc];
  const keys = allKeys;
  const colors = allColors;
  const nr_et = get(dataDot, 'NR_ETAPA');
  if (nr_et <= 9) {
    var cor = 2;
  } else {
    var cor = 3;
  }
  const dataScatter = [
    {
      year: etapa[get(dataDot, 'NR_ETAPA') - cor],
      name: get(dataDot, 'NM_ALUNO'),
      type: 'Aluno',
      nota: parseInt(get(dataDot, 'NT_ALUNO'))
    },
    {
      year: etapa[get(dataDot, 'NR_ETAPA') - cor],
      name: get(dataDot, 'NM_DRE'),
      type: 'DRE',
      nota: parseInt(get(dataDot, 'NT_DRE'))
    },
    {
      year: etapa[get(dataDot, 'NR_ETAPA') - cor],
      name: get(dataDot, 'NM_ESCOLA'),
      type: 'Escola',
      nota: parseInt(get(dataDot, 'NT_ESCOLA'))
    },
    {
      year: etapa[get(dataDot, 'NR_ETAPA') - cor],
      name: 'Rede de ensino',
      type: 'Estado',
      nota: parseInt(get(dataDot, 'NT_REDE'))
    }
  ];

  /* Definição das funçõe utilizadas para a renderização do gráfico */
  useEffect(() => {
    // evento que renderiza os dados no gráfico, nota-se que podemos implementar essa função de adicionar dados no nosso gráfico
    // modelo
    // @ts-ignore
    const svg = select(svgRef.current);
    const { width, height } =
      // @ts-ignore
      dimensions || wrapperRef.current.getBoundingClientRect(); //aparentemente essa parte fala do gráfico

    // stacks / layers
    const stackGenerator = stack().keys(keys);
    //.order(stackOrderAscending);
    // @ts-ignore
    const layers = stackGenerator(data);
    const extent = [
      100,
      max(layers, (layer) =>
        max(layer, (sequence) => sequence[1] + sequence.data.base)
      )
    ];
    // scales
    /* aqui definimos como irão funcionar as escalas de x e y, colocando a posição em pixels dos pontos em função das grandezas apresentadas no gráfico */
    const xScale = scalePoint()
      .domain(data.map((d) => d.year))
      .range([0, width]);
    // @ts-ignore
    const yScale = scaleLinear().domain(extent).range([height, 0]);

    // axes
    /* Aqui fazemos as declarações relativas aos eixos do gráfico, onde tudo que está dentro de svg é relativo ao style do elemento */
    const xAxis = axisBottom(xScale);
    svg
      .select('.x-axis')
      .attr('transform', `translate(0, ${height})`)
      // @ts-ignore
      .call(xAxis);
    svg
      .append('text')
      .attr('transform', `rotate(-90)`)
      .attr('dx', `${-height / 2}` + 'px')
      .attr('dy', `-50` + 'px')
      .style('font-family', 'helvetica')
      .style('font-size', '30px')
      .style('text-anchor', 'middle')
      .text('Nota');
    // @ts-ignore
    const makeXLines = () => axisBottom().scale(xScale);
    svg
      /* Nessa segunda declaração de estilo, temos as linhas que introduzem as linhas verticais do grid */
      .append('g')
      .attr('class', 'grid')
      // @ts-ignore
      .call(makeXLines().tickSize(height, 0, 0).tickFormat(''))
      .attr('color', '#8B8B8B');

    const yAxis = axisLeft(yScale);
    // @ts-ignore
    svg.select('.y-axis').call(yAxis);

    // @ts-ignore
    const makeYLines = () => axisLeft().scale(yScale);
    svg
      /* Nessa segunda declaração de estilo, temos as linhas que introduzem as linhas horizontais do grid */
      .append('g')
      .attr('class', 'grid')
      // @ts-ignore
      .call(makeYLines().tickSize(-width, 0, 0).tickFormat(''))
      .attr('color', '#8B8B8B');
    svg
      .append('text')
      .attr('transform', ``)
      .attr('dx', `${width / 2}` + 'px')
      .attr('dy', `${height + 60}` + 'px')
      .style('font-family', 'helvetica')
      .style('font-size', '30px')
      .style('text-anchor', 'middle')
      .text('Etapa Escolar');

    /* Em areaGenerator definimos como o gráfico de áreas será gerado a partir dos dados passados em data e pelas escalas definidas anteriormente */
    if (useBase === true) {
      // @ts-ignore
      var areaGenerator = area()
        // @ts-ignore
        .x((sequence) => xScale(sequence.data.year)) //define que a mudança em x é relativa ao elemento date.year do array de dados data
        // @ts-ignore
        .y0((sequence) => yScale(sequence[0] + sequence.data.base)) // define a posição relativa y0 em função do topo o elemento anterior e do elemento de base
        // @ts-ignore
        .y1((sequence) => yScale(sequence[1] + sequence.data.base)) // define a posição relativa y1 em função do topo do elemento anterior e do elemento de dado
        .curve(curveBasis); // define que as variações relativas às mudanças em y serão suavizadas
    } else {
      // @ts-ignore
      var areaGenerator = area()
        // @ts-ignore
        .x((sequence) => xScale(sequence.data.year)) //define que a mudança em x é relativa ao elemento date.year do array de dados data
        .y0((sequence) => yScale(sequence[0] + 100)) // define a posição relativa y0 em função do topo o elemento anterior e do elemento de base
        .y1((sequence) => yScale(sequence[1] + 100)) // define a posição relativa y1 em função do topo do elemento anterior e do elemento de dado
        .curve(curveBasis);
    }
    // rendering
    /* aqui definimos os parametros de style das áreas mostradas no gráfico */
    svg
      .selectAll('.layer')
      .data(layers)
      .join('path')
      .attr('class', 'layer')
      .attr('fill', (layer) => colors[layer.key])
      // @ts-ignore
      .attr('opacity', (layer) => 0.55)
      // @ts-ignore
      .attr('d', areaGenerator)
      .transition()
      .duration(500);

    /* Aqui, caso seja indicado (essa indicação vem pelas props) plota pontos relativos aos  */
    if (pontos === true) {
      var div = select('body')
        .append('div')
        .attr('class', 'tooltip')
        .style('opacity', 0)
        .style('background-color', '#B8B8B8');
      var line = svg
        .append('line')
        // @ts-ignore
        .attr('x1', xScale('2ºEF'))
        // @ts-ignore
        .attr('x2', xScale('2ºEF'))
        .attr('y1', yScale(dataScatter[0].nota))
        .attr('y2', yScale(dataScatter[0].nota))
        .attr('stroke', 'red')
        .attr('stroke-dasharray', '15')
        .style('opacity', 0.01)
        .style('stroke-width', '2px');

      svg
        .selectAll('indPoints')
        .data(dataScatter)
        .enter()
        .append('circle')
        // @ts-ignore
        .attr('cx', function (d) {
          if (d.year == '2ºEF') {
            if (d.type == 'Aluno') {
              // @ts-ignore
              return xScale(d.year) + 0;
            } else if (d.type == 'DRE') {
              // @ts-ignore
              return xScale(d.year) + 6;
            } else if (d.type == 'Escola') {
              // @ts-ignore
              return xScale(d.year) + 9;
            } else if (d.type == 'Estado') {
              // @ts-ignore
              return xScale(d.year) + 3;
            } else if (d.type == 'Turma') {
              // @ts-ignore
              return xScale(d.year) + 7;
            }
          } else if (d.year == '3ªEM') {
            if (d.type == 'Aluno') {
              // @ts-ignore
              return xScale(d.year) - 0;
            } else if (d.type == 'DRE') {
              // @ts-ignore
              return xScale(d.year) - 8;
            } else if (d.type == 'Escola') {
              // @ts-ignore
              return xScale(d.year) - 12;
            } else if (d.type == 'Estado') {
              // @ts-ignore
              return xScale(d.year) - 4;
            } else if (d.type == 'Turma') {
              // @ts-ignore
              return xScale(d.year) - 20;
            }
          } else {
            if (d.type == 'Aluno') {
              // @ts-ignore
              return xScale(d.year) + 0;
            } else if (d.type == 'DRE') {
              // @ts-ignore
              return xScale(d.year) + 6;
            } else if (d.type == 'Escola') {
              // @ts-ignore
              return xScale(d.year) - 12;
            } else if (d.type == 'Estado') {
              // @ts-ignore
              return xScale(d.year) - 6;
            } else if (d.type == 'Turma') {
              // @ts-ignore
              return xScale(d.year) + 12;
            }
          }
        })
        .attr('cy', function (d) {
          if (d.nota > 400) {
            return yScale(400);
          } else if (d.nota < 100) {
            return yScale(100);
          } else {
            return yScale(d.nota);
          }
        })
        .attr('r', function (d) {
          if (isNaN(d.nota)) {
            return 0;
          } else {
            return 3;
          }
        })
        .style('fill', function (d) {
          return colors[d.type];
        })
        .attr('stroke', 'black')
        .attr('class', 'circle')
        .on('mouseover', function (d, i) {
          line
            .transition()
            .attr('x1', xScale('2ºEF'))
            .duration('550')
            .attr('x2', xScale(i.year))
            .attr('y1', yScale(i.nota))
            .attr('y2', yScale(i.nota))
            .attr('stroke', i.type !== 'Aluno' ? colors[i.type] : 'black')
            .style('opacity', 1.0);
          // @ts-ignore
          if (xScale(i.year) >= xScale('8ºEF')) {
            // @ts-ignore
            select(this).transition().duration('100').attr('r', 8);
            div
              .transition()
              .duration(100)
              .style('opacity', 0.8)
              .style('background-color', colors[i.type])
              .style('color', i.type === 'Aluno' ? 'black' : colors['Aluno']);
            div
              .html(i.name + ': ' + i.nota)
              .style('font-family', 'helvetica')
              .style('font-size', '15px')
              .style(
                'left',
                // @ts-ignore
                d.clientX - div._groups[0][0].offsetWidth + 'px'
              )
              .style(
                'top',
                (i.nota <= 100 ? d.layerY + 170 : d.layerY + 200) + 'px'
              )
              .attr('color', '#B8B8B8');
          } else {
            // @ts-ignore

            select(this).transition().duration('100').attr('r', 8);
            div
              .transition()
              .duration(100)
              .style('opacity', 0.8)
              .style('background-color', colors[i.type])
              .style('color', i.type === 'Aluno' ? 'black' : colors['Aluno']);
            div
              .html(i.name + ': ' + i.nota)
              .style('font-family', 'helvetica')
              .style('font-size', '15px')
              .style('left', d.screenX + 10 + 'px')
              .style(
                'top',
                (i.nota < 101 ? d.layerY + 170 : d.layerY + 200) + 'px'
              )
              .attr('color', '#B8B8B8');
          }
        })
        // @ts-ignore
        .on('mouseout', function (d, i) {
          line
            .transition()
            .duration('550')
            .attr('x1', xScale(i.year))
            .attr('x2', xScale(i.year))
            .attr('y1', yScale(i.nota))
            .attr('y2', yScale(i.nota))
            .attr('stroke', 'red')
            .style('opacity', 0.01);
          // @ts-ignore
          select(this).transition().duration('200').attr('r', 3);
          // @ts-ignore
          div.transition().duration('200').style('opacity', 0);
        });
      // svg.append("line")
      //     // @ts-ignore
      //     .attr("x1", xScale(dataScatter[0].year))
      //     // @ts-ignore
      //     .attr("x2", xScale(dataScatter[0].year))
      //     .attr("y1", yScale(100))
      //     .attr("y2", yScale(dataScatter[0].nota))
      //     .attr("stroke", "red")
      //     .attr("stroke-dasharray", "4");
    }
  }, [colors, data, dimensions, keys]);
  return (
    <div style={{ width: '80%', marginTop: '1rem' }}>
      <div style={{ textAlign: 'center' }}>
        <h1>{titulo}</h1>
      </div>
      <div
        // @ts-ignore
        ref={wrapperRef}
        style={{ marginBottom: '3rem' }}
      >
        <svg
          // @ts-ignore
          ref={svgRef}
        >
          <g className="x-axis" />
          <g className="y-axis" />
        </svg>
      </div>
      <div
        className="fields"
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        {keys.map((key) => (
          <div
            style={{
              position: 'relative',
              height: '70px',
              justifyContent: 'space-between'
            }}
          >
            <div
              // @ts-ignore
              key={keys}
              className="field"
              style={{
                position: 'relative',
                bottom: '-100%',
                display: 'flex',
                fontFamily: 'helvetica',
                justifyContent: 'space-between'
              }}
            >
              <div
                style={{
                  padding: '0.7rem',
                  width: '20px',
                  background: colors[key],
                  borderRadius: '6px',
                  fontFamily: 'open-sans',
                  marginLeft: '1rem'
                }}
              />
              <label
                htmlFor=" o"
                // @ts-ignore
                height="20px"
                style={{ display: 'flex', fontFamily: 'helvetica' }}
              ></label>
              <label
                htmlFor={key}
                style={{
                  // @ts-ignore
                  x: '30%',
                  color: '#000'
                }}
              >
                {key}
              </label>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default StackedBarChart;
