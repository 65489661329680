import React from 'react';
import axios from 'axios';
import { get, map } from 'lodash';
import classnames from 'classnames';
import { ModalBody, Modal, ModalHeader, ModalFooter } from 'reactstrap';
import ReactPaginate from 'react-paginate';
import CardReference from '../components/CardReference';
import ListTag from '../components/ListTag';
import Item from '../components/Item';
import Loading from '../components/Loading';
import BaseFiltroAvancadoItemInstrumento from './BaseFiltroAvancadoItemInstrumento';
import { API_HOST } from '../consts';
import { toast } from 'react-toastify';

class BaseFiltroAvancadoItemAvaliacao extends BaseFiltroAvancadoItemInstrumento {
  constructor(props) {
    super(props);
    this.firstTimeOpening = true;
  }

  state = {
    itens: [],
    activeTab: 'filtros',
    total: 0,
    sortBySearchedIdOrder: true
  };

  handleOrderBySearchIdCheckboxChange = (event) => {
    const value = event.target.checked;
    this.setState({
      sortBySearchedIdOrder: value
    });
  };

  handleSubmit = () => {
    const { values } = this.formApi.getState();
    const { page, page_size } = this.state;

    if (JSON.stringify(values) === JSON.stringify({})) {
      toast.error(
        'Para prosseguir com essa ação deve ser preenchido os campos de filtro'
      );
    } else {
      this.setState({ activeTab: 'resultados', fetchingData: true });
      axios
        .get(`${API_HOST}/itens/item/permission_status_active`, {
          params: {
            page,
            page_size,
            ...this.getValues(values)
          }
        })
        .then((response) => {
          this.setState({
            fetchingData: false,
            itens: response.data.results,
            total: response.data.count,
            total_pages: response.data.total_pages
          });
        })
        .catch((error) => {
          this.setState({ activeTab: 'filtros', fetchingData: false });
          if (error.response && error.response.status === 400)
            toast.error('Não possui itens com status como ATIVO/APROVADO');
        });
    }
  };

  handleAddAll = () => {
    const { onAddAll } = this.props;
    const { values } = this.formApi.getState();
    onAddAll(this.getValues(values));
  };

  mapItem = (item) => {
    const { checked } = this.props;

    return (
      <div className="col-md-12 mb-3" key={item.id}>
        <CardReference
          descricao={
            <div className="row">
              <div className="col-md-9 list-data-title">
                <h3>Item {item.id}</h3>
              </div>
              <div className="col-md-3 mb-3">
                <ListTag
                  label={get(item, 'status.descricao')}
                  color={get(item, 'status.cor')}
                />
              </div>
              <div className="col-md-12">
                <Item item={item} />
              </div>
            </div>
          }
          onChange={(event) => this.handleCheckboxChange(event, item.id)}
          checked={checked.find((id) => id === item.id)}
          borderRight={false}
        />
      </div>
    );
  };

  getItensByOrderedIds = (ids) => {
    const { itens } = this.state;
    const orderedItens = [];
    ids.forEach((id) =>
      orderedItens.push(itens.filter((item) => item.id === id))
    );
    return orderedItens.flat();
  };

  getItensNotInIds = (ids) => {
    const { itens } = this.state;
    return itens.filter((item) => !ids.includes(item.id));
  };

  getSearchedIdsIntList = () => {
    const idFieldString = this.formApi.getState().values.id;
    if (idFieldString) {
      return idFieldString.split(',').map((id) => parseInt(id, 10));
    }
    return null;
  };

  mapBySearchIdsOrder = () => {
    const { itens } = this.state;
    const ids = this.getSearchedIdsIntList();
    if (ids) {
      const sortedItens = this.getItensByOrderedIds(ids);
      const itensNotInIds = this.getItensNotInIds(ids);
      return map([...sortedItens, ...itensNotInIds], this.mapItem);
    }
    return map(itens, this.mapItem);
  };

  changePage = (pagination) => {
    this.setState({ fetchingData: true });
    const page = pagination.selected + 1;
    this.setState({ page }, this.handleSubmit);
  };

  renderPagination() {
    const { page, itens, total_pages, fetchingData } = this.state;

    if (fetchingData) {
      return null;
    }

    if (itens.length > 0) {
      return (
        <nav style={{ textAlign: 'center' }}>
          <ReactPaginate
            pageCount={total_pages}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            previousLabel="ANTERIOR"
            nextLabel="PRÓXIMA"
            onPageChange={this.changePage}
            initialSelected={page}
            forcePage={page - 1}
            containerClassName="pagination"
            activeClassName="active"
            disabledClassName="disabled"
            disableInitialCallback
            breakLabel={<button className="disabled">...</button>}
          />
        </nav>
      );
    }

    return null;
  }

  displayItemResults = () => {
    const { itens, sortBySearchedIdOrder } = this.state;
    if (itens.length > 0) {
      return sortBySearchedIdOrder
        ? this.mapBySearchIdsOrder()
        : map(itens, this.mapItem);
    }
    return null;
  };

  renderResults() {
    const { fetchingData, total, sortBySearchedIdOrder } = this.state;
    const { onAddAll } = this.props;

    return (
      <React.Fragment>
        <div className="modal-separator">
          <h5>Itens encontrados</h5>
        </div>
        <label className="col-md-6 ml-3">
          <input
            name="sortBySearchedIdOrderCheckbox"
            type="checkbox"
            checked={sortBySearchedIdOrder}
            onChange={this.handleOrderBySearchIdCheckboxChange}
            style={{ marginRight: '5px' }}
          />
          Ordenar pelo código dos itens inseridos na busca
        </label>

        {onAddAll && total > 0 && (
          <p className="select-item-help form-text text-muted">
            Clique na caixa de seleção ao lado do item para adicioná-lo ao
            instrumento cognitivo, ou
            <button className="btn btn-link" onClick={this.handleAddAll}>
              {' '}
              clique aqui{' '}
            </button>
            para adicionar os {total} itens simultaneamente. Os itens que já
            fazem parte do instrumento serão ignorados.
          </p>
        )}
        <div className="number-itens">
          <p style={{ float: 'none' }}>{total} itens</p>
        </div>
        <div className="pr-3 pl-3">
          {fetchingData && <Loading />}
          {!fetchingData && (
            <React.Fragment>
              <div className="row">{this.displayItemResults()}</div>
              {this.renderPagination()}
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }

  renderFooter() {
    const { activeTab, fetchingData } = this.state;

    if (activeTab === 'filtros') {
      return (
        <ModalFooter>
          <button
            className="btn danger-button"
            type="button"
            onClick={this.toggle}
          >
            Cancelar
          </button>
          <button
            className="btn primary-button"
            type="button"
            onClick={this.handleSubmit}
            disabled={fetchingData}
          >
            {fetchingData ? 'Buscando...' : 'Buscar'}
          </button>
        </ModalFooter>
      );
    }

    if (activeTab === 'resultados') {
      return (
        <ModalFooter>
          <button
            className="btn btn-white"
            type="button"
            onClick={() => this.setState({ activeTab: 'filtros' })}
          >
            Nova busca
          </button>
          <button
            className="btn primary-button"
            type="button"
            onClick={this.toggle}
          >
            Concluir
          </button>
        </ModalFooter>
      );
    }

    return null;
  }

  render() {
    const { isOpen } = this.props;
    const { activeTab } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        toggle={this.toggle}
        size="lg"
        onOpened={this.onOpened}
      >
        <ModalHeader toggle={this.toggle}>Buscar itens</ModalHeader>
        <ModalBody className="p-0">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <button
                className={classnames('nav-link tab-modal', {
                  active: activeTab === 'filtros'
                })}
                onClick={() => this.setState({ activeTab: 'filtros' })}
              >
                Filtros
              </button>
            </li>
            <li className="nav-item">
              <button
                className={classnames('nav-link tab-modal', {
                  active: activeTab === 'resultados'
                })}
                onClick={() => this.setState({ activeTab: 'resultados' })}
              >
                Resultado
              </button>
            </li>
          </ul>
          <div className="tab-content">
            <div
              className={classnames('tab-pane', {
                active: activeTab === 'filtros'
              })}
            >
              {this.renderForm()}
            </div>
            <div
              className={classnames('tab-pane', {
                active: activeTab === 'resultados'
              })}
            >
              {this.renderResults()}
            </div>
          </div>
        </ModalBody>
        {this.renderFooter()}
      </Modal>
    );
  }
}

export default BaseFiltroAvancadoItemAvaliacao;
