import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import LabelView from '../../components/LabelView';
import { toggleDetalhesParametroImportado } from '../../actions/AnalisePsicometrica';

class DetalhesParametroImportadoModal extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggleDetalhesParametroImportado: PropTypes.func.isRequired,
    detalhesParametro: PropTypes.shape({
      titulo: PropTypes.string,
      status_importacao_parametro: PropTypes.string,
      observacao: PropTypes.string,
      dt_importacao: PropTypes.string,
      arquivo: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    }).isRequired,
  };

  renderBody() {
    const {
      titulo,
      arquivo,
      dt_importacao,
      status_importacao_parametro,
      observacao,
    } = this.props.detalhesParametro;

    return (
      <div className="row mt-2">
        <LabelView
          col="4"
          title="Título"
          text={titulo || '-'}
        />
        <LabelView
          col="4"
          title="Arquivo"
          text={arquivo && arquivo.name ? arquivo.name : '-'}
        />
        <LabelView
          col="4"
          title="Data do anexo"
          text={dt_importacao || '-'}
        />
        <LabelView
          col="12"
          title="Status"
          text={status_importacao_parametro || '-'}
        />
        <LabelView
          col="12"
          title="Observação"
          text={observacao || '-'}
        />
      </div>
    );
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggleDetalhesParametroImportado}
        size="lg"
      >
        <ModalHeader toggle={this.props.toggleDetalhesParametroImportado}>
          Parâmetros do pré-teste
        </ModalHeader>
        <ModalBody>
          {this.renderBody()}
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-padrao bg-padrao"
            onClick={this.props.toggleDetalhesParametroImportado}
          >
            Fechar
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  isOpen: state.AnalisePsicometrica.reducer.detalhesParametroModal,
  detalhesParametro: state.AnalisePsicometrica.reducer.detalhesParametro,
});

export default connect(mapStateToProps, {
  toggleDetalhesParametroImportado,
})(DetalhesParametroImportadoModal);
