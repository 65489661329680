import React from 'react';
import FIcon from '../../../components/text/FIcon';
import {
  COPYRIGHT_MESSAGE,
  CONTACT_EMAIL,
} from '../../../consts';
import Logo from '../../../assets/images/logos/Logos';
import { ThemeContext } from '../../../contexts/ThemeContext';
import { Footer as AppFooter } from './styles';

export default class Footer extends React.Component {
  render() {
    return (
      <AppFooter>
        <div className="container">
          <div className="row ">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <p>{COPYRIGHT_MESSAGE}</p>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 pr-0">
              <ul className="list-inline pull-right list-footer">
                <li className="list-inline-item">
                  <a href={`mailto:${CONTACT_EMAIL}`}><FIcon type="far" icon="envelope" />{CONTACT_EMAIL}</a>
                </li>
                <li className="list-inline-item">
                  <figure>
                    <ThemeContext.Consumer>
                      {theme => (
                        <img src={theme.logoImg || Logo.clientWhite || Logo.mainWhite} height="20" alt={Logo.clientText || Logo.mainText} />
                      )}
                    </ThemeContext.Consumer>
                  </figure>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* <Container>
        <Row>
          <Col sm="6">
            <p>Copyright © 2019 eduCAT Erieli</p>
          </Col>
          <Col sm="6">
            <ul className="list-inline pull-right list-footer">
              <li className="list-inline-item">
                <Link to="mailto:suporte@educat.net.br">suporte@educat.net.br</Link>
              </li>
              <li className="list-inline-item">
                <figure>
                <Link color="white" to="/"><img src={ LogoEducatWhite } height="20" alt="EDUCAT" /></Link>
              </figure>
              </li>

            </ul>
          </Col>
        </Row>
      </Container> */}
      </AppFooter>
    );
  }
}
