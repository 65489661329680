import React from 'react';
import ReactPaginate from 'react-paginate';
import { Modal, ModalBody } from 'reactstrap';
import queryString from 'query-string';
import axios from 'axios';
import Select from 'react-select';
import { get, debounce } from 'lodash';
import { toast } from 'react-toastify';
import Loading from '../components/Loading';

class BaseList extends React.Component {
  state = {
    fetchingData: true,
    page: 1,
    page_size: 10,
    data: [],
    ordering: '-criado_em',
  }

  componentDidMount() {
    const { location } = this.props;
    const search = queryString.parse(location.search);
    if (search.search) {
      this.search = search.search;
    }
    if (search.page) {
      this.setState({ page: search.page }, this.fetchData);
    } else {
      this.fetchData();
    }
  }

  getParams = () => {
    const { page, page_size, ordering } = this.state;
    return {
      page,
      page_size,
      search: this.search,
      ordering,
      ...this.params,
    };
  }

  maisAntigos = 'criado_em'
  maisRecentes = '-criado_em'
  shouldFetchPermissions = false;
  paginationOptions = [
    { value: '5', label: '5' },
    { value: '10', label: '10' },
    { value: '25', label: '25' },
    { value: '50', label: '50' },
  ]

  hasPermission(name) {
    const permissions = get(this.props, 'permissions') || {};
    // eslint-disable-next-line
    return permissions.__all__ || permissions[name];
  }

  fetchDataCallback() {

  }

  fetchData = (loading = true) => {
    if (loading) {
      this.setState({ fetchingData: true });
    }

    return axios.get(this.api, {
      params: this.getParams(),
    })
      .then((response) => {
        this.setState({
          data: response.data.results,
          total: get(response, 'data.count', '-'),
          total_pages: get(response, 'data.total_pages', 0),
          fetchingData: false,
        }, () => {
          this.fetchDataCallback(response.data.results);
        });
      })
      .catch((error) => {
        const status = get(error, 'response.status');
        if (status === 404) {
          const e = get(error, 'response.data.detail');
          toast.error(e);
        }

        this.setState({ fetchingData: false });
      });
  }

  debouncedFetchData = debounce((search, loading = true) => {
    this.search = search;
    this.setState({ page: 1 }, () => this.fetchData(loading));
  }, 500);


  changePage = (pagination) => {
    const { location, history } = this.props;
    this.setState({ fetchingData: true });
    const page = pagination.selected + 1;
    this.setState({ page }, this.fetchData);
    const search = queryString.stringify({
      ...queryString.parse(location.search),
      page,
    });
    history.push(`${location.pathname}?${search}`);
  }

  removeItem = (id) => {
    this.setState({ removing: true });

    axios.delete(`${this.api}/${id}`)
      .then(() => {
        this.setState({ openModal: null });
        // O timeout é para evitar que o usuário veja a mudança de estado
        setTimeout(() => {
          this.setState({ removing: false, selectedForRemovalId: null })
        }, 100);
        toast.success('Removido com sucesso.');
        this.fetchData(false);
      })
  }

  handleSearch = (search) => {
    this.search = search;
    this.debouncedFetchData(search, false);
  }

  handleOrderingChange = (value) => {
    this.setState({ ordering: get(value, 'value') }, this.fetchData);
  }

  handleRemove = (id) => {
    this.setState({
      selectedForRemovalId: id,
      openModal: 'removeConfirmation',
    });
  }

  handleCopy = (id) => {
    axios.post(`${this.api}/${id}/copy`)
      .then(() => {
        toast.success('Copiado com sucesso.');
        this.fetchData();
      })
      .catch((error) => {
        const status = get(error, 'response.status');
        if (status === 404) {
          const e = get(error, 'response.data.detail');
          toast.error(e);
        }
        else {
          toast.error('Aconteceu um erro. A cópia não foi efetuada')
        }
      })
  }

  toggleModal = (name) => {
    const { openModal } = this.state;
    this.setState({ openModal: openModal === name ? null : name });
  }

  // renderPagination() {
  //   const { page, data, total_pages, fetchingData } = this.state;

  //   if (fetchingData) {
  //     return null;
  //   }

  //   if (data.length > 0) {
  //     return (
  //       <nav style={{ textAlign: 'center' }}>
  //         <ReactPaginate
  //           pageCount={total_pages}
  //           pageRangeDisplayed={3}
  //           marginPagesDisplayed={2}
  //           previousLabel="ANTERIOR"
  //           nextLabel="PRÓXIMA"
  //           onPageChange={this.changePage}
  //           initialSelected={page}
  //           forcePage={page - 1}
  //           containerClassName="pagination"
  //           activeClassName="active"
  //           disabledClassName="disabled"
  //           disableInitialCallback
  //           breakLabel={<button className="disabled">...</button>}
  //         />
  //       </nav>
  //     );
  //   }

  //   return null;
  // }

  handlePageSizeChange = (value) => {
    this.setState({
      page_size: get(value, 'value'),
      page: 1,
    }, this.fetchData)
  }

  renderPagination() {
    const {
      page, data, total_pages, fetchingData, total, page_size,
    } = this.state;

    if (fetchingData) {
      return null;
    }

    if (data.length > 0) {
      return (
        <div className="row" style={{ textAlign: 'center' }}>
          <div className="col-md-3">
            <Select
              matchPos="auto"
              className="filter-size"
              value={page_size}
              options={this.paginationOptions}
              clearable={false}
              onChange={this.handlePageSizeChange}
              searchable={false}
            />
          </div>
          <div className="col-md-3 col-lg-3 col-xs-3" style={{ fontSize: 12 }}>
            <p style={{ marginBottom: 5, marginTop: 10 }}>Mostrando <b>{data.length}</b> de <b>{total}</b> registros</p>
          </div>
          <div className="col-6">
            <ReactPaginate
              pageCount={total_pages}
              pageRangeDisplayed={2}
              marginPagesDisplayed={1}
              previousLabel="ANTERIOR"
              nextLabel="PRÓXIMA"
              onPageChange={this.changePage}
              initialSelected={page}
              forcePage={page - 1}
              containerClassName="pagination"
              activeClassName="active"
              disabledClassName="disabled"
              disableInitialCallback
              breakLabel={<button className="disabled">...</button>}
            />
          </div>

        </div>
      );
    }

    return null;
  }

  renderBreadcrumb() {
    return null;
  }

  renderToolbar() {
    return null;
  }

  renderList() {
    return null;
  }

  renderRemoveConfirmationModal() {
    const { openModal, selectedForRemovalId, removing } = this.state;
    const isOpen = openModal === 'removeConfirmation';
    const toggleModal = () => this.toggleModal('removeConfirmation');

    return (
      <Modal
        centered
        isOpen={isOpen}
        toggle={toggleModal}
        size="sm"
      >
        <ModalBody>
          <p className="mt-3 mb-3">{this.removeConfirmationMessage(selectedForRemovalId)}</p>
          <p>Essa ação <strong>não</strong> poderá ser desfeita.</p>
          <button
            onClick={toggleModal}
            className="btn btn-default mr-2"
            disabled={removing}
          >
            Cancelar
          </button>
          <button
            onClick={() => this.removeItem(selectedForRemovalId)}
            className="btn btn-danger"
            disabled={removing}
          >
            {removing ? 'Removendo...' : 'Confirmar'}
          </button>
        </ModalBody>
      </Modal>
    );
  }

  renderOrderingSelect() {
    const { ordering } = this.state;
    return (
      <div className="ml-2">
        <Select
          style={{ fontSize: 12 }}
          value={ordering}
          options={[
            { value: this.maisAntigos, label: 'Mais Antigos' },
            { value: this.maisRecentes, label: 'Mais Recentes' },
          ]}
          clearable={false}
          onChange={this.handleOrderingChange}
          searchable={false}
        />
      </div>
    );
  }

  render() {
    const { fetchingData } = this.state;

    return (
      <div>
        {this.renderBreadcrumb()}
        <div className="container pb-5">
          {this.renderToolbar()}
          {fetchingData ? <Loading /> : this.renderList()}
          {this.renderPagination()}
        </div>
        {this.renderRemoveConfirmationModal()}
      </div>
    );
  }
}

export default BaseList;
