import React from 'react';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import { Form } from 'informed';
import axios from 'axios';
import FormContainer from '../../containers/Form';
import { InputSelect, InputText } from '../../components/inputs';
import { API_HOST } from '../../consts';
import { BaseForm } from '../../base';
import Loading from '../../components/Loading';

const mapPeriodo = (x) => ({
  value: x.id,
  label: x.descricao
});

class Dados extends BaseForm {
  constructor(props) {
    super(props);
    this.onChangeUselastItemData = this.onChangeUselastItemData.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.callApi = false;
    this.fields = [
      'tipo',
      'exame',
      'bisserial',
      'discriminacao',
      'acertos',
      'pa',
      'pb',
      'pc',
      'instituicao',
      'curso',
      'periodo',
      'disciplina'
    ];
    this.multiFields = ['areas_conhecimento'];
  }

  state = {
    tipo: [],
    save: 0,
    metadata: {},
    fetchingData: false,
    formOriginalState: {},
    lastItemData: {},
    usinglastItemData: false,
    dataItem: {},
    encomendaVinculada: []
  };

  save = true;

  componentDidMount() {
    this.fetchData();
    this.fetchlastItemData();
    this.fetchApi('tipo', 'itens');
    this.fetchApi('exame', 'core');
    this.fetchApi('instituicao', 'core');
    this.fetchApi('curso', 'core');
    this.fetchApi('disciplina', 'core');
    this.fetchMetadata();
    this.fetchPeriodo();
    this.fetchEncomendasVinculadas();
  }

  fetchEncomendasVinculadas() {
    axios
      .get(`${API_HOST}/encomenda/elaborador_item`, {
        params: {
          item: this.id
        }
      })
      .then((response) => {
        this.setState({
          encomendaVinculada: response.data[0]
        });
      });
  }

  fetchPeriodo(data) {
    axios
      .get(`${API_HOST}/core/periodo`, { params: { curso: data } })
      .then((response) => {
        this.setState({ periodo: response.data.map(mapPeriodo) });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  fetchData() {
    if (this.id !== 'new') {
      this.setState({ fetchingData: true });
      axios
        .get(`${API_HOST}/itens/item/${this.id}/formulario`)
        .then((response) => {
          this.setFields(response.data);
          this.setState({ fetchingData: false, data: response.data });

          const exame = get(response, 'data.exame');
          if (exame) {
            this.fetchAreaConhecimento(exame);
          } else {
            this.callApi = true;
          }
        });
    } else {
      this.callApi = true;
    }
  }

  fetchlastItemData() {
    axios
      .get(`${API_HOST}/itens/item/last`)
      .then((response) => {
        this.setState({ lastItemData: response.data });
      })
      .catch((error) => {
        this.setState({ lastItemData: {} });
      });
  }

  fetchMetadata() {
    axios.get(`${API_HOST}/params/param?model=item`).then((response) => {
      this.setState({ metadata: this.mapMetadata(response.data) });
    });
  }

  fetchAreaConhecimento(exame) {
    const params = {
      exames: exame
    };
    axios
      .get(`${API_HOST}/core/area_conhecimento`, { params })
      .then((response) => {
        const data = response.data.results
          ? response.data.results
          : response.data;
        this.setState({
          area_conhecimento: data.map((x) => ({
            value: x.id,
            label: x.descricao
          }))
        });

        const areas_conhecimento =
          get(this.formApi.getState().values, 'areas_conhecimento') || [];
        if (this.formApi.fieldExists('areas_conhecimento')) {
          this.formApi.setValue(
            'areas_conhecimento',
            areas_conhecimento.filter(
              (x) => data.findIndex((y) => x === y.id) !== -1
            )
          );
        }
        this.callApi = true;
      });
  }

  onValueChange(values) {
    if (!this.callApi) {
      return;
    }

    if (this.id === 'new') {
      if (!this.save) {
        return;
      }
      this.setState({ save: this.SALVANDO });
      this.save = false;
      axios
        .post(`${API_HOST}/itens/item`, values)
        .then((response) => {
          this.id = response.data.id;
          const { history } = this.props;
          history.push(`/itens/${this.id}/dados`);
          this.setState({ save: this.SALVO });
        })
        .catch(() => {
          this.setState({ save: this.ERRO });
        });
    } else {
      this.setState({ save: this.SALVANDO });
      axios
        .patch(`${API_HOST}/itens/item/${this.id}`, this.getSubmitData(values))
        .then(() => {
          this.setState({ save: this.SALVO });
        })
        .catch(() => {
          this.setState({ save: this.ERRO });
        });
    }
  }

  onSubmit(url) {
    axios
      .get(`${API_HOST}/itens/item/${this.id}/validate?form=dados`)
      .then((res) => {
        const { history } = this.props;
        history.push(`/itens/${this.id}/${url}`);
      })
      .catch((error) => {
        this.onValidateError(error, 'dados');
      });
  }

  onChangeUselastItemData(event) {
    const value = event.target.checked;
    if (value) {
      this.setState({
        formOriginalState: this.formApi.getState().values,
        usinglastItemData: true
      });
      this.formApi.setValues(this.state.lastItemData);
    } else {
      this.setState({
        usinglastItemData: false
      });
      this.formApi.setValues(this.state.formOriginalState);
    }
  }

  render() {
    const {
      tipo,
      area_conhecimento,
      exame,
      instituicao,
      curso,
      periodo,
      disciplina,
      fetchingData,
      data,
      encomendaVinculada
    } = this.state;

    return (
      <FormContainer
        title="Dados do item"
        next="matrizes-referencia"
        onClick={this.onSubmit}
        disabled={this.formIsDisabled()}
      >
        <Form
          onChange={(formState) => this.setState({ formState })}
          onValueChange={this.onValueChange}
          getApi={(formApi) => {
            this.formApi = formApi;
          }}
        >
          {({ formApi }) => {
            if (fetchingData) {
              return <Loading />;
            }

            return (
              <React.Fragment>
                {this.renderSaveStatus()}
                <div className="row">
                  {this.shouldDisplay('item.exame') && (
                    <InputSelect
                      required={this.isRequired('item.exame')}
                      col="col-sm-12"
                      label="Exame"
                      field="exame"
                      options={exame}
                      onChange={(value) => {
                        const exameId = get(value, 'value');
                        if (exameId) {
                          this.fetchAreaConhecimento(exameId);
                        } else {
                          formApi.setValue('areas_conhecimento', []);
                        }
                      }}
                    />
                  )}
                  {this.shouldDisplay('item.areas_conhecimento') && (
                    <InputSelect
                      multi
                      required={this.isRequired('item.areas_conhecimento')}
                      col="col-sm-12"
                      label="Área do conhecimento"
                      field="areas_conhecimento"
                      options={area_conhecimento}
                    />
                  )}
                  {this.shouldDisplay('item.tipo') && (
                    <InputSelect
                      required={this.isRequired('item.tipo')}
                      col="col-sm-12"
                      disabled={
                        get(data, 'criado_pela_encomenda') ? true : false
                      }
                      label="Tipo de item"
                      field="tipo"
                      options={tipo}
                    />
                  )}
                  {this.shouldDisplay('item.instituicao') && (
                    <InputSelect
                      required={this.isRequired('item.instituicao')}
                      col="col-sm-12"
                      label="Instituição"
                      field="instituicao"
                      options={instituicao}
                    />
                  )}
                  {this.shouldDisplay('item.curso') && (
                    <InputSelect
                      required={this.isRequired('item.curso')}
                      col="col-sm-12"
                      label="Nível/Curso"
                      field="curso"
                      onChange={(event) => {
                        if (event) {
                          this.fetchPeriodo(event.value);
                        }
                      }}
                      options={curso}
                    />
                  )}
                  {this.shouldDisplay('item.periodo') && (
                    <InputSelect
                      required={this.isRequired('item.periodo')}
                      col="col-sm-12"
                      label="Ano/Período"
                      field="periodo"
                      options={periodo}
                    />
                  )}
                  {this.shouldDisplay('item.disciplina') && (
                    <InputSelect
                      required={this.isRequired('item.disciplina')}
                      col="col-sm-12"
                      label="Eixo/Disciplina"
                      field="disciplina"
                      options={disciplina}
                    />
                  )}
                  {this.shouldDisplay('item.bisserial') && (
                    <InputText
                      required={this.isRequired('item.bisserial')}
                      col="col-sm-4"
                      label="Bisserial"
                      field="bisserial"
                    />
                  )}
                  {this.shouldDisplay('item.discriminacao') && (
                    <InputText
                      required={this.isRequired('item.discriminacao')}
                      col="col-sm-4"
                      label="Discriminação"
                      field="discriminacao"
                    />
                  )}
                  {this.shouldDisplay('item.acertos') && (
                    <InputText
                      required={this.isRequired('item.acertos')}
                      col="col-sm-4"
                      label="Dificuldade"
                      field="acertos"
                    />
                  )}
                  {this.shouldDisplay('item.pa') && (
                    <InputText
                      required={this.isRequired('item.pa')}
                      col="col-sm-4"
                      label="A"
                      field="pa"
                    />
                  )}
                  {this.shouldDisplay('item.pb') && (
                    <InputText
                      required={this.isRequired('item.pb')}
                      col="col-sm-4"
                      label="B"
                      field="pb"
                    />
                  )}
                  {this.shouldDisplay('item.pc') && (
                    <InputText
                      required={this.isRequired('item.pc')}
                      col="col-sm-4"
                      label="C"
                      field="pc"
                    />
                  )}
                </div>
                {get(data, 'status') === 1 ||
                get(data, 'status') === undefined ? (
                  <label>
                    <input
                      name="checkbox_last_item_data"
                      type="checkbox"
                      checked={this.state.usinglastItemData}
                      onChange={this.onChangeUselastItemData}
                      style={{ marginRight: '5px' }}
                    />
                    Aproveitar dados do último item preenchido
                  </label>
                ) : null}
                {this.shouldDisplay('item.ver_descricao') && (
                  <div
                    style={{ marginTop: '15px' }}
                    className="col-sm-10"
                    dangerouslySetInnerHTML={{
                      __html: get(encomendaVinculada, 'descricao')
                    }}
                  />
                )}
              </React.Fragment>
            );
          }}
        </Form>
      </FormContainer>
    );
  }
}

export default withRouter(Dados);
