import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fa';

class DeleteButton extends React.Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
  };

  render() {
    return (
      <div className="col-sm-1">
        <label />
        <button className="btn btn-padrao bg-padrao" style={{marginTop: 3}} color="default" onClick={this.props.onClick}>
          <Icon name="times" />
        </button>
      </div>
    );
  }

}

export default DeleteButton;
