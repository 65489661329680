import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import AddButton from '../components/buttons/AddButton';
import Dropdown from '../components/buttons/Dropdown';
// import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import BtnIcon from '../components/buttons/BtnIcon';

export default class ListItensHeader extends React.Component {
  constructor(props) {
    super(props);
    this.selecionarTodos = this.selecionarTodos.bind(this);
    this.state = {
      actions: [],
      collapse: false,
    };
  }

  renderAddButton() {
    const { onAddButtonClick, addLink, addButtonLabel } = this.props;

    if (!addButtonLabel) {
      return null;
    }

    if (onAddButtonClick) {
      return (
        <AddButton
          icon="plus"
          title={addButtonLabel}
          onClick={onAddButtonClick}
        />
      );
    }

    return (
      <Link to={addLink}>
        <AddButton icon="plus" title={addButtonLabel}/>
      </Link>
    );
  }

  static propTypes = {
    title: PropTypes.string,
    selectAll: PropTypes.bool,
    expand: PropTypes.bool,
    expandAll: PropTypes.func,
  };

  static defaultProps = {
    actions: [],
    selectAll: false,
    expand: false,
    expandAll: ()=>{},
  };

  selecionarTodos(event) {
    const { checked } = event.target;
    Array.from(document.querySelectorAll('[id^="checkbox-"]')).forEach((checkbox) => {
      checkbox.checked = checked;
    })
  }

  render() {
    const {
      title,
      actions,
      selectAll,
      expand,
      expandAll,
      onSearch,
    } = this.props;

    const { search } = this.state;

    return (
      <div className="actions-header">
        <div className="row">
          {selectAll ?
            <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">
              <label check className="container-check-custom">
                <input
                  type="checkbox"
                  id="selecionar-todos"
                  aria-label=""
                  onChange={this.selecionarTodos}
                />
                <span className="checkmark" />
              </label>
            </div> :
            null
          }
          <div className={expand ?
            `col-lg-3 col-md-3 col-sm-12 col-xs-12`
            :
            `col-lg-4 col-md-4 col-sm-12 col-xs-12`}>
            {this.renderAddButton()}
            <Dropdown
              title={title}
              actions={actions}
            />

          </div>
          <div className={
          expand ?
            (
              selectAll ?
              'col-lg-2 col-md-2 col-sm-12 col-xs-12'
              :
              'col-lg-3 col-md-3 col-sm-12 col-xs-12'
            )
            :
            (
              selectAll ?
                'col-lg-3 col-md-3 col-sm-6 col-xs-6'
                :
                'col-lg-4 col-md-4 col-sm-6 col-xs-6'
            )
            }>
            <div className="wrap">
              <div className="search">
                <input
                  className="search-term"
                  placeholder="Pesquisar..."
                  value={search}
                  onChange={(event) => {
                    const search = event.target.value;
                    this.setState({ search });
                    if (onSearch) onSearch(search);
                  }}
                />
              </div>
            </div>
          </div>
          <div className={expand ? `col-lg-6 col-md-6 col-sm-6 col-xs-6`
            :
            `col-lg-4 col-md-4 col-sm-12 col-xs-12`
          } style={{paddingRight: '35px'}} >
            {expand ?
              <BtnIcon col="pull-right" title="Conteúdo completo" onClick={expandAll} />
              :
              null
            }
            <Dropdown float="right" title="Mais Recentes" actions={
              [
                {
                  id: 1,
                  title: 'Mais Antigos',
                  url: '/',
                  key: 'action'
                },
              ]
            }
            />

            <BtnIcon float="right" type="fas" icon="filter" title="Filtro" />


          </div>
        </div>
      </div>
    );
  }
}
