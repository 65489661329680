import React from 'react'
import styled from "styled-components";

const ReportMapGraph = ({ data }) => {
	const colors = {
		"Abaixo do Básico": "#FFCECE",
		"Básico": "#FFE74F",
		"Adequado": "#BFEEA6",
		"Avançado": "#ABD4EC",
		"null": "#f7f7f71f"
	};
	const titulo = ['Linguagens, Codigos e suas Tecnologias | ',
		'Matemática e suas Tecnologias | ']
	// if (data.NR_ETAPA > 9) {
	// 	var indice = 3
	// } else {
	// 	var indice = 2
	// }

	const keys = [
		"Abaixo do Básico",
		"Básico",
		"Adequado",
		"Avançado",
	]
	const Images = styled.svg`
		.diamantino{
			fill: ${!data ? '' : colors[data['DRE DIAMANTINO']]};
		}
		.sinop{
			fill:${!data ? '' : colors[data['DRE SINOP']]};
		}
		.caceres{
			fill:${!data ? '' : colors[data['DRE CÁCERES']]};
		}
		.pontes{
			fill:${!data ? '' : colors[data['DRE PONTES E LACERDA']]};
		}
		.primavera{
			fill:${!data ? '' : colors[data['DRE PRIMAVERA DO LESTE']]};
		}
		.varzea{
			fill:${!data ? '' : colors[data['DRE VÁRZEA GRANDE']]};
		}
		.cuiaba{
			fill:${!data ? '' : colors[data['DRE CUIABÁ']]};
		}
		.rondonopolis{
			fill:${!data ? '' : colors[data['DRE RONDONÓPOLIS']]};
		}
		.barra{
			fill:${!data ? '' : colors[data['DRE BARRA DO GARÇAS']]};
		}
		.querencia{
			fill:${!data ? '' : colors[data['DRE QUERÊNCIA']]};
		}
		.confresa{
			fill:${!data ? '' : colors[data['DRE CONFRESA']]};
		}
		.floresta{
			fill:${!data ? '' : colors[data['DRE ALTA FLORESTA']]};
		}
		.matupa{
			fill:${!data ? '' : colors[data['DRE MATUPÁ']]};
		}
		.tangara{
			fill:${!data ? '' : colors[data['DRE TANGARÁ DA SERRA']]};
		}
		.juina{
			fill:${!data ? '' : colors[data['DRE JUÍNA']]};
		}
		.st15{
			fill:none;
		}
		.st16{
			font-family:'helvetica';
		}
		.st17{
			font-size:17px;
		}
	`
	return (
		<React.Fragment>
			<div style={{ marginTop: '2rem', fontSize: '22px', fontFamily: 'helvetica' }}>
				{/* <h1>{titulo[data.CD_DISC - 1] + etapa[data.NR_ETAPA - indice]}</h1> */}
			</div>
			<Images>

				<g>
					<path d="M792.97,775.11c-1.11,1.68-2.17,3.39-3.36,5.01c-0.39,0.52-1.08,0.91-1.71,1.15c-0.84,0.31-1.21,0.62-0.98,1.64
		c0.41,1.82-0.01,2.61-1.6,3.59c-1.43,0.88-2.84,1.8-4.34,2.54c-0.67,0.33-1.55,0.35-2.32,0.32c-2.03-0.08-3.48,1.32-3.14,3.3
		c0.22,1.3-0.09,2.22-1.17,3.01c-0.36,0.27-0.55,0.94-0.59,1.44c-0.13,1.61-0.62,2.14-2.21,2.14c-0.2,0-0.4-0.02-0.6-0.05
		c-2.2-0.31-3.78,0.57-5.2,2.26c-1.5,1.79-3.23,3.41-4.98,4.98c-1.68,1.51-3.8,1.23-5.71,0.73c-1.74-0.46-3.42-1.29-5.01-2.15
		c-1.57-0.85-2.99-2-4.52-2.93c-0.34-0.2-1.09-0.21-1.35,0.03c-2.37,2.13-5.23,3.37-8.09,4.59c-4.21,1.81-8.03,4.22-11.85,6.72
		c-2.42,1.59-5.33,2.48-8.08,3.52c-1.58,0.6-3.11,1.2-4.23,2.49c-0.24,0.28-0.29,0.78-0.3,1.18c-0.04,2.08-0.52,2.69-2.48,3.19
		c-0.48,0.12-0.97,0.38-1.35,0.69c-1.82,1.51-2.78,1.53-4.61,0c-0.24-0.2-0.51-0.38-0.87-0.65c-0.63,0.56-1.32,1.05-1.85,1.68
		c-0.81,0.95-1.74,1.25-2.97,1.12c-0.7-0.07-1.45,0.18-2.17,0.34c-0.54,0.12-1.04,0.39-1.58,0.47c-1.62,0.25-2.91,1.36-3.21,2.95
		c-0.06,0.32,0.12,0.79,0.36,1.03c0.59,0.59,1.27,1.09,1.91,1.63c2.75,2.31,3.74,5.3,3.23,8.78c-0.11,0.74-0.57,1.51-1.08,2.07
		c-0.31,0.34-1.13,0.53-1.57,0.38c-5.18-1.83-10.34-3.71-15.48-5.66c-1.9-0.72-3.72-1.63-5.57-2.46c-0.66-0.3-1.15-0.23-1.82,0.25
		c-1.28,0.9-2.74,1.54-4.11,2.32c-0.36,0.2-0.83,0.45-0.94,0.78c-0.38,1.16-1.26,1.47-2.3,1.58c-0.48,0.05-0.96,0.09-1.44,0.04
		c-1.22-0.13-2.22,0.14-2.71,1.38c-0.5,1.27-1.7,1.53-2.77,1.77c-2.14,0.49-3.7,1.74-4.73,3.54c-0.93,1.63-2.15,2.5-4,2.57
		c-0.34,0.01-0.82,0.1-0.98,0.33c-0.79,1.1-1.92,1.46-3.16,1.74c-0.41,0.09-0.82,0.6-1.05,1.01c-0.24,0.44-0.26,1-0.38,1.5
		c-0.62,2.65-2.15,4-4.87,4.07c-1.48,0.04-2.58,0.31-3.22,1.85c-0.24,0.58-1.09,0.96-1.72,1.33c-1.63,0.95-3.29,1.84-5.15,2.87
		c0.55,0.46,0.86,0.64,1.07,0.91c1.02,1.3,2.22,2.52,2.97,3.96c0.67,1.3,1.45,2.08,2.88,2.37c0.78,0.16,1.68,0.48,2.2,1.03
		c1.33,1.43,0.68,3.77-1.19,4.4c-0.93,0.31-1.96,0.34-2.95,0.4c-0.75,0.05-1.12,0.3-1.3,1.08c-0.57,2.39-1.04,2.61-3.53,2.41
		c-0.85-0.07-1.75,0.31-2.62,0.49c-0.24,0-0.48,0-0.72,0c-2.19-1-2.36-1.2-3.2-3.88c-3.24,0-6.54-0.01-9.84,0
		c-0.99,0-1.96-0.02-2.26-1.22c-0.17-0.66-0.22-1.35-0.33-2.03c0.12-0.04,0.23-0.09,0.35-0.13c-0.53-0.34-1.09-0.66-1.59-1.03
		c-1.02-0.76-1.11-1.5-0.46-2.58c0.16-0.27,0.2-0.71,0.12-1.03c-0.3-1.23,0.1-2.3,1.29-2.74c1.63-0.6,2.44-1.87,2.98-3.38
		c1-2.77,2.55-5.12,4.85-7c1.23-1.01,2.45-2.07,3.49-3.27c0.63-0.73,0.92-1.76,1.4-2.73c-1.04-1.64-1.01-1.75,0.22-3.55
		c0.16-0.24,0.05-0.84-0.15-1.11c-0.78-1.01-1.75-1.88-2.46-2.94c-0.68-1-1.2-2.13-1.64-3.27c-0.24-0.63-0.5-0.94-1.17-0.88
		c-1.4,0.12-2.93-0.15-3.84,1.38c-0.12,0.19-0.5,0.35-0.74,0.32c-1.57-0.21-3.17-0.36-4.69-0.79c-0.47-0.14-0.98-1.11-0.97-1.69
		c0.01-0.46,0.8-0.9,1.25-1.33c0.35-0.33,0.74-0.61,1.08-0.94c0.61-0.59,1.22-1.18,1.8-1.8c0.27-0.29,0.64-0.59,0.71-0.94
		c0.24-1.16,0.79-2.02,1.78-2.72c1.32-0.94,2.03-2.32,2.42-3.91c0.15-0.61,0.69-1.16,1.11-1.68c0.37-0.45,0.89-0.77,1.24-1.22
		c0.56-0.71,1.21-1.42,1.54-2.25c0.78-1.94,2.44-3.12,3.72-4.62c0.5-0.59,1.35-0.88,2.24-1.43c0-0.31-0.18-0.98,0.04-1.47
		c0.51-1.13,1.06-2.31,1.87-3.23c0.98-1.12,2.01-2.15,2.46-3.8c-1.73-0.33-3.43-0.55-5.06-0.99c-3.16-0.85-6.28-1.8-9.41-2.74
		c-1.08-0.33-1.71-1.08-1.87-2.25c-0.19-1.33-0.49-2.64-0.76-4.06c-1.35-0.54-1.69-0.46-2.68,0.79c-0.94,1.18-2.06,1.71-3.66,1.49
		c-1.52-0.21-3.09-0.14-4.63-0.06c-0.49,0.03-1.02,0.42-1.4,0.79c-1.89,1.82-3.06,1.92-5.19,0.36c-0.16-0.12-0.36-0.3-0.52-0.28
		c-2.26,0.29-4.29-1.37-6.68-0.58c-1.39,0.46-3,0.36-4.51,0.33c-1.62-0.03-2.47-0.96-2.64-2.55c-0.14-1.29-1.11-2.02-2.34-1.7
		c-1.11,0.29-1.99,0.04-2.87-0.72c-0.45-0.39-1.15-0.57-1.77-0.66c-0.82-0.12-1.68,0-2.51-0.08c-1.76-0.17-2.72-1.05-3.1-2.6
		c-1.13,0.3-2.29,0.52-3.38,0.92c-0.48,0.17-0.89,0.65-1.23,1.07c-1.32,1.64-2.7,2.13-4.75,1.54c-0.86-0.25-1.26,0.03-1.17,0.89
		c0.18,1.8-0.84,2.81-2.23,3.63c-3.88,2.26-4.75,2.05-7.35-1.57c-1.69-2.35-2.15-4.89-1.59-7.68c0.08-0.39,0.07-0.8,0.15-1.19
		c0.28-1.32,0.25-2.46-1.09-3.33c-0.44-0.29-0.68-1.1-0.75-1.7c-0.43-3.73-0.11-7.29,1.94-10.62c1.81-2.93,2.07-6.24,1.74-9.61
		c-0.11-1.16-0.37-2.19-1.18-3.15c-0.96-1.14-0.42-2.84,0.96-3.84c0.82-0.6,1.65-1.2,2.41-1.88c0.24-0.22,0.37-0.71,0.35-1.06
		c-0.07-1.23,0.07-2.29,1.3-2.98c0.28-0.16,0.39-0.74,0.43-1.15c0.16-1.49,0.83-2.61,2.3-3.05c2.18-0.65,3.05-2.27,3.1-4.34
		c0.07-2.76,1.44-4.42,3.91-5.43c1.54-0.63,2.64-1.85,3.04-3.47c0.48-1.97,1.55-3.46,2.85-5c1.3-1.54,2.08-3.55,2.91-5.43
		c0.49-1.11,0.81-2.22,1.93-2.92c0.35-0.22,0.6-0.74,0.71-1.16c0.49-1.9,0.77-2.15,2.79-2.24c0.24-0.01,0.48-0.03,0.71-0.08
		c0.1-0.02,0.18-0.13,0.29-0.21c-1.63-0.39-3.16-0.01-5.3,1.44c-1.28,0.86-2.57,1.76-4.15,1.63c-2.05-0.16-4.12-0.42-6.13-0.86
		c-1.32-0.29-1.73-1.17-1.52-2.45c0.29-1.76,0.49-3.45-0.84-4.99c-0.34-0.39-0.08-1.28-0.15-1.94c-0.07-0.6-0.03-1.33-0.36-1.76
		c-1.12-1.5-2.29-2.91-4.32-3.37c-1.84-0.42-2.38-2.29-1.39-3.87c0.46-0.74,0.93-1.51,1.17-2.34c0.49-1.7,1.49-2.97,2.87-4.02
		c0.96-0.72,1.9-1.47,2.81-2.25c0.72-0.62,1.41-1.28,1.39-2.38c-0.01-0.37,0.21-0.79,0.44-1.1c1.56-2.15,3.11-4.31,4.73-6.41
		c1.06-1.38,2.36-2.47,4.04-3.08c0.5-0.18,0.96-0.56,1.37-0.93c1.49-1.32,2.88-2.75,4.44-3.98c0.71-0.56,1.74-0.69,2.58-1.1
		c0.46-0.22,1.06-0.51,1.21-0.92c0.46-1.21,0.78-2.47,1.05-3.73c0.32-1.52,0.49-3.08,0.81-4.6c0.29-1.38-0.06-2.49-1.26-3.21
		c-1.89-1.14-3.23-2.8-4.62-4.47c-2.26-2.71-2.87-5.91-3.1-9.25c-0.03-0.5,0.2-1.15,0.55-1.51c0.85-0.88,0.68-1.6,0.11-2.55
		c-2.12-3.5-3.37-7.25-2.86-11.4c0.12-0.97,0.44-1.94,0.83-2.85c0.77-1.79,0.5-2.9-1.12-4.01c-1.69-1.16-3.36-2.34-5.03-3.54
		c-0.28-0.2-0.66-0.48-0.71-0.77c-0.22-1.21-1.17-1.48-2.1-1.87c-2.02-0.85-4.03-1.75-6.03-2.65c-1.55-0.7-2.36-1.82-2.05-3.63
		c0.07-0.4-0.16-0.93-0.41-1.3c-0.74-1.13-1.57-2.2-2.35-3.31c-0.2-0.29-0.48-0.64-0.46-0.95c0.11-1.32-0.59-1.46-1.69-1.48
		c-2.54-0.03-5.07-2.23-5.48-4.73c-0.09-0.57-0.01-1.52,0.34-1.73c0.5-0.31,1.45-0.37,1.98-0.09c1.05,0.55,1.76,0.21,2.47-0.45
		c0.59-0.54,1.06-1.21,1.69-1.69c1-0.77,2-1.61,3.14-2.09c1.18-0.5,2.02-1.17,2.59-2.31c1.36-2.72,2.86-3.53,5.87-3.28
		c2.72,0.23,5.39,0.66,7.89,1.85c0.6,0.29,1.27,0.44,2.16,0.73c-0.16-0.66-0.21-1.11-0.38-1.52c-0.44-1.04-0.37-2,0.26-2.96
		c0.16-0.24,0.14-0.63,0.1-0.94c-0.24-1.6-0.5-3.19-0.77-4.79c-0.1-0.58-0.07-1.24-0.34-1.71c-0.66-1.14-0.84-2.29-0.66-3.57
		c0.13-0.95,0.05-1.94,0.27-2.86c0.19-0.81,0.48-1.79,1.07-2.27c1.09-0.88,1.22-1.85,1.03-3.07c-0.21-1.38-0.51-2.79-0.41-4.16
		c0.08-1.19,0.54-2.4,1.07-3.48c0.4-0.82,1.16-1.07,2.11-0.4c0.36,0.25,1.1,0.12,1.62-0.03c1.62-0.44,2.16,0.04,1.87,1.69
		c-0.16,0.9-0.2,1.82-0.33,3.01c0.91-0.42,1.43-0.68,1.97-0.91c1.67-0.69,3.16-0.32,4.29,1.07c0.35,0.43,0.65,0.91,0.92,1.4
		c1.11,2.01,2.6,3.46,4.97,3.96c1.9,0.4,2.53,1.25,2.54,3.23c0,1.77,0.25,3.42,1.34,4.86c0.12,0.16,0.22,0.35,0.26,0.54
		c0.41,2.15,1,4.22,2.14,6.15c0.46,0.78,0.18,1.97,0.4,2.93c0.2,0.87,0.5,1.77,1,2.49c1.07,1.54,2.29,2.97,3.46,4.44
		c1.43,1.79,1.52,2.65,0.39,4.63c-0.35,0.61-0.26,1.07,0.32,1.42c0.37,0.23,0.79,0.38,1.18,0.59c1.2,0.66,1.91,1.52,1.88,3.04
		c-0.06,3.43,0.09,3.44,3.47,3.91c0.94,0.13,1.95,0.71,2.65,1.37c1.03,0.98,0.88,2.12-0.27,2.96c-0.67,0.49-0.66,0.92-0.41,1.61
		c0.37,1.03,0.56,2.13,0.93,3.17c0.23,0.66,0.59,1.3,0.99,1.89c1.11,1.65,1.06,2.9-0.4,4.25c-0.54,0.5-0.57,0.87-0.16,1.41
		c0.15,0.19,0.23,0.42,0.35,0.63c0.93,1.71,0.91,1.95-0.28,3.45c0.87,0.54,1.74,1.09,2.61,1.61c1.53,0.91,2.15,2.28,1.81,4.06
		c-0.05,0.24-0.1,0.47-0.16,0.7c-0.58,2.16-0.26,3.05,1.75,4.09c1.49,0.76,2.56,1.88,3.37,3.3c0.2,0.34,0.43,0.72,0.46,1.09
		c0.11,1.69,0.97,2.82,2.3,3.84c1.87,1.42,2.55,3.6,3.09,5.89c2.64-0.63,4.82,0.27,6.9,1.84c0.78,0.59,1.9,0.73,2.88,1.03
		c0.37,0.11,0.83-0.03,1.19,0.11c2.69,1.06,5.45,1.64,8.32,2.03c2.18,0.29,2.68,2.61,1.13,4.24c-1.28,1.35-3,1.78-4.75,2.07
		c-1.22,0.2-2.47,0.26-3.68,0.51c-0.77,0.16-1.55,0.44-2.21,0.85c-1.04,0.65-0.99,1.64,0.05,2.29c1.54,0.96,3.05,1.93,5.04,1.83
		c0.99-0.05,2,0.45,3.01,0.63c0.81,0.15,1.66,0.34,2.47,0.26c2.91-0.31,5.38,0.42,7.31,2.7c0.46,0.55,1,1.04,1.49,1.56
		c1.18,1.25,2.48,2.16,4.34,2.22c3.48,0.11,5.53,2.07,6.88,5.22c0.94,2.17,2.44,4.1,3.67,6.15c0.33,0.54,0.8,1.1,0.85,1.68
		c0.11,1.25,0.85,1.96,1.65,2.78c0.82,0.84,1.55,1.85,2.01,2.91c0.56,1.32,1.34,2.33,2.54,3.07c0.92,0.57,1.83,1.15,2.72,1.76
		c1.24,0.84,2.02,1.93,1.77,3.52c-0.13,0.82,0.18,1.3,1.05,1.37c0.71,0.05,1.44,0.28,2.13,0.19c2.03-0.28,4.03-0.58,6.21,0.45
		c0.1-1.22,0.25-2.24,0.26-3.25c0.01-2.19,0.41-4.18,2.14-5.76c0.46-0.42,0.73-1.15,0.87-1.79c0.48-2.18,1.86-3.45,3.87-4.19
		c0.92-0.34,1.78-0.84,2.71-1.17c0.37-0.13,0.94-0.17,1.24,0.03c1.56,1.03,3.12,2.07,4.55,3.27c0.42,0.35,0.47,1.2,0.58,1.85
		c0.09,0.57,0.02,1.16,0.02,1.96c1.1-0.25,1.99-0.42,2.86-0.66c1.97-0.53,3.77-0.57,5.24,1.21c0.26,0.32,1.04,0.28,1.57,0.28
		c0.88,0,1.81-0.31,2.62-0.1c0.79,0.2,1.22,0.02,1.63-0.53c0.5-0.67,0.93-1.4,1.45-2.06c1.13-1.44,2.59-1.89,4.37-1.43
		c1.6,0.42,3.22,0.77,4.84,1.13c1.25,0.28,2.21,1.59,2.1,3.24c-0.17,2.63,0.04,2.42,2.28,1.74c2-0.6,2.81,0.08,2.78,2.27
		c2.18,1.67,3.92,3.51,3.17,6.76c0.7-0.28,1.2-0.47,1.69-0.66c1.38-0.53,2.13-0.26,3,0.89c0.99,1.3,1.55,2.61,1.11,4.33
		c-0.36,1.42,0.22,2,1.67,2.09c0.68,0.04,1.36,0.06,2.04,0.07c0.95,0.01,1.67,0.25,2,1.33c0.09,0.31,0.91,0.66,1.32,0.58
		c1.99-0.37,3.52,0.09,4.88,1.72c1.13,1.37,2.72,2.24,4.58,2.34c2.38,0.12,3.37,1.61,3.84,3.71c0.4,1.78,1.12,3.51,0.37,5.39
		c-0.09,0.22,0.34,0.89,0.66,1c1.56,0.56,2.68,1.48,3.33,3.05c0.2,0.49,0.78,0.88,1.28,1.17c1.37,0.82,2.41,1.82,2.51,3.54
		c0.02,0.28,0.19,0.61,0.4,0.8c1.64,1.47,1.62,2.24,0.14,3.33c0.56,0.76,1.23,1.44,1.62,2.26c0.43,0.89,1,1.41,1.94,1.57
		c0.51,0.09,1.03,0.15,1.54,0.24c1.77,0.29,2.4,1.34,1.97,3.09c-0.21,0.88-0.18,1.82-0.45,2.68c-0.27,0.87-0.77,1.67-1.23,2.63
		c0.09,0.72,0.21,1.7,0.34,2.68c-0.11,0.05-0.22,0.11-0.32,0.16c0.41,0.21,0.81,0.43,1.23,0.63c1.07,0.5,1.59,1.26,1.34,2.49
		c-0.26,1.28-0.31,2.55,0.71,3.59c0.14,0.14,0.3,0.34,0.29,0.51c-0.08,1.68,0.86,2.98,1.54,4.4c0.23,0.48,0.04,1.16,0.04,1.91
		c1.81,0.03,3.54,0.38,4.74,1.92C792.97,774.79,792.97,774.95,792.97,775.11z M745.53,710.48c-0.29,0.12-0.58,0.23-0.87,0.36
		c-1.07,0.47-2.17,1.07-3.27,0.23c-1.02-0.78-0.71-1.93-0.58-3.01c0.14-1.2-0.64-2.91-1.71-3.16c-1.44-0.33-1.86-1.15-1.68-2.53
		c-0.37,0.1-0.59,0.17-0.82,0.22c-3.02,0.58-4.35-0.6-4.18-3.7c0.01-0.24,0.1-0.54-0.01-0.71c-0.21-0.33-0.49-0.73-0.83-0.84
		c-1.31-0.41-2.67-0.67-3.98-1.09c-1.19-0.39-2.1-0.18-2.84,0.85c-0.56,0.78-1.14,1.54-1.76,2.27c-0.84,0.97-1.85,1.28-3.12,0.84
		c-0.43-0.15-1.02-0.11-1.46,0.05c-1.42,0.52-3.09,0.27-4.06-0.88c-0.74-0.88-1.56-0.99-2.57-0.76c-1.13,0.26-2.25,0.6-3.39,0.73
		c-1.61,0.18-2.38-0.5-2.61-2.1c-0.06-0.43,0.01-0.89-0.1-1.31c-0.13-0.49-0.3-1.02-0.61-1.41c-1.39-1.77-3.04-2.2-5.21-1.23
		c-1.47,0.66-2.47,1.44-2.94,3.02c-0.27,0.92-0.72,1.9-1.37,2.59c-1.08,1.15-1.43,2.43-1.47,3.92c-0.04,1.23-0.08,2.49-0.34,3.68
		c-0.36,1.62-1.33,2.02-2.97,1.76c-1.36-0.21-2.76-0.15-4.15-0.23c-1.46-0.08-2.96-0.02-4.38-0.33c-1.62-0.35-2.12-1.12-2.04-2.77
		c0.06-1.29-0.46-2.14-1.49-2.78c-0.61-0.38-1.23-0.75-1.85-1.11c-1.66-0.94-2.8-2.29-3.58-4.05c-0.43-0.99-1.17-1.88-1.93-2.67
		c-0.93-0.97-1.91-1.82-1.86-3.33c0.01-0.32-0.23-0.67-0.41-0.97c-1.43-2.43-2.93-4.82-4.29-7.29c-1.01-1.83-2.22-3.25-4.49-3.22
		c-2.71,0.03-4.69-1.31-6.4-3.24c-0.63-0.72-1.25-1.48-2-2.06c-0.67-0.52-1.5-1.11-2.29-1.14c-2.54-0.12-5.01-0.26-7.54-0.85
		c-2.25-0.52-4.38-0.88-6.44-2.12c-2.86-1.72-2.39-4.86,0.01-6.26c0.94-0.55,2.07-0.82,3.15-1.07c1.71-0.39,3.46-0.59,5.16-1.01
		c0.65-0.16,1.2-0.69,2.07-1.22c-0.64-0.26-0.85-0.42-1.06-0.42c-2.61-0.01-5.01-0.8-7.38-1.82c-0.57-0.24-1.25-0.27-1.89-0.3
		c-1.41-0.06-2.66-0.5-3.73-1.43c-1.35-1.18-2.91-1.55-4.68-1.34c-1.68,0.2-2.48-0.38-2.82-2.03c-0.44-2.09-1.49-3.78-3.18-5.05
		c-1.05-0.79-1.64-1.83-1.85-3.1c-0.31-1.81-1.19-3.16-2.9-3.99c-1.41-0.68-2.39-1.81-3.03-3.27c-0.57-1.28-0.01-2.43,0.26-3.61
		c0.24-1.04-0.03-1.79-1.01-2.29c-0.67-0.35-1.32-0.75-1.96-1.16c-1.76-1.13-2.24-2.49-0.75-4.13c-0.07-0.14-0.13-0.28-0.21-0.42
		c-1.4-2.49-1.38-2.47,0.37-4.81c0.2-0.27,0.05-0.9-0.11-1.3c-0.37-0.94-0.91-1.82-1.26-2.77c-0.31-0.81-0.34-1.74-0.7-2.51
		c-0.78-1.66-0.96-3.14,0.85-4.47c-0.87-0.31-1.65-0.51-2.44-0.59c-2.88-0.29-3.75-1.15-3.99-4.03c-0.03-0.36-0.09-0.72-0.08-1.08
		c0.03-1.21-0.53-1.88-1.68-2.29c-1.89-0.66-2.56-2.57-1.48-4.26c0.61-0.96,0.48-1.66-0.2-2.47c-1.25-1.51-2.54-3-3.62-4.62
		c-0.64-0.96-1-2.14-1.3-3.28c-0.24-0.91,0.11-2.09-0.35-2.81c-1.1-1.74-1.73-3.57-1.98-5.57c-0.03-0.23-0.14-0.47-0.28-0.66
		c-1.24-1.61-1.55-3.48-1.49-5.43c0.03-0.95-0.35-1.44-1.3-1.69c-2.66-0.68-4.59-2.33-5.92-4.71c-1.15-2.05-1.47-2.15-3.54-1.07
		c-1.98,1.04-3.6,0.18-3.85-2.05c-0.07-0.58-0.08-1.16-0.11-1.71c-0.67,0-1.22,0-1.9,0c-0.06,0.71-0.21,1.39-0.15,2.05
		c0.12,1.39,0.35,2.77,0.55,4.15c0.17,1.19-0.1,2.3-1.03,3.04c-1.11,0.88-1.35,1.98-1.33,3.27c0.01,0.63-0.19,1.28-0.16,1.91
		c0.02,0.53,0.23,1.06,0.37,1.59c0.23,0.86,0.66,1.71,0.66,2.57c0.01,1.7,0.08,3.35,0.96,4.87c0.44,0.75,0.34,1.48-0.26,2.08
		c-0.53,0.54-0.48,1.05-0.27,1.74c0.25,0.82,0.34,1.72,0.34,2.59c0,0.89-0.56,1.46-1.48,1.33c-1.14-0.16-2.26-0.43-3.38-0.72
		c-2.04-0.53-4.05-1.21-6.11-1.62c-3.15-0.63-4.07-0.16-5.68,2.62c-0.71,1.22-1.66,2.06-2.99,2.63c-1.03,0.44-2.03,1.12-2.84,1.91
		c-1.42,1.4-2.72,2.91-4.99,3.09c0.73,1.07,1.55,1.6,2.66,1.72c0.87,0.09,1.79,0.13,2.58,0.45c0.53,0.22,1.21,0.87,1.24,1.36
		c0.09,1.77,0.97,3.08,2.08,4.28c0.78,0.84,1.27,1.73,1.11,2.9c-0.16,1.15,0.42,1.79,1.42,2.2c1.89,0.76,3.76,1.55,5.66,2.3
		c1.25,0.49,2.45,0.99,2.86,2.46c0.06,0.21,0.27,0.4,0.46,0.54c1.63,1.19,3.26,2.39,4.92,3.53c2.06,1.42,2.95,3.91,1.84,5.96
		c-0.93,1.72-0.98,3.51-0.93,5.35c0.07,2.82,1.34,5.23,2.72,7.58c0.91,1.55,1.14,3.04-0.08,4.55c-0.2,0.25-0.32,0.65-0.3,0.97
		c0.25,4.89,2.52,8.63,6.56,11.35c1.95,1.32,2.7,3.17,2.3,5.51c-0.35,2.05-0.52,4.13-1.01,6.14c-0.32,1.33-0.97,2.61-1.64,3.82
		c-0.24,0.44-0.96,0.83-1.47,0.86c-1.3,0.07-2.28,0.65-3.17,1.49c-1.22,1.15-2.43,2.31-3.68,3.42c-0.5,0.44-1.13,0.73-1.68,1.12
		c-1.15,0.84-2.47,1.54-3.37,2.59c-1.52,1.77-2.77,3.77-4.11,5.68c-0.1,0.14-0.06,0.38-0.12,0.56c-0.16,0.51-0.2,1.17-0.55,1.51
		c-1.64,1.62-3.39,3.14-5.04,4.75c-0.67,0.65-1.28,1.39-1.75,2.2c-0.59,1.02-1.05,2.13-1.47,3.23c-0.1,0.26,0.2,0.98,0.29,0.97
		c1.49-0.07,2.29,1.09,3.2,1.9c1.03,0.92,2.5,2.06,2.57,3.17c0.12,2.19,1.02,4.11,1.32,6.2c0.12,0.82-0.07,1.68-0.03,2.51
		c0.01,0.3,0.23,0.8,0.42,0.84c2.52,0.47,5.05,1.33,7.46-0.49c2.3-1.73,4.95-2.39,7.8-2.13c1.81,0.16,2.61,2.01,1.34,3.31
		c-0.71,0.73-1.87,1.12-2.9,1.4c-0.63,0.17-0.88,0.34-1.11,0.95c-0.28,0.74-0.86,1.37-1.3,2.06c-0.4,0.62-0.83,1.22-1.17,1.87
		c-1.06,2-1.8,4.25-3.21,5.95c-1.32,1.58-2.31,3.16-2.9,5.11c-0.57,1.88-1.88,3.25-3.73,3.96c-1.79,0.7-2.74,1.84-2.88,3.83
		c-0.19,2.81-1.3,5.1-4.24,6.06c-0.95,0.31-1.11,1.09-1.27,1.97c-0.13,0.69-0.4,1.53-0.91,1.94c-0.56,0.45-0.63,0.87-0.73,1.48
		c-0.13,0.79-0.28,1.7-0.76,2.28c-0.68,0.83-1.64,1.46-2.54,2.09c-0.67,0.46-0.8,0.9-0.35,1.59c0.38,0.59,0.85,1.22,0.96,1.89
		c0.78,4.71,0.27,9.27-2.22,13.42c-1.57,2.62-1.56,5.39-1.31,8.23c0.04,0.42,0.34,0.95,0.69,1.19c1.38,0.97,1.65,2.23,1.3,3.79
		c-0.19,0.85-0.14,1.76-0.33,2.61c-0.61,2.78,0.78,4.86,2.31,6.9c0.22,0.29,0.63,0.55,0.98,0.59c1.54,0.19,3.67-1.74,3.7-3.3
		c0.04-2.11,1.26-3.12,3.29-2.51c1.39,0.42,2.41,0.15,3.27-0.97c0.8-1.05,1.69-1.91,3.19-1.74c0.34,0.04,0.7-0.33,1.07-0.46
		c0.71-0.25,1.41-0.58,2.14-0.66c1-0.11,1.79,0.44,1.83,1.47c0.06,1.39,0.85,1.67,1.98,1.61c0.64-0.03,1.29-0.23,1.91-0.13
		c0.79,0.12,1.69,0.29,2.28,0.76c0.68,0.55,1.28,0.68,2.07,0.54c1.92-0.36,4.27,1.47,4.29,3.38c0.01,0.81,0.36,1.09,1.09,1.02
		c1.43-0.14,2.85-0.33,4.28-0.43c1.02-0.07,2.05-0.09,3.06,0c0.98,0.09,1.95,0.3,2.9,0.53c0.75,0.19,1.58,0.35,2.18,0.78
		c1.05,0.76,1.45,0.88,2.26-0.16c0.71-0.9,1.59-1.34,2.67-1.36c1.67-0.04,3.35-0.07,5.02,0.03c0.89,0.05,1.55-0.15,2.1-0.84
		c1.36-1.7,2.81-2.14,4.84-1.51c1.54,0.48,1.9,1.01,1.77,2.61c-0.22,2.72,0.57,3.9,3.15,4.74c0.23,0.07,0.46,0.14,0.69,0.19
		c4,0.94,8,1.9,12.01,2.8c1,0.22,1.32,0.72,1.15,1.7c-0.34,1.96-1.15,3.68-2.67,4.97c-1.35,1.14-2.14,2.41-1.86,4.26
		c0.13,0.86-0.26,1.6-1.18,1.85c-2.22,0.6-3.28,2.47-4.59,4.08c-1.36,1.68-2,3.89-3.86,5.2c-0.13,0.09-0.2,0.32-0.23,0.5
		c-0.29,1.98-1.53,3.38-2.97,4.58c-0.79,0.66-1.25,1.35-1.39,2.38c-0.07,0.54-0.53,1.38-0.93,1.45c-1.42,0.27-1.83,1.18-2.03,2.37
		c0.75,0.4,1.3,0.38,1.86-0.38c0.31-0.42,0.98-0.73,1.52-0.8c1.34-0.17,2.71-0.23,4.06-0.23c1.43,0,1.92,0.49,2.06,1.93
		c0.12,1.25,0.61,2.27,1.47,3.19c0.85,0.9,1.64,1.86,2.36,2.87c1.33,1.85,1.27,2.54-0.19,4.18c0.61,0.81,1.55,1.59,0.85,2.74
		c-1.57,2.56-3.34,4.96-5.88,6.68c-1.93,1.31-3.09,3.27-3.91,5.34c-0.91,2.3-1.97,4.35-4.2,5.26c0,1.07,0,2,0,3.07
		c1.01,0.83,2.53,1.6,1.96,3.57c3.49,0,6.88,0.02,10.26-0.02c0.87-0.01,1.38,0.29,1.54,1.14c0.07,0.39,0.06,0.8,0.14,1.19
		c0.23,1.14,0.73,1.43,1.87,1.29c0.94-0.12,1.91-0.08,2.85-0.21c0.34-0.05,0.82-0.34,0.91-0.62c0.81-2.48,0.95-2.62,3.58-2.84
		c0.4-0.03,0.82,0.02,1.19-0.11c0.44-0.16,0.83-0.46,1.24-0.71c-0.38-0.25-0.74-0.66-1.15-0.73c-1.72-0.3-3.19-0.94-3.84-2.67
		c-0.68-1.82-1.84-3.22-3.32-4.45c-1.53-1.27-1.61-2.62-0.08-3.81c1.62-1.26,3.45-2.25,5.18-3.37c0.35-0.23,0.81-0.48,0.95-0.83
		c0.76-1.92,2.26-2.4,4.14-2.37c2.28,0.03,2.92-0.53,3.47-2.79c0.08-0.31,0.15-0.62,0.23-0.93c0.4-1.63,1.38-2.63,3.06-3
		c0.54-0.12,1.01-0.56,1.52-0.83c0.71-0.38,1.41-0.94,2.17-1.06c1.29-0.2,2.18-0.68,2.89-1.82c1.35-2.16,3.22-3.68,5.8-4.28
		c0.63-0.14,1.45-0.6,1.66-1.13c0.48-1.17,1.33-1.69,2.42-1.9c0.89-0.17,1.83-0.13,2.74-0.24c0.35-0.04,0.87-0.15,0.99-0.39
		c0.86-1.71,2.67-2.01,4.09-2.89c0.57-0.35,1.29-0.53,1.71-1c0.99-1.11,2.13-1.17,3.38-0.75c0.64,0.21,1.19,0.65,1.82,0.89
		c6.44,2.45,12.88,4.88,19.36,7.33c0.14-0.35,0.24-0.53,0.27-0.72c0.05-0.27,0.06-0.56,0.06-0.83c-0.04-2.5-0.97-4.53-2.95-6.14
		c-1.06-0.87-1.95-1.97-2.81-3.06c-0.27-0.35-0.45-1.12-0.25-1.43c0.8-1.27,1.53-2.85,2.73-3.53c1.84-1.05,4.02-1.63,6.12-2.03
		c1-0.19,1.79-0.22,2.56-1.04c1.91-2.05,3.14-2.02,5.39-0.29c0.26,0.2,0.91,0.06,1.3-0.11c0.75-0.33,1.41-0.98,2.18-1.15
		c0.98-0.22,1.18-0.67,1.17-1.57c-0.01-0.67,0.02-1.55,0.42-1.99c1.29-1.46,2.86-2.49,4.81-3.09c3.82-1.17,7.52-2.59,10.76-5.1
		c3.04-2.36,6.53-3.97,10.05-5.53c2.49-1.11,4.84-2.59,7.11-4.12c1.14-0.77,2.18-1.19,3.42-0.61c0.9,0.42,1.7,1.03,2.57,1.52
		c2.18,1.22,4.31,2.57,6.58,3.59c2.02,0.91,3.97,0.5,5.54-1.27c1.46-1.64,2.99-3.22,4.5-4.81c1.22-1.28,2.69-1.96,4.5-1.84
		c0.62,0.04,1.25,0.01,1.88,0.01c0.12-1.31,0.25-2.44,1.46-3.11c0.23-0.13,0.34-0.77,0.27-1.13c-0.4-1.87,0.81-3.22,1.57-4.65
		c0.58-1.1,1.92-0.7,2.97-0.81c0.86-0.09,1.76-0.17,2.53-0.51c1.16-0.51,2.2-1.31,3.34-1.86c0.99-0.48,1.34-1.11,1.1-2.19
		c-0.25-1.12-0.12-2.29,1.06-2.78c1.31-0.54,2.06-1.45,2.68-2.65c0.38-0.74,1.02-1.35,1.62-2.13c-0.85-0.12-1.48-0.21-2.12-0.3
		c-1.74-0.23-2.26-0.97-2.07-2.68c0.06-0.53-0.09-1.19-0.39-1.62c-0.66-0.94-1.28-1.83-1.08-3.06c0.04-0.24-0.07-0.6-0.25-0.75
		c-1.14-0.98-1.14-2.29-0.87-3.54c0.23-1.09-0.12-1.55-1.09-1.95c-1.28-0.54-1.63-1.5-1.42-2.84c0.07-0.44-0.01-0.96-0.18-1.38
		c-0.33-0.87-0.33-1.62,0.43-2.28c0.36-0.31,0.83-0.67,0.91-1.07c0.24-1.17,0.32-2.38,0.47-3.7c-0.37-0.04-0.8-0.07-1.22-0.15
		c-1.55-0.3-3.14-0.59-3.73-2.36c-0.28-0.85-0.75-1.02-1.6-0.95c-1.96,0.16-3.41-0.65-4.08-2.12c-0.56-1.23-0.19-2.03,1.15-2.28
		c0.76-0.14,1.54-0.13,2.35-0.2c-0.14-0.43-0.34-0.77-0.33-1.11c0-1.53-0.87-2.36-2.13-3.05c-0.58-0.32-1.13-0.93-1.39-1.54
		c-0.49-1.17-1.23-1.88-2.45-2.23c-1.12-0.32-1.76-1.13-1.95-2.34c-0.22-1.44-0.58-2.85-0.83-4.29c-0.16-0.94-0.59-1.35-1.6-1.33
		c-0.54,0.01-1.28-0.3-1.57-0.71c-0.22-0.3,0.13-1.02,0.18-1.28c-1.45-0.97-3.05-1.82-4.35-3c-0.96-0.87-1.79-1.23-3.07-1.1
		c-1.85,0.2-3.27-0.41-3.78-1.99c-0.46,0-0.94,0.02-1.41,0c-3.39-0.16-4.41-1.31-4.08-4.7
		C746.75,712.13,746.36,711.27,745.53,710.48z"/>
					<path class="diamantino" d="M745.53,710.48c0.83,0.79,1.22,1.65,1.1,2.8c-0.33,3.39,0.69,4.54,4.08,4.7c0.48,0.02,0.96,0,1.41,0
		c0.5,1.58,1.93,2.19,3.78,1.99c1.28-0.14,2.12,0.23,3.07,1.1c1.3,1.18,2.9,2.03,4.35,3c-0.05,0.27-0.4,0.98-0.18,1.28
		c0.3,0.42,1.03,0.72,1.57,0.71c1.02-0.02,1.44,0.39,1.6,1.33c0.25,1.43,0.61,2.85,0.83,4.29c0.18,1.21,0.83,2.02,1.95,2.34
		c1.22,0.35,1.96,1.06,2.45,2.23c0.26,0.61,0.81,1.22,1.39,1.54c1.25,0.69,2.13,1.51,2.13,3.05c0,0.34,0.2,0.68,0.33,1.11
		c-0.8,0.06-1.59,0.05-2.35,0.2c-1.34,0.25-1.71,1.05-1.15,2.28c0.67,1.47,2.12,2.28,4.08,2.12c0.85-0.07,1.32,0.1,1.6,0.95
		c0.59,1.77,2.18,2.06,3.73,2.36c0.42,0.08,0.85,0.11,1.22,0.15c-0.15,1.32-0.23,2.53-0.47,3.7c-0.08,0.4-0.56,0.76-0.91,1.07
		c-0.76,0.66-0.76,1.4-0.43,2.28c0.16,0.43,0.25,0.94,0.18,1.38c-0.22,1.34,0.14,2.3,1.42,2.84c0.97,0.41,1.32,0.86,1.09,1.95
		c-0.27,1.26-0.27,2.56,0.87,3.54c0.18,0.15,0.29,0.52,0.25,0.75c-0.2,1.23,0.42,2.12,1.08,3.06c0.3,0.43,0.45,1.09,0.39,1.62
		c-0.19,1.7,0.33,2.45,2.07,2.68c0.63,0.08,1.26,0.18,2.12,0.3c-0.61,0.78-1.24,1.39-1.62,2.13c-0.62,1.2-1.37,2.12-2.68,2.65
		c-1.18,0.48-1.31,1.65-1.06,2.78c0.24,1.08-0.1,1.71-1.1,2.19c-1.15,0.55-2.18,1.35-3.34,1.86c-0.77,0.34-1.68,0.42-2.53,0.51
		c-1.05,0.11-2.39-0.29-2.97,0.81c-0.76,1.43-1.97,2.78-1.57,4.65c0.08,0.35-0.03,1-0.27,1.13c-1.21,0.67-1.34,1.8-1.46,3.11
		c-0.64,0-1.26,0.03-1.88-0.01c-1.81-0.12-3.28,0.56-4.5,1.84c-1.51,1.59-3.04,3.17-4.5,4.81c-1.57,1.77-3.51,2.17-5.54,1.27
		c-2.28-1.02-4.4-2.37-6.58-3.59c-0.87-0.49-1.67-1.1-2.57-1.52c-1.24-0.58-2.27-0.16-3.42,0.61c-2.27,1.52-4.62,3.01-7.11,4.12
		c-3.52,1.56-7.01,3.17-10.05,5.53c-3.23,2.51-6.94,3.93-10.76,5.1c-1.94,0.6-3.51,1.64-4.81,3.09c-0.4,0.45-0.43,1.32-0.42,1.99
		c0.01,0.9-0.19,1.35-1.17,1.57c-0.77,0.17-1.43,0.82-2.18,1.15c-0.39,0.17-1.04,0.31-1.3,0.11c-2.25-1.73-3.48-1.76-5.39,0.29
		c-0.77,0.82-1.56,0.85-2.56,1.04c-2.11,0.4-4.28,0.99-6.12,2.03c-1.2,0.68-1.93,2.27-2.73,3.53c-0.2,0.31-0.02,1.09,0.25,1.43
		c0.86,1.08,1.75,2.19,2.81,3.06c1.98,1.62,2.92,3.64,2.95,6.14c0,0.28-0.01,0.56-0.06,0.83c-0.03,0.19-0.13,0.37-0.27,0.72
		c-6.47-2.45-12.92-4.88-19.36-7.33c-0.63-0.24-1.19-0.67-1.82-0.89c-1.25-0.42-2.39-0.36-3.38,0.75c-0.42,0.47-1.14,0.65-1.71,1
		c-1.42,0.87-3.23,1.18-4.09,2.89c-0.12,0.24-0.64,0.35-0.99,0.39c-0.91,0.11-1.84,0.06-2.74,0.24c-1.09,0.21-1.94,0.72-2.42,1.9
		c-0.21,0.52-1.04,0.98-1.66,1.13c-2.58,0.59-4.45,2.12-5.8,4.28c-0.71,1.13-1.61,1.62-2.89,1.82c-0.76,0.12-1.45,0.68-2.17,1.06
		c-0.51,0.27-0.98,0.71-1.52,0.83c-1.68,0.37-2.65,1.37-3.06,3c-0.08,0.31-0.15,0.62-0.23,0.93c-0.55,2.26-1.19,2.82-3.47,2.79
		c-1.88-0.02-3.38,0.45-4.14,2.37c-0.14,0.35-0.6,0.6-0.95,0.83c-1.73,1.12-3.56,2.11-5.18,3.37c-1.53,1.19-1.45,2.55,0.08,3.81
		c1.48,1.23,2.64,2.62,3.32,4.45c0.65,1.73,2.12,2.37,3.84,2.67c0.41,0.07,0.77,0.48,1.15,0.73c-0.41,0.24-0.8,0.55-1.24,0.71
		c-0.36,0.13-0.79,0.08-1.19,0.11c-2.63,0.22-2.76,0.35-3.58,2.84c-0.09,0.28-0.58,0.57-0.91,0.62c-0.94,0.13-1.9,0.09-2.85,0.21
		c-1.14,0.14-1.64-0.15-1.87-1.29c-0.08-0.39-0.06-0.8-0.14-1.19c-0.15-0.85-0.67-1.15-1.54-1.14c-3.39,0.04-6.78,0.02-10.26,0.02
		c0.57-1.97-0.95-2.73-1.96-3.57c0-1.07,0-2,0-3.07c2.23-0.91,3.29-2.96,4.2-5.26c0.82-2.07,1.98-4.03,3.91-5.34
		c2.54-1.72,4.31-4.12,5.88-6.68c0.7-1.14-0.23-1.93-0.85-2.74c1.46-1.64,1.51-2.33,0.19-4.18c-0.72-1-1.51-1.97-2.36-2.87
		c-0.86-0.92-1.35-1.94-1.47-3.19c-0.14-1.44-0.63-1.93-2.06-1.93c-1.36,0-2.72,0.06-4.06,0.23c-0.54,0.07-1.21,0.38-1.52,0.8
		c-0.56,0.76-1.11,0.78-1.86,0.38c0.2-1.19,0.61-2.1,2.03-2.37c0.4-0.07,0.85-0.91,0.93-1.45c0.14-1.03,0.6-1.72,1.39-2.38
		c1.44-1.2,2.68-2.6,2.97-4.58c0.03-0.18,0.1-0.41,0.23-0.5c1.86-1.32,2.5-3.52,3.86-5.2c1.31-1.61,2.37-3.48,4.59-4.08
		c0.92-0.25,1.31-0.98,1.18-1.85c-0.28-1.84,0.51-3.11,1.86-4.26c1.52-1.28,2.32-3.01,2.67-4.97c0.17-0.98-0.15-1.48-1.15-1.7
		c-4.01-0.9-8.01-1.86-12.01-2.8c-0.23-0.05-0.46-0.12-0.69-0.19c-2.58-0.84-3.37-2.02-3.15-4.74c0.13-1.6-0.23-2.13-1.77-2.61
		c-2.03-0.63-3.48-0.19-4.84,1.51c-0.55,0.69-1.21,0.89-2.1,0.84c-1.67-0.09-3.35-0.07-5.02-0.03c-1.08,0.03-1.96,0.46-2.67,1.36
		c-0.82,1.04-1.21,0.92-2.26,0.16c-0.6-0.44-1.43-0.6-2.18-0.78c-0.95-0.24-1.93-0.45-2.9-0.53c-1.01-0.09-2.04-0.07-3.06,0
		c-1.43,0.1-2.85,0.29-4.28,0.43c-0.74,0.07-1.08-0.21-1.09-1.02c-0.03-1.91-2.37-3.73-4.29-3.38c-0.79,0.15-1.39,0.01-2.07-0.54
		c-0.59-0.47-1.49-0.64-2.28-0.76c-0.61-0.1-1.27,0.1-1.91,0.13c-1.14,0.06-1.92-0.22-1.98-1.61c-0.04-1.03-0.83-1.58-1.83-1.47
		c-0.73,0.08-1.44,0.41-2.14,0.66c-0.37,0.13-0.74,0.5-1.07,0.46c-1.5-0.17-2.39,0.69-3.19,1.74c-0.86,1.12-1.87,1.39-3.27,0.97
		c-2.03-0.61-3.25,0.4-3.29,2.51c-0.03,1.55-2.16,3.49-3.7,3.3c-0.35-0.04-0.76-0.3-0.98-0.59c-1.53-2.04-2.92-4.12-2.31-6.9
		c0.19-0.85,0.14-1.76,0.33-2.61c0.36-1.56,0.08-2.82-1.3-3.79c-0.35-0.24-0.65-0.77-0.69-1.19c-0.25-2.84-0.26-5.61,1.31-8.23
		c2.49-4.15,3-8.71,2.22-13.42c-0.11-0.67-0.57-1.3-0.96-1.89c-0.45-0.69-0.32-1.12,0.35-1.59c0.9-0.63,1.86-1.26,2.54-2.09
		c0.48-0.58,0.64-1.49,0.76-2.28c0.1-0.61,0.17-1.03,0.73-1.48c0.5-0.41,0.78-1.24,0.91-1.94c0.16-0.88,0.32-1.66,1.27-1.97
		c2.94-0.96,4.05-3.24,4.24-6.06c0.13-1.99,1.09-3.13,2.88-3.83c1.85-0.72,3.15-2.09,3.73-3.96c0.6-1.95,1.58-3.53,2.9-5.11
		c1.42-1.69,2.15-3.95,3.21-5.95c0.34-0.65,0.77-1.25,1.17-1.87c0.44-0.68,1.02-1.31,1.3-2.06c0.23-0.62,0.48-0.78,1.11-0.95
		c1.03-0.28,2.19-0.67,2.9-1.4c1.27-1.3,0.46-3.15-1.34-3.31c-2.85-0.26-5.5,0.4-7.8,2.13c-2.4,1.82-4.94,0.96-7.46,0.49
		c-0.19-0.04-0.41-0.54-0.42-0.84c-0.04-0.84,0.15-1.69,0.03-2.51c-0.3-2.08-1.19-4.01-1.32-6.2c-0.06-1.11-1.54-2.25-2.57-3.17
		c-0.91-0.81-1.71-1.96-3.2-1.9c-0.09,0-0.39-0.71-0.29-0.97c0.42-1.11,0.88-2.21,1.47-3.23c0.47-0.8,1.08-1.55,1.75-2.2
		c1.65-1.61,3.4-3.12,5.04-4.75c0.34-0.34,0.38-0.99,0.55-1.51c0.06-0.18,0.02-0.42,0.12-0.56c1.35-1.91,2.6-3.91,4.11-5.68
		c0.9-1.05,2.22-1.76,3.37-2.59c0.54-0.4,1.18-0.68,1.68-1.12c1.26-1.11,2.46-2.27,3.68-3.42c0.9-0.84,1.87-1.42,3.17-1.49
		c0.52-0.03,1.23-0.42,1.47-0.86c0.67-1.21,1.32-2.49,1.64-3.82c0.48-2.01,0.66-4.09,1.01-6.14c0.4-2.34-0.36-4.19-2.3-5.51
		c-4.03-2.73-6.3-6.46-6.56-11.35c-0.02-0.32,0.1-0.72,0.3-0.97c1.22-1.51,0.99-2.99,0.08-4.55c-1.38-2.35-2.65-4.76-2.72-7.58
		c-0.04-1.84,0-3.63,0.93-5.35c1.11-2.05,0.22-4.54-1.84-5.96c-1.66-1.14-3.29-2.34-4.92-3.53c-0.19-0.14-0.4-0.33-0.46-0.54
		c-0.41-1.47-1.61-1.97-2.86-2.46c-1.89-0.74-3.77-1.54-5.66-2.3c-1-0.4-1.58-1.05-1.42-2.2c0.16-1.17-0.33-2.06-1.11-2.9
		c-1.11-1.2-1.99-2.52-2.08-4.28c-0.02-0.49-0.71-1.15-1.24-1.36c-0.79-0.32-1.71-0.36-2.58-0.45c-1.12-0.12-1.94-0.65-2.66-1.72
		c2.27-0.18,3.57-1.69,4.99-3.09c0.8-0.79,1.8-1.47,2.84-1.91c1.33-0.57,2.28-1.4,2.99-2.63c1.61-2.78,2.52-3.25,5.68-2.62
		c2.06,0.41,4.07,1.1,6.11,1.62c1.11,0.29,2.24,0.56,3.38,0.72c0.92,0.13,1.48-0.44,1.48-1.33c0-0.87-0.09-1.76-0.34-2.59
		c-0.21-0.69-0.26-1.2,0.27-1.74c0.6-0.61,0.7-1.33,0.26-2.08c-0.88-1.52-0.95-3.17-0.96-4.87c-0.01-0.86-0.43-1.71-0.66-2.57
		c-0.14-0.53-0.35-1.05-0.37-1.59c-0.03-0.63,0.17-1.27,0.16-1.91c-0.02-1.29,0.22-2.39,1.33-3.27c0.94-0.74,1.2-1.84,1.03-3.04
		c-0.2-1.38-0.42-2.76-0.55-4.15c-0.06-0.66,0.09-1.33,0.15-2.05c0.68,0,1.22,0,1.9,0c0.03,0.55,0.05,1.13,0.11,1.71
		c0.25,2.23,1.87,3.09,3.85,2.05c2.07-1.08,2.39-0.99,3.54,1.07c1.33,2.38,3.27,4.03,5.92,4.71c0.95,0.24,1.33,0.74,1.3,1.69
		c-0.06,1.96,0.25,3.82,1.49,5.43c0.14,0.18,0.25,0.43,0.28,0.66c0.26,2,0.88,3.83,1.98,5.57c0.45,0.72,0.1,1.9,0.35,2.81
		c0.3,1.13,0.66,2.32,1.3,3.28c1.08,1.63,2.37,3.12,3.62,4.62c0.67,0.81,0.81,1.51,0.2,2.47c-1.08,1.69-0.42,3.6,1.48,4.26
		c1.15,0.4,1.71,1.08,1.68,2.29c-0.01,0.36,0.05,0.72,0.08,1.08c0.24,2.88,1.11,3.74,3.99,4.03c0.79,0.08,1.57,0.28,2.44,0.59
		c-1.81,1.33-1.63,2.81-0.85,4.47c0.36,0.78,0.39,1.7,0.7,2.51c0.36,0.95,0.89,1.83,1.26,2.77c0.16,0.4,0.3,1.03,0.11,1.3
		c-1.75,2.34-1.77,2.32-0.37,4.81c0.08,0.14,0.14,0.28,0.21,0.42c-1.49,1.64-1.01,2.99,0.75,4.13c0.64,0.41,1.29,0.81,1.96,1.16
		c0.98,0.5,1.25,1.25,1.01,2.29c-0.27,1.18-0.83,2.33-0.26,3.61c0.64,1.46,1.62,2.59,3.03,3.27c1.71,0.83,2.59,2.19,2.9,3.99
		c0.22,1.28,0.8,2.31,1.85,3.1c1.7,1.28,2.74,2.96,3.18,5.05c0.35,1.66,1.15,2.24,2.82,2.03c1.77-0.21,3.33,0.16,4.68,1.34
		c1.07,0.93,2.32,1.37,3.73,1.43c0.63,0.03,1.32,0.06,1.89,0.3c2.36,1.01,4.77,1.81,7.38,1.82c0.21,0,0.43,0.16,1.06,0.42
		c-0.86,0.53-1.42,1.06-2.07,1.22c-1.7,0.42-3.45,0.62-5.16,1.01c-1.08,0.25-2.21,0.52-3.15,1.07c-2.4,1.4-2.87,4.54-0.01,6.26
		c2.06,1.24,4.19,1.6,6.44,2.12c2.53,0.59,5,0.74,7.54,0.85c0.78,0.04,1.62,0.63,2.29,1.14c0.75,0.58,1.37,1.34,2,2.06
		c1.71,1.93,3.69,3.27,6.4,3.24c2.27-0.03,3.48,1.39,4.49,3.22c1.36,2.47,2.86,4.86,4.29,7.29c0.18,0.3,0.42,0.65,0.41,0.97
		c-0.05,1.51,0.94,2.36,1.86,3.33c0.76,0.79,1.5,1.68,1.93,2.67c0.78,1.77,1.92,3.11,3.58,4.05c0.62,0.36,1.24,0.73,1.85,1.11
		c1.04,0.63,1.55,1.49,1.49,2.78c-0.08,1.65,0.43,2.42,2.04,2.77c1.42,0.31,2.92,0.25,4.38,0.33c1.38,0.08,2.78,0.01,4.15,0.23
		c1.64,0.25,2.6-0.14,2.97-1.76c0.27-1.19,0.31-2.45,0.34-3.68c0.04-1.49,0.4-2.77,1.47-3.92c0.65-0.69,1.1-1.66,1.37-2.59
		c0.47-1.58,1.47-2.36,2.94-3.02c2.17-0.97,3.81-0.55,5.21,1.23c0.31,0.39,0.48,0.92,0.61,1.41c0.11,0.42,0.03,0.88,0.1,1.31
		c0.22,1.6,1,2.28,2.61,2.1c1.14-0.13,2.26-0.47,3.39-0.73c1.01-0.23,1.83-0.12,2.57,0.76c0.98,1.16,2.64,1.4,4.06,0.88
		c0.44-0.16,1.02-0.2,1.46-0.05c1.27,0.44,2.29,0.13,3.12-0.84c0.62-0.73,1.2-1.49,1.76-2.27c0.74-1.02,1.65-1.23,2.84-0.85
		c1.31,0.42,2.67,0.68,3.98,1.09c0.34,0.11,0.62,0.51,0.83,0.84c0.11,0.17,0.03,0.47,0.01,0.71c-0.17,3.1,1.15,4.28,4.18,3.7
		c0.23-0.04,0.45-0.12,0.82-0.22c-0.18,1.38,0.24,2.2,1.68,2.53c1.07,0.25,1.85,1.96,1.71,3.16c-0.13,1.07-0.44,2.23,0.58,3.01
		c1.1,0.84,2.21,0.24,3.27-0.23C744.95,710.72,745.25,710.6,745.53,710.48z"/>
				</g>
				<g>
					<path d="M591.88,526.08c0.62-1.12,1.61-1.63,2.85-1.66c1.12-0.03,2-0.3,2.31-1.6c0.24-1,1.14-1.34,2.1-1.54
		c1.24-0.26,1.62-0.76,0.98-1.72c-2.53-3.8-0.28-6.57,1.91-9.32c1.19-1.5,1.41-3.12,0.22-4.66c-1-1.29-1.46-2.68-1.5-4.32
		c-0.02-0.77-0.47-1.58-0.89-2.26c-0.87-1.41-0.96-2.19-0.14-3.16c0.84-0.99,2.43-1.32,3.61-0.74c0.36,0.18,0.7,0.39,1.03,0.61
		c0.56,0.37,1.04,0.47,1.57-0.12c0.67-0.75,1.52-0.55,2.33-0.25c0.63,0.23,1.24,0.51,1.88,0.77c0.48-2.07,0.75-2.24,2.84-2.25
		c0.73,0,1.49-0.18,2.2-0.39c1.37-0.42,2.42-0.18,3.62,0.76c1.16,0.9,2.72,1.39,4.18,1.78c1.49,0.4,2.05,0.81,2.23,2.35
		c0.03,0.27,0.15,0.62,0.35,0.78c1.04,0.8,2.56,1.01,3.04,2.52c0.03,0.08,0.6,0.11,0.82-0.01c0.94-0.5,1.49-1.29,1.59-2.38
		c0.11-1.15,0.3-2.29,0.36-3.44c0.02-0.32-0.23-0.73-0.49-0.97c-1.23-1.15-1.91-2.45-1.86-4.22c0.02-0.79-0.42-1.71-0.93-2.37
		c-1.64-2.16-3.4-4.23-5.13-6.32c-0.12-0.15-0.27-0.32-0.45-0.39c-1.88-0.81-2.02-2.32-1.84-4.09c0.09-0.83,0.01-1.81-0.35-2.54
		c-0.7-1.41-0.6-2.72,0.36-3.8c1.38-1.56,1.55-3.28,1.35-5.23c-0.3-2.98,0.18-3.69,2.99-4.93c0.75-0.33,1.03-0.84,0.95-1.59
		c-0.19-1.89,1.1-2.81,2.45-3.62c0.62-0.37,1.37-0.53,2.09-0.7c0.89-0.2,1.26,0.33,1.3,1.13c0.14,3.31,2.64,5.18,4.54,7.38
		c0.45,0.51,1.45,0.62,2.22,0.77c4.53,0.88,8.45,3.04,12.21,5.6c0.95,0.65,2,1.16,3.05,1.64c1.85,0.85,2.93,2.08,2.68,4.29
		c-0.1,0.92,0.29,1.9,0.28,2.84c-0.01,1.25-0.09,2.52-0.31,3.75c-0.31,1.76-1.55,2.76-2.96,3.82c-3.34,2.5-6.56,5.19-9.28,8.4
		c-0.15,0.18-0.3,0.38-0.41,0.59c-0.15,0.28-0.26,0.58-0.38,0.86c1.47,1.25,1.34,1.98-0.17,3.18c-1.28,1.01-2.37,2.27-3.64,3.5
		c2.4,0.54,4.52,0.89,6.56,1.52c1.17,0.36,2.17,1.24,3.3,1.75c0.71,0.32,1.54,0.42,2.32,0.54c0.93,0.13,1.86,0.19,2.8,0.23
		c0.7,0.03,1.46,0.14,2.11-0.06c1.76-0.55,3.49-0.81,5.3-0.33c1.05,0.28,1.85-0.15,2.25-1.1c0.71-1.67,1.93-2.29,3.72-2.49
		c1.78-0.19,3.54-0.81,5.25-1.4c0.71-0.24,1.3-0.87,1.9-1.38c1.43-1.2,2.73-2.6,4.28-3.61c3.56-2.34,7.04-4.91,11.17-6.18
		c2.44-0.75,4.04-1.82,4.53-4.61c0.39-2.19,2.68-2.42,4.06-0.61c0.43,0.57,0.77,1.21,1.11,1.84c0.36,0.65,0.82,0.92,1.58,0.67
		c0.45-0.14,0.94-0.23,1.41-0.24c1.85-0.06,2.79,1.41,1.74,2.94c-0.8,1.16-1.51,2.2-1.25,3.69c0.06,0.33-0.36,0.75-0.59,1.12
		c-0.22,0.35-0.47,0.67-0.52,1.21c0.91-0.37,1.88-1.11,2.73-1c0.9,0.12,1.68,1.05,2.59,1.67c0.16-0.21,0.33-0.64,0.63-0.81
		c0.59-0.33,1.45-0.89,1.87-0.68c1.72,0.85,3.49-0.21,5.17,0.34c0.12,0.04,0.35-0.03,0.43-0.13c1.07-1.31,2.29-0.79,3.48-0.27
		c1.61,0.7,1.94,0.47,2.47-1.15c0.76-2.31-0.41-4.19-1.52-5.79c-3.15-4.54-3.13-9.5-2.5-14.6c0.21-1.7,0.46-3.41,0.9-5.06
		c0.24-0.91,0.78-1.83,1.44-2.5c0.66-0.66,0.68-1.09,0.5-2.01c-0.4-2.06-0.53-4.19-0.59-6.29c-0.08-2.67-0.33-5.24-1.87-7.55
		c-1.04-1.56-0.9-3.34-0.27-4.97c0.77-2.01-0.47-3.23-1.51-4.53c-0.49-0.61-1.24-1-1.84-1.53c-1.89-1.69-1.6-4.22,0.71-5.29
		c1.28-0.59,2.73-0.84,4.12-1.18c3-0.73,5.55-2.06,6.28-5.33c0.27-1.23,0.86-2.15,1.58-3.07c0.24,0,0.48,0,0.72,0
		c0.76,0.44,1.55,0.83,2.26,1.33c1.13,0.79,1.85,1.89,2.02,3.27c0.14,1.17,0.62,1.9,1.79,2.4c2.38,1,2.84,2.44,1.85,4.83
		c-0.13,0.31-0.19,0.7-0.12,1.02c0.89,4.05,1.87,8.09,2.71,12.15c0.33,1.58,1.06,2.46,2.72,2.65c2.46,0.28,4.36,1.47,5.42,3.8
		c0.88,1.94,2.33,3.08,4.45,3.63c3.16,0.83,4.96,3.28,6.13,6.13c0.72,1.76,1.09,3.66,1.53,5.52c0.25,1.03,0.58,1.95,1.69,2.26
		c1.14,0.31,1.78-0.49,2.37-1.26c2.5-3.29,5.7-4.53,9.89-4.34c4.15,0.18,8.34-0.45,12.51-0.74c0.64-0.04,1.27-0.18,1.9-0.2
		c1.58-0.05,2.99-0.34,4.31-1.42c1.67-1.37,3.85-1.41,5.94-1.46c1.73-0.05,1.76-0.01,2.11-1.73c0.24-1.17,0.96-1.93,1.98-2.4
		c1.23-0.56,2.49-1.11,3.78-1.5c2.06-0.63,3.28,0.31,3.34,2.44c0.01,0.36,0.02,0.73-0.08,1.07c-0.98,3.66-1.9,7.36-5.26,9.74
		c-1.83,1.29-3.6,2.67-5.42,3.96c-0.9,0.64-1.39,1.37-1.09,2.53c0.13,0.49,0.12,1.04,0.09,1.55c-0.1,1.53-0.43,1.8-2.76,2.4
		c0.18,0.27,0.35,0.53,0.52,0.78c0.8,1.21,0.81,2.17-0.12,3.28c-0.48,0.58-1.02,1.17-1.66,1.54c-2.13,1.23-3.53,3.1-4.68,5.19
		c-0.69,1.25-1.33,2.48-3.1,2.89c0.59,0.39,0.93,0.63,1.3,0.86c1.36,0.84,2.69,1.73,4.09,2.5c0.89,0.49,1.84,0.96,2.82,1.14
		c2.31,0.43,4.12,1.24,4.47,3.9c0.05,0.42,0.53,0.87,0.93,1.14c0.73,0.49,1.54,0.85,2.31,1.26c2.28,1.2,3.83,3.07,4.94,5.36
		c0.5,1.03,1.35,1.43,2.48,1.6c0.68,0.1,1.46,0.54,1.89,1.08c1.16,1.47,2.15,3.08,3.2,4.63c0.09,0.13,0.19,0.27,0.22,0.42
		c0.41,2.23,1.63,4.32,0.97,6.75c-0.28,1.06-0.21,2.22-0.22,3.34c-0.02,1.75,0.38,2.28,2.16,2.9c1.47-2.09,2.96-4.19,4.44-6.3
		c0.34-0.49,0.66-1,1.02-1.48c0.66-0.9,1.44-1.07,2.52-0.64c0.83,0.33,1.77,0.49,2.67,0.49c1.69,0.01,3.03,0.5,3.96,2
		c0.23,0.38,0.8,0.55,1.23,0.8c0.13,0.08,0.36,0.02,0.46,0.12c1.45,1.32,2.34,0.1,3.42-0.65c1.77-1.22,3.54-2.53,5.49-3.38
		c1.23-0.53,2.8-0.45,4.2-0.36c1.35,0.08,2.49-0.1,3.56-0.99c0.47-0.39,1.21-0.46,1.84-0.66c0.36-0.12,0.81-0.12,1.08-0.34
		c2.5-2.01,5.46-2.26,8.48-2.31c0.48-0.01,0.98,0.12,1.43,0.01c5.19-1.28,10.22-1.03,14.96,1.61c0.94-0.71,1.24-1.57,1.21-2.66
		c-0.05-2.43,0.55-2.96,2.93-2.73c0.24-1.51,0.96-2.57,2.69-2.63c0.24-0.01,0.52-0.43,0.67-0.72c0.58-1.08,1.38-1.69,2.68-1.67
		c0.42,0.01,0.92-0.23,1.25-0.51c1.02-0.87,2.05-1.63,3.47-1.72c0.43-0.03,0.87-0.37,1.24-0.66c0.69-0.54,1.32-1.16,2.01-1.71
		c1.75-1.41,3.02-1.41,4.71,0c0.08-0.13,0.17-0.26,0.23-0.39c1.13-2.2,1.72-2.53,4.2-2.06c1.62,0.31,3.18,0.23,4.7-0.4
		c0.28-0.12,0.69-0.27,0.77-0.49c0.42-1.31,1.3-2.11,2.54-2.63c0.28-0.12,0.47-0.52,0.65-0.82c0.66-1.08,1.23-2.23,1.99-3.25
		c0.24-0.32,1.02-0.47,1.42-0.33c0.27,0.1,0.51,0.91,0.4,1.3c-0.46,1.6-0.28,2.96,1.11,3.98c1.07,0.79,1.46,1.82,1.39,3.12
		c-0.07,1.19,0.26,2.25,1.07,3.21c0.55,0.66,0.87,1.52,1.32,2.27c0.3,0.51,0.54,1.14,0.99,1.47c2.04,1.52,2.02,3.69,1.41,5.65
		c-0.61,1.95-0.42,3.45,0.85,4.95c0.35,0.41,0.53,0.97,0.78,1.47c0,0.24,0,0.48,0,0.72c-0.68,0.48-1.36,0.88-2.1,0.08
		c-0.09-0.09-0.28-0.16-0.4-0.14c-2.21,0.44-4.24,1.01-5.74,3.04c-1.72,2.33-3.84,4.37-5.76,6.56c-0.6,0.69-1.13,1.44-1.62,2.22
		c-1.39,2.19-2.29,2.47-4.62,1.23c-0.04,0.85-0.11,1.64-0.12,2.43c-0.01,1.38-0.44,2.59-1.36,3.61c-0.67,0.74-1.45,1.11-2.46,0.76
		c-0.94-0.32-1.63,0.05-2.14,0.85c-0.15,0.24-0.27,0.51-0.47,0.69c-1.05,0.94-1.91,1.74-0.88,3.4c0.61,0.99-0.27,2.17-1.45,2.61
		c-0.37,0.14-0.75,0.23-1.19,0.37c0.06,0.51,0.2,0.98,0.17,1.44c-0.08,1.01-0.11,2.06-0.42,3.01c-0.36,1.09-1.01,2.08-1.54,3.11
		c-1.12,2.19-1.05,4.5-0.45,6.78c0.24,0.91,0.87,1.8,1.52,2.51c1.09,1.18,1.44,2.38,0.95,3.94c-0.91,2.9-0.1,4.36,2.76,5.3
		c0.76,0.25,1.52,0.5,2.24,0.83c0.61,0.28,1.29,0.61,1.03,1.51c-0.25,0.85-0.6,1.48-1.71,1.45c-2.63-0.07-5.27-0.02-7.91,0.01
		c-0.47,0-0.93,0.13-1.05,0.15c-0.25,1.03-0.44,1.85-0.71,2.98c0.44,0.59-0.02,1.27-0.84,1.81c-1.1,0.73-2.21,1.43-3.33,2.12
		c-1.11,0.68-2.26,0.84-3.38,0.08c-0.87-0.59-1.37-0.21-1.59,0.61c-0.69,2.56-2.35,4.26-4.58,5.59c-0.59,0.35-1.07,1.21-1.22,1.92
		c-0.39,1.84-1.06,3.32-3.04,3.92c-0.36,0.11-0.65,0.65-0.85,1.06c-0.22,0.46-0.27,1-0.44,1.49c-0.6,1.67-1.55,2.37-3.08,2.32
		c-1.63-0.06-2.78-1.02-3.23-2.68c-0.03-0.11-0.1-0.21-0.06-0.12c-0.9,0.29-1.77,0.49-2.56,0.87c-0.53,0.25-1.14,0.67-1.36,1.16
		c-0.59,1.29-1.45,2.14-2.79,2.61c-1.16,0.4-2.34,0.85-3.39,1.46c-0.54,0.31-1.08,1-1.19,1.6c-0.45,2.44-1.36,3.87-4.46,3.73
		c-1.21-0.05-2.34,0.17-2.61,1.8c-0.11,0.68-0.74,1.31-1.2,1.9c-0.97,1.23-1.06,2.42-0.37,3.88c0.48,1.03,0.68,2.29,0.62,3.43
		c-0.09,1.68-1.23,2.62-2.81,3.19c-1.17,0.42-2.4,0.97-3.28,1.8c-1.72,1.65-3.28,3.48-4.77,5.34c-1.06,1.33-1.32,2.94-1.08,4.66
		c0.25,1.87-0.23,3.5-2.1,4.62c1.2,1.46,0.84,3.01,0.41,4.64c-0.59,2.25,0.08,4.41,0.68,6.58c0.24,0.86,0.08,1.83,0.1,2.75
		c0,0.11-0.12,0.22-0.12,0.34c-0.01,3.3-1.89,5.79-3.62,8.32c-0.67,0.98-1.45,1.9-2.27,2.77c-1.22,1.3-1.85,2.73-1.61,4.56
		c0.36,2.68-0.38,4.98-2.63,6.68c-1.12,0.85-1.43,2.06-1.33,3.42c0.23,3.21-0.42,4.21-3.41,5.32c-0.56,0.21-1.13,0.4-1.69,0.61
		c-1.68,0.65-2.67,1.81-2.85,3.65c-0.22,2.2-1.3,3.98-2.74,5.61c-0.94,1.07-1.74,2.27-2.65,3.38c-0.63,0.77-1.3,1.51-2.01,2.2
		c-0.91,0.87-1.11,1.74-0.4,2.86c0.85,1.35,0.74,2.08-0.33,3.21c-0.71,0.76-1.35,1.43-0.65,2.59c0.35,0.59-0.1,3.55-0.43,4.19
		c-0.81,1.57-0.61,2.01-0.77,3.73c-0.3,3.06,0.62,5.97,0.66,8.97c0.01,0.54,0.28,1.15,0.61,1.59c1.16,1.55,0.9,3.17-0.78,4.16
		c-0.24,0.14-0.49,0.27-0.76,0.35c-1.99,0.55-3.61,1.56-4.84,3.29c-0.39,0.55-1.33,0.9-2.06,0.98c-1.98,0.23-3.72-0.39-5.36-1.59
		c-1.6-1.16-3.34-2.18-5.13-3.01c-5.66-2.59-11.39-5.02-17.06-7.59c-1.25-0.57-2.33-1.53-3.51-2.27c-0.61-0.39-1.25-0.77-1.92-1.03
		c-0.94-0.37-1.06-1.25-0.17-2.21c-2.19-0.56-3.89-1.83-5.41-3.42c-0.32-0.34-1-0.4-1.53-0.46c-0.67-0.07-1.37,0.08-2.03-0.02
		c-1.14-0.17-2.17-0.58-2.45-1.86c-0.9-0.09-1.74-0.13-2.56-0.25c-2.47-0.36-3.5-1.67-3.09-4.17c0.21-1.28-0.07-2.27-1.05-3.18
		c-0.56,0.24-1.13,0.52-1.72,0.73c-2.04,0.72-3.24-0.15-3.06-2.31c0.11-1.33,0.09-2.56-0.97-3.53c-0.2-0.19-0.42-0.47-0.65-0.5
		c-1.41-0.18-1.87-1.04-1.72-2.42c-0.56,0.09-1.02,0.18-1.48,0.23c-2.24,0.25-3.28-0.58-3.52-2.82c-0.05-0.43,0.01-0.88,0.04-1.31
		c0.04-0.76-0.26-1.14-1.06-1.29c-1.2-0.22-2.39-0.52-3.54-0.91c-1.54-0.53-2.22-0.39-3.18,0.94c-0.42,0.58-0.84,1.16-1.29,1.72
		c-0.97,1.21-2.11,1.77-3.68,1.11c-0.34-0.14-0.85,0.01-1.24,0.14c-1.49,0.51-2.92,0.38-3.97-0.79c-0.87-0.96-1.77-1.08-2.9-0.8
		c-1,0.25-2.01,0.56-3.04,0.66c-1.85,0.17-2.89-0.61-2.74-2.42c0.2-2.38-1.49-3.12-2.95-4.15c-0.12-0.09-0.29-0.16-0.44-0.16
		c-2.08-0.13-4.74,1.82-5.42,3.77c-0.34,0.99-0.9,1.96-1.57,2.76c-0.82,0.98-1.22,2.01-1.26,3.27c-0.03,1.28-0.06,2.57-0.28,3.82
		c-0.34,1.88-1.32,2.34-3.21,2.04c-1.4-0.22-2.85-0.08-4.27-0.18c-1.5-0.1-3.02-0.16-4.48-0.47c-1.16-0.25-1.81-1.18-1.66-2.44
		c0.18-1.49-0.54-2.43-1.73-3.13c-0.79-0.47-1.7-0.82-2.33-1.45c-1-0.99-1.84-2.15-2.7-3.28c-0.79-1.03-1.45-2.18-2.31-3.13
		c-0.83-0.91-1.63-1.73-1.67-3.06c-0.01-0.37-0.21-0.77-0.4-1.1c-1.38-2.32-2.93-4.56-4.13-6.97c-1.11-2.22-2.6-3.62-5.14-3.6
		c-2.08,0.02-3.68-0.9-5.06-2.34c-0.69-0.72-1.46-1.38-2.08-2.16c-1.34-1.67-3.05-2.08-5.11-2.01c-1.47,0.05-2.95-0.38-4.43-0.58
		c-1.06-0.15-2.15-0.2-3.19-0.42c-0.91-0.2-1.8-0.52-2.65-0.89c-0.58-0.25-1.06-0.72-1.63-1c-3.06-1.53-2.28-4.95-0.06-6.12
		c1.34-0.7,2.94-0.93,4.43-1.31c1.43-0.36,2.89-0.58,4.31-0.97c0.52-0.14,0.95-0.6,1.42-0.91c-0.03-0.13-0.05-0.27-0.08-0.4
		c-0.55-0.11-1.11-0.21-1.66-0.33c-1.58-0.34-3.17-0.68-4.74-1.04c-1.27-0.29-2.56-0.56-3.8-0.96c-1.16-0.37-2.4-0.69-3.37-1.37
		c-1.49-1.06-3-1.57-4.81-1.39c-1.75,0.17-2.44-0.32-2.81-2.02c-0.47-2.13-1.64-3.73-3.25-5.17c-0.8-0.72-1.41-1.83-1.71-2.88
		c-0.52-1.78-1.3-3.14-2.96-4.14c-1.11-0.66-2.01-1.77-2.82-2.82c-0.7-0.9-0.56-2.01-0.23-3.1c0.63-2.08,0.49-2.38-1.31-3.47
		c-0.58-0.35-1.16-0.71-1.71-1.1c-1.59-1.12-1.73-2.36-0.42-3.84c-0.07-0.15-0.13-0.34-0.23-0.5c-1.44-2.53-1.42-2.51,0.37-4.81
		c0.2-0.25,0.18-0.97-0.03-1.19c-0.99-1.02-1.32-2.23-1.47-3.58c-0.07-0.66-0.34-1.3-0.58-1.93c-0.9-2.33-0.89-2.45,0.87-4.39
		c-0.59-0.18-1.08-0.37-1.59-0.47c-0.67-0.13-1.35-0.14-2.02-0.27c-1.76-0.35-2.53-1.2-2.76-2.97c-0.04-0.32-0.14-0.64-0.11-0.95
		c0.15-1.75-0.37-2.93-2.24-3.52c-1.38-0.43-1.95-2.53-1.07-3.78c0.82-1.18,0.56-1.99-0.28-2.95c-1.15-1.33-2.16-2.78-3.29-4.12
		c-1.58-1.87-1.71-4.1-1.52-6.35c0.07-0.79,0.47-1.69,1.52-1.9c0.26-0.05,0.57-0.19,0.71-0.4c0.82-1.21,2.07-1.21,3.32-1.25
		c1.35-0.05,2.71-0.07,4.06-0.19c1.05-0.1,2.19,0.07,2.89-1.2c0.23-0.42,1.22-0.59,1.86-0.58c1.37,0.01,2.42-0.47,3.46-1.34
		c0.69-0.57,1.7-1.02,2.58-1.05c3.11-0.11,6.18-0.42,9.16-1.36c1.29-0.41,2.28-0.1,3.16,1c0.49,0.62,1.28,1.09,2.02,1.39
		c1.47,0.6,2.88,1.16,3.51,2.83c0.11,0.3,0.69,0.56,1.08,0.6c2.02,0.21,3.98-0.13,5.82-0.98c0.18-0.08,0.29-0.42,0.36-0.66
		c0.57-1.92,3.76-3.14,5.35-1.91c0.46,0.35,0.7,1.21,0.73,1.85c0.07,1.25,0.19,1.42,1.39,1.1c0.58-0.16,1.15-0.32,1.74-0.45
		c0.27-0.06,0.61-0.14,0.82-0.02c1.42,0.82,3.09,1.48,3.5,3.28c0.25,1.1,0.73,1.92,1.61,2.53c1.15,0.8,1.8,1.85,1.91,3.29
		c0.05,0.64,0.29,1.37,0.69,1.86c1.22,1.53,1.61,3.26,1.55,5.16c-0.01,0.35,0.05,0.7,0.09,1.28c1.1-0.29,2.13-0.42,3.04-0.82
		c1.19-0.53,1.27-1.24,0.87-2.47c-0.21-0.63-0.25-1.51,0.04-2.06c0.79-1.46,1.71-2.88,2.77-4.15c1.08-1.31,1.61-2.67,1.57-4.37
		c-0.08-3.39,0.5-4.06,3.71-4.98c0.32-0.09,0.7-0.43,0.79-0.73c0.23-0.76,0.37-1.55,0.46-2.34c0.17-1.61,0.69-2.08,2.3-2.01
		c0.31,0.01,0.62,0,1.03,0c0-0.49,0-0.91,0-1.33c-0.01-1.95,0.92-2.97,2.85-3.05c0.76-0.03,1.09-0.39,1.16-1.08
		c0.03-0.28,0.07-0.57,0.02-0.84c-0.43-2.27,0.64-3.84,2.34-5.14c0.4-0.31,0.79-0.63,1.17-0.94c-1.59-2.66-0.83-4.33,2.2-4.77
		c2.4-0.35,4.66-0.85,6.1-3.06c0.21-0.32,0.43-0.64,0.59-1.09c-3.81,1.91-7.9,0.63-11.82,1.27c-3.13,0.52-6.11-0.44-8.94-1.75
		c-3.4-1.58-6.7-3.41-10.15-4.86c-5.81-2.44-11.87-3.99-18.16-4.62c-4.97-0.5-9.93-1.04-14.9-1.45c-1.03-0.09-2.14,0.21-3.15,0.52
		c-1.37,0.43-2.61,0.6-3.88-0.29c-0.31-0.22-1.06-0.17-1.4,0.06c-3.49,2.4-5.95-0.24-6.01-3.35c-0.04-2.11,0.05-4.14,0.88-6.1
		c0.2-0.46,0.33-0.95,0.28-1.69c-0.9,0.36-1.83,0.65-2.69,1.08c-1.25,0.62-2.43,0.91-3.74,0.1c-0.39-0.24-1.03-0.27-1.49-0.15
		c-2.01,0.52-3.74-0.09-5.24-1.34c-1-0.83-2-0.94-3.23-0.7c-1.15,0.22-2.37,0.35-3.53,0.24c-1.19-0.1-1.9-1.06-2.47-2.06
		C591.88,526.72,591.88,526.4,591.88,526.08z M728.42,427.2c-0.46,1.14-0.74,2.32-1.36,3.31c-1.72,2.75-4.38,4.08-7.51,4.62
		c-1.05,0.18-2.09,0.49-3.07,0.91c-0.71,0.3-1.03,1.04-0.38,1.66c1.34,1.26,2.86,2.27,3.92,3.89c0.54,0.82,0.88,1.45,0.68,2.34
		c-0.16,0.74-0.43,1.46-0.61,2.19c-0.29,1.15-0.45,2.32,0.25,3.37c1.7,2.59,2.04,5.48,2.09,8.48c0.04,2.34-0.18,4.7,0.92,6.95
		c0.23,0.48-0.1,1.65-0.56,1.97c-1.43,1.02-2,2.3-1.94,3.99c0.02,0.39-0.16,0.78-0.21,1.18c-0.27,2.21-0.71,4.41-0.76,6.63
		c-0.08,3.33,0.82,6.38,2.77,9.23c1.43,2.1,2.45,4.51,1.42,7.31c-0.92,2.51-2.74,3.46-5.1,2.24c-0.76-0.39-1.28-0.37-1.89,0.29
		c-0.28,0.3-0.9,0.4-1.36,0.4c-0.76,0-1.52-0.2-2.28-0.23c-1.1-0.04-2.2-0.01-3.43-0.01c-1.82,2.61-1.87,2.61-4.85-0.33
		c-0.15,0.1-0.31,0.21-0.47,0.32c-1.4,0.92-2.59,0.86-3.56-0.19c-1.02-1.1-0.99-2.15,0.06-3.52c0.23-0.3,0.5-0.72,0.46-1.05
		c-0.16-1.32-0.08-2.52,1.44-3.5c-3.16,0.54-3.49,0.37-4.88-2.1c-0.25-0.45-0.59-0.86-1.03-1.49c-0.19,0.71-0.35,1.12-0.4,1.55
		c-0.28,2.25-1.51,3.5-3.71,4.14c-5.38,1.55-9.88,4.8-14.43,7.9c-0.72,0.49-1.67,1.04-1.13,2.31c0.09,0.22-0.25,0.78-0.54,0.97
		c-0.55,0.38-1.17,0.74-1.81,0.87c-2.22,0.46-4.46,0.9-6.71,1.19c-1.18,0.16-2.02,0.55-2.54,1.66c-0.79,1.66-2.13,2.35-3.93,1.93
		c-1.74-0.41-3.39-0.09-5.07,0.38c-0.8,0.22-1.71,0.16-2.56,0.12c-0.93-0.04-1.86-0.34-2.78-0.29c-1.5,0.08-2.87,0.03-3.87-1.32
		c-0.24-0.33-0.69-0.62-1.09-0.71c-2.52-0.54-5.05-1.09-7.6-1.47c-1.29-0.19-1.99-0.89-1.6-2.17c0.18-0.6,0.47-1.21,0.84-1.7
		c0.82-1.08,1.75-2.07,2.6-3.13c0.19-0.24,0.31-0.57,0.36-0.88c0.12-0.76-0.06-1.69,0.31-2.27c0.84-1.33,2.02-2.44,2.93-3.72
		c2.21-3.1,5.53-4.9,8.35-7.28c1.23-1.04,2.17-3.04,1.89-4.63c-0.21-1.18-0.39-2.37-0.41-3.56c-0.02-1.24-0.53-2.02-1.62-2.54
		c-1.08-0.51-2.18-1.01-3.17-1.67c-3.56-2.42-7.28-4.53-11.56-5.3c-2.51-0.45-4.2-1.79-5.46-3.88c-0.43-0.71-0.98-1.35-1.44-2.05
		c-0.57-0.86-1.09-1.75-1.78-2.85c-0.33,0.51-1.08,1.14-1.11,1.8c-0.09,1.85-0.84,3.01-2.6,3.56c-1.01,0.32-1.45,1.05-1.34,2.12
		c0.05,0.48-0.04,0.96,0.02,1.44c0.24,2.07-0.04,3.96-1.62,5.52c-0.62,0.61-0.61,1.36-0.19,2.21c0.32,0.65,0.61,1.51,0.46,2.17
		c-0.38,1.69,0.11,2.67,1.42,3.9c2.16,2.03,3.92,4.51,5.73,6.9c0.6,0.79,1.11,1.87,1.12,2.82c0.02,1.51,0.59,2.55,1.57,3.59
		c0.47,0.5,0.88,1.32,0.85,1.97c-0.09,1.67-0.36,3.34-0.71,4.97c-0.41,1.93-2.25,3.37-4.12,3.43c-0.93,0.03-1.51-0.41-2.07-1.15
		c-0.48-0.64-1.21-1.28-1.95-1.5c-1.57-0.46-1.82-0.56-1.74-2.25c0.03-0.68-0.22-0.92-0.81-1.07c-1.4-0.36-2.81-0.72-4.18-1.19
		c-0.39-0.13-0.62-0.68-0.97-0.98c-0.24-0.2-0.59-0.49-0.83-0.44c-1.2,0.26-2.39,0.61-3.67,0.95c-0.22,1.62-1.16,2.28-2.95,1.81
		c-0.57-0.15-1.1-0.48-1.67-0.65c-0.31-0.1-0.82-0.2-0.98-0.04c-1.23,1.24-2.38,0.64-3.6,0.01c-0.54-0.28-1.16-0.4-1.74-0.59
		c-0.07,0.13-0.14,0.26-0.21,0.39c0.23,0.35,0.51,0.68,0.68,1.05c0.33,0.76,0.82,1.54,0.83,2.31c0.02,1.6,0.48,2.93,1.46,4.19
		c1.2,1.54,1.07,4.74-0.09,6.23c-0.71,0.91-1.4,1.84-2.11,2.76c-1.04,1.35-1.34,2.62-0.34,4.26c1.55,2.56,0.76,4.32-2,5.27
		c-0.41,0.14-0.86,0.59-1.01,1c-0.43,1.15-1.25,1.71-2.39,1.93c-0.62,0.12-1.25,0.23-1.87,0.34c0,0.12,0.01,0.25,0.01,0.37
		c0.82-0.05,1.67,0.01,2.45-0.18c2.06-0.5,3.92-0.46,5.59,1.1c0.98,0.91,2.18,1.24,3.56,0.81c0.58-0.18,1.39-0.29,1.85-0.01
		c1.25,0.76,2.3,0.6,3.41-0.17c0.32-0.22,0.73-0.32,1.11-0.42c1.2-0.32,2.5-0.74,3.51,0.3c1.02,1.06,0.57,2.33,0.09,3.49
		c-0.76,1.85-1.05,3.73-0.81,5.74c0.26,2.12,0.82,2.5,2.64,1.43c1.26-0.74,2.4-0.83,3.71-0.06c0.52,0.3,1.35,0.18,2.02,0.11
		c1.61-0.17,3.23-0.74,4.8-0.6c5.97,0.53,11.93,1.13,17.86,2c6.06,0.88,11.92,2.62,17.47,5.29c2.45,1.18,4.81,2.55,7.29,3.63
		c2.75,1.2,5.6,2.08,8.7,1.8c2.1-0.19,4.25,0.01,6.34-0.29c2.19-0.31,4.33-0.95,6.46-1.58c0.67-0.2,1.22-1.18,1.81-1.17
		c0.6,0.02,1.13,0.91,1.78,1.26c0.65,0.34,0.93,0.76,0.73,1.43c-0.25,0.81-0.92,0.68-1.58,0.69c-0.42,0-1.09,0.19-1.22,0.48
		c-1.64,3.74-4.35,5.91-8.5,6.21c-0.55,0.04-1.09,0.27-1.68,0.42c1.24,2.49,1.08,3.16-0.98,4.84c-0.12,0.1-0.24,0.21-0.37,0.3
		c-1.1,0.79-1.56,1.74-1.31,3.19c0.36,2.08-0.79,3.79-2.44,4.07c-1.68,0.29-1.68,0.29-1.63,2.1c0.05,1.56-0.62,2.32-2.16,2.41
		c-0.35,0.02-0.7,0-1.01,0c-0.26,1.05-0.43,2.04-0.76,2.97c-0.36,1.01-1,1.75-2.17,1.98c-2.13,0.42-2.51,0.93-2.3,3.12
		c0.17,1.72-0.21,3.26-1.27,4.61c-0.86,1.1-1.83,2.12-2.62,3.27c-0.34,0.49-0.6,1.32-0.42,1.82c0.68,1.87,0.34,3.58-1.4,4.63
		c-1.03,0.62-2.26,0.98-3.45,1.25c-2.44,0.55-3.2-0.06-3.43-2.55c-0.02-0.24-0.03-0.48-0.02-0.72c0.08-1.38-0.36-2.57-1.11-3.72
		c-0.45-0.69-0.94-1.48-1.02-2.26c-0.14-1.36-0.74-2.32-1.79-3.09c-0.95-0.69-1.56-1.58-1.61-2.81c-0.01-0.26-0.11-0.64-0.29-0.74
		c-1.11-0.62-2.07-1.93-3.6-0.87c-0.21,0.15-0.54,0.15-0.81,0.18c-1.66,0.2-2.76-0.72-2.88-2.41c-0.02-0.31,0-0.61,0-0.91
		c-1.54-0.07-1.47-0.05-1.87,1.23c-0.16,0.51-0.6,1.1-1.07,1.31c-2.3,1.01-4.72,1.48-7.24,1.28c-1.21-0.1-2.55-0.38-3.02-1.61
		c-0.44-1.16-1.3-1.47-2.23-1.93c-0.98-0.48-1.99-1.03-2.77-1.77c-0.59-0.56-1.04-0.74-1.77-0.51c-2.83,0.92-5.71,1.42-8.71,1.27
		c-0.63-0.03-1.43,0.37-1.89,0.83c-1.13,1.12-2.4,1.63-3.98,1.62c-0.47,0-1.11,0.29-1.37,0.65c-0.59,0.82-1.34,1.05-2.26,1.09
		c-2.12,0.1-4.23,0.22-6.34,0.36c-0.33,0.02-0.68,0.16-0.96,0.34c-0.62,0.41-1.19,0.88-1.89,1.4c-0.05,1.71,0.13,3.46,1.5,4.96
		c1.23,1.35,2.31,2.86,3.42,4.32c1.09,1.43,1.15,2.57,0.18,4.05c-0.64,0.97-0.21,1.5,0.67,1.84c1.92,0.74,2.92,2.01,2.61,4.14
		c-0.03,0.23,0.02,0.48,0.03,0.72c0.08,1.62,0.45,2.02,2.05,2.23c0.67,0.09,1.36,0.12,2.02,0.25c1.29,0.25,2.16,1.06,2.57,2.27
		c0.36,1.07-0.37,1.67-1.1,2.3c-0.22,0.19-0.27,0.78-0.17,1.13c0.21,0.72,0.8,1.4,0.81,2.1c0.01,1.38,0.59,2.42,1.35,3.49
		c0.91,1.28,0.8,2.67-0.37,3.67c-0.83,0.71-0.71,1.22-0.2,2c0.8,1.22,1.38,2.51-0.2,3.67c1.01,0.62,1.91,1.19,2.83,1.74
		c1.13,0.67,1.74,1.65,1.68,2.96c-0.03,0.75-0.13,1.52-0.34,2.23c-0.38,1.3,0.02,2.27,1.05,3.04c0.38,0.29,0.81,0.52,1.22,0.75
		c1.99,1.13,3.17,2.79,3.69,5.04c0.2,0.85,0.77,1.76,1.44,2.33c1.31,1.12,2.35,2.39,2.96,3.99c0.31,0.81,0.55,1.65,0.82,2.47
		c2.89-0.36,4.15-0.01,6.51,1.7c0.57,0.42,1.23,0.92,1.89,0.98c2.31,0.19,4.42,1,6.56,1.75c0.77,0.27,1.65,0.22,2.48,0.33
		c0.63,0.08,1.29,0.09,1.89,0.28c2.01,0.64,2.59,2.61,1.13,4.14c-0.69,0.72-1.65,1.32-2.6,1.6c-1.52,0.46-3.13,0.56-4.69,0.9
		c-1.12,0.24-2.25,0.54-3.3,0.98c-0.76,0.32-1.3,1.27-0.59,1.83c1.18,0.93,2.57,1.68,3.99,2.17c1.25,0.43,2.66,0.41,4,0.59
		c1.05,0.15,2.11,0.5,3.15,0.44c2.9-0.19,5.37,0.55,7.29,2.82c0.57,0.67,1.22,1.27,1.83,1.9c1.03,1.08,2.22,1.73,3.78,1.79
		c2.93,0.12,5.11,1.43,6.51,4.14c1.35,2.61,2.97,5.08,4.45,7.62c0.26,0.44,0.53,0.93,0.57,1.42c0.09,1.1,0.76,1.76,1.44,2.51
		c0.87,0.96,1.69,2.02,2.24,3.18c0.57,1.21,1.28,2.19,2.39,2.9c1.07,0.69,2.17,1.36,3.2,2.11c0.91,0.66,1.49,1.55,1.44,2.74
		c-0.07,1.66-0.06,1.67,1.59,1.97c0.43,0.08,0.88,0.17,1.31,0.1c2.09-0.3,4.18-0.67,6.47,0.43c0.1-1.45,0.24-2.66,0.27-3.87
		c0.03-1.87,0.48-3.52,1.86-4.9c0.51-0.5,0.9-1.25,1.06-1.95c0.54-2.3,1.96-3.66,4.16-4.36c0.82-0.26,1.56-0.76,2.37-1.05
		c0.4-0.14,1-0.22,1.32-0.02c1.54,0.98,3.06,2.01,4.49,3.14c0.37,0.29,0.57,0.96,0.6,1.48c0.16,2.55,0.13,2.55,2.69,1.87
		c0.23-0.06,0.46-0.12,0.7-0.17c1.77-0.39,3.38-0.35,4.69,1.22c0.29,0.35,1.09,0.36,1.66,0.36c0.88,0.01,1.82-0.34,2.63-0.13
		c0.85,0.23,1.28-0.01,1.72-0.59c0.24-0.32,0.45-0.66,0.66-1c1.63-2.57,3.22-3.09,6.09-2.06c0.85,0.31,1.74,0.64,2.63,0.69
		c2.38,0.14,3.51,1.39,3.27,3.82c-0.17,1.73,0.06,1.92,1.75,1.42c0.15-0.05,0.31-0.08,0.46-0.13c2.11-0.64,3.05,0.13,2.82,2.28
		c2.36,1.61,3.94,3.58,3.23,6.79c0.62-0.27,1.02-0.44,1.42-0.61c1.7-0.71,2.32-0.46,3.44,1.03c0.93,1.23,1.28,2.46,0.97,4.02
		c-0.32,1.6,0.2,2.1,1.83,2.18c0.72,0.03,1.46-0.05,2.15,0.08c0.5,0.09,1.29,0.37,1.37,0.71c0.35,1.42,1.31,1.23,2.33,1.15
		c1.07-0.09,2.26-0.31,3.05,0.63c1.85,2.18,4.14,3.35,6.98,3.77c2.03,0.3,2.56,1.99,1.28,3.74c1.31,0.71,2.57,1.49,3.91,2.09
		c5.47,2.44,10.98,4.8,16.43,7.26c1.48,0.67,2.83,1.62,4.24,2.45c0.82,0.49,1.59,1.1,2.45,1.47c1.43,0.6,2.27,0.3,3.22-0.91
		c0.98-1.26,2.11-2.28,3.77-2.55c0.46-0.08,0.91-0.31,1.33-0.52c0.67-0.34,0.76-0.72,0.28-1.42c-0.44-0.64-0.78-1.47-0.83-2.24
		c-0.11-1.47,0.01-2.95-0.8-4.3c-0.17-0.29-0.08-0.77-0.05-1.15c0.16-1.69,0.59-3.37-0.28-5.05c-0.23-0.44-0.12-1.24,0.14-1.7
		c0.62-1.1,1.42-2.1,2.15-3.13c-1.56-2.53-1.46-3.57,0.31-5.72c0.26-0.31,0.28-1.04,0.11-1.45c-0.9-2.15-0.68-3.32,1.01-4.94
		c0.57-0.55,1.08-1.17,1.57-1.8c0.8-1.04,1.48-2.19,2.37-3.14c1.52-1.62,2.67-3.35,2.92-5.63c0.24-2.24,1.61-3.68,3.61-4.56
		c0.69-0.31,1.41-0.55,2.12-0.83c2.02-0.79,2.42-1.38,2.18-3.53c-0.2-1.85,0.27-3.44,1.72-4.61c1.76-1.41,2.47-3.18,2.18-5.39
		c-0.3-2.28,0.56-4.08,2.05-5.77c1.07-1.2,1.85-2.65,2.77-3.99c0.34-0.49,0.66-1,1.08-1.42c0.84-0.83,2.1-5.9,1.69-7.01
		c-0.06-0.15-0.14-0.29-0.18-0.44c-0.65-2.32-1.23-4.67-0.51-7.06c0.38-1.27,0.43-2.35-0.42-3.49c-0.74-0.99-0.52-1.83,0.58-2.38
		c1.32-0.65,1.61-1.61,1.42-2.95c-0.29-2.06,0.23-3.94,1.32-5.71c1.88-3.06,4.55-5.34,7.26-7.58c0.39-0.33,1.02-0.43,1.55-0.52
		c1.56-0.26,2.15-1.02,1.84-2.58c-0.1-0.5-0.28-1.01-0.51-1.46c-1.02-1.95-0.74-3.74,0.49-5.51c0.54-0.77,0.87-1.69,1.38-2.49
		c0.39-0.6,0.81-1.32,1.4-1.6c0.88-0.42,1.9-0.6,2.88-0.71c2.02-0.21,2.19-0.28,2.45-2.27c0.19-1.47,0.95-2.41,2.3-2.94
		c1.04-0.41,2.04-0.95,3.1-1.27c1.41-0.43,2.12-1.33,2.29-2.74c0.09-0.68,0.44-0.95,1.14-1.13c1.05-0.28,2.16-0.18,3.12-1.1
		c1.05-1,2.72-0.35,3.22,0.95c0.31,0.8,0.43,1.91,1.58,1.8c1.14-0.1,1.06-1.26,1.25-2.06c0.29-1.22,0.86-2.16,2.09-2.55
		c1.13-0.36,1.66-1.09,1.81-2.26c0.17-1.33,0.6-2.66,1.88-3.31c2.12-1.08,3.41-2.73,4.2-4.97c0.73-2.07,2.06-2.46,4.11-1.64
		c0.47,0.19,1.23,0.35,1.56,0.11c1.12-0.81,2.95-1.02,2.85-3.02c-0.01-0.27,0.26-0.56,0.4-0.84c-1.23-1.87-1.05-2.36,1.15-2.96
		c0.88-0.24,1.79-0.4,2.7-0.5c1.23-0.14,2.47-0.18,3.89-0.28c-2.85-1.76-3.41-4.28-2.38-7.45c0.15-0.48-0.29-1.2-0.57-1.75
		c-0.62-1.18-1.57-2.24-1.93-3.49c-0.93-3.2-0.57-6.36,1.15-9.25c1.03-1.72,1.64-3.35,0.92-5.4c-0.47-1.36,0.32-2.16,1.79-2.32
		c0.33-0.04,0.66-0.12,1.09-0.19c-0.13-0.32-0.18-0.51-0.28-0.66c-1.08-1.54-1.02-2.14,0.34-3.48c0.48-0.48,1-0.95,1.34-1.52
		c0.97-1.63,2.39-2.33,4.22-2.09c0.71,0.09,1.14-0.17,1.25-0.76c0.2-1.05,0.3-2.12,0.39-3.18c0.26-3.14,1.58-3.81,4.23-2.06
		c0.2-0.23,0.41-0.45,0.6-0.7c1.16-1.52,2.26-3.09,3.49-4.55c1.19-1.4,2.72-2.54,3.73-4.04c1.71-2.53,4.01-3.87,7.23-4.25
		c-1.62-1.65-1.22-3.33-0.67-5.14c0.45-1.47,0.65-3.05-1.01-4.17c-0.45-0.3-0.67-0.97-0.97-1.49c-0.5-0.86-1.03-1.71-1.45-2.62
		c-0.47-1.02-1.21-2.11-1.17-3.15c0.07-2.14-1.38-3.13-2.79-4.7c0.1,1.55-0.58,2.05-1.76,2.13c-0.27,0.02-0.51,0.54-0.77,0.81
		c-0.51,0.53-0.95,1.29-1.58,1.53c-1.86,0.73-3.76,1.25-5.84,0.74c-1.73-0.42-2.01-0.18-2.75,1.45c-0.77,1.71-2.22,2.04-3.46,0.62
		c-0.71-0.81-1.23-0.69-1.89-0.12c-0.54,0.47-1.01,1.03-1.58,1.47c-0.66,0.5-1.36,1.22-2.09,1.28c-1.37,0.12-2.24,0.88-3.22,1.65
		c-0.46,0.37-1.13,0.67-1.7,0.66c-0.87-0.02-1.35,0.26-1.62,1.06c-0.32,0.95-0.85,1.56-2,1.53c-0.34-0.01-0.87,0.5-1.01,0.88
		c-0.65,1.88-0.81,2.04-2.81,2.01c-0.01,0.28-0.03,0.55-0.04,0.83c-0.07,3.45-2.19,4.85-5.29,3.49c-0.15-0.06-0.3-0.12-0.43-0.21
		c-2.47-1.57-5.26-1.57-8.01-1.46c-2.74,0.11-5.48,0.4-8.2,0.81c-1.43,0.21-2.83,0.74-4.18,1.28c-2.14,0.86-4.22,1.89-6.36,2.75
		c-0.59,0.24-1.35,0.24-2,0.12c-2.23-0.4-4.18,0.1-5.97,1.48c-1.36,1.05-2.77,2.03-4.18,3.02c-1.51,1.05-2.34,1.03-3.77-0.02
		c-0.18-0.14-0.41-0.28-0.63-0.3c-1.09-0.05-1.64-0.79-2.28-1.55c-0.45-0.52-1.16-0.98-1.82-1.12c-1.25-0.25-2.56-0.23-4.02-0.34
		c-1.53,2.26-3.22,4.72-4.88,7.21c-0.48,0.72-0.98,1.53-1.96,1.28c-2.68-0.7-4.3-1.68-4.45-4.55c-0.08-1.47,0.06-2.95,0.21-4.42
		c0.08-0.79,0.22-1.46-0.43-2.16c-0.37-0.4-0.59-1.26-0.43-1.77c0.39-1.23-0.16-2.08-0.79-2.97c-0.62-0.88-1.27-1.75-1.85-2.65
		c-0.47-0.74-1.07-1.04-1.96-1.14c-0.7-0.07-1.5-0.39-1.99-0.87c-0.73-0.71-1.41-1.59-1.77-2.53c-0.53-1.37-1.49-2.25-2.64-2.98
		c-0.94-0.6-1.91-1.17-2.9-1.67c-1.11-0.56-1.89-1.35-2.11-2.59c-0.27-1.51-1.26-2.08-2.66-2.32c-0.97-0.17-1.97-0.42-2.82-0.89
		c-1.85-1.02-3.63-2.18-5.41-3.31c-0.57-0.36-1.14-0.76-1.58-1.26c-0.75-0.87-0.66-2.16,0.33-2.58c1.71-0.72,2.49-2.13,3.32-3.59
		c0.37-0.65,0.85-1.28,1.39-1.79c1.33-1.26,2.73-2.45,4.11-3.65c0.67-0.59,0.7-1.09,0.07-1.79c-1.5-1.71-1.03-3.16,1.18-3.62
		c0.93-0.19,1.2-0.57,1.02-1.45c-0.43-2.06,0.38-3.58,2.11-4.68c0.44-0.28,0.86-0.58,1.28-0.89c3.06-2.22,6.43-4.16,7.43-8.24
		c0.03-0.11,0.13-0.21,0.17-0.32c0.46-1.24,1-2.48,0.66-3.96c-0.62,0.21-1.13,0.43-1.66,0.56c-1.79,0.45-3.11,1.3-3.37,3.36
		c-0.13,1.04-0.94,1.65-2.01,1.76c-0.95,0.09-1.94-0.02-2.86,0.2c-1.37,0.32-2.87,0.58-3.99,1.34c-1.12,0.76-2.16,1.27-3.49,1.31
		c-0.84,0.02-1.69,0.03-2.5,0.2c-4.92,1.02-9.89,1.27-14.9,0.93c-0.67-0.05-1.36,0.02-2.03,0.14c-2.35,0.4-3.82,2.04-5.17,3.8
		c-1.18,1.52-2.64,2.1-4.43,1.56c-1.69-0.51-2.84-1.57-3.1-3.43c-0.16-1.15-0.3-2.31-0.65-3.41c-0.97-3.06-2.19-6.05-5.62-7.09
		c-2.54-0.77-4.38-2.23-5.59-4.54c-0.88-1.69-2.27-2.57-4.15-2.87c-2.84-0.46-3.72-1.4-4.33-4.19c-0.83-3.78-1.73-7.54-2.51-11.33
		c-0.19-0.94-0.26-2.01-0.02-2.92c0.45-1.65,0.5-1.97-1.16-2.62c-1.46-0.57-2.13-1.52-2.3-3.03
		C730.41,428.82,729.73,427.73,728.42,427.2z"/>
					<path class="sinop" d="M728.42,427.2c1.31,0.53,1.99,1.62,2.14,3c0.17,1.51,0.85,2.46,2.3,3.03c1.65,0.65,1.6,0.96,1.16,2.62
		c-0.25,0.91-0.18,1.98,0.02,2.92c0.78,3.79,1.68,7.55,2.51,11.33c0.61,2.79,1.5,3.73,4.33,4.19c1.88,0.3,3.27,1.18,4.15,2.87
		c1.21,2.32,3.04,3.77,5.59,4.54c3.42,1.04,4.65,4.03,5.62,7.09c0.35,1.1,0.49,2.26,0.65,3.41c0.26,1.86,1.41,2.92,3.1,3.43
		c1.79,0.54,3.25-0.04,4.43-1.56c1.36-1.76,2.82-3.4,5.17-3.8c0.67-0.11,1.36-0.18,2.03-0.14c5.01,0.34,9.97,0.09,14.9-0.93
		c0.81-0.17,1.67-0.18,2.5-0.2c1.33-0.03,2.37-0.55,3.49-1.31c1.12-0.76,2.62-1.03,3.99-1.34c0.92-0.21,1.91-0.11,2.86-0.2
		c1.07-0.1,1.88-0.71,2.01-1.76c0.26-2.06,1.57-2.9,3.37-3.36c0.53-0.13,1.04-0.35,1.66-0.56c0.34,1.48-0.2,2.71-0.66,3.96
		c-0.04,0.11-0.14,0.2-0.17,0.32c-1,4.08-4.37,6.02-7.43,8.24c-0.42,0.31-0.84,0.61-1.28,0.89c-1.73,1.1-2.55,2.61-2.11,4.68
		c0.18,0.88-0.08,1.26-1.02,1.45c-2.21,0.46-2.68,1.92-1.18,3.62c0.62,0.71,0.6,1.21-0.07,1.79c-1.38,1.21-2.78,2.4-4.11,3.65
		c-0.55,0.51-1.02,1.14-1.39,1.79c-0.83,1.46-1.61,2.88-3.32,3.59c-0.99,0.41-1.08,1.71-0.33,2.58c0.43,0.5,1.01,0.9,1.58,1.26
		c1.78,1.14,3.56,2.3,5.41,3.31c0.85,0.47,1.86,0.72,2.82,0.89c1.4,0.25,2.39,0.82,2.66,2.32c0.22,1.23,1,2.03,2.11,2.59
		c1,0.5,1.96,1.08,2.9,1.67c1.16,0.73,2.11,1.61,2.64,2.98c0.37,0.94,1.04,1.82,1.77,2.53c0.49,0.48,1.3,0.79,1.99,0.87
		c0.89,0.09,1.49,0.4,1.96,1.14c0.58,0.91,1.23,1.77,1.85,2.65c0.63,0.89,1.18,1.73,0.79,2.97c-0.16,0.51,0.06,1.37,0.43,1.77
		c0.65,0.7,0.5,1.37,0.43,2.16c-0.14,1.47-0.28,2.95-0.21,4.42c0.15,2.87,1.78,3.85,4.45,4.55c0.98,0.26,1.47-0.56,1.96-1.28
		c1.66-2.48,3.35-4.95,4.88-7.21c1.46,0.11,2.77,0.1,4.02,0.34c0.66,0.13,1.38,0.59,1.82,1.12c0.64,0.75,1.19,1.49,2.28,1.55
		c0.22,0.01,0.44,0.16,0.63,0.3c1.42,1.05,2.25,1.08,3.77,0.02c1.41-0.98,2.82-1.97,4.18-3.02c1.79-1.38,3.74-1.87,5.97-1.48
		c0.64,0.11,1.41,0.12,2-0.12c2.14-0.86,4.22-1.89,6.36-2.75c1.35-0.54,2.75-1.07,4.18-1.28c2.71-0.4,5.46-0.7,8.2-0.81
		c2.75-0.11,5.54-0.11,8.01,1.46c0.13,0.09,0.29,0.14,0.43,0.21c3.1,1.36,5.21-0.03,5.29-3.49c0.01-0.28,0.02-0.55,0.04-0.83
		c2,0.03,2.16-0.13,2.81-2.01c0.13-0.38,0.67-0.89,1.01-0.88c1.15,0.04,1.68-0.58,2-1.53c0.27-0.8,0.75-1.07,1.62-1.06
		c0.57,0.01,1.24-0.29,1.7-0.66c0.97-0.77,1.84-1.53,3.22-1.65c0.73-0.06,1.43-0.78,2.09-1.28c0.57-0.43,1.04-0.99,1.58-1.47
		c0.65-0.57,1.17-0.69,1.89,0.12c1.24,1.42,2.69,1.1,3.46-0.62c0.74-1.63,1.02-1.88,2.75-1.45c2.07,0.51,3.98-0.01,5.84-0.74
		c0.63-0.25,1.06-1,1.58-1.53c0.27-0.28,0.5-0.8,0.77-0.81c1.18-0.08,1.86-0.58,1.76-2.13c1.42,1.58,2.86,2.57,2.79,4.7
		c-0.03,1.04,0.7,2.13,1.17,3.15c0.42,0.9,0.95,1.75,1.45,2.62c0.3,0.52,0.52,1.18,0.97,1.49c1.66,1.11,1.45,2.7,1.01,4.17
		c-0.55,1.81-0.95,3.48,0.67,5.14c-3.22,0.37-5.52,1.71-7.23,4.25c-1.02,1.5-2.55,2.64-3.73,4.04c-1.23,1.46-2.33,3.03-3.49,4.55
		c-0.19,0.25-0.4,0.47-0.6,0.7c-2.64-1.75-3.97-1.08-4.23,2.06c-0.09,1.07-0.19,2.13-0.39,3.18c-0.12,0.6-0.54,0.86-1.25,0.76
		c-1.84-0.23-3.25,0.46-4.22,2.09c-0.34,0.57-0.86,1.04-1.34,1.52c-1.35,1.35-1.42,1.95-0.34,3.48c0.11,0.15,0.15,0.34,0.28,0.66
		c-0.43,0.08-0.75,0.16-1.09,0.19c-1.47,0.16-2.26,0.96-1.79,2.32c0.72,2.06,0.11,3.68-0.92,5.4c-1.72,2.89-2.08,6.05-1.15,9.25
		c0.36,1.24,1.31,2.31,1.93,3.49c0.29,0.55,0.73,1.27,0.57,1.75c-1.03,3.17-0.47,5.69,2.38,7.45c-1.42,0.1-2.66,0.14-3.89,0.28
		c-0.91,0.1-1.82,0.26-2.7,0.5c-2.2,0.6-2.37,1.09-1.15,2.96c-0.14,0.28-0.42,0.56-0.4,0.84c0.1,2.01-1.73,2.21-2.85,3.02
		c-0.33,0.24-1.09,0.09-1.56-0.11c-2.05-0.83-3.38-0.44-4.11,1.64c-0.79,2.24-2.07,3.89-4.2,4.97c-1.29,0.66-1.71,1.98-1.88,3.31
		c-0.15,1.17-0.69,1.9-1.81,2.26c-1.24,0.39-1.8,1.34-2.09,2.55c-0.19,0.81-0.11,1.96-1.25,2.06c-1.15,0.1-1.27-1-1.58-1.8
		c-0.5-1.3-2.17-1.95-3.22-0.95c-0.96,0.92-2.08,0.82-3.12,1.1c-0.71,0.19-1.06,0.45-1.14,1.13c-0.18,1.4-0.88,2.31-2.29,2.74
		c-1.06,0.32-2.06,0.86-3.1,1.27c-1.35,0.53-2.11,1.47-2.3,2.94c-0.26,2-0.44,2.06-2.45,2.27c-0.98,0.1-2.01,0.29-2.88,0.71
		c-0.59,0.28-1.02,1.01-1.4,1.6c-0.51,0.8-0.84,1.71-1.38,2.49c-1.24,1.76-1.51,3.56-0.49,5.51c0.24,0.45,0.42,0.96,0.51,1.46
		c0.31,1.56-0.28,2.32-1.84,2.58c-0.54,0.09-1.16,0.19-1.55,0.52c-2.71,2.24-5.38,4.52-7.26,7.58c-1.09,1.77-1.62,3.66-1.32,5.71
		c0.19,1.34-0.1,2.3-1.42,2.95c-1.11,0.54-1.33,1.38-0.58,2.38c0.85,1.14,0.8,2.22,0.42,3.49c-0.72,2.39-0.14,4.74,0.51,7.06
		c0.04,0.15,0.12,0.29,0.18,0.44c0.41,1.11-0.85,6.18-1.69,7.01c-0.42,0.41-0.74,0.93-1.08,1.42c-0.92,1.33-1.71,2.78-2.77,3.99
		c-1.49,1.69-2.35,3.49-2.05,5.77c0.29,2.2-0.42,3.98-2.18,5.39c-1.46,1.17-1.92,2.76-1.72,4.61c0.23,2.15-0.17,2.74-2.18,3.53
		c-0.71,0.28-1.43,0.52-2.12,0.83c-2,0.88-3.37,2.32-3.61,4.56c-0.25,2.28-1.4,4.01-2.92,5.63c-0.89,0.95-1.56,2.1-2.37,3.14
		c-0.49,0.63-1,1.25-1.57,1.8c-1.69,1.62-1.91,2.8-1.01,4.94c0.17,0.41,0.15,1.14-0.11,1.45c-1.77,2.15-1.87,3.18-0.31,5.72
		c-0.72,1.03-1.53,2.03-2.15,3.13c-0.26,0.46-0.37,1.26-0.14,1.7c0.87,1.67,0.43,3.35,0.28,5.05c-0.04,0.38-0.13,0.86,0.05,1.15
		c0.81,1.35,0.69,2.83,0.8,4.3c0.06,0.77,0.39,1.6,0.83,2.24c0.48,0.7,0.39,1.08-0.28,1.42c-0.42,0.22-0.87,0.45-1.33,0.52
		c-1.66,0.27-2.79,1.29-3.77,2.55c-0.95,1.21-1.79,1.51-3.22,0.91c-0.87-0.37-1.63-0.98-2.45-1.47c-1.4-0.83-2.76-1.78-4.24-2.45
		c-5.46-2.47-10.96-4.82-16.43-7.26c-1.34-0.6-2.59-1.38-3.91-2.09c1.28-1.75,0.76-3.45-1.28-3.74c-2.84-0.42-5.13-1.59-6.98-3.77
		c-0.79-0.93-1.98-0.72-3.05-0.63c-1.02,0.09-1.98,0.28-2.33-1.15c-0.08-0.34-0.87-0.62-1.37-0.71c-0.7-0.13-1.43-0.04-2.15-0.08
		c-1.63-0.08-2.16-0.58-1.83-2.18c0.31-1.55-0.04-2.78-0.97-4.02c-1.11-1.48-1.74-1.74-3.44-1.03c-0.4,0.17-0.8,0.34-1.42,0.61
		c0.72-3.21-0.87-5.18-3.23-6.79c0.23-2.15-0.71-2.92-2.82-2.28c-0.15,0.05-0.31,0.08-0.46,0.13c-1.69,0.5-1.92,0.31-1.75-1.42
		c0.24-2.43-0.89-3.68-3.27-3.82c-0.89-0.05-1.78-0.39-2.63-0.69c-2.87-1.03-4.46-0.51-6.09,2.06c-0.21,0.34-0.42,0.68-0.66,1
		c-0.43,0.58-0.87,0.82-1.72,0.59c-0.8-0.22-1.75,0.13-2.63,0.13c-0.57-0.01-1.37-0.01-1.66-0.36c-1.31-1.57-2.92-1.61-4.69-1.22
		c-0.23,0.05-0.47,0.11-0.7,0.17c-2.56,0.68-2.53,0.68-2.69-1.87c-0.03-0.51-0.23-1.19-0.6-1.48c-1.44-1.12-2.95-2.16-4.49-3.14
		c-0.32-0.2-0.92-0.12-1.32,0.02c-0.81,0.29-1.55,0.79-2.37,1.05c-2.21,0.7-3.62,2.05-4.16,4.36c-0.16,0.7-0.55,1.44-1.06,1.95
		c-1.39,1.37-1.83,3.03-1.86,4.9c-0.02,1.21-0.16,2.42-0.27,3.87c-2.29-1.1-4.38-0.73-6.47-0.43c-0.42,0.06-0.88-0.03-1.31-0.1
		c-1.65-0.3-1.66-0.31-1.59-1.97c0.05-1.2-0.53-2.08-1.44-2.74c-1.03-0.75-2.12-1.42-3.2-2.11c-1.11-0.72-1.82-1.69-2.39-2.9
		c-0.55-1.16-1.37-2.22-2.24-3.18c-0.69-0.76-1.36-1.41-1.44-2.51c-0.04-0.49-0.32-0.98-0.57-1.42c-1.48-2.54-3.1-5.01-4.45-7.62
		c-1.4-2.71-3.58-4.02-6.51-4.14c-1.56-0.06-2.75-0.71-3.78-1.79c-0.61-0.64-1.26-1.23-1.83-1.9c-1.93-2.27-4.39-3.01-7.29-2.82
		c-1.03,0.07-2.1-0.29-3.15-0.44c-1.34-0.19-2.75-0.16-4-0.59c-1.42-0.49-2.81-1.24-3.99-2.17c-0.72-0.56-0.18-1.51,0.59-1.83
		c1.05-0.44,2.18-0.74,3.3-0.98c1.56-0.34,3.17-0.44,4.69-0.9c0.95-0.29,1.92-0.88,2.6-1.6c1.46-1.53,0.88-3.5-1.13-4.14
		c-0.6-0.19-1.26-0.2-1.89-0.28c-0.83-0.11-1.71-0.06-2.48-0.33c-2.15-0.75-4.25-1.56-6.56-1.75c-0.65-0.05-1.32-0.56-1.89-0.98
		c-2.36-1.71-3.62-2.06-6.51-1.7c-0.27-0.82-0.51-1.66-0.82-2.47c-0.61-1.6-1.65-2.87-2.96-3.99c-0.67-0.57-1.24-1.48-1.44-2.33
		c-0.52-2.25-1.69-3.92-3.69-5.04c-0.42-0.24-0.84-0.47-1.22-0.75c-1.03-0.77-1.44-1.74-1.05-3.04c0.21-0.72,0.31-1.48,0.34-2.23
		c0.06-1.31-0.55-2.29-1.68-2.96c-0.92-0.54-1.82-1.11-2.83-1.74c1.58-1.16,1-2.44,0.2-3.67c-0.51-0.78-0.63-1.29,0.2-2
		c1.17-1,1.27-2.39,0.37-3.67c-0.75-1.07-1.34-2.11-1.35-3.49c0-0.7-0.6-1.37-0.81-2.1c-0.1-0.34-0.06-0.94,0.17-1.13
		c0.73-0.63,1.46-1.24,1.1-2.3c-0.41-1.21-1.28-2.02-2.57-2.27c-0.67-0.13-1.35-0.16-2.02-0.25c-1.6-0.21-1.97-0.62-2.05-2.23
		c-0.01-0.24-0.06-0.48-0.03-0.72c0.31-2.13-0.7-3.41-2.61-4.14c-0.88-0.34-1.3-0.87-0.67-1.84c0.97-1.48,0.91-2.62-0.18-4.05
		c-1.12-1.46-2.19-2.96-3.42-4.32c-1.37-1.5-1.55-3.25-1.5-4.96c0.7-0.52,1.28-0.99,1.89-1.4c0.28-0.18,0.63-0.32,0.96-0.34
		c2.11-0.14,4.23-0.27,6.34-0.36c0.92-0.04,1.67-0.27,2.26-1.09c0.26-0.37,0.9-0.65,1.37-0.65c1.58,0,2.85-0.51,3.98-1.62
		c0.46-0.46,1.27-0.86,1.89-0.83c3,0.16,5.88-0.35,8.71-1.27c0.73-0.24,1.18-0.05,1.77,0.51c0.78,0.74,1.79,1.29,2.77,1.77
		c0.93,0.46,1.79,0.76,2.23,1.93c0.47,1.23,1.81,1.51,3.02,1.61c2.51,0.2,4.94-0.27,7.24-1.28c0.47-0.21,0.92-0.8,1.07-1.31
		c0.4-1.28,0.33-1.3,1.87-1.23c0,0.3-0.02,0.61,0,0.91c0.12,1.69,1.22,2.61,2.88,2.41c0.28-0.03,0.6-0.04,0.81-0.18
		c1.54-1.06,2.49,0.26,3.6,0.87c0.18,0.1,0.28,0.48,0.29,0.74c0.06,1.22,0.66,2.12,1.61,2.81c1.06,0.77,1.65,1.73,1.79,3.09
		c0.08,0.78,0.57,1.57,1.02,2.26c0.75,1.15,1.19,2.34,1.11,3.72c-0.01,0.24-0.01,0.48,0.02,0.72c0.23,2.49,0.99,3.1,3.43,2.55
		c1.19-0.27,2.42-0.63,3.45-1.25c1.75-1.04,2.08-2.76,1.4-4.63c-0.18-0.5,0.09-1.33,0.42-1.82c0.79-1.15,1.76-2.17,2.62-3.27
		c1.05-1.35,1.44-2.89,1.27-4.61c-0.22-2.18,0.17-2.7,2.3-3.12c1.17-0.23,1.81-0.97,2.17-1.98c0.33-0.93,0.5-1.92,0.76-2.97
		c0.31,0,0.66,0.02,1.01,0c1.53-0.1,2.2-0.85,2.16-2.41c-0.06-1.81-0.06-1.81,1.63-2.1c1.65-0.29,2.8-2,2.44-4.07
		c-0.25-1.45,0.21-2.4,1.31-3.19c0.13-0.09,0.25-0.2,0.37-0.3c2.07-1.68,2.22-2.35,0.98-4.84c0.59-0.15,1.13-0.38,1.68-0.42
		c4.14-0.29,6.86-2.47,8.5-6.21c0.13-0.29,0.8-0.48,1.22-0.48c0.66-0.01,1.33,0.12,1.58-0.69c0.2-0.67-0.08-1.09-0.73-1.43
		c-0.65-0.34-1.18-1.24-1.78-1.26c-0.59-0.02-1.14,0.97-1.81,1.17c-2.13,0.63-4.27,1.27-6.46,1.58c-2.08,0.3-4.23,0.1-6.34,0.29
		c-3.1,0.28-5.95-0.6-8.7-1.8c-2.48-1.08-4.84-2.45-7.29-3.63c-5.54-2.67-11.41-4.41-17.47-5.29c-5.93-0.86-11.89-1.46-17.86-2
		c-1.57-0.14-3.2,0.43-4.8,0.6c-0.67,0.07-1.5,0.2-2.02-0.11c-1.31-0.77-2.45-0.67-3.71,0.06c-1.82,1.07-2.38,0.69-2.64-1.43
		c-0.25-2.01,0.05-3.89,0.81-5.74c0.47-1.16,0.93-2.43-0.09-3.49c-1.01-1.04-2.3-0.62-3.51-0.3c-0.38,0.1-0.8,0.2-1.11,0.42
		c-1.12,0.78-2.17,0.94-3.41,0.17c-0.45-0.28-1.27-0.17-1.85,0.01c-1.38,0.43-2.58,0.11-3.56-0.81c-1.67-1.55-3.54-1.6-5.59-1.1
		c-0.78,0.19-1.63,0.13-2.45,0.18c0-0.12-0.01-0.25-0.01-0.37c0.62-0.11,1.25-0.22,1.87-0.34c1.14-0.21,1.96-0.78,2.39-1.93
		c0.15-0.41,0.6-0.85,1.01-1c2.76-0.96,3.56-2.71,2-5.27c-1-1.64-0.7-2.91,0.34-4.26c0.71-0.92,1.4-1.84,2.11-2.76
		c1.16-1.49,1.29-4.69,0.09-6.23c-0.98-1.26-1.44-2.59-1.46-4.19c-0.01-0.78-0.5-1.56-0.83-2.31c-0.16-0.38-0.45-0.7-0.68-1.05
		c0.07-0.13,0.14-0.26,0.21-0.39c0.58,0.19,1.2,0.31,1.74,0.59c1.22,0.63,2.37,1.23,3.6-0.01c0.16-0.16,0.67-0.06,0.98,0.04
		c0.57,0.18,1.09,0.5,1.67,0.65c1.79,0.47,2.73-0.19,2.95-1.81c1.28-0.34,2.46-0.7,3.67-0.95c0.24-0.05,0.59,0.23,0.83,0.44
		c0.35,0.3,0.58,0.84,0.97,0.98c1.37,0.47,2.78,0.83,4.18,1.19c0.59,0.15,0.84,0.39,0.81,1.07c-0.08,1.69,0.17,1.79,1.74,2.25
		c0.74,0.22,1.47,0.86,1.95,1.5c0.56,0.74,1.14,1.18,2.07,1.15c1.88-0.06,3.71-1.5,4.12-3.43c0.35-1.64,0.62-3.31,0.71-4.97
		c0.03-0.65-0.38-1.47-0.85-1.97c-0.98-1.04-1.55-2.09-1.57-3.59c-0.01-0.95-0.53-2.03-1.12-2.82c-1.8-2.39-3.56-4.87-5.73-6.9
		c-1.31-1.23-1.8-2.22-1.42-3.9c0.15-0.66-0.14-1.52-0.46-2.17c-0.42-0.84-0.43-1.59,0.19-2.21c1.58-1.56,1.85-3.45,1.62-5.52
		c-0.05-0.47,0.03-0.96-0.02-1.44c-0.11-1.07,0.33-1.8,1.34-2.12c1.76-0.56,2.51-1.72,2.6-3.56c0.03-0.66,0.78-1.29,1.11-1.8
		c0.68,1.1,1.21,1.99,1.78,2.85c0.46,0.7,1.01,1.34,1.44,2.05c1.26,2.09,2.96,3.43,5.46,3.88c4.28,0.77,8,2.88,11.56,5.3
		c0.98,0.67,2.09,1.16,3.17,1.67c1.09,0.52,1.6,1.3,1.62,2.54c0.02,1.19,0.2,2.38,0.41,3.56c0.28,1.59-0.66,3.59-1.89,4.63
		c-2.82,2.38-6.14,4.18-8.35,7.28c-0.92,1.29-2.09,2.4-2.93,3.72c-0.37,0.59-0.19,1.51-0.31,2.27c-0.05,0.31-0.17,0.64-0.36,0.88
		c-0.85,1.06-1.78,2.05-2.6,3.13c-0.38,0.5-0.66,1.1-0.84,1.7c-0.39,1.28,0.31,1.97,1.6,2.17c2.55,0.38,5.07,0.94,7.6,1.47
		c0.4,0.09,0.84,0.38,1.09,0.71c1,1.36,2.37,1.41,3.87,1.32c0.92-0.05,1.85,0.24,2.78,0.29c0.85,0.04,1.75,0.11,2.56-0.12
		c1.68-0.47,3.33-0.78,5.07-0.38c1.81,0.42,3.15-0.27,3.93-1.93c0.52-1.11,1.36-1.5,2.54-1.66c2.25-0.3,4.48-0.73,6.71-1.19
		c0.64-0.13,1.26-0.49,1.81-0.87c0.29-0.2,0.63-0.76,0.54-0.97c-0.54-1.26,0.41-1.81,1.13-2.31c4.55-3.1,9.05-6.35,14.43-7.9
		c2.2-0.64,3.43-1.89,3.71-4.14c0.05-0.42,0.21-0.84,0.4-1.55c0.44,0.63,0.77,1.03,1.03,1.49c1.39,2.47,1.73,2.64,4.88,2.1
		c-1.52,0.99-1.6,2.19-1.44,3.5c0.04,0.33-0.23,0.75-0.46,1.05c-1.04,1.37-1.08,2.42-0.06,3.52c0.97,1.05,2.16,1.11,3.56,0.19
		c0.16-0.11,0.32-0.22,0.47-0.32c2.98,2.94,3.02,2.94,4.85,0.33c1.23,0,2.33-0.03,3.43,0.01c0.76,0.03,1.52,0.23,2.28,0.23
		c0.47,0,1.08-0.1,1.36-0.4c0.61-0.65,1.14-0.68,1.89-0.29c2.36,1.22,4.18,0.27,5.1-2.24c1.03-2.8,0.02-5.21-1.42-7.31
		c-1.95-2.85-2.85-5.89-2.77-9.23c0.05-2.21,0.49-4.42,0.76-6.63c0.05-0.4,0.22-0.79,0.21-1.18c-0.07-1.69,0.51-2.97,1.94-3.99
		c0.45-0.32,0.79-1.5,0.56-1.97c-1.11-2.24-0.89-4.61-0.92-6.95c-0.05-3-0.39-5.89-2.09-8.48c-0.69-1.05-0.53-2.22-0.25-3.37
		c0.18-0.74,0.45-1.45,0.61-2.19c0.2-0.89-0.15-1.53-0.68-2.34c-1.06-1.61-2.58-2.63-3.92-3.89c-0.66-0.61-0.33-1.35,0.38-1.66
		c0.98-0.42,2.02-0.73,3.07-0.91c3.14-0.54,5.79-1.87,7.51-4.62C727.67,429.53,727.96,428.34,728.42,427.2z"/>
				</g>
				<g>
					<path d="M392.57,926.49c1.61-3.7,5.09-3.45,8.17-2.58c1.02,0.29,1.99,0.75,3.3,1.26c-0.2-1.09-0.22-1.95-0.53-2.68
		c-0.45-1.04-0.24-1.78,0.65-2.37c0.36-0.24,0.75-0.46,1.15-0.64c0.93-0.42,1.83-0.73,2.24-1.92c0.18-0.53,1.14-0.92,1.82-1.13
		c0.7-0.22,1.49-0.15,2.07-0.19c0.46-0.79,0.89-1.44,1.21-2.13c0.21-0.44,0.38-0.97,0.36-1.45c-0.07-1.91,0.36-2.5,2.32-2.85
		c-0.39-1.89,0.59-3.06,2.11-4c0.62-0.39,1.15-0.99,1.58-1.59c1.53-2.18,2.96-4.44,4.52-6.59c0.71-0.98,0.86-1.93,0.5-3.03
		c-0.27-0.83-0.56-1.67-0.74-2.52c-0.24-1.14,0.24-1.94,1.37-2.32c0.11-0.04,0.22-0.09,0.34-0.12c3.33-0.99,3.58-1.45,3.07-4.94
		c-0.11-0.78,0.16-1.61,0.24-2.42c0.12-1.06,0.27-2.12,0.33-3.18c0.07-1.23-0.13-2.62,1.23-3.25c1.43-0.66,2.5,0.3,3.52,1.16
		c2.27,1.91,4.23,4.09,5.78,6.64c0.23,0.37,0.93,0.56,1.44,0.63c1.31,0.17,2.61,0.22,3.64,1.26c0.28,0.29,0.89,0.31,1.35,0.35
		c0.84,0.06,1.68-0.01,2.52,0.06c0.54,0.05,1.31,0.08,1.57,0.43c1.04,1.39,2.51,1.06,3.88,1.26c1.44,0.21,2.85,0.6,3.46,2.19
		c0.05,0.14,0.16,0.33,0.29,0.36c1.53,0.42,2.72,1.3,3.69,2.53c0.06,0.07,0.21,0.1,0.33,0.1c4.47,0,8.93,0,13.5,0
		c-0.03-0.17-0.05-0.59-0.15-0.99c-0.32-1.21-0.3-2.32,0.39-3.45c0.21-0.34,0.02-1.22-0.3-1.54c-1.34-1.32-1.75-2.84-1.96-4.7
		c-0.23-2.07-0.39-4.31-1.84-6.09c-0.2-0.24-0.37-0.55-0.41-0.85c-0.3-2.27-1.66-3.87-3.12-5.52c-1.06-1.19-1.69-2.78-2.45-4.22
		c-0.96-1.84-1.79-3.75-2.81-5.55c-1.17-2.06-1.58-4.21-1.51-6.55c0.04-1.21-0.31-2.43-0.51-3.64c-0.13-0.82-0.32-1.64-0.44-2.46
		c-0.26-1.73-0.43-3.47-0.76-5.19c-0.22-1.16-0.06-2.07,0.82-2.93c0.44-0.42,0.67-1.1,0.87-1.71c0.25-0.78,0.75-0.83,1.47-0.85
		c2.17-0.05,4.33-0.2,6.5-0.31c2.64,0,5.28,0,7.92,0c0.46,0.08,0.91,0.22,1.37,0.23c5.35,0.06,10.71,0.1,16.06,0.13
		c3.43,0.02,6.87-0.01,10.3-0.02c6.99-0.01,13.98,0.01,20.97-0.03c2.49-0.02,4.97-0.2,7.46-0.31c4.32,0,8.64,0,12.96,0
		c2.28,0.8,3.12,1.88,3.05,4.46c-0.05,1.62,0.24,2.96,1.54,4.05c1.05,0.88,0.88,2.14,0.67,3.44c0.37,0.13,0.74,0.25,1.1,0.37
		c1.04,0.34,1.82,0.96,2.23,2.01c0.17,0.44,0.33,0.95,0.65,1.27c2.35,2.44,2.45,5.52,2.53,8.61c0.04,1.56,0.09,3.12,0.22,4.67
		c0.15,1.81,0.9,3.36,2.13,4.71c0.38,0.42,0.57,1.01,0.88,1.51c0.61,1,1.16,2.04,1.86,2.98c0.99,1.34,2.1,2.6,3.11,3.92
		c0.28,0.37,0.49,0.84,0.61,1.29c0.51,1.95,1.13,3.89,1.4,5.88c0.11,0.82-0.35,1.91-0.91,2.57c-0.92,1.1-0.86,2-0.16,3.12
		c1.31,2.11,0.97,3.06-1.28,4.24c-0.35,0.19-0.68,0.79-0.69,1.21c-0.02,2.45,0.86,3.25,3.32,2.82c0.96-0.17,1.96-0.69,2.73-1.31
		c2.12-1.7,4.39-2.05,6.84-1.01c0.77,0.33,1.39,1.03,2.05,1.58c0.55,0.45,1.01,1.03,1.6,1.39c2.02,1.23,2.28,2.4,0.74,4.41
		c0.37,0.25,0.74,0.7,1.08,0.68c0.28-0.02,0.64-0.55,0.78-0.92c0.93-2.47,2.46-2.98,4.46-1.2c1,0.89,1.61,2.19,2.55,3.51
		c2.42-0.17,5.2-0.37,7.99-0.55c1.04-0.07,2.08-0.15,3.11-0.12c1.95,0.05,3.21,1.84,1.91,3.46c-0.71,0.88-1.47,1.72-2.14,2.63
		c-0.29,0.39-0.53,0.89-0.6,1.37c-0.27,1.88-1.02,3.29-2.93,4.01c-0.54,0.2-0.97,0.96-1.25,1.56c-0.44,0.92-0.7,1.93-0.99,2.75
		c0.73,1.48,1.48,2.95,2.16,4.44c0.12,0.27,0,0.67-0.07,0.99c-0.18,0.83-0.72,1.73-0.55,2.47c0.46,1.92,0.93,3.94,1.93,5.61
		c1.75,2.93,2.58,5.97,2.52,9.34c-0.03,1.48,0.06,2.96,0.07,4.43c0.01,0.52-0.05,1.03-0.07,1.55c-0.07,1.39-0.2,2.77-0.2,4.16
		c0,1.66-0.44,2.5-2.04,2.95c-2.11,0.6-4.24,1.1-6.38,1.55c-1.34,0.28-1.5,0.43-1.27,1.78c0.36,2.03-0.07,3.97-1.42,5.46
		c-0.67,0.75-1.9,1.16-2.94,1.36c-1.25,0.25-2.23,0.67-2.66,1.9c-0.48,1.38-1.48,1.85-2.86,1.89c-1.6,0.05-3.19,0.16-4.78,0.32
		c-2.09,0.22-2.95,1.26-2.93,3.33c0,0.43,0.01,0.98-0.23,1.26c-1.27,1.51-0.74,3.17-0.25,4.66c0.43,1.3,0.54,2.3-0.66,3.21
		c0.67,0.82,1.43,1.52,1.89,2.38c0.36,0.67,0.43,1.54,0.46,2.32c0.04,0.83-0.14,1.66-0.18,2.49c-0.07,1.41-0.53,2.59-1.82,3.3
		c-1.06,0.59-1.84,0.3-2.56-0.66c-0.33-0.44-0.94-0.75-1.49-0.93c-0.55-0.18-1.21-0.03-1.78-0.19c-0.59-0.16-1.37-0.36-1.66-0.8
		c-0.76-1.18-1.83-1.29-3.02-1.27c-1.88,0.03-3.73,0.08-5.35-1.24c-0.81-0.66-2.88-0.26-3.56,0.56c-0.29,0.35-0.47,0.96-0.37,1.38
		c0.06,0.29,0.69,0.63,1.07,0.63c1.89,0,3.7,0.14,5.52,0.84c1.03,0.4,2.29,0.26,3.44,0.31c1.48,0.05,2.52,0.65,2.94,2.15
		c0.31,1.12,1.18,1.63,2.18,2.05c1.06,0.45,2.16,0.87,3.11,1.49c1.39,0.91,1.59,1.71,1.09,3.39c1.47,0.26,2.71,0.82,3.07,2.51
		c0.39,1.82-0.14,2.83-1.91,3.37c-1.44,0.44-1.37,0.41-0.91,1.88c0.44,1.4,1.02,2.58,2.31,3.47c0.61,0.42,0.85,1.48,1.08,2.3
		c0.22,0.8,0.27,1.66,0.26,2.5c-0.02,1.28,0.28,2.33,0.91,3.54c0.81,1.56,0.82,3.55,1.11,5.36c0.05,0.31-0.3,0.67-0.31,1.01
		c-0.03,0.68-0.15,1.46,0.13,2.02c0.96,1.97,0.42,3.76-1.52,4.68c-0.37,0.18-0.77,0.59-0.87,0.97c-0.37,1.38-1.18,2.23-2.57,2.53
		c-2.06,0.44-2.92,2.25-4.14,3.64c-0.8,0.91-1.25,2.12-2.57,2.58c-0.33,0.12-0.56,0.85-0.63,1.33c-0.32,2.15-1.34,3.46-3.63,3.8
		c-0.86,0.13-1.65,0.84-2.42,1.37c-0.92,0.63-1.75,1.4-2.67,2.02c-1.88,1.26-3.81,2.46-5.71,3.68c-0.72,0-1.44,0-2.16,0
		c-1.81-0.1-2.44-1.35-2.85-2.86c-0.36-1.34-0.75-2.68-1.17-4.16c-0.32,0.27-0.65,0.53-0.96,0.8c-1.4,1.22-1.79,1.25-3.23,0
		c-2.53-2.2-5.04-4.43-7.56-6.65c-1.5-1.32-1.79-1.27-3.39-0.15c-1.44,1-2.94,1.95-4.51,2.72c-3.42,1.68-6.23,0.8-8.15-2.49
		c-0.47-0.81-0.93-1.18-1.92-1.17c-2.04,0.03-3.63-1.02-4.68-2.69c-1.09-1.75-2.6-2.97-4.44-3.68c-4.66-1.8-7.99-5.16-10.99-8.98
		c-1.65-2.1-3.41-4.12-5.2-6.1c-2.18-2.42-2.77-4.26-2.29-7.14c-1-0.84-2.18-1.41-2.67-2.34c-0.52-0.98-0.43-2.31-0.51-3.49
		c-0.07-1.11,0.18-2.27-0.06-3.34c-0.3-1.36-0.75-2.78-1.5-3.94c-0.73-1.12-1.29-2.23-1.3-3.52c-0.06-6.71-0.28-13.42-0.02-20.12
		c0.23-5.89,3.04-10.58,8.01-13.89c0.23-0.15,0.47-0.3,0.71-0.45c2.65-1.68,2.91-2.5,1.48-5.28c-1.57-3.07-0.78-5.87,1.82-7.38
		c1.16-0.67,1.66-1.8,1.61-3.15c-0.02-0.44-0.05-0.88-0.12-1.31c-0.41-2.66-1.78-3.58-4.44-2.96c-0.21,0.05-0.44,0.3-0.54,0.52
		c-1.2,2.7-3.43,3.71-6.2,3.61c-5.63-0.19-11.25-0.5-16.88-0.7c-6.75-0.24-13.5-0.44-20.24-0.64c-9.98-0.3-19.97-0.6-29.95-0.9
		c-5.19-0.16-10.38-0.3-15.57-0.48c-0.39-0.01-0.84-0.26-1.15-0.52c-0.59-0.5-1.25-1.02-1.6-1.68c-0.55-1.03-0.76-2.24-1.33-3.26
		c-0.54-0.97-1.48-1.71-2.02-2.68c-0.55-0.98-0.22-2.02,0.46-2.91c0.26-0.34,0.51-0.67,0.87-1.14c-0.41-0.12-0.66-0.28-0.89-0.25
		c-2.03,0.21-2.98-1-3.57-2.68c-0.17-0.48-0.33-0.97-0.49-1.45C392.57,927.61,392.57,927.05,392.57,926.49z M548.25,1045.4
		c-0.36,2.94-0.22,5.5,0.74,7.97c0.52,1.34,1.99,1.81,3.21,1.01c1.13-0.75,2.32-1.39,3.43-2.16c1.21-0.84,2.33-1.8,3.54-2.64
		c0.86-0.6,1.76-1.44,2.72-1.55c1.77-0.21,2.45-1.08,2.78-2.69c0.16-0.78,0.75-1.52,1.26-2.18c0.5-0.64,1.21-1.11,1.73-1.73
		c1.62-1.95,2.96-4.18,5.69-4.9c0.56-0.15,1.07-1.01,1.32-1.66c0.33-0.85,0.66-1.48,1.63-1.69c0.93-0.2,1.12-0.86,0.62-1.63
		c-0.8-1.23-0.68-2.48-0.28-3.79c0.13-0.42,0.2-0.98,0.02-1.34c-0.83-1.68-0.36-3.8-1.79-5.29c-0.2-0.21-0.05-0.77-0.06-1.16
		c-0.04-1.31-0.01-2.63-0.17-3.93c-0.05-0.41-0.58-0.77-0.91-1.13c-0.55-0.61-1.29-1.12-1.63-1.82c-0.5-1.03-0.75-2.18-1.03-3.3
		c-0.47-1.84,0.21-2.9,2.07-3.35c0.36-0.09,0.72-0.2,1.08-0.31c0.05-0.13,0.1-0.25,0.15-0.38c-0.44-0.37-0.81-0.9-1.32-1.07
		c-2.16-0.76-2.57-1.41-1.97-3.78c-0.87-0.38-1.75-0.87-2.68-1.15c-1.65-0.5-2.84-1.5-3.45-3.07c-0.36-0.92-0.93-1.15-1.8-1.19
		c-1.15-0.05-2.41,0.09-3.43-0.31c-1.7-0.68-3.4-0.74-5.16-0.73c-2.01,0.01-3.31-1.58-2.98-3.57c0.47-2.84,4.26-4.73,6.89-3.25
		c1.44,0.81,2.86,1.03,4.43,1.09c1.78,0.07,3.65,0.06,4.79,1.9c0.16,0.26,0.71,0.37,1.08,0.38c1.56,0.04,3.09,0.12,4.33,1.41
		c0.01-2.14,1.1-4.28-1.02-6.17c-1.77-1.57-1.64-2-0.33-3.64c-0.15-1.34-0.2-2.49-0.41-3.61c-0.24-1.24-0.25-2.37,0.67-3.37
		c0.14-0.15,0.14-0.45,0.15-0.68c0.11-2.9,1.83-4.91,4.68-5.3c1.5-0.2,3.02-0.27,4.54-0.29c1.07-0.01,1.53-0.53,2.04-1.42
		c0.43-0.76,1.32-1.32,2.12-1.79c0.56-0.33,1.3-0.36,1.96-0.5c1.03-0.21,1.83-0.72,2.01-1.79c0.17-1,0.32-2.05,0.17-3.03
		c-0.34-2.12,0.17-3.07,2.25-3.6c0.35-0.09,0.71-0.13,1.06-0.22c1.93-0.49,3.87-0.96,5.78-1.52c0.3-0.09,0.69-0.58,0.7-0.9
		c0.11-3.54,0.21-7.08,0.21-10.62c0-2.8-0.79-5.35-2.27-7.82c-1.51-2.52-2.59-5.32-2.03-8.42c0.15-0.81-0.13-1.3-0.76-1.82
		c-2.21-1.84-4.36-3.76-6.54-5.64c-0.63-0.55-1.14-1.16-0.38-1.9c0.66-0.65,1.28-0.26,1.87,0.29c1.13,1.06,2.29,2.08,3.57,3.22
		c0.61-1.44,1.24-2.69,1.65-4c0.36-1.12,0.94-1.95,2.05-2.31c1.35-0.44,2.03-1.35,2.11-2.73c0.07-1.16,0.6-1.99,1.52-2.71
		c0.61-0.48,1-1.24,1.66-2.11c-0.93,0-1.49-0.03-2.04,0.01c-3.11,0.22-6.21,0.48-9.32,0.68c-1.69,0.11-2.12-0.3-2.64-1.91
		c-0.35-1.07-0.8-2.15-2.03-2.58c-1.51,2.93-2.82,3.84-4.33,3.08c-1.84-0.92-2.33-2.92-1.28-4.84c-0.87-0.8-1.76-1.51-2.52-2.34
		c-1.56-1.71-4.03-2.08-5.64-0.45c-2.04,2.06-4.44,2.16-6.99,1.84c-2.08-0.26-2.65-0.99-2.72-3.08c-0.01-0.4-0.04-0.8-0.08-1.2
		c-0.2-2.43,0.08-2.9,2.3-3.98c0.14-0.07,0.29-0.13,0.42-0.18c-0.19-0.33-0.25-0.47-0.34-0.6c-1.63-2.29-1.61-3.52,0.12-5.63
		c0.55-0.67,0.57-1.31,0.29-2.11c-0.33-0.95-0.49-1.96-0.72-2.94c-0.08-0.35-0.08-0.73-0.22-1.05c-0.82-1.74-1.63-3.48-3.23-4.68
		c-1.22-0.92-2.06-2.09-2.37-3.63c-0.07-0.33-0.3-0.64-0.53-0.9c-1.56-1.79-2.59-3.82-2.76-6.22c-0.1-1.35-0.14-2.71-0.17-4.07
		c-0.07-3.01-0.04-6.03-2.38-8.39c-0.03-0.03-0.04-0.07-0.05-0.11c-0.48-1.4-1.42-2.19-2.9-2.53c-1.25-0.28-1.66-1.01-1.25-2.12
		c0.46-1.24,0.06-2.08-0.89-2.81c-0.93-0.72-1.26-1.7-1.26-2.85c0-0.76-0.04-1.52-0.08-2.27c-0.05-1.04-0.54-1.61-1.65-1.6
		c-1.52,0.02-3.03,0-4.55-0.03c-4.43-0.08-8.87-0.23-13.26,0.6c-0.26,0.05-0.55,0.01-0.83-0.04c-0.69-0.12-1.39-0.37-2.08-0.37
		c-10.71,0-21.43,0.06-32.14,0.05c-5.16,0-10.32-0.12-15.47-0.14c-3.96-0.02-7.91,0.01-11.87,0.04c-1.21,0.01-3.13,1.3-3.49,2.35
		c-0.07,0.2-0.01,0.46,0.02,0.7c0.2,1.53,0.4,3.07,0.61,4.6c0.1,0.75,0.07,1.55,0.35,2.23c0.73,1.84,0.89,3.72,0.71,5.65
		c-0.15,1.62,0.32,3.04,1.1,4.45c1.17,2.13,2.16,4.36,3.32,6.49c0.7,1.29,1.46,2.56,2.38,3.69c1.31,1.63,2.73,3.15,2.94,5.37
		c0.03,0.3,0.21,0.61,0.4,0.86c1.6,2.06,1.75,4.58,2.08,6.98c0.18,1.33,0.38,2.49,1.43,3.39c0.9,0.77,1.47,2,0.95,2.89
		c-0.96,1.67-0.42,3.27-0.42,4.91c0,0.28-0.06,0.55-0.12,0.83c-0.19,0.86-0.69,1.31-1.61,1.27c-1.08-0.04-2.16-0.05-3.24-0.07
		c-3.79-0.06-7.57-0.09-11.36-0.22c-0.5-0.02-1.01-0.55-1.47-0.91c-0.74-0.57-1.35-1.46-2.17-1.72c-1.04-0.33-1.81-0.66-2.17-1.75
		c-0.1-0.3-0.58-0.59-0.92-0.66c-0.89-0.19-1.81-0.21-2.71-0.37c-0.61-0.11-1.38-0.17-1.75-0.56c-0.84-0.9-1.78-1.03-2.91-1.06
		c-1.1-0.03-2.38-0.01-3.24-0.55c-1.08-0.67-2.1-1.08-3.33-1.09c-1.38-0.01-2.25-0.71-2.97-1.87c-0.84-1.35-1.79-2.66-2.86-3.83
		c-0.91-1-2.06-1.79-3.27-2.82c-0.07,1.59,0.19,3.02-0.27,4.17c-0.67,1.65-0.4,3.18-0.26,4.8c0.15,1.74-0.47,3.16-2.09,3.98
		c-0.88,0.44-1.83,0.75-2.84,1.16c0.13,0.34,0.26,0.7,0.4,1.06c0.76,1.97,0.88,3.86-0.48,5.67c-0.98,1.3-1.78,2.74-2.78,4.02
		c-1.32,1.68-2.02,3.86-4.03,4.98c-0.97,0.54-1.42,1.27-1.15,2.47c0.21,0.95-0.28,1.61-1.34,1.73c-0.3,0.03-0.6,0.13-0.91,0.2
		c-0.02,0.22-0.07,0.39-0.04,0.53c0.5,1.97-1.36,3.17-1.63,4.9c-0.04,0.27-0.58,0.58-0.94,0.66c-0.65,0.15-1.34,0.19-2.01,0.19
		c-0.77-0.01-1.2,0.28-1.59,0.98c-0.33,0.62-0.94,1.13-1.53,1.55c-0.5,0.36-1.14,0.52-1.56,0.7c0.08,1.13,0.22,2.11,0.21,3.09
		c-0.04,2.36-1.05,3.06-3.34,2.35c-1.33-0.42-2.61-1.05-3.97-1.32c-0.92-0.18-2-0.1-2.89,0.2c-1.41,0.46-0.97,1.73-0.82,2.77
		c0.13,0.94,0.65,1.5,1.71,1.44c1.18-0.06,2.12,0.38,2.61,1.52c0.48,1.12,0.24,2.04-0.65,2.92c-0.89,0.87-0.66,2.14,0.46,2.63
		c0.99,0.43,1.15,1.12,1.38,2.11c0.27,1.16,1.06,2.25,1.82,3.22c0.26,0.33,1.09,0.28,1.66,0.29c8.03,0.25,16.06,0.48,24.09,0.72
		c9.91,0.3,19.81,0.58,29.72,0.9c5.95,0.19,11.9,0.42,17.85,0.64c3.19,0.12,6.39,0.32,9.58,0.38c2.36,0.04,3.6-0.98,4.29-3.24
		c0.13-0.44,0.27-0.88,0.37-1.19c1.48,0.12,2.82,0.21,4.15,0.33c2.03,0.18,3.38,1.3,3.77,3.26c0.21,1.05,0.3,2.14,0.33,3.21
		c0.06,1.9-0.77,3.39-2.3,4.5c-2.16,1.57-2.42,2.69-1.2,5.08c1.74,3.4,1.19,5.53-2.14,7.56c-5.47,3.34-8.05,8.27-8.1,14.58
		c-0.02,3.19,0.17,6.39,0.21,9.58c0.03,2.76-0.09,5.51-0.03,8.27c0.02,0.8,0.19,1.77,0.67,2.34c1.85,2.21,2.32,4.76,2.22,7.51
		c-0.04,1.04-0.13,2.08-0.03,3.11c0.13,1.35,0.64,1.73,1.98,1.64c0.8-0.05,1.6-0.18,2.54-0.29c-0.17,0.38-0.26,0.6-0.36,0.81
		c-1.84,3.81-1.55,5.34,1.36,8.34c1.3,1.34,2.56,2.76,3.67,4.26c3.2,4.34,6.78,8.16,11.99,10.14c1.82,0.69,3.27,1.97,4.32,3.67
		c1.14,1.85,2.79,2.55,4.92,2.12c0.27-0.05,0.55-0.04,0.81-0.05c0.45,0.85,0.83,1.69,1.32,2.46c1.39,2.18,3.01,2.7,5.31,1.55
		c1.56-0.78,3.06-1.73,4.48-2.76c1.94-1.41,3.59-1.44,5.41,0.14c0.91,0.79,1.77,1.62,2.67,2.41c1.82,1.59,3.65,3.17,5.49,4.77
		C544.99,1048.21,546.47,1046.93,548.25,1045.4z"/>
					<path class="caceres" d="M548.25,1045.4c-1.77,1.53-3.25,2.81-4.84,4.18c-1.84-1.6-3.67-3.18-5.49-4.77c-0.9-0.79-1.76-1.63-2.67-2.41
		c-1.83-1.59-3.48-1.55-5.41-0.14c-1.41,1.03-2.91,1.98-4.48,2.76c-2.31,1.16-3.92,0.63-5.31-1.55c-0.49-0.77-0.87-1.61-1.32-2.46
		c-0.26,0.02-0.54,0-0.81,0.05c-2.13,0.43-3.78-0.27-4.92-2.12c-1.04-1.7-2.5-2.98-4.32-3.67c-5.21-1.98-8.79-5.81-11.99-10.14
		c-1.11-1.5-2.37-2.92-3.67-4.26c-2.91-3-3.2-4.52-1.36-8.34c0.1-0.21,0.19-0.43,0.36-0.81c-0.94,0.11-1.73,0.24-2.54,0.29
		c-1.34,0.09-1.86-0.29-1.98-1.64c-0.1-1.03,0-2.07,0.03-3.11c0.1-2.75-0.37-5.3-2.22-7.51c-0.48-0.57-0.65-1.55-0.67-2.34
		c-0.06-2.75,0.06-5.51,0.03-8.27c-0.03-3.19-0.23-6.39-0.21-9.58c0.05-6.31,2.63-11.24,8.1-14.58c3.32-2.03,3.88-4.16,2.14-7.56
		c-1.23-2.39-0.96-3.51,1.2-5.08c1.53-1.11,2.35-2.6,2.3-4.5c-0.03-1.07-0.12-2.16-0.33-3.21c-0.39-1.96-1.74-3.08-3.77-3.26
		c-1.34-0.12-2.68-0.21-4.15-0.33c-0.09,0.31-0.23,0.75-0.37,1.19c-0.69,2.25-1.93,3.27-4.29,3.24c-3.2-0.05-6.39-0.26-9.58-0.38
		c-5.95-0.22-11.9-0.45-17.85-0.64c-9.91-0.31-19.81-0.6-29.72-0.9c-8.03-0.24-16.06-0.47-24.09-0.72
		c-0.57-0.02-1.41,0.04-1.66-0.29c-0.76-0.97-1.55-2.06-1.82-3.22c-0.23-0.99-0.39-1.67-1.38-2.11c-1.12-0.49-1.35-1.76-0.46-2.63
		c0.89-0.87,1.13-1.8,0.65-2.92c-0.49-1.14-1.43-1.59-2.61-1.52c-1.06,0.06-1.58-0.49-1.71-1.44c-0.15-1.04-0.58-2.31,0.82-2.77
		c0.9-0.3,1.97-0.38,2.89-0.2c1.36,0.27,2.64,0.9,3.97,1.32c2.28,0.72,3.3,0.01,3.34-2.35c0.02-0.98-0.12-1.96-0.21-3.09
		c0.42-0.18,1.06-0.34,1.56-0.7c0.59-0.42,1.19-0.94,1.53-1.55c0.38-0.71,0.81-0.99,1.59-0.98c0.67,0.01,1.36-0.04,2.01-0.19
		c0.36-0.08,0.9-0.39,0.94-0.66c0.27-1.73,2.13-2.93,1.63-4.9c-0.04-0.14,0.02-0.31,0.04-0.53c0.31-0.07,0.61-0.17,0.91-0.2
		c1.05-0.12,1.55-0.79,1.34-1.73c-0.27-1.2,0.17-1.93,1.15-2.47c2.02-1.12,2.71-3.3,4.03-4.98c1-1.28,1.8-2.71,2.78-4.02
		c1.36-1.81,1.24-3.7,0.48-5.67c-0.14-0.36-0.27-0.72-0.4-1.06c1.02-0.41,1.96-0.72,2.84-1.16c1.61-0.82,2.24-2.24,2.09-3.98
		c-0.14-1.61-0.41-3.14,0.26-4.8c0.46-1.14,0.2-2.58,0.27-4.17c1.22,1.03,2.36,1.82,3.27,2.82c1.07,1.18,2.02,2.48,2.86,3.83
		c0.72,1.16,1.6,1.86,2.97,1.87c1.23,0.01,2.25,0.42,3.33,1.09c0.86,0.54,2.14,0.52,3.24,0.55c1.13,0.03,2.07,0.16,2.91,1.06
		c0.37,0.39,1.14,0.45,1.75,0.56c0.9,0.16,1.82,0.19,2.71,0.37c0.35,0.07,0.82,0.36,0.92,0.66c0.37,1.09,1.13,1.43,2.17,1.75
		c0.82,0.26,1.43,1.15,2.17,1.72c0.46,0.36,0.97,0.89,1.47,0.91c3.78,0.14,7.57,0.16,11.36,0.22c1.08,0.02,2.16,0.02,3.24,0.07
		c0.92,0.04,1.42-0.41,1.61-1.27c0.06-0.27,0.12-0.55,0.12-0.83c0-1.64-0.54-3.25,0.42-4.91c0.51-0.89-0.05-2.12-0.95-2.89
		c-1.06-0.9-1.25-2.06-1.43-3.39c-0.33-2.41-0.48-4.92-2.08-6.98c-0.19-0.24-0.37-0.56-0.4-0.86c-0.21-2.22-1.63-3.74-2.94-5.37
		c-0.91-1.14-1.68-2.41-2.38-3.69c-1.16-2.14-2.14-4.37-3.32-6.49c-0.78-1.41-1.25-2.83-1.1-4.45c0.18-1.93,0.02-3.82-0.71-5.65
		c-0.27-0.68-0.25-1.48-0.35-2.23c-0.21-1.53-0.41-3.07-0.61-4.6c-0.03-0.23-0.1-0.49-0.02-0.7c0.37-1.04,2.28-2.34,3.49-2.35
		c3.96-0.03,7.91-0.06,11.87-0.04c5.16,0.03,10.32,0.14,15.47,0.14c10.71,0,21.43-0.05,32.14-0.05c0.69,0,1.38,0.26,2.08,0.37
		c0.27,0.05,0.56,0.09,0.83,0.04c4.39-0.83,8.83-0.68,13.26-0.6c1.52,0.03,3.03,0.05,4.55,0.03c1.11-0.01,1.6,0.56,1.65,1.6
		c0.04,0.76,0.08,1.51,0.08,2.27c0,1.14,0.33,2.12,1.26,2.85c0.95,0.73,1.35,1.57,0.89,2.81c-0.41,1.11,0,1.84,1.25,2.12
		c1.48,0.33,2.42,1.13,2.9,2.53c0.01,0.04,0.02,0.08,0.05,0.11c2.34,2.36,2.31,5.39,2.38,8.39c0.03,1.36,0.07,2.72,0.17,4.07
		c0.17,2.4,1.2,4.42,2.76,6.22c0.23,0.26,0.46,0.58,0.53,0.9c0.31,1.54,1.15,2.71,2.37,3.63c1.6,1.21,2.42,2.95,3.23,4.68
		c0.15,0.32,0.14,0.7,0.22,1.05c0.23,0.98,0.39,1.99,0.72,2.94c0.28,0.8,0.25,1.44-0.29,2.11c-1.74,2.11-1.75,3.34-0.12,5.63
		c0.09,0.13,0.16,0.27,0.34,0.6c-0.13,0.06-0.28,0.11-0.42,0.18c-2.23,1.08-2.5,1.56-2.3,3.98c0.03,0.4,0.06,0.8,0.08,1.2
		c0.06,2.09,0.64,2.82,2.72,3.08c2.55,0.32,4.96,0.22,6.99-1.84c1.61-1.64,4.09-1.27,5.64,0.45c0.76,0.84,1.65,1.55,2.52,2.34
		c-1.04,1.93-0.56,3.92,1.28,4.84c1.51,0.76,2.82-0.15,4.33-3.08c1.23,0.43,1.68,1.5,2.03,2.58c0.53,1.62,0.96,2.02,2.64,1.91
		c3.11-0.2,6.22-0.46,9.32-0.68c0.55-0.04,1.1-0.01,2.04-0.01c-0.67,0.87-1.05,1.63-1.66,2.11c-0.92,0.72-1.45,1.56-1.52,2.71
		c-0.08,1.38-0.76,2.3-2.11,2.73c-1.11,0.36-1.69,1.19-2.05,2.31c-0.42,1.31-1.05,2.56-1.65,4c-1.27-1.14-2.44-2.16-3.57-3.22
		c-0.58-0.55-1.21-0.94-1.87-0.29c-0.75,0.74-0.25,1.35,0.38,1.9c2.18,1.88,4.32,3.8,6.54,5.64c0.63,0.52,0.9,1.02,0.76,1.82
		c-0.56,3.1,0.52,5.9,2.03,8.42c1.47,2.47,2.26,5.01,2.27,7.82c0,3.54-0.1,7.08-0.21,10.62c-0.01,0.32-0.39,0.81-0.7,0.9
		c-1.91,0.56-3.85,1.02-5.78,1.52c-0.35,0.09-0.71,0.13-1.06,0.22c-2.09,0.53-2.6,1.47-2.25,3.6c0.16,0.98,0,2.03-0.17,3.03
		c-0.18,1.07-0.98,1.58-2.01,1.79c-0.66,0.13-1.4,0.17-1.96,0.5c-0.79,0.46-1.68,1.03-2.12,1.79c-0.51,0.89-0.97,1.41-2.04,1.42
		c-1.52,0.02-3.04,0.09-4.54,0.29c-2.85,0.39-4.57,2.4-4.68,5.3c-0.01,0.23-0.01,0.53-0.15,0.68c-0.92,1-0.9,2.13-0.67,3.37
		c0.21,1.12,0.27,2.27,0.41,3.61c-1.31,1.64-1.45,2.07,0.33,3.64c2.13,1.89,1.04,4.03,1.02,6.17c-1.24-1.29-2.77-1.37-4.33-1.41
		c-0.37-0.01-0.92-0.12-1.08-0.38c-1.14-1.83-3.01-1.82-4.79-1.9c-1.57-0.06-2.99-0.28-4.43-1.09c-2.63-1.49-6.42,0.41-6.89,3.25
		c-0.33,1.99,0.97,3.58,2.98,3.57c1.76-0.01,3.46,0.05,5.16,0.73c1.02,0.41,2.28,0.27,3.43,0.31c0.86,0.04,1.44,0.26,1.8,1.19
		c0.61,1.57,1.79,2.58,3.45,3.07c0.94,0.28,1.81,0.77,2.68,1.15c-0.6,2.36-0.19,3.02,1.97,3.78c0.5,0.18,0.88,0.7,1.32,1.07
		c-0.05,0.13-0.1,0.25-0.15,0.38c-0.36,0.1-0.71,0.22-1.08,0.31c-1.87,0.45-2.54,1.51-2.07,3.35c0.29,1.12,0.54,2.27,1.03,3.3
		c0.34,0.7,1.08,1.21,1.63,1.82c0.33,0.36,0.86,0.72,0.91,1.13c0.15,1.3,0.13,2.62,0.17,3.93c0.01,0.4-0.15,0.95,0.06,1.16
		c1.43,1.5,0.96,3.61,1.79,5.29c0.18,0.36,0.1,0.92-0.02,1.34c-0.39,1.31-0.52,2.56,0.28,3.79c0.5,0.76,0.32,1.43-0.62,1.63
		c-0.97,0.21-1.31,0.84-1.63,1.69c-0.25,0.64-0.76,1.51-1.32,1.66c-2.74,0.72-4.07,2.95-5.69,4.9c-0.52,0.62-1.23,1.1-1.73,1.73
		c-0.51,0.66-1.1,1.4-1.26,2.18c-0.34,1.61-1.01,2.48-2.78,2.69c-0.96,0.11-1.85,0.95-2.72,1.55c-1.21,0.84-2.33,1.8-3.54,2.64
		c-1.11,0.77-2.31,1.41-3.43,2.16c-1.21,0.8-2.69,0.33-3.21-1.01C548.03,1050.89,547.88,1048.33,548.25,1045.4z"/>
				</g>
				<g>
					<path d="M477.14,887.79c-0.84,0.95-1.16,1.96-0.72,3.26c0.23,0.67,0.07,1.51-0.03,2.25c-0.13,0.93-0.76,1.36-1.7,1.32
		c-3.99-0.15-7.99-0.28-11.98-0.44c-1.46-0.06-3.75-1.01-4.87-1.89c-0.25-0.2-0.52-0.43-0.81-0.49c-1.24-0.28-2.14-0.78-2.86-2.02
		c-0.36-0.63-1.84-0.79-2.83-0.82c-1.15-0.03-2.05-0.26-2.92-1.06c-0.46-0.42-1.29-0.58-1.96-0.61c-1.63-0.07-3.23,0.03-4.63-1.18
		c-0.5-0.43-1.44-0.44-2.18-0.47c-1.42-0.04-2.4-0.63-3.08-1.89c-1.35-2.5-3.42-4.4-5.54-6.22c-0.14-0.12-0.34-0.18-0.69-0.36
		c0,0.91,0.07,1.7-0.02,2.46c-0.1,0.9-0.37,1.77-0.5,2.67c-0.1,0.7-0.21,1.42-0.12,2.11c0.55,4.11-0.26,5.38-4.26,6.54
		c-0.11,0.03-0.21,0.1-0.45,0.22c0.11,0.35,0.21,0.71,0.35,1.07c0.76,1.96,0.88,3.83-0.44,5.68c-1.64,2.3-3.12,4.71-4.76,7.01
		c-0.54,0.76-1.29,1.43-2.07,1.96c-0.88,0.61-1.37,1.27-1.17,2.38c0.2,1.11-0.27,1.79-1.43,1.88c-0.67,0.05-0.92,0.33-0.79,1
		c0.2,0.98-0.11,1.8-0.84,2.49c-0.18,0.17-0.3,0.46-0.35,0.72c-0.29,1.6-0.85,2.24-2.4,2.25c-1.37,0.01-2.37,0.15-3.02,1.65
		c-0.3,0.69-1.56,0.96-2.39,1.42c-0.14,0.08-0.27,0.16-0.32,0.19c0.08,1.12,0.22,2.17,0.21,3.22c-0.01,2.08-1.02,2.85-3.03,2.28
		c-1.23-0.35-2.38-0.98-3.62-1.24c-1.05-0.22-2.22-0.29-3.27-0.09c-1.17,0.22-1.44,1.19-1.21,2.35c0.22,1.09,0.41,2.03,1.87,1.97
		c1.15-0.04,2.1,0.43,2.58,1.58c0.48,1.15,0.16,2.05-0.71,2.91c-0.87,0.86-0.6,2.12,0.54,2.63c0.93,0.41,1.25,1.01,1.3,2.04
		c0.03,0.71,0.48,1.49,0.95,2.06c0.6,0.73,1.18,1.49,0.72,2.35c-0.41,0.77-1.24,1.08-2.26,1.04c-12.06-0.38-24.13-0.7-36.19-1.03
		c-6.55-0.18-13.1-0.38-19.65-0.55c-1.46-0.04-2.75-0.45-3.85-1.42c-0.81-0.71-1.29-1.54-1.38-2.68
		c-1.01-11.99-2.07-23.97-3.08-35.96c-0.66-7.81-1.26-15.62-1.91-23.42c-0.24-2.84-1.39-5.27-3.31-7.4
		c-8.59-9.53-17.13-19.11-25.68-28.67c-0.13-0.15-0.25-0.3-0.55-0.65c9.56-0.62,18.92-1.04,28.51-0.76
		c-0.7-12.15-1.39-24.18-2.06-35.9c-0.54-0.52-0.94-0.84-1.24-1.23c-4.78-6.27-7.39-13.55-9.84-20.92c-0.38-1.14-1.06-2.2-1.72-3.23
		c-0.62-0.97-1.44-1.82-2.07-2.79c-1.18-1.79-1.52-3.74-0.94-5.84c0.11-0.41,0.14-1.05-0.09-1.31c-1.95-2.12-1.9-4.67-1.9-7.32
		c-0.01-3.08,0.98-5.55,3.24-7.64c1.11-1.02,2.02-2.27,2.96-3.46c1.18-1.48,1.08-2.04-0.08-3.58c-1.41-1.88-2.79-3.8-4.03-5.8
		c-1.35-2.18-2.06-4.56-1.69-7.21c0.05-0.37-0.41-1.01-0.8-1.2c-2.1-1.07-4.29-1.96-6.36-3.07c-1.18-0.63-2.28-1.46-3.27-2.37
		c-1-0.92-1.44-2.18-1.48-3.56c-0.01-0.46-0.15-0.92-0.23-1.38c0-2.32,0-4.64,0-6.96c-0.13-1.74,1.27-2.38,2.35-3.22
		c2.32-1.83,4.64-3.68,7.02-5.42c0.67-0.49,1.56-0.89,2.38-0.97c4.71-0.42,7.54-2.96,8.66-7.47c1.08-4.33,1.88-8.74,4.05-12.73
		c1.41-2.59,2.68-5.27,3.94-7.94c0.27-0.57,0.42-1.32,0.31-1.92c-0.47-2.5,0.31-4.54,1.95-6.39c1.48-1.67,2.9-3.4,4.27-5.15
		c1.29-1.65,2.58-3.02,4.95-3.14c0.98-0.05,2.11-0.96,2.82-1.78c2.81-3.22,3.54-7.1,2.99-11.23c-0.51-3.84-0.43-7.62,0.38-11.43
		c1.01-4.76,3.43-8.55,7.23-11.52c1.64-1.28,3.29-2.54,4.95-3.79c1.75-1.31,3.66-2.13,5.91-1.72c0.92,0.17,1.18-0.52,1.44-1.14
		c0.94-2.29,1.85-4.59,2.77-6.89c0.1-0.26,0.21-0.52,0.18-0.45c1.05-0.46,1.92-0.71,2.64-1.17c3.47-2.25,7.42-2.87,11.36-2.8
		c4.38,0.07,8.58-0.41,12.73-1.75c0.85-0.28,1.83-0.42,2.71-0.32c3.82,0.43,7.57-0.16,11.35-0.42c2.1-0.14,4.25,0.43,6.38,0.65
		c1.59,0.16,2.83,0.67,4.06,1.91c1.09,1.09,2.07,2.1,2.69,3.53c0.43,0.99,1.91,1.14,3.13,0.2c1.22-0.94,2.26-2.12,3.36-3.21
		c0.17-0.17,0.31-0.37,0.42-0.58c1.57-3.08,4.05-4.52,7.53-4.9c2.58-0.28,5.09-1.3,7.61-2.05c1.58-0.47,2.74,0.11,3.34,1.61
		c0.71,1.8,1.75,3.5,2.3,5.34c0.52,1.77,0.7,3.67,0.84,5.52c0.16,2.1,0.06,4.23,0.15,6.34c0.06,1.25-0.51,2.13-1.37,2.91
		c-1.21,1.1-2.4,2.21-3.62,3.3c-0.64,0.57-0.87,1.18-0.55,2.02c0.5,1.31,0.37,2.52-0.41,3.76c-0.36,0.57-0.27,1.43-0.33,2.16
		c-0.11,1.21-0.11,2.44-0.3,3.64c-0.35,2.1-0.88,4.17-1.2,6.27c-0.19,1.22-0.09,2.43,0.67,3.56c0.7,1.03,0.58,2.26,0.12,3.35
		c-0.56,1.3-0.22,2.38,0.46,3.5c0.7,1.16,1.31,2.37,1.92,3.58c0.49,0.97,0.32,1.88-0.17,2.85c-0.44,0.89-0.79,1.91-0.87,2.9
		c-0.14,1.62-0.75,2.85-1.94,3.96c-1.15,1.07-2.14,2.33-3.11,3.58c-0.21,0.27-0.17,0.92,0,1.27c1.12,2.25,0.9,4.57,0.47,6.91
		c-0.03,0.19-0.12,0.42-0.25,0.53c-1.27,1.01-1.3,2.26-1.02,3.76c0.34,1.85-0.84,3.03-2.93,3.29c-0.39,0.05-0.78,0.09-1.28,0.15
		c0.53,1.34,0.82,2.51-0.11,3.83c-0.47,0.66-0.19,1.85-0.26,2.93c2.06,0.69,2.37,2.36,2.16,4.38c-0.06,0.6,0.28,1.33,0.64,1.86
		c1.21,1.75,0.94,3.65,0.66,5.55c-0.05,0.36-0.46,0.82-0.8,0.95c-0.95,0.35-1.21,1.02-1.27,1.94c-0.04,0.67-0.12,1.4-0.43,1.97
		c-0.79,1.46-0.92,2.97-0.85,4.58c0.05,1.15,0.38,1.97,1.47,2.5c2.2,1.07,2.7,3,1.31,5c-0.35,0.51-0.46,0.86-0.07,1.36
		c0.65,0.83,0.59,1.63-0.22,2.32c-0.72,0.61-0.85,1.24-0.37,2.09c0.28,0.51,0.33,1.14,0.51,1.71c0.57,1.78,1.04,3.6,1.77,5.31
		c1.07,2.52,0.31,4.76-1.04,6.76c-0.83,1.23-1.34,2.41-1.34,3.85c-0.01,1.74-0.48,3.35-1.59,4.68c-1.1,1.32-1.06,2.77-0.88,4.3
		c0.38,3.17-0.46,5.98-2.43,8.5c-0.34,0.44-0.51,1.14-0.48,1.71c0.08,1.44,0.07,2.79-1.15,3.84c-0.25,0.21-0.42,0.6-0.49,0.93
		c-0.5,2.22,1.02,5.4,2.84,6.71c0.79,0.56,1.3,1.66,1.68,2.61c0.81,2.04,0.74,4.03-0.62,5.91c-0.39,0.53-0.41,1.33-0.59,2.01
		c-0.31,1.13-0.49,2.31-0.95,3.37c-0.54,1.26-0.15,3.19,0.94,4.09c0.42,0.35,0.8,0.92,0.89,1.44c0.36,2.16,0.61,4.34,0.93,6.76
		c0.6-0.28,1.21-0.44,1.66-0.81c2.2-1.77,4.67-2.1,7.43-1.94c4.19,0.24,8.39,0.14,12.59,0.18c3.07,0.03,3.71,0.98,2.35,3.72
		c-0.55,1.12-0.91,2.21-0.48,3.44c0.07,0.22,0.14,0.56,0.02,0.7c-0.83,0.97-0.32,1.99-0.18,3.01c0.27,1.94-0.46,3.56-1.85,4.82
		c-0.9,0.82-1.21,1.51-0.59,2.67c0.71,1.33,0.64,2.66-0.48,3.87c-0.41,0.45-0.54,1.15-0.86,1.69c-0.75,1.26-1.58,2.47-2.29,3.74
		c-0.39,0.69-0.91,1.52-0.82,2.22c0.23,1.87-0.79,3.29-1.52,5c1.17,0.05,2.19,0.13,3.22,0.14c2.16,0.02,4.32,0,6.48-0.01
		c1.43,0,2.08,0.8,1.63,2.17c-0.2,0.62-0.55,1.29-1.03,1.7c-0.68,0.58-0.7,1.1-0.45,1.88c0.26,0.83,0.35,1.71,0.44,2.58
		c0.07,0.63-0.08,1.31,0.07,1.91c0.8,3.1,1.15,6.23,1.17,9.43c0.01,1.02,0.8,2.05,1.3,3.04c0.49,0.96,1.17,1.83,1.57,2.82
		c1.29,3.16,2.79,6.15,5.18,8.67c0.77,0.81,1,2.12,1.47,3.21c0.2,0.47,0.39,0.95,0.61,1.4c0.29,0.6,0.76,1.16,0.91,1.79
		c0.43,1.89,0.7,3.82,1.11,5.71c0.15,0.71,0.43,1.43,0.8,2.07c0.5,0.88,1.13,1.68,1.7,2.51
		C477.14,886.99,477.14,887.39,477.14,887.79z M458.21,802.86c-0.36-0.02-0.6-0.03-0.83-0.04c-3.92-0.04-7.84,0.05-11.75-0.17
		c-2.69-0.15-5.12,0.09-7.31,1.86c-0.64,0.52-1.7,0.65-2.59,0.7c-1.03,0.06-1.69-0.55-1.84-1.65c-0.28-2.13-0.58-4.27-0.93-6.39
		c-0.06-0.34-0.34-0.7-0.62-0.94c-1.54-1.29-2.21-4.5-1.07-6.12c0.68-0.97,0.76-1.94,0.83-3.03c0.05-0.85,0.15-1.84,0.61-2.48
		c1.56-2.17,1.31-4.59-0.81-6.21c-0.53-0.41-1.09-0.85-1.46-1.39c-1.53-2.29-1.94-4.84-1.58-7.53c0.06-0.45,0.27-1,0.6-1.27
		c0.99-0.82,1-1.82,0.82-2.94c-0.18-1.09,0.22-1.97,0.91-2.86c0.74-0.96,1.53-2.05,1.75-3.19c0.33-1.73,0.26-3.55,0.19-5.33
		c-0.05-1.34,0.1-2.54,1.03-3.56c1.16-1.27,1.65-2.82,1.65-4.51c0-1.63,0.51-2.99,1.51-4.3c1.21-1.59,1.56-3.39,0.76-5.4
		c-0.79-1.99-1.32-4.08-1.95-6.13c-0.07-0.23-0.07-0.5-0.21-0.68c-0.92-1.22-0.38-2.31,0.18-3.46c0.2-0.4,0.25-0.95,0.16-1.39
		c-0.2-1-0.4-1.88,0.52-2.74c0.83-0.78,0.58-1.36-0.47-1.95c-0.61-0.34-1.38-0.66-1.71-1.21c-1.25-2.05-1.06-4.36-0.72-6.59
		c0.24-1.55,0.76-3.07,1.27-4.56c0.21-0.63,0.6-1.32,1.11-1.71c1.05-0.8,1.6-2.71,0.68-3.69c-1.21-1.29-1.35-2.71-1.19-4.33
		c0.04-0.42-0.2-1.05-0.53-1.28c-1.84-1.33-2.33-3.55-1.06-5.45c0.6-0.89,0.5-1.66-0.15-2.45c-0.82-1.02-0.61-2.11,0.57-2.65
		c0.68-0.31,1.44-0.47,2.18-0.6c1.61-0.27,1.72-0.38,1.4-2.01c-0.23-1.17-0.31-2.27,0.61-3.25c1.29-1.38,1.52-4.6,0.36-6.14
		c-0.68-0.91-0.67-1.76-0.3-2.69c0.71-1.8,1.89-3.26,3.47-4.34c1.24-0.85,1.82-1.92,1.76-3.43c-0.03-0.74-0.03-1.72,0.39-2.18
		c1.37-1.48,0.64-2.73-0.11-4.06c-0.3-0.52-0.62-1.03-0.93-1.54c-0.95-1.53-1.41-3.09-0.62-4.88c0.24-0.55,0.3-1.47,0-1.91
		c-1.69-2.4-0.83-4.91-0.48-7.39c0.14-1.01,0.36-2.02,0.67-2.99c0.57-1.78,0.61-3.56,0.43-5.4c-0.06-0.58,0.25-1.27,0.58-1.79
		c0.51-0.78,0.62-1.52,0.26-2.37c-0.65-1.49-0.23-2.74,0.89-3.79c1.13-1.07,2.26-2.14,3.45-3.14c0.74-0.62,1.04-1.29,1-2.28
		c-0.07-1.79,0.14-3.59,0.04-5.38c-0.1-1.7-0.28-3.43-0.73-5.07c-0.39-1.43-1.2-2.75-1.82-4.13c-0.26-0.57-0.48-1.15-0.65-1.56
		c-3.54,0.85-6.95,1.69-10.37,2.48c-1.4,0.32-2.39,1.07-3.25,2.24c-1.25,1.69-2.67,3.26-4.07,4.83c-1.26,1.42-2.99,1.74-4.79,1.83
		c-0.94,0.05-1.66-0.3-2.12-1.09c-0.79-1.36-1.56-2.62-2.84-3.72c-0.99-0.85-1.82-1.36-3.04-1.46c-0.51-0.04-1.01-0.26-1.52-0.3
		c-1.5-0.12-3-0.3-4.49-0.27c-3.71,0.07-7.41,0.22-11.12,0.38c-0.83,0.03-1.7,0.07-2.47,0.34c-3.94,1.39-7.99,1.69-12.15,1.69
		c-3.95,0-7.9,0.45-11.39,2.68c-0.53,0.34-1.25,0.39-1.9,0.58c-0.9,2.25-1.79,4.56-2.75,6.83c-0.79,1.85-1.75,2.39-3.73,2.16
		c-1.44-0.16-2.7,0.28-3.81,1.12c-2.23,1.68-4.49,3.35-6.61,5.17c-2.55,2.19-4.17,5-4.99,8.28c-0.92,3.73-1.18,7.46-0.64,11.29
		c0.67,4.76-0.12,9.24-3.25,13.11c-1.13,1.39-2.46,2.5-4.28,2.58c-1.45,0.07-2.35,0.77-3.17,1.82c-1.53,1.95-3.03,3.92-4.7,5.74
		c-1.36,1.49-1.82,3.09-1.5,5.06c0.13,0.77,0.04,1.69-0.28,2.4c-1.03,2.29-2.19,4.53-3.34,6.76c-1.88,3.63-3.49,7.35-4.11,11.44
		c-0.18,1.18-0.57,2.33-0.92,3.47c-1.52,4.84-4.83,7.46-9.86,7.96c-0.56,0.06-1.17,0.29-1.62,0.63c-2.62,1.98-5.2,3.99-7.76,6.04
		c-0.34,0.28-0.65,0.83-0.65,1.27c-0.04,2.64-0.06,5.28,0.07,7.91c0.04,0.81,0.38,1.84,0.96,2.35c1.18,1.04,2.55,1.89,3.94,2.63
		c1.72,0.92,3.55,1.63,5.31,2.49c1.47,0.72,1.92,1.54,1.69,3.17c-0.28,1.99,0.15,3.85,1.19,5.51c1.29,2.06,2.65,4.08,4.13,6.01
		c1.65,2.15,1.81,3.85,0.12,5.95c-1.55,1.93-3.22,3.76-4.8,5.67c-0.37,0.44-0.74,1-0.82,1.55c-0.18,1.3-0.18,2.63-0.28,3.94
		c-0.14,1.84,0.28,3.47,1.56,4.87c0.74,0.81,1.01,1.75,0.53,2.87c-0.69,1.61-0.31,3.16,0.65,4.54c0.79,1.15,1.74,2.19,2.55,3.33
		c0.46,0.64,0.93,1.34,1.13,2.08c1.46,5.43,3.69,10.55,6.13,15.58c0.91,1.88,2.18,3.59,3.26,5.34c0.72-0.27,1.19-0.44,1.84-0.68
		c0.8,13.83,1.58,27.52,2.38,41.36c-8.83-0.09-17.5-0.16-26.3,0.58c0.28,0.36,0.44,0.59,0.62,0.79
		c7.66,8.54,15.31,17.09,22.98,25.62c2.11,2.34,3.31,5.07,3.57,8.2c0.47,5.62,0.92,11.23,1.39,16.85c1.19,14.02,2.4,28.03,3.6,42.05
		c0.16,1.86,1.19,2.84,3.09,2.9c9.51,0.28,19.01,0.54,28.52,0.81c5.95,0.17,11.9,0.4,17.85,0.54c3.03,0.07,6.06,0.01,8.94,0.01
		c-0.26-0.77-0.55-1.75-0.92-2.7c-0.14-0.36-0.41-0.74-0.72-0.97c-1.74-1.29-2.07-3.68-0.73-5.39c0.2-0.25,0.27-0.6,0.4-0.91
		c-0.33-0.09-0.66-0.19-0.99-0.26c-0.57-0.13-1.23-0.11-1.71-0.4c-1.36-0.82-2.28-3.9-1.78-5.67c0.41-1.46,2.28-2.87,4.1-2.78
		c1.53,0.08,3.05,0.47,4.55,0.8c0.79,0.17,1.54,0.52,2.62,0.9c-0.18-1.03-0.21-1.79-0.46-2.47c-0.54-1.46-0.42-1.99,0.9-2.76
		c0.48-0.28,1.05-0.43,1.49-0.75c0.48-0.34,0.99-0.74,1.27-1.24c0.52-0.92,1.22-1.48,2.26-1.53c1.16-0.05,2.42,0.18,2.58-1.56
		c0.02-0.26,0.43-0.47,0.57-0.75c0.18-0.38,0.39-0.8,0.38-1.21c-0.02-2.27,0.12-2.5,2.23-3.13c-0.33-1.84,0.63-2.98,2.12-3.89
		c0.59-0.36,1.11-0.93,1.51-1.5c1.56-2.21,3.01-4.5,4.6-6.69c0.74-1.02,0.85-2.02,0.48-3.15c-0.23-0.72-0.5-1.44-0.67-2.17
		c-0.33-1.4,0.14-2.19,1.54-2.62c0.34-0.11,0.7-0.17,1.05-0.26c1.83-0.47,2.38-1.17,2.29-3.06c-0.05-1.03-0.51-2.2-0.18-3.07
		c0.66-1.72,0.6-3.42,0.67-5.17c0.1-2.41,1.79-3.28,3.76-1.86c2.57,1.86,4.73,4.17,6.4,6.87c0.46,0.73,0.93,1.11,1.77,1.15
		c1.37,0.06,2.7,0.21,3.74,1.31c0.2,0.21,0.67,0.22,1.02,0.24c0.88,0.04,1.78-0.07,2.63,0.09c0.79,0.14,1.7,0.36,2.27,0.87
		c0.63,0.57,1.19,0.74,2,0.76c1.94,0.04,3.88,0.21,4.82,2.38c0.08,0.19,0.47,0.24,0.7,0.39c0.98,0.67,1.95,1.35,2.93,2.01
		c0.27,0.18,0.57,0.41,0.87,0.42c2.15,0.09,4.31,0.16,6.47,0.18c2.23,0.03,4.46,0.01,6.8,0.01c-0.08-0.5-0.26-1.05-0.24-1.58
		c0.03-0.89,0.01-1.86,0.36-2.64c0.4-0.89,0.26-1.41-0.31-2.13c-0.7-0.88-1.38-1.88-1.7-2.94c-0.43-1.43-0.32-3.02-0.78-4.43
		c-0.8-2.47-1.81-4.88-2.81-7.28c-0.22-0.53-0.78-0.9-1.14-1.38c-0.75-0.98-1.6-1.91-2.17-2.98c-1.55-2.92-3.1-5.86-4.41-8.9
		c-0.6-1.38-0.69-2.99-0.91-4.51c-0.18-1.26-0.18-2.54-0.34-3.8c-0.19-1.49-0.48-2.97-0.71-4.46c-0.13-0.87-0.11-1.77-0.35-2.6
		c-0.49-1.66-0.93-3.26,1.06-4.7c-2.03,0-3.65,0.02-5.28-0.01c-1.68-0.03-3.36-0.05-5.02-0.2c-1.18-0.11-1.95-0.89-1.07-2.26
		c1.12-1.74,2.02-3.62,1.74-5.85c-0.07-0.53,0.14-1.33,0.52-1.64c2.18-1.79,2.76-4.54,4.18-6.78c0.19-0.3,0.19-0.92,0.01-1.24
		c-1.19-2.04-0.55-3.62,1.01-5.12c1.24-1.19,1.59-2.65,0.8-4.27c-0.48-0.98-0.47-1.85,0.64-2.52
		C456.32,806.11,457.85,804.65,458.21,802.86z"/>
					<path class="pontes" d="M458.21,802.86c-0.36,1.79-1.89,3.25-0.79,5.13c-1.11,0.66-1.12,1.54-0.64,2.52c0.79,1.62,0.45,3.07-0.8,4.27
		c-1.56,1.5-2.21,3.08-1.01,5.12c0.18,0.31,0.18,0.93-0.01,1.24c-1.42,2.24-2,4.98-4.18,6.78c-0.38,0.31-0.59,1.11-0.52,1.64
		c0.28,2.23-0.62,4.11-1.74,5.85c-0.88,1.36-0.11,2.15,1.07,2.26c1.67,0.15,3.35,0.17,5.02,0.2c1.63,0.03,3.25,0.01,5.28,0.01
		c-1.99,1.44-1.55,3.04-1.06,4.7c0.25,0.83,0.22,1.74,0.35,2.6c0.23,1.49,0.52,2.97,0.71,4.46c0.16,1.26,0.16,2.54,0.34,3.8
		c0.22,1.52,0.31,3.13,0.91,4.51c1.31,3.04,2.86,5.98,4.41,8.9c0.57,1.08,1.43,2,2.17,2.98c0.36,0.47,0.92,0.85,1.14,1.38
		c1,2.4,2.02,4.81,2.81,7.28c0.46,1.41,0.35,3.01,0.78,4.43c0.32,1.06,1,2.06,1.7,2.94c0.57,0.72,0.71,1.24,0.31,2.13
		c-0.35,0.78-0.32,1.75-0.36,2.64c-0.02,0.53,0.16,1.08,0.24,1.58c-2.34,0-4.57,0.02-6.8-0.01c-2.16-0.03-4.31-0.09-6.47-0.18
		c-0.3-0.01-0.6-0.24-0.87-0.42c-0.98-0.66-1.95-1.35-2.93-2.01c-0.22-0.15-0.61-0.2-0.7-0.39c-0.94-2.17-2.88-2.34-4.82-2.38
		c-0.81-0.02-1.37-0.19-2-0.76c-0.57-0.51-1.47-0.73-2.27-0.87c-0.86-0.15-1.75-0.04-2.63-0.09c-0.35-0.02-0.81-0.03-1.02-0.24
		c-1.04-1.09-2.37-1.24-3.74-1.31c-0.85-0.04-1.32-0.42-1.77-1.15c-1.68-2.7-3.83-5.01-6.4-6.87c-1.97-1.42-3.66-0.55-3.76,1.86
		c-0.07,1.75-0.01,3.45-0.67,5.17c-0.33,0.87,0.13,2.03,0.18,3.07c0.09,1.89-0.45,2.59-2.29,3.06c-0.35,0.09-0.7,0.15-1.05,0.26
		c-1.4,0.43-1.87,1.22-1.54,2.62c0.18,0.74,0.44,1.45,0.67,2.17c0.37,1.13,0.26,2.13-0.48,3.15c-1.59,2.19-3.04,4.48-4.6,6.69
		c-0.41,0.57-0.92,1.14-1.51,1.5c-1.5,0.91-2.45,2.05-2.12,3.89c-2.11,0.64-2.25,0.86-2.23,3.13c0,0.4-0.2,0.83-0.38,1.21
		c-0.13,0.28-0.54,0.49-0.57,0.75c-0.16,1.74-1.42,1.51-2.58,1.56c-1.04,0.04-1.74,0.61-2.26,1.53c-0.28,0.5-0.79,0.9-1.27,1.24
		c-0.45,0.32-1.01,0.47-1.49,0.75c-1.32,0.77-1.43,1.29-0.9,2.76c0.25,0.68,0.27,1.44,0.46,2.47c-1.08-0.38-1.83-0.73-2.62-0.9
		c-1.5-0.33-3.02-0.72-4.55-0.8c-1.82-0.09-3.69,1.32-4.1,2.78c-0.49,1.77,0.42,4.85,1.78,5.67c0.48,0.29,1.14,0.27,1.71,0.4
		c0.33,0.08,0.66,0.17,0.99,0.26c-0.13,0.3-0.2,0.65-0.4,0.91c-1.34,1.71-1.02,4.1,0.73,5.39c0.31,0.23,0.57,0.61,0.72,0.97
		c0.37,0.95,0.66,1.93,0.92,2.7c-2.88,0-5.91,0.06-8.94-0.01c-5.95-0.14-11.9-0.37-17.85-0.54c-9.51-0.28-19.01-0.53-28.52-0.81
		c-1.9-0.06-2.94-1.04-3.09-2.9c-1.2-14.01-2.41-28.03-3.6-42.05c-0.48-5.61-0.92-11.23-1.39-16.85c-0.26-3.13-1.47-5.85-3.57-8.2
		c-7.67-8.53-15.32-17.08-22.98-25.62c-0.18-0.2-0.34-0.43-0.62-0.79c8.8-0.74,17.47-0.67,26.3-0.58
		c-0.8-13.84-1.58-27.53-2.38-41.36c-0.65,0.24-1.12,0.41-1.84,0.68c-1.08-1.75-2.35-3.46-3.26-5.34
		c-2.44-5.03-4.68-10.15-6.13-15.58c-0.2-0.74-0.68-1.44-1.13-2.08c-0.81-1.14-1.76-2.18-2.55-3.33c-0.95-1.38-1.34-2.93-0.65-4.54
		c0.48-1.12,0.21-2.06-0.53-2.87c-1.27-1.4-1.69-3.03-1.56-4.87c0.1-1.31,0.09-2.64,0.28-3.94c0.08-0.55,0.45-1.1,0.82-1.55
		c1.58-1.91,3.25-3.74,4.8-5.67c1.69-2.1,1.53-3.8-0.12-5.95c-1.48-1.93-2.83-3.95-4.13-6.01c-1.05-1.66-1.47-3.51-1.19-5.51
		c0.23-1.63-0.21-2.46-1.69-3.17c-1.76-0.85-3.59-1.57-5.31-2.49c-1.39-0.75-2.77-1.6-3.94-2.63c-0.58-0.51-0.92-1.54-0.96-2.35
		c-0.14-2.63-0.11-5.27-0.07-7.91c0.01-0.43,0.31-0.99,0.65-1.27c2.56-2.05,5.14-4.07,7.76-6.04c0.45-0.34,1.06-0.57,1.62-0.63
		c5.04-0.5,8.35-3.13,9.86-7.96c0.36-1.14,0.75-2.29,0.92-3.47c0.62-4.09,2.23-7.81,4.11-11.44c1.16-2.23,2.31-4.47,3.34-6.76
		c0.32-0.71,0.41-1.63,0.28-2.4c-0.33-1.97,0.13-3.56,1.5-5.06c1.67-1.82,3.17-3.79,4.7-5.74c0.83-1.05,1.73-1.75,3.17-1.82
		c1.82-0.09,3.15-1.2,4.28-2.58c3.14-3.86,3.92-8.35,3.25-13.11c-0.54-3.83-0.29-7.56,0.64-11.29c0.81-3.29,2.43-6.09,4.99-8.28
		c2.12-1.82,4.37-3.49,6.61-5.17c1.11-0.83,2.37-1.28,3.81-1.12c1.98,0.23,2.95-0.31,3.73-2.16c0.97-2.28,1.85-4.59,2.75-6.83
		c0.66-0.19,1.37-0.24,1.9-0.58c3.49-2.23,7.44-2.67,11.39-2.68c4.16,0,8.2-0.31,12.15-1.69c0.77-0.27,1.64-0.3,2.47-0.34
		c3.7-0.15,7.41-0.31,11.12-0.38c1.5-0.03,3,0.15,4.49,0.27c0.51,0.04,1.01,0.26,1.52,0.3c1.22,0.1,2.05,0.61,3.04,1.46
		c1.27,1.1,2.04,2.36,2.84,3.72c0.46,0.79,1.18,1.13,2.12,1.09c1.8-0.09,3.52-0.41,4.79-1.83c1.4-1.57,2.82-3.14,4.07-4.83
		c0.86-1.17,1.86-1.92,3.25-2.24c3.42-0.79,6.83-1.63,10.37-2.48c0.17,0.41,0.4,0.99,0.65,1.56c0.62,1.38,1.43,2.69,1.82,4.13
		c0.45,1.64,0.63,3.37,0.73,5.07c0.1,1.79-0.1,3.59-0.04,5.38c0.04,0.99-0.26,1.66-1,2.28c-1.19,1-2.32,2.07-3.45,3.14
		c-1.12,1.06-1.54,2.31-0.89,3.79c0.37,0.84,0.25,1.58-0.26,2.37c-0.33,0.51-0.64,1.21-0.58,1.79c0.18,1.84,0.14,3.62-0.43,5.4
		c-0.31,0.97-0.52,1.98-0.67,2.99c-0.35,2.48-1.21,4.99,0.48,7.39c0.31,0.44,0.24,1.36,0,1.91c-0.79,1.79-0.33,3.35,0.62,4.88
		c0.31,0.51,0.63,1.02,0.93,1.54c0.76,1.32,1.48,2.58,0.11,4.06c-0.43,0.46-0.42,1.44-0.39,2.18c0.06,1.51-0.53,2.58-1.76,3.43
		c-1.58,1.09-2.76,2.55-3.47,4.34c-0.37,0.93-0.38,1.78,0.3,2.69c1.15,1.53,0.92,4.76-0.36,6.14c-0.92,0.99-0.84,2.08-0.61,3.25
		c0.32,1.63,0.21,1.74-1.4,2.01c-0.74,0.13-1.5,0.29-2.18,0.6c-1.18,0.53-1.39,1.63-0.57,2.65c0.64,0.8,0.75,1.56,0.15,2.45
		c-1.27,1.89-0.78,4.12,1.06,5.45c0.33,0.24,0.57,0.86,0.53,1.28c-0.15,1.61-0.02,3.04,1.19,4.33c0.92,0.98,0.37,2.89-0.68,3.69
		c-0.51,0.39-0.9,1.08-1.11,1.71c-0.51,1.49-1.03,3.01-1.27,4.56c-0.34,2.23-0.53,4.54,0.72,6.59c0.33,0.55,1.1,0.87,1.71,1.21
		c1.06,0.59,1.3,1.17,0.47,1.95c-0.91,0.86-0.72,1.73-0.52,2.74c0.09,0.44,0.03,0.99-0.16,1.39c-0.56,1.15-1.1,2.24-0.18,3.46
		c0.13,0.18,0.14,0.45,0.21,0.68c0.64,2.05,1.17,4.14,1.95,6.13c0.8,2.01,0.44,3.81-0.76,5.4c-1,1.32-1.51,2.68-1.51,4.3
		c0,1.69-0.49,3.23-1.65,4.51c-0.93,1.03-1.08,2.22-1.03,3.56c0.07,1.78,0.13,3.6-0.19,5.33c-0.21,1.14-1.01,2.23-1.75,3.19
		c-0.69,0.89-1.09,1.76-0.91,2.86c0.18,1.12,0.17,2.12-0.82,2.94c-0.33,0.27-0.54,0.82-0.6,1.27c-0.36,2.69,0.05,5.25,1.58,7.53
		c0.37,0.55,0.93,0.99,1.46,1.39c2.12,1.62,2.37,4.04,0.81,6.21c-0.47,0.65-0.56,1.64-0.61,2.48c-0.06,1.09-0.14,2.06-0.83,3.03
		c-1.14,1.62-0.47,4.83,1.07,6.12c0.28,0.23,0.57,0.6,0.62,0.94c0.35,2.12,0.64,4.26,0.93,6.39c0.15,1.1,0.8,1.71,1.84,1.65
		c0.89-0.05,1.95-0.19,2.59-0.7c2.19-1.77,4.62-2,7.31-1.86c3.91,0.21,7.83,0.12,11.75,0.17
		C457.61,802.83,457.85,802.85,458.21,802.86z"/>
				</g>
				<g>
					<path d="M860.11,952.96c-0.29-0.08-0.59-0.15-0.87-0.25c-0.71-0.25-1.52-0.37-2.1-0.8c-1.17-0.86-2.16-1.95-3.32-2.83
		c-0.54-0.41-1.28-0.55-1.93-0.8c-0.98-0.38-2.07-0.59-2.91-1.16c-1.67-1.12-2.9-1.15-4.43,0.18c-0.63,0.55-1.22,1.2-1.94,1.59
		c-0.71,0.38-1.55,0.61-2.35,0.69c-1.22,0.13-2.46,0.08-3.69,0.11c-0.44,0.01-0.88,0.06-1.31,0.01c-1.93-0.19-3.86-0.38-5.79-0.61
		c-0.63-0.08-1.35-0.11-1.83-0.45c-0.56-0.39-1.29-1.15-1.24-1.67c0.13-1.21-0.57-1.67-1.35-2.12c-0.69-0.4-1.46-0.64-2.19-0.95
		c-1.91-0.82-2.91-2.39-2.45-4.39c0.31-1.32,1.04-2.55,1.63-3.8c0.17-0.35,0.55-0.59,0.75-0.93c0.24-0.43,0.39-0.92,0.57-1.38
		c-0.47-0.16-0.93-0.44-1.4-0.46c-1.36-0.07-1.95-1-2.42-2.03c-0.95-2.07-0.93-4.24-0.67-6.46c0.41-3.41,0.68-6.84,1.09-10.25
		c0.1-0.83-0.37-0.95-0.92-1.08c-3.58-0.81-7.16-1.6-10.74-2.42c-1.71-0.39-3.42-0.79-5.11-1.26c-1.22-0.34-1.75-1.63-1.14-2.8
		c0.59-1.13,1.27-2.22,1.96-3.29c1.58-2.42,2.79-4.99,3.62-7.78c0.63-2.12,1.5-4.24,2.63-6.14c1.3-2.19,2.06-4.47,2.32-6.96
		c0.25-2.42,0.51-4.84,0.79-7.25c0.1-0.83,0.29-1.64,0.46-2.46c0.05-0.27,0.18-0.53,0.23-0.8c0.55-2.67-0.39-3.75-3.08-3.33
		c-1.92,0.3-3.8,0.83-5.7,1.23c-0.46,0.1-0.97,0.24-1.42,0.14c-1.47-0.31-2.59,0.34-3.81,1.01c-2.16,1.18-4.4,2.23-6.67,3.2
		c-1.99,0.86-3.91,1.74-5.55,3.23c-0.71,0.64-1.89,0.72-2.82,1.15c-0.48,0.22-0.99,0.56-1.29,0.99c-0.91,1.3-2.02,2.09-3.68,2.07
		c-1.76-0.03-1.96,0.33-1.32,1.96c0.39,1,0.81,2.01,1.02,3.05c0.33,1.62-0.41,2.65-2.06,2.66c-1.49,0.01-2.99-0.3-4.48-0.52
		c-0.94-0.14-1.86-0.45-2.8-0.59c-0.81-0.12-1.3,0.27-1.3,1.15c0,0.28-0.05,0.56-0.07,0.84c-0.29,3.47-1.73,5.09-5.19,5.58
		c-1.67,0.24-3.14,0.77-4.5,1.77c-0.68,0.49-1.56,0.77-2.4,0.97c-1.34,0.31-1.55,0.51-1.49,1.95c0.12,2.76-0.57,5.31-2.13,7.58
		c-0.97,1.41-1.73,1.4-3.08,0.41c-0.6-0.44-1.47-0.5-2.17-0.84c-0.91-0.44-1.75-0.99-2.64-1.47c-0.36-0.19-0.79-0.49-1.13-0.42
		c-0.77,0.14-1.55,0.39-2.24,0.75c-1.44,0.75-2.8,0.01-2.96-1.64c-0.15-1.53-0.56-3.05-0.55-4.57c0.01-1.58,0.27-3.16,0.33-4.76
		c0.03-0.67,0.67-1.32,1.05-2.01c-1.26-1.58-1.3-2.22-0.04-3.84c-1.69,0.02-3.3-0.02-4.11-1.74c0-0.4,0-0.8,0-1.2
		c1.38-3.77,4.4-6.31,7.01-9.13c1.49-1.62,3.81-1.7,5.67-0.71c1.07-2.41,2.15-4.84,3.27-7.38c-0.91-1.01-1.98-2.18-3.85-1.8
		c-0.29,0.06-0.67-0.01-0.93-0.15c-0.39-0.21-0.92-0.48-1.01-0.83c-0.09-0.36,0.14-0.97,0.44-1.25c3.03-2.78,6.2-5.4,9.12-8.28
		c2.78-2.75,5.34-5.72,7.91-8.67c0.76-0.87,1.24-1.99,1.82-3.01c0.67-1.17,1.83-1.56,3-1.72c1.53-0.2,2.9-0.63,4.26-1.41
		c0.91-0.52,2.14-0.52,3.24-0.68c0.87-0.13,1.75-0.16,2.63-0.21c0.98-0.06,1.82-0.06,2.42-1.2c0.66-1.24,2.53-1.28,3.69-0.33
		c1.36,1.12,2.14,1.2,3.59,0.17c2.32-1.63,4.53-3.43,6.9-4.97c5.72-3.72,11.46-7.39,18.17-9.21c4.12-1.12,3.95-1.01,3.55-4.9
		c-0.3-2.89,0.02-3.51,2.8-4.41c1.76-0.56,3.66-0.76,5.51-0.93c1.83-0.17,3.58-0.45,5.04-1.63c0.38-0.31,0.74-0.9,0.74-1.36
		c0.01-1.71-0.11-3.43-0.23-5.15c-0.07-0.92-0.42-1.57-1.48-1.89c-2.43-0.74-3.23-2.08-3.21-4.65c0.01-1.15-0.11-2.3-0.26-3.44
		c-0.03-0.26-0.4-0.56-0.68-0.68c-1.33-0.54-2.69-0.95-3.32-2.49c-0.17-0.41-0.95-0.68-1.5-0.8c-0.57-0.13-1.2-0.08-1.79,0.02
		c-2.72,0.47-4.59-0.87-6.21-2.8c-0.2-0.24-0.33-0.54-0.45-0.83c-0.23-0.55-0.27-1.42-0.65-1.6c-1.05-0.48-2.22-0.72-3.37-0.96
		c-0.35-0.07-0.77,0.17-1.16,0.26c-1.1,0.25-1.82-0.15-2.3-1.19c-0.29-0.62-0.72-1.26-1.25-1.68c-1.96-1.52-2.23-2.01-1.95-4.57
		c-2.12-0.35-4.25-0.74-6.39-1.03c-1.08-0.14-1.78-0.64-2.14-1.64c-0.16-0.45-0.27-0.92-0.41-1.38c-0.35-1.13-1-1.89-2.3-1.86
		c-0.39,0.01-0.79-0.1-1.18-0.17c-1.4-0.26-1.96-0.93-1.71-2.35c0.16-0.88-0.14-1.48-0.58-2.2c-0.47-0.77-0.61-1.73-0.9-2.6
		c-0.11-0.33-0.12-0.75-0.34-0.97c-0.96-0.96-1.12-2.08-0.83-3.32c0.24-1.02,0.11-1.81-1.08-2.08c-0.19-0.04-0.37-0.17-0.52-0.29
		c-1.18-0.92-0.63-2.16-0.79-3.31c-0.17-1.21-0.81-2.19,0.4-3.17c0.32-0.25,0.61-0.67,0.69-1.06c0.21-1.07,0.3-2.16,0.45-3.4
		c-0.66-0.13-1.49-0.3-2.33-0.47c-1.32-0.26-2.28-0.97-2.7-2.27c-0.25-0.77-0.66-0.88-1.42-0.8c-1.93,0.21-3.4-0.57-4.13-2.03
		c-0.65-1.3-0.19-2.2,1.25-2.39c0.67-0.09,1.35-0.1,2.06-0.16c-0.15-1.87-0.56-3.47-2.54-4.3c-0.59-0.25-0.87-1.18-1.36-1.72
		c-0.56-0.62-1.12-1.37-1.85-1.69c-2.08-0.91-2.71-1.87-2.22-4.11c0.15-0.68,0.02-1.04-0.58-1.47c-1.14-0.8-2.18-1.74-3.22-2.67
		c-0.59-0.53-0.68-1.2-0.08-1.79c0.61-0.59,1.16-0.26,1.64,0.27c1.63,1.78,3.66,2.95,5.84,3.9c5.41,2.38,10.84,4.7,16.22,7.13
		c1.55,0.7,2.95,1.73,4.42,2.6c0.75,0.45,1.46,1.01,2.26,1.33c1.48,0.6,2.23,0.33,3.21-0.95c0.98-1.28,2.15-2.25,3.78-2.55
		c0.35-0.07,0.7-0.19,1.02-0.34c1.08-0.5,1.08-0.8,0.39-1.76c-0.37-0.52-0.64-1.21-0.66-1.84c-0.05-1.56,0.02-3.09-0.78-4.54
		c-0.23-0.41-0.03-1.09,0.03-1.64c0.18-1.51,0.36-2.98-0.31-4.46c-0.23-0.51-0.12-1.37,0.17-1.9c0.58-1.07,1.38-2.03,1.99-2.9
		c-0.4-1.21-0.98-2.27-1.03-3.36c-0.03-0.69,0.6-1.53,1.14-2.1c0.62-0.65,0.8-1.14,0.37-1.98c-0.9-1.78-0.62-3.05,0.68-4.49
		c0.9-1,1.65-2.12,2.51-3.16c0.84-1.02,1.71-2.01,2.58-2.99c1.06-1.2,1.8-2.53,1.97-4.15c0.28-2.77,1.86-4.5,4.42-5.42
		c0.19-0.07,0.37-0.14,0.56-0.21c2.64-0.93,3.48-1.34,3.05-4.47c-0.22-1.57,0.43-2.96,1.66-3.92c1.85-1.46,2.55-3.3,2.25-5.59
		c-0.3-2.29,0.65-4.06,2.15-5.73c1.02-1.14,1.77-2.52,2.63-3.8c0.2-0.3,0.28-0.72,0.54-0.92c1.38-1.02,1.86-2.37,1.78-4.05
		c-0.03-0.56,0.38-1.13,0.47-1.72c0.09-0.61,0.17-1.28-0.01-1.85c-0.86-2.75-1.38-5.47-0.48-8.34c0.2-0.65-0.06-1.58-0.41-2.22
		c-0.83-1.54-0.78-2.15,0.74-3.01c0.9-0.51,1.27-1.24,1.11-2.18c-0.67-3.86,1.21-6.72,3.64-9.34c1.14-1.23,2.42-2.33,3.63-3.49
		c0.94-0.9,1.95-1.6,3.34-1.67c0.94-0.05,1.44-0.78,1.37-1.67c-0.07-0.81-0.28-1.66-0.63-2.4c-0.84-1.76-0.67-3.36,0.33-5
		c0.65-1.07,1.07-2.29,1.74-3.35c0.33-0.53,0.94-1.04,1.53-1.22c1.09-0.34,2.26-0.51,3.4-0.6c0.87-0.07,1.32-0.44,1.49-1.25
		c0.07-0.35,0.17-0.7,0.21-1.06c0.16-1.47,0.99-2.38,2.34-2.9c1.19-0.46,2.42-0.87,3.52-1.51c0.66-0.38,1.31-1.05,1.59-1.75
		c0.67-1.64,1.05-2.01,2.83-2.22c0.45-0.05,0.94-0.29,1.29-0.58c1.3-1.09,3.1-0.64,3.67,0.97c0.11,0.3,0.15,0.67,0.36,0.88
		c0.31,0.33,0.75,0.77,1.11,0.75c0.35-0.01,0.75-0.5,0.98-0.87c0.22-0.36,0.25-0.83,0.34-1.26c0.26-1.23,0.9-2.13,2.13-2.52
		c1.08-0.34,1.6-1.05,1.74-2.18c0.22-1.72,1.02-3.04,2.66-3.86c1.52-0.76,2.57-2,3.08-3.66c0.16-0.53,0.4-1.05,0.69-1.52
		c0.76-1.28,1.97-1.77,3.27-1.08c1.29,0.68,2.25,0.41,3.26-0.43c0.49-0.4,1.05-0.72,1.44-0.98c0.17-0.8,0.31-1.47,0.5-2.4
		c0.03,0.04-0.11-0.14-0.23-0.33c-0.69-1.13-0.49-1.74,0.74-2.2c2.65-0.98,5.42-0.89,8.18-0.85c0.92,0.01,1.84,0.1,2.76,0.05
		c0.41-0.03,0.81-0.32,1.21-0.49c0.4,0,0.8,0,1.2,0c0.25,0.61,0.6,1.19,0.74,1.82c0.29,1.32,0.45,1.49,1.76,1.11
		c1.7-0.5,2.97-0.08,4.05,1.4c0.97,1.33,1.9,2.76,3.61,3.38c0.44,0.16,1.04,0.89,0.97,1.24c-0.38,2,0.7,3.44,1.6,4.97
		c0.12,0.2,0.16,0.45,0.2,0.69c0.15,0.91,0.23,1.83,0.47,2.71c0.09,0.34,0.52,0.79,0.85,0.83c1.13,0.16,1.61,0.76,1.62,1.84
		c0,0.4-0.02,0.8,0.01,1.2c0.07,0.98,1.46,3.1,2.09,3.07c1.53-0.08,2.41,0.82,3.25,1.9c0.59,0.78,1.27,1.49,1.91,2.24
		c0.33-0.21,0.5-0.32,0.66-0.43c1.81-1.25,3.12-0.64,3.33,1.58c0.02,0.24,0.02,0.48,0.04,0.72c0.16,2.29,0.26,2.41,2.47,2.95
		c2.27,0.55,3.35,2.02,3.19,4.36c-0.06,0.88-0.2,1.75-0.21,2.63c-0.02,2.13,1.72,4.43,3.8,4.61c2.33,0.2,4.12,1.07,5.44,2.98
		c0.18,0.26,0.45,0.46,0.7,0.65c1.22,0.94,1.89,2.27,2,3.7c0.18,2.2,0.71,2.18,2.37,2.45c1.12,0.19,2.17,0.54,2.44,1.78
		c0.27,1.24-0.76,1.8-1.54,2.49c-1.06,0.94-2.08,1.92-3.21,2.97c0.33,0.21,0.44,0.31,0.58,0.36c1.5,0.59,2.63,1.47,2.81,3.24
		c0.03,0.28,0.56,0.57,0.91,0.75c0.88,0.45,1.77,0.93,1.73,2.06c-0.05,1.34,0.55,2.29,1.55,3.06c0.88,0.68,1.72,1.43,2.65,2.06
		c1.8,1.21,3.34,2.59,3.7,4.88c0,0.16,0,0.32,0,0.48c-0.79,1.8-1.51,3.64-2.41,5.38c-0.43,0.82-1.2,1.48-1.87,2.15
		c-0.83,0.84-1.18,1.72-0.82,2.92c0.28,0.95,0.42,1.96,0.49,2.95c0.09,1.3-0.32,2.44-1.47,3.22c-0.77,0.52-1.23,1.1-1.07,2.18
		c0.08,0.56-0.48,1.21-0.71,1.82c-0.21,0.56-0.57,1.14-0.54,1.7c0.08,1.3-0.13,2.42-0.98,3.46c-0.51,0.64-0.82,1.45-1.15,2.22
		c-0.51,1.2-1.16,2.23-2.32,2.96c-0.37,0.23-0.62,1-0.57,1.49c0.1,1.09-0.12,1.95-0.94,2.69c-1.89,1.7-2.79,3.82-3.16,6.37
		c-0.43,2.95-1.67,5.62-4.09,7.63c-0.48,0.4-0.79,1.31-0.75,1.97c0.06,1.18,0.07,2.2-0.96,3.02c-0.25,0.2-0.31,0.77-0.28,1.15
		c0.04,0.51,0.2,1.02,0.39,1.5c0.48,1.25,0.27,2.4-0.33,3.57c-2.05,4.01-4.7,7.56-7.95,10.67c-1.56,1.49-3.1,3.02-4.49,4.66
		c-0.66,0.78-1.12,1.82-1.4,2.82c-0.54,1.91-0.92,3.87-1.28,5.83c-0.15,0.81-0.19,1.67-0.13,2.5c0.13,1.77-0.59,3.1-1.91,4.17
		c-0.56,0.45-1.17,0.84-1.73,1.29c-0.89,0.72-1.57,1.46-1.27,2.83c0.31,1.4-0.43,2.27-1.83,2.61c-0.31,0.07-0.63,0.12-0.94,0.15
		c-2.04,0.2-1.84,0.24-1.71,2.34c0.19,3.21,0.05,6.45-0.07,9.67c-0.02,0.55-0.51,1.18-0.96,1.59c-2.43,2.23-4.27,4.88-5.4,7.94
		c-0.4,1.09-0.15,2.42-0.18,3.64c-0.03,0.86-0.04,1.72-0.05,2.58c-0.02,1.04-0.1,2.07-0.06,3.11c0.05,1.5,0.18,3,0.28,4.51
		c0.06,0.98,0.54,1.47,1.54,1.38c0.79-0.07,1.59-0.22,2.38-0.19c1.59,0.06,2.44,0.93,2.37,2.51c-0.07,1.67,0.29,3.12,1.38,4.45
		c0.82,1,1.01,2.21,0.75,3.51c-0.44,2.18-0.85,4.37-1.23,6.56c-0.23,1.33-0.7,2.5-1.7,3.48c-0.87,0.85-0.74,1.19,0.34,1.59
		c2.02,0.75,2.5,1.37,2.45,3.54c-0.02,1.15,0.22,2.13,0.83,3.15c0.72,1.22,1.1,2.63,1.65,3.95c0.19,0.47,0.33,1.03,0.67,1.36
		c0.72,0.7,0.65,1.28,0.04,1.96c-0.51,0.56-1.01,1.14-1.48,1.73c-1.39,1.74-3.15,2.48-5.38,2.21c-0.59-0.07-1.22,0.17-1.83,0.28
		c-0.27,0.05-0.57,0.25-0.79,0.18c-1.36-0.44-2.03,0.4-2.75,1.32c-1.08,1.4-2.85,1.24-4.34,1.69c-0.33,0.1-0.88-0.45-1.3-0.74
		c-0.54-0.38-1.05-0.82-1.5-1.16c-0.85,1.57-1.63,3.04-2.43,4.49c-0.15,0.28-0.31,0.58-0.54,0.79c-1.18,1.05-2.19,2.11-2.13,3.9
		c0.02,0.57-0.65,1.3-1.18,1.71c-1.39,1.06-2.87,2.01-4.35,2.94c-0.98,0.62-2.13,1.01-3,1.74c-1.72,1.45-3.57,1.41-5.53,0.82
		c-2.1-0.64-4.18-1.33-6.26-2.02c-1.36-0.45-2.05-0.2-2.76,1.06c-0.21,0.38-0.39,0.79-0.51,1.21c-0.49,1.63-1.18,3.1-2.54,4.24
		c-0.57,0.48-0.85,1.3-1.15,1.78c0.66,0.61,1.12,1.1,1.65,1.49c0.24,0.17,0.68,0.25,0.96,0.16c0.36-0.11,0.63-0.48,0.98-0.67
		c2.26-1.26,4.39-0.83,6.05,1.53c1.23,1.75,2.73,2.97,4.69,3.73c0.47,0.18,0.93,0.49,1.29,0.84c1.65,1.58,1.2,3.93-0.93,4.66
		c-0.97,0.33-2.01,0.53-3.03,0.67c-1.1,0.15-1.53,0.73-1.46,1.78c0.01,0.16-0.01,0.32,0.02,0.48c0.32,1.84-0.26,3.13-2.15,3.68
		c-0.11,0.03-0.24,0.08-0.32,0.17c-1.65,1.76-3.63,3.07-5.65,4.35c-0.35,0.22-0.51,0.87-0.62,1.35c-0.2,0.89-0.19,1.84-0.47,2.71
		c-0.39,1.23-0.6,2.28,0.32,3.46c0.67,0.85,0.37,1.92-0.48,2.69c-0.29,0.26-0.62,0.47-1.07,0.82c0.92,0.77,2.05,1.32,2.52,2.21
		c0.45,0.85,0.2,2.06,0.27,3.29c1.87,0.04,1.51,1.52,1.59,3.04c0.12,2.16,0.32,4.37,1.73,6.24c0.44,0.58,0.82,0.92,1.55,0.8
		c1.26-0.21,2.29,0.26,3.36,0.93c1.2,0.74,2.61,1.12,3.85,1.81c1.47,0.81,1.49,1.94,0.19,3.01c-0.14,0.12-0.36,0.32-0.34,0.44
		c0.22,1.27-0.57,2.1-1.21,3.02c-0.17,0.24-0.23,0.6-0.23,0.91c0,1.21-0.53,1.98-1.85,2.22c0,0.75,0,1.5,0,2.26
		c-0.01,1.55-0.48,2.08-2.04,2.17c-0.74,0.04-1.49,0.01-2.41,0.01c0.78,1.06,1.49,2,2.17,2.96c0.46,0.65,0.93,1.3,1.31,2
		c0.7,1.32,0.57,1.96-0.4,2.98c0.09,0.99,0.23,1.88,0.24,2.77c0.01,0.87,0.56,1.22,1.17,1.65c0.62,0.45,1.29,0.98,1.64,1.63
		c0.69,1.31,0.02,2.25-1.47,2.32c-3.79,0.17-7.58,0.27-11.39-0.76c0.31,1.36,0.53,2.6,0.88,3.79c0.36,1.23,0.13,2.3-0.62,3.27
		c-0.92,1.17-1.17,2.36-0.54,3.79c0.56,1.28,0.06,2.55-1.34,3.17c-1.36,0.61-2.84,0.93-4.27,1.38
		C860.43,952.96,860.27,952.96,860.11,952.96z M865.99,886.95c-1.72-1.31-1.03-2.95-0.63-4.59c0.19-0.81,0.39-1.66,0.36-2.48
		c-0.05-1.44,0.55-2.43,1.69-3.22c1.66-1.15,3.6-1.93,4.76-3.74c0.21-0.32,0.72-0.52,1.13-0.63c1.14-0.32,1.4-0.97,1.27-2.13
		c-0.3-2.53,0.82-3.74,3.38-4.09c0.96-0.13,1.9-0.47,3.23-0.81c-0.62-0.61-0.9-1.1-1.31-1.25c-2.4-0.91-4.21-2.46-5.71-4.54
		c-1.13-1.57-2.04-1.53-3.64-0.32c-1.23,0.93-2.85,1.01-3.71-0.03c-0.61-0.74-1.25-0.91-2.11-1.02c-0.54-0.07-1.31-0.51-1.44-0.95
		c-0.14-0.45,0.33-1.12,0.61-1.65c0.2-0.37,0.55-0.65,0.8-1c0.55-0.78,1.08-1.56,1.61-2.36c0.52-0.78,1.06-1.55,1.5-2.38
		c0.54-1.01,0.88-2.16,1.52-3.1c1.07-1.58,2.48-2,4.29-1.43c1.37,0.43,2.7,0.98,4.05,1.46c2.73,0.97,5.41,1.46,7.86-0.74
		c0.38-0.34,0.92-0.48,1.36-0.75c1.34-0.85,2.73-1.66,3.98-2.64c0.45-0.35,0.73-1.11,0.79-1.71c0.11-1.23,0.52-2.19,1.53-2.97
		c0.65-0.5,1.27-1.13,1.66-1.84c0.67-1.21,1.22-2.51,1.69-3.81c0.58-1.59,1.26-1.98,2.8-1.27c0.67,0.31,1.23,0.86,1.86,1.26
		c0.28,0.18,0.65,0.45,0.9,0.38c1.25-0.35,2.54-0.58,3.33-1.88c0.58-0.95,1.52-1.4,2.69-1.01c0.14,0.05,0.37,0.1,0.45,0.03
		c1.07-0.93,2.27-0.47,3.44-0.38c1.52,0.11,3.39-1.07,3.93-2.46c0.06-0.16,0.06-0.4,0-0.56c-0.72-1.76-1.5-3.5-2.17-5.28
		c-0.38-1.02-0.8-2.1-0.81-3.16c-0.02-1.27-0.19-2.01-1.59-2.46c-2.3-0.74-2.64-3.02-0.99-4.69c0.61-0.61,1.08-1.5,1.29-2.34
		c0.47-1.89,0.59-3.87,1.11-5.74c0.39-1.41,0.23-2.5-0.59-3.7c-0.61-0.89-0.97-1.98-1.27-3.03c-0.2-0.69,0.14-1.89-0.22-2.11
		c-0.56-0.34-1.51-0.05-2.29-0.01c-2.14,0.12-3.58-1.02-3.75-2.97c-0.15-1.78-0.38-3.56-0.43-5.34c-0.04-1.22,0.19-2.45,0.27-3.67
		c0.03-0.39,0.03-0.8-0.05-1.18c-0.47-2.26-0.51-4.44,0.91-6.43c0.3-0.42,0.43-0.96,0.73-1.37c1.39-1.88,2.86-3.71,4.2-5.62
		c0.45-0.64,0.85-1.48,0.85-2.22c0.01-3.1-0.15-6.21-0.24-9.31c-0.05-1.64,0.72-2.54,2.34-2.76c0.4-0.05,0.79-0.08,1.19-0.13
		c0.84-0.1,1.3-0.35,1.02-1.4c-0.15-0.56,0.04-1.42,0.41-1.86c0.89-1.05,1.95-1.98,3.01-2.87c0.85-0.72,1.52-1.44,1.6-2.66
		c0.09-1.49,0.39-2.97,0.61-4.45c0.15-0.99,0.33-1.97,0.49-2.95c0.28-1.65,0.33-3.39,1.56-4.69c1.84-1.95,3.69-3.88,5.59-5.77
		c2.41-2.4,4.54-4.98,6.22-7.96c0.99-1.75,1.57-3.4,0.73-5.4c-0.49-1.16-0.27-2.35,0.89-3.21c0.24-0.18,0.39-0.74,0.3-1.05
		c-0.49-1.81,0.32-3.13,1.59-4.23c1.96-1.71,3.03-3.97,3.44-6.41c0.43-2.57,1.19-4.83,3.07-6.71c0.49-0.48,0.69-1.4,0.74-2.13
		c0.08-1.16,0.51-2,1.35-2.8c0.81-0.76,1.46-1.72,2.09-2.66c0.47-0.69,0.86-1.44,1.16-2.22c0.25-0.63,0.52-1.37,0.42-2.01
		c-0.17-1.1,0.08-1.92,0.78-2.75c0.32-0.38,0.35-0.99,0.55-1.48c0.33-0.82,0.46-1.9,1.07-2.38c1.47-1.17,1.72-2.52,1.18-4.14
		c-0.81-2.43-0.08-4.37,1.74-6.04c0.46-0.42,0.83-0.97,1.12-1.52c0.56-1.04,1-2.16,1.56-3.2c0.52-0.96,0.56-1.85-0.2-2.65
		c-0.55-0.57-1.2-1.04-1.82-1.55c-1.32-1.09-2.76-2.07-3.94-3.3c-0.66-0.7-1.07-1.77-1.25-2.74c-0.14-0.76-0.41-1.06-1.04-1.38
		c-0.56-0.28-1.29-0.76-1.41-1.28c-0.35-1.48-1.18-2.29-2.54-2.89c-1.38-0.61-1.59-1.82-0.77-3.1c0.28-0.43,0.61-0.84,0.98-1.21
		c0.92-0.91,1.88-1.78,2.88-2.73c-0.24-0.06-0.38-0.11-0.53-0.13c-2.42-0.24-3.62-1.46-3.87-4c-0.25-2.49-2.51-3.74-4.18-5.29
		c-0.27-0.25-0.84-0.2-1.27-0.27c-0.39-0.07-0.8-0.08-1.18-0.17c-3.68-0.93-5.66-4.14-5.19-8.4c0.28-2.61,0.16-2.78-2.3-3.34
		c-2.21-0.51-2.83-1.2-3.09-3.49c-0.06-0.55-0.14-1.09-0.22-1.75c-1.03,0.59-2.05,1.13-3.03,0.22c-0.82-0.76-1.61-1.57-2.27-2.46
		c-0.55-0.74-1.13-1.16-2.08-1.16c-0.49,0-1.21-0.11-1.42-0.43c-0.94-1.43-2.36-2.67-2.23-4.62c0.05-0.79-0.16-1.16-1.05-1.39
		c-0.59-0.15-1.16-0.92-1.46-1.54c-0.3-0.62-0.17-1.42-0.37-2.11c-0.28-0.97-0.51-2.02-1.08-2.8c-0.71-0.96-1.2-1.81-0.9-3.06
		c0.1-0.39-0.14-1.19-0.4-1.29c-1.94-0.68-2.8-2.41-4.03-3.8c-0.27-0.3-0.89-0.55-1.25-0.46c-2.62,0.69-3.61,0.25-4.73-2.17
		c-3.33,0-6.62,0-10.14,0c0.74,1.04,0.7,1.78-0.12,2.42c-0.11,0.08-0.17,0.38-0.11,0.5c0.5,0.97-0.08,1.51-0.78,1.98
		c-1.06,0.71-2.14,1.39-3.23,2.06c-0.97,0.6-1.99,0.79-3.08,0.3c-1.52-0.7-1.62-0.69-2.13,0.95c-0.62,1.98-1.82,3.52-3.58,4.47
		c-1.39,0.75-2.06,1.81-2.28,3.27c-0.22,1.48-1.04,2.4-2.43,3.01c-0.56,0.24-0.97,0.94-1.3,1.52c-0.29,0.5-0.33,1.15-0.58,1.69
		c-0.73,1.59-2.09,2.16-3.76,1.65c-1.43-0.44-2.19-1.48-2.45-2.72c-0.68,0.32-1.29,0.8-1.93,0.86c-1.11,0.09-1.63,0.68-2.15,1.56
		c-0.43,0.73-1.02,1.48-1.73,1.89c-1.27,0.72-2.73,1.12-4.03,1.8c-0.56,0.29-1.17,0.89-1.33,1.47c-1.01,3.52-1.04,3.59-4.65,3.96
		c-0.12,0.01-0.24,0.02-0.36,0.03c-1.01,0.07-1.88,0.25-2,1.55c-0.04,0.43-0.36,0.91-0.68,1.24c-1.56,1.65-2,3.4-0.65,5.39
		c0.09,0.13,0.14,0.29,0.19,0.44c0.81,2.44-0.39,4.96-2.78,5.48c-1.42,0.31-2.49,1.03-3.47,1.99c-1.06,1.03-2.14,2.05-3.1,3.18
		c-1.46,1.72-2.83,3.57-2.57,6c0.23,2.16,0.07,4.09-2.05,5.33c1,1.29,1.04,2.6,0.56,3.96c-0.57,1.61-0.49,3.25-0.07,4.84
		c0.61,2.3,1.43,4.57,0.19,6.92c-0.15,0.29,0.02,0.74-0.1,1.06c-0.37,1.02-0.75,2.05-1.25,3.01c-0.37,0.69-0.97,1.26-1.44,1.9
		c-0.81,1.12-1.5,2.36-2.43,3.37c-1.35,1.46-2.15,3-1.88,5.06c0.34,2.6-0.43,4.81-2.59,6.45c-1.12,0.85-1.48,2.03-1.37,3.4
		c0.04,0.44,0.02,0.88,0.05,1.32c0.1,1.64-0.67,2.77-2.09,3.46c-0.89,0.44-1.83,0.79-2.77,1.11c-1.89,0.65-2.82,1.93-3.22,3.92
		c-0.3,1.52-0.92,3.1-1.82,4.35c-1.74,2.41-3.76,4.63-5.7,6.89c-0.61,0.71-0.86,1.38-0.41,2.28c1.09,2.16,1.04,2.25-0.53,4.1
		c-0.3,0.35-0.5,0.98-0.42,1.43c0.15,0.81,0.61,1.58-0.06,2.37c-0.06,0.07-0.04,0.32,0.03,0.39c0.65,0.59,0.33,1.13-0.03,1.7
		c-0.51,0.8-1,1.61-1.33,2.15c0.16,1.33,0.35,2.45,0.4,3.58c0.04,0.99-0.19,1.99-0.1,2.97c0.13,1.37,0.4,2.73,0.69,4.08
		c0.15,0.7,0.35,1.45,0.75,2.03c0.91,1.33,0.78,2.86-0.55,3.79c-0.52,0.36-1.11,0.66-1.72,0.83c-1.61,0.43-2.91,1.25-3.84,2.66
		c-0.76,1.14-1.9,1.54-3.22,1.46c-1.76-0.1-3.31-0.67-4.73-1.79c-1.21-0.96-2.54-1.82-3.94-2.46c-5.23-2.38-10.51-4.64-15.77-6.94
		c-0.35-0.15-0.72-0.27-1.08-0.4c-0.47,1.31-0.41,1.44,0.69,1.85c1.24,0.46,2.18,1.23,2.86,2.41c0.39,0.68,0.98,1.32,1.63,1.77
		c1.19,0.83,2.16,1.71,2.36,3.27c0.06,0.46,0.48,0.95,0.86,1.28c0.92,0.82,0.98,1.99,0.04,2.79c-0.25,0.22-0.56,0.37-0.97,0.64
		c1.3-0.04,1.92,0.62,2.2,1.65c0.31,1.16,1.06,1.73,2.22,1.85c0.48,0.05,0.95,0.11,1.42,0.2c1.63,0.3,2.29,1.36,1.86,2.97
		c-0.17,0.61-0.56,1.26-0.48,1.83c0.22,1.49-0.32,2.57-1.23,3.21c0.12,1.19,0.22,2.16,0.32,3.13c-0.11,0.21,0.06,0.38,0.27,0.49
		c2.13,1.08,2.05,1.07,1.94,3.47c-0.04,0.88,0.41,1.79,0.66,2.68c0.18,0.64,0.41,1.26,0.58,1.9c0.1,0.38,0.01,0.86,0.22,1.14
		c0.9,1.17,1.56,2.39,1.16,4.06c3.26-0.3,4.85,1.26,5.39,4.18c0.06,0.32,0.78,0.62,1.24,0.73c0.88,0.21,1.79,0.23,2.68,0.39
		c0.9,0.16,1.77,0.47,2.68,0.58c1.85,0.24,2.28,0.83,2.13,2.69c-0.05,0.63,0.19,1.43,0.59,1.9c0.82,0.96,1.84,1.74,2.95,2.75
		c-0.1,0.02,0.14,0,0.37-0.07c2.06-0.6,3.73,0.49,5.44,1.32c1.07,0.52,1.57,1.44,1.36,2.67c-0.01,0.06,0.06,0.16,0.11,0.2
		c1.02,0.85,1.79,2.1,3.4,1.93c0.71-0.08,1.43-0.1,2.15-0.15c1.56-0.1,2.96,0.41,3.75,1.7c0.68,1.12,1.61,1.57,2.74,1.88
		c0.99,0.27,1.6,0.89,1.72,1.91c0.14,1.26,0.47,2.56,0.3,3.79c-0.28,1.97,0.39,2.66,2.22,3.17c1.28,0.36,2.11,1.17,2.27,2.49
		c0.24,2.02,0.46,4.04,0.57,6.07c0.09,1.75-0.81,3.01-2.29,3.93c-1.83,1.14-3.81,1.5-5.93,1.64c-1.61,0.1-3.2,0.5-4.78,0.86
		c-1.08,0.24-1.32,1.08-1.24,2.1c0.08,1.11,0.09,2.23,0.09,3.35c0,1.26-0.64,2.11-1.8,2.57c-0.85,0.34-1.71,0.66-2.57,0.98
		c-2.31,0.86-4.76,1.46-6.91,2.62c-4.28,2.31-8.42,4.86-12.55,7.43c-2.07,1.28-3.94,2.87-5.96,4.23c-2.05,1.38-3.81,1.3-5.6-0.14
		c-0.59-0.48-1.05-0.4-1.31,0.28c-0.45,1.19-1.4,1.42-2.5,1.45c-1.12,0.04-2.24,0.05-3.35,0.17c-1.02,0.12-2.16,0.13-2.99,0.62
		c-1.38,0.81-2.73,1.44-4.36,1.45c-1.02,0.01-1.82,0.3-2.07,1.53c-0.12,0.59-0.57,1.15-0.99,1.62c-3.36,3.79-6.52,7.77-10.42,11.07
		c-1.96,1.66-3.59,3.7-5.36,5.56c1.02,0.05,1.97,0.42,2.4,1.61c0.07,0.19,0.35,0.32,0.54,0.45c1.37,0.97,1.51,1.93,0.58,3.36
		c-0.58,0.9-1.19,1.81-1.56,2.81c-0.53,1.4-0.89,2.88-1.26,4.33c-0.24,0.95-0.78,1.56-1.73,1.77c-0.75,0.17-1.52,0.14-1.86-0.67
		c-0.49-1.19-1.33-1.01-2.15-0.61c-0.66,0.33-1.29,0.81-1.81,1.35c-1.16,1.21-2.31,2.45-3.33,3.77c-0.8,1.04-1.41,2.23-2.1,3.35
		c0.12,0.14,0.23,0.29,0.35,0.43c0.91-0.19,1.83-0.36,2.73-0.58c1-0.25,1.98-0.63,2.99-0.79c1.16-0.18,1.64,0.41,1.44,1.54
		c-0.03,0.16-0.18,0.34-0.14,0.45c0.3,0.76,0.29,1.42-0.49,1.87c-0.61,0.35-1.24,0.67-1.9,0.93c-0.5,0.2-1.05,0.28-1.63,0.43
		c1.54,2.04,1.52,2.03,0.07,4.06c-0.22,0.3-0.31,0.82-0.24,1.19c0.2,0.96,0.4,1.88-0.25,2.76c-0.1,0.13-0.12,0.37-0.09,0.55
		c0.25,1.38,0.53,2.76,0.8,4.17c0.52-0.15,1.13-0.34,1.74-0.48c1.53-0.34,2.9-0.26,3.98,1.16c0.31,0.41,1.01,0.56,1.56,0.74
		c0.92,0.3,1.86,0.52,2.96,0.82c0.77-1.93,1.65-3.94,1.52-6.28c-0.13-2.25,0.91-3.4,3.12-3.78c0.47-0.08,1.01-0.12,1.36-0.39
		c1.85-1.35,3.91-2.06,6.13-2.57c2.59-0.59,2.83-1.07,2.93-3.71c0.01-0.24,0.04-0.48,0.06-0.72c0.21-2.06,1.11-2.99,3.18-2.9
		c1.33,0.06,2.65,0.51,3.98,0.74c1.11,0.2,2.22,0.33,3.61,0.54c-0.22-0.82-0.31-1.28-0.47-1.71c-0.29-0.79-0.67-1.54-0.91-2.34
		c-0.69-2.22,0.66-3.93,2.98-3.84c0.93,0.04,1.97,0.1,2.36-0.97c0.5-1.38,1.6-1.75,2.83-2.1c0.79-0.22,1.59-0.52,2.31-0.92
		c1.74-0.95,3.39-2.05,5.15-2.96c2.58-1.34,5.23-2.54,7.81-3.88c1.17-0.61,2.29-1.04,3.65-0.9c0.64,0.07,1.32-0.17,1.99-0.27
		c2.32-0.37,4.63-0.96,6.96-1.06c2.66-0.12,3.72,0.99,3.9,3.63c0.07,1.09-0.17,2.21-0.32,3.31c-0.09,0.71-0.32,1.4-0.4,2.11
		c-0.21,2.02-0.31,4.06-0.56,6.08c-0.41,3.35-1.15,6.57-3.09,9.46c-0.94,1.4-1.74,3.04-2.09,4.68c-0.81,3.69-2.57,6.85-4.66,9.9
		c-0.37,0.54-0.69,1.11-1.15,1.86c1.73,0.41,3.24,0.77,4.74,1.13c4,0.95,7.99,1.9,11.99,2.86c1.08,0.26,1.6,0.88,1.47,2.07
		c-0.4,3.65-0.8,7.31-1.07,10.97c-0.12,1.61-0.02,3.24,0.15,4.85c0.1,0.94,0.76,1.46,1.81,1.59c2.38,0.29,3.31,2.58,1.91,4.56
		c-0.49,0.68-1.05,1.31-1.53,2c-1.56,2.22-1.04,3.9,1.41,4.9c1.96,0.8,3.81,1.77,3.83,4.59c1.35,0,2.66-0.08,3.96,0.02
		c1.3,0.1,2.58,0.43,3.88,0.56c0.58,0.06,1.19-0.12,1.78-0.19c0.36-0.04,0.76-0.21,1.06-0.1c1.37,0.5,2.27-0.16,3.11-1.1
		c0.24-0.27,0.54-0.47,0.82-0.7c2.23-1.79,4.64-1.81,6.91-0.12c0.46,0.35,1.06,0.76,1.58,0.74c2.55-0.06,4.04,1.78,5.71,3.16
		c1.76,1.47,4.49,1.85,6.3,0.44c0.21-0.16,0.29-0.7,0.2-0.99c-0.62-1.9-0.33-3.58,0.83-5.21c0.33-0.47,0.45-1.26,0.33-1.84
		c-0.28-1.32-0.76-2.59-1.17-3.88c-0.41-1.3,0.29-2.37,1.66-2.41c0.63-0.02,1.28,0.09,1.89,0.25c2.81,0.7,5.63,0.83,8.69,0.47
		c-1.45-1.03-2.25-2.14-1.79-3.85c0.09-0.33-0.23-0.83-0.47-1.18c-0.59-0.84-0.58-1.56,0.31-2.16c0.6-0.41,0.62-0.85,0.21-1.4
		c-1-1.34-1.97-2.72-2.99-4.04c-0.62-0.8-1.12-1.6-0.57-2.62c0.54-0.99,1.48-1.09,2.49-1.04c0.74,0.04,1.48,0.01,2.23,0.01
		c-0.23-3.98-0.23-3.98,1.34-4.5c0.59-1.07,1.03-1.87,1.47-2.68c0.13-0.23,0.31-0.47,0.34-0.72c0.11-0.77,0.15-1.54,0.22-2.33
		c-0.79-0.39-1.78-0.96-2.84-1.4c-0.82-0.34-1.7-0.53-2.56-0.76c-0.67-0.18-1.65-0.09-1.99-0.51c-0.99-1.27-1.98-2.65-2.5-4.15
		c-0.57-1.67-0.61-3.51-0.89-5.3c-1.59-0.72-2.17-1.58-1.6-3.01c0.48-1.21,0.18-1.68-0.87-2.32c-2.08-1.27-2.08-3.19-0.19-4.66
		C865.68,887.35,865.77,887.21,865.99,886.95z"/>
					<path class="primavera" d="M865.99,886.95c-0.22,0.26-0.31,0.4-0.43,0.5c-1.89,1.47-1.89,3.39,0.19,4.66c1.04,0.64,1.35,1.11,0.87,2.32
		c-0.58,1.44,0.01,2.3,1.6,3.01c0.28,1.79,0.32,3.64,0.89,5.3c0.51,1.5,1.51,2.88,2.5,4.15c0.33,0.43,1.31,0.33,1.99,0.51
		c0.86,0.23,1.75,0.42,2.56,0.76c1.05,0.44,2.05,1,2.84,1.4c-0.07,0.79-0.12,1.56-0.22,2.33c-0.03,0.25-0.22,0.48-0.34,0.72
		c-0.44,0.81-0.88,1.61-1.47,2.68c-1.58,0.52-1.58,0.52-1.34,4.5c-0.75,0-1.49,0.03-2.23-0.01c-1.01-0.05-1.95,0.05-2.49,1.04
		c-0.55,1.01-0.05,1.82,0.57,2.62c1.02,1.33,1.99,2.7,2.99,4.04c0.42,0.56,0.4,1-0.21,1.4c-0.88,0.6-0.89,1.31-0.31,2.16
		c0.24,0.35,0.56,0.85,0.47,1.18c-0.46,1.72,0.34,2.83,1.79,3.85c-3.05,0.36-5.88,0.23-8.69-0.47c-0.61-0.15-1.26-0.27-1.89-0.25
		c-1.37,0.04-2.07,1.12-1.66,2.41c0.41,1.29,0.89,2.56,1.17,3.88c0.12,0.57,0,1.37-0.33,1.84c-1.16,1.63-1.45,3.32-0.83,5.21
		c0.1,0.29,0.01,0.83-0.2,0.99c-1.81,1.4-4.54,1.02-6.3-0.44c-1.67-1.39-3.16-3.23-5.71-3.16c-0.52,0.01-1.12-0.4-1.58-0.74
		c-2.27-1.7-4.68-1.68-6.91,0.12c-0.28,0.23-0.59,0.43-0.82,0.7c-0.84,0.94-1.74,1.6-3.11,1.1c-0.3-0.11-0.7,0.06-1.06,0.1
		c-0.59,0.07-1.2,0.25-1.78,0.19c-1.3-0.13-2.58-0.46-3.88-0.56c-1.3-0.1-2.61-0.02-3.96-0.02c-0.02-2.83-1.87-3.79-3.83-4.59
		c-2.45-1-2.97-2.68-1.41-4.9c0.48-0.69,1.05-1.31,1.53-2c1.4-1.97,0.47-4.26-1.91-4.56c-1.05-0.13-1.71-0.65-1.81-1.59
		c-0.17-1.61-0.27-3.24-0.15-4.85c0.27-3.66,0.67-7.32,1.07-10.97c0.13-1.19-0.38-1.81-1.47-2.07c-4-0.96-7.99-1.91-11.99-2.86
		c-1.5-0.36-3-0.72-4.74-1.13c0.46-0.74,0.78-1.31,1.15-1.86c2.09-3.05,3.85-6.21,4.66-9.9c0.36-1.64,1.15-3.27,2.09-4.68
		c1.95-2.89,2.68-6.11,3.09-9.46c0.25-2.02,0.35-4.05,0.56-6.08c0.07-0.71,0.3-1.4,0.4-2.11c0.14-1.1,0.39-2.22,0.32-3.31
		c-0.17-2.63-1.24-3.74-3.9-3.63c-2.33,0.1-4.64,0.69-6.96,1.06c-0.66,0.11-1.34,0.34-1.99,0.27c-1.35-0.15-2.48,0.29-3.65,0.9
		c-2.58,1.34-5.23,2.53-7.81,3.88c-1.75,0.91-3.41,2.01-5.15,2.96c-0.72,0.4-1.51,0.69-2.31,0.92c-1.22,0.35-2.33,0.72-2.83,2.1
		c-0.39,1.07-1.43,1.01-2.36,0.97c-2.31-0.09-3.66,1.62-2.98,3.84c0.25,0.8,0.63,1.55,0.91,2.34c0.16,0.43,0.25,0.89,0.47,1.71
		c-1.39-0.2-2.5-0.34-3.61-0.54c-1.33-0.24-2.64-0.69-3.98-0.74c-2.07-0.09-2.97,0.84-3.18,2.9c-0.02,0.24-0.06,0.48-0.06,0.72
		c-0.1,2.64-0.34,3.12-2.93,3.71c-2.22,0.51-4.28,1.22-6.13,2.57c-0.36,0.26-0.9,0.31-1.36,0.39c-2.21,0.38-3.25,1.53-3.12,3.78
		c0.13,2.33-0.75,4.34-1.52,6.28c-1.11-0.3-2.05-0.52-2.96-0.82c-0.55-0.18-1.25-0.33-1.56-0.74c-1.08-1.41-2.45-1.49-3.98-1.16
		c-0.62,0.14-1.22,0.33-1.74,0.48c-0.27-1.41-0.55-2.79-0.8-4.17c-0.03-0.17-0.01-0.41,0.09-0.55c0.65-0.88,0.45-1.8,0.25-2.76
		c-0.08-0.37,0.02-0.88,0.24-1.19c1.45-2.03,1.47-2.02-0.07-4.06c0.58-0.15,1.13-0.23,1.63-0.43c0.65-0.26,1.28-0.59,1.9-0.93
		c0.78-0.44,0.79-1.11,0.49-1.87c-0.05-0.11,0.11-0.29,0.14-0.45c0.2-1.13-0.29-1.72-1.44-1.54c-1.01,0.16-1.99,0.55-2.99,0.79
		c-0.9,0.22-1.82,0.39-2.73,0.58c-0.12-0.14-0.23-0.29-0.35-0.43c0.69-1.12,1.3-2.31,2.1-3.35c1.02-1.33,2.17-2.56,3.33-3.77
		c0.51-0.54,1.14-1.02,1.81-1.35c0.82-0.41,1.66-0.58,2.15,0.61c0.33,0.81,1.1,0.84,1.86,0.67c0.95-0.21,1.49-0.83,1.73-1.77
		c0.38-1.46,0.73-2.93,1.26-4.33c0.37-0.99,0.98-1.91,1.56-2.81c0.93-1.43,0.79-2.39-0.58-3.36c-0.19-0.14-0.47-0.26-0.54-0.45
		c-0.43-1.19-1.38-1.56-2.4-1.61c1.77-1.86,3.4-3.91,5.36-5.56c3.9-3.3,7.06-7.28,10.42-11.07c0.42-0.47,0.87-1.04,0.99-1.62
		c0.24-1.22,1.04-1.52,2.07-1.53c1.62-0.01,2.97-0.64,4.36-1.45c0.84-0.49,1.98-0.5,2.99-0.62c1.11-0.13,2.23-0.14,3.35-0.17
		c1.1-0.04,2.05-0.27,2.5-1.45c0.26-0.68,0.72-0.75,1.31-0.28c1.79,1.44,3.55,1.52,5.6,0.14c2.02-1.36,3.9-2.94,5.96-4.23
		c4.13-2.57,8.28-5.12,12.55-7.43c2.15-1.16,4.6-1.76,6.91-2.62c0.86-0.32,1.72-0.64,2.57-0.98c1.16-0.46,1.8-1.3,1.8-2.57
		c0-1.12-0.01-2.24-0.09-3.35c-0.08-1.03,0.16-1.86,1.24-2.1c1.58-0.36,3.18-0.75,4.78-0.86c2.11-0.14,4.1-0.5,5.93-1.64
		c1.48-0.92,2.38-2.17,2.29-3.93c-0.1-2.03-0.32-4.06-0.57-6.07c-0.16-1.32-0.99-2.13-2.27-2.49c-1.83-0.51-2.49-1.21-2.22-3.17
		c0.17-1.22-0.16-2.53-0.3-3.79c-0.12-1.03-0.73-1.65-1.72-1.91c-1.13-0.3-2.06-0.75-2.74-1.88c-0.79-1.3-2.19-1.81-3.75-1.7
		c-0.72,0.05-1.44,0.07-2.15,0.15c-1.61,0.18-2.38-1.08-3.4-1.93c-0.06-0.05-0.12-0.14-0.11-0.2c0.21-1.23-0.3-2.15-1.36-2.67
		c-1.71-0.82-3.38-1.91-5.44-1.32c-0.23,0.07-0.47,0.08-0.37,0.07c-1.11-1.01-2.13-1.79-2.95-2.75c-0.4-0.47-0.64-1.28-0.59-1.9
		c0.15-1.85-0.28-2.44-2.13-2.69c-0.9-0.12-1.78-0.42-2.68-0.58c-0.89-0.16-1.81-0.19-2.68-0.39c-0.46-0.11-1.18-0.41-1.24-0.73
		c-0.54-2.92-2.12-4.47-5.39-4.18c0.4-1.67-0.26-2.89-1.16-4.06c-0.21-0.27-0.12-0.76-0.22-1.14c-0.17-0.64-0.4-1.26-0.58-1.9
		c-0.25-0.89-0.7-1.8-0.66-2.68c0.1-2.4,0.18-2.39-1.94-3.47c-0.21-0.1-0.38-0.28-0.27-0.49c-0.1-0.97-0.2-1.94-0.32-3.13
		c0.91-0.64,1.45-1.72,1.23-3.21c-0.08-0.57,0.31-1.22,0.48-1.83c0.43-1.61-0.23-2.67-1.86-2.97c-0.47-0.09-0.95-0.15-1.42-0.2
		c-1.16-0.12-1.92-0.69-2.22-1.85c-0.27-1.03-0.9-1.68-2.2-1.65c0.41-0.27,0.71-0.42,0.97-0.64c0.94-0.8,0.88-1.97-0.04-2.79
		c-0.38-0.34-0.8-0.82-0.86-1.28c-0.21-1.56-1.18-2.44-2.36-3.27c-0.64-0.45-1.24-1.09-1.63-1.77c-0.68-1.18-1.62-1.95-2.86-2.41
		c-1.1-0.41-1.16-0.55-0.69-1.85c0.36,0.13,0.73,0.25,1.08,0.4c5.26,2.3,10.54,4.57,15.77,6.94c1.4,0.64,2.73,1.51,3.94,2.46
		c1.42,1.12,2.97,1.69,4.73,1.79c1.32,0.08,2.47-0.32,3.22-1.46c0.93-1.41,2.23-2.23,3.84-2.66c0.6-0.16,1.2-0.47,1.72-0.83
		c1.33-0.93,1.47-2.46,0.55-3.79c-0.4-0.58-0.6-1.33-0.75-2.03c-0.29-1.35-0.56-2.71-0.69-4.08c-0.09-0.98,0.14-1.98,0.1-2.97
		c-0.05-1.12-0.25-2.24-0.4-3.58c0.33-0.54,0.82-1.35,1.33-2.15c0.36-0.57,0.68-1.11,0.03-1.7c-0.07-0.07-0.09-0.32-0.03-0.39
		c0.67-0.79,0.21-1.56,0.06-2.37c-0.08-0.44,0.12-1.07,0.42-1.43c1.56-1.85,1.62-1.94,0.53-4.1c-0.45-0.9-0.2-1.57,0.41-2.28
		c1.94-2.27,3.96-4.48,5.7-6.89c0.9-1.25,1.52-2.83,1.82-4.35c0.39-1.99,1.32-3.27,3.22-3.92c0.94-0.32,1.88-0.67,2.77-1.11
		c1.41-0.69,2.19-1.83,2.09-3.46c-0.03-0.44-0.01-0.88-0.05-1.32c-0.11-1.37,0.25-2.55,1.37-3.4c2.16-1.64,2.93-3.85,2.59-6.45
		c-0.27-2.07,0.53-3.6,1.88-5.06c0.93-1.01,1.62-2.25,2.43-3.37c0.47-0.64,1.07-1.21,1.44-1.9c0.51-0.95,0.88-1.99,1.25-3.01
		c0.12-0.32-0.06-0.77,0.1-1.06c1.24-2.35,0.42-4.62-0.19-6.92c-0.42-1.6-0.5-3.23,0.07-4.84c0.48-1.36,0.43-2.67-0.56-3.96
		c2.12-1.24,2.28-3.17,2.05-5.33c-0.26-2.43,1.1-4.28,2.57-6c0.96-1.12,2.04-2.14,3.1-3.18c0.98-0.96,2.06-1.68,3.47-1.99
		c2.39-0.52,3.59-3.04,2.78-5.48c-0.05-0.15-0.1-0.31-0.19-0.44c-1.35-1.99-0.91-3.74,0.65-5.39c0.32-0.34,0.65-0.81,0.68-1.24
		c0.11-1.31,0.98-1.49,2-1.55c0.12-0.01,0.24-0.02,0.36-0.03c3.61-0.37,3.64-0.44,4.65-3.96c0.17-0.58,0.77-1.18,1.33-1.47
		c1.3-0.68,2.76-1.08,4.03-1.8c0.71-0.4,1.3-1.16,1.73-1.89c0.52-0.88,1.04-1.46,2.15-1.56c0.64-0.05,1.25-0.54,1.93-0.86
		c0.26,1.24,1.02,2.29,2.45,2.72c1.68,0.51,3.03-0.06,3.76-1.65c0.25-0.54,0.28-1.18,0.58-1.69c0.33-0.58,0.75-1.28,1.3-1.52
		c1.38-0.61,2.21-1.53,2.43-3.01c0.22-1.47,0.89-2.52,2.28-3.27c1.77-0.96,2.96-2.5,3.58-4.47c0.51-1.64,0.61-1.65,2.13-0.95
		c1.09,0.5,2.11,0.3,3.08-0.3c1.09-0.67,2.17-1.35,3.23-2.06c0.7-0.47,1.28-1.01,0.78-1.98c-0.06-0.12,0-0.42,0.11-0.5
		c0.83-0.64,0.86-1.38,0.12-2.42c3.52,0,6.81,0,10.14,0c1.12,2.43,2.11,2.86,4.73,2.17c0.36-0.1,0.98,0.16,1.25,0.46
		c1.23,1.38,2.09,3.12,4.03,3.8c0.27,0.09,0.5,0.9,0.4,1.29c-0.3,1.24,0.19,2.09,0.9,3.06c0.57,0.78,0.81,1.84,1.08,2.8
		c0.2,0.68,0.07,1.49,0.37,2.11c0.3,0.63,0.87,1.39,1.46,1.54c0.89,0.22,1.1,0.6,1.05,1.39c-0.13,1.95,1.29,3.19,2.23,4.62
		c0.21,0.32,0.94,0.43,1.42,0.43c0.95,0,1.52,0.43,2.08,1.16c0.67,0.89,1.46,1.7,2.27,2.46c0.99,0.91,2,0.37,3.03-0.22
		c0.08,0.65,0.16,1.2,0.22,1.75c0.26,2.29,0.88,2.99,3.09,3.49c2.46,0.56,2.58,0.74,2.3,3.34c-0.46,4.26,1.52,7.47,5.19,8.4
		c0.38,0.1,0.79,0.11,1.18,0.17c0.43,0.07,0.99,0.02,1.27,0.27c1.67,1.55,3.93,2.8,4.18,5.29c0.26,2.54,1.45,3.76,3.87,4
		c0.15,0.01,0.29,0.07,0.53,0.13c-1,0.95-1.96,1.82-2.88,2.73c-0.37,0.36-0.7,0.77-0.98,1.21c-0.82,1.28-0.61,2.49,0.77,3.1
		c1.36,0.6,2.19,1.41,2.54,2.89c0.12,0.52,0.85,1,1.41,1.28c0.63,0.32,0.9,0.62,1.04,1.38c0.18,0.97,0.59,2.04,1.25,2.74
		c1.17,1.23,2.61,2.21,3.94,3.3c0.61,0.51,1.27,0.97,1.82,1.55c0.76,0.79,0.72,1.69,0.2,2.65c-0.57,1.04-1,2.15-1.56,3.2
		c-0.3,0.55-0.67,1.1-1.12,1.52c-1.81,1.68-2.55,3.61-1.74,6.04c0.54,1.63,0.29,2.97-1.18,4.14c-0.6,0.48-0.74,1.56-1.07,2.38
		c-0.2,0.49-0.23,1.1-0.55,1.48c-0.69,0.83-0.95,1.66-0.78,2.75c0.1,0.63-0.17,1.37-0.42,2.01c-0.3,0.77-0.7,1.53-1.16,2.22
		c-0.63,0.93-1.28,1.89-2.09,2.66c-0.85,0.8-1.28,1.64-1.35,2.8c-0.05,0.73-0.26,1.65-0.74,2.13c-1.89,1.88-2.64,4.13-3.07,6.71
		c-0.41,2.43-1.48,4.69-3.44,6.41c-1.26,1.11-2.07,2.42-1.59,4.23c0.08,0.31-0.06,0.87-0.3,1.05c-1.16,0.86-1.37,2.05-0.89,3.21
		c0.84,2.01,0.26,3.66-0.73,5.4c-1.68,2.98-3.82,5.56-6.22,7.96c-1.89,1.89-3.75,3.82-5.59,5.77c-1.23,1.31-1.29,3.05-1.56,4.69
		c-0.16,0.98-0.34,1.97-0.49,2.95c-0.22,1.48-0.52,2.96-0.61,4.45c-0.08,1.23-0.75,1.94-1.6,2.66c-1.06,0.9-2.11,1.82-3.01,2.87
		c-0.37,0.44-0.56,1.3-0.41,1.86c0.28,1.05-0.18,1.3-1.02,1.4c-0.4,0.05-0.8,0.08-1.19,0.13c-1.62,0.22-2.38,1.12-2.34,2.76
		c0.09,3.1,0.25,6.21,0.24,9.31c0,0.75-0.4,1.59-0.85,2.22c-1.34,1.91-2.81,3.74-4.2,5.62c-0.3,0.41-0.43,0.95-0.73,1.37
		c-1.42,2-1.38,4.18-0.91,6.43c0.08,0.38,0.08,0.79,0.05,1.18c-0.08,1.23-0.3,2.45-0.27,3.67c0.05,1.78,0.28,3.56,0.43,5.34
		c0.17,1.95,1.61,3.09,3.75,2.97c0.78-0.05,1.73-0.33,2.29,0.01c0.36,0.22,0.02,1.41,0.22,2.11c0.31,1.05,0.67,2.14,1.27,3.03
		c0.82,1.2,0.98,2.29,0.59,3.7c-0.52,1.87-0.65,3.85-1.11,5.74c-0.21,0.84-0.68,1.73-1.29,2.34c-1.65,1.67-1.31,3.95,0.99,4.69
		c1.39,0.45,1.57,1.19,1.59,2.46c0.02,1.06,0.43,2.14,0.81,3.16c0.67,1.78,1.45,3.52,2.17,5.28c0.07,0.16,0.06,0.4,0,0.56
		c-0.54,1.4-2.41,2.58-3.93,2.46c-1.17-0.09-2.38-0.55-3.44,0.38c-0.08,0.07-0.31,0.01-0.45-0.03c-1.17-0.38-2.11,0.06-2.69,1.01
		c-0.78,1.29-2.08,1.52-3.33,1.88c-0.25,0.07-0.62-0.21-0.9-0.38c-0.63-0.41-1.19-0.95-1.86-1.26c-1.54-0.72-2.21-0.33-2.8,1.27
		c-0.48,1.3-1.02,2.6-1.69,3.81c-0.39,0.71-1.01,1.34-1.66,1.84c-1.01,0.77-1.42,1.73-1.53,2.97c-0.06,0.6-0.34,1.36-0.79,1.71
		c-1.25,0.98-2.63,1.78-3.98,2.64c-0.44,0.28-0.98,0.42-1.36,0.75c-2.45,2.2-5.13,1.7-7.86,0.74c-1.35-0.48-2.68-1.03-4.05-1.46
		c-1.82-0.57-3.22-0.15-4.29,1.43c-0.64,0.94-0.97,2.09-1.52,3.1c-0.44,0.82-0.99,1.6-1.5,2.38c-0.52,0.79-1.06,1.58-1.61,2.36
		c-0.25,0.35-0.6,0.63-0.8,1c-0.28,0.53-0.75,1.2-0.61,1.65c0.13,0.44,0.9,0.88,1.44,0.95c0.87,0.11,1.5,0.28,2.11,1.02
		c0.86,1.05,2.48,0.96,3.71,0.03c1.6-1.21,2.51-1.25,3.64,0.32c1.5,2.09,3.31,3.64,5.71,4.54c0.41,0.15,0.69,0.64,1.31,1.25
		c-1.34,0.34-2.27,0.68-3.23,0.81c-2.56,0.35-3.68,1.56-3.38,4.09c0.14,1.16-0.13,1.81-1.27,2.13c-0.41,0.11-0.92,0.31-1.13,0.63
		c-1.16,1.81-3.1,2.59-4.76,3.74c-1.14,0.79-1.74,1.78-1.69,3.22c0.03,0.82-0.17,1.67-0.36,2.48
		C864.97,884,864.27,885.64,865.99,886.95z"/>
				</g>
				<g>
					<path d="M691.82,937.79c-0.8,0.47-1.01,1.16-0.96,2.08c0.03,0.49-0.2,1.28-0.55,1.46c-0.94,0.48-0.98,1.13-0.98,2.04
		c0,0.57-0.49,1.17-0.84,1.7c-0.42,0.65-1.07,1.19-1.35,1.88c-0.48,1.19-1.27,1.84-2.5,1.9c-1.35,0.07-2.35,0.51-3.25,1.64
		c-1.15,1.45-3.01,1.53-4.73,1.63c-1.06,0.06-1.93,0.11-2.36,1.33c-0.16,0.45-0.76,0.86-1.25,1.04c-2.09,0.75-4.2,1.48-6.34,2.06
		c-0.81,0.22-1.14,0.43-1.1,1.31c0.1,2.01,0.18,4.07-1.6,5.52c-0.59,0.48-1.3,0.98-2.02,1.13c-1.67,0.35-2.74,1.18-3.09,2.89
		c-0.23,1.15-1.02,1.87-2.16,1.94c-1.16,0.06-1.28,0.77-1.45,1.64c-0.23,1.24-0.29,2.64-0.93,3.65c-0.77,1.23-1.24,2.35-1.25,3.82
		c-0.01,1.29-0.82,2.31-2.15,2.55c-1.09,0.2-1.11,0.7-0.91,1.59c0.19,0.8,0.31,1.66,0.22,2.46c-0.14,1.24-1.16,2.28-2.38,2.34
		c-1.21,0.06-1.83,0.76-2.5,1.59c-0.79,0.98-1.69,1.87-2.57,2.77c-0.95,0.97-1.63,2.01-1.51,3.48c0.11,1.39,0.43,2.91-1.13,3.87
		c-0.15,0.09-0.15,0.67-0.01,0.92c0.6,1.07,1.06,2.14,0.74,3.42c-0.06,0.25,0.07,0.7,0.27,0.85c1.49,1.14,1.77,2.91,2.34,4.49
		c0.56,1.56-0.19,2.89-1.22,4.04c-0.99,1.1-2.04,2.14-3.01,3.25c-1.57,1.81-2.41,3.95-2.04,6.31c0.42,2.77-2,4.84-4.15,5.09
		c-0.3,0.04-0.6,0.11-1.02,0.19c0.23,0.39,0.4,0.7,0.59,0.99c0.57,0.82,0.49,1.44-0.45,1.96c-0.36,0.2-0.57,0.66-0.96,1.13
		c-1.36-0.18-2.24,0.75-2.97,1.93c-0.34,0.54-0.67,1.09-1.01,1.63c-0.89,1.41-2.06,2.44-3.77,2.71c-0.59,0.09-0.97,0.29-0.74,1.03
		c0.71,2.3-0.12,4.2-1.54,5.98c-1.06,1.33-1.9,2.85-2.99,4.15c-0.58,0.7-1.44,1.23-2.27,1.65c-1.56,0.79-3.19,1.44-4.78,2.17
		c-0.35,0.16-0.65,0.4-0.92,0.57c0,0.17-0.02,0.22,0,0.25c1.37,1.92,1.42,2.33-0.15,4.17c-2.82,3.31-5.71,6.58-8.6,9.83
		c-1.34,1.5-3.13,2.28-5.08,2.43c-3.42,0.26-6.67,1.1-9.88,2.25c-2.44,0.87-4.94,1.59-7.45,2.25c-3.41,0.9-6.58,2.22-9.14,4.73
		c-0.84,0.82-1.53,0.86-2.55,0.17c-3.5-2.38-7.06-4.68-10.64-6.94c-1.21-0.76-1.92-1.75-2.17-3.13c-0.11-0.59-0.27-1.17-0.45-1.74
		c-0.55-1.76-0.58-1.77-2.39-1.68c-1.41,0.07-3.2-2.2-2.92-3.78c0.39-2.14-0.11-4.11-1.04-6.01c0-0.32,0-0.64,0-0.96
		c0.59-0.64,1.15-0.79,2.09-0.48c0.91,0.3,1.93,0.15,2.89-0.58c1.61-1.23,3.43-2.18,5.15-3.26c0.33-0.21,0.69-0.44,0.92-0.76
		c0.98-1.36,2.3-2.05,3.95-2.18c0.97-0.08,1.5-0.62,1.67-1.58c0.27-1.53,0.78-2.78,2.33-3.65c0.96-0.54,1.48-1.83,2.22-2.77
		c1.18-1.49,2.47-2.79,4.43-3.3c0.51-0.13,0.99-0.88,1.22-1.45c0.37-0.93,0.66-1.73,1.84-1.93c0.73-0.13,1.02-0.8,0.56-1.48
		c-0.88-1.29-0.85-2.61-0.29-3.99c0.06-0.15,0.16-0.32,0.13-0.46c-0.43-1.65-0.88-3.29-1.34-4.93c-0.21-0.74-0.53-1.47-0.64-2.22
		c-0.11-0.74,0.02-1.51-0.01-2.27c-0.05-1.37-0.1-2.75-1.72-3.31c-0.11-0.04-0.21-0.13-0.27-0.23c-1.19-1.88-1.96-3.93-1.75-6.16
		c0.05-0.53,0.85-1.07,1.41-1.44c0.44-0.29,1.05-0.32,1.6-0.47c0.11-0.9-0.23-1.34-1.1-1.57c-1.94-0.51-2.27-1.12-2.02-3.16
		c0.03-0.23-0.19-0.6-0.4-0.73c-0.62-0.35-1.26-0.71-1.94-0.9c-1.83-0.51-3.11-1.61-3.8-3.36c-0.26-0.66-0.6-0.97-1.31-0.95
		c-1.89,0.04-3.71-0.13-5.51-0.87c-0.83-0.35-1.89-0.17-2.85-0.15c-1.34,0.03-2.57-0.12-3.23-1.52c-0.69-1.45-0.5-2.8,0.49-4.04
		c1.33-1.67,4.56-2.35,6.35-1.18c1.15,0.75,2.34,0.9,3.63,0.95c1.03,0.05,2.08,0.12,3.09,0.34c0.58,0.13,1.27,0.46,1.6,0.92
		c0.6,0.83,1.3,1.02,2.25,1.02c1.76,0.01,2.38,0.3,3.77,1.53c0.1-0.69,0.2-1.24,0.25-1.8c0.03-0.27-0.13-0.6-0.04-0.83
		c0.73-1.79-0.5-2.8-1.5-3.9c-1.37-1.53-1.35-2.05-0.07-3.34c-0.19-1.83-0.37-3.52-0.54-5.21c-0.02-0.16-0.04-0.37,0.04-0.47
		c1.03-1.19,0.72-2.79,1.3-4.12c0.87-1.96,2.33-3.11,4.49-3.28c1.63-0.13,3.27-0.18,4.9-0.35c0.39-0.04,0.95-0.37,1.07-0.7
		c0.65-1.7,1.97-2.41,3.63-2.75c2.74-0.56,3.69-2.07,3.09-4.84c-0.51-2.36,0.12-3.29,2.48-3.82c2.1-0.47,4.17-1.05,6.27-1.54
		c0.89-0.21,1.1-0.66,0.98-1.56c-0.12-0.93,0.01-1.9,0.05-2.85c0.05-1.27,0.14-2.54,0.15-3.81c0.01-1.44-0.02-2.87-0.07-4.31
		c-0.1-2.53-0.94-4.77-2.22-6.98c-1.5-2.59-2.61-5.41-1.97-8.55c0.14-0.68-0.1-1.07-0.63-1.51c-2.3-1.93-4.53-3.92-6.81-5.88
		c-0.58-0.5-0.96-1.06-0.38-1.73c0.61-0.7,1.24-0.46,1.86,0.12c1.13,1.07,2.3,2.09,3.67,3.32c0.62-1.5,1.22-2.82,1.7-4.18
		c0.37-1.07,1.02-1.8,2.07-2.16c1.28-0.44,1.95-1.31,2.03-2.66c0.08-1.18,0.61-2.08,1.59-2.81c0.59-0.44,0.96-1.19,1.59-2.01
		c-1.14,0-1.91-0.04-2.69,0.01c-2.95,0.19-5.9,0.43-8.85,0.61c-0.46,0.03-1.06,0.01-1.37-0.25c-0.44-0.37-0.88-0.96-0.95-1.5
		c-0.04-0.33,0.55-0.84,0.96-1.11c0.64-0.42,1.37-0.7,2.18-0.92c0.45-0.97,0.89-1.97-0.03-3.16c-0.43-0.55-0.55-1.69-0.28-2.35
		c0.54-1.34,1.44-2.53,2.19-3.78c0.18-0.29,0.56-0.59,0.53-0.85c-0.2-1.52,0.45-2.37,1.82-2.6c-0.13-0.77-0.53-1.59-0.3-2.14
		c0.24-0.57,1.12-0.84,1.66-1.32c0.24-0.21,0.53-0.64,0.47-0.88c-0.32-1.3,0.32-2.06,1.31-2.67c0.5-0.31,1-0.63,1.45-0.91
		c0.02-0.19,0.06-0.27,0.03-0.34c-0.86-2.35-0.61-2.79,1.88-3.33c1.38-0.3,1.69-0.83,1.77-2.2c0.03-0.59,0.42-1.41,0.9-1.66
		c1.4-0.74,2.91-1.26,4.39-1.81c0.24-0.09,0.76,0.05,0.85,0.23c0.55,1.07,1.55,0.85,2.41,0.85c3.11,0,6.22-0.1,9.33-0.06
		c0.45,0.01,1.02,0.52,1.29,0.95c0.25,0.4,0.15,1.01,0.26,1.52c0.18,0.87,0.7,1.28,1.63,1.18c1.06-0.11,2.14-0.07,3.19-0.24
		c0.33-0.05,0.75-0.52,0.85-0.88c0.5-1.86,1.15-2.46,3.04-2.6c0.75-0.06,1.52-0.08,2.26-0.22c0.23-0.04,0.55-0.4,0.55-0.62
		c0-0.22-0.31-0.52-0.56-0.63c-0.36-0.15-0.78-0.16-1.17-0.23c-1.48-0.28-2.62-1.04-3.16-2.47c-0.67-1.78-1.79-3.16-3.22-4.38
		c-1.78-1.52-1.78-2.81,0.1-4.21c1.58-1.18,3.37-2.08,5.03-3.15c0.38-0.24,0.74-0.63,0.92-1.03c0.68-1.52,1.88-2.09,3.46-2.08
		c0.44,0,0.88-0.01,1.32-0.04c1.31-0.09,2.15-0.77,2.5-2.03c0.17-0.62,0.31-1.24,0.48-1.86c0.41-1.53,1.38-2.45,2.95-2.81
		c0.55-0.13,1.03-0.52,1.54-0.8c0.71-0.38,1.39-0.98,2.14-1.1c1.32-0.21,2.27-0.69,2.99-1.87c1.31-2.15,3.19-3.57,5.69-4.23
		c0.75-0.2,1.6-0.58,2.04-1.58c0.28-0.64,1.21-1.1,1.95-1.39c0.68-0.27,1.49-0.27,2.24-0.28c0.9-0.01,1.52-0.1,2.14-1.04
		c0.58-0.88,1.75-1.58,2.8-1.89c1.53-0.46,2.61-1.45,3.79-2.37c0.56,0,1.12,0,1.68,0c0.67,0.44,1.57,0.74,1.96,1.35
		c0.49,0.77,0.73,1.8,0.78,2.73c0.05,1.03,0.13,2.42-0.45,3.04c-1.06,1.14-0.93,2.3-0.79,3.52c0.05,0.42,0.56,1.09,0.91,1.12
		c1.2,0.12,2.42-0.03,3.63-0.01c0.59,0.01,1.35,0.02,1.72,0.36c0.31,0.29,0.27,1.08,0.21,1.63c-0.07,0.62-0.36,1.22-0.52,1.84
		c-0.39,1.45-0.83,1.87-2.32,1.91c-1.02,0.03-1.63,0.51-2.3,1.24c-0.9,0.98-1.98,1.84-3.11,2.53c-0.97,0.59-2.13,0.87-3.19,1.32
		c-0.25,0.11-0.49,0.35-0.64,0.59c-0.42,0.66-0.79,1.35-1.25,2.16c1.11,1.05,1.29,2.57,0.81,4.29c-0.16,0.56-0.14,1.34,0.14,1.81
		c0.8,1.34,1.8,2.57,2.68,3.87c0.19,0.28,0.31,0.67,0.3,1c-0.03,0.79-0.03,1.62-0.27,2.37c-0.32,0.99,0.07,1.52,0.88,1.92
		c0.64,0.32,1.27,0.69,1.93,0.96c0.97,0.4,1.48,1.15,1.83,2.08c0.46,1.22,0.81,2.48,1.97,3.31c0.32,0.23,0.38,0.78,0.64,1.12
		c0.27,0.36,0.58,0.79,0.98,0.95c1.48,0.6,3.17,3.03,3.11,4.63c-0.08,2.01-0.08,2.01,1.86,2.71c0.49,0.18,0.98,0.34,1.46,0.54
		c1.8,0.75,2.6,2.16,2.6,4.06c0,1.45-0.48,2.72-1.46,3.83c-0.6,0.68-1.32,1.39-1.57,2.21c-0.53,1.74-1.65,2.6-3.33,2.82
		c-1.23,0.16-1.73,0.81-1.76,1.98c-0.08,3.23-0.21,6.47-0.24,9.7c-0.01,0.54,0.22,1.24,0.59,1.61c1.23,1.2,1.8,2.63,1.85,4.29
		c0.08,2.29,0.89,4.29,2.38,6c1.36,1.56,2.79,3.07,4.16,4.62c0.47,0.53,0.91,1.11,1.27,1.72c0.62,1.05,1.18,2.15,1.76,3.22
		C691.82,937.15,691.82,937.47,691.82,937.79z M627.41,865.28c0.66,0.52,1.02,0.72,1.27,1.01c0.92,1.09,2.1,2.08,2.62,3.35
		c0.73,1.75,1.71,2.73,3.61,2.98c1.51,0.19,2.25,1.2,2.3,2.62c0.05,1.43-0.64,2.4-2.1,2.81c-0.68,0.19-1.42,0.34-2.12,0.3
		c-1.23-0.08-1.77,0.35-1.97,1.61c-0.25,1.6-1.22,2.1-2.87,1.94c-0.81-0.08-1.67-0.03-2.47,0.15c-1.92,0.43-3.52-0.38-4.19-2.24
		c-0.14-0.4-0.23-0.83-0.39-1.42c-3.46,0-6.94,0.01-10.41-0.02c-0.44,0-1.08-0.1-1.29-0.39c-0.66-0.9-1.24-0.18-1.78,0.02
		c-0.55,0.21-1.32,0.2-1.31,1.24c0.02,1.63-1.42,3.22-3,3.59c-0.34,0.08-0.68,0.17-1.06,0.27c0.1,0.32,0.17,0.54,0.25,0.76
		c0.43,1.15,0.17,2.02-1,2.56c-0.4,0.18-0.77,0.43-1.11,0.7c-0.22,0.18-0.53,0.48-0.51,0.71c0.13,1.55-0.34,2.72-1.98,3.2
		c1.62,1.86,1.53,2.04-1.75,3.71c0.67,1.16-0.2,1.75-0.89,2.51c-0.76,0.84-1.38,1.83-1.94,2.82c-0.16,0.29-0.08,0.97,0.15,1.18
		c0.98,0.91,0.94,1.95,0.63,3.09c-0.2,0.72-0.35,1.45-0.55,2.3c2.07-0.15,4.06-0.33,6.05-0.42c1.07-0.05,2.16-0.06,3.2,0.14
		c0.97,0.18,1.84,1.02,1.53,1.96c-0.43,1.27-1.21,2.5-2.1,3.51c-0.54,0.61-1.08,1.05-1.17,1.94c-0.18,1.73-0.88,3.15-2.71,3.72
		c-0.82,0.26-1.27,0.83-1.48,1.71c-0.24,1.02-0.68,1.99-0.92,2.66c0.8,1.68,1.55,3.2,2.24,4.73c0.09,0.2-0.1,0.52-0.14,0.78
		c-0.15,1.15-0.57,2.35-0.38,3.44c0.35,1.99,1,3.93,2.27,5.62c0.54,0.72,0.84,1.63,1.15,2.49c1.37,3.83,0.61,7.84,0.89,11.76
		c0.01,0.08-0.05,0.15-0.05,0.23c-0.06,1.3-0.19,2.61-0.15,3.91c0.05,1.47-0.37,2.5-1.74,2.93c-2.05,0.64-4.13,1.22-6.24,1.61
		c-1.86,0.35-1.89,0.37-1.68,2.25c0.29,2.6-0.88,6.42-4.56,6.48c-1.23,0.02-2.12,0.69-2.53,1.87c-0.48,1.39-1.51,1.84-2.87,1.88
		c-1.52,0.05-3.03,0.15-4.55,0.26c-2.16,0.16-3.14,1.25-3.15,3.4c0,0.42,0,0.97-0.25,1.25c-1.26,1.48-0.7,3.1-0.24,4.55
		c0.41,1.32,0.67,2.38-0.65,3.29c0.67,0.81,1.34,1.51,1.85,2.3c0.35,0.54,0.66,1.22,0.65,1.84c-0.01,1.22-0.27,2.44-0.4,3.67
		c-0.14,1.36-1,2.53-2.19,2.88c-0.8,0.24-1.59,0.16-1.95-0.67c-0.43-0.99-1.15-1.18-2.12-1.16c-1.4,0.03-2.69-0.19-3.54-1.55
		c-0.2-0.33-0.8-0.52-1.24-0.57c-0.79-0.1-1.6-0.06-2.4-0.06c-1.39,0.01-2.67-0.19-3.84-1.08c-0.95-0.73-2.96-0.56-3.79,0.31
		c-0.33,0.35-0.55,1.02-0.45,1.47c0.06,0.29,0.76,0.58,1.2,0.64c1.19,0.14,2.49-0.13,3.56,0.27c1.78,0.66,3.55,0.82,5.4,0.87
		c1.24,0.03,2.31,0.5,2.67,1.82c0.36,1.3,1.25,1.97,2.43,2.44c0.96,0.38,1.95,0.75,2.81,1.3c1.52,0.96,1.75,1.72,1.34,3.33
		c0.8,0.45,1.76,0.73,2.33,1.36c1.63,1.82,0.84,4.09-1.5,4.83c-0.36,0.11-0.94,0.65-0.88,0.79c0.54,1.41,0.53,3.04,2.04,4.05
		c0.71,0.47,1.26,1.45,1.48,2.31c0.31,1.22,0.32,2.53,0.32,3.81c0,0.84,0.1,1.52,0.74,2.16c0.35,0.35,0.46,0.98,0.58,1.51
		c0.21,0.91,0.38,1.82,0.48,2.75c0.09,0.76,0.26,1.6,0.02,2.28c-0.34,0.95-0.38,1.7,0.17,2.59c0.95,1.54,0.2,3.54-1.41,4.34
		c-0.46,0.23-0.91,0.73-1.08,1.2c-0.43,1.18-1.11,2.02-2.36,2.32c-1.35,0.32-2.34,1.11-3.15,2.23c-0.88,1.23-1.68,2.67-2.88,3.47
		c-1.06,0.7-1.45,1.45-1.65,2.53c-0.31,1.71-1.24,2.81-3.06,3.04c-1.23,0.15-2.28,0.65-3.05,1.69c-0.28,0.37-0.72,0.64-1.12,0.89
		c-1.52,0.95-3.11,1.81-4.58,2.84c-1.3,0.91-2.41,2.17-4.1,1.7c0.09,1.74,0.22,3.36,0.24,4.98c0.01,1.22,0.44,1.79,1.66,1.76
		c1.57-0.03,2.61,0.72,3.15,2.12c0.4,1.04,0.61,2.15,0.96,3.21c0.14,0.43,0.32,0.98,0.66,1.21c3.74,2.51,7.51,4.98,11.35,7.5
		c2.5-2.55,5.47-4.03,8.78-4.9c1.54-0.41,3.05-0.93,4.59-1.36c4.76-1.31,9.31-3.38,14.36-3.59c1.97-0.08,3.45-1.27,4.63-2.88
		c1.01-1.38,2.17-2.66,3.32-3.94c1.64-1.84,3.33-3.65,4.96-5.43c-1.64-2.39-1.39-3.54,0.99-4.75c1.49-0.76,3.06-1.36,4.57-2.08
		c0.6-0.28,1.32-0.55,1.7-1.03c1.23-1.56,2.31-3.23,3.5-4.83c0.92-1.24,1.28-2.52,0.72-4.04c-0.44-1.19,0.45-2.92,1.72-3.05
		c1.86-0.19,2.81-1.34,3.63-2.77c1.04-1.81,2.21-3.46,4.6-3.69c-0.19-0.37-0.3-0.61-0.43-0.85c-0.86-1.65-0.42-2.76,1.34-3.33
		c0.57-0.18,1.17-0.25,1.75-0.42c1.28-0.38,1.92-1.19,1.74-2.49c-0.44-3.13,0.72-5.73,2.61-8.06c0.9-1.12,1.95-2.12,2.94-3.16
		c0.82-0.87,1.15-1.83,0.5-2.93c-0.6-1.02-1.27-1.99-1.86-3.02c-0.25-0.44-0.54-0.95-0.52-1.42c0.06-1.36-0.18-2.57-0.93-3.74
		c-0.57-0.88-0.4-1.84,0.46-2.46c0.85-0.61,1.05-1.35,0.89-2.29c-0.34-1.99,0.28-3.8,1.78-4.95c1.5-1.16,2.36-2.78,3.67-4.03
		c0.64-0.61,1.59-1,2.47-1.24c0.83-0.23,1.19-0.6,1.04-1.41c-0.13-0.71-0.36-1.39-0.47-2.1c-0.24-1.44,0.44-2.52,1.87-2.68
		c1.29-0.15,1.53-0.84,1.54-1.95c0.01-0.77-0.01-1.79,0.45-2.26c1.26-1.33,1.6-2.87,1.74-4.58c0.05-0.62,0.2-1.34,0.58-1.8
		c0.43-0.54,1.12-1.11,1.76-1.19c0.84-0.11,1.14-0.42,1.38-1.16c0.63-1.97,1.93-3.28,4.02-3.69c1.55-0.3,2.27-1.08,2.4-2.66
		c0.08-0.95,0.02-1.92,0.06-2.88c0.06-1.57,0.69-2.13,2.21-2.47c1.93-0.43,3.85-0.98,5.72-1.61c0.53-0.18,1.07-0.75,1.32-1.28
		c0.43-0.91,1.05-1.34,2.02-1.43c1.15-0.11,2.32-0.19,3.44-0.47c0.65-0.16,1.38-0.57,1.76-1.09c0.83-1.15,1.92-1.75,3.24-1.78
		c1.26-0.03,1.73-0.58,2.35-1.66c0.81-1.4,1.52-2.73,2.06-4.29c0.37-1.08,1.01-1.99,1.01-3.28c0-1.55-0.17-3.1-1.3-4.04
		c-2.13-1.78-3.59-4.06-5.26-6.19c-1.13-1.43-1.72-3.02-1.81-4.85c-0.07-1.35-0.16-2.76-1.25-3.77c-1.33-1.24-1.55-2.79-1.45-4.5
		c0.16-2.71,0.23-5.43,0.35-8.14c0.03-0.68,0.08-1.36,0.15-2.03c0.12-1.24,0.86-2.03,2.03-2.2c1.63-0.23,2.87-0.79,3.38-2.52
		c0.15-0.5,0.66-0.89,0.99-1.34c0.36-0.48,0.75-0.94,1.04-1.46c1.04-1.88,0.43-3.46-1.58-4.17c-0.75-0.26-1.53-0.49-2.23-0.85
		c-1.37-0.71-1.98-1.93-1.81-3.35c0.21-1.72-0.54-2.86-2.18-3.42c-0.39-0.13-0.93-0.4-1.02-0.72c-0.41-1.36-1.26-2.36-2.3-3.26
		c-0.17-0.14-0.29-0.41-0.31-0.63c-0.17-2.08-1.5-3.09-3.28-3.85c-2.25-0.97-2.58-1.79-2-4.13c0.24-0.95,0.47-1.84-0.66-2.48
		c-0.31-0.17-0.38-0.76-0.58-1.15c-0.31-0.6-0.71-1.16-0.94-1.78c-0.33-0.86-0.88-1.83-0.73-2.64c0.3-1.64,0.14-2.96-0.87-4.36
		c-0.91-1.26-0.52-2.08,0.93-2.96c0.16-0.09,0.36-0.25,0.38-0.4c0.15-1.26,1.12-1.64,2.1-2.05c2.37-1.02,4.82-1.86,6.44-4.14
		c0.4-0.56,1.44-0.83,2.22-0.92c0.87-0.11,1.04-0.46,0.99-1.23c-0.24,0.03-0.4,0.05-0.55,0.07c-4.25,0.54-6.9-2.59-5.64-6.73
		c0.11-0.36,0.38-0.7,0.66-0.96c1-0.95,1.1-1.85,0.3-2.92c-0.37-0.49-0.73-0.98-0.97-1.3c-1.16,0.58-2.3,1.1-3.39,1.71
		c-0.88,0.49-2.04,0.92-2.45,1.71c-0.61,1.17-1.44,1.52-2.55,1.61c-0.76,0.07-1.53-0.05-2.27,0.08c-0.45,0.07-1.11,0.34-1.25,0.69
		c-0.59,1.47-1.77,2.11-3.15,2.41c-2.33,0.5-3.89,1.94-5.03,3.91c-0.74,1.28-1.75,1.99-3.24,2.15c-0.63,0.07-1.24,0.41-1.82,0.7
		c-0.76,0.38-1.44,1.01-2.24,1.21c-1.14,0.28-1.67,0.88-1.9,1.99c-0.79,3.72-1.96,4.7-5.81,4.87c-1.03,0.05-1.65,0.39-2.15,1.35
		c-0.38,0.71-1.13,1.31-1.84,1.74C631.11,863.36,629.38,864.22,627.41,865.28z"/>
					<path class="varzea" d="M627.41,865.28c1.97-1.06,3.7-1.92,5.35-2.91c0.72-0.43,1.47-1.02,1.84-1.74c0.51-0.96,1.12-1.3,2.15-1.35
		c3.85-0.17,5.02-1.15,5.81-4.87c0.24-1.11,0.76-1.71,1.9-1.99c0.8-0.2,1.47-0.83,2.24-1.21c0.58-0.29,1.2-0.63,1.82-0.7
		c1.49-0.16,2.5-0.87,3.24-2.15c1.14-1.97,2.69-3.41,5.03-3.91c1.38-0.29,2.57-0.93,3.15-2.41c0.14-0.35,0.79-0.61,1.25-0.69
		c0.74-0.12,1.52-0.01,2.27-0.08c1.11-0.1,1.94-0.44,2.55-1.61c0.41-0.78,1.57-1.21,2.45-1.71c1.09-0.62,2.23-1.13,3.39-1.71
		c0.24,0.32,0.6,0.81,0.97,1.3c0.81,1.07,0.7,1.96-0.3,2.92c-0.28,0.26-0.55,0.6-0.66,0.96c-1.25,4.13,1.39,7.27,5.64,6.73
		c0.15-0.02,0.31-0.04,0.55-0.07c0.05,0.77-0.13,1.12-0.99,1.23c-0.78,0.1-1.83,0.36-2.22,0.92c-1.61,2.28-4.07,3.12-6.44,4.14
		c-0.97,0.42-1.95,0.79-2.1,2.05c-0.02,0.15-0.23,0.31-0.38,0.4c-1.45,0.89-1.84,1.7-0.93,2.96c1.01,1.39,1.17,2.71,0.87,4.36
		c-0.15,0.81,0.4,1.78,0.73,2.64c0.24,0.62,0.63,1.18,0.94,1.78c0.2,0.39,0.27,0.97,0.58,1.15c1.13,0.63,0.9,1.53,0.66,2.48
		c-0.58,2.35-0.26,3.17,2,4.13c1.78,0.76,3.11,1.78,3.28,3.85c0.02,0.22,0.14,0.49,0.31,0.63c1.04,0.9,1.89,1.9,2.3,3.26
		c0.1,0.32,0.63,0.58,1.02,0.72c1.64,0.56,2.4,1.71,2.18,3.42c-0.18,1.42,0.44,2.65,1.81,3.35c0.7,0.36,1.48,0.59,2.23,0.85
		c2,0.71,2.62,2.29,1.58,4.17c-0.29,0.52-0.68,0.98-1.04,1.46c-0.33,0.45-0.85,0.84-0.99,1.34c-0.51,1.73-1.75,2.29-3.38,2.52
		c-1.17,0.17-1.91,0.96-2.03,2.2c-0.07,0.68-0.12,1.35-0.15,2.03c-0.12,2.72-0.19,5.43-0.35,8.14c-0.1,1.71,0.12,3.26,1.45,4.5
		c1.09,1.02,1.19,2.42,1.25,3.77c0.09,1.83,0.68,3.41,1.81,4.85c1.68,2.13,3.13,4.41,5.26,6.19c1.13,0.95,1.3,2.49,1.3,4.04
		c0,1.29-0.64,2.2-1.01,3.28c-0.54,1.56-1.25,2.89-2.06,4.29c-0.62,1.08-1.09,1.63-2.35,1.66c-1.32,0.03-2.41,0.63-3.24,1.78
		c-0.38,0.52-1.11,0.93-1.76,1.09c-1.12,0.28-2.29,0.36-3.44,0.47c-0.97,0.09-1.58,0.52-2.02,1.43c-0.25,0.53-0.79,1.1-1.32,1.28
		c-1.88,0.63-3.79,1.19-5.72,1.61c-1.52,0.34-2.15,0.89-2.21,2.47c-0.03,0.96,0.02,1.92-0.06,2.88c-0.13,1.58-0.85,2.35-2.4,2.66
		c-2.09,0.41-3.39,1.72-4.02,3.69c-0.24,0.74-0.54,1.05-1.38,1.16c-0.64,0.08-1.33,0.65-1.76,1.19c-0.37,0.46-0.52,1.18-0.58,1.8
		c-0.14,1.71-0.48,3.25-1.74,4.58c-0.45,0.47-0.44,1.49-0.45,2.26c-0.01,1.11-0.26,1.8-1.54,1.95c-1.43,0.16-2.11,1.24-1.87,2.68
		c0.12,0.71,0.35,1.39,0.47,2.1c0.15,0.81-0.21,1.18-1.04,1.41c-0.88,0.24-1.82,0.63-2.47,1.24c-1.31,1.25-2.16,2.87-3.67,4.03
		c-1.5,1.15-2.12,2.96-1.78,4.95c0.16,0.93-0.05,1.68-0.89,2.29c-0.86,0.62-1.03,1.58-0.46,2.46c0.75,1.16,0.99,2.38,0.93,3.74
		c-0.02,0.47,0.27,0.99,0.52,1.42c0.59,1.02,1.26,2,1.86,3.02c0.65,1.1,0.32,2.06-0.5,2.93c-0.99,1.05-2.03,2.05-2.94,3.16
		c-1.89,2.34-3.05,4.93-2.61,8.06c0.18,1.29-0.46,2.11-1.74,2.49c-0.57,0.17-1.18,0.24-1.75,0.42c-1.76,0.57-2.2,1.69-1.34,3.33
		c0.13,0.24,0.25,0.48,0.43,0.85c-2.39,0.23-3.57,1.88-4.6,3.69c-0.82,1.43-1.76,2.59-3.63,2.77c-1.27,0.13-2.15,1.85-1.72,3.05
		c0.56,1.51,0.2,2.8-0.72,4.04c-1.18,1.6-2.26,3.27-3.5,4.83c-0.39,0.49-1.1,0.75-1.7,1.03c-1.51,0.71-3.08,1.32-4.57,2.08
		c-2.38,1.21-2.63,2.36-0.99,4.75c-1.63,1.78-3.32,3.58-4.96,5.43c-1.14,1.28-2.3,2.56-3.32,3.94c-1.18,1.61-2.66,2.8-4.63,2.88
		c-5.05,0.21-9.6,2.28-14.36,3.59c-1.54,0.42-3.05,0.95-4.59,1.36c-3.31,0.87-6.28,2.35-8.78,4.9c-3.84-2.53-7.61-4.99-11.35-7.5
		c-0.34-0.23-0.52-0.77-0.66-1.21c-0.35-1.06-0.56-2.17-0.96-3.21c-0.54-1.39-1.58-2.15-3.15-2.12c-1.21,0.03-1.64-0.54-1.66-1.76
		c-0.02-1.62-0.15-3.24-0.24-4.98c1.69,0.47,2.79-0.79,4.1-1.7c1.47-1.03,3.05-1.88,4.58-2.84c0.4-0.25,0.85-0.52,1.12-0.89
		c0.77-1.04,1.82-1.54,3.05-1.69c1.81-0.23,2.74-1.33,3.06-3.04c0.2-1.08,0.59-1.83,1.65-2.53c1.2-0.79,2-2.24,2.88-3.47
		c0.81-1.12,1.8-1.91,3.15-2.23c1.26-0.3,1.93-1.14,2.36-2.32c0.18-0.48,0.63-0.98,1.08-1.2c1.62-0.81,2.36-2.81,1.41-4.34
		c-0.55-0.89-0.51-1.63-0.17-2.59c0.24-0.67,0.07-1.52-0.02-2.28c-0.1-0.92-0.28-1.84-0.48-2.75c-0.12-0.53-0.23-1.16-0.58-1.51
		c-0.63-0.64-0.74-1.32-0.74-2.16c0-1.27-0.01-2.59-0.32-3.81c-0.22-0.85-0.77-1.84-1.48-2.31c-1.52-1.01-1.51-2.64-2.04-4.05
		c-0.05-0.14,0.52-0.68,0.88-0.79c2.34-0.74,3.13-3.01,1.5-4.83c-0.57-0.63-1.53-0.91-2.33-1.36c0.41-1.61,0.17-2.37-1.34-3.33
		c-0.87-0.55-1.85-0.92-2.81-1.3c-1.17-0.47-2.07-1.14-2.43-2.44c-0.37-1.31-1.44-1.79-2.67-1.82c-1.85-0.04-3.62-0.2-5.4-0.87
		c-1.07-0.4-2.37-0.13-3.56-0.27c-0.44-0.05-1.14-0.34-1.2-0.64c-0.09-0.45,0.12-1.12,0.45-1.47c0.83-0.87,2.84-1.04,3.79-0.31
		c1.16,0.89,2.45,1.09,3.84,1.08c0.8-0.01,1.61-0.04,2.4,0.06c0.44,0.06,1.03,0.24,1.24,0.57c0.85,1.36,2.15,1.58,3.54,1.55
		c0.96-0.02,1.68,0.17,2.12,1.16c0.36,0.83,1.16,0.91,1.95,0.67c1.19-0.36,2.04-1.52,2.19-2.88c0.13-1.22,0.39-2.44,0.4-3.67
		c0.01-0.62-0.31-1.3-0.65-1.84c-0.51-0.79-1.18-1.49-1.85-2.3c1.32-0.91,1.06-1.97,0.65-3.29c-0.46-1.46-1.02-3.08,0.24-4.55
		c0.24-0.28,0.25-0.83,0.25-1.25c0-2.15,0.98-3.24,3.15-3.4c1.51-0.11,3.03-0.22,4.55-0.26c1.37-0.04,2.39-0.49,2.87-1.88
		c0.41-1.18,1.3-1.85,2.53-1.87c3.69-0.06,4.85-3.88,4.56-6.48c-0.21-1.87-0.18-1.9,1.68-2.25c2.11-0.39,4.19-0.97,6.24-1.61
		c1.37-0.43,1.79-1.46,1.74-2.93c-0.05-1.3,0.09-2.61,0.15-3.91c0-0.08,0.06-0.16,0.05-0.23c-0.28-3.92,0.48-7.93-0.89-11.76
		c-0.31-0.86-0.61-1.77-1.15-2.49c-1.26-1.69-1.91-3.63-2.27-5.62c-0.19-1.09,0.23-2.29,0.38-3.44c0.04-0.27,0.23-0.58,0.14-0.78
		c-0.69-1.54-1.44-3.06-2.24-4.73c0.24-0.67,0.68-1.64,0.92-2.66c0.21-0.88,0.66-1.45,1.48-1.71c1.83-0.58,2.53-1.99,2.71-3.72
		c0.09-0.89,0.64-1.32,1.17-1.94c0.89-1.02,1.67-2.24,2.1-3.51c0.32-0.94-0.55-1.78-1.53-1.96c-1.04-0.2-2.14-0.18-3.2-0.14
		c-1.99,0.09-3.98,0.27-6.05,0.42c0.2-0.85,0.36-1.58,0.55-2.3c0.31-1.14,0.35-2.18-0.63-3.09c-0.23-0.22-0.31-0.89-0.15-1.18
		c0.56-0.99,1.18-1.97,1.94-2.82c0.69-0.77,1.56-1.35,0.89-2.51c3.28-1.67,3.36-1.85,1.75-3.71c1.64-0.48,2.11-1.65,1.98-3.2
		c-0.02-0.22,0.28-0.53,0.51-0.71c0.34-0.28,0.72-0.52,1.11-0.7c1.17-0.54,1.43-1.41,1-2.56c-0.08-0.22-0.15-0.44-0.25-0.76
		c0.39-0.1,0.72-0.19,1.06-0.27c1.58-0.37,3.02-1.96,3-3.59c-0.01-1.04,0.76-1.03,1.31-1.24c0.53-0.2,1.12-0.92,1.78-0.02
		c0.21,0.29,0.85,0.39,1.29,0.39c3.47,0.03,6.95,0.02,10.41,0.02c0.16,0.6,0.25,1.02,0.39,1.42c0.67,1.87,2.28,2.67,4.19,2.24
		c0.8-0.18,1.66-0.23,2.47-0.15c1.64,0.16,2.61-0.33,2.87-1.94c0.2-1.26,0.74-1.69,1.97-1.61c0.7,0.04,1.44-0.11,2.12-0.3
		c1.46-0.41,2.15-1.39,2.1-2.81c-0.05-1.42-0.79-2.43-2.3-2.62c-1.9-0.24-2.88-1.22-3.61-2.98c-0.53-1.26-1.71-2.26-2.62-3.35
		C628.43,865.99,628.07,865.8,627.41,865.28z"/>
				</g>
				<g>
					<path d="M832.85,813.19c-1.09,3.22-3.83,4.83-8.2,5.03c-1.61,0.07-3.21,0.44-4.79,0.8c-1.03,0.23-1.43,0.97-1.3,2.07
		c0.12,1.03,0.07,2.07,0.1,3.11c0.03,1.39-0.55,2.39-1.87,2.9c-0.97,0.37-1.93,0.79-2.92,1.07c-7.05,1.93-13.09,5.79-19.02,9.87
		c-1.94,1.34-3.86,2.71-5.73,4.15c-1.23,0.94-2.66,1.07-4.06,1.13c-0.52,0.03-1.13-0.52-1.58-0.93c-0.67-0.61-1.22-0.82-1.63,0.19
		c-0.41,1.01-1.21,1.3-2.2,1.33c-1.04,0.03-2.08,0.09-3.11,0.13c-1.73,0.07-3.35,0.34-4.85,1.41c-0.71,0.5-1.81,0.5-2.74,0.63
		c-1.05,0.15-2,0.25-2.29,1.6c-0.15,0.69-0.71,1.34-1.2,1.91c-2.53,2.88-5.02,5.81-7.7,8.56c-2.47,2.55-5.16,4.89-7.74,7.32
		c-0.11,0.1-0.2,0.22-0.42,0.48c2.3-0.18,2.83,1.9,4.12,2.94c0.69,0.55,0.32,1.58-0.2,2.31c-1.59,2.21-2.63,4.63-2.95,7.36
		c-0.13,1.08-0.89,1.56-1.85,1.78c-0.92,0.21-1.47-0.25-1.94-1.03c-0.18-0.3-0.79-0.6-1.1-0.52c-0.67,0.17-1.44,0.44-1.9,0.92
		c-1.74,1.86-3.41,3.8-5.03,5.78c-0.46,0.56-0.65,1.34-0.97,2.01c0.06,0.14,0.12,0.27,0.18,0.41c0.92-0.18,1.84-0.34,2.75-0.56
		c1.04-0.25,2.05-0.64,3.1-0.84c0.98-0.19,1.62,0.57,1.38,1.52c-0.04,0.15-0.21,0.28-0.21,0.42c0,0.51,0.27,1.28,0.04,1.48
		c-0.71,0.6-1.59,1.02-2.44,1.42c-0.44,0.21-0.97,0.23-1.6,0.37c0.27,0.39,0.44,0.65,0.63,0.9c0.83,1.12,0.84,1.76-0.12,2.74
		c-0.64,0.65-1.06,1.27-0.56,2.2c0.12,0.23,0.15,0.56,0.09,0.82c-0.23,1.03-0.64,1.95-0.18,3.11c0.36,0.91,0.2,2.03,0.27,3.05
		c0.84-0.22,1.67-0.49,2.52-0.64c1.29-0.22,2.34,0.07,3.3,1.15c0.53,0.59,1.6,0.8,2.46,0.98c0.97,0.2,1.4,0.69,1.36,1.63
		c-0.26,7.14,1.22,14.06,2.54,21.01c0.71,3.76,1.13,7.6,2.08,11.3c1.12,4.36,2.73,8.59,3.98,12.92c0.53,1.84,1.24,3.52,2.44,5.01
		c0.96,1.2,1.48,1.28,2.84,0.52c0.89-0.5,1.85-0.95,2.83-1.17c2.06-0.45,3.64,0.43,4.85,2.1c0.31,0.43,0.73,0.87,1.2,1.1
		c1.58,0.77,1.83,1.12,1.64,2.92c0.37-0.31,0.68-0.6,1.02-0.86c1.27-1,2.44-0.86,3.16,0.56c0.58,1.14,1.36,1.76,2.58,2.07
		c0.71,0.18,1.37,0.65,1.97,1.1c1.15,0.86,1.41,1.95,1.05,3.39c-0.59,2.35-1.16,4.77-0.38,7.19c0.26,0.79,0.74,1.55,1.28,2.19
		c1.37,1.61,2.9,3.07,4.22,4.71c1.02,1.26,1.16,2.73,0.41,4.28c-0.28,0.58-0.38,1.33-0.32,1.98c0.22,2.27-0.87,3.75-2.72,4.78
		c-0.62,0.35-0.81,0.71-0.7,1.41c0.24,1.58,0.34,3.18,0.59,4.75c0.09,0.57,0.43,1.11,0.72,1.62c1.14,2.05,1.88,4.12,1,6.51
		c-0.34,0.92-0.35,1.96-0.54,2.94c-0.2,1.02-0.75,1.48-1.87,1.32c-1.86-0.26-3.66-0.09-5.22,1.14c-2.32,1.83-4.89,2.01-7.58,1.02
		c-1.27-0.47-2.26-0.13-3.08,0.88c-1.42,1.73-2.88,3.43-4.25,5.2c-1.04,1.33-2.32,2.03-4.01,2.01c-0.4,0-0.8,0.01-1.19,0.07
		c-1.35,0.2-1.93,0.93-1.86,2.3c0.12,2.2-0.74,3.21-2.9,3.61c-2.18,0.4-4.34,0.94-6.52,1.36c-0.42,0.08-0.9-0.11-1.36-0.17
		c-0.19,0.68-0.26,1.49-0.64,2.1c-0.4,0.65-1.01,1.27-1.68,1.61c-0.84,0.44-1.5,0.83-1.88,1.8c-0.22,0.57-1.02,0.91-1.56,1.36
		c-0.96,0-1.92,0-2.88,0c-0.26-0.08-0.52-0.22-0.78-0.22c-1.25,0.01-2.26-0.47-3.04-1.41c-0.61-0.73-1.37-0.95-2.32-1
		c-0.97-0.06-1.97-0.3-2.89-0.63c-1.21-0.44-2.26-0.41-3.2,0.53c-1.28,1.28-2.63,1.44-4.13,0.35c-0.34-0.25-0.8-0.37-1.22-0.47
		c-2.83-0.7-5.68-1.29-8.48-2.07c-3.49-0.98-6.95-2.1-10.4-3.21c-3.42-1.1-6.83-2.23-10.23-3.39c-2.48-0.85-4.29-2.39-5.01-5.04
		c-0.11-0.4-0.59-0.83-1-1c-0.88-0.36-1.82-0.72-2.75-0.77c-1.16-0.07-2.39-0.02-3.49,0.33c-2.16,0.69-4.27,1.58-6.37,2.45
		c-2.57,1.06-4.88,2.5-6.93,4.43c-0.85,0.8-1.95,1.4-3.04,1.85c-0.68,0.28-1.61,0.33-2.3,0.1c-1.07-0.36-1.85-0.16-2.62,0.55
		c-2.65,2.46-5.62,2.99-9.1,1.87c-1.23-0.39-2.75-0.03-4.11,0.19c-4.99,0.8-9.96,1.45-14.99,0.3c-0.07-0.02-0.14-0.07-0.04-0.21
		c-0.46-0.79-0.91-1.59-1.38-2.37c-1.22-2.02-0.68-3.29,1.65-3.85c0.5-0.12,1.02-0.2,1.51-0.38c1-0.37,1.64-1.11,1.48-2.2
		c-0.61-4.12,1.41-7.13,4.05-9.91c0.11-0.12,0.23-0.23,0.34-0.34c2.55-2.55,2.49-4.15,0.01-6.91c-0.51-0.57-0.54-1.58-0.77-2.4
		c-0.21-0.74-0.25-1.56-0.59-2.23c-0.84-1.67-0.74-2.19,0.53-3.59c0.4-0.44,0.58-1.31,0.5-1.93c-0.28-1.93,0.44-3.44,1.66-4.8
		c1.37-1.52,2.73-3.06,4.2-4.49c0.47-0.46,1.23-0.78,1.9-0.85c1.1-0.12,1.38-0.66,1.21-1.6c-0.1-0.55-0.29-1.08-0.41-1.62
		c-0.37-1.68,0.31-2.75,2-3.01c0.93-0.14,1.38-0.59,1.31-1.5c-0.11-1.47,0.25-2.67,1.3-3.82c0.55-0.6,0.59-1.68,0.82-2.56
		c0.22-0.84,0.2-1.82,0.63-2.52c0.38-0.62,1.18-1.2,1.89-1.35c0.76-0.16,1.07-0.41,1.29-1.1c0.68-2.12,2.07-3.46,4.34-3.79
		c1.19-0.17,1.83-0.99,2-2.08c0.17-1.1,0.14-2.23,0.18-3.34c0.05-1.67,0.62-2.21,2.25-2.54c1.75-0.35,3.48-0.82,5.19-1.34
		c0.93-0.28,1.61-0.91,2.05-1.88c0.22-0.47,0.92-0.84,1.48-1.01c0.71-0.22,1.5-0.19,2.26-0.27c1.46-0.15,2.77-0.45,3.68-1.85
		c0.72-1.11,2.06-1.34,3.32-1.36c0.91-0.01,1.23-0.44,1.64-1.21c0.84-1.58,2.23-2.89,2.16-4.88c1.34-0.71,1.04-1.93,1.07-3.12
		c0.04-1.66-0.22-3.24-1.53-4.3c-2.52-2.03-4.15-4.74-5.9-7.34c-0.56-0.84-0.71-2.02-0.83-3.07c-0.2-1.78-0.53-3.4-1.84-4.81
		c-0.58-0.62-0.9-1.73-0.9-2.62c0.02-3.59,0.18-7.19,0.37-10.78c0.1-1.92,0.76-2.58,2.7-2.91c1.24-0.21,2.14-0.66,2.56-1.98
		c0.21-0.65,0.81-1.17,1.22-1.76c0.41-0.59,0.89-1.15,1.19-1.79c0.81-1.76,0.2-3.14-1.6-3.8c-0.64-0.23-1.3-0.4-1.92-0.66
		c-1.77-0.76-2.45-2.01-2.18-3.92c0.16-1.19-0.76-2.56-1.88-2.97c-0.53-0.19-1.28-0.5-1.42-0.92c-0.43-1.28-1.2-2.21-2.17-3.08
		c-0.29-0.26-0.42-0.77-0.49-1.19c-0.26-1.46-1.02-2.47-2.43-3c-0.45-0.17-0.88-0.39-1.3-0.62c-1.67-0.92-2.01-1.79-1.52-3.65
		c0.25-0.97,0.7-2.01-0.6-2.71c-0.28-0.15-0.37-0.68-0.54-1.04c-0.23-0.5-0.34-1.13-0.71-1.5c-1.24-1.24-1.33-2.67-0.87-4.21
		c0.27-0.9,0.05-1.6-0.48-2.35c-0.4-0.57-0.7-1.25-0.87-1.93c-0.1-0.37,0.02-0.96,0.27-1.22c1.68-1.71,3.27-3.58,5.77-4.14
		c1.4-0.31,2.44-1.15,3.28-2.27c0.81-1.06,1.75-1.8,3.2-1.81c0.37,0,0.75-0.53,1.23-0.91c-1.57-0.23-2.82-0.35-4.04-0.62
		c-1.65-0.36-2.42-1.4-2.47-3.07c-0.02-0.72-0.22-1.45-0.13-2.14c0.1-0.7,0.29-1.52,0.73-2.02c1-1.13,1.1-1.93,0.22-3.12
		c-0.33-0.45-0.64-1.12-0.56-1.62c0.11-0.73,0.96-0.4,1.43-0.59c1.09-0.46,1.84,0.09,2.72,0.42c1.98,0.75,3.96,1.48,5.94,2.22
		c3.96,1.48,7.91,2.96,12.01,4.5c0.46-1.69,0.38-3.27-0.35-4.63c-0.64-1.19-1.7-2.17-2.6-3.21c-0.85-0.99-1.8-1.91-2.58-2.96
		c-0.46-0.63-0.56-1.4,0.25-2.02c0.26-0.2,0.38-0.59,0.55-0.91c0.89-1.69,2.18-2.82,4.14-3.1c0.46-0.07,0.89-0.3,1.34-0.47
		c0.41-0.15,0.85-0.51,1.22-0.45c2.04,0.38,3.35-0.71,4.77-1.93c1.42-1.22,2.7-1.01,4.02,0.35c0.65,0.67,1.08,0.67,1.79,0.06
		c0.61-0.52,1.41-0.88,2.19-1.13c0.64-0.2,1.06-0.46,0.92-1.13c-0.51-2.48,1.34-3.57,2.95-4.44c1.98-1.07,4.26-1.57,6.35-2.45
		c1.68-0.71,3.41-1.44,4.86-2.51c4.56-3.33,9.61-5.67,14.7-7.99c1.64-0.75,3.1-1.93,4.57-3.01c1.14-0.84,2.3-0.94,3.46-0.29
		c1.32,0.74,2.55,1.64,3.8,2.49c1.96,1.33,3.99,2.5,6.4,2.85c1.31,0.19,2.48-0.04,3.43-0.97c1.22-1.2,2.53-2.35,3.59-3.68
		c1.9-2.38,4.04-4.11,7.32-3.61c0.22,0.03,0.46-0.04,0.76-0.07c0.03-1.28,0.27-2.41,1.49-3.1c0.21-0.12,0.29-0.72,0.22-1.06
		c-0.42-2,0.86-3.41,1.77-4.89c0.24-0.39,1.17-0.6,1.74-0.52c3.11,0.43,5.22-1.68,7.64-2.96c0.3-0.16,0.4-1,0.34-1.49
		c-0.24-1.91,0.12-2.56,1.81-3.44c0.57-0.3,1.02-0.88,1.44-1.4c1.04-1.3,1.98-2.67,3.08-3.91c0.19-0.22,1.19-0.12,1.41,0.15
		c0.53,0.66,0.94,1.48,1.17,2.31c0.24,0.83,0.57,1.16,1.47,1.18c1.01,0.02,2.02,0.29,3.03,0.46c0.86,0.15,1.71,0.36,2.58,0.46
		c1.29,0.15,2.06,0.86,1.83,2.13c-0.28,1.55,0.26,2.45,1.57,3.23c0.68,0.41,1.1,1.27,1.73,2.04c2.02-0.77,3.96,0.08,5.86,1.06
		c0.96,0.5,1.66,1.18,1.59,2.44c-0.05,0.98,1.95,2.48,3.27,2.49c0.8,0.01,1.59-0.11,2.39-0.17c1.62-0.12,3.01,0.5,3.8,1.84
		c0.61,1.03,1.45,1.44,2.45,1.68c1.29,0.31,1.91,1.13,2.01,2.36c0.12,1.42,0.2,2.85,0.19,4.28c-0.01,1.04,0.42,1.8,1.37,1.99
		c2.89,0.56,3.36,2.69,3.53,5.09c0.06,0.82,0.18,1.63,0.27,2.44C832.85,811.91,832.85,812.55,832.85,813.19z M702.51,823.47
		c-0.66,0.58-1.36,1.06-1.88,1.69c-0.8,0.96-1.73,1.32-2.96,1.12c-0.53-0.09-1.11-0.05-1.64,0.08c-0.95,0.23-1.88,0.6-2.82,0.86
		c-1.51,0.42-2.24,1.63-2.48,2.95c-0.1,0.52,0.83,1.28,1.36,1.86c0.98,1.09,2.24,1.99,2.98,3.22c1.37,2.26,1.82,4.79,0.77,7.34
		c-0.66,1.6-1.48,1.81-3.03,1.05c-0.32-0.16-0.65-0.3-0.99-0.43c-5.32-1.93-10.63-3.86-15.95-5.79c-0.23-0.08-0.47-0.14-0.5-0.15
		c-0.23,1.11-0.13,2.41-0.72,3.11c-0.93,1.08-1,2.08-0.93,3.35c0.06,0.97,0.45,1.47,1.31,1.51c1.05,0.05,2.11-0.11,3.17-0.09
		c0.63,0.01,1.44-0.02,1.84,0.34c0.32,0.29,0.24,1.14,0.18,1.73c-0.06,0.59-0.32,1.15-0.48,1.73c-0.39,1.46-0.81,1.85-2.33,1.91
		c-1.01,0.04-1.63,0.52-2.31,1.25c-0.9,0.98-1.98,1.84-3.11,2.53c-0.97,0.59-2.13,0.87-3.19,1.32c-0.25,0.11-0.47,0.37-0.63,0.61
		c-0.42,0.66-0.81,1.34-1.28,2.13c1.13,1.05,1.32,2.57,0.83,4.29c-0.15,0.5-0.21,1.2,0.03,1.61c0.79,1.35,1.76,2.6,2.62,3.91
		c0.23,0.35,0.45,0.78,0.45,1.17c0,0.83-0.04,1.7-0.27,2.49c-0.24,0.83,0.03,1.32,0.69,1.69c0.52,0.29,1.05,0.59,1.61,0.79
		c1.35,0.5,2.13,1.47,2.54,2.8c0.33,1.07,0.75,2.06,1.7,2.78c0.35,0.26,0.45,0.83,0.73,1.21c0.27,0.36,0.58,0.8,0.97,0.96
		c1.49,0.61,3.18,3.04,3.11,4.62c-0.09,2.01-0.07,2.04,1.85,2.71c0.53,0.18,1.06,0.36,1.56,0.6c1.82,0.84,2.87,2.52,2.46,4.46
		c-0.25,1.2-0.93,2.32-1.52,3.43c-0.43,0.8-1.25,1.43-1.51,2.26c-0.51,1.64-1.61,2.39-3.17,2.71c-1.62,0.33-1.82,0.56-1.88,2.22
		c-0.11,3.07-0.25,6.15-0.27,9.22c-0.01,0.66,0.24,1.5,0.68,1.94c1.19,1.18,1.62,2.59,1.82,4.18c0.16,1.26,0.2,2.7,0.85,3.69
		c1.49,2.25,3.25,4.34,5.04,6.36c0.91,1.02,1.94,1.88,2.19,3.33c0.05,0.26,0.3,0.52,0.51,0.72c0.84,0.81,1.08,2.09,0.6,2.78
		c-0.28,0.4-0.39,0.93-0.57,1.4c-0.26,0.67-0.35,1.61-0.84,1.94c-0.67,0.46-0.84,0.88-0.72,1.56c0.17,0.95-0.23,1.58-0.96,2.15
		c-0.45,0.35-0.95,0.83-1.1,1.34c-0.49,1.69-1.62,2.33-3.27,2.44c-0.64,0.04-1.53,0.22-1.83,0.67c-1.39,2.08-3.49,2.39-5.68,2.48
		c-1,0.04-1.76,0.19-2.16,1.26c-0.15,0.4-0.6,0.81-1.01,0.96c-2.08,0.78-4.16,1.59-6.3,2.16c-1.01,0.27-1.61,0.45-1.4,1.65
		c0.11,0.62,0.03,1.28-0.04,1.91c-0.3,2.6-1.53,4-4.09,4.6c-1.4,0.33-2.23,1.18-2.54,2.56c-0.25,1.12-0.84,2.01-2.01,2.08
		c-1.39,0.08-1.46,0.94-1.68,1.97c-0.29,1.38-0.59,2.84-1.3,4.02c-0.48,0.8-0.79,1.44-0.77,2.36c0.04,1.98-0.69,2.93-2.59,3.46
		c-0.68,0.19-0.81,0.5-0.61,1.13c0.18,0.57,0.35,1.16,0.4,1.74c0.15,1.73-0.92,3.3-2.48,3.41c-1.26,0.09-1.94,0.78-2.56,1.7
		c-0.45,0.66-0.8,1.51-1.43,1.89c-2.12,1.28-2.89,3.05-2.56,5.45c0.13,0.97-0.03,1.88-0.97,2.53c-0.22,0.16-0.31,0.87-0.15,1.15
		c0.57,1.01,0.89,2.03,0.7,3.2c-0.05,0.31,0.08,0.7,0.24,0.99c0.66,1.2,1.44,2.34,2.04,3.57c0.85,1.73,0.49,3.35-0.77,4.77
		c-0.42,0.48-0.87,0.93-1.33,1.38c-2.54,2.45-4.29,5.19-3.94,8.97c0.2,2.09-1.26,3.75-3.39,4.33c-0.52,0.14-1.07,0.22-1.63,0.33
		c0.38,0.84,0.73,1.61,1.14,2.52c4.29,0.56,8.66,0.27,12.99-0.59c2.14-0.43,4.21-0.72,6.35,0.09c1.81,0.69,3.55,0.26,5.09-0.89
		c0.48-0.36,0.95-0.74,1.41-1.12c1.13-0.94,2.37-1.18,3.81-0.8c0.6,0.16,1.36,0.11,1.92-0.13c0.78-0.35,1.45-0.97,2.16-1.48
		c1.55-1.12,2.99-2.46,4.67-3.3c2.82-1.4,5.81-2.43,8.69-3.71c1.7-0.75,3.39-0.83,5.14-0.38c0.89,0.23,1.76,0.55,2.65,0.73
		c1.3,0.26,2.01,1.02,2.31,2.28c0.47,1.97,1.81,3.16,3.66,3.78c3.82,1.3,7.63,2.62,11.48,3.81c3.85,1.18,7.74,2.21,11.62,3.29
		c2.38,0.66,4.82,1.16,7.13,2.02c1.14,0.42,1.84,0.43,2.71-0.38c1.29-1.2,2.79-1.33,4.46-0.74c1.03,0.37,2.15,0.55,3.25,0.68
		c1.24,0.14,2.33,0.46,3.12,1.51c0.57,0.76,3.67,1.47,4.55,1.07c0.19-0.09,0.41-0.31,0.44-0.5c0.21-1.22,1.12-1.65,2.12-2.05
		c1.37-0.55,1.82-1.34,1.65-2.81c-0.03-0.24-0.14-0.5-0.08-0.71c0.18-0.62,0.26-1.5,0.69-1.75c0.68-0.41,1.57-0.38,2.26,0.4
		c0.24,0.27,0.82,0.41,1.22,0.36c1.74-0.23,3.46-0.59,5.2-0.82c1.4-0.19,1.61-0.38,1.58-1.81c-0.05-2.41,1.55-4.1,3.95-4.19
		c0.52-0.02,1.07,0.06,1.55-0.09c0.62-0.2,1.33-0.46,1.73-0.92c1.58-1.8,3.06-3.69,4.57-5.55c1.41-1.74,3.1-2.3,5.21-1.55
		c2.17,0.77,4.23,0.69,5.99-0.8c1.6-1.36,3.45-1.53,5.37-1.61c0.26-0.01,0.53-0.03,0.83-0.05c0.12-0.73,0.11-1.45,0.37-2.05
		c0.93-2.2,0.17-4.03-1.07-5.82c-0.31-0.44-0.55-1-0.62-1.53c-0.23-1.7-0.23-3.44-0.58-5.11c-0.39-1.83,0.26-2.88,1.84-3.65
		c1.18-0.57,1.74-1.52,1.61-2.92c-0.07-0.85,0.02-1.81,0.38-2.57c0.53-1.12,0.39-2.05-0.36-2.88c-1.28-1.41-2.72-2.69-3.96-4.13
		c-0.68-0.78-1.26-1.73-1.61-2.7c-0.96-2.68-0.23-5.38,0.18-8.05c0.22-1.4,0.09-1.9-1.29-2.29c-1.65-0.46-3.04-1.18-3.77-2.9
		c-1.27,0.97-2.36,2.33-4.08,1.35c-1.31-0.74-0.82-1.98-0.64-2.81c-0.85-0.85-1.58-1.5-2.22-2.23c-1.31-1.48-2.63-1.74-4.36-0.75
		c-0.42,0.24-0.81,0.51-1.24,0.73c-1.58,0.78-3.23,0.56-4.29-0.61c-1.57-1.74-2.88-3.61-3.27-6.02c-0.16-0.98-0.53-1.92-0.83-2.87
		c-1.99-6.14-4.29-12.18-5.05-18.69c-0.93-7.88-3.15-15.6-3.01-23.63c0-0.28-0.39-0.67-0.69-0.84c-0.69-0.37-1.67-0.44-2.12-0.98
		c-1.11-1.3-2.32-1.25-3.5-0.45c-1.85,1.25-3.64-0.04-3.47-2.24c0.07-0.94-0.15-1.89-0.18-2.84c-0.06-1.81-0.13-3.62-0.09-5.42
		c0.01-0.46,0.42-0.93,0.67-1.38c0.17-0.3,0.4-0.57,0.58-0.83c-1.33-1.93-1.35-2.21-0.38-3.81c-0.55-0.06-1.11-0.07-1.64-0.19
		c-1.88-0.4-2.66-1.57-1.92-3.34c0.59-1.41,1.48-2.73,2.41-3.96c1.01-1.34,2.13-2.61,3.35-3.75c0.87-0.82,1.92-1.53,3.01-2.02
		c1.24-0.55,2.55-0.27,3.59,0.34c1.12-2.47,2.2-4.89,3.32-7.36c-1-1.12-2.28-2.15-4.17-1.83c-0.04,0.01-0.09,0.01-0.12-0.01
		c-0.54-0.32-1.33-0.55-1.52-1.01c-0.14-0.33,0.42-1.11,0.84-1.5c1-0.92,2.19-1.63,3.14-2.58c3.87-3.86,7.75-7.72,11.49-11.71
		c1.52-1.63,3.31-3.15,3.89-5.5c0.08-0.32,0.51-0.66,0.85-0.79c0.96-0.36,1.96-0.63,2.94-0.92c0.76-0.22,1.64-0.26,2.25-0.69
		c1.29-0.89,2.69-1.16,4.18-1.25c1.28-0.08,2.56-0.14,3.82-0.3c0.36-0.04,0.81-0.3,0.99-0.6c1.03-1.71,2.85-2.06,4.3-0.63
		c0.85,0.84,1.67,0.79,2.59,0.4c0.47-0.2,0.91-0.5,1.33-0.8c4.92-3.4,9.58-7.19,15-9.85c3.12-1.53,6.12-3.3,9.58-3.99
		c0.74-0.15,1.44-0.48,2.17-0.68c1.23-0.32,1.65-1.12,1.51-2.33c-0.11-0.99-0.14-1.99-0.28-2.98c-0.17-1.31,0.45-2.12,1.52-2.72
		c1.79-1,3.71-1.42,5.74-1.49c2.16-0.07,4.36-0.14,6.13-1.62c0.4-0.34,0.8-0.96,0.8-1.45c0.02-1.67-0.14-3.34-0.22-5.02
		c-0.05-1.06-0.46-1.72-1.62-2.02c-1.45-0.38-2.66-1.3-2.95-2.86c-0.27-1.48-0.31-3.01-0.31-4.52c0-0.8-0.18-1.22-1-1.48
		c-1.25-0.39-2.48-0.86-3.02-2.3c-0.13-0.36-0.7-0.69-1.12-0.78c-0.64-0.14-1.36-0.18-2.01-0.06c-2.93,0.55-4.88-0.96-6.58-3.02
		c-0.17-0.2-0.36-0.53-0.31-0.75c0.24-1.16-0.54-1.53-1.36-1.93c-1.09-0.53-2.16-0.69-3.36-0.35c-1.65,0.46-2.13,0.13-2.86-1.46
		c-0.19-0.42-0.48-0.89-0.86-1.11c-1.85-1.05-2.75-2.51-2.13-4.75c-2.27-0.36-4.41-0.71-6.56-1.03c-1.06-0.16-1.75-0.71-2.09-1.72
		c-0.11-0.34-0.22-0.67-0.42-1.25c-0.61,0.93-1.28,1.63-1.56,2.46c-0.37,1.08-0.97,1.7-2.04,1.93c-0.99,0.21-1.3,0.75-1,1.72
		c0.47,1.49-0.17,2.58-1.4,3.36c-0.88,0.56-1.85,0.96-2.69,1.57c-1.51,1.08-3.08,1.76-5,1.45c-0.45-0.07-1.26,0.24-1.42,0.6
		c-0.43,0.94-0.76,1.99-0.81,3.01c-0.06,1.03-0.25,1.82-1.05,2.53c-0.42,0.37-0.77,1.01-0.83,1.57c-0.18,1.69-0.69,2.36-2.29,2.12
		c-2.77-0.41-4.56,0.77-6.21,2.89c-1.36,1.74-3.12,3.19-4.86,4.57c-0.68,0.54-1.78,0.77-2.69,0.77c-2.82,0-5.43-0.77-7.74-2.47
		c-1.38-1.02-2.83-1.96-4.29-2.85c-0.36-0.22-1.14-0.33-1.37-0.12c-2.75,2.53-6.25,3.65-9.47,5.3c-3.15,1.62-6.28,3.33-9.23,5.28
		c-1.92,1.27-3.81,2.45-6,3.18c-1.97,0.66-3.96,1.29-5.81,2.2c-1.2,0.59-2.37,1.44-2.17,3.24c0.15,1.36-0.66,2.08-1.96,2.49
		c-0.73,0.23-1.47,0.58-2.07,1.05c-1.44,1.12-2.73,1.15-4.08-0.02C703.28,824.1,702.94,823.83,702.51,823.47z"/>
					<path class="cuiaba" d="M702.51,823.47c0.43,0.36,0.77,0.63,1.1,0.91c1.36,1.17,2.64,1.14,4.08,0.02c0.6-0.47,1.34-0.82,2.07-1.05
		c1.29-0.41,2.11-1.13,1.96-2.49c-0.2-1.81,0.97-2.65,2.17-3.24c1.85-0.91,3.85-1.55,5.81-2.2c2.19-0.73,4.08-1.91,6-3.18
		c2.95-1.95,6.08-3.67,9.23-5.28c3.22-1.65,6.73-2.77,9.47-5.3c0.24-0.22,1.01-0.1,1.37,0.12c1.47,0.89,2.91,1.83,4.29,2.85
		c2.32,1.7,4.93,2.47,7.74,2.47c0.91,0,2.01-0.23,2.69-0.77c1.74-1.38,3.51-2.84,4.86-4.57c1.66-2.12,3.44-3.3,6.21-2.89
		c1.61,0.24,2.11-0.43,2.29-2.12c0.06-0.56,0.41-1.2,0.83-1.57c0.8-0.71,0.99-1.5,1.05-2.53c0.06-1.02,0.39-2.07,0.81-3.01
		c0.16-0.35,0.97-0.67,1.42-0.6c1.93,0.31,3.49-0.37,5-1.45c0.84-0.6,1.82-1.01,2.69-1.57c1.23-0.78,1.87-1.86,1.4-3.36
		c-0.3-0.97,0-1.51,1-1.72c1.07-0.22,1.67-0.85,2.04-1.93c0.28-0.83,0.95-1.53,1.56-2.46c0.2,0.58,0.31,0.92,0.42,1.25
		c0.34,1.01,1.03,1.56,2.09,1.72c2.15,0.32,4.29,0.67,6.56,1.03c-0.62,2.24,0.29,3.7,2.13,4.75c0.38,0.21,0.67,0.69,0.86,1.11
		c0.73,1.59,1.21,1.92,2.86,1.46c1.2-0.34,2.27-0.18,3.36,0.35c0.82,0.4,1.6,0.77,1.36,1.93c-0.05,0.22,0.15,0.55,0.31,0.75
		c1.71,2.06,3.66,3.57,6.58,3.02c0.65-0.12,1.36-0.08,2.01,0.06c0.42,0.09,0.99,0.42,1.12,0.78c0.53,1.44,1.76,1.91,3.02,2.3
		c0.82,0.26,0.99,0.68,1,1.48c0.01,1.51,0.04,3.04,0.31,4.52c0.28,1.55,1.5,2.48,2.95,2.86c1.16,0.3,1.56,0.96,1.62,2.02
		c0.09,1.67,0.24,3.34,0.22,5.02c-0.01,0.49-0.4,1.12-0.8,1.45c-1.77,1.47-3.97,1.55-6.13,1.62c-2.03,0.06-3.95,0.49-5.74,1.49
		c-1.08,0.6-1.7,1.41-1.52,2.72c0.13,0.99,0.16,1.99,0.28,2.98c0.14,1.21-0.28,2.01-1.51,2.33c-0.73,0.19-1.43,0.53-2.17,0.68
		c-3.46,0.69-6.46,2.46-9.58,3.99c-5.42,2.66-10.08,6.45-15,9.85c-0.43,0.29-0.86,0.6-1.33,0.8c-0.92,0.39-1.74,0.44-2.59-0.4
		c-1.45-1.43-3.27-1.07-4.3,0.63c-0.18,0.3-0.63,0.56-0.99,0.6c-1.27,0.16-2.55,0.22-3.82,0.3c-1.49,0.1-2.89,0.36-4.18,1.25
		c-0.61,0.43-1.5,0.46-2.25,0.69c-0.99,0.29-1.98,0.56-2.94,0.92c-0.34,0.13-0.77,0.47-0.85,0.79c-0.58,2.35-2.37,3.88-3.89,5.5
		c-3.73,3.99-7.61,7.85-11.49,11.71c-0.96,0.95-2.15,1.66-3.14,2.58c-0.42,0.39-0.98,1.16-0.84,1.5c0.19,0.47,0.98,0.69,1.52,1.01
		c0.03,0.02,0.08,0.02,0.12,0.01c1.89-0.32,3.17,0.72,4.17,1.83c-1.11,2.47-2.2,4.88-3.32,7.36c-1.03-0.62-2.35-0.9-3.59-0.34
		c-1.09,0.49-2.14,1.2-3.01,2.02c-1.22,1.14-2.35,2.41-3.35,3.75c-0.93,1.23-1.82,2.55-2.41,3.96c-0.73,1.77,0.04,2.94,1.92,3.34
		c0.53,0.11,1.08,0.13,1.64,0.19c-0.97,1.6-0.95,1.88,0.38,3.81c-0.18,0.26-0.41,0.53-0.58,0.83c-0.26,0.45-0.66,0.91-0.67,1.38
		c-0.04,1.81,0.03,3.61,0.09,5.42c0.03,0.95,0.25,1.91,0.18,2.84c-0.16,2.2,1.62,3.49,3.47,2.24c1.18-0.8,2.39-0.85,3.5,0.45
		c0.46,0.54,1.43,0.61,2.12,0.98c0.3,0.16,0.7,0.56,0.69,0.84c-0.14,8.03,2.09,15.74,3.01,23.63c0.77,6.51,3.06,12.54,5.05,18.69
		c0.31,0.95,0.68,1.89,0.83,2.87c0.39,2.41,1.7,4.28,3.27,6.02c1.06,1.18,2.71,1.39,4.29,0.61c0.43-0.21,0.82-0.49,1.24-0.73
		c1.73-0.99,3.06-0.73,4.36,0.75c0.64,0.73,1.37,1.39,2.22,2.23c-0.18,0.82-0.66,2.07,0.64,2.81c1.72,0.97,2.82-0.38,4.08-1.35
		c0.73,1.72,2.12,2.43,3.77,2.9c1.38,0.39,1.51,0.89,1.29,2.29c-0.42,2.67-1.14,5.37-0.18,8.05c0.35,0.97,0.93,1.92,1.61,2.7
		c1.25,1.44,2.68,2.72,3.96,4.13c0.75,0.83,0.88,1.76,0.36,2.88c-0.35,0.75-0.45,1.72-0.38,2.57c0.12,1.41-0.43,2.35-1.61,2.92
		c-1.58,0.76-2.24,1.82-1.84,3.65c0.36,1.67,0.35,3.41,0.58,5.11c0.07,0.53,0.31,1.08,0.62,1.53c1.24,1.79,1.99,3.62,1.07,5.82
		c-0.25,0.6-0.25,1.32-0.37,2.05c-0.3,0.02-0.57,0.04-0.83,0.05c-1.93,0.08-3.77,0.25-5.37,1.61c-1.76,1.49-3.82,1.56-5.99,0.8
		c-2.11-0.75-3.81-0.18-5.21,1.55c-1.51,1.86-2.99,3.75-4.57,5.55c-0.41,0.46-1.11,0.72-1.73,0.92c-0.48,0.15-1.03,0.07-1.55,0.09
		c-2.4,0.09-4,1.78-3.95,4.19c0.03,1.43-0.18,1.62-1.58,1.81c-1.74,0.24-3.46,0.59-5.2,0.82c-0.39,0.05-0.98-0.08-1.22-0.36
		c-0.69-0.78-1.58-0.81-2.26-0.4c-0.43,0.26-0.51,1.14-0.69,1.75c-0.06,0.21,0.05,0.47,0.08,0.71c0.17,1.47-0.28,2.26-1.65,2.81
		c-0.99,0.4-1.91,0.84-2.12,2.05c-0.03,0.19-0.25,0.41-0.44,0.5c-0.88,0.4-3.97-0.31-4.55-1.07c-0.79-1.05-1.88-1.37-3.12-1.51
		c-1.1-0.12-2.21-0.31-3.25-0.68c-1.67-0.59-3.17-0.47-4.46,0.74c-0.87,0.81-1.57,0.81-2.71,0.38c-2.31-0.86-4.75-1.36-7.13-2.02
		c-3.88-1.08-7.77-2.11-11.62-3.29c-3.85-1.18-7.66-2.51-11.48-3.81c-1.85-0.63-3.19-1.81-3.66-3.78c-0.3-1.26-1.02-2.02-2.31-2.28
		c-0.9-0.18-1.76-0.51-2.65-0.73c-1.75-0.45-3.44-0.37-5.14,0.38c-2.88,1.28-5.88,2.31-8.69,3.71c-1.69,0.84-3.12,2.19-4.67,3.3
		c-0.71,0.51-1.37,1.13-2.16,1.48c-0.56,0.25-1.32,0.29-1.92,0.13c-1.44-0.38-2.68-0.15-3.81,0.8c-0.46,0.38-0.92,0.76-1.41,1.12
		c-1.55,1.15-3.29,1.58-5.09,0.89c-2.14-0.82-4.21-0.52-6.35-0.09c-4.33,0.87-8.69,1.16-12.99,0.59c-0.41-0.91-0.76-1.68-1.14-2.52
		c0.56-0.11,1.1-0.18,1.63-0.33c2.13-0.58,3.59-2.25,3.39-4.33c-0.35-3.77,1.4-6.52,3.94-8.97c0.46-0.44,0.91-0.9,1.33-1.38
		c1.25-1.42,1.61-3.04,0.77-4.77c-0.6-1.23-1.38-2.37-2.04-3.57c-0.16-0.29-0.29-0.68-0.24-0.99c0.19-1.17-0.13-2.19-0.7-3.2
		c-0.16-0.28-0.07-1,0.15-1.15c0.94-0.65,1.11-1.57,0.97-2.53c-0.33-2.4,0.44-4.17,2.56-5.45c0.63-0.38,0.98-1.23,1.43-1.89
		c0.63-0.92,1.3-1.61,2.56-1.7c1.56-0.11,2.63-1.68,2.48-3.41c-0.05-0.59-0.22-1.18-0.4-1.74c-0.2-0.63-0.06-0.94,0.61-1.13
		c1.91-0.53,2.64-1.49,2.59-3.46c-0.02-0.92,0.29-1.57,0.77-2.36c0.71-1.18,1.01-2.64,1.3-4.02c0.22-1.03,0.29-1.9,1.68-1.97
		c1.17-0.06,1.77-0.95,2.01-2.08c0.3-1.38,1.13-2.23,2.54-2.56c2.56-0.6,3.79-2,4.09-4.6c0.07-0.63,0.15-1.3,0.04-1.91
		c-0.21-1.2,0.39-1.38,1.4-1.65c2.14-0.57,4.22-1.38,6.3-2.16c0.41-0.15,0.86-0.56,1.01-0.96c0.4-1.07,1.16-1.21,2.16-1.26
		c2.19-0.09,4.28-0.4,5.68-2.48c0.3-0.44,1.19-0.62,1.83-0.67c1.66-0.11,2.78-0.75,3.27-2.44c0.15-0.51,0.65-0.99,1.1-1.34
		c0.73-0.57,1.14-1.2,0.96-2.15c-0.12-0.68,0.05-1.1,0.72-1.56c0.49-0.33,0.57-1.27,0.84-1.94c0.19-0.47,0.29-1,0.57-1.4
		c0.48-0.69,0.24-1.98-0.6-2.78c-0.21-0.2-0.47-0.46-0.51-0.72c-0.25-1.44-1.28-2.3-2.19-3.33c-1.8-2.02-3.56-4.11-5.04-6.36
		c-0.65-0.99-0.69-2.43-0.85-3.69c-0.2-1.59-0.63-3-1.82-4.18c-0.44-0.44-0.69-1.28-0.68-1.94c0.02-3.07,0.16-6.15,0.27-9.22
		c0.06-1.66,0.27-1.89,1.88-2.22c1.56-0.32,2.66-1.06,3.17-2.71c0.26-0.83,1.08-1.46,1.51-2.26c0.59-1.1,1.27-2.23,1.52-3.43
		c0.41-1.95-0.64-3.62-2.46-4.46c-0.51-0.23-1.04-0.41-1.56-0.6c-1.92-0.67-1.94-0.7-1.85-2.71c0.07-1.58-1.62-4.01-3.11-4.62
		c-0.39-0.16-0.7-0.6-0.97-0.96c-0.28-0.38-0.38-0.95-0.73-1.21c-0.95-0.72-1.37-1.71-1.7-2.78c-0.41-1.33-1.19-2.3-2.54-2.8
		c-0.56-0.21-1.09-0.5-1.61-0.79c-0.66-0.37-0.93-0.86-0.69-1.69c0.23-0.79,0.27-1.66,0.27-2.49c0-0.39-0.23-0.82-0.45-1.17
		c-0.86-1.31-1.83-2.56-2.62-3.91c-0.24-0.41-0.17-1.11-0.03-1.61c0.5-1.72,0.3-3.24-0.83-4.29c0.47-0.79,0.85-1.48,1.28-2.13
		c0.16-0.24,0.38-0.5,0.63-0.61c1.06-0.46,2.22-0.73,3.19-1.32c1.13-0.69,2.21-1.55,3.11-2.53c0.67-0.73,1.3-1.21,2.31-1.25
		c1.52-0.06,1.94-0.44,2.33-1.91c0.15-0.58,0.42-1.14,0.48-1.73c0.06-0.59,0.14-1.43-0.18-1.73c-0.4-0.36-1.21-0.33-1.84-0.34
		c-1.06-0.02-2.12,0.14-3.17,0.09c-0.86-0.04-1.25-0.54-1.31-1.51c-0.07-1.27,0-2.28,0.93-3.35c0.6-0.69,0.5-1.99,0.72-3.11
		c0.03,0.01,0.27,0.06,0.5,0.15c5.32,1.93,10.64,3.86,15.95,5.79c0.34,0.12,0.67,0.27,0.99,0.43c1.54,0.76,2.36,0.56,3.03-1.05
		c1.05-2.55,0.6-5.08-0.77-7.34c-0.74-1.22-2-2.13-2.98-3.22c-0.53-0.59-1.45-1.34-1.36-1.86c0.25-1.32,0.97-2.53,2.48-2.95
		c0.95-0.26,1.87-0.63,2.82-0.86c0.52-0.13,1.11-0.16,1.64-0.08c1.23,0.2,2.16-0.16,2.96-1.12
		C701.15,824.53,701.85,824.05,702.51,823.47z"/>
				</g>
				<g>
					<path d="M811.79,866.43c3.66,0.69,3.76,2.86,3.39,6.17c-0.29,2.57-0.61,5.13-0.98,7.69c-0.26,1.86-0.55,3.72-0.97,5.55
		c-0.26,1.11-0.68,2.22-1.22,3.22c-1.49,2.74-2.98,5.44-3.69,8.53c-0.68,2.96-2.37,5.48-4.06,7.97c-0.4,0.59-0.77,1.2-1.31,2.05
		c2.08,0.46,3.96,0.86,5.84,1.31c3.65,0.87,7.28,1.77,10.93,2.67c1.09,0.27,1.58,0.89,1.46,2.07c-0.45,4.17-0.87,8.34-1.24,12.52
		c-0.08,0.9,0.02,1.84,0.16,2.73c0.2,1.23,0.76,2.03,2.23,2.21c2.06,0.25,2.95,2.42,1.87,4.21c-0.37,0.61-0.9,1.12-1.3,1.72
		c-0.42,0.63-0.88,1.26-1.11,1.96c-0.59,1.81-0.09,2.62,1.64,3.33c0.99,0.41,1.94,0.94,2.81,1.55c0.94,0.66,1.62,1.56,1.37,2.89
		c0.19,0.06,0.33,0.16,0.47,0.15c3.32-0.15,6.55,0.83,9.94,0.6c1.91-0.13,3.02-0.86,4.29-1.9c2.28-1.87,4.63-1.83,7-0.09
		c0.6,0.44,1.45,0.53,2.18,0.81c0.84,0.32,1.77,0.52,2.48,1.02c1.17,0.82,2.16,1.88,3.3,2.75c1.22,0.93,2.64,0.67,4.01,0.38
		c0.38-0.08,0.73-0.32,1.11-0.44c0.72-0.23,0.91-0.58,0.62-1.34c-0.69-1.78-0.37-3.47,0.79-4.96c0.52-0.66,0.68-1.27,0.38-2.09
		c-0.45-1.23-0.78-2.5-1.19-3.75c-0.42-1.28,0.3-2.43,1.67-2.38c1.27,0.05,2.51,0.44,3.78,0.55c2.17,0.19,4.34,0.27,6.71,0.41
		c-0.51-0.6-0.87-0.91-1.08-1.3c-0.31-0.58-0.76-1.24-0.7-1.82c0.09-0.93,0.02-1.69-0.56-2.46c-0.52-0.7-0.3-1.38,0.42-1.84
		c0.68-0.44,0.65-0.92,0.22-1.5c-1-1.34-1.98-2.71-3-4.04c-0.59-0.77-1.06-1.54-0.56-2.52c0.49-0.96,1.37-1.12,2.34-1.11
		c0.79,0.01,1.57,0,2.39,0c0.15-1.7-1.32-3.93,1.32-4.46c0.63-1.09,1.15-1.98,1.66-2.88c0.09-0.16,0.23-0.4,0.18-0.53
		c-0.39-0.98,0.03-1.65,0.68-2.35c0.18-0.2,0.08-0.89-0.13-1.18c-0.36-0.51-0.92-0.87-1.38-1.32c-1.26-1.23-1.35-2.57-0.02-3.71
		c0.97-0.83,1.22-1.64,0.91-2.82c-0.26-1-0.35-2.03-0.54-3.05c-0.21-1.11,0.26-1.82,1.55-1.9c1.79-0.11,3.59-0.07,5.39-0.04
		c1.55,0.02,3.11,0.07,4.66,0.18c0.98,0.06,1.66-0.27,2.2-1.11c2.31-3.54,5.2-6.45,9.1-8.27c0.77-0.36,1.34-1.1,2.07-1.57
		c0.84-0.54,1.72-1.29,2.64-1.39c2.88-0.33,5.73-0.56,8.54,0.9c1.5,0.78,3.41,0.85,5.15,1.07c1.64,0.2,2.02,0.6,2.2,3.22
		c1.24,0,2.5-0.08,3.76,0.02c3.45,0.28,4.37,1.85,4.38,4.29c0,0.5,0.4,1,0.64,1.49c0.26,0.53,0.62,1.02,0.8,1.57
		c0.46,1.47,1.71,2.16,3.17,1.6c1.03-0.4,1.99-0.41,3.01,0.06c0.32,0.15,0.82,0.01,1.2-0.12c1.22-0.39,3,0.81,2.71,2.03
		c-0.29,1.19,0.33,1.78,0.95,2.48c0.24,0.27,0.56,0.51,0.69,0.82c0.3,0.76,0.88,1.66,0.69,2.31c-0.65,2.18-1.27,4.46-3.14,6
		c-1.1,0.9-1.76,1.83-1.17,3.29c0.09,0.22,0.15,0.49,0.11,0.71c-0.43,1.9,0.79,3.19,1.64,4.64c0.21,0.36,0.29,0.97,0.12,1.32
		c-0.36,0.74-0.85,1.45-1.42,2.04c-1.02,1.06-1.12,1.88-0.03,2.85c0.71,0.63,1.61,1.03,2.35,1.64c1.13,0.93,2.24,1.9,3.27,2.94
		c0.82,0.83,0.81,1.84,0.03,2.7c-1.78,1.95-3.56,3.93-6.37,4.4c-0.23,0.04-0.48,0.11-0.67,0.24c-2.34,1.65-4.7,3.28-7,4.99
		c-0.89,0.66-1.2,1.58-0.48,2.63c0.13,0.19,0.31,0.47,0.27,0.65c-0.52,1.92,0.74,3.52,0.91,5.31c0.18,1.83-0.08,3.35-1.93,4.36
		c-1.49,0.81-2.26,3-1.69,4.61c0.57,1.62,1.07,3.42,2.15,4.67c1.92,2.24,4.16,4.23,6.43,6.13c1.42,1.19,2.07,2.63,1.48,4.65
		c-0.53,1.83-0.49,1.77,1.06,2.87c0.83,0.59,1.46,1.45,2.22,2.13c0.39,0.35,0.86,0.63,1.34,0.85c1.03,0.48,2.22,0.73,3.12,1.38
		c1.67,1.21,3.4,1.82,5.47,1.58c0.98-0.11,2-0.04,2.99,0.07c0.43,0.04,0.82,0.38,1.23,0.58c0,0.32,0,0.64,0,0.96
		c-0.38,0.19-0.79,0.61-1.12,0.55c-1.15-0.21-1.79,0.48-2.46,1.16c-0.4,0.39-0.84,0.76-1.16,1.21c-1.44,2.09-3.5,3.2-5.89,3.83
		c-0.38,0.1-0.85,0.38-1.02,0.71c-1.79,3.35-3.59,6.71-4.04,10.56c-0.13,1.11-0.18,2.24-0.07,3.35c0.11,1.1-0.16,1.95-0.92,2.71
		c-4.41,4.42-7.4,9.78-10.2,15.28c-1.12,2.2-1.02,4.5-0.81,6.8c0.16,1.75,0.08,3.33-0.72,4.98c-2.15,4.47-3.04,9.22-2.61,14.2
		c0.1,1.14,0.04,2.34-0.22,3.44c-0.75,3.22-0.03,6.15,2.34,8.24c3.87,3.41,5.45,7.91,6.74,12.57c0.67,2.41,1.55,4.51,3.42,6.29
		c2.49,2.38,2.92,5.92,1.36,8.83c-0.17,0.31-0.53,0.72-0.81,0.72c-2.75,0.02-5.52,0.14-8.24-0.13c-1.35-0.14-2.76-0.86-3.91-1.65
		c-1.94-1.32-3.99-1.66-6.25-1.55c-2.86,0.13-5.73,0.14-8.6,0.15c-0.54,0-1.14-0.15-1.61-0.42c-1.17-0.67-1.85-0.1-2.5,0.78
		c-2.4,3.23-2.72,3.28-6.39,1.67c-2.81-1.24-5.68-2.35-8.51-3.54c-1.07-0.45-2-0.44-3.05,0.21c-3.59,2.2-7.28,2.21-10.88,0.05
		c-1.5-0.9-2.91-1.97-4.27-3.08c-2.35-1.91-5.24-2.48-8-3.36c-0.31-0.1-0.86,0.4-1.25,0.69c-0.46,0.35-0.85,0.78-1.44,1.34
		c0.16-3.83-0.29-7.46,1.89-10.74c1.77-2.66,3.18-5.54,4.89-8.24c2.31-3.64,5.66-5.52,10.03-5.51c0.79,0,1.58-0.23,2.37-0.25
		c1.26-0.03,1.6-0.63,1.66-1.86c0.51-9.01,1.11-18.02,1.65-27.04c0.07-1.15,0.02-2.32-0.19-3.45c-0.11-0.63-0.68-1.68-1-1.67
		c-1.01,0.04-2.16,0.28-2.98,0.84c-1.42,0.99-2.55,2.37-3.94,3.41c-1.5,1.12-2.61,2.28-2.38,4.36c0.06,0.5-0.5,1.35-1,1.59
		c-3.05,1.47-5.17,3.95-7.35,6.41c-1.94,2.2-4.33,3.6-7.39,3.72c-0.52,0.02-1.27,0.52-1.49,0.99c-0.76,1.65-1.41,3.36-1.95,5.1
		c-0.39,1.25-0.64,2.57-0.76,3.87c-0.11,1.22-0.71,1.68-1.81,1.63c-1.91-0.08-3.83-0.13-5.74-0.33c-3.01-0.31-5.71-0.01-8.15,2.16
		c-1.9,1.7-4.47,2.14-6.99,1.98c-2.7-0.17-4.44-1.87-5.18-4.34c-0.76-2.57-2.47-4.32-4.33-6.03c-0.15-0.13-0.31-0.35-0.47-0.35
		c-1.63-0.03-2.23-1.25-2.99-2.4c-0.88-1.34-2.32-1.76-3.84-1.91c-3.13-0.3-6.2-0.19-9.22,0.85c-2,0.69-4.06,1.18-6.1,1.77
		c-1.5,0.44-2.53,1.4-3.3,2.77c-2.19,3.91-5.62,5.57-10.05,5.76c-3.09,0.13-6.16,0.78-9.23,1.23c-5.1,0.74-10.21,1.52-15.38,0.85
		c-2.35-0.3-4.41-1.36-6.22-2.89c-1.28-1.09-2.55-2.18-3.82-3.28c-1.18-1.03-2.55-1.53-4.11-1.77c-1.67-0.26-3.36-0.66-4.94-1.24
		c-3.18-1.16-6.26-2.61-9.45-3.72c-3.23-1.13-5.68-3.19-7.74-5.8c-0.15-0.18-0.31-0.41-0.33-0.62c-0.12-1.35-0.2-2.71-0.27-4.07
		c-0.01-0.19,0.06-0.4,0.16-0.56c1.15-1.96,4.16-2.95,6.32-2.23c1.12,0.38,2.31,0.57,3.49,0.71c1.14,0.13,2.07,0.51,2.81,1.39
		c0.64,0.77,3.6,1.56,4.5,1.2c0.22-0.09,0.49-0.34,0.54-0.56c0.23-1.07,0.97-1.59,1.91-1.95c1.6-0.62,1.95-1.21,1.86-2.91
		c-0.02-0.4-0.12-0.83-0.01-1.19c0.15-0.49,0.39-1.23,0.73-1.31c0.57-0.14,1.45-0.06,1.84,0.3c0.62,0.58,1.12,0.65,1.89,0.49
		c1.72-0.36,3.46-0.62,5.19-0.86c0.87-0.12,1.24-0.49,1.23-1.4c-0.04-2.97,1.44-4.52,4.43-4.55c1.54-0.01,2.57-0.53,3.48-1.83
		c1.27-1.83,2.76-3.53,4.3-5.15c1.25-1.32,2.82-1.9,4.68-1.17c1.91,0.76,3.88,0.96,5.58-0.42c1.96-1.6,4.16-2.08,6.57-1.86
		c0.94-2.91,1.55-5.69-0.71-8.26c-0.31-0.36-0.43-0.95-0.49-1.45c-0.23-1.86-0.34-3.74-0.6-5.59c-0.17-1.21,0.19-2.08,1.26-2.57
		c1.67-0.76,2.35-1.99,2.24-3.82c-0.05-0.76,0.18-1.6,0.48-2.32c0.35-0.84,0.34-1.6-0.2-2.25c-1.04-1.25-2.01-2.65-3.3-3.59
		c-2.45-1.78-2.97-4.27-2.98-6.96c0-1.54,0.35-3.09,0.62-4.63c0.28-1.54,0.01-1.92-1.5-2.52c-1-0.4-1.92-1.04-2.83-1.64
		c-0.31-0.2-0.46-0.63-0.75-1.05c-1.25,0.93-2.43,2.34-4.18,1.2c-1.15-0.75-0.69-1.89-0.46-2.72c-0.83-0.8-1.6-1.47-2.28-2.23
		c-1.3-1.46-2.81-1.73-4.49-0.73c-0.61,0.36-1.23,0.77-1.9,0.98c-1.42,0.45-2.64,0.13-3.66-1.05c-1.59-1.85-2.61-3.91-3.25-6.3
		c-1.11-4.12-2.8-8.08-3.85-12.21c-1.06-4.17-1.67-8.45-2.42-12.69c-0.74-4.21-1.5-8.42-2.09-12.65c-0.32-2.29-0.28-4.62-0.41-6.94
		c-0.02-0.43-0.12-0.87-0.11-1.3c0.01-0.52,0.1-1.04,0.16-1.56c0.56,0.07,1.12,0.11,1.67,0.22c0.22,0.04,0.4,0.25,0.77,0.49
		c0.91-1.96,1.75-3.87,1.67-6.04c-0.1-2.75,0.67-3.76,3.36-4.3c0.57-0.12,1.15-0.35,1.63-0.67c1.55-1.03,3.18-1.77,5.05-2.07
		c0.92-0.15,1.84-0.58,2.64-1.08c0.42-0.27,0.68-0.94,0.8-1.48c0.15-0.69,0.07-1.43,0.13-2.15c0.15-1.79,1.04-2.78,2.83-2.74
		c1.37,0.02,2.74,0.42,4.11,0.64c1.19,0.2,2.37,0.39,3.92,0.64c-0.38-1.1-0.61-1.76-0.84-2.43c-0.27-0.79-0.63-1.56-0.8-2.37
		c-0.33-1.53,1.14-3.27,2.68-3.15c1.48,0.11,2.64-0.05,3.27-1.69c0.2-0.53,1.07-0.95,1.72-1.12c1.48-0.41,2.77-1.01,4.03-1.96
		c1.34-1.01,3.01-1.6,4.54-2.36c2.42-1.21,4.88-2.35,7.27-3.62c1.23-0.65,2.43-1.2,3.87-0.91c0.23,0.05,0.49,0.06,0.71,0.01
		c2.28-0.5,4.57-1.02,6.85-1.53C810.67,866.43,811.23,866.43,811.79,866.43z M748.18,1030.24c-0.06,2.38-1.01,3.83-3.08,4.49
		c-0.29,0.09-0.57,0.44-0.71,0.73c-0.73,1.53-1.7,2.15-3.39,2.03c-0.92-0.06-1.87-0.06-2.73-0.33c-0.87-0.28-1.66-0.85-2.47-1.32
		c-0.5-0.29-0.95-0.76-1.48-0.91c-1.41-0.38-2.86-0.59-4.29-0.93c-1.12-0.26-3.28,0.4-3.51,1.45c-0.21,0.99-0.07,2.32,0.47,3.15
		c1.71,2.65,4.36,4.08,7.31,5.11c2.93,1.02,5.76,2.34,8.66,3.45c1.25,0.48,2.57,1.01,3.88,1.08c2.5,0.15,4.53,1.11,6.33,2.76
		c1.24,1.13,2.44,2.35,3.86,3.21c1.47,0.89,3.12,1.69,4.79,2c4.34,0.8,8.7,0.01,13-0.59c4.1-0.57,8.1-1.59,12.32-1.62
		c2.95-0.02,5.49-1.65,6.96-4.43c1.05-1.98,2.55-3.34,4.72-3.95c2.15-0.6,4.32-1.18,6.44-1.86c2.51-0.8,5.06-1.07,7.68-0.92
		c2.47,0.14,4.92,0.34,6.68,2.37c0.84,0.97,1.43,2.18,2.91,2.36c0.23,0.03,0.42,0.35,0.63,0.53c2.2,1.96,4.11,4.08,4.96,7.04
		c0.49,1.72,1.78,2.92,3.71,2.87c2.14-0.05,4.2-0.5,5.88-2.02c1.47-1.33,3.21-2.17,5.2-2.16c2.31,0.01,4.62,0.22,6.93,0.36
		c2.18,0.13,2.18,0.14,2.6-1.96c0.06-0.31,0.1-0.63,0.18-0.94c0.57-2.4,0.81-4.95,2.5-6.91c0.64-0.75,1.61-1.7,2.44-1.72
		c3.36-0.07,5.6-1.86,7.58-4.23c1.75-2.1,3.61-4.06,6.14-5.25c0.32-0.15,0.71-0.63,0.7-0.95c-0.04-2.5,1.36-4.03,3.25-5.38
		c1.27-0.92,2.38-2.07,3.52-3.17c1.46-1.4,3.24-1.72,5.11-1.43c1.53,0.24,2.02,1.59,2.31,2.91c0.21,0.92,0.34,1.9,0.29,2.84
		c-0.4,7.3-0.84,14.6-1.28,21.9c-0.18,2.95-0.36,5.9-0.59,8.85c-0.03,0.35-0.33,0.92-0.59,0.97c-1.68,0.33-3.38,0.76-5.08,0.77
		c-3.74,0.01-6.57,1.55-8.52,4.66c-1.61,2.57-3.11,5.21-4.62,7.85c-0.55,0.97-1.06,1.97-1.47,3.01c-0.73,1.88-0.39,2.55,1.46,3.17
		c0.64,0.22,1.29,0.43,1.94,0.62c2.68,0.78,5.12,1.96,7.25,3.83c1.07,0.94,2.33,1.69,3.58,2.38c2.68,1.47,5.41,1.39,7.97-0.24
		c1.78-1.14,3.49-1.17,5.34-0.38c3.08,1.32,6.18,2.6,9.26,3.92c1.47,0.63,1.74,0.51,2.62-0.82c0.5-0.75,1.15-1.42,1.8-2.05
		c0.76-0.74,1.6-0.74,2.64-0.38c1.34,0.47,2.78,0.8,4.19,0.86c1.75,0.07,3.51-0.15,5.26-0.32c3.04-0.31,5.86,0.07,8.42,1.96
		c0.92,0.68,2.16,1.17,3.29,1.29c2.05,0.2,4.14,0.08,6.21,0.01c0.27-0.01,0.66-0.4,0.76-0.7c0.73-2.18,0.05-4.11-1.53-5.57
		c-2.11-1.94-3.07-4.35-3.8-7.03c-1.19-4.41-2.77-8.57-6.35-11.85c-2.76-2.52-3.66-6.01-2.77-9.8c0.19-0.81,0.34-1.67,0.26-2.48
		c-0.6-5.64,0.42-10.98,2.87-16.06c0.5-1.03,0.53-2.01,0.45-3.12c-0.18-2.5-0.57-5.06,0.56-7.44c2.8-5.9,5.94-11.6,10.59-16.31
		c0.58-0.59,0.76-1.21,0.72-2.03c-0.06-1.19-0.02-2.4,0.14-3.59c0.49-3.84,2.26-7.21,3.96-10.6c0.56-1.13,1.28-1.83,2.53-2.07
		c2.45-0.47,4.19-1.94,5.33-3.83c-1.59-0.69-3.18-1.37-4.77-2.07c-1.07-0.47-2.15-0.93-3.2-1.44c-0.69-0.33-1.68-0.59-1.95-1.16
		c-0.62-1.3-1.64-1.99-2.79-2.65c-1.35-0.77-1.7-1.7-1.34-3.24c0.13-0.54,0.32-1.07,0.42-1.62c0.17-0.91-0.2-1.54-0.92-2.16
		c-1.5-1.29-2.92-2.69-4.31-4.1c-0.82-0.83-1.5-1.79-2.27-2.67c-0.43-0.49-1.1-0.86-1.34-1.42c-0.59-1.39-1-2.85-1.49-4.29
		c-0.92-2.7,0.78-4.51,2.36-6.16c1.32-1.38,1.73-2.2,1-4.02c-0.23-0.58-0.38-1.2-0.5-1.82c-0.22-1.12-0.44-2.25-0.55-3.38
		c-0.07-0.65-0.12-1.42,0.16-1.96c0.47-0.9,1.09-1.82,1.88-2.43c2.19-1.67,4.49-3.19,6.76-4.73c0.38-0.26,0.88-0.43,1.34-0.49
		c2.38-0.3,3.56-2.19,5.17-3.6c-0.44-0.51-0.71-0.94-1.09-1.21c-1.07-0.77-2.17-1.48-3.28-2.19c-3.11-1.98-3.51-5.34-0.68-7.39
		c0.06-0.04,0.08-0.13,0.07-0.11c-0.26-2.02-2.42-3.41-1.58-5.7c0.08-0.22-0.12-0.53-0.17-0.81c-0.27-1.4-0.25-2.84,0.87-3.8
		c1.39-1.17,2.17-2.68,3.06-4.18c0.65-1.09,0.66-1.92-0.12-2.93c-0.84-1.08-1.51-2.29-2.3-3.51c-0.4-0.08-1.04-0.26-1.68-0.33
		c-0.64-0.07-1.35-0.19-1.94-0.01c-2.44,0.74-4.83-0.44-5.61-2.84c-0.15-0.45-0.45-0.84-0.63-1.27c-0.37-0.94-0.91-1.87-1.01-2.84
		c-0.13-1.17-0.65-1.82-1.72-1.95c-1.1-0.13-2.23-0.07-3.34-0.07c-2.61-0.01-3.09-0.45-3.21-2.97c0-0.07-0.08-0.13-0.12-0.19
		c-0.03,0.1-0.06,0.19-0.08,0.29c-2-0.53-4.16-0.76-5.98-1.66c-2.46-1.22-4.89-0.92-7.38-0.64c-0.45,0.05-0.88,0.48-1.28,0.78
		c-0.98,0.72-1.93,1.48-2.93,2.18c-1.3,0.91-2.8,1.61-3.93,2.69c-1.52,1.45-2.83,3.14-4.11,4.82c-0.83,1.1-1.76,1.84-3.17,1.82
		c-2.44-0.04-4.87-0.07-7.31-0.14c-0.82-0.02-1.64-0.13-2.58-0.21c0.16,1.37,0.28,2.56,0.45,3.74c0.18,1.28,0.01,2.4-1.19,3.14
		c-0.81,0.51-0.72,1.02-0.05,1.59c0.58,0.49,1.2,0.97,1.65,1.56c0.62,0.83,1.29,2.04,0.52,2.8c-0.77,0.77-1.11,1.46-1.24,2.47
		c-0.05,0.43-0.5,0.8-0.74,1.21c-0.21,0.38-0.54,0.78-0.52,1.17c0.04,1.31-0.49,2.13-1.84,2.43c0,0.81,0.01,1.57,0,2.32
		c-0.02,1.42-0.49,1.94-1.92,2.04c-0.81,0.06-1.63,0.01-2.55,0.01c1.1,1.51,2.14,2.86,3.09,4.28c1.13,1.69,1.06,2.41-0.06,3.81
		c0.13,0.8,0.37,1.51,0.32,2.19c-0.09,1.09,0.39,1.63,1.25,2.21c0.7,0.47,1.35,1.23,1.67,2.01c0.41,0.99-0.18,1.75-1.25,1.81
		c-1.87,0.1-3.76,0.21-5.63,0.12c-2.01-0.09-4.01-0.41-6.09-0.64c0.12,0.44,0.23,0.85,0.35,1.27c0.62,2.26,1.17,4.45-0.64,6.57
		c-0.44,0.51-0.24,1.75-0.04,2.58c0.23,0.95,0.34,1.79-0.29,2.6c-1.53,1.99-6.68,2.72-8.67,1.22c-0.19-0.14-0.33-0.35-0.51-0.5
		c-1.14-0.97-2.27-1.95-3.45-2.87c-0.26-0.2-0.67-0.28-1.02-0.28c-1.34,0-2.46-0.44-3.49-1.32c-1.24-1.06-2.64-0.96-3.92,0.09
		c-0.4,0.33-0.8,0.66-1.17,1.02c-1.16,1.12-2.47,1.7-4.13,1.41c-0.62-0.11-1.28,0.06-1.91,0.02c-3.12-0.21-6.23-0.44-9.35-0.69
		c-0.31-0.02-0.7-0.11-0.89-0.31c-0.4-0.45-1.03-1.06-0.96-1.51c0.21-1.33-0.64-1.8-1.5-2.28c-0.73-0.4-1.54-0.65-2.31-0.98
		c-1.28-0.55-2-1.51-2.19-2.91c-0.27-2.05,0.49-3.75,1.72-5.31c0.35-0.44,0.74-0.85,1.02-1.32c0.45-0.74,0.25-1.32-0.66-1.27
		c-1.8,0.09-2.68-1.02-3.1-2.46c-0.4-1.36-0.71-2.83-0.62-4.22c0.22-3.59,0.68-7.16,1.04-10.73c0.26-2.65,0.52-2.14-1.89-2.74
		c-4.8-1.2-9.64-2.22-14.47-3.33c-1.47-0.34-2.09-1.58-1.39-2.91c0.58-1.09,1.33-2.09,1.9-3.19c1.14-2.19,2.46-4.33,3.23-6.65
		c1.01-3.01,2.21-5.89,3.72-8.67c0.67-1.23,1.12-2.64,1.36-4.03c0.7-4.12,1.36-8.26,1.82-12.41c0.31-2.82-0.3-3.26-2.84-3.01
		c-2,0.2-3.96,0.81-5.94,1.21c-0.54,0.11-1.13,0.26-1.64,0.16c-1.2-0.24-2.15,0.18-3.17,0.73c-2.17,1.17-4.41,2.21-6.62,3.31
		c-1.7,0.85-3.53,1.55-5.05,2.66c-1.23,0.89-2.39,1.66-3.9,1.91c-0.41,0.07-0.94,0.35-1.12,0.7c-0.83,1.58-2.05,2.35-3.84,2.35
		c-0.59,0-1.18,0.32-1.82,0.51c0.53,1.35,0.99,2.41,1.37,3.5c0.85,2.49,0.02,3.81-2.59,3.71c-1.68-0.07-3.35-0.61-5.01-0.98
		c-2.37-0.53-2.83-0.28-2.84,2.1c-0.01,2.35-1.07,3.86-3.22,4.65c-0.74,0.27-1.54,0.41-2.28,0.68c-1.33,0.49-2.63,1.04-3.94,1.58
		c-0.91,0.38-1.78,0.93-2.73,1.12c-1.15,0.23-1.57,0.74-1.47,1.88c0.24,2.56-0.43,4.93-1.75,7.08c-0.5,0.81-1.38,1.4-2.08,2.08
		c-0.24,0.24-0.25,0.68-0.24,1.11c0.13,5.86,1.43,11.56,2.47,17.3c0.62,3.41,0.92,6.91,1.79,10.25c1.29,4.94,2.91,9.79,4.5,14.64
		c0.5,1.53,1.23,3.01,2.09,4.37c1.13,1.8,1.56,1.8,3.37,0.62c2.68-1.76,5.42-1.29,7.38,1.25c0.26,0.34,0.55,0.76,0.92,0.9
		c1.37,0.51,2.29,1.27,1.83,3.13c0.67-0.5,1.06-0.84,1.48-1.11c1.06-0.69,2.01-0.63,2.55,0.47c0.71,1.44,1.8,2.11,3.25,2.53
		c0.66,0.19,1.24,0.71,1.79,1.17c0.84,0.7,1.06,1.63,0.86,2.71c-0.31,1.64-0.79,3.29-0.81,4.94c-0.03,1.92,0.17,3.79,1.93,5.27
		c1.62,1.36,2.9,3.15,4.2,4.86c0.77,1,0.75,2.2,0.24,3.42c-0.3,0.72-0.53,1.56-0.47,2.31c0.21,2.34-0.95,3.77-2.81,4.86
		c-0.27,0.16-0.62,0.51-0.6,0.74c0.24,2.48,0.17,4.99,1.45,7.31c0.7,1.27,1.15,2.84,1.18,4.29c0.03,1.6-0.52,3.21-0.86,4.81
		c-0.21,1-0.74,1.43-1.89,1.28c-1.76-0.24-3.51-0.12-5.02,1.06c-2.38,1.86-4.98,2.2-7.79,1.12c-1.25-0.48-2.26-0.1-3.08,0.91
		c-1.44,1.76-2.9,3.51-4.32,5.29c-0.99,1.24-2.22,1.9-3.82,1.91c-0.59,0-1.22,0.01-1.78,0.18c-0.99,0.3-1.51,1.01-1.41,2.08
		c0.21,2.32-0.75,3.42-3.09,3.73c-1.78,0.24-3.55,0.55-5.29,0.95C749.84,1030.55,749.12,1031.22,748.18,1030.24z"/>
					<path class="rondonopolis" d="M748.18,1030.24c0.94,0.98,1.66,0.32,2.45,0.14c1.75-0.39,3.52-0.71,5.29-0.95c2.34-0.31,3.3-1.42,3.09-3.73
		c-0.1-1.07,0.43-1.79,1.41-2.08c0.56-0.17,1.18-0.17,1.78-0.18c1.6,0,2.83-0.67,3.82-1.91c1.42-1.78,2.88-3.53,4.32-5.29
		c0.82-1.01,1.83-1.39,3.08-0.91c2.81,1.08,5.41,0.74,7.79-1.12c1.51-1.18,3.26-1.3,5.02-1.06c1.15,0.16,1.68-0.27,1.89-1.28
		c0.34-1.6,0.89-3.21,0.86-4.81c-0.03-1.45-0.48-3.01-1.18-4.29c-1.28-2.33-1.21-4.84-1.45-7.31c-0.02-0.23,0.34-0.59,0.6-0.74
		c1.86-1.09,3.02-2.53,2.81-4.86c-0.07-0.75,0.17-1.59,0.47-2.31c0.51-1.22,0.53-2.41-0.24-3.42c-1.3-1.7-2.58-3.49-4.2-4.86
		c-1.76-1.48-1.96-3.35-1.93-5.27c0.02-1.65,0.5-3.3,0.81-4.94c0.2-1.07-0.02-2.01-0.86-2.71c-0.55-0.45-1.14-0.97-1.79-1.17
		c-1.45-0.43-2.54-1.09-3.25-2.53c-0.54-1.09-1.49-1.15-2.55-0.47c-0.43,0.28-0.82,0.61-1.48,1.11c0.46-1.86-0.46-2.62-1.83-3.13
		c-0.37-0.14-0.66-0.56-0.92-0.9c-1.97-2.54-4.7-3.01-7.38-1.25c-1.81,1.18-2.24,1.19-3.37-0.62c-0.86-1.36-1.59-2.85-2.09-4.37
		c-1.59-4.85-3.21-9.71-4.5-14.64c-0.87-3.34-1.17-6.84-1.79-10.25c-1.04-5.74-2.34-11.43-2.47-17.3c-0.01-0.44,0-0.88,0.24-1.11
		c0.71-0.69,1.58-1.27,2.08-2.08c1.32-2.15,2-4.52,1.75-7.08c-0.11-1.13,0.32-1.65,1.47-1.88c0.95-0.19,1.82-0.75,2.73-1.12
		c1.31-0.54,2.62-1.09,3.94-1.58c0.74-0.28,1.54-0.41,2.28-0.68c2.16-0.79,3.21-2.3,3.22-4.65c0.01-2.37,0.47-2.63,2.84-2.1
		c1.66,0.37,3.33,0.92,5.01,0.98c2.6,0.1,3.44-1.22,2.59-3.71c-0.37-1.09-0.84-2.15-1.37-3.5c0.64-0.19,1.23-0.51,1.82-0.51
		c1.8,0.01,3.02-0.77,3.84-2.35c0.18-0.34,0.71-0.63,1.12-0.7c1.51-0.24,2.67-1.01,3.9-1.91c1.52-1.11,3.35-1.8,5.05-2.66
		c2.21-1.11,4.45-2.14,6.62-3.31c1.02-0.55,1.97-0.97,3.17-0.73c0.52,0.1,1.1-0.05,1.64-0.16c1.98-0.4,3.94-1.01,5.94-1.21
		c2.54-0.25,3.15,0.18,2.84,3.01c-0.46,4.15-1.12,8.29-1.82,12.41c-0.24,1.38-0.69,2.8-1.36,4.03c-1.51,2.79-2.71,5.66-3.72,8.67
		c-0.77,2.32-2.1,4.46-3.23,6.65c-0.57,1.1-1.32,2.1-1.9,3.19c-0.7,1.33-0.09,2.57,1.39,2.91c4.82,1.11,9.67,2.13,14.47,3.33
		c2.41,0.6,2.16,0.09,1.89,2.74c-0.36,3.58-0.82,7.15-1.04,10.73c-0.08,1.39,0.22,2.86,0.62,4.22c0.42,1.44,1.3,2.55,3.1,2.46
		c0.91-0.05,1.11,0.54,0.66,1.27c-0.29,0.47-0.68,0.88-1.02,1.32c-1.23,1.56-1.99,3.26-1.72,5.31c0.18,1.4,0.91,2.36,2.19,2.91
		c0.77,0.33,1.58,0.58,2.31,0.98c0.86,0.47,1.71,0.94,1.5,2.28c-0.07,0.45,0.56,1.05,0.96,1.51c0.18,0.21,0.58,0.29,0.89,0.31
		c3.11,0.25,6.23,0.48,9.35,0.69c0.63,0.04,1.3-0.13,1.91-0.02c1.66,0.29,2.97-0.29,4.13-1.41c0.37-0.36,0.77-0.69,1.17-1.02
		c1.28-1.05,2.68-1.15,3.92-0.09c1.04,0.88,2.15,1.32,3.49,1.32c0.34,0,0.76,0.08,1.02,0.28c1.17,0.93,2.3,1.91,3.45,2.87
		c0.18,0.15,0.32,0.36,0.51,0.5c1.99,1.5,7.14,0.76,8.67-1.22c0.63-0.82,0.52-1.65,0.29-2.6c-0.2-0.83-0.4-2.07,0.04-2.58
		c1.81-2.12,1.25-4.31,0.64-6.57c-0.11-0.41-0.23-0.83-0.35-1.27c2.08,0.23,4.08,0.55,6.09,0.64c1.87,0.09,3.75-0.03,5.63-0.12
		c1.07-0.06,1.67-0.82,1.25-1.81c-0.32-0.78-0.97-1.54-1.67-2.01c-0.85-0.58-1.34-1.12-1.25-2.21c0.06-0.68-0.19-1.38-0.32-2.19
		c1.12-1.4,1.19-2.12,0.06-3.81c-0.95-1.42-1.99-2.77-3.09-4.28c0.93,0,1.74,0.05,2.55-0.01c1.43-0.1,1.91-0.63,1.92-2.04
		c0.01-0.76,0-1.51,0-2.32c1.35-0.29,1.88-1.11,1.84-2.43c-0.01-0.38,0.31-0.79,0.52-1.17c0.23-0.42,0.68-0.78,0.74-1.21
		c0.12-1,0.46-1.7,1.24-2.47c0.76-0.76,0.1-1.97-0.52-2.8c-0.45-0.6-1.07-1.07-1.65-1.56c-0.67-0.57-0.76-1.09,0.05-1.59
		c1.2-0.74,1.37-1.86,1.19-3.14c-0.17-1.18-0.29-2.37-0.45-3.74c0.94,0.08,1.76,0.19,2.58,0.21c2.44,0.07,4.87,0.1,7.31,0.14
		c1.41,0.02,2.34-0.72,3.17-1.82c1.27-1.68,2.59-3.37,4.11-4.82c1.13-1.08,2.62-1.78,3.93-2.69c1-0.7,1.94-1.46,2.93-2.18
		c0.41-0.3,0.83-0.73,1.28-0.78c2.49-0.28,4.91-0.58,7.38,0.64c1.82,0.9,3.97,1.13,5.98,1.66c0.03-0.1,0.06-0.19,0.08-0.29
		c0.04,0.06,0.12,0.13,0.12,0.19c0.11,2.52,0.59,2.96,3.21,2.97c1.12,0,2.24-0.06,3.34,0.07c1.06,0.12,1.59,0.77,1.72,1.95
		c0.11,0.97,0.64,1.9,1.01,2.84c0.18,0.44,0.48,0.83,0.63,1.27c0.78,2.4,3.18,3.58,5.61,2.84c0.59-0.18,1.3-0.06,1.94,0.01
		c0.64,0.07,1.28,0.24,1.68,0.33c0.79,1.22,1.46,2.43,2.3,3.51c0.79,1.01,0.77,1.83,0.12,2.93c-0.89,1.5-1.67,3.01-3.06,4.18
		c-1.13,0.95-1.14,2.4-0.87,3.8c0.05,0.27,0.25,0.59,0.17,0.81c-0.84,2.28,1.32,3.67,1.58,5.7c0.01-0.02-0.01,0.07-0.07,0.11
		c-2.83,2.05-2.43,5.41,0.68,7.39c1.11,0.71,2.22,1.42,3.28,2.19c0.38,0.27,0.64,0.71,1.09,1.21c-1.6,1.41-2.78,3.3-5.17,3.6
		c-0.46,0.06-0.96,0.23-1.34,0.49c-2.28,1.54-4.58,3.06-6.76,4.73c-0.79,0.6-1.41,1.53-1.88,2.43c-0.28,0.54-0.23,1.31-0.16,1.96
		c0.12,1.13,0.34,2.26,0.55,3.38c0.12,0.62,0.27,1.24,0.5,1.82c0.73,1.82,0.32,2.63-1,4.02c-1.59,1.66-3.28,3.46-2.36,6.16
		c0.49,1.43,0.89,2.9,1.49,4.29c0.24,0.56,0.91,0.93,1.34,1.42c0.77,0.88,1.46,1.84,2.27,2.67c1.39,1.41,2.81,2.81,4.31,4.1
		c0.72,0.62,1.09,1.25,0.92,2.16c-0.1,0.55-0.29,1.08-0.42,1.62c-0.36,1.54-0.01,2.47,1.34,3.24c1.16,0.66,2.17,1.35,2.79,2.65
		c0.27,0.57,1.26,0.82,1.95,1.16c1.05,0.51,2.13,0.97,3.2,1.44c1.59,0.69,3.18,1.38,4.77,2.07c-1.15,1.88-2.89,3.35-5.33,3.83
		c-1.25,0.24-1.97,0.94-2.53,2.07c-1.7,3.4-3.47,6.77-3.96,10.6c-0.15,1.18-0.19,2.39-0.14,3.59c0.04,0.83-0.14,1.44-0.72,2.03
		c-4.64,4.71-7.79,10.4-10.59,16.31c-1.13,2.38-0.74,4.94-0.56,7.44c0.08,1.11,0.05,2.09-0.45,3.12
		c-2.45,5.08-3.47,10.42-2.87,16.06c0.09,0.81-0.07,1.68-0.26,2.48c-0.89,3.79,0,7.28,2.77,9.8c3.59,3.27,5.16,7.44,6.35,11.85
		c0.73,2.69,1.69,5.09,3.8,7.03c1.58,1.46,2.26,3.39,1.53,5.57c-0.1,0.3-0.49,0.69-0.76,0.7c-2.07,0.06-4.16,0.19-6.21-0.01
		c-1.14-0.11-2.37-0.6-3.29-1.29c-2.56-1.88-5.38-2.27-8.42-1.96c-1.75,0.18-3.51,0.4-5.26,0.32c-1.41-0.06-2.85-0.39-4.19-0.86
		c-1.04-0.37-1.88-0.36-2.64,0.38c-0.65,0.63-1.3,1.3-1.8,2.05c-0.88,1.33-1.15,1.45-2.62,0.82c-3.08-1.32-6.18-2.6-9.26-3.92
		c-1.85-0.79-3.56-0.76-5.34,0.38c-2.55,1.63-5.29,1.71-7.97,0.24c-1.25-0.69-2.51-1.44-3.58-2.38c-2.12-1.87-4.57-3.05-7.25-3.83
		c-0.65-0.19-1.3-0.4-1.94-0.62c-1.85-0.62-2.2-1.3-1.46-3.17c0.41-1.04,0.91-2.04,1.47-3.01c1.51-2.63,3.01-5.27,4.62-7.85
		c1.95-3.12,4.78-4.65,8.52-4.66c1.7-0.01,3.4-0.43,5.08-0.77c0.26-0.05,0.56-0.62,0.59-0.97c0.23-2.95,0.41-5.9,0.59-8.85
		c0.44-7.3,0.88-14.6,1.28-21.9c0.05-0.94-0.08-1.92-0.29-2.84c-0.29-1.31-0.78-2.67-2.31-2.91c-1.87-0.29-3.65,0.03-5.11,1.43
		c-1.14,1.09-2.24,2.25-3.52,3.17c-1.89,1.36-3.29,2.89-3.25,5.38c0.01,0.32-0.38,0.8-0.7,0.95c-2.54,1.19-4.39,3.15-6.14,5.25
		c-1.98,2.37-4.22,4.17-7.58,4.23c-0.83,0.02-1.8,0.97-2.44,1.72c-1.69,1.96-1.93,4.51-2.5,6.91c-0.07,0.31-0.11,0.63-0.18,0.94
		c-0.42,2.11-0.42,2.1-2.6,1.96c-2.31-0.14-4.62-0.35-6.93-0.36c-1.99-0.01-3.73,0.83-5.2,2.16c-1.68,1.52-3.73,1.96-5.88,2.02
		c-1.93,0.05-3.22-1.15-3.71-2.87c-0.84-2.97-2.75-5.08-4.96-7.04c-0.21-0.19-0.4-0.5-0.63-0.53c-1.49-0.18-2.07-1.39-2.91-2.36
		c-1.76-2.03-4.21-2.23-6.68-2.37c-2.62-0.15-5.17,0.12-7.68,0.92c-2.13,0.68-4.29,1.26-6.44,1.86c-2.17,0.61-3.68,1.96-4.72,3.95
		c-1.47,2.78-4.01,4.41-6.96,4.43c-4.22,0.03-8.22,1.04-12.32,1.62c-4.3,0.6-8.65,1.39-13,0.59c-1.67-0.31-3.32-1.11-4.79-2
		c-1.42-0.86-2.62-2.08-3.86-3.21c-1.81-1.65-3.83-2.61-6.33-2.76c-1.31-0.08-2.62-0.6-3.88-1.08c-2.9-1.11-5.73-2.43-8.66-3.45
		c-2.95-1.03-5.6-2.46-7.31-5.11c-0.54-0.83-0.68-2.16-0.47-3.15c0.23-1.05,2.39-1.71,3.51-1.45c1.42,0.33,2.88,0.55,4.29,0.93
		c0.54,0.14,0.98,0.62,1.48,0.91c0.81,0.47,1.59,1.04,2.47,1.32c0.86,0.27,1.81,0.27,2.73,0.33c1.69,0.12,2.66-0.51,3.39-2.03
		c0.14-0.3,0.42-0.64,0.71-0.73C747.17,1034.06,748.13,1032.62,748.18,1030.24z"/>
				</g>
				<g>
					<path d="M862.18,855.94c1.26-1.61,2.53-3.22,3.77-4.85c0.31-0.4,0.54-0.88,0.74-1.35c0.35-0.81,0.58-1.66,0.96-2.45
		c1.21-2.51,2.94-3.22,5.59-2.37c2.16,0.7,4.32,1.4,6.49,2.05c1.22,0.37,2.32,0.08,3.41-0.6c1.69-1.06,3.5-1.93,5.18-3
		c1.33-0.84,2.67-1.7,2.5-3.71c-0.05-0.63,0.58-1.53,1.16-1.97c1.81-1.39,2.88-3.25,3.41-5.36c0.64-2.55,1.96-2.54,3.87-1.4
		c0.14,0.08,0.26,0.19,0.39,0.28c0.47,0.33,1.04,1,1.41,0.91c1.08-0.26,2.31-0.3,3.05-1.43c0.74-1.13,1.8-1.32,3.17-1.56
		c1.64-0.29,3.22-0.03,4.79-0.28c1.44-0.23,2.17-1.4,2.93-2.47c0.07-0.1,0.05-0.32,0-0.45c-0.68-1.69-1.28-3.43-2.09-5.05
		c-0.63-1.24-1.19-2.41-0.96-3.85c0.15-0.97-0.16-1.59-1.27-1.92c-2.53-0.74-3-3.08-1.08-4.89c1.29-1.21,1.21-2.89,1.52-4.43
		c0.26-1.29,0.44-2.59,0.78-3.86c0.3-1.12,0.3-2.14-0.47-3.05c-1.18-1.38-1.73-2.94-1.45-4.77c0.12-0.78-0.3-0.98-0.98-0.93
		c-0.8,0.06-1.59,0.16-2.39,0.13c-1.51-0.05-2.74-1.12-2.88-2.55c-0.22-2.38-0.59-4.78-0.45-7.14c0.12-2.07,0.01-4.09-0.06-6.14
		c-0.03-0.91,0.24-1.93,0.68-2.73c1.21-2.2,2.59-4.3,3.91-6.44c0.13-0.2,0.25-0.46,0.44-0.56c1.74-0.98,2.1-2.53,1.99-4.37
		c-0.15-2.42-0.06-4.85-0.28-7.26c-0.22-2.39,0.51-3.47,2.87-3.66c1.65-0.13,1.64-0.19,1.59-1.82c-0.02-0.54,0.18-1.23,0.54-1.62
		c0.93-1.02,1.95-1.99,3.04-2.84c1.08-0.84,1.52-1.84,1.53-3.19c0.01-1.13,0.27-2.27,0.46-3.39c0.31-1.85,0.56-3.72,1.02-5.53
		c0.26-1,0.79-2,1.42-2.83c0.97-1.26,2.12-2.39,3.21-3.55c2.29-2.45,4.57-4.9,6.9-7.31c0.33-0.34,0.92-0.45,1.4-0.65
		c0.31-0.13,0.64-0.23,0.96-0.34c0.16,0,0.32,0,0.48,0c0.75,0.48,0.78,1.07,0.55,1.93c-0.36,1.34-0.46,2.75-0.67,4.12
		c-0.15,0.97-0.31,1.93-0.48,2.96c1.95,1.33,2.02,1.57,1.02,3.37c0.87,1.81,3.89,2.41,2.92,5.24c0.04,0.06,0.08,0.17,0.14,0.18
		c2.13,0.38,2.77,1.85,2.88,3.79c0.04,0.74,0.35,1.47,0.53,2.21c0.24,0.99,0.74,1.69,1.75,2.1c1.1,0.45,1.54,1.47,1.05,2.57
		c-0.44,0.98-1.18,1.83-1.87,2.86c0.68,1.62,0.41,2.21-1.22,3.72c-0.69,0.64-0.82,1.79-0.24,2.63c0.91,1.31,0.67,2.45,0.24,3.91
		c-0.36,1.21-0.06,2.8,0.44,4.02c0.67,1.64,1.77,3.12,2.78,4.6c0.24,0.35,0.86,0.54,1.33,0.61c1.7,0.23,3.41,0.52,5.12,0.5
		c2.17-0.03,4.34-0.35,6.52-0.51c1.31-0.1,2.64-0.29,3.93-0.17c2.96,0.26,5.8-0.14,8.55-1.16c0.48-2.75,2.81-3.65,5.21-1.9
		c1.02,0.74,1.8,0.68,2.84,0.21c0.68-0.31,1.52-0.26,2.29-0.35c0.31-0.04,0.69,0.08,0.93-0.06c1.56-0.91,3.21-0.69,4.9-0.59
		c2.26,0.13,4.54,0.03,6.82,0.02c0.6,0,1.2-0.06,1.8-0.02c1.34,0.1,2.21,0.94,2.11,2.26c-0.08,1.01-0.33,2.07-0.79,2.97
		c-0.86,1.69-0.52,3.35-0.13,5.02c0.07,0.31,0.5,0.58,0.83,0.77c0.65,0.39,1.35,0.68,2,1.07c1.08,0.65,1.39,1.63,1.34,2.86
		c-0.04,0.92,0.19,1.86,0.31,2.79c0.04,0.28,0.17,0.55,0.15,0.82c-0.1,1.45,0.53,2.81,0.66,4.12c0.22,2.17,0.34,4.48-0.24,6.71
		c-0.3,1.15-0.44,2.34-0.52,3.53c-0.01,0.2,0.61,0.63,0.95,0.64c0.74,0.04,1.5-0.21,2.24-0.18c0.7,0.03,1.4,0.25,2.18,0.4
		c0.14-0.21,0.33-0.46,0.49-0.73c0.63-1.07,1.55-1.43,2.74-1.19c1.36,0.28,2.25,1.01,2.27,2.24c0.03,1.59,0.73,2.39,2.2,2.81
		c0.56,0.16,1.01,0.73,1.55,1.14c2.65-0.62,3.19-0.2,3.23,2.55c0.79,0.29,1.56,0.6,2.34,0.86c0.86,0.29,3.02-0.86,3.32-2.06
		c0.88-3.52,3.76-3.86,6.56-4.38c0.67-0.12,1.37-0.09,2.01-0.29c1.13-0.34,1.62-1.24,1.74-2.35c0.09-0.87,0.12-1.76,0.25-2.62
		c0.28-1.85,2.01-3.1,3.67-2.37c2.04,0.9,3.15-0.79,4.68-1.28c0.14-0.04,0.21-0.48,0.21-0.73c0.02-1.49,0.49-2.14,2-2.28
		c0.93-0.09,1.13-0.45,1.04-1.29c-0.09-0.83-0.12-1.68-0.08-2.51c0.11-2.46,3.27-4.11,5.38-2.79c0.31,0.19,0.53,0.58,0.7,0.92
		c0.13,0.27,0.2,0.64,0.14,0.93c-0.61,2.87,0.3,5.59,0.79,8.35c0.17,0.94,0.67,1.42,1.6,1.46c1.07,0.04,2.37,0.29,2.65-1.14
		c0.32-1.6,1.34-2.37,2.57-3.22c0.84-0.58,1.53-1.63,1.89-2.62c0.57-1.57,1.12-3.01,2.93-3.46c0.07-0.02,0.16-0.06,0.21-0.12
		c1.41-1.86,3.5-1.7,5.47-1.77c1.86-0.06,3.41-0.67,4.7-2.02c1.44-1.52,3.37-2.12,5.33-2.52c1.43-0.29,2.53,0.88,2.06,2.39
		c-0.5,1.59-1.34,3.13-2.28,4.52c-0.5,0.73-0.82,1.13-0.2,1.91c0.95,1.2,1.08,2.56,0.43,3.95c-0.58,1.22-0.8,2.44-0.61,3.82
		c0.28,2-0.89,3.4-2.33,4.59c-0.65,0.53-1.34,1.02-1.99,1.55c-1.45,1.19-2.34,2.66-2.55,4.58c-0.1,0.92-0.51,1.82-0.85,2.71
		c-0.46,1.23-1.1,2.4-1.46,3.65c-0.42,1.47-1.27,2.33-2.72,2.71c-1.75,0.46-1.77,0.65-1.59,2.43c0.25,2.57,0.32,5.13-1.56,7.27
		c-0.22,0.25-0.34,0.65-0.34,0.99c-0.03,1.58-0.89,2.58-2.14,3.39c-0.34,0.22-0.73,0.61-0.79,0.97c-0.38,2.15-0.66,4.31-0.98,6.49
		c2-0.14,4.01-0.3,6.02-0.42c1.99-0.12,3.99-0.21,5.99-0.29c0.81-0.03,1.73-0.07,1.77,1.08c0.04,1.09-0.86,1.07-1.4,1.39
		c-0.35,0.4-0.63,0.95-1.08,1.16c-2.36,1.1-4.37,2.64-6.07,4.59c-1.26,1.45-2.52,2.89-3.84,4.28c-0.68,0.72-1.42,1.43-2.25,1.96
		c-3.07,1.99-3.74,4.27-2.1,7.56c0.09,0.17,0.16,0.35,0.27,0.57c-0.52,0.37-1.01,0.71-1.48,1.07c-1.84,1.43-2.39,2.89-1.49,5.04
		c0.74,1.77,0.47,3.31-0.21,4.96c-0.52,1.29-0.89,2.64-1.35,3.95c-0.3,0.86-0.59,1.74-1,2.55c-0.78,1.53-1.61,2.96-1.57,4.82
		c0.04,1.6-0.94,2.31-3.05,2.76c1.85,3.96,0.14,7.18-1.97,10.46c-2.62,4.08-5.33,8.06-8.61,11.64c-0.29,0.32-0.56,0.66-0.88,1.03
		c-0.21-0.14-0.37-0.24-0.53-0.35c-2.69-1.8-4.32-1.73-6.05,1.03c-1.83,2.91-4.18,4.08-7.52,4.07c-4.63-0.02-9.27,0.27-13.9,0.33
		c-0.99,0.01-1.46,0.34-1.87,1.21c-1.78,3.75-3.68,7.44-5.44,11.2c-0.98,2.09-2.5,3.45-4.67,4.17c-3.79,1.25-7.56,2.55-11.36,3.77
		c-0.93,0.3-1.46,0.74-1.75,1.71c-0.79,2.64-1.67,5.25-2.6,7.84c-0.16,0.46-0.7,0.95-1.17,1.1c-1.97,0.63-3.98,1.16-5.99,1.69
		c-2.74,0.72-3.35,2.58-2.92,5.56c0.22,1.47,1.29,2.88,2.18,4.18c0.83,1.21,1.94,2.23,2.88,3.37c0.86,1.05,1.24,2.27,0.69,3.58
		c-0.78,1.86-1.48,3.79-2.52,5.51c-1.05,1.72-2.85,2.62-4.79,3.17c-0.83,0.23-1.62,0.61-2.59,0.99c1.27,1.38,0.61,2.42-0.21,3.49
		c-2.09,2.77-4.01,5.68-6.28,8.3c-1.07,1.24-2.68,2.18-4.22,2.82c-1.15,0.48-1.15,1.61-1.96,2.17c-1.92,0-3.84,0-5.76,0
		c-1.93-0.53-3.7-1.36-5.27-2.65c-0.44-0.36-1.19-0.4-1.81-0.5c-1.14-0.19-1.93-0.67-2.51-1.77c-0.4-0.74-1.21-1.34-1.96-1.81
		c-1.9-1.18-2.26-1.93-1.63-4.05c0.62-2.09,0.34-2.25-1.28-3.71c-1.99-1.8-3.68-3.92-5.5-5.91c-0.08-0.08-0.1-0.23-0.19-0.28
		c-2.79-1.83-3.04-4.87-3.27-7.7c-0.14-1.71,1.3-3.19,2.57-4.37c1.38-1.28,1.63-1.91,1.09-3.68c-0.33-1.08-0.44-2.23-0.65-3.35
		c-0.07-0.39-0.04-0.83-0.22-1.16c-0.92-1.77-0.39-3.26,0.88-4.57c2.42-2.49,5.31-4.49,8.45-5.74c2.24-0.89,3.94-2.13,5.69-3.99
		c-0.79-0.68-1.52-1.38-2.33-1.98c-0.99-0.74-2.12-1.3-3.05-2.11c-2.17-1.88-2.16-4.21-0.1-6.2c0.17-0.17,0.33-0.34,0.62-0.65
		c-0.54-0.97-1.1-1.95-1.64-2.94c-0.11-0.21-0.2-0.45-0.21-0.68c-0.07-1.42-0.17-2.84-0.15-4.26c0.02-1.39,1.03-2.22,1.85-3.22
		c1.06-1.29,1.91-2.79,2.63-4.31c0.2-0.42-0.28-1.25-0.61-1.8c-0.69-1.19-1.49-2.31-2.03-3.6c-0.03-0.02-0.06-0.05-0.1-0.05
		c-1.93-0.37-3.86-0.07-5.79-0.12c-1.72-0.05-3.18-1-3.6-2.97c-0.09-0.45-0.45-0.83-0.63-1.27c-0.37-0.9-0.87-1.78-1.02-2.72
		c-0.23-1.43-0.61-1.99-2.07-2.12c-0.83-0.07-1.68-0.01-2.52,0c-3.01,0.05-3.36-0.23-3.76-3.13c-2.8,0.05-5.24-1.33-7.86-1.99
		c-1.88-0.47-4-0.01-6.02,0.07c-0.21,0.01-0.48,0.18-0.59,0.36c-0.96,1.58-2.61,2.14-4.12,2.94c-2.94,1.56-5.1,3.99-6.88,6.71
		c-1.2,1.84-2.71,2.28-4.78,2.14c-2.77-0.19-5.56-0.05-8.44-0.05c0.13,1.14,0.26,2.36,0.4,3.57c0.14,1.22-0.02,2.32-1.2,3.02
		c-0.82,0.49-0.71,1.02-0.05,1.6c0.63,0.55,1.37,1.07,1.76,1.77c0.29,0.51,0.36,1.43,0.09,1.93c-0.35,0.64-1.11,0.48-1.81,0.13
		c-1.55-0.79-3.13-1.51-4.73-2.2c-0.42-0.18-0.95-0.21-1.41-0.15c-1.4,0.18-2.25-0.45-3.11-1.54c-1.96-2.51-1.87-5.51-2.42-8.44
		c-1.86-0.93-2.19-1.64-1.42-3.64c0.27-0.7,0.02-0.96-0.49-1.36c-0.71-0.55-1.45-1.16-1.89-1.91c-0.7-1.18-0.39-2.11,0.79-2.84
		c0.73-0.45,0.58-0.78,0.25-1.43c-0.33-0.65-0.53-1.49-0.46-2.21c0.15-1.7,0.43-3.4,0.79-5.07c0.14-0.65,0.55-1.35,1.03-1.8
		c0.72-0.67,1.65-1.11,2.44-1.71c0.79-0.59,1.49-1.29,2.27-1.89c0.68-0.53,1.37-1.09,2.14-1.45c0.74-0.34,1.01-0.73,0.93-1.55
		c-0.3-3.12,0.48-4.05,3.6-4.57c0.89-0.15,1.75-0.46,2.98-0.79c-0.69-0.6-1.05-1.12-1.55-1.32c-2.18-0.87-3.9-2.21-5.26-4.17
		c-1.29-1.86-2.1-1.87-3.96-0.53c-1.32,0.95-2.88,0.73-3.98-0.43c-0.33-0.35-0.93-0.46-1.41-0.66c-0.64-0.26-1.28-0.51-1.92-0.76
		C862.18,856.58,862.18,856.26,862.18,855.94z M933.44,723.27c-0.1-0.01-0.21-0.03-0.31-0.04c-2.88,2.98-5.76,5.96-8.64,8.93
		c-0.17,0.17-0.34,0.34-0.47,0.54c-0.26,0.4-0.64,0.8-0.7,1.23c-0.44,3.19-1.72,6.23-1.64,9.5c0.04,1.74-0.6,3.11-1.95,4.17
		c-0.53,0.42-1.1,0.79-1.64,1.21c-0.98,0.77-1.7,1.58-1.38,3.03c0.29,1.28-0.51,2.23-1.83,2.5c-0.54,0.11-1.11,0.18-1.67,0.18
		c-0.97-0.02-1.33,0.45-1.09,1.34c0.86,3.21,0.34,6.45,0.16,9.66c-0.05,0.87-0.54,1.82-1.08,2.55c-1.16,1.56-2.6,2.92-3.7,4.52
		c-1.6,2.35-2.77,4.88-1.72,7.87c0.12,0.35,0,0.78-0.04,1.18c-0.1,1.15-0.31,2.29-0.29,3.43c0.03,1.63,0.26,3.25,0.36,4.88
		c0.05,0.91,0.52,1.31,1.38,1.28c0.6-0.02,1.19-0.15,1.79-0.2c2.12-0.17,3.17,0.75,3.11,2.87c-0.05,1.42,0.24,2.67,1.17,3.78
		c1.12,1.34,1.21,2.88,0.8,4.53c-0.32,1.31-0.51,2.65-0.75,3.98c-0.35,1.87-0.43,3.81-1.95,5.29c-1.03,1.01-0.93,1.31,0.48,1.72
		c1.24,0.36,2.22,1.09,2.13,2.39c-0.15,2.12,0.64,3.86,1.68,5.58c0.32,0.54,0.48,1.18,0.68,1.79c0.2,0.6,0.27,1.28,0.58,1.82
		c0.9,1.61,0.94,1.59-0.28,3.05c-0.39,0.46-0.78,0.91-1.17,1.37c-1.31,1.52-2.94,1.99-4.93,1.87c-1.38-0.09-2.79,0.25-4.18,0.43
		c-0.17,0.02-0.38,0.19-0.47,0.34c-0.88,1.51-2.32,2.01-3.9,2.47c-1.08,0.31-1.82,0.22-2.61-0.5c-0.5-0.46-1.08-0.84-1.68-1.3
		c-0.99,3.03-1.98,4.56-4.03,6.68c-0.37,0.39-0.67,1.04-0.67,1.57c0.01,1.2-0.46,2.11-1.37,2.79c-1.28,0.95-2.59,1.85-3.94,2.7
		c-1.32,0.83-2.74,1.49-4.02,2.37c-1.11,0.76-2.25,1.04-3.51,0.7c-2.12-0.57-4.25-1.1-6.32-1.82c-3.14-1.1-3.72-0.89-4.82,2.25
		c-0.03,0.08-0.06,0.15-0.08,0.23c-0.31,1.28-0.96,2.31-1.94,3.22c-0.66,0.61-1.09,1.48-1.49,2.06c0.7,0.64,1.26,1.02,1.63,1.53
		c0.44,0.61,0.77,0.34,1.19,0.05c2.73-1.87,4.96-1.65,6.84,0.97c1.24,1.73,2.73,2.98,4.69,3.73c0.4,0.15,0.79,0.4,1.13,0.67
		c0.94,0.78,1.23,1.86,1.11,3.02c-0.1,1.07-0.94,1.54-1.85,1.8c-0.96,0.27-1.93,0.49-2.91,0.66c-1.32,0.22-1.71,0.66-1.62,2.02
		c0.02,0.32,0.08,0.64,0.11,0.95c0.14,1.26-0.33,2.15-1.52,2.69c-0.65,0.29-1.26,0.68-1.83,1.1c-1.3,0.96-2.56,1.98-3.86,2.94
		c-0.74,0.55-1.52,0.98-1.63,2.07c-0.11,1.14-0.33,2.29-0.63,3.4c-0.26,0.95-0.27,1.71,0.4,2.55c0.84,1.06,0.54,2.3-0.56,3.14
		c-0.24,0.18-0.49,0.37-0.85,0.65c0.94,0.79,2.11,1.35,2.53,2.25c0.42,0.9,0.09,2.14,0.09,3.26c1.61,0.31,1.72,0.53,1.74,2.31
		c0.03,2.53,0.28,5.03,1.91,7.13c0.23,0.3,0.66,0.54,1.03,0.6c1.13,0.18,2.28,0.25,3.43,0.36c-0.12-1.04,0.44-1.94,1.37-2.7
		c0.3-0.25,0.62-0.73,0.6-1.08c-0.08-1.27-0.2-2.56-0.5-3.79c-0.42-1.7-0.01-2.48,1.76-2.49c3.15-0.02,6.31,0.08,9.46,0.19
		c1.1,0.04,1.87-0.25,2.5-1.24c2-3.15,4.55-5.79,7.88-7.54c1.35-0.71,2.7-1.35,3.63-2.64c0.22-0.3,0.62-0.58,0.97-0.65
		c2.83-0.56,5.68-0.93,8.5,0.08c1.52,0.54,3.04,1.08,4.57,1.6c0.29,0.1,0.63,0.08,0.95,0.08c2.71,0.04,2.92,0.24,3.07,2.89
		c0,0.06,0.09,0.12,0.22,0.28c1.24,0,2.56-0.1,3.86,0.03c1.49,0.14,3.15,0.46,3.63,2.08c0.52,1.78,1.04,3.5,1.87,5.17
		c0.75,1.51,1.71,2.11,3.38,1.68c0.86-0.22,1.84,0,2.76,0.02c0.36,0.01,0.73-0.01,1.09,0.02c0.67,0.06,1.47-0.05,1.97,0.28
		c0.65,0.43,1.27,1.1,0.91,2.13c-0.11,0.3,0.06,0.78,0.25,1.09c0.29,0.47,0.7,0.87,1.07,1.29c1.36,1.56,1.48,2.76,0.5,4.59
		c-0.34,0.63-0.61,1.3-0.99,1.9c-0.5,0.79-0.95,1.66-1.64,2.25c-1.08,0.93-1.73,1.85-1.16,3.31c0.13,0.32,0.19,0.72,0.12,1.05
		c-0.2,0.97,0.08,1.74,0.63,2.57c1.57,2.38,1.49,3.44-0.17,4.99c-0.09,0.08-0.19,0.15-0.28,0.23c-0.79,0.78-0.9,1.64-0.11,2.42
		c0.67,0.67,1.54,1.12,2.28,1.72c1.19,0.98,2.39,1.96,3.51,3.03c0.92,0.89,0.91,1.94,0.04,2.88c-1.77,1.91-3.53,3.84-6.29,4.31
		c-0.34,0.06-0.69,0.22-0.99,0.41c-1.64,1.06-3.3,2.1-4.89,3.23c-0.75,0.52-1.4,1.19-2.05,1.84c-0.65,0.65-0.81,1.38-0.32,2.27
		c0.27,0.49,0.26,1.13,0.37,1.71c0.15,0.8,0.15,1.66,0.46,2.39c1.09,2.5,0.47,4.72-1.55,6.42c-0.88,0.74-1.88,2-1.86,3
		c0.04,1.89,0.49,3.88,1.85,5.43c1.08,1.22,2.14,2.47,3.3,3.62c1.21,1.2,2.47,2.36,3.81,3.41c0.84,0.67,1.47,1.41,1.47,2.47
		c0,0.9-0.12,1.83-0.36,2.69c-0.25,0.89,0.01,1.36,0.73,1.9c1.03,0.78,1.91,1.77,2.89,2.61c0.36,0.31,0.86,0.48,1.3,0.69
		c1.07,0.5,2.26,0.82,3.2,1.49c1.61,1.15,3.3,1.55,5.27,1.51c1.6-0.03,2.75-0.93,4.12-1.42c2.35-0.83,4.15-2.39,5.64-4.3
		c1.15-1.48,2.17-3.07,3.29-4.57c1.2-1.61,2.39-3.18,0.37-5.22c1.73-0.71,3.2-1.41,4.74-1.93c1.52-0.51,2.99-1.13,3.82-2.51
		c0.85-1.42,1.44-3,2.12-4.52c0.39-0.86,0.14-1.63-0.46-2.31c-0.95-1.08-1.93-2.14-2.85-3.24c-2.46-2.94-3.44-6.18-1.89-9.9
		c0.43-1.04,1.08-1.88,2.19-2.24c2.09-0.67,4.17-1.36,6.28-1.94c0.85-0.23,1.34-0.56,1.6-1.47c0.64-2.22,1.51-4.38,2.12-6.61
		c0.44-1.61,1.37-2.46,2.94-2.94c3.7-1.13,7.37-2.39,11.04-3.62c1.77-0.59,3.1-1.6,3.93-3.4c1.91-4.13,4.01-8.17,5.95-12.29
		c0.41-0.86,0.87-1.1,1.8-1.13c5.87-0.16,11.74-0.41,17.61-0.61c1.03-0.04,1.69-0.56,2.25-1.36c0.64-0.92,1.29-1.84,2.04-2.66
		c1.87-2.05,3.78-2.45,6.38-1.49c0.3,0.11,0.86,0.01,1.07-0.22c3.81-3.89,6.51-8.58,9.33-13.17c1.39-2.26,1.2-4.57-0.24-6.78
		c-0.4-0.62-0.81-1.25-1.31-2.01c1.22,0,2.24-0.02,3.27,0c1.04,0.02,1.52-0.41,1.52-1.5c0-0.74,0.08-1.54,0.34-2.22
		c1.19-3.12,2.45-6.21,3.72-9.3c0.44-1.07,0.57-2.06,0.11-3.21c-1.07-2.69-0.53-5.07,1.66-7.07c0.28-0.25,0.41-0.9,0.32-1.3
		c-0.83-3.42,0.32-6.23,3.3-8.14c0.8-0.51,1.51-1.17,2.18-1.84c2.95-2.99,5.27-6.64,9.47-8.76c-0.49-0.05-0.63-0.08-0.77-0.07
		c-2.71,0.21-5.42,0.37-8.12,0.65c-1.75,0.18-2.76-0.63-2.75-2.42c0-0.48,0.08-0.95,0.16-1.42c0.27-1.57,0.67-3.13,0.78-4.72
		c0.09-1.24,0.47-2.16,1.57-2.74c1-0.53,1.51-1.34,1.56-2.5c0.02-0.52,0.22-1.15,0.56-1.53c1.54-1.71,1.51-3.73,1.23-5.77
		c-0.37-2.77,0.31-3.85,2.96-4.75c0.55-0.19,1.13-0.74,1.4-1.27c0.67-1.34,1.19-2.76,1.75-4.16c0.22-0.55,0.48-1.12,0.52-1.69
		c0.19-2.86,1.63-4.94,3.87-6.58c0.77-0.57,1.61-1.11,2.19-1.84c0.46-0.57,0.88-1.46,0.79-2.13c-0.21-1.54-0.01-2.93,0.69-4.29
		c0.45-0.87,0.29-1.65-0.24-2.47c-1.16-1.81-0.98-2.67,0.38-4.28c0.73-0.87,1.21-1.95,1.81-2.94c-0.14-0.14-0.27-0.28-0.41-0.42
		c-1.14,0.67-2.42,1.17-3.38,2.04c-1.4,1.28-2.94,2.01-4.79,2.23c-2.14,0.26-4.45,0.02-6.08,1.92c-0.07,0.08-0.24,0.09-0.31,0.17
		c-0.42,0.48-1.06,0.91-1.21,1.47c-0.56,2.14-1.7,3.75-3.65,4.81c-0.31,0.17-0.58,0.59-0.69,0.95c-0.45,1.44-1.18,2.82-2.68,3.09
		c-1.9,0.34-3.96,0.44-5.45-1.25c-0.32-0.36-0.48-0.92-0.57-1.42c-0.57-2.95-1.64-5.85-1-8.94c0.01-0.03-0.04-0.07-0.06-0.1
		c-1.52-0.25-1.87,0.02-1.83,1.43c0.02,0.56,0.04,1.12,0.08,1.68c0.21,2.67-0.09,3.08-2.72,3.65c-0.07,0.01-0.13,0.09-0.19,0.14
		c-0.11,0.75-0.2,1.67-0.7,2.04c-1.34,0.97-2.84,1.74-4.32,2.51c-0.3,0.15-0.82,0.07-1.16-0.08c-2.2-0.98-2.84-0.43-2.69,2.08
		c0,0.08,0,0.16,0,0.24c-0.09,2.67-1.75,4.45-4.4,4.83c-1.38,0.2-2.77,0.44-4.08,0.88c-0.64,0.21-1.45,0.83-1.6,1.42
		c-0.45,1.81-1.71,2.81-3.12,3.76c-1.2,0.8-2.37,1.11-3.67,0.24c-0.29-0.19-0.67-0.28-1.01-0.33c-2.22-0.34-2.36-0.48-2.65-2.83
		c-1.24,0.53-2.25,0.35-2.91-0.95c-0.13-0.25-0.46-0.48-0.74-0.55c-1.87-0.46-2.86-1.59-3-3.53c-0.03-0.41-0.41-0.79-0.63-1.19
		c-0.31,0.27-0.71,0.48-0.9,0.82c-0.54,0.95-1.34,1.49-2.37,1.18c-1.42-0.44-2.78-0.4-4.24-0.18c-1.85,0.27-3.38-1.52-3.03-3.35
		c0.27-1.4,0.61-2.78,0.88-4.18c0.07-0.38-0.02-0.79-0.03-1.18c-0.03-2.14,0.26-4.3-0.57-6.37c-0.04-0.1,0.01-0.24,0-0.36
		c-0.1-0.74-0.21-1.48-0.31-2.22c-0.1-0.66-0.29-1.33-0.27-1.99c0.08-2.31,0.09-2.26-2.07-3.26c-0.67-0.31-1.49-0.86-1.72-1.49
		c-0.86-2.33-1.14-4.72,0.09-7.08c0.32-0.62,0.48-1.31,0.77-2.11c-0.88,0-1.54,0-2.21,0c-2.27-0.01-4.55,0.08-6.81-0.09
		c-1.35-0.1-2.6-0.13-3.83,0.53c-0.43,0.23-1,0.17-1.51,0.25c-0.85,0.14-1.73,0.17-2.53,0.45c-1.26,0.43-2.37,0.46-3.5-0.38
		c-0.58-0.44-1.35-0.63-2.12-0.98c-0.13,2.32-0.41,2.64-2.43,3.1c-0.39,0.09-0.76,0.26-1.16,0.31c-2.09,0.28-4.19,0.77-6.28,0.73
		c-2.36-0.05-4.68-0.15-7.03,0.35c-1.42,0.3-2.92,0.34-4.38,0.29c-1.95-0.07-3.89-0.35-5.84-0.54c-1.02-0.1-1.98-0.32-2.56-1.3
		c-0.95-1.62-2.08-3.16-2.84-4.86c-0.88-1.96-1.57-4.06-0.69-6.23c0.25-0.62,0.12-1.04,0.01-1.64c-0.28-1.5-0.42-3.07-0.28-4.58
		c0.06-0.61,0.97-1.15,1.49-1.72c-1.25-1.03-1.25-1.67,0.08-2.88c0.62-0.56,1.24-1.13,2.11-1.93c-2.5-0.7-2.99-2.47-3.36-4.31
		c-0.1-0.47-0.18-0.95-0.21-1.42c-0.07-1.15-0.54-1.89-1.7-2.32c-1.36-0.49-1.73-1.4-1.53-2.84c0.04-0.3-0.1-0.69-0.3-0.93
		c-0.38-0.46-0.86-0.84-1.3-1.24c-1.12-1.02-2.09-2.08-0.96-3.69c-1.26-0.64-1.77-1.48-1.43-2.8
		C932.88,726.22,933.13,724.74,933.44,723.27z"/>
					<path class="barra" d="M933.44,723.27c-0.3,1.47-0.55,2.95-0.92,4.4c-0.34,1.32,0.17,2.16,1.43,2.8c-1.13,1.61-0.16,2.67,0.96,3.69
		c0.44,0.4,0.92,0.78,1.3,1.24c0.2,0.23,0.34,0.63,0.3,0.93c-0.2,1.44,0.17,2.34,1.53,2.84c1.16,0.42,1.63,1.17,1.7,2.32
		c0.03,0.48,0.11,0.95,0.21,1.42c0.38,1.84,0.86,3.62,3.36,4.31c-0.87,0.79-1.49,1.36-2.11,1.93c-1.34,1.21-1.33,1.85-0.08,2.88
		c-0.52,0.57-1.44,1.11-1.49,1.72c-0.14,1.51,0,3.08,0.28,4.58c0.11,0.6,0.24,1.02-0.01,1.64c-0.88,2.17-0.19,4.28,0.69,6.23
		c0.76,1.7,1.89,3.24,2.84,4.86c0.58,0.98,1.54,1.2,2.56,1.3c1.95,0.2,3.89,0.47,5.84,0.54c1.46,0.05,2.96,0.01,4.38-0.29
		c2.34-0.49,4.66-0.4,7.03-0.35c2.09,0.05,4.19-0.45,6.28-0.73c0.39-0.05,0.77-0.22,1.16-0.31c2.02-0.46,2.3-0.78,2.43-3.1
		c0.78,0.35,1.54,0.55,2.12,0.98c1.13,0.84,2.24,0.82,3.5,0.38c0.8-0.28,1.68-0.31,2.53-0.45c0.51-0.08,1.08-0.03,1.51-0.25
		c1.23-0.66,2.48-0.62,3.83-0.53c2.26,0.16,4.54,0.07,6.81,0.09c0.67,0,1.34,0,2.21,0c-0.28,0.8-0.45,1.5-0.77,2.11
		c-1.23,2.35-0.95,4.75-0.09,7.08c0.23,0.63,1.04,1.18,1.72,1.49c2.16,1,2.15,0.95,2.07,3.26c-0.02,0.66,0.17,1.33,0.27,1.99
		c0.11,0.74,0.21,1.48,0.31,2.22c0.02,0.12-0.04,0.25,0,0.36c0.83,2.07,0.54,4.23,0.57,6.37c0.01,0.39,0.1,0.8,0.03,1.18
		c-0.27,1.4-0.61,2.78-0.88,4.18c-0.35,1.84,1.18,3.62,3.03,3.35c1.45-0.21,2.81-0.25,4.24,0.18c1.03,0.32,1.83-0.22,2.37-1.18
		c0.19-0.34,0.59-0.55,0.9-0.82c0.22,0.39,0.6,0.78,0.63,1.19c0.14,1.94,1.14,3.07,3,3.53c0.28,0.07,0.61,0.3,0.74,0.55
		c0.66,1.3,1.67,1.48,2.91,0.95c0.29,2.34,0.43,2.48,2.65,2.83c0.35,0.05,0.73,0.14,1.01,0.33c1.3,0.86,2.47,0.56,3.67-0.24
		c1.42-0.95,2.67-1.94,3.12-3.76c0.15-0.58,0.96-1.2,1.6-1.42c1.31-0.44,2.7-0.68,4.08-0.88c2.64-0.38,4.31-2.17,4.4-4.83
		c0-0.08,0.01-0.16,0-0.24c-0.15-2.51,0.49-3.06,2.69-2.08c0.34,0.15,0.86,0.24,1.16,0.08c1.48-0.77,2.98-1.54,4.32-2.51
		c0.5-0.36,0.59-1.29,0.7-2.04c0.06-0.05,0.12-0.12,0.19-0.14c2.63-0.56,2.93-0.97,2.72-3.65c-0.04-0.56-0.07-1.12-0.08-1.68
		c-0.04-1.41,0.31-1.68,1.83-1.43c0.02,0.03,0.07,0.07,0.06,0.1c-0.64,3.09,0.43,5.99,1,8.94c0.1,0.49,0.25,1.05,0.57,1.42
		c1.5,1.69,3.55,1.59,5.45,1.25c1.5-0.27,2.22-1.66,2.68-3.09c0.11-0.36,0.37-0.78,0.69-0.95c1.95-1.06,3.09-2.67,3.65-4.81
		c0.14-0.56,0.78-0.99,1.21-1.47c0.07-0.08,0.24-0.09,0.31-0.17c1.63-1.9,3.94-1.66,6.08-1.92c1.85-0.22,3.39-0.96,4.79-2.23
		c0.95-0.87,2.24-1.37,3.38-2.04c0.14,0.14,0.27,0.28,0.41,0.42c-0.59,0.99-1.08,2.07-1.81,2.94c-1.36,1.61-1.54,2.47-0.38,4.28
		c0.52,0.82,0.68,1.6,0.24,2.47c-0.7,1.36-0.9,2.75-0.69,4.29c0.09,0.67-0.33,1.56-0.79,2.13c-0.59,0.73-1.42,1.27-2.19,1.84
		c-2.24,1.64-3.68,3.73-3.87,6.58c-0.04,0.57-0.3,1.15-0.52,1.69c-0.55,1.4-1.07,2.82-1.75,4.16c-0.27,0.53-0.84,1.08-1.4,1.27
		c-2.64,0.89-3.33,1.98-2.96,4.75c0.27,2.05,0.3,4.07-1.23,5.77c-0.34,0.38-0.53,1.01-0.56,1.53c-0.05,1.17-0.56,1.97-1.56,2.5
		c-1.1,0.59-1.48,1.5-1.57,2.74c-0.12,1.58-0.52,3.15-0.78,4.72c-0.08,0.47-0.16,0.95-0.16,1.42c-0.01,1.79,1,2.59,2.75,2.42
		c2.7-0.27,5.41-0.44,8.12-0.65c0.14-0.01,0.28,0.02,0.77,0.07c-4.2,2.12-6.52,5.77-9.47,8.76c-0.67,0.68-1.39,1.33-2.18,1.84
		c-2.98,1.91-4.13,4.72-3.3,8.14c0.1,0.39-0.04,1.04-0.32,1.3c-2.19,1.99-2.73,4.38-1.66,7.07c0.46,1.15,0.33,2.14-0.11,3.21
		c-1.27,3.09-2.52,6.18-3.72,9.3c-0.26,0.69-0.35,1.48-0.34,2.22c0,1.09-0.48,1.52-1.52,1.5c-1.02-0.02-2.05,0-3.27,0
		c0.5,0.77,0.9,1.39,1.31,2.01c1.43,2.21,1.63,4.52,0.24,6.78c-2.82,4.59-5.52,9.28-9.33,13.17c-0.22,0.22-0.77,0.33-1.07,0.22
		c-2.6-0.96-4.52-0.57-6.38,1.49c-0.75,0.83-1.4,1.74-2.04,2.66c-0.56,0.8-1.21,1.33-2.25,1.36c-5.87,0.2-11.74,0.45-17.61,0.61
		c-0.93,0.03-1.39,0.26-1.8,1.13c-1.94,4.11-4.04,8.16-5.95,12.29c-0.83,1.8-2.17,2.81-3.93,3.4c-3.67,1.23-7.34,2.49-11.04,3.62
		c-1.57,0.48-2.5,1.34-2.94,2.94c-0.62,2.23-1.48,4.39-2.12,6.61c-0.26,0.91-0.76,1.23-1.6,1.47c-2.11,0.58-4.2,1.27-6.28,1.94
		c-1.11,0.36-1.75,1.2-2.19,2.24c-1.54,3.72-0.57,6.95,1.89,9.9c0.92,1.1,1.9,2.16,2.85,3.24c0.6,0.68,0.85,1.45,0.46,2.31
		c-0.68,1.52-1.27,3.1-2.12,4.52c-0.83,1.39-2.3,2-3.82,2.51c-1.54,0.52-3.01,1.22-4.74,1.93c2.02,2.04,0.84,3.61-0.37,5.22
		c-1.12,1.5-2.14,3.09-3.29,4.57c-1.5,1.92-3.29,3.47-5.64,4.3c-1.36,0.48-2.52,1.38-4.12,1.42c-1.97,0.04-3.65-0.35-5.27-1.51
		c-0.94-0.67-2.13-1-3.2-1.49c-0.45-0.21-0.94-0.38-1.3-0.69c-0.99-0.84-1.86-1.83-2.89-2.61c-0.72-0.54-0.98-1.01-0.73-1.9
		c0.24-0.87,0.37-1.79,0.36-2.69c0-1.06-0.63-1.8-1.47-2.47c-1.34-1.06-2.6-2.21-3.81-3.41c-1.16-1.15-2.22-2.4-3.3-3.62
		c-1.37-1.55-1.81-3.54-1.85-5.43c-0.02-1,0.98-2.26,1.86-3c2.02-1.7,2.64-3.92,1.55-6.42c-0.32-0.73-0.31-1.59-0.46-2.39
		c-0.11-0.58-0.1-1.22-0.37-1.71c-0.49-0.9-0.33-1.62,0.32-2.27c0.65-0.65,1.3-1.31,2.05-1.84c1.6-1.12,3.25-2.17,4.89-3.23
		c0.3-0.19,0.65-0.35,0.99-0.41c2.77-0.47,4.53-2.4,6.29-4.31c0.87-0.94,0.88-1.99-0.04-2.88c-1.11-1.07-2.31-2.05-3.51-3.03
		c-0.74-0.6-1.61-1.06-2.28-1.72c-0.78-0.78-0.68-1.64,0.11-2.42c0.08-0.08,0.19-0.15,0.28-0.23c1.66-1.56,1.74-2.61,0.17-4.99
		c-0.55-0.83-0.82-1.59-0.63-2.57c0.07-0.33,0.01-0.73-0.12-1.05c-0.57-1.46,0.08-2.38,1.16-3.31c0.69-0.59,1.14-1.47,1.64-2.25
		c0.38-0.6,0.65-1.27,0.99-1.9c0.99-1.83,0.86-3.03-0.5-4.59c-0.37-0.42-0.78-0.82-1.07-1.29c-0.19-0.31-0.36-0.79-0.25-1.09
		c0.37-1.03-0.26-1.7-0.91-2.13c-0.5-0.33-1.3-0.22-1.97-0.28c-0.36-0.03-0.72-0.01-1.09-0.02c-0.92-0.02-1.9-0.25-2.76-0.02
		c-1.67,0.43-2.63-0.17-3.38-1.68c-0.84-1.68-1.36-3.4-1.87-5.17c-0.47-1.62-2.14-1.94-3.63-2.08c-1.3-0.12-2.63-0.03-3.86-0.03
		c-0.13-0.16-0.21-0.22-0.22-0.28c-0.15-2.66-0.36-2.86-3.07-2.89c-0.32,0-0.66,0.02-0.95-0.08c-1.53-0.51-3.05-1.05-4.57-1.6
		c-2.82-1.01-5.67-0.64-8.5-0.08c-0.36,0.07-0.76,0.35-0.97,0.65c-0.93,1.29-2.28,1.93-3.63,2.64c-3.33,1.76-5.87,4.4-7.88,7.54
		c-0.63,0.99-1.4,1.27-2.5,1.24c-3.15-0.11-6.31-0.21-9.46-0.19c-1.78,0.01-2.18,0.79-1.76,2.49c0.3,1.23,0.42,2.52,0.5,3.79
		c0.02,0.35-0.29,0.83-0.6,1.08c-0.93,0.76-1.49,1.66-1.37,2.7c-1.16-0.11-2.3-0.19-3.43-0.36c-0.37-0.06-0.8-0.31-1.03-0.6
		c-1.63-2.1-1.88-4.6-1.91-7.13c-0.02-1.79-0.12-2-1.74-2.31c0-1.11,0.33-2.36-0.09-3.26c-0.42-0.9-1.59-1.46-2.53-2.25
		c0.37-0.28,0.61-0.46,0.85-0.65c1.1-0.84,1.4-2.08,0.56-3.14c-0.67-0.84-0.66-1.6-0.4-2.55c0.3-1.11,0.52-2.25,0.63-3.4
		c0.11-1.09,0.89-1.52,1.63-2.07c1.3-0.97,2.56-1.99,3.86-2.94c0.57-0.42,1.19-0.81,1.83-1.1c1.19-0.54,1.66-1.43,1.52-2.69
		c-0.03-0.32-0.09-0.63-0.11-0.95c-0.09-1.35,0.3-1.8,1.62-2.02c0.98-0.16,1.96-0.38,2.91-0.66c0.92-0.26,1.75-0.73,1.85-1.8
		c0.11-1.16-0.17-2.24-1.11-3.02c-0.33-0.28-0.72-0.52-1.13-0.67c-1.96-0.75-3.45-2-4.69-3.73c-1.88-2.62-4.11-2.84-6.84-0.97
		c-0.42,0.29-0.75,0.56-1.19-0.05c-0.37-0.51-0.93-0.88-1.63-1.53c0.41-0.58,0.83-1.45,1.49-2.06c0.98-0.91,1.63-1.95,1.94-3.22
		c0.02-0.08,0.05-0.15,0.08-0.23c1.1-3.13,1.67-3.35,4.82-2.25c2.07,0.72,4.2,1.25,6.32,1.82c1.26,0.34,2.4,0.06,3.51-0.7
		c1.28-0.88,2.71-1.54,4.02-2.37c1.35-0.85,2.66-1.75,3.94-2.7c0.91-0.67,1.38-1.58,1.37-2.79c0-0.53,0.29-1.18,0.67-1.57
		c2.06-2.13,3.04-3.65,4.03-6.68c0.61,0.46,1.18,0.84,1.68,1.3c0.79,0.72,1.53,0.81,2.61,0.5c1.59-0.46,3.03-0.95,3.9-2.47
		c0.09-0.16,0.3-0.32,0.47-0.34c1.39-0.18,2.8-0.52,4.18-0.43c1.99,0.13,3.63-0.34,4.93-1.87c0.39-0.46,0.78-0.91,1.17-1.37
		c1.22-1.45,1.18-1.44,0.28-3.05c-0.3-0.54-0.37-1.21-0.58-1.82c-0.2-0.6-0.36-1.25-0.68-1.79c-1.04-1.72-1.82-3.46-1.68-5.58
		c0.09-1.3-0.89-2.02-2.13-2.39c-1.4-0.41-1.51-0.71-0.48-1.72c1.51-1.48,1.6-3.41,1.95-5.29c0.25-1.33,0.43-2.67,0.75-3.98
		c0.41-1.65,0.32-3.19-0.8-4.53c-0.93-1.11-1.21-2.36-1.17-3.78c0.07-2.12-0.98-3.04-3.11-2.87c-0.6,0.05-1.19,0.18-1.79,0.2
		c-0.86,0.03-1.32-0.38-1.38-1.28c-0.1-1.63-0.33-3.25-0.36-4.88c-0.02-1.14,0.19-2.29,0.29-3.43c0.03-0.39,0.16-0.83,0.04-1.18
		c-1.05-2.99,0.12-5.52,1.72-7.87c1.09-1.6,2.54-2.96,3.7-4.52c0.54-0.73,1.04-1.68,1.08-2.55c0.18-3.22,0.7-6.45-0.16-9.66
		c-0.24-0.89,0.12-1.35,1.09-1.34c0.56,0.01,1.12-0.06,1.67-0.18c1.31-0.28,2.12-1.23,1.83-2.5c-0.33-1.46,0.4-2.26,1.38-3.03
		c0.53-0.42,1.1-0.8,1.64-1.21c1.35-1.06,1.99-2.43,1.95-4.17c-0.08-3.27,1.2-6.31,1.64-9.5c0.06-0.44,0.44-0.83,0.7-1.23
		c0.13-0.2,0.3-0.37,0.47-0.54c2.88-2.98,5.76-5.96,8.64-8.93C933.23,723.24,933.33,723.25,933.44,723.27z"/>
				</g>
				<g>
					<path d="M923.78,520.79c1.82,0.24,2.42,1.88,3.36,3.05c0.98,1.23,2.09,2.18,3.54,2.75c1.28,0.5,2.22,1.29,2.82,2.6
		c0.25,0.53,1.03,0.96,1.65,1.16c1.66,0.52,1.92,0.75,1.98,2.43c0,0.04,0,0.08,0,0.12c0.2,2.3,0.2,2.3-0.85,4.14
		c0.38,0.03,0.71,0.05,1.04,0.09c1.68,0.22,2.73,1.23,3.03,2.91c0.06,0.31,0.11,0.63,0.21,0.93c0.51,1.45,0.92,1.6,2.4,1.24
		c0.92-0.22,1.9-0.27,2.85-0.33c1.04-0.06,2.08,0.08,2.52,1.18c0.46,1.16-0.47,1.72-1.24,2.32c0.18,0.65,0.36,1.29,0.44,1.59
		c1.05,0.23,1.99,0.32,2.83,0.64c0.76,0.29,1.31,0.53,2.05-0.12c0.81-0.71,2.19-0.28,2.77,0.72c0.24,0.41,0.41,0.86,0.56,1.2
		c0.97-0.19,1.85-0.46,2.75-0.52c1.2-0.08,1.92,0.55,1.98,1.73c0.04,0.77,0.37,1.05,1.06,1.16c1.44,0.22,2.85,0.75,4.32-0.03
		c0.31-0.16,0.94,0.16,1.37,0.38c0.54,0.28,1,0.7,1.53,1.01c0.64,0.39,1.16,0.41,1.75-0.28c0.23-0.27,1.17-0.24,1.57,0.01
		c0.66,0.4,1.18,1.06,1.69,1.67c0.8,0.97,1.76,1.58,3.03,1.74c0.54,0.07,1.05,0.33,1.67,0.53c0.37-0.34,0.82-0.79,1.3-1.19
		c1.16-0.97,2.15-0.98,3.15,0.13c0.79,0.87,1.52,0.95,2.53,0.45c2.17-1.05,3.47-0.75,4.92,1.13c0.53,0.69,1.14,0.88,1.88,0.6
		c2.04-0.79,3.54-0.45,4.98,1.44c0.95,1.25,2.67,1.91,4.03,2.87c1.43,1,2.8,2.1,4.28,3.04c2.17,1.37,2.51,3.41,2.2,5.68
		c-0.21,1.51,0.28,2.66,1.46,3.56c0.67,0.51,1.44,0.91,2.01,1.5c0.61,0.62,1.05,1.41,1.54,2.14c0.15,0.22,0.12,0.66,0.29,0.73
		c1.78,0.75,3.34,2.03,5.41,2.13c3.06,0.15,5.33-0.89,6.89-3.69c0.6-1.08,1.79-2,2.91-2.59c3.89-2.03,7.79-4.15,12.25-4.61
		c2.32-0.24,4.6,0.14,6.58,1.58c1.13,0.82,2.33,1.54,3.44,2.38c0.6,0.45,1.11,1.02,1.59,1.6c0.72,0.86,2.29,1.44,3.31,1.09
		c0.19-0.06,0.35-0.29,0.45-0.49c0.47-0.94,1.26-1.29,2.26-1.29c1.12,0,2.24-0.02,3.36,0.06c1.04,0.07,1.3-0.44,1.37-1.35
		c0.07-0.82,0.21-1.66,0.5-2.42c0.55-1.43,1.83-1.69,3.12-0.9c0.92,0.57,1.97,0.95,2.98,1.36c1.22,0.49,2.49,0.85,3.7,1.38
		c1.14,0.5,2.04,0.51,3.03-0.43c2-1.89,4.59-2.51,7.24-2.84c0.77-0.1,1.21-0.35,1.44-1.12c0.35-1.16,1.38-1.38,2.43-1.64
		c1.54-0.37,3.12-0.71,4.53-1.4c2.81-1.38,5.86-1.44,8.84-1.86c0.63-0.09,1.48,0.37,2,0.83c0.97,0.86,0.71,2.23-0.38,2.94
		c-0.8,0.52-1.71,0.97-2.32,1.67c-0.64,0.74-1.27,1.71-1.4,2.65c-0.38,2.62-1.41,4.78-3.49,6.46c-0.96,0.78-1.77,1.75-2.65,2.63
		c0.08,0.09,0.15,0.19,0.23,0.28c0.37-0.11,0.74-0.22,1.11-0.33c1.71-0.5,3.16-0.22,4.51,1.08c0.74,0.71,1.76,1.22,2.74,1.58
		c1.21,0.44,2.1,1.06,2.59,2.28c0.21,0.54,0.51,1.16,0.95,1.48c1.11,0.81,2.08,1.68,2.71,2.96c0.18,0.35,0.99,0.58,1.5,0.55
		c1.78-0.1,3.55-0.37,5.33-0.51c1.12-0.09,1.98-0.47,2.56-1.48c0.42-0.72,1.01-1.35,1.42-2.07c0.44-0.77,0.72-1.63,1.14-2.41
		c0.31-0.59,0.71-1.13,1.1-1.67c0.42-0.58,1.05-1.06,1.27-1.7c0.7-2.1,1.22-4.26,1.85-6.38c0.35-1.17,0.61-2.41,1.2-3.46
		c0.38-0.67,1.28-1.06,2.06-1.66c-0.18-2.72,0.66-2.49,3.06-3.16c0.9-0.25,1.45-0.59,1.77-1.51c0.49-1.4,3.2-3.27,4.57-2.98
		c0.63,0.14,1.26,1.01,1.58,1.69c0.47,1,0.66,2.13,0.95,3.21c0.46,1.65,1.23,2.98,3,3.59c1.15,0.4,1.44,1.45,1.23,2.57
		c-0.51,2.71-1.01,5.41-2.45,7.85c-0.87,1.48-1.04,3.16-0.49,4.76c0.96,2.8,0.72,5.59,0.26,8.41c-0.21,1.3-0.39,2.61-0.54,3.92
		c-0.03,0.26,0.06,0.69,0.25,0.8c2.61,1.62,3.34,4.1,3.09,6.94c-0.27,3.12,0.31,6.14,0.94,9.15c0.52,2.49,0.09,4.73-1.02,6.95
		c-0.82,1.64-1.58,3.32-2.26,5.02c-0.31,0.76-0.64,1.68-0.49,2.43c0.36,1.92-0.03,3.41-2.01,4.34c0.86,0.74,1.49,1.42,2.24,1.91
		c1.43,0.94,2.11,2.24,2.49,3.89c0.67,2.95,1.51,5.86,2.3,8.82c0.73-0.06,1.35-0.11,2.08-0.17c0,0.41,0,0.76,0,1.11
		c0.01,2.26,0.44,2.63,2.73,2.42c3.09-0.29,3.21-0.54,3.94,2.93c1.12,5.27-1.13,9.94-5.99,12.78c0.46,0.94,0.93,1.89,1.41,2.85
		c-3.38,1.03-2.77,3.8-2.67,6.21c0.09,2.1,0.6,4.17,0.82,6.27c0.11,1.09,0.18,2.25-0.05,3.31c-0.81,3.91-2.32,7.55-4.66,10.82
		c-1.18,1.65-1.99,3.4-2.13,5.5c-0.06,0.92-0.61,1.94-1.23,2.66c-1.14,1.32-2.42,2.55-3.79,3.64c-1.71,1.36-2.7,3.02-3.2,5.16
		c-0.73,3.1-1.72,6.14-2.63,9.19c-0.61,2.05-1.96,3.49-3.93,4.24c-1.2,0.46-1.31,1.17-1.17,2.3c0.45,3.68,0.86,7.37,1.15,11.07
		c0.1,1.34-0.13,2.71-0.3,4.05c-0.46,3.55-0.28,7.08,0.93,10.42c1.48,4.07-1.38,6.69-4.93,6.76c-0.86,0.02-1.19,0.24-1.39,1.02
		c-0.63,2.41-1.59,4.66-2.97,6.77c-1.82,2.79-2.16,6.05-2.64,9.26c-0.28,1.9-0.72,3.77-0.9,5.68c-0.12,1.23-0.08,2.54,0.19,3.74
		c0.35,1.55,0.95,3.05,1.53,4.53c0.48,1.25,0.35,2.38-0.2,3.57c-0.94,2.03-1.83,4.08-2.66,6.16c-1.19,3-2.31,6.03-3.46,9.04
		c-0.49,1.27-0.56,2.53-0.2,3.87c0.14,0.53-0.02,1.25-0.27,1.78c-0.81,1.77-1.82,3.46-2.57,5.26c-0.4,0.96-0.49,2.09-0.56,3.15
		c-0.08,1.31-0.5,2.4-1.48,3.28c-1.99,1.79-4,3.56-5.95,5.39c-2.23,2.08-4.85,3.43-7.71,4.39c-0.48,0-0.96,0-1.44,0
		c-2.6-0.76-3.11-3.3-4.27-5.28c-0.41,0.2-0.72,0.26-0.9,0.44c-1.35,1.32-3.07,1.78-4.82,2.25c-1.76,0.48-3.48,1.11-4.89,1.56
		c-0.85-0.27-1.48-0.66-2.1-0.63c-3.39,0.17-6.77,0.42-10.15,0.68c-1.71,0.13-2.7-0.68-2.65-2.41c0.03-0.83,0.23-1.66,0.38-2.48
		c0.16-0.9,0.53-1.81,0.5-2.7c-0.05-1.9,0.57-3.27,2.28-4.25c0.49-0.28,0.63-1.2,0.89-1.84c0.22-0.54,0.22-1.25,0.58-1.65
		c1.45-1.59,1.5-3.49,1.29-5.42c-0.37-3.35,0.04-4.02,3.13-5.18c0.52-0.2,0.97-0.86,1.24-1.42c0.56-1.13,1-2.33,1.45-3.51
		c0.31-0.85,0.68-1.71,0.79-2.59c0.25-2.1,1.24-3.78,2.72-5.19c0.63-0.6,1.36-1.12,2.08-1.61c1.54-1.06,2.39-2.31,1.99-4.35
		c-0.17-0.88,0.26-1.95,0.61-2.86c0.43-1.1,0.44-2.02-0.24-3.04c-1.02-1.52-0.81-2.5,0.34-3.89c0.77-0.93,1.33-2.03,1.98-3.06
		c-0.1-0.12-0.19-0.24-0.29-0.36c-0.95,0.42-2.18,0.59-2.81,1.29c-2.16,2.4-4.91,3-7.9,3.1c-1.18,0.04-2.18,0.32-3,1.22
		c-0.26,0.28-0.72,0.37-1,0.64c-0.45,0.45-1.03,0.92-1.18,1.48c-0.57,2.08-1.68,3.67-3.56,4.75c-0.37,0.21-0.62,0.72-0.8,1.15
		c-0.68,1.66-1.88,3.43-4.12,3.11c-0.63-0.09-1.28-0.11-1.9-0.22c-1.58-0.28-2.44-1.2-2.72-2.77c-0.19-1.05-0.54-2.08-0.69-3.14
		c-0.18-1.29-0.26-2.6-0.32-3.9c-0.03-0.59,0.08-1.18,0.13-1.75c-1.63-0.41-1.98-0.15-1.93,1.4c0.02,0.84,0.12,1.67,0.14,2.51
		c0.04,1.59-0.52,2.33-2.05,2.58c-0.68,0.11-0.92,0.28-1.06,1.06c-0.12,0.7-0.79,1.42-1.4,1.89c-0.8,0.63-1.74,1.18-2.7,1.5
		c-0.76,0.25-1.71,0.29-2.49,0.1c-1.69-0.43-2.08-0.2-2.18,1.53c-0.06,1.02-0.15,2.08-0.5,3.03c-0.6,1.61-1.84,2.52-3.6,2.75
		c-1.42,0.19-2.82,0.52-4.22,0.84c-1.07,0.24-1.69,0.94-1.98,2.02c-0.64,2.33-2.61,3.18-4.58,3.99c-0.31,0.13-0.78-0.05-1.16-0.15
		c-0.96-0.25-1.89-0.55-2.85-0.79c-1.43-0.35-1.45-1.51-1.59-2.78c-1.38,0.74-2.3,0.26-3-0.97c-0.16-0.28-0.64-0.43-1-0.54
		c-1.48-0.45-2.42-1.54-2.6-3.09c-0.03-0.28-0.01-0.58-0.12-0.82c-0.13-0.26-0.36-0.6-0.59-0.65c-0.21-0.04-0.57,0.22-0.73,0.44
		c-1.2,1.66-1.51,1.73-3.49,1.26c-0.78-0.18-1.66-0.18-2.46-0.04c-2.72,0.5-4.12-1.15-3.74-3.9c0.26-1.85,0.9-3.62,0.71-5.62
		c-0.16-1.64-0.04-3.25-0.48-4.94c-0.53-2.03-0.35-4.23-0.6-6.35c-0.06-0.5-0.47-1.07-0.89-1.4c-0.48-0.39-1.19-0.48-1.71-0.83
		c-0.5-0.34-1.08-0.79-1.26-1.32c-0.82-2.35-1.11-4.73,0.13-7.08c0.32-0.61,0.47-1.32,0.75-2.13c-0.74,0-1.29,0-1.84,0
		c-2.55-0.02-5.1-0.09-7.64-0.04c-1.04,0.02-2.09,0.28-3.12,0.47c-2.01,0.36-4.01,0.8-6.04,1.05c-0.54,0.07-1.15-0.44-1.73-0.7
		c-0.66-0.31-1.31-0.63-2.3-1.11c0.43,2.45-0.9,2.96-2.59,3.35c-2.85,0.67-5.68,1.12-8.64,0.86c-1.68-0.15-3.43,0.09-5.1,0.42
		c-2.16,0.43-4.25,0.52-6.41,0.09c-1.24-0.25-2.54-0.3-3.81-0.32c-1.54-0.02-2.67-0.57-3.38-2c-0.51-1.03-1.14-2.02-1.8-2.97
		c-1.15-1.68-1.98-3.74-1.56-5.52c0.59-2.49-0.49-4.76-0.17-7.14c0.23-1.71,0.15-1.76,1.63-2.47c-0.29-0.56-0.9-1.19-0.79-1.67
		c0.14-0.65,0.79-1.22,1.28-1.77c0.44-0.49,0.96-0.91,1.61-1.53c-3.01-1.13-3.15-3.65-3.47-6.06c-0.13-0.94-0.51-1.6-1.46-1.99
		c-1.67-0.68-1.91-1.23-1.71-3.03c0.03-0.26-0.14-0.6-0.32-0.81c-0.54-0.59-1.12-1.14-1.72-1.67c-1.02-0.91-1.39-1.93-0.63-3.17
		c-1.75-1.52-1.62-1.5-1.03-3.8c0.46-1.8,0.56-3.7,0.81-5.55c0.01-0.07,0-0.19-0.05-0.22c-1.47-1-0.48-1.87,0.1-2.8
		c0.42-0.68,0.86-1.37,1.09-2.12c0.22-0.72,0.28-1.52,0.24-2.28c-0.04-0.71-0.39-1.39-0.45-2.1c-0.04-0.57-0.05-1.42,0.29-1.69
		c1.11-0.91,0.85-2.03,0.99-3.19c0.11-0.97,0.75-2.05,1.47-2.74c2.14-2.06,3.33-4.57,3.75-7.42c0.31-2.05,0.93-3.85,2.46-5.31
		c0.86-0.82,1.31-1.75,1.3-3.06c0-0.8,0.75-1.65,1.26-2.41c0.63-0.93,1.42-1.76,2.06-2.69c0.49-0.72,0.93-1.5,1.23-2.31
		c0.25-0.67,0.22-1.45,0.38-2.16c0.19-0.81,0.46-1.61,0.7-2.41c0.18-0.57,0.34-1.16,0.58-1.71c0.35-0.81,0.56-1.82,1.18-2.35
		c1.28-1.09,1.69-2.28,1.15-3.81c-0.96-2.71-0.05-4.81,2-6.62c0.44-0.39,0.66-1.06,0.94-1.62c0.39-0.78,0.68-1.62,1.14-2.35
		c1-1.58,0.92-2.53-0.56-3.72c-1.24-1-2.59-1.88-3.82-2.9c-1.39-1.16-2.74-2.37-2.62-4.46c0.01-0.27-0.5-0.73-0.84-0.82
		c-1.04-0.27-1.54-0.95-1.74-1.91c-0.21-1.03-0.79-1.63-1.78-2.05c-2.06-0.88-2.32-2.19-0.93-3.96c0.99-1.26,1.96-2.52,2.94-3.78
		c0.17,0.14,0.34,0.27,0.51,0.41c-0.65-0.16-1.3-0.3-1.95-0.47c-1.24-0.33-2.13-1.09-2.16-2.41c-0.05-2.94-2.27-4.44-3.99-6.26
		c-0.33-0.35-1.01-0.45-1.54-0.51c-4.45-0.46-7.16-3.85-6.6-8.31c0.06-0.47,0.11-0.95,0.12-1.43c0.02-1.4-0.32-1.82-1.73-2.12
		c-0.23-0.05-0.47-0.07-0.71-0.12c-2.07-0.42-2.86-1.3-3.07-3.4c-0.06-0.58-0.12-1.16-0.18-1.79c-2.13,0.98-2.29,0.95-3.87-0.62
		c-0.45-0.45-0.98-0.87-1.3-1.4c-0.62-1.04-1.42-1.53-2.65-1.47c-0.41,0.02-1.04-0.25-1.23-0.59c-0.91-1.59-2.44-2.95-2.08-5.06
		c0.02-0.11-0.04-0.23-0.09-0.52c-2.43-0.02-2.57-1.89-2.71-3.69c-0.1-1.32-0.43-2.42-1.38-3.45c-0.51-0.56-0.41-1.67-0.62-2.53
		c-0.12-0.48-0.19-1.27-0.48-1.37c-1.85-0.66-2.76-2.26-3.82-3.67c-0.53-0.7-0.95-0.77-1.7-0.5c-2.25,0.82-3.89-0.24-4.36-2.6
		c-0.09-0.46-0.73-0.92-1.22-1.18c-1.15-0.61-2.47-0.94-3.53-1.66c-2-1.37-2.7-3.92-1.75-6.15c0.47-1.11,0.29-1.89-0.61-2.62
		c-1.23-0.99-1.6-2.44-1.92-3.9c-0.08-0.34-0.18-0.68-0.27-1.02c0-1.2,0-2.4,0-3.6c0.53-1.33,0.93-2.73,1.64-3.96
		c1-1.74,1.53-3.42,0.89-5.45c-0.4-1.25,0.31-2.05,1.61-2.27c0.37-0.06,0.74-0.13,1.19-0.2c-0.12-0.28-0.16-0.43-0.23-0.56
		c-0.95-1.6-0.87-2.26,0.37-3.61c0.57-0.62,1.12-1.25,1.63-1.91c0.98-1.28,2.25-1.93,3.86-1.63c0.72,0.13,1.11-0.13,1.3-0.69
		c0.23-0.71,0.37-1.46,0.46-2.2c0.08-0.63-0.05-1.29,0.05-1.91c0.37-2.29,1.93-2.88,3.73-1.44c0.11,0.09,0.27,0.12,0.35,0.16
		c1.34-1.71,2.6-3.41,3.96-5.01c1.62-1.91,3.32-3.76,5.01-5.61c1.27-1.4,2.8-2.34,4.72-2.61c0.32-0.04,0.62-0.29,0.93-0.45
		C923.14,520.79,923.46,520.79,923.78,520.79z M1046.9,789.05c0-0.96,0.06-1.84-0.01-2.7c-0.27-3.21,1.71-4.15,4.16-4
		c1.38,0.09,2.16,0.61,2.03,1.96c-0.27,2.74,0.03,5.36,0.75,8.01c0.4,1.48,0.5,1.92,1.82,2.03c1.76,0.15,2.29-0.15,2.63-1.65
		c0.23-1,0.7-1.79,1.65-2.21c1.6-0.7,2.46-1.95,2.85-3.61c0.33-1.4,1.07-2.47,2.53-2.94c0.39-0.13,0.68-0.54,1.06-0.74
		c0.72-0.38,1.44-0.83,2.22-1.01c0.96-0.21,1.98-0.2,2.98-0.24c1.55-0.05,2.91-0.55,3.98-1.68c1.41-1.48,3.19-2.21,5.12-2.71
		c1.88-0.48,3.13,0.57,2.64,2.4c-0.45,1.68-0.98,3.41-2.44,4.57c-0.58,0.46-0.68,0.91-0.19,1.51c1.15,1.43,1.15,2.94,0.43,4.61
		c-0.36,0.84-0.6,1.87-0.49,2.76c0.25,1.95-0.39,3.46-1.77,4.72c-0.83,0.75-1.75,1.39-2.62,2.1c-1.34,1.11-2.17,2.49-2.45,4.26
		c-0.18,1.12-0.64,2.19-1.04,3.27c-0.41,1.12-0.99,2.19-1.3,3.33c-0.4,1.47-1.24,2.34-2.7,2.73c-1.74,0.46-1.82,0.72-1.63,2.42
		c0.28,2.57,0.32,5.12-1.53,7.27c-0.22,0.25-0.39,0.65-0.38,0.98c0.03,1.6-0.87,2.57-2.09,3.4c-0.35,0.24-0.71,0.68-0.8,1.08
		c-0.3,1.4-0.47,2.83-0.69,4.24c-0.11,0.72-0.23,1.44-0.33,2.05c4.07-0.2,8.02-0.42,11.96-0.58c0.66-0.03,1.39,0.37,1.97,0.18
		c2.54-0.84,5.32-0.97,7.52-2.88c1.45-1.26,3.04-0.74,4,0.96c0.35,0.62,0.59,1.31,0.92,1.95c0.83,1.61,1.56,2.01,3.18,1.27
		c1.8-0.82,3.6-1.76,5.15-2.97c2.32-1.82,4.47-3.87,6.6-5.92c0.5-0.49,0.69-1.37,0.88-2.11c0.32-1.27,0.36-2.62,0.82-3.83
		c0.65-1.7,1.59-3.3,2.38-4.95c0.14-0.3,0.25-0.69,0.18-0.99c-0.44-1.79-0.13-3.49,0.54-5.15c2.01-4.99,4.03-9.98,5.99-14.99
		c0.21-0.54,0.26-1.26,0.11-1.82c-0.39-1.42-1.07-2.77-1.39-4.2c-0.34-1.49-0.64-3.07-0.52-4.57c0.17-2.26,0.75-4.48,1.08-6.73
		c0.52-3.49,1.21-6.9,3.07-10c0.89-1.49,1.54-3.14,2.2-4.75c0.52-1.27,0.89-2.6,1.32-3.89c0.8,0.16,1.42,0.34,2.05,0.39
		c2.66,0.2,3.9-1.49,3.33-4.04c-0.58-2.59-1.02-5.25-1.15-7.9c-0.11-2.34,0.53-4.71,0.41-7.04c-0.2-3.82-0.75-7.62-1.15-11.44
		c-0.28-2.68-0.3-2.74,2.17-3.89c1.51-0.7,2.6-1.69,3.07-3.29c0.83-2.79,1.8-5.55,2.41-8.39c0.6-2.79,1.73-5.09,4.06-6.8
		c1.12-0.82,2.16-1.79,3.07-2.84c0.58-0.66,1.13-1.56,1.2-2.4c0.19-2.2,0.98-4.1,2.26-5.84c2.17-2.98,3.57-6.32,4.45-9.88
		c0.21-0.83,0.38-1.79,0.17-2.59c-0.94-3.52-0.96-7.1-0.95-10.7c0-1.55,0.18-2.73,1.82-3.23c0.06-0.02,0.1-0.12,0.17-0.22
		c-0.54-1.09-1.09-2.19-1.67-3.36c0.33-0.1,0.58-0.23,0.85-0.25c1.9-0.16,3.3-1.16,4.36-2.65c1.69-2.39,2.35-5.02,1.88-7.95
		c-0.19-1.18-0.58-1.66-1.93-1.53c-3.24,0.32-3.95-0.22-4.87-3.08c-0.02-0.06-0.12-0.1-0.18-0.15c-0.43,0.04-0.86,0.08-1.36,0.12
		c-0.12-0.41-0.24-0.78-0.34-1.16c-0.87-3.28-1.7-6.57-2.64-9.83c-0.22-0.74-0.73-1.48-1.3-2.02c-1.34-1.26-2.79-2.39-4.27-3.63
		c0.85-0.76,1.68-1.45,2.42-2.22c0.29-0.3,0.54-0.81,0.52-1.21c-0.09-2.1,0.45-4.04,1.32-5.91c0.72-1.56,1.63-3.05,2.15-4.67
		c0.4-1.24,0.63-2.68,0.42-3.95c-0.6-3.71-1.23-7.4-1-11.19c0.12-1.99-0.82-3.45-2.6-4.36c-0.35-0.18-0.68-0.38-1.01-0.57
		c0.2-1.52,0.35-2.99,0.59-4.45c0.52-3.16,1.02-6.27-0.06-9.47c-0.72-2.13,0-4.21,0.87-6.26c0.95-2.25,1.62-4.62,2.46-7.08
		c-4.27-1.28-4.38-5-5.49-8.44c-0.86,0.66-1.9,1.04-2.12,1.7c-0.68,2-2.07,2.81-3.98,3.11c-0.22,0.03-0.43,0.13-0.66,0.2
		c0,0.24,0,0.39,0,0.55c0.02,2,0.02,1.99-1.94,2.6c-0.26,0.08-0.57,0.34-0.65,0.59c-0.76,2.29-1.84,4.54-2.12,6.89
		c-0.37,3.13-3.04,4.93-3.82,7.79c-0.33,1.22-1.56,2.19-2.36,3.28c-0.77,1.05-1.74,1.75-3.07,1.87c-2.21,0.2-4.43,0.42-6.64,0.61
		c-1.03,0.09-2.16,0.02-2.66-0.97c-0.7-1.41-1.74-2.43-2.9-3.42c-0.46-0.39-0.62-1.12-1.03-1.58c-0.5-0.57-1.08-1.1-1.71-1.5
		c-0.93-0.59-2.03-0.93-2.89-1.59c-1.7-1.3-2.41-1.46-4.28-0.34c-0.79,0.47-1.48,1.07-2.23,1.62c-0.68,0.49-1.31,1.05-2.04,1.45
		c-0.77,0.43-1.6,0.5-2.31-0.22c-0.73-0.73-0.67-1.6-0.13-2.29c0.8-1.02,1.77-1.92,2.7-2.83c0.7-0.69,1.48-1.31,2.18-2.01
		c0.58-0.58,1-1.38,1.67-1.83c2.3-1.58,3.66-3.63,3.72-6.47c0.03-1.42,0.79-2.48,1.86-3.34c0.61-0.49,1.2-1,2.04-1.69
		c-1.65,0.21-2.99,0.31-4.3,0.56c-0.96,0.18-1.99,0.39-2.8,0.89c-1.62,0.98-3.34,1.54-5.19,1.7c-0.8,0.07-1.32,0.35-1.48,1.1
		c-0.27,1.21-1.09,1.48-2.19,1.6c-2.57,0.27-5.09,0.82-7.08,2.61c-1.58,1.43-3.18,1.37-4.98,0.57c-1.12-0.5-2.32-0.84-3.48-1.24
		c-1.18-0.4-2.36-0.79-3.72-1.25c-0.08,0.57-0.2,1.33-0.28,2.1c-0.17,1.65-0.98,2.45-2.64,2.47c-1.16,0.02-2.32-0.08-3.48,0
		c-0.36,0.02-0.92,0.39-0.99,0.69c-0.23,1.08-0.92,1.33-1.85,1.27c-1-0.06-2-0.09-2.98-0.28c-0.56-0.1-1.27-0.35-1.56-0.77
		c-1.33-1.97-3.44-2.95-5.2-4.37c-1.64-1.32-3.66-1.72-5.52-1.34c-2.71,0.56-5.31,1.63-7.93,2.57c-2.83,1.02-5.34,2.47-6.91,5.23
		c-1.06,1.87-2.76,2.81-4.95,2.85c-1.04,0.02-2.08,0.26-3.1,0.21c-0.81-0.04-1.63-0.32-2.4-0.61c-0.65-0.24-1.26-0.6-1.83-1
		c-0.92-0.65-2.23-1.21-2.58-2.12c-0.44-1.14-1.05-1.85-1.99-2.46c-0.6-0.39-1.16-0.86-1.7-1.33c-1.31-1.16-1.96-2.69-1.79-4.39
		c0.15-1.48,0.2-2.6-1.03-3.85c-1.4-1.42-2.91-2.52-4.61-3.5c-1.48-0.85-3.04-1.75-4.14-3c-1.04-1.19-1.87-1.42-3.27-0.9
		c-1.57,0.59-2.78,0.12-3.72-1.3c-0.67-1.01-1.39-1.12-2.44-0.63c-2.36,1.11-3.24,0.93-5-0.92c-0.12,0.08-0.26,0.15-0.37,0.25
		c-2.02,1.97-2.02,1.97-4.51,0.91c-0.07-0.03-0.15-0.08-0.22-0.08c-2.12-0.2-3.61-1.39-4.7-3.16c-1.86,1.16-3.39,0.03-5-0.56
		c-0.4-0.15-0.87-0.32-1.27-0.25c-2.98,0.48-6.03-0.9-7.05-3.25c-0.96,0.24-1.99,0.83-2.85,0.63c-0.79-0.19-1.38-1.22-2.09-1.91
		c-1.49,0.63-1.8,0.6-4.13-0.4c-0.24,0.36-0.44,0.75-0.72,1.08c-0.62,0.75-1.36,1.29-2.39,0.86c-1.08-0.44-1.06-1.3-0.9-2.3
		c0.12-0.79,0.03-1.64-0.11-2.43c-0.14-0.81-0.46-1.58-0.74-2.5c-0.39,0.12-0.98,0.4-1.59,0.46c-0.71,0.07-1.74,0.21-2.1-0.17
		c-0.9-0.95-1.77-2.09-2.14-3.32c-0.37-1.23-0.79-1.84-2.11-1.95c-2.24-0.19-2.97-1.75-1.68-3.6c0.5-0.71,0.82-1.29,0.34-2.12
		c-0.12-0.21-0.07-0.53-0.11-0.83c-1.32-0.25-2.65-0.6-3.08-2.08c-0.35-1.18-1.22-1.62-2.2-2.15c-1.27-0.69-2.48-1.55-3.56-2.52
		c-0.9-0.81-1.58-1.89-2.37-2.87c-2.04,0.46-4.06,0.98-5.37,2.93c-0.69,1.02-1.58,1.89-2.38,2.83c-1.65,1.95-3.27,3.92-4.94,5.85
		c-0.66,0.76-1.34,1.67-2.21,2.04c-0.62,0.26-1.58-0.29-2.47-0.5c0,0.38,0,0.88,0,1.39c0.02,1.6-0.25,3.16-1.39,4.34
		c-0.47,0.49-1.38,1.05-1.91,0.9c-1.66-0.45-2.59,0.19-3.35,1.52c-0.21,0.37-0.52,0.73-0.86,0.99c-0.67,0.52-0.7,0.97-0.23,1.7
		c0.88,1.35,0.41,2.68-1.07,3.34c-0.42,0.19-0.87,0.3-1.46,0.49c1.04,2.3,0.2,4.22-0.98,6.14c-0.52,0.83-0.88,1.81-1.09,2.77
		c-0.58,2.63-0.78,5.31,1.22,7.47c1.31,1.41,1.81,2.74,1.12,4.64c-0.78,2.12,0.07,3.76,2.15,4.66c0.84,0.36,1.73,0.62,2.6,0.92
		c1.03,0.36,1.63,1.03,1.79,2.16c0.21,1.51,0.35,1.55,1.78,1.18c1.89-0.49,2.65-0.17,3.94,1.37c0.88,1.06,1.78,2.21,2.91,2.93
		c1.55,0.99,1.99,1.37,1.77,3.24c-0.09,0.78,0.07,1.33,0.63,1.92c0.49,0.53,0.84,1.27,1.04,1.97c0.27,0.95,0.32,1.97,0.52,2.95
		c0.06,0.29,0.28,0.69,0.51,0.77c1.93,0.66,2.03,0.65,1.96,2.61c-0.05,1.43,0.9,2.27,1.57,3.26c0.21,0.32,1.01,0.18,1.47,0.41
		c0.66,0.33,1.31,0.74,1.85,1.24c0.58,0.54,1.07,1.19,1.52,1.85c0.57,0.82,1.08,0.92,1.94,0.28c1.2-0.9,2.48-0.34,2.82,1.13
		c0.12,0.54,0.17,1.11,0.22,1.66c0.2,2.18,0.23,2.23,2.41,2.7c2.1,0.46,3.15,1.73,3.15,3.89c0,0.8-0.11,1.59-0.17,2.39
		c-0.2,2.54,1.41,5.07,3.82,5.31c2.34,0.24,4.11,1.09,5.43,2.99c0.13,0.19,0.38,0.3,0.52,0.5c0.67,0.97,1.73,1.9,1.87,2.94
		c0.32,2.3,1.3,3.33,3.64,3.64c1.7,0.22,2.01,2.11,0.74,3.31c-0.35,0.33-0.78,0.57-1.13,0.89c-0.95,0.88-1.87,1.79-2.89,2.78
		c0.71,0.36,1.31,0.51,1.68,0.88c0.62,0.63,1.26,1.34,1.56,2.14c0.24,0.65,0.37,1.1,1.11,1.33c1.07,0.34,1.82,1.11,1.78,2.3
		c-0.05,1.29,0.61,2.1,1.52,2.81c1.36,1.05,2.75,2.07,4.08,3.15c2.42,1.96,2.73,4.16,1.03,6.72c-0.32,0.49-0.66,1.04-0.76,1.6
		c-0.21,1.22-0.83,2.15-1.76,2.9c-1.3,1.06-1.94,2.29-1.25,3.98c0.19,0.47,0.25,1.01,0.29,1.53c0.11,1.37,0.23,2.78-0.99,3.77
		c-0.92,0.74-1.85,1.42-1.41,2.82c-1.29,1.66-1.13,3.73-1.67,5.61c-0.47,1.64-1.54,3.13-2.44,4.62c-0.36,0.59-1.05,0.96-1.48,1.52
		c-0.35,0.45-0.86,1.09-0.76,1.53c0.26,1.24-0.17,2.1-1,2.88c-1.77,1.68-2.69,3.67-3.05,6.16c-0.41,2.93-1.59,5.69-4.07,7.63
		c-0.74,0.58-0.9,1.31-0.88,2.22c0.02,0.76,0.04,1.78-0.4,2.24c-0.89,0.91-0.99,1.76-0.54,2.8c0.75,1.71,0.4,3.24-0.57,4.76
		c-0.47,0.75-1.37,1.53-0.42,2.58c0.09,0.1,0.01,0.39-0.02,0.58c-0.46,2.52-0.92,5.05-1.38,7.55c2.01,1.22,2.07,1.4,1.14,3.46
		c1,1.76,3.84,2.46,2.92,5.24c0.08,0.09,0.11,0.17,0.18,0.2c0.14,0.07,0.29,0.14,0.45,0.16c1.55,0.24,2.04,1.31,2.25,2.7
		c0.17,1.14,0.37,2.31,0.79,3.37c0.26,0.66,0.88,1.29,1.5,1.67c1.15,0.71,1.67,1.53,1.12,2.77c-0.42,0.95-1.14,1.76-1.82,2.77
		c0.48,1.32,0.36,2.72-0.63,3.31c-0.66,0.39-1.06,0.81-1,1.53c0.05,0.65,0.08,1.38,0.4,1.9c0.67,1.08,0.65,2.04,0.1,3.16
		c-0.27,0.56-0.3,1.29-0.27,1.94c0.1,2.87,2.07,4.86,3.46,7.12c0.19,0.32,0.8,0.47,1.24,0.52c1.7,0.21,3.41,0.4,5.12,0.52
		c1.1,0.08,2.22,0.1,3.33,0.01c2.5-0.2,5-0.69,7.49-0.66c2.58,0.03,5.09-0.08,7.51-1.01c0.34-0.13,0.69-0.56,0.78-0.92
		c0.31-1.24,0.93-2.02,2.21-1.93c1.1,0.08,2.23,0.49,3.23,0.99c0.71,0.36,1.25,0.4,2.01,0.22c2.2-0.54,4.43-0.94,6.65-1.38
		c0.08-0.02,0.16,0,0.24,0c3.07,0.04,6.14,0.13,9.21,0.1c2.93-0.03,4.01,1.44,2.98,4.21c-0.33,0.9-0.88,1.78-0.94,2.7
		c-0.08,1.16,0.07,2.4,0.42,3.51c0.15,0.48,1.07,0.72,1.64,1.05c1.08,0.62,2.4,1.18,2.37,2.61c-0.03,1.61,0.25,3.13,0.47,4.7
		c0.18,1.32-0.06,2.67,0.81,3.88c0.24,0.33,0.24,0.96,0.13,1.4c-0.32,1.25-0.57,2.46-0.04,3.73c0.13,0.3-0.12,0.76-0.2,1.15
		c-0.27,1.28-0.58,2.55-0.8,3.83c-0.1,0.59,0.17,0.94,0.9,0.91c1.48-0.06,2.97-0.02,4.44-0.02c0.6-0.53,1.17-1.36,1.95-1.67
		c1.76-0.7,3.54,0.6,3.69,2.56c0.08,1,0.36,1.73,1.39,2.14c0.82,0.33,1.56,0.85,2.19,1.2c0.98,0.1,2.09-0.09,2.68,0.37
		c0.58,0.45,0.64,1.58,0.97,2.54c0.53,0.18,1.3,0.48,2.09,0.72c0.29,0.08,0.69,0.12,0.92-0.02c1.14-0.71,2.37-1.31,2.72-2.84
		c0.34-1.5,1.41-2.4,2.87-2.76c1.43-0.35,2.87-0.66,4.33-0.87c1.82-0.27,2.62-1.03,2.79-2.85c0.07-0.8,0.1-1.6,0.23-2.38
		c0.29-1.81,1.9-2.96,3.59-2.36c1.24,0.44,2.2,0.38,3.18-0.45c0.26-0.22,0.73-0.22,0.96-0.46c0.34-0.34,0.75-0.8,0.77-1.23
		c0.05-1.14,0.54-1.8,1.63-2.04C1045.94,789.21,1046.37,789.15,1046.9,789.05z"/>
					<path class="querencia" d="M1046.9,789.05c-0.53,0.1-0.96,0.16-1.39,0.26c-1.09,0.24-1.58,0.9-1.63,2.04c-0.02,0.43-0.43,0.88-0.77,1.23
		c-0.23,0.24-0.7,0.23-0.96,0.46c-0.98,0.84-1.94,0.9-3.18,0.45c-1.7-0.61-3.3,0.55-3.59,2.36c-0.13,0.79-0.16,1.59-0.23,2.38
		c-0.16,1.82-0.97,2.59-2.79,2.85c-1.45,0.21-2.9,0.52-4.33,0.87c-1.46,0.36-2.53,1.27-2.87,2.76c-0.35,1.53-1.58,2.13-2.72,2.84
		c-0.23,0.14-0.63,0.1-0.92,0.02c-0.79-0.23-1.56-0.53-2.09-0.72c-0.33-0.96-0.39-2.09-0.97-2.54c-0.59-0.46-1.7-0.27-2.68-0.37
		c-0.63-0.35-1.37-0.87-2.19-1.2c-1.02-0.41-1.31-1.14-1.39-2.14c-0.15-1.96-1.93-3.26-3.69-2.56c-0.78,0.31-1.35,1.14-1.95,1.67
		c-1.46,0-2.95-0.04-4.44,0.02c-0.73,0.03-1-0.32-0.9-0.91c0.22-1.29,0.53-2.55,0.8-3.83c0.08-0.39,0.33-0.85,0.2-1.15
		c-0.53-1.27-0.28-2.48,0.04-3.73c0.11-0.44,0.11-1.06-0.13-1.4c-0.87-1.21-0.62-2.56-0.81-3.88c-0.22-1.57-0.5-3.09-0.47-4.7
		c0.03-1.43-1.29-1.99-2.37-2.61c-0.58-0.33-1.49-0.57-1.64-1.05c-0.36-1.11-0.51-2.35-0.42-3.51c0.07-0.92,0.61-1.8,0.94-2.7
		c1.03-2.77-0.04-4.24-2.98-4.21c-3.07,0.03-6.14-0.06-9.21-0.1c-0.08,0-0.16-0.02-0.24,0c-2.22,0.45-4.45,0.85-6.65,1.38
		c-0.76,0.19-1.3,0.14-2.01-0.22c-1-0.5-2.13-0.91-3.23-0.99c-1.28-0.09-1.9,0.69-2.21,1.93c-0.09,0.36-0.44,0.79-0.78,0.92
		c-2.42,0.92-4.92,1.04-7.51,1.01c-2.49-0.03-4.99,0.47-7.49,0.66c-1.1,0.09-2.22,0.07-3.33-0.01c-1.71-0.12-3.42-0.3-5.12-0.52
		c-0.44-0.06-1.05-0.21-1.24-0.52c-1.39-2.26-3.35-4.25-3.46-7.12c-0.02-0.65,0-1.38,0.27-1.94c0.55-1.12,0.57-2.07-0.1-3.16
		c-0.32-0.52-0.35-1.26-0.4-1.9c-0.05-0.72,0.34-1.14,1-1.53c0.98-0.59,1.1-1.99,0.63-3.31c0.68-1.01,1.4-1.83,1.82-2.77
		c0.55-1.23,0.03-2.06-1.12-2.77c-0.62-0.38-1.23-1.01-1.5-1.67c-0.42-1.06-0.62-2.23-0.79-3.37c-0.21-1.39-0.7-2.46-2.25-2.7
		c-0.16-0.02-0.31-0.09-0.45-0.16c-0.07-0.03-0.1-0.12-0.18-0.2c0.93-2.78-1.91-3.48-2.92-5.24c0.93-2.06,0.87-2.24-1.14-3.46
		c0.46-2.5,0.92-5.02,1.38-7.55c0.04-0.19,0.12-0.48,0.02-0.58c-0.96-1.04-0.06-1.83,0.42-2.58c0.96-1.53,1.31-3.05,0.57-4.76
		c-0.45-1.03-0.36-1.89,0.54-2.8c0.44-0.45,0.43-1.48,0.4-2.24c-0.03-0.91,0.14-1.64,0.88-2.22c2.48-1.94,3.65-4.7,4.07-7.63
		c0.35-2.49,1.27-4.48,3.05-6.16c0.83-0.79,1.27-1.65,1-2.88c-0.09-0.44,0.41-1.08,0.76-1.53c0.43-0.55,1.13-0.93,1.48-1.52
		c0.91-1.49,1.97-2.98,2.44-4.62c0.53-1.87,0.38-3.94,1.67-5.61c-0.44-1.4,0.49-2.08,1.41-2.82c1.22-0.99,1.1-2.4,0.99-3.77
		c-0.04-0.51-0.1-1.05-0.29-1.53c-0.69-1.7-0.05-2.92,1.25-3.98c0.92-0.75,1.54-1.68,1.76-2.9c0.1-0.56,0.43-1.11,0.76-1.6
		c1.7-2.57,1.39-4.77-1.03-6.72c-1.34-1.08-2.72-2.1-4.08-3.15c-0.92-0.71-1.57-1.52-1.52-2.81c0.05-1.18-0.71-1.96-1.78-2.3
		c-0.74-0.23-0.86-0.68-1.11-1.33c-0.3-0.8-0.94-1.51-1.56-2.14c-0.37-0.38-0.97-0.52-1.68-0.88c1.02-0.98,1.95-1.89,2.89-2.78
		c0.35-0.33,0.78-0.56,1.13-0.89c1.27-1.2,0.95-3.09-0.74-3.31c-2.33-0.3-3.32-1.34-3.64-3.64c-0.14-1.05-1.21-1.97-1.87-2.94
		c-0.13-0.19-0.38-0.3-0.52-0.5c-1.31-1.91-3.09-2.75-5.43-2.99c-2.41-0.25-4.02-2.78-3.82-5.31c0.06-0.8,0.17-1.59,0.17-2.39
		c0-2.17-1.04-3.43-3.15-3.89c-2.18-0.48-2.21-0.52-2.41-2.7c-0.05-0.56-0.1-1.12-0.22-1.66c-0.33-1.47-1.61-2.03-2.82-1.13
		c-0.86,0.64-1.36,0.54-1.94-0.28c-0.45-0.65-0.94-1.31-1.52-1.85c-0.54-0.5-1.19-0.91-1.85-1.24c-0.45-0.23-1.25-0.09-1.47-0.41
		c-0.67-0.99-1.62-1.83-1.57-3.26c0.07-1.96-0.03-1.95-1.96-2.61c-0.24-0.08-0.45-0.48-0.51-0.77c-0.2-0.98-0.25-1.99-0.52-2.95
		c-0.2-0.71-0.55-1.45-1.04-1.97c-0.56-0.6-0.72-1.14-0.63-1.92c0.22-1.86-0.22-2.24-1.77-3.24c-1.13-0.73-2.03-1.87-2.91-2.93
		c-1.29-1.54-2.05-1.86-3.94-1.37c-1.43,0.37-1.57,0.33-1.78-1.18c-0.16-1.14-0.75-1.8-1.79-2.16c-0.87-0.3-1.76-0.56-2.6-0.92
		c-2.08-0.9-2.93-2.53-2.15-4.66c0.69-1.9,0.19-3.23-1.12-4.64c-2.01-2.16-1.8-4.84-1.22-7.47c0.21-0.96,0.58-1.94,1.09-2.77
		c1.18-1.91,2.02-3.84,0.98-6.14c0.59-0.2,1.04-0.31,1.46-0.49c1.47-0.66,1.94-1.98,1.07-3.34c-0.47-0.73-0.44-1.18,0.23-1.7
		c0.34-0.26,0.64-0.62,0.86-0.99c0.76-1.33,1.7-1.97,3.35-1.52c0.53,0.14,1.44-0.41,1.91-0.9c1.13-1.18,1.41-2.74,1.39-4.34
		c-0.01-0.5,0-1,0-1.39c0.9,0.21,1.85,0.76,2.47,0.5c0.87-0.36,1.55-1.27,2.21-2.04c1.68-1.92,3.3-3.9,4.94-5.85
		c0.8-0.94,1.69-1.82,2.38-2.83c1.31-1.95,3.32-2.47,5.37-2.93c0.8,0.98,1.47,2.06,2.37,2.87c1.08,0.97,2.29,1.83,3.56,2.52
		c0.98,0.53,1.85,0.97,2.2,2.15c0.44,1.48,1.77,1.82,3.08,2.08c0.03,0.3-0.02,0.62,0.11,0.83c0.48,0.82,0.16,1.41-0.34,2.12
		c-1.28,1.85-0.56,3.41,1.68,3.6c1.31,0.11,1.74,0.72,2.11,1.95c0.37,1.22,1.24,2.37,2.14,3.32c0.37,0.39,1.39,0.24,2.1,0.17
		c0.61-0.06,1.2-0.34,1.59-0.46c0.28,0.92,0.6,1.7,0.74,2.5c0.14,0.8,0.23,1.64,0.11,2.43c-0.16,1.01-0.18,1.86,0.9,2.3
		c1.03,0.42,1.77-0.11,2.39-0.86c0.28-0.33,0.48-0.72,0.72-1.08c2.33,1,2.64,1.02,4.13,0.4c0.71,0.69,1.3,1.72,2.09,1.91
		c0.85,0.2,1.89-0.39,2.85-0.63c1.02,2.35,4.07,3.73,7.05,3.25c0.4-0.07,0.87,0.11,1.27,0.25c1.61,0.59,3.14,1.72,5,0.56
		c1.09,1.78,2.58,2.96,4.7,3.16c0.08,0.01,0.15,0.05,0.22,0.08c2.49,1.06,2.49,1.06,4.51-0.91c0.11-0.1,0.25-0.17,0.37-0.25
		c1.76,1.85,2.63,2.03,5,0.92c1.04-0.49,1.77-0.37,2.44,0.63c0.94,1.42,2.15,1.89,3.72,1.3c1.4-0.52,2.23-0.29,3.27,0.9
		c1.09,1.25,2.66,2.15,4.14,3c1.7,0.98,3.2,2.08,4.61,3.5c1.23,1.24,1.18,2.37,1.03,3.85c-0.17,1.69,0.48,3.22,1.79,4.39
		c0.54,0.48,1.1,0.94,1.7,1.33c0.94,0.61,1.55,1.32,1.99,2.46c0.35,0.9,1.66,1.46,2.58,2.12c0.57,0.4,1.18,0.76,1.83,1
		c0.77,0.29,1.59,0.57,2.4,0.61c1.03,0.05,2.07-0.2,3.1-0.21c2.19-0.04,3.89-0.98,4.95-2.85c1.57-2.76,4.08-4.21,6.91-5.23
		c2.62-0.95,5.22-2.02,7.93-2.57c1.86-0.38,3.88,0.02,5.52,1.34c1.76,1.42,3.87,2.4,5.2,4.37c0.29,0.42,1,0.66,1.56,0.77
		c0.98,0.18,1.98,0.22,2.98,0.28c0.93,0.06,1.62-0.19,1.85-1.27c0.06-0.3,0.63-0.66,0.99-0.69c1.15-0.08,2.32,0.02,3.48,0
		c1.66-0.02,2.47-0.81,2.64-2.47c0.08-0.77,0.2-1.53,0.28-2.1c1.36,0.46,2.55,0.84,3.72,1.25c1.17,0.4,2.36,0.74,3.48,1.24
		c1.8,0.8,3.4,0.86,4.98-0.57c1.98-1.79,4.51-2.34,7.08-2.61c1.1-0.12,1.92-0.39,2.19-1.6c0.17-0.76,0.69-1.04,1.48-1.1
		c1.85-0.16,3.57-0.72,5.19-1.7c0.82-0.5,1.84-0.7,2.8-0.89c1.31-0.25,2.66-0.36,4.3-0.56c-0.83,0.69-1.43,1.19-2.04,1.69
		c-1.07,0.87-1.83,1.92-1.86,3.34c-0.07,2.84-1.42,4.9-3.72,6.47c-0.66,0.45-1.08,1.25-1.67,1.83c-0.7,0.7-1.48,1.32-2.18,2.01
		c-0.93,0.92-1.89,1.81-2.7,2.83c-0.54,0.69-0.6,1.55,0.13,2.29c0.72,0.72,1.54,0.65,2.31,0.22c0.73-0.4,1.36-0.96,2.04-1.45
		c0.74-0.54,1.44-1.15,2.23-1.62c1.87-1.11,2.59-0.96,4.28,0.34c0.86,0.66,1.96,1,2.89,1.59c0.63,0.4,1.21,0.94,1.71,1.5
		c0.41,0.47,0.57,1.19,1.03,1.58c1.17,0.99,2.2,2.01,2.9,3.42c0.49,1,1.62,1.06,2.66,0.97c2.22-0.19,4.43-0.41,6.64-0.61
		c1.33-0.12,2.3-0.82,3.07-1.87c0.8-1.1,2.02-2.07,2.36-3.28c0.78-2.86,3.45-4.66,3.82-7.79c0.28-2.35,1.36-4.61,2.12-6.89
		c0.08-0.24,0.39-0.5,0.65-0.59c1.96-0.61,1.97-0.6,1.94-2.6c0-0.16,0-0.31,0-0.55c0.22-0.07,0.43-0.17,0.66-0.2
		c1.91-0.29,3.3-1.11,3.98-3.11c0.23-0.66,1.26-1.05,2.12-1.7c1.11,3.44,1.22,7.16,5.49,8.44c-0.84,2.46-1.51,4.83-2.46,7.08
		c-0.87,2.05-1.58,4.13-0.87,6.26c1.07,3.2,0.58,6.31,0.06,9.47c-0.24,1.45-0.39,2.92-0.59,4.45c0.33,0.19,0.66,0.4,1.01,0.57
		c1.78,0.91,2.72,2.37,2.6,4.36c-0.23,3.79,0.4,7.48,1,11.19c0.21,1.27-0.02,2.71-0.42,3.95c-0.52,1.62-1.43,3.11-2.15,4.67
		c-0.86,1.87-1.41,3.81-1.32,5.91c0.02,0.4-0.23,0.91-0.52,1.21c-0.74,0.77-1.58,1.46-2.42,2.22c1.48,1.24,2.93,2.38,4.27,3.63
		c0.57,0.54,1.08,1.28,1.3,2.02c0.94,3.26,1.77,6.55,2.64,9.83c0.1,0.38,0.22,0.75,0.34,1.16c0.5-0.05,0.93-0.08,1.36-0.12
		c0.06,0.05,0.16,0.08,0.18,0.15c0.92,2.85,1.63,3.39,4.87,3.08c1.35-0.13,1.74,0.35,1.93,1.53c0.47,2.92-0.18,5.56-1.88,7.95
		c-1.06,1.49-2.46,2.49-4.36,2.65c-0.26,0.02-0.52,0.15-0.85,0.25c0.58,1.17,1.13,2.27,1.67,3.36c-0.07,0.1-0.11,0.2-0.17,0.22
		c-1.65,0.5-1.82,1.68-1.82,3.23c-0.01,3.6,0.01,7.18,0.95,10.7c0.21,0.79,0.04,1.75-0.17,2.59c-0.88,3.56-2.27,6.89-4.45,9.88
		c-1.27,1.74-2.06,3.64-2.26,5.84c-0.07,0.84-0.63,1.74-1.2,2.4c-0.91,1.05-1.95,2.02-3.07,2.84c-2.34,1.71-3.46,4.02-4.06,6.8
		c-0.61,2.84-1.58,5.6-2.41,8.39c-0.48,1.6-1.57,2.59-3.07,3.29c-2.47,1.15-2.45,1.21-2.17,3.89c0.4,3.81,0.95,7.61,1.15,11.44
		c0.12,2.33-0.52,4.71-0.41,7.04c0.13,2.65,0.58,5.31,1.15,7.9c0.57,2.56-0.67,4.24-3.33,4.04c-0.63-0.05-1.25-0.23-2.05-0.39
		c-0.43,1.29-0.8,2.62-1.32,3.89c-0.66,1.62-1.31,3.26-2.2,4.75c-1.86,3.1-2.56,6.51-3.07,10c-0.33,2.25-0.91,4.47-1.08,6.73
		c-0.11,1.51,0.18,3.08,0.52,4.57c0.32,1.43,1,2.78,1.39,4.2c0.15,0.56,0.1,1.27-0.11,1.82c-1.97,5.01-3.99,10-5.99,14.99
		c-0.67,1.67-0.99,3.36-0.54,5.15c0.07,0.3-0.04,0.7-0.18,0.99c-0.79,1.66-1.72,3.25-2.38,4.95c-0.46,1.2-0.5,2.56-0.82,3.83
		c-0.19,0.74-0.37,1.62-0.88,2.11c-2.12,2.06-4.27,4.11-6.6,5.92c-1.55,1.21-3.35,2.15-5.15,2.97c-1.62,0.74-2.35,0.33-3.18-1.27
		c-0.33-0.64-0.57-1.32-0.92-1.95c-0.96-1.7-2.55-2.22-4-0.96c-2.2,1.91-4.98,2.04-7.52,2.88c-0.57,0.19-1.31-0.21-1.97-0.18
		c-3.94,0.17-7.89,0.38-11.96,0.58c0.1-0.61,0.21-1.33,0.33-2.05c0.22-1.42,0.4-2.84,0.69-4.24c0.09-0.4,0.44-0.84,0.8-1.08
		c1.22-0.83,2.12-1.8,2.09-3.4c-0.01-0.33,0.16-0.73,0.38-0.98c1.85-2.15,1.81-4.7,1.53-7.27c-0.19-1.71-0.11-1.97,1.63-2.42
		c1.46-0.38,2.3-1.25,2.7-2.73c0.31-1.14,0.89-2.21,1.3-3.33c0.39-1.07,0.86-2.15,1.04-3.27c0.28-1.77,1.1-3.15,2.45-4.26
		c0.86-0.71,1.79-1.35,2.62-2.1c1.38-1.25,2.03-2.77,1.77-4.72c-0.12-0.89,0.12-1.91,0.49-2.76c0.71-1.66,0.72-3.18-0.43-4.61
		c-0.48-0.6-0.39-1.04,0.19-1.51c1.46-1.17,1.99-2.89,2.44-4.57c0.49-1.84-0.77-2.88-2.64-2.4c-1.92,0.49-3.71,1.22-5.12,2.71
		c-1.07,1.13-2.43,1.63-3.98,1.68c-1,0.04-2.02,0.02-2.98,0.24c-0.78,0.17-1.5,0.63-2.22,1.01c-0.38,0.2-0.67,0.62-1.06,0.74
		c-1.47,0.47-2.2,1.54-2.53,2.94c-0.4,1.66-1.25,2.91-2.85,3.61c-0.95,0.42-1.42,1.21-1.65,2.21c-0.35,1.5-0.88,1.8-2.63,1.65
		c-1.31-0.11-1.41-0.55-1.82-2.03c-0.72-2.65-1.02-5.27-0.75-8.01c0.13-1.35-0.65-1.87-2.03-1.96c-2.44-0.15-4.42,0.79-4.16,4
		C1046.96,787.21,1046.9,788.09,1046.9,789.05z"/>
				</g>
				<g>
					<path d="M1107.28,593.45c-0.62-0.74-1.24-1.48-1.85-2.23c-0.28-0.34-0.48-0.77-0.82-1c-1.18-0.82-2.05-1.84-2.64-3.18
		c-0.2-0.46-0.85-0.76-1.35-1.06c-1.14-0.69-2.4-1.22-3.45-2.03c-0.95-0.74-1.9-0.95-2.88-0.42c-1.22,0.66-2.32,1.52-3.49,2.29
		c-0.76,0.5-1.49,1.13-2.33,1.4c-0.52,0.16-1.4-0.08-1.79-0.47c-0.35-0.34-0.52-1.31-0.28-1.7c0.68-1.09,1.57-2.07,2.45-3.02
		c0.98-1.07,2.03-2.08,3.08-3.09c0.57-0.55,1.18-1.08,1.82-1.55c1.85-1.36,2.93-3.14,3.01-5.46c0.06-1.73,0.83-3.05,2.19-4.07
		c0.57-0.43,1.12-0.88,1.63-1.46c-2.43,0.76-5.08,0.14-7.43,1.61c-1.3,0.81-2.95,1.12-4.49,1.47c-0.9,0.2-1.58,0.45-1.8,1.41
		c-0.2,0.85-0.73,1.19-1.62,1.28c-3.06,0.31-5.93,1.14-8.33,3.26c-1.04,0.92-2.32,0.64-3.54,0.25c-1.85-0.6-3.71-1.19-5.54-1.84
		c-0.7-0.25-1.34-0.64-2.22-1.08c-0.1,0.51-0.28,1.08-0.32,1.67c-0.16,2.43-0.96,3.18-3.4,3.09c-0.64-0.02-1.28-0.07-1.91-0.09
		c-0.92-0.03-1.81-0.19-2.05,1.19c-0.07,0.38-1.11,0.76-1.74,0.83c-0.86,0.1-1.8,0.05-2.6-0.23c-0.86-0.29-1.72-0.8-2.37-1.43
		c-2.5-2.44-5.05-4.8-8.74-5.13c-1.08-0.1-2.28-0.02-3.29,0.35c-3.1,1.14-6.21,2.31-9.16,3.78c-1.32,0.66-2.49,1.9-3.33,3.15
		c-1.54,2.29-3.44,3.59-6.25,3.47c-1.22-0.05-2.54,0.28-3.65-0.08c-1.59-0.51-3.05-1.45-4.53-2.28c-0.39-0.22-0.9-0.67-0.93-1.04
		c-0.14-1.83-1.7-2.38-2.84-3.26c-2.03-1.55-2.98-3.43-2.52-6.02c0.16-0.89-0.13-1.66-0.77-2.54c-2.04-2.8-5.39-3.63-7.85-5.73
		c-0.57-0.49-1.23-0.94-1.64-1.55c-0.58-0.87-1.29-1.04-2.16-0.7c-1.98,0.77-3.55,0.45-4.77-1.43c-0.23-0.36-1-0.42-1.54-0.51
		c-0.23-0.04-0.51,0.19-0.77,0.3c-2.16,0.98-3.25,0.75-4.9-1.2c-0.44,0.51-0.84,1.05-1.32,1.49c-1.01,0.94-1.74,0.96-2.89,0.22
		c-0.26-0.17-0.55-0.39-0.84-0.42c-2.05-0.27-3.55-1.35-4.63-3.14c-1.89,1.09-3.45,0.04-5.08-0.64c-0.3-0.13-0.62-0.25-0.94-0.3
		c-1.72-0.23-3.46-0.35-5.17-0.67c-1.21-0.23-1.88-1.14-1.97-2.6c-1.9,0.58-4.07,2.05-4.71-1.16c-0.87,0.13-1.6,0.37-2.32,0.32
		c-0.73-0.06-1.44-0.4-2.21-0.64c-0.34,0.43-0.7,0.95-1.13,1.4c-0.62,0.65-1.4,0.78-2.19,0.36c-0.79-0.42-0.93-1.12-0.73-1.95
		c0.28-1.15,0.33-2.25-0.38-3.33c-0.28-0.43-0.19-1.11-0.28-1.82c-0.5,0.15-1.1,0.3-1.68,0.51c-1.12,0.4-2.13,0.18-2.77-0.77
		c-0.68-1-1.3-2.1-1.65-3.25c-0.29-0.93-0.74-1.45-1.64-1.41c-2.76,0.11-3.4-2.56-1.73-4.18c0.22-0.21,0.21-0.75,0.18-1.13
		c-0.03-0.43-0.21-0.84-0.35-1.36c-1.73-0.09-2.74-1.16-3.44-2.75c-0.22-0.5-0.85-0.97-1.4-1.17c-2.77-1-4.47-2.94-5.45-5.66
		c-0.35-0.96-0.92-1.86-1.49-2.72c-0.76-1.17-0.86-2.34-0.42-3.65c0.29-0.85,0.43-1.77,0.54-2.67c0.11-0.88-0.15-1.62-1-2.13
		c-0.38-0.23-0.65-0.7-0.89-1.1c-0.82-1.37-1.68-2.72-2.37-4.15c-0.37-0.76-0.5-1.67-0.57-2.52c-0.08-1-0.31-1.81-1.11-2.53
		c-0.69-0.62-1.18-1.47-1.76-2.23c0-1.28,0-2.56,0-3.84c0.98-1.49,1.92-3,2.94-4.45c0.65-0.93,1.43-1.77,2.13-2.66
		c1.36-1.74,3.21-2.23,5.31-2.25c1.67-0.01,3.35-0.09,5.02-0.26c0.47-0.05,1.03-0.45,1.31-0.85c1.38-1.97,2.03-4.22,2.3-6.6
		c0.15-1.3,0.38-2.59,0.52-3.89c0.05-0.46,0.04-0.98-0.13-1.4c-1.14-2.85-0.31-5.06,2.08-6.82c0.13-0.09,0.25-0.2,0.36-0.32
		c0.05-0.05,0.09-0.13,0.21-0.32c-2.21-0.57-2.28-2.32-2.35-4.13c-0.05-1.06-0.25-2.13-0.5-3.17c-0.33-1.38-0.09-2.67,0.67-3.78
		c2.1-3.06,3.31-6.41,3.77-10.09c0.26-2.07,2.86-4.35,4.96-4.68c0.43-0.07,0.91-0.09,1.26-0.31c0.29-0.18,0.58-0.64,0.56-0.95
		c-0.02-0.29-0.41-0.67-0.72-0.79c-1.47-0.58-2.01-1.11-1.94-2.73c0.09-2.02,0.43-4.03,0.61-6.05c0.03-0.35-0.13-0.82-0.37-1.09
		c-1.17-1.28-1.28-2-0.5-3.54c0.15-0.29,0.29-0.73,0.18-0.99c-0.82-1.88,0.09-3.31,1.3-4.51c0.95-0.94,2.11-1.7,3.24-2.44
		c1.49-0.97,2.63-2.1,3.06-3.94c0.17-0.71,0.87-1.42,1.51-1.87c0.9-0.64,1.54-1.29,1.66-2.46c0.11-1.09,0.83-1.76,1.97-1.67
		c1.33,0.11,1.89-0.54,2.33-1.71c1.24-3.28,3.56-5.36,6.94-6.58c2.2-0.8,4.37-1.77,6.58-2.62c0.55-0.21,1.11-0.53,1.51-0.95
		c0.43-0.46,0.9-1.06,0.97-1.65c0.19-1.54,0.12-3.11,0.28-4.66c0.05-0.54,0.47-1.05,0.71-1.58c0.16-0.34,0.29-0.69,0.42-1
		c67.33,4.46,134.69,8.92,202.23,13.39c-2.08,5.71-4.05,11.4-6.28,17c-0.51,1.28-1.69,2.49-2.87,3.26c-3.46,2.27-4.7,5.47-4.93,9.44
		c-0.22,3.74-0.73,7.47-1.38,11.16c-0.55,3.13-2.24,5.75-4.44,8.08c-0.75,0.79-1.08,2-1.51,3.05c-0.7,1.73-1.35,3.49-1.97,5.25
		c-0.44,1.27-0.75,2.58-1.21,3.84c-0.43,1.19-0.76,2.51-1.51,3.47c-1.57,2-3.39,3.8-5.2,5.79c0.27,0.53,0.61,1.15,0.91,1.79
		c1.87,4.04,1.45,7.78-1.56,11.1c-0.59,0.65-0.69,1.2-0.48,2.01c0.72,2.68,0.15,5.31-0.38,7.94c-0.53,2.59-1.11,5.21-0.06,7.79
		c0.57,1.4,0.52,2.75,0.01,4.08c-0.29,0.77-0.71,1.54-1.24,2.16c-2.16,2.56-2.82,5.49-2.3,8.74c0.45,2.8,0,5.33-1.72,7.7
		c-4.01,5.5-5.56,11.69-4.69,18.46c0.23,1.78,1.1,3.16,2.64,4.06c2.81,1.64,4.02,4.12,3.89,7.3c-0.07,1.78-0.86,3.21-2.32,4.26
		c-0.32,0.23-0.66,0.45-0.98,0.7c-1.67,1.31-1.98,2.47-1.22,4.41c0.45,1.15,0.21,1.8-0.99,2.15c-1.45,0.42-2.38,1.2-2.99,2.64
		c-0.3,0.71-1.27,1.19-2.03,1.62c-0.57,0.32-1.28,0.38-1.93,0.55c0,0.49,0,0.89,0,1.28c0,1.08-0.44,1.79-1.59,1.83
		c-0.68,0.02-0.92,0.31-1.11,0.97c-0.6,2.08-1.34,4.13-1.99,6.2c-0.17,0.53-0.18,1.1-0.34,1.63c-0.41,1.3-0.88,2.57-2.07,3.42
		c-0.27,0.19-0.52,0.56-0.57,0.88c-0.42,2.67-2.5,4.31-4,6.25c-0.54,0.69-1.88,0.99-2.88,1.05c-1.83,0.1-3.67-0.07-5.39,0.73
		C1108.72,593.45,1108,593.45,1107.28,593.45z M978.72,557.19c0.63-0.54,1.17-1.08,1.78-1.52c0.9-0.64,1.89-0.63,2.59,0.22
		c0.92,1.11,1.81,1.12,3.02,0.49c1.6-0.83,3.27-0.53,4.22,0.79c0.83,1.16,1.65,1.36,2.95,0.9c1.28-0.46,2.57-0.46,3.6,0.77
		c0.68,0.81,1.45,1.59,2.31,2.21c1.05,0.75,2.31,1.22,3.36,1.98c1.71,1.24,3.33,2.61,4.97,3.94c0.17,0.14,0.35,0.38,0.36,0.59
		c0.09,1.27,0.27,2.55,0.17,3.81c-0.11,1.32,0.24,2.37,1.22,3.2c0.73,0.62,1.49,1.21,2.3,1.72c0.83,0.52,1.34,1.18,1.56,2.15
		c0.1,0.44,0.47,0.94,0.86,1.16c1.13,0.64,2.32,1.16,3.5,1.69c0.24,0.11,0.55,0.12,0.82,0.1c0.84-0.05,1.67-0.22,2.5-0.2
		c1.74,0.04,2.95-0.71,3.83-2.19c1.22-2.04,2.9-3.63,5.08-4.6c2.55-1.14,5.09-2.33,7.73-3.23c4.05-1.38,7.89-1.12,11.33,1.85
		c1.4,1.21,3.17,1.94,4.28,3.57c0.53,0.78,2.8,0.8,3.1,0.16c0.57-1.22,1.61-1.47,2.77-1.48c0.96-0.01,1.92,0.01,2.87,0.11
		c1.02,0.1,1.33-0.35,1.39-1.3c0.05-0.82,0.22-1.66,0.51-2.43c0.59-1.54,1.98-1.84,3.29-0.83c0.47,0.36,0.96,0.74,1.5,0.94
		c1.83,0.66,3.68,1.31,5.56,1.82c0.61,0.17,1.57,0.16,1.98-0.21c2.22-2,4.86-2.86,7.73-3.19c0.75-0.09,1.16-0.36,1.5-1.09
		c0.29-0.61,0.99-1.38,1.56-1.43c2.5-0.24,4.69-1.2,6.87-2.31c0.49-0.25,1.07-0.32,1.62-0.42c1.34-0.24,2.67-0.47,4.02-0.66
		c0.67-0.09,1.36-0.15,2.03-0.1c0.46,0.03,0.95,0.21,1.33,0.46c1.31,0.86,1.23,2.5-0.1,3.34c-1.59,1-3.44,1.86-3.64,4
		c-0.29,3.08-1.69,5.41-4.11,7.27c-0.78,0.6-1.36,1.47-2.03,2.21c0.37,0.15,0.56,0.1,0.73,0.03c2.08-0.84,3.85-0.5,5.44,1.19
		c0.59,0.62,1.52,1.01,2.37,1.28c1.28,0.41,2.13,1.11,2.67,2.35c0.3,0.7,0.92,1.26,1.43,1.86c0.63,0.75,1.41,1.39,1.91,2.21
		c0.51,0.82,1.08,1.02,1.98,0.89c1.6-0.24,3.23-0.31,4.82-0.59c0.86-0.15,1.87-0.38,2.45-0.95c2.34-2.28,2.91-5.76,5.26-8.07
		c0.08-0.08,0.1-0.22,0.13-0.33c0.61-2.17,1.05-4.41,1.87-6.5c0.7-1.78,0.42-4.38,3.36-4.66c-0.33-1.21-0.42-2.28,0.87-2.84
		c0.4-0.18,0.81-0.4,1.23-0.45c1.37-0.17,2.32-0.68,2.8-2.14c0.22-0.65,1.07-1.13,1.69-1.6c0.4-0.3,0.91-0.45,1.16-0.57
		c0-1.05-0.1-1.93,0.02-2.78c0.26-1.9,1.55-3.1,3.07-4.08c1.54-0.99,2.03-2.44,1.77-4.14c-0.28-1.82-1.16-3.28-2.83-4.22
		c-2.08-1.17-3.31-2.98-3.63-5.35c-0.99-7.34,0.63-14.07,4.96-20.08c1.39-1.92,1.77-3.92,1.42-6.21c-0.58-3.8,0.11-7.31,2.69-10.32
		c0.38-0.44,0.79-0.99,0.88-1.53c0.14-0.9,0.26-1.92-0.01-2.76c-1-3.03-0.56-6,0.04-9c0.46-2.27,1.1-4.58,0.39-6.86
		c-0.51-1.64-0.17-2.79,0.96-4c2.39-2.55,2.8-5.49,1.28-8.63c-0.62-1.28-1.41-2.49-2.15-3.79c0.8-0.46,1.73-0.8,2.41-1.42
		c1.18-1.07,2.2-2.31,3.29-3.48c1.08-1.16,1.83-2.47,2.11-4.08c0.21-1.24,0.64-2.46,1.06-3.65c0.65-1.83,1.34-3.66,2.1-5.45
		c0.55-1.3,1-2.79,1.94-3.77c2.3-2.41,3.79-5.17,4.27-8.41c0.53-3.59,0.87-7.22,1.17-10.84c0.31-3.71,1.62-6.81,4.77-8.97
		c0.39-0.27,0.83-0.53,1.13-0.89c0.82-1,1.84-1.95,2.3-3.11c1.51-3.78,2.8-7.64,4.17-11.47c0.25-0.69,0.45-1.4,0.66-2.07
		c-66.16-4.38-132.17-8.75-198.27-13.13c-0.06,1.16-0.28,2.48-0.18,3.77c0.24,3.06-1.35,4.93-3.89,6.11
		c-2.11,0.98-4.35,1.66-6.49,2.57c-1.23,0.52-2.59,1.03-3.49,1.93c-1.09,1.09-1.89,2.54-2.55,3.96c-0.72,1.55-1.67,2.49-3.43,2.46
		c-0.19,0-0.38,0.06-0.65,0.11c0.05,1.67-0.96,2.63-2.18,3.51c-0.46,0.33-0.98,0.82-1.1,1.32c-0.4,1.77-1.46,2.98-2.88,3.97
		c-1.11,0.78-2.27,1.5-3.33,2.34c-1.11,0.86-1.72,1.94-1.23,3.45c0.15,0.46,0.03,1.12-0.2,1.58c-0.36,0.71-0.43,1.21,0.25,1.76
		c0.83,0.67,0.95,1.53,0.73,2.59c-0.33,1.64-0.48,3.32-0.7,4.98c-0.11,0.84,0.14,1.36,1.02,1.75c0.61,0.27,1.22,0.95,1.46,1.59
		c0.8,2.13-0.35,3.89-2.62,4.25c-2.33,0.37-4,1.85-4.24,4.06c-0.37,3.42-1.47,6.52-3.46,9.32c-0.76,1.07-0.94,2.21-0.52,3.52
		c0.28,0.86,0.31,1.81,0.34,2.72c0.03,1.1,0.26,1.93,1.37,2.53c1.46,0.78,1.55,2.08,0.39,3.25c-0.45,0.45-1.09,0.77-1.4,1.29
		c-0.47,0.78-1.19,1.8-1.01,2.52c0.55,2.15,0.67,4.21-0.06,6.31c-0.03,0.07-0.01,0.16-0.02,0.24c-0.21,3.19-0.86,6.22-2.7,8.94
		c-0.72,1.07-1.52,1.54-2.73,1.58c-1.91,0.06-3.83,0.14-5.74,0.23c-1.72,0.08-3.11,0.65-3.73,2.45c-0.12,0.35-0.42,0.66-0.69,0.94
		c-0.77,0.85-1.63,1.63-2.32,2.53c-0.62,0.8-1.06,1.74-1.53,2.53c0.21,0.36,0.67,0.78,0.6,1.08c-0.3,1.36,0.73,1.95,1.47,2.61
		c0.78,0.71,1.09,1.48,1.17,2.5c0.07,0.97,0.19,2,0.57,2.88c0.52,1.2,1.29,2.29,2,3.4c0.34,0.53,0.83,0.96,1.18,1.48
		c0.39,0.57,0.99,1.19,1,1.79c0.03,1.57-0.1,3.17-0.4,4.71c-0.16,0.86-0.16,1.53,0.31,2.25c0.52,0.8,1.13,1.6,1.4,2.49
		c0.72,2.36,2.12,3.97,4.48,4.76c1.19,0.4,2.03,1.16,2.54,2.37c0.2,0.49,0.73,1.08,1.21,1.19c1.68,0.38,2.84,1.07,2.66,3.03
		c-0.01,0.14,0.1,0.3,0.17,0.44c0.4,0.88,0.14,1.63-0.46,2.31c-0.25,0.28-0.49,0.57-0.89,1.03c2.77,0.02,4.11,1.25,4.43,3.8
		c0.17,1.38,1.01,1.66,2.41,1.42c1.25-0.22,2.54-0.36,3.8-0.29c0.54,0.03,1.32,0.6,1.48,1.1c0.16,0.48-0.28,1.2-0.54,1.77
		c-0.12,0.26-0.44,0.42-0.63,0.59c0.21,0.72,0.4,1.35,0.59,2.01c0.85-0.69,1.66-0.42,2.47,0.03c0.76,0.43,1.42,0.69,2.27-0.01
		c0.83-0.69,2.16-0.25,2.8,0.75c0.25,0.39,0.43,0.83,0.6,1.19c0.78-0.17,1.47-0.38,2.17-0.46c1.72-0.19,2.19,0.22,2.53,1.89
		c0.07,0.33,0.46,0.81,0.76,0.86c1.46,0.24,2.91,0.91,4.44,0.09c0.33-0.18,0.99,0.04,1.39,0.26c0.89,0.5,1.72,1.11,2.52,1.64
		c1.39-1.48,1.99-1.55,3.14-0.24c1.29,1.48,2.55,2.87,4.69,2.98C978.11,556.75,978.39,557.01,978.72,557.19z"/>
					<path class="confresa" d="M978.72,557.19c-0.33-0.17-0.61-0.44-0.9-0.45c-2.14-0.11-3.4-1.5-4.69-2.98c-1.14-1.31-1.75-1.24-3.14,0.24
		c-0.8-0.53-1.63-1.15-2.52-1.64c-0.4-0.22-1.06-0.44-1.39-0.26c-1.53,0.82-2.98,0.15-4.44-0.09c-0.3-0.05-0.69-0.52-0.76-0.86
		c-0.34-1.66-0.81-2.07-2.53-1.89c-0.7,0.08-1.39,0.29-2.17,0.46c-0.18-0.36-0.35-0.8-0.6-1.19c-0.64-0.99-1.97-1.43-2.8-0.75
		c-0.85,0.7-1.52,0.44-2.27,0.01c-0.81-0.46-1.62-0.73-2.47-0.03c-0.19-0.66-0.38-1.3-0.59-2.01c0.19-0.17,0.52-0.33,0.63-0.59
		c0.26-0.57,0.7-1.29,0.54-1.77c-0.17-0.5-0.94-1.07-1.48-1.1c-1.26-0.07-2.55,0.07-3.8,0.29c-1.41,0.24-2.24-0.04-2.41-1.42
		c-0.32-2.55-1.66-3.78-4.43-3.8c0.4-0.46,0.64-0.75,0.89-1.03c0.6-0.68,0.86-1.43,0.46-2.31c-0.07-0.15-0.18-0.3-0.17-0.44
		c0.17-1.96-0.99-2.65-2.66-3.03c-0.48-0.11-1.01-0.7-1.21-1.19c-0.5-1.21-1.35-1.97-2.54-2.37c-2.36-0.79-3.76-2.4-4.48-4.76
		c-0.27-0.89-0.88-1.69-1.4-2.49c-0.47-0.72-0.48-1.4-0.31-2.25c0.29-1.54,0.43-3.14,0.4-4.71c-0.01-0.6-0.61-1.22-1-1.79
		c-0.36-0.52-0.85-0.95-1.18-1.48c-0.71-1.11-1.48-2.2-2-3.4c-0.38-0.88-0.5-1.91-0.57-2.88c-0.07-1.02-0.38-1.79-1.17-2.5
		c-0.74-0.67-1.77-1.26-1.47-2.61c0.07-0.3-0.39-0.72-0.6-1.08c0.47-0.79,0.91-1.72,1.53-2.53c0.69-0.91,1.55-1.68,2.32-2.53
		c0.26-0.29,0.57-0.59,0.69-0.94c0.62-1.8,2-2.37,3.73-2.45c1.91-0.09,3.83-0.16,5.74-0.23c1.2-0.04,2.01-0.52,2.73-1.58
		c1.84-2.73,2.49-5.76,2.7-8.94c0.01-0.08-0.01-0.17,0.02-0.24c0.73-2.1,0.61-4.15,0.06-6.31c-0.18-0.72,0.54-1.74,1.01-2.52
		c0.32-0.52,0.95-0.84,1.4-1.29c1.16-1.17,1.07-2.47-0.39-3.25c-1.11-0.6-1.34-1.42-1.37-2.53c-0.03-0.91-0.06-1.86-0.34-2.72
		c-0.42-1.31-0.25-2.45,0.52-3.52c1.99-2.8,3.09-5.9,3.46-9.32c0.24-2.21,1.91-3.69,4.24-4.06c2.27-0.36,3.42-2.13,2.62-4.25
		c-0.24-0.64-0.85-1.32-1.46-1.59c-0.88-0.39-1.13-0.9-1.02-1.75c0.22-1.66,0.36-3.34,0.7-4.98c0.22-1.06,0.1-1.93-0.73-2.59
		c-0.69-0.55-0.61-1.05-0.25-1.76c0.23-0.45,0.35-1.11,0.2-1.58c-0.49-1.52,0.13-2.59,1.23-3.45c1.07-0.84,2.22-1.56,3.33-2.34
		c1.42-0.99,2.48-2.21,2.88-3.97c0.11-0.5,0.64-0.99,1.1-1.32c1.22-0.88,2.23-1.85,2.18-3.51c0.27-0.05,0.46-0.12,0.65-0.11
		c1.76,0.04,2.72-0.91,3.43-2.46c0.66-1.42,1.47-2.86,2.55-3.96c0.9-0.91,2.26-1.41,3.49-1.93c2.14-0.91,4.38-1.59,6.49-2.57
		c2.54-1.18,4.13-3.04,3.89-6.11c-0.1-1.29,0.12-2.61,0.18-3.77c66.1,4.38,132.11,8.75,198.27,13.13c-0.21,0.67-0.41,1.38-0.66,2.07
		c-1.37,3.83-2.66,7.7-4.17,11.47c-0.46,1.16-1.48,2.11-2.3,3.11c-0.3,0.36-0.73,0.62-1.13,0.89c-3.15,2.16-4.47,5.27-4.77,8.97
		c-0.3,3.62-0.64,7.24-1.17,10.84c-0.47,3.23-1.97,6-4.27,8.41c-0.94,0.98-1.38,2.47-1.94,3.77c-0.76,1.79-1.45,3.61-2.1,5.45
		c-0.43,1.19-0.85,2.41-1.06,3.65c-0.28,1.61-1.03,2.92-2.11,4.08c-1.09,1.17-2.11,2.41-3.29,3.48c-0.68,0.62-1.61,0.96-2.41,1.42
		c0.74,1.3,1.53,2.51,2.15,3.79c1.52,3.14,1.11,6.09-1.28,8.63c-1.13,1.21-1.48,2.37-0.96,4c0.72,2.28,0.08,4.59-0.39,6.86
		c-0.61,2.99-1.05,5.97-0.04,9c0.28,0.84,0.16,1.86,0.01,2.76c-0.09,0.55-0.5,1.09-0.88,1.53c-2.58,3.01-3.27,6.52-2.69,10.32
		c0.35,2.3-0.03,4.29-1.42,6.21c-4.34,6.01-5.95,12.74-4.96,20.08c0.32,2.37,1.54,4.17,3.63,5.35c1.67,0.94,2.55,2.4,2.83,4.22
		c0.26,1.7-0.22,3.15-1.77,4.14c-1.51,0.98-2.81,2.18-3.07,4.08c-0.12,0.85-0.02,1.73-0.02,2.78c-0.25,0.12-0.76,0.27-1.16,0.57
		c-0.62,0.47-1.47,0.95-1.69,1.6c-0.48,1.46-1.43,1.97-2.8,2.14c-0.42,0.05-0.83,0.27-1.23,0.45c-1.29,0.56-1.2,1.63-0.87,2.84
		c-2.94,0.28-2.66,2.87-3.36,4.66c-0.82,2.09-1.26,4.33-1.87,6.5c-0.03,0.11-0.05,0.26-0.13,0.33c-2.35,2.31-2.93,5.78-5.26,8.07
		c-0.58,0.57-1.59,0.79-2.45,0.95c-1.59,0.28-3.22,0.34-4.82,0.59c-0.91,0.14-1.47-0.06-1.98-0.89c-0.51-0.82-1.28-1.47-1.91-2.21
		c-0.51-0.6-1.13-1.16-1.43-1.86c-0.54-1.25-1.4-1.95-2.67-2.35c-0.85-0.27-1.78-0.65-2.37-1.28c-1.59-1.68-3.35-2.03-5.44-1.19
		c-0.18,0.07-0.36,0.12-0.73-0.03c0.67-0.74,1.25-1.61,2.03-2.21c2.42-1.85,3.83-4.18,4.11-7.27c0.2-2.14,2.05-3.01,3.64-4
		c1.33-0.83,1.4-2.47,0.1-3.34c-0.38-0.25-0.88-0.43-1.33-0.46c-0.67-0.05-1.36,0.01-2.03,0.1c-1.34,0.19-2.68,0.42-4.02,0.66
		c-0.55,0.1-1.13,0.17-1.62,0.42c-2.18,1.11-4.38,2.07-6.87,2.31c-0.58,0.06-1.27,0.83-1.56,1.43c-0.35,0.73-0.75,1-1.5,1.09
		c-2.87,0.33-5.51,1.19-7.73,3.19c-0.41,0.37-1.36,0.37-1.98,0.21c-1.88-0.51-3.73-1.16-5.56-1.82c-0.54-0.2-1.04-0.58-1.5-0.94
		c-1.31-1.01-2.7-0.71-3.29,0.83c-0.29,0.76-0.45,1.61-0.51,2.43c-0.06,0.95-0.38,1.4-1.39,1.3c-0.95-0.1-1.91-0.12-2.87-0.11
		c-1.17,0.01-2.2,0.26-2.77,1.48c-0.3,0.63-2.57,0.61-3.1-0.16c-1.11-1.62-2.88-2.36-4.28-3.57c-3.44-2.97-7.28-3.23-11.33-1.85
		c-2.64,0.9-5.18,2.09-7.73,3.23c-2.17,0.98-3.86,2.56-5.08,4.6c-0.88,1.48-2.09,2.22-3.83,2.19c-0.83-0.02-1.67,0.15-2.5,0.2
		c-0.27,0.02-0.58,0-0.82-0.1c-1.18-0.53-2.37-1.05-3.5-1.69c-0.39-0.22-0.76-0.72-0.86-1.16c-0.22-0.97-0.73-1.63-1.56-2.15
		c-0.81-0.51-1.57-1.1-2.3-1.72c-0.98-0.83-1.33-1.87-1.22-3.2c0.1-1.26-0.08-2.54-0.17-3.81c-0.02-0.21-0.19-0.45-0.36-0.59
		c-1.64-1.33-3.26-2.7-4.97-3.94c-1.05-0.76-2.3-1.23-3.36-1.98c-0.86-0.61-1.63-1.4-2.31-2.21c-1.03-1.23-2.32-1.22-3.6-0.77
		c-1.29,0.46-2.12,0.26-2.95-0.9c-0.95-1.33-2.61-1.62-4.22-0.79c-1.22,0.63-2.1,0.62-3.02-0.49c-0.71-0.85-1.69-0.86-2.59-0.22
		C979.89,556.11,979.35,556.64,978.72,557.19z"/>
				</g>
				<g>
					<path d="M729.84,425.99c-0.24,0.31-0.64,0.6-0.7,0.95c-0.7,4.2-3.87,7.28-8.16,7.94c-1.06,0.16-2.14,0.35-3.13,0.74
		c-1.44,0.56-1.52,1.41-0.35,2.38c0.58,0.48,1.31,0.83,1.79,1.4c1.42,1.66,3.21,3.18,1.94,5.83c-0.8,1.67-0.58,3.39,0.48,5.01
		c1.72,2.62,1.54,5.64,1.62,8.59c0.05,1.96-0.04,3.9,0.92,5.73c0.51,0.98,0.07,1.9-0.85,2.48c-1.13,0.71-1.59,1.7-1.61,3.01
		c-0.01,0.67-0.12,1.36-0.3,2.01c-0.85,3.1-0.91,6.22-0.5,9.38c0.22,1.73,0.72,3.31,1.71,4.8c0.98,1.48,1.92,3.07,2.47,4.75
		c0.65,1.97,0.13,4-1.03,5.71c-0.83,1.23-2.69,1.5-4,0.73c-0.84-0.49-1.48-0.61-2.19,0.23c-0.23,0.28-0.85,0.31-1.29,0.31
		c-0.92,0-1.84-0.12-2.76-0.15c-0.91-0.03-1.82-0.01-3.15-0.01c-0.42,0.39-0.59,2.15-2.04,1.69c-0.99-0.31-1.77-1.27-2.74-2.01
		c-0.17,0.11-0.46,0.31-0.77,0.48c-1.19,0.65-2.43,0.44-3.24-0.54c-0.89-1.08-0.87-2.1-0.01-3.26c0.29-0.39,0.59-0.89,0.62-1.36
		c0.09-1.93,0.06-1.94,1.23-3.5c-3.58,1.64-4.15-1.45-5.68-3.49c-0.27,1.05-0.49,1.8-0.66,2.55c-0.35,1.5-1.24,2.48-2.72,2.89
		c-5.48,1.54-10.06,4.78-14.75,7.81c-0.53,0.34-0.97,0.83-1.41,1.29c-0.17,0.18-0.32,0.48-0.3,0.71c0.09,1.36-0.22,1.98-1.66,2.36
		c-1.42,0.38-2.9,0.53-4.35,0.79c-1.33,0.24-2.69,0.41-3.98,0.78c-0.5,0.14-1,0.68-1.25,1.17c-0.99,1.89-2.37,2.54-4.53,2.19
		c-1.36-0.22-2.85,0.05-4.24,0.31c-1.27,0.24-2.42,0.5-3.72-0.07c-0.73-0.32-1.72-0.1-2.59-0.04c-1.31,0.08-2.46-0.15-3.3-1.28
		c-0.18-0.25-0.46-0.52-0.74-0.59c-2.51-0.59-5.01-1.34-7.56-1.64c-1.8-0.22-2.72-1.07-1.8-2.68c0.86-1.51,2.09-2.8,3.15-4.2
		c0.2-0.27,0.56-0.62,0.5-0.84c-0.52-1.9,0.5-3.23,1.6-4.52c0.95-1.13,1.95-2.21,2.96-3.29c0.43-0.47,0.89-0.92,1.39-1.31
		c2.01-1.57,4.11-3.03,6.05-4.69c1.19-1.02,1.68-2.49,1.49-4.11c-0.15-1.27-0.38-2.53-0.43-3.8c-0.04-1.14-0.51-1.85-1.48-2.39
		c-2.13-1.17-4.3-2.28-6.32-3.61c-2.76-1.82-5.81-2.84-8.98-3.52c-2.02-0.43-3.47-1.49-4.6-3.16c-0.78-1.15-1.7-2.21-2.46-3.38
		c-0.35-0.54-0.63-1.22-0.67-1.85c-0.12-1.97-0.07-1.98-1.86-2.67c-1.5-0.58-1.77-1.03-1.61-2.64c-1.68-0.09-3.02-0.64-3.94-2.23
		c-0.62-1.08-1.75-1.75-3.07-1.87c-3.66-0.35-7.32-0.63-10.96-1.06c-3.58-0.42-6.71,0.67-9.58,2.64c-1.06,0.72-1.86,1.83-2.94,2.51
		c-0.91,0.58-2.01,1.02-3.07,1.14c-2.42,0.28-4.86,0.41-7.29,0.46c-2.87,0.05-5.63,0.6-8.3,1.62c-0.75,0.28-1.51,0.53-2.26,0.81
		c-2.36,0.87-4.74,1.02-7.18,0.37c-1.57-0.42-3.14-0.55-4.7,0.11c-1.14,0.48-1.94,1.2-2.15,2.5c-0.1,0.62-0.28,1.27-0.6,1.8
		c-0.54,0.94-1.47,1.26-2.48,0.96c-0.98-0.29-1.16-1.14-1.03-2.04c0.24-1.67-0.42-3.05-1.4-4.32c-0.73-0.95-1.56-1.83-2.24-2.81
		c-1.12-1.63-1.02-2.93,0.24-4.48c0.94-1.16,0.79-1.84-0.71-1.95c-1.97-0.14-3.98,0.02-5.96,0.2c-6.09,0.55-11.81,2.73-17.73,4.05
		c-1.79,0.4-3.57,0.88-5.39,1.12c-1.84,0.25-2.5-0.38-2.77-2.21c-0.22-1.46-1.12-2-2.48-1.38c-1.38,0.63-2.72,1.34-4.06,2.06
		c-2.27,1.2-4.56,1.03-6.83,0.09c-1.04-0.43-1.79-0.38-2.54,0.5c-2.37,2.8-5.6,2.93-8.89,2.76c-0.96-0.05-1.4-0.8-1.74-1.62
		c-2.02-4.92-2.8-10.05-2.57-15.34c0.04-0.86,0.12-1.81,0.51-2.55c1.2-2.33,1.64-4.82,1.98-7.36c0.5-3.74,2.11-6.84,5.31-9
		c0.13-0.09,0.24-0.22,0.38-0.29c1.82-0.99,2.47-2.42,1.74-4.43c-0.16-0.43-0.21-0.97-0.11-1.41c0.91-3.8,1.86-7.59,2.8-11.38
		c0.1,0.02,0.19,0.03,0.29,0.05c-0.07-0.09-0.12-0.24-0.21-0.27c-2.16-0.77-2.59-2.5-2.53-4.51c0.01-0.36-0.03-0.72,0-1.08
		c0.22-2.34-0.23-4.41-1.72-6.38c-1.54-2.05-1.91-4.64-2.07-7.15c-0.06-0.92,0.47-1.88,0.79-2.8c0.15-0.44,0.63-0.87,0.6-1.27
		c-0.2-2.12-0.04-4.31-1.15-6.28c-0.46-0.82-1.06-1.21-2.04-1.09c-1.94,0.22-3.9,0.33-5.84,0.55c-1.31,0.15-2.55-0.07-3.49-0.92
		c-1.95-1.75-4.28-2.58-6.74-3.18c-0.62-0.15-1.22-0.38-1.84-0.52c-1.96-0.44-3.13-1.61-3.88-3.51c-0.53-1.36-1.46-2.64-2.46-3.73
		c-1.86-2.01-3.23-4.17-3.18-7c0.01-0.94-0.53-1.38-1.26-1.85c-0.94-0.61-1.76-1.42-2.63-2.15c0-0.32,0-0.64,0-0.96
		c0.44-0.49,0.89-0.99,1.33-1.47c-1.25-2.04-0.67-3.75,1.56-4.68c2.41-1,2.99-1.94,2.83-4.56c-0.07-1.08-0.18-2.15-0.17-3.23
		c0.01-1.76,0.39-3.32,1.44-4.92c0.85-1.31,1.09-3.23,1.05-4.86c-0.06-2.06,0.83-3.06,2.83-2.44c2.84,0.88,4.24,0.34,5.92-2.1
		c0.95-1.38,1.77-2.71,1.56-4.51c-0.08-0.68,0.28-1.4,0.48-2.26c3.19-0.67,6.15-2.11,8.57-5.11c-2.97,0.18-5.72,0.35-8.86,0.54
		c1.04-2.4,1.8-4.72,3-6.77c1.33-2.28,1.52-4.57,1-7.03c-0.13-0.62-0.27-1.25-0.48-1.85c-0.92-2.63-0.52-5.09,0.78-7.5
		c1.1-2.04,2.18-4.09,3.14-6.19c2.4-5.26,3.85-10.74,3.36-16.59c-0.13-1.52,0.31-2.82,1.47-3.87c1.2-1.08,1.5-2.47,0.94-3.94
		c-1.21-3.21-2.54-6.38-3.76-9.58c-1.06-2.8-2.06-5.62-3.05-8.45c-0.82-2.34-0.03-4.64,2-6.08c0.91-0.65,1.85-1.26,2.73-1.95
		c1.49-1.17,2.6-2.68,2.92-4.54c0.39-2.24,1.58-3.95,3-5.62c1.06-1.24,1.98-2.6,2.91-3.94c0.5-0.72,0.51-1.49,0.22-2.38
		c-2.27-6.95-0.31-12.87,4.5-18.07c1.19-1.29,2.35-2.61,3.55-3.95c0.06,0.02,0.2,0.02,0.27,0.09c2.65,2.93,5.6,5.66,7.87,8.87
		c2.27,3.2,3.93,6.84,5.73,10.35c1.16,2.26,2.07,4.65,3.12,6.98c0.1,0.23,0.33,0.46,0.55,0.56c1.79,0.77,2.54,2.25,3.06,4.03
		c1.36,4.67,2.86,9.29,4.22,13.96c0.41,1.39,0.71,2.86,0.75,4.3c0.06,2.78,0.86,5.29,2.43,7.53c3.3,4.71,6.68,9.37,10.06,14.02
		c0.61,0.83,1.32,1.62,2.11,2.29c0.91,0.77,1.15,1.68,1.01,2.78c-0.05,0.4-0.12,0.79-0.19,1.18c-0.71,3.88-1.44,7.76-2.13,11.65
		c-0.51,2.86,0.11,5.45,1.83,7.82c0.65,0.89,1.18,1.9,1.59,2.93c0.59,1.5,0.34,2.95-0.5,4.36c-0.25,0.42-0.32,1.14-0.14,1.59
		c0.9,2.22,1.87,4.41,2.88,6.58c0.92,1.97,1.28,4.01,1.07,6.17c-0.13,1.4,0.19,1.64,1.59,1.72c1.95,0.11,3.9,0.25,5.83,0.51
		c0.76,0.1,1.47,0.57,2.2,0.86c0.54,0.21,1.07,0.51,1.63,0.57c2.89,0.28,4.08,1.41,4.19,4.34c0.04,1.27,0.59,2.06,1.57,2.74
		c3.45,2.43,7.23,3.88,11.49,3.92c2.28,0.02,4.13,0.78,5.7,2.52c3.44,3.84,6.95,7.61,10.55,11.31c1.7,1.75,1.99,3.56,1.23,5.79
		c-1.13,3.32,0.68,6.23,4.14,6.84c0.66,0.12,1.35,0.09,2.03,0.15c3.22,0.26,6.45,0.47,9.66,0.82c3.01,0.32,4.98,2.07,5.9,4.91
		c0.87,2.68,1.6,5.41,2.36,8.04c2.13-0.56,4.37-1.28,6.66-1.72c4.78-0.91,9.58-0.49,14.31,0.34c7.78,1.36,15.64,1.74,23.48,2.3
		c7.81,0.56,15.62,1.2,23.43,1.81c1,0.08,2,0.3,2.98,0.22c1.11-0.1,1.65,0.66,2.24,1.26c0.26,0.26,0.3,1.06,0.09,1.38
		c-1.02,1.58-1.13,3.25-0.59,4.95c0.71,2.23,0.31,4.22-0.93,6.15c-1.41,2.19-1.24,6.31,0.31,8.39c0.98,1.31,2.04,2.56,3.05,3.85
		c0.62,0.79,1.37,1.14,2.4,1.02c0.62-0.07,1.28-0.01,1.9,0.1c1.17,0.22,2.05,0.83,2.44,2.03c0.36,1.11,1.09,1.79,2.27,2.06
		c3.65,0.87,5.43,3.46,6.23,6.92c0.07,0.31,0.12,0.63,0.13,0.95c0.01,1.85,1.15,2.66,2.8,3.12c0.19-0.46,0.25-1.02,0.56-1.3
		c0.47-0.42,1.11-0.89,1.67-0.88c0.48,0.01,1.17,0.57,1.38,1.05c0.8,1.89,2.02,3.43,3.55,4.74c0.94,0.81,0.94,1.68,0.25,2.62
		c0.35,0.49,0.67,0.93,0.99,1.38c0,1.12,0,2.24,0,3.36c-0.24,2.03-0.52,4.06-0.68,6.1c-0.03,0.35,0.44,0.73,0.68,1.1
		C729.84,425.19,729.84,425.59,729.84,425.99z M476.32,322.38c-0.19,1.29-0.26,2.48-0.57,3.59c-0.29,1.05-0.85,2.02-1.28,3.03
		c-0.34,0.82-0.82,1.63-0.93,2.49c-0.13,0.97,0.03,1.99,0.17,2.98c0.55,3.92-0.6,6.05-4.12,7.71c-0.32,0.15-0.69,0.9-0.57,1.14
		c0.73,1.41,0.56,2.59-0.79,3.61c0.7,0.33,1.24,0.59,1.78,0.85c1.24,0.6,1.94,1.57,1.81,2.96c-0.21,2.37,0.79,4.18,2.45,5.73
		c1.58,1.47,2.74,3.19,3.42,5.27c0.2,0.61,0.9,1.25,1.52,1.49c1.59,0.61,3.26,1.05,4.92,1.48c1.7,0.44,3.23,1.16,4.49,2.39
		c0.77,0.75,1.67,1.01,2.74,0.89c2.02-0.22,4.06-0.35,6.08-0.55c1.54-0.15,2.7,0.5,3.38,1.8c0.57,1.1,0.95,2.32,1.27,3.53
		c0.25,0.96,0.28,1.97,0.4,2.97c0.15,1.26,0.04,2.42-0.76,3.52c-0.41,0.56-0.65,1.44-0.56,2.12c0.28,2.18,0.69,4.4,2.08,6.18
		c1.43,1.83,1.87,3.82,1.68,6.06c-0.08,0.91,0.04,1.83,0.05,2.75c0.01,0.84,0.37,1.36,1.15,1.77c1.6,0.84,1.91,1.77,1.32,3.48
		c-0.97,2.77-2.27,5.45-2.17,8.5c0.01,0.31,0.01,0.71-0.16,0.93c-1.04,1.38-0.25,2.75-0.09,4.13c0.06,0.5,0.05,1.19-0.24,1.51
		c-0.92,1.04-1.89,2.08-3,2.9c-2.65,1.93-4.08,4.46-4.58,7.69c-0.36,2.3-1.11,4.55-1.67,6.82c-0.31,1.3-0.79,2.61-0.82,3.93
		c-0.11,4.47,0.73,8.81,2.37,12.97c0.1,0.27,0.46,0.59,0.72,0.62c2.52,0.2,4.84-0.2,6.65-2.23c1.25-1.41,2.73-1.75,4.53-0.91
		c1.91,0.88,3.84,0.84,5.74-0.29c1.23-0.74,2.54-1.35,3.86-1.91c2.13-0.91,4.27-0.01,5.02,2.06c0.16,0.44,0.21,0.93,0.33,1.5
		c0.51-0.04,1.04-0.01,1.53-0.13c2.18-0.5,4.36-0.98,6.52-1.57c6.32-1.72,12.6-3.64,19.23-3.75c1.33-0.02,2.74,0.07,3.97,0.51
		c2.21,0.8,2.72,2.96,1.14,4.71c-0.97,1.07-0.73,1.85-0.02,2.83c1.27,1.73,2.5,3.5,3.67,5.15c1.24-2.46,5.26-3.72,7.72-3.11
		c2.61,0.65,5.25,0.88,7.77-0.21c5.19-2.26,10.68-2.52,16.21-2.61c1.02-0.02,2.18,0.23,2.71-1.09c0.06-0.14,0.34-0.19,0.51-0.28
		c0.97-0.55,2-1.01,2.88-1.67c3.45-2.59,7.18-4.02,11.59-3.47c3.72,0.46,7.47,0.69,11.2,1.06c2,0.2,3.56,1.28,4.66,2.86
		c0.55,0.79,1.09,1.12,1.99,1.21c2.28,0.23,2.66,0.71,2.5,2.93c2.74,0.94,2.82,1.07,3.03,3.92c0.04,0.48,0.31,0.99,0.58,1.4
		c0.59,0.9,1.32,1.71,1.89,2.61c1.08,1.72,2.51,2.68,4.55,3.15c2.39,0.55,4.77,1.39,6.96,2.48c3.06,1.52,5.95,3.4,8.91,5.12
		c1.16,0.67,1.8,1.63,1.86,3.01c0.05,1.31,0.16,2.62,0.33,3.92c0.3,2.31-0.19,4.53-1.77,6.11c-1.82,1.81-4.15,3.1-6.09,4.81
		c-1.81,1.6-3.42,3.44-5.08,5.2c-0.26,0.27-0.35,0.7-0.56,1.13c0.93,0.43,1.4,1.11,0.94,2.02c-0.23,0.46-0.8,0.74-1.2,1.13
		c-1.13,1.12-2.25,2.25-3.45,3.44c2.53,0.58,4.78,1,6.95,1.64c0.99,0.29,1.82,1.06,2.77,1.53c0.64,0.31,1.34,0.56,2.04,0.65
		c0.86,0.11,1.81-0.2,2.59,0.06c1.15,0.4,2.14,0.21,3.26-0.01c1.34-0.27,2.76-0.42,4.11-0.29c1.94,0.19,2.13,0.14,3.2-1.49
		c0.49-0.74,1.26-1.5,2.07-1.78c1.34-0.47,2.8-0.58,4.22-0.8c1.84-0.29,3.36-1.09,4.57-2.54c0.59-0.7,1.2-1.43,1.95-1.93
		c4.37-2.94,8.64-6.12,13.71-7.75c2.23-0.72,3.62-1.71,3.85-4.15c0.09-0.96,0.74-1.79,1.83-1.98c1.14-0.2,2.06,0.37,2.48,1.3
		c0.85,1.88,1.94,2.66,4.14,2.16c1.6-0.37,2.46,1.14,1.73,2.67c-0.22,0.46-0.63,0.82-0.87,1.28c-0.23,0.44-0.54,0.96-0.49,1.41
		c0.13,1.08-0.08,1.96-0.91,2.7c-0.17,0.15-0.22,0.45-0.41,0.88c1.14-0.42,2.12-1.12,2.98-0.99c0.9,0.13,1.68,1.05,2.52,1.63
		c1.3-1.78,1.3-1.78,3.64-1.09c0.08,0.02,0.17,0.09,0.22,0.07c1.58-0.75,3.51,0.53,5-0.88c0.23-0.22,0.78-0.22,1.14-0.14
		c0.69,0.16,1.34,0.55,2.03,0.65c0.52,0.08,1.48-0.04,1.55-0.27c0.33-1.12,0.81-2.41,0.51-3.44c-0.48-1.64-1.53-3.12-2.34-4.67
		c-0.33-0.63-0.81-1.22-1.01-1.89c-0.98-3.37-1.36-6.81-0.89-10.31c0.25-1.82,0.72-3.61,0.93-5.43c0.17-1.46,0.55-2.71,1.82-3.6
		c0.26-0.18,0.46-0.7,0.42-1.03c-0.2-1.81-0.64-3.61-0.71-5.42c-0.13-3.28-0.13-6.53-2.06-9.45c-0.51-0.77-0.47-1.99-0.44-3
		c0.03-0.98,0.38-1.95,0.57-2.93c0.05-0.26,0.14-0.62,0.01-0.78c-1.05-1.31-1.91-2.73-3.34-3.77c-2.34-1.71-1.91-4.54,0.76-5.67
		c0.98-0.41,2.09-0.51,3.1-0.87c1.56-0.56,3.17-1.07,4.61-1.87c2.09-1.17,2.54-3.42,2.82-5.53c0.27-2.02,0.07-4.11,0.13-6.17
		c0.04-1.28,0.26-2.55,0.24-3.82c-0.02-1.3-1.53-2.3-0.83-3.79c0.02-0.04-0.15-0.16-0.23-0.25c-1.03-1.21-2.06-2.43-2.94-3.46
		c-0.66,0.33-1.28,0.9-1.92,0.92c-2.14,0.09-4.66-2.05-5.1-4.14c-0.12-0.59-0.17-1.19-0.29-1.77c-0.62-2.88-2.12-4.96-5.16-5.58
		c-1.18-0.24-2.09-0.83-2.54-1.96c-0.6-1.51-1.63-2.18-3.27-1.91c-1.38,0.22-2.51-0.31-3.4-1.36c-0.85-1.01-1.62-2.1-2.56-3.01
		c-3.34-3.25-3.23-9.36-1.04-12.79c0.49-0.77,0.82-1.98,0.58-2.79c-0.73-2.42-0.67-4.71,0.32-7.1c-0.55-0.07-1.02-0.15-1.49-0.19
		c-8.56-0.67-17.12-1.44-25.7-1.98c-8.38-0.53-16.73-1.2-25.03-2.53c-5-0.8-10.05-0.91-14.97,0.62c-1.92,0.6-3.75,1.47-5.68,2.24
		c-0.09-0.63-0.23-1.26-0.26-1.89c-0.15-3.2-1.3-6.16-2.34-9.13c-0.61-1.75-1.88-2.89-3.78-3.07c-3.57-0.34-7.15-0.71-10.73-0.89
		c-3.58-0.17-6.58-2.15-7.18-5.38c-0.27-1.46,0.06-3.07,0.31-4.58c0.17-1.07,0.36-1.99-0.49-2.89c-3.67-3.9-7.34-7.8-10.93-11.78
		c-1.25-1.39-2.72-1.94-4.52-2.04c-1.63-0.08-3.35-0.06-4.85-0.6c-2.46-0.88-4.81-2.1-7.13-3.34c-1.81-0.97-3-2.37-2.87-4.7
		c0.09-1.67-0.58-2.08-2.28-2.26c-0.8-0.09-1.58-0.42-2.35-0.69c-0.74-0.26-1.43-0.76-2.18-0.87c-1.65-0.25-3.33-0.39-4.99-0.43
		c-2.82-0.07-3.78-1.19-3.7-4.04c0.04-1.47-0.18-3.04-0.68-4.42c-0.91-2.51-2.12-4.91-3.19-7.36c-0.51-1.17-0.63-2.26,0.35-3.33
		c0.67-0.72,0.79-1.67,0.36-2.58c-0.37-0.79-0.73-1.61-1.25-2.3c-2.22-2.93-2.83-6.21-2.21-9.76c0.68-3.89,1.42-7.77,2.17-11.65
		c0.17-0.9,0.06-1.58-0.68-2.24c-0.83-0.74-1.58-1.6-2.23-2.5c-3.25-4.46-6.49-8.92-9.65-13.43c-2.07-2.96-3.17-6.24-2.91-9.92
		c0.04-0.61-0.16-1.26-0.35-1.87c-1.57-5.11-3.13-10.22-4.76-15.31c-0.24-0.75-0.7-1.74-1.32-1.99c-1.47-0.61-2.24-1.52-2.37-3.08
		c-0.03-0.38-0.29-0.74-0.46-1.1c-1.93-3.9-3.8-7.83-5.8-11.7c-2.06-3.99-5.47-6.89-8.1-9.9c-0.88,0.71-1.74,1.26-2.43,1.98
		c-4.42,4.63-6.3,9.95-4.16,16.24c0.47,1.39,0.4,2.63-0.34,3.77c-1.06,1.63-2.23,3.21-3.46,4.72c-1.08,1.32-1.96,2.66-2.29,4.39
		c-0.42,2.23-1.66,4.06-3.4,5.5c-0.92,0.76-1.92,1.43-2.9,2.12c-1.48,1.04-1.99,2.37-1.41,4c1,2.82,2,5.64,3.06,8.44
		c1.23,3.25,2.61,6.44,3.76,9.71c0.76,2.17,0.15,4.12-1.43,5.86c-0.52,0.58-0.83,1.51-0.9,2.31c-0.18,2.31,0.01,4.66-0.3,6.94
		c-0.73,5.28-2.69,10.2-5.35,14.76c-1.87,3.21-2.6,6.22-1.36,9.87c1.02,3.03,0.66,6.15-1.28,8.94c-0.66,0.95-1.07,2.08-1.69,3.34
		c3.49-0.22,6.65-0.42,9.97-0.63c-2.53,4.59-5.96,7.73-11.01,9.05c0.66,2.49-0.48,4.36-1.77,6.27c-2.13,3.17-4.18,4.07-7.82,3.15
		C476.45,322.37,476.33,322.38,476.32,322.38z"/>
					<path class="floresta" d="M476.32,322.38c0.01,0,0.13-0.01,0.24,0.02c3.64,0.92,5.69,0.03,7.82-3.15c1.28-1.91,2.43-3.77,1.77-6.27
		c5.05-1.32,8.48-4.46,11.01-9.05c-3.32,0.21-6.48,0.41-9.97,0.63c0.63-1.26,1.03-2.39,1.69-3.34c1.94-2.79,2.31-5.91,1.28-8.94
		c-1.23-3.65-0.51-6.66,1.36-9.87c2.66-4.56,4.61-9.48,5.35-14.76c0.32-2.28,0.12-4.63,0.3-6.94c0.06-0.8,0.37-1.73,0.9-2.31
		c1.57-1.73,2.19-3.69,1.43-5.86c-1.15-3.27-2.53-6.47-3.76-9.71c-1.06-2.8-2.06-5.62-3.06-8.44c-0.58-1.64-0.08-2.96,1.41-4
		c0.98-0.69,1.98-1.36,2.9-2.12c1.74-1.45,2.98-3.27,3.4-5.5c0.33-1.74,1.22-3.07,2.29-4.39c1.23-1.51,2.4-3.09,3.46-4.72
		c0.74-1.14,0.82-2.39,0.34-3.77c-2.14-6.29-0.26-11.61,4.16-16.24c0.69-0.72,1.55-1.27,2.43-1.98c2.63,3.01,6.04,5.91,8.1,9.9
		c1.99,3.87,3.87,7.8,5.8,11.7c0.18,0.36,0.43,0.72,0.46,1.1c0.13,1.56,0.9,2.47,2.37,3.08c0.62,0.26,1.08,1.24,1.32,1.99
		c1.63,5.09,3.2,10.2,4.76,15.31c0.18,0.6,0.39,1.25,0.35,1.87c-0.26,3.68,0.84,6.97,2.91,9.92c3.17,4.51,6.41,8.97,9.65,13.43
		c0.66,0.9,1.4,1.76,2.23,2.5c0.74,0.66,0.85,1.34,0.68,2.24c-0.75,3.88-1.49,7.76-2.17,11.65c-0.62,3.54-0.01,6.83,2.21,9.76
		c0.52,0.69,0.88,1.51,1.25,2.3c0.43,0.92,0.3,1.86-0.36,2.58c-0.98,1.06-0.86,2.16-0.35,3.33c1.07,2.45,2.28,4.85,3.19,7.36
		c0.5,1.38,0.72,2.95,0.68,4.42c-0.08,2.85,0.88,3.97,3.7,4.04c1.67,0.04,3.34,0.19,4.99,0.43c0.75,0.11,1.44,0.61,2.18,0.87
		c0.77,0.28,1.55,0.61,2.35,0.69c1.7,0.18,2.37,0.59,2.28,2.26c-0.13,2.33,1.05,3.74,2.87,4.7c2.31,1.23,4.67,2.45,7.13,3.34
		c1.5,0.54,3.22,0.51,4.85,0.6c1.8,0.09,3.27,0.65,4.52,2.04c3.59,3.97,7.26,7.88,10.93,11.78c0.85,0.9,0.66,1.83,0.49,2.89
		c-0.24,1.51-0.58,3.12-0.31,4.58c0.59,3.23,3.6,5.21,7.18,5.38c3.58,0.17,7.16,0.55,10.73,0.89c1.91,0.18,3.17,1.32,3.78,3.07
		c1.04,2.97,2.19,5.92,2.34,9.13c0.03,0.63,0.17,1.26,0.26,1.89c1.93-0.77,3.76-1.64,5.68-2.24c4.92-1.54,9.97-1.42,14.97-0.62
		c8.3,1.33,16.65,2,25.03,2.53c8.57,0.54,17.13,1.31,25.7,1.98c0.47,0.04,0.93,0.12,1.49,0.19c-0.99,2.38-1.05,4.68-0.32,7.1
		c0.24,0.81-0.09,2.03-0.58,2.79c-2.18,3.43-2.3,9.54,1.04,12.79c0.94,0.92,1.71,2.01,2.56,3.01c0.89,1.05,2.02,1.59,3.4,1.36
		c1.64-0.26,2.67,0.4,3.27,1.91c0.45,1.13,1.36,1.72,2.54,1.96c3.05,0.62,4.55,2.7,5.16,5.58c0.13,0.58,0.17,1.19,0.29,1.77
		c0.44,2.1,2.96,4.23,5.1,4.14c0.64-0.03,1.25-0.59,1.92-0.92c0.88,1.03,1.91,2.25,2.94,3.46c0.08,0.09,0.25,0.21,0.23,0.25
		c-0.7,1.49,0.82,2.49,0.83,3.79c0.02,1.27-0.21,2.55-0.24,3.82c-0.06,2.06,0.14,4.14-0.13,6.17c-0.28,2.1-0.73,4.36-2.82,5.53
		c-1.44,0.8-3.05,1.31-4.61,1.87c-1.01,0.36-2.12,0.45-3.1,0.87c-2.68,1.13-3.11,3.96-0.76,5.67c1.43,1.04,2.29,2.46,3.34,3.77
		c0.13,0.16,0.04,0.53-0.01,0.78c-0.19,0.98-0.54,1.95-0.57,2.93c-0.03,1.01-0.07,2.23,0.44,3c1.93,2.92,1.93,6.17,2.06,9.45
		c0.07,1.81,0.51,3.61,0.71,5.42c0.04,0.33-0.16,0.85-0.42,1.03c-1.27,0.89-1.65,2.14-1.82,3.6c-0.21,1.82-0.69,3.61-0.93,5.43
		c-0.47,3.49-0.1,6.94,0.89,10.31c0.2,0.67,0.68,1.26,1.01,1.89c0.82,1.55,1.86,3.03,2.34,4.67c0.3,1.02-0.18,2.32-0.51,3.44
		c-0.07,0.23-1.03,0.34-1.55,0.27c-0.69-0.1-1.34-0.49-2.03-0.65c-0.36-0.08-0.91-0.08-1.14,0.14c-1.49,1.41-3.42,0.13-5,0.88
		c-0.05,0.02-0.15-0.05-0.22-0.07c-2.35-0.69-2.35-0.69-3.64,1.09c-0.84-0.58-1.62-1.5-2.52-1.63c-0.86-0.12-1.84,0.58-2.98,0.99
		c0.19-0.43,0.24-0.73,0.41-0.88c0.83-0.74,1.03-1.62,0.91-2.7c-0.05-0.45,0.25-0.97,0.49-1.41c0.24-0.45,0.65-0.82,0.87-1.28
		c0.73-1.53-0.12-3.04-1.73-2.67c-2.2,0.5-3.29-0.28-4.14-2.16c-0.42-0.93-1.34-1.5-2.48-1.3c-1.09,0.19-1.74,1.02-1.83,1.98
		c-0.23,2.44-1.62,3.43-3.85,4.15c-5.07,1.63-9.34,4.81-13.71,7.75c-0.75,0.5-1.37,1.23-1.95,1.93c-1.21,1.44-2.73,2.25-4.57,2.54
		c-1.42,0.22-2.88,0.33-4.22,0.8c-0.81,0.28-1.58,1.04-2.07,1.78c-1.07,1.63-1.26,1.68-3.2,1.49c-1.35-0.13-2.77,0.02-4.11,0.29
		c-1.12,0.23-2.11,0.41-3.26,0.01c-0.78-0.27-1.73,0.04-2.59-0.06c-0.7-0.09-1.4-0.34-2.04-0.65c-0.95-0.46-1.78-1.24-2.77-1.53
		c-2.18-0.64-4.42-1.06-6.95-1.64c1.19-1.19,2.32-2.32,3.45-3.44c0.39-0.39,0.96-0.67,1.2-1.13c0.46-0.91-0.01-1.59-0.94-2.02
		c0.2-0.43,0.3-0.85,0.56-1.13c1.66-1.76,3.27-3.6,5.08-5.2c1.94-1.71,4.27-3,6.09-4.81c1.59-1.58,2.08-3.8,1.77-6.11
		c-0.17-1.3-0.28-2.61-0.33-3.92c-0.06-1.38-0.71-2.33-1.86-3.01c-2.96-1.72-5.85-3.6-8.91-5.12c-2.19-1.09-4.57-1.93-6.96-2.48
		c-2.05-0.47-3.48-1.43-4.55-3.15c-0.57-0.91-1.3-1.72-1.89-2.61c-0.28-0.42-0.55-0.92-0.58-1.4c-0.21-2.85-0.29-2.98-3.03-3.92
		c0.16-2.22-0.22-2.7-2.5-2.93c-0.9-0.09-1.43-0.42-1.99-1.21c-1.1-1.58-2.66-2.66-4.66-2.86c-3.73-0.37-7.48-0.6-11.2-1.06
		c-4.41-0.55-8.14,0.88-11.59,3.47c-0.88,0.66-1.92,1.12-2.88,1.67c-0.17,0.1-0.45,0.14-0.51,0.28c-0.53,1.32-1.69,1.08-2.71,1.09
		c-5.53,0.09-11.02,0.34-16.21,2.61c-2.52,1.1-5.15,0.86-7.77,0.21c-2.46-0.61-6.48,0.65-7.72,3.11c-1.17-1.65-2.39-3.41-3.67-5.15
		c-0.72-0.97-0.95-1.76,0.02-2.83c1.58-1.75,1.08-3.91-1.14-4.71c-1.23-0.44-2.65-0.54-3.97-0.51c-6.63,0.11-12.91,2.03-19.23,3.75
		c-2.16,0.59-4.34,1.07-6.52,1.57c-0.49,0.11-1.01,0.09-1.53,0.13c-0.12-0.57-0.17-1.05-0.33-1.5c-0.75-2.07-2.89-2.97-5.02-2.06
		c-1.32,0.56-2.63,1.18-3.86,1.91c-1.89,1.13-3.82,1.17-5.74,0.29c-1.8-0.83-3.28-0.5-4.53,0.91c-1.8,2.03-4.12,2.44-6.65,2.23
		c-0.26-0.02-0.62-0.35-0.72-0.62c-1.64-4.17-2.48-8.5-2.37-12.97c0.03-1.32,0.5-2.63,0.82-3.93c0.55-2.28,1.3-4.52,1.67-6.82
		c0.51-3.23,1.93-5.76,4.58-7.69c1.12-0.81,2.09-1.85,3-2.9c0.29-0.33,0.29-1.01,0.24-1.51c-0.16-1.38-0.95-2.76,0.09-4.13
		c0.17-0.22,0.17-0.62,0.16-0.93c-0.11-3.05,1.2-5.73,2.17-8.5c0.6-1.71,0.28-2.64-1.32-3.48c-0.78-0.41-1.14-0.92-1.15-1.77
		c-0.01-0.92-0.12-1.84-0.05-2.75c0.19-2.24-0.26-4.23-1.68-6.06c-1.39-1.78-1.79-4-2.08-6.18c-0.09-0.68,0.16-1.56,0.56-2.12
		c0.81-1.11,0.91-2.26,0.76-3.52c-0.12-0.99-0.15-2.01-0.4-2.97c-0.32-1.21-0.7-2.43-1.27-3.53c-0.67-1.3-1.83-1.95-3.38-1.8
		c-2.03,0.2-4.06,0.33-6.08,0.55c-1.07,0.12-1.97-0.14-2.74-0.89c-1.26-1.23-2.79-1.95-4.49-2.39c-1.66-0.43-3.33-0.87-4.92-1.48
		c-0.62-0.24-1.32-0.88-1.52-1.49c-0.69-2.08-1.85-3.79-3.42-5.27c-1.65-1.55-2.66-3.36-2.45-5.73c0.13-1.39-0.58-2.36-1.81-2.96
		c-0.54-0.26-1.08-0.51-1.78-0.85c1.35-1.02,1.52-2.2,0.79-3.61c-0.12-0.23,0.25-0.99,0.57-1.14c3.52-1.66,4.66-3.79,4.12-7.71
		c-0.14-0.98-0.29-2.01-0.17-2.98c0.11-0.86,0.59-1.67,0.93-2.49c0.42-1.01,0.98-1.99,1.28-3.03
		C476.06,324.85,476.13,323.67,476.32,322.38z"/>
				</g>
				<g>
					<path d="M817.05,534.82c-4.07-1.82-4.54-2.59-4.34-7.14c0.03-0.6,0.09-1.2,0.19-1.78c0.15-0.86,0.3-1.61-0.43-2.4
		c-0.4-0.43-0.27-1.33-0.39-2.02c-0.11-0.67-0.08-1.42-0.39-1.98c-0.71-1.24-1.58-2.4-2.44-3.55c-0.24-0.33-0.68-0.77-1-0.74
		c-2.52,0.19-3.61-1.65-4.44-3.41c-0.77-1.65-1.98-2.65-3.4-3.55c-0.67-0.43-1.38-0.81-2.1-1.15c-1.25-0.58-2.06-1.51-2.32-2.86
		c-0.25-1.3-1.09-1.91-2.32-2.06c-1.67-0.2-3.11-0.85-4.47-1.86c-1.18-0.87-2.54-1.5-3.8-2.27c-0.58-0.35-1.17-0.72-1.64-1.2
		c-1.02-1.03-0.88-2.35,0.4-2.97c1.27-0.61,1.92-1.64,2.7-2.73c1.24-1.74,2.8-3.24,4.26-4.81c0.32-0.35,0.77-0.57,1.13-0.88
		c1.32-1.14,1.32-1.16,0.26-2.55c-1-1.32-0.6-2.68,0.97-3.21c0.48-0.16,0.99-0.26,1.49-0.38c0-0.56,0.07-1.09-0.01-1.59
		c-0.29-1.81,0.5-3.08,1.91-4.08c1.53-1.09,3.03-2.22,4.57-3.3c1.78-1.24,3.24-2.69,3.82-4.89c0.44-1.64,1.01-3.24,1.52-4.85
		c0.03-0.1-0.03-0.23-0.04-0.34c-2.49-0.03-4.4,1.31-5.04,3.56c-0.33,1.18-1.05,1.85-2.28,1.94c-0.92,0.07-1.84,0.07-2.75,0.16
		c-1.68,0.17-3.26,0.49-4.58,1.77c-0.58,0.56-1.65,0.7-2.53,0.85c-1.41,0.25-2.84,0.4-4.27,0.5c-3.86,0.28-7.71,0.51-11.57,0.76
		c-0.28,0.02-0.56-0.01-0.84-0.05c-3.57-0.5-6.17,1.02-8.2,3.84c-1.71,2.38-5.12,2.37-6.82-0.03c-0.42-0.59-0.6-1.38-0.75-2.1
		c-0.46-2.22-0.75-4.47-1.99-6.47c-1.16-1.88-2.55-3.33-4.77-3.91c-2.03-0.53-3.62-1.73-4.58-3.6c-1.13-2.21-2.79-3.49-5.29-3.77
		c-2.05-0.23-3.14-1.58-3.57-3.52c-0.92-4.13-1.89-8.24-2.72-12.39c-0.18-0.88-0.02-1.9,0.26-2.77c0.29-0.9,0.17-1.45-0.64-1.88
		c-0.28-0.15-0.55-0.34-0.85-0.44c-1.34-0.45-1.97-1.38-2.11-2.76c-0.16-1.6-0.99-2.81-2.5-3.38c-1.29-0.48-1.63-1.46-1.57-2.63
		c0.14-2.67,0.39-5.34,0.5-8.02c0.03-0.66-0.28-1.35-0.47-2.01c-0.11-0.38-0.53-0.81-0.43-1.07c0.54-1.43-0.61-2.06-1.26-2.91
		c-0.58-0.76-1.2-1.49-1.62-2c-0.9,0.28-1.84,0.92-2.53,0.72c-1.68-0.49-3.54-1.01-4.27-2.93c-0.32-0.85-0.47-1.77-0.63-2.67
		c-0.54-3.09-2.13-5.17-5.33-5.82c-1.13-0.23-1.99-0.87-2.41-1.96c-0.58-1.48-1.6-2.12-3.18-1.88c-1.59,0.24-2.8-0.47-3.75-1.66
		c-1.23-1.53-2.47-3.04-3.63-4.62c-1-1.37-1.33-2.93-1.24-4.65c0.13-2.34,0.36-4.57,1.72-6.62c0.48-0.71,0.55-1.98,0.3-2.84
		c-0.8-2.76-0.52-5.27,1.1-7.65c0.17-0.25,0.24-0.58,0.38-0.94c91.7,7.96,183.56,14.16,275.05,21.13c0.45,0.64,0.78,1.05,1.04,1.51
		c0.17,0.29,0.32,0.65,0.31,0.98c-0.04,1.84-0.01,3.69-0.27,5.5c-0.13,0.91-0.67,1.91-1.32,2.55c-1.41,1.39-3.12,2.36-5.16,2.57
		c-0.04,0-0.08,0.01-0.12,0.02c-2.25,1.06-4.65,1.89-6.68,3.27c-1.22,0.82-2.06,2.4-2.73,3.81c-0.78,1.62-1.51,3-3.61,2.89
		c-0.23-0.01-0.67,0.38-0.69,0.61c-0.11,1.35-0.93,2.19-1.95,2.86c-0.81,0.54-1.18,1.26-1.56,2.15c-0.44,1.06-1.21,2.06-2.06,2.85
		c-1.02,0.94-2.3,1.59-3.41,2.43c-1.3,0.98-2.33,2.07-1.69,3.95c0.15,0.45-0.05,1.11-0.28,1.59c-0.31,0.64-0.33,1.12,0.25,1.58
		c0.95,0.75,0.97,1.72,0.76,2.82c-0.29,1.61-0.48,3.24-0.69,4.86c-0.11,0.79,0.1,1.32,0.98,1.64c1.3,0.46,1.89,1.7,1.73,3.21
		c-0.15,1.39-1.12,2.44-2.5,2.69c-3.14,0.58-4.35,1.77-4.71,4.93c-0.33,2.89-1.3,5.51-2.96,7.87c-0.98,1.4-1.4,2.77-0.79,4.49
		c0.33,0.91,0.27,1.97,0.3,2.96c0.03,0.8,0.32,1.3,1.05,1.71c1.76,0.99,1.88,2.24,0.45,3.63c-0.29,0.28-0.59,0.55-0.9,0.8
		c-1.31,1.06-1.66,2.4-1.13,3.97c0.46,1.37,0.48,2.63-0.01,4.06c-0.47,1.37-0.41,2.91-0.6,4.38c-0.31,2.4-1.3,4.54-2.75,6.43
		c-0.45,0.59-1.47,0.95-2.27,1.04c-1.82,0.2-3.66,0.17-5.49,0.23c-1.84,0.06-3.4,0.54-4.04,2.53c-0.1,0.32-0.35,0.67-0.63,0.84
		c-1.57,0.98-2.39,2.53-3.22,4.09c-0.72,1.37-1.51,2.7-2.25,4.06c-0.17,0.31-0.2,0.68-0.36,0.99c-0.13,0.27-0.36,0.73-0.53,0.72
		c-1.42-0.09-1.7,1.16-2.47,1.89c-1.42,1.35-3.34,1.58-5.23,1.65c-0.36,0.01-0.72-0.07-1.07-0.12c-2.32-0.35-2.32-0.35-3.44,1.76
		c-0.71,1.35-2.17,1.56-3.26,0.29c-0.8-0.93-1.38-0.7-2.08-0.07c-0.56,0.51-1.04,1.13-1.65,1.57c-0.67,0.48-1.43,0.81-2.17,1.18
		c-0.76,0.38-1.62,0.6-2.29,1.09c-0.88,0.64-1.63,1.35-2.88,1.2c-0.31-0.04-0.82,0.54-1.03,0.94c-0.47,0.89-0.87,1.67-2.13,1.55
		c-0.3-0.03-0.84,0.63-0.99,1.06c-0.55,1.64-0.94,1.95-2.78,1.8c0.28,1.81-0.42,3.3-1.69,4.49c-0.39,0.36-1.11,0.6-1.64,0.54
		c-0.77-0.1-1.54-0.43-2.23-0.81c-3.45-1.93-7.12-1.72-10.85-1.24c-2.21,0.28-4.46,0.32-6.64,0.73c-1.28,0.24-2.47,0.99-3.71,1.5
		c-0.72,0.29-1.46,0.54-2.19,0.82c-0.59,0.22-1.23,0.37-1.73,0.72c-1.15,0.77-2.36,1.18-3.73,0.93c-2.3-0.42-4.34,0.1-6.18,1.57
		c-1.22,0.97-2.52,1.83-3.78,2.74c-1.65,1.2-2.38,1.21-4.05,0.09c-0.19-0.13-0.43-0.25-0.65-0.26c-0.99-0.05-1.63-0.6-2.09-1.41
		c-0.59-1.03-1.45-1.31-2.62-1.26c-1,0.05-2.02-0.21-3.27-0.36c0.06-0.06-0.13,0.09-0.26,0.28c-1.45,2.05-2.88,4.12-4.34,6.17
		c-0.54,0.76-1.16,1.47-1.75,2.2C817.53,534.82,817.29,534.82,817.05,534.82z M974.39,387.48c-4.52-0.34-8.82-0.66-13.12-0.98
		c-9.05-0.67-18.09-1.34-27.14-2.01c-9.29-0.69-18.57-1.38-27.86-2.07c-8.01-0.59-16.02-1.17-24.03-1.76
		c-9.41-0.69-18.81-1.39-28.22-2.08c-8.05-0.59-16.1-1.18-24.15-1.78c-9.01-0.68-18.01-1.36-27.02-2.06
		c-8.69-0.67-17.37-1.34-26.06-2.02c-8.73-0.68-17.45-1.36-26.18-2.06c-8.41-0.67-16.81-1.36-25.21-2.06
		c-7.65-0.64-15.29-1.3-22.93-2c-0.87-0.08-1.33,0.2-1.67,0.95c-0.67,1.46-0.62,2.94-0.19,4.44c0.49,1.71,0.58,3.47-0.43,4.98
		c-1.01,1.51-1.23,3.15-1.43,4.86c-0.21,1.76,0.1,3.32,1.27,4.7c0.98,1.16,1.91,2.36,2.84,3.56c0.72,0.92,1.57,1.31,2.81,1.16
		c2.11-0.26,3.3,0.49,4.15,2.4c0.44,1,1.14,1.56,2.2,1.82c0.88,0.22,1.91,0.4,2.52,0.98c1.97,1.83,3.8,3.85,3.81,6.79
		c0.01,1.93,1.14,2.75,2.81,3.22c0.1-0.27,0.2-0.53,0.29-0.79c0.3-0.88,0.84-1.44,1.82-1.45c0.99-0.02,1.24,0.7,1.68,1.42
		c0.89,1.45,1.86,2.88,3.01,4.12c1.18,1.27,1.34,1.39,0.59,3.03c0.9,0.81,1.08,1.86,0.95,3.03c-0.24,2.22-0.43,4.45-0.67,6.68
		c-0.13,1.19,0.16,2.04,1.32,2.64c1.51,0.77,2.41,2.08,2.6,3.8c0.12,1.11,0.65,1.74,1.71,2.19c2.13,0.9,2.88,2.2,2.15,4
		c-0.49,1.2-0.26,2.2,0,3.33c0.84,3.66,1.62,7.33,2.41,10.99c0.25,1.14,0.72,2.08,2.01,2.2c3.12,0.3,5.23,1.88,6.52,4.73
		c0.66,1.46,1.88,2.32,3.49,2.66c1.61,0.34,2.92,1.21,4.07,2.39c2.47,2.52,3.41,5.72,4.02,9.05c0.22,1.2,0.47,2.35,1.82,2.67
		c1.25,0.3,1.82-0.74,2.48-1.53c2.3-2.74,5.01-4.51,8.82-4.03c1.49,0.19,3.04,0.19,4.54,0.06c4.17-0.34,8.34-0.79,12.51-1.22
		c0.5-0.05,1.1-0.17,1.44-0.49c2.14-2.01,4.81-2.2,7.5-2.26c0.99-0.02,1.48-0.35,1.59-1.33c0.13-1.25,0.82-2.18,1.94-2.7
		c1.3-0.6,2.62-1.2,3.99-1.59c2.04-0.59,3.19,0.33,3.2,2.44c0.02,3.47-1.76,6.31-3.38,9.18c-0.19,0.33-0.56,0.57-0.87,0.81
		c-1.78,1.36-3.51,2.79-5.38,4.02c-1.56,1.02-2.46,2.07-2.18,4.15c0.3,2.26-0.26,2.7-2.51,2.96c-0.05,0.01-0.08,0.11-0.12,0.17
		c1.57,2.45,1.36,3.55-1.01,5.41c-0.09,0.07-0.18,0.16-0.29,0.22c-2.08,1.15-3.37,2.99-4.59,4.95c-0.75,1.21-1.73,2.28-2.64,3.45
		c1.3,0.79,2.95,1.68,4.47,2.77c1.11,0.79,2.23,1.29,3.59,1.47c1.89,0.24,3.53,1.03,3.9,3.13c0.2,1.14,0.79,1.68,1.76,2.18
		c2.54,1.3,4.97,2.72,6.17,5.57c0.56,1.33,1.53,2.23,3.19,2.32c0.62,0.03,1.3,0.71,1.79,1.24c0.67,0.73,1.22,1.58,1.77,2.41
		c1.06,1.6,2.21,3.17,2.03,5.25c-0.02,0.2,0.22,0.41,0.26,0.63c0.14,0.68,0.37,1.37,0.34,2.04c-0.07,1.67-0.32,3.33-0.38,5
		c-0.05,1.49,0.66,2.17,2.24,2.39c1.69-2.45,3.37-4.92,5.08-7.36c0.56-0.8,1.17-1.47,2.38-1.13c1.25,0.35,2.57,0.45,3.84,0.74
		c0.76,0.17,1.67,0.32,2.17,0.82c0.95,0.96,1.83,1.78,3.16,2.32c0.95,0.38,1.39,0.23,2.08-0.24c1.9-1.32,3.77-2.7,5.79-3.81
		c0.98-0.54,2.41-1,3.39-0.69c2.98,0.94,4.99-1.68,7.64-1.73c0.03,0,0.07-0.04,0.1-0.07c1.82-1.51,3.94-2.19,6.27-2.32
		c1.54-0.09,3.13-0.01,4.62-0.33c2.9-0.62,5.81-0.73,8.68-0.16c1.92,0.38,3.76,1.21,5.92,1.93c0.24-0.8,0.68-1.63,0.69-2.47
		c0.04-2.48,0.51-3.02,2.95-2.9c0.18-0.48,0.32-0.98,0.54-1.44c0.51-1.08,1.74-0.8,2.51-1.31c0.78-0.52,1.29-1.94,1.99-1.99
		c1.51-0.11,2.45-0.92,3.53-1.71c0.46-0.34,1.1-0.43,1.64-0.66c0.68-0.29,1.4-0.53,2.01-0.93c0.66-0.43,1.2-1.06,1.82-1.55
		c1.78-1.41,2.89-1.41,4.71,0.01c0.13-0.25,0.26-0.49,0.37-0.74c0.58-1.4,1.61-2.03,3.13-1.83c0.75,0.1,1.5,0.27,2.26,0.27
		c1.97,0.01,3.96,0.05,4.79-2.36c0.12-0.33,0.64-0.52,0.97-0.79c0.53-0.45,1.19-0.82,1.55-1.38c1.33-2.08,2.49-4.25,3.84-6.32
		c0.6-0.92,1.68-1.55,2.17-2.5c1.25-2.39,3.18-3.33,5.76-3.34c1.67-0.01,3.36-0.06,5.02-0.26c0.57-0.07,1.3-0.51,1.57-0.99
		c0.73-1.31,1.43-2.7,1.8-4.14c0.41-1.61,0.28-3.35,0.69-4.95c0.33-1.31,0.33-2.47-0.09-3.73c-0.67-2.01,0.05-3.68,1.53-5.07
		c0.46-0.43,0.95-0.83,1.55-1.35c-2.07-0.48-2.43-1.92-2.46-3.61c-0.01-0.91,0.06-1.91-0.26-2.73c-0.85-2.16-0.25-3.99,0.96-5.75
		c1.67-2.41,2.66-5.09,2.96-7.99c0.2-1.92,0.98-3.5,2.62-4.49c1.03-0.62,2.27-0.9,3.37-1.43c0.41-0.2,0.81-0.6,0.99-1.01
		c0.08-0.17-0.39-0.82-0.68-0.87c-1.39-0.25-1.94-1.24-1.92-2.4c0.03-2.02,0.26-4.05,0.54-6.06c0.09-0.66,0.12-1.07-0.4-1.57
		c-1.11-1.05-1.18-1.95-0.48-3.33c0.17-0.33,0.34-0.81,0.22-1.11c-0.84-2.18,0.36-3.65,1.77-4.93c1.02-0.93,2.27-1.62,3.42-2.42
		c1.04-0.72,1.97-1.54,2.2-2.87c0.21-1.23,0.94-2.02,1.93-2.74c0.61-0.44,1.14-1.21,1.36-1.94c0.49-1.56,0.75-1.95,2.39-1.97
		c1.12-0.01,1.54-0.59,1.97-1.53c0.61-1.32,1.37-2.6,2.26-3.76c2.06-2.68,5.59-2.87,8.2-4.64c0.03-0.02,0.08-0.02,0.12-0.02
		c1.76-0.09,3.22-0.94,4.56-1.95c0.45-0.34,0.84-1,0.9-1.55C974.28,391.29,974.3,389.46,974.39,387.48z"/>
					<path class="matupa" d="M974.39,387.48c-0.09,1.98-0.11,3.81-0.29,5.63c-0.06,0.55-0.45,1.21-0.9,1.55c-1.34,1.01-2.8,1.86-4.56,1.95
		c-0.04,0-0.09,0-0.12,0.02c-2.61,1.77-6.14,1.96-8.2,4.64c-0.89,1.15-1.66,2.43-2.26,3.76c-0.43,0.94-0.85,1.52-1.97,1.53
		c-1.63,0.02-1.9,0.41-2.39,1.97c-0.23,0.73-0.75,1.5-1.36,1.94c-1,0.72-1.72,1.51-1.93,2.74c-0.23,1.33-1.16,2.15-2.2,2.87
		c-1.15,0.8-2.4,1.48-3.42,2.42c-1.41,1.29-2.61,2.76-1.77,4.93c0.12,0.3-0.06,0.78-0.22,1.11c-0.7,1.38-0.63,2.28,0.48,3.33
		c0.52,0.49,0.49,0.91,0.4,1.57c-0.28,2.01-0.51,4.03-0.54,6.06c-0.02,1.16,0.53,2.15,1.92,2.4c0.29,0.05,0.75,0.7,0.68,0.87
		c-0.18,0.41-0.58,0.82-0.99,1.01c-1.1,0.52-2.33,0.8-3.37,1.43c-1.64,0.99-2.42,2.57-2.62,4.49c-0.3,2.91-1.28,5.58-2.96,7.99
		c-1.22,1.76-1.81,3.59-0.96,5.75c0.32,0.82,0.24,1.81,0.26,2.73c0.03,1.69,0.39,3.13,2.46,3.61c-0.6,0.52-1.09,0.92-1.55,1.35
		c-1.48,1.39-2.2,3.06-1.53,5.07c0.42,1.26,0.43,2.42,0.09,3.73c-0.41,1.61-0.28,3.35-0.69,4.95c-0.37,1.44-1.07,2.82-1.8,4.14
		c-0.27,0.49-1,0.93-1.57,0.99c-1.66,0.2-3.34,0.25-5.02,0.26c-2.58,0.01-4.51,0.95-5.76,3.34c-0.5,0.95-1.58,1.58-2.17,2.5
		c-1.34,2.06-2.51,4.24-3.84,6.32c-0.36,0.56-1.02,0.93-1.55,1.38c-0.32,0.27-0.85,0.46-0.97,0.79c-0.83,2.4-2.82,2.37-4.79,2.36
		c-0.75,0-1.51-0.17-2.26-0.27c-1.52-0.2-2.55,0.43-3.13,1.83c-0.1,0.25-0.24,0.48-0.37,0.74c-1.82-1.42-2.94-1.42-4.71-0.01
		c-0.63,0.5-1.16,1.12-1.82,1.55c-0.61,0.4-1.33,0.64-2.01,0.93c-0.55,0.23-1.18,0.32-1.64,0.66c-1.08,0.79-2.02,1.6-3.53,1.71
		c-0.7,0.05-1.21,1.47-1.99,1.99c-0.77,0.51-2.01,0.23-2.51,1.31c-0.22,0.46-0.36,0.96-0.54,1.44c-2.44-0.12-2.91,0.42-2.95,2.9
		c-0.01,0.84-0.46,1.67-0.69,2.47c-2.15-0.72-3.99-1.54-5.92-1.93c-2.87-0.57-5.78-0.46-8.68,0.16c-1.5,0.32-3.08,0.24-4.62,0.33
		c-2.33,0.13-4.45,0.81-6.27,2.32c-0.03,0.03-0.06,0.07-0.1,0.07c-2.64,0.05-4.65,2.67-7.64,1.73c-0.98-0.31-2.41,0.15-3.39,0.69
		c-2.02,1.11-3.89,2.5-5.79,3.81c-0.69,0.47-1.13,0.62-2.08,0.24c-1.34-0.53-2.21-1.36-3.16-2.32c-0.5-0.5-1.41-0.64-2.17-0.82
		c-1.27-0.29-2.59-0.39-3.84-0.74c-1.21-0.34-1.82,0.33-2.38,1.13c-1.72,2.44-3.4,4.91-5.08,7.36c-1.59-0.22-2.29-0.9-2.24-2.39
		c0.06-1.67,0.31-3.33,0.38-5c0.03-0.68-0.2-1.37-0.34-2.04c-0.05-0.22-0.28-0.43-0.26-0.63c0.18-2.09-0.97-3.66-2.03-5.25
		c-0.55-0.83-1.1-1.68-1.77-2.41c-0.49-0.53-1.17-1.21-1.79-1.24c-1.66-0.08-2.63-0.99-3.19-2.32c-1.2-2.85-3.63-4.27-6.17-5.57
		c-0.97-0.49-1.56-1.03-1.76-2.18c-0.37-2.1-2.01-2.89-3.9-3.13c-1.36-0.18-2.48-0.67-3.59-1.47c-1.51-1.08-3.17-1.97-4.47-2.77
		c0.91-1.18,1.89-2.25,2.64-3.45c1.22-1.96,2.51-3.8,4.59-4.95c0.1-0.06,0.19-0.14,0.29-0.22c2.37-1.85,2.58-2.96,1.01-5.41
		c0.04-0.06,0.08-0.17,0.13-0.17c2.25-0.26,2.82-0.7,2.51-2.96c-0.28-2.08,0.62-3.13,2.18-4.15c1.87-1.22,3.6-2.66,5.38-4.02
		c0.32-0.24,0.69-0.48,0.87-0.81c1.62-2.88,3.4-5.71,3.38-9.18c-0.01-2.12-1.16-3.03-3.2-2.44c-1.37,0.4-2.69,0.99-3.99,1.59
		c-1.12,0.52-1.81,1.44-1.94,2.7c-0.1,0.98-0.59,1.31-1.59,1.33c-2.69,0.06-5.36,0.25-7.5,2.26c-0.34,0.32-0.94,0.44-1.44,0.49
		c-4.17,0.44-8.33,0.88-12.51,1.22c-1.5,0.12-3.05,0.12-4.54-0.06c-3.81-0.48-6.52,1.29-8.82,4.03c-0.66,0.79-1.23,1.82-2.48,1.53
		c-1.35-0.32-1.6-1.47-1.82-2.67c-0.61-3.33-1.55-6.53-4.02-9.05c-1.15-1.18-2.47-2.05-4.07-2.39c-1.6-0.34-2.83-1.2-3.49-2.66
		c-1.29-2.85-3.41-4.43-6.52-4.73c-1.29-0.12-1.77-1.07-2.01-2.2c-0.79-3.67-1.58-7.34-2.41-10.99c-0.26-1.13-0.48-2.13,0-3.33
		c0.73-1.8-0.02-3.1-2.15-4c-1.06-0.45-1.59-1.08-1.71-2.19c-0.19-1.72-1.09-3.02-2.6-3.8c-1.16-0.6-1.45-1.45-1.32-2.64
		c0.24-2.22,0.43-4.45,0.67-6.68c0.13-1.17-0.05-2.22-0.95-3.03c0.75-1.64,0.59-1.76-0.59-3.03c-1.15-1.24-2.13-2.67-3.01-4.12
		c-0.44-0.72-0.7-1.43-1.68-1.42c-0.98,0.02-1.53,0.58-1.82,1.45c-0.09,0.26-0.19,0.51-0.29,0.79c-1.67-0.47-2.81-1.29-2.81-3.22
		c-0.01-2.94-1.84-4.96-3.81-6.79c-0.62-0.57-1.65-0.76-2.52-0.98c-1.06-0.26-1.76-0.82-2.2-1.82c-0.85-1.91-2.04-2.65-4.15-2.4
		c-1.24,0.15-2.08-0.24-2.81-1.16c-0.94-1.2-1.86-2.4-2.84-3.56c-1.17-1.38-1.48-2.95-1.27-4.7c0.2-1.71,0.42-3.35,1.43-4.86
		c1.01-1.51,0.93-3.27,0.43-4.98c-0.43-1.51-0.48-2.98,0.19-4.44c0.34-0.74,0.8-1.03,1.67-0.95c7.64,0.7,15.29,1.36,22.93,2
		c8.4,0.71,16.81,1.39,25.21,2.06c8.72,0.7,17.45,1.38,26.18,2.06c8.69,0.68,17.37,1.35,26.06,2.02c9,0.69,18.01,1.38,27.02,2.06
		c8.05,0.6,16.1,1.19,24.15,1.78c9.41,0.69,18.81,1.39,28.22,2.08c8.01,0.59,16.02,1.17,24.03,1.76c9.29,0.69,18.57,1.38,27.86,2.07
		c9.05,0.67,18.09,1.34,27.14,2.01C965.57,386.82,969.87,387.14,974.39,387.48z"/>
				</g>
				<g>
					<path d="M428.78,770.35c0.46-0.63,1.01-1.22,1.34-1.91c0.25-0.54,0.37-1.23,0.29-1.81c-0.19-1.42,0.15-2.52,1.16-3.59
		c1.54-1.62,1.98-3.69,1.87-5.88c-0.05-0.96-0.3-1.93-0.18-2.86c0.12-0.99,0.35-2.1,0.93-2.86c1.14-1.51,1.74-3.12,1.75-4.98
		c0.01-1.45,0.46-2.68,1.38-3.86c1.35-1.74,1.69-3.68,0.8-5.84c-0.71-1.72-1.16-3.54-1.74-5.31c-0.21-0.64-0.64-1.27-0.64-1.9
		c-0.01-0.9,0.3-1.8,0.45-2.71c0.08-0.5,0.26-1.03,0.17-1.5c-0.19-1.01-0.34-1.88,0.56-2.73c0.79-0.75,0.61-1.48-0.42-1.86
		c-2.1-0.76-2.56-2.37-2.65-4.35c-0.08-1.81-0.07-3.52,0.85-5.2c0.43-0.79,0.28-1.88,0.56-2.79c0.2-0.64,0.55-1.35,1.05-1.74
		c1.19-0.93,1.66-2.75,0.65-3.86c-1.06-1.15-1.33-2.43-1.08-3.87c0.15-0.86-0.04-1.39-0.88-1.83c-1.43-0.75-1.7-1.48-1.28-3.08
		c0.22-0.86,0.72-1.66,0.84-2.52c0.09-0.64-0.07-1.45-0.41-1.99c-0.8-1.25-0.6-2.35,0.79-2.85c0.78-0.28,1.63-0.41,2.45-0.51
		c0.83-0.1,1.11-0.53,1-1.3c-0.08-0.59-0.31-1.2-0.23-1.77c0.1-0.71,0.29-1.51,0.71-2.06c1.23-1.6,1.54-4.66,0.35-6.26
		c-0.6-0.82-0.65-1.59-0.34-2.47c0.67-1.87,1.86-3.37,3.47-4.48c1.27-0.88,1.8-1.96,1.86-3.52c0.04-1.05,0.49-2.13,0.95-3.11
		c0.29-0.61,0.41-1.03,0.04-1.63c-0.65-1.05-1.19-2.17-1.81-3.23c-0.85-1.45-1.24-2.93-0.47-4.55c0.46-0.95,0.32-1.82-0.39-2.66
		c-0.31-0.36-0.5-0.94-0.51-1.42c-0.05-2.15,0.2-4.22,0.75-6.35c0.51-1.99,1.21-4.09,0.8-6.32c-0.17-0.89,0.17-1.98,0.57-2.85
		c0.37-0.79,0.59-1.45,0.24-2.26c-0.67-1.52-0.26-2.81,0.9-3.9c1.08-1.01,2.14-2.04,3.28-2.97c0.84-0.69,1.22-1.44,1.15-2.57
		c-0.11-1.59,0.05-3.19,0-4.78c-0.08-2.25-0.04-4.49-1.31-6.55c-0.66-1.07-0.89-2.39-1.41-3.55c-0.2-0.44-0.6-0.84-1.01-1.12
		c-0.72-0.5-0.78-1.19-0.24-1.91c1.55-2.08,3.49-3.37,6.1-3.8c0.89-0.15,1.65-1.04,2.48-1.57c1.5-0.96,2.95-2.01,4.53-2.81
		c1.67-0.85,2.8-2.17,3.49-3.77c1.1-2.57,2.96-3.76,5.68-3.77c0.92-0.01,1.85,0.06,2.76-0.03c0.29-0.03,0.75-0.44,0.76-0.69
		c0.1-1.95,0.61-2.62,2.52-2.95c0.82-0.14,1.65-0.2,2.48-0.3c-0.26-2.4,0.41-3.65,2.84-4.92c1.26-0.66,2.6-1.14,3.91-1.71
		c0.24,0,0.48,0,0.72,0c0.88,0.71,0.93,1.44,0.2,2.35c-0.77,0.96-1.03,2.08-0.93,3.36c0.15,1.83-0.51,3.42-1.85,4.74
		c-0.36,0.36-0.52,1-0.59,1.54c-0.2,1.4-0.33,2.82-0.43,4.23c-0.03,0.47-0.05,1.12,0.22,1.41c1.3,1.37,1.3,2.93,0.35,4.29
		c-1.42,2.02-2.21,4.17-2.43,6.61c-0.08,0.85-0.55,1.65-0.89,2.6c2.05,0.98,2.89,2.72,2.86,5.01c-0.01,0.39,0.36,0.92,0.71,1.15
		c1.98,1.28,2.48,3.21,2.59,5.37c0.03,0.57,0.31,1.14,0.3,1.71c-0.01,0.62-0.01,1.42-0.37,1.82c-0.62,0.7-0.55,1.16,0.06,1.7
		c0.47,0.43,0.96,0.85,1.44,1.26c0.9,0.76,0.97,1.64,0.27,2.53c-0.78,0.98-1.43,1.91-0.66,3.2c0.14,0.24,0.09,0.64,0.02,0.94
		c-0.3,1.33,0.09,2.4,1.04,3.38c0.97,1,1.01,2.33,0.9,3.61c-0.06,0.7-0.07,1.1,0.7,1.35c0.94,0.3,1.3,1.15,1.08,2.02
		c-0.51,2.01-0.09,3.95,0.16,5.94c0.15,1.25,0.03,2.52,0.03,3.83c1.71,0.32,1.63,0.39,2.23,2.36c0.27,0.88,0.98,1.72,1.7,2.34
		c2,1.7,4.09,3.3,6.18,4.88c0.85,0.64,1.81,1.17,2.75,1.68c1.11,0.61,1.78,1.46,2.24,2.67c0.63,1.66,1.43,3.28,2.29,4.83
		c0.56,1.01,0.77,1.92,0.48,3.07c-0.2,0.8-0.03,1.71,0.01,2.57c0.07,1.38,0.33,2.76,0.21,4.12c-0.17,1.95,0.05,2.65,1.73,3.67
		c0.31,0.19,0.68,0.35,0.87,0.62c2.22,3.2,5.45,2.98,8.68,2.49c2.56-0.39,5.09-0.99,7.63-1.51c2-0.41,3.96-0.17,5.93,0.26
		c3.35,0.73,6.73,1.36,10.09,2.03c0.23,0.04,0.6,0.09,0.69-0.02c0.87-1.13,2.4-1.78,2.38-3.56c-0.02-2.19,0.66-2.99,2.88-3.08
		c1.94-0.08,3.89,0.18,5.83,0.27c0.67,0.03,1.35,0,2.02-0.05c1.86-0.13,3.72-0.31,5.58-0.4c1.35-0.07,2.71-0.11,4.05-0.01
		c0.66,0.05,1.48,0.27,1.86,0.72c0.21,0.25-0.22,1.19-0.53,1.72c-0.83,1.4-1.59,2.9-2.7,4.06c-1.13,1.19-1.04,2.92-2.18,4.01
		c-1.36,1.29-2.67,2.65-4.16,3.77c-1.28,0.96-2.2,2-2.59,3.58c-0.18,0.75-0.64,1.45-1.07,2.11c-0.39,0.6-0.28,0.91,0.33,1.2
		c0.83,0.4,1.75,0.72,2.44,1.3c1.07,0.89,2.13,1.86,2.89,3c0.47,0.7,0.4,1.77,0.54,2.68c0.06,0.41-0.16,1,0.06,1.23
		c1.39,1.52,1.25,3.32,1.01,5.11c-0.13,0.97,0.25,1.38,1.09,1.45c1.55,0.14,3.1,0.29,4.65,0.28c0.58,0,1.24-0.33,1.73-0.7
		c2.29-1.74,4.83-2.57,7.71-2.46c1.08,0.04,2.02,0.28,2.35,1.44c0.33,1.17-0.23,2.11-1.2,2.61c-0.86,0.44-2.06,0.32-2.78,0.88
		c-0.69,0.54-0.78,1.81-1.44,2.44c-0.89,0.84-1.29,1.79-1.78,2.86c-0.95,2.08-2.13,4.1-3.5,5.92c-0.88,1.17-1.74,2.26-2.1,3.69
		c-0.61,2.41-2.05,4.06-4.39,4.98c-1.37,0.54-2.32,1.51-2.41,3.13c-0.05,0.79-0.2,1.59-0.4,2.36c-0.49,1.94-1.59,3.39-3.54,4.06
		c-1.03,0.35-1.58,0.99-1.62,2.1c-0.03,0.94-0.23,1.82-1.25,2.27c-0.19,0.09-0.34,0.54-0.32,0.82c0.15,2.25-1.28,3.5-2.94,4.53
		c-1.32,0.82-1.36,0.9-0.55,2.16c1.18,1.84,1.11,3.93,1.02,6.01c-0.15,3.68-1.3,7.01-3.13,10.19c-1.27,2.22-0.95,4.73-0.67,7.16
		c0.05,0.44,0.47,0.94,0.86,1.22c1.03,0.75,1.34,1.7,1.16,2.93c-0.21,1.42-0.31,2.86-0.49,4.29c-0.32,2.59,1.32,4.33,2.67,6.2
		c0.1,0.14,0.33,0.23,0.51,0.28c1.36,0.34,3.92-1.67,3.93-3.07c0.02-2.44,1.17-3.36,3.52-2.65c1.23,0.37,2.1,0.04,2.85-0.89
		c0.87-1.08,1.77-2.07,3.37-1.88c0.3,0.04,0.64-0.29,0.97-0.42c0.63-0.25,1.24-0.58,1.89-0.68c1.31-0.21,2.02,0.3,2.17,1.6
		c0.13,1.15,0.76,1.49,1.77,1.46c0.48-0.01,0.96-0.03,1.43-0.1c1.24-0.17,2.35-0.04,3.3,0.94c0.31,0.32,1.03,0.4,1.52,0.33
		c2.22-0.31,4.32,1.31,4.54,3.52c0.03,0.31,0.55,0.82,0.84,0.82c1.35-0.01,2.7-0.16,4.05-0.29c0.43-0.04,0.88-0.31,1.29-0.25
		c2.59,0.42,5.38,0.03,7.64,1.81c0.67,0.52,1.27,0.3,1.66-0.26c1.13-1.6,2.77-1.71,4.49-1.68c1.28,0.03,2.56,0.17,3.82,0.05
		c0.62-0.06,1.36-0.46,1.77-0.95c1.4-1.64,3.99-2.1,5.8-0.91c0.74,0.48,0.81,1.14,0.84,1.96c0.04,1.21,0.24,2.43,0.52,3.62
		c0.07,0.31,0.61,0.61,0.99,0.72c3.26,0.93,6.52,1.84,9.8,2.69c1.47,0.38,2.98,0.58,4.45,0.95c0.46,0.11,0.86,0.49,1.29,0.75
		c0,0.24,0,0.48,0,0.72c-0.58,2.05-1.3,4.02-3.05,5.4c-1.23,0.97-1.83,2.14-1.59,3.75c0.13,0.92-0.14,1.86-1.18,2.09
		c-2.16,0.49-3.16,2.29-4.39,3.78c-1.03,1.25-1.77,2.74-2.69,4.08c-0.47,0.69-1.39,1.21-1.55,1.94c-0.41,1.82-1.43,3.23-2.74,4.34
		c-0.99,0.84-1.6,1.67-1.73,2.96c-0.04,0.42-0.51,1.08-0.85,1.13c-1.43,0.21-1.84,1.18-2.1,2.36c1.15,0.38,1.12,0.36,1.88-0.43
		c0.29-0.3,0.7-0.64,1.09-0.67c1.66-0.15,3.33-0.25,5-0.26c0.92,0,1.55,0.64,1.55,1.52c0,1.77,0.92,2.99,2.07,4.16
		c0.61,0.62,1.14,1.34,1.65,2.05c1.42,1.98,1.4,2.56-0.04,4.4c0.66,0.84,1.47,1.64,0.74,2.86c-1.68,2.8-3.95,5.02-6.42,7.14
		c-1.45,1.25-2.54,3.09-3.33,4.87c-0.99,2.21-1.99,4.24-4.15,5.11c0,1.06,0,1.99,0,3.07c0.11,0.08,0.48,0.24,0.67,0.52
		c0.51,0.76,1.32,1.57,1.32,2.36c0,1.12-1.14,1.59-2.2,1.88c-0.8,0.22-1.61,0.49-2.35,0.86c-0.29,0.14-0.57,0.6-0.6,0.94
		c-0.13,1.54-1.43,3.19-2.92,3.58c-0.37,0.1-0.75,0.18-1.19,0.29c0.11,0.32,0.18,0.54,0.25,0.76c0.36,1.13,0.19,2.04-1.02,2.57
		c-0.4,0.17-0.78,0.42-1.1,0.71c-0.23,0.21-0.51,0.56-0.49,0.82c0.09,1.49-0.39,2.59-2.01,3.05c0.67,0.78,1.58,1.52,0.69,2.41
		c-0.6,0.6-1.53,0.88-2.38,1.34c0.45,1.03,0,1.78-0.87,2.41c-0.35,0.25-0.65,0.59-0.94,0.92c-1.23,1.37-1.28,2.27-0.37,3.85
		c0.22,0.38,0.31,0.92,0.27,1.36c-0.06,0.75-0.26,1.49-0.43,2.23c-0.26,1.1-0.94,1.84-2,2.2c-0.65,0.22-1.33,0.37-2,0.55
		c-0.24,0-0.48,0-0.72,0c-0.31-0.43-0.66-0.84-0.93-1.3c-0.29-0.47-0.44-1.04-0.78-1.47c-0.3-0.38-0.77-0.62-1.16-0.92
		c-0.18,0.37-0.4,0.72-0.54,1.11c-0.48,1.34-2.14,2.35-3.43,2.07c-1.15-0.25-2.69-2.36-2.44-3.5c0.12-0.55,0.52-1.04,0.74-1.46
		c-1.07-1.01-2.1-2.08-3.23-3.04c-1.41-1.2-3.66-1.14-5.05,0.26c-2.25,2.27-4.91,2.16-7.69,1.58c-1.26-0.26-1.9-1.07-1.9-2.39
		c0-0.8-0.08-1.6-0.11-2.39c-0.06-1.79,0.34-2.42,1.98-3.14c0.21-0.09,0.41-0.22,0.64-0.34c-0.39-0.81-0.82-1.56-1.13-2.35
		c-0.53-1.34-0.4-2.6,0.76-3.6c0.91-0.79,0.96-1.65,0.39-2.67c-0.28-0.51-0.58-1.11-0.57-1.67c0.04-2.86-1.45-4.92-3.44-6.68
		c-1.15-1.02-1.97-2.14-2.38-3.63c-0.16-0.56-0.77-0.97-1.09-1.5c-1.22-2.03-2.3-4.09-2.27-6.58c0.03-2.27-0.05-4.56-0.3-6.82
		c-0.13-1.13-0.73-2.23-1.2-3.3c-0.23-0.54-0.73-0.97-0.96-1.51c-0.57-1.34-1.41-2.21-2.92-2.52c-1.27-0.26-1.66-1.03-1.24-2.25
		c0.37-1.09,0.1-1.88-0.74-2.53c-1.12-0.86-1.45-2.02-1.45-3.38c0.01-3.52-0.03-3.57-3.52-3.54c-4.35,0.03-8.73-0.15-13.05,0.25
		c-2.59,0.24-5.1-0.12-7.65-0.09c-8.15,0.07-16.31,0.21-24.46,0.18c-8.43-0.03-16.86-0.26-25.3-0.32c-3.11-0.02-6.22,0.27-9.34,0.28
		c-3.23,0.01-6.45-0.13-9.68-0.21c-1.16-0.03-2.33,0.02-3.46-0.16c-1.53-0.25-1.77-0.9-1.05-2.23c1-1.83,1.94-3.64,1.74-5.87
		c-0.06-0.68,0.38-1.58,0.88-2.11c1.24-1.3,2.23-2.7,2.74-4.44c0.02-0.08,0.05-0.16,0.1-0.22c1.02-1.37,1.34-2.7,0.46-4.37
		c-0.55-1.04,0.08-2.21,0.95-3.02c1.64-1.52,2.34-3.2,1.22-5.34c-0.15-0.29-0.24-0.8-0.09-1.04c1.18-1.86,0.05-4.31,1.89-5.97
		c-0.09-0.08-0.18-0.17-0.27-0.25c-2.09,0-4.18,0.03-6.27-0.01c-3-0.05-5.99-0.27-8.98-0.16c-1.15,0.04-2.28,0.75-3.41,1.19
		c-1.13,0.45-2.21,1.15-3.38,1.36c-1.43,0.26-2.09-0.49-2.27-1.96c-0.26-2.06-0.55-4.11-0.87-6.16c-0.04-0.28-0.28-0.56-0.51-0.76
		c-1.93-1.69-2.28-4.56-0.93-6.69c0.37-0.59,0.49-1.41,0.52-2.13c0.05-1.3,0.14-2.45,1.13-3.52c1.18-1.26,0.49-4.35-0.95-5.34
		c-1.59-1.1-2.8-2.47-3.06-4.5c-0.08-0.61-0.33-1.21-0.5-1.81C428.78,772.27,428.78,771.31,428.78,770.35z M451.06,836.47
		c4.16,0.1,8.18,0.27,12.19,0.27c2.95,0,5.9-0.35,8.86-0.33c7.79,0.06,15.59,0.32,23.38,0.35c8.84,0.04,17.67-0.08,26.51-0.14
		c3.79-0.03,7.58-0.13,11.37-0.14c4-0.01,8,0.04,12,0.08c1.27,0.01,2.18,0.65,2.49,1.86c0.27,1.07,0.25,2.21,0.47,3.29
		c0.17,0.82,0.33,1.81,0.86,2.37c1.21,1.25,1.92,2.53,1.43,3.93c1.12,0.84,2.23,1.42,2.98,2.3c0.89,1.05,1.53,2.33,2.18,3.56
		c0.42,0.8,0.86,1.67,0.94,2.54c0.25,2.62,0.36,5.26,0.46,7.9c0.08,2.25,0.76,4.22,2.4,5.82c0.22,0.22,0.48,0.5,0.52,0.78
		c0.26,1.99,1.61,3.2,2.95,4.49c1.57,1.52,2.7,3.35,3.14,5.55c0.23,1.16,0.45,2.33,0.83,3.44c0.45,1.36,0.42,2.55-0.53,3.7
		c-1.2,1.46-1.18,1.68-0.22,3.27c1.19,1.97,0.81,3.07-1.24,4.11c-0.41,0.21-0.7,0.86-0.82,1.36c-0.1,0.43,0.19,0.93,0.2,1.41
		c0.02,0.85,0.48,1.12,1.25,1.28c1.99,0.42,3.54-0.29,5.04-1.55c2.36-1.97,6.15-1.7,8.11,0.36c0.75,0.8,1.61,1.51,2.48,2.17
		c1.34,1.01,1.53,2.12,0.61,3.55c-0.15,0.24,0.15,0.77,0.25,1.16c0.32-0.11,0.76-0.12,0.93-0.34c0.26-0.32,0.35-0.79,0.49-1.21
		c0.42-1.28,1.18-1.7,2.47-1.41c1.66,0.38,2.69,1.48,3.44,2.91c0.58,0,1.12,0,1.68,0c0.36-1.19,0.69-2.22-0.25-3.39
		c-0.42-0.52-0.46-1.69-0.18-2.37c0.54-1.3,1.42-2.45,2.16-3.67c0.18-0.29,0.57-0.59,0.53-0.84c-0.21-1.58,0.49-2.43,2.05-2.65
		c-1.16-1.87-0.91-2.63,0.99-3.35c0.5-0.19,0.83-0.35,0.63-1.02c-0.34-1.12,0.21-1.93,1.15-2.51c0.57-0.36,1.14-0.71,1.71-1.07
		c-0.08-0.25-0.11-0.41-0.17-0.55c-0.73-1.83-0.34-2.58,1.57-2.99c0.39-0.08,0.86-0.08,1.14-0.31c0.43-0.36,1.1-0.94,1.03-1.32
		c-0.35-2.01,1.08-2.57,2.41-3.2c0.8-0.38,1.67-0.61,2.58-0.93c-2.94-2.37-2.94-2.37-1.68-4.27c-0.67-1.43-0.37-2.71,0.95-3.19
		c1.73-0.64,2.54-1.97,3.12-3.55c0.91-2.48,2.26-4.66,4.28-6.4c1.12-0.96,2.25-1.92,3.33-2.93c0.49-0.46,0.91-1.02,1.24-1.6
		c0.33-0.58,0.51-1.25,0.81-2c-0.91-1.34-1.07-2.08-0.12-2.99c0.85-0.81,0.54-1.34-0.05-1.99c-0.24-0.27-0.48-0.54-0.71-0.81
		c-1.4-1.58-2.96-3.06-3.25-5.33c-0.03-0.2-0.45-0.53-0.66-0.51c-1.39,0.15-3.02-0.47-4.02,1.16c-0.16,0.26-0.63,0.53-0.91,0.49
		c-1.61-0.21-3.24-0.39-4.79-0.85c-0.45-0.13-0.88-1.14-0.86-1.73c0.01-0.41,0.79-0.81,1.23-1.22c0.38-0.35,0.92-0.62,1.11-1.05
		c0.47-1.05,1.51-1.33,2.24-1.99c0.72-0.64,0.89-1.88,1.35-2.83c0.11-0.22,0.49-0.3,0.65-0.51c0.63-0.78,1.34-1.53,1.81-2.41
		c0.72-1.33,0.98-2.79,2.35-3.89c1.27-1.02,1.91-2.78,2.97-4.11c1.43-1.8,2.74-3.79,5.13-4.54c0.34-3.18,0.38-3.15,2.73-5.75
		c0.72-0.79,1.2-1.8,1.9-2.88c-1.45-0.29-2.59-0.46-3.71-0.75c-3.62-0.96-7.25-1.9-10.82-3.01c-0.75-0.23-1.59-1.05-1.86-1.79
		c-0.51-1.43-0.64-2.99-0.95-4.63c-1.02-0.46-1.96-0.23-2.71,0.89c-0.63,0.94-1.53,1.54-2.7,1.53c-1.54-0.01-3.09-0.07-4.63-0.16
		c-0.98-0.06-1.74,0.15-2.45,0.91c-1.62,1.74-3.14,1.78-5.01,0.34c-0.33-0.25-0.78-0.39-1.19-0.48c-1.04-0.22-2.08-0.4-3.13-0.55
		c-0.54-0.08-1.1-0.1-1.64-0.05c-1.91,0.16-3.81,0.48-5.71,0.5c-1.68,0.02-2.53-0.96-2.67-2.6c-0.09-1.09-1.22-1.83-2.29-1.71
		c-0.78,0.08-1.9,0.16-2.34-0.28c-1.09-1.08-2.25-1.12-3.6-1.04c-2.53,0.16-3.85-0.73-4.47-2.86c-0.68,0.32-1.35,0.87-2.03,0.9
		c-1.33,0.05-2.09,0.72-2.85,1.67c-1.05,1.29-2.49,1.7-4.1,1.28c-1.38-0.36-1.59-0.26-1.52,1.24c0.05,1.08-0.32,1.93-1.2,2.45
		c-1.3,0.77-2.64,1.5-4.04,2.03c-0.57,0.21-1.61,0.11-1.99-0.28c-2.23-2.25-4.35-4.61-4.05-8.14c0.13-1.47,0.26-2.95,0.46-4.41
		c0.1-0.75-0.12-1.26-0.69-1.77c-0.51-0.46-1.05-1.1-1.17-1.73c-0.6-3.37-0.59-6.79,1.19-9.8c1.62-2.75,2.48-5.64,2.52-8.75
		c0.02-1.78,0.14-3.65-1.26-5.18c-0.85-0.93-0.66-2.41,0.31-3.38c0.75-0.75,1.55-1.5,2.45-2.04c0.77-0.47,1.06-0.96,0.97-1.83
		c-0.11-1.05,0.12-1.95,1.16-2.55c0.31-0.18,0.48-0.79,0.52-1.21c0.13-1.35,0.68-2.51,1.98-2.89c2.68-0.79,3.43-2.76,3.52-5.21
		c0.08-2.3,1.43-3.65,3.39-4.5c1.88-0.82,3.05-2.16,3.65-4.13c0.35-1.16,0.76-2.47,1.58-3.28c2.1-2.11,3.37-4.6,4.26-7.37
		c0.22-0.69,0.68-1.4,1.23-1.85c0.7-0.57,1.15-1.14,1.29-2.04c0.19-1.19,0.94-1.79,2.19-1.73c0.48,0.02,0.97-0.12,1.46-0.19
		c-2.24-0.56-3.98,0.49-5.78,1.56c-1.1,0.65-2.39,1.24-3.63,1.33c-1.68,0.12-3.43-0.12-5.1-0.48c-2.56-0.55-2.85-1.13-2.45-3.69
		c0.22-1.39,0.14-2.66-0.83-3.84c-0.36-0.43-0.27-1.25-0.34-1.9c-0.07-0.61,0.13-1.39-0.18-1.82c-1.11-1.54-2.24-3.08-4.33-3.54
		c-1.95-0.44-2.56-2.31-1.49-4c0.45-0.7,0.91-1.44,1.12-2.23c0.49-1.8,1.55-3.11,3.03-4.17c0.78-0.56,1.53-1.16,2.26-1.79
		c0.91-0.78,1.78-1.55,1.86-2.93c0.03-0.53,0.52-1.05,0.86-1.53c0.91-1.32,1.86-2.61,2.79-3.91c-0.05-0.15-0.11-0.3-0.16-0.45
		c-2.52,0.12-5.03,0.24-7.55,0.35c-1.03,0.05-2.06,0.12-3.09,0.1c-1.7-0.04-3.41-0.17-5.11-0.21c-0.69-0.02-0.94,0.37-0.88,1.1
		c0.1,1.31-0.44,2.4-1.4,3.3c-0.44,0.41-0.85,0.85-1.21,1.32c-0.68,0.91-1.56,1.19-2.64,0.97c-3.68-0.74-7.34-1.55-11.04-2.19
		c-1.47-0.25-3.02-0.31-4.5-0.14c-2.17,0.25-4.35,0.65-6.45,1.24c-2.72,0.77-5.35,0.64-8.03-0.08c-1.41-0.38-2.61-0.94-3.38-2.25
		c-0.19-0.32-0.55-0.58-0.89-0.77c-2.22-1.27-2.93-2.85-2.7-5.35c0.12-1.36-0.19-2.75-0.26-4.13c-0.05-0.9-0.19-1.83-0.01-2.69
		c0.19-0.86,0.09-1.51-0.32-2.26c-0.82-1.5-1.7-3-2.23-4.61c-0.37-1.11-0.92-1.74-1.92-2.24c-0.91-0.46-1.76-1.07-2.57-1.71
		c-1.51-1.18-2.88-2.55-4.47-3.6c-2-1.33-3.94-2.61-4.26-5.21c-2.34-0.17-2.85-0.99-2.36-3.15c0.26-1.14,0.23-2.35,0.22-3.53
		c-0.01-1.66-0.11-3.31-0.21-4.97c-0.02-0.26-0.18-0.67-0.37-0.75c-1.44-0.54-1.5-1.58-1.37-2.92c0.09-0.88-0.06-2.05-0.6-2.65
		c-0.98-1.09-1.35-2.2-1.23-3.59c0.04-0.49-0.09-1-0.17-1.5c-0.11-0.67-0.44-1.36-0.33-1.98c0.16-0.87,0.63-1.69,1.02-2.66
		c-0.39-0.44-0.87-0.96-1.34-1.5c-0.69-0.79-1.31-1.81-0.79-2.72c0.68-1.19,0.38-2.33,0.45-3.51c0.09-1.74-0.56-3.02-1.99-4.1
		c-0.67-0.51-1.26-1.54-1.3-2.37c-0.08-1.59-0.53-2.76-1.97-3.54c-1.04-0.57-1.22-1.52-0.81-2.58c0.32-0.82,0.92-1.62,0.95-2.44
		c0.1-2.71,1.1-5,2.68-7.13c0.22-0.29,0.17-1.12-0.09-1.36c-1.17-1.15-1.11-2.42-0.78-3.88c0.27-1.19,0.39-2.44,0.33-3.66
		c-0.06-1.19,0.18-2.09,1.15-2.83c1.02-0.78,1.44-1.88,1.35-3.17c-0.05-0.79-0.08-1.58-0.13-2.49c-0.62,0.39-1.21,0.72-1.75,1.12
		c-0.64,0.47-0.84,1.07-0.57,1.89c0.48,1.47,0.04,2.14-1.52,2.42c-0.86,0.15-1.75,0.17-2.62,0.29c-0.56,0.08-0.88,0.27-0.81,1.02
		c0.14,1.48-0.74,2.54-2.23,2.75c-0.82,0.12-1.67,0.08-2.5,0.02c-2.68-0.2-3.5,0.33-4.74,2.69c-0.95,1.8-2.11,3.59-4.1,4.36
		c-2.03,0.79-3.68,2.03-5.31,3.39c-0.82,0.68-1.93,1.04-2.92,1.5c-0.97,0.45-1.98,0.8-2.92,1.29c-0.26,0.14-0.53,0.71-0.44,0.98
		c0.57,1.74,1.24,3.44,1.87,5.15c0.36,0.99,0.91,1.95,1.04,2.97c0.24,1.85,0.24,3.73,0.32,5.6c0.05,1.16-0.1,2.35,0.12,3.47
		c0.35,1.75-0.34,2.92-1.58,3.97c-1.12,0.96-2.17,1.99-3.28,2.97c-0.69,0.6-0.88,1.25-0.55,2.13c0.48,1.27,0.34,2.45-0.42,3.65
		c-0.36,0.57-0.47,1.47-0.33,2.16c0.47,2.38-0.71,4.54-0.75,6.84c0,0.21-0.27,0.41-0.33,0.63c-0.51,1.86-0.96,3.77,0.02,5.57
		c0.71,1.31,0.84,2.59,0.29,3.9c-0.49,1.17-0.25,2.15,0.35,3.17c0.67,1.13,1.26,2.31,1.9,3.46c0.66,1.21,0.56,2.31-0.19,3.49
		c-0.41,0.64-0.68,1.48-0.69,2.23c-0.01,1.96-0.81,3.38-2.29,4.65c-1.1,0.94-1.93,2.19-2.83,3.35c-0.18,0.23-0.25,0.75-0.11,0.97
		c1.3,2.01,1.01,4.22,0.82,6.4c-0.04,0.49-0.22,1.12-0.56,1.41c-1.15,0.98-1.17,2.09-0.9,3.45c0.36,1.8-0.69,3.08-2.52,3.39
		c-0.54,0.09-1.08,0.15-1.68,0.23c0.51,1.33,1.01,2.56-0.16,3.69c-0.08,0.08,0,0.31-0.01,0.46c-0.06,0.82-0.13,1.65-0.21,2.58
		c1.96,0.68,2.39,2.2,2.18,4.18c-0.07,0.64,0.21,1.44,0.59,1.99c1.25,1.79,0.96,3.72,0.7,5.66c-0.04,0.28-0.29,0.67-0.53,0.75
		c-1.4,0.44-1.53,1.54-1.62,2.74c-0.04,0.55-0.17,1.15-0.46,1.6c-0.89,1.41-0.73,2.94-0.74,4.48c0,1.07,0.4,1.8,1.38,2.3
		c2.35,1.22,2.74,2.94,1.34,5.2c-0.17,0.28-0.2,0.85-0.03,1.11c0.66,0.97,0.58,1.78-0.31,2.53c-0.63,0.54-0.75,1.12-0.3,1.89
		c0.28,0.47,0.34,1.06,0.51,1.59c0.58,1.82,1.02,3.69,1.76,5.44c1.08,2.55,0.36,4.82-1.01,6.87c-0.81,1.19-1.32,2.33-1.32,3.73
		c0,1.65-0.36,3.23-1.42,4.49c-1.21,1.44-1.24,3.02-1.05,4.73c0.34,3.07-0.47,5.83-2.39,8.28c-0.35,0.44-0.56,1.13-0.53,1.7
		c0.05,1.47,0.12,2.88-1.18,3.95c-0.23,0.19-0.37,0.53-0.45,0.83c-0.46,1.76,0.97,5.49,2.48,6.53c2.51,1.72,3.27,5.86,1.64,8.42
		c-0.45,0.7-0.58,1.6-0.83,2.42c-0.33,1.12-0.53,2.3-0.98,3.37c-0.48,1.15-0.19,3.28,0.82,3.9c0.82,0.51,1.01,1.2,1.1,2.1
		c0.22,2.04,0.57,4.06,0.91,6.32c0.77-0.44,1.46-0.74,2.05-1.18c1.82-1.36,3.83-1.91,6.08-1.68c0.83,0.08,1.68,0.07,2.51,0.08
		c3.92,0.06,7.84,0.1,11.75,0.16c2.01,0.03,2.96,1.15,1.92,2.7c-1.36,2.03-0.25,4.4-1.4,6.35c-0.07,0.11,0.11,0.37,0.17,0.56
		c0.77,2.48,0.08,4.56-1.81,6.26c-0.7,0.63-0.99,1.22-0.51,2.18c0.54,1.06,0.9,2.21,0.17,3.42c-0.6,0.98-1.04,2.05-1.63,3.04
		c-0.63,1.05-1.28,2.11-2.06,3.06c-0.59,0.72-0.88,1.41-0.92,2.36c-0.05,1.11-0.4,2.22-0.69,3.31
		C451.67,835.35,451.36,835.83,451.06,836.47z"/>
					<path class="tangara" d="M451.06,836.47c0.3-0.64,0.6-1.12,0.74-1.64c0.29-1.09,0.64-2.2,0.69-3.31c0.04-0.95,0.32-1.64,0.92-2.36
		c0.78-0.95,1.43-2,2.06-3.06c0.59-0.98,1.03-2.06,1.63-3.04c0.74-1.21,0.37-2.35-0.17-3.42c-0.49-0.96-0.19-1.55,0.51-2.18
		c1.89-1.71,2.58-3.78,1.81-6.26c-0.06-0.19-0.24-0.44-0.17-0.56c1.15-1.95,0.04-4.32,1.4-6.35c1.04-1.55,0.09-2.66-1.92-2.7
		c-3.92-0.07-7.84-0.11-11.75-0.16c-0.84-0.01-1.68,0-2.51-0.08c-2.25-0.23-4.26,0.32-6.08,1.68c-0.59,0.44-1.27,0.74-2.05,1.18
		c-0.33-2.26-0.69-4.28-0.91-6.32c-0.1-0.9-0.28-1.58-1.1-2.1c-1.01-0.63-1.3-2.75-0.82-3.9c0.44-1.07,0.64-2.25,0.98-3.37
		c0.24-0.82,0.38-1.72,0.83-2.42c1.64-2.56,0.88-6.7-1.64-8.42c-1.51-1.04-2.95-4.76-2.48-6.53c0.08-0.3,0.22-0.64,0.45-0.83
		c1.3-1.06,1.23-2.48,1.18-3.95c-0.02-0.56,0.19-1.25,0.53-1.7c1.92-2.45,2.73-5.2,2.39-8.28c-0.19-1.72-0.17-3.29,1.05-4.73
		c1.06-1.26,1.42-2.84,1.42-4.49c0-1.4,0.52-2.54,1.32-3.73c1.38-2.04,2.1-4.31,1.01-6.87c-0.74-1.75-1.19-3.62-1.76-5.44
		c-0.17-0.53-0.24-1.12-0.51-1.59c-0.45-0.76-0.33-1.35,0.3-1.89c0.89-0.75,0.97-1.55,0.31-2.53c-0.17-0.26-0.15-0.83,0.03-1.11
		c1.41-2.26,1.01-3.97-1.34-5.2c-0.97-0.51-1.38-1.23-1.38-2.3c0.01-1.54-0.15-3.07,0.74-4.48c0.29-0.45,0.41-1.05,0.46-1.6
		c0.09-1.2,0.21-2.31,1.62-2.74c0.24-0.07,0.49-0.47,0.53-0.75c0.26-1.94,0.56-3.87-0.7-5.66c-0.38-0.54-0.66-1.34-0.59-1.99
		c0.21-1.97-0.23-3.5-2.18-4.18c0.08-0.93,0.15-1.76,0.21-2.58c0.01-0.16-0.07-0.39,0.01-0.46c1.18-1.14,0.68-2.37,0.16-3.69
		c0.6-0.08,1.14-0.14,1.68-0.23c1.84-0.31,2.88-1.59,2.52-3.39c-0.27-1.36-0.26-2.48,0.9-3.45c0.34-0.29,0.52-0.92,0.56-1.41
		c0.19-2.18,0.48-4.39-0.82-6.4c-0.14-0.22-0.06-0.74,0.11-0.97c0.9-1.16,1.73-2.41,2.83-3.35c1.48-1.27,2.28-2.69,2.29-4.65
		c0-0.75,0.28-1.59,0.69-2.23c0.75-1.17,0.86-2.28,0.19-3.49c-0.63-1.15-1.23-2.33-1.9-3.46c-0.61-1.02-0.84-2.01-0.35-3.17
		c0.55-1.31,0.42-2.59-0.29-3.9c-0.98-1.8-0.53-3.71-0.02-5.57c0.06-0.22,0.32-0.42,0.33-0.63c0.04-2.3,1.22-4.46,0.75-6.84
		c-0.14-0.68-0.03-1.59,0.33-2.16c0.76-1.2,0.9-2.38,0.42-3.65c-0.33-0.88-0.14-1.53,0.55-2.13c1.11-0.97,2.16-2.01,3.28-2.97
		c1.24-1.06,1.94-2.23,1.58-3.97c-0.23-1.12-0.08-2.31-0.12-3.47c-0.08-1.87-0.08-3.75-0.32-5.6c-0.13-1.02-0.67-1.98-1.04-2.97
		c-0.63-1.72-1.3-3.42-1.87-5.15c-0.09-0.26,0.18-0.84,0.44-0.98c0.94-0.5,1.95-0.84,2.92-1.29c1-0.46,2.1-0.81,2.92-1.5
		c1.64-1.37,3.29-2.6,5.31-3.39c1.99-0.77,3.15-2.56,4.1-4.36c1.25-2.36,2.06-2.88,4.74-2.69c0.83,0.06,1.68,0.1,2.5-0.02
		c1.49-0.21,2.37-1.27,2.23-2.75c-0.07-0.75,0.25-0.94,0.81-1.02c0.87-0.12,1.75-0.14,2.62-0.29c1.56-0.28,2-0.95,1.52-2.42
		c-0.27-0.82-0.06-1.42,0.57-1.89c0.54-0.4,1.13-0.73,1.75-1.12c0.05,0.91,0.08,1.7,0.13,2.49c0.09,1.29-0.33,2.38-1.35,3.17
		c-0.96,0.74-1.21,1.64-1.15,2.83c0.06,1.21-0.06,2.47-0.33,3.66c-0.33,1.46-0.39,2.73,0.78,3.88c0.25,0.25,0.3,1.07,0.09,1.36
		c-1.58,2.13-2.58,4.42-2.68,7.13c-0.03,0.82-0.63,1.62-0.95,2.44c-0.41,1.07-0.24,2.01,0.81,2.58c1.44,0.78,1.88,1.95,1.97,3.54
		c0.04,0.83,0.63,1.86,1.3,2.37c1.42,1.08,2.08,2.36,1.99,4.1c-0.06,1.18,0.23,2.32-0.45,3.51c-0.52,0.91,0.1,1.93,0.79,2.72
		c0.47,0.53,0.95,1.06,1.34,1.5c-0.39,0.97-0.86,1.78-1.02,2.66c-0.11,0.62,0.22,1.31,0.33,1.98c0.08,0.5,0.21,1.01,0.17,1.5
		c-0.11,1.39,0.25,2.5,1.23,3.59c0.54,0.6,0.69,1.77,0.6,2.65c-0.13,1.34-0.07,2.38,1.37,2.92c0.19,0.07,0.35,0.48,0.37,0.75
		c0.1,1.65,0.2,3.31,0.21,4.97c0.01,1.18,0.04,2.4-0.22,3.53c-0.49,2.16,0.02,2.98,2.36,3.15c0.32,2.6,2.26,3.88,4.26,5.21
		c1.59,1.05,2.96,2.42,4.47,3.6c0.81,0.63,1.66,1.25,2.57,1.71c1,0.5,1.55,1.13,1.92,2.24c0.53,1.61,1.41,3.11,2.23,4.61
		c0.41,0.75,0.51,1.41,0.32,2.26c-0.19,0.85-0.04,1.79,0.01,2.69c0.08,1.38,0.39,2.77,0.26,4.13c-0.23,2.5,0.47,4.08,2.7,5.35
		c0.34,0.19,0.7,0.45,0.89,0.77c0.77,1.31,1.97,1.88,3.38,2.25c2.68,0.71,5.31,0.85,8.03,0.08c2.1-0.59,4.28-0.99,6.45-1.24
		c1.48-0.17,3.03-0.12,4.5,0.14c3.7,0.64,7.36,1.45,11.04,2.19c1.08,0.22,1.96-0.05,2.64-0.97c0.35-0.48,0.77-0.92,1.21-1.32
		c0.97-0.9,1.5-1.99,1.4-3.3c-0.06-0.72,0.19-1.11,0.88-1.1c1.7,0.04,3.41,0.17,5.11,0.21c1.03,0.02,2.06-0.05,3.09-0.1
		c2.52-0.11,5.03-0.23,7.55-0.35c0.05,0.15,0.11,0.3,0.16,0.45c-0.93,1.3-1.88,2.6-2.79,3.91c-0.34,0.49-0.83,1.01-0.86,1.53
		c-0.08,1.37-0.95,2.15-1.86,2.93c-0.73,0.62-1.48,1.23-2.26,1.79c-1.48,1.06-2.54,2.37-3.03,4.17c-0.22,0.79-0.68,1.53-1.12,2.23
		c-1.07,1.68-0.47,3.56,1.49,4c2.09,0.46,3.21,2,4.33,3.54c0.31,0.43,0.1,1.21,0.18,1.82c0.08,0.65-0.02,1.46,0.34,1.9
		c0.97,1.18,1.05,2.45,0.83,3.84c-0.4,2.56-0.1,3.14,2.45,3.69c1.66,0.36,3.41,0.59,5.1,0.48c1.24-0.09,2.53-0.67,3.63-1.33
		c1.8-1.07,3.54-2.12,5.78-1.56c-0.49,0.07-0.97,0.21-1.46,0.19c-1.26-0.05-2,0.54-2.19,1.73c-0.15,0.9-0.59,1.47-1.29,2.04
		c-0.55,0.45-1.01,1.16-1.23,1.85c-0.89,2.77-2.16,5.26-4.26,7.37c-0.81,0.82-1.22,2.13-1.58,3.28c-0.6,1.97-1.78,3.31-3.65,4.13
		c-1.95,0.85-3.3,2.2-3.39,4.5c-0.09,2.46-0.84,4.42-3.52,5.21c-1.29,0.38-1.85,1.54-1.98,2.89c-0.04,0.43-0.21,1.03-0.52,1.21
		c-1.04,0.6-1.27,1.51-1.16,2.55c0.09,0.87-0.2,1.36-0.97,1.83c-0.9,0.54-1.7,1.29-2.45,2.04c-0.97,0.97-1.16,2.45-0.31,3.38
		c1.41,1.53,1.29,3.4,1.26,5.18c-0.04,3.11-0.9,6-2.52,8.75c-1.78,3.01-1.79,6.43-1.19,9.8c0.11,0.63,0.66,1.27,1.17,1.73
		c0.57,0.52,0.79,1.02,0.69,1.77c-0.19,1.47-0.33,2.94-0.46,4.41c-0.3,3.52,1.83,5.89,4.05,8.14c0.39,0.39,1.43,0.5,1.99,0.28
		c1.4-0.53,2.75-1.26,4.04-2.03c0.87-0.52,1.24-1.37,1.2-2.45c-0.06-1.5,0.14-1.6,1.52-1.24c1.61,0.42,3.05,0.01,4.1-1.28
		c0.77-0.94,1.53-1.62,2.85-1.67c0.69-0.02,1.35-0.58,2.03-0.9c0.62,2.13,1.94,3.01,4.47,2.86c1.35-0.08,2.52-0.04,3.6,1.04
		c0.44,0.44,1.56,0.37,2.34,0.28c1.08-0.12,2.2,0.62,2.29,1.71c0.14,1.65,0.99,2.62,2.67,2.6c1.91-0.02,3.81-0.34,5.71-0.5
		c0.54-0.04,1.1-0.02,1.64,0.05c1.05,0.15,2.09,0.33,3.13,0.55c0.41,0.09,0.86,0.23,1.19,0.48c1.87,1.44,3.39,1.4,5.01-0.34
		c0.71-0.76,1.48-0.97,2.45-0.91c1.54,0.1,3.09,0.15,4.63,0.16c1.17,0.01,2.07-0.6,2.7-1.53c0.76-1.12,1.7-1.35,2.71-0.89
		c0.31,1.63,0.44,3.2,0.95,4.63c0.26,0.74,1.11,1.56,1.86,1.79c3.58,1.11,7.2,2.05,10.82,3.01c1.11,0.29,2.26,0.46,3.71,0.75
		c-0.7,1.08-1.18,2.09-1.9,2.88c-2.35,2.6-2.39,2.57-2.73,5.75c-2.39,0.75-3.7,2.74-5.13,4.54c-1.05,1.33-1.7,3.09-2.97,4.11
		c-1.37,1.1-1.63,2.56-2.35,3.89c-0.47,0.87-1.18,1.62-1.81,2.41c-0.17,0.21-0.55,0.28-0.65,0.51c-0.46,0.95-0.63,2.19-1.35,2.83
		c-0.73,0.66-1.77,0.94-2.24,1.99c-0.19,0.43-0.73,0.7-1.11,1.05c-0.44,0.4-1.22,0.8-1.23,1.22c-0.01,0.59,0.42,1.6,0.86,1.73
		c1.55,0.46,3.18,0.64,4.79,0.85c0.29,0.04,0.75-0.23,0.91-0.49c1-1.63,2.62-1.01,4.02-1.16c0.21-0.02,0.63,0.31,0.66,0.51
		c0.29,2.27,1.86,3.75,3.25,5.33c0.24,0.27,0.47,0.54,0.71,0.81c0.59,0.65,0.9,1.18,0.05,1.99c-0.95,0.9-0.79,1.65,0.12,2.99
		c-0.3,0.76-0.48,1.42-0.81,2c-0.33,0.58-0.75,1.14-1.24,1.6c-1.07,1.01-2.21,1.97-3.33,2.93c-2.02,1.74-3.37,3.92-4.28,6.4
		c-0.58,1.58-1.39,2.92-3.12,3.55c-1.32,0.49-1.62,1.76-0.95,3.19c-1.26,1.89-1.26,1.89,1.68,4.27c-0.91,0.32-1.78,0.55-2.58,0.93
		c-1.34,0.63-2.76,1.19-2.41,3.2c0.07,0.38-0.6,0.96-1.03,1.32c-0.28,0.23-0.75,0.23-1.14,0.31c-1.9,0.41-2.3,1.16-1.57,2.99
		c0.06,0.14,0.09,0.29,0.17,0.55c-0.58,0.36-1.15,0.72-1.71,1.07c-0.94,0.59-1.48,1.39-1.15,2.51c0.2,0.67-0.13,0.84-0.63,1.02
		c-1.9,0.71-2.15,1.48-0.99,3.35c-1.55,0.22-2.26,1.07-2.05,2.65c0.03,0.25-0.36,0.55-0.53,0.84c-0.74,1.21-1.63,2.37-2.16,3.67
		c-0.28,0.68-0.24,1.85,0.18,2.37c0.94,1.17,0.61,2.2,0.25,3.39c-0.57,0-1.11,0-1.68,0c-0.74-1.43-1.78-2.53-3.44-2.91
		c-1.29-0.29-2.05,0.13-2.47,1.41c-0.14,0.41-0.23,0.88-0.49,1.21c-0.18,0.22-0.61,0.24-0.93,0.34c-0.09-0.39-0.4-0.92-0.25-1.16
		c0.92-1.44,0.73-2.54-0.61-3.55c-0.88-0.66-1.73-1.38-2.48-2.17c-1.95-2.07-5.75-2.34-8.11-0.36c-1.51,1.26-3.05,1.97-5.04,1.55
		c-0.77-0.16-1.23-0.42-1.25-1.28c-0.01-0.47-0.3-0.98-0.2-1.41c0.12-0.5,0.41-1.16,0.82-1.36c2.05-1.04,2.43-2.14,1.24-4.11
		c-0.96-1.59-0.98-1.81,0.22-3.27c0.95-1.15,0.98-2.34,0.53-3.7c-0.37-1.12-0.59-2.28-0.83-3.44c-0.44-2.2-1.57-4.03-3.14-5.55
		c-1.34-1.3-2.69-2.51-2.95-4.49c-0.04-0.28-0.29-0.57-0.52-0.78c-1.64-1.6-2.31-3.57-2.4-5.82c-0.1-2.63-0.21-5.27-0.46-7.9
		c-0.08-0.87-0.52-1.74-0.94-2.54c-0.65-1.23-1.28-2.51-2.18-3.56c-0.75-0.88-1.86-1.46-2.98-2.3c0.5-1.4-0.22-2.68-1.43-3.93
		c-0.54-0.56-0.69-1.55-0.86-2.37c-0.23-1.08-0.2-2.22-0.47-3.29c-0.31-1.21-1.21-1.85-2.49-1.86c-4-0.03-8-0.09-12-0.08
		c-3.79,0.01-7.58,0.12-11.37,0.14c-8.84,0.07-17.67,0.18-26.51,0.14c-7.79-0.03-15.59-0.29-23.38-0.35
		c-2.95-0.02-5.9,0.33-8.86,0.33C459.24,836.74,455.22,836.57,451.06,836.47z"/>
				</g>
				<g>
					<path d="M697.53,551.3c-0.61,1.27-1.75,0.73-2.55,0.75c-0.83,1.24-1.58,2.39-2.36,3.53c-1.43,2.09-3.6,2.8-5.96,3.15
		c-0.36,0.05-0.71,0.11-1.07,0.16c-0.94,0.11-1.41,0.3-0.73,1.42c0.33,0.54,0.16,1.51-0.04,2.22c-0.13,0.48-0.63,0.94-1.08,1.24
		c-1.57,1.04-2.33,2.29-2.08,4.31c0.22,1.75-0.77,3.77-3.16,3.64c-0.28-0.02-0.65,0.52-0.87,0.86c-0.13,0.2-0.03,0.55-0.03,0.83
		c-0.01,2.22-0.57,2.77-2.86,2.8c-0.11,0-0.23,0.04-0.36,0.06c-0.14,0.71-0.25,1.42-0.42,2.11c-0.38,1.56-1.22,2.6-2.94,2.85
		c-1.45,0.21-1.98,0.76-1.79,2.19c0.43,3.2-1.05,5.54-3.08,7.71c-0.43,0.46-0.89,0.95-1.17,1.51c-0.19,0.37-0.24,0.96-0.08,1.33
		c0.66,1.57,0.45,2.98-0.85,4.19c-1.6,1.49-3.63,1.8-5.68,1.8c-0.47,0-1.12-0.63-1.36-1.12c-0.29-0.61-0.22-1.4-0.35-2.1
		c-0.19-1.01-0.22-2.11-0.68-2.99c-0.74-1.42-1.58-2.74-1.78-4.39c-0.07-0.57-0.71-1.12-1.19-1.57c-0.94-0.88-1.92-1.68-1.93-3.16
		c0-0.32-0.45-0.65-0.74-0.94c-0.22-0.22-0.62-0.31-0.74-0.55c-0.35-0.7-0.85-0.57-1.41-0.39c-0.49,0.16-0.99,0.34-1.5,0.43
		c-1.99,0.37-3.17-0.61-3.21-2.63c0-0.2,0-0.39,0-0.64c-1.1-0.2-1.76-0.02-1.91,1.27c-0.05,0.47-0.59,1.08-1.05,1.29
		c-2.3,1.01-4.74,1.47-7.24,1.24c-1.4-0.13-2.72-0.49-3.27-2.16c-0.17-0.51-0.95-0.97-1.55-1.16c-1.37-0.45-2.57-1.06-3.46-2.23
		c-0.17-0.23-0.6-0.33-0.92-0.35c-0.35-0.02-0.72,0.08-1.05,0.21c-2.58,0.96-5.25,1.2-8,1.16c-0.74-0.01-1.65,0.42-2.22,0.93
		c-1.07,0.97-2.2,1.56-3.65,1.48c-0.77-0.04-1.3,0.19-1.81,0.86c-0.37,0.48-1.18,0.8-1.83,0.86c-2.11,0.21-4.23,0.24-6.34,0.42
		c-0.49,0.04-0.94,0.44-1.42,0.63c-0.95,0.37-1.89,0.74-2.86,1.04c-0.18,0.06-0.6-0.15-0.69-0.35c-0.68-1.42-1.34-2.86-1.94-4.31
		c-0.41-0.98-0.8-1.97-1.07-3c-0.39-1.48-0.79-2.98-0.96-4.5c-0.12-1.03-0.41-1.55-1.46-1.83c-2.56-0.68-4.43-2.29-5.75-4.58
		c-0.24-0.42-0.46-0.85-0.75-1.22c-0.49-0.64-1.11-0.82-1.9-0.47c-3.72,1.66-4.59,1.12-4.84-2.97c0-0.07-0.05-0.15-0.1-0.27
		c-0.53,0-1.07,0-1.93,0c0,1.11-0.04,2.24,0.02,3.36c0.03,0.59,0.2,1.18,0.33,1.76c0.41,1.86,0.13,3.47-1.47,4.74
		c-0.35,0.28-0.57,0.86-0.64,1.33c-0.17,1.06-0.26,2.14-0.28,3.22c-0.01,0.53,0.23,1.06,0.37,1.59c0.23,0.9,0.66,1.79,0.67,2.69
		c0.02,1.62,0.07,3.2,0.91,4.64c0.46,0.79,0.43,1.54-0.2,2.2c-0.51,0.53-0.52,1.04-0.27,1.73c0.41,1.16,0.92,2.66-0.12,3.47
		c-0.67,0.53-2.12,0.35-3.14,0.12c-2.68-0.6-5.29-1.45-7.96-2.09c-2.22-0.53-3.92,0.24-4.92,2.37c-0.69,1.47-1.83,2.39-3.36,3.05
		c-1.16,0.5-2.19,1.43-3.12,2.33c-1.26,1.22-2.48,2.39-4.43,2.56c0.56,1.09,1.4,1.57,2.47,1.72c0.91,0.13,1.87,0.14,2.7,0.47
		c0.53,0.21,1.2,0.87,1.23,1.37c0.11,1.76,0.98,3.07,2.08,4.28c0.74,0.81,1.27,1.64,1.1,2.78c-0.18,1.25,0.44,1.93,1.54,2.36
		c2.15,0.85,4.32,1.68,6.41,2.67c0.72,0.34,1.25,1.12,1.84,1.73c0.24,0.25,0.35,0.65,0.62,0.85c1.62,1.2,3.25,2.39,4.91,3.54
		c2.19,1.51,2.83,3.9,1.72,6.3c-1.39,2.98-1.03,6.01,0.08,8.96c0.48,1.26,1.17,2.45,1.84,3.62c0.89,1.56,1.14,3.05-0.09,4.55
		c-0.17,0.2-0.37,0.52-0.32,0.74c0.37,1.83,0.49,3.78,1.26,5.43c1.13,2.41,2.7,4.6,5.11,6.04c2.14,1.29,2.94,3.26,2.5,5.75
		c-0.35,2-0.52,4.05-0.99,6.02c-0.32,1.33-0.94,2.63-1.65,3.81c-0.3,0.5-1.19,0.65-1.82,0.93c-0.75,0.33-1.63,0.49-2.24,0.99
		c-1.48,1.21-2.83,2.58-4.26,3.85c-0.44,0.39-0.98,0.68-1.47,1.01c-1.08,0.74-2.13,1.51-3.24,2.19c-0.41,0.25-0.95,0.4-1.44,0.43
		c-3.25,0.15-6.51,0.28-9.76,0.37c-1.17,0.03-2.35-0.07-3.53-0.09c-1.55-0.03-3.11-0.09-4.65-0.01c-0.28,0.01-0.76,0.6-0.75,0.92
		c0.03,1.58-0.61,2.77-1.79,3.75c-0.87,0.72-1.71,1.48-2.57,2.22c-0.16,0-0.32,0-0.48,0c-2.4-0.54-4.79-1.09-7.2-1.61
		c-1.21-0.26-2.44-0.41-3.64-0.72c-2.06-0.52-4.08-0.56-6.16-0.08c-2.53,0.58-5.07,1.09-7.62,1.54c-2.6,0.46-5.22,0.04-7.46-1.16
		c-1.75-0.93-3.2-2.55-4.59-4.03c-1.09-1.16-0.88-2.74-0.71-4.24c0.09-0.74,0.17-1.54-0.01-2.24c-0.41-1.6-0.86-3.15-0.23-4.81
		c0.12-0.32-0.13-0.83-0.3-1.21c-0.85-1.88-1.7-3.76-2.61-5.6c-0.23-0.47-0.66-0.88-1.09-1.2c-1.53-1.14-3.1-2.21-4.64-3.33
		c-1.32-0.96-2.59-1.99-3.93-2.91c-1.63-1.12-3.02-2.38-3.26-4.57c-0.25-0.03-0.47-0.05-0.69-0.08c-1.45-0.15-2.28-0.99-1.81-2.33
		c0.79-2.26,0.29-4.41-0.07-6.61c-0.06-0.39-0.18-0.82-0.09-1.19c0.32-1.23,0.39-2.24-1.07-2.9c-0.35-0.16-0.46-1.23-0.4-1.85
		c0.14-1.42-0.02-2.57-1.08-3.72c-0.6-0.65-0.54-1.9-0.77-2.88c-0.14-0.59-0.31-1.17-0.39-1.77c-0.09-0.65-0.24-1.35-0.1-1.96
		c0.19-0.8,0.64-1.53,1.03-2.41c-0.37-0.41-0.82-0.91-1.28-1.41c-1.19-1.32-1.13-2.27-0.46-3.9c0.36-0.88,0.08-2.05,0.03-3.08
		c-0.08-1.39-0.61-2.55-1.87-3.29c-1.06-0.63-1.55-1.6-1.5-2.81c0.05-1.41-0.48-2.41-1.73-3.16c-1.24-0.74-1.5-1.54-0.9-2.85
		c0.76-1.66,1.18-3.36,1.31-5.18c0.05-0.7,0.53-1.39,0.89-2.04c0.41-0.73,1.03-1.36,1.33-2.12c0.18-0.45,0.1-1.25-0.2-1.58
		c-0.93-1.02-0.93-2.1-0.73-3.35c0.23-1.44,0.19-2.91,0.42-4.35c0.12-0.77,0.36-1.71,0.88-2.2c1.25-1.17,1.88-2.42,1.54-4.14
		c-0.1-0.53-0.02-1.11-0.02-1.85c-0.69,0.44-1.3,0.79-1.86,1.21c-0.65,0.5-0.71,1.14-0.46,1.93c0.42,1.32,0.02,1.98-1.33,2.26
		c-0.93,0.2-1.91,0.17-2.84,0.39c-0.31,0.07-0.72,0.57-0.74,0.9c-0.11,1.85-1.06,2.87-2.94,2.92c-1.04,0.03-2.08-0.05-3.11-0.07
		c-1.47-0.02-2.51,0.66-3.15,1.98c-1.29,2.68-3.09,4.73-6.02,5.81c-1.29,0.48-2.3,1.68-3.5,2.45c-1.02,0.65-2.09,1.3-3.23,1.66
		c-1.49,0.47-2.85,0.95-3.76,2.34c-0.51,0.78-1.29,1.1-2.27,1.31c-3.51,0.76-7,1.63-10.43,2.67c-0.92,0.28-1.7,1.23-2.41,1.99
		c-1.65,1.77-3.15,3.7-4.86,5.4c-1.24,1.23-3.01,1.38-4.62,1.12c-0.73-0.12-1.67-0.98-1.9-1.71c-0.6-1.88-2.54-2.47-3.43-4.01
		c-0.07-0.12-0.27-0.22-0.41-0.22c-2.62-0.05-5.05-1.22-7.81-0.88c-3.71,0.47-7.49,0.27-11.23,0.51c-1.45,0.09-2.91,0.46-4.32,0.88
		c-2.47,0.74-4.97,1.05-7.55,1.12c-3.06,0.09-6.14,0.35-9.16,0.82c-1.53,0.24-3,1.01-4.41,1.71c-1.59,0.77-1.71,0.8-2.73-0.6
		c-0.19-0.26-0.57-0.39-0.86-0.58c-0.01-0.08-0.04-0.16-0.01-0.22c0.65-1.82,0.61-1.78-0.88-3.05c-1.61-1.37-3.27-2.72-4.67-4.29
		c-2.09-2.34-3.23-5.06-2.74-8.35c0.42-2.77-0.56-5.12-2.79-6.88c-2.45-1.94-3.44-4.35-3.28-7.54c0.09-1.87-0.61-3.85-1.31-5.64
		c-0.25-0.65-1.53-1.29-2.33-1.28c-3.75,0.07-6.74-2.43-7.02-6.43c-0.47-6.57-0.7-13.16-0.96-19.74c-0.06-1.61,0.84-2.89,1.99-3.96
		c3.99-3.69,7.99-7.37,12.01-11.03c2.57-2.34,2.8-6.93,0.44-9.47c-1.54-1.66-2.91-3.42-3.26-5.74c-0.1-0.63-0.27-1.34-0.07-1.9
		c0.78-2.15-0.38-4.04-0.69-6.03c-0.05-0.29-0.56-0.67-0.9-0.72c-4.54-0.7-9.07-1.4-13.62-1.99c-2.34-0.3-4.7-0.42-7.05-0.57
		c-1.16-0.08-2.13-0.42-3.06-1.17c-1.67-1.36-1.89-1.28-3.14,0.54c-0.05,0.07-0.09,0.13-0.14,0.2c-1.12,1.67-1.12,1.67-3.01,0.91
		c-1.87-0.75-3.35-2.01-4.56-3.59c-0.87-1.14-1.69-2.33-2.48-3.53c-0.96-1.46-2.25-2.07-4.02-2.07
		c-10.36,0.02-20.71-0.04-31.07-0.07c-2.68-0.01-5.36-0.03-8.04-0.03c-15.8,0-31.6,0-47.39,0c-0.47,0-0.94,0-1.55,0
		c0.28-2.03,0.53-3.92,0.8-5.8c0.48-3.36,1.01-6.72,1.46-10.08c0.22-1.64,0.75-2.95,2.69-3.27c-0.36-1.48-0.72-2.85-1.04-4.24
		c-0.63-2.69,0-5.32,0.33-7.97c0.59-4.71,1.11-9.44,1.63-14.16c0.26-2.31-0.57-4.29-1.9-6.14c-1.66-2.3-3.22-4.67-4.87-6.98
		c-1.98-2.79-3.02-5.7-1.49-9.08c0.3-0.65,0.03-1.18-0.7-1.53c-1.78-0.87-2.32-1.96-1.7-3.92c1.1-3.42,2.44-6.75,3.51-10.18
		c1.11-3.57,2.2-7.17,2.96-10.82c0.5-2.41-0.37-4.75-1.71-6.82c-0.7-1.07-1.1-2.1-0.33-3.32c0.48-0.77,0.15-1.38-0.59-1.78
		c-0.77-0.41-1.55-0.79-2.42-1.24c2.22-2.11,4.35-4.15,6.51-6.16c0.55-0.51,0.72-0.97,0.6-1.75c-0.71-4.5-3.16-8.15-5.74-11.71
		c-1.98-2.73-2.1-3.09-0.29-5.92c0.86-1.33,0.65-2.54-0.79-3.18c-1.44-0.64-3.02-0.98-4.53-1.45c0-3.44,0-6.88,0-10.32
		c2.46-0.68,4.91-1.41,7.39-2.03c0.91-0.23,1.26-0.75,1.07-1.52c-0.29-1.19-0.61-2.41-1.19-3.48c-1.57-2.93-1.91-5.86-0.54-8.96
		c1.68-3.8,3.24-7.64,4.91-11.45c0.33-0.75,0.32-1.26-0.18-1.94c-2.26-3.09-4.46-6.23-6.84-9.57c87.68-0.03,175.15,0.57,262.77,0.2
		c0.02,0.05,0.12,0.43,0.26,0.91c1.39-0.16,1.54,0.79,1.58,1.87c0.05,1.28-0.18,2.45-1,3.49c-0.44,0.56-0.86,1.17-1.18,1.8
		c-0.93,1.84-2.69,2.56-4.42,3.27c-0.56,0.23-1.32,0.01-1.98-0.09c-0.53-0.08-1.05-0.29-1.46-0.41c-0.19,1.39-0.3,2.72-0.58,4
		c-0.18,0.8-0.44,1.72-0.98,2.27c-1.43,1.45-1.41,3.15-1.17,4.92c0.24,1.71,0.41,3.4-0.15,5.09c-0.55,1.66-1.49,2.86-3.27,3.35
		c-1.46,0.4-1.51,0.56-0.93,1.89c0.53,1.23,0.1,2.16-0.97,3.02c0.53,0.28,0.92,0.54,1.35,0.7c1.59,0.6,2.37,1.76,2.25,3.43
		c-0.17,2.15,0.71,3.83,2.2,5.22c1.7,1.59,2.95,3.43,3.72,5.64c0.2,0.56,0.9,1.12,1.49,1.34c1.64,0.6,3.4,0.91,5.02,1.56
		c1.49,0.6,2.93,1.4,4.27,2.31c0.91,0.61,1.76,1.05,2.86,0.93c1.75-0.19,3.51-0.25,5.24-0.54c3.13-0.52,4.58,1.51,5.3,4.14
		c0.44,1.6,0.53,3.32,0.58,4.99c0.02,0.78-0.42,1.58-0.63,2.37c-0.25,0.93-0.75,1.9-0.65,2.8c0.28,2.35,0.94,4.62,2.47,6.53
		c0.87,1.08,1.2,2.31,1.2,3.68c0,1.56,0.02,3.12,0.17,4.66c0.05,0.48,0.47,1.07,0.89,1.34c1.77,1.11,2.1,1.82,1.41,3.76
		c-0.42,1.2-1.05,2.35-1.36,3.57c-0.36,1.41-0.44,2.89-0.7,4.33c-0.12,0.69-0.52,1.36-0.54,2.05c-0.04,1.13,0.17,2.26,0.2,3.39
		c0.02,0.55,0.05,1.24-0.24,1.63c-0.64,0.85-1.38,1.71-2.26,2.28c-3.53,2.31-5.07,5.64-5.57,9.72c-0.25,2.02-0.99,4-1.7,5.93
		c-0.8,2.17-0.32,4.34-0.38,6.52c-0.09,3.41,1.23,6.47,2.18,9.63c0.09,0.3,0.47,0.71,0.74,0.74c2.47,0.22,4.79-0.06,6.58-2.07
		c1.27-1.42,2.74-1.93,4.61-1.03c1.96,0.94,3.92,0.84,5.84-0.31c1.09-0.65,2.26-1.19,3.42-1.72c2.9-1.33,5.33,0.1,5.63,3.44
		c0.53-0.06,1.08-0.07,1.6-0.2c4.88-1.22,9.76-2.45,14.63-3.71c4.12-1.07,8.32-1.57,12.57-1.62c0.89-0.01,1.84,0.21,2.66,0.57
		c2.05,0.91,2.47,2.85,1.11,4.63c-1.09,1.42-1.1,1.53-0.02,2.97c0.62,0.83,1.36,1.58,1.93,2.45c0.56,0.86,0.98,1.82,1.41,2.63
		c0.8-0.68,1.62-1.54,2.59-2.15c1.84-1.17,3.9-1.39,6-0.86c2.7,0.67,5.32,0.51,7.86-0.58c4.55-1.95,9.37-2.22,14.22-2.33
		c0.72-0.02,1.44-0.06,2.16-0.08c0.72-0.02,1.48,0.04,1.69-0.92c0.01-0.07,0.09-0.14,0.15-0.18c2.48-1.41,4.98-2.79,7.45-4.23
		c2.62-1.53,5.46-1.29,8.29-1.07c3.22,0.25,6.43,0.6,9.65,0.89c2.46,0.22,4.4,1.27,5.74,3.41c0.18,0.29,0.57,0.5,0.91,0.63
		c0.32,0.13,0.71,0.11,1.06,0.17c2.1,0.38,2.41,0.79,2.19,2.55c1.01,0.76,2.03,1.32,2.76,2.13c0.66,0.72,0.11,1.7-0.86,1.82
		c-1.25,0.15-2.58,1.33-2.57,2.55c0.01,1.77-0.84,2.75-2.43,3.25c-1.13,0.36-1.54,1.17-1.48,2.3c0.03,0.64-0.03,1.29,0.06,1.92
		c0.26,1.73-0.08,3.24-1.33,4.52c-0.81,0.83-0.85,1.69-0.38,2.83c0.38,0.92,0.32,2.1,0.22,3.14c-0.09,0.9-0.07,1.54,0.84,1.98
		c0.38,0.18,0.71,0.52,0.99,0.85c1.64,1.96,3.25,3.94,4.87,5.92c1.11,1.35,1.91,2.76,1.83,4.66c-0.03,0.76,0.67,1.7,1.29,2.31
		c0.97,0.96,1.24,1.94,0.94,3.24c-0.25,1.08-0.29,2.21-0.46,3.32c-0.37,2.4-2.26,4.13-4.44,4.09c-0.88-0.02-1.58-0.33-1.87-1.21
		c-0.35-1.04-1.26-1.37-2.12-1.47c-1.25-0.14-1.73-0.74-1.62-1.88c0.09-0.95-0.25-1.28-1.2-1.46c-1.34-0.25-2.63-0.77-3.94-1.19
		c-0.18-0.06-0.39-0.21-0.45-0.36c-0.49-1.28-1.15-1.08-2.19-0.52c-0.66,0.36-1.64,0.14-2.47,0.18c-0.86,2.38-1.85,2.77-4.31,1.64
		c-0.73-0.34-1.28-0.53-2.02,0.13c-0.94,0.83-1.94,0.16-2.89-0.28c-0.6-0.28-1.23-0.48-1.85-0.71c-0.06,0.11-0.13,0.22-0.19,0.34
		c0.07,0.21,0.1,0.46,0.23,0.61c1.06,1.17,1.3,2.57,1.38,4.1c0.04,0.81,0.47,1.69,0.95,2.37c1.66,2.36,1.57,5.17-0.27,7.37
		c-0.79,0.95-1.47,1.99-2.13,3.04c-0.62,0.99-0.66,1.98,0.02,3.03c1.47,2.28,1.52,4.78-1.99,5.68c-0.27,0.07-0.62,0.33-0.68,0.57
		c-0.51,1.99-2.08,2.34-3.77,2.54c-0.2,0.02-0.39,0.05-0.59,0.08c0,0.11,0,0.22-0.01,0.32c0.75,0,1.5,0.03,2.25-0.01
		c1.14-0.07,2.3-0.36,3.42-0.22c0.76,0.09,1.51,0.66,2.17,1.15c1.15,0.86,2.3,1.26,3.79,0.9c0.77-0.19,1.68,0.2,2.53,0.26
		c0.62,0.04,1.28,0.07,1.88-0.07c1.27-0.3,2.48-0.91,3.76-1.05c1.57-0.17,2.38,0.82,2.19,2.39c-0.09,0.7-0.29,1.42-0.58,2.06
		c-0.8,1.79-0.84,3.64-0.64,5.53c0.19,1.85,0.96,2.26,2.51,1.29c1.32-0.83,2.53-0.95,3.91-0.08c0.45,0.28,1.29,0.31,1.79,0.09
		c1.92-0.86,3.88-0.85,5.88-0.62c5.48,0.62,10.97,1.11,16.42,1.92c6.86,1.02,13.49,2.88,19.64,6.24c3.29,1.8,6.64,3.56,10.37,4.26
		c1.35,0.26,2.77,0.22,4.16,0.22c1.32-0.01,2.64-0.27,3.95-0.2c2.51,0.13,4.96-0.07,7.26-1.14c0.84-0.39,1.58-1.01,2.43-1.35
		c0.5-0.2,1.28-0.28,1.68-0.03c0.7,0.44,1.2,1.19,1.79,1.81C697.53,550.98,697.53,551.14,697.53,551.3z M505.04,401.25
		c-0.32-0.21-0.56-0.42-0.85-0.55c-1.27-0.57-1.88-1.49-1.85-2.92c0.03-1.59-0.04-3.19-0.16-4.78c-0.06-0.73-0.13-1.62-0.57-2.14
		c-1.93-2.32-2.6-5.09-3.15-7.92c-0.28-1.44-0.05-2.75,0.81-3.99c0.34-0.49,0.58-1.18,0.55-1.76c-0.08-1.43-0.3-2.86-0.55-4.28
		c-0.32-1.82-1.37-2.55-3.2-2.38c-1.79,0.17-3.58,0.33-5.37,0.52c-1.34,0.14-2.61-0.17-3.59-1.05c-1.79-1.61-3.91-2.42-6.19-2.96
		c-0.97-0.23-1.91-0.57-2.87-0.85c-1.54-0.44-2.56-1.4-3.05-2.94c-0.53-1.66-1.43-3.06-2.75-4.27c-1.75-1.6-3.02-3.59-3.02-6.05
		c0-1.56-0.7-2.34-2.03-2.98c-2.29-1.1-2.44-2.35-0.66-4.11c0.05-0.04,0.03-0.15,0.05-0.22c-1.16-1.61-0.76-3.36,1-4.3
		c0.31-0.17,0.65-0.33,0.99-0.42c1.61-0.4,2.3-1.54,2.35-3.07c0.04-1.23-0.15-2.47-0.12-3.71c0.04-1.73-0.3-3.56,0.98-5.03
		c1.47-1.69,1.64-3.74,1.49-5.8c-0.13-1.81,0.82-2.78,2.57-2.31c0.46,0.12,0.9,0.41,1.36,0.45c0.64,0.06,1.31,0.07,1.93-0.09
		c1.94-0.49,2.72-2.22,3.74-3.68c0.74-1.05,0.91-2.18,0.58-3.29c-86.1,0.33-172.14-0.18-258.37-0.21c0.31,0.48,0.5,0.81,0.72,1.13
		c1.32,1.86,2.56,3.78,4,5.54c0.95,1.16,0.93,2.1,0.34,3.42c-1.77,3.89-3.34,7.86-5.06,11.77c-1.1,2.49-0.76,4.77,0.47,7.11
		c0.66,1.25,1.12,2.65,1.4,4.04c0.42,2.1-0.43,3.28-2.47,3.84c-1.69,0.46-3.39,0.94-5.09,1.37c-0.6,0.15-0.86,0.43-0.84,1.07
		c0.05,1.24,0.01,2.48,0.01,3.72c0,2.56,0,2.56,2.43,3.47c0.15,0.06,0.3,0.11,0.45,0.17c3.01,1.34,3.78,4.12,1.8,6.75
		c-0.76,1.01-0.71,1.71,0.06,2.72c3.26,4.29,6.14,8.79,6.71,14.34c0.05,0.46-0.14,1.09-0.46,1.41c-1.72,1.72-3.51,3.37-5.28,5.05
		c1.88,1.72,1.98,2.16,1.28,4.65c-0.1,0.34,0.07,0.84,0.27,1.17c1.56,2.53,2.18,5.26,2.04,8.23c-0.18,3.97-2.18,7.41-3.15,11.15
		c-0.88,3.39-2.27,6.64-3.44,9.95c-0.29,0.83-0.19,1.42,0.79,1.77c1.45,0.52,2.28,2.45,1.59,3.87c-1.13,2.32-0.72,4.42,0.66,6.44
		c1.69,2.48,3.31,5,5.12,7.39c2.05,2.7,2.92,5.66,2.52,8.99c-0.67,5.51-1.42,11.01-2.07,16.53c-0.14,1.17-0.15,2.41,0.06,3.57
		c0.38,2.03,0.97,4.02,1.49,6.1c-0.61,0.28-1.17,0.61-1.77,0.79c-0.78,0.24-1.16,0.66-1.26,1.5c-0.18,1.59-0.51,3.15-0.74,4.73
		c-0.43,2.99-0.83,5.99-1.27,9.11c0.6,0,1.07,0,1.55,0c24.64,0.01,49.28,0.03,73.92,0.04c3.48,0,6.96,0.04,10.44,0
		c2.2-0.02,3.97,0.78,5.26,2.55c0.66,0.9,1.16,1.94,1.91,2.76c1.2,1.31,2.52,2.53,3.84,3.72c0.58,0.52,1.12,0.43,1.65-0.33
		c1.82-2.58,3.11-2.72,5.68-0.84c0.46,0.34,1.01,0.71,1.55,0.78c1.74,0.23,3.5,0.35,5.25,0.47c5.91,0.4,11.77,1.17,17.54,2.48
		c0.3,0.07,0.77,0.34,0.79,0.55c0.17,2.6,1.98,4.89,1.16,7.74c-0.71,2.49,0.79,4.55,2.51,6.15c2.47,2.3,2.59,5.07,2.12,8.06
		c-0.26,1.66-0.89,3.12-2.18,4.29c-4.14,3.77-8.29,7.52-12.34,11.38c-0.71,0.67-1.33,1.87-1.3,2.81c0.18,6.31,0.47,12.61,0.88,18.91
		c0.21,3.21,2.07,4.72,5.29,4.81c1.72,0.05,3.2,0.77,3.94,2.31c1.04,2.17,1.78,4.49,1.52,6.94c-0.25,2.29,0.52,4.07,2.3,5.46
		c3.14,2.45,4.17,5.7,3.7,9.57c-0.1,0.84-0.07,1.84,0.3,2.57c0.75,1.52,1.56,3.1,2.72,4.3c1.94,1.99,4.18,3.7,6.33,5.56
		c-0.18,0.52-0.38,1.07-0.58,1.66c1.24-0.5,2.37-1.06,3.57-1.41c3.44-1,6.97-1.43,10.55-1.2c2.27,0.14,4.44-0.2,6.62-0.82
		c1.94-0.56,3.95-1.06,5.96-1.21c3.49-0.26,7.02-0.12,10.51-0.38c2.1-0.16,4.09-0.07,6.16,0.4c2.06,0.47,4.19,0.52,5.53,2.54
		c0.55,0.83,1.44,1.47,1.89,2.34c0.83,1.58,1.47,1.93,3.14,1.24c0.4-0.17,0.82-0.39,1.1-0.71c1.37-1.55,2.78-3.07,4-4.73
		c1.25-1.7,2.72-2.77,4.83-3.21c3.27-0.68,6.5-1.6,9.73-2.45c0.35-0.09,0.76-0.36,0.93-0.66c0.89-1.51,2.29-2.21,3.92-2.47
		c1.58-0.25,2.77-1.07,3.95-2.09c1.07-0.93,2.25-1.86,3.55-2.36c2.2-0.84,3.52-2.39,4.43-4.41c0.87-1.96,2.32-3.14,4.45-3.35
		c1.19-0.12,2.39-0.04,3.59-0.07c0.5-0.01,1.09,0.13,1.08-0.72c-0.04-1.96,0.6-2.71,2.52-3c0.82-0.12,1.65-0.2,2.57-0.31
		c-0.73-1.61-0.18-2.91,1.1-3.71c1.64-1.03,3.42-1.85,5.19-2.63c0.39-0.17,1.28,0.01,1.45,0.31c0.22,0.38,0.09,1.13-0.16,1.57
		c-0.66,1.17-1.15,2.25-1,3.71c0.17,1.71-0.46,3.33-1.81,4.5c-0.59,0.51-0.7,1.02-0.71,1.76c-0.01,1.33-0.26,2.66-0.31,3.99
		c-0.02,0.57,0.01,1.29,0.33,1.7c0.82,1.07,1.03,2.18,0.53,3.37c-0.39,0.91-0.98,1.73-1.46,2.6c-0.32,0.59-0.72,1.18-0.86,1.81
		c-0.42,1.84-0.74,3.71-1.11,5.65c2.11,1.5,2.32,1.93,2.65,4.79c0.06,0.48,0.4,1.08,0.79,1.34c1.4,0.9,2.02,2.22,2.32,3.76
		c0.24,1.23,0.5,2.46,0.62,3.7c0.04,0.45-0.17,1.1-0.5,1.38c-0.84,0.71-0.47,1.21,0.09,1.76c0.26,0.25,0.54,0.47,0.82,0.71
		c1.67,1.4,1.7,1.78,0.45,3.58c-0.31,0.44-0.67,0.98-0.66,1.47c0.01,1.09,0.2,2.18,0.39,3.26c0.09,0.53,0.2,1.17,0.54,1.52
		c1.28,1.33,1.59,2.88,1.42,4.64c-0.03,0.29,0.24,0.74,0.5,0.9c1.27,0.76,1.46,1.29,1.27,2.74c-0.16,1.2-0.12,2.44-0.07,3.66
		c0.07,1.84,0.22,3.67,0.34,5.56c0.81-0.04,1.83,0.24,1.94,1.47c0.17,1.87,1.33,2.87,2.76,3.8c1.33,0.87,2.55,1.91,3.83,2.86
		c1.37,1.02,2.72,2.07,4.14,3c0.95,0.63,1.78,1.26,2.24,2.38c0.75,1.83,1.61,3.63,2.52,5.39c0.47,0.9,0.64,1.72,0.43,2.74
		c-0.19,0.89-0.05,1.86-0.01,2.8c0.06,1.22,0.18,2.43,0.26,3.64c0.06,0.94-0.24,2.15,0.24,2.77c1.09,1.41,2.38,2.92,3.93,3.66
		c1.76,0.84,3.83,1.39,5.93,0.96c2.66-0.54,5.31-1.14,7.99-1.54c1.68-0.25,3.44-0.45,5.1-0.2c3.78,0.58,7.52,1.46,11.28,2.2
		c0.38,0.07,0.99,0.2,1.13,0.01c0.94-1.2,2.57-1.97,2.32-3.96c-0.17-1.37,0.43-2.11,1.79-2.42c1.07-0.25,2.21-0.33,3.32-0.29
		c1.62,0.06,3.32,0.69,4.84,0.38c2.72-0.57,5.37-0.59,8.09-0.34c1.62,0.15,3.15-0.1,4.38-1.4c0.41-0.44,1.09-0.63,1.64-0.95
		c0.58-0.34,1.2-0.64,1.7-1.08c1.47-1.28,2.82-2.71,4.36-3.9c0.77-0.6,1.86-0.79,2.8-1.17c0.29-0.12,0.71-0.18,0.82-0.39
		c0.47-0.96,1.02-1.92,1.23-2.95c0.38-1.83,0.5-3.71,0.82-5.56c0.35-2.03,0.09-2.65-1.55-3.87c-1.08-0.8-2.1-1.71-3.04-2.68
		c-2.78-2.89-4.17-6.4-4.29-10.38c-0.02-0.68,0.29-1.49,0.7-2.05c0.54-0.74,0.57-1.32,0.13-2.07c-0.62-1.07-1.25-2.15-1.71-3.29
		c-1.55-3.85-2.24-7.74-0.34-11.71c0.47-0.97,0.46-2.06-0.39-2.77c-1.57-1.32-3.27-2.48-4.89-3.73c-0.67-0.52-1.29-1.12-1.92-1.7
		c-0.44-0.4-0.78-0.98-1.29-1.22c-2.27-1.08-4.59-2.05-6.88-3.09c-1.19-0.54-2.05-1.48-1.85-2.8c0.19-1.26-0.41-1.99-1.06-2.87
		c-0.82-1.11-1.72-2.28-2.1-3.56c-0.29-0.97-0.51-1.3-1.5-1.31c-2.65-0.02-5.06-1.94-5.65-4.52c-0.14-0.63-0.07-1.61,0.32-1.96
		c0.39-0.35,1.43-0.35,1.97-0.07c0.99,0.51,1.69,0.23,2.38-0.39c0.62-0.56,1.14-1.24,1.78-1.78c0.85-0.71,1.69-1.61,2.69-1.93
		c1.65-0.53,2.63-1.49,3.31-3.01c0.77-1.71,2.17-2.64,4.06-2.71c2.99-0.12,5.88,0.42,8.66,1.51c0.9,0.35,1.81,0.67,2.88,1.06
		c-0.07-0.53-0.05-0.78-0.14-0.99c-0.58-1.28-0.8-2.5,0.21-3.7c0.12-0.14-0.02-0.5-0.07-0.76c-0.26-1.48-0.5-2.96-0.79-4.43
		c-0.17-0.87-0.42-1.73-0.6-2.61c-0.17-0.81-0.42-1.63-0.42-2.44c0-1.19,0.07-2.41,0.32-3.57c0.16-0.77,0.5-1.71,1.07-2.15
		c1.13-0.87,1.19-1.86,1.01-3.08c-0.2-1.34-0.51-2.71-0.41-4.04c0.1-1.23,0.59-2.45,1.09-3.6c0.3-0.69,1.13-1.15,1.79-0.54
		c0.67,0.62,1.22,0.36,1.83,0.17c1.77-0.53,2.27-0.05,1.92,1.79c-0.16,0.85-0.16,1.74-0.25,2.85c0.74-0.34,1.14-0.52,1.53-0.7
		c2.13-1,3.75-0.57,5.08,1.38c0.38,0.56,0.7,1.17,1.06,1.74c0.89,1.39,2.03,2.45,3.67,2.9c2.97,0.82,3.3,1.32,3.4,4.44
		c0.03,0.89,0.14,1.9,0.58,2.63c0.85,1.41,1.26,2.9,1.52,4.49c0.13,0.8,0.63,1.53,0.84,2c1.36-0.52,2.39-1.14,3.48-1.28
		c2.01-0.27,4.06-0.25,6.09-0.38c0.32-0.02,0.8-0.16,0.92-0.39c0.63-1.2,1.72-1.37,2.86-1.34c1.16,0.03,2.01-0.49,2.91-1.16
		c0.79-0.59,1.82-1.16,2.76-1.2c3.04-0.13,6.03-0.39,8.94-1.32c0.23-0.07,0.46-0.12,0.7-0.17c0.94-0.19,1.65,0.12,2.38,0.78
		c0.83,0.75,1.86,1.28,2.78,1.94c0.95,0.68,2.1,1.25,2.73,2.17c0.58,0.84,1.14,1.19,2.02,1.09c1.83-0.22,3.66-0.52,5.38-0.77
		c0.47-2.25,1.65-3.11,3.86-3.22c2.57-0.12,2.59,1.66,2.5,3.63c0.83-0.2,1.48-0.35,2.12-0.53c1.22-0.34,2.36-0.4,3.26,0.74
		c0.21,0.27,0.63,0.38,0.85,0.64c0.43,0.54,1.03,1.1,1.13,1.73c0.18,1.04,0.69,1.74,1.44,2.38c0.6,0.52,1.5,1.07,1.6,1.7
		c0.32,2.12,1.35,3.87,2.32,5.69c0.2,0.37,0.22,0.85,0.27,1.28c0.11,0.97,0.18,1.94,0.29,3.14c1.22-0.43,2.35-0.7,3.35-1.22
		c0.57-0.3,1.25-0.92,0.65-1.77c-0.64-0.91-0.57-1.87-0.08-2.72c0.73-1.27,1.52-2.54,2.49-3.64c1.3-1.47,1.92-3.02,1.75-5.01
		c-0.21-2.45,0.78-4.04,2.96-4.37c1.67-0.25,1.82-1.28,1.96-2.47c0.33-2.76,0.31-2.77,3.11-2.8c0.07,0,0.14-0.04,0.3-0.09
		c0-0.33,0-0.68,0-1.04c-0.03-2.19,0.8-3.08,2.96-3.36c0.39-0.05,0.85-0.51,1.03-0.9c0.18-0.4,0.1-0.95,0.03-1.42
		c-0.25-1.72,0.45-3.03,1.71-4.11c0.6-0.51,1.21-1.01,1.79-1.49c-1.55-2.84-0.81-4.29,2.34-4.81c0.43-0.07,0.87-0.14,1.3-0.2
		c2.45-0.32,4.13-1.69,5.24-3.97c-3.09,1.75-6.44,0.81-9.66,1.14c-0.28,0.03-0.56,0.11-0.83,0.1c-1.75-0.01-3.53,0.16-5.24-0.1
		c-3.8-0.59-7.17-2.44-10.52-4.21c-5.59-2.96-11.49-5.06-17.71-6.06c-5.56-0.89-11.18-1.33-16.77-2.02
		c-2.49-0.31-4.9-0.42-7.31,0.53c-0.65,0.26-1.69,0.16-2.27-0.22c-0.98-0.63-1.68-0.51-2.61,0.02c-2.54,1.45-5,0.24-5.33-2.66
		c-0.27-2.35-0.28-4.71,0.73-6.95c0.22-0.48,0.31-1.01,0.57-1.88c-1.21,0.48-2.12,0.75-2.95,1.18c-1.25,0.64-2.44,0.91-3.74,0.1
		c-0.36-0.22-0.95-0.28-1.37-0.17c-2.15,0.6-3.93-0.13-5.54-1.46c-0.81-0.68-1.69-0.84-2.69-0.61c-0.7,0.16-1.39,0.38-2.1,0.46
		c-1.28,0.15-2.49-0.1-3.42-1.06c-1.34-1.37-0.85-3.1,0.99-3.58c0.58-0.15,1.21-0.1,1.77-0.29c0.48-0.17,1.15-0.47,1.28-0.86
		c0.44-1.36,1.28-2.02,2.66-2.23c0.85-0.13,1.26-0.66,0.8-1.56c-0.18-0.35-0.29-0.75-0.52-1.08c-1.08-1.58-0.89-3.23-0.09-4.78
		c0.63-1.23,1.46-2.4,2.35-3.47c1.39-1.68,1.42-3.14,0.24-4.97c-0.66-1.03-1.15-2.28-1.33-3.49c-0.17-1.14-0.39-2.13-1.08-3.09
		c-1.27-1.77-0.27-3.85,1.93-3.91c0.85-0.02,1.73,0.43,2.55,0.78c0.61,0.26,1.02,0.49,1.71-0.04c0.49-0.38,1.39-0.41,2.08-0.32
		c0.72,0.09,1.39,0.51,2.11,0.79c0.34-1.89,0.82-2.24,2.66-2.25c0.81-0.01,1.64-0.18,2.43-0.4c1.13-0.3,2.11-0.28,3.17,0.42
		c1.33,0.86,2.77,1.68,4.28,2.1c2.02,0.56,2.41,0.77,2.51,2.91c1.04,1,2.83,1.05,3.37,2.71c0.03,0.1,0.74,0.12,1.01-0.03
		c1.12-0.64,1.47-1.72,1.53-2.94c0.04-0.8,0.03-1.61,0.21-2.38c0.16-0.69-0.02-1.09-0.54-1.54c-1.05-0.92-1.86-2.07-1.72-3.53
		c0.12-1.24-0.33-2.18-1.04-3.08c-0.71-0.91-1.47-1.8-2.22-2.67c-1.17-1.36-2.19-2.9-3.57-3.99c-1.32-1.04-1.8-2.07-1.56-3.68
		c0.13-0.87,0.12-1.92-0.26-2.66c-0.83-1.59-0.63-3,0.45-4.22c1.13-1.29,1.43-2.7,1.21-4.32c-0.05-0.35-0.03-0.72-0.03-1.08
		c-0.01-2.91,0.29-3.39,2.95-4.5c0.67-0.28,1.06-0.66,1.01-1.42c-0.09-1.43,0.38-2.59,1.71-3.29c0.24-0.13,0.48-0.28,0.81-0.48
		c-2.02-0.98-2.02-0.98-2.32-2.97c-1.65-0.14-2.97-0.75-3.89-2.33c-0.7-1.2-2.01-1.71-3.47-1.83c-3.22-0.27-6.42-0.73-9.64-0.93
		c-1.89-0.12-3.92-0.26-5.67,0.31c-2.16,0.69-4.12,2.05-6.1,3.23c-0.71,0.43-1.12,1.42-1.84,1.74c-1.21,0.53-2.56,0.97-3.87,1.03
		c-5.96,0.26-11.89,0.48-17.49,2.95c-1.9,0.84-3.95,0.76-6.01,0.31c-1.23-0.27-2.54-0.44-3.78-0.34c-2.24,0.18-3.14,1.12-3.65,3.29
		c-0.09,0.39-0.21,0.78-0.38,1.13c-0.44,0.94-1.57,1.51-2.47,1.25c-1.05-0.3-1.29-1.12-1.17-2.08c0.22-1.71-0.45-3.13-1.45-4.43
		c-0.49-0.63-1.04-1.22-1.55-1.83c-1.59-1.89-2.04-3.75-0.09-5.73c0.6-0.61,0.42-1.16-0.29-1.38c-0.78-0.24-1.64-0.37-2.45-0.35
		c-6,0.17-11.77,1.59-17.51,3.24c-2.99,0.86-6.04,1.54-9.08,2.21c-2.25,0.5-3.22-0.2-3.58-2.35c-0.2-1.2-1.04-1.75-2.17-1.31
		c-0.78,0.3-1.51,0.73-2.23,1.16c-3.05,1.83-6.17,2.52-9.57,0.91c-0.39-0.18-1.17,0.19-1.64,0.5c-1.03,0.67-1.91,1.58-2.97,2.17
		c-1.98,1.1-4.2,0.83-6.34,0.66c-0.55-0.04-1.29-0.86-1.49-1.47c-1.17-3.46-2.66-6.86-2.57-10.64c0.06-2.5-0.65-5.09,0.49-7.46
		c1.08-2.25,1.55-4.59,1.87-7.02c0.49-3.72,2.03-6.87,5.2-9.08c0.69-0.48,1.32-1.04,1.96-1.58c0.5-0.43,0.76-0.85,0.56-1.64
		c-0.41-1.63-0.6-3.28,0.28-4.9c0.29-0.52,0.23-1.24,0.33-1.86c0.18-1.13,0.24-2.3,0.56-3.38
		C503.83,404.06,504.47,402.69,505.04,401.25z"/>
					<path class="juina" d="M505.04,401.25c-0.57,1.44-1.21,2.82-1.64,4.25c-0.33,1.09-0.38,2.25-0.56,3.38
		c-0.1,0.63-0.04,1.34-0.33,1.86c-0.88,1.61-0.69,3.27-0.28,4.9c0.2,0.79-0.06,1.21-0.56,1.64c-0.64,0.55-1.27,1.1-1.96,1.58
		c-3.17,2.21-4.7,5.37-5.2,9.08c-0.32,2.43-0.79,4.77-1.87,7.02c-1.14,2.37-0.43,4.96-0.49,7.46c-0.09,3.78,1.4,7.18,2.57,10.64
		c0.21,0.61,0.95,1.43,1.49,1.47c2.13,0.17,4.35,0.44,6.34-0.66c1.06-0.59,1.94-1.51,2.97-2.17c0.47-0.31,1.25-0.68,1.64-0.5
		c3.4,1.6,6.52,0.92,9.57-0.91c0.72-0.43,1.45-0.86,2.23-1.16c1.13-0.44,1.97,0.11,2.17,1.31c0.36,2.16,1.33,2.85,3.58,2.35
		c3.04-0.67,6.08-1.36,9.08-2.21c5.74-1.64,11.51-3.07,17.51-3.24c0.82-0.02,1.67,0.11,2.45,0.35c0.7,0.22,0.88,0.77,0.29,1.38
		c-1.95,1.98-1.5,3.85,0.09,5.73c0.51,0.61,1.06,1.2,1.55,1.83c1.01,1.3,1.67,2.71,1.45,4.43c-0.12,0.96,0.11,1.77,1.17,2.08
		c0.9,0.26,2.03-0.31,2.47-1.25c0.17-0.36,0.29-0.75,0.38-1.13c0.51-2.17,1.41-3.11,3.65-3.29c1.25-0.1,2.55,0.07,3.78,0.34
		c2.05,0.45,4.11,0.53,6.01-0.31c5.6-2.47,11.53-2.69,17.49-2.95c1.31-0.06,2.66-0.5,3.87-1.03c0.73-0.32,1.13-1.31,1.84-1.74
		c1.98-1.18,3.94-2.54,6.1-3.23c1.75-0.56,3.78-0.42,5.67-0.31c3.22,0.2,6.43,0.66,9.64,0.93c1.46,0.12,2.77,0.63,3.47,1.83
		c0.92,1.58,2.24,2.18,3.89,2.33c0.3,1.99,0.3,1.99,2.32,2.97c-0.33,0.2-0.56,0.35-0.81,0.48c-1.33,0.7-1.8,1.86-1.71,3.29
		c0.05,0.76-0.34,1.14-1.01,1.42c-2.66,1.11-2.96,1.58-2.95,4.5c0,0.36-0.02,0.72,0.03,1.08c0.23,1.62-0.08,3.02-1.21,4.32
		c-1.07,1.23-1.27,2.63-0.45,4.22c0.38,0.74,0.39,1.79,0.26,2.66c-0.24,1.61,0.24,2.64,1.56,3.68c1.38,1.09,2.4,2.63,3.57,3.99
		c0.76,0.88,1.51,1.76,2.22,2.67c0.7,0.9,1.15,1.84,1.04,3.08c-0.14,1.45,0.67,2.61,1.72,3.53c0.52,0.45,0.69,0.85,0.54,1.54
		c-0.18,0.77-0.17,1.58-0.21,2.38c-0.06,1.22-0.41,2.3-1.53,2.94c-0.27,0.15-0.97,0.13-1.01,0.03c-0.54-1.66-2.32-1.71-3.37-2.71
		c-0.1-2.14-0.49-2.35-2.51-2.91c-1.51-0.42-2.95-1.23-4.28-2.1c-1.07-0.69-2.04-0.72-3.17-0.42c-0.79,0.21-1.62,0.39-2.43,0.4
		c-1.84,0.02-2.33,0.36-2.66,2.25c-0.71-0.28-1.39-0.7-2.11-0.79c-0.68-0.09-1.58-0.06-2.08,0.32c-0.69,0.53-1.09,0.3-1.71,0.04
		c-0.82-0.35-1.71-0.8-2.55-0.78c-2.2,0.06-3.2,2.14-1.93,3.91c0.69,0.95,0.91,1.95,1.08,3.09c0.18,1.21,0.67,2.46,1.33,3.49
		c1.17,1.83,1.15,3.29-0.24,4.97c-0.89,1.07-1.71,2.23-2.35,3.47c-0.8,1.55-0.99,3.2,0.09,4.78c0.22,0.32,0.34,0.72,0.52,1.08
		c0.46,0.9,0.05,1.43-0.8,1.56c-1.38,0.21-2.22,0.87-2.66,2.23c-0.13,0.39-0.8,0.69-1.28,0.86c-0.55,0.19-1.19,0.14-1.77,0.29
		c-1.85,0.48-2.33,2.22-0.99,3.58c0.94,0.96,2.14,1.22,3.42,1.06c0.71-0.08,1.4-0.3,2.1-0.46c0.99-0.22,1.87-0.06,2.69,0.61
		c1.61,1.33,3.39,2.06,5.54,1.46c0.42-0.12,1.01-0.06,1.37,0.17c1.3,0.81,2.48,0.55,3.74-0.1c0.83-0.43,1.74-0.7,2.95-1.18
		c-0.26,0.87-0.35,1.4-0.57,1.88c-1.01,2.24-1.01,4.6-0.73,6.95c0.34,2.9,2.79,4.11,5.33,2.66c0.93-0.53,1.63-0.65,2.61-0.02
		c0.58,0.37,1.62,0.47,2.27,0.22c2.41-0.95,4.83-0.84,7.31-0.53c5.59,0.7,11.22,1.13,16.77,2.02c6.22,1,12.12,3.1,17.71,6.06
		c3.35,1.77,6.71,3.62,10.52,4.21c1.71,0.27,3.49,0.09,5.24,0.1c0.28,0,0.55-0.08,0.83-0.1c3.22-0.32,6.58,0.61,9.66-1.14
		c-1.12,2.27-2.79,3.65-5.24,3.97c-0.44,0.06-0.87,0.13-1.3,0.2c-3.15,0.52-3.88,1.97-2.34,4.81c-0.59,0.49-1.2,0.98-1.79,1.49
		c-1.26,1.08-1.96,2.39-1.71,4.11c0.07,0.47,0.15,1.02-0.03,1.42c-0.17,0.39-0.64,0.85-1.03,0.9c-2.16,0.29-2.99,1.17-2.96,3.36
		c0.01,0.35,0,0.71,0,1.04c-0.15,0.05-0.23,0.09-0.3,0.09c-2.8,0.04-2.78,0.04-3.11,2.8c-0.14,1.18-0.3,2.22-1.96,2.47
		c-2.18,0.33-3.17,1.92-2.96,4.37c0.17,1.99-0.45,3.54-1.75,5.01c-0.97,1.09-1.76,2.36-2.49,3.64c-0.49,0.85-0.56,1.81,0.08,2.72
		c0.6,0.84-0.08,1.47-0.65,1.77c-1,0.52-2.13,0.79-3.35,1.22c-0.11-1.19-0.18-2.17-0.29-3.14c-0.05-0.43-0.07-0.91-0.27-1.28
		c-0.97-1.82-2-3.57-2.32-5.69c-0.1-0.64-1-1.19-1.6-1.7c-0.75-0.65-1.26-1.34-1.44-2.38c-0.1-0.62-0.7-1.19-1.13-1.73
		c-0.22-0.27-0.64-0.37-0.85-0.64c-0.9-1.15-2.03-1.09-3.26-0.74c-0.64,0.18-1.29,0.32-2.12,0.53c0.08-1.97,0.07-3.75-2.5-3.63
		c-2.21,0.1-3.39,0.96-3.86,3.22c-1.72,0.25-3.54,0.56-5.38,0.77c-0.88,0.1-1.44-0.25-2.02-1.09c-0.63-0.92-1.78-1.48-2.73-2.17
		c-0.92-0.66-1.95-1.19-2.78-1.94c-0.72-0.66-1.44-0.97-2.38-0.78c-0.23,0.05-0.47,0.1-0.7,0.17c-2.91,0.92-5.91,1.18-8.94,1.32
		c-0.95,0.04-1.97,0.61-2.76,1.2c-0.9,0.67-1.75,1.19-2.91,1.16c-1.14-0.03-2.23,0.14-2.86,1.34c-0.12,0.23-0.6,0.37-0.92,0.39
		c-2.03,0.14-4.08,0.12-6.09,0.38c-1.09,0.15-2.12,0.76-3.48,1.28c-0.2-0.47-0.7-1.21-0.84-2c-0.27-1.6-0.67-3.08-1.52-4.49
		c-0.44-0.73-0.55-1.74-0.58-2.63c-0.1-3.11-0.43-3.62-3.4-4.44c-1.65-0.45-2.78-1.51-3.67-2.9c-0.37-0.57-0.68-1.18-1.06-1.74
		c-1.33-1.95-2.95-2.38-5.08-1.38c-0.39,0.18-0.78,0.36-1.53,0.7c0.09-1.11,0.09-1.99,0.25-2.85c0.34-1.84-0.15-2.32-1.92-1.79
		c-0.62,0.18-1.17,0.45-1.83-0.17c-0.66-0.61-1.49-0.15-1.79,0.54c-0.5,1.15-0.99,2.38-1.09,3.6c-0.11,1.33,0.21,2.7,0.41,4.04
		c0.18,1.21,0.11,2.21-1.01,3.08c-0.58,0.44-0.91,1.38-1.07,2.15c-0.25,1.16-0.32,2.38-0.32,3.57c0,0.82,0.25,1.63,0.42,2.44
		c0.18,0.87,0.43,1.73,0.6,2.61c0.29,1.47,0.53,2.95,0.79,4.43c0.04,0.26,0.18,0.62,0.07,0.76c-1.01,1.2-0.79,2.42-0.21,3.7
		c0.09,0.2,0.07,0.46,0.14,0.99c-1.07-0.39-1.98-0.71-2.88-1.06c-2.79-1.09-5.67-1.63-8.66-1.51c-1.89,0.07-3.29,1-4.06,2.71
		c-0.68,1.52-1.66,2.48-3.31,3.01c-1,0.32-1.84,1.22-2.69,1.93c-0.64,0.53-1.16,1.21-1.78,1.78c-0.69,0.62-1.38,0.9-2.38,0.39
		c-0.54-0.28-1.58-0.28-1.97,0.07c-0.39,0.35-0.46,1.33-0.32,1.96c0.59,2.58,3.01,4.5,5.65,4.52c0.99,0.01,1.22,0.34,1.5,1.31
		c0.38,1.29,1.27,2.45,2.1,3.56c0.66,0.88,1.25,1.61,1.06,2.87c-0.2,1.32,0.66,2.26,1.85,2.8c2.29,1.04,4.61,2.01,6.88,3.09
		c0.51,0.24,0.85,0.82,1.29,1.22c0.63,0.58,1.25,1.17,1.92,1.7c1.62,1.26,3.32,2.42,4.89,3.73c0.85,0.72,0.86,1.8,0.39,2.77
		c-1.91,3.97-1.21,7.86,0.34,11.71c0.46,1.14,1.1,2.22,1.71,3.29c0.43,0.75,0.4,1.34-0.13,2.07c-0.41,0.56-0.72,1.36-0.7,2.05
		c0.12,3.98,1.51,7.49,4.29,10.38c0.93,0.97,1.96,1.88,3.04,2.68c1.64,1.22,1.91,1.84,1.55,3.87c-0.32,1.85-0.44,3.73-0.82,5.56
		c-0.22,1.03-0.76,2-1.23,2.95c-0.11,0.21-0.53,0.28-0.82,0.39c-0.94,0.38-2.03,0.57-2.8,1.17c-1.54,1.19-2.89,2.62-4.36,3.9
		c-0.5,0.44-1.12,0.74-1.7,1.08c-0.55,0.32-1.22,0.51-1.64,0.95c-1.22,1.3-2.76,1.55-4.38,1.4c-2.72-0.25-5.37-0.23-8.09,0.34
		c-1.52,0.32-3.22-0.31-4.84-0.38c-1.1-0.04-2.24,0.05-3.32,0.29c-1.36,0.31-1.96,1.05-1.79,2.42c0.25,1.99-1.37,2.75-2.32,3.96
		c-0.14,0.18-0.76,0.06-1.13-0.01c-3.76-0.74-7.5-1.62-11.28-2.2c-1.65-0.25-3.42-0.05-5.1,0.2c-2.68,0.4-5.33,1-7.99,1.54
		c-2.1,0.43-4.17-0.12-5.93-0.96c-1.55-0.74-2.84-2.25-3.93-3.66c-0.48-0.61-0.17-1.83-0.24-2.77c-0.08-1.21-0.2-2.43-0.26-3.64
		c-0.05-0.93-0.18-1.9,0.01-2.8c0.21-1.02,0.04-1.84-0.43-2.74c-0.91-1.76-1.77-3.55-2.52-5.39c-0.46-1.13-1.28-1.75-2.24-2.38
		c-1.42-0.94-2.77-1.99-4.14-3c-1.28-0.95-2.5-1.99-3.83-2.86c-1.43-0.93-2.58-1.93-2.76-3.8c-0.11-1.23-1.14-1.51-1.94-1.47
		c-0.12-1.89-0.27-3.73-0.34-5.56c-0.05-1.22-0.08-2.46,0.07-3.66c0.19-1.45,0-1.98-1.27-2.74c-0.26-0.16-0.53-0.61-0.5-0.9
		c0.17-1.76-0.14-3.31-1.42-4.64c-0.34-0.36-0.45-0.99-0.54-1.52c-0.19-1.08-0.38-2.17-0.39-3.26c0-0.49,0.36-1.03,0.66-1.47
		c1.25-1.8,1.22-2.17-0.45-3.58c-0.28-0.23-0.56-0.45-0.82-0.71c-0.56-0.55-0.93-1.06-0.09-1.76c0.33-0.28,0.55-0.93,0.5-1.38
		c-0.12-1.24-0.38-2.47-0.62-3.7c-0.3-1.54-0.93-2.86-2.32-3.76c-0.4-0.26-0.74-0.86-0.79-1.34c-0.33-2.86-0.54-3.29-2.65-4.79
		c0.37-1.95,0.69-3.81,1.11-5.65c0.15-0.64,0.54-1.22,0.86-1.81c0.47-0.87,1.07-1.69,1.46-2.6c0.51-1.19,0.3-2.3-0.53-3.37
		c-0.32-0.41-0.35-1.13-0.33-1.7c0.05-1.33,0.3-2.66,0.31-3.99c0.01-0.74,0.12-1.25,0.71-1.76c1.35-1.17,1.99-2.79,1.81-4.5
		c-0.15-1.45,0.34-2.54,1-3.71c0.25-0.44,0.37-1.19,0.16-1.57c-0.17-0.3-1.06-0.48-1.45-0.31c-1.77,0.79-3.55,1.61-5.19,2.63
		c-1.29,0.81-1.83,2.11-1.1,3.71c-0.92,0.11-1.75,0.18-2.57,0.31c-1.92,0.28-2.56,1.04-2.52,3c0.02,0.85-0.58,0.71-1.08,0.72
		c-1.2,0.03-2.4-0.05-3.59,0.07c-2.13,0.22-3.57,1.4-4.45,3.35c-0.9,2.02-2.23,3.57-4.43,4.41c-1.3,0.5-2.48,1.43-3.55,2.36
		c-1.18,1.02-2.37,1.84-3.95,2.09c-1.63,0.26-3.03,0.95-3.92,2.47c-0.18,0.3-0.58,0.57-0.93,0.66c-3.24,0.85-6.46,1.77-9.73,2.45
		c-2.11,0.44-3.59,1.52-4.83,3.21c-1.22,1.66-2.63,3.18-4,4.73c-0.28,0.32-0.7,0.54-1.1,0.71c-1.67,0.69-2.3,0.34-3.14-1.24
		c-0.46-0.87-1.34-1.5-1.89-2.34c-1.34-2.02-3.46-2.08-5.53-2.54c-2.07-0.47-4.06-0.56-6.16-0.4c-3.49,0.27-7.02,0.12-10.51,0.38
		c-2.01,0.15-4.01,0.65-5.96,1.21c-2.18,0.63-4.35,0.97-6.62,0.82c-3.58-0.23-7.11,0.2-10.55,1.2c-1.2,0.35-2.33,0.92-3.57,1.41
		c0.21-0.59,0.4-1.13,0.58-1.66c-2.15-1.86-4.38-3.57-6.33-5.56c-1.16-1.19-1.97-2.78-2.72-4.3c-0.36-0.73-0.4-1.73-0.3-2.57
		c0.47-3.87-0.55-7.12-3.7-9.57c-1.78-1.39-2.55-3.16-2.3-5.46c0.26-2.45-0.47-4.78-1.52-6.94c-0.74-1.54-2.22-2.27-3.94-2.31
		c-3.22-0.09-5.08-1.6-5.29-4.81c-0.41-6.3-0.7-12.6-0.88-18.91c-0.03-0.94,0.6-2.13,1.3-2.81c4.05-3.86,8.2-7.62,12.34-11.38
		c1.28-1.17,1.92-2.63,2.18-4.29c0.47-2.99,0.35-5.75-2.12-8.06c-1.72-1.6-3.22-3.66-2.51-6.15c0.82-2.86-0.99-5.14-1.16-7.74
		c-0.01-0.2-0.49-0.48-0.79-0.55c-5.78-1.31-11.64-2.08-17.54-2.48c-1.75-0.12-3.51-0.24-5.25-0.47c-0.54-0.07-1.09-0.44-1.55-0.78
		c-2.57-1.89-3.86-1.74-5.68,0.84c-0.53,0.75-1.07,0.85-1.65,0.33c-1.33-1.19-2.64-2.4-3.84-3.72c-0.75-0.82-1.25-1.86-1.91-2.76
		c-1.3-1.77-3.06-2.57-5.26-2.55c-3.48,0.03-6.96,0-10.44,0c-24.64-0.01-49.28-0.03-73.92-0.04c-0.47,0-0.95,0-1.55,0
		c0.43-3.12,0.84-6.12,1.27-9.11c0.23-1.58,0.56-3.15,0.74-4.73c0.1-0.84,0.49-1.26,1.26-1.5c0.6-0.19,1.15-0.51,1.77-0.79
		c-0.52-2.08-1.12-4.07-1.49-6.1c-0.21-1.16-0.2-2.39-0.06-3.57c0.65-5.51,1.41-11.01,2.07-16.53c0.4-3.32-0.47-6.29-2.52-8.99
		c-1.81-2.38-3.44-4.91-5.12-7.39c-1.38-2.02-1.79-4.11-0.66-6.44c0.69-1.42-0.14-3.35-1.59-3.87c-0.98-0.35-1.08-0.94-0.79-1.77
		c1.16-3.31,2.56-6.56,3.44-9.95c0.97-3.74,2.97-7.17,3.15-11.15c0.14-2.98-0.48-5.7-2.04-8.23c-0.2-0.33-0.37-0.83-0.27-1.17
		c0.69-2.48,0.6-2.92-1.28-4.65c1.77-1.68,3.56-3.33,5.28-5.05c0.32-0.32,0.51-0.95,0.46-1.41c-0.58-5.55-3.45-10.05-6.71-14.34
		c-0.76-1-0.82-1.7-0.06-2.72c1.99-2.63,1.21-5.41-1.8-6.75c-0.15-0.07-0.3-0.12-0.45-0.17c-2.43-0.91-2.43-0.91-2.43-3.47
		c0-1.24,0.03-2.48-0.01-3.72c-0.02-0.65,0.24-0.92,0.84-1.07c1.7-0.43,3.39-0.91,5.09-1.37c2.04-0.56,2.89-1.74,2.47-3.84
		c-0.28-1.39-0.74-2.79-1.4-4.04c-1.23-2.34-1.57-4.62-0.47-7.11c1.72-3.91,3.29-7.88,5.06-11.77c0.6-1.32,0.61-2.26-0.34-3.42
		c-1.44-1.76-2.68-3.68-4-5.54c-0.22-0.31-0.42-0.65-0.72-1.13c86.23,0.04,172.27,0.55,258.37,0.21c0.33,1.1,0.16,2.23-0.58,3.29
		c-1.02,1.46-1.8,3.19-3.74,3.68c-0.61,0.16-1.29,0.15-1.93,0.09c-0.46-0.04-0.9-0.33-1.36-0.45c-1.75-0.47-2.7,0.5-2.57,2.31
		c0.14,2.06-0.02,4.1-1.49,5.8c-1.28,1.47-0.94,3.31-0.98,5.03c-0.03,1.23,0.16,2.48,0.12,3.71c-0.05,1.53-0.74,2.67-2.35,3.07
		c-0.34,0.09-0.68,0.25-0.99,0.42c-1.75,0.94-2.15,2.69-1,4.3c-0.01,0.07,0,0.17-0.05,0.22c-1.78,1.76-1.64,3.01,0.66,4.11
		c1.34,0.64,2.03,1.42,2.03,2.98c0.01,2.46,1.28,4.45,3.02,6.05c1.32,1.21,2.22,2.61,2.75,4.27c0.49,1.54,1.51,2.5,3.05,2.94
		c0.96,0.28,1.9,0.61,2.87,0.85c2.28,0.55,4.4,1.36,6.19,2.96c0.98,0.88,2.24,1.19,3.59,1.05c1.79-0.18,3.58-0.35,5.37-0.52
		c1.83-0.17,2.87,0.57,3.2,2.38c0.25,1.41,0.47,2.84,0.55,4.28c0.03,0.58-0.21,1.27-0.55,1.76c-0.86,1.24-1.1,2.55-0.81,3.99
		c0.56,2.83,1.22,5.61,3.15,7.92c0.43,0.52,0.51,1.41,0.57,2.14c0.12,1.59,0.18,3.19,0.16,4.78c-0.02,1.43,0.59,2.35,1.85,2.92
		C504.48,400.82,504.72,401.04,505.04,401.25z"/>
				</g>
				<rect x="593.87" y="752.66" class="st15" width="135.53" height="18.31" />
				<text transform="matrix(1 0 0 1 621.872 763.2987)" class="st16 st17">Diamantino</text>
				<rect x="717.45" y="613.57" class="st15" width="77.98" height="18.31" />
				<text transform="matrix(1 0 0 1 737.5637 624.2145)" class="st16 st17">Sinop</text>
				<rect x="491.8" y="916.14" class="st15" width="54.6" height="36.62" />
				<text transform="matrix(1 0 0 1 492.6436 926.7841)" class="st16 st17">Cáceres</text>
				<rect x="335.3" y="771.15" class="st15" width="77.98" height="36.62" />
				<text transform="matrix(1 0 0 1 345.2215 781.7882)"><tspan x="0" y="0" class="st16 st17">Pontes e </tspan><tspan x="2.28" y="16.8" class="st16 st17">Lacerda</tspan></text>
				<rect x="812.94" y="755.96" class="st15" width="83.6" height="31.59" />
				<text transform="matrix(1 0 0 1 819.8511 766.5955)"><tspan x="0" y="0" class="st16 st17">Primavera </tspan><tspan x="6.41" y="16.8" class="st16 st17">do Leste</tspan></text>
				<rect x="601.81" y="935.46" class="st15" width="54.6" height="36.62" />
				<text transform="matrix(1 0 0 1 606.2393 946.0962)"><tspan x="0" y="0" class="st16 st17">Várzea </tspan><tspan x="-2.13" y="16.8" class="st16 st17">Grande</tspan></text>
				<rect x="688.73" y="966.72" class="st15" width="58.13" height="15.3" />
				<text transform="matrix(1 0 0 1 688.735 977.3575)" class="st16 st17">Cuiabá</text>
				<rect x="810.8" y="993.16" class="st15" width="130.72" height="15.3" />
				<text transform="matrix(1 0 0 1 810.7953 1003.8009)" class="st16 st17">Rondonópolis</text>
				<rect x="921.21" y="850.72" class="st15" width="130.72" height="15.3" />
				<text transform="matrix(1 0 0 1 921.2132 861.3569)" class="st16 st17">Barra do Garças</text>
				<rect x="1000.99" y="685.89" class="st15" width="101.48" height="15.3" />
				<text transform="matrix(1 0 0 1 1000.9928 696.5303)" class="st16 st17">Querência</text>
				<rect x="1010.07" y="480.25" class="st15" width="101.48" height="15.3" />
				<text transform="matrix(1 0 0 1 1010.0682 490.8883)" class="st16 st17">Confresa</text>
				<rect x="561.34" y="400.45" class="st15" width="101.48" height="15.3" />
				<text transform="matrix(1 0 0 1 561.3381 411.0851)" class="st16 st17">Alta Floresta</text>
				<rect x="816.26" y="430.1" class="st15" width="101.48" height="15.3" />
				<text transform="matrix(1 0 0 1 816.2646 440.7442)" class="st16 st17">Matupá</text>
				<rect x="458.14" y="720.32" class="st15" width="77.98" height="36.62" />
				<text transform="matrix(1 0 0 1 469.6359 730.9572)"><tspan x="0" y="0" class="st16 st17">Tangará</tspan><tspan x="-0.72" y="16.8" class="st16 st17">da Serra</tspan></text>
				<rect x="376.27" y="441.87" class="st15" width="40.9" height="13.47" />
				<text transform="matrix(1 0 0 1 376.2689 452.5062)" class="st16 st17">Juína</text>

			</Images>

			<div className="fields" style={{ marginTop: '40rem', display: 'flex', justifyContent: 'center' }}>
				{keys.map(key => (
					<div style={{ position: 'relative', height: '70px', justifyContent: 'space-between' }}>
						<div key={keys} className="field" style={{ position: 'relative', bottom: '-100%', display: 'flex', fontFamily: 'helvetica', justifyContent: 'space-between' }}>
							<div style={{ marginLeft:'3%',padding: '0.7rem', width: '20px', background: colors[key], borderRadius: '6px', fontFamily: 'open-sans' }} />
							<label htmlFor=" o" height="20px" style={{ display: 'flex', fontFamily: 'helvetica' }}></label>
							<label htmlFor={key} style={{ x: '30%', color: '#000', marginLeft: '1rem' }}>
								{key}
							</label>
						</div>
					</div>

				))}
			</div>
		</React.Fragment>
	)


}
export default ReportMapGraph;
