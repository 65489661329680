import React from 'react';

export default class Ruler extends React.Component {
  render () {
    return (
      <div className={`ruler ${this.props.bottom ? 'bottom' : 'top'}`}>
        <div className="cm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="cm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="cm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="cm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="cm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="cm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="cm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="cm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="cm"/>
        <div className="mm"/>
        <div className="cm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="cm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="cm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="cm"/>
        <div className="mm"/>
        <div className="cm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="cm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="cm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="cm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="mm"/>
        <div className="cm"/>
      </div>
    );
  }
}
