import React from 'react';
import PropTypes from 'prop-types';

export default class FIcon extends React.Component {
  static propTypes = {
    type: PropTypes.string,
    icon: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.string,
    margin: PropTypes.string,
  };

  static defaultProps = {
    type: 'fas',
    icon: 'plus',
    color: '',
    size: '13px',
    margin: '3px'
  };

  render() {
    const {
      icon,
      color,
      size,
      margin,
      type
    } = this.props;
    return (
      <i
        className={`${type} fa-${icon} ${color} `}
        style={{
          fontSize: size,
          marginLeft: margin,
          marginRight: margin,
        }}
      />
    );
  }
}
