import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Modal } from 'reactstrap';

class HabModal extends React.Component {
  constructor(props) {
    super(props);
    this.excluirHabilidade = this.excluirHabilidade.bind(this);
  }

  excluirHabilidade() {
    const { excluirHabilidade, id } = this.props;
    excluirHabilidade(id);
  }

  render() {
    const {
      title,
      isOpen,
      competencia,
      eixo,
      habilidade,
      toggle,
    } = this.props;

    return (
      <Modal className="bgModal" size="lg" isOpen={isOpen} toggle={toggle}>
        <div className="modal-header border-bottom-0">
          <h5 className="modal-title">{title}</h5>
          <button className="close" type="button" aria-label="Close" onClick={toggle}>
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="eixo">
          <h5>EIXO COGNITIVO</h5>
          <p>{get(eixo, 'descricao')}</p>
        </div>
        <div className="competencia">
          <h5>COMPETÊNCIA</h5>
          <p>{get(competencia, 'descricao')}</p>
        </div>
        <div className="habilidades">
          <h5>HABILIDADE</h5>
          <div className="hab-tag white">
            <p>{get(habilidade, 'descricao')}</p>
          </div>
        </div>
        <div className="modal-footer">
          <button
            className="btn danger-button"
            onClick={this.excluirHabilidade}
          >
            <i className="fas fa-trash mr-1" /> Excluir habilidade
          </button>
          <button className="btn danger-button" onClick={toggle}>Fechar</button>
        </div>
      </Modal>
    );
  }
}

HabModal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  competencia: PropTypes.shape({
    descricao: PropTypes.string,
  }),
  eixo: PropTypes.shape({
    descricao: PropTypes.string,
  }),
  habilidade: PropTypes.shape({
    descricao: PropTypes.string,
  }),
  toggle: PropTypes.func,
  excluirHabilidade: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

HabModal.defaultProps = {
  title: '',
  isOpen: false,
  competencia: {},
  eixo: {},
  habilidade: {},
  toggle: () => {},
  excluirHabilidade: () => {},
  id: null,
};

export default HabModal;
