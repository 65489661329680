import React from 'react';
import { get } from 'lodash-es';
import { Form } from 'informed';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import NavBreadCrumb from '../../containers/NavBreadCrumb';
import { InputSelect, InputText, InputMaskDate, InputSelectAsyncCreatable } from '../../components/inputs';
import InputSummernote from '../../components/inputs/InputSummernote';
import FormContainer from '../../containers/Form';
import { BaseForm } from '../../base';
import { API_HOST } from '../../consts';
import ItemConteudo from '../../components/ItemConteudo';

const mapAplicacoes = d => ({
  value: d.id, label: d.nome, ...d,
});

const mapItens = i => ({
  value: i.id, label: `Item ${i.id}`, ...i,
});

const mapStatus = s => ({
  value: s.id, label: s.descricao,
});

const mapCandidatos = c => ({
  value: c.usuario_id, label: c.nome,
});

class NovoRecurso extends BaseForm {
  constructor(props) {
    super(props);
    this.id = get(this.props, 'match.params.id');
  }

  state = {
    aplicacoes: [],
    itens: [],
    status: [],
    item: null,
  }

  componentDidMount() {
    Promise.all([
      this.fetchAplicacoes(),
      this.fetchStatus(),
    ])
      .then(() => {
        if (this.id !== 'new') {
          this.fetchRecurso();
        }
      });
  }

  fetchRecurso() {
    axios.get(`${API_HOST}/recursos/recurso/${this.id}`)
      .then((response) => {
        const {
          aplicacao, requerente, nome_requerente, status, ...values
        } = response.data;
        values.aplicacao = aplicacao.id;
        values.status = status.id;
        if (requerente) {
          values.requerente = { value: requerente.id, label: requerente.last_name };
        } else {
          values.requerente = { value: nome_requerente, label: nome_requerente };
        }
        this.formApi.setValues(values);
        const { aplicacoes, itens } = this.state;
        const ap = aplicacoes.find(d => d.id === aplicacao.id);
        const it = itens.find(d => d.id === values.item);
        this.setState({ item: it });
        this.fetchItens(ap);
      });
  }

  fetchAplicacoes() {
    return axios.get(`${API_HOST}/aplicacoes/aplicacao?page_size=100`)
      .then((response) => {
        this.setState({
          aplicacoes: response.data.results.map(mapAplicacoes),
        });
      });
  }

  fetchCandidatos = (input, callback) => {
    const { formState } = this.state;
    const aplicacao = get(formState, 'values.aplicacao');
    if (aplicacao) {
      axios.get(`${API_HOST}/aplicacoes/candidato`, { params: { aplicacao, search: input } })
        .then((response) => {
          callback(null, {
            options: response.data.results.map(mapCandidatos),
          });
        });
    } else {
      callback(null, { options: [] });
    }
  }

  fetchItens = (value) => {
    const avaliacaoId = get(value, 'avaliacao.id');
    axios.get(`${API_HOST}/avaliacoes/avaliacao/${avaliacaoId}/itens`)
      .then((response) => {
        const itens = response.data.map(mapItens);
        this.setState({ itens });
      });
  }

  fetchStatus() {
    return axios.get(`${API_HOST}/recursos/status`)
      .then((response) => {
        this.setState({
          status: response.data.map(mapStatus),
        });
      })
  }

  getCurrentPath() {
    return this.id === 'new' ? 'Novo recurso' : `Recurso ${this.id}`;
  }

  handleSubmit = () => {
    const { formState } = this.state;
    const { values } = formState;
    const requerenteValue = get(values, 'requerente.value');
    const requerenteLabel = get(values, 'requerente.label');
    const createdRequerente = requerenteValue && requerenteValue === requerenteLabel;
    if (createdRequerente) {
      values.nome_requerente = requerenteValue;
      delete values.requerente;
    } else {
      values.requerente = requerenteValue;
    }

    if (this.id === 'new') {
      axios.post(`${API_HOST}/recursos/recurso`, values)
        .then(() => {
          const { history } = this.props;
          toast.success('Recurso criado com sucesso.');
          history.push('/recursos');
        })
        .catch(this.onValidateError);
    } else {
      axios.put(`${API_HOST}/recursos/recurso/${this.id}`, values)
        .then(() => {
          const { history } = this.props;
          toast.success('Recurso atualizado com sucesso.');
          history.push('/recursos');
        })
        .catch(this.onValidateError);
    }

  }

  showItem() {
    const { itens, formState } = this.state;
    if (formState) {
      const { item } = formState.values;
      const itemToShow = itens.find(d => d.id === item);
      if (itemToShow) {
        return <ItemConteudo item={itemToShow} />;
      }
    }
    return null;
  }

  render() {
    const {
      aplicacoes,
      status,
      itens,
    } = this.state;
    const pathList = [{
      label: 'Recursos',
      link: '/recursos',
    }];
    return (
      <React.Fragment>
        <NavBreadCrumb pathList={pathList} currentPath={this.getCurrentPath()} />
        <div className="container pb-5">
          <FormContainer
            title="Dados do Recurso"
            last="/recursos"
            onClick={this.handleSubmit}
          >
            <Form
              onChange={formState => this.setState({ formState })}
              getApi={(formApi) => { this.formApi = formApi; }}
            >
              <React.Fragment>
                <div className="row">
                  <InputSelect
                    col="col-sm-8"
                    label="Aplicação"
                    field="aplicacao"
                    options={aplicacoes}
                    onChange={this.fetchItens}
                  />
                </div>
                <div className="row">
                  <InputMaskDate
                    col="col-sm-4"
                    label="Data do recurso"
                    field="data"
                  />
                  <InputMaskDate
                    col="col-sm-4"
                    label="Data limite para resposta"
                    field="data_limite"
                  />
                </div>
                <div className="row">
                  <InputSelectAsyncCreatable
                    col="col-sm-8"
                    label="Nome do candidato"
                    field="requerente"
                    autoload={false}
                    loadOptions={this.fetchCandidatos}
                  />
                  <InputText
                    col="col-sm-4"
                    label="Telefone de contato"
                    field="telefone"
                  />
                </div>
                <div className="row">
                  <InputSelect
                    col="col-sm-8"
                    label="Estado do Recurso"
                    field="status"
                    options={status}
                  />
                </div>
                <hr />
                <div className="row">
                  <InputSelect
                    col="col-sm-8"
                    label="Item"
                    field="item"
                    options={itens}
                  />
                </div>
                {this.showItem()}
                <hr />
                <div className="row mt-3">
                  <InputSummernote
                    col="col-sm-12"
                    label="Justificativa e bibliografia"
                    field="justificativa"
                  />
                </div>
                <div className="row">
                  <InputSummernote
                    col="col-sm-12"
                    label="Mensagem para o elaborador"
                    field="mensagem_elaborador"
                  />
                </div>
              </React.Fragment>
            </Form>
          </FormContainer>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(NovoRecurso);
