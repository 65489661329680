import React from 'react';
import { zip } from 'lodash';
import RulerCm from "./RulerCm";

class ReguaProficiencia extends React.Component {

  renderQ(s){
    if(s) return <a href={`#${s}`}>{s}</a>;
    return '-';
  }

  render() {
    const { data } = this.props;

    return (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="table-responsive">
                <table className="table regua-pro">
                  <thead>
                  <tr>
                    {data.map(r => <th width="50px" className="ruler-cm" key={r[0]}><RulerCm /></th>)}
                  </tr>
                  <tr>
                    {data.map(r => <th width="50px" className="below-ruler" key={r[0]}>{r[0]}</th>)}
                  </tr>
                  </thead>
                  <tbody>
                  {zip(...data).slice(1).map((r, i) => (
                    <tr key={i}>
                      {r.map((s, j) => (
                        <td width="30px" key={j}>
                          {/*{s || '-'}*/}
                          {this.renderQ(s)}
                        </td>
                      ))}
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

ReguaProficiencia.defaultProps = {
  data: [],
};

export default ReguaProficiencia;
