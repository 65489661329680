import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default class SubStep extends React.Component {
  static propTypes = {
    link: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  };

  render() {
    const active = this.props.active ? 'active' : '';
    const done = this.props.done ? 'done' : '';

    const { link, title } = this.props;

    return (
      <li className={`sub-step ${active} ${done}`}>
        <Link to={link} className="step-name">{title}</Link>
      </li>
    );
  }
}
