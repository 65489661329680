import styled from 'styled-components';

const ItemTotalSharedStyle = `
  text-align: center;
  color: #fff !important;
  padding: 10px;
  -webkit-animation: fadein .6s;
  min-height: 110px;
  border-radius: 8px;

  label {
    letter-spacing: 0.6px;
  }

  h3 {
    color: #fff !important;
    font-size: 30px !important;
  }
`;

export const ItemTotal = styled.div`
  ${ItemTotalSharedStyle};
  background: ${props => props.theme.leftCard};

  label {
    color: white;
  }
`;

export const ItemTotalCalibrado = styled.div`
  ${ItemTotalSharedStyle};
  background: ${props => props.theme.rightCard};

  label {
    color: white;
  }
`;
