import React from 'react';
import PropTypes from 'prop-types';

const FilterBox = ({ label, value, className, onClick }) => (
  <button className={`${className} btn btn-white filter-box`} onClick={onClick}>
    <strong>{label}: </strong>
    {value}
    <i className="ml-2 fas fa-times" />
  </button>
);

FilterBox.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

FilterBox.defaultProps = {
  className: '',
};

export default FilterBox;
