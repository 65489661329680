import React from 'react';
import PropTypes from 'prop-types';
import Legenda from './Legenda';

class AnaliseReportLegenda extends React.Component {
    legendas = {
      bisserial: [
        { text: 'Bisserial muito bom', color: '#52CE50' },
        { text: 'Bisserial bom', color: '#257ED1' },
        { text: 'Bisserial baixo', color: '#FF8F2E' },
        { text: 'Bisserial muito baixo', color: '#AA3F3F' },
        { text: 'Bisserial inadequado', color: '#FF1437' },
        { text: 'Bisserial inapropriado', color: '#ccc' },
        { text: 'Bisserial não calculado', color: '#545454' },
      ],
      dificuldade: [
        { text: 'Muito Difícil', color: '#8470FF' },
        { text: 'Difícil', color: '#AA3F3F' },
        { text: 'Dificuldade média', color: '#FF8F2E' },
        { text: 'Fácil', color: '#257ED1' },
        { text: 'Muito Fácil', color: '#52CE50' },
      ],
      discriminacao: [
        { text: 'Discriminação muito boa', color: '#52CE50' },
        { text: 'Discriminação boa', color: '#257ED1' },
        { text: 'Discriminação baixa', color: '#FF8F2E' },
        { text: 'Discriminação muito baixa', color: '#AA3F3F' },
        { text: 'Não Discrimina', color: '#FF1437' },
      ],
    }

    render() {
      const { selectedParameter } = this.props;
      const legenda = this.legendas[selectedParameter];

      return (
        <span className="row" style={{ margin: '10px' }} >
          {legenda.map((tipo) => {
              return (
                <Legenda key={tipo.text} text={tipo.text} color={tipo.color} />
              );
            })}
        </span>
      );
    }
}

AnaliseReportLegenda.propTypes = {
  selectedParameter: PropTypes.string,
};

AnaliseReportLegenda.defaultProps = {
  selectedParameter: '',
};

export default AnaliseReportLegenda;
