import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default class Dropdown extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    // actions: [],
    icon: PropTypes.string,
    iconSide: PropTypes.string,
    float: PropTypes.string,
  };

  static defaultProps = {
    title: 'Ações',
    actions: [{
      id: 1,
      title: 'Imprimir',
      url: '/',
      key: 'action'
    },
      {
        id: 2,
        title: 'Exportar',
        url: '/',
        key: 'action'
      },
      {
        id: 3,
        title: 'Enviar para revisão',
        url: '/',
        key: 'action'
      },
      {
        id: 4,
        title: 'Excluir',
        url: '/',
        key: 'action'
      },],
    float: '',
  };

  state = {
    listOpen: false,
    actions: []
  }

  toggleList() {
    this.setState(prevState => ({
      listOpen: !prevState.listOpen,
    }));
  }

  render() {
    const {
      title,
      actions,
      float,
    } = this.props;
    const {
      listOpen,
    } = this.state;

    return (
      <div className={`dropdown-button btn btn-default pull-${float}`}>
        <div className="dd-header" onClick={() => this.toggleList()}>
          <div className="dd-header-title ">{title}
         {/* <span className="pull-right">
            {listOpen
              ? <FIcon color="pull-right" type="fas" icon="chevron-up"/>
              : <FIcon color="pull-right" type="fas" icon="chevron-down"/>
            }
          </span>*/}
          </div>

        </div>
        {listOpen && <div className="dd-list dropdown-list">
          {actions.map((item) => {
            if (item.onClick) {
              return (
                <li>
                  <span
                    onClick={() => {
                      this.setState({ listOpen: false });
                      item.onClick();
                    }}
                    className="dd-list-item"
                    key={item.id}
                  >
                    {item.title}
                  </span>
                </li>
              )
            }

            return (
              <li>
                <Link to={item.url} className="dd-list-item" key={item.id}>{item.title}</Link>
              </li>
            );
          })
          }

        </div>
        }
      </div>


    )
  }
}
