import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { get } from 'lodash-es';
import BaseList from '../../base/BaseList';
import { API_HOST } from '../../consts';
import Loading from '../../components/Loading';
import ListItem from '../../containers/ListItem';
import NavBreadCrumb from '../../containers/NavBreadCrumb';

class ListaRecursos extends BaseList {
  state = {
    data: [],
    search: '',
    page: 1,
    ordering: '-data',
  }

  maisAntigos = 'data'
  maisRecentes = '-data'
  api = `${API_HOST}/recursos/recurso`
  removeConfirmationMessage = id => `Tem certeza que deseja remover o recurso ${id}?`

  renderAddButton() {
    return (
      <Link to="/recursos/new" className="btn primary-button mb-3">
        <i className="fas fa-plus" /> Novo Recurso
      </Link>
    );
  }

  getActions = (recurso) => {
    const id = get(recurso, 'id');
    const permissions = get(recurso, 'permissions') || [];
    const actions = [];

    if (get(permissions, 'update')) {
      actions.push((
        <Link className="btn btn-white mb-3" key="1" to={`recursos/${id}`}>
          <i className="fas fa-edit" />
        </Link>
      ));
    }

    if (get(permissions, 'destroy')) {
      actions.push((
        <button className="btn btn-white mb-3" key="2" onClick={() => this.handleRemove(id)}>
          <i className="fas fa-times" />
        </button>
      ));
    }

    return actions;
  }

  renderToolbar() {
    const { search } = this.state;

    return (
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <label className="container-check-custom">
            <input
              type="checkbox"
              id="selecionar-todos"
              aria-label=""
            />
            <span className="checkmark" />
          </label>
          {this.renderAddButton()}
        </div>
        <div className="d-flex">
          <div className="search">
            <input
              className="search-term"
              placeholder="Pesquisar..."
              value={search}
              onChange={(event) => {
                const search = event.target.value;
                this.setState({ search });
                this.debouncedFetchData(search);
              }}
            />
          </div>
          {this.renderOrderingSelect()}
        </div>
      </div>
    );
  }

  renderList() {
    const { fetchingData, data } = this.state;

    if (fetchingData) return <Loading />;

    return data.map(recurso => (
      <ListItem
        className="bg-cinza"
        key={recurso.id}
        title={`Recurso ${recurso.id}`}
        status={{
          label: get(recurso, 'status.descricao', '-'),
          color: get(recurso, 'status.cor', '#fff'),
        }}
        description={[
          { label: 'Elaborador:', value: get(recurso, 'elaborador', '-') },
          { label: 'Disciplina:', value: get(recurso, 'aplicacao.disciplina.descricao', '-') },
          { label: 'Aplicação:', value: get(recurso, 'aplicacao.nome', '-') },
          { label: 'Data do envio:', value: get(recurso, 'data', '-') },
          { label: 'Data do limite resposta:', value: get(recurso, 'data_limite', '-') },
        ]}
        actions={this.getActions(recurso)}
      />
    ));
  }

  renderBreadcrumb() {
    const currentPath = 'Recursos';
    return <NavBreadCrumb currentPath={currentPath} hasBackslash={false} />;
  }
}

export default withRouter(ListaRecursos);
