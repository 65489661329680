import React from 'react';
import PropTypes from 'prop-types';

class Legenda extends React.Component {
  render() {
    const { text, color } = this.props;
    return (
      <span style={{ margin: '5px' }}>
        <span
          style={{
                 backgroundColor: color,
                 width: '10px',
                 height: '10px',
                 marginRight: '5px',
                 display: 'inline-block',
               }}
        />
        {text}
      </span>
    );
  }
}

Legenda.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
};

Legenda.defaultProps = {
  text: PropTypes.string,
  color: PropTypes.string,
};

export default Legenda;
