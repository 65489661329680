import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import ListItem from '../../containers/ListItem';
import ListItensHeader from '../../containers/ListItensHeader';
import NavBreadCrumb from '../../containers/NavBreadCrumb';
import { ListActions } from '../../actions';
import Loading from '../../components/Loading';
import FormTable from '../../components/FormTable';
import DocumentoModal from './DocumentoModal';

class ListaElaboradores extends React.Component {
  static propTypes = {
    fetchList: PropTypes.func.isRequired,
    elaboradores: PropTypes.arrayOf(PropTypes.object).isRequired,
    loading: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      link: '',
      title: '',
    };
  }

  componentWillMount() {
    this.props.fetchList('elaboradores');
  }

  renderAnexo(title, link) {
    return (
      <div className="col" onClick={() => this.setState({ isOpen: true, link, title })}>
        <i className="fas fa-file fa-lg" style={{ color: '#45c0f5', cursor: 'pointer' }} />
      </div>
    );
  }

  renderClassificacao(color) {
    const titles = {
      '#f5c246': 'Em análise',
      '#86db55': 'Aprovado',
      '#a80000': 'Reprovado',
    };

    return color ? <div className="col"><i className="fas fa-circle fa-lg" style={{ color }} />{titles[color]}</div> : null;
  }

  renderList() {
    const headers = ['Nome', 'Anexo', 'Data de Validade', 'Status', 'Classificação'];
    const data = [
      ['CNH', this.renderAnexo('CNH', 'https://s3.amazonaws.com/educat/cnh.jpeg'), '30/11/2018', 'Em análise', this.renderClassificacao('#f5c246')],
      ['CPF', this.renderAnexo('CPF', 'https://s3.amazonaws.com/educat/cpf.jpeg'), '13/01/2020', 'Análise Finalizada', this.renderClassificacao('#86db55')],
      ['Título eleitor', this.renderAnexo('Título eleitor', 'https://s3.amazonaws.com/educat/te.jpeg'), '01/03/2020', 'Análise Finalizada', this.renderClassificacao('#a80000')],
    ];

    if (this.props.loading) return <Loading />;
    return this.props.elaboradores.map(elaborador => (
      <ListItem
        title={get(elaborador, 'pessoa_fisica.nome', '-')}
        status={{
          label: get(elaborador, 'status_elaborador.ds_status_elaborador', '-'),
          color: get(elaborador, 'status_elaborador.cor', '#45C0F5'),
        }}
        description={[
          { label: 'Email', value: get(elaborador, 'pessoa_fisica.emails', '-') },
          { label: 'Telefone', value: get(elaborador, 'pessoa_fisica.contatos', '-') },
        ]}
      >
        <FormTable
          headers={headers}
          data={data.filter(item => Object.keys(item).some(key => item[key]))}
        />
      </ListItem>
    ));
  }

  render() {
    return (
      <div>
        <NavBreadCrumb currentPath="Elaboradores" />
        <div className="container">
          <DocumentoModal isOpen={this.state.isOpen} link={this.state.link} title={this.state.title} />
          <ListItensHeader
            addButtonLabel="Novo Elaborador"
            addLink="elaborador/dados"
          />
          {this.renderList()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  elaboradores: state.ListElaborador.list,
  loading: state.ListElaborador.loading,
});

const listActions = new ListActions('ListElaborador');

export default connect(mapStateToProps, {
  fetchList: listActions.fetchList,
})(ListaElaboradores);
