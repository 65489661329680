import React from 'react';
import TableAvalia from './TableAvalia';


const ProficiencyScaleDreTables = ({ data, length, pSize, ...props }) => (
  <TableAvalia
    data={data}
    pageSize={length === 0 ? 2 : pSize}
    // pagSize={16}
    showPagination={false}
    resizable={false}
    sortable={true}
    defaulte="default"
    isResponsive
    {...props}
  />
);

export default ProficiencyScaleDreTables;
