import styled from 'styled-components';

export const HomeCard = styled.div`
  background: #ffffff;
  min-height: 430px;
  margin-top: 20px;
  @include radius(8px);
  border: 1px solid ${props => props.theme.bgModal};
`;

export const HomeCardTitle = styled.h4`
  background: ${props => props.theme.secondary};
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  padding: 10px 0 10px 0;
  -webkit-border-radius: 8px 8px 0px 0px;
  -moz-border-radius: 8px 8px 0px 0px;
  border-radius: 8px 8px 0px 0px;
`;
