import PropTypes from 'prop-types';
import axios from 'axios';

class Upload {
  constructor(props) {
    this.file = props.file;
    this.method = props.method;
    this.uploadUrl = props.uploadUrl;
    this.onUploadProgress = props.onUploadProgress;
    this.onSuccess = props.onSuccess;
    this.onError = props.onError;
  }

  upload() {
    axios({
      method: this.method,
      data: this.file,
      headers: this.headers,
      transformRequest: this.transformRequest,
      url: this.uploadUrl,
      onUploadProgress: this.onUploadProgress,
    }).then(this.onSuccess)
      .catch(this.onError);
  }
}

Upload.propTypes = {
  file: PropTypes.oneOf(['File', 'FormData']),
  method: PropTypes.string,
  uploadUrl: PropTypes.string,
  onUploadProgress: PropTypes.func,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};


export default Upload;
