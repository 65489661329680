import React from 'react';
import RelatorioTurmaTable from './RelatorioTurmaTable';
import withRouter from 'react-router-dom/withRouter';

const RelatorioTurmaColumn = ({ hideHeader, relatorios, ...rest }) => {

  const columns = [
    {
      Header: "Aluno",
      headerStyle: {
        "border-top-left-radius": "10px",
        "display": "flex",
        "justify-content": "center",
      },
      accessor: "NM_ALUNO",
      sortable: false,
      className: 'd-flex justify-content-start align-items-center',

    },
    {
      Header: "Nota",
      headerStyle: {
        "display": "flex",
        "justify-content": "center",
      },
      accessor: "NT_ALUNO",
      sortable: false,
      className: 'd-flex justify-content-center align-items-center',
      width: 200
    },
    {
      Header: "Nível de Proficiência",
      headerStyle: {
        "border-top-right-radius": "10px",
        "display": "flex",
        "justify-content": "center",
      },
      width: 300,

      Cell: (row) => (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <div>
            {row.original.proficiencia_aluno}
          </div>
          <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "space-between", backgroundColor: row.original.cor, width: "25px", height: "10px", borderRadius: "10px" }} />
        </div>
      ),
      sortable: false,
    },
  ];

  return (
    <div className="mt-2 mb-2" style={{ width: '100%' }}>
      <RelatorioTurmaTable
        columns={columns}
        data={relatorios}
        {...rest}
      />
    </div>
  );
};

export default withRouter(RelatorioTurmaColumn);
