import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import RawInputSearch from '../../components/inputs/RawInputSearch';
import Loading from '../../components/Loading';
import ModalItensTable from './ModalItensTable';
import { API_HOST } from '../../consts';

export default class ModalItens extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    nome: PropTypes.string,
    filters: PropTypes.objectOf(PropTypes.node),
  };

  static defaultProps = {
    nome: '',
    filters: {},
  }

  state = {
    itens: [],
  }

  fetchItens = (search) => {
    const { filters } = this.props;

    this.setState({ fetchingItens: true });

    axios.get(`${API_HOST}/encomenda/item`, {
      params: {
        ordering: 'criado_em',
        search,
        ...filters,
      },
    })
      .then((response) => {
        this.setState({
          itens: response.data,
          fetchingItens: false,
        });
      });
  }

  filterItens = debounce((search) => {
    this.fetchItens(search);
  }, 500)

  render() {
    const { toggle, isOpen, nome } = this.props;
    const { fetchingItens, itens } = this.state;

    return (
      <Modal
        size="lg"
        isOpen={isOpen}
        toggle={toggle}
        onOpened={this.fetchItens}
      >
        <ModalHeader toggle={toggle}>
          <i className="fas fa-user" /> {nome || '-'}
        </ModalHeader>
        <ModalBody className="modal-filtros">
          <div className="modal-body-inside">
            <div className="col-4 mb-4 pl-0">
              <RawInputSearch onChange={this.filterItens} />
            </div>
            <br />
            {fetchingItens ? <Loading message="Carregando itens..." /> : <ModalItensTable data={itens} />}
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn primary-button mr-3" type="button" onClick={toggle}>OK</button>
        </ModalFooter>
      </Modal>
    );
  }
}
