import React from 'react';
import { BasicTextArea, asField } from 'informed';

export default asField(({
  fieldState, field, label, helpBlock, numbers, col, ...props
}) => (
  <div className={`text-input-form-state form-group ${col}`}>
    <label htmlFor={field}>{label}</label>
    <BasicTextArea
      className={`form-control ${fieldState.error ? 'has-error' : null}`}
      id={field}
      fieldState={fieldState}
      maxLength={numbers}
      {...props}
    />
    {helpBlock && <small className="form-text text-muted">{helpBlock}</small>}
    {fieldState.error && <small className={fieldState.error ? 'text-danger' : null}>{fieldState.error}</small>}
  </div>
));
