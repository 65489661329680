import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import BaseListaTestes from '../../base/testes/BaseListaTestes';
import { API_HOST } from '../../consts';
import NavBreadCrumb from '../../containers/NavBreadCrumb';
import AddButton from '../../components/buttons/AddButton';

class ListaTestes extends BaseListaTestes {
  api = `${API_HOST}/testes/teste`;
  removeConfirmationMessage = id => `Tem certeza que deseja remover o teste ${id}?`;
  params = { pre_teste: false };
  urlPrefix = 'testes';

  renderAddButton() {
    return (
      <Link to="/testes/new/dados">
        <AddButton icon="plus" title="Novo Teste" />
      </Link>
    );
  }

  renderBreadcrumb() {
    const currentPath = 'Teste';
    return <NavBreadCrumb currentPath={currentPath} hasBackslash={false} />;
  }

  renderToolbar() {
    const { search } = this.state;

    return (
      <div className="mb-4">
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <label check className="container-check-custom">
              <input
                type="checkbox"
                id="selecionar-todos"
                aria-label=""
                onChange={this.selecionarTodos}
              />
              <span className="checkmark" />
            </label>
            {this.renderAddButton()}
          </div>
          <div className="d-flex">
            <div className="search">
              <input
                className="search-term"
                placeholder="Pesquisar..."
                value={search}
                onChange={(event) => {
                  const search = event.target.value;
                  this.setState({ search });
                  this.debouncedFetchData(search);
                }}
              />
            </div>
            <div>
              {this.renderOrderingSelect()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ListaTestes);
