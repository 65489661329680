import React from 'react';
import PropTypes from 'prop-types'


const HeaderAvalia = ({
	schoolName,
	schoolYear,
	dreAndMunicipio,
	student
}) => {
	return (
		<>
			<div className="container__background">
				<div className="basic__info">
					<div className="input__background">
						<span className="first__info">
							Escola: <span>{schoolName}</span>
						</span>
					</div>
					<div className="input__background">
						<span className="first__info">
							Etapa Escolar: <span>{schoolYear}</span>
						</span>

					</div>
				</div>
				<div className="basic__info">
					<div className="input__background">
						<span className="first__info">
							{/* Exemplo: DRE Juina | Juina |  */}
							DRE/Município: <span>{dreAndMunicipio}</span>
						</span>
					</div>
					<div className="input__background">
						<span className="first__info">
							Aluno: <span>{student}</span>
						</span>
					</div>
				</div>
			</div>
		</>
	);
};

HeaderAvalia.propTypes = {
	schoolName: PropTypes.string,
	schoolYear: PropTypes.string,
	dreAndMunicipio: PropTypes.string,
	student: PropTypes.string
}

export default HeaderAvalia;
