import axios from 'axios';
import {
  LEGACY_API_HOST,
} from '../consts';

const DEFAULT_LEGACY_API_QUERY = {
  results_per_page: 10,
  page: 1,
};

const toLegacyApi = (response) => {
  if (Array.isArray(response)) {
    return {
      total_pages: 1,
      num_results: response.length,
      objects: response,
    };
  }

  const { count, results } = response;
  let total_pages = 0;

  if (!Number.isNaN(count) && !Number.isNaN(results.length)) {
    total_pages = Math.ceil(count / results.length);
  }

  return {
    total_pages,
    num_results: count,
    objects: results,
  };
};

export default class ListActions {
  constructor(name) {
    this.name = name;
    this.fetchList = this.fetchList.bind(this);
    this.fetchFromLegacyApi = this.fetchFromLegacyApi.bind(this);
    this.fetchRelationship = this.fetchRelationship.bind(this);
    this.simpleSearch = this.simpleSearch.bind(this);
    this.advancedItemSearch = this.advancedItemSearch.bind(this);
    this.toggleAdvancedSearchModal = this.toggleAdvancedSearchModal.bind(this);
  }

  fetchList(api) {
    return (dispatch) => {
      dispatch({ name: this.name, type: 'FETCHING_LIST' });
      axios.get(`${LEGACY_API_HOST}/${api}/`)
        .then(response => dispatch({ name: this.name, type: 'FETCH_LIST_SUCCESS', payload: toLegacyApi(response.data) }))
        .catch(() => dispatch({ name: this.name, type: 'FETCH_LIST_ERROR' }));
    };
  }

  fetchFromLegacyApi(api, q = {}) {
    return (dispatch) => {
      dispatch({ name: this.name, type: 'FETCHING_LIST' });
      axios.get(`http://staging.educat.net.br/api/${api}`, {
        params: {
          ...DEFAULT_LEGACY_API_QUERY,
          ...q,
        },
      })
        .then(response => dispatch({ name: this.name, type: 'FETCH_LIST_SUCCESS', payload: response.data }))
        .catch(() => dispatch({ name: this.name, type: 'FETCH_LIST_ERROR' }));
    };
  }

  fetchRelationship(api, relationship, id, idLabel, single = false) {
    return (dispatch) => {
      dispatch({ name: this.name, type: 'FETCHING_RELATIONSHIP' });
      const params = {
        q: {
          filters: [{
            name: idLabel,
            op: '==',
            val: id,
          }],
          single,
        },
      };

      axios.get(`http://staging.educat.net.br/api/${api}`, { params })
        .then(response => dispatch({
          name: this.name,
          type: 'FETCH_RELATIONSHIP_SUCCESS',
          payload: {
            id,
            idLabel,
            data: { [relationship]: single ? response.data : response.data.objects },
          },
        }))
        .catch(() => dispatch({
          name: this.name,
          type: 'FETCH_RELATIONSHIP_ERROR',
          payload: {
            id,
            idLabel,
            data: { [relationship]: null },
          },
        }));
    };
  }

  simpleSearch(api, query) {
    return (dispatch) => {
      dispatch({ name: this.name, type: 'FETCHING_LIST' });
      axios.get(`http://staging.educat.net.br/api/${api}/search`, { params: { q: query } })
        .then(response => dispatch({ name: this.name, type: 'FETCH_LIST_SUCCESS', payload: response.data }))
        .catch(() => dispatch({ name: this.name, type: 'FETCH_LIST_ERROR' }));
    };
  }

  advancedItemSearch(data, resultsPerPage = 10) {
    return (dispatch) => {
      dispatch({ name: this.name, type: 'FETCHING_LIST' });
      const filters = [];

      [
        'id_curso',
        'id_periodo',
        'id_disciplina',
        'id_pessoa_fisica',
        'id_tipo_prova',
        'fonte__id_dificuldade_presumida',
        'id_assunto',
        'id_area_conhecimento',
        'id_item_tipo',
        'nr_aula',
        'requisitos_item__id_ordem_producao',
        'requisitos_item__id_instrumento',
        'id_item',
        'id_estado_revisao',
      ].forEach((field) => {
        if (data[field]) {
          filters.push({
            field,
            op: field.includes('__') ? 'has' : '==',
            val: data[field],
          });
        }
      });

      if (data.enunciado_txt) {
        filters.push({
          name: 'conteudo',
          op: 'has',
          val: {
            name: 'enunciado_txt',
            op: 'ilike',
            val: `%${data.enunciado_txt}%`,
          },
        });
      }

      const params = {
        q: {
          filters,
        },
        results_per_page: resultsPerPage,
      };

      axios.get('http://staging.educat.net.br/api/v2/itens', { params })
        .then(response => dispatch({
          name: this.name,
          type: 'FETCH_LIST_SUCCESS',
          payload: response.data,
        }));
    };
  }

  toggleAdvancedSearchModal() {
    return {
      name: this.name,
      type: 'TOGGLE_ADVANCED_SEARCH_MODAL',
    };
  }
}
