import React from 'react';
import { Form } from 'informed';
import axios from 'axios';
import { get, map } from 'lodash';
import { API_HOST, ANALYSIS_API_HOST } from '../../consts';
import { default as FormContainer } from '../../containers/Form';
import InputText from '../../components/inputs/InputText';
import Loading from '../../components/Loading';
import LabelView from '../../components/LabelView';
import InputSelect from '../../components/inputs/InputSelect';
import InputNumber from '../../components/inputs/InputNumber';
import BaseForm from '../../base/BaseForm';
import AddButton from '../../components/buttons/AddButton';

const mapAreaConhecimento = x => ({
  value: x.id, label: x.descricao,
})

const mapTiposBloco = mapAreaConhecimento;

class Dados extends BaseForm {
  constructor(props) {
    super(props);
    this.id = props.match.params.id;
    this.fields = [
      'nome',
      'total_itens_caderno',
      'tipo_bloco',
    ];
    this.multiFields = ['areas_conhecimento'];
  }

  state = {
    areas_conhecimento: [],
    tipos_bloco: [],
    submitting: false,
    fetchingData: true,
    calculouCadernos: false,
  }

  componentDidMount() {
    this.fetchData();
    this.fetchAreasConhecimento();
    this.fetchTiposBloco();
  }

  fetchAreasConhecimento() {
    axios.get(`${API_HOST}/core/area_conhecimento`)
      .then((response) => {
        this.setState({ areas_conhecimento: response.data.map(mapAreaConhecimento) });
      })
  }

  fetchTiposBloco() {
    axios.get(`${API_HOST}/testes/tipo_bloco`)
      .then((response) => {
        this.setState({ tipos_bloco: response.data.map(mapTiposBloco) });
      })
  }

  fetchData() {
    if (this.id !== 'new') {
      axios.get(`${API_HOST}/testes/teste/${this.id}/form`)
        .then((response) => {
          const { data } = response;
          this.setFields(data);
          this.setState({ teste: data, fetchingData: false, calculouCadernos: true });
        })
    } else {
      this.setState({ fetchingData: false });
    }
  }

  mapPostData(values) {
    return values;
  }

  handleSubmit = (next) => {
    this.setState({ submitting: true });
    const { cadernos, caderno } = this.state;
    const { values } = this.formApi.getState();
    let promise;

    if (this.id === 'new') {
      promise = axios.post(`${API_HOST}/testes/teste`, {
        ...this.mapPostData(values),
        ...cadernos[caderno],
      });
    } else {
      promise = axios.patch(`${API_HOST}/testes/teste/${this.id}`, values)
    }
    promise
      .then((response) => {
        const { history, location: { pathname } } = this.props;
        const { id } = response.data;
        const url = pathname.replace('dados', next).replace('new', id);
        history.push(url);
      })
      .catch(this.onValidateError);
  }

  onValidateError = (error) => {
    this.setState({ submitting: false });
    super.onValidateError(error);
  };

  calcularCadernos = () => {
    const { values } = this.formApi.getState();
    const { tipo_bloco, total_itens_caderno } = values;

    this.setState({ fetchingCadernos: true, cadernos: [] });

    axios.post(`${ANALYSIS_API_HOST}/bib/options`, {
      total_itens_caderno,
      tipo: tipo_bloco,
    })
      .then((response) => {
        const options = get(response, 'data.options') || [];
        this.setState({
          cadernos: options,
          calculouCadernos: true,
        })
      })
      .catch(this.onValidateError)
      .then(() => this.setState({ fetchingCadernos: false }));
  }

  definirCaderno = (event, i) => {
    const { checked } = event.target;
    if (checked) {
      this.setState({ caderno: i });
    }
  }

  clearCadernos = () => {
    this.setState({ calculouCadernos: false, fetchingCadernos: false, cadernos: [], caderno: null });
  }

  renderCadernos() {
    const { fetchingCadernos, calculouCadernos, cadernos, caderno, teste } = this.state;

    if (this.id !== 'new') {
      return (
        <React.Fragment>
          <div className="row">
            <LabelView col="col-sm-4" title="Itens" text={get(teste, 'total_itens')} />
            <LabelView col="col-sm-4" title="Blocos" text={get(teste, 'total_blocos')} />
            <LabelView col="col-sm-4" title="Itens por bloco" text={get(teste, 'total_itens_bloco')} />
            <LabelView col="col-sm-4" title="Cadernos" text={get(teste, 'total_cadernos')} />
            <LabelView col="col-sm-4" title="Blocos por caderno" text={get(teste, 'total_blocos_caderno')} />
            <LabelView col="col-sm-4" title="Repetição de blocos" text={get(teste, 'repeticao_blocos')} />
          </div>
        </React.Fragment>
      );
    }

    if (fetchingCadernos) {
      return <Loading message="Calculando cadernos..." />;
    }

    if (!calculouCadernos) {
      return null;
    }

    const headers = ['Itens', 'Blocos', 'Itens por bloco',
      'Cadernos', 'Blocos por caderno', 'Repetição de blocos'];

    return (
      <React.Fragment>
        <p className="azul"><strong>Escolha uma das configurações de caderno:</strong></p>
        <div className="table-responsive">
          <table
            className="table regua-pro"
            style={{ backgroundColor: '#fff' }}
          >
            <thead>
              <tr>
                <th></th>
                {map(headers, h => <th key={h}>{h}</th>)}
              </tr>
            </thead>
            <tbody>
              {map(cadernos, (c, i) => (
                <tr key={i}>
                  <td>
                    <input
                      type="radio"
                      onChange={(e) => this.definirCaderno(e, i)}
                      checked={caderno === i}
                    />
                  </td>
                  <td>{c.total_itens}</td>
                  <td>{c.total_blocos}</td>
                  <td>{c.total_itens_bloco}</td>
                  <td>{c.total_cadernos}</td>
                  <td>{c.total_blocos_caderno}</td>
                  <td>{c.repeticao_blocos}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const {
      areas_conhecimento,
      tipos_bloco,
      submitting,
      fetchingData,
      calculouCadernos,
      fetchingCadernos,
    } = this.state;

    return (
      <Form
        onChange={formState => this.setState({ formState })}
        getApi={(formApi) => { this.formApi = formApi; }}
      >
        {() => {
          if (fetchingData) {
            return <Loading />;
          }

          return (
            <FormContainer
              next={calculouCadernos ? 'cadernos' : null}
              title="Dados gerais"
              onClick={this.handleSubmit}
              disabled={submitting}
            >
              <div className="row">
                <InputText
                  col="col-sm-12"
                  label="Nome"
                  field="nome"
                />
              </div>
              <div className="row">
                <InputSelect
                  multi
                  col="col-sm-12"
                  label="Áreas de conhecimento"
                  options={areas_conhecimento}
                  field="areas_conhecimento"
                />
              </div>
              <hr />
              <div className="row">
                <InputSelect
                  disabled={this.id !== 'new'}
                  col="col-sm-6"
                  label="Tipo de bloco"
                  options={tipos_bloco}
                  field="tipo_bloco"
                  onChange={this.clearCadernos}
                />
                <InputNumber
                  disabled={this.id !== 'new'}
                  col="col-sm-6"
                  label="Número de itens no caderno"
                  field="total_itens_caderno"
                  onChange={this.clearCadernos}
                />
                <div className="col-md-12 mb-3">
                  {this.id === 'new' && (
                    <AddButton
                      type="button"
                      disabled={fetchingCadernos}
                      onClick={this.calcularCadernos}
                      title={fetchingCadernos ? 'calculando...' : 'calcular cadernos'}
                      noIcon
                      classes="float-right"
                    />
                  )}
                </div>
              </div>
              {this.renderCadernos()}
            </FormContainer>
          );
        }}
      </Form>
    );
  }
}

export default Dados;
