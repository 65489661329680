import React, { useState } from 'react';

import { Link, withRouter } from 'react-router-dom';
import BarChartSkill from '../../components/BarChartSkill';
import Axios from 'axios';
import { get } from 'lodash';
import { API_HOST } from '../../consts';
import { useEffect } from 'react';
import HeaderAvaliaEscola from '../../components/HeaderAvaliaEscola';
import { FooterAvalia } from '../../containers/global/FooterAvalia/styles';
import { Nav, NavItem } from 'reactstrap';
import { ThemeContext } from '../../contexts/ThemeContext';
import Logo from '../../assets/images/logos/Logos';
import AvaliaLogo from '../../assets/images/logos/avalia-logo.png';
import { toast } from 'react-toastify';



const HeadQuartersPage = ({ match }) => {
  const [habilidadeData, setHabilidadeData] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const avaliaId = match.params.id;

  const fetchBoletimData = () => {
    Axios.get(
      `${API_HOST}/avalia/habilidade?prova_a_cd_disciplina=${match.params.disciplinaId}&cd_escola=${match.params.escolaId}&serie=${match.params.etapaId}`
    )
      .then((res) => {
        if (res.data.results.length == 0 ){
          return toast.error('Não existem dados para a consulta realizada')
        }
        else{
        setHabilidadeData(res.data.results);
        setLoading(true);
        }
      })
      .catch((err) => toast.error('Não existem dados para a consulta realizada'));

  };
  useEffect(() => {
    fetchBoletimData();
  }, []);

  const titulo = [
    'Linguagens, Códigos e suas Tecnologias',
    'Matemática e suas Tecnologias'
  ];
  const etapa = {
    2: 'EF 2º ano',
    3: 'EF 3º ano',
    4: 'EF 4º ano',
    5: 'EF 5º ano',
    6: 'EF 6º ano',
    7: 'EF 7º ano',
    8: 'EF 8º ano',
    9: 'EF 9º ano',
    11: 'EM 1ª série',
    12: 'EM 2ª série',
    13: 'EM 3ª série'
  };

  const allKeys = ['AB', 'B', 'AD', 'AV'];
  const colors = {
    AB: '#FFCECE',
    B: '#FFE74F',
    AD: '#BFEEA6',
    AV: '#ABD4EC',
    'Abaixo do básico': '#FFCECE',
    'Abaixo do Básico': '#FFCECE',
    Básico: '#FFE74F',
    Adequado: '#BFEEA6',
    Avançado: '#ABD4EC',
    base: '#F7F7F7',
    Aluno: '#FFFFFF',
    Escola: '#47BEF3',
    Turma: '#737272',
    DRE: '#B972F1',
    Estado: '#001AFF'
  };

  return (
    <>
      <div className="container">
        <div className="row header-row">
          <div className="col-sm-6 col-md-6">
            <div className="col-sm-6 col-md-6">
              <Nav className="nav-logos">
                <NavItem>
                  <figure>
                    <ThemeContext.Consumer>
                      {(theme) => (
                        <img
                          className="logo-main"
                          src={AvaliaLogo}
                          alt={Logo.clientText || Logo.mainText}
                          height="70"
                          style={{ opacity: '0' }}
                        />
                      )}
                    </ThemeContext.Consumer>
                  </figure>
                </NavItem>
              </Nav>
            </div>
          </div>
        </div>
      </div>
      <div
        id="nav-expand"
        className="nav-expand"
        style={{ background: '#3C4583' }}
      >
        <Nav
          className="navbar-links"
          style={{ background: '#3C4583', border: 'none' }}
        />
      </div>
      <div style={{ marginTop: '5rem', background: '#fff' }}>
        <div className="container mb-4 pb-4">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h1>Relatório diagnóstico de proficiência por habilidade - visão por escola</h1>
                  <hr />
                </div>
                <div
                  className="card-body"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                >
                  <HeaderAvaliaEscola
                    schoolName={
                      habilidadeData ? get(habilidadeData[0], 'nm_escola') : ''
                    }
                    schoolYear={
                      habilidadeData
                        ? etapa[
                            habilidadeData
                              ? get(habilidadeData[0], 'serie')
                              : ''
                          ]
                        : ''
                    }
                    dreAndMunicipio={
                      habilidadeData ? get(habilidadeData[0], 'dre') : ''
                    }
                  />

                  <div className="infos__school">
                    <h2>Habilidades a serem desenvolvidas</h2>
                    <span className="example-hability">
                      Os dados abaixo dizem respeito às habilidades de pior
                      desempenho, a partir de informações extraídas do
                      processamento do resultado de todas as escolas do Estado
                      de Mato Grosso até o momento. O gráfico demonstra a
                      porcentagem de acertos do aluno dentro do seu grupo de
                      proficiência por habilidade.
                    </span>
                  </div>
                  <div className="school__material">
                    <span>
                      {
                        titulo[
                          habilidadeData
                            ? get(habilidadeData[0], 'prova_a_cd_disciplina') -
                              1
                            : ''
                        ]
                      }
                    </span>
                  </div>
                  <div className="table-proficiency">
                    <span>
                      {
                        etapa[
                          habilidadeData ? get(habilidadeData[0], 'serie') : ''
                        ]
                      }
                    </span>
                  </div>
                  <hr className="pro-hr" />
                  {habilidadeData &&
                    habilidadeData.map((dataHability) => (
                      <div style={{ width: '100%' }}>
                        <div className="proeficiency__infos">
                          <>
                            <div className="axis__infos">
                              <div className="axis__text">
                                <span>
                                  Eixo de Conhecimento:{' '}
                                  <span
                                    style={{
                                      color: '#3C4583',
                                      marginLeft: '1rem',
                                      fontWeight: '500'
                                    }}
                                  >
                                    {get(dataHability, 'competencia', '')}
                                  </span>
                                </span>
                              </div>

                              <div className="habilidade">
                                <span>Habilidade: </span>
                                <span className="skill-text">
                                  {get(dataHability, 'habilidade', '')}
                                </span>
                              </div>
                            </div>
                          </>
                          <div className="graphic">
                            <BarChartSkill
                              data={dataHability.porc_acertos_hab}
                              keys={allKeys}
                              colors={colors}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterAvalia />
    </>
  );
};

export default withRouter(HeadQuartersPage);
