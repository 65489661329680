import axios from 'axios';
import { Form } from 'informed';
import { get } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import UserForm from '../../components/UserForm';
import { API_HOST } from '../../consts';

const mapPermission = (d) => ({
  ...d,
  label: `${d.verbose_name} | ${d.name}`
});
const mapGroup = (d) => ({
  ...d,
  label: d.name
});

const mapInstituicao = (d) => ({
  ...d,
  label: d.descricao
});

class UserFormPage extends Component {
  state = {
    groups: [],
    permissions: [],
    instituicoes: [],
    fetchingData: true,
    submitting: false,
    errors: null,
    courses: [],
    courseOptions: [],
    disciplineOptions: []
  };

  componentDidMount() {
    axios
      .all([
        this.fetchData(),
        this.fetchGroups(),
        this.fetchPermissions(),
        this.fetchInstituicoes()
      ])
      .then(() => this.setState({ fetchingData: false }));
  }

  get shouldDisplayPassword() {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    return id === 'new';
  }

  get breadCrumbCurrentPath() {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    const { data } = this.state;
    if (id === 'new') {
      return 'Novo usuário';
    }
    return get(data, 'last_name');
  }

  fetchData() {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    if (id === 'new') {
      this.setState({ fetchingData: false, data: { information: 'new user' } });
      return Promise.resolve();
    }
    return axios.get(`${API_HOST}/login/user/${id}`).then((response) => {
      const data = response.data.results || response.data;
      this.setState({
        data: {
          last_name: data.last_name,
          cpf: data.cpf,
          email: data.email,
          is_active: data.is_active,
          user_permissions: data.user_permissions.map((d) => d.id),
          groups: data.groups.map((d) => d.id),
          instituicoes: data.instituicoes.map((d) => d.id),
          totvs_permissions: data.totvs_permissions
        },
        courses: data.totvs_permissions
      });
    });
  }

  fetchGroups() {
    return axios.get(`${API_HOST}/login/groups`).then((response) => {
      const data = response.data.results || response.data;
      this.setState({ groups: data.map(mapGroup) });
    });
  }

  fetchPermissions() {
    return axios.get(`${API_HOST}/login/permissions`).then((response) => {
      const data = response.data.results || response.data;
      this.setState({ permissions: data.map(mapPermission) });
    });
  }

  fetchInstituicoes() {
    return axios.get(`${API_HOST}/core/instituicao`).then((response) => {
      const data = response.data.results || response.data;
      this.setState({ instituicoes: data.map(mapInstituicao) });
    });
  }

  fetchCursos = () => {
    axios
      .get(`${API_HOST}/avaliacoes/avaliacao/get_cursos_from_totvs`)
      .then((res) => {
        this.setState({ courseOptions: res.data });
      })
      .catch((err) => {
        toast.error('Ocorreu um erro');
        console.error(err);
      });
  };

  fetchDisciplinas = () => {
    axios
      .get(`${API_HOST}/avaliacoes/avaliacao/get_all_disciplinas_from_totvs`)
      .then((res) => {
        this.setState({ disciplineOptions: res.data });
      })
      .catch((err) => {
        toast.error('Ocorreu um erro');
        console.error(err);
      });
  };

  addCourse = () => {
    this.setState((prevState) => ({
      courses: [...prevState.courses, {}]
    }));
  };

  removeCourse = (index) => {
    this.setState((prevState) => ({
      courses: prevState.courses.filter((_, i) => i !== index)
    }));
  };

  handleSubmit = (values) => {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    this.setState({ submitting: true });

    const data = {
      ...values,
      totvs_permissions:
        values.totvs_permissions &&
        JSON.stringify(values.totvs_permissions.filter((p) => p))
    };

    if (id === 'new') {
      axios
        .post(`${API_HOST}/login/user`, data)
        .then(this.handleSubmitSuccess)
        .catch(this.handleSubmitError);
    } else {
      axios
        .patch(`${API_HOST}/login/user/${id}`, data)
        .then(this.handleSubmitSuccess)
        .catch(this.handleSubmitError);
    }
  };

  handleSubmitSuccess = () => {
    const { history } = this.props;
    history.push('/usuarios');

    const created = this.id === 'new';
    const msg = created ? 'Usuário criado.' : 'Usuário atualizado.';
    toast.success(msg);
  };

  handleSubmitError = (error) => {
    this.setState({ submitting: false });
    const badRequest = get(error, 'response.status') === 400;
    if (badRequest) {
      const { data } = error.response;
      Object.keys(data).forEach((field) => {
        if (field === 'non_field_errors') {
          toast.error(data[field][0]);
        } else {
          try {
            this.formApi.setError(field, data[field]);
          } catch (_) {}
        }
      });
      window.scrollTo(0, 0);
    } else {
      toast.error('Ocorreu um erro inesperado. Por favor, tente novamente.');
    }
  };

  render() {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    const { data } = this.state;

    if (!data) {
      return <Loading />;
    }

    return (
      <Form
        onSubmit={this.handleSubmit}
        initialValues={data}
        getApi={(api) => {
          this.formApi = api;
        }}
      >
        <UserForm
          {...this.state}
          addCourse={this.addCourse}
          removeCourse={this.removeCourse}
          shouldDisplayPassword={this.shouldDisplayPassword}
          breadCrumbCurrentPath={this.breadCrumbCurrentPath}
          title={id === 'new' ? 'Novo usuário' : get(data, 'last_name')}
        />
      </Form>
    );
  }
}

UserFormPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default UserFormPage;
