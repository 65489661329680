import PropTypes from 'prop-types';
import React from 'react';
import LabelView from './LabelView';
import Video from './Video';

export default class ItemAlternativa extends React.Component {
  static propTypes = {
    showJustificativa: PropTypes.bool,
    gabarito: PropTypes.bool.isRequired,
    descricao: PropTypes.string,
    arquivo: PropTypes.shape({
      src: PropTypes.string,
      type: PropTypes.string,
    }),
  };

  static defaultProps = {
    descricao: '',
    arquivo: null,
    showJustificativa: false
  };

  renderDescricao() {
    const { descricao, arquivo } = this.props;

    if (descricao) {
      return (
        <span
          dangerouslySetInnerHTML={{ __html: descricao }}
        />
      )
    }

    if (arquivo) {
      return null;
    }

    return '-';
  }

  renderJustificativa() {
    const { justificativa, showJustificativa } = this.props;

    if (!showJustificativa) {
      return null
    }

    if (justificativa) {
      return (
        <div className="row">
          <LabelView
            html
            title="Justificativa"
            text={justificativa}
          />
        </div>
      )
    }

    return <small><i>Sem justificativa</i></small>;
  }

  render() {
    const { arquivo, gabarito, showJustificativa } = this.props;
    const className = gabarito ? 'right' : 'wrong';
    return (
      <React.Fragment>
        <li
          className={className}
          style={showJustificativa ? { border: '1px solid #ccc', padding: 20 } : {}}
        >
          {this.renderDescricao()}
          {arquivo && (
            <Video src={arquivo.src} type={arquivo.content_type} />
            )}
          {this.renderJustificativa()}
        </li>
      </React.Fragment>
    );
  }
}
