import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';

class ItemActionFeedbackModal extends React.Component {
  render() {
    const {
      isOpen,
      toggle,
      title,
      valid,
      invalid,
      validTitle,
      invalidTitle,
    } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
      >
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
          <React.Fragment>
            {valid.length > 0 && (
              <ul>
                <p className="pt-3">
                  <i className="fas fa-check-circle text-success mr-1" /> <strong>{validTitle}</strong>
                </p>
                {valid.map(x => <li key={x}>{x}</li>)}
              </ul>
            )}
            {valid.length > 0 && invalid.length > 0 && <hr />}
            {invalid.length > 0 && (
              <ul>
                <p className="pt-3">
                  <i className="fas fa-times-circle text-danger mr-1" /> <strong>{invalidTitle}</strong>
                </p>
                {invalid.map((x, i) => (
                  <React.Fragment key={x.item}>
                    {i > 0 && <hr />}
                    <ul className="style-list">
                      <p><strong>{x.item}</strong></p>
                      {x.errors.map(y => <li key={y}>{y}</li>)}
                    </ul>
                  </React.Fragment>
                ))}
              </ul>
            )}
          </React.Fragment>
        </ModalBody>
        <ModalFooter>
          <button className="btn primary-button" onClick={toggle}>OK</button>
        </ModalFooter>
      </Modal>
    );
  }
}

ItemActionFeedbackModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  validTitle: PropTypes.string,
  invalidTitle: PropTypes.string,
  valid: PropTypes.arrayOf(PropTypes.number),
  invalid: PropTypes.arrayOf(PropTypes.shape({
    item: PropTypes.number,
    errors: PropTypes.arrayOf(PropTypes.string),
  })),
};

ItemActionFeedbackModal.defaultProps = {
  isOpen: false,
  title: '',
  validTitle: '',
  invalidTitle: '',
  valid: [],
  invalid: [],
};

export default ItemActionFeedbackModal;
