import React from 'react';
import PropTypes from 'prop-types';

const StatusRibbon = ({ color, children }) => <div className="status-tag" style={{ background: color }}>{children}</div>;

StatusRibbon.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node,
}

StatusRibbon.defaultProps = {
  color: 'white',
  children: null,
}

export default StatusRibbon;
