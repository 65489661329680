import React from 'react';
import { asField } from 'informed';
import { Async } from 'react-select';
import { FormGroup } from 'reactstrap';

const InputSelectAsync = asField(({
  field,
  fieldState,
  fieldApi,
  col,
  label,
  onChange,
  loadOptions,
  multi,
  ...props
}) => {
  const { value } = fieldState;
  const { setValue } = fieldApi;


  return (
    <FormGroup className={col}>
      <label>{label}</label>
      <Async
        onChange={(v) => {
          setValue(v);
          fieldApi.setError(null);
          fieldApi.setTouched();
          if (onChange) onChange(v);
        }}
        loadOptions={loadOptions}
        value={value}
        noResultsText="Nenhum resultado encontrado."
        placeholder="selecione"
        searchPromptText="Digite para pesquisar..."
        loadingPlaceholder="Carregando..."
        ignoreAccents={false}
        cache={false}
        {...props}
      />
    </FormGroup>
  );
});

InputSelectAsync.defaultProps = {
  placeholder: 'selecione',
  loadingMessage: () => 'carregando...',
};

export default InputSelectAsync;
