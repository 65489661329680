import React from 'react';
import TableAvalia from './TableAvalia'


const ParticipaçãoDreTable = ({ data, ...props }) => (
	<TableAvalia
		data={data.results}
		pageSize={data.length === 0 ? 2 : 4}
		// pagSize={1}
		showPagination={false}
		resizable={false}
		sortable={false}
		defaulte="table-student-diagnosis-default"
		{...props}
	/>
);

export default ParticipaçãoDreTable;
