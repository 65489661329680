import React from 'react';
import PropTypes from 'prop-types';
import Step from '../../components/Step';
import SubStep from '../../components/SubStep';

import { ProgressTracker as AppProgressTracker } from './styles';

export default class ProgressTracker extends React.Component {
  static propTypes = {
    steps: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      link: PropTypes.string,
      active: PropTypes.boolean,
      done: PropTypes.boolean,
    })).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      steps: props.steps,
    };
  }

  componentWillReceiveProps({ steps }) {
    this.setState({ steps });
  }

  renderSteps() {
    return this.state.steps.map(step => (
      <div key={step.id}>
        <Step
          {...step}
        />
        {this.renderSubSteps(step)}
      </div>
    ));
  }

  renderSubSteps(step) {
    if (step.substeps) {
      return step.substeps.map(subStep => (
        <SubStep
          {...subStep}
          key={subStep.link}
        />
      ));
    }
    return <></>;
  }

  render() {
    return (
      <div className="col-sm-12">
        <AppProgressTracker>
          {this.renderSteps()}
        </AppProgressTracker>
      </div>
    );
  }
}
