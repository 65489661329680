import React from 'react';
import axios from 'axios';
import { Popover, PopoverBody } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import Mark from 'mark.js';
import { get, map } from 'lodash';
import { API_HOST, REVISAO_ITEM_API_HOST } from '../consts';
import { BaseForm } from '../base';
import ItemConteudo from './ItemConteudo';
import IgnorarPalavraButton from './IgnorarPalavraButton';
import Loading from './Loading';
import { removeDuplicates } from '../utils';

class ItemPendencias extends BaseForm {
  constructor(props) {
    super(props);
    this.errorNames = [];
    this.getPalavraIgnorada = this.getPalavraIgnorada.bind(this);
    this.id = props.item_id || this.id;
  }

  state = {
    tipo: [],
    item: {},
    pendencias: {},
    popovers: [],
    popoverOpen: {},
    fetchingItem: true,
    fetchingPendencias: false,
    itemVerification: {}
  };

  componentDidMount() {
    this.fetchItem();
    this.fetchItemVerification({item: this.id});
  }

  fetchItem() {
    if (this.id !== 'new') {
      axios.get(`${API_HOST}/itens/item/${this.id}`)
        .then((response) => {
          this.setState({
            item: response.data,
            fetchingItem: false,
          }, this.fetchPendencias);
        });
    }
  }

  formatDate(stringDate) {
    // Takes a string such as "yy/MM/dd 'às' HH:mm"
    // and convert into "yyyy/MM/dd HH:mm" date field

    let str = stringDate.replace('às', '').trim();
    let dateParts = str.split(' ');
    let formattedDate = '20' + dateParts[0] + ' ' + dateParts[1];
    let date = new Date(formattedDate);

    return date;
  }

  fetchItemVerification(params) {
    axios.get(`${API_HOST}/itens/verificacao_item`, { params })
    .then(res => this.setState({itemVerification: res.data.results[0]}))
    .catch(err => console.error(err));
  }

  saveItemVerification(errors) {
    // If an item aint have a verification, it creates one
    // If an item does have an verification but is outdated, it creates a new one
    const { item, itemVerification } = this.state;

    let itemUpdateDate = this.formatDate(item.atualizado_em);

    if (!itemVerification) {
      let verification = ""

      if (errors) {
        verification += "{ "
        Object.keys(errors).forEach(key => {
          verification += `${errors[key].mensagem};`
        });
        verification += " }"
      }

      const payload = {
        item: this.id,
        verificacao_ds: verification,
      }

      this.handleSaveItemVerification(payload)
    }
    else {
      let itemVerificationDate = new Date(itemVerification.data_verificacao);
      if (itemUpdateDate > itemVerificationDate) {
        let verification = ""

        if (errors) {
          verification += "{ "
          Object.keys(errors).forEach(key => {
            verification += `${errors[key].mensagem};`
          });
          verification += " }"
        }

        const payload = {
          item: this.id,
          verificacao_ds: verification,
        }

        this.handleSaveItemVerification(payload)
      }
    }
  }

  handleSaveItemVerification(payload) {
    axios.post(`${API_HOST}/itens/verificacao_item`, {...payload})
    .catch(err => console.error(err))
  }

  fetchPendencias() {
    this.setState({ fetchingPendencias: true });
    const { item } = this.state;
    const data = {
      situacao_problema: get(item, 'situacao_problema'),
      comando_resposta: get(item, 'comando_resposta'),
      alternativas: map(item.alternativas, a => a.descricao),
    };

    const promises = [
      axios.post(`${REVISAO_ITEM_API_HOST}/check_item`, data),
      axios.post(`${REVISAO_ITEM_API_HOST}/check_ortografia`, data),
    ];
    axios.all(promises)
      .then(axios.spread((...responses) => {
        const { errors } = responses[0].data;
        const grammarErrors = removeDuplicates(responses[1].data.errors, 'trecho_destacavel');

        grammarErrors.forEach((error, index) => {
          errors[`check_ortografia_${index}`] = error;
        });
        this.setState({
          pendencias: errors,
          fetchingPendencias: false,
        }, this.highlight);
        this.saveItemVerification(errors);
      }))
      .catch(() => {
        this.setState({ fetchingPendencias: false });
      });
    }

  renderPopovers() {
    let popovers = [];

    this.errorNames.forEach((errorName) => {
      const errorPopovers = get(this.state, errorName, []);

      popovers = popovers.concat(errorPopovers.map(({ index, element }) => (
        <Popover
          key={`${errorName}${index}`}
          placement="bottom"
          isOpen={get(this.state, `${errorName}.${index}.isOpen`)}
          target={() => element}
          toggle={() => this.toggle(errorName, index)}
        >
          <PopoverBody>
            {this.state.pendencias[errorName].mensagem}
          </PopoverBody>
        </Popover>
      )));
    });

    return popovers;
  }

  createPopovers(errorName) {
    const elements = Array.from(document.querySelectorAll(`mark.${errorName}`));
    const popovers = elements.map((element, index) => {
      element.addEventListener('mouseenter', () => this.toggle(errorName, index));
      element.addEventListener('mouseout', () => this.toggle(errorName, index));
      return {
        errorName, index, element, isOpen: false,
      };
    });

    this.setState({
      [errorName]: popovers,
    });
  }

  performMask(elements, keyword, errorName) {
    const markInstance = new Mark(elements);
    markInstance.mark(keyword, {
      separateWordSearch: true,
      diacritics: false,
      className: errorName,
      done: () => this.createPopovers(errorName),
    });
  }

  performMaskRange(elements, ranges, errorName) {
    const markInstance = new Mark(elements);
    markInstance.markRanges(ranges, {
      separateWordSearch: true,
      diacritics: false,
      className: errorName,
      done: () => this.createPopovers(errorName),
    });
  }

  highlightCheckPalavraNegativa() {
    const comandoResposta = document.querySelector(`#item-${this.id} [name="comando_resposta"]`);
    const situacaoProblema = document.querySelector(`#item-${this.id} [name="situacao_problema"]`);
    const { trecho_destacavel } = this.state.pendencias.check_palavra_negativa;

    trecho_destacavel.forEach((palavraNegativa) => {
      this.performMask([comandoResposta, situacaoProblema].filter(x => x), palavraNegativa, 'check_palavra_negativa');
    });
  }

  highlightCheckQuantidadePalavras() {
    const { trecho_destacavel } = this.state.pendencias.check_quantidade_palavras;
    const alternativas = document.querySelectorAll(`#item-${this.id} .alternatives li`);
    const alternativasComErro = Array.from(alternativas).filter((_, index) => trecho_destacavel[index]);
    alternativasComErro.forEach(alternativa => alternativa.classList.add('check_quantidade_palavras'));
  }

  highlightCheckPalavraProibida() {
    const { trecho_destacavel } = this.state.pendencias.check_palavra_proibida;
    const alternativas = document.querySelectorAll(`#item-${this.id} .alternatives [name="alternativa"]`);
    trecho_destacavel.forEach((palavraProibida) => {
      this.performMask(alternativas, palavraProibida, 'check_palavra_proibida');
    });
  }

  highlightCheckOrtografia(errorName) {
    const { trecho_destacavel } = this.state.pendencias[errorName];
    if (trecho_destacavel) {
      const comandoResposta = document.querySelector(`#item-${this.id} [name="comando_resposta"]`);
      const situacaoProblema = document.querySelector(`#item-${this.id} [name="situacao_problema"]`);
      const alternativas = document.querySelectorAll(`#item-${this.id} .alternatives li`);
      this.performMask([comandoResposta, situacaoProblema].filter(x => x).concat(Array.from(alternativas)), trecho_destacavel, errorName);
    }
  }

  highlight() {
    const { pendencias } = this.state;

    Object.keys(pendencias).forEach((errorName) => {
      this.errorNames.push(errorName);

      if (errorName.startsWith('check_ortografia_')) {
        this.highlightCheckOrtografia(errorName);
        return;
      }

      switch (errorName) {
        case 'check_palavra_negativa':
          this.highlightCheckPalavraNegativa();
          break;
        case 'check_quantidade_palavras':
          this.highlightCheckQuantidadePalavras();
          break;
        case 'check_palavra_proibida':
          this.highlightCheckPalavraProibida();
          break;
        default:
      }
    });
  }

  toggle(errorName, index) {
    const popovers = get(this.state, errorName, []);
    popovers[index].isOpen = !popovers[index].isOpen;
    this.setState({ [errorName]: popovers });
  }

  getPalavraIgnorada(errorName) {
    const { pendencias } = this.state;
    const { campo, trecho_destacavel } = pendencias[errorName];
    const { item } = this.state;
    let contexto;

    if (campo.startsWith('alternativa_')) {
      contexto = item.alternativas[campo.split('_')[1]].descricao;
    } else {
      contexto = item[campo];
    }

    return {
      contexto,
      descricao: trecho_destacavel,
      item: this.id,
      instituicao: 'INEP',
    };
  }


  renderIgnorarPalavraButton(errorName) {
    if (errorName.startsWith('check_ortografia')) {
      return (
        <span className="pull-right">
          <IgnorarPalavraButton palavra={this.getPalavraIgnorada(errorName)} />
        </span>
      );
    }

    return null;
  }

  renderPendencias() {
    const { pendencias, fetchingPendencias, fetchingItem } = this.state;

    if (fetchingItem || fetchingPendencias) {
      return <Loading />;
    }

    if (Object.keys(pendencias).length === 0) {
      return <p className="text-success"><strong>O item está de acordo com os critérios de revisão automática.</strong></p>;
    }

    return (
      <React.Fragment>
        <p className="text-danger"><strong>Os seguintes problemas foram encontrados:</strong></p>
        <table className="table-pendencias" style={{ width: '100%', backgroundColor: '#fff' }}>
          <thead>
            <tr>
              <th width="200px">Verificação Encontrada</th>
              <th>Instrução</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(pendencias).map((error, index) => (
              <tr key={index}>
                <td>
                  <div className={`item-correction-legend ${error}`} />
                  {pendencias[error].mensagem || error}
                </td>
                <td>{pendencias[error].justificativa || '-'} {this.renderIgnorarPalavraButton(error)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </React.Fragment>
    );
  }

  render() {
    const { item, fetchingItem } = this.state;

    return (
      <React.Fragment>
        {!fetchingItem && <ItemConteudo item={item} />}
        {this.renderPopovers()}
        {!fetchingItem && <hr />}
        {this.renderPendencias()}
      </React.Fragment>
    );
  }
}


export default withRouter(ItemPendencias);
