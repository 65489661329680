import React from 'react';
import NavHeader from '../../NavHeader';
import Menu from '../Menu';

import {
  AppMenu,
} from './styles';

export default class Header extends React.Component {
  render() {
    return (
      <>
        <NavHeader />
        <AppMenu>
          <Menu />
        </AppMenu>
      </>
    );
  }
}
