import React from 'react';
import PropTypes from 'prop-types';
import katex from 'katex';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import Equation from './Equation';
import EquationShortcut from './EquationShortcut';
import EquationShortcuts from './EquationShortcuts';

const getMostRecentEquations = () => {
  let mostRecentEquations;
  try {
    mostRecentEquations = JSON.parse(localStorage.getItem('mostRecentEquations')) || [];
  } catch (_) {
    mostRecentEquations = [];
  }
  return mostRecentEquations;
};

const updateMostRecentEquations = (mostRecentEquations) => {
  localStorage.setItem('mostRecentEquations', JSON.stringify(mostRecentEquations));
};

class EquationBox extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      value: '',
      mostRecentEquations: getMostRecentEquations(),
    }
  }

  get latexIsValid() {
    const { value } = this.state;
    if (!value) {
      return false;
    }
    try {
      katex.renderToString(value);
      return true;
    } catch (e) {
      return false;
    }
  }

  handleChange = (event) => {
    this.setState({ value: event.target.value });
  }

  handleShortcutClick = (value) => {
    const cursorPosition = this.inputRef.current.selectionStart;
    const { mostRecentEquationsLimit } = this.props;
    this.setState((prevState) => {
      const equationAlreadyInList = prevState.mostRecentEquations.find(d => d === value);
      const mostRecentEquations = equationAlreadyInList
        ? prevState.mostRecentEquations
        : [value, ...prevState.mostRecentEquations.slice(0, mostRecentEquationsLimit - 1)];

      updateMostRecentEquations(mostRecentEquations);

      let newValue;
      if (cursorPosition === 0) {
        newValue = `${value} ${prevState.value}`;
      } else {
        newValue = `${prevState.value.slice(0, cursorPosition - 1)} ${value}${prevState.value.slice(cursorPosition - 1)}`;
      }

      this.inputRef.current.focus();

      return {
        mostRecentEquations,
        value: newValue,
      };
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { onClick } = this.props;
    const { value } = this.state;
    this.setState({ value: '' });
    onClick(value);
  }

  render() {
    const { isOpen, onClose } = this.props;
    const { value, mostRecentEquations } = this.state;

    return (
      <Modal isOpen={isOpen} toggle={onClose}>
        <ModalHeader toggle={onClose}>
          Escrever fórmula
        </ModalHeader>
        <ModalBody>
          <form onSubmit={this.handleSubmit}>
            <label className="mt-2">Escreva a fórmula no formato LaTeX</label>
            <input
              ref={this.inputRef}
              className="form-control"
              value={value}
              onChange={this.handleChange}
              placeholder="e=mc^2"
            />
            <p className="mt-2 mb-2 equation-preview">
              <Equation
                style={{ paddingLeft: 10 }}
                formula={value}
              />
            </p>
            {mostRecentEquations.length > 0 && (
              <>
                <label className="mt-2">Mais recentes</label>
                <div className="btn-group-equation-shortcut mb-2">
                  {mostRecentEquations.map(d => <EquationShortcut onClick={this.handleShortcutClick} key={d} formula={d} />)}
                </div>
              </>
            )}
            <label className="mt-2">Atalhos</label>
            <br />
            <EquationShortcuts onClick={this.handleShortcutClick} />
            <button type="submit" disabled={!this.latexIsValid} className="btn primary-button pull-right" onClick={this.handleClick}>salvar</button>
            <button type="button" className="btn btn-sm btn-white" onClick={onClose}>cancelar</button>
          </form>
        </ModalBody>
      </Modal>
    );
  }
}

EquationBox.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  mostRecentEquationsLimit: PropTypes.number,
};

EquationBox.defaultProps = {
  isOpen: false,
  onClick: () => {},
  onClose: () => {},
  mostRecentEquationsLimit: 9,
};

export default EquationBox;
