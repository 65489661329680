import React from 'react';
import Highcharts from 'highcharts';

const LETRA = 0;
const FREQUENCIA = 1;
const DIFICULDADE = 2;
const DEFAULT_COLORS = ['#CC7B38', '#5F0F40', '#EE6055', '#57C5F5', '#5A5A5A'];
const GABARITO_COLOR = 'limegreen';

class BarraAlternativaEscolhida extends React.Component {
  componentDidUpdate(prevProps) {
    const { data } = this.props;
    if (prevProps.data !== data) {
      this.createChart();
    }
  }

  get colors() {
    const { gabarito } = this.props;
    const colors = [...DEFAULT_COLORS];
    if (gabarito) {
      colors[gabarito.charCodeAt(0) - 97] = GABARITO_COLOR;
    }
    return colors;
  }

  get categories() {
    const { data } = this.props;
    return data.map((d) => d[0]);
  }

  get numeroCandidatosPorAlternativa() {
    const { data } = this.props;
    const obj = {};
    data.forEach((d) => {
      obj[d[LETRA].charCodeAt(0) - 65] = d[FREQUENCIA];
    });
    return obj;
  }

  createChart() {
    const { id, data } = this.props;
    const series = data.map((d) => [d[LETRA], d[DIFICULDADE]]);
    const numeroCandidatosPorAlternativa = this.numeroCandidatosPorAlternativa;

    Highcharts.setOptions({
      lang: {
        decimalPoint: ',',
        thousandsSep: '.'
      }
    });

    var options = {
      series: [
        {
          name: 'Porcentagem',
          data: series
        }
      ],
      chart: {
        events: {
          // load: function () {
          //   var $difs = $('.dif-value');
          //   $difs.each(function (index, dif){
          //     var value = $(dif).html();
          //     $(dif).html(parseFloat(value).toLocaleString('pt', {minimumFractionDigits:1, maximumFractionDigits:1}) + ' %');
          //   });
          // }
        },
        type: 'column',
        width: 400,
        marginTop: 20
      },
      yAxis: {
        allowDecimals: true,
        title: {
          text: 'Porcentagem'
        },
        min: 0,
        max: 100,
        tickInterval: 25
      },
      xAxis: {
        title: {
          text: 'Alternativas escolhidas'
        },
        categories: this.categories
      },
      title: false,
      legend: {
        enabled: false
      },
      plotOptions: {
        column: {
          tooltip: {
            headerFormat: '',
            pointFormatter: function () {
              return `Respondentes: <b>${
                numeroCandidatosPorAlternativa[this.x]
              }</b>`;
            }
          },
          dataLabels: {
            enabled: true,
            format: '{point.y:.1f} %'
          },
          colors: this.colors,
          colorByPoint: true,
          enableMouseTracking: true
        }
      }
    };

    Highcharts.chart(id, Highcharts.merge(options));
  }

  render() {
    const { id } = this.props;
    return <div id={id} />;
  }
}

export default BarraAlternativaEscolhida;
