import React from 'react';
import { LineChart, XAxis, YAxis, Line, ResponsiveContainer, Tooltip, Label } from 'recharts';
import { max, min } from 'lodash';

class CurvaProficiencia extends React.Component {
  get ticksWithOneInterval() {
    const { data } = this.props;
    const dataMin = min(data.map(d => d.theta)) || 0;
    const dataMax = max(data.map(d => d.theta)) || 0;
    const tickCount = Math.abs(Math.ceil(dataMin)) + Math.abs(Math.ceil(dataMax)) + 1;
    const ticks = [...Array(tickCount)].map((_, i) => Math.ceil(dataMin) + i);
    return ticks;
  }

  get domain() {
    const { data } = this.props;
    return data.length > 0 ? ['dataMin', 'dataMax'] : [0, 'auto'];
  }

  render() {
    const { data } = this.props;

    return (
      <div style={{ width: '100%', height: 330 }}>
        <ResponsiveContainer>
          <LineChart data={data} height={200} margin={{bottom: 10}}>
            <XAxis
              type="number"
              dataKey="theta"
              domain={this.domain}
              ticks={this.ticksWithOneInterval}
              tick={{fontSize: 13}}
            >
              <Label style={{fontSize: 13}} position="bottom" offset={0}>
                Proficiência
              </Label>
            </XAxis>
            <YAxis type="number" tick={{fontSize: 13}}>
              <Label style={{fontSize: 13}} value="I(θ)" offset={0} position="insideLeft" />
            </YAxis>
            <Tooltip />
            <Line dot={false} type="monotone" dataKey="tinfo" stroke="#218ec6" strokeWidth={2} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

CurvaProficiencia.defaultProps = {
  data: [],
};

export default CurvaProficiencia;
