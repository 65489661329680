import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateStep } from '../../../actions';
import BrazilMap from '../../../components/BrazilMap';

class DistribuicaoGeografica extends React.Component {
  componentWillMount() {
    this.props.dispatch(updateStep({
      activeStepIndex: 1,
    }));
  }

  render() {
    return (
      <div className="card">
        <div className="animated fadeIn">
          <div className="card-body">
            <div className="row">
              <div className="col-sm-12">
                <h5 align="middle">Evolução de Elaboradores</h5>
                <hr />
              </div>
            </div>
            <BrazilMap />
            <div className="graphic-map" />
            <div className="graphic-map-legends" >
              <p>0</p>
              <p>500</p>
              <p>1000</p>
              <p>1500</p>
              <p>3000</p>
              <p>4000</p>
            </div>
            <div className="date-map">
              <p>2014</p>
              <p>2015</p>
              <p>2016</p>
              <p>2017</p>
              <p className="active">2018</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  distribuicaoGeografica: state.distribuicaoGeografica,
});

export default withRouter(connect(mapStateToProps)(DistribuicaoGeografica));
