import React from 'react';
import { Form, Scope } from 'informed';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { get, map } from 'lodash';
import { API_HOST } from '../../consts';
import FormContainer from '../../containers/Form';
import Loading from '../../components/Loading';
import InputSpan from '../../components/inputs/InputSpan';
import InputCurrency from '../../components/inputs/InputCurrency';
import { Modal, ModalFooter, ModalHeader, ModalBody } from 'reactstrap';
import Detalhes from '../../components/Detalhes';
import BaseCreate from '../../base/BaseCreate';
import RemoverEtapaModal from '../../components/RemoverEtapaModal';
import { toast } from 'react-toastify';
import InputSelect from '../../components/inputs/InputSelect';
import InputCheckbox from '../../components/inputs/InputCheckbox';

class Pedido extends BaseCreate {
  state = {
    matrizes_referencia: [],
    markedForDeletion: [],
    fetchingData: false,
    tipo: {},
    tipoRevisao: [],
    isOpen: false,
    openModal: false
  };

  componentDidMount() {
    this.fetchData();
    this.fetchTipoRevisao();
  }

  fetchTipoRevisao() {
    axios.get(`${API_HOST}/encomenda/tipo-revisao`).then((response) => {
      this.setState({
        tipoRevisao: response.data.map((tipo) => ({
          value: tipo.id,
          label: tipo.descricao
        }))
      });
    });
  }

  fetchData() {
    if (this.id !== 'new') {
      axios
        .get(`${API_HOST}/encomenda/revisao/form`, {
          params: {
            encomenda: this.id,
            ordering: 'posicao'
          }
        })
        .then((response) => {
          this.setState({ fetchingData: false });
          this.formApi.setValues({ revisoes: response.data });
          this.setState({
            inicio_validacao_pedagogica: get(
              response.data,
              '[0].inicio_validacao_pedagogica',
              '-'
            ),
            termino_validacao_pedagogica: get(
              response.data,
              '[0].termino_validacao_pedagogica',
              '-'
            )
          });
        });
    }
  }

  handleSubmit = (values) => {
    const { markedForDeletion } = this.state;
    this.setState({ submitting: true });

    values.revisoes.forEach((pedido) => {
      pedido.encomenda = this.id;
    });

    axios
      .put(`${API_HOST}/encomenda/revisao`, values.revisoes, {
        params: {
          delete: markedForDeletion.join(',')
        }
      })
      .then(() => {
        const { history } = this.props;
        history.push(this.next);
      })
      .catch(this.onValidateError);
  };

  add = () => {
    const revisoes = this.formApi.getState().values.revisoes || [];
    this.formApi.setValues({ revisoes: [{}, ...revisoes] });
  };

  remove = (i) => {
    const { revisoes } = this.formApi.getState().values;
    this.formApi.setValues({ revisoes: revisoes.filter((_, j) => j !== i) });

    const { markedForDeletion } = this.state;
    this.setState({
      markedForDeletion: [...markedForDeletion, revisoes[i].id]
    });
  };

  handleCreate = (revisao, tipo) => {
    let posicao = 0;
    let encomenda = 0;
    const { markedForDeletion } = this.state;
    this.setState({ submitting: false });

    revisao.forEach((pedido) => {
      posicao = pedido.posicao + 1;
      encomenda = pedido.encomenda;
    });

    axios
      .post(`${API_HOST}/encomenda/revisao/create_revisao`, {
        params: {
          delete: markedForDeletion.join(',')
        },
        encomenda: revisao.length === 0 ? this.id : encomenda,
        posicao: revisao.length === 0 ? 1 : posicao,
        tipo_revisao_id: tipo.value //revisao_id
      })
      .then(() => {
        toast.success('Etapa de revisão criada com sucesso!');
        this.fetchData().then(() => {
          this.setState({ editing: false, submitting: true });
        });
      })
      .catch((err) => console.error(err));
  };

  removeRevisao = async (revisao) => {
    this.setState({ editing: true });

    axios
      .delete(`${API_HOST}/encomenda/revisao/${revisao.id}`)
      .then(() => {
        this.fetchData().then(() => {
          this.setState({ editing: false });
        });
      })
      .catch(() => {
        this.setState({ editing: false });
      });
  };

  alterarPosicao = (revisao, offset) => {
    this.setState({ editing: true, fetchingData: false });
    axios
      .patch(`${API_HOST}/encomenda/revisao/${revisao.id}`, {
        encomenda: revisao.encomenda,
        posicao: revisao.posicao + offset
      })
      .then(() => {
        this.fetchData(false).then(() => {
          this.setState({ editing: false });
        });
      })
      .catch(() => {
        this.setState({ editing: false });
      });
  };

  handleNext = (next) => {
    this.next = next;
    this.handleSubmit(this.formApi.getState().values);
  };

  closeModal = () => {
    this.setState({ openModal: false });
  };

  render() {
    const {
      fetchingData,
      submitting,
      openModal,
      editing,
      isOpen,
      tipo,
      tipoRevisao
    } = this.state;
    const { novoFluxo } = this.props;

    return (
      <Form
        onChange={(formState) => this.setState({ formState })}
        getApi={(formApi) => {
          this.formApi = formApi;
        }}
        onSubmit={this.handleSubmit}
        initialValues={{ revisoes: [] }}
      >
        {({ formState }) => {
          if (fetchingData) {
            return <Loading />;
          }

          return (
            <React.Fragment>
              <Detalhes header="Etapas de Revisão">
                <div className="detalhes-revisao">
                  <div className="linha-vertical"></div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <label className="detalhes-label mb-0">
                      Período de validação pedagógica
                    </label>
                    <div className="detalhes-container">
                      <div className="detalhes-body">
                        <label className="detalhes-label mb-0">Início:</label>
                        <p className="mb-0" style={{ marginLeft: '0.5rem' }}>
                          {get(this.state, 'inicio_validacao_pedagogica') ||
                            '-'}
                        </p>
                      </div>
                      <div className="detalhes-body">
                        <label className="detalhes-label mb-0">Término:</label>
                        <p className="mb-0" style={{ marginLeft: '0.5rem' }}>
                          {get(this.state, 'termino_validacao_pedagogica') ||
                            '-'}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="card-body-revisao"
                  style={{
                    margin: '10px 0 0 20px',
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    fontSize: '16px',
                    color: '#6DBEF0'
                  }}
                >
                  <span>
                    ATENÇÃO: Utilizando as setas, defina a posição das etapas
                    antes de preencher os campos em branco.{' '}
                  </span>
                </div>
              </Detalhes>

              <FormContainer
                previous={novoFluxo ? 'dados' : 'elaboradores'}
                next="revisores"
                onClick={this.handleNext}
                disabled={submitting}
              >
                <Modal size="lg" isOpen={isOpen}>
                  <ModalHeader>Adicionar Etapa de Revisão</ModalHeader>
                  <ModalBody style={{ minHeight: '200px' }}>
                    <div className="row" style={{ padding: '1rem' }}>
                      <InputSelect
                        label="Descrição"
                        col="col-md-4"
                        field="descricao"
                        options={tipoRevisao}
                        onChange={(e) =>
                          this.setState({
                            tipo: e
                          })
                        }
                      />
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <button
                      className="btn danger-button mr-3"
                      type="button"
                      onClick={() => this.setState({ isOpen: false })}
                    >
                      Cancelar
                    </button>
                    <button
                      className="btn primary-button mr-3"
                      type="button"
                      onClick={() => {
                        this.handleCreate(formState.values.revisoes, tipo);
                        this.setState({ isOpen: false });
                      }}
                    >
                      Criar
                    </button>
                  </ModalFooter>
                </Modal>
                <button
                  className="btn mb-3 ml-3 primary-button"
                  onClick={() => this.setState({ isOpen: true })}
                >
                  <i className="fas fa-plus" /> adicionar revisão
                </button>
                {map(formState.values.revisoes, (r, i) => (
                  <>
                    <Scope scope={`revisoes[${i}]`} key={r.id}>
                      <div className="card item-card mb-3" key={r.id}>
                        <div className="card-header">
                          <div className="row">
                            <div
                              style={{
                                display: 'flex',
                                padding: '1rem',
                                flexDirection: 'column',
                                width: '100%',
                                alignItems: 'flex-start'
                              }}
                            >
                              <span style={{ fontSize: '14px', color: '#000' }}>
                                Etapa:
                              </span>

                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  width: '100%',
                                  justifyContent: 'space-between'
                                }}
                              >
                                <label
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    color: '#000'
                                  }}
                                >
                                  {get(r, 'posicao')}.{' '}
                                  {get(r, 'tipo_revisao.descricao')}
                                </label>
                                <div
                                  style={{ marginBottom: '0.5rem' }}
                                  role="group"
                                  className="btn-group list-btn pull-right mt-2"
                                >
                                  {i !==
                                    formState.values.revisoes.length - 1 && (
                                    <button
                                      className="btn btn-white"
                                      onClick={() => this.alterarPosicao(r, 1)}
                                      disabled={editing}
                                    >
                                      <i className="fas fa-arrow-down" />
                                    </button>
                                  )}
                                  {i > 0 && (
                                    <button
                                      className="btn btn-white"
                                      onClick={() => this.alterarPosicao(r, -1)}
                                      disabled={editing}
                                    >
                                      <i className="fas fa-arrow-up" />
                                    </button>
                                  )}
                                  <button
                                    className="btn btn-white"
                                    onClick={() =>
                                      this.setState({ openModal: true })
                                    }
                                    disabled={editing}
                                  >
                                    <i className="fas fa-times" />
                                  </button>
                                </div>
                              </div>
                              <hr
                                style={{
                                  width: '100%',
                                  border: '1px solid #bfbfbf',
                                  margin: '0'
                                }}
                              />
                            </div>
                            <div style={{ display: 'flex', width: '100%' }}>
                              <div
                                style={{
                                  display: 'grid'
                                }}
                              >
                                <InputSpan
                                  type="number"
                                  col="col-sm-12"
                                  label="Quantidade de revisões"
                                  leftAddon="mínimo"
                                  field="minimo"
                                />
                                <InputSpan
                                  type="number"
                                  col="col-sm-12"
                                  leftAddon="máximo"
                                  field="maximo"
                                />
                              </div>
                              <div
                                style={{
                                  display: 'grid'
                                }}
                              >
                                <InputSpan
                                  col="col-sm-12"
                                  label="Período da etapa"
                                  leftAddon="início"
                                  field="inicio"
                                  type="date"
                                />
                                <InputSpan
                                  col="col-sm-12"
                                  leftAddon="término"
                                  field="termino"
                                  type="date"
                                />
                              </div>
                              <InputCurrency
                                col="col-sm-4"
                                label="Valor por revisão"
                                field="valor_revisao"
                              />
                            </div>
                            <InputCheckbox
                              col="col-md-12"
                              label="Permitir edição do item pelo revisor"
                              field="editar_item"
                            />
                          </div>
                        </div>
                        <RemoverEtapaModal
                          openModal={openModal}
                          toggle={() => {
                            this.removeRevisao(r);
                            this.setState({ openModal: false });
                          }}
                          closeModal={this.closeModal}
                        />
                      </div>
                    </Scope>
                  </>
                ))}
              </FormContainer>
            </React.Fragment>
          );
        }}
      </Form>
    );
  }
}

export default withRouter(Pedido);
