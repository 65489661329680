import PropTypes from 'prop-types';
import { createContext } from 'react';

const getApiHost = () => {
  const domains = window.location.hostname.split('.');
  if (domains[0] === 'bancodeitens') {
    return 'https://educat-fgv-api.educat.net.br';
  } else if (domains[0] === 'localhost') {
    return 'http://localhost:8000';
  }
  domains[0] = `${domains[0]}-api`;
  return `${window.location.protocol}//${domains.join('.')}`;
};

export const getTenant = () => {
  const domains = window.location.hostname.split('.');
  console.log(domains[0]);
  if (domains[0] === 'localhost') {
    return 'localhost';
  } else {
    return domains[0].replace('educat-', '');
  }
};

export const API_HOST = process.env.REACT_APP_API_HOST || getApiHost();
export const LEGACY_API_HOST = 'http://api.educat.net.br';
export const REVISAO_ITEM_API_HOST =
  process.env.REACT_APP_REVISAO_ITEM_API_HOST ||
  'https://api.revisao.educat.net.br';
export const PLAGIUS_API_HOST =
  process.env.REACT_APP_PLAGIUS_API_HOST || 'https://plagio.educat.net.br';
export const ID_ITEM_AO_IMPRIMIR_AVALIACAO =
  process.env.REACT_APP_ID_ITEM_AO_IMPRIMIR_AVALIACAO || false;
export const AREAS_CONHECIMENTO_AO_IMPRIMIR_AVALIACAO =
  process.env.REACT_APP_AREAS_CONHECIMENTO_AO_IMPRIMIR_AVALIACAO || false;
export const COPYRIGHT_MESSAGE =
  process.env.REACT_APP_COPYRIGHT_MESSAGE || 'Copyright © 2019 eduCAT Eireli';
export const CONTACT_EMAIL =
  process.env.REACT_APP_CONTACT_EMAIL || 'contato@educat.com.br';

export const ANALYSIS_API_HOST = `${
  window.location.protocol
}//analise-${getTenant()}.educat.net.br`;

export const NIVEIS_ESCOLARIDADE = {
  ENSINO_FUNDAMENTAL_ID: 1,
  ENSINO_MEDIO_ID: 2,
  ESPECIALIZACAO_ID: 3,
  ENSINO_SUPERIOR_ID: 4,
  MESTRADO_ID: 5,
  DOUTORADO_ID: 6,
  POS_DOUTORADO_ID: 7
};

export const ATUACAO_PROFISSIONAL = {
  ATUANDO: 1,
  NAO_ATUANDO: 2
};

export const getAxiosConfig = () => {};

export const SELECT_PROP_TYPE = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.oneOf([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired
  })
);

export const TipoImportacaoAnalisePsicometrica = {
  PARAMETRO_PRE_TESTE: 'PARAMETRO_PRE_TESTE',
  CURVA_CARACTERISTICA_ITEM: 'CURVA_CARACTERISTICA_ITEM',
  CURVA_PROPORCAO_RESPOSTA: 'CURVA_PROPORCAO_RESPOSTA'
};

export const AVALIACAO_ITEM_PROP_TYPE = PropTypes.shape({
  item: PropTypes.shape({
    id_item: PropTypes.number.isRequired
  }).isRequired,
  valor: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
});

export const UserContext = createContext({
  user: {},
  features: [],
  permissions: {},
  hasPermission: () => false
});

export const TIPOS_ITENS = {
  MULTIPLA_ESCOLHA_UNICA: 1,
  MULTIPLA_ESCOLHA_MULTIPLA: 2,
  RESPOSTA_BREVE: 3,
  ESCALA_LINEAR_UNICA: 4,
  ESCALA_LINEAR_MULTIPLA: 5,
  DISCURSIVO: 6
};
