import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

export default class Step extends React.Component {
  render() {
    const { link, title, active, done, disabled, hide } = this.props;

    if (hide) {
      return '';
    } else {
      return (
        <li className={classnames('step', { active, done, disabled, hide })}>
          {disabled ? (
            <span className="step-name disabled">{title}</span>
          ) : (
            <Link to={link} className="step-name">
              {title}
            </Link>
          )}
        </li>
      );
    }
  }
}
