import React from 'react';
import InputMask from './InputMask';

const dateTimeMask = [
  /[0-9]/,
  /[0-9]/,
  '/',
  /[0-9]/,
  /[0-9]/,
  '/',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  ' ',
  /[0-2]/,
  /[0-9]/,
  ':',
  /[0-5]/,
  /[0-9]/,
];
export default props => <InputMask {...props} mask_={dateTimeMask} />;
