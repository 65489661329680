import React from 'react';
import { Link } from 'react-router-dom';
import InfoBoxItem from './InfoBoxItem';

const RevisaoCard = ({
  title,
  totalRevisoes,
  item,
  children,
  totalItensAguardandoRevisao
}) => (
  <div className="card card-bg revisao-card">
    <div className="card-body">
      {totalItensAguardandoRevisao ? (
        <InfoBoxItem
          className="float-right"
          label="Total de itens aguardando revisão"
          value={totalItensAguardandoRevisao}
        />
      ) : null}
      {totalRevisoes >= 0 && (
        <InfoBoxItem
          className="float-right"
          label="Total de revisões"
          value={totalRevisoes}
        />
      )}
      <span className="revisao-card-title">{title}</span>
      {item && (
        <>
          <span> - </span>
          <Link to={`/itens?id=${item}`}>
            <span className="revisao-card-title">Item {item}</span>
          </Link>
        </>
      )}
      <div className="mb-4" />
      {children}
    </div>
  </div>
);

RevisaoCard.defaultProps = {
  title: 'Revisões'
};

export default RevisaoCard;
