const resources = {
  uninove: {
    translation: {
      'Eixos Cognitivos': 'Conhecimentos',
      'EIXOS COGNITIVOS': 'CONHECIMENTOS',
      'Nenhum eixo': 'Nenhum conhecimento',
    },
  },
};

const language = process.env.REACT_APP_LANGUAGE || 'default';
const t = text => (resources[language] ? resources[language].translation[text] : text);
export default t;
