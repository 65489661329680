import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'informed';
import { get, debounce } from 'lodash';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ReferenceTable from '../../containers/ReferenceTable';
import NavBreadCrumb from '../../containers/NavBreadCrumb';
import CardModal from './CardModal';
import HabModal from './HabModal';
import { API_HOST } from '../../consts';
import FormContainer from '../../containers/Form';
import AddReference from '../../components/AddReference';
import {
  InputTextArea,
  InputText,
  InputSelect,
} from '../../components/inputs';
import SwitchButton from '../../components/SwitchButton';
import t from '../../i18n';
import AddButton from '../../components/buttons/AddButton';

const habilidadeBreadcrumb = ['Habilidades', 'Selecione as habilidades'];
const eixoBreadcrumb = ['Eixos', 'Selecione os eixos'];
const competenciaBreadcrumb = ['Competência', 'Selecione as competências'];

class MatrizReferencia extends React.Component {
  constructor(props) {
    super(props);
    this.handleCheckboxClick = this.handleCheckboxClick.bind(this);
    this.handleHabilidadeCheckboxClick = this.handleHabilidadeCheckboxClick.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.excluirHabilidade = this.excluirHabilidade.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.alterarStatusMatrizReferencia = this.alterarStatusMatrizReferencia.bind(this);
  }

  state = {
    eixos: [],
    competencias: [],
    habilidades: [],
    areas_conhecimento: [],
  }

  componentDidMount() {
    this.fetchEixos();
    this.fetchCompetencias();
    this.fetchHabilidades();
    this.fetchMatrizReferencia();
    this.fetchAreasConhecimento();
  }

  componentDidUpdate(prevProps) {
    const id = get(prevProps, 'match.params.id');
    const prevId = get(this.props, 'match.params.id');

    if (prevId !== id) {
      this.fetchMatrizReferencia();
    }
  }

  onValueChange(values) {
    if (this.id !== 'new') {
      axios.patch(`${API_HOST}/matriz_referencia/matriz_referencia/${this.id}`, values);
    }
  }

  get id() {
    return get(this.props, 'match.params.id');
  }

  getCurrentPath() {
    return this.id === 'new' ? 'Nova Matriz' : `Matriz ${this.id}`;
  }

  getActiveHabilidadeCards() {
    const { competenciaAtual, eixoAtual, matriz_referencia } = this.state;
    const ech = get(matriz_referencia, 'ech', []);

    if (competenciaAtual && eixoAtual) {
      const habilidades = ech
        .filter(x => x.competencia.id === competenciaAtual.id && get(x, 'eixo.id') === eixoAtual.id)
        .map(x => x.habilidade);

      return habilidades;
    }

    return [];
  }

  fetchAreasConhecimento() {
    axios.get(`${API_HOST}/core/area_conhecimento`)
      .then((response) => {
        this.setState({
          areas_conhecimento: response.data.map(x => ({
            value: x.id, label: x.descricao,
          })),
        });
      });
  }

  fetchEixos() {
    axios.get(`${API_HOST}/matriz_referencia/eixo`)
      .then((response) => {
        this.setState({
          eixos: [{ id: null, descricao: t('Nenhum eixo') }].concat(response.data.results),
        });
      });
  }

  fetchCompetencias() {
    axios.get(`${API_HOST}/matriz_referencia/competencia`)
      .then((response) => {
        this.setState({
          competencias: response.data.results,
        });
      });
  }

  fetchHabilidades() {
    axios.get(`${API_HOST}/matriz_referencia/habilidade`)
      .then((response) => {
        this.setState({
          habilidades: response.data.results,
        });
      });
  }

  fetchMatrizReferencia() {
    if (this.id !== 'new') {
      axios.get(`${API_HOST}/matriz_referencia/matriz_referencia/${this.id}`)
        .then((response) => {
          const nenhumSelecionado = response.data.eixos.find(x => x.id === null) !== undefined;
          this.setState({
            nenhumSelecionado,
            matriz_referencia: {
              ...response.data,
              ech: response.data.ech.map(x => ({
                ...x,
                eixo: x.eixo || { id: null, descricao: '' },
              })),
            },
          });
          this.formApi.setValues({
            nome: response.data.nome,
            descricao: response.data.descricao,
            areas_conhecimento: response.data.areas_conhecimento,
          });
        });
    }
  }

  addMatrizReferencia(values) {
    axios.post(`${API_HOST}/matriz_referencia/matriz_referencia`, values)
      .then((response) => {
        const { history } = this.props;
        history.push(`/matrizes-referencia/${response.data.id}`);
      });
  }

  toggleModal(modal, additionalState = {}) {
    const { openModal } = this.state;

    if (openModal === modal) {
      this.setState({ openModal: null, ...additionalState });
    } else {
      this.setState({ openModal: modal, ...additionalState });
    }
  }

  handleCheckboxClick(event, id, name) {
    const { checked } = event.target;
    const id_matriz_referencia = get(this.props, 'match.params.id');
    const data = {
      matriz_referencia: id_matriz_referencia,
      [name]: id,
    };

    if (checked) {
      axios.post(`${API_HOST}/matriz_referencia/matriz_referencia_${name}`, data)
        .then(() => {
          this.fetchMatrizReferencia();
        });
    } else {
      axios.delete(`${API_HOST}/matriz_referencia/matriz_referencia/${id_matriz_referencia}/${name}`, {
        params: {
          [name]: id === null ? -1 : id,
        },
      })
        .then(() => {
          this.fetchMatrizReferencia();
        });
    }
  }

  handleHabilidadeCheckboxClick(event, id) {
    const { eixoAtual, competenciaAtual } = this.state;
    const { checked } = event.target;
    const data = {
      matriz_referencia: this.id,
      eixo: eixoAtual.id,
      competencia: competenciaAtual.id,
      habilidade: id,
    };

    if (checked) {
      axios.post(`${API_HOST}/matriz_referencia/eixo_competencia_habilidade`, data)
        .then(() => {
          this.fetchMatrizReferencia();
        });
    }
  }

  excluirHabilidade() {
    const {
      competenciaAtual,
      habilidadeAtual,
      eixoAtual,
      matriz_referencia,
    } = this.state;
    const ech = get(matriz_referencia, 'ech', []);
    const id = get(ech.find(x =>
      x.competencia.id === competenciaAtual.id
      && x.eixo.id === eixoAtual.id
      && x.habilidade.id === habilidadeAtual.id), 'id');

    axios.delete(`${API_HOST}/matriz_referencia/eixo_competencia_habilidade/${id}`)
      .then(() => {
        this.toggleModal('habilidade');
        this.fetchMatrizReferencia();
      });
  }

  handleCardModalSearch(search, api) {
    axios.get(`${API_HOST}/matriz_referencia/${api}`, {
      params: {
        search,
      },
    })
      .then((response) => {
        this.setState({
          [`${api}s`]: response.data.results,
        });
      })
      .catch(() => {});
  }

  alterarStatusMatrizReferencia() {
    if (this.id !== 'new') {
      const { matriz_referencia } = this.state;
      const status_id = get(matriz_referencia, 'status.id') === 1 ? 2 : 1;
      axios.patch(`${API_HOST}/matriz_referencia/matriz_referencia/${this.id}`, { status_id })
        .then(() => {
          this.fetchMatrizReferencia();
        });
    }
  }

  renderMatriz() {
    const { matriz_referencia, nenhumSelecionado } = this.state;
    if (this.id !== 'new') {
      return (
        <FormContainer>
          <div className="d-flex">
            <AddReference
              title="COMPETÊNCIAS"
              color="card-comp"
              onAdd={() => this.toggleModal('competencias')}
              count={get(matriz_referencia, 'competencias', []).length || 0}
            />
            <AddReference
              title={t('EIXOS COGNITIVOS')}
              color="card-cog w-100"
              onAdd={() => this.toggleModal('eixos')}
              count={get(matriz_referencia, 'eixos', []).length || 0}
            />
          </div>
          <ReferenceTable
            eixos={get(matriz_referencia, 'eixos', [])}
            competencias={get(matriz_referencia, 'competencias', [])}
            ech={get(matriz_referencia, 'ech', [])}
            toggleModal={this.toggleModal}
            nenhumSelecionado={nenhumSelecionado}
          />
        </FormContainer>
      );
    }
    return null;
  }

  renderHandleButtons() {
    const { formState } = this.state;
    if (this.id !== 'new') {
      return (
        <Link className="btn primary-button" to="/matrizes-referencia">
          Concluir
        </Link>
      );
    }
    return (
      <AddButton noIcon title="Salvar" onClick={() => this.addMatrizReferencia(formState.values)} />
    );
  }

  renderDesativar() {
    const { matriz_referencia } = this.state;
    if (this.id !== 'new') {
      return (
        <div className="desativar">
          <p>{get(matriz_referencia, 'status.descricao')}</p>
          <SwitchButton
            onChange={this.alterarStatusMatrizReferencia}
            isChecked={get(matriz_referencia, 'status.id') === 1}
          />
        </div>
      );
    }
    return null;
  }

  render() {
    const {
      competencias,
      eixos,
      habilidades,
      openModal,
      matriz_referencia,
      eixoAtual,
      habilidadeAtual,
      competenciaAtual,
      areas_conhecimento,
      nenhumSelecionado,
    } = this.state;

    const pathList = [{
      label: 'Matriz de Referência',
      link: '/matrizes-referencia',
    }];

    return (
      <div className="matrizes pb-5">
        <NavBreadCrumb pathList={pathList} currentPath={this.getCurrentPath()} />
        <div className="container">
          <div className="card-bg">
            <FormContainer title="Matriz de referência">
              {this.renderDesativar()}
              <Form
                onValueChange={debounce(this.onValueChange, 500)}
                onChange={formState => this.setState({ formState })}
                getApi={(formApi) => { this.formApi = formApi; }}
              >
                <div className="row">
                  <InputText
                    col="col-sm-12"
                    label="Nome da Matriz"
                    field="nome"
                  />
                  <InputTextArea
                    col="col-sm-12 mt-3"
                    label="Descrição"
                    field="descricao"
                  />
                  <InputSelect
                    multi
                    col="col-sm-12 mt-3"
                    label="Áreas de conhecimento"
                    field="areas_conhecimento"
                    options={areas_conhecimento}
                  />
                </div>
              </Form>
              <div className="row float-right">
                {this.renderHandleButtons()}
              </div>
            </FormContainer>
          </div>
        </div>
        <div className="container-fluid mt-5">
          <div className="card-bg">
            {this.renderMatriz()}
          </div>
        </div>

        <CardModal
          title="Competências"
          breadcrumb={competenciaBreadcrumb}
          cards={competencias}
          activeCards={get(matriz_referencia, 'competencias', [])}
          name="competencia"
          isOpen={openModal === 'competencias'}
          onCheckboxClick={(event, id) => this.handleCheckboxClick(event, id, 'competencia')}
          onSearch={search => this.handleCardModalSearch(search, 'competencia')}
          toggle={() => this.toggleModal('competencias')}
        />

        <CardModal
          title={t('Eixos Cognitivos')}
          breadcrumb={eixoBreadcrumb}
          cards={eixos}
          activeCards={get(matriz_referencia, 'eixos', [])}
          name="eixo"
          isOpen={openModal === 'eixos'}
          onCheckboxClick={(event, id) => this.handleCheckboxClick(event, id, 'eixo')}
          onSearch={search => this.handleCardModalSearch(search, 'eixo')}
          toggle={() => this.toggleModal('eixos')}
          nenhumSelecionado={nenhumSelecionado}
        />

        <CardModal
          eixo={eixoAtual}
          competencia={competenciaAtual}
          title="Habilidades"
          breadcrumb={habilidadeBreadcrumb}
          cards={habilidades}
          activeCards={this.getActiveHabilidadeCards()}
          name="habilidade"
          isOpen={openModal === 'habilidades'}
          onCheckboxClick={this.handleHabilidadeCheckboxClick}
          onSearch={search => this.handleCardModalSearch(search, 'habilidade')}
          toggle={() => this.toggleModal('habilidades')}
        >
          <div className="eixo">
            <h5>EIXO COGNITIVO</h5>
            <p>{get(eixoAtual, 'descricao')}</p>
          </div>
          <div className="competencia">
            <h5>COMPETÊNCIA</h5>
            <p>{get(competenciaAtual, 'descricao')}</p>
          </div>
        </CardModal>

        <HabModal
          title="Matriz de referência"
          eixo={eixoAtual}
          competencia={competenciaAtual}
          habilidade={habilidadeAtual}
          isOpen={openModal === 'habilidade'}
          toggle={() => this.toggleModal('habilidade')}
          excluirHabilidade={this.excluirHabilidade}
        />
      </div>
    );
  }
}

MatrizReferencia.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default MatrizReferencia;
