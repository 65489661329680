import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import BaseListaTestes from '../../base/testes/BaseListaTestes';
import AddButton from '../../components/buttons/AddButton';
import { API_HOST } from '../../consts';
import NavBreadCrumb from '../../containers/NavBreadCrumb';

class ListaPreTestes extends BaseListaTestes {
  api = `${API_HOST}/testes/teste`;
  removeConfirmationMessage = id => `Tem certeza que deseja remover o pré-teste ${id}?`;
  params = { pre_teste: true };
  urlPrefix = 'pre-testes';

  renderBreadcrumb() {
    const currentPath = 'Pré-Teste';
    return <NavBreadCrumb currentPath={currentPath} hasBackslash={false} />;
  }

  renderAddButton() {
    return (
      <Link to="/pre-testes/new/dados">
        <AddButton icon="plus" title="Novo Pré-Teste" />
      </Link>
    );
  }

  renderToolbar() {
    const { search } = this.state;

    return (
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <label className="container-check-custom">
            <input
              type="checkbox"
              id="selecionar-todos"
              aria-label=""
              onChange={this.selecionarTodos}
            />
            <span className="checkmark" />
          </label>
          {this.renderAddButton()}
        </div>
        <div className="d-flex">
          <div className="search">
            <input
              className="search-term"
              placeholder="Pesquisar..."
              value={search}
              onChange={(event) => {
                const s = event.target.value;
                this.setState({ search: s });
                this.debouncedFetchData(s);
              }}
            />
          </div>
          {this.renderOrderingSelect()}
        </div>
      </div>
    );
  }
}

export default withRouter(ListaPreTestes);
