import $ from 'jquery';

const negativeMargin = (margin) => +margin.split('px')[0] < 0;
const getNewMargin = (oldMargin, offset) =>
  `${+oldMargin.split('px')[0] + offset}px`;

const ImageMarginButton =
  ({ offset, marginAttr, contents, tooltip }) =>
  (context) => {
    const { ui } = $.summernote;
    const button = ui.button({
      contents,
      tooltip,
      click: () => {
        const $editable = context.layoutInfo.editable;
        const img = $editable.data('target');
        const newMargin = getNewMargin(img.style[marginAttr], offset);
        img.style[marginAttr] = newMargin;
      }
    });
    return button.render();
  };

export const AdjustBothMarginsButton =
  ({ offset, contents, tooltip }) =>
  (context) => {
    const { ui } = $.summernote;
    const button = ui.button({
      contents,
      tooltip,
      click: () => {
        const $editable = context.layoutInfo.editable;
        const img = $editable.data('target');
        let newMarginTop = getNewMargin(img.style.marginTop, offset);
        let newMarginBottom = getNewMargin(img.style.marginBottom, offset);
        if (negativeMargin(newMarginTop)) {
          newMarginTop = '0px';
        }
        if (negativeMargin(newMarginBottom)) {
          newMarginBottom = '0px';
        }
        img.style.marginTop = newMarginTop;
        img.style.marginBottom = newMarginBottom;
      }
    });
    return button.render();
  };

export const ResetMarginButton = (context) => {
  const { ui } = $.summernote;
  const button = ui.button({
    contents: '<i class="fa fa-ban"></i>',
    tooltip: 'Remover Ajustes',
    click: () => {
      const $editable = context.layoutInfo.editable;
      const img = $editable.data('target');
      img.style.marginTop = '0';
      img.style.marginBottom = '0';
      img.style.margin = '0';
    }
  });
  return button.render();
};

export const EquationButton = (onOpened) => (context) => {
  const { ui } = $.summernote;
  const button = ui.button({
    contents: '<strong>&pi;</strong>',
    tooltip: 'Fórmula',
    click: () => {
      onOpened(context);
      context.invoke('editor.saveRange');
    }
  });
  return button.render();
};

export const VideoButton = (onOpened) => (context) => {
  const { ui } = $.summernote;
  const button = ui.button({
    contents: '<i class="fa fa-video-camera"/>',
    tooltip: 'Video',
    click: () => {
      onOpened(context);
      context.invoke('editor.saveRange');
    }
  });
  return button.render();
};

export const UpImageButton = ImageMarginButton({
  offset: 2,
  marginAttr: 'marginBottom',
  contents: '<i class="fa fa-arrow-up"></i>',
  tooltip: 'Ajustar para Cima'
});

export const DownImageButton = ImageMarginButton({
  offset: 2,
  marginAttr: 'marginTop',
  contents: '<i class="fa fa-arrow-down"></i>',
  tooltip: 'Ajustar para Baixo'
});

export const IncreaseBothMarginsButton = AdjustBothMarginsButton({
  offset: 2,
  contents: '<i class="fa fa-arrows-v"></i>',
  tooltip: 'Aumentar Espaçamento'
});

export const DecreaseBothMarginsButton = AdjustBothMarginsButton({
  offset: -2,
  contents: '<i class="fa fa-compress fa-rotate-315"></i>',
  tooltip: 'Diminuir Espaçamento'
});

export const ModalButton = (onOpened) => (context) => {
  const { ui } = $.summernote;
  const button = ui.button({
    contents: '<i class="fa fa-window-restore" aria-hidden="true"/>',
    tooltip: 'Modal imagem',
    click: () => {
      onOpened(context);
      context.invoke('editor.saveRange');
    }
  });
  return button.render();
};
