import React from 'react';
import DiagonalReference from '../components/DiagonalReference';
import ViewReference from '../components/ViewReference';
import HabReference from '../components/HabReference';

class ReferenceTable extends React.Component {
    static defaultProps = {
      eixos: [],
      competencias: [],
      habilidades: [],
      ech: [],
    };

    addHabilidade(competenciaIndex, eixoIndex) {
      const habilidade = {
        id_habilidade: 1,
        ds_habilidade: `habilidade ${competenciaIndex} ${eixoIndex}`,
      };
      let habilidades = [...this.props.habilidades][competenciaIndex][eixoIndex];

      if (Array.isArray(habilidades)) {
        habilidades.push(habilidade);
      } else {
        habilidades = [habilidade];
      }

      return habilidades;
    }

    renderHabilidades() {
      const {
        eixos, competencias, toggleModal, ech,
      } = this.props;

      return competencias.map((competencia) => {
        const competenciaNode = (
          <ViewReference
            key={competencia.id}
            col="4"
            color="card-comp"
            id={competencia.id}
            text={competencia.descricao}
          />
        );

        const habilidadeNodes = eixos.map((eixo) => {
          const habilidades = ech
            .filter(x => (x.competencia.id === competencia.id && x.eixo.id === eixo.id))
            .map(x => x.habilidade);

          return (
            <HabReference
              key={`e${eixo.id}c${competencia.id}`}
              color="card-comp"
              onAdd={this.addHabilidade}
              competencia={competencia}
              eixo={eixo}
              habilidades={habilidades}
              toggleModal={toggleModal}
            />
          );
        });

        return (
          <div className="d-flex" key={competencia.id}>
            {[competenciaNode].concat(habilidadeNodes)}
          </div>
        );
      });
    }

    render() {
      const { nenhumSelecionado } = this.props;
      let { eixos } = this.props;
      if (nenhumSelecionado) {
        eixos = eixos.filter(x => x.id === null);
      }

      return (
        <div className="overflow-horizontal">
          <div className="base-horizontal">
            <div className="d-flex">
              <DiagonalReference />
              {
                eixos.map(eixo => (
                  <ViewReference
                    key={eixo.id}
                    color="card-cog"
                    id={eixo.id}
                    text={eixo.descricao}
                  />
                  ))
                }
            </div>
            {this.renderHabilidades()}
          </div>
        </div>
      );
    }
}

export default ReferenceTable;
