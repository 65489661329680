import React from 'react';
import { get } from 'lodash-es';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import ReactSelect from 'react-select';
import { API_HOST } from '../../consts';
import InfoBox from '../../components/InfoBox';
import Loading from '../../components/Loading';
import RevisaoCard from '../../components/RevisaoCard';
import InfoBoxItem from '../../components/InfoBoxItem';
import NavBreadCrumb from '../../containers/NavBreadCrumb';
import Quiz from '../../components/Quiz';

const mapRevisao = (d) => ({
  value: d.id,
  label: `${
    get(d, 'ocultar_revisor') ? 'Revisado' : get(d, 'criado_por.last_name')
  } em ${get(d, 'finalizado_em')} - ${get(d, 'status_item.descricao')}`
});

class Revisao extends React.Component {
  state = {
    revisoes: [],
    fetchingRevisao: true
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.id) {
      this.fetchData();
    }
  }

  get id() {
    return get(this.props, 'match.params.id');
  }

  get breadcrumb() {
    const { revisao } = this.state;
    const item = get(revisao, 'item');
    return [
      {
        link: '/itens',
        label: 'Itens'
      },
      {
        link: `/itens?id=${item}`,
        label: `Item ${item}`
      },
      {
        link: `/revisoes?item=${item}`,
        label: 'Revisões'
      }
    ];
  }

  fetchData = () => {
    this.setState({ fetchingRevisao: true });
    this.fetchRevisao().then((data) => {
      Promise.all([
        this.fetchRevisoes(data),
        this.fetchQuestionario(data)
      ]).then(() => this.setState({ fetchingRevisao: false }));
    });
  };

  fetchRevisao = () => {
    return axios
      .get(`${API_HOST}/revisoes/revisao/${this.id}`)
      .then((response) => {
        this.setState({
          revisao: response.data
        });
        return response.data;
      });
  };

  fetchRevisoes = (revisao) => {
    return axios
      .get(`${API_HOST}/revisoes/revisao/summary`, {
        params: {
          item: revisao.item,
          ordering: '-finalizado_em',
          page_size: 100
        }
      })
      .then((response) => {
        this.setState({ revisoes: response.data.results.map(mapRevisao) });
      });
  };

  fetchQuestionario = (revisao) => {
    return axios
      .get(`${API_HOST}/questionarios/questionario/${revisao.questionario}`)
      .then((response) => {
        this.setState({
          questionario: response.data
        });
        return response.data;
      })
      .catch((error) => {
        const status = get(error, 'status.code');
        if (status === 404) {
          Promise.resolve();
        }
      });
  };

  handleRevisaoChange = (value) => {
    const { history } = this.props;
    const revisaoId = get(value, 'value');
    if (revisaoId) {
      history.push(`/revisoes/${revisaoId}`);
    }
  };

  render() {
    const { revisoes, revisao, questionario, fetchingRevisao } = this.state;

    return (
      <div className="container">
        <NavBreadCrumb
          pathList={this.breadcrumb}
          currentPath={`Revisão ${get(revisao, 'id')}`}
        />
        <RevisaoCard
          totalRevisoes={revisoes.length}
          item={get(revisao, 'item')}
        >
          <div>
            <div className="row mb-4">
              <div className="col-md-6">
                <ReactSelect
                  clearable={false}
                  options={revisoes}
                  value={get(revisao, 'id')}
                  onChange={this.handleRevisaoChange}
                />
              </div>
            </div>
            {fetchingRevisao && <Loading />}
            {!fetchingRevisao && (
              <React.Fragment>
                <InfoBox>
                  <div className="row">
                    <InfoBoxItem
                      label="Data de revisão"
                      value={get(revisao, 'finalizado_em')}
                    />
                    {get(revisao, 'ocultar_revisor') ? null : (
                      <InfoBoxItem
                        label="Revisor"
                        value={get(revisao, 'criado_por.last_name')}
                      />
                    )}
                    <InfoBoxItem
                      label="Item definido como"
                      value={get(revisao, 'status_item.descricao')}
                    />
                  </div>
                </InfoBox>
                <InfoBox text="Comentários">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: get(revisao, 'comentario')
                    }}
                  />
                </InfoBox>
                {questionario && (
                  <Quiz
                    quiz={questionario}
                    answers={get(revisao, 'respostas', [])}
                  />
                )}
              </React.Fragment>
            )}
          </div>
        </RevisaoCard>
      </div>
    );
  }
}

export default withRouter(Revisao);
