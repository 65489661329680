import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'informed';

class InputCheckbox extends React.Component {
  static propTypes = {
    col: PropTypes.string,
    label: PropTypes.string,
  };

  static defaultProps = {
    col: 'col-md-12',
    label: '',
  };

  render() {
    const { col, label, value, onChange, field, help } = this.props;
    return (
      <div className={`${col} form-group`}>
        <label className="container-check-custom">
          {label && <span className="ml-4"> {label} </span>}
          <Checkbox
            value={value}
            onChange={onChange}
            field={field}
          />
          <span className="checkmark" />
          {help && <small className="form-text text-muted">{help}</small>}
        </label>
      </div>
    );
  }
}

export default InputCheckbox;
