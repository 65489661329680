import React from 'react';
import { Form } from 'informed';
import axios from 'axios';
import { get, debounce } from 'lodash';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import InputSelect from '../components/inputs/InputSelect';
import InputSelectAsync from '../components/inputs/InputSelectAsync';
import InputTextArea from '../components/inputs/InputTextArea';
import InputText from '../components/inputs/InputText';
import InputSelectAsyncCreatable from '../components/inputs/InputSelectAsyncCreatable';
import InputDate from '../components/inputs/InputDate';
import InputSpan from '../components/inputs/InputSpan';
import { API_HOST } from '../consts';
import InputCheckbox from '../components/inputs/InputCheckbox';

const mapResponse = ({ id, descricao }) => ({
  value: id,
  label: descricao
});

const mapMatrizReferencia = ({ id, nome }) => ({
  value: id,
  label: nome
});

const mapEncomenda = mapMatrizReferencia;

class BaseFiltroAvancadoItem extends React.Component {
  constructor(props) {
    super(props);
    this.firstTimeOpening = true;
    this.onOpened = this.onOpened.bind(this);
  }
  state = {
    exame: [],
    area_conhecimento: [],
    criado_por: [],
    status: [],
    tipo: [],
    dificuldade: [],
    filtros: [],
    itens: [],
    showItens: false,
    itensChecked: [],
    metadata: []
  };

  metadataModels = ['matriz'];

  mapMetadata(metadata) {
    const mapped = {};
    metadata &&
      metadata
        .filter((d) => d.is_active === true)
        .forEach((m) => {
          mapped[`${m.model}.${m.name}`] = m.default;
        });
    return mapped;
  }

  shouldDisplayDefault(field) {
    const { metadata } = this.state;
    return get(metadata, field);
  }

  fetchMetadata() {
    this.setState({ fetchingMetadata: true });
    return axios
      .get(`${API_HOST}/params/param?model=${this.metadataModels.join('|')}`)
      .then((response) => {
        this.setState({
          metadata: this.mapMetadata(response.data),
          fetchingMetadata: false
        });
      });
  }

  onOpened() {
    // O timeout é para não deixar a animação do modal lenta
    setTimeout(() => {
      if (this.firstTimeOpening) {
        this.fetchExames();
        this.fetchAreasConhecimento();
        this.fetchCriadoPor();
        this.fetchStatus();
        this.fetchTipo();
        this.fetchDificuldades();
        this.fetchMetadata();
        this.firstTimeOpening = false;
      }
    }, 1000);

    const { initialValues } = this.props;
    this.formApi.setValues({ ...initialValues, ...this.values });
  }

  onCheckboxClick(_, id) {
    const { itensChecked } = this.state;
    itensChecked.push(id);
    this.setState({ itensChecked });
  }

  getValues(v) {
    const values = { ...v };
    this.asyncSelects.forEach((select) => {
      if (values[select]) {
        values[select] = get(values, `${select}.value`);
      }
    });
    return values;
  }

  asyncSelects = [
    'matriz_referencia',
    'competencia',
    'habilidade',
    'encomenda'
  ];

  fetchExames() {
    axios.get(`${API_HOST}/core/exame`).then((response) => {
      this.setState({
        exame: response.data.map((item) => ({
          value: item.id,
          label: item.descricao
        }))
      });
    });
  }

  fetchAreasConhecimento() {
    axios.get(`${API_HOST}/core/area_conhecimento`).then((response) => {
      this.setState({
        area_conhecimento: response.data.map((item) => ({
          value: item.id,
          label: item.descricao
        }))
      });
    });
  }

  fetchDificuldades() {
    axios.get(`${API_HOST}/itens/dificuldade`).then((response) => {
      this.setState({
        dificuldade: response.data.map((item) => ({
          value: item.id,
          label: item.descricao
        }))
      });
    });
  }

  fetchCompetencias = debounce((input, callback) => {
    axios
      .get(`${API_HOST}/matriz_referencia/competencia`, {
        params: {
          search: input
        }
      })
      .then((response) => {
        const options = response.data.results.map(mapResponse);
        callback(null, { options });
      });
  }, 1000);

  fetchCriadoPor() {
    axios.get(`${API_HOST}/itens/criado_por`).then((response) => {
      this.setState({
        criado_por: response.data.map((item) => ({
          value: item.id,
          label: `${item.first_name}  ${item.last_name}`
        }))
      });
    });
  }

  fetchStatus() {
    axios.get(`${API_HOST}/itens/status`).then((response) => {
      this.setState({
        status: response.data.map((item) => ({
          value: item.id,
          label: item.descricao
        }))
      });
    });
  }

  fetchTipo() {
    axios.get(`${API_HOST}/itens/tipo`).then((response) => {
      this.setState({
        tipo: response.data.map((item) => ({
          value: item.id,
          label: item.descricao
        }))
      });
    });
  }

  fetchMatrizReferencia = debounce((input, callback) => {
    axios
      .get(`${API_HOST}/matriz_referencia/matriz_referencia`, {
        params: {
          search: input,
          status: 1
        }
      })
      .then((response) => {
        const options = response.data.results.map(mapMatrizReferencia);
        callback(null, { options });
      })
      .catch((error) => console.error(error));
  }, 1000);

  fetchHabilidade = debounce((input, callback) => {
    axios
      .get(`${API_HOST}/matriz_referencia/habilidade`, {
        params: {
          search: input
        }
      })
      .then((response) => {
        const options = response.data.results.map(mapResponse);
        callback(null, { options });
      });
  }, 1000);

  fetchEncomendas = debounce((input, callback) => {
    axios
      .get(`${API_HOST}/encomenda/encomenda`, {
        params: {
          search: input
        }
      })
      .then((response) => {
        const options = response.data.results.map(mapEncomenda);
        callback(null, { options });
      });
  }, 1000);

  fetchPalavrasChave = debounce((input, callback) => {
    axios
      .get(`${API_HOST}/itens/palavra_chave`, {
        params: {
          search: input
        }
      })
      .then((response) => {
        const options = response.data.results.map(mapResponse);
        callback(null, { options });
      });
  }, 1000);

  fetchItens() {
    axios.get(`${API_HOST}/itens/item`).then((response) => {
      this.setState({
        itens: response.data.results.map((item) => ({
          id: item.id,
          descricao: item.situacao_problema
        })),
        showItens: true
      });
    });
  }

  toggle = () => {
    const { toggle } = this.props;
    this.values = this.formApi.getState().values;
    toggle();
  };

  handleSubmit = (values) => {
    this.buscarItens(this.getValues(values));
  };

  buscarItens(values) {
    return axios.get(`${API_HOST}/itens/item`, { params: values });
  }

  renderList() {
    return null;
  }

  renderFooter() {
    return null;
  }

  renderForm() {
    const { exame, area_conhecimento, criado_por, status, tipo, dificuldade } =
      this.state;

    return (
      <Form
        onChange={(formState) => this.setState({ formState })}
        onValueChange={this.onValueChange}
        onSubmit={this.handleSubmit}
        getApi={(formApi) => {
          this.formApi = formApi;
        }}
      >
        <div className="modal-separator">
          <h5>Dados Gerais</h5>
        </div>
        <div className="modal-body-inside">
          <div className="row mt-3">
            <InputText label="Código do Item" field="id" col="col-md-4" />
            <InputSelect
              label="Elaborador"
              col="col-md-4"
              field="criado_por"
              options={criado_por}
            />
            <InputSelect
              label="Tipo do Item"
              field="tipo"
              col="col-md-4"
              options={tipo}
            />
            <InputSelect
              label="Status do Item"
              field="status"
              col="col-md-4"
              options={status}
            />
            <InputSelect
              label="Exame"
              col="col-md-4"
              field="exame"
              options={exame}
            />
            <InputSelect
              label="Dificuldade Presumida"
              field="dificuldade"
              col="col-md-4"
              options={dificuldade}
            />
            <InputDate
              label="Itens criados a partir de"
              field="min_criado_em"
              type="date"
              col="col-md-4"
            />
            <InputDate
              label="Itens criados até"
              field="max_criado_em"
              type="date"
              col="col-md-4"
            />
            <InputCheckbox
              required
              col="col-sm-4 mt-4 pt-2"
              label="Mostrar somente itens nunca utilizados em avaliações"
              field="nunca_utilizados"
            />
          </div>
        </div>
        <div className="modal-separator">
          <h5>Conteúdo</h5>
        </div>
        <div className="row modal-body-inside">
          <InputSelect
            label="Área do Conhecimento"
            col="col-md-12"
            field="area_conhecimento"
            options={area_conhecimento}
          />
          <InputTextArea label="Enunciado" field="enunciado" col="col-md-12" />
          <InputTextArea
            label="Alternativa"
            field="alternativa"
            col="col-md-12"
          />
          <InputSelectAsyncCreatable
            label="Palavras-chave"
            field="palavras_chave"
            col="col-md-12"
            loadOptions={this.fetchPalavrasChave}
            autoload={true}
            multi
          />
        </div>
        <div className="modal-separator">
          <h5>Matriz de Referência</h5>
        </div>
        <div className="modal-body-inside">
          <div className="row">
            <InputSelectAsync
              autoload
              label="Matriz de Referência"
              field="matriz_referencia"
              col="col-md-12"
              loadOptions={this.fetchMatrizReferencia}
            />
            <InputSelectAsync
              autoload
              label={
                this.shouldDisplayDefault('matriz.competencia') || 'Competência'
              }
              col="col-md-12"
              field="competencia"
              loadOptions={this.fetchCompetencias}
            />
            <InputSelectAsync
              autoload
              label={
                this.shouldDisplayDefault('matriz.habilidade') || 'Habilidade'
              }
              field="habilidade"
              col="col-md-12"
              loadOptions={this.fetchHabilidade}
            />
          </div>
        </div>
        <div className="modal-separator">
          <h5>Encomenda</h5>
        </div>
        <div className="modal-body-inside">
          <div className="row">
            <InputSelectAsync
              label="Título"
              field="encomenda"
              col="col-md-6"
              loadOptions={this.fetchEncomendas}
              autoload={false}
            />
          </div>
        </div>
        <div className="modal-separator">
          <h5>Parâmetros da Análise TCT</h5>
        </div>
        <div className="modal-body-inside">
          <div className="row mt-3">
            <InputSpan
              col="col-4"
              field="min_bisserial"
              label="Bisserial"
              leftAddon="Mínimo"
            />
            <InputSpan col="col-4" field="max_bisserial" leftAddon="Máximo" />
          </div>
          <div className="row mt-3">
            <InputSpan
              col="col-4"
              field="min_discriminacao"
              label="Discriminação"
              leftAddon="Mínimo"
            />
            <InputSpan
              col="col-4"
              field="max_discriminacao"
              leftAddon="Máximo"
            />
          </div>
          <div className="row mt-3">
            <InputSpan
              col="col-4"
              field="min_dificuldade"
              label="Dificuldade (porcentagem de acertos)"
              leftAddon="Mínimo"
            />
            <InputSpan col="col-4" field="max_dificuldade" leftAddon="Máximo" />
          </div>
        </div>
        <div className="modal-separator">
          <h5>Parâmetros da Análise TRI</h5>
        </div>
        <div className="modal-body-inside">
          <div className="row mt-3">
            <InputSpan
              col="col-4"
              field="min_pa"
              label="A"
              leftAddon="Mínimo"
            />
            <InputSpan col="col-4" field="max_pa" leftAddon="Máximo" />
          </div>
          <div className="row mt-3">
            <InputSpan
              col="col-4"
              field="min_pb"
              label="B"
              leftAddon="Mínimo"
            />
            <InputSpan col="col-4" field="max_pb" leftAddon="Máximo" />
          </div>
          <div className="row mt-3">
            <InputSpan
              col="col-4"
              field="min_pc"
              label="C"
              leftAddon="Mínimo"
            />
            <InputSpan col="col-4" field="max_pc" leftAddon="Máximo" />
          </div>
        </div>
      </Form>
    );
  }

  render() {
    const { isOpen } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        toggle={this.toggle}
        size="lg"
        onOpened={() => this.onOpened()}
        onClosed={this.onExit}
      >
        <ModalHeader toggle={this.toggle}>Buscar itens</ModalHeader>
        <ModalBody className="p-0">{this.renderForm()}</ModalBody>
        <ModalFooter>{this.renderFooter()}</ModalFooter>
      </Modal>
    );
  }
}

export default BaseFiltroAvancadoItem;
