import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';


class AnaliseReportTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: this.props.rows,
      coloredColumn: 'posicao',
      handleSorting: this.props.handleSorting,
    };
  }

  sortByKey = (key) => {
    const { rows, handleSorting } = this.state;

    rows.sort((a, b) => {
      return a[key] - b[key];
    });

    this.setState({ rows });
    handleSorting(rows)
  }

  cellColor = (item) => {
    const { coloredProperty } = this.props;
    return coloredProperty ? item[`cor_${coloredProperty}`] : null;
  }

  render() {
    const { columns } = this.props;
    const { rows, coloredColumn } = this.state;
    return (
      <Table striped>
        <thead>
          <tr>
            {columns.map((header) => {
              return (
                <th onClick={() => this.sortByKey(header.key)} key={header.key} >
                  {header.title}{' '}
                  {header.order_by ? <i className="fas fa-sort-down" /> : null}
                </th>
              );
          })}
          </tr>
        </thead>
        <tbody className="text-right">
          {rows.map((row) => {
            return (
              <tr key={row.id}>
                {columns.map((col) => {
                  return (
                    <td key={`${row.id}-${col.key}`}>
                      <div style={{
                              backgroundColor: col.key === coloredColumn ? this.cellColor(row) : null,
                              textAlign: 'center',
                            }}
                      >
                        {row[col.key]}
                      </div>
                    </td>
                    );
              })
            }
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }
}

AnaliseReportTable.propTypes = {
  columns:PropTypes.array,
  rows: PropTypes.array,
  coloredProperty: PropTypes.string,
  coloredColumn: PropTypes.string,
  handleSorting: PropTypes.func,
};

AnaliseReportTable.defaultProps = {
  columns: [],
  rows: [],
  coloredProperty: '',
  coloredColumn: '',
  handleSorting: () => {},
};

export default AnaliseReportTable;
