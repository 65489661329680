import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

export default class GerarPinModal extends React.Component {
  render() {
    const { toggle, isOpen, number } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        size="sm"
      >
        <ModalHeader toggle={toggle}>
          PIN
        </ModalHeader>
        <ModalBody style={{borderRadius: '0px 0px 5px 5px'}} className="p-0">

          <h1 className="pin">{number}</h1>
        </ModalBody>
      </Modal>
    );
  }
}
