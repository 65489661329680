import React from 'react';
import axios from 'axios';
import { get } from 'lodash';
import withRouter from 'react-router-dom/withRouter';
import { Form } from 'informed';
import { toast } from 'react-toastify';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import {
  InputText,
  InputSelect,
  InputMaskDateTime,
  InputMaskDuration,
} from '../../components/inputs';
import BaseCreate from '../../base/BaseCreate';
import { API_HOST } from '../../consts';

class AgendarAplicacaoModal extends BaseCreate {
  state = {
    cursos: [],
    instituicoes: [],
    disciplinas: [],
    midias: [],
  }

  api = `${API_HOST}/aplicacoes/aplicacao`
  fields = ['nome', 'data', 'duracao', 'instituicao', 'curso', 'periodo', 'disciplina', 'midias']
  shouldFetchApis = true

  componentDidMount() {
  }

  onOpened = () => {
    if (this.shouldFetchApis) {
      this.shouldFetchApis = false;
      this.fetchApi({ link: '/core/instituicao', name: 'instituicoes' });
      this.fetchApi({ link: '/core/curso', name: 'cursos' });
      this.fetchApi({ link: '/core/periodo', name: 'periodos' });
      this.fetchApi({ link: '/core/disciplina', name: 'disciplinas' });
      this.fetchApi({ link: '/aplicacoes/midia', name: 'midias' });
    }

    this.fetchData();
  }

  fetchData() {
    if (this.id === 'new') {
      const { aplicacao } = this.props;
      const avaliacao = get(aplicacao, 'avaliacao');
      this.formApi.setValues({ avaliacao });
    } else {
      axios.get(`${API_HOST}/aplicacoes/aplicacao/${this.id}/form`)
        .then((response) => {
          const values = {};
          this.fields.forEach((d) => {
            values[d] = response.data[d];
          });
          this.formApi.setValues(values);
        });
    }
  }

  handleSubmitCallback = () => {
    const { toggle, fetchAplicacoes } = this.props;
    const created = this.id === 'new';
    const message = created ? 'Aplicação criada com sucesso.' : 'Aplicação atualizada com sucesso.';
    toast.success(message);
    toggle();
    fetchAplicacoes();
  }

  get id() {
    const { aplicacao } = this.props;
    return get(aplicacao, 'id');
  }

  renderBody() {
    const {
      instituicoes,
      cursos,
      periodos,
      disciplinas,
      midias,
    } = this.state;

    return (
      <React.Fragment>
        <div className="row p-4">
          <InputText
            required
            col="col-sm-12"
            label="Título"
            field="nome"
          />
          <InputSelect
            label="Instituição"
            col="col-md-6"
            field="instituicao"
            options={instituicoes}
          />
          <InputSelect
            label="Nível/Curso"
            col="col-md-6"
            field="curso"
            options={cursos}
          />
          <InputSelect
            label="Ano/Período"
            col="col-md-6"
            field="periodo"
            options={periodos}
          />
          <InputSelect
            label="Eixo/Disciplina"
            col="col-md-6"
            field="disciplina"
            options={disciplinas}
          />
          <InputMaskDateTime
            required
            label="Horário"
            field="data"
            col="col-md-4"
          />
          <InputMaskDuration
            required
            col="col-sm-4"
            label="Duração"
            field="duracao"
          />
          <InputSelect
            multi
            label="Mídia onde será executada a aplicação"
            col="col-md-12"
            field="midias"
            options={midias}
          />
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { toggle, isOpen, submitting } = this.props;

    return (
      <Form
        getApi={(api) => { this.formApi = api; }}
        onSubmit={this.handleSubmit}
      >
        <Modal
          isOpen={isOpen}
          onOpened={this.onOpened}
          toggle={toggle}
          size="lg"
        >
          <ModalHeader toggle={toggle}>
            Agendar Aplicação
          </ModalHeader>
          <ModalBody className="p-0">
            {this.renderBody()}
          </ModalBody>
          <ModalFooter>
            <button type="button" className="btn btn-white" onClick={toggle}>Fechar</button>
            <button
              type="button"
              className="btn primary-button"
              onClick={this.handleNext}
              disabled={submitting}
            >
              {submitting ? 'Agendando...' : 'Agendar'}
            </button>
          </ModalFooter>
        </Modal>
      </Form>
    );
  }
}

export default withRouter(AgendarAplicacaoModal);
