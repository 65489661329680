import axios from 'axios';
import debounce from 'debounce-promise';
import { get } from 'lodash-es';
import queryString from 'query-string';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import BaseList from '../../base/BaseList';
import AddButton from '../../components/buttons/AddButton';
import RevisaoCard from '../../components/RevisaoCard';
import StatusColor from '../../components/StatusColor';
import Table from '../../components/Table';
import { API_HOST } from '../../consts';
import NavBreadCrumb from '../../containers/NavBreadCrumb';
import withUser from '../../hoc/withUser';
import { getOrdering, parseFiltered } from '../../utils';

class ListaRevisoes extends BaseList {
  state = {
    data: [],
    search: '',
    page_size: 6,
    page: 1,
    fetchingData: true,
    sorted: [{ id: 'finalizado_em', desc: 'desc' }],
    ordering: '-finalizado_em',
    revisoesEmAndamento: [],
    itensAguardandoRevisao: [],
    tiposRevisao: [
      { id: 1, nome: 'Padrão' },
      { id: 2, nome: 'Técnica' },
      { id: 3, nome: 'Linguística' }
    ],
    statusesItem: [
      { id: 3, nome: 'Ativo' },
      { id: 4, nome: 'Inativo' },
      { id: 5, nome: 'Reprovado' }
    ]
  };

  componentDidMount() {
    this.params = { item: this.itemId };
    this.fetchData();
    // this.fetchRevisoesEmAndamento();
  }

  retornarRevisaoFila = async (revisaoId) => {
    this.setState({ retornandoRevisaoFila: true });
    try {
      await axios.post(
        `${API_HOST}/revisoes/revisao/${revisaoId}/retornar_fila`
      );
      toast.success('Revisão retornada para a fila.');
      // this.fetchRevisoesEmAndamento();
    } catch (_) {
      toast.error('Não foi possível retornar a revisão para a fila.');
    } finally {
      this.setState({ retornandoRevisaoFila: false });
    }
  };

  get itemId() {
    const { location } = this.props;
    const params = queryString.parse(location.search);
    return params.item || undefined;
  }

  get breadcrumb() {
    const steps = [
      {
        link: '/itens',
        label: 'Itens'
      }
    ];

    if (this.itemId) {
      steps.push({
        link: `/itens?id=${this.itemId}`,
        label: `Item ${this.itemId}`
      });
    }

    return steps;
  }

  get itemColumns() {
    const { tiposRevisao } = this.state;
    let columns = [
      {
        Header: 'Item',
        accessor: 'item.id',
        id: 'item',
        Cell: (row) => <Link to={`/itens?id=${row.value}`}>{row.value}</Link>,
        width: 80
      },
      {
        Header: 'Tipo de revisão',
        accessor: 'tipo.descricao',
        id: 'tipo_id',
        sortable: true,
        Filter: ({ filter, onChange }) => (
          <select
            onChange={(event) => onChange(event.target.value)}
            value={filter ? filter.value : 'all'}
          >
            <option value="all">Todos</option>
            {tiposRevisao.map((d) => (
              <option key={d.id} value={d.id}>
                {d.nome}
              </option>
            ))}
          </select>
        )
      },
      {
        Header: 'Encomenda',
        accessor: 'encomenda.nome',
        id: 'encomenda',
        sortable: true,
        filterable: false
      },
      {
        Header: 'Status',
        accessor: 'item.status_id',
        id: 'status_id',
        sortable: false,
        filterable: false,
        Cell: (row) => (
          <StatusColor
            color={get(row, 'value.cor')}
            text={get(row, 'value.descricao')}
          />
        )
      }
    ];

    return columns;
  }

  get columns() {
    const { tiposRevisao, statusesItem } = this.state;

    let columns = [
      {
        Header: 'Finalizada em',
        accessor: 'finalizado_em'
      },
      {
        Header: 'Tipo de revisão',
        accessor: 'tipo.descricao',
        id: 'tipo_id',
        Filter: ({ filter, onChange }) => (
          <select
            onChange={(event) => onChange(event.target.value)}
            value={filter ? filter.value : 'all'}
          >
            <option value="all">Todos</option>
            {tiposRevisao.map((d) => (
              <option key={d.id} value={d.id}>
                {d.nome}
              </option>
            ))}
          </select>
        )
      },
      {
        Header: 'Encomenda',
        accessor: 'encomenda.nome',
        id: 'encomenda_id',
        sortable: true,
        filterable: false
      },
      {
        Header: 'Revisor',
        accessor: 'criado_por.last_name',
        id: 'criado_por',
        Cell: (row) => (
          <div className="d-flex">
            {row.original.ocultar_revisor ? null : (
              <span>{row.original.criado_por.last_name}</span>
            )}
          </div>
        )
      },
      {
        Header: 'Status',
        accessor: 'status_item',
        id: 'status_item_id',
        Cell: (row) => (
          <StatusColor
            color={get(row, 'value.cor')}
            text={get(row, 'value.descricao')}
          />
        ),
        Filter: ({ filter, onChange }) => (
          <select
            onChange={(event) => onChange(event.target.value)}
            value={filter ? filter.value : 'all'}
          >
            <option value="all">Todos</option>
            {statusesItem.map((d) => (
              <option key={d.id} value={d.id}>
                {d.nome}
              </option>
            ))}
          </select>
        )
      },
      {
        Header: 'Ações',
        width: 80,
        accessor: 'id',
        sortable: false,
        filterable: false,
        Cell: (row) => (
          <div className="d-flex">
            <Link className="btn btn-white" to={`/revisoes/${row.value}`}>
              <i className="fas fa-eye" />
            </Link>
          </div>
        )
      }
    ];

    if (!this.itemId) {
      columns = [
        {
          Header: 'Item',
          accessor: 'item',
          id: 'item',
          Cell: (row) => <Link to={`/itens?id=${row.value}`}>{row.value}</Link>,
          width: 80
        },
        ...columns
      ];
    }

    return columns;
  }

  get revisoesEmAndamentoColumns() {
    return [
      ...this.columns.filter(
        (d) => d.accessor !== 'finalizado_em' && d.accessor !== 'id'
      ),
      {
        Header: 'Ações',
        width: 100,
        accessor: 'id',
        sortable: false,
        filterable: false,
        className: 'justify-content-center',
        Cell: (row) => (
          <div className="d-flex">
            {this.props.hasPermission('revisoes.change_revisao') && (
              <Link
                className="btn btn-white"
                to={`/revisoes/${row.value}/edit`}
              >
                <i className="fas fa-edit" />
              </Link>
            )}
            {this.props.hasPermission('revisoes.retornar_fila') && (
              <>
                <UncontrolledTooltip
                  delay={0}
                  placement="right"
                  target={`retornar-revisao-fila-${row.value}`}
                >
                  retornar para fila
                </UncontrolledTooltip>
                <button
                  id={`retornar-revisao-fila-${row.value}`}
                  type="button"
                  disabled={this.state.retornandoRevisaoFila}
                  className="btn btn-white"
                  onClick={() => this.retornarRevisaoFila(row.value)}
                >
                  <i className="fas fa-undo" />
                </button>
              </>
            )}
          </div>
        )
      }
    ];
  }

  maisRecentes = '-finalizado_em';
  maisAntigos = 'finalizado_em';
  api = `${API_HOST}/revisoes/revisao/summary?em_andamento=false`;

  mapFiltered = (filtered) => {
    return filtered.reduce((acc, filter) => {
      return {
        ...acc,
        [filter.id]: filter.value === 'all' ? undefined : filter.value
      };
    }, {});
  };

  fetchRevisoesEmAndamento = debounce(({ page, pageSize, filtered }) => {
    this.setState({ fetchingRevisoesEmAndamento: true });

    axios
      .get(`${API_HOST}/revisoes/revisao/summary`, {
        params: {
          em_andamento: true,
          item: this.itemId,
          page: page + 1,
          page_size: pageSize,
          ...this.mapFiltered(filtered)
        }
      })
      .then((response) => {
        this.setState({
          revisoesEmAndamento: response.data.results,
          totalRevisoesEmAndamento: response.data.count,
          fetchingRevisoesEmAndamento: false
        });
      })
      .catch(() => {
        this.setState({ fetchingRevisoesEmAndamento: false });
      });
  }, 500);

  fetchItensAguardandoRevisao = debounce(({ page, pageSize, filtered }) => {
    this.setState({ fetchingItensAguardandoRevisao: true });

    axios
      .get(`${API_HOST}/revisoes/revisao/summary_item_revisao`, {
        params: {
          item: this.itemId,
          page: page + 1,
          page_size: pageSize,
          ...this.mapFiltered(filtered)
        }
      })
      .then((response) => {
        this.setState({
          itensAguardandoRevisao: response.data.results,
          totalItensAguardandoRevisao: response.data.count,
          fetchingItensAguardandoRevisao: false
        });
      })
      .catch(() => {
        this.setState({ fetchingItensAguardandoRevisao: false });
      });
  }, 500);

  // fetchData = () => {
  //   const { page, sorted } = this.state;
  //   const ordering = getOrdering(sorted);

  //   axios.get(`${API_HOST}/revisoes/revisao/summary`, {
  //     params: {
  //       page,
  //       ordering,
  //       item: this.itemId,
  //       page_size: 10,
  //     },
  //   })
  //     .then((response) => {
  //       this.setState({
  //         data: response.data.results,
  //         total_pages: response.data.total_pages,
  //         total: response.data.count,
  //         page_size: response.data.results.length,
  //         fetchingData: false,
  //       });
  //     });
  // }

  handleFilteredChange = (filtered) => {
    const { ordering } = this.params;
    this.params = { ordering, item: this.itemId, ...parseFiltered(filtered) };
    this.setState({ filtered, page: 1 }, this.debouncedFetchData);
  };

  handleSortedChange = (sorted) => {
    this.params = { ...this.params, ordering: getOrdering(sorted) };
    this.setState({ sorted }, this.debouncedFetchData);
  };

  handleRevisarItem = () => {
    axios
      .get(`${API_HOST}/revisoes/revisao/iniciar_revisao`)
      .then((response) => {
        this.props.history.push(`/revisoes/${response.data.id}/edit`);
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          toast.warn('Não há nenhum item disponível para revisão.');
        } else {
          toast.error(
            'Não foi possível iniciar uma revisão. Por favor, tente novamente.'
          );
        }
      });
  };

  renderToolbar() {
    return (
      <div className="d-flex justify-content-between mb-3">
        <div className="d-flex">
          {/* <Link className="btn btn-white font-weight-bold" to="/itens">
            Voltar aos itens
          </Link> */}
          <AddButton onClick={this.handleRevisarItem} title="Nova revisão" />
        </div>
      </div>
    );
  }

  renderList() {
    const {
      data,
      page_size,
      page,
      fetchingData,
      sorted,
      total_pages,
      total,
      revisoesEmAndamento,
      totalRevisoesEmAndamento,
      fetchingRevisoesEmAndamento,
      totalItensAguardandoRevisao,
      itensAguardandoRevisao,
      fetchingItensAguardandoRevisao
    } = this.state;

    return (
      <div>
        <RevisaoCard
          title="Itens aguardando revisão"
          totalItensAguardandoRevisao={totalItensAguardandoRevisao}
          item={this.itemId}
        >
          <Table
            manual
            filterable
            onFetchData={this.fetchItensAguardandoRevisao}
            data={itensAguardandoRevisao}
            columns={this.itemColumns}
            loading={fetchingItensAguardandoRevisao}
            pages={totalItensAguardandoRevisao}
            defaultPageSize={page_size}
          />
        </RevisaoCard>
        <div className="mt-3" />
        <RevisaoCard
          title="Revisões em andamento"
          totalRevisoes={revisoesEmAndamento.length}
          item={this.itemId}
        >
          <Table
            manual
            filterable
            onFetchData={this.fetchRevisoesEmAndamento}
            data={revisoesEmAndamento}
            columns={this.revisoesEmAndamentoColumns}
            loading={fetchingRevisoesEmAndamento}
            pages={totalRevisoesEmAndamento}
            defaultPageSize={page_size}
          />
        </RevisaoCard>
        <div className="mt-3" />
        <RevisaoCard
          title="Revisões finalizadas"
          totalRevisoes={total}
          item={this.itemId}
        >
          <Table
            manual
            filterable
            showPageSizeOptions={false}
            showPagination={false}
            data={data}
            columns={this.columns}
            pages={total_pages}
            page={page}
            loading={fetchingData}
            pageSize={page_size || 10}
            sorted={sorted}
            onSortedChange={this.handleSortedChange}
            onFilteredChange={this.handleFilteredChange}
          />
        </RevisaoCard>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <NavBreadCrumb
          pathList={this.breadcrumb}
          currentPath="Revisões"
          hasBackslash={false}
        />
        <div className="container pb-3">
          {this.renderToolbar()}
          {this.renderList()}
          <div className="mb-2" />
          {this.renderPagination()}
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(withUser(ListaRevisoes));
