import React from 'react';
import PropTypes from 'prop-types';

const QuizQuestion = ({ text, value }) => (
  <div className="quiz-question">
    <div className="row">
      <div className="col-md-10 col-sm-6 col-xs-12">
        {text}
      </div>
      <div className="col-md-1 col-sm-3 col-xs-6">
        <i className={`${value === true ? 'fas fa-check-circle' : 'far fa-circle'}`} /> Sim
      </div>
      <div className="col-md-1 col-sm-3 col-xs-6">
        <i className={`${value === false ? 'fas fa-times-circle' : 'far fa-circle'}`} /> Não
      </div>
    </div>
  </div>
);

QuizQuestion.propTypes = {
  text: PropTypes.node,
  value: PropTypes.bool,
};

QuizQuestion.defaultProps = {
  text: '',
  value: null,
};

export default QuizQuestion;
