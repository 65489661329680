import React from 'react';
import { get, uniqBy } from 'lodash';

const getStepProgress = (form) => {
  const stepFilledInputs = Object.keys(form).reduce((x, input) => {
    if (Array.isArray(form[input])) {
      return x + (form[input].length > 0 ? 1 : 0);
    }

    return x + (form[input] ? 1 : 0);
  }, 0);
  const stepInputs = Object.keys(form).length;
  return (100 * stepFilledInputs) / stepInputs;
};

export const getProgress = (forms) => {
  const numberOfForms = forms.length;
  const progress = forms.filter(form => form).reduce((totalProgress, form) => {
    if (Array.isArray(form)) {
      if (form.length > 0) {
        const itemProgresses = form.map(getStepProgress);
        const totalItemProgress = itemProgresses.reduce((x, itemProgress) => x + itemProgress, 0);
        const stepProgress = totalItemProgress / form.length;
        return totalProgress + (stepProgress / numberOfForms);
      }

      return totalProgress;
    }

    const stepProgress = getStepProgress(form);
    return totalProgress + (stepProgress / numberOfForms);
  }, 0);

  return Math.trunc(progress);
};

export const orderByDate = array => (
  array.sort(() => 1)
);

export const getStatusColor = className => ({
  primary: '#15AEF1',
  success: '#69CE2D',
  warning: '#F1B115',
  danger: '#ED1A0D',
  default: '#fff',
}[className]);

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const getItemTitle = item => (
  `Item ${get(item, 'id_item')}`
);

export const removeDuplicates = (array, key) => (
  array.filter((thing, index, self) => self.findIndex(t => t[key] && t[key] === thing[key]) === index)
);

export const getErrorList = (error) => {
  const status = get(error, 'response.status');
  if (status === 400) {
    const errors = get(error, 'response.data');
    let e = [];
    Object.keys(errors).forEach((field) => {
      e = e.concat(errors[field]);
    });
    return e;
  }

  if (status !== 403) {
    return ['Ocorreu um erro desconhecido'];
  }
};

export const getErrorToast = error => (
  <ul>
    {getErrorList(error).map(e => <li key={e}>{e}</li>)}
  </ul>
);

const checkDuplicates = d => d.value;

export const getCompetencias = (matrizes_referencia, m) => {
  const matriz_referencia_id = get(m, 'matriz_referencia');

  if (!matriz_referencia_id) {
    return [];
  }

  const matriz_referencia = matrizes_referencia.find(d => d.id === +matriz_referencia_id);
  if (!matriz_referencia) {
    return [];
  }

  const ech = get(matriz_referencia, 'ech') || [];
  return uniqBy(ech
    .map(d => ({
      value: get(d, 'competencia.id'),
      label: get(d, 'competencia.descricao'),
    }))
    .filter(d => d), checkDuplicates);
};

export const getHabilidades = (matrizes_referencia, m, c) => {
  const matriz_referencia_id = get(m, 'matriz_referencia');

  if (!matriz_referencia_id) {
    return [];
  }

  const competencia_id = get(c, 'competencia');

  if (!competencia_id) {
    return [];
  }

  const matriz_referencia = matrizes_referencia.find(d => d.id === +matriz_referencia_id);
  if (!matriz_referencia) {
    return [];
  }

  const ech = get(matriz_referencia, 'ech') || [];
  return uniqBy(ech
    .filter(d => get(d, 'competencia.id') === competencia_id)
    .map(d => ({
      value: get(d, 'habilidade.id'),
      label: get(d, 'habilidade.descricao'),
    }))
    .filter(d => d), checkDuplicates);
};

export const getOrdering = sorted => (
  sorted.map(field => `${field.desc ? '-' : ''}${field.id}`).join(',')
);

export const getFilters = (filtered) => {
  const filters = {};
  filtered.forEach((d) => {
    filters[d.id] = d.value;
  });
  return filters;
};

export const tableTextFilterMethod = (filter, row) => {
  const { id, value } = filter;
  const text = row[id].toString().toUpperCase();
  const search = value.toUpperCase();
  return text.includes(search);
};

const isDate = obj => Object.prototype.toString.call(obj) === '[object Date]';

const addLeadingZero = d => (d < 10 ? `0${d}` : d);

export const formatDateTime = (obj) => {
  try {
    let date;
    if (isDate(date)) {
      date = obj;
    } else {
      date = obj.replace('GMT', '');
      date = new Date(date);
    }
    const year = date.getFullYear();
    const day = addLeadingZero(date.getDate());
    const month = addLeadingZero(date.getMonth() + 1);
    const hours = addLeadingZero(date.getHours());
    const minutes = addLeadingZero(date.getMinutes());
    return `${day}/${month}/${year} às ${hours}:${minutes}`;
  } catch (_) {
    return obj;
  }
};

export const formatDate = (obj) => {
  try {
    let date;
    if (isDate(date)) {
      date = obj;
    } else {
      date = new Date(obj);
    }
    const year = date.getUTCFullYear();
    const day = addLeadingZero(date.getUTCDate());
    const month = addLeadingZero(date.getUTCMonth() + 1);
    return `${day}/${month}/${year}`;
  } catch (_) {
    return obj;
  }
};

export const parseFiltered = filtered => filtered.reduce((a, b) => {
  a[b.id] = b.value;
  return a;
}, {});

export const downloadJson = (json, filename) => {
  /* Receives a json object and a file name string and with this data downloads the json file */
  const url = window.URL.createObjectURL(new Blob([JSON.stringify(json)]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${filename}.json`);
  document.body.appendChild(link);
  link.click();
};

export const rgbToArgb = (hexRgb = '#d3d3d3', alpha = 'FF') => {
  // Removes #, turn into upper case and add alpha
  const normalizedRgb = hexRgb.substring(1).toUpperCase();
  return `${alpha}${normalizedRgb}`;
};
