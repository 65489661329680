import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import AddButton from '../../components/buttons/AddButton';
import NavBreadCrumb from '../../containers/NavBreadCrumb';
import BaseList from '../../base/BaseList';
import { API_HOST } from '../../consts';
import AvaliacaoItem from './AvaliacaoItem';
import { fetchTemplates } from './services/template_service';

class ListaAvaliacao extends BaseList {
  api = `${API_HOST}/avaliacoes/avaliacao`;

  state = {
    data: [],
    ordering: '-criado_em',
    templates: [],
  }

  fetchDataCallback() {
    fetchTemplates()
      .then(response => this.setState({
        templates: response,
      }));
  }

  removeConfirmationMessage = selectedForRemovalId => `Tem certeza que deseja remover a avaliação ${selectedForRemovalId}?`

  renderBreadcrumb = () => {
    const currentPath = 'Instrumentos cognitivos';
    return <NavBreadCrumb currentPath={currentPath} hasBackslash={false} />;
  }

  renderAddButton() {
    return (
      <Link to="/instrumentos-cognitivos/new/dados">
        <AddButton icon="plus" title="Novo instrumento cognitivo" />
      </Link>
    );
  }

  renderToolbar() {
    const { search } = this.state;

    return (
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <label className="container-check-custom">
            <input
              type="checkbox"
              id="selecionar-todos"
              aria-label=""
              onChange={this.selecionarTodos}
            />
            <span className="checkmark" />
          </label>
          {this.renderAddButton()}
        </div>

        <div className="d-flex">
          <div className="search mr-2">
            <input
              className="search-term"
              placeholder="Pesquisar..."
              value={search}
              onChange={(event) => {
                const s = event.target.value;
                this.setState({ search: s });
                this.debouncedFetchData(s);
              }}
            />
          </div>
          <div className="btn-group" role="group" aria-label="Filtro">
            <Link to="/instrumentos-cognitivos/" >
              <button type="button" className="btn btn-white selected-option radius-5-0">Por Avaliação</button>
            </Link>
            <Link to="/aplicacoes">
              <button type="button" className="btn btn-white radius-0-5">Por Aplicação</button>
            </Link>
          </div>
          {this.renderOrderingSelect()}
        </div>
      </div>
    );
  }


  renderList() {
    const { data, templates } = this.state;
    return data.map(avaliacao => (
      <AvaliacaoItem key={avaliacao.id} avaliacao={avaliacao} onRemove={this.handleRemove} onCopy={this.handleCopy} templates={templates} />
    ));
  }
}

export default withRouter(ListaAvaliacao);
