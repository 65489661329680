import React from 'react';

class EtapaInicialCalibracao extends React.Component {
  columns = 'a epa b epb c epc'.split(' ');

  renderRow(d, i) {
    if (d.a === undefined) {
      return (
        <tr className="bg-danger">
          <td>{d._row}</td>
          <td colSpan="7" className="anulado-tri">
            <i className="fas fa-minus-circle wrong" /> ITEM ANULADO PELA TRI
          </td>
        </tr>
      );
    }

    return (
      <tr key={`${d.id}${i}`}>
        <td>{d._row}</td>
        {this.columns.map((c) => (
          <td key={c}>{d[c] ? d[c].toLocaleString('pt-BR') : d[c]}</td>
        ))}
      </tr>
    );
  }

  render() {
    const { data } = this.props;

    return (
      <table className="table table-etapa">
        <thead>
          <tr>
            <th>Item</th>
            <th>a</th>
            <th>EP(a)</th>
            <th>b</th>
            <th>EP(b)</th>
            <th>c</th>
            <th>EP(c)</th>
          </tr>
        </thead>
        <tbody>{data.map((d, i) => this.renderRow(d, i))}</tbody>
      </table>
    );
  }
}

export default EtapaInicialCalibracao;
