import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ModalResolvedComentario = ({ isModalOpen, handleModal, handleComentarioResolvido, resolvido }) => {
	const toggle = () => handleModal(!isModalOpen);

	return (
		<Modal
			isOpen={isModalOpen}
			toggle={toggle}
			centered={true}>
			<ModalHeader>Atenção</ModalHeader>
			<ModalBody>
				Deseja marcar este comentário como {resolvido ? `pendente` : 'resolvido'}?
			</ModalBody>
			<ModalFooter>
				<button type="submit" className='btn btn-primary' onClick={handleComentarioResolvido}>
					Confirmar
				</button>
				<button className='btn btn-secondary' onClick={toggle}>
					Cancelar
				</button>
			</ModalFooter>
		</Modal >
	);
};

export default ModalResolvedComentario;
