import favicon from '../assets/images/favicon.ico';

const defaultTheme = {
  logoImg: null,
  favicon,
  primary: '#006591',
  secondary: '#033849',
  leftCard: '#006591',
  rightCard: '#034a69',
  button: '#006591',
  buttonLight: '#006591',
  buttonSecondary: '#006591',
  buttonHover: '#006591',
  buttonLightHover: '#006591',
  burronSecondaryHover: '#006591',
  primaryAccent: '#034a69',
  action: '#006591',
  light: '#45c0f5',
  lightAccent: '#13b2b9',
  accent: '#c8700a',
  title: '#23607a',
  hover: 'rgba(86, 207, 226, 0.22)',
  icon: '#829fac',
  black: '#383838',
  miniBlack: '#5a5a5a',
  textCinza: '#797979',
  cinzaIcon: '#7c7c7c',
  cinza: '#898989',
  border: '#bfbfbf',
  cinzaD6: '#d6d6d6',
  menu: '#d8d8d8',
  whiteCinza: '#f2f2f2',
  bgModal: '#f7f7f7',
  bgWhite: '#fcfcfc',
  pure_white: '#fff',
  pink: '#ef98ef',
  danger: '#db6969',
  ama: '#f5ad5a',
  warning: '#e59e00',
  success: '#89b218',
  check: '#28a745',
  successBackground: '#d9e6d8',
};

export default defaultTheme;
