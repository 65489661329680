import React from 'react';
import { BreadcrumbItem } from 'reactstrap';

export default class BreadCrumbActive extends React.Component {
	render() {
		const { hasBackslash, label } = this.props;
		return (
			<BreadcrumbItem
				className={`${hasBackslash ? 'breadcrumb-active' : 'breadcrumb-active-nobackslash'}`}
				active
			>
				{label}
			</BreadcrumbItem>
		)
	}
};
