import React from 'react';
import PropTypes from 'prop-types';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';

export default class PopoverMap extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    target: PropTypes.string.isRequired,
  };

  state = {
    isOpen: this.props.isOpen,
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ isOpen: nextProps.isOpen });
  }

  render() {
    const { estado, ibge, elaboradores, target } = this.props;
    const { isOpen } = this.state;

    return (
      <Popover className="popover-map" placement="top" isOpen={isOpen} target={target}>
        <div className="card-body">
          <PopoverHeader>{estado}</PopoverHeader>
          <PopoverBody>
            <div className="inf-popover-map">
              <p>ID IBGE:</p>
              <p>{ibge}</p>
            </div>
            <div className="inf-popover-body">
              <p>Elaboradores</p>
              <p>{elaboradores}</p>
            </div>
          </PopoverBody>
        </div>
      </Popover>
    );
  }
}
