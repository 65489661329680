import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Nav,
  Navbar,
  Collapse,
} from 'reactstrap';
import { get } from 'lodash';
import DropdownControlled from '../../components/DropdownControlled';
import { UserContext } from '../../consts';
/*
const menu = {
  editais:
    {
      menu: 'Editais',
      permission: 'editais.ver_menu_edital',
      items: [
        {
          to: 'divider',
          label: '',
          children: [
            {
              to: '/edital/dados',
              label: 'Novo',
              disabled: true,
              permission: 'editais.criar_edital',
            },
            {
              to: '/lista',
              label: 'Lista',
              disabled: true,
              permission: 'editais.ver_edital',
            },
            {
              to: '/dashboard-edital',
              label: 'Dashboard',
              disabled: true,
              permission: 'editais.ver_dashboard_edital',
            },
          ],
        },
      ],
    },
  matriz:
    {
      menu: 'MATRIZ DE REFERÊNCIA',
      permission: 'matriz_referencia.ver_menu_matriz_referencia',
      items: [
        {
          to: 'divider',
          label: 'Matrizes',
          children: [
            {
              to: '/matrizes-referencia/new',
              label: 'Nova Matriz',
              disabled: false,
              permission: 'matriz_referencia.criar_matriz_referencia',
            },
            {
              to: '/matrizes-referencia',
              label: 'Lista Matrizes',
              disabled: false,
              permission: 'matriz_referencia.ver_matriz_referencia',
            },
          ],
        },
        {
          to: 'divider',
          label: 'Cadastro',
          children: [
            {
              to: '/eixos',
              label: 'Eixos',
              disabled: false,
              permission: 'core.ver_eixo',
            },
            {
              to: '/competencias',
              label: 'Competências',
              disabled: false,
              permission: 'core.ver_competencia',
            },
            {
              to: '/habilidades',
              label: 'Habilidades',
              disabled: false,
              permission: 'core.ver_habilidade',
            },
          ],
        },
      ],
    },
  itens: {
    menu: 'Itens',
    permission: 'ver_menu_itens',
    items: [
      {
        to: 'divider',
        label: 'Banco de Itens',
        children: [
          {
            to: '/itens/new/dados',
            label: 'Novo item',
            permission: 'itens.criar_item',
          },
          {
            to: '/itens',
            label: 'Lista de Itens',
            permission: 'itens.ver_item',
          },
          {
            to: '/dashboard-itens',
            label: 'Dashboard Itens',
            disabled: true,
            permission: 'itens.ver_dashboard_item',
          },
        ],
      },

      {
        to: 'divider',
        label: 'Recursos',
        permission: 'aplicacoes.ver_menu_recurso',
        children: [
          {
            to: '/recurso',
            label: 'Novo Recurso',
            disabled: true,
            permission: 'aplicacoes.criar_recurso',
          },
          {
            to: '/recurso',
            label: 'Lista de Recursos',
            permission: 'aplicacoes.ver_recurso',
            disabled: true,
          },
        ],
      },
      {
        to: 'divider',
        label: 'Encomendar Itens',
        permission: 'encomendas.ver_menu_encomenda',
        children: [
          {
            to: '/encomendas/new',
            label: 'Nova Encomenda',
            permission: 'encomendas.criar_encomenda',
            disabled: false,
          },
          {
            to: '/encomendas',
            label: 'Lista de Encomendas',
            permission: 'encomendas.ver_encomenda',
            disabled: false,
          },
        ],
      },

    ],
  },
  preteste: {
    menu: 'Pré-testes',
    permission: 'testes.ver_menu_teste',
    items: [
      {
        to: 'divider',
        label: '',
        children: [
          {
            to: '/pre-testes/new',
            label: 'Novo pré-teste',
            permission: 'testes.criar_teste',
          },
          {
            to: '/pre-testes',
            label: 'Lista',
            permission: 'testes.ver_teste',
          },
        ],
      },
    ],
  },
  teste: {
    menu: 'Teste',
    permission: 'testes.ver_menu_teste',
    items: [
      {
        to: 'divider',
        label: '',
        children: [
          {
            to: '/testes/new',
            label: 'Novo teste',
            permission: 'testes.criar_teste',
          },
          {
            to: '/testes',
            label: 'Lista de Testes',
            permission: 'testes.ver_teste',
          },
        ],
      },
    ],
  },
  instrumento: {
    menu: 'Instrumentos',
    permission: 'avaliacoes.ver_menu_avaliacao',
    items: [
      {
        to: 'divider',
        label: 'Cognitivo',
        children: [
          {
            to: '/instrumentos-cognitivos/new',
            label: 'Novo Instrumento',
            permission: 'avaliacoes.criar_avaliacao',
          },
          {
            to: '/instrumentos-cognitivos',
            label: 'Lista de Instrumentos',
            permission: 'avaliacoes.ver_avaliacao',
          },
        ],
      },
      {
        to: 'divider',
        label: 'Não Cognitivo',
        children: [
          {
            to: '/instrumento-cognitivo/dados',
            label: 'Novo Instrumento',
            disabled: true,
          },
          {
            to: '/instrumento-cognitivo/dados',
            label: 'Lista de Instrumentos',
            disabled: true,
          },
        ],
      },

    ],
  },
  elaboradores: {
    menu: 'Elaborador',
    items: [
      {
        to: 'divider',
        label: '',
        children: [
          {
            to: '/elaborador/dados',
            label: 'Novo Elaborador',
          },
          {
            to: '/elaboradores',
            label: 'Lista',
          },
          {
            to: '/dashboard-elaborador/distribuicao-categoria',
            label: 'Dashboard de Elaboradores',
            disabled: true,
          },
        ],
      },

    ],
  },
  analise: {
    menu: 'Análise Psicométrica',
    items: [
      {
        to: 'divider',
        label: '',
        children: [
          {
            to: '/analise/gerar',
            label: 'Análise TCT/TRI',
            disabled: false,
          },
        ],
      },
      {
        to: 'divider',
        label: 'Importação / Exportação',
        children: [
          {
            to: '/analise-psicometrica',
            label: 'Importação de Parâmetros de Itens',
            disabled: true,

          },
          {
            to: '/analise-psicometrica',
            label: 'Importação de Itens',
            disabled: true,

          },
          {
            to: '/analise-psicometrica',
            label: 'Importação de Vetores de Resposta',
            disabled: true,

          },
          {
            to: '/analise-psicometrica',
            label: 'Exportação de resultados',
            disabled: true,

          },
        ],
      },
    ],
  },
};
*/

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  state = {
    isMenuOpen: false,
    menu: [],
  }

  get shouldRenderAllMenus() {
    const { permissions } = this.props;
    return get(permissions, '__all__');
  }

  toggleMenu() {
    this.setState({
      isMenuOpen: !this.state.isMenuOpen,
    });
  }

  renderMenu() {
    const { menu } = this.props;
    return (
      <>
        {menu.map(m => <DropdownControlled key={m.id} menu={m} />)}
      </>
    );
  }

  render() {
    const { isMenuOpen } = this.state;

    return (
      <Navbar expand="md">
        <i className="fas fa-bars hamburguer" onClick={this.toggleMenu} />
        <Collapse isOpen={isMenuOpen} navbar>
          <Container>
            <Nav navbar>
              {this.renderMenu()}
            </Nav>
          </Container>
        </Collapse>
      </Navbar>
    );
  }
}

Menu.propTypes = {
  permissions: PropTypes.objectOf(PropTypes.bool),
  menu: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    sections: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }).isRequired).isRequired,
    }).isRequired).isRequired,
  }).isRequired).isRequired,
};

Menu.defaultProps = {
  permissions: {},
  menu: [],
};

export default props => (
  <UserContext.Consumer>
    {context => <Menu {...context} {...props} />}
  </UserContext.Consumer>
);
