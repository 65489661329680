import React from 'react';
import PropTypes from 'prop-types';

export default class CircleGraph extends React.Component {
    static propTypes = {
      color: PropTypes.string,
    };

    static defaultProps = {
      color: '#4CC1F3',
    };

    render() {
      const { col, color, title, subtitle } = this.props;
      return(
        <div className={`col-sm-${col}`}>
          <div className="mb-4">
            <div className="circle-graph-line" style={{ border: `2px solid ${color}` }}>
              <div className="circle-graph" style={{ background: color }}>
                <center>
                  <label className="pt-4 labelGraph">
                    {title}
                  </label>
                  <p className="labelGraph-p">{subtitle}</p>
                </center>
              </div>
            </div>
          </div>
        </div>
      );
  }
}
