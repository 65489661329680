import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import axios from 'axios';
import Loading from './Loading';
import { API_HOST, PLAGIUS_API_HOST, UserContext, getTenant } from '../consts';
import { formatDateTime } from '../utils';
import ProgressBar from '../containers/ProgressBar';
import PlagiusAddresses from './PlagiusAddresses';
import Alert from './Alert';

class Plagius extends React.Component {
  state = {
    plagius: {},
    detectingPlagius: true
  };

  componentDidMount() {
    this.fetchDetectedPlagius();
  }

  get canDetectPlagius() {
    const permissions = get(this.props, 'permissions') || {};
    // eslint-disable-next-line
    return permissions.__all__ || permissions['itens.create_plagio'];
  }

  forceNewPlagiusDetection = false;

  fetchItem = () => {
    const { item } = this.props;
    return axios.get(`${API_HOST}/itens/item/${item}`);
  };

  mapItem = (response) => {
    const item = response.data;
    // const alternativas = item.alternativas.map(a => a.descricao);
    // return [item.situacao_problema, item.comando_resposta].concat(alternativas).join(' ');
    return [item.situacao_problema, item.comando_resposta].join(' ');
  };

  handleFetchPlagiusSuccess = (response) => {
    this.setState(
      {
        plagius: response.data,
        detectedPlagius: true,
        detectingPlagius: false
      },
      this.fetchDetectedPlagiusPermission
    );
  };

  handleFetchPlagiusError = () => {
    this.setState({
      plagius: {},
      detectedPlagius: false,
      detectingPlagius: false
    });
    toast.error(
      'Não foi possível procurar por suspeitas de plágio para este item. Por favor, tente novamente.'
    );
  };

  detectPlagius = (content) => {
    const { item } = this.props;
    const data = {
      conteudo: content,
      id_item: item,
      subdominio: getTenant()
    };
    const callApi = () => {
      axios
        .post(PLAGIUS_API_HOST, data)
        .then(this.handleFetchPlagiusSuccess)
        .catch(this.handleFetchPlagiusError);
    };
    if (this.forceNewPlagiusDetection) {
      axios.delete(`${PLAGIUS_API_HOST}/${item}`).then(callApi);
    } else {
      callApi();
    }
  };

  checkPlagius = () => {
    this.setState({ detectingPlagius: true });
    this.fetchItem()
      .then(this.mapItem)
      .then(this.detectPlagius)
      .catch(this.handleFetchPlagiusError);
  };

  fetchDetectedPlagius = () => {
    const { item } = this.props;
    axios
      .get(`${PLAGIUS_API_HOST}/${getTenant()}/${item}`)
      .then(this.handleFetchPlagiusSuccess)
      .catch(() => {
        this.setState({ detectingPlagius: false });
      });
  };

  fetchDetectedPlagiusPermission = () => {
    const { plagius } = this.state;
    const { item } = this.props;
    const calculationDate = get(plagius, 'data');
    axios
      .get(`${API_HOST}/itens/item/${item}/detectar_plagio`, {
        params: {
          data: new Date(calculationDate)
        }
      })
      .then((response) => {
        this.setState({ shouldDetectPlagius: response.data === true });
      })
      .catch(() => {
        this.setState({ shouldDetectPlagius: false });
      });
  };

  handleForceNewPlagiusDetectionButton = () => {
    this.forceNewPlagiusDetection = true;
    this.checkPlagius();
  };

  render() {
    const { detectingPlagius, detectedPlagius, plagius, shouldDetectPlagius } =
      this.state;

    if (detectingPlagius) {
      return (
        <React.Fragment>
          <Loading message="Procurando por suspeitas de plágio..." />
          <p style={{ marginLeft: '15vh' }}>
            <i className="fa fa-exclamation-circle mr-1" />
            <b>
              O tempo médio da análise de detecção de plágio, é de 2 minutos.
            </b>
          </p>
        </React.Fragment>
      );
    }

    if (!detectedPlagius) {
      return (
        <React.Fragment>
          <p>
            O processo de detecção de plágio deste item ainda não foi realizado.
          </p>
          {this.canDetectPlagius && (
            <React.Fragment>
              <p>
                <small>
                  <i className="fa fa-exclamation-circle mr-1" />
                  Detectar plágio em um item pode levar mais de um minuto. Por
                  favor, não feche esta página enquanto o processo não for
                  concluído.
                </small>
              </p>
              <button
                className="btn primary-button"
                onClick={this.checkPlagius}
                disabled={detectingPlagius}
              >
                Detectar plágio
              </button>
            </React.Fragment>
          )}
          {detectingPlagius && <ProgressBar />}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div className="plagius">
          <p className="timestamp">
            <small>
              Verificação realizada em {formatDateTime(get(plagius, 'data'))}
            </small>
          </p>
          {shouldDetectPlagius && (
            <Alert category="warning" onClick={this.checkPlagius}>
              <span>
                O item foi modificado desde a última verificação de plágio.
                {this.canDetectPlagius && (
                  <React.Fragment>
                    <button
                      onClick={this.handleForceNewPlagiusDetectionButton}
                      className="btn btn-link check-plagius-btn ml-1 mr-1"
                    >
                      Clique aqui
                    </button>
                    para realizar uma nova verificação.
                  </React.Fragment>
                )}
              </span>
            </Alert>
          )}
          <p className="plagius-section">
            <strong>Suspeitas na Internet</strong>
            <br />
            <small>
              {' '}
              Percentual do texto com expressões localizadas na internet
            </small>
          </p>
          <ProgressBar
            align="right"
            progress={get(plagius, 'suspeitas_na_internet')}
            color="info"
          />
          <p className="plagius-section">
            <strong>Suspeitas confirmadas</strong>
            <br />
            <small>
              {' '}
              Confirmada existência dos trechos suspeitos nos endereços
              encontrados
            </small>
          </p>
          <ProgressBar
            align="right"
            progress={get(plagius, 'suspeitas_confirmadas')}
            color="info"
          />
          <p className="plagius-section">
            <strong>Texto analisado</strong>
            <br />
            <small>
              {' '}
              Percentual do texto efetivamente analisado (frases curtas,
              caracteres especiais, texto quebrado não são analisados).
            </small>
          </p>
          <ProgressBar
            align="right"
            progress={get(plagius, 'texto_analisado')}
            color="info"
          />
          <p className="plagius-section">
            <strong>Sucesso da análise</strong>
            <br />
            <small>
              {' '}
              Percentual das pesquisas com sucesso. Indica a qualidade da
              análise. Quanto maior, melhor.
            </small>
          </p>
          <ProgressBar
            align="right"
            progress={get(plagius, 'sucesso_da_analise')}
            color="info"
          />

          <p className="plagius-section mb-2">
            <strong>Endereços encontrados</strong>
          </p>
          <PlagiusAddresses addresses={get(plagius, 'enderecos') || []} />
        </div>
      </React.Fragment>
    );
  }
}

Plagius.propTypes = {
  item: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

export default (props) => (
  <UserContext.Consumer>
    {({ permissions }) => <Plagius permissions={permissions} {...props} />}
  </UserContext.Consumer>
);
