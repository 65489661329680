import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Icon from 'react-fa';
import NavBreadCrumb from '../../containers/NavBreadCrumb';
import Form from '../../containers/Form';
import Toolbar from './Toolbar';
import FormTable from '../../components/FormTable';
import DetalhesParametroImportadoModal from './DetalhesParametroImportadoModal';
import { toggleDetalhesParametroImportado } from '../../actions/AnalisePsicometrica';

class AnalisePsicometrica extends React.Component {
  static propTypes = {
    sortDirection: PropTypes.oneOf(['desc', 'asc']).isRequired,
    parametrosImportados: PropTypes.arrayOf(PropTypes.object).isRequired,
    toggleDetalhesParametroImportado: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.getItem = this.getItem.bind(this);
    this.getData = this.getData.bind(this);
  }

  getItem(parametroImportado) {
    return [
      parametroImportado.titulo,
      parametroImportado.arquivo ? parametroImportado.arquivo.name : '-',
      parametroImportado.data,
      parametroImportado.status_importacao_parametro,
      parametroImportado.tipoImportacao.label,
      <Icon name="search" onClick={() => this.props.toggleDetalhesParametroImportado(parametroImportado)} />,
    ];
  }

  getData() {
    const parametrosImportados = this.props.parametrosImportados.map(this.getItem);
    if (this.props.sortDirection === 'desc') {
      parametrosImportados.reverse();
    }

    return parametrosImportados;
  }

  render() {
    const headers = ['Título', 'Arquivo', 'Data', 'Status', 'Tipo', 'Detalhes'];
    const data = this.getData();

    return (
      <div>
        <DetalhesParametroImportadoModal />
        <NavBreadCrumb />
        <div className="container">
          <Form title="Importação de Parâmetros">
            <Toolbar />
            <div className="mt-4 row">
              <div className="col-sm-12">
                <FormTable
                  headers={headers}
                  data={data}
                  noResultsText="Nenhum parâmetro encontrado."
                />
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  parametrosImportados: state.AnalisePsicometrica.reducer.parametrosImportados,
  sortDirection: state.AnalisePsicometrica.reducer.sortDirection,
});

export default connect(mapStateToProps, { toggleDetalhesParametroImportado })(AnalisePsicometrica);
