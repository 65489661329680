import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const HighchartSkill = ({ data }) => {
  useEffect(() => {
    Highcharts.chart(`container-${data.cd_habilidade}`, {
      chart: {
        type: 'pie',
        backgroundColor: 'none',
        width: '300'
      },
      title: {
        text: ''
      },
      subtitle: {
        text: `${data.qtd}/${data.qtd_total}<br>Acertos`,
        align: 'center',
        verticalAlign: 'center',
        style: {
          fontSize: '20px',
          textAlign: 'center'
        },
        x: 0,
        y: 80
      },
      plotOptions: {
        pie: {
          shadow: false,
          center: ['50%', '20%'],
          dataLabels: {
            enabled: false
          },
          states: {
            hover: {
              enabled: false
            }
          },
          size: 10,
          innerSize: '10%',
          borderColor: null,
          borderWidth: 8
        },
        size: 10
      },
      tooltip: {
        valueSuffix: '%',
        valueDecimals: 2
      },
      series: [
        {
          size: '50%',
          innerSize: '100%',
          data: [
            {
              name: 'erros',
              y: 100 * (1 - data.qtd / data.qtd_total),
              color: '#cccc'
            },
            {
              name: 'acertos',
              y: (100 * data.qtd) / data.qtd_total,
              color: '#48ab86'
            }
          ]
        }
      ]
    });
  });

  return (
    <div
      id={`container-${data.cd_habilidade}`}
      style={{ overflow: 'scroll' }}
    ></div>
  );
};

export default HighchartSkill;
