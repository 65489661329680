import React from 'react';
import Form from '../../containers/Form';

class Confirmar extends React.Component {
  render() {
    return (
      <Form title="Confirmar" last="/pre-testes" />
    );
  }
}

export default Confirmar;
