import React from 'react';
import axios from 'axios';
import { API_HOST } from '../consts';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Form } from 'informed';
import { InputSelect, InputTextArea } from './inputs';
import { toast } from 'react-toastify';

const ModalCreateComentario = ({ isModalOpen, handleModal, avaliacaoItem, api }) => {
	const toggle = () => handleModal(!isModalOpen);

	const handleAddComentario = (formValues) => {
		const { comentario, tipoComentario } = formValues;
		if (tipoComentario !== 1 && tipoComentario !== 2) {
			return toast.error('Por favor, selecione um tipo de comentário.')
		}

		if (comentario === '') {
			return toast.error('Por favor, insira um comentário.')
		}

		const params = { comentario: comentario, tipo: tipoComentario, avaliacao_item: avaliacaoItem.id };

		axios.post(`${API_HOST}/avaliacoes/avaliacao_item_comentarios`, params)
			.then((res) => {
				return axios
					.get(`${API_HOST}/avaliacoes/avaliacao_item`, {
						params: {
							avaliacao: avaliacaoItem.avaliacao.id
						}
					}).then((response) => {

						const itens = response.data.results || response.data

						api.setValues({
							itens
						})
					})
			})
			.catch(() => toast.error('Ocorreu um erro ao tentar adicionar um comentário. Tente novamente.'));

		toggle();
	};

	return (
		<Modal isOpen={isModalOpen} toggle={toggle} centered={true}>
			<ModalHeader>Novo comentário</ModalHeader>
			<ModalBody>
				<Form onSubmit={handleAddComentario}>
					{({ formState }) => (
						<>
							<div className='my-3'>
								<InputTextArea
									label="Comentário:"
									field="comentario"
									rows={7}
								/>
							</div>
							<div className='my-3'>
								<InputSelect
									label="Tipo de comentário"
									field="tipoComentario"
									options={[
										{ value: 1, label: 'Conteúdo' },
										{ value: 2, label: 'Valor' },
									]}
								>
								</InputSelect>
							</div>
							<ModalFooter>
								<button type="submit" className='btn btn-primary'>
									Adicionar Comentário
								</button>
								<button type="button" className='btn btn-secondary' onClick={toggle}>
									Cancelar
								</button>
							</ModalFooter>
						</>
					)}
				</Form>
			</ModalBody>
		</Modal>
	);
};

export default ModalCreateComentario;
