import React, { Component } from 'react';
import axios from 'axios';
import { API_HOST } from '../../consts';
import ReportMapGraph from './ReportMapGraph';

class ReportDrePage extends Component {
  state = {
    loading: false,
    boletimData: {}
  };

  componentDidMount() {
    this.fetchBoletimData();
  }
  componentWillUnmount() {
    this.state.loading = true;
  }

  fetchBoletimData = () => {
    const cd_disciplina = this.props.match.params.cd_disciplina;

    const nr_etapa = this.props.match.params.nr_etapa;
    const codigo_dre = this.props.match.params.codigo_dre;

    axios
      .get(
        `${API_HOST}/avalia/boletim_mt/grafico_mapa?CD_DISCIPLINA=${cd_disciplina}&NR_ETAPA=${nr_etapa}`
      )
      .then((res) => {
        this.setState({ loading: true, boletimData: res.data });
      })
      .catch((err) => console.error(err));
  };

  render() {
    const { boletimData, loading } = this.state;
    return (
      <>
        <div style={{ marginTop: '3rem', background: '#fff' }}>
          <div className="container mb-4 pb-4">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Relatório de proficiência dos alunos por DRE</h1>
                    <hr />
                  </div>
                  <div
                    className="card-body"
                    style={{
                      marginLeft: '-13.5%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}
                  >
                    {boletimData && loading && (
                      <>
                        <ReportMapGraph data={boletimData.dres} />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ReportDrePage;
