import axios from 'axios';
import { Form, Scope } from 'informed';
import { map } from 'lodash';
import React from 'react';
import { withRouter } from 'react-router-dom';
import BaseCreate from '../../base/BaseCreate';
import InputSelect from '../../components/inputs/InputSelect';
import Loading from '../../components/Loading';
import { API_HOST } from '../../consts';
import FormContainer from '../../containers/Form';
import { getCompetencias, getHabilidades } from '../../utils';

class Pedido extends BaseCreate {
  state = {
    matrizes_referencia: [],
    markedForDeletion: [],
    fetchingData: true
  };
  metadataModels = ['matriz'];
  componentDidMount() {
    this.fetchData();
    this.fetchMetadata();
  }

  getCompetencias(m) {
    const { matrizes_referencia } = this.state;
    return getCompetencias(matrizes_referencia, m);
  }

  getHabilidades(m) {
    const { matrizes_referencia } = this.state;
    return getHabilidades(matrizes_referencia, m, m);
  }

  fetchData() {
    if (this.id !== 'new') {
      axios
        .get(`${API_HOST}/encomenda/pedido/form`, {
          params: {
            encomenda: this.id,
            ordering: '-id'
          }
        })
        .then((response) => {
          this.setState({ fetchingData: false });
          this.formApi.setValues({ pedidos: response.data });

          axios
            .get(
              `${API_HOST}/encomenda/encomenda/${this.id}/areas_conhecimento`
            )
            .then(({ data }) => {
              this.fetchApi({
                link: `/matriz_referencia/matriz_referencia?areas_conhecimento__id=${data.join(
                  '|'
                )}&status=1&page_size=100`,
                name: 'matrizes_referencia',
                label: 'nome'
              });
            });
        });
    } else {
      this.setState({ fetchingData: false });
    }
  }

  handleSubmit = (values) => {
    const { markedForDeletion } = this.state;
    this.setState({ submitting: true });

    values.pedidos.forEach((pedido) => {
      pedido.encomenda = this.id;
    });

    axios
      .put(`${API_HOST}/encomenda/pedido`, values.pedidos, {
        params: {
          delete: markedForDeletion.join(',')
        }
      })
      .then(() => {
        const { history } = this.props;
        history.push(this.next);
      })
      .catch(this.onValidateError);
  };

  add = () => {
    const pedidos = this.formApi.getState().values.pedidos || [];
    this.formApi.setValues({ pedidos: [{}, ...pedidos] });
  };

  remove = (i) => {
    const pedidos = this.formApi.getState().values.pedidos;
    this.formApi.setValues({ pedidos: pedidos.filter((_, j) => j !== i) });

    const { markedForDeletion } = this.state;
    this.setState({ markedForDeletion: [...markedForDeletion, pedidos[i].id] });
  };

  handleNext = (next) => {
    this.next = next;
    this.handleSubmit(this.formApi.getState().values);
  };

  render() {
    const { matrizes_referencia, fetchingData, submitting } = this.state;

    return (
      <Form
        onChange={(formState) => this.setState({ formState })}
        getApi={(formApi) => {
          this.formApi = formApi;
        }}
        initialValues={{ pedidos: [] }}
      >
        {({ formState }) => {
          if (fetchingData) {
            return <Loading />;
          }

          return (
            <FormContainer
              title="Matrizes de referência"
              previous="dados"
              next="elaboradores"
              onClick={this.handleNext}
              disabled={submitting}
            >
              <div className="row mb-3">
                <div className="col-md-6">
                  <button
                    type="button"
                    className="btn primary-button"
                    onClick={this.add}
                  >
                    <i className="fas fa-plus" /> Adicionar matriz de referência
                  </button>
                </div>
              </div>
              {map(formState.values.pedidos, (m, i) => (
                <Scope scope={`pedidos[${i}]`}>
                  <div className="row">
                    <InputSelect
                      required
                      col="col-sm-11"
                      label="Matriz de referência"
                      options={matrizes_referencia}
                      field="matriz_referencia"
                    />
                    <div className="col-sm-1">
                      <label />
                      <button
                        type="button"
                        className="btn btn-outline"
                        onClick={() => this.remove(i)}
                      >
                        <i className="fas fa-times" />
                      </button>
                    </div>
                    <InputSelect
                      required
                      col="col-sm-11"
                      label={
                        this.shouldDisplayDefault('matriz.competencia') ||
                        'Competência'
                      }
                      options={this.getCompetencias(m)}
                      field="competencia"
                    />
                    <InputSelect
                      required
                      col="col-sm-11"
                      label={
                        this.shouldDisplayDefault('matriz.habilidade') ||
                        'Habilidade'
                      }
                      options={this.getHabilidades(m)}
                      field="habilidade"
                    />
                  </div>
                  <hr />
                </Scope>
              ))}
            </FormContainer>
          );
        }}
      </Form>
    );
  }
}

export default withRouter(Pedido);
