import React from 'react';
import { Link } from 'react-router-dom';

export default class DashboardNavigationItem extends React.Component {
  render() {
    const active = this.props.active ? 'active' : '';

    return (
      <Link to={this.props.link}>
        <li className={`${active}`}>
          {this.props.title}
        </li>
      </Link>
    );
  }
}
