import React, { Component } from 'react';
import axios from 'axios';
import { get } from 'lodash';
import { Label, UncontrolledTooltip } from 'reactstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import ListData from '../../components/ListData';
import ListTag from '../../components/ListTag';
import { downloadJson } from '../../utils';
import GabaritoModal from '../../containers/modals/GabaritoModal';
import { API_HOST } from '../../consts';
import AgendarAplicacaoModal from './AgendarAplicacaoModal';
import TabelaAplicacoes from './TabelaAplicacoes';
import ExportarAvaliacaoModal from './ExportarAvaliacaoModal';
import ImportarVetoresModal from '../../containers/modals/ImportarVetoresModal';
import AddButton from '../../components/buttons/AddButton';

export default class AvaliacaoItem extends Component {
  state = {
    isOpenGabarito: false,
    isOpenAgendar: false,
    isOpenExportar: false,
    aplicacoes: [],
    aplicacaoAtual: null
  };

  defaultFetchAplicacoesArgs = {
    loading: true,
    values: {}
  };

  fetchAplicacoes = ({ loading, params } = this.defaultFetchAplicacoesArgs) => {
    if (loading) {
      this.setState({ fetchingAplicacoes: true });
    }

    const { avaliacao } = this.props;
    const axiosParams = {
      avaliacao: avaliacao.id,
      ...params
    };

    axios
      .get(`${API_HOST}/aplicacoes/aplicacao`, { params: axiosParams })
      .then((response) => {
        const aplicacoes = response.data.results || response.data;
        this.setState({ aplicacoes, fetchingAplicacoes: false });
      })
      .catch(() => {
        toast.error('Ocorreu um erro ao carregar as aplicações.');
        this.setState({ fetchingAplicacoes: false });
      });
  };

  openGabaritoModal = () => {
    this.setState({
      isOpenGabarito: !this.state.isOpenGabarito
    });
  };

  openAgendarModal = () => {
    this.setState({
      isOpenAgendar: !this.state.isOpenAgendar
    });
  };

  toggleAplicacoes = () => {
    const { showAplicacoes } = this.state;
    this.setState({ showAplicacoes: !showAplicacoes });
  };

  toggleAgendarAplicacaoModal = (aplicacao) => {
    const { avaliacao } = this.props;
    const criarNovaAplicacao = aplicacao === null;
    let aplicacaoAtual;

    if (criarNovaAplicacao) {
      aplicacaoAtual = { avaliacao: avaliacao.id, id: 'new' };
    } else {
      aplicacaoAtual = { ...aplicacao };
    }

    const toggleModal = () => this.toggleModal('agendarAplicacao');
    this.setState({ aplicacaoAtual }, toggleModal);
  };

  toggleExportarAvaliacaoModal = (aplicacao) => {
    const { avaliacao } = this.props;
    const criarNovaAplicacao = aplicacao === null;
    let aplicacaoAtual;

    if (criarNovaAplicacao) {
      aplicacaoAtual = { avaliacao: avaliacao.id, id: 'new' };
    } else {
      aplicacaoAtual = { ...aplicacao };
    }

    const toggleModal = () => this.toggleModal('exportarAvaliacao');
    this.setState({ aplicacaoAtual }, toggleModal);
  };

  toggleImportarVetoresModal = () => {
    const toggleModal = () => this.toggleModal('importarVetores');
    this.setState(toggleModal);
  };

  handleExportToExams = () => {
    const { avaliacao } = this.props;
    axios
      .get(`${API_HOST}/avaliacoes/avaliacao/${avaliacao.id}/exame`)
      .then((response) => {
        downloadJson(response.data, avaliacao.nome);
      })
      .catch((error) => {
        const status = get(error, 'response.status');
        const e = get(error, 'response.data');

        if (status === 400) {
          toast.error(e);
        }
      });
  };

  toggleModal = (modal) => {
    const { openModal } = this.state;
    this.setState({ openModal: openModal === modal ? null : modal });
  };

  handleRemoverAplicacao = (aplicacao) => {
    axios
      .delete(`${API_HOST}/aplicacoes/aplicacao/${aplicacao.id}`)
      .then(() => {
        toast.success('Aplicação removida com sucesso.');

        const { aplicacoes } = this.state;
        this.setState({
          aplicacoes: aplicacoes.filter((d) => d.id !== aplicacao.id)
        });
      });
  };

  renderAplicacoes() {
    const { fetchingAplicacoes, showAplicacoes, aplicacoes } = this.state;

    if (!showAplicacoes) {
      return null;
    }

    return (
      <React.Fragment>
        <TabelaAplicacoes
          aplicacoes={aplicacoes}
          editar={this.toggleAgendarAplicacaoModal}
          remover={this.handleRemoverAplicacao}
          fetchAplicacoes={this.fetchAplicacoes}
          fetchingAplicacoes={fetchingAplicacoes}
        />
      </React.Fragment>
    );
  }

  render() {
    const { avaliacao, onCopy, onRemove, templates } = this.props;
    const { aplicacaoAtual } = this.state;
    const { openModal } = this.state;

    return (
      <div className="item" key={avaliacao.id}>
        <div className="container">
          <div className="row">
            <div className="checkbox-input-item col-lg-1 col-md-1 col-sm-2 col-xs-2 border-0">
              <Label check className="container-check-custom">
                <input type="checkbox" id={`checkbox-${avaliacao.id}`} />
                <span className="checkmark" />
              </Label>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-10 col-xs-10">
              <ListData
                description={[
                  {
                    label: 'Criado por:',
                    value: get(avaliacao, 'criado_por') || '-'
                  },
                  {
                    label: 'N. de Itens:',
                    value: get(avaliacao, 'itens') || '-'
                  },
                  {
                    label: 'Pontos da Prova:',
                    value: get(avaliacao, 'valor') || '-'
                  },
                  {
                    label: 'Aplicações:',
                    value: get(avaliacao, 'aplicacoes') || '-'
                  }
                ]}
                title={get(avaliacao, 'nome', '-')}
                atualizado_em={get(avaliacao, 'atualizado_em') || '-'}
                versao={1}
                totvs={avaliacao}
              />
              <AddButton
                type="button"
                // className="btn btn-padrao bg-padrao mb-3 mr-2 pr-3"
                onClick={this.toggleAplicacoes}
                title="Aplicações"
                icon="eye"
                classes="mb-3 mr-2 pr-3"
                backgroundType="light"
              />
              <AddButton
                onClick={() => this.toggleModal('gabarito')}
                type="button"
                icon="clipboard"
                title="Gabarito"
                classes="mb-3 mr-2 pr-3"
                backgroundType="secondary"
              />
              <GabaritoModal
                toggle={() => this.toggleModal('gabarito')}
                isOpen={openModal === 'gabarito'}
                avaliacao={avaliacao.id}
              />
              <AddButton
                onClick={() => this.toggleAgendarAplicacaoModal(null)}
                type="button"
                // className="btn btn-padrao bg-verde mb-3 mr-2 pr-3"
                icon="plus"
                title="Agendar Aplicação"
                classes="mb-3 mr-2 pr-3"
              />
              <AgendarAplicacaoModal
                isOpen={openModal === 'agendarAplicacao'}
                toggle={() => this.toggleAgendarAplicacaoModal(null)}
                aplicacao={aplicacaoAtual}
                fetchAplicacoes={this.fetchAplicacoes}
              />
            </div>
            <div
              style={{ paddingLeft: '50px', marginLeft: '39px' }}
              className="col-lg-3 col-md-3 col-xs-12 col-sm-12"
            >
              <ListTag
                actions={[
                  <UncontrolledTooltip
                    key={0}
                    delay={0}
                    placement="top"
                    target="edit"
                  >
                    Editar
                  </UncontrolledTooltip>,
                  <Link
                    key={1}
                    id="edit"
                    className="btn btn-white mb-3"
                    to={`/instrumentos-cognitivos/${avaliacao.id}`}
                  >
                    <i className="fas fa-edit" />
                  </Link>,
                  <UncontrolledTooltip
                    key={1}
                    delay={0}
                    placement="top"
                    target="import-vectors"
                  >
                    Importar vetores
                  </UncontrolledTooltip>,
                  <button
                    id="import-vectors"
                    key="7"
                    type="button"
                    className="btn btn-white mb-3"
                    onClick={() => this.toggleImportarVetoresModal(null)}
                  >
                    <i className="fas fa-file-import"></i>
                  </button>,
                  <UncontrolledTooltip
                    key={2}
                    delay={0}
                    placement="top"
                    target="copy-avaliacao"
                  >
                    Fazer um Cópia da Avaliação
                  </UncontrolledTooltip>,
                  <button
                    id="copy-avaliacao"
                    key="3"
                    type="button"
                    className="btn btn-white mb-3"
                    onClick={() => onCopy(avaliacao.id)}
                  >
                    <i className="far fa-copy" />
                  </button>,
                  <UncontrolledTooltip
                    key={4}
                    delay={0}
                    placement="top"
                    target="print"
                  >
                    Imprimir
                  </UncontrolledTooltip>,
                  <Link
                    key={5}
                    id="print"
                    className="btn btn-white mb-3"
                    to={`/instrumentos-cognitivos/${avaliacao.id}/impressao`}
                  >
                    <i className="fas fa-print" />
                  </Link>,
                  <UncontrolledTooltip
                    key={6}
                    delay={0}
                    placement="top"
                    target="export-doc"
                  >
                    Exportar DOC
                  </UncontrolledTooltip>,
                  <button
                    id="export-doc"
                    key="7"
                    type="button"
                    className="btn btn-white mb-3"
                    onClick={() => this.toggleExportarAvaliacaoModal(null)}
                  >
                    <i className="far fa-file-word" />
                  </button>,
                  <UncontrolledTooltip
                    key={8}
                    delay={0}
                    placement="top"
                    target="export-excel"
                  >
                    Exportar para exame
                  </UncontrolledTooltip>,
                  <button
                    id="export-excel"
                    key="9"
                    type="button"
                    className="btn btn-white mb-3"
                    onClick={() => this.handleExportToExams(null)}
                  >
                    <i className="fas fa-file-export" />
                  </button>,
                  <ExportarAvaliacaoModal
                    isOpen={openModal === 'exportarAvaliacao'}
                    toggle={() => this.toggleExportarAvaliacaoModal(null)}
                    avaliacao={avaliacao}
                    fetchAplicacoes={this.fetchAplicacoes}
                    options={templates}
                  />,
                  <ImportarVetoresModal
                    isOpen={openModal === 'importarVetores'}
                    toggle={() => this.toggleImportarVetoresModal(null)}
                    avaliacao={avaliacao}
                  />,
                  <UncontrolledTooltip
                    key={10}
                    delay={0}
                    placement="top"
                    target="remove"
                  >
                    Remover
                  </UncontrolledTooltip>,
                  <button
                    key="11"
                    type="button"
                    className="btn btn-white mb-3"
                    onClick={() => onRemove(avaliacao.id)}
                    id="remove"
                  >
                    <i className="fas fa-times" />
                  </button>
                ]}
                groupIcon={this.props.groupIcon}
                onClickGroup={this.onClickGroup}
                color="white"
              />
            </div>
          </div>
        </div>
        {this.renderAplicacoes()}
      </div>
    );
  }
}
