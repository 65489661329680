import React from 'react';

class Alert extends React.Component {

  render() {
    const { children, category, onClick } = this.props;

    return (
      <div className={`alert alert-${category} alert-dismissible fade show`} role="alert">
        <button
          type="button"
          className="close"
          aria-label="fechar"
          onClick={onClick}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        {children}
      </div>
    );
  }
}

export default Alert;
