import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'informed';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { API_HOST } from '../../consts';
import { TemplateCardStyle } from './styles';

class ExportarAvaliacaoModal extends Component {
  state = {
    data: [],
    templateId: false,
    availableWithKeys: false,
    withAnswerKeys: false,
    isChecked: false
  };

  handleChange = (id, availableAnswerKeys) => {
    this.setState({
      templateId: id,
      availableAnswerKeys,
      withAnswerKeys: false,
      isChecked: true
    });
  };

  handleCheckWithAnswerKeys = () => {
    this.setState((prevState) => ({
      withAnswerKeys: !prevState.withAnswerKeys
    }));
  };

  handleSubmit = () => {
    const { avaliacao } = this.props;
    const { templateId, withAnswerKeys } = this.state;
    const token = localStorage.getItem('token');
    const cacheControl = new Date().getTime();
    const exportLink = `${API_HOST}/export_item/avaliacao/${avaliacao.id}?token=${token}&template_id=${templateId}&with_answer_keys=${withAnswerKeys}&_=${cacheControl}`;
    window.open(exportLink);
  };

  renderTemplate = () => {
    const { options } = this.props;
    return options.map((d) => (
      <TemplateCardStyle
        className="d-flex justify-content-start radio"
        key={d.id}
      >
        <div>
          <div>
            <label className="card-title">
              <input
                className="mr-2 pt-3"
                type="radio"
                checked={this.state.templateId === d.id}
                onChange={() =>
                  this.handleChange(d.id, d.available_answer_keys)
                }
              />
              {d.name}
            </label>
          </div>
          <div className="ml-4">
            <p className="badge bdg-padrao bg-secondary mr-2 mb-0 white">
              {d.columns} Coluna(s)
            </p>
            {d.available_answer_keys ? (
              <div className="badge badge-success mr-2 white">
                Gabarito disponível
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </TemplateCardStyle>
    ));
  };

  render() {
    const { toggle, isOpen, submitting } = this.props;
    const { availableAnswerKeys, withAnswerKeys, isChecked } = this.state;
    return (
      <Form
        getApi={(api) => {
          this.formApi = api;
        }}
      >
        <Modal
          isOpen={isOpen}
          onOpened={this.onOpened}
          toggle={toggle}
          size="lg"
        >
          <ModalHeader toggle={toggle}>Exportar Avaliação</ModalHeader>
          <ModalBody>
            <div className="card-header justify-content-start">
              <div className="ml-2">
                <h1>Escolha o seu template</h1>
                <hr />
              </div>
              <div
                style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}
              >
                {this.renderTemplate()}
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-end mr-4">
              <div className="mr-2">
                <label
                  className="mr-1 small mb-1"
                  style={
                    !availableAnswerKeys
                      ? { color: 'grey' }
                      : { color: 'black' }
                  }
                >
                  <input
                    type="checkbox"
                    checked={withAnswerKeys}
                    id="com_gabarito"
                    aria-label=""
                    onChange={() => this.handleCheckWithAnswerKeys()}
                    disabled={availableAnswerKeys ? '' : 'disabled'}
                    className="mr-2"
                  />
                  Exportar com gabarito
                  <p className="small danger mt-0 ml-4 mb-0">
                    {isChecked && !availableAnswerKeys
                      ? 'Gabarito Indisponível'
                      : ''}
                  </p>
                </label>
              </div>
              <button type="button" className="btn btn-white" onClick={toggle}>
                Fechar
              </button>
              <button
                type="submit"
                className="btn primary-button ml-1"
                onClick={this.handleSubmit}
              >
                {submitting ? 'Exportando...' : 'Exportar'}
              </button>
            </div>
          </ModalBody>
        </Modal>
      </Form>
    );
  }
}

ExportarAvaliacaoModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  avaliacao: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      external_id: PropTypes.number,
      name: PropTypes.string,
      available_answer_keys: PropTypes.bool
    })
  ).isRequired
};

export default ExportarAvaliacaoModal;
