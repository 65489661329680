import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';

class ImageModal extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.closeModal = this.closeModal.bind(this);

    this.state = {
      titleButton: '',
      url: ''
    };
  }

  closeModal() {
    const { onClose } = this.props;
    onClose();
  }

  updateTitle(event) {
    this.setState({ titleButton: event });
  }

  updateUrl(event) {
    this.setState({ url: event });
  }

  handleClick(e) {
    const { onClose, onClick } = this.props;
    const { url, titleButton } = this.state;

    e.preventDefault();

    this.setState({ url });

    const urlProps = url;
    const titleButtonProps = titleButton;
    onClick(urlProps, titleButtonProps);
    onClose();
  }

  render() {
    const { isOpen } = this.props;
    const { url, titleButton } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        toggle={this.closeModal}
        style={{
          marginTop: '10%',
          maxWidth: '700px',
          width: '560px',
          borderRadius: '60px'
        }}
        size={'lg'}
        scrollable={false}
        // backdrop={false}
      >
        <ModalHeader toggle={this.closeModal} style={{ BorderRadius: '30px' }}>
          Modal imagem - pdf
        </ModalHeader>
        <ModalBody
          style={{
            height: '25.5rem',
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px'
          }}
        >
          <form onSubmit={this.handleSubmit}>
            <div style={{ msrginTop: '40px' }}>
              <label>Texto para exibir</label>
              <input
                type="text"
                value={titleButton}
                onChange={(event) => this.updateTitle(event.target.value)}
                // value={setUrl}
                style={{
                  // display: 'block',
                  marginBottom: '-30px',
                  width: '100%',
                  height: 'calc(1.5em + 0.75rem + 2px)',
                  padding: '0.375rem 0.75rem',
                  fontSize: '1rem',
                  fontWeight: '400',
                  lineHeight: '1.5',
                  color: '#495057',
                  backgroundColor: '#fff',
                  border: '1px solid #ced4da',
                  borderRadius: '0.25rem'
                }}
              />
              <label style={{ marginTop: '10px' }}>Url da imagem</label>
              <input
                type="text"
                value={url}
                onChange={(event) => this.updateUrl(event.target.value)}
                style={{
                  // display: 'block',
                  marginBottom: '-30px',
                  width: '100%',
                  height: 'calc(1.5em + 0.75rem + 2px)',
                  padding: '0.375rem 0.75rem',
                  fontSize: '1rem',
                  fontWeight: '400',
                  lineHeight: '1.5',
                  color: '#495057',
                  backgroundColor: '#fff',
                  border: '1px solid #ced4da',
                  borderRadius: '0.25rem'
                }}
              />
            </div>

            {url ? (
              <>
                <label
                  style={{
                    position: 'relative',
                    top: '20px',
                    textAlign: 'center'
                  }}
                >
                  {titleButton}
                </label>
                <iframe
                  scrolling="no"
                  style={{
                    width: '100%',
                    overflow: 'hidden',
                    marginTop: '20px',
                    height: '95%'
                  }}
                  src={url}
                />
                <button
                  type="submit"
                  // disabled={!url}
                  className="btn primary-button pull-right"
                  onClick={(e) => this.handleClick(e)}
                  style={{ cursor: 'pointer' }}
                >
                  salvar
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-white"
                  onClick={this.closeModal}
                >
                  cancelar
                </button>
                {/* <input type="submit">Salvar</input> */}
              </>
            ) : (
              <label style={{ marginTop: '20px' }}>
                Pré-visualização indisponível no momento, por favor insira a url
              </label>
            )}
          </form>
        </ModalBody>
      </Modal>
    );
  }
}

ImageModal.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  onClose: PropTypes.func
};

ImageModal.defaultProps = {
  isOpen: false,
  onClick: () => {},
  onClose: () => {}
};

export default ImageModal;
