import React from 'react';

export default class LabelViewHorizontal extends React.Component {
  render() {
    const {
      title,
      text
    } = this.props;
    return (
      <div className="label-horizontal mt-2">
        <li><span>{title}</span> {text}</li>
      </div>
    );
  }
}
