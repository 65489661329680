import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actions } from 'react-redux-form';
import { FormGroup, Input } from 'reactstrap';
import { Icon } from 'react-fa';
import resolvePath from 'object-resolve-path';

class InputFile extends React.Component {
  static propTypes = {
    model: PropTypes.string.isRequired,
    col: PropTypes.string,
  };

  static defaultProps = {
    col: 'col-md-12',
  };

  constructor(props) {
    super(props);
    this.onAdd = this.onAdd.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  onAdd(event) {
    const file = event.target.files[0];
    this.props.dispatch(actions.change(this.props.model, file));
  }

  onRemove() {
    this.props.dispatch(actions.reset(this.props.model));
  }

  render() {
    const value = resolvePath(this.props, this.props.model);
    const { label, model } = this.props;

    return (
      <FormGroup className={this.props.col}>
        <label>{label}</label>
        {
          value ?
          (<p>{value.name} <Icon name="times" onClick={this.onRemove} /></p>) :
          null
        }
        <Input
          model={model}
          type="file"
          className="form-control"
          updateOn="blur"
          onChange={this.onAdd}
        />
      </FormGroup>
    );
  }
};

export default connect(state => state)(InputFile);
