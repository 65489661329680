import React from 'react';
import PropTypes from 'prop-types'


const HeaderAvaliaEscola = ({
	schoolName,
	schoolYear,
	dreAndMunicipio,
	disableSchoolYear,
	disableDreAndMunicipio
}) => {
	return (
		<>
			<div className="container__background">
				<div className="basic__info">
					<div className="input__background">
						<span className="first__info">
							Escola: <span>{schoolName}</span>
						</span>
					</div>
					{disableSchoolYear ?
						''
						: <div className="input__background">
							<span className="first__info">
								Etapa Escolar: <span>{schoolYear}</span>
							</span>
						</div>}
				</div>
				<div className="basic__info">
					{disableDreAndMunicipio === false ?
						<div className="input__background">
							<span className="first__info">
								{/* Exemplo: DRE Juina | Juina |  */}
								DRE/Município: <span>{dreAndMunicipio}</span>
							</span>
						</div>
						: ''}
				</div>
			</div>
		</>
	);
};

HeaderAvaliaEscola.propTypes = {
	schoolName: PropTypes.string,
	schoolYear: PropTypes.string,
	dreAndMunicipio: PropTypes.string,
	disableSchoolYear: PropTypes.bool,
	disableDreAndMunicipio: PropTypes.bool
}

HeaderAvaliaEscola.defaultProps = {
	disableSchoolYear: false,
	disableDreAndMunicipio: false
};
export default HeaderAvaliaEscola;
