import React from 'react';
import PropTypes from 'prop-types';

export default class AddReference extends React.Component {
  static propTypes = {
    onEdit: PropTypes.func,
    onAdd: PropTypes.func,
    count: PropTypes.number,
  };

  render() {
    const { color, title, count, onAdd } = this.props;
    return (
      <div className={`card-reference head ${color}`}>
        <h5 className="pt-3">{title}</h5>
        <div className="card-reference-body">
          <button className="btn btn-default w-4 mr-2 no-pointer">
            {count}
          </button>
          <button className="btn btn-default" onClick={onAdd}>
            <i className="fas fa-plus accent-color" />
          </button>
        </div>
      </div>
    );
  }
};
