const putFileinFormData = (file) => {
  const data = new FormData();
  data.append('file', file);
  return data;
};

const renameFile = (file, newName) => {
  return new File([file], `${newName}`, { type: file.type });
};

const getkeyFromS3URL = (url) => {
  return url.split('/')[3].split('?')[0];
};

export { putFileinFormData,
  renameFile,
  getkeyFromS3URL };
