import React from 'react';
import { Link } from 'react-router-dom';
import LabelViewHorizontal from './LabelViewHorizontal';
import LabelView from './LabelView';
import FIcon from './text/FIcon';
import PropTypes from 'prop-types';

export default class ListData extends React.Component {
  static propTypes = {
    buttons: PropTypes.arrayOf(PropTypes.element)
  };

  static defaultProps = {
    atualizado_em: '',
    versao: '',
    buttons: []
  };

  renderDescription() {
    if (this.props.vertical) {
      return (
        <div className="row">
          {this.props.description
            ? this.props.description.map((data) => (
                <LabelView title={data.label} text={data.value} />
              ))
            : null}
        </div>
      );
    }
    return this.props.description
      ? this.props.description.map((data) => (
          <LabelViewHorizontal
            key={data.label}
            title={data.label}
            text={data.value}
          />
        ))
      : null;
  }

  renderTotvsInfos() {
    const { totvs } = this.props;
    if (totvs) {
      if (totvs.integracao_totvs) {
        const passedJson = JSON.parse(totvs.integracao_totvs);
        return (
          <div
            style={{
              display: 'grid',
              marginTop: '-0.4rem',
              marginBottom: '0.8rem',
              border: '1px solid ##bfbfbf',
              borderRadius: '4px',
              backgroundColor: '#FAFAFA',
              padding: '0.4rem'
            }}
          >
            <p
              style={{
                marginBottom: '-0.1rem',
                fontWeight: 600,
                fontSize: '14px'
              }}
              className="totvs-title"
            >
              IntegracaoTOTVS{' '}
              {totvs.prova_integradora ? '- Prova Integradora' : ''}
            </p>
            <div className="label-horizontal mt-2">
              <li>
                <span>Curso:</span> {passedJson.curso || passedJson.cod_curso}
              </li>
              {totvs.prova_integradora ? (
                <>
                  {' '}
                  <li>
                    <span>Período Letivo:</span> {passedJson.periodo_letivo}
                  </li>
                  <li>
                    <span>Período Turma:</span> {passedJson.periodo_turma}
                  </li>
                  <li>
                    <span>Coligada:</span> {passedJson.coligada}
                  </li>
                  <li>
                    <span>Filial:</span> {passedJson.filial}
                  </li>
                  <li>
                    <span>Etapa:</span> {passedJson.cod_etapa}
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <span>ID Disciplina:</span> {passedJson.id_disciplina}
                  </li>
                  <li>
                    <span>Turma Disciplina:</span>{' '}
                    {passedJson.turma_disciplina_ds}
                  </li>
                  <li>
                    <span>Disciplina:</span> {passedJson.disciplina}
                  </li>
                  <li>
                    <span>Avaliação:</span> {passedJson.avaliacao}
                  </li>
                </>
              )}
            </div>
          </div>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  render() {
    const { title, small, atualizado_em, criado_em, versao, item_id } =
      this.props;

    return (
      <div className="list-data">
        <div className="list-data-title">
          <h3>{title}</h3>
          {small ? (
            <small className="list-data-text">
              <p>{small}</p>
            </small>
          ) : null}
          {this.renderDescription()}
          {atualizado_em && versao && (
            <p className="meta-descip mt-2">
              <FIcon icon="clock" type="far" margin="5" />
              {criado_em && (
                <span className="mr-2 font-weight-normal">
                  Criado em: {criado_em} -
                </span>
              )}
              Última atualização: {atualizado_em} -{' '}
              <b>Versão Atual: {versao}</b>
              <Link to={`/itens/historico/${item_id}`} className="">
                Todas as versões
              </Link>
            </p>
          )}
          {this.renderTotvsInfos()}
          <div className="text-left">{this.props.buttons}</div>
        </div>
      </div>
    );
  }
}
