import React, { Component } from 'react';
import InputDropdownDre from './InputDropdownDre';
import { Form } from 'informed';
import axios from 'axios';
import { API_HOST } from '../../consts';
import InputSelect from '../../components/inputs/InputSelect';
import { toast } from 'react-toastify';

class FiltersPageHabilidade extends Component {
  handleFilterChange = (filters) => {
    this.props.onFilterChange(filters);
  };

  state = {
    loading: false,
    avaliacoes: [],
    dreOptions: [],
    escolaOptions: [],
    etapaOption: [],
    turmaOptions: [],
    disciplinaOptions: [],
    alunoOptions: [],
    cursosAtual: null,
    disciplinaAtual: null,
    integracaoPayload: null,
    currentEscola: null,
    disciplinaID: null,
    DisciplinaID: null,
    nrEtapa: null,
    numeroEtapa: null,
    dsiciplinaCurrent: null,
    DisciplinaID: null
  };

  mapData = (d) => ({ value: d.id, label: d.dre_name, ...d });
  escolaData = (d) => ({ value: d.id, label: d.nm_escola, ...d });
  etapaEscolaData = (d) => ({ value: d.nr_etapa, label: d.dc_etapa, ...d });
  turmaData = (d) => ({
    value: d.cd_turma,
    label: '(' + d.nm_turma + ') ' + d.cd_turma,
    ...d
  });
  disciplinaData = (d) => ({ value: d.id, label: d.disciplina, ...d });
  alunoData = (d) => ({ value: d.id, label: d.nm_aluno, ...d });
  municipioData = (d) => ({
    value: d.cd_municipio,
    label: d.nm_municipio,
    ...d
  });

  fetchDre = () => {
    axios
      .get(`${API_HOST}/avalia/dre`)
      .then((res) => {
        const data = res.data.map(this.mapData);
        this.setState({ dreOptions: data });
      })
      .catch((err) => {});
  };

  fetchMunicipio = (dreId) => {
    if (dreId) {
      axios
        .get(`${API_HOST}/avalia/municipio?dre_id=${dreId.id}`)
        .then((res) => {
          const data = res.data.results.map(this.municipioData);
          this.setState({ municipioOption: data });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  fetchEscola = (dreId) => {
    if (dreId) {
      axios
        .get(`${API_HOST}/avalia/escola?cd_municipio=${dreId.value}`)
        .then((res) => {
          const data = res.data.map(this.escolaData);
          this.setState({ escolaOptions: data });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  fetchEtapaEscola = (cdEscola) => {
    if (cdEscola) {
      this.setState({ currentEscola: cdEscola.cd_escola });
      axios
        .get(`${API_HOST}/avalia/escola_etapa?cd_escola=${cdEscola.cd_escola}`)
        .then((res) => {
          const data = res.data.results.map(this.etapaEscolaData);
          this.setState({ etapaOption: data });
        })
        .catch((err) => {});
    }
  };

  fetchTurma = (escolaId) => {
    if (escolaId) {
      this.setState({ numeroEtapa: escolaId.nr_etapa });
      this.setState({ nrEtapa: escolaId.cd_etapa });
      axios
        .get(
          `${API_HOST}/avalia/turma?cd_escola=${escolaId.cd_escola}&cd_etapa=${escolaId.cd_etapa}`
        )
        .then((res) => {
          const data = res.data.results.map(this.turmaData);
          this.setState({ turmaOptions: data });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  fetchDisciplinas = (disciplina) => {
    this.setState({ EtapaNumero: disciplina.value });
    axios
      .get(`${API_HOST}/avalia/disciplina`)
      .then((res) => {
        const data = res.data.results.map(this.disciplinaData);
        this.setState({
          disciplinaOptions: data,
          cd_turma: disciplina.cd_turma,
          cd_escola: disciplina.cd_escola
        });
      })
      .catch((err) => {
        console.error(err);
      });
    // }
  };

  fetchAlunos = (idDisciplina) => {
    const { currentEscola } = this.state;
    if (idDisciplina) {
      axios
        .get(
          `${API_HOST}/avalia/estudante?cd_escola=${this.state.cd_escola}&cd_turma=${this.state.cd_turma}&prova_a_cd_disciplina=${idDisciplina.id}`
        )
        .then((res) => {
          const data = res.data.results.map(this.alunoData);
          this.setState({ alunoOptions: data, DisciplinaID: idDisciplina.id });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  fetchIdnAluno = (idn) => {
    this.setState({ currentStudent: idn.idn });
  };
  componentDidMount() {
    this.fetchDre();
  }

  render() {
    const {
      dreOptions,
      escolaOptions,
      etapaOption,
      disciplinaOptions,
      EtapaNumero,
      DisciplinaID,
      municipioOption
    } = this.state;
    const { history } = this.props;
    return (
      <>
        <div style={{ marginTop: '5rem', background: '#fff' }}>
          <div className="container mb-4 pb-4">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h1>
                      Relatório diagnóstico de proficiência por habilidade -
                      visão por escola
                    </h1>
                    <hr />
                  </div>
                  <div className="card-body">
                    <Form
                      getApi={(formApi) => {
                        this.formApi = formApi;
                      }}
                    >
                      {({ formState }) => (
                        <div className="row">
                          <InputDropdownDre col="12" />
                        </div>
                      )}
                      <div className="row">
                        <div className="input-type-style">
                          <label
                            htmlFor="Diretório Regional de Educação (DRE)"
                            className="input-label-style"
                            style={{ marginBottom: '1rem' }}
                          >
                            <span className="filter-label">
                              Diretório Regional de Educação (DRE)
                            </span>
                          </label>
                          <InputSelect
                            label=""
                            col="col-md-12"
                            field="dreOptions"
                            onChange={this.fetchMunicipio}
                            options={dreOptions}
                          />
                        </div>
                        <div className="input-type-style">
                          <label
                            htmlFor="Diretório Regional de Educação (DRE)"
                            className="input-label-style"
                            style={{ marginBottom: '1rem' }}
                          >
                            <span className="filter-label">Município</span>
                          </label>
                          <InputSelect
                            label=""
                            col="col-md-12"
                            field="municipioOption"
                            onChange={this.fetchEscola}
                            options={municipioOption}
                          />
                        </div>

                        <div className="input-type-style">
                          <label
                            htmlFor="Escola"
                            className="input-label-style"
                            style={{ marginBottom: '1rem' }}
                          >
                            <span className="filter-label">Escola</span>
                          </label>
                          <InputSelect
                            label=""
                            col="col-md-12"
                            field="escolaOptions"
                            onChange={this.fetchEtapaEscola}
                            options={escolaOptions}
                          />
                        </div>

                        <div className="input-type-style">
                          <label
                            htmlFor="Etapa Escolar"
                            className="input-label-style"
                            style={{ marginBottom: '1rem' }}
                          >
                            <span className="filter-label">Etapa Escolar</span>
                          </label>
                          <InputSelect
                            label=""
                            col="col-md-12"
                            field="etapaOption"
                            onChange={this.fetchDisciplinas}
                            options={etapaOption}
                          />
                        </div>

                        <div className="input-type-style">
                          <label
                            htmlFor="Disciplina"
                            className="input-label-style"
                            style={{ marginBottom: '1rem' }}
                          >
                            <span className="filter-label">Disciplina</span>
                          </label>
                          <InputSelect
                            label=""
                            col="col-md-12"
                            field="disciplinaOptions"
                            onChange={this.fetchAlunos}
                            options={disciplinaOptions}
                          />
                        </div>

                        <button
                          style={{
                            color: 'white',
                            backgroundColor: '#3C4583',
                            border: '1px solid rgba(58, 58, 58, 0.25)',
                            fontSize: '12pt',
                            textAlign: 'center',
                            cursor: 'pointer',
                            borderRadius: '8px',
                            boxShadow: '2px 4px 5px rgba(58, 58, 58, 0.25)',
                            letterSpacing: '1px',
                            padding: '4px 16px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                          }}
                          onClick={() => {
                            DisciplinaID
                              ? history.push(
                                  `/nivel-habilidade/${DisciplinaID}/escola/${this.state.cd_escola}/etapa/${EtapaNumero}`
                                )
                              : toast.error(
                                  'Os campos não foram preenchidos corretamente. Por favor selecione alguma opção.'
                                );
                          }}
                        >
                          Buscar
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default FiltersPageHabilidade;
