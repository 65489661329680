import React, { useState } from 'react';

import { withRouter } from 'react-router-dom';
import BarChartSkill from '../../components/BarChartSkill';
import Axios from 'axios';
import { get } from 'lodash';
import { API_HOST } from '../../consts';
import { useEffect } from 'react';
import HeaderAvaliaEscola from '../../components/HeaderAvaliaEscola';
import { FooterAvalia } from '../../containers/global/FooterAvalia/styles';
import { Nav, NavItem } from 'reactstrap';
import { ThemeContext } from '../../contexts/ThemeContext';
import Logo from '../../assets/images/logos/Logos';
import AvaliaLogo from '../../assets/images/logos/avalia-logo.png';
import Highcharts from 'highcharts';
import HighchartSkill from './HighchartSkill';
import HeaderAvalia from '../../components/HeaderAvalia';

const ReportStudentSkillLevel = ({ match }) => {
  const [habilidadeData, setHabilidadeData] = useState(undefined);
  const [boletimData, setBoletimData] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const avaliaId = match.params.id;
  const fetchHabilidade = () => {
    Axios.get(`${API_HOST}/avalia/estudante_desempenho?idn=${avaliaId}`)
      .then((res) => {
        setHabilidadeData(res.data.results);
        setLoading(true);
      })
      .catch((err) => console.error(err));
  };
  const fetchBoletimData = () => {
    Axios.get(`${API_HOST}/avalia/boletim_mt/${avaliaId}`)
      .then((res) => {
        setBoletimData(res.data);
      })
      .catch((err) => console.error(err));
  };
  useEffect(() => {
    fetchHabilidade();
    fetchBoletimData();
  }, []);

  const titulo = [
    'Linguagens, Códigos e suas Tecnologias',
    'Matemática e suas Tecnologias'
  ];

  return (
    <>
      <div className="container">
        <div className="row header-row">
          <div className="col-sm-6 col-md-6">
            <div className="col-sm-6 col-md-6">
              <Nav className="nav-logos">
                <NavItem>
                  <figure>
                    <ThemeContext.Consumer>
                      {(theme) => (
                        <img
                          className="logo-main"
                          src={AvaliaLogo}
                          alt={Logo.clientText || Logo.mainText}
                          height="70"
                          style={{ opacity: '0' }}
                        />
                      )}
                    </ThemeContext.Consumer>
                  </figure>
                </NavItem>
              </Nav>
            </div>
          </div>
        </div>
      </div>
      <div
        id="nav-expand"
        className="nav-expand"
        style={{ background: '#3C4583' }}
      >
        <Nav
          className="navbar-links"
          style={{ background: '#3C4583', border: 'none' }}
        />
      </div>
      <div style={{ marginTop: '5rem', background: '#fff' }}>
        <div className="container mb-4 pb-4">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h1>
                    Relatório individual do Aluno - desempenho por habilidade{' '}
                  </h1>
                  <hr />
                </div>
                <div
                  className="card-body"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                >
                  <HeaderAvalia
                    schoolName={
                      boletimData ? get(boletimData, 'NM_ESCOLA', '') : ''
                    }
                    schoolYear={
                      boletimData ? get(boletimData, 'NM_ETAPA', '') : ''
                    }
                    dreAndMunicipio={
                      boletimData ? get(boletimData, 'NM_DRE', '') : ''
                    }
                    student={
                      boletimData ? get(boletimData, 'NM_ALUNO', '') : ''
                    }
                  />
                  <div className="infos__school">
                    <h2>Habilidades a serem desenvolvidas</h2>
                    <span className="example-hability">
                      Os dados abaixo dizem respeito às habilidades de pior
                      desempenho, a partir de informações extraídas do
                      processamento do resultado de todas as escolas do Estado
                      de Mato Grosso até o momento. O gráfico demonstra a
                      porcentagem de acertos do aluno dentro do seu grupo de
                      proficiência por habilidade.
                    </span>
                  </div>
                  <div className="school__material">
                    <span>
                      {
                        titulo[
                          boletimData
                            ? get(boletimData, 'CD_DISCIPLINA', '') - 1
                            : ''
                        ]
                      }
                    </span>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                      width: '100%',
                      fontSize: '18px',
                      fontWeight: 'bold'
                    }}
                  >
                    <span>
                      {boletimData ? get(boletimData, 'NM_ALUNO', '') : ''} :
                    </span>
                    <div
                      style={{
                        marginLeft: '1rem',
                        width: '40px',
                        height: '20px',
                        border: 'none',
                        borderRadius: '8px',
                        background: `${
                          boletimData ? get(boletimData, 'cor', '') : '#fff'
                        }`
                      }}
                    ></div>
                    <span style={{ margin: '1rem' }}>
                      {boletimData
                        ? get(boletimData, 'proficiencia_aluno', 'indefinida')
                        : 'indefinida'}
                    </span>
                  </div>
                  <div className="table-proficiency">
                    <span>
                      {boletimData ? get(boletimData, 'NM_ETAPA', '') : ''}
                    </span>
                  </div>
                  <hr className="pro-hr" />
                  {habilidadeData &&
                    habilidadeData.map((dataHability) => (
                      <div style={{ width: '100%' }}>
                        <div className="proeficiency__infos">
                          <>
                            <div className="axis__infos">
                              <div className="axis__text">
                                <span>
                                  Eixo de Conhecimento:{' '}
                                  <span
                                    style={{
                                      color: '#3C4583',
                                      marginLeft: '1rem',
                                      fontWeight: '500'
                                    }}
                                  >
                                    {get(dataHability, 'competencia', '')}
                                  </span>
                                </span>
                              </div>

                              <div className="habilidade">
                                <span>Habilidade: </span>
                                <span className="skill-text">
                                  {get(dataHability, 'habilidade', '')}
                                </span>
                              </div>
                            </div>
                          </>
                          <div className="graphic_circular">
                            <HighchartSkill data={dataHability} />
                          </div>
                        </div>
                      </div>
                    ))}
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'right',
                      margin: '1rem'
                    }}
                  >
                    <button
                      style={{
                        border: 'none',
                        color: '#fff',
                        background: '#3C4583',
                        borderRadius: '8px',
                        fontSize: '16px'
                      }}
                      onClick={() => window.open(`/aluno/${avaliaId}`, '_self')}
                    >
                      Relatório do Aluno
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterAvalia />
    </>
  );
};

export default withRouter(ReportStudentSkillLevel);
