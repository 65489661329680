import React from 'react';

export default class ViewReference extends React.Component {
	render () {
		const { text, color } = this.props;
		return (
			<div className={`card-reference view ${color}`}>
				<div className="card-reference-body text-left">
					<p>{text}</p>
				</div>
			</div>
		);
	}
};
