import React from 'react';
import Highcharts from 'highcharts';
import { zip } from 'lodash';

const DEFAULT_COLORS = ['#CC7B38', '#5F0F40', '#EE6055', '#57C5F5', '#5A5A5A'];
const GABARITO_COLOR = 'limegreen';

class LinhaAlternativaEscolhida extends React.Component {
  componentDidUpdate(prevProps) {
    const { data } = this.props;
    if (prevProps.data !== data) {
      this.createChart();
    }
  }

  get colors() {
    const { gabarito } = this.props;
    const colors = [...DEFAULT_COLORS];
    if (gabarito) {
      colors[gabarito.charCodeAt(0) - 97] = GABARITO_COLOR;
    }
    return colors;
  }

  get categories() {
    const { data } = this.props;
    return data.map((d) => d[0]);
  }

  createChart() {
    const { id, data } = this.props;
    const dataSemGrupos = data.map((d) => d.slice(1));
    const dataComAlternativa = zip(...dataSemGrupos).map((d, i) => [
      String.fromCharCode(i + 65),
      ...d
    ]);
    const series = dataComAlternativa.map((d) => ({
      name: d[0],
      data: d.slice(1)
    }));

    Highcharts.setOptions({
      lang: {
        decimalPoint: ',',
        thousandsSep: '.'
      }
    });

    const options = {
      series,
      colors: this.colors,
      chart: {
        events: {
          // load: function () {
          //   var $difs = $('.group_value');
          //   $difs.each(function (index, dif){
          //   var value = $(dif).html();
          //     $(dif).html(parseFloat(value).toLocaleString('pt', {minimumFractionDigits:1, maximumFractionDigits:1}));
          //   });
          // }
        },
        type: 'line',
        width: 400,
        marginTop: 20
      },
      xAxis: {
        allowDecimals: false,
        colors: this.colors,
        title: {
          text: 'Grupos'
        },
        categories: this.categories
      },
      title: false,
      yAxis: {
        allowDecimals: true,
        title: {
          text: 'Porcentagem'
        },
        min: 0,
        max: 100,
        tickInterval: 25
      }
    };

    Highcharts.chart(id, Highcharts.merge(options));
  }

  render() {
    const { id } = this.props;
    return <div id={id} />;
  }
}

export default LinhaAlternativaEscolhida;
