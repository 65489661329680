import React from 'react';
import { get } from 'lodash';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip
} from 'reactstrap';
import BaseList from '../../base/BaseList';
import FormTable from '../../components/FormTable';
import NavBreadCrumb from '../../containers/NavBreadCrumb';
import { API_HOST, UserContext } from '../../consts';
import ListData from '../../components/ListData';
import ListTag from '../../components/ListTag';
import ListaEncomendaCardTabs from './ListaEncomendaCardTabs';
import Loading from '../../components/Loading';
import AddButton from '../../components/buttons/AddButton';
import swal from 'sweetalert2';

class ListaEncomendas extends BaseList {
  constructor(props) {
    super(props);
    this.state = {
      trimmedContent: 'collapse-conteudo',
      iconToggle: 'down',
      collapse: false,
      data: [],
      ordering: '-criado_em',
      checked: {},
      search: ''
    };
  }

  api = `${API_HOST}/encomenda/encomenda`;
  removeConfirmationMessage = (id) =>
    `Tem certeza que deseja remover a encomenda ${id}?`;
  shouldFetchPermissions = true;

  fetchDataCallback(data) {
    this.fetchPedidos(data).then(this.fetchItensPorStatus);
  }

  fetchPedidos = (data) => {
    const ids = data.map((d) => d.id);
    return axios
      .get(`${API_HOST}/encomenda/pedido?encomenda=${ids}`)
      .then((response) => {
        const pedidos = response.data;
        this.setState({
          data: data.map((d) => ({
            ...d,
            pedidos: pedidos.filter((p) => p.encomenda === d.id)
          }))
        });
      });
  };

  fetchItensPorStatus = () => {
    axios
      .get(`${API_HOST}/encomenda/encomenda/itens_por_status`, {
        params: this.getParams()
      })
      .then((response) => {
        const itens_por_status = response.data;
        const { data } = this.state;
        this.setState({
          data: data.map((d) => ({
            ...d,
            itens_por_status: get(itens_por_status, d.id) || []
          }))
        });
      });
  };

  renderItensPorStatus(encomenda) {
    const itensPorStatus = get(encomenda, 'itens_por_status') || [];
    const headers = itensPorStatus.map((d) => d.status);
    const data = [itensPorStatus.map((d) => d.total)];
    return (
      <FormTable
        classe="table-lista-encomendas"
        headers={headers}
        data={data}
      />
    );
  }

  renderListSmall(encomenda) {
    return (
      <>
        {encomenda.instituicao && (
          <p className="mb-0 mt-1">{encomenda.instituicao.descricao}</p>
        )}
        <div className="mt-1">
          <span>Início: {encomenda.inicio_recebimento_item || '-'}</span>
          <span className="ml-3">
            Término: {encomenda.termino_recebimento_item || '-'}
          </span>
        </div>
      </>
    );
  }

  onCopy = (id) => {
    axios
      .post(`${API_HOST}/encomenda/encomenda/${id}/copy`)
      .then(() => {
        toast.success('Copiado com sucesso.');
        this.fetchData();
      })
      .catch((error) => {
        const status = get(error, 'response.status');
        if (status === 404) {
          const e = get(error, 'response.data.detail');
          toast.error(e);
        } else {
          toast.error('Aconteceu um erro. A cópia não foi efetuada');
        }
      });
  };

  getActions(d) {
    const permissions = get(d, 'permissions') || {};
    const permissionsUser = get(this.props, 'permissionsUser') || {};
    const getAllPermissions = get(permissionsUser, '__all__');

    const actions = {
      update: (
        <Link className="btn btn-white mb-3" key="1" to={`/encomendas/${d.id}`}>
          <i className="fas fa-edit" />
        </Link>
      ),
      destroy: (
        <button
          type="button"
          className="btn btn-white mb-3"
          key="3"
          onClick={() => this.handleRemove(d.id)}
        >
          <i className="fas fa-times" />
        </button>
      )
    };

    return (
      <>
        <UncontrolledTooltip
          key={1}
          delay={0}
          placement="top"
          target="copy-encomenda"
        >
          Fazer uma Cópia da Encomenda
        </UncontrolledTooltip>
        <button
          id="copy-encomenda"
          key="3"
          type="button"
          className="btn btn-white mb-3"
          style={{
            display: `${
              getAllPermissions ||
              get(permissionsUser, 'encomenda.duplicate_encomenda')
                ? ''
                : 'none'
            }`
          }}
          onClick={() => {
            swal
              .fire({
                icon: 'warning',
                title: 'Tem certeza que deseja duplicar a encomenda',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
                reverseButtons: true
              })
              .then((result) => {
                if (result.value === true) {
                  this.onCopy(d.id);
                }
              });
          }}
        >
          <i className="far fa-copy" />
        </button>
        {Object.entries(actions)
          .filter((a) => permissions[a[0]])
          .map((a) => a[1])}
      </>
    );
  }

  get encomendaTabs() {
    const tabs = [];
    if (this.hasPermission('encomenda.view_elaborador')) {
      tabs.push('pedidos');
    }
    if (this.hasPermission('encomenda.view_any_encomenda')) {
      tabs.push('elaboradores');
    }
    if (this.hasPermission('encomenda.view_revisor')) {
      tabs.push('revisores');
    }
    return tabs;
  }

  alterarStatus({ noCheckedMsg, successMsg, errorMsg, endpoint }) {
    const { checked } = this.state;
    const encomendas = Object.entries(checked)
      .filter((d) => d[1])
      .map((d) => d[0]);

    if (encomendas.length === 0) {
      toast.error(noCheckedMsg);
      return;
    }

    axios
      .post(`${API_HOST}/encomenda/encomenda/${endpoint}`, { encomendas })
      .then(() => {
        toast.success(successMsg);
        this.fetchData(false);
        this.setState({ checked: {} });
      })
      .catch(() => {
        toast.error(errorMsg);
      });
  }

  colocarEmAndamento = () => {
    this.alterarStatus({
      noCheckedMsg:
        'Por favor, selecione uma ou mais encomendas para colocar em andamento.',
      successMsg: 'As encomendas selecionadas foram colocadas em andamento.',
      errorMsg: 'Não foi possível colocar as encomendas em andamento.',
      endpoint: 'colocar_em_andamento'
    });
  };

  finalizar = () => {
    this.alterarStatus({
      noCheckedMsg:
        'Por favor, selecione uma ou mais encomendas para finalizar.',
      successMsg: 'As encomendas selecionadas foram finalizadas',
      errorMsg: 'Não foi possível finalizar as encomendas.',
      endpoint: 'finalizar'
    });
  };

  handleChecked(event, id) {
    const { checked } = this.state;
    checked[id] = event.target.checked;
    this.setState({ checked });
  }

  renderList() {
    const { data, fetchingData, checked } = this.state;

    if (fetchingData) {
      return <Loading />;
    }

    return data.map((encomenda) => (
      <div className="item" key={encomenda.id}>
        <div className="container">
          <div className="row">
            <div className="checkbox-input-item col-lg-1 col-md-1 col-sm-2 col-xs-2 border-0">
              <label className="container-check-custom">
                <input
                  type="checkbox"
                  aria-label=""
                  id={`checkbox-${encomenda.id}`}
                  onChange={(e) => this.handleChecked(e, encomenda.id)}
                  checked={checked[encomenda.id]}
                />
                <span className="checkmark" />
              </label>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-10 col-xs-10">
              <ListData
                description={[
                  { label: '', value: this.renderItensPorStatus(encomenda) }
                ]}
                title={get(encomenda, 'nome', '-')}
                small={this.renderListSmall(encomenda)}
                atualizado_em={encomenda.atualizado_em || '-'}
                versao={1}
              />
            </div>
            <div
              style={{ paddingLeft: '50px', marginLeft: '39px' }}
              className="col-lg-3 col-md-3 col-xs-12 col-sm-12"
            >
              <ListTag
                onClickExpand={this.onClickExpand}
                actions={this.getActions(encomenda)}
                groupIcon={this.props.groupIcon}
                onClickGroup={this.onClickGroup}
                label={get(encomenda, 'status.descricao')}
                color={get(encomenda, 'status.cor')}
              />
            </div>
          </div>
        </div>
        <ListaEncomendaCardTabs
          view_revisor_permission={this.hasPermission('encomenda.view_revisor')}
          view_generate_report_permission={this.hasPermission(
            'encomenda.generate_report'
          )}
          fetchEncomenda={this.debouncedFetchData}
          encomenda_dados={encomenda}
          encomenda={encomenda.id}
          pedidos={get(encomenda, 'pedidos') || []}
          tabs={this.encomendaTabs}
        />
      </div>
    ));
  }

  renderBreadcrumb() {
    const currentPath = 'Encomendas';
    return <NavBreadCrumb currentPath={currentPath} hasBackslash={false} />;
  }

  get showAddButton() {
    const { permissions } = this.props;
    return (
      get(permissions, 'encomenda.add_encomenda') || get(permissions, '__all__')
    );
  }

  get showActionButton() {
    const { permissions } = this.props;
    return (
      get(permissions, 'encomenda.change_encomenda') ||
      get(permissions, '__all__')
    );
  }

  renderAddButton() {
    if (this.showAddButton) {
      return (
        <Link to="/encomendas/new">
          <AddButton icon="plus" title="Nova encomenda" />
        </Link>
      );
    }
  }

  renderToolbar() {
    const { search } = this.state;
    return (
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          {this.showActionButton && (
            <label className="container-check-custom">
              <input
                type="checkbox"
                id="selecionar-todos"
                aria-label=""
                onChange={this.selecionarTodos}
              />
              <span className="checkmark" />
            </label>
          )}
          {this.renderAddButton()}
          {this.showActionButton && (
            <div>
              <UncontrolledDropdown>
                <DropdownToggle caret className="btn-white ml-2">
                  Ações
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={this.colocarEmAndamento}>
                    Colocar em andamento
                  </DropdownItem>
                  <DropdownItem onClick={this.finalizar}>
                    Finalizar
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          )}
        </div>
        <div className="d-flex">
          <div className="search">
            <input
              className="search-term"
              placeholder="Pesquisar..."
              value={search}
              onChange={(event) => {
                const { value } = event.target;
                this.setState({ search: value });
                this.debouncedFetchData(value);
              }}
            />
          </div>
          {this.renderOrderingSelect()}
        </div>
      </div>
    );
  }
}

export default (props) => (
  <UserContext.Consumer>
    {({ permissions }) => (
      <ListaEncomendas permissionsUser={permissions} {...props} />
    )}
  </UserContext.Consumer>
);
