import React, { Fragment } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import axios from 'axios';
import { size, map } from 'lodash';
import { API_HOST } from '../../consts';
import Loading from '../../components/Loading';
import Link from "react-router-dom/es/Link";

class Blocos extends React.Component {
  state = {
    matrizes_referencia: [],
    markedForDeletion: [],
    fetchingData: true,
    tooltipOpen: false,
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const { match } = this.props;

    axios.get(`${API_HOST}/testes/bloco`, {
      params: {
        teste: match.params.id,
      }
    })
      .then((response) => {
        this.setState({ blocos: response.data, fetchingData: false });
      })
  }

  get next() {
    const { step } = this.props;
    if (size(step.substeps) > 0) {
      return step.substeps[0].link;
    }

    return 'confirmar';
  }

  itemCountClass(bloco) {
    if (size(bloco.itens) === bloco.total_itens_bloco) return <i className="fas fa-check-circle right"/>;
    if (size(bloco.itens) < bloco.total_itens_bloco) return <i className="fas fa-exclamation-circle wrong"/>;
    return null;
  }

  renderActions(bloco, i) {
    if (size(bloco.itens) === bloco.total_itens_bloco) {
      return (
        <Fragment>
          <Link id={`editar${i + 1}`} className="btn btn-white" to={`blocos/${i + 1}`}><i
          className="far fa-edit"/>
          </Link>
          <UncontrolledTooltip delay={0} placement="right" target={`#editar${i + 1}`}>
            Editar Bloco
          </UncontrolledTooltip>
        </Fragment>
      );
    }

    if (size(bloco.itens) < bloco.total_itens_bloco) {
      return (
        <Fragment>
          <Link id={`p${i + 1}`} className="btn btn-white" to={{pathname: `blocos/${i + 1}`, openModal: 'modalItens'}}>
            <i className="fas fa-plus"/>
          </Link>
          <Link id={`editar${i + 1}`} className="btn btn-white" to={`blocos/${i + 1}`}>
            <i className="far fa-edit"/>
          </Link>
          <UncontrolledTooltip delay={0} placement="right" target={`#editar${i + 1}`}>
            Editar Bloco
          </UncontrolledTooltip>
          <UncontrolledTooltip delay={0} placement="top" target={`#p${i + 1}`}>
            Adicionar Item
          </UncontrolledTooltip>
        </Fragment>
      );
    }

    return null;
  }

  render() {
    const {
      blocos,
      fetchingData,
    } = this.state;

    if (fetchingData) {
      return <Loading />;
    }

    return (
      <div className="row mb-4 pb-3">
        {map(blocos, (b, i) => (
          <div key={b.id} className="col-sm-12 mb-1 mt-2">
            <div className="card">
              <div className="card-body pt-4 pb-2">
                <div className="row">
                  <div className="col-6">
                    <h5 className="azul"><b>Bloco {i + 1}</b></h5>
                  </div>
                  <div className="col-4">
                    <p className="text-right azul"><span>{this.itemCountClass(b)}</span><b> Itens:</b><span
                      className="cinza"> {size(b.itens)}/{b.total_itens_bloco}</span></p>
                  </div>
                  <div className="col-2">
                    <div className="btn-group pull-right" role="group" aria-label="Actions">
                      {this.renderActions(b, i)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default Blocos;
