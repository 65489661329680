import React from 'react';
import LabelView from '../components/LabelView';

export default class Details extends React.Component {
    static defaultProps = {
      initialDropdownState: false,
      title: '-',
    }

    state = {
      dropdown: false,
    };

    componentDidMount() {
      const { initialDropdownState } = this.props;
      this.setState({ dropdown: initialDropdownState });
    }

    renderContent() {
      if (this.state.dropdown) {
        return (
          <div style={{borderTop: '0.5px solid #d6d6d6'}} id="expand-card" className="card-body bg-white">

            <div className="row">
              {this.props.details.map((detail, index) => {
                const props = {
                  ...detail,
                  text: detail.text || '-',
                };
                return <LabelView key={index} {...props} />;
              })}
            </div>
          </div>
        );
      }

      return null;
    }

  render() {
    const { dropdown, title } = this.state;
    return (
      <div className="mb-4 details bg-white card">
        <div className="card-header bg-white" onClick={() => this.setState({ dropdown: !dropdown })}>
          <i className={`mr-2 ${dropdown ? 'chevron-up' : 'chevron-down'}`} />
          <span> {title}</span>
        </div>
        {this.renderContent()}
      </div>
    )
  }
};
