import axios from 'axios';
import { get, join } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import Table from '../../components/Table';
import { API_HOST } from '../../consts';
import withUser from '../../hoc/withUser';

class ModalItensTable extends React.Component {
  state = {
    definindoFluxoRevisao: false
  }

  definirFluxoRevisao = async (itemId) => {
    this.setState({ definindoFluxoRevisao: true })
    try {
      await axios.post(
        `${API_HOST}/encomenda/definir_fluxo_revisao_item/${itemId}`)
      toast.success(`Fluxo de revisão definido para o item #${itemId}.`)
    } catch (_) {
      toast.error('Não foi possível definir o fluxo de revisão.')
    } finally {
      this.setState({ definindoFluxoRevisao: false })
    }
  }

  get columns() {
    return [
      {
        Header: 'Item',
        accessor: 'id',
        Cell: row => (
          <div>
            <Link to={`/itens?id=${row.value}`}>{row.value}</Link>
            {this.props.hasPermission(this.props.permissions, 'encomenda.definir_fluxo_revisao') && (
              <>
                <UncontrolledTooltip delay={0} placement="right" target={`definir-fluxo-${row.value}`}>
                  definir fluxo de revisão
                </UncontrolledTooltip>
                <button
                  id={`definir-fluxo-${row.value}`}
                  className="btn btn-default"
                  disabled={this.state.definindoFluxoRevisao}
                  onClick={() => this.definirFluxoRevisao(row.value)}
                >
                  <i className="fa fa-refresh" />
                </button>
              </>
            )}
          </div>
        )
      },
      {
        Header: 'Conteúdo',
        accessor: 'areas_conhecimento',
        Cell: row => join(row.value, ', ') || '-',
      },
      {
        Header: 'Situação',
        accessor: 'status',
        Cell: row => (
          <React.Fragment>
            <i className="fas fa-circle" style={{ color: get(row.value, 'cor') }} />
            {` ${get(row.value, 'descricao')}`}
          </React.Fragment>
        ),
      },
      {
        Header: 'Data do item',
        accessor: 'criado_em',
      },
      {
        Header: 'Dificuldade',
        accessor: 'dificuldade',
        Cell: row => row.value || '-',
      },
    ];
  }

  render() {
    const { data } = this.props;
    if (data.length === 0) {
      return <p>Nenhum item.</p>;
    }
    return (
      <Table
        data={data}
        columns={this.columns}
        pageSize={data.length}
        showPagination={false}
        showPageSizeOptions={false}
      />
    );
  }
}

ModalItensTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

ModalItensTable.defaultProps = {
  data: [],
};

export default withUser(ModalItensTable)
