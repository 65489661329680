import React, { useEffect } from 'react';
import styled from 'styled-components';
import { AuthProvider, useAuth } from 'oidc-react';
import Loading from '../components/Loading';
import { API_HOST } from '../consts'
import Axios from 'axios';
import { useState } from 'react';
import { withRouter } from 'react-router-dom';

const ExternalAuthWrapper = ({ provider, history }) => {
  const auth = useAuth();

  const handleExternalLogin = (userData) => {
    Axios
      .post(`${API_HOST}/external-auth/${provider}/signin`, { profile: userData.profile })
      .then((response) => {
        localStorage.setItem('token', response.data.token);
        history.push('/');
      })
  }

  useEffect(() => {
    if (!auth.isLoading && auth.userData) {
      handleExternalLogin(auth.userData)
    }

  }, [auth])

  return (
    <>
      <span>External Auth Page</span>
    </>
  );
}

const ExternalAuthPage = ({ className, match, history }) => {


  const [ providerConfig, setProviderConfig ] = useState(null)
  const { provider } = match.params

  const fetchProvider = async (provider) => {
    const response = await Axios.get(`${API_HOST}/external-auth/${provider}`)

    setProviderConfig(response.data)
  }

  useEffect(() => {
    if (provider) {
      fetchProvider(provider)
    }

  }, [provider])

  if (!providerConfig) {
    return <Loading />
  }

  const oidcConfig = {
    authority: providerConfig.authority_uri,
    clientId: providerConfig.client_id,
    clientSecret: providerConfig.client_secret,
    redirectUri: providerConfig.redirect_uri,
    responseType: 'code',
    scope: 'openid profile email',
  };

  return (
    <AuthProvider {...oidcConfig}>
      <div className={className}>
        <ExternalAuthWrapper provider={provider} history={history}/>
      </div>
    </AuthProvider>
  );
};
export default styled(withRouter(ExternalAuthPage))`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  p {
    margin-top: 0.5rem;
    font-weight: bold;
  }
`;
