import React from 'react';
import PropTypes from 'prop-types';

class RawInputCheckbox extends React.Component {
  static propTypes = {
    col: PropTypes.string,
    label: PropTypes.string,
    help: PropTypes.string,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    col: 'col-md-12',
    label: '',
    help: null,
  };

  render() {
    const {
      col,
      label,
      onChange,
      checked,
      help,
      ...rest
    } = this.props;
    return (
      <div className={`${col} form-group`}>
        <label className="container-check-custom">
          {label && <span className="ml-4"> {label} </span>}
          <input
            type="checkbox"
            checked={checked}
            onChange={onChange}
            {...rest}
          />
          <span className="checkmark" />
          {help && <small className="form-text text-muted">{help}</small>}
        </label>
      </div>
    );
  }
}

export default RawInputCheckbox;
