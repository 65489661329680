import React from 'react';
import PropTypes from 'prop-types';
import { BreadcrumbItem } from 'reactstrap';

class BreadCrumbItem extends React.Component {
  render() {
    const { label } = this.props;
    return <BreadcrumbItem className="breadcrumb-item"> {label} </BreadcrumbItem>;
  }
}

BreadCrumbItem.propTypes = {
  label: PropTypes.node,
};

BreadCrumbItem.defaultProps = {
  label: '',
};

export default BreadCrumbItem;
