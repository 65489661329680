export const addParametro = () => ({
  type: 'ADICIONAR_PARAMETRO',
});

export const toggleImportarParametrosModal = () => ({
  type: 'TOGGLE_IMPORTAR_PARAMETROS_MODAL',
});

export const toggleDetalhesParametroImportado = (parametro = null) => ({
  type: 'TOGGLE_DETALHES_PARAMETRO_IMPORTADO_MODAL',
  payload: parametro === null ? null : { ...parametro },
});

export const sortParametrosImportados = direction => ({
  type: 'SORT',
  payload: direction,
});
