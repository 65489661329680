import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AddButton from '../components/buttons/AddButton';

class PrevButton extends React.Component {

  render() {
    const { onClick, to, disabled } = this.props;

    if (onClick) {
      return <AddButton disabled={disabled} onClick={onClick} icon="chevron-left" noTitle />;
    }

    return (
      <Link to={to}>
        <button className="btn primary-button mr-2"><i className="fas fa-chevron-left" /></button>
      </Link>
    );
  }
};

PrevButton.propTypes = {
  to: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

PrevButton.defaultProps = {
  onClick: null,
  disabled: false,
};

export default PrevButton;
