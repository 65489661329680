import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import Loading from '../../components/Loading';
import ItemPendencias from '../../components/ItemPendencias';

class VerificacaoAutomatica extends React.Component {
  render() {
    const { item } = this.props;
    return (
      <div className="mb-3">
        <div className="card card-bg">
          <div className="card-header pb-0">
            <h1>Verificação automática</h1>
            <Link target="blank" to={`/itens/historico/${item}`} className="">
              Todas as versões
            </Link>
          </div>
          <div className="card-body">
            {item ? <ItemPendencias item_id={item} /> : <Loading />}
          </div>
        </div>
      </div>
    );
  }
}

VerificacaoAutomatica.propTypes = {
  item: PropTypes.number.isRequired,
};

export default withRouter(VerificacaoAutomatica);
