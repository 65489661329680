import React  from 'react';
import { get, size } from 'lodash';

class BaseAnalise extends React.Component {
  get analise() {
    const { match: { params: { id } } } = this.props;
    return id;
  }

  get itens() {
    const { analise } = this.state;
    return get(analise, 'itens') || [];
  }

  get loading() {
    const { fetchingAnalise, fetchingAnaliseR } = this.state;
    return fetchingAnalise || fetchingAnaliseR;
  }

  get aplicacoes() {
    const { analise } = this.state;
    const aplicacoes = get(analise, 'aplicacoes') || [];
    return aplicacoes;
  }

  get totalItens() {
    const { analise } = this.state;
    return size(get(analise, 'itens')) || 0;
  }

  get totalCandidatos() {
    return this.aplicacoes.reduce((acc, a) => acc + a.numero_candidatos, 0);
  }
}

export default BaseAnalise;
