import React from 'react';
import { get, map } from 'lodash';
import { API_HOST } from '../../consts';
import NavBreadCrumb from '../../containers/NavBreadCrumb';
import RawInputSearch from '../../components/inputs/RawInputSearch';
import ListTag from '../../components/ListTag';
import BaseList from '../../base/BaseList';
import LinkButton from '../../components/buttons/LinkButton';

class ListaCompetencias extends BaseList {
  constructor(props) {
    super(props);
    this.api = `${API_HOST}/matriz_referencia/competencia`;
  }

  removeConfirmationMessage() {

  }

  renderActions(competencia) {
    const actions = [];

    actions.push((
      <LinkButton white key="1" to={`/competencias/${get(competencia, 'id')}`}>
        <i className="fas fa-edit" />
      </LinkButton>
    ));

    return actions;
  }

  renderList() {
    const {
      small,
    } = this.props;

    const { data } = this.state;

    return (
      <div className="form-configuracoes">
        <NavBreadCrumb currentPath="Competências" hasBackslash={false} />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card toolbar">
                <div className="card-header">
                  <div className="row">
                    <div className="col-6"><h1>Lista de Competências</h1></div>
                    <div className="col-4">
                      <RawInputSearch
                        onChange={this.handleSearch}
                      />
                    </div>
                    <div className="col-2 display-flex">
                      <LinkButton to="/competencias/new" primary>+ Adicionar Competência</LinkButton>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body card-body-form">
                {map(data, (competencia, i) => (
                    <div className="item-competencia" key={i}>
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-1 col-md-1 col-sm-2 col-xs-2 border-right">
                            <p className="number">{get(competencia, 'id')}</p>
                          </div>
                          <div className="col-lg-8 col-md-8 col-sm-10 col-xs-10">
                            <div className="list-data">
                              <div className="list-data-title">
                                <small className="list-data-text">
                                  <p>{get(competencia, 'descricao')}</p>
                                </small>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{ paddingLeft: '50px', marginLeft: '30px' }}
                            className="col-lg-3 col-md-3 col-xs-12 col-sm-12"
                          >
                            <ListTag
                              actions={this.renderActions(competencia)}
                              groupIcon={this.props.groupIcon}
                              onClickGroup={this.onClickGroup}
                              label="Ativo"
                              color="var(--success)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ListaCompetencias;
