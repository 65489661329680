import React from 'react';
import { get } from 'lodash';
import { toast } from 'react-toastify';

class BaseComponent extends React.Component {
  constructor(props) {
    super(props);
    this.renderErrors = this.renderErrors.bind(this);
  }

  getErrors(errors) {
    let messages = [];
    Object.keys(errors).forEach((e) => {
      messages = messages.concat(errors[e]);
    });
    return messages;
  }

  renderErrors(error) {
    const status = get(error, 'response.status');
    if (status === 400) {
      const errors = get(error, 'response.data');
      const messages = this.getErrors(errors);
      toast.error((
        <ul>
          {messages.map(m => <li key={m}>{m}</li>)}
        </ul>
      ));
    } else {
      toast.error('Ocorreu um erro. Por favor, tente novamente.');
    }
  }
}

export default BaseComponent;
