import React, { Fragment } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { Form } from 'informed';
import { Link, withRouter } from 'react-router-dom';
import { get, debounce } from 'lodash';
import Table from '../../components/Table';
import NavBreadCrumb from '../../containers/NavBreadCrumb';
import { API_HOST } from '../../consts';
import InputText from '../../components/inputs/InputText';
import InputDate from '../../components/inputs/InputDate';
import CriarRecursoModal from '../../containers/modals/CriarRecursoModal';

class Notas extends React.Component {
  state = {
    arrow: 'down',
    showSearch: false,
    headers: [],
    data: [],
    gabarito: [],
    aplicacao: [],
    avaliacao: {},
    search: null,
    permission: [],
  };

  get id() {
    const { match } = this.props;
    return get(match, 'params.id');
  };

  get columns() {
    const { headers, gabarito } = this.state;
    const respostasIndex = 4;
    const respostas = headers.slice(respostasIndex);

    return [
      {
        Header: 'Aluno',
        accessor: '1',
      },
      {
        Header: 'RA',
        accessor: '2',
      },
      {
        Header: 'Nota',
        accessor: '3',
      },
      ...respostas.map((d, i) => ({
        Header: d,
        accessor: (respostasIndex + i).toString(),
        Cell: row => {
         if(gabarito[(respostasIndex + i)] === row.value) {
          return <p className="success text-center">{row.value}</p>
         } else if (row.value === '-') {
          return <p className="azul text-center">{row.value}</p>
         } else {
          return <p className="danger text-center">{row.value}</p>
         }
        },
      })),
    ];
  }

  componentDidMount() {
    this.fetchAplicacao();
    this.fetchNotas();
  }

  fetchAplicacao() {
    axios.get(`${API_HOST}/aplicacoes/aplicacao/${this.id}`)
      .then((response) => {
        this.setState({
          aplicacao: response.data,
        }, () => {
          this.fetchAvaliacao();
        });
      })
      .catch(() => {
      });
  }

  fetchAvaliacao = () => {
    const { aplicacao } = this.state;
    axios.get(`${API_HOST}/avaliacoes/avaliacao/${get(aplicacao, 'avaliacao.id')}`)
      .then((response) => {
        this.setState({
          avaliacao: response.data,
        });
      })
      .catch(() => {
      });
  }

  fetchNotas() {
    const { search } = this.state;
    axios.get(`${API_HOST}/aplicacoes/aplicacao/${this.id}/notas`, { params: search })
      .then((response) => {
        this.setState({
          data: response.data.data,
          headers: response.data.headers,
          gabarito: response.data.gabarito,
        })
      })
  }

  handleSearch = debounce((search) => {
    this.setState({ search }, this.fetchNotas);
  }, 500);

  defaultSorted = [{ id: '1', desc: false }]

  renderTable() {
    const { data } = this.state;

    return (
      <Table
        filterable
        className="table-default"
        columns={this.columns}
        data={data}
        showPageSizeOptions={false}
        defaultSorted={this.defaultSorted}
      />
    );
  }

  renderForm = () => (
    <Form >
      {({ formApi }) => (
        <React.Fragment>
          <div className="row">
            <InputDate
              label="Data"
              field="data"
              type="date"
              col="col-md-3"
              placeholder="23/06/2018"
            />
            <InputText
              required
              col="col-sm-3"
              label="Avaliação"
              field="avaliacao"
            />
            <InputText
              required
              col="col-sm-3"
              label="Aplicação"
              field="aplicacao"
            />
            <InputText
              required
              col="col-sm-3"
              label="Aluno"
              field="aluno"
            />
            <div className="col-12 text-right">
              <button className="btn primary-button">Buscar</button>
            </div>
          </div>
        </React.Fragment>
      )}
    </Form>
  );

  toggleModal = (name) => {
    const { openModal } = this.state;
    this.setState({ openModal: openModal === name ? null : name });
  };

  renderAdvancedSearch = () => {
    this.setState({ arrow: this.state.arrow === 'down' ? 'up' : 'down', showSearch: !this.state.showSearch });
  };

  render() {
    const pathList = [
      { link: '/instrumentos-cognitivos', label: 'Instrumentos cognitivos' },
      { link: `/aplicacoes/${this.id}`, label: 'Gerenciamento da aplicação' },
    ];
    const {
      aplicacao,
      avaliacao,
      openModal,
    } = this.state;
    return (
      <Fragment>
        <NavBreadCrumb pathList={pathList} currentPath="Notas" />
        <div className="container">
          <div className="row">
            <div className="col-12 mb-4 pb-4">
              <div className="card">
                <div className="card-header">
                  <h1 className="azul">Notas - {get(aplicacao, 'nome')}</h1>
                  <hr />
                  <ul className="list-inline list-style">
                    <li className="list-inline-item item-bg"><strong>Data da prova: </strong> { get(aplicacao, 'data') } </li>
                    <li className="list-inline-item item-bg"><strong>Valor: </strong> { get(avaliacao, 'valor') } </li>
                    <li className="list-inline-item item-bg"><strong>Itens: </strong> { get(avaliacao, 'itens') } </li>
                    <li className="list-inline-item item-bg"><strong>Recursos: </strong>0</li>
                  </ul>
                  <div className="actions-table d-flex">
                    <Link to={`/aplicacoes/${this.id}/notas/itens-anulados`} className="btn bg-amarelo btn-padrao mr-2" ><i className="fas fa-ban mr-1" /> Itens Anulados</Link>
                    <button onClick={() => this.toggleModal('criarRecurso')} className="btn btn-white mr-2"><i className="fas fa-plus mr-1"/> Criar Recurso</button>
                    <CriarRecursoModal toggle={() => this.toggleModal('criarRecurso')} isOpen={openModal === 'criarRecurso'}/>
                    <button className="btn-invisible">
                      <Select
                        style={{ fontSize: 13, height: '33px !important' }}
                        // value={ordering}
                        options={[
                          { value: 'criado_em', label: 'Enviar boletim' },
                          { value: '-criado_em', label: 'Enviar Gabarito' },
                        ]}
                        clearable={false}
                        placeholder="Exportar"
                        // onChange={this.handleOrderingChange}
                        searchable={false}
                      />
                    </button>
                  </div>
                </div>
                <hr />
                <div className="card-body pt-0">
                  <div className="row mb-2 pb-2 align-items-center">
                    <div className="col-sm-12 col-md-5 col-lg-5">
                      <p className="mb-0">Legenda:
                        <i className="fas fa-circle ml-3 success" /> Correta
                        <i className="fas fa-circle ml-3 wrong" /> Incorreta
                        <i className="fas fa-circle ml-3 azulP" /> Anulada
                      </p>
                    </div>
                    {/* <div className="col-sm-12 col-md-3 col-lg-3 text-right">
                      <div className="wrap">
                        <div className="search">
                          <input
                            style={{maxHeight: 33}}
                            className="search-term"
                            placeholder="Pesquisar..."
                            onChange={event => this.handleSearch(event.target.value)}
                          />
                        </div>
                      </div>
                    </div> */}
                    <div className="col-sm-6 col-md-2 col-lg-2">
                      <Select
                        style={{ fontSize: 13, height: '33px !important' }}
                        options={[
                          { value: 'criado_em', label: 'Excel' },
                          { value: '-criado_em', label: 'PDF' },
                        ]}
                        clearable={false}
                        placeholder="Exportar"
                        searchable={false}
                        />
                    </div>
                    <div className="col-sm-6 col-md-2 col-lg-2">
                      <Select
                        style={{ fontSize: 13, height: '33px !important' }}
                        options={[
                          { value: 'criado_em', label: 'Enviar boletim' },
                          { value: '-criado_em', label: 'Enviar Gabarito' },
                        ]}
                        clearable={false}
                        placeholder="Ações"
                        searchable={false}
                      />
                    </div>
                  </div>
                  {this.renderTable()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(Notas);
