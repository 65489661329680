import { isNull } from 'lodash';
import React, { useContext } from 'react';
import withRouter from 'react-router-dom/withRouter';
import { ThemeContext } from 'styled-components';
import ParticipaçãoDreTable from '../components/ParticipacaoDreTable';

const ParticipacaoDreColumn = ({ boletimaluno, hideHeader, ...rest }) => {
  const theme = useContext(ThemeContext);

  const dadosTable = {
    next: null,
    previous: null,
    count: 0,
    total_pages: 1,
    results: [
      {
        firstName: 'Aluno',
        pontuacao: isNull(boletimaluno.NT_ALUNO) ? '-' : boletimaluno.NT_ALUNO,
        nivel: isNull(boletimaluno.NT_ALUNO)
          ? '-'
          : boletimaluno.proficiencia_aluno,
        status: isNull(boletimaluno.NT_ALUNO)
          ? '-'
          : boletimaluno.proficiencia_aluno
          ? boletimaluno.status_proficiencia_ano[
              boletimaluno.proficiencia_aluno
            ]
          : '-'
      },
      {
        firstName: 'DRE',
        pontuacao: isNull(boletimaluno.NT_DRE) ? '-' : boletimaluno.NT_DRE,
        nivel: isNull(boletimaluno.NT_DRE)
          ? '-'
          : boletimaluno.proficiencia_dre,
        status: isNull(boletimaluno.NT_DRE)
          ? '-'
          : boletimaluno.proficiencia_dre
          ? boletimaluno.status_proficiencia_ano[boletimaluno.proficiencia_dre]
          : '-'
      },
      {
        firstName: 'Plano Amostral',
        pontuacao: isNull(boletimaluno.NT_REDE) ? '-' : boletimaluno.NT_REDE,
        nivel: isNull(boletimaluno.NT_REDE)
          ? '-'
          : boletimaluno.proficiencia_rede,
        status: isNull(boletimaluno.NT_REDE)
          ? '-'
          : boletimaluno.proficiencia_rede
          ? boletimaluno.status_proficiencia_ano[boletimaluno.proficiencia_rede]
          : '-'
      },

      {
        firstName: 'Escola',
        pontuacao: isNull(boletimaluno.NT_ESCOLA)
          ? '-'
          : boletimaluno.NT_ESCOLA,
        nivel: isNull(boletimaluno.NT_ESCOLA)
          ? '-'
          : boletimaluno.proficiencia_escola,
        status: isNull(boletimaluno.NT_ESCOLA)
          ? '-'
          : boletimaluno.proficiencia_escola
          ? boletimaluno.status_proficiencia_ano[
              boletimaluno.proficiencia_escola
            ]
          : '-'
      }
    ]
  };

  const columns = [
    {
      Header: () => (
        <span style={{ display: 'flex', justifyContent: 'end' }}>
          Pontuação
        </span>
      ),
      headerStyle: {
        color: 'white',
        'border-top-left-radius': '10px',
        'border-bottom-left-radius': '10px',
        'border-lef': '10px',
        'background-color': `${theme.primary}`
      },
      columns: [
        {
          accessor: 'firstName',
          className: 'd-flex justify-content-center align-items-center',
          width: 150
        },
        {
          accessor: 'pontuacao',
          className: 'd-flex justify-content-center align-items-center'
        }
      ],
      sortable: false,
      className: 'd-flex justify-content-center align-items-center'
    },
    {
      Header: 'Nível',
      headerStyle: {
        color: 'white',
        'border-top-right-radius': '10px',
        'border-bottom-right-radius': '10px',
        'border-lef': '10px',
        'background-color': `${theme.primary}`
      },
      columns: [
        {
          accessor: 'nivel',
          className: 'd-flex justify-content-center align-items-center',
          width: 150
        },
        {
          accessor: 'status',
          className: 'd-flex justify-content-center align-items-center'
        }
      ],
      sortable: false,
      className: 'd-flex justify-content-center align-items-center'
    }
  ];

  return (
    <div style={{ width: '50%', marginTop: '3.2rem' }}>
      <ParticipaçãoDreTable columns={columns} data={dadosTable} {...rest} />
    </div>
  );
};

export default withRouter(ParticipacaoDreColumn);
