import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { REVISAO_ITEM_API_HOST } from '../consts';

const Feedback = ({ text }) => <p style={{ margin: 0 }} className="small">{text}</p>;

class IgnorarPalavraButton extends React.Component {
  state = {
    sending: false,
    sent: false,
    error: false,
  };

  onClick() {
    this.setState({ sending: true });
    const { palavra } = this.props;

    axios.post(`${REVISAO_ITEM_API_HOST}/ignorar_palavra`, palavra)
      .then(() => this.setState({ sent: true, sending: false }))
      .catch(() => this.setState({ error: true, sending: false }));
  }

  render() {
    const { error, sent, sending } = this.state;

    if (error) {
      return <Feedback text="Ocorreu um erro" />;
    }

    if (sent) {
      return <Feedback text="Enviado para análise" />;
    }

    return (
      <button
        className="btn btn-white"
        onClick={() => this.onClick()}
        disabled={sending}
      >
        {sending ? 'enviando...' : 'ignorar correção'}
      </button>
    );
  }
}

IgnorarPalavraButton.propTypes = {
  palavra: PropTypes.shape({
    descricao: PropTypes.string.isRequired,
    instituicao: PropTypes.string.isRequired,
    item: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    contexto: PropTypes.string.isRequired,
  }).isRequired,
};

export default IgnorarPalavraButton;
