import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { BreadcrumbItem } from 'reactstrap';

export default class BreadCrumbIcon extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    link: PropTypes.string.isRequired,
  };

  render() {
    return (
      <BreadcrumbItem className="breadcrumb-link">
        <Link to={this.props.link}>
           {this.props.label}
        </Link>
      </BreadcrumbItem>
    )
  }
};
