import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { get, size } from 'lodash';
import NavBreadCrumb from '../../containers/NavBreadCrumb';
import BaseList from '../../base/BaseList';
import { API_HOST } from '../../consts';
import Loading from '../../components/Loading';
import Table from '../../components/Table';
import AgendarAplicacaoModal from './AgendarAplicacaoModal';
import AddButton from '../../components/buttons/AddButton';

const getStatusStyle = (aplicacao) => {
  const color = get(aplicacao, 'cor') || 'black';
  return { color, fontWeight: 600 };
};

class ListaAplicacoes extends BaseList {
  api = `${API_HOST}/aplicacoes/aplicacao`
  removeConfirmationMessage = id => `Tem certeza que deseja remover a aplicação ${id}?`
  shouldFetchPermissions = true

  state = {
    data: [],
    aplicacaoAtual: null,
    ordering: '-criado_em',
    page_size: 100,
  }

  shouldDisableAction(aplicacao, permission) {
    return get(aplicacao, `permissions.${permission}`);
  }

  handleEditar = (aplicacao) => {
    const toggleModal = () => this.toggleModal('agendarAplicacao');
    this.setState({ aplicacaoAtual: aplicacao }, toggleModal);
  }

  toggleModal = (name, extra = {}) => {
    const { openModal } = this.state;
    this.setState({ openModal: openModal === name ? null : name, ...extra });
  }

  get pageSize() {
    const { data } = this.state;
    if (size(data) === 0) {
      return 0;
    }
    if (size(data) > 20) {
      return 20;
    }
    return size(data);
  }

  getActions(aplicacao) {
    return [
      {
        permission: 'editar_aplicacao',
        label: 'Editar',
        icon: 'edit',
        onClick: () => this.handleEditar(aplicacao),
      },
      {
        permission: 'importar_respondentes',
        label: 'Importar respondentes',
        icon: 'upload',
        // onClick: this.handleImportarRespondentes,
      },
      {
        permission: 'download_respondentes',
        label: 'Baixar respondentes',
        icon: 'cloud-download-alt',
        // onClick: this.handleDownloadRespondentes,
      },
      {
        permission: 'remover_aplicacao',
        label: 'Remover',
        icon: 'times',
        onClick: () => this.handleRemove(aplicacao.id),
      },
    ];
  }

  renderActions(aplicacao) {
    const actions = this.getActions(aplicacao);
    return actions.map((action, i) => (
      <React.Fragment>
        <button
          id={`${action.permission}${i}`}
          type="button"
          className="btn btn-white"
          disabled={this.shouldDisableAction(action, action.permission)}
          onClick={() => action.onClick(aplicacao)}
        >
          <i className={`fas fa-${action.icon}`} />
        </button>
        <UncontrolledTooltip delay={0} placement="bottom" target={`${action.permission}${i}`}>
          {action.label}
        </UncontrolledTooltip>
      </React.Fragment>
    ));
  }

  renderBreadcrumb() {
    const pathList = [
      { link: '/instrumentos-cognitivos', label: 'Instrumentos cognitivos' },
    ];
    return <NavBreadCrumb pathList={pathList} currentPath="" />;
  }

  renderToolbar() {
    const { search } = this.state;

    return (
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <label className="container-check-custom">
            <input
              type="checkbox"
              id="selecionar-todos"
              aria-label=""
              onChange={this.selecionarTodos}
            />
            <span className="checkmark" />
          </label>
          <Link to="/instrumentos-cognitivos/new/dados">
            <AddButton
              icon="plus"
              title="Novo instrumento cognitivo"
            />
          </Link>
        </div>
        <div className="d-flex">
          <div className="search mr-2">
            <input
              style={{ maxHeight: 33 }}
              className="search-term"
              placeholder="Pesquisar..."
              value={search}
              onChange={event => this.handleSearch(event.target.value)}
            />
          </div>
          <div className="btn-group" role="group" aria-label="Filtro">
            <Link to="/instrumentos-cognitivos/">
              <button type="button" className="btn btn-white  radius-5-0">Por Avaliação</button>
            </Link>
            <Link to="/aplicacoes">
              <button type="button" className="btn btn-white radius-0-5 selected-option">Por Aplicação</button>
            </Link>
          </div>
          {this.renderOrderingSelect()}
        </div>
      </div>
    );
  }

  renderSmall(aplicacao) {
    return (
      <React.Fragment>
        {get(aplicacao, 'data')}
        <br />
        <strong>Avaliação</strong>:
        {' '}
        <Link to={`/instrumentos-cognitivos?search=${get(aplicacao, 'avaliacao.id')}`}>
          {get(aplicacao, 'avaliacao.nome')}
        </Link>
      </React.Fragment>
    );
  }

  getColumns() {
    const columns = [
      {
        Header: 'Título',
        accessor: 'nome',
      },
      {
        Header: 'Avaliação',
        accessor: 'avaliacao.nome',
      },
      {
        Header: 'Instituição',
        accessor: 'instituicao',
      },
      {
        Header: 'Curso',
        accessor: 'curso',
      },
      {
        Header: 'Disciplina',
        accessor: 'disciplina',
      },
      {
        Header: 'Data',
        accessor: 'data',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: row => (
          <div style={getStatusStyle(row.value)}>
            {get(row, 'value.descricao')}
          </div>
        ),
      },
      {
        Header: 'Recurso',
        accessor: 'recursos',
      },
      {
        Header: 'Ações',
        accessor: 'acoes',
        width: 280,
        className: 'justify-content-center',
        Cell: row => (
          <div className="d-flex text-right">
            <button
              id={`acoes-${get(row, 'original.id')}`}
              type="button"
              className="btn btn-padrao bg-verde dropdown-toggle mr-1"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Gerenciar
            </button>
            <div className="dropdown-menu dropdown-educat" aria-labelledby={`acoes-${get(row, 'original.id')}`}>
              <Link className="dropdown-item" to={`/aplicacoes/${get(row, 'original.id')}`}>Aplicação</Link>
              <Link className="dropdown-item" to={`/aplicacoes/${get(row, 'original.id')}/resultado`}>Resultados</Link>
            </div>
            <div className="btn-group" role="group" aria-label="Ações">
              {this.renderActions(get(row, 'original'))}
            </div>
          </div>
        ),
      },
    ];
    return columns;
  }

  renderList() {
    const {
      data,
      fetchingData,
      openModal,
      aplicacaoAtual,
    } = this.state;

    if (fetchingData) {
      return <Loading />;
    }

    return (
      <div className="mb-2">
        <Table
          showPageSizeOptions={false}
          showPagination={false}
          pageSize={this.pageSize}
          columns={this.getColumns()}
          data={data}
        />
        <AgendarAplicacaoModal
          isOpen={openModal === 'agendarAplicacao'}
          toggle={() => this.toggleModal(null)}
          aplicacao={aplicacaoAtual}
          fetchAplicacoes={() => this.fetchData(false)}
        />
      </div>
    );
  }
}

export default withRouter(ListaAplicacoes);
