import { withRouter } from 'react-router-dom';
import BaseDados from '../../base/testes/BaseDados';

class Dados extends BaseDados {

  mapPostData(values) {
    return {
      ...values,
      pre_teste: true,
    };
  }
}

export default withRouter(Dados);
