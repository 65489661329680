import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { get, size, capitalize } from 'lodash-es';
import Table from '../../components/Table';
import Loading from '../../components/Loading';
import ModalItensElaborador from './ModalItensElaborador';
import { API_HOST } from '../../consts';

class ElaboradoresTab extends React.Component {
  state = {
    data: {},
    fetchingData: true,
    itemModalProps: {
      filters: {},
      nome: null,
      id: null,
    },
  }

  componentDidMount() {
    const { shouldFetchData } = this.props;
    if (shouldFetchData) {
      this.fetchElaboradores();
    }
  }

  componentDidUpdate(prevProps) {
    const { shouldFetchData } = this.props;
    if (!prevProps.shouldFetchData && shouldFetchData) {
      this.fetchElaboradores();
    }
  }

  get columns() {
    const statuses = get(this.state, 'data.statuses', []);
    return [
      {
        Header: 'Nome',
        accessor: 'nome',
      },
      {
        Header: 'Solicitado',
        accessor: 'quantidade',
      },
      ...statuses.map(status => ({
        Header: capitalize(status.descricao),
        accessor: `statuses.${status.id}`,
        Cell: row => row.value || 0,
      })),
      {
        Header: 'Ações',
        filterable: false,
        sortable: false,
        width: 100,
        Cell: row => (
          <div className="text-center">
            <button
              onClick={() => this.handleVerMais(row)}
              className="btn btn-white btn-sm"
            >
              <i className="fas fa-eye" />
            </button>
          </div>
        ),
      },
    ];
  }

  get pageSize() {
    const length = get(this.state, 'data.usuarios.length');
    const maxPageSize = 20;
    return length > maxPageSize ? maxPageSize : length;
  }

  fetchElaboradores = () => {
    const { encomenda } = this.props;
    this.setState({ fetchingData: true });
    axios.get(`${API_HOST}/encomenda/itens_por_status/usuario`, {
      params: {
        encomenda,
      },
    })
      .then((response) => {
        this.setState({ data: response.data, fetchingData: false });
      });
  }

  handleVerMais(row) {
    const itemModalProps = {
      nome: row.original.nome,
      filters: {
        usuario: row.original.id,
        encomenda: row.original.encomenda,
      },
    };
    this.toggleModal('item', { itemModalProps });
  }

  toggleModal(name, extra = {}) {
    const { openModal } = this.state;
    this.setState({ openModal: openModal === name ? null : name, ...extra });
  }

  render() {
    const {
      data,
      fetchingData,
      openModal,
      itemModalProps,
    } = this.state;
    const { usuarios } = data;
    if (fetchingData) {
      return <Loading />;
    }

    if (size(usuarios) === 0) {
      return <p>Nenhum elaborador.</p>;
    }

    return (
      <div className="professores-first professores-table">
        <div className="row">
          <div className="col-12">
            <Table
              filterable
              showPageSizeOptions={false}
              pageSize={this.pageSize}
              className="table-default"
              columns={this.columns}
              data={usuarios}
            />
            <ModalItensElaborador
              isOpen={openModal === 'item'}
              toggle={() => this.toggleModal('item', { selecionado: null })}
              {...itemModalProps}
            />
          </div>

        </div>
      </div>
    );
  }
}

ElaboradoresTab.propTypes = {
  encomenda: PropTypes.number.isRequired,
  shouldFetchData: PropTypes.bool,
};

ElaboradoresTab.defaultProps = {
  shouldFetchData: false,
};

export default ElaboradoresTab;
