import React from 'react';
import { get } from 'lodash';
import { Link, withRouter } from 'react-router-dom';
import BaseFiltroAvancadoItemAvaliacao from '../../base/BaseFiltroAvancadoItemAvaliacao';
import ListTag from '../../components/ListTag';
import Item from '../../components/Item';
import BaseFiltroAvancadoItemVinculo from '../../base/BaseFiltroAvancadoItemVinculo';

class FiltroAvancadoItens extends BaseFiltroAvancadoItemVinculo {
  handleCheckboxChange = (event, id) => {
    const { onResultClick } = this.props;
    const data = {
      item: id
    };
    onResultClick(event, data);
  };

  onOpened = () => {
    super.onOpened();
    this.setState({ activeTab: 'resultados' });
    this.handleSubmit();
  };

  renderVincularButton(item) {
    const { checked, vincularItem, desvincularItem } = this.props;
    const itemVinculado = checked.find((d) => d === item.id);
    if (itemVinculado) {
      return (
        <button
          className="btn btn-danger"
          onClick={() => desvincularItem(item)}
        >
          <i className="fas fa-times" /> desvincular item
        </button>
      );
    }

    return (
      <button className="btn btn-success" onClick={() => vincularItem(item)}>
        <i className="fas fa-check" /> vincular item
      </button>
    );
  }

  mapItem = (item) => {
    return (
      <div className="col-md-12 mb-3" key={item.id}>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-2">{this.renderVincularButton(item)}</div>
              <div className="col-9 offset-1">
                <div className="row">
                  <div className="col-md-9 list-data-title">
                    <Link to={`/itens/${item.id}`}>
                      <h3>Item {item.id}</h3>
                    </Link>
                  </div>
                  <div className="col-md-3 mb-3">
                    <ListTag
                      label={get(item, 'status.descricao')}
                      color={get(item, 'status.cor')}
                    />
                  </div>
                  <div className="col-md-12">
                    <Item item={item} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default withRouter(FiltroAvancadoItens);
