import axios from 'axios';
import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import AnaliseReportTable from '../../components/AnaliseReportTable';
import AnaliseParameterPicker from '../../components/AnaliseParameterPicker';
import AnaliseReportLegenda from '../../components/AnaliseReportLegenda';
import AnaliseReportExportMenu from '../../components/AnaliseReportExportMenu';
import { rgbToArgb } from '../../utils';
import { API_HOST } from '../../consts';

class AnaliseReportModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coloredProperty: 'bisserial',
      itensAnalise: [],
      sortedItens: []
    };
    this.componentToPrintRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { itens, parametros } = this.props;
    if (prevProps.itens !== itens || prevProps.parametros !== parametros) {
      const showTable = itens.length && parametros.length;
      if (showTable) {
        this.setState({
          showTable,
          itensAnalise: this.itensAnalise
        });
        this.addItensExtraFields();
      }
    }
  }

  onParameterSelect = (e) => {
    const selectedParameter = e.target.id;
    this.setState({
      coloredProperty: selectedParameter
    });
  };

  get itensAnalise() {
    const { itens } = this.props;
    return itens.map((item) => this.standarizeItem(item));
  }

  mergeFieldsWithItems(itensWithFields) {
    const { itensAnalise } = this.state;
    let newItens = [];
    itensWithFields.map((item) => {
      itensAnalise.map((oldItem) => {
        if (item.id === oldItem.id) {
          newItens.push({ ...item, ...oldItem });
        }
      });
    });
    this.setState({
      itensAnalise: newItens
    });
  }

  addItensExtraFields() {
    const { itens } = this.props;
    const extraFields = ['id','disciplina_name','dificuldade', 'dificuldade_name', 'matriz_referencia', 'habilidade_name', 'competencias'];
    const itensIds = itens.map((item) => item.id);
    axios
      .get(`${API_HOST}/itens/item/specific_values`, {
        params: {
          itens: itensIds.join(),
          fields: extraFields.join()
        }
      })
      .then((response) => {
        this.mergeFieldsWithItems(response.data);
      });
  }

  get colorMap() {
    const { itensAnalise } = this.state;
    const coloredColumn = 1;
    const headerOffset = 2;
    return itensAnalise.map((item, index) => {
      const cellColor = this.cellColor(item);
      return {
        x: coloredColumn,
        y: index + headerOffset,
        color: rgbToArgb(cellColor)
      };
    });
  }

  formatItensToExport = (itens) => {
    // Formats itens columns to be more user friendly
    return itens.map((item) => {
      return {
        'Posição do Item na Prova': item.posicao,
        'Item no Educat': item.id,
        'Acertos(%)': item.dificuldade,
        'Dificuldade Real': item.nivel_dificuldade,
        'Dificuldade Presumida': item.dificuldade_name,
        Bisserial: item.bisserial,
        'Nível Bisserial': item.nivel_bisserial,
        Discriminação: item.discriminacao,
        'Nível de Discriminação': item.nivel_discriminacao,
        'Disciplina / Eixo': item.disciplina_name,
        'Habilidades': item.habilidade_name,
        'Competências': item.competencias,
        'Matriz de Referência': item.matriz_referencia,
      };
    });
  };


  onSorting = (rows) => {
    this.setState({
      sortedItens: rows
    });
  };

  cellColor = (item) => {
    const { coloredProperty } = this.state;
    return coloredProperty ? item[`cor_${coloredProperty}`] : null;
  };

  standarizeItem = (item) => {
    const { parametros } = this.props;
    const { gabarito } = item;
    const posicao = item.posicao_corrigida - 1;
    const analiseDoItem = parametros[posicao][gabarito];
    return {
      posicao: item.posicao_corrigida,
      id: item.id,
      ...analiseDoItem
    };
  };

  reportColumns = [
    { title: 'Número', key: 'posicao', order_by: true },
    { title: 'Item no Educat', key: 'id', order_by: true },
    { title: 'Acerto (%)', key: 'dificuldade', order_by: true },
    { title: 'Bisserial', key: 'bisserial', order_by: true },
    { title: 'Discriminação', key: 'discriminacao', order_by: true },
    { title: 'Nível Bisserial', key: 'nivel_bisserial', order_by: false },
    { title: 'Dificuldade Real', key: 'nivel_dificuldade', order_by: false },
    { title: 'Matriz de Referência', key: 'matriz_referencia', order_by: false},
    { title: 'Habilidades', key: 'habilidade_name'},
    {title: 'Competências', key:  'competencias'},
    { title: 'Dificuldade Presumida', key: 'dificuldade_name', order_by: false },
    {
      title: 'Nível Discriminação',
      key: 'nivel_discriminacao',
      order_by: false
    },
    { title: 'Disciplina / Eixo', key: 'disciplina_name', order_by: false },
  ];
;

  render() {
    const { isOpen, onClose, title } = this.props;
    const { coloredProperty, itensAnalise, sortedItens } = this.state;
    const itensToExport = sortedItens.length > 0 ? sortedItens : itensAnalise;

    return (
      <Modal isOpen={isOpen} toggle={onClose} size="xl">
        <ModalHeader toggle={onClose}>Relatório da Análise</ModalHeader>
        <ModalBody>
          <div ref={this.componentToPrintRef}>
            <div className="d-md-flex">
              <h5
                style={{
                  textAlign: 'center',
                  marginTop: '20px'
                }}
              >
                {title}
              </h5>
            </div>
            <AnaliseParameterPicker
              onSelect={this.onParameterSelect}
              checkedParameter={coloredProperty}
            />
            <AnaliseReportLegenda selectedParameter={coloredProperty} />
            <AnaliseReportExportMenu
              componentToPrint={this.componentToPrintRef}
              dataToExport={this.formatItensToExport(itensToExport)}
              colorMap={this.colorMap}
            />
            {this.state.showTable && (
              <AnaliseReportTable
                columns={this.reportColumns}
                rows={itensAnalise}
                coloredProperty={coloredProperty}
                handleSorting={this.onSorting}
              />
            )}
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

AnaliseReportModal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  itens: PropTypes.array,
  parametros: PropTypes.array,
  totalCandidatos: PropTypes.number
};

AnaliseReportModal.defaultProps = {
  title: '',
  isOpen: false,
  onClose: () => {},
  itens: [],
  parametros: [],
  totalCandidatos: 0
};

export default AnaliseReportModal;
