import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-fa';

import { AddButton as AppAddButton } from './styles';

export default class AddButton extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.string,
    disabled: PropTypes.bool,
    noIcon: PropTypes.bool,
    type: PropTypes.string,
    button: PropTypes.string,
    noTitle: PropTypes.bool,
    classes: PropTypes.string,
    backgroundType: PropTypes.oneOf(['primary', 'light', 'secondary']),
  };

  static defaultProps = {
    title: '',
    onClick: () => {},
    icon: 'plus',
    disabled: false,
    noIcon: false,
    noTitle: false,
    type: 'button',
    button: '',
    classes: '',
    backgroundType: 'primary',
  };

  render() {
    return (
      <AppAddButton backgroundType={this.props.backgroundType} type={this.props.type} className={`btn mb-3 ml-3 ${this.props.classes}`} onClick={this.props.onClick} disabled={this.props.disabled}>
        { this.props.noIcon ? <></> : <Icon name={this.props.icon} />} {this.props.noTitle ? '' : this.props.title}
      </AppAddButton>
    );
  }
}
