import React from 'react';
import FIcon from '../text/FIcon';

export default class BtnIcon extends React.Component {
  static defaultProps = {
    col: '',
    title: '',
    clase: '',
    icon: '',
    type: '',
    iconSide: 'left',
    color: '',
    margin: '3px',
    onClick: () => {
    },
  };

  render() {

  const {
  col,
  onClick,
  title,
    type,
    iconSide,
    color,
    margin,
  icon} = this.props;

    return (
      <div className={`btn  ${col}`} onClick={onClick}>
        { iconSide === 'left' ? (
          <div>
            <FIcon type={type} icon={icon} color={color} margin={margin} />
            {title}
          </div>
          ): (
          <div>
            {title}
            <FIcon type={type} icon={icon} color={color} margin={margin}  />
          </div>
        )

        }
      </div>
    )
  }
};
