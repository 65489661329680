import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { get, debounce } from 'lodash';
import { Form } from 'informed';
import axios from 'axios';
import { toast } from 'react-toastify';
import { UncontrolledTooltip } from 'reactstrap';
import InputText from '../../components/inputs/InputText';
import InputMaskDate from '../../components/inputs/InputMaskDate';
import InputCheckbox from '../../components/inputs/InputCheckbox';
import Table from '../../components/Table';
import Loading from '../../components/Loading';
import { API_HOST } from '../../consts';

const getStatusStyle = (aplicacao) => {
  const color = get(aplicacao, 'cor') || 'black';
  return { color, fontWeight: 600 };
};

export default class TabelaAplicacoes extends Component {
  state = {

  }

  componentDidMount() {
    this.props.fetchAplicacoes()
  }

  getActions(aplicacao) {
    const { editar, remover } = this.props;

    return [
      {
        permission: 'editar_aplicacao',
        label: 'Editar',
        icon: 'edit',
        onClick: () => editar(aplicacao),
      },
      {
        permission: 'importar_respondentes',
        label: 'Importar respondentes',
        icon: 'upload',
        // onClick: this.handleImportarRespondentes,
      },
      {
        permission: 'download_respondentes',
        label: 'Baixar respondentes',
        icon: 'cloud-download-alt',
        // onClick: this.handleDownloadRespondentes,
      },
      {
        permission: 'remover_aplicacao',
        label: 'Remover',
        icon: 'times',
        onClick: () => remover(aplicacao),
      },
    ];
  }

  get aplicacoesSelecionadas() {
    const aplicacoes = get(this.formApi.getState(), 'values.aplicacoes') || [];
    return Object.entries(aplicacoes).filter(d => d[1]).map(d => d[0]);
  }

  getColumns() {
    const { selectAll } = this.state;
    const columns = [
      {
        Header: (
          <label className="container-check-custom">
            <input
              type="checkbox"
              onChange={this.selectAll}
              checked={selectAll}
            />
            <span className="checkmark" style={{ top: -5, left: 9 }} />
          </label>
        ),
        accessor: 'id',
        sortable: false,
        width: 50,
        className: 'p-0',
        Cell: row => <InputCheckbox col="" field={`aplicacoes.${row.value}`} />,
      },
      {
        Header: 'Título',
        accessor: 'nome',
      },
      {
        Header: 'Instituição',
        accessor: 'instituicao',
      },
      {
        Header: 'Curso',
        accessor: 'curso',
      },
      {
        Header: 'Disciplina',
        accessor: 'disciplina',
      },
      {
        Header: 'Data',
        accessor: 'data',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: row => (
          <div style={getStatusStyle(row.value)}>
            {get(row, 'value.descricao')}
          </div>
        ),
      },
      {
        Header: 'Recursos',
        accessor: 'recursos',
      },
      {
        Header: 'Ações',
        accessor: 'acoes',
        width: 280,
        className: 'justify-content-center',
        Cell: row => (
          <div className="d-flex text-right">
            <button
              id={`acoes-${get(row, 'original.id')}`}
              type="button"
              className="btn btn-padrao bg-verde dropdown-toggle mr-2"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Gerenciar
            </button>
            <div className="dropdown-menu dropdown-educat" aria-labelledby={`acoes-${get(row, 'original.id')}`}>
              <Link className="dropdown-item" to={`/aplicacoes/${get(row, 'original.id')}`}>Aplicação</Link>
              <Link className="dropdown-item" to={`/aplicacoes/${get(row, 'original.id')}/resultado`}>Resultados</Link>
            </div>
            <div className="btn-group" role="group" aria-label="Ações">
              {this.renderActions(get(row, 'original'))}
            </div>
          </div>
        ),
      },
    ];

    return columns;
  }

  debouncedSearchTimeout = 500

  debouncedfetchAplicacoes = debounce((...args) => {
    return this.props.fetchAplicacoes(...args);
  }, this.debouncedSearchTimeout)


  handleFilter = () => {
    this.debouncedfetchAplicacoes({ params: this.formApi.getState().values, loading: true });
  }

  shouldDisableAction({ permission }) {
    const { aplicacao } = this.props;
    return get(aplicacao, `permissions.${permission}`);
  }

  atualizarStatus(endpoint) {
    const { aplicacoesSelecionadas } = this;
    const { fetchAplicacoes } = this.props;

    if (aplicacoesSelecionadas.length === 0) {
      toast.error('Selecione pelo menos uma aplicação.');
      return;
    }

    axios.patch(`${API_HOST}/aplicacoes/aplicacao/${endpoint}`, {
      aplicacoes: aplicacoesSelecionadas,
    })
      .then(() => {
        this.formApi.setValues({ aplicacoes: [] });
        fetchAplicacoes({ loading: false });
        this.setState({ selectAll: false });
        toast.success('Status das aplicações alterado com sucesso.');
      })
      .catch(() => {
        toast.error('Ocorreu um erro ao atualizar o status das aplicações selecionadas');
      });
  }

  selectAll = (event) => {
    this.setState({ selectAll: event.target.checked });

    const { aplicacoes } = this.props;
    const values = {};

    aplicacoes.forEach((d) => {
      values[d.id] = event.target.checked;
    });

    this.formApi.setValues({ aplicacoes: values });
  }

  renderActions(aplicacao) {
    const actions = this.getActions(aplicacao);
    return actions.map((action, i) => (
      <React.Fragment>
        <button
          id={`${action.permission}${i}-${aplicacao.id}`}
          type="button"
          className="btn btn-white"
          disabled={this.shouldDisableAction(action)}
          onClick={() => action.onClick && action.onClick(aplicacao)}
        >
          <i className={`fas fa-${action.icon}`} />
        </button>
        <UncontrolledTooltip delay={0} placement="top" target={`${action.permission}${i}-${aplicacao.id}`}>
          {action.label}
        </UncontrolledTooltip>
      </React.Fragment>
    ));
  }

  renderCheckboxActions() {
    const checkboxActions = [
      { onClick: () => this.atualizarStatus('iniciar'), label: 'Iniciar' },
      { onClick: () => this.atualizarStatus('pausar'), label: 'Pausar' },
      { onClick: () => this.atualizarStatus('finalizar'), label: 'Finalizar' },
    ];

    return (
      <div className="dropdown">
        <button
          className="btn btn-white dropdown-toggle mb-3 mr-3"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Ações
        </button>
        <div className="dropdown-menu dropdown-educat" aria-labelledby="dropdownMenuButton">
          {
            checkboxActions.map(d => (
              <button className="dropdown-item" onClick={d.onClick}>{d.label}</button>
            ))
          }
        </div>
      </div>
    );
  }

  renderAplicacoes() {
    const { fetchingAplicacoes, aplicacoes } = this.props;

    if (fetchingAplicacoes) {
      return <Loading />;
    }

    return (
      <Table
        columns={this.getColumns()}
        data={aplicacoes}
        showPageSizeOptions={false}
        pageSize={aplicacoes.length}
      />
    );
  }

  render() {
    return (
      <Form
        getApi={(api) => { this.formApi = api; }}
      >
        <div className="container mt-2">
          <hr />
          <div className="d-flex ml-1">
            <p className="azul mb-0 mt-1 text-spacing mr-3 font-weight-bold">APLICAÇÕES</p>
            {this.renderCheckboxActions()}
            <div className="non-default-input mr-3">
              <InputText
                noLabel
                field="search"
                onChange={this.handleFilter}
                placeholder="Pesquisar..."
              />
            </div>
            <div className="non-default-input mr-3">
              <InputMaskDate
                noLabel
                field="data"
                onChange={this.handleFilter}
                placeholder="Data"
              />
            </div>
          </div>
          {this.renderAplicacoes()}
        </div>
      </Form>
    );
  }
}
