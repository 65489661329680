import React, { Fragment } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { get, map } from 'lodash';

class DropdownControlled extends React.Component {
  state = {
    isOpen: false,
  };

  toggle = (url) => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });

    const { location: { pathname } } = this.props;
    if (pathname === url) {
      window.location.reload();
    }
  };

  mapOption = (option) => {
    if (!option.enabled) {
      return (
        <button
          key={option.id}
          className="dropdown-item children-menu disabled"
        >
          {option.name}
        </button>
      );
    }

    return (
      <Link
        key={option.id}
        className="dropdown-item children-menu"
        onClick={() => this.toggle(option.url)}
        to={option.url || '/'}
      >
        {option.name}
      </Link>
    );
  }

  mapSection = (section) => {
    const options = get(section, 'options') || [];

    return (
      <Fragment key={section.id}>
        {section.name && (
          <span className="dropdown-item divider-menu">
            {section.name}
          </span>
        )}
        {map(options, this.mapOption)}
      </Fragment>
    );
  }

  render() {
    const { isOpen } = this.state;
    const { menu } = this.props;
    const name = get(menu, 'name');
    const sections = get(menu, 'sections') || [];

    return (
      <Fragment>
        <Dropdown isOpen={isOpen} toggle={() => {}} onMouseEnter={this.toggle} onMouseLeave={this.toggle}>
          <DropdownToggle nav className={isOpen ? 'active' : ''}>
            {name}
          </DropdownToggle>
          <DropdownMenu>
            {map(sections, this.mapSection)}
          </DropdownMenu>
        </Dropdown>
      </Fragment>
    );
  }
}

DropdownControlled.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  menu: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    sections: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }).isRequired).isRequired,
    }).isRequired).isRequired,
  }).isRequired,
};

export default withRouter(DropdownControlled);
