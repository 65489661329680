import React, { Component } from 'react';
import { get } from 'lodash';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Form } from 'informed';
import NavBreadCrumb from '../../containers/NavBreadCrumb';
import InputTextArea from '../../components/inputs/InputTextArea';
import Loading from '../../components/Loading';
import { API_HOST } from '../../consts';
import AddButton from '../../components/buttons/AddButton';

class Habilidade extends Component {
  constructor(props) {
    super(props);
    this.id = get(props, 'match.params.id');
  }

  state = {
    submitting: false,
    loading: true,
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    if (this.id !== 'new') {
      axios.get(`${API_HOST}/matriz_referencia/habilidade/${this.id}`)
        .then((response) => {
          this.formApi.setValues({
            descricao: response.data.descricao,
          });
          this.setState({ loading: false });
        });
    } else {
      this.setState({ loading: false });
    }
  }

  handleError = (error) => {
    if (get(error, 'response.status') === 400) {
      get(error, 'response.data.descricao', []).forEach(e => (
        toast.error(e)
      ));
    } else {
      toast.error('Por favor, preencha corretamente todos os campos.');
    }
    this.setState({ submitting: false });
  }

  handleSubmit = (values) => {
    this.setState({ submitting: true });

    if (this.id === 'new') {
      axios.post(`${API_HOST}/matriz_referencia/habilidade`, values)
        .then(() => {
          const { history } = this.props;
          toast.success('Habilidade criada com sucesso.');
          history.push('/habilidades');
        })
        .catch(this.handleError);
    } else {
      axios.patch(`${API_HOST}/matriz_referencia/habilidade/${this.id}`, values)
        .then(() => {
          const { history } = this.props;
          toast.success('Habilidade atualizada com sucesso.');
          history.push('/habilidades');
        })
        .catch(this.handleError);
    }
  }

  render() {
    const pathList = [
      { link: '/habilidades', label: 'Habilidades' },
    ];
    const currentPath = this.id === 'new' ? 'Nova Habilidade' : `Habilidade ${this.id}`;
    const { submitting, loading } = this.state;

    return (
      <div className="form-configuracoes">
        <NavBreadCrumb pathList={pathList} currentPath={currentPath} />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <Form
                  onSubmit={this.handleSubmit}
                  getApi={(formApi) => { this.formApi = formApi; }}
                >
                  {() => {
                    if (loading) {
                      return <Loading />;
                    }

                    return (
                      <React.Fragment>
                        <div className="card-header">
                          <div className="row">
                            <div className="col-6">
                              {this.id === 'new' ? <h1>Nova habilidade</h1> : <h1>Habilidade <b>{this.id}</b></h1>}
                            </div>
                          </div>
                        </div>
                        <div className="card-body card-body-form">
                          <InputTextArea
                            field="descricao"
                            label="Descrição"
                          />
                        </div>
                        <div className="card-footer display-flex">
                          <AddButton
                            type="submit"
                            noIcon
                            title="Salvar"
                            disabled={submitting}
                          />
                        </div>
                      </React.Fragment>
                    );
                  }}
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Habilidade;
