import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { get, size, capitalize } from 'lodash-es';
import Table from '../../components/Table';
import Loading from '../../components/Loading';
import ModalItensRevisor from './ModalItensRevisor';
import { API_HOST, UserContext } from '../../consts';
import { toast } from 'react-toastify';
import AddButton from '../../components/buttons/AddButton';

class RevisoresTab extends React.Component {
  state = {
    data: {},
    fetchingData: true,
    itemModalProps: {
      filters: {},
      nome: null,
      id: null
    },
    tipos_revisao: []
  };

  componentDidMount() {
    const { shouldFetchData } = this.props;
    if (shouldFetchData) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    const { shouldFetchData } = this.props;
    if (!prevProps.shouldFetchData && shouldFetchData) {
      this.fetchData();
    }
  }

  get columns() {
    const { tipos_revisao } = this.state;
    return [
      {
        Header: 'Nome',
        accessor: 'nome'
      },
      {
        Header: 'Limite de Revisão',
        accessor: 'solicitado'
      },
      {
        Header: 'Revisado',
        accessor: 'revisado',
        Cell: (row) => row.value || 0
      },
      ...tipos_revisao.map((tipo_revisao) => ({
        Header: capitalize(tipo_revisao.descricao),
        accessor: `tipos_revisao.${tipo_revisao.id}`,
        Cell: (row) => row.value || 0
      })),
      {
        Header: 'Ações',
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (row) => (
          <div className="text-center">
            <button
              onClick={() => this.handleVerMais(row)}
              className="btn btn-white btn-sm"
            >
              <i className="fas fa-eye" />
            </button>
          </div>
        )
      }
    ];
  }

  handleRevisarItem = () => {
    const { encomenda } = this.props;
    axios
      .get(
        `${API_HOST}/revisoes/revisao/${encomenda}/iniciar_revisao_encomenda`
      )
      .then((response) => {
        this.props.history.push(`/revisoes/${response.data.id}/edit`);
      })
      .catch((error) => {
        console.error(error.response);
        if (error.response && error.response.status === 404) {
          toast.warn('Não há nenhum item disponível para revisão.');
        } else if (error.response && error.response.status === 400) {
          toast.warn(error.response.data[0]);
        } else {
          console.error(error);
          toast.error(
            'Não foi possível iniciar uma revisão. Por favor, tente novamente.'
          );
        }
      });
  };

  get pageSize() {
    const length = get(this.state, 'data.usuarios.length');
    const maxPageSize = 20;
    return length > maxPageSize ? maxPageSize : length;
  }

  fetchData() {
    this.setState({ fetchingData: true });
    Promise.all([this.fetchRevisores(), this.fetchTiposRevisao()]).then(() =>
      this.setState({ fetchingData: false })
    );
  }

  fetchTiposRevisao = () => {
    return axios.get(`${API_HOST}/revisoes/tipo`).then((response) => {
      this.setState({ tipos_revisao: response.data });
    });
  };

  fetchRevisores = () => {
    const { encomenda } = this.props;
    return axios
      .get(`${API_HOST}/encomenda/revisor/tabela`, {
        params: {
          encomenda
        }
      })
      .then((response) => {
        this.setState({ data: response.data, fetchingData: false });
      });
  };

  handleVerMais(row) {
    const { encomenda } = this.props;
    const itemModalProps = {
      nome: row.original.nome,
      filters: {
        encomenda,
        revisado_por: row.original.id
      }
    };
    this.toggleModal('item', { itemModalProps });
  }

  toggleModal(name, extra = {}) {
    const { openModal } = this.state;
    this.setState({ openModal: openModal === name ? null : name, ...extra });
  }

  render() {
    const { data, fetchingData, openModal, itemModalProps } = this.state;
    const { usuarios } = data;
    const permissions = get(this.props, 'permissions') || {};

    if (fetchingData) {
      return <Loading />;
    }

    if (size(usuarios) === 0) {
      return <p>Nenhum revisor.</p>;
    }

    return (
      <div className="professores-first professores-table">
        <div className="row">
          <div className="col-12">
            {get(permissions, '__all__') ||
            get(permissions, 'revisoes.review_item_encomenda') ? (
              <AddButton
                onClick={this.handleRevisarItem}
                title="Nova revisão"
              />
            ) : null}

            <Table
              filterable
              showPageSizeOptions={false}
              showPagination={false}
              pageSize={this.pageSize}
              className="table-default"
              columns={this.columns}
              data={usuarios}
            />
            <ModalItensRevisor
              isOpen={openModal === 'item'}
              toggle={() => this.toggleModal('item', { selecionado: null })}
              {...itemModalProps}
            />
          </div>
        </div>
      </div>
    );
  }
}

RevisoresTab.propTypes = {
  encomenda: PropTypes.number.isRequired,
  shouldFetchData: PropTypes.bool
};

RevisoresTab.defaultProps = {
  shouldFetchData: false
};

export default (props) => (
  <UserContext.Consumer>
    {({ permissions }) => <RevisoresTab permissions={permissions} {...props} />}
  </UserContext.Consumer>
);
