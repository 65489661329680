import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const GradeLinearMultipla = ({ className, onChangeItem, onExcludeItem, values }) => {

  const [ columns, setColumns ] = useState([])
  const [ rows, setRows ] = useState([])

  const addColumn = () => {

    if(columns.length > 0) {
      const lastLabel = columns[columns.length - 1].label

      if(lastLabel)
      setColumns([
        ...columns,
        {label: lastLabel + 1, value: 'mais uma coluna'}
      ])
    } else {
      setColumns([{label: 1, value: 'Escreva aqui...'}])
    }
  }

  const addRow = () => {
    if (rows.length > 0) {
      const lastLabel = rows[rows.length - 1].label
      setRows([
        ...rows,
        {label: lastLabel + 1, value: 'mais uma linha'}
      ])
    } else {
      setRows([{label: 1, value: 'Escreva aqui...'}])
    }
  }

  const changeInputRow = (label, e) => {
    setRows(rows.map(i => {
      if(i.label === label) {
        return { label : i.label, value : e.target.value}
      }
      return i
    }))
  }

  const changeInputColumn = (label, e) => {
    setColumns(columns.map(c => {
      if(c.label === label) {
        return { label : c.label, value : e.target.value}
      }
      return c
    }))
  }

  const handleChangeInputColumn = (id, e) => {
    changeInputColumn(id, e)

    const data = { position: id, value: e.target.value}

    onChangeItem(data, 'column')
  }

  const handleChangeInputRow = (id, e) => {
    changeInputRow(id, e)

    const data = { position: id, value: e.target.value}

    onChangeItem(data, 'row')
  }

  const handleExludeRow = (id) => {
    setRows(rows.filter(c => c.label !== id))

    onExcludeItem('row', id)
  }

  const handleExludeColumn = (id) => {
    setColumns(columns.filter(c => c.label !== id))

    onExcludeItem('column', id)
  }

  useEffect(() => {
    if (values.row) {
      setRows(values.row.map(x => { return { label: x.position, value: x.value} }))
    }
    if (values.column){
      setColumns(values.column.map(x => { return { label: x.position, value: x.value} }))
    }
  }, [values])


  return (
    <div className={className}>
      <table>
        <thead>
          <th></th>
          {columns.map(column => (
            <th>
              {column && <input value={column.value} onChange={e => handleChangeInputColumn(column.label, e)}/>}
              <button onClick={() => handleExludeColumn(column.label)}>excluir</button>
            </th>
          ))}
        </thead>
        {rows.map(row => (
          <tbody>
            <td>
              {row && <input value={row.value} onChange={e => handleChangeInputRow(row.label, e)}/>}
              <button onClick={() => handleExludeRow(row.label)}>excluir</button>
            </td>
            {columns.map(() => (
              <td className="radio__button">
                <div className="radio__button__fake"/>
              </td>
            ))}
          </tbody>
        ))}
      </table>
      <div className="add__column">
        <button onClick={addColumn}>Adicionar Coluna</button>
      </div>
      <div className="add__line">
        <button onClick={addRow}>Adicionar Linha</button>
      </div>
    </div>
  )
}

export default styled(GradeLinearMultipla)`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    table {
      th {
        &:nth-child(n + 2) {
          border: 1px solid gray;
          font-size: 14px;
          padding: 5px;
        }
      }

      tbody {
        color: gray;

        td {
          padding: 5px;
          border: 1px solid gray;
        }

        .radio__button {
          position: relative;


          .radio__button__fake {
            height: 10px;
            width: 10px;
            border-radius: 50%;
            position: absolute;
            background: gray;
            right: calc(50% - 7px);
            bottom: calc(50% - 7px);
          }
        }
      }
    }

    .add__column {
      position: absolute;
      right: -65px;
      transform: rotate(90deg);
    }

    .add__line {
      position: absolute;
      bottom: -45px;
      left: 40px;
    }

`
