import React from 'react'
import PropTypes from 'prop-types'
import ReactSummernote from 'react-summernote'
import axios from 'axios'
import $ from 'jquery'
import 'bootstrap/dist/css/bootstrap.css'
import 'react-summernote/dist/react-summernote.css'
import EquationBox from '../EquationBox'
import UploadVideoBox from '../UploadVideoBox'
import * as buttons from './summernote_buttons'
import { API_HOST, getTenant } from '../../consts'
import { standardizeClipboardData } from '../../utils/html'
import { toast } from 'react-toastify'
import ImageModal from './ImageModal'

import katex from 'katex'
import html2canvas from 'html2canvas'

const ambiente =
  getTenant() === 'fgv' ? { Font: 'Calibri' } : { Font: 'Open Sans' }

window.jQuery = $
require( 'bootstrap' )
require( 'react-summernote/lang/summernote-pt-BR' )

class RawInputSummernote extends React.Component {
  constructor( props ) {
    super( props )
    this.onPaste = this.onPaste.bind( this )
  }

  defaultFont = ambiente.Font;
  defaultFontSize = 14;
  insertTableMaxSize = {
    col: 20,
    row: 30
  };

  state = {
    openModal: null
  };

  onImageUpload ( images, insertImage ) {
    for ( let i = 0;i < images.length;i += 1 ) {
      const reader = new FileReader()
      const fileSizeLimit = 500 * 1024
      if ( images[0].size > fileSizeLimit ) {
        toast.error( 'Tamanho da Imagem excedeu limite permitido de 500Kb.' )
        return
      } else {
        reader.onloadend = () => {
          insertImage( reader.result )
        }
        reader.readAsDataURL( images[i] )
      }
    }
  }

  convertLatexToImage ( latex ) {
    return axios.get( `${API_HOST}/uploads/tex2img`, {
      params: {
        cht: 'tx',
        chl: latex
      }
    } )
  }

  onPaste ( e ) {
    let clipboardData =
      ( e.originalEvent || e ).clipboardData || window.clipboardData
    e.preventDefault()
    let contentToPaste = standardizeClipboardData(
      clipboardData,
      this.defaultFont,
      this.defaultFontSize
    )
    setTimeout( function () {
      document.execCommand( 'insertHtml', false, contentToPaste )
    }, 10 )
  }

  getBase64URI = ( context, formula ) => {
    const tempElement = document.createElement( 'span' )
    tempElement.innerHTML = katex.renderToString( formula )

    document.body.appendChild( tempElement )
    const width = tempElement.offsetWidth
    const height = tempElement.offsetHeight

    setTimeout( () => {
      const options = {
        width: width + 10,
        height: height + 20,
        y: -10
      }
      html2canvas( tempElement, options ).then( ( canvas ) => {
        const base64URI = canvas.toDataURL( 'image/png' )
        console.log( base64URI )
        const node = document.createElement( 'img' )
        node.classList = ['formula']
        node.setAttribute( 'data-formula', formula )
        node.src = base64URI
        context.invoke( 'editor.restoreRange' )
        context.invoke( 'editor.insertNode', node )

        document.body.removeChild( tempElement )
      } )
    }, 500 )
  };
  // handleEquationBoxOpened = (context) => {
  //   this.setState({
  //     openModal: 'equationBox',
  //     addEquationToContent: (formula) => {
  //       this.getBase64URI(context, formula);
  //       this.setState({ openModal: null });
  //     }
  //   });
  // };

  handleEquationBoxOpened = ( context ) => {
    this.setState( {
      openModal: 'equationBox',
      addEquationToContent: ( formula ) => {
        this.convertLatexToImage( formula ).then( ( response ) => {
          const base64 = response.data
          const node = document.createElement( 'img' )
          node.classList = ['formula']
          node.setAttribute( 'data-formula', formula )
          node.src = base64
          context.invoke( 'editor.restoreRange' )
          context.invoke( 'editor.insertNode', node )
        } )
        this.setState( { openModal: null } )
      }
    } )
  };

  handleUploadVideoOpened = ( context ) => {
    this.setState( {
      openModal: 'uploadVideoBox',
      addVideoToContent: ( videoNode ) => {
        context.invoke( 'editor.restoreRange' )
        context.invoke( 'editor.insertNode', videoNode )
        this.setState( { openModal: null } )
      }
    } )
  };

  handleImageModalOpened = ( context ) => {
    this.setState( {
      openModal: 'ImageModal',
      addImageModalToContentState: ( url, titleButton ) => {
        const node = document.createElement( 'div' )
        node.innerHTML = `<link rel="stylesheet" href="https://educat-cmmg-uploads.s3.sa-east-1.amazonaws.com/w3.css">
        <button onclick="document.getElementById('id01').style.display='block'" class="w3-button " style="background-color: #fff; border: 1px solid #cccc; border-radius: 8px; color: #000; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);" control-id="ControlID-28">${titleButton}</button>
        <div id="id01" class="w3-modal">
        <div class="w3-modal-content" style="width: 75%; height: 65%; margin-top: 1rem">
        <div class="w3-container" style="height: 100%">
        <span onclick="document.getElementById('id01').style.display='none'" class="w3-button w3-display-topright" style="font-size: 24px;">×</span>
        <iframe style="width: 100%; height: 90%; margin-top: 3rem" src=${url}></iframe>
        </div>
        </div>
        </div>`
        context.invoke( 'editor.restoreRange' )
        context.invoke( 'editor.insertNode', node )
        this.setState( { openModal: null } )
      }
    } )
  };

  render () {
    const { value, onChange, ...rest } = this.props
    const {
      openModal,
      addEquationToContent,
      addVideoToContent,
      addImageModalToContentState
    } = this.state

    return (
      <React.Fragment>
        <ReactSummernote
          className="text-options"
          onChange={onChange}
          value={value || ''}
          onImageUpload={this.onImageUpload}
          onPaste={this.onPaste}
          options={{
            buttons: {
              math: buttons.EquationButton( this.handleEquationBoxOpened ),
              uploadVideo: buttons.VideoButton( this.handleUploadVideoOpened ),
              imageMarginTop: buttons.UpImageButton,
              imageMarginBottom: buttons.DownImageButton,
              increaseBothMarginsButton: buttons.IncreaseBothMarginsButton,
              decreaseBothMarginsButton: buttons.DecreaseBothMarginsButton,
              resetMarginButton: buttons.ResetMarginButton,
              ImageModal: buttons.ModalButton( this.handleImageModalOpened )
            },
            fontSize: this.defaultFontSize,
            fontNames: [
              this.defaultFont,
              'Arial',
              'Courier New',
              'Helvetica',
              'Verdana'
            ],
            fontNamesIgnoreCheck: ['Calibri'],
            lang: 'pt-BR',
            height: 80,
            dialogsInBody: true,
            disableResize: true,
            resize: false,
            insertTableMaxSize: this.insertTableMaxSize,
            toolbar: [
              ['fontname', ['fontname']],
              ['fontsize', ['fontsize']],
              ['font', ['bold', 'underline', 'italic', 'clear']],
              ['color', ['color']],
              ['font', ['strikethrough', 'superscript', 'subscript']],
              ['para', ['ul', 'ol', 'paragraph']],
              ['table', ['table']],
              ['insert', ['link', 'picture']],
              ['view', ['codeview']],
              ['custom', ['math']],
              ['custom', ['uploadVideo']],
              ['custom', ['ImageModal']]
            ],
            popover: {
              image: [
                ['imagesize', ['imageSize100', 'imageSize50', 'imageSize25']],
                ['float', ['floatLeft', 'floatRight', 'floatNone']],
                [
                  'adjustMargin',
                  [
                    'imageMarginTop',
                    'imageMarginBottom',
                    'increaseBothMarginsButton',
                    'decreaseBothMarginsButton',
                    'resetMarginButton'
                  ]
                ],
                ['remove', ['removeMedia']]
              ]
            },
            ...rest
          }}
        />
        <EquationBox
          isOpen={openModal === 'equationBox'}
          onClick={addEquationToContent}
          onClose={() => this.setState( { openModal: null } )}
        />
        <UploadVideoBox
          isOpen={openModal === 'uploadVideoBox'}
          onClick={addVideoToContent}
          onClose={() => this.setState( { openModal: null } )}
        />
        <ImageModal
          isOpen={openModal === 'ImageModal'}
          onClose={() => this.setState( { openModal: null } )}
          onClick={addImageModalToContentState}
        />
      </React.Fragment>
    )
  }
}

RawInputSummernote.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
}

RawInputSummernote.defaultProps = {
  value: ''
}

export default RawInputSummernote
