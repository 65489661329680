import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const AppMenu = styled.div`
  nav {
    background: ${props => props.theme.primary};
  font-weight: 400;
  padding: 12px 0px 0px 0px;

  .dropdown{
    margin:0px;
    margin-right: 10px !important;
    a{
      &:hover{
        color: white;
      }
    }
  }
  ul > div > a {
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: ${props => props.theme.menu};
    font-size: 13px;
    padding: 0 !important;
    margin: 0px;
    margin-right: 18px !important;
    padding-bottom: 12px !important;
    border-bottom: 3px solid transparent;
    font-weight: 500;
  }
  .active {
    transition: all 0.3s;
    color: white;
    border-bottom: 3px solid ${props => props.theme.light};

  }
  .dropdown-menu {

    top: -2px !important;
    padding: 0;
    border: none !important;
    border-radius: 0px 0px 3px 3px;
    -webkit-box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.25);
    padding-top: 10px !important;
    padding-bottom: 12px !important;
    z-index: 99;
    a, span{
      margin: 0;
      font-size: 13px;
      text-transform: uppercase;
      color: $black;
      min-width: 100%;
      padding: 8px 20px !important;
      letter-spacing: 1px !important;
      font-weight: 600;
      &:hover {
        background: ${props => props.theme.hover};
        color: ${props => props.theme.primary};
        text-decoration: none;
      }

    }
    .disabled {
      color: ${props => props.theme.cinza} !important;
      cursor: default !important;
      &:hover{
        background: rgba(0,0,0,0.1);
      }
    }
    .divider-menu{
      cursor: default;
      &:hover{
        background: transparent !important;
      }
    }
    .children-menu{
      font-size: 12px;
      padding-left: 30px !important;
      text-transform: none !important;
      padding-top: 5px !important;
      padding-bottom: 5px !important;
      font-weight: 400 !important;
      letter-spacing: 1.2px !important;
    }
  }
  }
`;
