import React, { Component } from 'react';
import { Form } from 'informed';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import axios from 'axios';
import NavBreadCrumb from '../../containers/NavBreadCrumb';
import InputTextArea from '../../components/inputs/InputTextArea';
import Loading from '../../components/Loading';
import { API_HOST } from '../../consts';
import AddButton from '../../components/buttons/AddButton';

class Eixo extends Component {
  constructor(props) {
    super(props);
    this.id = get(props, 'match.params.id');
  }

  state = {
    submitting: false,
    loading: true,
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    if (this.id !== 'new') {
      axios.get(`${API_HOST}/matriz_referencia/eixo/${this.id}`)
        .then((response) => {
          this.formApi.setValues({
            descricao: response.data.descricao,
          });
          this.setState({ loading: false });
        });
    } else {
      this.setState({ loading: false });
    }
  }

  handleError = (error) => {
    if (get(error, 'response.status') === 400) {
      get(error, 'response.data.descricao', []).forEach(e => (
        toast.error(e)
      ));
    } else {
      toast.error('Por favor, preencha corretamente todos os campos.');
    }
    this.setState({ submitting: false });
  }

  handleSubmit = (values) => {
    this.setState({ submitting: true });

    if (this.id === 'new') {
      axios.post(`${API_HOST}/matriz_referencia/eixo`, values)
        .then(() => {
          const { history } = this.props;
          toast.success('Eixo criado com sucesso.');
          history.push('/eixos');
        })
        .catch(this.handleError);
    } else {
      axios.patch(`${API_HOST}/matriz_referencia/eixo/${this.id}`, values)
        .then(() => {
          const { history } = this.props;
          toast.success('Eixo atualizado com sucesso.');
          history.push('/eixos');
        })
        .catch(this.handleError);
    }
  }

  render() {
    const pathList = [
      { link: '/eixos', label: 'Eixos' },
    ];
    const currentPath = this.id === 'new' ? 'Novo eixo' : `Eixo ${this.id}`;
    const { submitting, loading } = this.state;

    return (
      <div className="form-configuracoes">
        <NavBreadCrumb pathList={pathList} currentPath={currentPath} />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <Form
                  onSubmit={this.handleSubmit}
                  getApi={(formApi) => { this.formApi = formApi; }}
                >
                  {
                    () => {
                      if (loading) {
                        return <Loading />;
                      }

                      return (
                        <>
                          <div className="card-header">
                            <div className="row">
                              <div className="col-6">
                                {this.id === 'new' ? <h1>Nova eixo</h1> : <h1>Eixo <b>{this.id}</b></h1>}
                              </div>
                            </div>
                          </div>
                          <div className="card-body card-body-form">
                            <InputTextArea
                              field="descricao"
                              label="Descrição"
                            />
                          </div>
                          <div className="card-footer display-flex">
                            <AddButton
                              type="submit"
                              disabled={submitting}
                              noIcon
                              title="Salvar"
                            />
                          </div>
                        </>
                      );
                    }
                  }
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Eixo;
