import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { get, debounce } from 'lodash';
import NavBreadCrumb from '../../containers/NavBreadCrumb';
import Loading from '../../components/Loading';
import { Form } from 'informed';
import InputSelect from '../../components/inputs/InputSelect';
import InputSelectAsync from '../../components/inputs/InputSelectAsync';
import InputCheckbox from '../../components/inputs/InputCheckbox';
import { Label } from 'reactstrap';
import { API_HOST } from '../../consts';
import { toast } from 'react-toastify';

class GerarAnalise extends Component {

  state = {
    avaliacoesBase: [],
    aplicacoes: [],
    fetchingAplicacoes: false,
  }

  analises = [
    { value: 'tri', label: 'Teoria de Resposta ao Item - TRI' },
    { value: 'tct', label: 'Teoria Clássica dos Testes - TCT' },
  ]

  fetchAvaliacoesBase = debounce((input, callback) => {
    axios.get(`${API_HOST}/analises/avaliacao_base`, {
      params: {
        nome: input,
      }
    })
      .then((response) => {
        this.setState({ avaliacoesBase: response.data });
        callback(null, {
          options: response.data.results.map(a => ({
            value: a.id, label: a.nome,
          })),
        });
      })
  }, 500)

  fetchAplicacoes = (avaliacao) => {
    if (avaliacao) {
      this.setState({ fetchingAplicacoes: true });
      axios.get(`${API_HOST}/aplicacoes/aplicacao_semelhante`, {
        params: {
          avaliacao: avaliacao.value,
        },
      })
        .then((response) => {
          this.setState({ aplicacoes: response.data, fetchingAplicacoes: false });
        })
    }
  }

  selecionarTodasAplicacoes = (event) => {
    const { aplicacoes } = this.state;
    aplicacoes.forEach((_, i) => {
      this.formApi.setValue(`aplicacoes[${i}]`, event.target.checked);
    })
  }

  fetchAnalise = (formState) => {
    this.setState({ fetchingAnalise: true });

    const { aplicacoes } = this.state;
    const values = {
      aplicacoes: aplicacoes
        .filter((_, i) => formState.values.aplicacoes[i])
        .map(a => a.id),
    }

    axios.post(`${API_HOST}/analises/analise/buscar_por_aplicacao`, values)
      .then((response) => {
        const { tipo } = formState.values;
        const { analise } = response.data;
        const { history } = this.props;
        history.push(`/analise/${tipo}/${analise}`);
      })
      .catch((error) => {
        const status = get(error, 'response.status');
        let message = 'Ocorreu um erro inesperado. Por favor, tente novamente.';
        if (status === 404) {
          message = get(error, 'response.data.detail');
        }
        toast.error(message);
      })
      .then(() => this.setState({ fetchingAnalise: false }));
  }

  get numeroCandidatos() {
    const { aplicacoes } = this.state;
    return aplicacoes.reduce((acc, value) => {
      return acc + value.numero_candidatos;
    }, 0);
  }

  get numeroCandidatosSelecionados() {
    const { formState, aplicacoes } = this.state;
    const ap = get(formState, 'values.aplicacoes') || [];
    return ap.reduce((acc, value, i) => {
      return acc + (value ? aplicacoes[i].numero_candidatos : 0)
    }, 0);
  }

  get gerarAnaliseDisabled() {
    const { formState } = this.state;
    const tipo = get(formState, 'values.tipo');
    const avaliacao = get(formState, 'values.avaliacao');
    const aplicacoes = (get(formState, 'values.aplicacoes') || []).some(a => a);
    return !(tipo && avaliacao && aplicacoes);
  }

  renderAplicacoes = () => {
    const { formState, aplicacoes, fetchingAplicacoes } = this.state;

    if (!get(formState, 'values.avaliacao')) {
      return <p>Selecione uma avaliação base para selecionar as aplicações.</p>;
    }

    if (fetchingAplicacoes) {
      return <Loading message="Carregando aplicações..." />
    }

    if (aplicacoes.length === 0) {
      return <p>Nenhuma aplicação disponível para a avaliação selecionada.</p>
    }

    return (
      <Fragment>
      <table className="table table-analise">
        <thead>
        <tr>
          <th width="30px">
            <div className=" checkbox-input">
            <Label check className="container-check-custom">
              <input
                type="checkbox"
                aria-label=""
                onChange={this.selecionarTodasAplicacoes}
              />
              <span className="checkmark"></span>
            </Label>

          </div></th>
          <th >Nome</th>
          <th>Data</th>
          <th>Respondentes</th>
        </tr>
        </thead>
        <tbody>
        {aplicacoes.map((a, i) => (
          <tr key={a.id}>
            <td>
              <div className="checkbox-input">
                <InputCheckbox
                  field={`aplicacoes[${i}]`}
                  onChange={this.selecionarTodasAplicacoes}
                />
              </div>
            </td>
            <td>{a.nome}</td>
            <td>{a.data}</td>
            <td>{a.numero_candidatos}</td>
          </tr>
        ))}
        </tbody>
      </table>
        <p><b>Respondentes: </b>{this.numeroCandidatos} <b className="ml-3">Respondentes Selecionados: </b>{this.numeroCandidatosSelecionados}</p>
      </Fragment>
    );
  }

  render() {
    const { fetchingAnalise } = this.state;
    const pathList = [{
      label: 'Análise TCT/TRI',
      icon: 'list',
      link: '/analise/gerar',
    }];

    return (
      <Form
        getApi={(api) => { this.formApi = api; }}
        onChange={formState => this.setState({ formState })}
      >
        {({ formState, formApi }) => (
          <Fragment>
            <NavBreadCrumb pathList={pathList} currentPath="Gerar Análise" />
            <div className="container mb-4 pb-4">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h1>Gerar Análise</h1>
                      <hr/>
                    </div>
                    <div className="card-body">
                      <p>Por favor, selecione abaixo os modelos e as suas respectivas aplicações para gerar a análise.</p>
                      <p className="mb-4 pb-4"><i className="fas fa-exclamation-circle wrong"/> Somente aplicações finalizadas são mostradas.</p>
                        <InputSelect
                          searchable={false}
                          col="col-sm-12 col-md-6 p-0"
                          label="Tipo de análise"
                          options={this.analises}
                          field="tipo"
                        />
                        <InputSelectAsync
                          col="col-sm-12 col-md-6 p-0"
                          label="Avaliação Base"
                          loadOptions={this.fetchAvaliacoesBase}
                          onChange={this.fetchAplicacoes}
                          field="avaliacao"
                        />
                        <hr className="mb-4 mt-4"/>
                        <label>Aplicações Disponíveis</label>
                        {this.renderAplicacoes(formState)}
                        <button
                          className="btn primary-button"
                          onClick={() => this.fetchAnalise(formState)}
                          disabled={this.gerarAnaliseDisabled || fetchingAnalise}
                        >
                          {fetchingAnalise ? 'Gerando análise...' : 'Gerar análise'}
                        </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </Form>
    )
  }
}

export default GerarAnalise;
