import React from 'react';
import PropTypes from 'prop-types';

const QuizSection = ({ text, children }) => (
  <div className="quiz-section">
    <h4>{text}</h4>
    <div className="quiz-questions">
      {children}
    </div>
  </div>
);

QuizSection.propTypes = {
  text: PropTypes.node,
  children: PropTypes.arrayOf(PropTypes.element),
};

QuizSection.defaultProps = {
  text: '',
  children: null,
};

export default QuizSection;
