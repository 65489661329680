import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateStep } from '../../../actions';
import Box from '../../../components/Box';

const minicards = [
  {
    col: '3',
    title: '2000',
    subtitle: 'ENEM',
    id: 1,
    cor: '#0E45FB',
  },
  {
    col: '3',
    title: '900',
    subtitle: 'CENSO DA EDUCAÇÃO SUPERIOR',
    id: 2,
    cor: '#5894E2',
  },
  {
    col: '3',
    title: '1700',
    subtitle: 'CELP-BRAS',
    id: 3,
    cor: '#C7431B',
  },
  {
    col: '3',
    title: '525',
    subtitle: 'ENCEJA',
    id: 4,
    cor: '#F5C145',
  },
  {
    col: '3',
    title: '1230',
    subtitle: 'ANASEM',
    id: 5,
    cor: '#E18F3B',
  },
  {
    col: '3',
    title: '220',
    subtitle: 'PISA',
    id: 6,
    cor: '#A95E17',
  },
  {
    col: '3',
    title: '10000',
    subtitle: 'ENADE',
    id: 7,
    cor: '#AE3BB2',
  },
  {
    col: '3',
    title: '175',
    subtitle: 'IDEB',
    id: 8,
    cor: '#8D4DB8',
  },
  {
    col: '3',
    title: '120',
    subtitle: 'SAEB',
    id: 9,
    cor: '#79127C',
  },
  {
    col: '3',
    title: '87',
    subtitle: 'ANA',
    id: 10,
    cor: '#B8CF87',
  },
  {
    col: '3',
    title: '1230',
    subtitle: 'REVALIDA',
    id: 11,
    cor: '#87AE35',
  },
  {
    col: '3',
    title: '1230',
    subtitle: 'ENCEJA EXTERIOR',
    id: 12,
    cor: '#5F9740',
  },
];

class DistribuicaoGrupos extends React.Component {
  componentWillMount() {
    this.props.dispatch(updateStep({
      activeStepIndex: 3,
    }));
  }

  renderTotalGeralCards() {
    return minicards.map(info => (
      <div className="col-sm-12">
        <Box number={info.title} label={info.subtitle} color={info.cor} />
      </div>
    ));
  }

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <h5 align="middle">Distribuição por Grupos</h5>
          <hr />
          <div className="animated fadeIn">
            <div className="row">
              {this.renderTotalGeralCards()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  distribuicaoGrupos: state.distribuicaoGrupos,
});

export default withRouter(connect(mapStateToProps)(DistribuicaoGrupos));
