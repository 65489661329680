import React from 'react';
import classnames from 'classnames';
import { asField } from 'informed';
import { get } from 'lodash';
import { AsyncCreatable } from 'react-select';

const InputSelectAsyncCreatable = asField(({
  field, fieldState, fieldApi, ...props
}) => {
  const { value } = fieldState;
  const { setValue, setError } = fieldApi;
  const {
    col,
    label,
    required,
    ...rest
  } = props;
  const error = get(fieldState, 'error', []);
  const hasError = Array.isArray(error) && error.length > 0;

  return (
    <div className={classnames('form-group', col, { required, 'has-error': hasError })}>
      <label className={classnames({ 'text-danger': hasError })}>{label}</label>
      <AsyncCreatable
        onChange={(v) => {
          setValue(v);
          setError(null);
        }}
        value={value}
        noResultsText="Nenhum resultado encontrado."
        placeholder="selecione"
        searchPromptText="Digite para pesquisar..."
        loadingPlaceholder="Carregando..."
        promptTextCreator={text => `Criar opção "${text}"`}
        {...rest}
      />
      {error.map(e => <p key={e} className="text-danger">{e}</p>)}
    </div>
  );
});

InputSelectAsyncCreatable.defaultProps = {
  placeholder: 'selecione',
  loadingMessage: () => 'carregando...',
};

export default InputSelectAsyncCreatable;
