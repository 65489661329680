import React from 'react';
import PropTypes from 'prop-types';
import katex from 'katex';
import 'katex/dist/katex.min.css';

const Equation = ({ formula, ...rest }) => (
  <span
    dangerouslySetInnerHTML={{
      __html: katex.renderToString(formula || '', {
        throwOnError: false,
        output: 'mathml'
      })
    }}
    {...rest}
  />
);

Equation.propTypes = {
  formula: PropTypes.string.isRequired
};

export default Equation;
