import React from 'react';
import PropTypes from 'prop-types';

export default class LabelView extends React.Component {
  static propTypes = {
    col: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    status: PropTypes.string,
    html: PropTypes.bool,
    name: PropTypes.string,
  };

  static defaultProps = {
    col: 'col-md-12',
    title: '',
    text: '-',
    status: '',
    html: false,
    name: null,
  };

  render() {
    return (
      <div className={`label-text label-view ${this.props.col}`} >
        <dl>
          <dt>{this.props.title}</dt>
          <dd className={this.props.status} name={this.props.name}>
          {
            this.props.html && this.props.text ?
            <div dangerouslySetInnerHTML={{ __html: `${this.props.text}` }} /> :
            this.props.text || '-'
          }
          </dd>
        </dl>
      </div>
    );
  }
}
