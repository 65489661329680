import React from 'react';
import Highcharts from 'highcharts';
import { withRouter } from 'react-router-dom';

class CurvaInformacao extends React.Component {
  componentDidMount() {
    const { proficiencia } = this.props;
    if (proficiencia.length > 0) {
      this.createChart();
    }
  }

  componentDidUpdate(prevProps) {
    const { proficiencia } = this.props;
    const proficienciaMudou = proficiencia !== prevProps.proficiencia;
    if (proficienciaMudou) {
      this.createChart();
    }
  }

  createChart() {
    const { proficiencia, id } = this.props;
    let theta = [];
    let tse = [];
    let tinfo = [];
    let maxTSE = 0;
    let maxTINFO = 0;

    proficiencia.forEach((d) => {
      maxTSE = d.tse > maxTSE ? d.tse : maxTSE;
      maxTINFO = d.tinfo > maxTINFO ? d.tinfo : maxTINFO;
      theta.push(parseFloat(d.theta));
      tse.push(parseFloat(d.tse));
      tinfo.push(parseFloat(d.tinfo));
    });

    Highcharts.chart(id, {
      chart: {
        zoomType: 'xy',
        alignTicks: false,
        width: 900
      },
      title: {
        text: ' '
      },
      xAxis: [
        {
          categories: theta,
          title: {
            text: 'Proficiência'
          },
          labels: {
            formatter: function () {
              const num = this.value;
              const s_num = num.toString();
              const length = s_num.length;
              return length <= 2 ? num : '';
            }
          }
        }
      ],
      yAxis: [
        {
          title: {
            text: 'SE(θ)'
          },
          lineColor: 'var(--pink)',
          opposite: true,
          gridLineWidth: 0,
          lineWidth: 2
        },
        {
          title: {
            text: 'I(θ)'
          },
          lineColor: 'var(--padrao)',
          gridLineWidth: 0,
          lineWidth: 2
        }
      ],
      tooltip: {
        shared: true
      },
      plotOptions: {
        line: {
          marker: {
            enabled: false
          }
        }
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        x: -90,
        verticalAlign: 'top',
        y: 0,
        floating: true,
        backgroundColor: '#fff'
      },
      series: [
        {
          name: 'SE(θ)',
          type: 'line',
          yAxis: 0,
          data: tse,
          color: 'var(--pink)'
        },
        {
          name: 'I(θ)',
          type: 'line',
          yAxis: 1,
          color: 'var(--padrao)',
          data: tinfo
        }
      ]
    });
  }

  render() {
    const { id } = this.props;

    return (
      <React.Fragment>
        <p className="azul p-4">
          <b>Informação Total do Teste e Erro Padrão</b>
        </p>
        <div style={{ height: '550px' }} id={id} />
      </React.Fragment>
    );
  }
}

export default withRouter(CurvaInformacao);
