import React from 'react';
import { get } from 'lodash';
import { asField } from 'informed';
import Select from 'react-select';
import { FormGroup } from 'reactstrap';
import classnames from 'classnames';

const InputSelect = asField(({ field, fieldState, fieldApi, ...props }) => {

  const { value } = fieldState;
  const { setValue } = fieldApi;
  const {
    col,
    label,
    onChange,
    multi,
    required,
    selectAll,
    options,
    disabled,
    ...rest
  } = props;

  const error = get(fieldState, 'error', []);
  const hasError = Array.isArray(error) && error.length > 0;
  const optionsValue = () => options.map(v => v.value);

  return (
    <FormGroup className={classnames(col, { required, 'has-error': hasError })}>
      <label className={classnames({ 'text-danger': hasError })}>{label}</label>
      <Select
        onChange={(value) => {
            if (multi) {
              if (value) {
                setValue(value.map(x => x.value));
              } else {
                setValue([]);
              }
            } else {
              setValue(get(value, 'value'));
            }

            fieldApi.setError(null);
            fieldApi.setTouched();

            if (onChange) onChange(value);
          }}
        value={value}
        noResultsText="Nenhum resultado encontrado."
        placeholder="selecione"
        multi={multi}
        options={options}
        disabled={disabled}
        {...rest}
      />
      {error.map(e => <p key={e} className="text-danger">{e}</p>)}
      {
        multi && selectAll ?
          <div className="mt-3 float-right">
            <button type="button" className="btn btn-sm btn-white mr-2" onClick={() => setValue([])}>Remover todos</button>
            <button type="button" className="btn btn-sm primary-button" onClick={() => setValue(optionsValue())}>Selecionar Todos</button>
          </div>
        : null
      }
    </FormGroup>
  );
});

InputSelect.defaultProps = {
  placeholder: 'Selecione',
  loadingMessage: () => 'Carregando...',
};

export default InputSelect;
