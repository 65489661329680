import React, { Fragment } from 'react';
import axios from 'axios';
import { get, merge, size } from 'lodash';
import BaseAnalise from '../../base/BaseAnalise';
import NavBreadCrumb from '../../containers/NavBreadCrumb';
import Item from '../../components/Item';
import { Form } from 'informed';
import classnames from 'classnames';
import Loading from '../../components/Loading';
import Link from 'react-router-dom/es/Link';
import { API_HOST, ANALYSIS_API_HOST } from '../../consts';
import Select from 'react-select';
import Highcharts from 'highcharts';
import AlphaCronbach from '../../components/AlphaCronbach';
import GrupoAnalise from '../../components/GrupoAnalise';
import LinhaAlternativaEscolhida from '../../components/LinhaAlternativaEscolhida';
import BarraAlternativaEscolhida from '../../components/BarraAlternativaEscolhida';
import TabelaAplicacoesAnalise from '../../components/TabelaAplicacoesAnalise';
import AnaliseReportModal from './AnaliseReportModal';
import ParametroItem from '../../components/ParametroItem';

const GABARITO_COLOR = '#dff0d8';
const mapItem = (d) => ({
  value: d.id,
  label: `Item na posição ${
    d.posicao_atual ? d.posicao_atual : d.posicao_corrigida
  } da avaliação - Código do Item: ${d.id} ${
    d.alpha > d.alpha_teste ? ' 🔴' : ''
  }`,
  ...d
});
const letraAlternativa = (key) => key.length === 1;

class AnaliseTCT extends BaseAnalise {
  state = {
    activeTab: 'qualidade',
    loading: false,
    aplicacoes: [],
    alphas: [],
    porcentagemGrupos: [],
    showAnaliseReportModal: false
  };

  componentDidMount() {
    this.fetchAnalise().then(this.fetchAnaliseR);
    this.fetchAlphaCronbach();
    this.fetchPorcentagemGrupos();
  }

  renderQualidade = () => {
    const { alphas, fetchingAnaliseR } = this.state;
    return (
      <Fragment>
        <div className="container">
          {alphas ? <AlphaCronbach alphas={alphas} /> : null}
          <hr />
          <div className="row">
            <div className="col-12">
              <p className="azul">
                <b>Dificuldade</b>
              </p>
              {fetchingAnaliseR && (
                <div>
                  <Loading />
                </div>
              )}
              <div id="dificuldade-chart" style={{ height: 550 }} />

              <hr />
              <p className="azul">
                <b>Discriminação</b>
              </p>
              {fetchingAnaliseR && (
                <div>
                  <Loading />
                </div>
              )}
              <div id="discriminacao-chart" style={{ height: 550 }} />
              <hr />
              <p className="azul">
                <b>Bisserial</b>
              </p>
              {fetchingAnaliseR && (
                <div>
                  <Loading />
                </div>
              )}
              <div id="bisserial-chart" style={{ height: 550 }} />
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  get parametrosGabarito() {
    const { parametros, itemSelecionado } = this.state;
    if (itemSelecionado) {
      return get(
        parametros,
        `${itemSelecionado.posicao_corrigida - 1}.${itemSelecionado.gabarito}`
      );
    }
    return null;
  }

  get parametrosAlternativas() {
    const { parametros, itemSelecionado } = this.state;
    if (itemSelecionado) {
      const p =
        { ...get(parametros, itemSelecionado.posicao_corrigida - 1) } || {};
      const numeroDeAlternativas = p.alternativas;
      delete p.item;
      delete p.alternativas;
      const filteredAndOrderedParams = Object.entries(p)
        .map((d) => [
          d[0].toUpperCase(),
          d[1].frequencia,
          d[1].dificuldade,
          d[1].bisserial,
          d[1].discriminacao
        ])
        .sort((a, b) => a[0].localeCompare(b[0]));

      return filteredAndOrderedParams.slice(0, numeroDeAlternativas);
    }
    return [];
  }

  get porcentagemGrupos() {
    const { porcentagemGrupos, itemSelecionado, item } = this.state;
    if (itemSelecionado && porcentagemGrupos && item) {
      const numeroDeAlternativas = item.alternativas.length;
      let p =
        porcentagemGrupos.filter(
          (d) => d.item === itemSelecionado.posicao_corrigida
        ) || [];
      this.removeUnexistentAlternativas(p, numeroDeAlternativas);
      return p.map((d) => [
        d.grupo,
        ...Object.keys(d)
          .filter(letraAlternativa)
          .map((f) => d[f])
      ]);
    }
    return [];
  }

  removeUnexistentAlternativas(p, numeroDeAlternativas) {
    let alternativas = ['a', 'b', 'c', 'd', 'e'];
    let alternativasToRemove = alternativas.slice(
      numeroDeAlternativas,
      alternativas.length
    );
    alternativasToRemove.forEach((key) =>
      p.forEach((elem) => delete elem[key])
    );
  }

  get porcentagemGruposHeaders() {
    if (this.porcentagemGrupos.length > 0) {
      return ['Grupos'].concat(
        this.porcentagemGrupos[0]
          .slice(1)
          .map((_, i) => String.fromCharCode(i + 65))
      );
    }
    return [];
  }

  get gabarito() {
    const { itemSelecionado } = this.state;
    return get(itemSelecionado, 'gabarito');
  }

  get bisserial() {
    return (get(this.parametrosGabarito, 'bisserial') || '').toLocaleString(
      'pt-BR'
    );
  }

  get discriminacao() {
    return (get(this.parametrosGabarito, 'discriminacao') || '').toLocaleString(
      'pt-BR'
    );
  }

  get dificuldade() {
    return (get(this.parametrosGabarito, 'dificuldade') || '').toLocaleString(
      'pt-BR'
    );
  }

  get respondentesItem() {
    const FREQUENCIA = 1;
    return this.parametrosAlternativas.reduce(
      (acc, d) => acc + d[FREQUENCIA],
      0
    );
  }

  get groupsList() {
    const numberOfGroups = this.porcentagemGrupos.length;

    const fiveGroupsList = [
      { title: 'Grupo 1', color: '#F4C85B', text: 'a menor' },
      { title: 'Grupo 2', color: '#B2585C', text: 'a segunda menor' },
      { title: 'Grupo 3', color: '#9FB76A', text: 'a terceira maior' },
      { title: 'Grupo 4', color: '#56BFAD', text: 'a segunda maior' },
      { title: 'Grupo 5', color: '#055C61', text: 'a maior' }
    ];

    const threeGroupsList = [
      { title: 'Grupo 1', color: '#F4C85B', text: 'a menor' },
      { title: 'Grupo 2', color: '#B2585C', text: 'a segunda menor' },
      { title: 'Grupo 3', color: '#9FB76A', text: 'a maior' }
    ];

    if (numberOfGroups === 5) {
      return fiveGroupsList;
    } else if (numberOfGroups === 3) {
      return threeGroupsList;
    }

    return null;
  }

  get naoRespondentesItem() {
    return this.totalCandidatos - this.respondentesItem;
  }

  handleItemChange = (value) => {
    if (value) {
      this.setState(
        {
          itemSelecionado: value || null
        },
        this.fetchItem
      );
    } else {
      this.setState({ item: null });
    }
  };

  handleExport = () => {
    const exportLink = `/analise/${this.analise}/impressao`;
    window.open(exportLink);
  };

  createPieChart(param, title) {
    const { parametros } = this.state;

    if (size(parametros) === 0) {
      return;
    }

    const p = this.itens
      .map((d) => ({
        ...parametros[d.posicao_corrigida - 1][d.gabarito]
      }))
      .map((d) => ({
        name: d[`nivel_${param}`],
        color: d[`cor_${param}`],
        y: 1
      }));
    const data = {};
    p.forEach((d) => {
      if (data[d.name]) {
        data[d.name].y += 1;
      } else {
        data[d.name] = { ...d };
      }
    });

    Highcharts.chart(`${param}-chart`, {
      chart: {
        type: 'pie',
        marginTop: 40,
        width: document.getElementById(`${param}-chart`).style.width
      },
      title: {
        text: ''
      },
      tooltip: {
        formatter: function () {
          return `Quantidade: ${
            this.y
          }<br /><b>${this.point.percentage.toLocaleString('pt')}%</b>`;
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            size: 30,
            enabled: true,
            color: 'black',
            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
          },
          size: 200
        }
      },

      series: [
        {
          name: 'porcentagem',
          colorByPoint: true,
          data: Object.values(data)
        }
      ]
    });
  }

  fetchAnaliseR = () => {
    this.setState({ fetchingAnaliseR: true });

    Promise.all([
      axios.get(
        `${ANALYSIS_API_HOST}/analise_tct/${this.analise}/analise_estatistica/`
      ),
      axios.get(`${ANALYSIS_API_HOST}/analise_tct/${this.analise}/intervalos/`)
    ]).then((responses) => {
      const d1 = responses[0].data;
      const d2 = responses[1].data;
      this.setState({
        parametros: d1.map((_, i) => ({
          ...merge(d1[i], d2[i])
        }))
      });

      this.createPieChart('bisserial', 'Bisserial dos itens');
      this.createPieChart('discriminacao', 'Discriminação dos itens');
      this.createPieChart('dificuldade', 'Dificuldade dos itens');
      this.setState({ fetchingAnaliseR: false });
    });
  };

  fetchAnalise() {
    this.setState({ fetchingAnalise: true });

    return axios
      .get(`${API_HOST}/analises/analise/${this.analise}`)
      .then((response) => {
        this.setState({ analise: response.data });
        this.handleItemChange(get(this.itens, 0));
        this.setState({ fetchingAnalise: false });
      });
  }

  fetchPorcentagemGrupos() {
    axios
      .get(
        `${ANALYSIS_API_HOST}/analise_tct/${this.analise}/porcentagem_grupo/`
      )
      .then((response) => {
        this.setState({ porcentagemGrupos: response.data });
      });
  }

  fetchAlphaCronbach() {
    axios
      .get(`${ANALYSIS_API_HOST}/analise_tct/${this.analise}/alpha/`)
      .then((response) => {
        this.setState({ alphas: response.data });
      });
  }

  fetchItem() {
    this.setState({ fetchingItem: true });
    const { itemSelecionado } = this.state;
    const id = get(itemSelecionado, 'id');
    axios.get(`${API_HOST}/itens/item/${id}`).then((response) => {
      this.setState({ item: response.data, fetchingItem: false });
    });
  }

  renderItem() {
    const { fetchingItem, item } = this.state;

    if (fetchingItem) {
      return <Loading message="Carregando item..." />;
    }

    if (!item) {
      return null;
    }

    return <Item item={item} />;
  }

  colunaGabarito(i) {
    return (
      this.porcentagemGruposHeaders[i].toUpperCase() ===
      this.gabarito.toUpperCase()
    );
  }

  linhaGabarito(i) {
    return String.fromCharCode(i + 65) === this.gabarito.toUpperCase();
  }

  get loading() {
    const { fetchingAnalise, fetchingAnaliseR } = this.state;
    return fetchingAnalise || fetchingAnaliseR;
  }

  showAnaliseReportModal = () => {
    this.setState({ showAnaliseReportModal: true });
  };

  renderParametrosItem() {
    return (
      <div className="row pt-4 mt-4 mb-4 pb-4">
        <ParametroItem
          col="col-4"
          name="Bisserial"
          title="Índice bisserial"
          value={this.bisserial}
          color={get(this.parametrosGabarito, 'cor_bisserial')}
          label={get(this.parametrosGabarito, 'nivel_bisserial')}
        />
        <ParametroItem
          col="col-4"
          name="Discriminação"
          title="Índice de discriminação"
          value={this.discriminacao}
          color={get(this.parametrosGabarito, 'cor_discriminacao')}
          label={get(this.parametrosGabarito, 'nivel_discriminacao')}
        />
        <ParametroItem
          col="col-4"
          name="Dificuldade"
          title="Índice de dificuldade"
          value={this.dificuldade}
          color={get(this.parametrosGabarito, 'cor_dificuldade')}
          label={get(this.parametrosGabarito, 'nivel_dificuldade')}
        />
      </div>
    );
  }

  renderAnaliseGrupos() {
    return (
      <div className="row pt-3">
        <div className="col-12 pb-3">
          <p className="azul">
            <b>Alternativa escolhida por grupo de respondentes do item</b>
          </p>
        </div>
        <div className="col-6">
          <LinhaAlternativaEscolhida
            id="linha-alternativa-escolhida"
            data={this.porcentagemGrupos}
            gabarito={this.gabarito}
          />
        </div>
        <div className="col-6">
          <table
            className="table table-analise table-alternativa-analise"
            id="table-alternativa-escolhida"
          >
            <thead>
              <tr>
                {this.porcentagemGruposHeaders.map((d) => (
                  <th key={d}>{d}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {this.porcentagemGrupos.map((d, j) => (
                <tr key={`${d}${j}`}>
                  {d.map((f, i) => (
                    <td
                      key={`${f}${i}`}
                      className={i ? 'text-right' : 'text-center'}
                      style={{
                        backgroundColor: this.colunaGabarito(i)
                          ? GABARITO_COLOR
                          : null
                      }}
                    >
                      {f
                        ? f.toLocaleString('pt-BR', {
                            maximumFractionDigits: 1
                          })
                        : '-'}
                      {f && i > 0 ? '%' : ''}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <p>
            <i className="fas fa-circle " style={{ color: GABARITO_COLOR }} />{' '}
            Gabarito.
          </p>
        </div>
      </div>
    );
  }

  renderAnaliseAlternativas() {
    return (
      <div className="row pt-3">
        <div className="col-12 pb-3">
          <p className="azul">
            <b>Análise por Alternativa</b>
          </p>
        </div>
        <div className="col-6">
          <BarraAlternativaEscolhida
            id="barra-alternativa-escolhida"
            data={this.parametrosAlternativas}
            gabarito={this.gabarito}
          />
        </div>
        <div className="col-6">
          <table className="table table-analise table-alternativa-analise">
            <thead>
              <tr>
                <th>Alternativa</th>
                <th>Respondentes</th>
                <th>Dificuldade</th>
                <th>Bisserial</th>
                <th>Discriminação</th>
              </tr>
            </thead>
            <tbody>
              {this.parametrosAlternativas.map((d, i) => (
                <tr
                  key={`${d}${i}`}
                  style={{
                    backgroundColor: this.linhaGabarito(i)
                      ? GABARITO_COLOR
                      : null
                  }}
                >
                  {d.map((f, j) => (
                    <td
                      key={`${f}${j}`}
                      className={j ? 'text-right' : 'text-center'}
                    >
                      {f
                        ? f.toLocaleString('pt-BR', {
                            maximumFractionDigits: 3
                          })
                        : '-'}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <p>
            <span>
              <i className="fas fa-exclamation-circle danger" /> As alternativas
              marcadas são consideradas inadequadas.
            </span>
            <span className="ml-2">
              <i className="fas fa-circle " style={{ color: GABARITO_COLOR }} />{' '}
              Gabarito.
            </span>
          </p>
        </div>
      </div>
    );
  }

  getItensWithAlpha = () => {
    const { alphas } = this.state;
    let itemInfos = this.itens;
    let itemIndex;
    alphas.forEach((d) => {
      itemIndex = parseInt(d._row.slice(1)) - 1;
      itemInfos[itemIndex].alpha_teste = d.alpha_teste;
      itemInfos[itemIndex].alpha = d.alpha;
    });
    return itemInfos;
  };

  renderAnaliseItem() {
    const { itemSelecionado } = this.state;
    const itemInfos = this.getItensWithAlpha();
    const selectOptions = itemInfos.map(mapItem);

    function getItemLabel() {
      if (!selectOptions[0]) {
        return 'Item não encontrado';
      }

      return itemSelecionado.label
        ? itemSelecionado.label
        : selectOptions[0].label;
    }

    return (
      <div className="pt-3 container">
        <Form>
          <Select
            clearable={false}
            placeholder="selecione"
            col="col-sm-6 pl-0"
            label=""
            options={selectOptions}
            onChange={this.handleItemChange}
            value={get(itemSelecionado, 'id')}
            field="itens"
          />
        </Form>
        <div className="border-dados">
          <div className="row pt-3 ">
            <div className="col-8">
              <p className="azul titulo-dados">
                <b>Análise do {getItemLabel()}</b>
              </p>
            </div>
            <div className="col-2">
              <p>
                <b>Respondentes: </b>
                <br />
                {this.respondentesItem}
              </p>
            </div>
            <div className="col-2">
              <p>
                <b>Não Respondentes: </b>
                <br />
                {this.naoRespondentesItem}
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">{this.renderItem()}</div>
        </div>
        <hr />
        {this.loading && <Loading message="Carregando análise..." />}
        <div className={classnames({ 'd-none': this.loading })}>
          <React.Fragment>
            {this.renderParametrosItem()}
            <hr />
            {this.renderAnaliseGrupos()}
            <hr />
            {this.renderAnaliseAlternativas()}
          </React.Fragment>
        </div>
        <hr className="pt-3" />
        <div className="row">
          <div className="col-12">
            <p className="azul pt-3">
              <b>Definição dos grupos</b>
            </p>
            <div className="def-groups">
              {this.groupsList
                ? this.groupsList.map((group) => (
                    <GrupoAnalise key={group.title} group={group} />
                  ))
                : null}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const pathList = [
      {
        label: 'Análise TCT/TRI',
        icon: 'list',
        link: '/analise/gerar'
      }
    ];
    const { activeTab, showAnaliseReportModal, itemSelecionado } = this.state;

    return (
      <Fragment>
        <NavBreadCrumb pathList={pathList} currentPath="Análise TCT" />
        <div className="container mb-4 pb-4">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-6">
                      <h1>Análise TCT</h1>
                    </div>
                    <div className="col-6">
                      <div className="text-right">
                        <Link to="/analise/gerar">
                          <button className="btn btn-white">Nova Busca</button>
                        </Link>
                        <Link to={`/analise/tri/${this.analise}`}>
                          <button className="btn primary-button ml-3">
                            Ir para Análise TRI
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
                <div className="card-body pt-0">
                  <div className="row">
                    <div className="col-12">
                      <TabelaAplicacoesAnalise aplicacoes={this.aplicacoes} />
                    </div>
                    <div className="col-6">
                      <p>
                        <b>Total de Itens: </b>
                        {this.totalItens}{' '}
                        <b className="ml-3">Total de Respondentes: </b>
                        {this.totalCandidatos}
                      </p>
                    </div>
                    <div className="col-6">
                      <div className="text-right">
                        <button
                          className="btn btn-white"
                          onClick={this.showAnaliseReportModal}
                        >
                          Parâmetros das Questões
                        </button>
                        <button
                          className="btn btn-white ml-3"
                          onClick={this.handleExport}
                        >
                          <i className="fas fa-print" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <button
                        className={classnames('nav-link tab-modal', {
                          active: activeTab === 'qualidade'
                        })}
                        onClick={() =>
                          this.setState({ activeTab: 'qualidade' })
                        }
                      >
                        Qualidade do Teste
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={classnames('nav-link tab-modal', {
                          active: activeTab === 'analise_item'
                        })}
                        onClick={() =>
                          this.setState({ activeTab: 'analise_item' })
                        }
                      >
                        Análise por Item
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content bg-white">
                    <div
                      className={classnames('tab-pane', {
                        active: activeTab === 'qualidade'
                      })}
                    >
                      {this.renderQualidade()}
                    </div>
                    <div
                      className={classnames('tab-pane', {
                        active: activeTab === 'analise_item'
                      })}
                    >
                      {itemSelecionado && this.renderAnaliseItem()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AnaliseReportModal
          title={get(this.aplicacoes, '0.nome')}
          isOpen={showAnaliseReportModal}
          onClose={() => this.setState({ showAnaliseReportModal: false })}
          itens={this.itens}
          parametros={this.state.parametros}
          totalCandidatos={this.totalCandidatos}
        />
      </Fragment>
    );
  }
}

export default AnaliseTCT;
