import React from 'react';
import { withRouter } from 'react-router-dom';
import { Form } from 'informed';
import { get } from 'lodash';
import { default as FormContainer } from '../../containers/Form';
import InputText from '../../components/inputs/InputText';
import InputSummernote from '../../components/inputs/InputSummernote';
import InputSelect from '../../components/inputs/InputSelect';
import Loading from '../../components/Loading';
import BaseCreate from '../../base/BaseCreate';
import { API_HOST } from '../../consts';

class Dados extends BaseCreate {

  api = `${API_HOST}/avaliacoes/avaliacao`;
  fields = ['nome', 'valor', 'exame', 'instrucoes', 'numero_de_alternativas'];
  multiFields = ['tipos_item'];

  state = {
    fetchingData: true,
  }

  componentDidMount() {
    super.componentDidMount();

    this.fetchApi({ link: '/itens/tipo', name: 'tipos_item' });
    this.fetchApi({ link: '/core/exame', name: 'exames' });

  }

  handleSubmitCallback = (response) => {
    const { history } = this.props;
    const id = get(response, 'data.id');
    history.push(`/instrumentos-cognitivos/${id}/itens`);
  }

  get hasAlternativas(){
  	let tiposHasAlternativas = {
 		1: 'MULTIPLA_ESCOLHA_UNICA_RESPOSTA',
 		2: 'MULTIPLA_ESCOLHA_MULTIPLAS_RESPOSTAS'
	}

    if(this.state.formState){
      if(this.state.formState.values.hasOwnProperty('tipos_item')){
        if(this.state.formState.values.tipos_item.some(r=> tiposHasAlternativas[r])){
          return true
        }
      }
    }
    return false
  }

  render() {
    const { tipos_item, fetchingData, submitting, exames } = this.state;
    return (
      <Form
        onChange={formState => this.setState({ formState })}
        getApi={(formApi) => { this.formApi = formApi; }}
        onSubmit={this.handleSubmit}
      >
        {() => {
          if (fetchingData) {
            return <Loading />;
          }

          return (
            <FormContainer
              title="Dados"
              next="itens"
              onClick={this.handleNext}
              disabled={submitting}
            >
              <div className="row">
                <InputText col="col-sm-6" label="Nome" field="nome" />
                <InputText col="col-sm-2" label="Valor" field="valor" />
                <InputSelect
                  col="col-sm-6"
                  label="Exame"
                  options={exames}
                  field="exame"
                />
                <InputSelect
                  col="col-sm-6"
                  label="Tipo de item"
                  options={tipos_item}
                  field="tipos_item"
                  multi
                />
                <React.Fragment>
               { this.hasAlternativas ?
                <InputText
                  col="col-sm-3"
                  label="Número de Alternativas"
                  field="numero_de_alternativas"
                />
                : null}
                </React.Fragment>
                <InputSummernote
                  col="col-sm-12"
                  label="Instruções"
                  field="instrucoes"
                />
              </div>
            </FormContainer>
          );
        }}
      </Form>
    );
  }
}

export default withRouter(Dados);
