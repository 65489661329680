import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const CollapseButton = ({ collapsed, onClick, detailPedido }) => (
  <button
    onClick={onClick}
    className="btn btn-white"
    style={{ marginLeft: '-1rem' }}
  >
    {detailPedido ? (
      `Ver detalhes`
    ) : (
      <i
        className={classnames('fas', {
          'fa-chevron-down': collapsed,
          'fa-chevron-up': !collapsed
        })}
      />
    )}
  </button>
);

CollapseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  collapsed: PropTypes.bool,
  detailPedido: PropTypes.bool
};

CollapseButton.defaultProps = {
  collapsed: false,
  detailPedido: false
};

export default CollapseButton;
