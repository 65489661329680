import React from 'react';
import PropTypes from 'prop-types';
import Equation from './Equation';

const EquationShortcut = ({ formula, onClick }) => (
  <button type="button" className="btn btn-white btn-equation-shortcut" onClick={() => onClick(formula)}>
    <Equation formula={formula} />
  </button>
);

EquationShortcut.propTypes = {
  formula: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default EquationShortcut;
