import React, { Fragment } from 'react';
import { Route, Link, withRouter } from 'react-router-dom';
import { get } from 'lodash';
import NavBreadCrumb from '../../containers/NavBreadCrumb';
import BaseAnalise from '../../base/BaseAnalise';
import classnames from 'classnames';
import EtapasCalibracao from './EtapasCalibracao';
import CurvaInformacao from '../../components/CurvaInformacao';
import ReguaProficiencia from '../../components/ReguaProficiencia';
import TabelaAplicacoesAnalise from '../../components/TabelaAplicacoesAnalise';
import HistogramaDistribuicaoProficiencia from '../../components/HistogramaDistribuicaoProficiencia';
import axios from 'axios';
import { ANALYSIS_API_HOST, API_HOST } from '../../consts';

class AnaliseTRI extends BaseAnalise {
  state = {
    proficiencia: [],
    activeTab: 'etapas_calib',
    loading: false,
    parametrosCorrigidos: [],
    parametrosIniciais: [],
    curvas: [],
    escalaProficiencia: [],
    histograma: null
  };

  componentDidMount() {
    this.fetchAnalise().then(() => {
      Promise.all([
        this.fetchProficiencia(),
        this.fetchParametrosIniciais(),
        this.fetchParametrosCorrigidos(),
        this.fetchCurva(),
        this.fetchRegua().then(this.fetchHistograma)
      ]).then(() => {
        this.setState({ fetchingAnaliseR: false });
      });
    });

    const { location, history } = this.props;
    if (location.pathname === `/analise/tri/${this.analise}`) {
      history.push(`${location.pathname}/etapas-calibracao`);
    }
  }

  handleExport = () => {
    const exportLink = `/analise/${this.analise}/impressao`;
    window.open(exportLink);
  };

  fetchAnalise() {
    this.setState({ fetchingAnalise: true });

    return axios
      .get(`${API_HOST}/analises/analise/${this.analise}`)
      .then((response) => {
        this.setState({ analise: response.data, fetchingAnalise: false });
      });
  }

  fetchProficiencia() {
    return axios
      .get(`${ANALYSIS_API_HOST}/analise_tri/${this.analise}/proficiencia/`)
      .then((response) => {
        this.setState({ proficiencia: response.data });
      });
  }

  fetchParametrosIniciais() {
    return axios
      .get(
        `${ANALYSIS_API_HOST}/analise_tri/${this.analise}/parametros_iniciais/`
      )
      .then((response) => {
        this.setState({ parametrosIniciais: response.data });
      });
  }

  fetchParametrosCorrigidos() {
    return axios
      .get(
        `${ANALYSIS_API_HOST}/analise_tri/${this.analise}/parametros_corrigidos/`
      )
      .then((response) => {
        this.setState({ parametrosCorrigidos: response.data });
      });
  }

  fetchRegua() {
    return axios
      .get(`${ANALYSIS_API_HOST}/analise_tri/${this.analise}/regua/`)
      .then((response) => {
        this.setState({
          escalaProficiencia: response.data,
          regua: this.mapRegua(response.data)
        });
      });
  }

  fetchCurva() {
    return axios
      .get(
        `${ANALYSIS_API_HOST}/analise_tri/${this.analise}/curva_caracteristica/`
      )
      .then((response) => {
        this.setState({ curvas: response.data });
      });
  }

  fetchHistograma = () => {
    return axios
      .get(`${ANALYSIS_API_HOST}/analise_tri/${this.analise}/histograma/`)
      .then((response) => {
        this.setState({ histograma: response.data });
      });
  };

  mapRegua(data) {
    const { conteudo } = data;
    const initial = -4;
    const ratio = 0.5;
    return conteudo.map((d, i) => [
      initial + ratio * i,
      ...d.map((f) => f.item)
    ]);
  }

  get activeTab() {
    return get(this.props, 'match.params.tab');
  }

  render() {
    const pathList = [
      {
        label: 'Análise TCT/TRI',
        icon: 'list',
        link: '/analise/gerar'
      }
    ];

    const {
      proficiencia,
      parametrosIniciais,
      parametrosCorrigidos,
      curvas,
      escalaProficiencia,
      histograma,
      regua
    } = this.state;

    return (
      <Fragment>
        <NavBreadCrumb pathList={pathList} currentPath="Análise TRI" />
        <div className="container mb-4 pb-4">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-6">
                      <h1>Análise TRI</h1>
                    </div>
                    <div className="col-6">
                      <div className="text-right">
                        <Link to="/analise/gerar">
                          <button className="btn btn-white">Nova Busca</button>
                        </Link>
                        <Link to={`/analise/tct/${this.analise}`}>
                          <button className="btn primary-button ml-3">
                            Ir para Análise TCT
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
                <div className="card-body pt-0">
                  <div className="row">
                    <div className="col-12">
                      <TabelaAplicacoesAnalise aplicacoes={this.aplicacoes} />
                    </div>
                    <div className="col-6">
                      <p>
                        <b>Itens: </b>
                        {this.totalItens} <b className="ml-3">Respondentes: </b>
                        {this.totalCandidatos}
                      </p>
                    </div>
                    <div className="col-6">
                      <div className="text-right">
                        {/* <button className="btn btn-white">
                          Relatório do análise
                        </button> */}
                        <button
                          className="btn btn-white ml-3"
                          onClick={this.handleExport}
                        >
                          <i className="fas fa-print" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <ul className="nav nav-tabs pt-3 mt-2">
                    <li className="nav-item">
                      <Link
                        to={`/analise/tri/${this.analise}/etapas-calibracao`}
                        className={classnames('nav-link tab-modal', {
                          active: this.activeTab === 'etapas-calibracao'
                        })}
                        onClick={() =>
                          this.setState({ activeTab: 'etapas_calib' })
                        }
                      >
                        Etapas de Calibração
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={`/analise/tri/${this.analise}/curva-informacao`}
                        className={classnames('nav-link tab-modal', {
                          active: this.activeTab === 'curva-informacao'
                        })}
                        onClick={() => this.setState({ activeTab: 'curva' })}
                      >
                        Curva da Informação
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={`/analise/tri/${this.analise}/escala-proficiencia`}
                        className={classnames('nav-link tab-modal', {
                          active: this.activeTab === 'escala-proficiencia'
                        })}
                        onClick={() =>
                          this.setState({ activeTab: 'proficiencia' })
                        }
                      >
                        Escala de proficiência
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to={`/analise/tri/${this.analise}/histograma`}
                        className={classnames('nav-link tab-modal', {
                          active: this.activeTab === 'histograma'
                        })}
                        onClick={() =>
                          this.setState({ activeTab: 'histograma' })
                        }
                      >
                        Histograma de distribuição de proficiência
                      </Link>
                    </li>
                    {/*<li className="nav-item">
                      <button
                        className={classnames('nav-link tab-modal', { active: activeTab === 'comparativo_histogramas' })}
                        onClick={() => this.setState({ activeTab: 'comparativo_histogramas' })}
                      >
                        Histogramas
                      </button>
                    </li>*/}
                  </ul>
                  <div className="tab-content bg-white">
                    <Route
                      path="/analise/tri/:id/etapas-calibracao/:etapa?"
                      render={() => (
                        <EtapasCalibracao
                          parametrosIniciais={parametrosIniciais}
                          parametrosCorrigidos={parametrosCorrigidos}
                          curvas={curvas}
                        />
                      )}
                    />
                  </div>
                  <div className="tab-content bg-white">
                    <Route
                      path="/analise/tri/:id/curva-informacao"
                      render={() => (
                        <CurvaInformacao
                          id="curva-informacao"
                          proficiencia={proficiencia}
                        />
                      )}
                    />
                  </div>
                  <div className="tab-content bg-white">
                    <Route
                      path="/analise/tri/:id/escala-proficiencia"
                      render={() => <ReguaProficiencia data={regua} />}
                    />
                  </div>
                  <div className="tab-content bg-white">
                    <Route
                      path="/analise/tri/:id/histograma"
                      render={() => (
                        <HistogramaDistribuicaoProficiencia
                          id="histograma"
                          histograma={histograma}
                          escalaProficiencia={escalaProficiencia}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(AnaliseTRI);
