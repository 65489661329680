import React from 'react';

class RawInputSearch extends React.Component {

  state = {
    value: '',
  }

  handleChange = (event) => {
    const { onChange } = this.props;
    const { value } = event.target;
    this.setState({ value: value });
    if (onChange) onChange(value);
  }

  render() {
    const { value } = this.state;
    return (
      <div className="wrap">
        <div className="search">
          <input
            className="search-term"
            placeholder="Pesquisar..."
            value={value}
            onChange={this.handleChange}
          />
        </div>
      </div>
    );
  }
}

export default RawInputSearch;
