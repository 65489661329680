import React from 'react';

import { get } from 'lodash';
import axios from 'axios';
import { toast } from 'react-toastify';

import { API_HOST } from '../../consts';
import { Form } from 'informed';
import { withRouter } from 'react-router-dom';
import FormContainer from '../../containers/Form';
import InputSelect from '../../components/inputs/InputSelect';
import LabelView from '../../components/LabelView';
import InputCheckbox from '../../components/inputs/InputCheckbox';
import { InputText } from '../../components/inputs';
import InputNumber from '../../components/inputs/InputNumber';

class IntegracaoTOTVS extends React.Component {
  state = {
    avaliacao: undefined,
    avaliacoes: [],
    cursos: [],
    cursoAtual: [],
    disciplinas: [],
    turma_disciplina: [],
    turmaDisciplinaAtual: null,
    cursosAtual: null,
    disciplinaAtual: null,
    integracaoPayload: null,
    periodo_letivo: null,
    provaIntegradora: true,
    coligada: null,
    filial: null,
    cod_curso: null,
    cod_etapa: null,
    periodo_turma: null
  };

  fetchCursos = () => {
    axios
      .get(
        `${API_HOST}/avaliacoes/avaliacao/get_cursos_from_totvs_distinct_period`
      )
      .then((res) => {
        this.setState({ cursos: res.data });
      })
      .catch((err) => {
        toast.error('Ocorreu um erro');
        console.error(err);
      });
  };

  fetchAvaliacao = () => {
    const { match } = this.props;

    axios
      .get(`${API_HOST}/avaliacoes/avaliacao/${match.params.id}`)
      .then((res) => {
        this.setState({ avaliacao: res.data }, this.setInitialValues);
      })
      .catch((err) => {
        toast.error('Ocorreu um erro');
        console.error(err);
      });
  };

  fetchDisciplinas = (curso) => {
    const { cursoAtual } = this.state;

    if (
      cursoAtual &&
      get(cursoAtual, 'descricao') !== get(curso, 'descricao')
    ) {
      this.formApi.setValue('avaliacoes', null);
      this.formApi.setValue('disciplina', null);
    }
    if (curso) {
      axios
        .get(
          `${API_HOST}/avaliacoes/avaliacao/get_disciplinas_from_totvs?curso=${curso.descricao}`
        )
        .then((res) => {
          this.setState({ disciplinas: res.data, cursoAtual: curso });
        })
        .catch((err) => {
          toast.error('Ocorreu um erro, tente novamente.');
          console.error(err);
        });
    }
  };

  fetchTurmaDisciplina = (idDisciplina) => {
    const { cursoAtual, disciplinaAtual } = this.state;

    if (
      disciplinaAtual &&
      get(disciplinaAtual, 'descricao') !== get(idDisciplina, 'descricao')
    ) {
      this.formApi.setValue('avaliacoes', null);
      this.formApi.setValue('turma_disciplina', null);
    }

    if (idDisciplina) {
      axios
        .get(
          `${API_HOST}/avaliacoes/avaliacao/get_turma_disciplina_from_totvs?curso=${cursoAtual.descricao}&id_disciplina=${idDisciplina.descricao}`
        )
        .then((res) => {
          this.setState({
            turma_disciplina: res.data,
            disciplinaAtual: idDisciplina
          });
        })
        .catch((err) => {
          toast.error('Ocorreu um erro, tente novamente.');
          console.error(err);
        });
    }
  };

  fetchAvaliacoes = (turmaDisciplina) => {
    const { cursoAtual, turmaDisciplinaAtual, disciplinaAtual } = this.state;

    if (
      turmaDisciplinaAtual &&
      get(turmaDisciplinaAtual, 'descricao') !==
        get(turmaDisciplina, 'descricao')
    ) {
      this.formApi.setValue('avaliacoes', null);
    }

    if (turmaDisciplina) {
      axios
        .get(
          `${API_HOST}/avaliacoes/avaliacao/get_avaliacoes_from_totvs?curso=${cursoAtual.descricao}&id_disciplina=${disciplinaAtual.descricao}&turma_disciplina_id=${turmaDisciplina.id}`
        )
        .then((res) => {
          this.setState({
            avaliacoes: res.data,
            turmaDisciplinaAtual: turmaDisciplina
          });
        })
        .catch((err) => {
          toast.error('Ocorreu um erro, tente novamente.');
          console.error(err);
        });
    }
  };

  setInitialValues = () => {
    const initialValues = this.formatInitialValues();
    this.formApi.setValues(initialValues);
  };

  componentDidMount() {
    Promise.all([this.fetchCursos(), this.fetchAvaliacao()]);
  }

  formatIntegracaoPayload = () => {
    const {
      cursoAtual,
      disciplinaAtual,
      turmaDisciplinaAtual,
      avaliacaoAtual,
      provaIntegradora,
      periodo_letivo,
      coligada,
      filial,
      cod_curso,
      cod_etapa,
      periodo_turma
    } = this.state;

    return provaIntegradora
      ? JSON.stringify({
          periodo_letivo: periodo_letivo,
          periodo_turma: periodo_turma,
          coligada: coligada,
          filial: filial,
          cod_curso: cod_curso,
          cod_etapa: cod_etapa
        })
      : JSON.stringify({
          curso: cursoAtual.descricao,
          disciplina: disciplinaAtual.label,
          id_disciplina: disciplinaAtual.descricao,
          turma_disciplina_id: turmaDisciplinaAtual.id,
          turma_disciplina_ds: turmaDisciplinaAtual.descricao,
          avaliacao: avaliacaoAtual.descricao
        });
  };

  handleUpdateAvaliacao = () => {
    const { match } = this.props;
    const { provaIntegradora } = this.state;
    const integracaoPayload = this.formatIntegracaoPayload();

    axios
      .patch(
        `${API_HOST}/avaliacoes/avaliacao/${match.params.id}/update_integracao_field`,
        {
          integracaoPayload,
          provaIntegradora
        }
      )
      .then((res) => {
        const { history } = this.props;
        if (res.status === 200) {
          history.push('/instrumentos-cognitivos');
          toast.success('Dados enviados para processamento!');
        }
      })
      .catch((err) => {
        const status = get(err, 'response.status');
        const errorMessage = get(err, 'response.data');
        if (status === 404) {
          toast.error(
            'Para finalizar, é necessário preencher todos os campos.'
          );
        } else {
          toast.error(errorMessage);
        }
        console.error(err);
      });
  };

  formatInitialValues = () => {
    const { avaliacao } = this.state;

    const prova_integradora = avaliacao.prova_integradora;

    const rawIntegracaoTotvs = JSON.parse(
      get(avaliacao, 'integracao_totvs', '{}')
    );

    const curso = get(rawIntegracaoTotvs, 'curso')
      ? {
          id: get(rawIntegracaoTotvs, 'curso'),
          value: get(rawIntegracaoTotvs, 'curso'),
          label: get(rawIntegracaoTotvs, 'curso'),
          descricao: get(rawIntegracaoTotvs, 'curso')
        }
      : undefined;

    const disciplina = get(rawIntegracaoTotvs, 'disciplina')
      ? {
          id: get(rawIntegracaoTotvs, 'disciplina'),
          idDisciplina: get(rawIntegracaoTotvs, 'id_disciplina'),
          value: get(rawIntegracaoTotvs, 'disciplina'),
          label: get(rawIntegracaoTotvs, 'disciplina'),
          descricao: get(rawIntegracaoTotvs, 'id_disciplina')
        }
      : undefined;

    const avaliacoes = get(rawIntegracaoTotvs, 'avaliacao')
      ? {
          id: get(rawIntegracaoTotvs, 'avaliacao'),
          value: get(rawIntegracaoTotvs, 'avaliacao'),
          label: get(rawIntegracaoTotvs, 'avaliacao'),
          descricao: get(rawIntegracaoTotvs, 'avaliacao')
        }
      : undefined;

    const turma_disciplina = get(rawIntegracaoTotvs, 'turma_disciplina_id')
      ? {
          id: get(rawIntegracaoTotvs, 'turma_disciplina_id'),
          idTurmaDisciplina: get(rawIntegracaoTotvs, 'turma_disciplina_id'),
          value: get(rawIntegracaoTotvs, 'turma_disciplina_id'),
          label: get(rawIntegracaoTotvs, 'turma_disciplina_ds'),
          descricao: get(rawIntegracaoTotvs, 'turma_disciplina_ds')
        }
      : undefined;

    // Dados Prova Integadora
    const cod_curso = get(rawIntegracaoTotvs, 'cod_curso');
    const cod_etapa = get(rawIntegracaoTotvs, 'cod_etapa');
    const periodo_turma = get(rawIntegracaoTotvs, 'periodo_turma');
    const coligada = get(rawIntegracaoTotvs, 'coligada');
    const periodo_letivo = get(rawIntegracaoTotvs, 'periodo_letivo');
    const filial = get(rawIntegracaoTotvs, 'filial');

    this.setState(
      {
        provaIntegradora: prova_integradora,
        cursoAtual: curso,
        disciplinaAtual: disciplina,
        turmaDisciplinaAtual: turma_disciplina,
        avaliacaoAtual: avaliacoes,
        periodo_letivo: periodo_letivo,
        coligada: coligada,
        filial: filial,
        cod_curso: cod_curso,
        cod_etapa: cod_etapa,
        periodo_turma: periodo_turma
      },
      () => {
        if (curso) {
          this.fetchDisciplinas(curso);
        }
        if (disciplina) {
          this.fetchTurmaDisciplina(disciplina);
        }
        if (turma_disciplina) {
          this.fetchAvaliacoes(turma_disciplina);
        }
      }
    );

    return {
      prova_integradora,
      periodo_letivo,
      coligada,
      filial,
      cod_curso,
      cod_etapa,
      periodo_turma,
      turma_disciplina,
      curso,
      disciplina,
      avaliacoes
    };
  };

  render() {
    const {
      avaliacoes,
      cursos,
      disciplinas,
      avaliacao,
      provaIntegradora,
      turma_disciplina,
      coligada,
      filial,
      periodo_letivo,
      cod_curso,
      cod_etapa,
      periodo_turma
    } = this.state;

    return (
      <Form
        getApi={(formApi) => {
          this.formApi = formApi;
        }}
      >
        <FormContainer
          title="Integração TOTVS"
          previous="itens"
          onClick={this.handleUpdateAvaliacao}
          last="instrumentos-cognitivos"
        >
          <div className="row">
            <LabelView
              col="col-sm-6"
              title="Avaliação"
              text={get(avaliacao, 'nome')}
            />
            <LabelView
              col="col-sm-6"
              title="Número de Itens"
              text={get(avaliacao, 'itens')}
            />
          </div>
          <hr />
          <div className="row">
            <InputCheckbox
              col="col-md-5 col-sm-12"
              label="Prova integradora"
              field="prova_integradora"
              onChange={(event) =>
                this.setState({ provaIntegradora: event.target.checked })
              }
            />
          </div>
          {provaIntegradora ? (
            <div className="row" style={{ marginBottom: '1.5rem' }}>
              <InputNumber
                col="col-sm-6"
                label="Coligada"
                field="coligada"
                value={coligada}
                onChange={(event) => {
                  this.setState({ coligada: event });
                }}
              />
              <InputNumber
                col="col-sm-6"
                label="Filial"
                field="filial"
                value={filial}
                onChange={(event) => {
                  this.setState({ filial: event });
                }}
              />
              <InputText
                col="col-sm-6"
                label="Período Letivo"
                field="periodo_letivo"
                value={periodo_letivo}
                onChange={(event) => {
                  this.setState({ periodo_letivo: event });
                }}
              />
              <InputText
                col="col-sm-6"
                label="Período Turma"
                field="periodo_turma"
                value={periodo_turma}
                onChange={(event) => {
                  this.setState({ periodo_turma: event });
                }}
              />
              <InputText
                col="col-sm-12"
                label="Curso"
                field="cod_curso"
                value={cod_curso}
                onChange={(event) => this.setState({ cod_curso: event })}
              />
              <InputText
                col="col-sm-12"
                label="Etapa"
                field="cod_etapa"
                value={cod_etapa}
                onChange={(event) => this.setState({ cod_etapa: event })}
              />
            </div>
          ) : (
            <div className="row" style={{ marginBottom: '1.5rem' }}>
              <InputSelect
                label="Curso"
                col="col-md-4"
                field="curso"
                onChange={this.fetchDisciplinas}
                options={cursos}
              />
              <InputSelect
                label="Disciplina"
                col="col-md-4"
                field="disciplina"
                onChange={this.fetchTurmaDisciplina}
                options={disciplinas}
              />
              <InputSelect
                label="Turma Disciplina"
                col="col-md-4"
                field="turma_disciplina"
                onChange={this.fetchAvaliacoes}
                options={turma_disciplina}
              />
              <InputSelect
                label="Avaliação"
                col="col-md-4"
                field="avaliacoes"
                onChange={(avaliacaoAtual) => this.setState({ avaliacaoAtual })}
                options={avaliacoes}
              />
            </div>
          )}
        </FormContainer>
      </Form>
    );
  }
}

export default withRouter(IntegracaoTOTVS);
