import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import Uploader from '../uploads/Uploader';

const initialState = {
  progressEvent: {},
  uploadPercentage: 0,
  uploadedVideoURL: '',
  videoFile: {}
};

class UploadVideoBox extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.closeModal = this.closeModal.bind(this);
    this.uploadVideo = this.uploadVideo.bind(this);
    this.onUploadError = this.onUploadError.bind(this);
    this.onUploadProgress = this.onUploadProgress.bind(this);
    this.onUploadSuccess = this.onUploadSuccess.bind(this);
    this.state = initialState;
  }

  closeModal() {
    const { onClose } = this.props;
    this.setState(initialState);
    onClose();
  }

  createVideoNode(videoURL) {
    const { videoFile } = this.state;
    const node = document.createElement('center');
    const video = document.createElement('video');
    const source = document.createElement('source');
    const space = document.createElement('br');

    source.src = videoURL;
    source.type = videoFile.type;

    video.appendChild(source);
    video.width = '640';
    video.height = '360';
    video.controls = true;

    video.appendChild(source);

    node.appendChild(video);
    node.append(space);
    return node;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { onClick } = this.props;
    const { uploadedVideoURL } = this.state;
    const videoNode = this.createVideoNode(uploadedVideoURL);
    if (uploadedVideoURL) {
      onClick(videoNode);
      this.setState(initialState);
    }
  };

  onUploadProgress(progressEvent) {
    if (progressEvent) {
      this.setState({ progressEvent });
      this.setState({
        uploadPercentage: Math.trunc(
          100 * (progressEvent.loaded / progressEvent.total)
        )
      });
    }
  }

  onUploadSuccess(response) {
    this.setState({
      uploading: false,
      uploadedVideoURL:
        response.data.src || response.request.responseURL.split('?')[0]
    });
  }

  onUploadError(error) {
    this.setState({ uploading: false });
    const status = get(error, 'response.status');
    let message = 'Ocorreu um erro e não foi possível submeter o arquivo.';
    if (status === 400) {
      message = get(error, 'response.data.file.0') || message;
    }
    toast.error(message);
  }

  acceptedVideoTypes = {
    mp4: 'video/mp4',
    ogv: 'video/ogg',
    webm: 'video/webm'
  };

  async uploadVideo(event) {
    const file = get(event, 'target.files.0');
    if (file) {
      if (!Object.values(this.acceptedVideoTypes).includes(file.type)) {
        toast.error(
          `No momento, somente os formato ${Object.keys(
            this.acceptedVideoTypes
          ).join()} são suportados.`
        );
        return;
      }

      this.setState({ uploading: true, videoFile: file });
      const uploader = new Uploader({
        file: file,
        onUploadProgress: this.onUploadProgress,
        onSuccess: this.onUploadSuccess,
        onError: this.onUploadError
      });
      uploader.upload();
    }
  }

  render() {
    const { isOpen } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={this.closeModal}>
        <ModalHeader toggle={this.closeModal}>Upload Video</ModalHeader>
        <ModalBody>
          <br />
          <label>
            {' '}
            Selecione um vídeo nos seguintes formatos:{' '}
            {Object.keys(this.acceptedVideoTypes).join()}{' '}
          </label>
          <input type="file" name="file" onChange={this.uploadVideo} />
          <label>Status: {this.state.uploadPercentage}%</label>
          <br />
          <br />
          <button
            type="submit"
            className="btn primary-button pull-right"
            disabled={this.state.uploadPercentage === 100 ? false : true}
            onClick={this.handleSubmit}
          >
            salvar
          </button>
          <button
            type="button"
            className="btn btn-sm btn-white"
            onClick={this.closeModal}
          >
            cancelar
          </button>
        </ModalBody>
      </Modal>
    );
  }
}
UploadVideoBox.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  onClose: PropTypes.func
};

UploadVideoBox.defaultProps = {
  isOpen: false,
  onClick: () => {},
  onClose: () => {}
};

export default UploadVideoBox;
