import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const ProgressTracker = styled.ol`
  margin: 0;
  counter-reset: item;
  list-style-type: none;
  padding: 0;

  .step {
    margin: 0 auto 20px;
    counter-reset: subitem;

    .step-name {
      background: #eee;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      display: inline-flex;
      margin: 0 0 0 15px;
      height: 35px;
      width: 100%;
      align-items: center;
      padding-left: 30px;
      color: #777777;
      line-height: 1.2em;
      font-size: 14px;
      text-decoration: none;

      &:hover {
        color: ${props => props.theme.primary};
      }
    }

    &:before {
      background: ${props => props.theme.border};
      border-radius: 20px;
      color: #fff;
      width: 35px;
      height: 35px;
      font-size: 15px;
      line-height: 1.8em;
      align-items: center;
      display: flex;
      justify-content: center;
      position: absolute;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      counter-increment: item;
      content: counter(item) " " !important;

    }

    &.active {
      .step-name {
        color: #333333;
        font-weight: bold;
        background: #ddd;
      }
      &:before {
        background: ${props => props.theme.action};
      }
    }
    &.done {
      .step-name {
        color: #3c763d;
      }
      &:before {
        background: #87db55;
        content: "\2714";
        color: #fff;
      }
    }
  }

  .sub-step {
    margin: 0 auto 20px;

    &::before {
      background: #aaa;
      border-radius: 20px;
      margin: 1px 0 0 38px;
      color: #FFFFFF;
      content: "";
      font-size: 12px;
      line-height: 1.9em;
      align-items: center;
      display: flex;
      justify-content: center;
      position: absolute;
      width: 30px;
      height: 30px;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      counter-increment: subitem;
      content: counter(item) "." counter(subitem) " ";
    }

    .step-name {
      display: inline-flex;
      margin: 1px 0 0 60px;
      height: 35px;
      width: 83%;
      align-items: center;
      padding-left: 20px;
      color: #777777;
      line-height: 1.2em;
      border-radius: 0 20px 20px 0;
      font-size: 12px;
    }

    &.active {
      .step-name {
        color: #333333;
        font-weight: bold;
        background: #FFFFFF;
      }
    }


    &.completed {

      .step-name {
        color: #3c763d;
      }
    }
  }
`;
