import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { addParametro, toggleImportarParametrosModal } from '../../actions/AnalisePsicometrica';
import InputText from '../../components/InputText';
import InputTextarea from '../../components/InputTextarea';
import InputFile from '../../components/InputFile';
import InputSelect from '../../components/InputSelect';
import { TipoImportacaoAnalisePsicometrica } from '../../consts';

const tiposImportacaoAnalisePsicometrica = [
  {
    value: TipoImportacaoAnalisePsicometrica.PARAMETRO_PRE_TESTE,
    label: 'Parâmetros do pré-teste',
  },
  {
    value: TipoImportacaoAnalisePsicometrica.CURVA_CARACTERISTICA_ITEM,
    label: 'Curva de característica dos itens',
  },
  {
    value: TipoImportacaoAnalisePsicometrica.CURVA_PROPORCAO_RESPOSTA,
    label: 'Curva de proporção de resposta',
  },
];

class ImportarParametrosModal extends React.Component {

  constructor(props) {
    super(props);
    this.addButtonIsActive = this.addButtonIsActive.bind(this);
  }

  addButtonIsActive() {
    const { importarParametrosForm } = this.props.AnalisePsicometrica.reducer;
    return Object.keys(importarParametrosForm).every(key => key === 'observacao' || importarParametrosForm[key]);
  }

  render() {
    return (
      <Modal
        isOpen={this.props.AnalisePsicometrica.reducer.importarParametrosModal}
        toggle={this.props.toggleImportarParametrosModal}
        size="lg"
      >
        <ModalHeader toggle={this.props.toggleImportarParametrosModal}>
          Parâmetros do pré-teste
        </ModalHeader>
        <ModalBody>
          <div className="row mt-2">
            <InputText
              label="Título"
              model="AnalisePsicometrica.reducer.importarParametrosForm.titulo"
              required
            />
            <InputSelect
              label="Tipo de importação"
              options={tiposImportacaoAnalisePsicometrica}
              model="AnalisePsicometrica.reducer.importarParametrosForm.tipoImportacao"
              required
            />
            <InputFile
              label="Arquivo"
              model="AnalisePsicometrica.reducer.importarParametrosForm.arquivo"
              required
            />
            <InputTextarea
              label="Observação"
              model="AnalisePsicometrica.reducer.importarParametrosForm.observacao"
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-padrao bg-padrao"
            onClick={this.props.addParametro}
            disabled={!this.addButtonIsActive()}
          >
            Adicionar
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  AnalisePsicometrica: state.AnalisePsicometrica,
});

export default connect(mapStateToProps, {
  addParametro,
  toggleImportarParametrosModal,
})(ImportarParametrosModal);
