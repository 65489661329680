import React from 'react';
import { BasicText, asField } from 'informed';

export default asField(({
  fieldState, field, label, helpBlock, numbers, icon, type, col, ...props
}) => (
  <React.Fragment>
    <div className={`${col} form-group`}>
      <label htmlFor={field}>{label}</label>
      <BasicText
        className={`form-control ${fieldState.error ? 'has-error' : null}`}
        id={field}
        fieldState={fieldState}
        maxLength={numbers}
        type={type}
        {...props}
      />
    </div>
    {helpBlock && <small className="form-text text-muted">{helpBlock}</small>}
    {fieldState.error && <small className={fieldState.error ? 'text-danger' : null}>{fieldState.error}</small>}
  </React.Fragment>
));
