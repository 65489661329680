import React from 'react';
import PropTypes from 'prop-types';
import HabView from '../components/HabView';

class HabReference extends React.Component {
  static defaultProps = {
    habilidades: [],
    isOpen: false,
    competencia: null,
    habilidade: null,
    eixo: null,
    color: '',
  };

  static propTypes = {
    isOpen: PropTypes.bool,
    habilidades: PropTypes.arrayOf(PropTypes.object),
    toggleModal: PropTypes.func.isRequired,
    competencia: PropTypes.objectOf(PropTypes.oneOfType([
      PropTypes.number, PropTypes.string])),
    eixo: PropTypes.objectOf(PropTypes.oneOfType([
      PropTypes.number, PropTypes.string])),
    habilidade: PropTypes.objectOf(PropTypes.oneOfType([
      PropTypes.number, PropTypes.string])),
    color: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    const {
      isOpen,
      competencia,
      eixo,
      habilidade,
      toggleModal,
    } = this.props;

    if (isOpen) {
      toggleModal('habilidade', {
        competencia,
        eixo,
        habilidade,
      });
    } else {
      toggleModal('habilidade', {
        competencia: null,
        eixo: null,
        habilidade: null,
      });
    }
  }

  renderHabilidades() {
    const {
      competencia,
      eixo,
      habilidades,
      toggleModal,
    } = this.props;

    if (habilidades.length > 0) {
      return habilidades.map(habilidade => (
        <HabView
          key={habilidade.id}
          id={habilidade.id}
          text={habilidade.descricao}
          onClick={() => toggleModal('habilidade', {
            competenciaAtual: competencia,
            eixoAtual: eixo,
            habilidadeAtual: habilidade,
          })}
        />
      ));
    }

    return <h5>Adicionar Habilidades</h5>;
  }

  render() {
    const {
      toggleModal,
      eixo,
      competencia,
      color,
    } = this.props;

    return (
      <div className={`card-reference view hability ${color}`}>
        <div className="card-reference-body center">
          {this.renderHabilidades()}
          <br />
          <button
            className="btn btn-default ad-habilidade"
            onClick={() => toggleModal('habilidades', {
              eixoAtual: eixo,
              competenciaAtual: competencia,
            })}
          >
            <i className="fas fa-plus accent-color" />
          </button>
        </div>
      </div>
    );
  }
}

export default HabReference;
