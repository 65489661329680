import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'informed';
import { withRouter } from 'react-router-dom';
import { get, capitalize } from 'lodash';
import { toast } from 'react-toastify';
import axios from 'axios';
import { API_HOST } from '../../consts';
import FormContainer from '../../containers/Form';
import Loading from '../../components/Loading';
import InputSelect from '../../components/inputs/InputSelect';
import InputSummernote from '../../components/inputs/InputSummernote';
import BaseCreate from '../../base/BaseCreate';
import Convites from './Convites';
import InputFile from '../../components/inputs/InputFile';

class Confirmar extends BaseCreate {
  state = {
    areas_conhecimento: [],
    submitting: false,
    fetchingData: false,
    openModal: null,
    arquivos: [],
  }

  componentDidMount() {
    this.fetchDestinatarios();
    this.fetchArquivos();
    this.fetchMensagemPadrao();
  }

  get tipoConvite() {
    const { tipoConvite } = this.props;
    return tipoConvite === 1 ? 'elaboradores' : 'revisores';
  }

  fetchDestinatarios() {
    const { tipoConvite } = this.props;
    axios.get(`${API_HOST}/encomenda/encomenda/${this.id}/destinatarios`, {
      params: {
        tipo_convite: tipoConvite,
      },
    })
      .then((response) => {
        const destinatarios = response.data.map(p => ({
          value: get(p, 'id'), label: get(p, 'nome'),
        }));
        this.setState({ destinatarios });
      });
  }

  fetchArquivos() {
    axios.get(`${API_HOST}/encomenda/encomenda/${this.id}/arquivos_pendentes`)
      .then((response) => {
        this.setState({ arquivos: response.data });
      });
  }

  fetchMensagemPadrao() {
    axios.get(`${API_HOST}/encomenda/convite/mensagem_padrao`)
      .then((response) => {
        const { mensagem_padrao } = response.data;
        if (mensagem_padrao) {
          this.formApi.setValue('mensagem', mensagem_padrao);
        }
      });
  }

  handleSubmit = (_values) => {
    const { history, tipoConvite } = this.props;
    const { formState } = this.state;
    const values = { ..._values };
    values.encomenda = this.id;
    values.tipo_convite = tipoConvite;

    this.setState({ submitting: true });

    if (values.mensagem) {
      axios.post(`${API_HOST}/encomenda/convite`, values)
        .then(() => {
          if (get(formState, 'values.destinatarios.length') > 0) {
            toast.success('Convites enviados com sucesso.');
          }
          history.push(this.next);
        })
        .catch(this.onValidateError);
    } else {
      history.push(this.next);
    }
  }

  onValidateError = (error) => {
    this.setState({ submitting: false });
    super.onValidateError(error);
  }

  handleNext = (next) => {
    this.next = next;
    this.formApi.submitForm();
  }

  toggleModal = (name) => {
    const { openModal } = this.state;
    this.setState({ openModal: openModal === name ? null : name });
  }

  uploadArquivo = (event) => {
    const file = get(event, 'target.files.0');

    if (file) {
      this.setState({ uploading: true });

      const data = new FormData();
      data.append('file', file);
      axios.post(`${API_HOST}/encomenda/encomenda/${this.id}/arquivos_pendentes`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          if (progressEvent) {
            this.setState({ progressEvent });
          }
        },
      })
        .then((response) => {
          const { arquivos } = this.state;
          this.setState({
            arquivos: [response.data, ...arquivos],
            uploading: false,
          });
        })
        .catch((error) => {
          this.setState({ uploading: false });
          const status = get(error, 'response.status');
          let message = 'Ocorreu um erro e não foi possível submeter o arquivo.';
          if (status === 400) {
            message = get(error, 'response.data.file.0') || message;
          }
          toast.error(message);
        });
    }
  }

  removeArquivo = (file) => {
    axios.delete(`${API_HOST}/uploads/${file.id}`)
      .then(() => {
        const { arquivos } = this.state;
        this.setState({ arquivos: arquivos.filter(f => f.id !== file.id) });
        toast.success('Arquivo removido com sucesso.');
      })
      .catch(() => {
        toast.error('Ocorreu um erro e não foi possível remover o arquivo');
      });
  }

  render() {
    const {
      destinatarios,
      submitting,
      fetchingData,
      openModal,
      arquivos,
      uploading,
      progressEvent,
    } = this.state;
    const { tipoConvite, formContainerProps } = this.props;

    return (
      <Form
        onChange={formState => this.setState({ formState })}
        getApi={(formApi) => { this.formApi = formApi; }}
        onSubmit={this.handleSubmit}
        initialValues={{ mensagem: '' }}
      >
        {() => {
          if (fetchingData) {
            return <Loading />;
          }

          return (
            <FormContainer
              previous="revisores"
              title={`Convocar ${this.tipoConvite}`}
              onClick={this.handleNext}
              disabled={submitting}
              {...formContainerProps}
            >
              <div className="row">
                <InputSelect
                  multi
                  selectAll
                  col="col-sm-12"
                  label={capitalize(this.tipoConvite)}
                  options={destinatarios}
                  field="destinatarios"
                />
                <InputSummernote
                  col="col-sm-12"
                  label="Mensagem"
                  field="mensagem"
                  height={250}
                />
              </div>
              <button
                type="button"
                className="btn btn-sm btn-white"
                onClick={() => this.toggleModal('convites')}
              >
                Visualizar mensagens anteriores
              </button>
              <Convites
                encomenda={this.id}
                tipoConvite={tipoConvite}
                toggle={() => this.toggleModal('convites')}
                isOpen={openModal === 'convites'}
              />
              <div className="row mt-4">
                <InputFile
                  multi
                  preview={false}
                  label="Anexos"
                  onChange={this.uploadArquivo}
                  onDelete={this.removeArquivo}
                  files={arquivos}
                  uploading={uploading}
                  progressEvent={progressEvent}
                />
              </div>
            </FormContainer>
          );
        }}
      </Form>
    );
  }
}

Confirmar.propTypes = {
  tipoConvite: PropTypes.oneOf([1, 2]).isRequired,
  formContainerProps: PropTypes.shape({
    last: PropTypes.string,
    next: PropTypes.string,
  }).isRequired,
};

export default withRouter(Confirmar);
