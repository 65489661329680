import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { get } from 'lodash';
import axios from 'axios';
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import ListItem from '../../containers/ListItem';
import NavBreadCrumb from '../../containers/NavBreadCrumb';
import Loading from '../../components/Loading';
import { API_HOST } from '../../consts';
import AddButton from '../../components/buttons/AddButton';
import BaseList from '../../base/BaseList';

class ListaMatriz extends BaseList {
  constructor(props) {
    super(props);
    this.fetchData = this.fetchData.bind(this);
  }

  state = {
    data: [],
    fetchingData: false,
    search: '',
    page_size: 10,
    page: 1,
    ordering: '-criado_em',
  };

  api = `${API_HOST}/matriz_referencia/matriz_referencia/summary`;

  removeMatrizReferencia(id) {
    axios.delete(`${API_HOST}/matriz_referencia/matriz_referencia/${id}`)
      .then(() => {
        toast.success(`Matriz de referência ${id} removida com sucesso.`);
        this.fetchData(false);
      });
  }

  changePage(pagination) {
    this.setState({ fetchingData: true });
    const page = pagination.selected + 1;
    this.setState({ page }, this.fetchData);
  }

  handleOrderingChange(value) {
    this.setState({ ordering: get(value, 'value') }, this.fetchData);
  }

  renderPagination() {
    const {
      page,
      data,
      total_pages,
      fetchingData,
    } = this.state;
    if (fetchingData) return null;

    if (data.length > 0) {
      return (
        <nav style={{ textAlign: 'center' }}>
          <ReactPaginate
            pageCount={total_pages}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            previousLabel="ANTERIOR"
            nextLabel="PRÓXIMA"
            onPageChange={pagination => this.changePage(pagination)}
            initialSelected={page}
            forcePage={page - 1}
            containerClassName="pagination"
            activeClassName="active"
            disabledClassName="disabled"
            disableInitialCallback
            breakLabel={<button className="disabled">...</button>}
          />
        </nav>
      );
    }

    return null;
  }

  renderAddButton() {
    return (
      <Link to="/matrizes-referencia/new">
        <AddButton icon="plus" title="Nova matriz" />
      </Link>
    );
  }

  renderToolbar() {
    const { search } = this.state;

    return (
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <label className="container-check-custom">
            <input
              type="checkbox"
              id="selecionar-todos"
              aria-label=""
              onChange={this.selecionarTodos}
            />
            <span className="checkmark" />
          </label>
          {this.renderAddButton()}
        </div>
        <div className="d-flex">
          <div className="search">
            <input
              className="search-term"
              placeholder="Pesquisar..."
              value={search}
              onChange={(event) => {
                const search = event.target.value;
                this.setState({ search });
                this.debouncedFetchData(search);
              }}
            />
          </div>
          {this.renderOrderingSelect()}
        </div>
      </div>
    );
  }

  renderList() {
    const { fetchingData, data } = this.state;

    if (fetchingData) return <Loading />;

    return data.map(matriz_referencia => (
      <ListItem
        className="bg-cinza"
        key={matriz_referencia.id}
        title={matriz_referencia.nome}
        small={matriz_referencia.descricao}
        status={{
          label: get(matriz_referencia, 'status.descricao', '-'),
          color: get(matriz_referencia, 'status.cor', '#fff'),
        }}
        description={[
          { label: 'Competências:', value: matriz_referencia.competencias },
          { label: 'Eixos:', value: matriz_referencia.eixos },
          { label: 'Habilidades:', value: matriz_referencia.ech },
        ]}
        details={false}
        actions={[
          <Link className="btn btn-white mb-3" key="0" to={`/matrizes-referencia/${matriz_referencia.id}`}>
            <i className="fas fa-edit" />
          </Link>,
          <button className="btn btn-white mb-3" key="1">
            <i className="fas fa-clone" />
          </button>,
          <button key="2" className="btn btn-white mb-3" onClick={() => this.removeMatrizReferencia(matriz_referencia.id)}>
            <i className="fas fa-times" />
          </button>,
        ]}
        atualizado_em={matriz_referencia.atualizado_em}
        versao={matriz_referencia.versao}
        criado_em={matriz_referencia.criado_em}
      />
    ));
  }

  renderBreadcrumb() {
    const currentPath = 'Matriz de Referência';
    return <NavBreadCrumb currentPath={currentPath} hasBackslash={false} />;
  }

  render() {
    return (
      <div>
        {this.renderBreadcrumb()}
        <div className="container pb-5">
          {this.renderToolbar()}
          {this.renderList()}
          {this.renderPagination()}
        </div>
      </div>
    );
  }
}

export default withRouter(ListaMatriz);
