import React from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router-dom/es/Link';

import { LinkContainer } from './styles';

function LinkButton({
  to,
  children,
  primary,
  danger,
  success,
  white,
}) {
  return (
    <LinkContainer className="btn" primary={primary} danger={danger} success={success} white={white}>
      <Link to={to} >
        {children}
      </Link>
    </LinkContainer>
  );
}

export default LinkButton;

LinkButton.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  primary: PropTypes.bool,
  danger: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
}

LinkButton.defaultProps = {
  primary: false,
  danger: false,
  success: false,
  white: false,
}
