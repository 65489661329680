import React from 'react';
import TableAvalia from './TableAvalia';

const RelatorioTurmaTable = ({ data, ...props }) => (
  <TableAvalia
    data={data}
    // pageSize={data.results.length === 0 ? 2 : 5}
    pagSize={1}
    showPagination={true}
    resizable={false}
    sortable={true}
    defaulte={"avalia-default"}
    {...props}
  />
);

export default RelatorioTurmaTable;
