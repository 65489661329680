import React from 'react';
import axios from 'axios';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { API_HOST } from '../consts';

class BaseCreate extends React.Component {
  fields = [];
  multiFields = [];
  metadataModels = [];
  state = {};

  componentDidMount() {
    this.fetchData();
  }

  get id() {
    return this.props.match.params.id;
  }

  fetchApi({ link, name, label }) {
    axios.get(`${API_HOST}${link}`).then((response) => {
      const data = response.data.results || response.data;
      this.setState({
        [name]: data.map((x) => ({
          ...x,
          value: x.id,
          label: label ? x[label] : x.descricao
        }))
      });
    });
  }

  fetchData() {
    if (this.id !== 'new') {
      axios.get(`${this.api}/${this.id}/form`).then((response) => {
        this.setFields(response.data);
        this.setState({ fetchingData: false });
      });
    } else {
      this.setState({ fetchingData: false });
    }
  }

  mapApi(x) {
    return { value: x.id, label: x.descricao };
  }

  onValidateError(error, name) {
    const status = get(error, 'response.status');

    if (status === 400) {
      const errors = get(error, 'response.data');
      Object.keys(errors).forEach((field) => {
        if (field === 'non_field_errors') {
        } else {
          try {
            this.formApi.setError(field, errors[field]);
          } catch (_) {
            // Essa exceção ocorre quando <field> não existe no form
            // Está aqui só para evitar quebrar a página caso a API retorne o que não deveria
          }
        }
      });

      this.renderErrorToast(errors, name);
    }
  }

  renderErrorToast(errors, name) {
    const label = name ? `non_field_errors.${name}` : 'non_field_errors';
    const non_field_errors = get(errors, label, []);
    delete errors.non_field_errors;
    toast.error(
      <React.Fragment>
        {Object.keys(errors).length > 0 && (
          <p>Por favor, preencha os campos marcados como obrigatórios.</p>
        )}
        <ul>
          {non_field_errors.map((e) => (
            <li key={e}>{e}</li>
          ))}
        </ul>
      </React.Fragment>
    );
  }

  getValues(data) {
    const values = {};
    this.fields.forEach((field) => {
      values[field] = get(data, field) === undefined ? null : get(data, field);
    });
    this.multiFields.forEach((field) => {
      values[field] = get(data, field, []);
    });
    return values;
  }

  setFields(data) {
    const values = {};
    this.fields.forEach((field) => {
      values[field] = get(data, field);
    });
    this.multiFields.forEach((field) => {
      values[field] = get(data, field, []);
    });
    this.formApi.setValues(values);
  }

  handleSubmitCallback(response) {}

  handleSubmit = (values) => {
    this.setState({ submitting: true });

    Object.keys(values).forEach((x) => {
      if (values[x] === '') {
        values[x] = null;
      }
    });
    if (this.id === 'new') {
      axios
        .post(`${this.api}`, values)
        .then(this.handleSubmitCallback)
        .catch(this.onValidateError);
    } else {
      axios
        .patch(`${this.api}/${this.id}`, values)
        .then(this.handleSubmitCallback)
        .catch(this.onValidateError);
    }
  };

  onValidateError = (error, name) => {
    this.setState({ submitting: false });
    const status = get(error, 'response.status');

    if (status === 400) {
      const errors = get(error, 'response.data');
      Object.keys(errors).forEach((field) => {
        if (field === 'non_field_errors') {
        } else {
          try {
            this.formApi.setError(field, errors[field]);
          } catch (_) {
            // Essa exceção ocorre quando <field> não existe no form
            // Está aqui só para evitar quebrar a página caso a API retorne o que não deveria
          }
        }
      });

      this.renderErrorToast(errors, name);
    }
  };

  handleNext = (next) => {
    this.next = next;
    this.formApi.submitForm();
  };

  mapMetadataDefult(metadata) {
    const mapped = {};
    metadata.forEach((m) => {
      mapped[`${m.model}.${m.name}`] = m.default;
    });
    return mapped;
  }

  mapMetadata(metadata) {
    const mapped = {};
    metadata.forEach((m) => {
      mapped[`${m.model}.${m.name}`] = m.requireness;
    });
    return mapped;
  }

  shouldDisplayDefault(field) {
    const { metadataDefault } = this.state;

    return get(metadataDefault, field);
  }

  shouldDisplay(field) {
    const { metadata } = this.state;
    return get(metadata, field) > 0;
  }

  fetchMetadata() {
    this.setState({ fetchingMetadata: true });
    return axios
      .get(`${API_HOST}/params/param?model=${this.metadataModels.join('|')}`)
      .then((response) => {
        this.setState({
          metadataDefault: this.mapMetadataDefult(response.data),
          metadata: this.mapMetadata(response.data),
          fetchingMetadata: false
        });
      });
  }
}

export default BaseCreate;
