import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash-es';
import { Link } from 'react-router-dom';
import Collapse from 'reactstrap/lib/Collapse';
import ListTag from '../../components/ListTag';
import ListData from '../../components/ListData';
import withUser from '../../hoc/withUser';

class ListItemDetailed extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    photo: PropTypes.string,
    description: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      })
    ),
    children: PropTypes.element,
    status: PropTypes.shape({
      label: PropTypes.string,
      color: PropTypes.string
    }).isRequired,
    onClickExpand: PropTypes.func,
    onClickGroup: PropTypes.func,
    expandAll: PropTypes.func,
    groupIcon: PropTypes.bool,
    actions: PropTypes.arrayOf(PropTypes.element),
    startCollapsed: PropTypes.bool,
    atualizado_em: PropTypes.string,
    versao: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  };

  static defaultProps = {
    title: '',
    photo: '',
    description: [],
    children: null,
    onClickExpand: () => {},
    onClickGroup: () => {},
    expandAll: () => {},
    groupIcon: false,
    actions: [],
    startCollapsed: true,
    atualizado_em: '',
    versao: ''
  };

  constructor(props) {
    super(props);
    this.onClickExpand = this.onClickExpand.bind(this);
    this.onClickGroup = this.onClickGroup.bind(this);
    this.viewMore = this.viewMore.bind(this);
    this.state = {
      collapseGroup: false,
      collapse: false,
      trimmedContent: 'collapse-conteudo',
      iconToggle: 'down'
    };
  }

  onClickExpand() {
    const { collapse } = this.state;
    this.setState(
      {
        collapse: !collapse
      },
      () => {
        setTimeout(this.props.onClickExpand, 500);
      }
    );
  }

  onClickGroup() {
    this.setState({
      collapseGroup: !this.state.collapseGroup,
      collapse: false
    });
  }

  get viewRevisoes() {
    const permission = get(this.props, 'permissions') || {};
    return permission.__all__ || permission['revisoes.view_revisao'];
  }

  viewMore() {
    if (this.state.trimmedContent === '') {
      this.setState({
        trimmedContent: 'collapse-conteudo',
        iconToggle: 'down'
      });
    } else {
      this.setState({
        trimmedContent: '',
        iconToggle: 'up'
      });
    }
  }

  renderImage() {
    if (this.props.photo) {
      return (
        <img
          className="image"
          alt={this.props.title}
          src={this.props.photo}
          width="100"
          height="100"
        />
      );
    }

    return null;
  }

  render() {
    const {
      description,
      title,
      small,
      status,
      vertical,
      actions,
      details,
      children,
      atualizado_em,
      versao,
      startCollapsed,
      id
    } = this.props;
    const { collapse, trimmedContent, iconToggle } = this.state;

    return (
      <div className="item-section">
        <div className="container">
          <div className="row">
            <div className="list-item-detail col-lg-1 col-md-1 col-sm-2 col-xs-2 border-0">
              {this.renderImage()}
              <label className="container-check-custom">
                <input type="checkbox" aria-label="" id={`checkbox-${id}`} />
                <span className="checkmark" />
              </label>
            </div>
            <div className="col-lg-7 col-md-5 col-sm-10 col-xs-10">
              <ListData
                vertical={vertical}
                description={description}
                title={title}
                small={small}
                atualizado_em={atualizado_em}
                versao={versao}
                item_id={id}
              />
            </div>
            <div
              style={{ paddingLeft: 50, marginLeft: 40 }}
              className="col-lg-4 col-md-6 col-xs-12 col-sm-12"
            >
              <div className="d-flex align-items-center justify-content-end">
                {this.viewRevisoes && (
                  <Link
                    to={`/revisoes?item=${id}`}
                    className="btn btn-white mr-2 p-2"
                  >
                    REVISÕES
                  </Link>
                )}
                <ListTag
                  onClickExpand={this.onClickExpand}
                  details={details}
                  actions={actions}
                  groupIcon={this.props.groupIcon}
                  onClickGroup={this.onClickGroup}
                  {...status}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card-item card-alone">
          <Collapse
            className={`collapse-itens ${trimmedContent}`}
            isOpen={collapse || startCollapsed}
          >
            <div className="hidden-content mt-5 position-relative">
              <button
                className="position-absolute btn btn-white mb-3 btn-trimmed"
                onClick={this.viewMore}
                style={{ right: 0, zIndex: 0 }}
              >
                <i className={`fas fa-chevron-${iconToggle}`} />
              </button>
              {children}
            </div>
          </Collapse>
        </div>
      </div>
    );
  }
}

export default withUser(ListItemDetailed);
