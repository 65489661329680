import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NavBreadCrumb from '../../containers/NavBreadCrumb';
import Search from '../../components/Search';
import BtnIcon from '../../components/buttons/BtnIcon';

class ListaGrupos extends Component {
  render() {
    return (
      <div className="form-configuracoes">
        <NavBreadCrumb currentPath="Lista de Grupos"/>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card toolbar">
                <div className="card-header">
                  <div className="row">
                    <div className="col-6"><h1>Lista de Grupos</h1></div>
                    <div className="col-4">
                      <Search />
                    </div>
                    <div className="col-2 display-flex">
                      <Link to="/configuracoes/grupo/0" className="btn primary-button">+ Adicionar Grupo</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body card-body-form">
                  <table className="table table-configuracoes">
                    <thead>
                      <tr>
                        <th>Grupos</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Elaborador</td>
                        <td>
                          <Link to="/configuracoes/grupo/1">
                            <BtnIcon col="pull-right btn-white" icon="edit" type="fas"/>
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>Admin</td>
                        <td>
                          <Link to="/configuracoes/grupo/2">
                            <BtnIcon col="pull-right btn-white" icon="edit" type="fas"/>
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ListaGrupos;
