import React from 'react';
import { Breadcrumb } from 'reactstrap';
import BreadCrumbIcon from '../components/BreadCrumbIcon.js';
import BreadCrumbActive from '../components/BreadCrumbActive.js';

class NavBreadCrumb extends React.Component {
  static defaultProps = {
    pathList: [],
    hasBackslash: true,
  };

  render() {
    const { pathList, hasBackslash, currentPath } = this.props;
    return (
      <div className="container">
        <Breadcrumb className="bg-breadcrumb pl-0">
          {
            pathList.map(path => <BreadCrumbIcon key={path.link} {...path} />)
          }
          {currentPath &&
          <BreadCrumbActive label={currentPath} hasBackslash={hasBackslash} />

          }
        </Breadcrumb>
      </div>
    );
  }
}

export default NavBreadCrumb;
