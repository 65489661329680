export const Edital = {
  dados: {
    nr_edital: '',
    ds_edital: '',
    modalidade: '',
    grupo: '',
    objetivos: '',
    recursos_financeiros: '',
    total_vagas: '',
    dt_vigencia_inicio: '',
    dt_vigencia_termino: '',
    dt_ativacao_web_inicio: '',
    dt_ativacao_web_termino: '',
    dt_liberacao_resultado_inicio: '',
    dt_liberacao_resultado_termino: '',
    nr_minimo_candidatos: '',
  },
  versoes: [{
    ds_versao: '',
    codigo: '',
    nr_versao: '',
    data: '',
    arquivo: null,
  }],
  vagas: [{
    nivel_escolaridade: '',
    area_conhecimento: '',
    nr_vagas: '',
  }],
  criterios_avaliacao: [{
    criterio_avaliacao: '',
    minimo: '',
    maximo: '',
  }],
  documentos: [{
    documento: '',
    quantidade: '',
    classificacao: '',
  }],
  qualificacoes: [{
    ds_qualificacao: '',
    pontos: '',
    minimo: '',
    maximo: '',
    comprovacao: '',
    classificacao: '',
  }],
  instrumentos_cognitivos: [{
    instrumento: '',
  }],
  instrumentos_nao_cognitivos: [{
    instrumento: '',
  }],
  atividades: [{
    ds_atividade: '',
    dt_entrega: '',
  }],
  produtos: [{
    ds_produto: '',
    dt_entrega: '',
  }],
  dotacoes_orcamentarias: [{
    ds_dotacao_orcamentaria: '',
    quantidade: '',
  }],
  tramites: [{
    tramite: '',
    setor: '',
    dt_vigencia_edital_inicio: '',
    dt_vigencia_edital_termino: '',
  }],
};


export const PreTeste = {
  dados: {
    ds_pre_teste: '',
    total_itens: '',
    itens_caderno: '',
    porcentagem_itens_calibrados: '',
    numero_blocos: '',
    numero_respostas: '',
    areas_conhecimento: [],
  },
  selecaoHabilidades: {
    matrizes_referencia: [],
  },
  selecaoItens: {
    id_matriz: {
      id_item: 'bloco',
    },
  },

};

export const Elaborador = {
  dados: {
    nome: '',
    cpf: '',
    cor: '',
    curriculoLattes: '',
    pais: '',
    estado: '',
    cidade: '',
    data_nascimento: '',
  },
  enderecos: [{
    cep: '',
    rua: '',
    numero: '',
    bairro: '',
    cidade: '',
    uf: '',
    tipo_endereco: '',
  }],
  emails: [{
    numero: '',
    tipo_numero: '',
  }],
  telefones: [{
    numero: '',
    tipo_telefone: '',
  }],
  dados_bancarios: [{
    banco: '',
    agencia: '',
    operacao: '',
    contaDigito: '',
  }],
  redes_sociais: {
    facebook: '',
    instagram: '',
    linkedin: '',
    outro: '',
  },
  formacoes_academicas: [{
    nivel_escolaridade: '',
    status: '',
    instituicao: '',
    inicio: '',
    conclusao: '',
  }],
  atuacao_profissional: {
    instituicao: '',
    cargo: '',
    tipo_vinculo: '',
    pais_instituicao: '',
    area_atuacao: '',
    atuando_no_momento: '',
  },
  documentos_digitalizados: [],
  habilitacoes: [{
    nr_edital: '',
    area_conhecimento: '',
    disciplina: '',
    perfil: '',
    status_habilitacao: '',
  }],
  formacoes_complementares: [{
    nivel_escolaridade: '',
    status_formacao_academica: '',
    instituicao: '',
    curso: '',
    carga_horaria: '',
  }],
  pessoaJuridica: '',
};

export const MatrizReferencia = {
  cards: {
    eixos: [],
    competencias: [],
    habilidades: [],
  },
  table: {
    eixos: [],
    competencias: [],
    habilidades: {},
  },
  habilidadeModal: {
    isOpen: false,
    competencia: {
      value: '',
      label: '',
    },
    eixo: {
      value: '',
      label: '',
    },
    habilidade: {
      value: '',
      label: '',
    },
  },
  nome_matriz_referencia: '',
  ds_matriz_referencia: '',
  loading: false,
};

export const Item = {
  dados: {
    encomenda: '',
    instituicao: '',
    curso: '',
    periodo: '',
    disciplina: '',
    tipo_item: '',
    assunto: '',
  },
  listaReferencia: [],
  listaCompetencia: [],
  competencias_habilidades: [{
    competencia: '',
    habilidade: '',
  }],
  fonte: {
    instrumento: '',
    autor: '',
    ano: '',
    bl_autor: {
      value: 1,
      label: 'Sou autor do item',
    },
    bl_publico: 0,
    dificuldade: '',
    palavras_chave: '',
  },
  referencias_bibliograficas: [{
    titulo: '',
    autor: '',
    capitulo: '',
    pagina_inicial: '',
    pagina_final: '',
    link: '',
  }],
  conteudo: {
    situacao_problema: '',
    comando_resposta: '',
    conteudo_relacionado: '',
  },
  alternativas: [{
    alternativa: '',
    justificativa: '',
    eixo: '',
  }],
  matrizes_referencia: [
    undefined,
  ],
};

export const RevisaoItem = {
  item: undefined,
  id_status_item: '',
  bl_notificar: 0,
  comentario: '',
};

export const Avaliacao = {
  dados: {
    ds_avaliacao: '',
    instituicao: '',
    nota: '',
    tipo_avaliacao: '',
    tipos_item: [],
    instrucoes: '',
  },
  avaliacao_itens: [],
  matrizes_referencia: [
    undefined,
  ],
  selecionarItemModalIsOpen: false,
};

export const InstrumentoNaoCognitivo = {
  descricao: '',
  instrucoes: '',
  itens: [
    {
      tipo: {
        value: 'SHORT',
        label: 'Texto curto',
      },
      enunciado: '',
      alternativas: [
        '',
      ],
      min: undefined,
      max: undefined,
      start_label: undefined,
      finish_label: undefined,
      resposta: '',
    },
  ],
  matrizes_referencia: [
    undefined,
  ],
};

export const AdvancedItemSearch = {
  id_curso: '',
  id_periodo: '',
  id_disciplina: '',
  id_pessoa_fisica: '',
  id_tipo_prova: '',
  fonte__id_dificuldade_presumida: '',
  id_assunto: '',
  id_area_conhecimento: '',
  id_item_tipo: '',
  nr_aula: '',
  requisitos_item__id_ordem_producao: '',
  requisitos_item__id_instrumento: '',
  id_item: '',
  enunciado_txt: '',
  id_estado_revisao: '',
};

export const Recurso = {
  recurso: {
    data_recurso: '',
    dt_limite_resposta: '',
    nome_candidato: '',
    telefone_contato: '',
    estado_recurso: '',
    item: '',
    justificativa_bibliografia: '',
    mensagem_elaborador: '',
  },
};

export const EncomendarItem = {
  dados: {
    nome_os: '',
  },
  matriz_curricular: [{
    disciplina: '',
    assunto: '',
    quantidade: '',
  }],
};

export const AnaliseClassica = {
  analiseItem: {
    item: {},
  },
};

export const Teste = {
  dados: {
    descricao: '',
    instrumento: undefined,
    curso: undefined,
    periodo: undefined,
    disciplina: undefined,
    data: undefined,
    hora: undefined,
    duracao: undefined,
  },
};
