import React from 'react';
import Highcharts from 'highcharts';
import Loading from './Loading';

class Histograma extends React.Component {
  state = {
    loading: true
  };

  minTick = -4;
  maxTick = 4;
  tickInterval = 0.5;

  componentDidUpdate(prevProps) {
    const { histograma } = this.props;
    if (prevProps.histograma !== histograma) {
      this.createChart();
      this.setState({ loadingChart: false });
    }
  }

  componentDidMount() {
    const { histograma } = this.props;
    if (histograma) {
      this.createChart();
    }
  }

  nthTick(n) {
    return this.minTick + this.tickInterval * n;
  }

  get numberOfTicks() {
    return (
      (Math.abs(this.minTick) + Math.abs(this.maxTick)) / this.tickInterval + 1
    );
  }

  get ticks() {
    const t = {};
    [...Array(this.numberOfTicks)].forEach((_, i) => {
      const nthTick = this.nthTick(i);
      t[nthTick.toFixed(1).toString()] = i;
    });
    return t;
  }

  get title() {
    const { title } = this.props;

    if (!title) {
      return null;
    }

    return {
      text: ' ',
      style: {
        color: '#333333',
        fontSize: '14px',
        margin: '10px 0 10px 0'
      }
    };
  }

  breakLegendLine(name) {
    if (name.replace(' - Respondentes:', ' ').length < 80) {
      return name;
    }

    return name.replace(' - Respondentes:', '<br /> Respondentes: ');
  }

  tickLabelFormatter = (value) => {
    const { escalaProficiencia, showItemsOnTick } = this.props;

    if (showItemsOnTick) {
      const tickIndex = value.toFixed(1).toString();
      const tickItems = escalaProficiencia.conteudo[this.ticks[tickIndex]].map(
        (item) => item.item
      );
      return `<span style="margin-left: 2px; text-align: center">${Highcharts.numberFormat(
        value,
        1
      )}<br/><br/>${tickItems.join('<br>')}</span>`;
    }
    return `<span style="margin-left: 2px; text-align: center">${Highcharts.numberFormat(
      value,
      1
    )}</span>`;
  };

  createChart() {
    let { histograma, colors } = this.props;
    const tickLabelFormatter = this.tickLabelFormatter;
    const breakLegendLine = this.breakLegendLine;

    if (!Array.isArray(histograma)) {
      histograma = [histograma];
    }

    Highcharts.setOptions({
      colors,
      lang: {
        decimalPoint: ',',
        thousandsSep: ','
      }
    });

    Highcharts.chart(this.props.id, {
      chart: {
        type: 'column',
        ignoreHiddenSeries: false,
        borderRadius: 5,
        colors: this.props.colors,
        width: 900
      },
      title: this.title,
      xAxis: {
        gridLineWidth: 0,
        tickInterval: 0.5,
        labels: {
          useHTML: true,
          formatter: function () {
            return tickLabelFormatter(this.value);
          }
        }
      },
      yAxis: {
        title: {
          text: 'Respondentes'
        },
        min: 0,
        max: 50,
        tickInterval: 5,
        labels: {
          format: '{value}%'
        }
      },
      plotOptions: {
        column: {
          colors,
          grouping: false,
          shadow: false
        }
      },
      series: histograma.map((d) => ({
        data: d.data,
        name: `${d.instituicao} - ${
          d.curso
        } - Respondentes: ${d.nr_respondentes.toLocaleString('pt')}`,
        type: 'column',
        pointPadding: 0,
        groupPadding: 0
      })),
      legend: {
        labelFormatter: function () {
          return breakLegendLine(this.name);
        },
        itemStyle: {
          color: 'var(--cinza)'
        }
      },
      tooltip: {
        formatter: function () {
          return `Respondentes: ${Highcharts.numberFormat(this.y, 2)}%`;
        }
      }
    });

    this.setState({ loading: false });
  }

  render() {
    const { loading } = this.state;

    return (
      <React.Fragment>
        <p className="azul p-4">
          <b>Histograma de distribuição de proficiência</b>
        </p>
        {loading && <Loading />}
        <div id={this.props.id} />
      </React.Fragment>
    );
  }
}

Histograma.defaultProps = {
  id: 'histograma',
  showItemsOnTick: true,
  title: true,
  colors: [
    '#BEDAF6',
    '#86BA93',
    '#70C6CA',
    '#F9DC66',
    '#ED943B',
    '#0DA035',
    '#E6F06D',
    '#FF9400',
    '#EF6666',
    '#5A5A5A'
  ]
};

export default Histograma;
