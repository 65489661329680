import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const FooterAvalia = styled.footer`
  background: #3C4583;
  color: #fff;
  padding: 35px 0 0px 0;
  font-weight: 400;
  font-size: 15px;

  p {
    color: #fff;
  }

  ul{
    li{
      margin-right: 15px;
      &:first-child{
        padding-right: 20px;
        border-right: 1px solid #fff;
      }
      a{
        color: #fff;
        &:hover{
          opacity: 0.7;
          text-decoration: none;
        }
      }
      img{
        padding-left: 20px;
        margin-bottom: 5px;
      }
    }
  }

  @media screen and (max-width: 980px){
    .footer{
      text-align: center;
      .container{
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
      ul{
        border-top: 1px solid #fff;
        padding-top:5px;
        text-align: center;
        float: none;
        display: block;
        li{
          display: block !important;
          margin-right: 0px !important;
          &:first-child{
            padding-right: 0px !important;
            border-right: none;
          }
          a{
            color: #fff;
            &:hover{

            }
          }
          img{
            margin-top:15px;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
`;
