import React from 'react';
import { asField } from 'informed';
import classnames from 'classnames';
import MaskedInput from 'react-text-mask';

export default asField(({
  fieldState,
  fieldApi,
  onChange,
  field,
  label,
  helpBlock,
  col,
  forwardedRef,
  required,
  mask_,
  noLabel,
  ...props
}) => (
  <div className={classnames('text-input-form-state form-group', col, { required, 'has-error': fieldState.error })}>
    {!noLabel && <label id={field} htmlFor={field}>{label}</label>}
    <MaskedInput
      mask={mask_}
      className={classnames('form-control', { 'has-error': fieldState.error })}
      value={fieldState.value || ''}
      onChange={e => {
        fieldApi.setValue(e.target.value);
        fieldApi.setError(null);
        if (onChange) {
          onChange(e.target.value);
        }
      }}
      {...props}
    />
    {helpBlock && <small className="form-text text-muted">{helpBlock}</small>}
    {fieldState.error && <small className={fieldState.error ? 'text-danger' : null}>{fieldState.error}</small>}
  </div>
));
