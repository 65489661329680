import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import HeaderAvaliaEscola from '../../components/HeaderAvaliaEscola';
import Axios from 'axios';
import { get } from 'lodash';
import { API_HOST } from '../../consts';
import { useEffect } from 'react';
import RelatorioTurmaColumn from '../../components/RelatorioTurmaColumn';
import HeaderRelatorio from '../../components/HeaderRelatorio';

const ClassReportPage = ({ match, className }) => {
  const [relatorioTurma, setRelatorioTurma] = useState();
  const [loading, setLoading] = useState(false);
  const titulo = [
    'Linguagens, Códigos e suas Tecnologias',
    'Matemática e suas Tecnologias'
  ];
  const [etapaData, setEtapaData] = useState();

  const fetchEtapaData = (turma) =>
    Axios.get(`${API_HOST}/avalia/etapa/${turma.NR_ETAPA}`)
      .then((res) => {
        setEtapaData(res.data);
      })
      .catch((err) => console.error(err));

  const fetchrelatorioTurma = () => {
    Axios.get(
      `${API_HOST}/avalia/boletim_mt?CD_ESCOLA=${match.params.id}&NR_ETAPA=${match.params.etapaId}&CD_DISCIPLINA=${match.params.disciplinaId}`
    )
      .then((res) => {
        setRelatorioTurma(res.data.results);
        fetchEtapaData(res.data.results[0]);
        setLoading(true);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    fetchrelatorioTurma();
  }, []);

  return (
    <div className={className}>
      <div style={{ marginTop: '3rem', background: '#fff' }}>
        <div className="container mb-4 pb-4">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h1>Relatório de nota e proficiência dos alunos</h1>
                  <hr />
                </div>
                <div
                  className="card-body"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                >
                  <HeaderRelatorio
                    schoolName={
                      relatorioTurma ? get(relatorioTurma[0], 'NM_ESCOLA') : ''
                    }
                    schoolYear={relatorioTurma ? get(etapaData, 'etapa') : ''}
                    dreAndMunicipio={
                      relatorioTurma ? get(relatorioTurma[0], 'NM_DRE') : ''
                    }
                    disableStudent={false}
                  />
                  <div style={{ marginTop: '2rem' }}>
                    <h1
                      style={{
                        fontWeight: 'bold',
                        color: `${(props) => props.theme.primary}`
                      }}
                    >
                      {
                        titulo[
                          relatorioTurma
                            ? get(relatorioTurma[0], 'CD_DISCIPLINA') - 1
                            : ''
                        ]
                      }
                    </h1>
                  </div>
                  <RelatorioTurmaColumn relatorios={relatorioTurma} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ClassReportPage);
