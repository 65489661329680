import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import { Control } from 'react-redux-form';

export default class InputTextarea extends React.Component {
  static propTypes = {
    col: PropTypes.string,
    label: PropTypes.string.isRequired,
    model: PropTypes.string.isRequired,
  };

  static defaultProps = {
    col: 'col-sm-12',
  };

  render() {
    const { col, label, model } = this.props;
    return (
      <FormGroup className={col}>
        <label>{label}</label>
        <Control.textarea
          model={model}
          className="form-control"
          updateOn="blur"
        />
      </FormGroup>
    );
  }
};
