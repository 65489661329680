import React from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { API_HOST } from '../consts';
import Logo from '../assets/images/logos/Logos';
import { ThemeContext } from '../contexts/ThemeContext';

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: '', submit: true };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submit: false });

    const data = new FormData();
    data.append('email', this.state.value);
    axios.post(`${API_HOST}/password-reset/`, data)
      .then(() => {
        const { history } = this.props;
        toast.success('Um email contendo os passos para a redefinição de senha foi enviado.');
        history.push('/login');
      })
      .catch((error) => {
        const isBadRequest = error.response && error.response.status === 400;
        const { history } = this.props;
        if (isBadRequest) {
          toast.error(error.response.data.email[0]);
        } else {
          toast.error('Ocorreu um erro e não foi possível enviar o email no momento.');
          history.push('/login');
        }

        this.setState({ submit: true });
      });
  }

  render() {
    const { submit } = this.state;
    return (
      <section className="login-bg">
        <div className="container">
          <div className="box-form text-center">
            <figure>
              <ThemeContext.Consumer>
                {theme => (
                  <img src={theme.logoImg || Logo.client || Logo.main} className="logo-login" alt="logo-fgv" title="2.0" />
                )}
              </ThemeContext.Consumer>
            </figure>
            <div className="group-input mt-1">
              <h6>Insira seu email e enviaremos um link para redefinir sua senha</h6>
              <form onSubmit={this.handleSubmit}>
                <input className="form-control login-mail" type="text" value={this.state.value} onChange={this.handleChange} placeholder="Email" />
                <div className="action-login mt-3">
                  <button
                    className="btn btn-padrao bg-padrao"
                    type="submit"
                    disabled={this.state.value.length === 0 || submit === false}
                  >
                    {submit ? 'REDEFINIR SENHA' : 'ENVIANDO...' }
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(ForgotPassword);
