import styled from 'styled-components';

function getBackgroundColor(props) {
  switch (props.backgroundType) {
    case 'primary':
      return `
        background: ${props.theme.button};
        &:hover,
        &:active {
          background: ${props.theme.buttonHover};
          color: #fff;
        }
      `;
    case 'light':
      return `
        background: ${props.theme.buttonLight};
        &:hover,
        &:active {
          background: ${props.theme.buttonLightHover};
          color: #fff;
        }
      `;
    case 'secondary':
      return `
        background: ${props.theme.buttonSecondary};
        &:hover,
        &:active {
          background: ${props.theme.buttonSecondaryHover};
          color: #fff;
        }
      `;
    default:
      return `
        background: ${props.theme.button};
        &:hover,
        &:active {
          background: ${props.theme.buttonHover};
          color: #fff;
        }
      `;
  }
}

// eslint-disable-next-line import/prefer-default-export
export const AddButton = styled.button`
  color: #fff;
  font-size: 12px;
  border: none;

  ${props => getBackgroundColor(props)};
`;
