import React, { Component } from 'react';
import axios from 'axios';
import { API_HOST } from '../../consts';
import StackedBarChart from '../../components/StackedAreaChart';
import ParticipacaoDreColumn from '../../components/ParticipacaoDreColumn';
import HeaderAvalia from '../../components/HeaderAvalia';
import { get } from 'lodash';
import { ThemeContext } from '../../contexts/ThemeContext';
import HeaderRelatorio from '../../components/HeaderRelatorio';

class ReportStudentAnalysis extends Component {
  state = {
    loading: false,
    boletimData: {}
  };

  componentDidMount() {
    this.fetchBoletimData();
  }
  componentWillUnmount() {
    this.state.loading = true;
  }

  fetchBoletimData = () => {
    const avaliaPk = this.props.match.params.id;

    axios
      .get(`${API_HOST}/avalia/boletim_mt/${avaliaPk}`)
      .then((res) => {
        this.setState({ boletimData: res.data, loading: true });
      })
      .catch((err) => console.error(err));
  };

  render() {
    const { boletimData, loading } = this.state;

    return (
      <>
        <div style={{ marginTop: '3rem', background: '#fff' }}>
          <div className="container mb-4 pb-4">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Diagnóstico do Aluno</h1>
                    <hr />
                  </div>
                  <div
                    className="card-body"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}
                  >
                    <HeaderRelatorio
                      schoolName={get(boletimData, 'NM_ESCOLA')}
                      schoolYear={get(boletimData, 'NM_ETAPA', '')}
                      dreAndMunicipio={get(boletimData, 'NM_DRE')}
                      student={get(boletimData, 'NM_ALUNO', '')}
                    />
                    {loading && (
                      <StackedBarChart
                        dataDot={boletimData}
                        pontos={true}
                        useBase={false}
                      />
                    )}
                    <ParticipacaoDreColumn boletimaluno={boletimData} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ReportStudentAnalysis;
