import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';

export default class RemoverEtapaModal extends React.Component {
  constructor(props) {
    super(props);
  }

  static propTypes = {
    openModal: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    closeModal: PropTypes.func
  };

  render() {
    const { closeModal, toggle, openModal } = this.props;
    return (
      <Modal size="lg" isOpen={openModal}>
        <ModalHeader>Remover Etapa de Revisão</ModalHeader>
        <ModalBody className="modal-filtros" style={{ padding: '1rem' }}>
          <strong>
            Você tem certeza que deseja remover esta etapa de revisão?
          </strong>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn danger-button mr-3"
            type="button"
            onClick={closeModal}
          >
            Cancelar
          </button>
          <button
            className="btn primary-button mr-3"
            type="button"
            onClick={toggle}
          >
            Aceitar
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}
