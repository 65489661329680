const inputHasImage = (htmlText) => {
  let img_regexp = new RegExp('\\<img')
  let matches_array = htmlText.match(img_regexp);
  if(matches_array){
    return true
  }
  return false
}

const addFontTagsToText = (text, font, fontSize) => {
  return '<font face="'+ font +'"><span style="font-size:'+ fontSize +'px;">'+ text.trim() +'</span></font>'
}

export const standardizeClipboardData = (clipboardData, font, fontSize) => {
  let clipboardHtml = clipboardData.getData('text/html');
  if(inputHasImage(clipboardHtml)){
    return clipboardHtml
  } else {
    let clipboardText = clipboardData.getData('Text');
    return addFontTagsToText(clipboardText, font, fontSize)
  }

}
