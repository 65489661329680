import React from 'react';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import ExcelExporter from '../utils/excel';


class AnaliseReportExportMenu extends React.Component {
    handleExportExcel = () => {
      const { dataToExport, colorMap } = this.props;
      const excelExporter = new ExcelExporter();
      excelExporter.export(dataToExport, 'itensReport', [], colorMap);
    }

    render() {
      const { componentToPrint } = this.props;
      return (
        <div className="no-print">
          <UncontrolledTooltip delay={0} target="export-excel">
              Exportar para Excel
          </UncontrolledTooltip>
          <button
            id="export-excel"
            type="button"
            className="btn btn-white mb-3"
            onClick={() => this.handleExportExcel(null)}
          >
            <i className="fas fa-file-export" />
          </button>
          <ReactToPrint content={() => componentToPrint.current}>
            <PrintContextConsumer>
              {({ handlePrint }) => (
                <React.Fragment>
                  <UncontrolledTooltip delay={0} target="export-print">
                      Imprimir
                  </UncontrolledTooltip>
                  <button
                    id="export-print"
                    type="button"
                    className="btn btn-white mb-3"
                    onClick={handlePrint}
                  >
                    <i className="fas fa-print" />
                  </button>
                </React.Fragment>
                )}
            </PrintContextConsumer>
          </ReactToPrint>
        </div>
      );
    }
}

AnaliseReportExportMenu.propTypes = {
  componentToPrint: PropTypes.object,
  dataToExport: PropTypes.array,
  colorMap: PropTypes.array,
};

AnaliseReportExportMenu.defautProps = {
  componentToPrint: {},
  dataToExport: [],
  colorMap: [],
};


export default AnaliseReportExportMenu;
