import React from 'react';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import InputMask from './InputMask';

const numberMask = createNumberMask({
  prefix: 'R$ ',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: true,
  decimalSymbol: ',',
  requireDecimal: true,
})

export default props => <InputMask {...props} mask_={numberMask} />;
