import React from 'react';
import { Form, Scope } from 'informed';
import axios from 'axios';
import { map } from 'lodash';
import { API_HOST } from '../../consts';
import { default as FormContainer } from '../../containers/Form';
import Loading from '../../components/Loading';
import { InputText } from '../../components/inputs';
import BaseCreate from '../../base/BaseCreate';

class Cadernos extends BaseCreate {
  state = {
    matrizes_referencia: [],
    markedForDeletion: [],
    fetchingData: true,
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    axios.get(`${API_HOST}/testes/caderno`, {
      params: {
        teste: this.id,
      }
    })
      .then((response) => {
        this.setState({ fetchingData: false });
        this.formApi.setValues({ cadernos: response.data });
      })
  }

  handleSubmit = (values) => {
    this.setState({ submitting: true });

    values.cadernos.forEach((caderno) => {
      caderno.teste = this.id;
    })

    axios.put(`${API_HOST}/testes/caderno`, values.cadernos)
      .then(() => {
        const { history } = this.props;
        history.push(this.next);
      })
      .catch(this.onValidateError)
  }

  handleNext = (next) => {
    this.next = next;
    this.handleSubmit(this.formApi.getState().values);
  }

  render() {
    const {
      fetchingData,
      submitting,
    } = this.state;

    return (
      <Form
        onChange={formState => this.setState({ formState })}
        getApi={(formApi) => { this.formApi = formApi; }}
        initialValues={{ cadernos: [] }}
      >
      {({ formState }) => {
        if (fetchingData) {
          return <Loading />;
        }

        return (
          <FormContainer
            title="Cadernos"
            previous="dados"
            next="blocos"
            onClick={this.handleNext}
            disabled={submitting}
          >
            {map(formState.values.cadernos, (c, i) => (
              <Scope scope={`cadernos[${i}]`} key={c.id}>
                <div className="row">
                  <div className="col-sm-2">
                    <label className="form-group m-0">Blocos</label>
                    <p className="mt-2 pt-1 azulP"><b>{c.blocos.join(', ')}</b></p>
                  </div>
                  <InputText
                    col="col-sm-10"
                    label="Nome"
                    field="nome"
                  />
                </div>
                <hr />
              </Scope>
            ))}
          </FormContainer>
        );
      }}
      </Form>
    );
  }
}

export default Cadernos;
