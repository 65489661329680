import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { map } from 'lodash-es';
import Video from './Video';

class FileList extends React.Component {
  renderFile(file) {
    const { preview } = this.props;
    if (!preview) {
      return null;
    }
    return <Video src={file.src} type={file.content_type} />;
  }

  render() {
    const { files, preview, onDelete, timestamp } = this.props;

    return map(files, file => (
      <div className={classnames('col-md-12', { 'mb-4': preview })}>
        <p className="mb-2">
          <strong>
            <a href={file.src} download>{file.name}</a>
          </strong>
          {timestamp && <small> Submetido em {file.criado_em}</small>}
          {!preview && onDelete && (
            <button type="button" className="ml-2" onClick={() => onDelete(file)}>
              <i className="fas fa-times" />
            </button>
          )}
        </p>
        {preview && onDelete && (
          <button type="button" className="btn btn-outline pull-right" onClick={() => onDelete(file)}>
            <i className="fas fa-times" />
          </button>
        )}
        {this.renderFile(file)}
      </div>
    ));
  }
}

FileList.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({
    src: PropTypes.string,
    name: PropTypes.string,
    criado_em: PropTypes.string,
  })),
  preview: PropTypes.bool,
  timestamp: PropTypes.bool,
  onDelete: PropTypes.func,
};

FileList.defaultProps = {
  files: [],
  preview: true,
  onDelete: null,
  timestamp: true,
};

export default FileList;
