import React from 'react';
import { withRouter } from 'react-router-dom';
import FormContainer from '../../containers/Form';
import { BaseForm } from '../../base';
import ItemPendencias from '../../components/ItemPendencias';

class Pendencias extends BaseForm {
  render() {
    return (
      <FormContainer
        title="Verificação automática do item"
        previous="fonte"
        next="confirmar"
      >
        <ItemPendencias />
      </FormContainer>
    );
  }
}


export default withRouter(Pendencias);
