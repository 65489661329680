import React, { useState } from 'react';
import axios from 'axios';
import { API_HOST } from '../consts';
import { toast } from 'react-toastify';
import ModalResolvedComentario from './ModalResolvedComentario';
import ModalEditComentario from './ModalEditComentario';

const ItemComentario = ({ comentario, avaliacaoItem, api }) => {
	const [isModalResolvidoOpen, setIsModalResolvidoOpen] = useState(false);
	const [isModalEditOpen, setIsModalEditOpen] = useState(false);

	const handleModalResolvido = (value) => {
		setIsModalResolvidoOpen(value);
	}

	const handleModalEdit = (value) => {
		setIsModalEditOpen(value);
	}

	const handleComentarioResolvido = () => {
		const params = { resolvido: !comentario.resolvido }

		axios.patch(`${API_HOST}/avaliacoes/avaliacao_item_comentarios/${comentario.id}/alterar_resolvido`, params)
			.then(() => {
				handleModalResolvido(false);

				return axios
					.get(`${API_HOST}/avaliacoes/avaliacao_item`, {
						params: {
							avaliacao: avaliacaoItem.avaliacao.id
						}
					}).then((response) => {

						const itens = response.data.results || response.data

						api.setValues({
							itens
						})
						toast.success('Status do comentário alterado com sucesso!')
					})
			})
	}

	const handleComentarioText = (text) => {
		if (text.length > 60) {
			return text.substring(0, 60) + '...';
		}
		return text;
	};

	return (
		<div className="ml-2 mb-2 card rounded" style={{ width: '100%' }}>
			<div className='card-header d-flex justify-content-between align-items-end pb-1'>
				<div>
					<p className="card-subtitle text-muted p-0" style={{ paddingRight: 20, paddingLeft: 20 }}>{`Comentário #${comentario.id}`}</p>
				</div>
				<div className="d-flex align-items-end p-1">
					<button
						className="btn p-1"
						onClick={() => handleModalEdit(true)} // TODO: implementar edição de comentário
					>
						<i className="far fa-edit" />
					</button>
					<button
						className="btn p-1"
						onClick={() => handleModalResolvido(true)}
					>
						<i className={comentario.resolvido ? "far fa-check-square" : "far fa-square"}></i>
					</button>
				</div>
			</div>
			<p
				className="card-subtitle text-muted"
				style={{ paddingRight: 20, paddingLeft: 20, marginBottom: 4 }}>
				Status:
				<span style={{
					marginLeft: 5,
					marginBottom: 0,
					fontSize: 12,
					border: '1px solid #ccc',
					borderRadius: 5,
					padding: 3,
					color: comentario.resolvido ? '#4CAF50' : '#aca408',
					backgroundColor: comentario.resolvido ? '#bddbbe' : '#fdf4d9'
				}}>{comentario.resolvido ? 'Resolvido' : 'Pendente'}
				</span></p>
			<div>
				<p className="card-title" style={{ paddingLeft: 20, marginBottom: 0, fontSize: 12 }}>{`Criado por: ${comentario.criado_por}`}</p>
				<p className="card-title" style={{ paddingLeft: 20, marginBottom: 0, fontSize: 12 }}>{`Atualizado por: ${comentario.atualizado_por}`}</p>
			</div>
			<div className="card-body pt-3">
				<p className="card-text">{handleComentarioText(comentario.comentario)}</p>
			</div>
			<ModalResolvedComentario isModalOpen={isModalResolvidoOpen} handleModal={handleModalResolvido} handleComentarioResolvido={handleComentarioResolvido} resolvido={comentario.resolvido} />
			<ModalEditComentario isModalOpen={isModalEditOpen} handleModal={handleModalEdit} comentario={comentario} avaliacaoItem={avaliacaoItem} api={api} />
		</div>
	);
}

export default ItemComentario;
