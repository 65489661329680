import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import Loading from '../components/Loading';

class DadosGerais extends React.Component {

  state = {
    icon: 'up',
    opened: true
  };

  static propTypes = {
    curso: PropTypes.string,
    periodo: PropTypes.string,
    disciplina: PropTypes.string,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    areas_conhecimento: [],
    tipo: '-',
    exame: '-',
    loading: true,
  };

  getAreasConhecimento() {
    const { areas_conhecimento } = this.props;

    if (Array.isArray(areas_conhecimento) && areas_conhecimento.length > 0) {
      return areas_conhecimento.join(', ');
    }

    return '-';
  }

  toggleOpen = () => {
    const { opened, icon } = this.state;
    this.setState({ opened: !opened, icon: icon === 'down' ? 'up' : 'down' });
  };


  render() {
    const {
      exame,
      tipo,
      loading,
    } = this.props;

    const { icon, opened } = this.state;

    return (
      <div className="dados-gerais-style">
        <div className="dadosGerais col-lg-12 col-md-12 col-xs-12 card-bg">
          <div onClick={this.toggleOpen} className={`card-header clickable ${!opened ? 'closed' : ''}`}>
            Dados do item <i className={`fas fa-chevron-${icon} pull-right`} />
          </div>
          {opened &&
          (
            <div className="card-body">
              {
                loading
                  ? <Loading />
                  : (
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-xs-6 col-sm-6">
                        <p><b>Áreas do conhecimento</b></p>
                        <p>{this.getAreasConhecimento()}</p>
                      </div>
                      <div className="col-lg-4 col-md-4 col-xs-6 col-sm-6">
                        <p><b>Exame</b></p>
                        <p>{get(exame, 'descricao') || '-'}</p>
                      </div>
                      <div className="col-lg-4 col-md-4 col-xs-6 col-sm-6">
                        <p><b>Tipo de item</b></p>
                        <p>{get(tipo, 'descricao') || '-'}</p>
                      </div>
                    </div>
                  )
              }
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default DadosGerais;
