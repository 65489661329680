import { get } from 'lodash';
import React from 'react';
import BaseFiltroAvancadoItens from '../../base/BaseFiltroAvancadoItem';
import { formatDate } from '../../utils';

class FiltroAvancadoItens extends BaseFiltroAvancadoItens {
  handleSubmit = () => {
    const { buscarItens } = this.props;
    const { values } = this.formApi.getState();
    buscarItens(this.getValues(values));
    this.toggle();
    this.updateFilterBoxes(values);
  }

  removeFilter() {
    const { filterToRemove, buscarItens } = this.props;
    const newValues = { ...this.values };
    delete newValues[filterToRemove];
    this.values = newValues;
    buscarItens(this.values);
    this.updateFilterBoxes(this.values);
  }

  componentDidUpdate(prevProps) {
    const { filterToRemove } = this.props;
    const shouldRemoveFilter = filterToRemove && prevProps.filterToRemove !== filterToRemove;
    if (shouldRemoveFilter) {
      this.removeFilter();
    }
  }

  updateFilterBoxes = (values) => {
    const { updateFilterBoxes } = this.props;
    const labels = {
      criado_por: 'elaborador',
      tipo: 'tipo de item',
      status: 'status',
      exame: 'exame',
      dificuldade: 'dificuldade',
      area_conhecimento: 'área do conhecimento',
      competencia: 'competência',
      habilidade: 'habilidade',
      encomenda: 'encomenda',
      matriz_referencia: 'matriz de referência',
      nunca_utilizados: 'nunca utilizados',
      enunciado: 'enunciado',
      alternativa: 'alternativa',
      palavras_chave: 'palavras-chave',
      min_bisserial: 'min. bisserial',
      max_bisserial: 'max. bisserial',
      min_discriminacao: 'min. discriminação',
      max_discriminacao: 'max. discriminação',
      min_dificuldade: 'min. dificuldade',
      max_dificuldade: 'max. dificuldade',
      min_pa: 'min. A',
      max_pa: 'max. A',
      min_pb: 'min. B',
      max_pb: 'max. B',
      min_pc: 'min. C',
      max_pc: 'max. C',
      min_criado_em: 'min. data de criação',
      max_criado_em: 'max. data de criação',
      id: 'código',
    };
    const fieldsWithOptions = [
      'criado_por',
      'tipo',
      'status',
      'exame',
      'dificuldade',
      'area_conhecimento',
    ];
    const fieldsWithAsyncOptions = [
      'competencia',
      'habilidade',
      'encomenda',
      'matriz_referencia',
    ];
    const multi = [
      'palavras_chave',
    ];
    const dates = [
      'min_criado_em',
      'max_criado_em',
    ];
    const bools = [
      'nunca_utilizados',
    ];
    const boxes = Object.entries(values).map((entry) => {
      const field = entry[0];
      const value = entry[1];
      const box = { field, label: labels[field] };
      const hasOptions = fieldsWithOptions.find(d => d === field);
      const hasAsyncOptions = fieldsWithAsyncOptions.find(d => d === field);
      const isMulti = multi.find(d => d === field);
      const isDate = dates.find(d => d === field);
      const isBool = bools.find(d => d === field);
      if (hasOptions) {
        box.value = get(this.state, field).find(d => +d.value === +value).label;
      } else if (hasAsyncOptions) {
        box.value = get(value, 'label');
      } else if (isMulti) {
        box.value = value.map(d => d.label).join(', ');
      } else if (isDate) {
        box.value = formatDate(value);
      } else if (isBool) {
        if (value) {
          box.value = <i className="fas fa-check-circle" />;
        }
      } else {
        box.value = value;
      }
      return box;
    });

    const boxesWithValidValues = boxes.filter(d => d.value);
    updateFilterBoxes(boxesWithValidValues);
  }

  renderFooter() {
    return (
      <React.Fragment>
        <button className="btn danger-button mr-3" type="button" onClick={this.toggle}>Fechar</button>
        <button className="btn primary-button mr-1" type="button" onClick={this.handleSubmit}>Buscar</button>
      </React.Fragment>
    );
  }
}

export default FiltroAvancadoItens;
