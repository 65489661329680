import styled from 'styled-components';

function getLinkBackground(props) {
  if (props.white) {
    return `
      background: ${props.theme.pure_white};
      border: 1px solid ${props.theme.border};
      margin-bottom: 0;

      a {
        color: black;
      }

      &:hover,
      &:active {
        background: ${props.theme.border};
      }
    `;
  }
  if (props.primary) {
    return `
      background: ${props.theme.primaryAccent};
      border: none;

      a {
        color: ${props.theme.pure_white};
      }
    `;
  }
  if (props.danger) {
    return `
      background: ${props.theme.danger};
      border: none;

      a {
        color: ${props.theme.pure_white};
      }

      &:hover,
      &:active {
        background: ${props.theme.danger};
      }
    `;
  }
  if (props.success) {
    return `
      background: ${props.theme.success};
      border: none;

      a {
        color: ${props.theme.pure_white};
      }

      &:hover,
      &:active {
        background: ${props.theme.success};
      }
    `;
  }
}

// eslint-disable-next-line import/prefer-default-export
export const LinkContainer = styled.div`
  color: #fff;
  font-size: 12px;

  &:hover,
  &:active {
    color: #fff;
    background: ${props => props.theme.primaryAccent};
  }

  ${props => getLinkBackground(props)};
`;
