import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import 'react-select/dist/react-select.css';
import { connect } from 'react-redux';
import { actions } from 'react-redux-form';
import Select from 'react-select';
import resolvePath from 'object-resolve-path';

const defaultProps = {
  col: 'col-md-12',
  multi: false,
  options: [],
};

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  col: PropTypes.string,
  multi: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
};

class InputSelect extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(selectedOption) {
    const { model, dispatch } = this.props;
    let value = selectedOption || null;

    // O código abaixo é necessário apenas para manter a compatibilidade dos defaults já criados.
    // Campos multiplos deveriam ser inicializados com []
    // Campos simples deveriam ser inicializados com null

    if (this.props.multi && selectedOption.length === 0) {
      value = [];
    }

    dispatch(actions.change(model, value));
  }

  render() {
    const value = resolvePath(this.props, this.props.model);
    const {
      label,
      col,
      multi,
      options,
    } = this.props;

    return (
      <FormGroup className={col}>
        <label> {label} </label>
        <Select
          multi={multi}
          onChange={this.onChange}
          options={options}
          value={value}
          noResultsText="Nenhum resultado encontrado."
          placeholder="Selecione"
        />
      </FormGroup>
    );
  }
}

InputSelect.propTypes = propTypes;
InputSelect.defaultProps = defaultProps;

export default connect(state => state)(InputSelect);
