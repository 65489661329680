import React, { Fragment } from 'react';
import { Form } from 'informed';
import { get } from 'lodash';
import { InputText } from '../../components/inputs';
import UserImg from '../../assets/images/dev/faceimg.jpg';
import NavBreadCrumb from '../../containers/NavBreadCrumb';
import axios from 'axios';
import { API_HOST } from '../../consts';
import Alert from '../../components/Alert';

class PerfilUsuario extends React.Component {

  state = {
    nome: 'Felipe',
    telefone: '123',
    email: '12',
    mostrarAlterarSenha: false,
    alterandoSenha: false,
    alterarSenhaSuccess: '',
    alterarSenhaErrors: [],
  }

  fetchData() {
    axios.get(`${API_HOST}/login/me`)
      .then(() => {

      })
  }

  /* componentDidMount() {
     this.fetchDadosPessoais();
   }

   fetchDadosPessoais() {
     axios.get(`${API_URL}/dados-pessoais`, getAxiosConfig())
       .then(response => this.setState({ ...response.data }))
       .catch(handleRequestError);
   }*/

  alterarSenha = (values, is_new=false) => {
    this.setState({ alterandoSenha: true, alterarSenhaSuccess: '' });
    let endpoint = `${API_HOST}/login/alterar_senha`
    if (is_new) {
      endpoint = `${API_HOST}/login/criar_senha`
    }
    axios.post(endpoint, values)
      .then((response) => {
        this.setState({
          alterarSenhaSuccess: response.data.detail,
          alterandoSenha: false,
          alterarSenhaErrors: [],
        });
        this.formApi.setValues({});
      })
      .catch((error) => {
        const status = get(error, 'response.status');
        if (status === 400) {
          const errors = get(error, 'response.data', {});
          if (!is_new) {
            this.formApi.setError('current_password', errors.current_password);
          }
          this.formApi.setError('new_password', errors.new_password);
          this.formApi.setError('confirm_new_password', errors.confirm_new_password);
          if (errors.non_field_errors) {
            this.setState({ alterarSenhaErrors: errors.non_field_errors });
          }
        } else {
          this.setState({ alterarSenhaErrors: ['Ocorre um erro inesperado.'] });
        }

        this.setState({ alterandoSenha: false });
      })
  }

  renderCriarSenha() {
    const { alterandoSenha, alterarSenhaSuccess, alterarSenhaErrors } = this.state;

    return (
      <div className="row mt-3">
        <div className="col-6">
          {alterarSenhaSuccess && (
            <Alert category="success" onClick={() => this.setState({ alterarSenhaSuccess: '' })}>
              {alterarSenhaSuccess}
            </Alert>
          )}
          {alterarSenhaErrors.map(error => (
              <Alert
                key={error}
                category="danger"
                onClick={() => this.setState({ alterarSenhaErrors: alterarSenhaErrors.filter(e => e !== error)})}
              >
                {error}
              </Alert>
            ))}
          <Form
            getApi={(formApi) => { this.formApi = formApi; }}
            onSubmit={values => this.alterarSenha(values, true)}
          >
            <div className="form-sign">
              <div className="group-input ">
                <i className="fas fa-lock"/>
                <InputText
                  placeholder="Nova senha"
                  field="new_password"
                  type="password"
                />
              </div>
              <div className="group-input">
                <i className="fas fa-redo-alt"/>
                <InputText
                  placeholder="Confirmar nova senha"
                  field="confirm_new_password"
                  type="password"
                />
              </div>
              <button
                disabled={alterandoSenha}
                type="submit"
                className="btn primary-button"
              >
                Salvar
              </button>
            </div>
          </Form>
        </div>

      </div>

    )
  }

  renderAlterarSenha() {
    const { alterandoSenha, alterarSenhaSuccess, alterarSenhaErrors } = this.state;

    return (
      <div className="row mt-3">
        <div className="col-6">
          {alterarSenhaSuccess && (
            <Alert category="success" onClick={() => this.setState({ alterarSenhaSuccess: '' })}>
              {alterarSenhaSuccess}
            </Alert>
          )}
          {alterarSenhaErrors.map(error => (
              <Alert
                key={error}
                category="danger"
                onClick={() => this.setState({ alterarSenhaErrors: alterarSenhaErrors.filter(e => e !== error)})}
              >
                {error}
              </Alert>
            ))}
          <Form
            getApi={(formApi) => { this.formApi = formApi; }}
            onSubmit={this.alterarSenha}
          >
            <div className="form-sign">
              <div className="group-input">
                <i className="fas fa-lock"/>
                <InputText
                  placeholder="Senha atual"
                  field="current_password"
                  type="password"
                />
              </div>
              <div className="group-input ">
                <i className="fas fa-lock"/>
                <InputText
                  placeholder="Nova senha"
                  field="new_password"
                  type="password"
                />
              </div>
              <div className="group-input">
                <i className="fas fa-redo-alt"/>
                <InputText
                  placeholder="Confirmar nova senha"
                  field="confirm_new_password"
                  type="password"
                />
              </div>
              <button
                disabled={alterandoSenha}
                type="submit"
                className="btn primary-button"
              >
                Salvar
              </button>
            </div>
          </Form>
        </div>

      </div>

    )
  }


  render() {
    const { user } = this.props;
    const currentPath = 'Perfil';
    const pathList = [
      { link: '/perfil', label: 'Perfil de Usuário' },
    ];

    return (
      <Fragment>
        <NavBreadCrumb pathList={pathList} currentPath={currentPath}/>
        <div className="container">
          <section>
            <div className="row mb-4 pb-4">
              <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 body-dados">
                <img src={UserImg} alt={`Foto de ${get(user, 'last_name')}`}/>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 body-dados">
                <h2 className="text-name">{get(user, 'last_name')}</h2>
                <h3 className="mb-0">
                  <span>
                  Alterar senha
                  </span>
                </h3>
                {this.renderCriarSenha()}
                <br/>
                <br/>
                <h3>
                  <i className="fas fa-user"/>
                  <span>
                  Informações de contato
                  </span>
                </h3>
                <div className="body-dados--contato">
                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                      <p>Telefone:</p>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 pull-left">
                      <p className="text-default-blue">-</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                      <p>Email:</p>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 pull-left">
                      <p className="text-default-blue">{get(user, 'email')}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Fragment>
    );
  }
}

export default PerfilUsuario;
