import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { get } from 'lodash';
import { Link, withRouter } from 'react-router-dom';
import { API_HOST } from '../../consts';
import { BaseForm } from '../../base';
import DetectorPlagio from './DetectorPlagio';
import VerificacaoAutomatica from './VerificacaoAutomatica';

class Etapa1 extends BaseForm {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.id = get(props, 'match.params.id');
  }

  state = {
    tipo: [],
    save: 0,
    metadata: {},
    fetchingData: false,
    status_item: [],
  };

  componentDidMount() {
    this.fetchRevisao();
  }

  mapRespostas(data) {
    const respostas = get(data, 'respostas') || [];
    const mapped = {};

    respostas.forEach((r) => {
      mapped[r.secao] = mapped[r.secao] || {};
      mapped[r.secao][r.pergunta] = r.valor;
    });

    return mapped;
  }

  fetchRevisao() {
    axios.get(`${API_HOST}/revisoes/revisao/${this.id}`)
      .then((response) => {
        const {
          item, questionario, tipo, permissions, ...data
        } = response.data;
        const hasUpdatePermission = get(permissions, 'update');
        if (hasUpdatePermission) {
          const respostas = this.mapRespostas(data);
          this.setState({ tipo, respostas, item_id: item });
          this.fetchQuestionario(questionario);
        } else {
          const { history } = this.props;
          history.push('/itens');
        }
      });
  }

  fetchQuestionario(questionario) {
    axios.get(`${API_HOST}/questionarios/questionario/${questionario}`)
      .then((response) => {
        this.setState({
          questionario: response.data,
        });
      });
  }

  onSubmit() {
    const { history } = this.props;
    history.push('2');
  }

  onRespostaChange(secao, pergunta, valor) {
    this.setState({ loading: true });

    axios.post(`${API_HOST}/revisoes/revisao/${this.id}/resposta`, {
      secao, pergunta, valor,
    })
      .then(() => {
        const { respostas } = this.state;
        respostas[secao] = respostas[secao] || {};
        respostas[secao][pergunta] = valor;
        this.setState({ respostas, loading: false });
      });
  }

  renderQuestionario() {
    const { questionario, respostas } = this.state;
    const secoes = get(questionario, 'secoes', []);

    return (
      <div className="card card-bg questionario">
        <div className="card-header pb-0">
          <h1>{get(questionario, 'descricao')}</h1>
        </div>
        <div className="card-body">
          {secoes.map(s => (
            <React.Fragment key={s.id}>
              <h5>{s.descricao}</h5>
              <table className="table table-bordered">
                <tbody>
                  {s.perguntas.map(p => (
                    <tr key={p.id}>
                      <td>
                        <div className="row pergunta">
                          <div className="col-md-9 col-sm-5 col-xs-12">
                            <p>{p.descricao}</p>
                          </div>
                          <div className="col-md-3 col-sm-7 col-xs-12">
                            <input
                              type="radio"
                              name={`s${s.id}p${p.id}`}
                              id={`s${s.id}p${p.id}_1`}
                              onChange={() => this.onRespostaChange(s.id, p.id, '1')}
                              value="1"
                              checked={get(respostas, `${s.id}.${p.id}`) === '1'}
                            />
                            <label htmlFor={`s${s.id}p${p.id}_1`} className="ml-1 mr-3">Sim</label>
                            <input
                              type="radio"
                              name={`s${s.id}p${p.id}`}
                              id={`s${s.id}p${p.id}_0`}
                              onChange={() => this.onRespostaChange(s.id, p.id, '0')}
                              value="0"
                              checked={get(respostas, `${s.id}.${p.id}`) === '0'}
                            />
                            <label htmlFor={`s${s.id}p${p.id}_0`} className="ml-1">Não</label>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  }

  render() {
    const { item_id, loading, tipo } = this.state;
    const { canViewPlagiusDetector, canDetectPlagius } = this.props;

    return (
      <div
        style={{ cursor: loading ? 'wait' : 'auto' }}
      >
        <h1 className="mb-3">Revisão {get(tipo, 'descricao')} - <Link to={`/itens?id=${item_id}`}>Item {item_id}</Link></h1>
        <div className="row">
          <div className="col-md-12">
            {item_id && <VerificacaoAutomatica item={item_id} />}
          </div>
          {canViewPlagiusDetector && (
            <div className="col-md-12">
              {item_id && <DetectorPlagio item={item_id} canDetectPlagius={canDetectPlagius} />}
            </div>
          )}
          <div className="col-md-12">
            {this.renderQuestionario()}
            <button
              className="btn primary-button mt-2 mb-2 pull-right"
              type="button"
              onClick={this.onSubmit}
            >
              <i className="fas fa-chevron-right" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

Etapa1.propTypes = {
  canViewPlagiusDetector: PropTypes.bool,
  canDetectPlagius: PropTypes.bool,
};

Etapa1.defaultProps = {
  canViewPlagiusDetector: false,
  canDetectPlagius: false,
};

export default withRouter(Etapa1);
