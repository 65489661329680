import React, { Fragment } from 'react';

export default class GrupoAnalise extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      group: this.props.group
    }
    }

  render() {
    const {group} = this.state
    return (
      <Fragment>
          <div className="placeholder" key={group.title}>
            <i className="fas fa-group icono-grupos" style={{background: group.color}} />
            <h4 className="grupo-title">{group.title}</h4>
            <hr/>
            <p>Grupo com respondentes que obtiveram <b>{group.text}</b> nota no teste</p>
          </div>
    </Fragment>
    );
  }
}
