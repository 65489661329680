import React from 'react';
import PropTypes from 'prop-types';

export default class FormTable extends React.Component {
  static propTypes = {
    headers: PropTypes.arrayOf(PropTypes.string).isRequired,
    noResultsText: PropTypes.string,
    classe: PropTypes.string,
  };

  static defaultProps = {
    data: [],
    noResultsText: '',
    classe: '',
  };

  renderBody() {
    if (this.props.data.length > 0) {
      return (
        this.props.data.map((items, i) => (
          <tr key={i}>
            {items.map((item, j) => <td key={j}>{item}</td>)}
          </tr>
        ))
      );
    }

    if (this.props.noResultsText) {
      return (
        <tr>
          <td>{this.props.noResultsText}</td>
        </tr>
      );
    }

    return null;
  }

  render() {
    const { classe } = this.props;
    return (
      <table className={`table table-responsive form-table ${classe}`}>
        <thead>
          <tr>
            {this.props.headers.map((header, index) => <th key={index}>{header}</th>)}
          </tr>
        </thead>
        <tbody>
          {this.renderBody()}
        </tbody>
      </table>
    );
  }
}
