import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import { Form } from 'informed';
import axios from 'axios';
import { get } from 'lodash';
import Item from '../components/Item';
import NavBreadCrumb from '../containers/NavBreadCrumb';
import { InputSelect, InputTextArea } from '../components/inputs';
import { API_HOST } from '../consts';

const mapItens = d => ({
  value: d.id, label: `Item ${d.posicao} - ${d.item.id}`,
});

class AnularItens extends React.Component {
  constructor(props) {
    super(props);
    this.id = get(props, 'match.params.id');
  }
  state = {
    itens: [],
    itensAnulados: [],
    loading: true,
    permission: 'add_anulacao',
    formState: {},
  }

  componentDidMount() {
    this.fetchData();
  }

  getItens() {
    const { itens } = this.state;
    return itens.filter(item => item.anulado === false);
  }

  getSelectedItem = () => {
    const { itens, formState } = this.state;
    const selectedItem = itens.find(item => (item.id === get(formState, 'values.avaliacao_item')));
    if (selectedItem) {
      return <Item item={get(selectedItem, 'item')} />;
    }
    return <div className="no-item ml-1">Nenhum item selecionado</div>;
  }

  getItensAnulados() {
    const { itens } = this.state;
    const itensAnulados = itens.filter(item => item.anulado === true);
    if (itensAnulados.length !== 0) {
      return <Item item={this.renderItensAnulados()} />;
    }
    return <div className="no-item ml-1">Não existem itens anulados nesta aplicação.</div>;
  }

  fetchData() {
    const params = { aplicacao: this.id, ordering: 'posicao' };
    axios.get(`${API_HOST}/aplicacoes/avaliacao_item`, { params })
      .then((response) => {
        this.setState({
          itens: response.data,
          loading: false,
        });
      })
      .catch(() => {
        toast.error('Erro ao carregar lista de itens.');
        this.setState({
          loading: false,
        });
      });
  }

  anularItem = () => {
    const { formState } = this.state;
    this.setState({ loading: true });
    axios.post(`${API_HOST}/aplicacao/anulacao`, { aplicacao: this.id, ...formState.values })
      .then(() => {
        this.fetchData();
        this.setState({ loading: false });
        toast.success('Item anulado com sucesso!');
      })
      .catch(() => {
        this.setState({ loading: false });
        toast.error('Erro ao anular item, por favor tente mais tarde.');
      });
  }

  renderItensAnulados() {
    return this.getItensAnulados().map(e => e.item);
  }

  render() {
    const { itens, loading } = this.state;
    const pathList = [
      { link: '/instrumentos-cognitivos', label: 'Instrumentos cognitivos' },
      { link: `/aplicacoes/${this.id}`, label: 'Gerenciamento da aplicação' },
      { link: `/aplicacoes/${this.id}/notas`, label: 'Notas' },
    ];
    return (
      <Fragment>
        <NavBreadCrumb pathList={pathList} currentPath="Anular Itens" />
        <div className="container">
          <div className="card">
            <div className="card-header">
              <h1 className="azul">Itens Anulados</h1>
              <hr />
              <ul className="list-inline list-style mb-0">
                <li className="list-inline-item item-bg"><strong className="mr-1">Total de itens:</strong>{get(itens, 'length')}</li>
                <li className="list-inline-item item-bg"><strong className="mr-1">Itens anulados: </strong>{get(this.getItensAnulados(), 'length')}</li>
                <li className="list-inline-item float-right">
                  <button className={`btn bg-danger btn-padrao mr-2 ${loading ? 'btn-disabled' : ''}`} onClick={() => this.anularItem()} disabled={loading}> Anular item selecionado</button>
                </li>
              </ul>
              <hr />
            </div>
            <Form
              onChange={formState => this.setState({ formState })}
              getApi={(formApi) => { this.formApi = formApi; }}
            >
              <div className="card-body pt-0">
                <InputSelect
                  col="12"
                  field="avaliacao_item"
                  label="Selecione um item para anular"
                  options={itens.map(mapItens)}
                />
                <InputTextArea
                  field="justificativa"
                  label="Justificativa para anular o item"
                />
                <label>Item selecionado: </label>
                {this.getSelectedItem()}
                <hr />
                <label>Itens Anulados: </label>
                {this.getItensAnulados()}
              </div>
            </Form>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default AnularItens;
