import React from 'react';
import classnames from 'classnames';
import { isString } from 'lodash';

export default class CardReference extends React.Component {

  static defaultProps = {
    borderRight: true,
    checked: false,
  };

  render() {
    const {
      onChange, checked, descricao, borderRight
    } = this.props;

    return (
      <div className={`card ${classnames({ cardInput: borderRight })}`}>
        <div className="card-body">
          <div className="row">
            <div className="col-1">
              <ul className="listCheckBox">
                <li>
                  <label className="container-check-custom">
                    <input
                      type="checkbox"
                      aria-label=""
                      onChange={onChange}
                      checked={checked}
                    />
                    <span className="checkmark" />
                  </label>
                </li>
              </ul>
            </div>
            <div className="col-11">
              {isString(descricao) ? <p>{descricao}</p> : descricao}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
