import axios from 'axios';
import {
  LEGACY_API_HOST,
} from '../consts';

export const updateStep = index => ({
  type: 'UPDATE_STEP',
  payload: index,
});

export const loadApi = api => (
  (dispatch) => {
    dispatch({ type: 'FETCHING' });
    axios.get(`${LEGACY_API_HOST}/${api}/`)
      .then(response => dispatch({ type: 'FETCH', payload: { [api]: (Array.isArray(response.data) ? response.data : response.data.results) } }))
      .catch(() => dispatch({ type: 'FETCH_ERROR' }));
  }
);

export const toggleModal = name => ({
  type: 'TOGGLE_MODAL',
  payload: name,
});

export { default as ListActions } from './ListActions';
export * from './AvaliacaoFormItemList';
export * from './PreTeste';
export * from './ItemActions';
export * from './MatrizReferenciaActions';
