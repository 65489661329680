import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { get, debounce } from 'lodash';
import { withRouter } from 'react-router-dom';
import { Form, Scope } from 'informed';
import FormContainer from '../../containers/Form';
import FiltroAvancadoItens from './FiltroAvancadoItens';
import { API_HOST } from '../../consts';
import Item from '../../components/Item';
import StatusRibbon from '../../components/StatusRibbon';
import InputText from '../../components/inputs/InputText';
import Loading from '../../components/Loading';
import ContainerItemComentarios from '../../components/ContainerItemComentario';
import { getErrorToast } from '../../utils';
import queryString from 'query-string';
import swal from 'sweetalert2';

class Itens extends React.Component {
  label = 'Itens';
  link = '/itens';

  state = {
    itens: [],
    checked: [],
    showTotvsPage: false,
    nextStep: '',
    lastStep: '',
    isComentariosOpen: false
  };

  componentDidMount() {
    this.fetchData();
  }

  toggleModal = (name) => {
    const { openModal } = this.state;
    this.setState({ openModal: openModal === name ? null : name });
  };

  fetchData(loading = true) {
    const { match, location } = this.props;
    const params = queryString.parse(location.search);

    if (params.show) {
      this.setState({
        showTotvsPage: params.show,
        nextStep: 'IntegracaoTOTVS'
      });
    } else {
      this.setState({
        showTotvsPage: false,
        nextStep: '',
        lastStep: '/instrumentos-cognitivos'
      });
    }

    if (loading) {
      this.setState({ fetchingData: true });
    }

    return axios
      .get(`${API_HOST}/avaliacoes/avaliacao_item`, {
        params: {
          avaliacao: match.params.id
        }
      })
      .then((response) => {
        const itens = response.data.results || response.data;

        this.setState({
          checked: itens.map((item) => get(item, 'item.id')),
          fetchingData: false
        });

        this.formApi.setValues({
          itens
        });
      });
  }

  handleResultClick = (event, data) => {
    const { checked } = event.target;
    if (checked) {
      axios
        .post(`${API_HOST}/avaliacoes/avaliacao_item`, data)
        .then(() => {
          this.fetchData(false);
        })
        .catch((error) => {
          toast.error(getErrorToast(error));
        });
    } else {
      const { itens } = this.formApi.getState().values;
      const item = itens.find((i) => get(i, 'item.id') === data.item);
      this.removeItem(item);
    }
  };

  handleAddAll = (itemFilters) => {
    const { match } = this.props;
    axios
      .post(`${API_HOST}/avaliacoes/bulk_avaliacao_item`, {
        avaliacao: match.params.id,
        itens: itemFilters
      })
      .then(() => {
        this.fetchData(false);
        this.toggleModal(null);
      })
      .catch((error) => {
        toast.error(getErrorToast(error));
      });
  };

  handleChange = (index, id, data) => {
    this.patchItem(index, id, data);
  };

  patchItem = debounce((index, id, data) => {
    axios
      .patch(`${API_HOST}/avaliacoes/avaliacao_item/${id}`, data)
      .then(() => {})
      .catch((error) => {
        const status = get(error, 'response.status');
        if (status === 400) {
          const errors = get(error, 'response.data');
          Object.keys(errors).forEach((field) => {
            const fieldName = `itens[${index}].${field}`;
            if (this.formApi.fieldExists(fieldName)) {
              this.formApi.setError(fieldName, errors[field]);
            }
          });
        }
      });
  }, 500);

  removeItem(item) {
    this.setState({ editing: true });

    axios
      .delete(`${API_HOST}/avaliacoes/avaliacao_item/${item.id}`)
      .then(() => {
        this.fetchData(false).then(() => {
          this.setState({ editing: false });
          toast.success('Item removido do instrumento com sucesso.');
        });
      })
      .catch(() => {
        this.setState({ editing: false });
      });
  }

  alterarPosicao = (item, offset) => {
    this.setState({ editing: true });

    axios
      .patch(`${API_HOST}/avaliacoes/avaliacao_item/${item.id}`, {
        posicao: item.posicao + offset
      })
      .then(() => {
        this.fetchData(false).then(() => {
          this.setState({ editing: false });
        });
      });
  };

  distribuirValor = () => {
    this.setState({ distribuindoValor: true });

    const { match } = this.props;
    axios
      .get(
        `${API_HOST}/avaliacoes/avaliacao/${match.params.id}/distribuir_valor`
      )
      .then((response) => {
        const { itens } = this.formApi.getState().values;
        const { valor } = response.data;

        this.formApi.setValues({
          itens: itens.map((item) => ({ ...item, valor }))
        });
      })
      .catch((error) => {
        const status = get(error, 'response.status');
        if (status === 400) {
          const msg = get(error, 'response.data.valor[0]');
          if (msg) {
            toast.error(msg);
            return;
          }
        }

        if (status !== 403) {
          toast.error('Ocorreu um erro desconhecido.');
        }
      })
      .then(() => this.setState({ distribuindoValor: false }));
  };

  renderItens(formState) {
    const { fetchingData, editing, openModal, isComentariosOpen } = this.state;

    if (fetchingData) {
      return <Loading />;
    }

    if (openModal) {
      return null;
    }

    const itens = get(formState, 'values.itens') || [];

    return itens.map((item, index) => (
      <div>
        {index === 0 && (
          <div className="d-flex justify-content-end mb-2">
            <button
              className="btn btn-white"
              onClick={() =>
                this.setState({ isComentariosOpen: !isComentariosOpen })
              }
            >
              {isComentariosOpen
                ? 'Ocultar Comentários'
                : 'Mostrar Comentários'}
            </button>
          </div>
        )}
        <div className="d-flex">
          <div
            className="card item-card mb-3"
            key={item.id}
            style={{
              width: isComentariosOpen ? '70%' : '100%',
              marginBottom: '24px',
              transition: 'width 0.3s'
            }}
          >
            <div className="card-header">
              <div className="row">
                <div className="col-lg-7 col-md-7 col-xs-12 col-sm-12 mb-2">
                  <strong>{item.posicao}.</strong> Item {get(item, 'item.id')}
                </div>
                <div className="col-lg-5 col-md-5 col-xs-12 col-sm-12 mb-2">
                  <StatusRibbon color={get(item, 'item.status.cor')}>
                    {get(item, 'item.status.descricao')}
                  </StatusRibbon>
                  <div
                    role="group"
                    className="btn-group list-btn pull-right mt-2"
                  >
                    {index !== formState.values.itens.length - 1 && (
                      <button
                        className="btn btn-white"
                        onClick={() => this.alterarPosicao(item, 1)}
                        disabled={editing}
                      >
                        <i className="fas fa-arrow-down" />
                      </button>
                    )}
                    {index > 0 && (
                      <button
                        className="btn btn-white"
                        onClick={() => this.alterarPosicao(item, -1)}
                        disabled={editing}
                      >
                        <i className="fas fa-arrow-up" />
                      </button>
                    )}
                    <button
                      className="btn btn-white"
                      type="button"
                      onClick={() => {
                        swal
                          .fire({
                            icon: 'warning',
                            title: `Tem certeza que deseja apagar o item ${get(
                              item,
                              'item.id'
                            )}?`,
                            showCancelButton: true,
                            showConfirmButton: true,
                            confirmButtonText: 'Confirmar',
                            cancelButtonText: 'Cancelar',
                            reverseButtons: true
                          })
                          .then((result) => {
                            if (result.value === true) {
                              this.removeItem(item);
                            }
                          });
                      }}
                      disabled={editing}
                    >
                      <i className="fas fa-times" />
                    </button>
                  </div>
                </div>
                <Scope scope={`itens[${index}]`}>
                  <InputText
                    field="valor"
                    label="Valor"
                    col="col-md-3"
                    onChange={(value) =>
                      this.handleChange(index, item.id, { valor: value })
                    }
                  />
                  <InputText
                    field="posicao_atual"
                    label="Posição Atual do Item"
                    col="col-md-3"
                    onChange={(value) =>
                      this.handleChange(index, item.id, {
                        posicao_atual: value
                      })
                    }
                  />
                </Scope>
              </div>
            </div>
            <div className="card-body ">
              <Item item={item.item} />
            </div>
          </div>
          {isComentariosOpen && (
            <ContainerItemComentarios item={item} api={this.formApi} />
          )}
        </div>
      </div>
    ));
  }

  render() {
    const { openModal, checked, distribuindoValor, nextStep, lastStep } =
      this.state;

    return (
      <FormContainer
        title="Itens"
        previous="dados"
        next={nextStep}
        last={lastStep}
      >
        <Form
          getApi={(formApi) => {
            this.formApi = formApi;
          }}
          initialValues={{ itens: [] }}
        >
          {({ formState }) => (
            <React.Fragment>
              <button
                className="btn mb-3 ml-3 primary-button"
                onClick={() => this.toggleModal('modalItens')}
              >
                <i className="fas fa-plus" /> adicionar itens do banco de itens
              </button>
              <br />
              {get(formState, 'values.itens.length') > 0 && (
                <button
                  className="btn btn-sm mb-3 btn-white"
                  onClick={this.distribuirValor}
                  disabled={distribuindoValor}
                >
                  distribuir valor dos itens
                </button>
              )}
              {this.renderItens(formState)}
            </React.Fragment>
          )}
        </Form>

        <FiltroAvancadoItens
          isOpen={openModal === 'modalItens'}
          toggle={() => this.toggleModal('modalItens')}
          filter={false}
          onResultClick={this.handleResultClick}
          onAddAll={this.handleAddAll}
          checked={checked}
        />
      </FormContainer>
    );
  }
}

export default withRouter(Itens);
