import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import InputDate from "../../components/inputs/InputDate";
import InputText from "../../components/inputs/InputText";
import { Form } from 'informed';
import InputSelect from "../../components/inputs/InputSelect";
import InputSummernote from "../../components/inputs/InputSummernote";

export default class CriarRecursoModal extends React.Component{


  renderBody = () =>{


    return(
      <Form
        // onChange={formState => this.setState({ formState })}
        // onValueChange={this.onValueChange}
        // getApi={(formApi) => { this.formApi = formApi; }}
      >
        {({ formApi }) => {
          return (
            <React.Fragment>
              <div className="row p-4">
                <div className="col-4">
                  <p className="azul"><b>Data da aplicação</b></p>
                  <p >06/06/2018 08:00</p>
                </div>

                <InputDate
                  label="Data do recurso"
                  field="data"
                  type="date"
                  col="col-md-4"
                  placeholder="23/06/2018"
                />
                <InputDate
                  label="Data limite para resposta"
                  field="data"
                  type="date"
                  col="col-md-4"
                  placeholder="23/06/2018"
                />
                <InputText
                required
                col="col-sm-6"
                label="Nome do candidato"
                field="titulo"
              />
                <InputText
                  required
                  col="col-sm-6"
                  label="Telefone de contato"
                  field="titulo"
                />
                <InputSelect
                  label="Estado do Recurso"
                  col="col-md-6"
                  field="midia"
                  // options={criado_por}
                />
                <InputSelect
                  label="Item"
                  col="col-md-6"
                  field="midia"
                  // options={criado_por}
                />

                <InputSummernote
                  label="Justificativa e Bibliografia"
                  field="comentario"
                />
                <InputSummernote
                  label="Mensagem para o elaborador"
                  field="comentario"
                />

                <div className="col-12">
                  <p className="azul">Anexos</p>
                </div>
                {/*<InputText
                  required
                  col="col-sm-12"
                  label="Título"
                  field="titulo"
                />
                <InputSelect
                  label="Instituição"
                  col="col-md-6"
                  field="instituicao"
                  // options={criado_por}
                />
                <InputSelect
                  label="Curso"
                  col="col-md-6"
                  field="curso"
                  // options={criado_por}
                />
                <InputSelect
                  label="Período"
                  col="col-md-6"
                  field="periodo"
                  // options={criado_por}
                />
                <InputSelect
                  label="Disciplina"
                  col="col-md-6"
                  field="disciplina"
                  // options={criado_por}
                />
                <InputDate
                  label="Data"
                  field="data"
                  type="date"
                  col="col-md-4"
                  placeholder="23/06/2018"
                />
                <InputText
                  required
                  col="col-sm-4"
                  label="Hora"
                  field="hora"
                />
                <InputText
                  required
                  col="col-sm-4"
                  label="Duração"
                  field="duracao"
                />
                <InputSelect
                  label="Mídia onde será executada a aplicação"
                  col="col-md-12"
                  field="midia"
                  // options={criado_por}
                />*/}

              </div>
            </React.Fragment>
          );
        }}
      </Form>
    )
  };

  render() {
    const { toggle, isOpen } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
      >
        <ModalHeader toggle={toggle}>
          Criar Recurso
        </ModalHeader>
        <ModalBody className="p-0">

          {this.renderBody()}
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-white" onClick={toggle}>Fechar</button>
          <button className="btn primary-button">Criar</button>

        </ModalFooter>
      </Modal>
    );
  }
}
