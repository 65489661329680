import React from 'react';
import { Link, Route, Switch, withRouter } from 'react-router-dom';
import axios from 'axios';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import { toast } from 'react-toastify';
import AddButton from '../../components/buttons/AddButton';
import Table from '../../components/Table';
import NavBreadCrumb from '../../containers/NavBreadCrumb';
import BaseList from '../../base/BaseList';
import { API_HOST } from '../../consts';
import { getOrdering, getFilters } from '../../utils';
import RawInputCheckbox from '../../components/inputs/RawInputCheckbox';
import UserFormPage from '../usuarios/UserFormPage';

const mapGroup = (d) => (
  <option key={d.id} value={d.id}>
    {d.name}
  </option>
);

const mapInstituicao = (d) => (
  <option key={d.id} value={d.id}>
    {d.descricao}
  </option>
);

class ListaUsuario extends BaseList {
  api = `${API_HOST}/login/user`;

  state = {
    data: [],
    sorted: [],
    ordering: '-criado_em',
    groups: [],
    page_size: 30,
    changingUserStatus: false,
  };

  componentDidMount() {
    super.componentDidMount();
    this.fetchGroups();
    this.fetchInstituicoes();
  }

  fetchGroups() {
    axios.get(`${API_HOST}/login/groups`).then((response) => {
      const data = response.data.results || response.data;
      this.setState({ groups: data.map(mapGroup) });
    });
  }

  fetchInstituicoes() {
    axios.get(`${API_HOST}/core/instituicao`).then((response) => {
      const data = response.data.results || response.data;
      this.setState({ instituicoes: data.map(mapInstituicao) });
    });
  }

  changeUserStatus = (event, id) => {
    this.setState({ changingUserStatus: true });
    const { checked } = event.target;
    axios
      .patch(`${API_HOST}/login/user/${id}`, {
        is_active: checked,
      })
      .then(() => {
        const { data } = this.state;
        this.setState({
          data: data.map((d) =>
            d.id === id ? { ...d, is_active: checked } : d
          ),
          changingUserStatus: false,
        });
      })
      .catch(() => this.setState({ changingUserStatus: false }));
  };

  removeConfirmationMessage = (selectedForRemovalId) =>
    `Tem certeza que deseja remover o usuário ${selectedForRemovalId}?`;

  get pageSize() {
    const { total, page_size } = this.state;

    if (total === 0) {
      return 1;
    }

    if (total && total < page_size) {
      return total;
    }

    return page_size;
  }

  get columns() {
    const {
      groups,
      loginAsLoading,
      changingUserStatus,
      instituicoes,
    } = this.state;

    return [
      {
        Header: 'Nome',
        accessor: 'last_name',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'CPF',
        accessor: 'cpf',
        width: 100,
      },
      {
        Header: 'Grupos',
        accessor: 'groups',
        Cell: (row) => row.value.map((g) => g.name).join(', '),
        Filter: ({ filter, onChange }) => (
          <select
            className="form-control"
            onChange={(event) => onChange(event.target.value)}
            value={filter ? filter.value : ''}
            style={{ width: '100%' }}
          >
            <option value="">todos</option>
            {groups}
          </select>
        ),
      },
      {
        Header: 'Instituições',
        accessor: 'instituicoes',
        Cell: (row) => row.value.map((g) => g.descricao).join(', '),
        Filter: ({ filter, onChange }) => (
          <select
            className="form-control"
            onChange={(event) => onChange(event.target.value)}
            value={filter ? filter.value : ''}
            style={{ width: '100%' }}
          >
            <option value="">todas</option>
            {instituicoes}
          </select>
        ),
      },
      {
        Header: 'Editar',
        accessor: 'id',
        width: 80,
        className: 'd-flex justify-content-center',
        Cell: (row) => (
          <div className="text-center btn-group">
            <UncontrolledTooltip
              delay={0}
              placement="top"
              target={`edit-${row.value}`}
            >
              editar
            </UncontrolledTooltip>
            <Link
              id={`edit-${row.value}`}
              className="btn btn-white btn-sm"
              to={`/usuarios/${row.value}`}
            >
              <i className="fas fa-edit" />
            </Link>
            {this.hasPermission('login.acessar_como') && (
              <React.Fragment>
                <UncontrolledTooltip
                  delay={0}
                  placement="top"
                  target={`login-as-${row.value}`}
                >
                  acessar como
                </UncontrolledTooltip>
                <button
                  disabled={loginAsLoading}
                  id={`login-as-${row.value}`}
                  className="btn btn-white btn-sm"
                  onClick={() => this.handleLoginAs(row.original)}
                >
                  <i className="fas fa-sign-in-alt" />
                </button>
              </React.Fragment>
            )}
          </div>
        ),
      },
      {
        Header: 'Ativo',
        accessor: 'is_active',
        width: 80,
        className: 'd-flex justify-content-center',
        Cell: (row) => (
          <RawInputCheckbox
            checked={row.original.is_active}
            onChange={(e) => this.changeUserStatus(e, row.original.id)}
            disabled={changingUserStatus}
          />
        ),
      },
    ];
  }

  handleLoginAs = (user) => {
    this.setState({ loginAsLoading: `Acessando como ${user.last_name}...` });
    axios
      .get(`${API_HOST}/login/user/${user.id}/generate_login_url`)
      .then((response) => {
        let url = response.data.login_url;
        if (!url.startsWith('http')) {
          const http =
            process.env.NODE_ENV === 'production' ? 'https://' : 'http://';
          url = http + url;
        }
        window.location.replace(url);
      })
      .catch(() => {
        this.setState({ loginAsLoading: null });
        toast.error(
          'Não foi possível acessar como este usuário. Por favor, tente novamente.'
        );
      });
  };

  renderBreadcrumb = () => (
    <NavBreadCrumb currentPath="Usuários" hasBackslash={false} />
  );

  renderAddButton() {
    return (
      <Link to="/usuarios/new">
        <AddButton icon="plus" title="Novo usuário" />
      </Link>
    );
  }

  renderToolbar() {
    const { search } = this.state;

    return (
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <label className="container-check-custom">
            <input
              type="checkbox"
              id="selecionar-todos"
              aria-label=""
              onChange={this.selecionarTodos}
            />
            <span className="checkmark" />
          </label>
          {this.renderAddButton()}
        </div>
        <div className="d-flex">
          <div className="search">
            <input
              className="search-term"
              placeholder="Pesquisar..."
              value={search}
              onChange={(event) => {
                const s = event.target.value;
                this.setState({ search: s });
                this.debouncedFetchData(s);
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  renderList() {
    const { data, sorted, filtered } = this.state;
    return (
      <Table
        manual
        filterable
        className="table-default mb-3"
        columns={this.columns}
        data={data}
        showPagination={false}
        showPageSizeOptions={false}
        sorted={sorted}
        filtered={filtered}
        pageSize={this.pageSize}
        onSortedChange={(s) =>
          this.setState({ sorted: s, ordering: getOrdering(s) }, this.fetchData)
        }
        onFilteredChange={(f) => {
          this.params = getFilters(f);
          this.debouncedFetchData(null, false);
        }}
      />
    );
  }
}

export default withRouter(ListaUsuario);
