import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { get, size } from 'lodash-es';
import { toast } from 'react-toastify';
import Table from '../../components/Table';
import Loading from '../../components/Loading';
import CollapseButton from '../../components/CollapseButton';
import { API_HOST } from '../../consts';

class PedidosTab extends React.Component {
  metadataModels = [];

  constructor(props) {
    super(props);
    this.state = {
      collapsed: props.startCollapsed,
      fetchingElaboradores: true,
      elaboradores: [],
      entregas: [],
      data: {},
      metadata: []
    };
  }

  componentDidMount() {
    const { collapsed } = this.state;
    if (!collapsed) {
      this.fetchElaboradores();
    }
    this.fetchMetadata();
  }

  componentDidUpdate(_, prevState) {
    const { collapsed } = this.state;
    if (prevState.collapsed && !collapsed) {
      this.fetchElaboradores();
    }
  }

  mapMetadata(metadata) {
    const mapped = {};
    metadata.forEach((m) => {
      mapped[`${m.model}.${m.name}`] = m.requireness;
    });
    return mapped;
  }

  shouldDisplay(field) {
    const { metadata } = this.state;
    return get(metadata, field) > 0;
  }

  fetchMetadata() {
    this.setState({ fetchingMetadata: true });
    return axios
      .get(`${API_HOST}/params/param?model=${this.metadataModels.join('|')}`)
      .then((response) => {
        this.setState({
          metadata: this.mapMetadata(response.data),
          fetchingMetadata: false
        });
      });
  }

  get columns() {
    const { handleVerMais } = this.props;
    const statuses = get(this.state, 'data.statuses', []);
    return [
      {
        Header: 'Nome',
        accessor: 'nome'
      },
      {
        Header: 'Dificuldade',
        accessor: 'dificuldade'
      },
      {
        Header: 'Tipo',
        accessor: 'tipo',
        width: 100
      },
      {
        Header: 'Solicitados',
        accessor: 'quantidade'
      },
      {
        Header: 'Adicionar Item',
        width: 130,
        filterable: false,
        Cell: (row) => (
          <>
            <button
              onClick={() => this.handleNovoItem(row)}
              className="btn primary-button ml-2"
            >
              {this.shouldDisplay('item.encomenda') ? (
                <span>
                  <i
                    className="fas fa-plus"
                    style={{ marginRight: '0.5rem' }}
                  />{' '}
                  Encomenda
                </span>
              ) : (
                <span>
                  <i
                    className="fas fa-plus"
                    style={{ marginRight: '0.5rem' }}
                  />{' '}
                  Novo Item
                </span>
              )}
            </button>
          </>
        )
      },
      ...statuses.map((status) => ({
        Header: status.descricao,
        accessor: `statuses.${status.id}`,
        Cell: (row) => row.value || 0
      })),
      {
        Header: 'Ações',
        filterable: false,
        Cell: (row) => (
          <button onClick={() => handleVerMais(row)} className="btn btn-white">
            <i className="fas fa-eye" />
          </button>
        )
      }
    ];
  }

  get pageSize() {
    const length = get(this.state, 'data.elaboradores.length');
    const maxPageSize = 20;
    return length > maxPageSize ? maxPageSize : length;
  }

  get itensSolicitados() {
    const elaboradores = get(this.state, 'data.elaboradores', []);
    return elaboradores.reduce((acc, el) => acc + el.quantidade || 0, 0);
  }

  get itensEntregues() {
    const elaboradores = get(this.state, 'data.elaboradores', []);
    return elaboradores.reduce(this.reduceItensEntregues, 0);
  }

  reduceItensEntregues(acc, el) {
    const statuses = { ...get(el, 'statuses', {}) };
    delete statuses[1];
    return (
      acc + Object.values(statuses).reduce((acc2, value) => acc2 + value, 0)
    );
  }

  fetchElaboradores() {
    const { pedido } = this.props;
    axios
      .get(`${API_HOST}/encomenda/itens_por_status/elaborador`, {
        params: {
          pedido: get(pedido, 'id')
        }
      })
      .then((response) => {
        this.setState({ data: response.data, fetchingElaboradores: false });
      });

    this.fetchItensVinculados();
  }

  fetchItensVinculados() {
    const { pedido, getItensVinculados } = this.props;
    axios
      .get(`${API_HOST}/encomenda/item`, { params: { pedido: pedido.id } })
      .then((response) => {
        const itensVinculados = response.data.map((d) => d.id);
        getItensVinculados(itensVinculados);
      });
  }

  handleCollapse = () => {
    const { collapsed } = this.state;
    this.setState({ collapsed: !collapsed });
  };

  handleNovoItem = async (row) => {
    try {
      const { history, pedido } = this.props;
      this.setState({ handlingNovoItem: true });
      const response = await axios.post(
        `${API_HOST}/encomenda/pedido/${pedido.id}/item`,
        {
          dificuldade: row.original.dificuldade_id,
          tipo: row.original.tipo_id,
          id: row.original.id
        }
      );
      const params = {
        vincular: true,
        encomendas: [row.original.encomenda],
        id: row.original.id
      };
      await axios.post(
        `${API_HOST}/encomenda/finalizar_item/${response.data.id}`,
        params
      );
      history.push(`/itens/${response.data.id}/dados`);
    } catch (error) {
      const fieldError = get(error, 'response.data.non_field_errors');
      if (fieldError && fieldError.length > 0) {
        toast.error(fieldError[0]);
      } else if (get(error, 'response.status') === 403) {
        toast.error(get(error, 'response.data.detail'));
      } else {
        toast.error('Erro ao criar novo item.');
      }
    } finally {
      this.setState({ handlingNovoItem: false });
    }
  };

  render() {
    const { data, fetchingElaboradores, collapsed } = this.state;
    const { elaboradores } = data;
    const { pedido, handleVincularItens, labelCompetencia, labelHabilidade } =
      this.props;

    return (
      <Fragment key={get(pedido, 'id')}>
        <div className="container">
          <div className="professores-first">
            <div className="row">
              <div className="col-3">
                <p className="subtitle">Matriz de Referência</p>
                <p>{get(pedido, 'matriz_referencia.nome') || '-'}</p>
              </div>
              <div className="col-4">
                <p className="subtitle">{labelCompetencia}</p>
                <p>{get(pedido, 'competencia.descricao') || '-'}</p>
              </div>
              <div className="col-4">
                <p className="subtitle">{labelHabilidade}</p>
                <p>{get(pedido, 'habilidade.descricao') || '-'}</p>
              </div>
              <div className="col-1">
                <CollapseButton
                  detailPedido={true}
                  collapsed={collapsed}
                  onClick={this.handleCollapse}
                />
              </div>
            </div>
            {!collapsed && fetchingElaboradores && <Loading />}
            {!collapsed && !fetchingElaboradores && (
              <Fragment>
                <hr />
                <div className="row">
                  <div className="col-4">
                    <button
                      className="btn primary-button"
                      onClick={handleVincularItens}
                    >
                      VINCULAR ITENS
                    </button>
                  </div>
                  <div className="offset-4 col-2">
                    <p className="subtitle">Itens Solicitados:</p>
                    <p>{this.itensSolicitados}</p>
                  </div>
                  <div className="col-2">
                    <p className="subtitle">Itens Entregues:</p>
                    <p>{this.itensEntregues}</p>
                  </div>
                  <div className="col-md-12">
                    <Table
                      showPageSizeOptions={false}
                      pageSize={size(elaboradores)}
                      filterable
                      data={elaboradores}
                      columns={this.columns}
                    />
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

PedidosTab.propTypes = {
  pedido: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  startCollapsed: PropTypes.bool,
  handleVincularItens: PropTypes.func.isRequired,
  handleVerMais: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

PedidosTab.defaultProps = {
  startCollapsed: true
};

export default withRouter(PedidosTab);
