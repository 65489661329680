import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import axios from 'axios';
import Table from '../../components/Table';
import { API_HOST } from '../../consts';
import Loading from '../../components/Loading';
import Link from 'react-router-dom/es/Link';
import { get } from 'lodash';
import FormTable from '../../components/FormTable';
import ExcelExporter from '../../utils/excel';
export default class GabaritoModal extends React.Component {
  state = {
    data: [],
    pageSize: this.defaultPageSize,
    quantitativoData: [],
    pageSize: this.defaultPageSize
  };

  onOpened = () => {
    if (this.shouldFetchData) {
      this.shouldFetchData = false;
      this.fetchData();
      this.fetchQuatitativo();
    }
  };

  getPageSize(data) {
    if (data.length === 0) {
      return 1;
    }

    if (data.length < this.defaultPageSize) {
      return data.length;
    }

    return this.defaultPageSize;
  }

  formatItensToExport = (data) => {
    return data.map((dataAvaliacao) => {
      return {
        Posicao: dataAvaliacao.posicao,
        Gabarito: dataAvaliacao.gabarito,
        Item: dataAvaliacao.item,
        'Item no Exams': dataAvaliacao.id_exams,
        Elaborador: dataAvaliacao.criado_por,
        'Áreas do conhecimento': dataAvaliacao.areas_conhecimento,
        Dificuldade: dataAvaliacao.dificuldade,
        'Eixo/Disciplina': dataAvaliacao.disciplina
      };
    });
  };

  handleExportExcel = () => {
    const { data } = this.state;
    const dataToExport = this.formatItensToExport(data);
    const excelExporter = new ExcelExporter();
    excelExporter.export(dataToExport, 'itensReport', []);
  };

  shouldFetchData = true;
  defaultPageSize = 20;
  headersAlternativa = ['Letra', 'Quantidade', 'Porcentagem'];
  columns = [
    {
      Header: 'Posição',
      accessor: 'posicao',
      width: 70
    },
    {
      Header: 'Gabarito',
      accessor: 'gabarito',
      width: 70
    },
    {
      Header: 'Item',
      accessor: 'item',
      width: 80
    },
    {
      Header: 'Item no Exams',
      accessor: 'id_exams'
    },
    {
      Header: 'Autor',
      accessor: 'item_autor'
    },
    {
      Header: 'Elaborador',
      accessor: 'criado_por'
    },
    {
      Header: 'Áreas do conhecimento',
      accessor: 'areas_conhecimento'
    },
    {
      Header: 'Dificuldade',
      accessor: 'dificuldade'
    },
    {
      Header: 'Eixo/Disciplina',
      accessor: 'disciplina'
    }
  ];

  fetchData() {
    this.setState({ fetchingData: true });
    const { avaliacao } = this.props;

    axios
      .get(`${API_HOST}/avaliacoes/avaliacao/${avaliacao}/gabarito`)
      .then((response) => {
        this.setState({
          data: response.data,
          fetchingData: false,
          pageSize: this.getPageSize(response.data)
        });
      });
  }

  fetchQuatitativo() {
    this.setState({ fetchingQuatitativo: true });
    const { avaliacao } = this.props;

    axios
      .get(
        `${API_HOST}/avaliacoes/avaliacao/${avaliacao}/quantidade_alternativas`
      )
      .then((response) => {
        this.setState({
          quantitativoData: response.data,
          fetchingQuatitativo: false
        });
      });
  }

  renderBody = () => {
    const { data, fetchingData, pageSize, quantitativoData } = this.state;

    if (fetchingData) {
      return <Loading />;
    }
    const tableData = [];
    quantitativoData &&
      quantitativoData.map((d) => {
        tableData.push([d.letra, d.quantidade, `${d.porcentagem}%`]);
        return tableData;
      });
    return (
      <div style={{ padding: '0 17px' }}>
        <h5>Quantitativo das alternativas</h5>
        {get(quantitativoData, 'length') > 0 ? (
          <FormTable headers={this.headersAlternativa} data={tableData} />
        ) : (
          <span style={{ fontWeight: 600 }}>
            Este instrumento não possui Alternativas
          </span>
        )}
        <hr />
        <Table
          columns={this.columns}
          data={data}
          pageSize={pageSize}
          onPageSizeChange={(pageSize) => this.setState({ pageSize })}
        />
      </div>
    );
  };

  render() {
    const { toggle, isOpen, avaliacao } = this.props;

    return (
      <Modal
        filterable
        className="table-default"
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        onOpened={this.onOpened}
      >
        <ModalHeader toggle={toggle}>Itens</ModalHeader>
        <ModalBody className="bg-white pb-0">
          <div className="row">
            <div className="col-12 p-0">
              <div
                className="btn-group mt-2 mb-2 ml-3"
                role="group"
                aria-label="Actions"
              >
                <Link
                  className="btn btn-white"
                  to={`/instrumentos-cognitivos/gabarito/${avaliacao}/impressao`}
                >
                  <i className="fas fa-print" />
                </Link>
                <button
                  className="btn btn-white"
                  onClick={() => this.handleExportExcel(null)}
                >
                  <i className="far fa-file-excel" />
                </button>
              </div>
              {this.renderBody()}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-4 text-right">
            <button className="btn btn-white" onClick={toggle}>
              Fechar
            </button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}
