import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Axios from 'axios';
import { get } from 'lodash';
import { API_HOST } from '../../consts';
import { useEffect } from 'react';
import HeaderAvaliaEscola from '../../components/HeaderAvaliaEscola';
import { FooterAvalia } from '../../containers/global/FooterAvalia/styles';
import { Nav, NavItem } from 'reactstrap';
import { ThemeContext } from '../../contexts/ThemeContext';
import Logo from '../../assets/images/logos/Logos';
import AvaliaLogo from '../../assets/images/logos/avalia-logo.png';
import ProficiencyScaleDreColumns from '../../components/ProficiencyScaleDreColumns';
import { toast } from 'react-toastify';


const ProficiencyScalePage = ({ match }) => {
  const [schoolLevel, setSchoolLevel] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const fetchSchoolLevel = () => {
    Axios.get(
      `${API_HOST}/avalia/distribuicao_percentual?cd_escola=${match.params.escolaId}&prova_a_cd_disciplina=${match.params.discId}`
    )
      .then((res) => {
        if (res.data.results.length == 0 ){
          return toast.error('Não existem dados para a consulta realizada')
        }
        else{
          setLoading(true);
          return setSchoolLevel(res.data.results);

        }
      })
      .catch((err) => toast.error('Não existem dados para a consulta realizada'));
  };
  useEffect(() => {
    fetchSchoolLevel();
  }, []);

  const titulo = [
    'Linguagens, Códigos e suas Tecnologias',
    'Matemática e suas Tecnologias'
  ];

  return (
    <>
      <div className="container">
        <div className="row header-row">
          <div className="col-sm-6 col-md-6">
            <div className="col-sm-6 col-md-6">
              <Nav className="nav-logos">
                <NavItem>
                  <figure>
                    <ThemeContext.Consumer>
                      {(theme) => (
                        <img
                          className="logo-main"
                          src={AvaliaLogo}
                          alt={Logo.clientText || Logo.mainText}
                          height="70"
                          style={{ opacity: '0' }}
                        />
                      )}
                    </ThemeContext.Consumer>
                  </figure>
                </NavItem>
              </Nav>
            </div>
          </div>
        </div>
      </div>
      <div
        id="nav-expand"
        className="nav-expand"
        style={{ background: '#3C4583' }}
      >
        <Nav
          className="navbar-links"
          style={{ background: '#3C4583', border: 'none' }}
        />
      </div>
      <div style={{ marginTop: '5rem', background: '#fff' }}>
        <div className="container mb-4 pb-4">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h1>
                    Distribuição percentual dos alunos nos níveis da escala de
                    proficiência
                  </h1>
                  <hr />
                </div>
                <div
                  className="card-body"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                >
                  <HeaderAvaliaEscola
                    schoolName={
                      schoolLevel ? get(schoolLevel[0], 'nm_escola', '') : ''
                    }
                    dreAndMunicipio={
                      schoolLevel ? get(schoolLevel[0], 'dre', '') : ''
                    }
                    disableSchoolYear={true}
                    disableDreAndMunicipio={false}
                  />
                  <div className="school__material">
                    <span>
                      {
                        titulo[
                          schoolLevel
                            ? get(schoolLevel[0], 'prova_a_cd_disciplina', '') -
                              1
                            : ''
                        ]
                      }
                    </span>
                  </div>
                  {schoolLevel &&
                    schoolLevel.map((dados) => (
                      <div className="mt-2" style={{ width: '100%' }}>
                        <div
                          style={{
                            background: '#3c4583',
                            borderTopLeftRadius: '10px',
                            borderTopRightRadius: '10px'
                          }}
                        >
                          <div
                            className="basic__info"
                            style={{
                              padding: '0.3rem',
                              justifyContent: 'center'
                            }}
                          >
                            <label
                              style={{ color: 'white', textAlign: 'center' }}
                            >
                              {' '}
                              {dados.prova_a_prova_ds}{' '}
                            </label>
                          </div>
                        </div>
                        <ProficiencyScaleDreColumns
                          dadosTable={dados}
                          pSize={schoolLevel}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterAvalia />
    </>
  );
};

export default withRouter(ProficiencyScalePage);
