import React, { useContext } from 'react';
import ProficiencyScaleDreTables from './ProficiencyScaleDreTables';
import withRouter from 'react-router-dom/withRouter';
import { isNull } from 'lodash';
import { ThemeContext } from 'styled-components';

const ProficiencyScaleDreColumns = ({
  hideHeader,
  dadosTable,
  pSize,
  ...rest
}) => {
  const theme = useContext(ThemeContext);
  const columns = [
    {
      Header: () => <span style={{ fontWeight: 'bold' }}>Nível</span>,
      headerStyle: {
        'border-top-left-radius': '10px',
        'border-bottom-left-radius': '10px',
        'border-left': '10px',
        textAlign: 'center',
        'background-color': `${theme.primary}`,
        width: '799.29px',
        height: '47.96px',
        color: 'white'
      },
      Cell: (row) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          {row.original.nivel === 'Plano Amostral' ? (
            <div style={{ fontWeight: 'bold', fontSize: '1rem' }}>
              {row.original.nivel}
            </div>
          ) : (
            <div>{row.original.nivel}</div>
          )}
        </div>
      ),
      sortable: false
    },
    {
      Header: () => (
        <div>
          <span style={{ fontWeight: 'bold' }}>Abaixo do Básico</span>
          <br />
          <span>{dadosTable.status['Abaixo do Básico']}</span>
        </div>
      ),
      headerStyle: {
        textAlign: 'center',
        'background-color': `${theme.primary}`,
        width: '799.29px',
        height: '47.96px',
        color: 'white'
      },
      Cell: (row) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          {isNull(row.original.abaixoBasico) ? (
            '-'
          ) : (
            <div>{row.original.abaixoBasico}</div>
          )}
        </div>
      ),
      className: 'd-flex justify-content-center align-items-center',
      sortable: false
    },
    {
      Header: () => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white'
          }}
        >
          <span style={{ fontWeight: 'bold' }}>Básico</span>
          <span>{dadosTable.status['Básico']}</span>
        </div>
      ),
      headerStyle: {
        textAlign: 'center',
        'background-color': `${theme.primary}`,
        width: '799.29px',
        height: '47.96px',
        color: 'white'
      },
      Cell: (row) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          {isNull(row.original.basico) ? '-' : <div>{row.original.basico}</div>}
        </div>
      ),
      sortable: false,
      className: 'd-flex justify-content-center align-items-center'
    },
    {
      Header: () => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <span style={{ fontWeight: 'bold' }}>Adequado</span>
          <span>{dadosTable.status['Adequado']}</span>
        </div>
      ),
      headerStyle: {
        textAlign: 'center',
        'background-color': `${theme.primary}`,
        width: '799.29px',
        height: '47.96px',
        color: 'white'
      },
      Cell: (row) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          {isNull(row.original.adequado) ? (
            '-'
          ) : (
            <div>{row.original.adequado}</div>
          )}
        </div>
      ),
      sortable: false,
      className: 'd-flex justify-content-center align-items-center'
    },
    {
      Header: () => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <span style={{ fontWeight: 'bold' }}>Avançado</span>
          <span>{dadosTable.status['Avançado']}</span>
        </div>
      ),
      headerStyle: {
        'border-top-right-radius': '10px',
        'border-bottom-right-radius': '10px',
        textAlign: 'center',
        'background-color': `${theme.primary}`,
        width: '799.29px',
        height: '47.96px',
        color: 'white'
      },
      Cell: (row) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          {isNull(row.original.avancado) ? (
            '-'
          ) : (
            <div>{row.original.avancado}</div>
          )}
        </div>
      ),
      sortable: false,
      className: 'd-flex justify-content-center align-items-center'
    }
  ];

  return (
    <div className="mt-2 mb-2" style={{ width: '100%' }}>
      <ProficiencyScaleDreTables
        columns={columns}
        data={dadosTable.dados_etapa_escolar}
        pSize={dadosTable.dados_etapa_escolar.length}
        length={pSize.length}
        {...rest}
      />
    </div>
  );
};

export default withRouter(ProficiencyScaleDreColumns);
