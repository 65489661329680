import React from 'react';
import PropTypes from 'prop-types';
import Plagius from '../../components/Plagius';
import Loading from '../../components/Loading';

class DetectorPlagio extends React.Component {
  render() {
    const { item } = this.props;
    return (
      <div className="mb-3">
        <div className="card card-bg">
          <div className="card-header pb-0">
            <h1>Detector de plágio</h1>
          </div>
          <div className="card-body">
            {item ? <Plagius item={item} /> : <Loading />}
          </div>
        </div>
      </div>
    );
  }
}

DetectorPlagio.propTypes = {
  item: PropTypes.number,
};

DetectorPlagio.defaultProps = {
  item: null,
};

export default DetectorPlagio;
