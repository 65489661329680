import PropTypes from 'prop-types';
import axios from 'axios';
import { API_HOST } from '../consts';
import S3Upload from './S3Upload';
import DiskUpload from './DiskUpload';

class Uploader {
  constructor(props) {
    this.props = props;
    this.storage = '';
    this.uploadUrl = '';
  }

  async fetchUploadDetails() {
    const { file } = this.props;
    const response = await axios.get(`${API_HOST}/uploads/upload_details/${file.type}`);
    this.storage = response.data.storage;
    this.uploadUrl = response.data.url;
  }

  get uploadProps() {
    return { ...this.props, uploadUrl: this.uploadUrl };
  }

  sendToStorage() {
    switch (this.storage) {
      case 's3': {
        const s3Upload = new S3Upload(this.uploadProps);
        return s3Upload.upload();
      }
      case 'disk': {
        const diskUpload = new DiskUpload(this.uploadProps);
        return diskUpload.upload();
      }
      default:
        break;
    }
    return null;
  }

  async upload() {
    await this.fetchUploadDetails();
    this.sendToStorage();
  }
}

Uploader.propTypes = {
  file: PropTypes.instanceOf('File'),
  onUploadProgress: PropTypes.func,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

export default Uploader;
