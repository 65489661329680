import React from 'react';

import {
  HomeCard as AppHomeCard,
  HomeCardTitle,
} from './styles';

export default class HomeCard extends React.Component {
  render() {
    const { children, icon, title } = this.props;
    return (
      <AppHomeCard>
        <HomeCardTitle><i className={icon} />{title}</HomeCardTitle>
        <div className="card-body">
          {children}
        </div>
      </AppHomeCard>
    );
  }
}
