import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { tableTextFilterMethod } from '../utils';
import CellLabel from './CellLabel';
const translations = {
	previousText: 'anterior',
	nextText: 'próximo',
	noDataText: 'nenhum dado encontrado',
	pageText: 'pág.',
	ofText: 'de',
	rowsText: 'linhas por página',
};
const mapToResponsiveColumn = (column) => {
	const DefaultCell = (row) => <Fragment>{row.value}</Fragment>;
	const Cell = column.Cell || DefaultCell;
	return {
		...column,
		Cell: (row) => (
			<CellLabel row={row}>
				<Cell {...row} />
			</CellLabel>
		),
	};
};
const TableAvalia = ({ isResponsive, columns, defaulte, ...props }) => {
	const getColumns = () =>
		isResponsive ? columns.map(mapToResponsiveColumn) : columns;
	return (
		<ReactTable
			defaultFilterMethod={tableTextFilterMethod}
			className={`${defaulte === "table-student-diagnosis-default" ? "table-student-diagnosis-default" : "table-avalia-default"} ${isResponsive ? 'table-responsive' : ''}`}
			columns={getColumns()}
			onSortedChange={(d) => props.onSortedChange(d)}
			{...props}
			{...translations}
		/>
	);
};
TableAvalia.propTypes = {
	isResponsive: PropTypes.bool,
	columns: PropTypes.arrayOf(PropTypes.object).isRequired,
	defaulte: PropTypes.string,
};
TableAvalia.defaultProps = {
	isResponsive: false,
};
export default TableAvalia;
