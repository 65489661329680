import React from 'react';
import { Form, Scope } from 'informed';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import { get, map, sortBy, size, debounce } from 'lodash';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import { API_HOST } from '../../consts';
import FormContainer from '../../containers/Form';
import Loading from '../../components/Loading';
import InputSelect from '../../components/inputs/InputSelect';
import InputText from '../../components/inputs/InputText';
import InputSelectAsync from '../../components/inputs/InputSelectAsync';
import BaseCreate from '../../base/BaseCreate';
import RawInputSummernote from '../../components/inputs/RawInputSummernote';

const mapElaborador = (d) => ({ value: d.id, label: d.last_name });

class Pedido extends BaseCreate {
  state = {
    matrizes_referencia: [],
    markedForDeletion: [],
    fetchingData: true,
    instituicao: null
  };

  componentDidMount() {
    this.fetchApi({
      link: '/itens/criado_por',
      name: 'elaboradores',
      label: 'last_name'
    });
    this.fetchApi({ link: '/itens/dificuldade', name: 'dificuldades' });
    this.fetchApi({ link: '/itens/tipo', name: 'tipos' });
    this.fetchData();
    this.fetchEncomenda();
    this.fetchMetadata();
  }

  fetchEncomenda() {
    axios.get(`${API_HOST}/encomenda/encomenda/${this.id}`).then((response) => {
      if (response.data.instituicao) {
        this.setState({ instituicao: response.data.instituicao.id });
      }
    });
  }

  fetchData() {
    if (this.id !== 'new') {
      axios
        .get(`${API_HOST}/encomenda/pedido`, {
          params: {
            encomenda: this.id,
            ordering: '-id'
          }
        })
        .then((response) => {
          this.setState({ fetchingData: false });
          const pedidos = response.data;
          pedidos.forEach((p) => {
            p.elaboradores = sortBy(p.elaboradores, ['id']);
            p.elaboradores.reverse();
          });
          this.formApi.setValues({ pedidos });
          this.updateQuantidadePreenchida(this.formApi.getState());
        });
    } else {
      this.setState({ fetchingData: false });
    }
  }

  defaultElaboradorOptions(elaborador, options = []) {
    let selectedOption;
    const usuario = get(elaborador, 'usuario');

    if (usuario) {
      const usuario__str = get(elaborador, 'usuario__str');
      selectedOption = {
        value: usuario,
        label: usuario__str
      };
    }

    if (selectedOption) {
      const selectedOptionInOptions = options.find(
        (d) => d.value === selectedOption.value
      );
      if (!selectedOptionInOptions) {
        return [selectedOption];
      }
    }

    return [];
  }

  fetchElaboradores = (elaborador) =>
    debounce((input, callback) => {
      if (!input) {
        callback(null, { options: this.defaultElaboradorOptions(elaborador) });
        return;
      }
      const { instituicao } = this.state;
      axios
        .get(`${API_HOST}/login/user`, {
          params: {
            search: input,
            ordering: 'last_name',
            permissions: 'itens.add_item',
            instituicoes: instituicao,
            page_size: 30
          }
        })
        .then((response) => {
          let options = response.data.results.map(mapElaborador);
          options = [
            ...this.defaultElaboradorOptions(elaborador, options),
            ...options
          ];
          callback(null, { options });
        });
    }, 1000);

  handleSubmit = (values) => {
    const { descricao } = this.state;
    if (this.submitting) {
      return;
    }

    this.submitting = true;

    const { markedForDeletion } = this.state;
    this.setState({ submitting: true });
    let data = [];

    values.pedidos.forEach((pedido) => {
      data = [
        ...pedido.elaboradores.map((d, i) => ({
          ...d,
          descricao: descricao ? descricao[get(d, 'id')] : get(d, 'descricao'),
          usuario: get(d, 'usuario.value') || get(d, 'usuario')
        })),
        ...data
      ];
    });

    axios
      .put(`${API_HOST}/encomenda/elaborador`, data, {
        params: {
          delete: markedForDeletion.join(',')
        }
      })
      .then(() => {
        const { history } = this.props;
        history.push(this.next);
      })
      .catch(() => {
        this.setState({ submitting: true });
        toast.error('Todos os campos devem ser preenchidos.');
        this.submitting = false;
      });
  };

  add = (i) => {
    const pedidos = this.formApi.getState().values.pedidos || [];
    const elaboradores = pedidos[i].elaboradores || [];
    pedidos[i] = {
      ...pedidos[i],
      elaboradores: [{ pedido: pedidos[i].id }, ...elaboradores]
    };
    this.formApi.setValues({ pedidos });
  };

  remove = (i, j) => {
    const pedidos = this.formApi.getState().values.pedidos || [];
    const { elaboradores, ...rest } = pedidos[i];
    const { id } = elaboradores[j];
    elaboradores.splice(j, 1);
    pedidos[i] = {
      elaboradores: elaboradores || [],
      ...rest
    };
    this.formApi.setValues({ pedidos });

    const { markedForDeletion } = this.state;
    this.setState({ markedForDeletion: [id, ...markedForDeletion] });
  };

  updateQuantidadePreenchida = (formState) => {
    const { pedidos } = formState.values;
    let value = 0;
    pedidos.forEach((p) => {
      value += p.elaboradores.reduce((acc, x) => acc + +x.quantidade, 0);
    });
    this.setState({ quantidadePreenchida: isFinite(value) ? value : '!' });
  };

  updateDescricao = (id, value) => {
    const { descricao } = this.state;
    this.setState({ descricao: { ...descricao, [id]: value } });
  };

  renderRemoveButton(i, j) {
    return (
      <button
        type="button"
        className="btn btn-outline mt-4"
        onClick={() => this.remove(i, j)}
      >
        <i className="fas fa-times" />
      </button>
    );
  }

  renderQuantidadePreenchida(p) {
    const { quantidadePreenchida } = this.state;
    const itensNecessarios = get(p, 'total_itens_encomenda', 0);

    return (
      <React.Fragment>
        <label>Itens atribuídos</label>
        <p
          className={classnames({
            'text-danger': quantidadePreenchida > itensNecessarios,
            'text-success': quantidadePreenchida === itensNecessarios
          })}
        >
          {quantidadePreenchida}
        </p>
      </React.Fragment>
    );
  }

  render() {
    const { fetchingData, submitting, dificuldades, tipos } = this.state;

    return (
      <Form
        onChange={(formState) => this.setState({ formState })}
        getApi={(formApi) => {
          this.formApi = formApi;
        }}
        onSubmit={this.handleSubmit}
        initialValues={{ pedidos: [] }}
      >
        {({ formState }) => {
          if (fetchingData) {
            return <Loading />;
          }

          return (
            <React.Fragment>
              <FormContainer
                title="Elaboradores"
                next="revisao"
                previous="matrizes-referencia"
                onClick={this.handleNext}
                disabled={submitting}
              >
                {formState.values.pedidos.map((p, i) => (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card mb-3">
                        <div className="card-body bg-white no-overflow">
                          <React.Fragment>
                            <label className="form-group mb-0">
                              Matriz de referência
                            </label>
                            <p>{get(p, 'matriz_referencia.nome')}</p>
                            <label className="form-group mb-0">
                              {this.shouldDisplayDefault(
                                'matriz.competencia'
                              ) || 'Competência'}
                              s
                            </label>
                            <p>{get(p, 'competencia.descricao')}</p>
                            <label className="form-group mb-0">
                              {this.shouldDisplayDefault('matriz.habilidade') ||
                                'Habilidade'}
                            </label>
                            <p>{get(p, 'habilidade.descricao')}</p>
                            <div className="row mb-3">
                              <div className="col-md-6">
                                <button
                                  type="button"
                                  className="btn primary-button"
                                  onClick={() => this.add(i)}
                                >
                                  <i className="fas fa-plus" /> Adicionar
                                  elaborador
                                </button>
                              </div>
                            </div>
                            <Scope scope={`pedidos[${i}]`}>
                              <div className="row">
                                <div className="col-md-4 form-group mb-0">
                                  <label>Itens solicitados</label>
                                  <p>{get(p, 'total_itens_encomenda')}</p>
                                </div>
                                <div className="col-md-4 form-group mb-0">
                                  {this.renderQuantidadePreenchida(p)}
                                </div>
                              </div>
                              {map(
                                formState.values.pedidos[i].elaboradores,
                                (e, j) => (
                                  <Scope scope={`elaboradores[${j}]`}>
                                    <hr />
                                    <div className="row">
                                      <InputSelectAsync
                                        autoload={false}
                                        col="col-md-5 col-sm-12"
                                        label="Elaborador"
                                        options={this.defaultElaboradorOptions(
                                          e
                                        )}
                                        loadOptions={this.fetchElaboradores(e)}
                                        field="usuario"
                                      />
                                      <InputSelect
                                        col="col-md-5 col-sm-12"
                                        label="Dificuldade"
                                        options={dificuldades}
                                        field="dificuldade"
                                      />
                                      <InputSelect
                                        col="col-md-5 col-sm-12"
                                        label="Tipo"
                                        options={tipos}
                                        field="tipo"
                                      />
                                      <InputText
                                        col="col-md-5 col-sm-12"
                                        label="Quantidade"
                                        field="quantidade"
                                        onChange={() =>
                                          this.updateQuantidadePreenchida(
                                            formState
                                          )
                                        }
                                      />
                                      <div className="col-md-1 col-sm-1">
                                        {this.renderRemoveButton(i, j)}
                                      </div>
                                      {this.shouldDisplay(
                                        'item.ver_descricao'
                                      ) && (
                                        <div className="col-md-12 form-group">
                                          <label>Descrição</label>
                                          <RawInputSummernote
                                            value={get(e, 'descricao') || ''}
                                            onChange={(event) =>
                                              this.updateDescricao(
                                                get(
                                                  formState,
                                                  `values.pedidos[${i}].elaboradores[${j}].id`
                                                ),
                                                event
                                              )
                                            }
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </Scope>
                                )
                              )}
                            </Scope>
                          </React.Fragment>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {size(formState.values.pedidos) === 0 && (
                  <p>
                    Por favor, antes de prosseguir,{' '}
                    <Link to="matrizes-referencia">clique aqui</Link> para
                    definir as matrizes de referência.
                  </p>
                )}
              </FormContainer>
            </React.Fragment>
          );
        }}
      </Form>
    );
  }
}

export default withRouter(Pedido);
