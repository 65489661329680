import axios from 'axios';
import { get } from 'lodash';
import React, { Fragment, lazy, Suspense } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import PrivateRoute from '../../components/PrivateRoute';
import { API_HOST, UserContext } from '../../consts';
import * as pages from '../../pages/index';
import withTracker from '../../withTracker';
import Header from './Header';

const Itens = lazy(() => import('../../routers/Itens'));
const Relatorios = lazy(() => import('../../routers/Relatorios'));
const MatrizesReferencia = lazy(() =>
  import('../../routers/MatrizesReferencia')
);
const Analise = lazy(() => import('../../routers/Analise'));
const Testes = lazy(() => import('../../routers/Testes'));
const Avaliacoes = lazy(() => import('../../routers/Avaliacoes'));
const Usuarios = lazy(() => import('../../routers/Usuarios'));
const Recursos = lazy(() => import('../../routers/Recursos'));
const Cruds = lazy(() => import('../../routers/Cruds'));

class MainSection extends React.Component {
  constructor(props) {
    super(props);

    const token = localStorage.getItem('token');
    if (token) {
      axios.defaults.headers.common.Authorization = `JWT ${token}`;
    } else {
      delete axios.defaults.headers.common.Authorization;
    }

    axios.interceptors.response.use(null, (error) => {
      const status = get(error, 'response.status');
      if (status === 401) {
        const { history } = props;
        // const message = 'Sessão expirada.';

        localStorage.removeItem('token');
        // toast.error(message);
        history.push('/login');
      } else if (status === 403) {
        const message = get(
          error,
          'response.data.detail',
          'Você não tem permissão para executar essa ação.'
        );
        toast.error(message);
      }

      return Promise.reject(error);
    });
  }

  state = {
    features: [],
    permissions: {},
    menu: [],
    user: {}
  };

  componentDidMount() {
    this.fetchUser();
    this.fetchPermissions();
    this.fetchMenu();
  }

  componentDidUpdate(prevProps) {
    const prevPath = get(prevProps, 'location.pathname');
    const path = get(this.props, 'location.pathname');
    if (prevPath !== path) {
      window.scrollTo(0, 0);
    }
  }

  get contextProps() {
    const { features, permissions, user, menu } = this.state;

    const hasPermission = (name) => {
      return permissions.__all__ || permissions[name];
    };

    return {
      features,
      permissions,
      user,
      menu,
      hasPermission
    };
  }

  get shouldRenderAllMenus() {
    const { permissions } = this.state;
    return get(permissions, '__all__');
  }

  fetchUser() {
    axios.get(`${API_HOST}/login/me`).then((response) => {
      this.setState({ user: response.data });
    });
  }

  fetchPermissions() {
    axios.get(`${API_HOST}/login/me/permissions`).then((response) => {
      this.setState({ permissions: response.data });
    });
  }

  fetchMenu() {
    axios.get(`${API_HOST}/menu/menu`).then((response) => {
      this.setState({ menu: response.data });
    });
  }

  renderSuspenseFallback() {
    return (
      <Fragment>
        <Header {...this.contextProps} />
        <Loading />
      </Fragment>
    );
  }

  render() {
    return (
      <Suspense fallback={this.renderSuspenseFallback()}>
        <UserContext.Provider value={this.contextProps}>
          <div id="dropdown-content">
            <Switch>
              <PrivateRoute exact path="/" component={pages.HomePage} />
              <PrivateRoute path="/perfil" component={pages.PerfilUsuario} />
              <Route
                path="/matrizes-referencia"
                render={() => <MatrizesReferencia />}
              />
              <Route
                path="/(itens|revisoes|encomendas)"
                render={() => <Itens />}
              />
              <Route path="/analise" render={() => <Analise />} />
              <Route path="/(pre-testes|testes)" render={() => <Testes />} />
              <Route
                path="/(instrumentos-cognitivos|aplicacoes)"
                render={() => <Avaliacoes />}
              />
              <Route
                path="/(exames|competencias|habilidades|eixos)"
                render={() => <Cruds />}
              />
              <Route path="/usuarios" render={() => <Usuarios />} />
              <Route path="/recursos" render={() => <Recursos />} />
              <Route
                path="/relatorio-(aluno|nivel-habilidade|dres|turmas|distribuicao-proficiencia-aluno)"
                render={() => <Relatorios />}
              />
            </Switch>
          </div>
        </UserContext.Provider>
      </Suspense>
    );
  }
}

export default withTracker(withRouter(MainSection));
