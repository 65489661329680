import React from 'react';

class Detalhes extends React.Component {
  render() {
    const { children, header } = this.props;

    return (
      <div className="dados-gerais-style">
        <div className="dadosGerais col-lg-12 col-md-12 col-xs-12 card-bg">
          <div className="card-header">
            {header}
          </div>
          <div className="card-body">
            <div className="row">
              {children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Detalhes.defaultProps = {
  header: 'Detalhes',
};

export default Detalhes;
