import React from 'react';
import PropTypes from 'prop-types';

const InfoBoxItem = ({ className, label, value }) => (
  <div className={className}>
    <p className="mb-0 info-box-item"><strong className="azul">{label}:</strong> {value}</p>
  </div>
);

InfoBoxItem.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  value: PropTypes.node,
};

InfoBoxItem.defaultProps = {
  className: 'col-md-4',
  value: '',
};

export default InfoBoxItem;
