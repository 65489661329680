import React from 'react';
import t from '../i18n';

class ViewReference extends React.Component {
  render() {
    return (
      <div className="card-reference view one">
        <p>{t('EIXOS COGNITIVOS')}</p>
        <p>COMPETÊNCIAS</p>
      </div>
    );
  }
}
export default ViewReference;
