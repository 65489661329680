import React from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter, Redirect, Switch } from 'react-router-dom';
import { get } from 'lodash';
import NavBreadCrumb from '../../containers/NavBreadCrumb';
import ProgressTracker from '../../containers/ProgressTracker';
import Etapa1 from './Etapa1';
import Etapa2 from './Etapa2';

import Axios from 'axios';
import { API_HOST } from '../../consts';
import ConteudoRevisao from '../itens/ConteudoRevisao';

class Revisao extends React.Component {
  get id() {
    return get(this.props, 'match.params.id');
  }

  getCurrentPath() {
    return `Revisão ${this.id}`;
  }

  get canViewPlagiusDetector() {
    const permissions = get(this.props, 'permissions') || {};
    // eslint-disable-next-line
    return permissions.__all__ || permissions['itens.view_plagio'];
  }

  state = {
    item_id: ''
  };

  componentDidMount() {
    this.fetchRevisao();
  }

  fetchRevisao() {
    Axios.get(`${API_HOST}/revisoes/revisao/${this.id}`).then((response) => {
      const { item, editar_item } = response.data;
      this.setState({
        editar_item: editar_item,
        item_id: item
      });
    });
  }

  getSteps() {
    const {
      location: { pathname }
    } = this.props;

    const { item_id, editar_item } = this.state;
    if (editar_item) {
      return [
        {
          id: 1,
          title: 'Etapa 1',
          path: '/revisoes/:id/edit/1',
          link: `/revisoes/${this.id}/edit/1`,
          active: pathname === `/revisoes/${this.id}/edit/1`,
          component: (props) => (
            <Etapa1
              canViewPlagiusDetector={this.canViewPlagiusDetector}
              {...props}
            />
          ),
          disabled: false
        },
        {
          id: 2,
          title: 'Etapa 2',
          path: '/revisoes/:id/edit/2',
          link: `/revisoes/${this.id}/edit/2`,
          active: pathname === `/revisoes/${this.id}/edit/2`,
          component: (props) => (
            <ConteudoRevisao
              revisao_id={this.id}
              item_id={item_id}
              editar_item={editar_item}
              {...props}
            />
          ),
          disabled: false
        },
        {
          id: 3,
          title: 'Etapa 3',
          path: '/revisoes/:id/edit/3',
          link: `/revisoes/${this.id}/edit/3`,
          active: pathname === `/revisoes/${this.id}/edit/3`,
          component: Etapa2,
          disabled: false
        }
      ];
    }
    return [
      {
        id: 1,
        title: 'Etapa 1',
        path: '/revisoes/:id/edit/1',
        link: `/revisoes/${this.id}/edit/1`,
        active: pathname === `/revisoes/${this.id}/edit/1`,
        component: (props) => (
          <Etapa1
            canViewPlagiusDetector={this.canViewPlagiusDetector}
            {...props}
          />
        ),
        disabled: false
      },
      {
        id: 2,
        title: 'Etapa 2',
        path: '/revisoes/:id/edit/2',
        link: `/revisoes/${this.id}/edit/2`,
        active: pathname === `/revisoes/${this.id}/edit/2`,
        component: Etapa2,
        disabled: false
      }
    ];
  }

  render() {
    const pathList = [
      {
        label: 'Itens',
        icon: 'list',
        link: '/itens'
      }
    ];

    const steps = this.getSteps();

    return (
      <div>
        <NavBreadCrumb
          pathList={pathList}
          currentPath={this.getCurrentPath()}
        />
        <div className="container">
          <div className="row">
            <div className="col-md-3 mr-0">
              <ProgressTracker steps={steps} />
            </div>
            <div className="col-md-9">
              <Switch>
                {steps.map((step, index) => (
                  <Route
                    key={step.id}
                    path={step.path}
                    render={() =>
                      step.disabled ? (
                        <Redirect to="/itens" />
                      ) : (
                        <step.component step={index} />
                      )
                    }
                  />
                ))}
                <Route
                  path="/revisoes/:id/edit"
                  render={() => <Redirect to={`/revisoes/${this.id}/edit/1`} />}
                />
                <Redirect to="/itens" />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Revisao.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
};

export default withRouter(Revisao);
