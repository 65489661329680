import React from 'react';
import { connect } from 'react-redux';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, ButtonDropdown, ButtonGroup } from 'reactstrap';
import Icon from 'react-fa';
import ImportarParametrosModal from './ImportarParametrosModal';
import { toggleImportarParametrosModal, sortParametrosImportados } from '../../actions/AnalisePsicometrica';

class ReviewHead extends React.Component {
  state = {
    baixarModeloAnaliseIsOpen: false,
  };

  render() {
    return (
      <div className="review-head row">
        <div className="col-sm-12">
          <ButtonGroup className="analise-psicometrica-toolbar">
          <button onClick={this.props.toggleImportarParametrosModal} className="btn btn-padrao bg-padrao">
            <Icon name="upload" /> Importar Parâmetros
          </button>

          <Dropdown
            isOpen={this.state.baixarModeloAnaliseIsOpen}
            toggle={() => this.setState({ baixarModeloAnaliseIsOpen: !this.state.baixarModeloAnaliseIsOpen })}
          >
            <DropdownToggle caret className="primary-button">
              Baixar Modelo de Análise
            </DropdownToggle>
            <DropdownMenu>
               <DropdownItem>Parâmetros para pré-teste</DropdownItem>
               <DropdownItem>Curva de caracteristica dos itens</DropdownItem>
               <DropdownItem>Curva de proporção de resposta</DropdownItem>
            </DropdownMenu>
          </Dropdown>

{/*        <Col sm="4">
          <Search />
        </Col>
        <Col sm="1">
          <BtnIcon icon="filter" label="Filtro"/>
        </Col>*/}

          <ButtonDropdown
            isOpen={this.state.sortDropdown}
            toggle={() => this.setState({ sortDropdown: !this.state.sortDropdown })}
          >
            <DropdownToggle caret className="primary-button">
              {this.props.AnalisePsicometrica.reducer.sortDirection === 'asc' ? 'mais antigos' : 'mais recentes'}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => this.props.sortParametrosImportados('asc')}>mais antigos</DropdownItem>
              <DropdownItem onClick={() => this.props.sortParametrosImportados('desc')}>mais recentes</DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </ButtonGroup>
        </div>
        <ImportarParametrosModal />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  AnalisePsicometrica: state.AnalisePsicometrica,
});

export default connect(mapStateToProps, {
  toggleImportarParametrosModal,
  sortParametrosImportados,
})(ReviewHead);
