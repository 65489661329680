import React, { Fragment, Component } from 'react';
import { get, debounce } from 'lodash';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Label, Progress, UncontrolledTooltip } from 'reactstrap';
import axios from 'axios';
import { API_HOST } from '../../consts';
import Table from '../../components/Table';
import NavBreadCrumb from '../../containers/NavBreadCrumb';
import GerarPinModal from '../../containers/modals/GerarPinModal';
import AdicionarCandidatoModal from '../../containers/modals/AdicionarCandidatoModal';
import ImportarCSV from '../../containers/modals/ImportarCSV';

export default class GerenciamentoAplicacao extends Component {
  constructor(props) {
    super(props);
    this.id = get(props, 'match.params.id');
  }

  state = {
    aplicacao: {},
    progresso: [],
    candidatos: [],
    search: null,
    pinValue: 0,
    table: [],
    page: 0,
    pageSize: 10,
    sorted: [],
    loading: true,
  }

  componentDidMount() {
    this.fetchAplicacao();
    this.fetchCandidatos();
    this.fetchProgresso();
    this.toggleModal();
  }

  getColumns = () => {
    const { progresso } = this.state;
    const columns = [
      {
        Header: 'Nome',
        accessor: 'nome',
      },
      {
        Header: 'RA',
        accessor: 'username',
      },
      {
        Header: 'Progresso',
        accessor: 'id',
        Cell: row => (
          <Progress className="mt-1" color="success" value={get(progresso, `candidatos.${row.value}`)} max={progresso.itens}>
            {get(progresso, `candidatos.${row.value}`)}/{progresso.itens}
          </Progress>
        ),
        sortable: false,
      },
      {
        Header: 'Presente',
        accessor: 'presente',
        Cell: row => (
          <Label check className="container-check-custom">
            <input
              type="checkbox"
              id="selecionar-todos"
              aria-label=""
            />
            <span className="checkmark" style={{ left: 30, top: 0 }} />
          </Label>
        ),
        sortable: false,
      },
      {
        Header: 'Hora Finalização',
        accessor: 'horario_conclusao',
      },
      {
        Header: 'QRCODE / PIN',
        accessor: 'qrcode',
        Cell: row => (
          <div className="text-center">
            <button onClick={() => this.toggleModal('pin')} className="btn btn-padrao bg-verde pl-4 pr-4">Gerar</button>
          </div>
        ),
        sortable: false,
      },
      {
        Header: 'Ações',
        accessor: 'id',
        Cell: row => (
          <div className="btn-group" role="group" aria-label="Ações">
            <UncontrolledTooltip delay={0} placement="right" target={`apagar${get(row, 'row.id')}`}>
              Apagar
            </UncontrolledTooltip>
            <button onClick={() => this.deleteCandidato(get(row, 'row.id'))} id={`apagar${get(row, 'row.id')}`} type="button" className="btn btn-white warning-hover"><i className="fas fa-times" /></button>
          </div>
        ),
        sortable: false,
      },
    ];
    return columns;
  }

  fetchProgresso() {
    axios.get(`${API_HOST}/aplicacoes/aplicacao/${this.id}/progresso`)
      .then((response) => {
        this.setState({ progresso: response.data });
      })
      .catch(() => {
      });
  }

  fetchAplicacao = () => {
    axios.get(`${API_HOST}/aplicacoes/aplicacao/${this.id}`)
      .then((response) => {
        this.setState({ aplicacao: response.data });
      })
      .catch(() => {
      });
  }

  fetchCandidatos = () => {
    const {
      search, page, pageSize, sorted,
    } = this.state;
    const ordering = sorted.map(field => `${field.desc ? '-' : ''}${field.id}`).join(',');

    axios.get(`${API_HOST}/aplicacoes/candidato`, {
      params: {
        aplicacao: this.id,
        search,
        page: page + 1,
        per_page: pageSize,
        ordering,
      },
    })
      .then((response) => {
        this.setState({
          candidatos: response.data.results,
          table: response.data,
          pageSize: response.data.length,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  }

  deleteCandidato = (candidato) => {
    axios.delete(`${API_HOST}/aplicacoes/candidato/${candidato}`)
      .then(() => {
        this.fetchCandidatos();
      })
      .catch(() => {
      });
  }

  handleSearch = debounce((search) => {
    this.setState({ search }, this.fetchCandidatos);
  }, 500);

  toggleModal = (name) => {
    const min = 1000;
    const max = 9999;
    const rand = Math.floor(Math.random() * (max - min + 1)) + min;
    const { openModal } = this.state;
    this.setState({
      openModal: openModal === name ? null : name,
    });
    openModal === null &&
      this.setState({
        pinValue: rand
      })
  };

  // getProgress(row) {
  //   const { progressos } = this.state;
  //   const progresso = progressos.find()
  // }

  getColumns = () => {
    const columns = [
      {
        Header: 'Nome',
        accessor: 'nome',
      },
      {
        Header: 'RA',
        accessor: 'username',
      },
      {
        Header: 'Progresso',
        accessor: 'id',
        Cell: row => (
          <div className="progress">
            <div className="progress-bar mt-1" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" />
          </div>
        ),
        sortable: false,
      },
      {
        Header: 'Presente',
        accessor: 'presente',
        width: 100,
        Cell: row => (
          <Label check className="container-check-custom">
            <input
              type="checkbox"
              id="selecionar-todos"
              aria-label=""
            />
            <span className="checkmark" style={{ left: 30, top: 0 }} />
          </Label>
        ),
        sortable: false,
      },
      {
        Header: 'Hora Finalização',
        accessor: 'horario_conclusao',
      },
      {
        Header: 'QRCODE / PIN',
        accessor: 'qrcode',
        Cell: row => (
          <div className="text-center">
            <button onClick={() => this.toggleModal('pin')} className="btn btn-padrao bg-verde pl-4 pr-4">Gerar</button>
          </div>
        ),
        sortable: false,
      },
      {
        Header: 'Ações',
        accessor: 'id',
        width: 100,
        Cell: row => (
          <div className="text-center" role="group" aria-label="Ações">
            <UncontrolledTooltip delay={0} placement="right" target={`apagar${get(row, 'row.id')}`}>
              Apagar
            </UncontrolledTooltip>
            <button onClick={() => this.deleteCandidato(get(row, 'row.id'))} id={`apagar${get(row, 'row.id')}`} type="button" className="btn btn-white warning-hover"><i className="fas fa-times" /></button>
          </div>
        ),
        sortable: false,
      },
    ];
    return columns;
  }

  renderTable = () => {
    const {
      openModal,
      candidatos,
      pinValue,
      table,
      page,
      pageSize,
      loading,
    } = this.state;
    return (
      <Fragment>
        <Table
          manual
          data={candidatos}
          columns={this.getColumns()}
          className="table-default mt-4"
          pages={get(table, 'total_pages')}
          pageSize={pageSize || 5}
          page={page}
          loading={loading}
          showPageSizeOptions={false}
          onPageChange={a => this.setState({ page: a }, this.fetchCandidatos)}
          onPageSizeChange={a => this.setState({ pageSize: a, page: 0 }, this.fetchCandidatos)}
          onSortedChange={a => this.setState({ sorted: a }, this.fetchCandidatos)}
        />
        <GerarPinModal
          toggle={() => this.toggleModal('pin')}
          isOpen={openModal === 'pin'}
          number={`${pinValue}`}
        />
      </Fragment>
    );
  }

  importEstudantes(files) {
    const file = files[0];
    const aplicacao_id = get(this, 'props.match.params.id');
    if (file) {
      const data = new FormData();
      data.append('file', file);
      this.setState({ importandoEstudantes: true });
      axios.post(`${API_HOST}/aplicacoes/aplicacao/${aplicacao_id}/importar_candidatos`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(() => {
          toast.success('Alunos importados com sucesso.');
          this.setState({ importandoEstudantes: false, openModal: null });
          this.fetchCandidatos();
        })
        .catch((err) => {
          toast.error(get(err, 'response.data.file[0]', 'Ocorreu um erro inesperado.'));
          this.setState({ importandoEstudantes: false, openModal: null });
        });
    }
  }

  importarRespostas(files) {
    const file = files[0];
    const aplicacao_id = get(this, 'props.match.params.id');
    if (file) {
      const data = new FormData();
      data.append('file', file);
      this.setState({ importandoRespostas: true });
      axios.post(`${API_HOST}/aplicacoes/aplicacao/${aplicacao_id}/importar_respostas`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(() => {
          toast.success('Respostas importadas com sucesso.');
          this.setState({ importandoRespostas: false, openModal: null });
          this.fetchCandidatos();
        })
        .catch((err) => {
          toast.error(get(err, 'response.data.file[0]', 'Ocorreu um erro inesperado.'));
          this.setState({ importandoRespostas: false, openModal: null });
        });
    }
  }

  statusAplicacao = [
    {
      endpoint: 'iniciar',
      label: 'Iniciar',
      color: '#00b4bb',
      icon: 'fas fa-play',
    },
    {
      endpoint: 'pausar',
      label: 'Pausar',
      color: '#00b4bb',
      icon: 'fas fa-pause',
    },
    {
      endpoint: 'finalizar',
      label: 'Finalizar',
      color: '#00c2fa',
      icon: 'fas fa-stop',
      successMessage: 'Aplicação finalizada com sucesso!',
    },
  ];

  showAtualizarStatusButton(endpoint) {
    const { aplicacao } = this.state;
    return get(aplicacao, `permissions.${endpoint}`);
  }

  atualizarStatus(endpoint, successMessage) {
    const { aplicacao } = this.state;

    axios.patch(`${API_HOST}/aplicacoes/aplicacao/${endpoint}`, {
      aplicacoes: [aplicacao.id],
    })
      .then(() => {
        this.fetchAplicacao();
        successMessage &&
          toast.success((
            <ul>
              {successMessage}
            </ul>
          ));
      })
      .catch(() => {
        toast.error('Ocorreu um erro ao atualizar o status da aplicação.');
      });
  }


  renderTable = () => {
    const {
      openModal,
      candidatos,
      pinValue,
      table,
      page,
      pageSize,
      loading,
    } = this.state;
    return (
      <Fragment>
        <Table
          manual
          data={candidatos}
          columns={this.getColumns()}
          className="table-default mt-4"
          pages={get(table, 'total_pages')}
          pageSize={pageSize || 5}
          page={page}
          loading={loading}
          showPageSizeOptions={false}
          onPageChange={a => this.setState({ page: a }, this.fetchCandidatos)}
          onPageSizeChange={a => this.setState({ pageSize: a, page: 0 }, this.fetchCandidatos)}
          onSortedChange={a => this.setState({ sorted: a }, this.fetchCandidatos)}
        />
        <GerarPinModal
          toggle={() => this.toggleModal('pin')}
          isOpen={openModal === 'pin'}
          number={`${pinValue}`}
        />
      </Fragment>
    );
  }

  renderAtualizarStatusButtons() {
    const buttons = [];

    this.statusAplicacao.forEach((d) => {
      const showButton = this.showAtualizarStatusButton(d.endpoint);
      if (showButton) {
        buttons.push((
          <Fragment>
            <button
              key={d.endpoint}
              className="btn btn-padrao mr-2"
              style={{ backgroundColor: d.color }}
              onClick={() => this.atualizarStatus(d.endpoint, d.successMessage)}
            >
              <i className={`mr-1 ${d.icon}`} /> {d.label}
            </button>
          </Fragment>
        ));
      }
    });

    return buttons;
  }

  render() {
    const pathList = [
      { link: '/instrumentos-cognitivos', label: 'Instrumentos cognitivos' },
    ];
    const {
      aplicacao, openModal, importandoEstudantes, importantoRespostas,
    } = this.state;
    return (
      <Fragment>
        <NavBreadCrumb pathList={pathList} currentPath="Gerenciamento da aplicação" />
        <div className="container">
          <div className="row">
            <div className="col-12 mb-4 pb-4">
              <div className="card">
                <div className="card-header">
                  <div className="d-flex justify-content-between align-items-center">
                    <h1 className="azul mb-0">Gerenciamento da aplicação - {get(aplicacao, 'nome')}</h1>
                    <div style={{ color: 'white', padding: '5px 40px', borderRadius: '3px', fontSize: '14px', backgroundColor: get(aplicacao, 'status.cor') }}>
                      {get(aplicacao, 'status.descricao')}
                    </div>
                  </div>
                  <hr />
                  <ul className="list-inline list-style">
                    <li className="list-inline-item item-bg">
                      <strong className="mr-1">
                        <i className="fas fa-calendar mr-1" />
                        Data:
                      </strong>
                      {get(aplicacao, 'data')}
                    </li>
                    <li className="list-inline-item">
                      <strong className="mr-1">
                        <i className="fas fa-clock mr-1" />
                        Início:
                      </strong>
                      {get(aplicacao, 'horario_inicio')}
                    </li>
                    <li className="list-inline-item">
                      <strong className="mr-1">
                        <i className="fas fa-clock mr-1" />
                      Término:
                      </strong>
                      {get(aplicacao, 'horario_termino')}
                    </li>
                    <li className="list-inline-item float-right">
                      <Link to={`/aplicacoes/${this.id}/notas`} className="azul">
                        <strong>
                          Notas
                        </strong>
                      </Link>
                    </li>
                  </ul>
                  <hr />
                  <div>
                    {this.renderAtualizarStatusButtons()}
                  </div>
                </div>
                <div className="card-body pt-0">
                  <div className="actions-table">
                    <button onClick={() => this.toggleModal('importarEstudantes')} className="btn btn-white mr-2 "><i className="fas fa-user mr-1" /> Importar Estudantes</button>
                    <ImportarCSV
                      nome="Estudantes"
                      toggle={() => this.toggleModal('importarEstudantes')}
                      isOpen={openModal === 'importarEstudantes'}
                      onChange={event => this.importEstudantes(event)}
                      loading={importandoEstudantes}
                    />
                    <button onClick={() => this.toggleModal('anadirEstudiante')} className="btn btn-white mr-2"><i className="fas fa-plus mr-1" /> Adicionar Estudantes</button>
                    <AdicionarCandidatoModal
                      aplicacao={aplicacao}
                      toggle={() => this.toggleModal('anadirEstudiante')}
                      isOpen={openModal === 'anadirEstudiante'}
                      fetchCandidatos={this.fetchCandidatos}
                    />
                    <button onClick={() => this.toggleModal('importarRespostas')} className="btn btn-white mr-2"><i className="fas fa-upload mr-1" /> Importar Respostas</button>
                    <button type="button" className="btn btn-white mr-2">Lista de presença</button>
                    <button type="button" className="btn btn-white mr-2">Baixar provas</button>
                    <ImportarCSV
                      nome="Respostas"
                      toggle={() => this.toggleModal('importarRespostas')}
                      onChange={event => this.importarRespostas(event)}
                      isOpen={openModal === 'importarRespostas'}
                      loading={importantoRespostas}
                    />
                    <button className="btn-invisible" style={{ verticalAlign: 'bottom', minWidth: '250px' }}>
                      <div className="wrap">
                        <div className="search">
                          <input
                            style={{ maxHeight: 33 }}
                            className="search-term"
                            placeholder="Pesquisar..."
                            // value={search}
                            onChange={event => this.handleSearch(event.target.value)}
                          />
                        </div>
                      </div>
                    </button>
                  </div>
                  {this.renderTable()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
