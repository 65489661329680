import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup } from 'reactstrap';
import BtnIcon from './buttons/BtnIcon';

export default class ListTag extends React.Component {
  static propTypes = {
    color: PropTypes.string,
    label: PropTypes.string,
    onClickGroup: PropTypes.func,
    groupIcon: PropTypes.bool,
    actions: PropTypes.arrayOf(PropTypes.element),
  };

  static defaultProps = {
    color: '#45C0F5',
    label: '',
    onClickGroup: () => {},
    groupIcon: false,
    actions: [],
  };

  renderGroupIcon() {
    if (this.props.groupIcon) {
      return <BtnIcon icon="users" onClick={this.props.onClickGroup} />;
    }
    return null;
  }

  render() {
    return (
      <div className="status-tag" style={{ background: this.props.color }}>
        <p style={{color: 'white'}}>{this.props.label}</p>
        <ButtonGroup className="list-btn pull-right">
          {this.props.groupIcon && this.renderGroupIcon()}
          <div className="btn-group" role="group" aria-label="Actions">
            {this.props.actions}
          </div>
        </ButtonGroup>
      </div>
    );
  }
}
