import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormText } from 'reactstrap';
import { Icon } from 'react-fa';
import { Control } from 'react-redux-form';

class InputText extends React.Component {
  static propTypes = {
    model: PropTypes.string.isRequired,
    type: PropTypes.string,
    col: PropTypes.string,
    helpBlock: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.string,
  };

  static defaultProps = {
    type: 'text',
    col: 'col-md-12',
    helpBlock: '',
    icon: '',
    label: '',
  };

  renderIcon() {
    if (this.props.icon) {
      return <Icon className="mt-2" name={this.props.icon} />;
    }

    return null;
  }

  render() {
    const {
      col,
      label,
      model,
      type,
      helpBlock,
    } = this.props;

    return (
      <FormGroup className={col}>
        {this.renderIcon()}
        <label>{label}</label>
        <Control
          model={model}
          type={type}
          className="form-control"
          updateOn="blur"
        />
        {
          helpBlock ?
          (
            <FormText color="muted">
              {helpBlock}
            </FormText>
          ) : null
        }
      </FormGroup>
    );
  }
}

export default InputText;
