import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NavBreadCrumb from '../containers/NavBreadCrumb';
import { InputCheckbox } from './inputs';
import InputText from './inputs/InputText';
import Loading from './Loading';
import MultiSelectSection from './MultiSelectSection';
import TOTVSCourseDisciplineForm from './users/TOTVSCourseDisciplineForm';
import styled from 'styled-components';

class UserForm extends Component {
  renderBreadcrumb() {
    const { breadCrumbCurrentPath } = this.props;
    const pathList = [{ label: 'Usuários', link: '/usuarios' }];
    return (
      <NavBreadCrumb
        pathList={pathList}
        currentPath={breadCrumbCurrentPath}
        hasBackslash={false}
      />
    );
  }

  renderForm() {
    const {
      fetchingData,
      groups,
      permissions,
      submitting,
      instituicoes,
      shouldDisplayPassword,
      title,
      courseOptions,
      disciplineOptions,
      courses,
      addCourse,
      removeCourse,
      className
    } = this.props;

    if (fetchingData) {
      return <Loading />;
    }

    return (
      <div className={className}>
        <div className="card-header bg-white">
          <div className="row">
            <div className="col-6">
              <h1>{title}</h1>
            </div>
          </div>
        </div>
        <div className="card-body card-body-form bg-white">
          <div className="row">
            <InputText col="col-sm-6" label="Nome" field="last_name" />
            <InputText col="col-sm-3" label="CPF" field="cpf" />
            <div className="col-sm-3" style={{ marginTop: 32 }}>
              <InputCheckbox col="col-sm-12" label="Ativo" field="is_active" />
            </div>
          </div>
          <div className="row">
            <InputText col="col-sm-3" label="Email" field="email" />
            {shouldDisplayPassword && (
              <React.Fragment>
                <InputText
                  type="password"
                  col="col-sm-3"
                  label="Senha"
                  field="password"
                />
                <InputText
                  type="password"
                  col="col-sm-3"
                  label="Confirmar senha"
                  field="confirm_password"
                />
              </React.Fragment>
            )}
          </div>
        </div>
        <MultiSelectSection
          label="Grupos"
          description="Selecione os grupos a que o usuário pertence. Todas as permissões referentes aos grupos selecionados serão atribuídas a ele."
          items={groups}
          field="groups"
        />
        <MultiSelectSection
          label="Permissões"
          description="Selecione as permissões individuais do usuário."
          items={permissions}
          field="user_permissions"
        />
        <MultiSelectSection
          label="Instituições"
          description="Selecione as instituições as quais o usuário possui acesso."
          items={instituicoes}
          field="instituicoes"
        />
        <TOTVSCourseDisciplineForm
          courseOptions={courseOptions}
          disciplineOptions={disciplineOptions}
          courses={courses}
          addCourse={addCourse}
          removeCourse={removeCourse}
        />
        <div className="card-footer">
          <Link className="btn btn-white" to="/usuarios">
            Cancelar
          </Link>
          <button
            type="submit"
            className="btn primary-button float-right"
            disabled={submitting}
          >
            {submitting ? 'Salvando...' : 'Salvar'}
          </button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="form-configuracoes">
        {this.renderBreadcrumb()}
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card">{this.renderForm()}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UserForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
  breadCrumbCurrentPath: PropTypes.string,
  title: PropTypes.string,
  fetchingData: PropTypes.bool.isRequired,
  groups: PropTypes.arrayOf(PropTypes.object).isRequired,
  permissions: PropTypes.arrayOf(PropTypes.object).isRequired,
  instituicoes: PropTypes.arrayOf(PropTypes.object).isRequired,
  submitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  shouldDisplayPassword: PropTypes.bool.isRequired
};

UserForm.defaultProps = {
  data: {},
  breadCrumbCurrentPath: '',
  title: ''
};

export default styled(UserForm)`
  svg {
    width: 20px !important;
  }
`;
