import React from 'react';
import PropTypes from 'prop-types';
import { Input, FormGroup, Label } from 'reactstrap';

class AnaliseParameterPicker extends React.Component {
  get parameters() {
    return ([{ id: 'bisserial', text: 'Bisserial' },
      { id: 'dificuldade', text: 'Dificuldade' },
      { id: 'discriminacao', text: 'Discriminação' }]);
  }

  render() {
    const { onSelect, checkedParameter } = this.props;
    return (
      <FormGroup
        tag="fieldset"
        style={{ margin: '10px' }}
        className="no-print-d-none"
      >
        <div>Marcar linhas por:</div>
        <FormGroup check style={{ marginTop: '10px' }}>
          {this.parameters.map((parameter) => {
            return (
              <Label
                className="col-md-2"
                key={parameter.id}
              >
                <Input
                  type="radio"
                  name="radio1"
                  id={parameter.id}
                  onChange={onSelect}
                  checked={parameter.id === checkedParameter}
                />
                {' '} {parameter.text}
              </Label>
            );
          })
        }
        </FormGroup>
      </FormGroup>
    );
  }
}

AnaliseParameterPicker.propTypes = {
  onSelect: PropTypes.func,
  checkedParameter: PropTypes.string,
};

AnaliseParameterPicker.defautProps = {
  onSelect: () => {},
  checkedParameter: '',
};


export default AnaliseParameterPicker;
