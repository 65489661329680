import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

class TokenLogin extends React.Component {
  componentDidMount() {
    this.login();
  }

  login() {
    const { history, match } = this.props;
    const { token } = match.params;
    localStorage.setItem('token', token);
    history.push('/');
  }

  render() {
    return null;
  }
}

TokenLogin.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string,
    }),
  }).isRequired,
};

export default withRouter(TokenLogin);
