import React from 'react';
import Highcharts from 'highcharts';
import { get } from 'lodash';

class AlphaCronbach extends React.Component {

  get alphaTeste() {
    const { alphas } = this.props;
    return get(alphas, `0.alpha_teste`) || 0;
  }

  get title() {
    return `<p class="azul pt-3">Alfa de Cronbach <b>(&alpha; = ${this.alphaTeste.toFixed(2).replace('.',',')})</b></p>`;
  }

  get data() {
    const { alphas } = this.props;
    return alphas.map(d => ({
      y: parseFloat(d.alpha),
      color: parseFloat(d.alpha) > this.alphaTeste ? 'var(--danger)' : 'var(--success)',
    }));
  }

  componentDidUpdate(prevProps) {
    const { alphas } = this.props;
    if ((prevProps.alphas !== alphas)) {
      this.createChart();
    }
  }

  createChart() {
    const { colors, alphas } = this.props;

    Highcharts.chart('alpha-cronbach-chart', {
      title: {
        text: this.title,
        useHTML: true,
      },
      chart: {
        colors,
        type: 'line',
        zoomType: 'xy',
        height: 550,
      },
      xAxis: {
        title: {
          enabled: true,
          text: 'Itens'
        },
        categories: alphas.map(d => `${d._row}`),
      },
      tooltip: {
        formatter: function () {
          return `<b>${this.x}:</b> ${this.y.toFixed(2).replace('.',',')}`;
        }
      },
      yAxis: {
        title: {
          text: 'Alfa'
        },
        gridLineWidth: 0,
        lineWidth: 1,
        min: 0,
        max: 1,
        tickInterval: 0.1,
        plotLines: [{
          value: this.alphaTeste,
          color: '#716F7F',
          width: 2,
          dashStyle: 'shortdash',
        }, {
          value: 0.8,
          color: 'var(--azul)',
          width: 2,
          dashStyle: 'shortdash',
        }]
      },
      series: [
        {
          type: 'scatter',
          name: 'alpha',
          showInLegend: false,
          data: this.data,
          marker: {
            radius: 5,
          }
        },
        {
          color: 'var(--azul)',
          name: 'Alfa Referência',
          type: 'line',
          marker: false,
          dashStyle: 'shortdash'
        },
        {
          color: '#716F7F',
          name: 'Alfa do Teste',
          type: 'line',
          marker: false,
          dashStyle: 'shortdash'
        }
      ],
      legend: {
          layout: 'vertical',
          x: 90,
          y: 30,
          verticalAlign: 'top',
          align: 'left',
          floating: true,
          itemStyle:{
            color: 'var(--cinza)'
          }
      },
    });
  }

  render() {
    return (
      <div id="alpha-cronbach-chart" />
    );
  }
}

AlphaCronbach.defaultProps = {
  alphas: [],
};

export default AlphaCronbach;
