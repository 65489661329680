import React from 'react';
import InputMask from './InputMask';

const durationMask = [
  /[0-9]/,
  /[0-9]/,
  ':',
  /[0-9]/,
  /[0-9]/,
];
export default props => <InputMask {...props} mask_={durationMask} />;
