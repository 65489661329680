import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import NxtButton from '../components/NxtButton';
import PrevButton from '../components/PrevButton';
import SwitchButton from '../components/SwitchButton';

const propTypes = {
  previous: PropTypes.string,
  next: PropTypes.string,
  last: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.oneOfType(
    [PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

const defaultProps = {
  previous: '',
  next: '',
  children: [],
  last: '',
  title: '',
  toggleButton: false,
  onClick: null,
  disabled: false,
};

class Form extends React.Component {

  renderFinalizarButton() {
    const { last, onClick, disabled } = this.props;

    if (last) {
      if (onClick || disabled) {
        return <button disabled={disabled} type="button" onClick={() => onClick(last)} className="btn primary-button mr-2">FINALIZAR</button>
      }

      return <Link to={last} className="btn primary-button mr-2">FINALIZAR</Link>;
    }

    return null;
  }

  render() {
    const {
      title,
      previous,
      next,
      children,
      toggleButton,
      onClick,
      disabled,
    } = this.props;

    return (
      <div className="card-bg">
        <div className="card card-form">
          <div className="card-body">
            <div className="card-header">
              <div className="row">
                <div className="col-sm-9">
                  <h1>{title}</h1>
                </div>
                <div className="col-sm-3">
                  {toggleButton === true &&
                    <div className="desativar">
                      <p>Desabilitado</p>
                      <SwitchButton />
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className="card-body card-body-form">{children}
              <div className="row">
                <div className="col-sm-12 btns-next">
                  {previous ? <PrevButton disabled={disabled} to={previous} onClick={onClick ? () => onClick(previous) : null} /> : null}
                  {next ? <NxtButton disabled={disabled} to={next} onClick={onClick ? () => onClick(next) : null} /> : null}
                  {this.renderFinalizarButton()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Form.propTypes = propTypes;
Form.defaultProps = defaultProps;

export default Form;
