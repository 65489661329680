import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { get, map, join, size } from 'lodash';
import axios from 'axios';
import { API_HOST } from '../../consts';
import Loading from '../../components/Loading';
import FileList from '../../components/FileList';

class Convites extends React.Component {
  state = {
    convites: [],
    fetchingData: true,
  }

  fetchData = () => {
    const { encomenda, tipoConvite } = this.props;
    const { fetchingData } = this.state;

    if (!fetchingData) {
      return;
    }

    axios.get(`${API_HOST}/encomenda/convite`, {
      params: {
        encomenda,
        ordering: '-criado_em',
        tipo_convite: tipoConvite,
      },
    })
      .then((response) => {
        const data = response.data.results || response.data;
        const convites = data.map(c => ({
          ...c,
          criado_por: get(c, 'criado_por.last_name'),
          destinatarios: join(map(c.destinatarios, d => d.last_name), ', '),
        }));

        this.setState({
          convites,
          fetchingData: false,
        });
      });
  }

  render() {
    const { convites, fetchingData } = this.state;
    const { isOpen, toggle } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        onOpened={this.fetchData}
      >
        <ModalHeader toggle={toggle}>
          Mensagens enviadas
        </ModalHeader>
        <ModalBody>
          {fetchingData && <Loading />}
          {map(convites, m => (
            <div className="card mt-3">
              <div className="card-body bg-white">
                <label className="form-group mb-0">Destinatários</label>
                <p>{m.destinatarios}</p>
                <label className="form-group mb-0 mt-2">Mensagem</label>
                <p dangerouslySetInnerHTML={{ __html: m.mensagem }} />
                {size(m.arquivos) > 0 && (
                  <React.Fragment>
                    <label className="form-group mb-0 mt-2">Anexos</label>
                    <FileList
                      files={m.arquivos}
                      timestamp={false}
                      preview={false}
                    />
                  </React.Fragment>
                )}
                <small>Enviado por {m.criado_por} em {m.criado_em}</small>
              </div>
            </div>
          ))}
        </ModalBody>
        <ModalFooter>
          <button className="btn primary-button" onClick={toggle}>
            OK
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

Convites.propTypes = {
  encomenda: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  tipoConvite: PropTypes.number,
};

Convites.defaultProps = {
  isOpen: false,
  toggle: () => {},
  tipoConvite: 1,
};

export default Convites;
