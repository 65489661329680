import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { get, map } from 'lodash';
import axios from 'axios';
import ProgressTracker from '../containers/ProgressTracker';
import NavBreadCrumb from '../containers/NavBreadCrumb';
import Details from '../containers/Details';
import { API_HOST } from '../consts';

class BaseTeste extends React.Component {
  constructor(props) {
    super(props);
    this.id = props.match.params.id;
  }

  state = {
    blocos: [],
  }

  componentDidMount() {
    this.fetchBlocos();
    this.fetchTeste();
  }

  componentDidUpdate(prevProps) {
    const prevId = get(prevProps, 'match.params.id');
    const id = get(this.props, 'match.params.id');

    if (prevId !== id) {
      this.id = id;
      this.fetchBlocos();
    }
  }

  fetchTeste() {
    if (this.id !== 'new') {
      axios.get(`${API_HOST}/testes/teste/${this.id}`)
        .then((response) => {
          this.setState({ teste: response.data });
        })
    }
  }

  fetchBlocos() {
    if (this.id !== 'new') {
      axios.get(`${API_HOST}/testes/bloco`, {
        params: {
          teste: this.id,
        },
      })
        .then((response) => {
          this.setState({ blocos: response.data.results || response.data });
        })
    }
  }

  get details() {
    const { teste } = this.state;

    return [
      { col: 'col-sm-4', title: 'Itens', text: get(teste, 'total_itens')},
      { col: 'col-sm-4', title: 'Blocos', text: get(teste, 'total_blocos')},
      { col: 'col-sm-4', title: 'Itens por bloco', text: get(teste, 'total_itens_bloco')},
      { col: 'col-sm-4', title: 'Cadernos', text: get(teste, 'total_cadernos')},
      { col: 'col-sm-4', title: 'Blocos por caderno', text: get(teste, 'total_blocos_caderno')},
      { col: 'col-sm-4', title: 'Repetição de blocos', text: get(teste, 'repeticao_blocos')},
    ];
  }

  get steps() {
    const { match, location: { pathname } } = this.props;
    const { blocos } = this.state;

    return [
      {
        id: 1,
        title: 'Dados gerais',
        link: `/${this.urlPrefix}/${match.params.id}/dados`,
        path: `/${this.urlPrefix}/:id/dados`,
        active: pathname === `/${this.urlPrefix}/${match.params.id}/dados`,
        done: false,
        disabled: false,
        component: null,
      },
      {
        id: 2,
        title: 'Cadernos',
        link: `/${this.urlPrefix}/${match.params.id}/cadernos`,
        path: `/${this.urlPrefix}/:id/cadernos`,
        active: pathname === `/${this.urlPrefix}/${match.params.id}/cadernos`,
        done: false,
        disabled: match.params.id === 'new',
        component: null,
      },
      {
        id: 4,
        title: 'Blocos',
        link: `/${this.urlPrefix}/${match.params.id}/blocos`,
        path: `/${this.urlPrefix}/:id/blocos`,
        active: pathname.startsWith(`/${this.urlPrefix}/${match.params.id}/blocos`),
        done: false,
        exact: true,
        disabled: match.params.id === 'new',
        component: null,
        substeps: map(blocos, b => ({
          id: b.id,
          title: `B${b.posicao}`,
          link: `/${this.urlPrefix}/${match.params.id}/blocos/${b.posicao}`,
          active: pathname === `/${this.urlPrefix}/${match.params.id}/blocos/${b.posicao}`,
        })),
      },
      {
        id: 5,
        title: 'Confirmar',
        link: `/${this.urlPrefix}/${match.params.id}/confirmar`,
        path: `/${this.urlPrefix}/:id/confirmar`,
        active: pathname === `/${this.urlPrefix}/${match.params.id}/confirmar`,
        done: false,
        disabled: match.params.id === 'new',
        component: null,
      },
    ];
  }

  render() {
    const { match } = this.props;
    const { teste } =this.state;
    const pathList = [{ label: this.verboseNamePlural, icon: 'list', link: `/${this.urlPrefix}` }];
    const currentPath = match.params.id === 'new' ? `Novo ${this.verboseName}` : `${this.verboseName} ${match.params.id}`;
    const Itens = this.itensStep;

    return (
      <div>
        <NavBreadCrumb pathList={pathList} currentPath={currentPath} />
        <div className="container">
          <div className="row pb-4">
            <div className="col-sm-3">
              <ProgressTracker steps={this.steps} />
            </div>
            <div className="col-sm-9">
              {!this.steps[0].active && <Details initialDropdownState title={get(teste, 'nome')} details={this.details} />}
              <Switch>
                <Route
                  path={`/${this.urlPrefix}/:id/blocos/:posicao`}
                  render={() => <Itens step={this.steps[2]} />}
                />
                {this.steps.map((step, index) => (
                  <Route
                    key={step.id}
                    path={step.path}
                    exact={!!step.exact}
                    render={() => step.disabled ? <Redirect to={`/${this.urlPrefix}/new/dados`} /> : <step.component step={step} />}
                  />
                ))}
                <Route path={`/${this.urlPrefix}/:id`} render={() => <Redirect to={`/${this.urlPrefix}/${match.params.id}/dados`} />} />
                <Redirect to={`/${this.urlPrefix}`} />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BaseTeste;
