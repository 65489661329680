import React, { Component, lazy } from 'react';
import { Form } from 'informed';
import Link from 'react-router-dom/es/Link';
import NavBreadCrumb from '../../containers/NavBreadCrumb';
import SwitchButton from '../../components/SwitchButton';
import Search from '../../components/Search';
import InputText from '../../components/inputs/InputText';

const MultiSelect = lazy(() => import('../../components/inputs/InputMultiSelect'));

class Grupo extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {

      permissoes: [
        { id: 0, label: 'Permissão 1' },
        { id: 2, label: 'Permissão 2' },
        { id: 3, label: 'Permissão 3' },
      ],
      selectedItemsPermissoes: [],
      messages: {
        searchPlaceholder: 'Pesquisar...',
        noItemsMessage: 'Selecione um item na coluna esquerda...',
        noneSelectedMessage: 'Nenhum Selecionado',
        selectedMessage: 'selecionado',
        selectAllMessage: 'Selecionar todos',
        clearAllMessage: 'Remover Todos',
        disabledItemsTooltip: 'You can only select 1 file',
      },
    };
  }

  handleChange(selectedItems) {
    this.setState({ selectedItems });
  }

  render() {
    const {
      permissoes,
      selectedItemsPermissoes,
      messages,
    } = this.state;
    return (
      <div className="form-configuracoes">
        <NavBreadCrumb currentPath="Autenticação e Autorização de Grupos"/>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card toolbar">
                <div className="card-header">
                  <div className="row">
                    <div className="col-6"><h1>Edição de Grupos</h1></div>
                    <div className="col-4">
                      <Search />
                    </div>
                    <div className="col-2 display-flex">
                      <Link to="/configuracoes/grupo">
                        <button className="btn primary-button">+ Adicionar Grupo</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <Form
                  // onValueChange={formState => this.setState({ formState })}
                >
                  <div className="card-header">
                    <div className="row">
                      <div className="col-6">

                          <InputText
                            col="col-sm-12"
                            label="Grupo"
                            field="grupo"
                          />

                      </div>
                      <div className="col-6">
                        <SwitchButton/>
                      </div>
                    </div>
                  </div>


                  <div className="card-body card-body-form">


                    <MultiSelect
                      items={permissoes}
                      selectedItems={selectedItemsPermissoes}
                      onChange={this.handleChange}
                      messages={messages}
                    />

                  </div>


                  <div className="card-footer display-flex">

                    <button className="btn primary-button">Salvar</button>
                  </div>
                </Form>
              </div>


              {/*<div className="card">
                <div className="card-header">
                  <h1>Usuário</h1>
                </div>
                <div className="card-body card-body-form">
                  <p>asdas</p>
                </div>
                <div className="card-header">
                  <h1>Usuário</h1>
                </div>
              </div>*/}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Grupo;
