import React from 'react';
import { withRouter, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import Form from '../../../containers/Form';
import DashboardNavigation from './DashboardNavigation';
import DistribuicaoGeografica from './DistribuicaoGeografica';
import DistribuicaoCategoria from './DistribuicaoCategoria';
import BancaElaboradores from './BancaElaboradores';
import DistribuicaoGrupos from './DistribuicaoGrupos';

class DashboardElaborador extends React.Component {
  static defaultProps = {
    steps: [],
  };

  render() {
    return (
      <div className="container">
        <Form title="Painel Geral de Elaboradores">
          <DashboardNavigation steps={this.props.steps} />
          <Route path="/dashboard-elaborador/distribuicao-categoria" component={DistribuicaoCategoria} />
          <Route path="/dashboard-elaborador/distribuicao-geografica" component={DistribuicaoGeografica} />
          <Route path="/dashboard-elaborador/banca-elaboradores" component={BancaElaboradores} />
          <Route path="/dashboard-elaborador/distribuicao-grupos" component={DistribuicaoGrupos} />
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dashboardElaborador: state.dashboardElaborador,
  steps: state.DashboardElaboradorSteps,
});

export default withRouter(connect(mapStateToProps)(DashboardElaborador));
