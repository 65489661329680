import React from 'react';
import PropTypes from 'prop-types';
import loadingGif from '../assets/images/loading.gif';

export default class Loading extends React.Component {
  static propTypes = {
    message: PropTypes.string,
  };

  static defaultProps = {
    message: '',
  };

  render() {
    return (
      <div style={{ textAlign: 'center' }}>
        <img src={loadingGif} style={{ width: '42px' }} alt="" />
        <p style={{ color: '#ccc' }}>{this.props.message}</p>
      </div>
    );
  }
}
