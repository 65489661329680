import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateStep } from '../../../actions';
// import elaboradorAreaConhecimento from '../../../static/img/elaborador-area-conhecimento.png';

const data = [
  { id: 'Linguagens, códigos e suas tecnologias', value: 2000 },
  { id: 'Ciências Humanas e suas tecnologias', value: 800 },
  { id: 'Matemática e suas tecnologias', value: 800 },
  { id: 'Ciências da Natureza e suas tecnologias', value: 400 },
];

// const treemapConfig = {
//   data,
//   groupBy: 'id',
//   size: d => d.value,
// };

class BancaElaboradores extends React.Component {
  static propTypes = {
    updateStep: PropTypes.func.isRequired,
  };

  componentWillMount() {
    this.props.updateStep({ activeStepIndex: 2 });
  }

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <h5 align="middle">Elaboradores por Área Conhecimento</h5>
          <hr />
          <div className="row" style={{ padding: '50px 0px' }}>
            {
              data.map(item => (
                <div className="col-sm-3">
                  <div className="animated slideInUp">
                    <h3 align="middle" style={{ color: '#0A5C89' }}>{item.value}</h3>
                    <hr style={{ color: '#0A5C89' }} />
                    <h5 align="middle">{item.id}</h5>
                  </div>
                </div>
              ))
            }
          </div>
          <hr />
          <div style={{ textAlign: 'center' }}>
            {/*<img src={elaboradorAreaConhecimento} style={{ width: '60%' }} alt="Gráfico de elaboradores por área do conhecimento" />*/}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  bancaElaboradores: state.bancaElaboradores,
});

export default withRouter(connect(mapStateToProps, { updateStep })(BancaElaboradores));
