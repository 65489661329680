import { withRouter } from 'react-router-dom';
import BaseFiltroAvancadoItemAvaliacao from '../../base/BaseFiltroAvancadoItemAvaliacao';

class FiltroAvancadoItens extends BaseFiltroAvancadoItemAvaliacao {
  handleCheckboxChange = (event, id) => {
    const { match, onResultClick } = this.props;
    const data = {
      avaliacao: match.params.id,
      item: id,
    };
    onResultClick(event, data);
  }
}

export default withRouter(FiltroAvancadoItens);
