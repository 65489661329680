import PropTypes from 'prop-types';
import Upload from './Upload';
import { getkeyFromS3URL, renameFile } from '../utils/upload';

class S3Upload extends Upload {
  constructor(props) {
    super(props);
    const key = getkeyFromS3URL(props.uploadUrl);
    this.file = renameFile(props.file, key);
    this.method = 'put';
    this.headers = {'Content-Type': this.file.type };
    this.transformRequest = (data, headers) => {
      // Remove authorization from headears
      delete headers.common['Authorization'];
      return data;
    };
  }
}

S3Upload.propTypes = {
  file: PropTypes.instanceOf('File'),
  uploadUrl: PropTypes.string,
  onUploadProgress: PropTypes.func,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

export default S3Upload;
