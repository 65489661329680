import React from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { get } from 'lodash';
import CurvaCaracteristica from './CurvaCaracteristica';

class ModalCurvaCaracteristica extends React.Component {

  columns = ['a', 'epa', 'b', 'epb', 'c', 'epc']

  renderHeader() {
    const { parametrosCorrigidos } = this.props;
    return get(parametrosCorrigidos, '_row');
  }

  renderBody() {
    const { parametrosCorrigidos, curva } = this.props;
    return (
      <React.Fragment>
        <div className="container">
          <div className="row">
            <div className="col-12 mt-4 mb-4">
              <table className="table table-etapa">
                <thead>
                <th>Item</th>
                <th>a</th>
                <th>EP(a)</th>
                <th>b</th>
                <th>EP(b)</th>
                <th>c</th>
                <th>EP(c)</th>
                </thead>
                <tbody>
                <tr>
                  <td>{parametrosCorrigidos._row}</td>
                  {this.columns.map(c => (
                    <td key={c}>{parametrosCorrigidos[c] ? parametrosCorrigidos[c].toLocaleString('pt-BR') : parametrosCorrigidos[c]}</td>
                  ))}
                </tr>
                </tbody>
              </table>
              <CurvaCaracteristica
                id="curva-caracteristica-chart"
                parametrosCorrigidos={parametrosCorrigidos}
                curva={curva}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderFooter() {
    const { toggle } = this.props;
    return <button className="btn btn-white" onClick={toggle}>Fechar</button>
  }

  render() {
    const { toggle, isOpen } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
      >
        <ModalHeader toggle={toggle}>
          {this.renderHeader()}
        </ModalHeader>
        <ModalBody className="p-0">
          {this.renderBody()}
        </ModalBody>
        <ModalFooter>
          {this.renderFooter()}
        </ModalFooter>
      </Modal>
    );
  }
}

export default ModalCurvaCaracteristica;
