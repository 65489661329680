import React from 'react';
import { asField } from 'informed';
import MultiSelect from '@kenshooui/react-multi-select';
import { get } from 'lodash';

const messages = {
  searchPlaceholder: 'Pesquisar...',
  noItemsMessage: 'Selecione um ou mais itens na coluna da esquerda',
  noneSelectedMessage: 'Nenhum selecionado',
  selectedMessage: 'selecionado(s)',
  selectAllMessage: 'Selecionar todos',
  clearAllMessage: 'Remover todos',
};

const InputMultiSelect = asField(({
  field, fieldState, fieldApi, ...props
}) => {
  const { setValue } = fieldApi;
  const {
    col,
    label,
    onChange,
    multi,
    required,
    items,
    ...rest
  } = props;
  const value = get(fieldState, 'value') || [];

  return (
    <MultiSelect
      messages={messages}
      onChange={selectedItems => setValue(selectedItems.map(item => item.id))}
      selectedItems={items.filter(item => value.find(i => i === item.id))}
      items={items}
      {...rest}
    />
  );
});

export default InputMultiSelect;
