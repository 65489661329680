import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Route, Redirect } from 'react-router-dom';
import { UserContext } from '../consts';
import Header from '../containers/global/Header';
import Footer from '../containers/global/Footer';

const PrivateRoute = ({
  navbar,
  footer,
  permission,
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      (localStorage.getItem('token') ? (
        <UserContext.Consumer>
          {(context) => {
            if (permission) {
              if (get(context, `user.permissions.${permission}`) !== true) {
                return <Redirect to="/404" />;
              }
            }
            return (
              <React.Fragment>
                {navbar && <Header {...context} />}
                <div style={{ background: 'rgb(249, 249, 249)' }}>
                  <Component {...props} {...context} />
                </div>
                {footer && <Footer />}
              </React.Fragment>
            );
          }}
        </UserContext.Consumer>
      ) : (
        <Redirect to="/login" />
      ))
    }
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  navbar: PropTypes.bool,
  footer: PropTypes.bool,
  permission: PropTypes.string,
};

PrivateRoute.defaultProps = {
  navbar: true,
  footer: true,
  permission: '',
};

export default PrivateRoute;
