import React from 'react';
import PropTypes from 'prop-types';
import ListTag from '../components/ListTag';
import ListData from '../components/ListData';

export default class ListItem extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    photo: PropTypes.string,
    description: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })),
    children: PropTypes.element,
    status: PropTypes.shape({
      label: PropTypes.string,
      color: PropTypes.string,
    }).isRequired,
    onClickExpand: PropTypes.func,
    onClickGroup: PropTypes.func,
    groupIcon: PropTypes.bool,
    actions: PropTypes.arrayOf(PropTypes.element),
    startCollapsed: PropTypes.bool,
  };

  static defaultProps = {
    title: '',
    photo: '',
    description: [],
    children: null,
    onClickExpand: () => {},
    onClickGroup: () => {},
    groupIcon: false,
    actions: [],
    startCollapsed: false,
  };

  constructor(props) {
    super(props);
    this.onClickExpand = this.onClickExpand.bind(this);
    this.onClickGroup = this.onClickGroup.bind(this);
    this.state = {
      collapse: props.startCollapsed,
      collapseGroup: false,
    };
  }

  onClickExpand() {
    this.setState({ collapse: !this.state.collapse, collapseGroup: false }, () => setTimeout(this.props.onClickExpand, 500));
  }

  onClickGroup() {
    this.setState({ collapseGroup: !this.state.collapseGroup, collapse: false });
  }

  renderImage() {
    if (this.props.photo) {
      return (
        <img
          className="image"
          alt={this.props.title}
          src={this.props.photo}
          width="100"
          height="100"
        />
      );
    }

    return null;
  }

  render() {
    const {
      description,
      title,
      small,
      status,
      vertical,
      actions,
      details,
      atualizado_em,
      versao,
      buttons,
      criado_em,
    } = this.props;

    return (
      <div className="list-item-base" >
        <div className="container">
          <div className="row">
            <div className="checkbox-input-item col-lg-1 col-md-1 col-sm-2 col-xs-2">
              {this.renderImage()}
              <label className="container-check-custom">
                <input
                  type="checkbox"
                  aria-label=""
                />
                <span className="checkmark" />
              </label>

            </div>
            <div className="col-lg-8 col-md-8 col-sm-10 col-xs-10">
              <ListData
                vertical={vertical}
                description={description}
                title={title}
                small={small}
                atualizado_em={atualizado_em}
                criado_em={criado_em}
                versao={versao}
                buttons={buttons}
              />
            </div>
            <div style={{ paddingLeft: 50, marginLeft: 40 }} className="col-lg-3 col-md-3 col-xs-12 col-sm-12">
              <ListTag
                onClickExpand={this.onClickExpand}
                details={details}
                actions={actions}
                groupIcon={this.props.groupIcon}
                onClickGroup={this.onClickGroup}
                {...status}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
