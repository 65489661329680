import React from 'react';
import PropTypes from 'prop-types';

export default class HabReference extends React.Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    onClick: () => {},
  }

  render() {
    const { onClick, text } = this.props;
    return (
      <div className="hability-tag mt-2" onClick={onClick}>
        <p>{text}</p>
      </div>
    );
  }
}
