import React from 'react';

class Video extends React.Component {

  render() {
    const { type } = this.props;

    if (type !== 'video/mp4') {
      return <p>Tipo incompatível</p>;
    }

    return (
      <video controls height="200">
        <source {...this.props} />
      </video>
    );
  }
}

export default Video;
