import React from 'react';
import axios from 'axios';
import { API_HOST } from '../consts';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Form, TextArea, Select } from 'informed';
import { InputSelect, InputText, InputTextArea } from './inputs';
import { toast } from 'react-toastify';
import { get } from 'lodash';

const ModalEditComentario = ({ isModalOpen, handleModal, comentario, avaliacaoItem, api }) => {
	const toggle = () => handleModal(!isModalOpen);

	const handleEditComentario = (formValues) => {
		const { editedComentario, editedTipoComentario } = formValues;

		if (editedTipoComentario !== 1 && editedTipoComentario !== 2) {
			return toast.error('Por favor, selecione uma opção válida.')
		}

		if (editedComentario === '') {
			return toast.error('Por favor, insira um comentário.')
		}

		const params = { comentario: editedComentario, tipo: editedTipoComentario };

		axios.patch(`${API_HOST}/avaliacoes/avaliacao_item_comentarios/${comentario.id}`, params)
			.then(() => {
				return axios
					.get(`${API_HOST}/avaliacoes/avaliacao_item`, {
						params: {
							avaliacao: avaliacaoItem.avaliacao.id
						}
					}).then((response) => {

						const itens = response.data.results || response.data

						api.setValues({
							itens
						})
					})
			})
			.catch(() => toast.error('Ocorreu um erro ao tentar atualizar o comentário. Tente novamente.'));

		toggle();
	};

	return (
		<Modal isOpen={isModalOpen} toggle={toggle} centered={true}>
			<ModalHeader>Editar Comentário</ModalHeader>
			<ModalBody>
				<Form
					onSubmit={handleEditComentario}
					initialValues={{ editedComentario: comentario.comentario, editedTipoComentario: comentario.tipo }}>
					{({ formState }) => (
						<>
							<div className='my-3'>
								<InputTextArea
									label="Comentário:"
									field="editedComentario"
									rows={7}
								/>
							</div>
							<div className='my-3'>
								<InputSelect
									label="Tipo de comentário"
									field="editedTipoComentario"
									options={[
										{ value: 1, label: 'Conteúdo' },
										{ value: 2, label: 'Valor' },
									]}
								>
								</InputSelect>
							</div>
							<ModalFooter>
								<button type="submit" className='btn btn-primary'>
									Editar Comentário
								</button>
								<button type="button" className='btn btn-secondary' onClick={toggle}>
									Cancelar
								</button>
							</ModalFooter>
						</>
					)}
				</Form>
			</ModalBody>
		</Modal>
	);
};

export default ModalEditComentario;
