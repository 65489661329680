import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Axios from 'axios';
import { get } from 'lodash';
import { API_HOST } from '../../consts';
import { useEffect } from 'react';
import ProficiencyScaleDreColumns from '../../components/ProficiencyScaleDreColumns';
import HeaderAvalia from '../../components/HeaderAvalia';
import HeaderRelatorio from '../../components/HeaderRelatorio';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const ReportProficiencyScalePage = ({ match, className }) => {
  const [schoolLevel, setSchoolLevel] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const theme = useContext(ThemeContext);

  const fetchSchoolLevel = () => {
    Axios.get(
      `${API_HOST}/avalia/distribuicao_percentual?cd_escola=${match.params.escolaId}&prova_a_cd_disciplina=${match.params.discId}`
    )
      .then((res) => {
        setSchoolLevel(res.data.results);
        setLoading(true);
      })
      .catch((err) => console.error(err));
  };
  useEffect(() => {
    fetchSchoolLevel();
  }, []);

  const titulo = [
    'Linguagens, Códigos e suas Tecnologias',
    'Matemática e suas Tecnologias'
  ];

  return (
    <div className={className}>
      <div style={{ marginTop: '3rem', background: '#fff' }}>
        <div className="container mb-4 pb-4">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h1>
                    Distribuição percentual dos alunos nos níveis da escala de
                    proficiência
                  </h1>
                  <hr />
                </div>
                <div
                  className="card-body"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                >
                  <HeaderRelatorio
                    schoolName={
                      schoolLevel ? get(schoolLevel[0], 'nm_escola') : ''
                    }
                    dreAndMunicipio={
                      schoolLevel ? get(schoolLevel[0], 'dre') : ''
                    }
                    disableStudent={false}
                    disableSchoolYear={false}
                  />
                  <div
                    className="school__material"
                    style={{ color: `${theme.primary}` }}
                  >
                    <span>
                      {
                        titulo[
                          schoolLevel
                            ? get(schoolLevel[0], 'prova_a_cd_disciplina', '') -
                              1
                            : ''
                        ]
                      }
                    </span>
                  </div>
                  {schoolLevel &&
                    schoolLevel.map((dados) => (
                      <div className="mt-2" style={{ width: '100%' }}>
                        <div
                          style={{
                            background: `${theme.primary}`,
                            borderTopLeftRadius: '10px',
                            borderTopRightRadius: '10px'
                          }}
                        >
                          <div
                            className="basic__info"
                            style={{
                              padding: '0.3rem',
                              justifyContent: 'center'
                            }}
                          >
                            <label
                              style={{ color: 'white', textAlign: 'center' }}
                            >
                              {' '}
                              {dados.prova_a_prova_ds}{' '}
                            </label>
                          </div>
                        </div>
                        <ProficiencyScaleDreColumns
                          dadosTable={dados}
                          pSize={schoolLevel}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ReportProficiencyScalePage);
