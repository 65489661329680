import React from 'react';
import PropTypes from 'prop-types'


const HeaderDre = ({
	discipline,
	schoolYear,
	dreAndMunicipio,

}) => {
	return (
		<>
			<div className="container__background">
				<div className="basic__info">
					<div className="input__background">
						<span className="first__info">
							Disciplina: <span>{discipline}</span>
						</span>
					</div>
					<div className="input__background">
						<span className="first__info">
							Etapa Escolar: <span>{schoolYear}</span>
						</span>

					</div>
				</div>
				<div className="basic__info">
					<div className="input__background">
						<span className="first__info">
							{/* Exemplo: DRE Juina | Juina |  */}
							DRE: <span>{dreAndMunicipio}</span>
						</span>
					</div>

				</div>
			</div>
		</>
	);
};

HeaderDre.propTypes = {
	discipline: PropTypes.string,
	schoolYear: PropTypes.string,
	dreAndMunicipio: PropTypes.string,
}

export default HeaderDre;
