import React from 'react';
import PropTypes from 'prop-types';
import { get, size } from 'lodash';
import classnames from 'classnames';
import FileList from '../FileList';

class InputFile extends React.Component {
  renderLoading() {
    const { uploading } = this.props;
    const progressEvent = get(this.props, 'progressEvent') || {};
    let loaded;

    if (!uploading) {
      loaded = 0;
    } else if (progressEvent.lengthComputable) {
      loaded = Math.trunc(100 * (progressEvent.loaded / progressEvent.total));
    }

    return uploading && (
      <div className="progress">
        <div
          className={classnames('progress-bar', { 'progress-bar-striped progress-bar-animated': loaded === 100 || !progressEvent.lengthComputable },)}
          style={{ width: `${loaded}%` }}
          role="progressbar"
        >
          {loaded && `${loaded}%`}
        </div>
      </div>
    );
  }

  renderInput() {
    const {
      files, onChange, multi, label, uploading,
    } = this.props;

    if (!multi && size(files) > 0) {
      return null;
    }

    return (
      <div className="col-md-12 form-group">
        <label>{label}</label>
        <br />
        {
          uploading
           ? this.renderLoading()
           : <input className="inputfile" type="file" onChange={onChange} />
       }
      </div>
    );
  }

  render() {
    const { files, onDelete, preview } = this.props;

    return (
      <React.Fragment>
        {this.renderInput()}
        <div className="col-md-12">
          <div className="row">
            <FileList
              files={files}
              onDelete={onDelete}
              preview={preview}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

InputFile.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({
    src: PropTypes.string,
    name: PropTypes.string,
    criado_em: PropTypes.string,
  })),
  preview: PropTypes.bool,
  onDelete: PropTypes.func,
  onChange: PropTypes.func,
  uploading: PropTypes.bool,
  label: PropTypes.string,
  multi: PropTypes.bool,
};

InputFile.defaultProps = {
  files: [],
  onChange: () => {},
  onDelete: () => {},
  uploading: false,
  label: '',
  multi: false,
  preview: true,
};

export default InputFile;
