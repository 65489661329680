import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash-es';
import InfoBox from './InfoBox';
import QuizSection from './QuizSection';
import QuizQuestion from './QuizQuestion';

class Quiz extends React.Component {
  getAnswer(question, section) {
    const { answers } = this.props;
    const answer = answers.find(a => a.pergunta === question.id && a.secao === section.id);
    return answer ? !!(+answer.valor) : null;
  }

  render() {
    const { quiz } = this.props;
    return (
      <InfoBox text={get(quiz, 'descricao')}>
        {get(quiz, 'secoes', []).map(s => (
          <QuizSection key={s.id} text={s.descricao}>
            {s.perguntas.map(p => (
              <QuizQuestion key={p.id} text={p.descricao} value={this.getAnswer(p, s)} />
            ))}
          </QuizSection>
        ))}
      </InfoBox>
    );
  }
}

Quiz.propTypes = {
  quiz: PropTypes.shape({
    descricao: PropTypes.string,
    secoes: PropTypes.array,
  }),
  answers: PropTypes.arrayOf(PropTypes.object),
};

Quiz.defaultProps = {
  quiz: {},
  answers: [],
};

export default Quiz;
