import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-fa';
import { Link } from 'react-router-dom';
import AddButton from '../components/buttons/AddButton';

class NxtButton extends React.Component {

  render() {
    const { onClick, to, disabled } = this.props;

    if (onClick) {
      return <AddButton disabled={disabled} onClick={onClick} noTitle icon="chevron-right" />;
    }

    return (
      <Link to={to}>
        <button className="btn primary-button"><Icon name="chevron-right"></Icon></button>
      </Link>
    );
  }
};

NxtButton.propTypes = {
  to: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

NxtButton.defaultProps = {
  onClick: null,
  disabled: false,
  to: '/',
};

export default NxtButton;
