import React from 'react';

export default class SwitchButton extends React.Component {
/*  constructor(props){
    super(props);
    this.onChange = this.onChange.bind(this);
  }
  state = {
    textSwitch: 'Ativar',
  };*/

/*  onChange(){
    if(this.props.isChecked === true){
      this.setState({
        textSwitch: 'Ativar'
      })
    }else{
      this.setState({
        textSwitch: 'Inativar'
      })

    }

  }*/
  render() {

    const { onChange, isChecked } = this.props;
    // const { textSwitch } = this.state;

    return (

        <label className="switch">
          <input type="checkbox" checked={isChecked} onChange={onChange}/>
          <div className="slider" />
        </label>

    );
  }
}
