import React from 'react';
import { asField } from 'informed';
import { AsyncCreatable } from 'react-select';
import { FormGroup } from 'reactstrap';

const InputCreatable = asField(({ field, fieldState, fieldApi, ...props }) => {
  const { value } = fieldState;
  const { setValue } = fieldApi;
  const {
    col,
    label,
    ...rest
  } = props;

  return (
    <FormGroup className={col}>
      <label>{label}</label>
      <AsyncCreatable
        onChange={(value) => {
          setValue(value);
        }}
        value={value}
        promptTextCreator={text => `Criar opção "${text}"`}
        {...rest}
      />
    </FormGroup>
  );
});

InputCreatable.defaultProps = {
  placeholder: 'selecione',
  loadingMessage: () => 'carregando...',
};

export default InputCreatable;
