import React from 'react';
import { Form } from 'informed';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { API_HOST } from '../../consts';
import { default as FormContainer } from '../../containers/Form';
import InputText from '../../components/inputs/InputText';
import InputSpan from '../../components/inputs/InputSpan';
import Loading from '../../components/Loading';
import InputSelect from '../../components/inputs/InputSelect';
import InputNumber from '../../components/inputs/InputNumber';
import InputCurrency from '../../components/inputs/InputCurrency';
import BaseForm from '../../base/BaseForm';
import { toast } from 'react-toastify';
import InputCheckbox from '../../components/inputs/InputCheckbox';
import { get } from 'lodash';

const mapAreaConhecimento = (x) => ({
  value: x.id,
  label: x.descricao
});

const mapInstituicao = (x) => ({
  value: x.id,
  label: x.descricao
});

class Dados extends BaseForm {
  constructor(props) {
    super(props);
    this.id = props.match.params.id;
    this.fields = [
      'nome',
      'inicio_realizacao_exame',
      'termino_realizacao_exame',
      'inicio_recebimento_item',
      'termino_recebimento_item',
      'inicio_validacao_pedagogica',
      'termino_validacao_pedagogica',
      'total_itens_encomenda',
      'total_itens_exame',
      'minimo_itens',
      'valor_elaboracao',
      'total_alternativas',
      'instituicao',
      'habilitar_fluxo'
    ];
    this.multiFields = ['areas_conhecimento'];
  }

  state = {
    areas_conhecimento: [],
    instituicoes: [],
    submitting: false,
    fetchingData: true
  };

  componentDidMount() {
    this.fetchData();
    this.fetchAreasConhecimento();
    this.fetchInstituicoes();
  }

  fetchAreasConhecimento() {
    axios.get(`${API_HOST}/core/area_conhecimento`).then((response) => {
      this.setState({
        areas_conhecimento: response.data.map(mapAreaConhecimento)
      });
    });
  }

  fetchInstituicoes() {
    axios.get(`${API_HOST}/core/instituicao`).then((response) => {
      this.setState({ instituicoes: response.data.map(mapInstituicao) });
    });
  }

  fetchData() {
    if (this.id !== 'new') {
      axios
        .get(`${API_HOST}/encomenda/encomenda/${this.id}/form`)
        .then((response) => {
          const { data } = response;
          let { valor_elaboracao } = data;
          if (valor_elaboracao) {
            valor_elaboracao = valor_elaboracao.replace('.', ',');
          }
          this.setFields({ ...data, valor_elaboracao });
          this.setState({ fetchingData: false });
        });
    } else {
      this.setState({ fetchingData: false });
    }
  }

  showDiv(id) {
    if (document.getElementById(id).style.display == 'none') {
      document.getElementById(id).style.display = 'flex';
    } else if (document.getElementById(id).style.display == 'flex') {
      document.getElementById(id).style.display = 'none';
    }
  }

  handleSubmit = (values) => {
    if (
      values.termino_recebimento_item > values.termino_realizacao_exame ||
      values.termino_validacao_pedagogica > values.termino_realizacao_exame
    ) {
      toast.error(
        'A data de termino do período deve ser estar dentro do período de "Termino da Realização da Encomenda"'
      );
      return;
    }
    this.setState({ submitting: true });

    Object.keys(values).forEach((x) => {
      if (values[x] === '') {
        values[x] = null;
      }
    });

    if (values.valor_elaboracao) {
      values.valor_elaboracao = values.valor_elaboracao
        .replace('R$ ', '')
        .replace(',', '.');
    }

    if (this.id === 'new') {
      axios
        .post(`${API_HOST}/encomenda/encomenda`, values)
        .then((response) => {
          const { history } = this.props;
          this.id = response.data.id;
          this.props.parentCallback(values.habilitar_fluxo);
          values.habilitar_fluxo
            ? history.push(`/encomendas/${response.data.id}/revisao`)
            : history.push(
                `/encomendas/${response.data.id}/matrizes-referencia`
              );
        })
        .catch(this.onValidateError);
    } else {
      axios
        .patch(`${API_HOST}/encomenda/encomenda/${this.id}`, values)
        .then(() => {
          const { history } = this.props;
          this.props.parentCallback(values.habilitar_fluxo);
          values.habilitar_fluxo
            ? history.push(`/encomendas/${this.id}/revisao`)
            : history.push(`/encomendas/${this.id}/matrizes-referencia`);
        })
        .catch(this.onValidateError);
    }
  };

  onValidateError = (error) => {
    this.setState({ submitting: false });
    super.onValidateError(error);
  };

  handleNext = (next) => {
    this.next = next;
    this.formApi.submitForm();
  };

  render() {
    const { areas_conhecimento, submitting, fetchingData, instituicoes } =
      this.state;

    return (
      <Form
        onChange={(formState) => this.setState({ formState })}
        getApi={(formApi) => {
          this.formApi = formApi;
        }}
        onSubmit={this.handleSubmit}
      >
        {() => {
          if (fetchingData) {
            return <Loading />;
          }

          return (
            <FormContainer
              next
              title="Dados gerais"
              onClick={() => this.formApi.submitForm()}
              disabled={submitting}
            >
              <div className="row">
                <InputText col="col-sm-12" label="Nome" field="nome" />
              </div>
              <div className="row">
                <InputSelect
                  col="col-sm-12"
                  label="Instituição"
                  options={instituicoes}
                  field="instituicao"
                />
              </div>
              <div className="row">
                <InputSelect
                  multi
                  col="col-sm-12"
                  label="Áreas de conhecimento"
                  options={areas_conhecimento}
                  field="areas_conhecimento"
                />
              </div>
              {get(this.props.permissions, '__all__') && (
                <div className="row" style={{ marginTop: '1rem' }}>
                  <InputCheckbox
                    col="col-md-12"
                    label="Habilitar novo fluxo (Ignorar fluxo de matriz e elaborador)"
                    field="habilitar_fluxo"
                  />
                </div>
              )}
              <hr />
              <div className="row">
                <InputSpan
                  col="col-sm-5"
                  label="Período de realização da encomenda"
                  leftAddon="início"
                  field="inicio_realizacao_exame"
                  type="date"
                  infoBtn={true}
                  onClick={this.onClickButton}
                  onMouseOver={(e) =>
                    this.showDiv('inicio_realizacao_exame_div')
                  }
                />
                <div
                  id="inicio_realizacao_exame_div"
                  style={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#5B5B5B',
                    color: ' white',
                    padding: '1%',
                    zIndex: '+15',
                    position: 'relative',
                    display: ' none',
                    borderRadius: '6px'
                  }}
                >
                  A abertura da encomenda para o status "Em andamento" será
                  automático a partir da data inicial e o mudança da encomenda
                  para o status "finalizada" será automático a partir da
                  definição da data término.
                </div>
                <InputSpan
                  col="col-sm-5"
                  leftAddon="término"
                  field="termino_realizacao_exame"
                  type="date"
                />
              </div>
              <div className="row">
                <InputSpan
                  col="col-sm-5"
                  label="Período de recebimento dos itens"
                  leftAddon="início"
                  field="inicio_recebimento_item"
                  type="date"
                  infoBtn={true}
                  onClick={this.onClickButton}
                  onMouseOver={(e) =>
                    this.showDiv('inicio_recebimento_item_div')
                  }
                />
                <div
                  id="inicio_recebimento_item_div"
                  style={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#5B5B5B',
                    color: ' white',
                    padding: '1%',
                    zIndex: '+15',
                    position: 'relative',
                    display: ' none',
                    borderRadius: '6px'
                  }}
                >
                  O recebimento de itens só será permitido dentro do período
                  definido neste campo.
                </div>
                <InputSpan
                  col="col-sm-5"
                  leftAddon="término"
                  field="termino_recebimento_item"
                  type="date"
                />
              </div>
              <div className="row">
                <InputSpan
                  col="col-sm-5"
                  label="Período de validação pedagógica "
                  leftAddon="início"
                  field="inicio_validacao_pedagogica"
                  type="date"
                  infoBtn={true}
                  onClick={this.onClickButton}
                  onMouseOver={(e) =>
                    this.showDiv('inicio_validacao_pedagogica_div')
                  }
                />
                <div
                  id="inicio_validacao_pedagogica_div"
                  style={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#5B5B5B',
                    color: ' white',
                    padding: '1%',
                    zIndex: '+15',
                    position: 'relative',
                    display: ' none',
                    borderRadius: '6px'
                  }}
                >
                  Todas as etapas de revisão só serão permitidas dentro do
                  período definido neste campo.
                </div>
                <InputSpan
                  col="col-sm-5"
                  leftAddon="término"
                  field="termino_validacao_pedagogica"
                  type="date"
                />
              </div>
              <hr />
              <div className="row">
                <InputNumber
                  col="col-sm-4"
                  label="Total de itens da encomenda"
                  field="total_itens_encomenda"
                />
                <InputNumber
                  col="col-sm-4"
                  label="Total de itens do exame"
                  field="total_itens_exame"
                />
                <InputNumber
                  col="col-sm-4"
                  label="Mínimo de itens para considerar a encomenda concluída"
                  field="minimo_itens"
                />
                <InputCurrency
                  col="col-sm-4"
                  label="Valor de elaboração do item"
                  field="valor_elaboracao"
                />
                <InputNumber
                  col="col-sm-4"
                  label="Quantidade de alternativas do item"
                  field="total_alternativas"
                  mask_="9"
                />
              </div>
            </FormContainer>
          );
        }}
      </Form>
    );
  }
}

export default withRouter(Dados);
