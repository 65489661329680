// Lists
export { default as ListaItens } from './itens/ListaItens';
// export { default as ListaEditais } from './editais/ListaEditais';
export { default as ListaPreTestes } from './pre-testes/ListaPreTestes';
export { default as ListaElaboradores } from './elaborador/ListaElaboradores';
// export { default as DashboardEdital } from './editais/dashboard-edital/DashboardEdital';
export { default as Avaliacao } from './avaliacoes/Avaliacao';
export { default as DashboardElaborador } from './elaborador/dashboard-elaborador/DashboardElaborador';
export { default as ListaMatriz } from './matriz-referencia/ListaMatriz';
// export { default as ListaRecursos } from './recursos/ListaRecursos';
export { default as ListaAvaliacao } from './avaliacoes/ListaAvaliacao';
// export { default as ListaInstrumentosNaoCognitivos } from './instrumentos-nao-cognitivos/ListaInstrumentosNaoCognitivos'
export { default as ListaEncomendas } from './encomendas/ListaEncomendas';
export { default as ListaTestes } from './testes/ListaTestes';
export { default as ListaAplicacoes } from './avaliacoes/ListaAplicacoes';
export { default as GerenciamentoAplicacao } from './avaliacoes/GerenciamentoAplicacao';
export { default as GerenciamentoResultados } from './avaliacoes/GerenciamentoResultados';
export { default as Notas } from './avaliacoes/Notas';

// Forms
export { default as AnalisePsicometrica } from './analise-psicometrica/AnalisePsicometrica';
// export { default as Edital } from './editais/Edital';
export { default as PreTeste } from './pre-testes/PreTeste';
// export { default as Elaborador } from './elaborador/Elaborador';
export { default as Encomenda } from './encomendas/Encomenda';
export { default as Itens } from './itens/Itens';
export { default as AnularItens } from './AnularItens';
export { default as MatrizReferencia } from './matriz-referencia/MatrizReferencia';
// export { default as AnaliseClassica } from './analise-classica/AnaliseClassica';
// export { default as InstrumentoNaoCognitivo } from './instrumentos-nao-cognitivos/InstrumentoNaoCognitivo';
export { default as HomePage } from './home/HomePage';
// export { default as DashboardItens } from './itens/dashboard-itens/DashboardItens';
// export { default as RevisarItem } from './itens/RevisarItem';
export { default as Teste } from './testes/Teste';

export { default as Revisao } from './revisoes/Revisao';
export { default as RevisaoForm } from './revisoes/RevisaoForm';
export { default as ListaRecursos } from './recursos/ListaRecursos';
export { default as NovoRecurso } from './recursos/NovoRecurso';
export { default as ListaRevisoes } from './revisoes/ListaRevisoes';

// CONFIGURAÇÕES
export { default as Grupo } from './configuracoes/Grupo';
export { default as ListaGrupos } from './configuracoes/ListaGrupos';
export { default as ListaUsuarios } from './configuracoes/ListaUsuarios';
export { default as Exame } from './configuracoes/Exame';
export { default as ListaExames } from './configuracoes/ListaExames';
export { default as ListaEixos } from './configuracoes/ListaEixos';
export { default as Eixo } from './configuracoes/Eixo';
export { default as ListaCompetencias } from './configuracoes/ListaCompetencias';
export { default as Competencia } from './configuracoes/Competencia';
export { default as ListaHabilidades } from './configuracoes/ListaHabilidades';
export { default as Habilidade } from './configuracoes/Habilidade';
export { default as PerfilUsuario } from './usuario/PerfilUsuario';

// Análise
export { default as GerarAnalise } from './analise-psicometrica/GerarAnalise';
export { default as AnaliseTCT } from './analise-psicometrica/AnaliseTCT';
export { default as AnaliseTRI } from './analise-psicometrica/AnaliseTRI';

// AVALIA
export { default as StudentAnalysis } from './avalia/StudentAnalysis';
export { default as HeadQuartersPage } from './avalia/HeadQuartersPage';
export { default as RelatorioTurma } from './avalia/RelatorioTurma';
export { default as HighchartSkill } from './avalia/HighchartSkill';

// Relatorio
export { default as ReportStudentAnalysis } from './relatorio/ReportStudentAnalysis';
export { default as ReportSkillLevelPage } from './relatorio/ReportSkillLevelPage';
export { default as ClassReportPage } from './relatorio/ClassReportPage';
export { default as ReportMapGraph } from './relatorio/ReportMapGraph';
export { default as ReportProficiencyScalePage } from './relatorio/ReportProficiencyScalePage';
export { default as ReportDrePage } from './relatorio/ReportDrePage';
