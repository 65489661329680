import React from 'react';
import { get } from 'lodash';
import ModalCurvaCaracteristica from '../../components/ModalCurvaCaracteristica';

class EtapaFinalCalibracao extends React.Component {
  state = {
    openModal: null,
  };

  columns = ['a', 'epa', 'b', 'epb', 'c', 'epc'];

  handleCurvaClick = (d) => {
    this.setState({
      openModal: 'curva',
      parametrosCorrigidosItemSelecionado: { ...d },
    });
  };

  toggleModal = (modal) => {
    const { openModal } = this.state;
    this.setState({ openModal: openModal === modal ? null : modal });
  };

  renderRow = (d) => {
    if (d.is_bad || d.a === undefined) {
      return (
        <tr className="bg-danger">
          <td>{d._row}</td>
          <td colSpan="7" className="anulado-tri">
            <i className="fas fa-minus-circle wrong" /> ITEM ANULADO PELA TRI
          </td>
          {/*{this.columns.map(() => <td />)}*/}
        </tr>
      );
    }

    return (
      <tr>
        <td>{d._row}</td>
        {this.columns.map((c) => (
          <td key={c}>{d[c] ? d[c].toLocaleString('pt-BR') : d[c]}</td>
        ))}
        <td>
          <button
            className="btn btn-white"
            onClick={() => this.handleCurvaClick(d)}
          >
            <i className="fas fa-eye" />
          </button>
        </td>
      </tr>
    );
  };

  get parametrosCorrigidosItemSelecionado() {
    const { parametrosCorrigidos } = this.props;
    const { parametrosCorrigidosItemSelecionado } = this.state;
    const _row = get(parametrosCorrigidosItemSelecionado, '_row');
    return parametrosCorrigidos.find((d) => get(d, '_row') === _row) || {};
  }

  get curva() {
    const { curvas } = this.props;
    return curvas.filter(
      (d) => get(d, 'item') === this.parametrosCorrigidosItemSelecionado._row
    );
  }

  render() {
    const { parametrosCorrigidos } = this.props;
    const { openModal } = this.state;

    return (
      <React.Fragment>
        <table className="table table-etapa">
          <thead>
            <tr>
              <th>Item</th>
              <th>a</th>
              <th>EP(a)</th>
              <th>b</th>
              <th>EP(b)</th>
              <th>c</th>
              <th>EP(c)</th>
              <th width="200px">Curva característica</th>
            </tr>
          </thead>
          <tbody>{parametrosCorrigidos.map(this.renderRow)}</tbody>
        </table>
        <ModalCurvaCaracteristica
          isOpen={openModal === 'curva'}
          toggle={() => this.toggleModal('curva')}
          parametrosCorrigidos={this.parametrosCorrigidosItemSelecionado}
          curva={this.curva}
        />
      </React.Fragment>
    );
  }
}

export default EtapaFinalCalibracao;
