import styled from 'styled-components';

export const TemplateCardStyle = styled.div`
  margin: 10px;
  border: 1px #BFBFBF solid;
  border-radius: 3px;
  background: #fff;
  padding: 10px;

`
