import React, { useEffect, useRef } from 'react';
import {
  select,
  scaleBand,
  axisBottom,
  stack,
  max,
  scaleLinear,
  axisLeft,
  stackOrderAscending,
  format,
  curveMonotoneX,
  line
} from 'd3';
import useResizeObserver from './useResizeObserver';
function BarChartSkill({ data, keys, colors }) {
  const svgRef = useRef();
  const wrapperRef = useRef();
  const dimensions = useResizeObserver(wrapperRef);
  const data1 = [
    {
      percent: 0.27,
      proficiencia: 'Adequado'
    }
  ];
  useEffect(() => {
    var div = select('body')
      .append('div')
      .attr('class', 'tooltip')
      .style('opacity', 0)
      .style('background-color', '#B8B8B8');
    const svg = select(svgRef.current);
    const { width, height } =
      dimensions || wrapperRef.current.getBoundingClientRect();
    // stacks / layers
    const stackGenerator = stack().keys(keys).order(stackOrderAscending);
    const layers = stackGenerator(data);
    const extent = [0, 1];
    // scales
    const xScale = scaleBand()
      .domain(data.map((d) => d.nivel))
      .range([0, width])
      .padding(0.4);
    const yScale = scaleLinear().range([height, 0]).domain(extent);
    // rendering
    svg
      .selectAll('.layer')
      .data(layers)
      .join('g')
      .attr('class', 'layer')
      .attr('fill', (layer) => colors[layer.key])
      .selectAll('rect')
      .data((layer) => layer)
      .join('rect')
      .attr('x', (sequence) => xScale(sequence.data.nivel))
      .attr('width', xScale.bandwidth())
      .attr('y', (sequence) => yScale(sequence[1]))
      .attr('height', (sequence) => height - yScale(sequence[1]))
      .attr('opacity', (layer) => 1)
      .attr('borderRadius', '200px')
      .on('mouseover', function (d, i) {
        select(this).transition().duration('100');
        div
          .transition()
          .duration(100)
          .style('opacity', 1)
          .style('background-color', colors[i['data'].nivel])
          .style('color', 'black');
        div
          .html(i['data'].nivel + ': ' + i[1] * 100 + '%')
          .style('font-family', 'helvetica')
          .style('font-size', '15px')
          .style('left', d.screenX + div._groups[0][0].offsetWidth + 'px')
          .style('top', (i.nota <= 100 ? d.layerY + 60 : d.layerY + 150) + 'px')
          .attr('color', '#B8B8B8');
      })
      .on('mouseout', function (d, i) {
        select(this).transition().duration('200');
        div.transition().duration('200').style('opacity', 0);
      });

    const xAxis = axisBottom(xScale);
    svg
      .select('.x-axis')
      .attr('transform', `translate(0, ${height})`)
      .call(xAxis);
    const yAxis = axisLeft(yScale).tickFormat(format('~%'));
    const makeYLines = () => axisLeft().scale(yScale);

    svg
      /* Nessa segunda declaração de estilo, temos as linhas que introduzem as linhas horizontais do grid */
      .append('g')
      .attr('class', 'grid')
      .call(makeYLines().tickSize(-width, 0, 0).tickFormat(''))
      .attr('color', '#8B8B8B')
      .style('z-index', 3);
    svg.select('.y-axis').call(yAxis);
  }, [colors, data, dimensions, keys]);
  return (
    <div>
      <div
        ref={wrapperRef}
        style={{ marginBottom: '2rem', marginRight: '1.3rem' }}
      >
        <svg ref={svgRef} className="test">
          <g className="x-axis" />
          <g className="y-axis" />
        </svg>
      </div>
    </div>
  );
}
export default BarChartSkill;
