import React from 'react';
import Loading from './Loading';

const ParametroItem = ({ col, color, value, label, title, name, loading }) => (
  <div className={`${col} text-center`}>
    <h3 style={{ fontSize: 15, color, textTransform: 'UPPERCASE', marginBottom: '20px' }}><b>{title}</b></h3>
    {loading ? <Loading /> : (
      <React.Fragment>
        <i style={{ fontSize: 37, color }} className="fas fa-check-circle" />
        <p style={{ fontSize: 16 }} className="pt-2 cinza">{label}</p>
        <hr style={{ borderBottom: `1px solid ${color}`, width: '90px' }} />
        <p className="cinza">{name}: <b>{value}</b></p>
      </React.Fragment>
    )}
  </div>
);

export default ParametroItem;
