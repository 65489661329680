import React from 'react';
import { InputGroup, InputGroupAddon } from 'reactstrap';
import { BasicText, asField } from 'informed';
import classnames from 'classnames';
import Icon from 'react-fa';

export default asField(
  ({
    fieldState,
    field,
    label,
    helpBlock,
    numbers,
    col,
    required,
    leftAddon,
    rightAddon,
    infoBtn,
    onClick,
    onMouseOver,
    ...props
  }) => (
    <div
      className={classnames('form-group', col, {
        required,
        'has-error': fieldState.error
      })}
    >
      {label ? <label>{label}</label> : <label />}
      {infoBtn ? (
        <button
          className="btn btn-white"
          onMouseOver={onMouseOver}
          onClick={onClick}
          style={{
            width: ' 30px',
            height: ' 30px',
            margin: '0px 5px',
            marginLeft: '5px'
          }}
        >
          <Icon name="info" style={{ width: '100%', height: '100%' }} />
        </button>
      ) : (
        ''
      )}
      <InputGroup className={`form-input ${label ? '' : 'no-label'}`}>
        {leftAddon && (
          <InputGroupAddon addonType="prepend" className="addon-left">
            {' '}
            {leftAddon}{' '}
          </InputGroupAddon>
        )}
        <BasicText
          className="form-control"
          fieldState={fieldState}
          id={field}
          {...props}
        />
        {rightAddon && (
          <InputGroupAddon addonType="append" className="addon-right">
            {' '}
            {rightAddon}{' '}
          </InputGroupAddon>
        )}
      </InputGroup>
      {fieldState.error && (
        <small className={fieldState.error ? 'text-danger' : null}>
          {fieldState.error}
        </small>
      )}
    </div>
  )
);
