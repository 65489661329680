import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { get, size } from 'lodash';
import classnames from 'classnames';
import { API_HOST } from '../../consts';
import ReguaProficiencia from '../../components/ReguaProficiencia';
import CurvaProficiencia from '../../components/CurvaProficiencia';
import Loading from '../../components/Loading';
import BaseItens from '../../base/testes/BaseItens';

class Itens extends BaseItens {
  fetchAnaliseTries = 0;
  maxFetchAnaliseTries = 3;

  state = {
    ...this.initialState,
    regua: [],
    curva: [],
    showRegua: true,
    showCurva: true,
    fetchAnaliseError: false
  };

  get shouldFetchAnalise() {
    const itens = get(this.formApi.getState(), 'values.itens');
    return size(itens) > 2;
  }

  fetchAnalise = (loading = true) => {
    if (!this.shouldFetchAnalise) {
      return;
    }

    if (loading) {
      this.setState({ fetchingAnalise: true });
    } else {
      this.setState({ updatingAnalise: true });
    }

    const { itens } = this.formApi.getState().values;
    const { bloco } = this.state;
    const data = {
      itens: itens.map((item) => get(item, 'item.id'))
    };

    axios
      .put(`${API_HOST}/testes/bloco/${bloco}/analise`, data)
      .then((response) => {
        const curva = get(response, 'data.curva_informacao') || [];
        curva.forEach((d) => {
          d.theta = parseFloat(d.theta);
          d.tinfo = parseFloat(d.tinfo);
        });

        this.setState({
          curva,
          regua: get(response, 'data.regua'),
          fetchAnaliseError: false,
          fetchingAnalise: false,
          updatingAnalise: false
        });
      })
      .catch((error) => {
        this.fetchAnaliseTries += 1;
        if (this.fetchAnaliseTries < this.maxFetchAnaliseTries) {
          this.fetchAnalise(loading);
        } else {
          const fetchAnaliseError = get(
            error,
            'response.data.non_field_errors[0]',
            'Ocorreu um erro ao gerar a análise do bloco.'
          );
          this.setState({
            fetchAnaliseError,
            fetchingAnalise: false,
            updatingAnalise: false
          });
        }
      });
  };

  toggleCurva = () => {
    const { showCurva } = this.state;
    this.setState({ showCurva: !showCurva });
  };

  toggleRegua = () => {
    const { showRegua } = this.state;
    this.setState({ showRegua: !showRegua });
  };

  renderAnalise() {
    const {
      showCurva,
      showRegua,
      regua,
      curva,
      fetchAnaliseError,
      fetchingAnalise,
      updatingAnalise
    } = this.state;

    if (fetchingAnalise) {
      return <Loading message="Gerando análise..." />;
    }

    if (size(this.formApi.getState().values.itens) < 3) {
      return (
        <p>Adicione pelo menos três itens para gerar a análise do bloco.</p>
      );
    }

    if (fetchAnaliseError) {
      return (
        <React.Fragment>
          <p className="text-danger">
            {fetchAnaliseError}
            <br />
            <button className="btn primary-button" onClick={this.fetchAnalise}>
              {' '}
              Tentar novamente
            </button>
          </p>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <ul className="nav nav-pills mb-3 teste-buttons">
          <li className="nav-item">
            <button
              className={classnames('btn primary-button', {
                deactivated: !showRegua
              })}
              onClick={this.toggleRegua}
            >
              Régua
            </button>
          </li>
          <li className="nav-item ml-3">
            <button
              className={classnames('btn primary-button', {
                deactivated: !showCurva
              })}
              onClick={this.toggleCurva}
            >
              Curva
            </button>
          </li>
        </ul>

        <div className="row">
          <div className="col-12 mb-2">
            <div className="card p-0 card-blocos">
              <div className="card-header " style={{ borderBottom: 'none' }}>
                <div className="row">
                  <div className="col-6">
                    <h5>Régua</h5>
                  </div>
                  <div className="col-6">
                    <button
                      className="btn btn-white pull-right"
                      onClick={this.toggleRegua}
                    >
                      <i
                        className={classnames('fas fa-chevron-up', {
                          'fa-chevron-down': !showRegua
                        })}
                      />
                    </button>
                  </div>
                </div>
              </div>
              {showRegua && (
                <Fragment>
                  <div className="card-body bg-white">
                    {updatingAnalise && (
                      <p className="text-center">
                        <i className="text-center fas fa-spinner fa-spin azul" />
                      </p>
                    )}
                    {!updatingAnalise && <ReguaProficiencia data={regua} />}
                  </div>
                </Fragment>
              )}
            </div>
          </div>

          <div className="col-12 mb-3">
            <div className="card p-0 card-blocos">
              <div className="card-header" style={{ borderBottom: 'none' }}>
                <div className="row">
                  <div className="col-6">
                    <h5>Curva</h5>
                  </div>
                  <div className="col-6">
                    <button
                      className="btn btn-white pull-right"
                      onClick={this.toggleCurva}
                    >
                      <i
                        className={classnames('fas fa-chevron-up', {
                          'fa-chevron-down': !showCurva
                        })}
                      />
                    </button>
                  </div>
                </div>
              </div>
              {showCurva && (
                <Fragment>
                  <div className="card-body bg-white">
                    {updatingAnalise && (
                      <p className="text-center">
                        <i className="text-center fas fa-spinner fa-spin azul" />
                      </p>
                    )}
                    {!updatingAnalise && <CurvaProficiencia data={curva} />}
                  </div>
                </Fragment>
              )}
            </div>
          </div>

          {/*
            {showCurva && (
              <div className="col-12 mb-3">
                <div className="card">
                  <div className="card-body">
                    {updatingAnalise && <i className="fas fa-spinner fa-spin pull-right azul"/>}
                    <h5 className="mb-3">Curva</h5>
                    <CurvaProficiencia data={curva}/>
                  </div>
                </div>
              </div>
            )}
          */}
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Itens);
