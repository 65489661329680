import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Form } from 'informed';

export default class ImportarCSV extends React.Component {
  state = {
    event: null,
  }

  handleChange = (event) => {
    this.setState({ files: event.target.files });
  }

  renderBody = () => (
    <Form>
      {() => (
        <React.Fragment>
          <div className="row p-4">
            <div className="col-12">
              <p>O arquivo a ser importado deve atender aos seguintes critérios:</p>
            </div>
            <div className="col-12">
              <ul className="lista-normal">
                <li>Possui as colunas: NOME e RA do aluno</li>
                <li>Posuir a extensão .csv</li>
                <li>Utilizar vírgula (,) como separador</li>
                <li>Utilizar encoding UTF-8</li>
              </ul>
            </div>
            <div className="col-12">
              <input type="file" label="Importar" onChange={this.handleChange} />
            </div>
            <br />
          </div>
        </React.Fragment>
      )}
    </Form>
  );

  render() {
    const {
      toggle, isOpen, nome, onChange, loading,
    } = this.props;
    const { files } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
      >
        <ModalHeader toggle={toggle}>
          Importar {nome}
        </ModalHeader>
        <ModalBody style={{ borderRadius: '0px 0px 5px 5px' }} className="p-0">
          {this.renderBody()}
        </ModalBody>
        <ModalFooter>
          <button onClick={toggle} className="btn btn-white">Cancelar</button>
          <button className="btn btn-padrao bg-padrao" onClick={() => onChange(files)}>
            {loading ? 'Importando...' : 'Importar'}
          </button>
        </ModalFooter>

      </Modal>
    );
  }
}
