import { withRouter } from 'react-router-dom';
import Dados from './Dados';
import Cadernos from './Cadernos';
import Blocos from './Blocos';
import Itens from './Itens';
import Confirmar from './Confirmar';
import BaseTeste from '../../base/BaseTeste';

class PreTeste extends BaseTeste {
  urlPrefix = 'pre-testes';
  verboseName = 'Pré-teste';
  verboseNamePlural = 'Pré-testes';
  itensStep = Itens;

  get steps() {
    const steps = super.steps;
    steps[0].component = Dados;
    steps[1].component = Cadernos;
    steps[2].component = Blocos;
    steps[3].component = Confirmar;
    return steps;
  }
}

export default withRouter(PreTeste);
