import React from 'react';
import { withRouter } from 'react-router-dom';
import Dados from './Dados';
import Itens from './Itens';
import { Route, Switch, Redirect } from 'react-router-dom';
import ProgressTracker from '../../containers/ProgressTracker';
import NavBreadCrumb from '../../containers/NavBreadCrumb';
import IntegracaoTOTVS from './IntegracaoTOTVS';
import { API_HOST } from '../../consts';
import axios from 'axios';

class Avaliacao extends React.Component {
  label = 'Instrumentos cognitivos';
  link = '/instrumentos-cognitivos';

  state = {
    showTotvsIntegrationFlow: false
  };

  componentDidMount() {
    this.showTotvsIntegrationFlow();
  }

  showTotvsIntegrationFlow() {
    axios
      .get(`${API_HOST}/avaliacoes/avaliacao/show_integracao_totvs_section`)
      .then((res) => {
        this.setState({ showTotvsIntegrationFlow: res.data });
      });
  }

  get steps() {
    const {
      match,
      location: { pathname }
    } = this.props;
    const { showTotvsIntegrationFlow } = this.state;

    const itensLink = showTotvsIntegrationFlow ? `?show=true` : ``;
    return [
      {
        id: 1,
        title: 'Dados gerais',
        link: `${this.link}/${match.params.id}/dados`,
        path: `${this.link}/:id/dados`,
        active: pathname === `${this.link}/${match.params.id}/dados`,
        done: match.params.id !== 'new',
        disabled: false,
        component: Dados
      },
      {
        id: 2,
        title: 'Adicionar itens',
        link: `${this.link}/${match.params.id}/itens${itensLink}`,
        path: `${this.link}/:id/itens`,
        active: pathname === `${this.link}/${match.params.id}/itens`,
        done: false,
        disabled: match.params.id === 'new',
        component: Itens
      },
      {
        id: 3,
        title: 'Integração TOTVS',
        link: `${this.link}/${match.params.id}/IntegracaoTOTVS`,
        path: `${this.link}/:id/IntegracaoTOTVS`,
        active: pathname === `${this.link}/${match.params.id}/IntegracaoTOTVS`,
        done: false,
        hide: !showTotvsIntegrationFlow,
        disabled: match.params.id === 'new',
        component: IntegracaoTOTVS
      }
    ];
  }

  render() {
    const { match } = this.props;
    const pathList = [
      {
        label: 'Instrumentos cognitivos',
        icon: 'list',
        link: '/instrumentos-cognitivos'
      }
    ];
    const currentPath =
      match.params.id === 'new'
        ? 'Novo instrumento cognitivo'
        : `Instrumento cognitivo ${match.params.id}`;

    return (
      <div>
        <NavBreadCrumb pathList={pathList} currentPath={currentPath} />
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <ProgressTracker steps={this.steps} />
            </div>
            <div className="col-sm-9">
              <Switch>
                {this.steps.map((step, index) => (
                  <Route
                    key={step.id}
                    path={step.path}
                    render={() =>
                      step.disabled ? (
                        <Redirect to="/instrumentos-cognitivos/new/dados" />
                      ) : (
                        <step.component step={index} />
                      )
                    }
                  />
                ))}
                <Route
                  path="/instrumentos-cognitivos/:id"
                  render={() => (
                    <Redirect
                      to={`/instrumentos-cognitivos/${match.params.id}/dados`}
                    />
                  )}
                />
                <Redirect to="/instrumentos-cognitivos" />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Avaliacao);
