import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import CircleGraph from '../../../containers/CircleGraph';
import { updateStep } from '../../../actions';

class DistribuicaoCategoria extends React.Component {
  componentWillMount() {
    this.props.dispatch(updateStep({
      activeStepIndex: 0,
    }));
  }

  render() {
    const data = [
      { name: '2010', corretores: 200, elaboradores: 400, revisores: 160 },
      { name: '2011', corretores: 240, elaboradores: 500, revisores: 200 },
      { name: '2012', corretores: 260, elaboradores: 600, revisores: 270 },
      { name: '2013', corretores: 300, elaboradores: 700, revisores: 300 },
      { name: '2014', corretores: 400, elaboradores: 800, revisores: 340 },
      { name: '2015', corretores: 500, elaboradores: 1000, revisores: 390 },
      { name: '2016', corretores: 600, elaboradores: 1100, revisores: 440 },
      { name: '2017', corretores: 800, elaboradores: 1200, revisores: 500 },
    ];
    return (
      <div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-sm-3">
                <h5 align="middle">Total de Elaboradores</h5>
                <hr />
                <CircleGraph title="4000" subtitle="Elaboradores" color="#4CC1F3" />
              </div>
              <div className="col-sm-9">
                <h5 align="middle">Distribuição por Categoria</h5>
                <hr />
                <div className="row">
                  <div className="col-sm-4">
                    <CircleGraph title="2000" subtitle="Elaboradores de Item" color="#DC6F34" />
                  </div>
                  <div className="col-sm-4">
                    <CircleGraph title="2500" subtitle="Revisores de Item" color="#8AB02B" />
                  </div>
                  <div className="col-sm-4">
                    <CircleGraph title="800" subtitle="Corretores de Item" color="#21A4B6" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-sm-12">
                <h5 align="middle">Evolução de Elaboradores</h5>
                <hr />
                <ResponsiveContainer width="100%" height={500}>
                  <AreaChart
                    data={data}
                    margin={{
                      top: 10,
                      right: 30,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <XAxis dataKey="name" />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3" />
                    <Tooltip />
                    <Area type="monotone" dataKey="corretores" stackId="1" stroke="#22a4b6" fill="#22a4b6" />
                    <Area type="monotone" dataKey="elaboradores" stackId="1" stroke="#de6f2a" fill="#de6f2a" />
                    <Area type="monotone" dataKey="revisores" stackId="1" stroke="#89b219" fill="#89b219" />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  distribuicaoCategoria: state.distribuicaoCategoria,
});

export default withRouter(connect(mapStateToProps)(DistribuicaoCategoria));
