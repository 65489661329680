import React from 'react';
import { Form } from 'informed';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import { get, debounce } from 'lodash';
import { toast } from 'react-toastify';
import {
  InputSelect,
  InputText,
  InputSummernote,
  InputCheckbox
} from '../../components/inputs';
import { API_HOST } from '../../consts';
import { BaseForm } from '../../base';
import Loading from '../../components/Loading';
import Item from '../../components/Item';

class Etapa2 extends BaseForm {
  constructor(props) {
    super(props);
    this.onValueChange = this.onValueChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.id = get(props, 'match.params.id');
    this.fields = [
      'status_item',
      'comentario',
      'notificar_elaborador',
      'destinatario_copia'
    ];
  }

  state = {
    tipo: [],
    save: 0,
    metadata: {},
    fetchingData: false,
    status_item: []
  };

  componentDidMount() {
    this.fetchStatusItem();
    this.fetchRevisao();
  }

  fetchRevisao() {
    axios
      .get(`${API_HOST}/revisoes/revisao/${this.id}/form`)
      .then((response) => {
        const hasUpdatePermission = get(response, 'data.permissions.update');
        if (hasUpdatePermission) {
          const values = this.getValues(response.data);
          this.formApi.setValues(values);
          this.fetchItem(response.data.item);
        } else {
          const { history } = this.props;
          history.push('/itens');
        }
      });
  }

  fetchStatusItem() {
    axios
      .get(`${API_HOST}/itens/status`, {
        params: {
          permitir_definir_item_durante_revisao: true
        }
      })
      .then((response) => {
        this.setState({
          status_item: response.data.map((x) => ({
            value: x.id,
            label: x.descricao
          }))
        });
      });
  }

  fetchItem(item) {
    axios.get(`${API_HOST}/itens/item/${item}`).then((response) => {
      this.setState({ item: response.data });
    });
  }

  fetchMetadata() {
    axios
      .get(`${API_HOST}/params/param?model=item&is_active=true`)
      .then((response) => {
        this.setState({ metadata: this.mapMetadata(response.data) });
      });
  }

  onValueChange(values) {
    this.setState({ saving: true });
    this.patchRevisao(values);
  }

  patchRevisao = debounce((values) => {
    axios
      .patch(`${API_HOST}/revisoes/revisao/${this.id}`, this.getValues(values))
      .then(() => this.setState({ saving: false }));
  }, 1000);

  onSubmit() {
    this.setState({ submitting: true });

    axios
      .get(`${API_HOST}/revisoes/revisao/${this.id}/finalizar`)
      .then(() => {
        const { history } = this.props;
        toast.success('Revisão enviada com sucesso!');
        history.push('/revisoes');
        this.setState({ submitting: false });
      })
      .catch((error) => {
        this.onValidateError(error);
        this.setState({ submitting: false });
      });
  }

  fetchUpdateVersion(item) {
    return axios
      .post(`${API_HOST}/itens/item/${item}/save_log_itens`, {})
      .then((response) => {});
  }
  fetchUpdateVersionAlternativa(item) {
    return axios
      .post(`${API_HOST}/itens/atividades/${item}/save_log_alternativas`, {})
      .then((response) => {});
  }

  render() {
    const { status_item, fetchingData, item, submitting, saving } = this.state;

    return (
      <React.Fragment>
        <div className="card card-bg mb-3">
          <div className="card-header pb-0">
            <h1>Revisão e comentários</h1>
          </div>
          <div className="card-body">
            <div className="mb-3">
              <Item item={item} />
            </div>
            <hr />
            <Form
              onChange={(formState) => this.setState({ formState })}
              onValueChange={this.onValueChange}
              onSubmit={this.onSubmit}
              getApi={(formApi) => {
                this.formApi = formApi;
              }}
            >
              {() => {
                if (fetchingData) {
                  return <Loading />;
                }

                return (
                  <React.Fragment>
                    <div className="row">
                      <InputSelect
                        required
                        col="col-sm-12"
                        label="Definir item como"
                        field="status_item"
                        options={status_item}
                      />
                      <InputSummernote
                        required
                        col="col-sm-12"
                        label="Comentários"
                        field="comentario"
                      />
                      <InputCheckbox
                        required
                        col="col-sm-12"
                        label="Notificar elaborador via email"
                        field="notificar_elaborador"
                      />
                    </div>
                    <hr />
                    <div className="row">
                      <InputText
                        col="col-sm-4"
                        label="Enviar cópia de email para"
                        field="destinatario_copia"
                      />
                    </div>
                    <div className="row mt-3 pull-right">
                      <Link to="1">
                        <span className="btn primary-button mr-2">
                          <i className="fas fa-chevron-left" />
                        </span>
                      </Link>
                      <button
                        className="btn primary-button mr-2"
                        type="submit"
                        disabled={submitting || saving}
                      >
                        {submitting ? 'FINALIZANDO...' : 'FINALIZAR'}
                      </button>
                    </div>
                  </React.Fragment>
                );
              }}
            </Form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Etapa2);
