import React from 'react';
import axios from 'axios';
import { Route, withRouter, Switch, Redirect } from 'react-router-dom';
import ProgressTracker from '../../containers/ProgressTracker';
import NavBreadCrumb from '../../containers/NavBreadCrumb';
import Dados from './Dados';
import Pedido from './Pedido';
import Elaboradores from './Elaboradores';
import Revisao from './Revisao';
import Revisores from './Revisores';
import Confirmar from './Confirmar';
import { API_HOST } from '../../consts';

class Encomenda extends React.Component {
  constructor(props) {
    super(props);
    this.id = props.match.params.id;
  }
  state = {
    data: {}
  };

  componentDidMount() {
    this.fetchEncomenda();
  }

  fetchEncomenda() {
    axios.get(`${API_HOST}/encomenda/encomenda/${this.id}`).then((response) => {
      this.setState({ data: response.data.habilitar_fluxo });
    });
  }

  handleCallback = (childData) => {
    this.setState({ data: childData });
  };

  get steps() {
    const {
      match,
      location: { pathname }
    } = this.props;
    const { data } = this.state;

    return [
      {
        id: 1,
        title: 'Dados gerais',
        link: `/encomendas/${match.params.id}/dados`,
        path: '/encomendas/:id/dados',
        active: pathname === `/encomendas/${match.params.id}/dados`,
        done: false,
        disabled: false,
        component: () => (
          <Dados
            permissions={this.props.permissions}
            parentCallback={this.handleCallback}
          />
        )
      },
      {
        id: 2,
        title: 'Matrizes de referência',
        link: `/encomendas/${match.params.id}/matrizes-referencia`,
        path: '/encomendas/:id/matrizes-referencia',
        active:
          pathname === `/encomendas/${match.params.id}/matrizes-referencia`,
        done: false,
        disabled: match.params.id === 'new' || data,
        component: Pedido
      },
      {
        id: 3,
        title: 'Elaboradores',
        link: `/encomendas/${match.params.id}/elaboradores`,
        path: '/encomendas/:id/elaboradores',
        active: pathname === `/encomendas/${match.params.id}/elaboradores`,
        done: false,
        disabled: match.params.id === 'new' || data,
        component: Elaboradores
      },
      {
        id: 4,
        title: 'Etapas de revisão',
        link: `/encomendas/${match.params.id}/revisao`,
        path: '/encomendas/:id/revisao',
        active: pathname === `/encomendas/${match.params.id}/revisao`,
        done: false,
        disabled: match.params.id === 'new',
        component: () => <Revisao novoFluxo={data} />
      },
      {
        id: 5,
        title: 'Revisores',
        link: `/encomendas/${match.params.id}/revisores`,
        path: '/encomendas/:id/revisores',
        active: pathname === `/encomendas/${match.params.id}/revisores`,
        done: false,
        disabled: match.params.id === 'new',
        component: () => <Revisores novoFluxo={data} />
      },
      {
        id: 6,
        title: 'Convocar elaboradores',
        link: `/encomendas/${match.params.id}/convocar-elaboradores`,
        path: '/encomendas/:id/convocar-elaboradores',
        active:
          pathname === `/encomendas/${match.params.id}/convocar-elaboradores`,
        done: false,
        disabled: match.params.id === 'new',
        component: () => (
          <Confirmar
            tipoConvite={1}
            formContainerProps={{ next: 'convocar-revisores' }}
          />
        )
      },
      {
        id: 7,
        title: 'Convocar revisores',
        link: `/encomendas/${match.params.id}/convocar-revisores`,
        path: '/encomendas/:id/convocar-revisores',
        active:
          pathname === `/encomendas/${match.params.id}/convocar-revisores`,
        done: false,
        disabled: match.params.id === 'new',
        component: () => (
          <Confirmar
            tipoConvite={2}
            formContainerProps={{ last: '/encomendas' }}
          />
        )
      }
    ];
  }

  render() {
    const { match } = this.props;
    const pathList = [
      { label: 'Encomendas', icon: 'list', link: '/encomendas' }
    ];
    const currentPath =
      match.params.id === 'new'
        ? 'Nova Encomenda'
        : `Encomenda ${match.params.id}`;

    return (
      <div>
        <NavBreadCrumb pathList={pathList} currentPath={currentPath} />
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <ProgressTracker steps={this.steps} />
            </div>
            <div className="col-sm-9">
              <Switch>
                {this.steps.map((step, index) => (
                  <Route
                    key={step.id}
                    path={step.path}
                    render={() =>
                      step.disabled ? (
                        <Redirect to="/encomendas/new/dados" />
                      ) : (
                        <step.component step={index} />
                      )
                    }
                  />
                ))}
                <Route
                  path="/encomendas/:id"
                  render={() => (
                    <Redirect to={`/encomendas/${match.params.id}/dados`} />
                  )}
                />
                <Redirect to="/encomendas" />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Encomenda);
