import React, { useState } from 'react';
import { Form } from 'informed';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { get } from 'lodash';
import axios from 'axios';
import { API_HOST } from '../../consts';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

const ImportarVetoresModal = ({ toggle, isOpen, avaliacao }) => {
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    toggle();
  };

  const importVector = (data) => {
    axios
      .post(
        `${API_HOST}/avaliacoes/avaliacao/${avaliacao.id}/import_vectors`,
        data,
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      )
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          toast.success('Importação dos vetores concluída com sucesso!');
          closeModal();
        }
      })
      .catch((err) => {
        toast.error(
          'Ocorreu um erro no processo de importação dos vetores, tente novamente.'
        );
        setLoading(false);
        console.error(err);
      });
  };

  const handleSubmit = () => {
    setLoading(true);
    if (file !== undefined) {
      if (file.type === 'text/csv') {
        const formData = new FormData();
        formData.append('file', file);
        importVector(formData);
      } else {
        setLoading(false);
        toast.error(
          'Não é permitido o uso de outros formatos de arquivos, utilize somente a extensão .csv'
        );
      }
    } else {
      setLoading(false);
      toast.error(
        'É necessário o anexo de um arquivo .csv, para realizar a importação.'
      );
    }
  };

  const inputForm = () => {
    return (
      <Form>
        <input
          type="file"
          accept=".csv"
          onChange={(event) => setFile(get(event, 'target.files[0]', {}))}
        />
      </Form>
    );
  };

  const getInfos = () => {
    return (
      <div>
        <p>Total de questões neste instrumento: {avaliacao.itens}</p>
      </div>
    );
  };

  return (
    <Form>
      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>
          Importar Vetores - {avaliacao.nome}
        </ModalHeader>
        <ModalBody>
          <div className="card-header justify-content-start">
            <div className="ml-2">
              <h2 style={{ fontSize: '18px' }}>
                Recebe um arquivo .csv com os vetores deste instrumento. Esse
                arquivo deve ser gerado antes de realizar a importação.
              </h2>
              <hr />
            </div>
            <div
              style={{ display: 'grid', gridTemplateColumns: '2fr 2fr 2fr' }}
            >
              {getInfos()}
            </div>
            <div
              style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}
            >
              {inputForm()}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-end mr-4">
            <button type="button" className="btn btn-white" onClick={toggle}>
              Fechar
            </button>
            <button
              type="submit"
              className="btn primary-button ml-1"
              onClick={() => handleSubmit()}
            >
              {loading ? 'Importando...' : 'Importar'}
            </button>
          </div>
        </ModalBody>
      </Modal>
    </Form>
  );
};
export default ImportarVetoresModal;

ImportarVetoresModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  avaliacao: PropTypes.array
};
