import React from 'react';
import { get } from 'lodash';
import classnames from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import EtapaInicialCalibracao from './EtapaInicialCalibracao';
import EtapaFinalCalibracao from './EtapaFinalCalibracao';

class EtapasCalibracao extends React.Component {

  get etapa() {
    return get(this.props, 'match.params.etapa');
  }

  get analise() {
    return get(this.props, 'match.params.id');
  }

  componentDidMount() {
    const { history } = this.props;
    if (!this.etapa) {
      history.push(`/analise/tri/${this.analise}/etapas-calibracao/inicial`);
    }
  }

  render() {
    const { parametrosCorrigidos, parametrosIniciais, curvas } = this.props;

    return (
      <React.Fragment>
        <div className="container">
        <div className="row">
          <div className="col-12">
            <ul style={{borderBottom: 'none'}} className="nav nav-tabs pt-3 mt-2 mb-4">
              <li className="nav-item">
                <Link
                  className={classnames('nav-link-v2', { 'active-v2': this.etapa === 'inicial' })}
                  to={`/analise/tri/${this.analise}/etapas-calibracao/inicial`}
                >
                  Etapa inicial
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={classnames('nav-link-v2', { 'active-v2': this.etapa === 'final' })}
                  to={`/analise/tri/${this.analise}/etapas-calibracao/final`}
                >
                  Etapa final
                </Link>
              </li>
            </ul>
            {this.etapa === 'inicial' && <EtapaInicialCalibracao data={parametrosIniciais} />}
            {this.etapa === 'final' && <EtapaFinalCalibracao parametrosCorrigidos={parametrosCorrigidos} curvas={curvas} />}
          </div>
        </div>
        </div>

      </React.Fragment>
    );
  }
}

export default withRouter(EtapasCalibracao);
