import React from 'react';
import PropTypes from 'prop-types';
import EquationShortcut from './EquationShortcut';

const shortcuts = {
  general: [
    'x^a',
    '\\frac{a}{b}',
    '\\left (  \\right )',
    'a_{x}',
    '\\sqrt{x}',
    '\\sqrt[a]{x}',
    '\\div',
    '\\cdot'
  ],
  bracket: ['\\lbrace', '\\lbrace_{a}', '\\lbrace_{a}^{b}'],
  algebra: [
    '\\int',
    '\\int_{a}^{b}',
    '\\oint_{a}^{b}',
    '\\iint_{a}^{b}',
    '\\lim_{a \\rightarrow \\infty}',
    '\\sum',
    '\\prod',
    '\\bigcap',
    '\\frac{\\partial }{\\partial x}',
    '\\frac{\\partial^2 }{\\partial x^2}',
    '\\frac{\\mathrm{d} }{\\mathrm{d} x}'
  ],
  lowerCaseGreekAlphabet: [
    '\\alpha',
    '\\beta',
    '\\gamma',
    '\\delta',
    '\\epsilon',
    '\\zeta',
    '\\eta',
    '\\theta',
    '\\iota',
    '\\kappa',
    '\\lambda',
    '\\mu',
    '\\nu',
    '\\xi',
    '\\pi',
    '\\rho',
    '\\sigma',
    '\\tau',
    '\\upsilon',
    '\\phi',
    '\\chi',
    '\\psi',
    '\\omega'
  ],
  upperCaseGreekAlphabet: [
    '\\Gamma',
    '\\Delta',
    '\\Theta',
    '\\Lambda',
    '\\Sigma',
    '\\Omega'
  ],
  signals: ['\\gt', '\\lt', '\\geq', '\\leq', '\\equiv', '\\approx', '\\cong'],
  sets: [
    '\\cup',
    '\\cap',
    '\\sqsubset',
    '\\sqsupset',
    '\\sqsubseteq',
    '\\sqsupseteq',
    '\\subset',
    '\\not\\subset',
    '\\supset',
    '\\subseteq',
    '\\supseteq',
    '\\in',
    '\\ni',
    '\\notin',
    '\\neq',
    '\\exists',
    '\\forall',
    '\\vee',
    '\\wedge',
    '\\mid'
  ],
  arrows: [
    '\\overset{a}{\\rightarrow}',
    '\\overset{a}{\\leftarrow}',
    '\\underset{a}{\\rightarrow}',
    '\\underset{a}{\\leftarrow}',
    '\\rightarrow ',
    '\\leftarrow',
    '\\leftrightarrow ',
    'n \\to '
  ]
};

const EquationShortcuts = ({ onClick }) => (
  <>
    {Object.entries(shortcuts).map((shortcut) => (
      <div key={shortcut[0]} className="btn-group-equation-shortcut mb-2">
        {shortcut[1].map((d) => (
          <EquationShortcut key={d} formula={d} onClick={onClick} />
        ))}
      </div>
    ))}
  </>
);

EquationShortcuts.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default EquationShortcuts;
