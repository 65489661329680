import React from 'react';
import PopoverMap from './PopoverMap';
import './BrazilMap.css';

export default class BrazilMap extends React.Component {

  state = {
    mgPopoverIsOpen: false,
    spPopoverIsOpen: false,
  };

  togglemgPopover() {
    this.setState({ mgPopoverIsOpen: !this.state.mgPopoverIsOpen });
  }
  togglespPopover() {
    this.setState({ spPopoverIsOpen: !this.state.spPopoverIsOpen });
  }

  render () {
    return (
      <div>
        <center>
          <svg version="1.1" id="Camada_1" >
            <g className="brazil-map">
              <path id="BR-AC" class="st0" d="M30.5,237.8l-2.1,0l0.5-1.8l-0.2-0.8l-0.2-0.5l-1.2-0.7l-0.1-0.4l0.3-1.1l-0.8-1.9l-1.1-0.6
                l-5.6-1.2l-7.3-0.2l0.3-0.6l2.5-2.3l0.9-1.2l0.3-1.2l-0.2-1.2l-0.7-0.9l-0.8-0.4l-1.7-2.9l-0.8-0.4l-0.9-0.2l-1.1-1.1l-0.9-2.4
                l-2-1.5l-1-3.6l-0.9-1.6l-1.9-1.1l-0.1-1.2l0.8,0.1l0.4-0.5l0.1-0.7l-0.2-0.4l-2.1-0.9l-0.4-0.7L0,199.3l0-0.4l0.6-0.1l0.5-1.3
                l0-1.3l3-0.4l0.5-0.4l-0.2-1.4l-1.2-1.6l0,0l17.9,7.5l23.7,5.7l12.1,3.1L83.5,223l28.5,12.1l0,0l3,1.3l0,0l-0.3,0.5l-1.6,0.7
                l-2.1,1.6l-2.9,2.8l-1.7,0.9l-0.7-0.2l-1.5,0.2l0,0.6l-1.9,1.3l-2.1,1l-1.4,1.8l-0.4,1.2l-0.6,0.3l-1.2-0.8l-0.8-0.1l-2.4,0.1
                l-0.7,0.3l-0.6,0.4l-0.4,0.9l-2.4,3.5l-3.8,1.2l-0.8,0.7l-1.9,0.5l-1,0l0.1-2.1l-1.4,0.2l-3.6-0.8l-5.2-0.5l-5.1,0.4l-0.7-0.6
                l-2.3-0.2l-3.6,1.8l-2.7,0.6l-1.7-0.7l-1.4-1.4l-1.6,1.2l0-17.6l0.1-1.1l0.5-0.7l0.1-2.4L53,231l1-1.2l0.5-1.1l-1.2,0.2l-3,2.6
                l-1.6,1l-2.1,2.4l-2,0.7l-0.6,1.1l-1.8,1L30.5,237.8z"/>
              <path id="BR-AL" class="st0" d="M561.9,230.3l-0.2-0.7l-1.4-0.8l-0.4,0.1l-1-0.3l-0.5-1.4l0,0l1.3-1.1l0.6-1.1l2.2-0.8l2.4-3.3
                l0.1-0.7l0.8-0.6l1,1.2l1.7,0.3l1.1-0.1l2.5,2l1.9,2.1l1.8,1.1l1.9,0.5l2.5-0.6l3.1,0.3l1.2-0.1l1.9-1.1l1.2,0.1l1.8-1.1l2.2-2.3
                l0.1-0.5l1.5-1.2l2-0.2l2.2,0.9l2.6-1.3l1.5-0.2l1.9,0.8l2.9,0.3l0.3,0.2l0,0l-2.8,5l-4.5,5.1l-3.6,3.6l-3,4.6l-3.3,2.9l-1,2
                l-1.3,1.8l0,0l-0.6-1.3l-3.6-2.2l-0.5,0l-1.7-0.8l-1.8-1.4l-1.3-2.4l-5.4-3.1l-3.7-0.9L561.9,230.3z"/>
              <path id="BR-AM" class="st0" d="M165.8,47.3l0.7,0.8l0.8,0.3l0.6,0L169,48l0.6,0.1l0.1,1l1.2,1.2l1,0.4l0.9-0.2l1.4,0.1l1.9,1
                l0.5,0.7l-0.9,3.3l-0.7,1.5l2.7,3.7l1.7,4.8l0.2,2.3l0.9,1.1l0.1,0.4l-1.6,1.5l0.4,1.6l0.7,1.6l-0.8,3l-0.6,1l0.3,5l0.9,2.2
                l1.2,0.8l0.6,0.9l1.1,4.3l-0.1,0.6l-1.1,1.3l-1.8-0.3l-0.1,1.1l5,4.4l0.5,0l2,1.3l1.5,1.7l1,2l0.5,0.1l1.3-0.4l2.4,1l-0.4-2.1
                l0.8-2.3l0.2-1.7l-0.4-1.8l0.8-3l1-1.3l1.3-0.7l2.1-0.7l0.6-0.9l1.6-0.1l2.8,0.9l1.9,2l0.9,2.5l1.8,0.2l1.2-0.5l1.4-1.4l2-0.5
                l0.4-0.4l0-0.5l-1.1-1.4l-0.2-0.7l0.1-0.7l1.2-2.6l0.1-1.1l1.7-3l2.3-3.3l0-0.7l18.5,0.1l0,0l0.4,4.9l-0.4,1.4l0.2,2.3l0.2,0.7
                l1.7,0.9l0.2,0.4l-0.3,0.7l-0.1,2.1l1.8,2l0.4,0.2l0.7-0.2l1.8,1.2l0.6,1.8l0,0.9l0.3,0.4l4.6,3.8l4.6,2.5l0.6,0.9l1.3,1.1l1.9,0.7
                l2.4,1.3l2.3,0.6l1.4,0.2l1.3,0.8l0.1,0.6l1.3,1.3l2.7,1.4l1.1,2.2l1.9,0.8l0.9-0.6l1.4-0.3l0.2,0.5l-0.1,0.8l1.8,1.7l-29.9,64.2
                l-0.8,1.3l-1.4,1.1l-0.6,1l0,1.1l0.8,2.3l0.4,0.6l1.1,0.6l1.6,1.6l0.6,1.3l0.1,2l0.6,0.6l0,0l-1.2,1.2l-0.2,0.6l-0.1,0.8l0.3,0.8
                l-0.3,1l-1.2,1.8l-0.9,0.6l-0.4,0.9l0.1,0.8l0.7,1.4l0.5,1.9l-1.4,4.2l-1.2,5.2l-1.2,0.7l-47.9,0.3l0,0l0-0.7l-0.4-0.4l-1.4-0.3
                l-1.9,1.1l-0.5,1.7l-0.7,0.2l-3.2-1.5l-0.7-2.9l-0.6-0.2l-1,0.1l-0.5-0.2l-1.2-3.3l-2.5-1l-1.3-1.3l-0.8-1.9l-0.4-0.5l-2.1-1.1
                l-10.8,0.1l-1.4,2.6l-1.7,0.5l0.1,1.2l-0.3,0.2l-2.5,0.5l-1.2,1.6l-0.1,0.7l0.9,0.7l0.2,0.4l-0.4,1l-0.8,1.1l-2.1,1.2l-0.1,1.8
                l0.2,1.5l-3.9-0.5l-2.1,0.4l-1.2,1.1l-1.8-0.1l-0.8-0.5l-0.5,0l-1.8,1.3l-0.5,0.8l0.3,1.8l-2.5,3.2l-0.7,0.1l-0.7-0.5l-0.6-2.3
                l-0.4-0.1l-1.8,1l-1.3,1.2l-1.4,0.4l-0.8-0.1l-1.1,0.5l-0.4,0.5l-0.1,0.7l-1.2,0.8l-0.4,0l-2.3-2.3l-0.7-0.4l-2.9,0.3l-4-0.3
                l0.2,1.3l-0.2,0.6l-1.5,1.8l-1.9,0.6l-3.3,2.6l0,0L83.5,223L57,208.9l-12.1-3.1l-23.7-5.7l-17.9-7.5l0,0l-0.1-0.7l0.7-2.9l1.2-1.4
                l4.9-3.5l2.3-0.2l0.5-0.3l1-1.6l0.1-1l-1.7-4.5l0.4-1.2l1.2-2.2l1.3-1.3l0.9-1.2l0.4-1l0.3-1.7l-0.3-1.3l0.7-2l0.3-2.3l0.7-0.8
                l3.7-1.6l2.2-1.2l1.1-1l0.4-1.4l0.8-0.4l1.4-0.1l1.6-0.9l3.5-2.7l2.4-0.4l1.5,0.3l4.2-1.2l1.5-0.8l1.8-0.4l3.3,0.4l1.3-1.6l0.6-1.4
                l1.1-0.6l2.1,0.2l0.6,0.5l1.3-0.2l1.1-0.7l1.7,0.1l0.3,0.5l0,1.2l1.6,1.3l2.8,0.1l0.6-0.4l0.5-0.8l-0.1-0.4l1.2-4.9l6.7-37.2
                l1.2-2.9l-1-5l0.1-0.3l-1.3-1l-1.5-2.8l0-0.5l0.7-1.4l-0.5-1.8l-0.4-0.4l-1.7-0.6l-3-2.3l-1.9-2.3l0.2-11.5l3.9-0.3l1.8-1.2
                l3.5-0.9l2.7,1.8l1.2,0.1l1.3-0.4l-0.5-1.7l0.3-1.7l-1.4-2.1L74,65.8l-1.2-0.6l-1.5,0.5l-2.8-0.6L65,65.2l-0.1-9.9l1,0l1.3-0.6
                l2.3-0.6l2.8,0.9l19,0.1l-0.5-0.7l-0.8-0.1l-0.4-1.2l1-1.9l1.4,0.4l0.6,1.5l0.9,1.3l0.7,0.4l0.8,0l1.6-0.6l1.9-2.1l0.3-0.8l1.3-1.2
                l2.6-1.4l1.3,0.5l1.2,2.6l1.6,2l0.7,1.3l0.7,1.8l0.2,1.5l-0.5,3.6l0.2,1.8l3.2-0.8l8.3,7.1l0.8,0.3l2.5,0.2l2.3-1l1.7-1.7l2.1-1.2
                l2.2-0.1l0.6,0.3l0.8,1.1l0,1l-1.1,1.7l0.4,1l0.6,0.3l1.3-0.7l0.6-0.9l0.2-1.3l1-1.2l0.5-0.2l1,0.2l0.5-0.2l0.4-0.7l0.5-2.3
                l0.5-0.4l1.5-0.4l2.9-1.8l1,0.5l0.9-0.3l1.6-1l1-1.6l2.1-1.2l2,0.6l2.4-1.7l0.5-0.8l0.4-2.7l0-1.3l0.8-0.9l1-0.4l1.7,0l1.3-0.4
                l2.1-1.6l1-0.4l2.3-0.3L165.8,47.3z"/>
              <path id="BR-AP" class="st0" d="M345,99.7l-0.9-0.1l-0.8,0.3l-1.3,0.1l-2.8-0.7l-1.5-1l-0.4-1.5l-1.9-1.4l-0.1-1.3l0.2-0.7
                l-0.3-1.6l-0.3-0.5l-1-0.2l-0.4-0.9l0.1-2.4l-2.9-3.3l-0.8,0.4l-0.8-0.2l-1.5-3.5l0.1-1.6l-0.6-1.8l-0.6-0.6l-0.7-1.5l-0.2-2.1
                l0.4-3.1l-0.2-0.3l-2.6-0.7l-2-2.3l-0.8-2.8l0.2-0.7l0.7-0.2l-0.5-1.2l-0.7,0l-2.8-2.7l-0.9,0.1l-0.4,0.4l-1.3,0.1l-0.8-0.3l-0.8-1
                l-1.1-0.8l-1.2-0.1l-0.5-1.3l-0.7-0.8l-1.9-1l-1.3-0.5l-3.4-0.4l-1.1,0.3l-1.2,0l-0.3-0.5l-0.2-1.4l0.2-4.5l0.6-1.3l-0.4-2.3
                l-0.6-0.6l0,0l0.4-0.2l1,0.3l0,0.8l-0.3,0.2l0.3,1l1.5-0.1l0.8,0.2l0.4,0.9l0.8,0.7l2.2,0.8l3.3,0.8l2.8-1.8l0.4-0.8l1.7-1.2
                l0.8-0.3l0.7,0.9l2.7,0.9l1.1-0.1l1.7-1l0.1-0.4l0.3,0l1.5,1.3l-0.2,0.9l2.4-0.2l0.5,0.5l1,0.2l0.6,0l0.9-0.5l1.7-1.5l1.8-1.1
                l1.8-2.3l0.4-1l-0.2-0.5l3.1-6.5l0.1-1.8l1.1-1.3l0.7-0.2l3.6-5.8l0.2-1.1l0.8-1.2l1.8-1.5l0.6-1.7l2.1-1.1l0.5-0.9l1-0.6l0.6,1.5
                l0.9,1.4l-1.7-4.8l-0.1-1.3l0.2-0.3l3.4,2.4l1.1,1.2l0.9,1.2l0.5,1.2l0,1.6l0.4,0.3l0.3-0.5l0.4-0.1l0.2,0.4l0.2,7.9l0.7,3.9l2.7,6
                l2.7,8.9l3.3,5l1,0.3l3.3-0.1l2.8,0.9l1.3,0.8l0.8,2.7l-0.2,3.3l-1.3,1l-1.7,0.4l-0.4,0.3l1.8-0.2l1-0.4l0.3,0.2l0.1,0.6l-0.2,0.7
                l-5,4.1l-1.2,2.1l-2.1,1.4l-1.9,3.7l-2.9,3.5l-0.7,0.5l-0.9-0.1l-1.5,0.5l-2.1,3l0,0l-1.3,0.1l-0.8,0.9l0,0l-1.5,1.7l-1,2.2
                l-1.1,1.9l-0.8,0.7l-3,3.4l-0.4,2.4l0.2,1.8l-2,1.8L345,99.7z M368.3,52.7l-0.5-0.3l-1.1-1.9l0.1-0.9l0.2-0.4l1.7-0.5l0.4,0.4
                l0.8,2.3l-1,1.1L368.3,52.7z M370.8,70.3l-0.4-0.2l-0.1-0.7l1.1-1.1l0.9-0.2l1.2,0.2l0.5-0.1l0.4-0.6l0,0.6l-1,1.4l-1,0.5
                L370.8,70.3z M374.3,67.4l-0.8-0.2v-0.6l0.7-1l1.2-0.1l0.2,0.3l-0.1,0.5L374.3,67.4z M367.1,49l-0.8-0.5l0.3-0.8l0.9-0.2l0.9,0.3
                l0,0.7L367.1,49z"/>
              <path id="BR-BA" class="st0" d="M527.5,365.3l-0.4-0.8l0.7-1.4l0-0.5l-0.5-1l-4.1-3L523,358l0.2-1.5l-0.6-1.2l-0.7,0.2l-0.2,0.5
                l-0.3,0.2l-0.2-0.2l0.5-4.4l0.8-3.3l0.8-0.9l2.5,0.2l1-0.7v-0.4l-0.6-1l0.2-2.6l5-4.2l1.5-3.1l-0.4-1.2l-0.8-1l-0.9-0.1l-0.7-0.4
                l-2.8-2.5l-2.1,0l-2.2-0.6l-2.3-1.1l-2.7-0.6l-2-0.1l-1.3,1l-1.5,0.5l-2.6-0.5l-0.6,0.2l-0.4-4l-6.5-6.2l-0.4-0.2l-4.4,1.2
                l-1.5-1.2l-0.5,0.2l-0.9-0.2l-2.5-1.2l-2.2-1.6l-1,0.2l-4.4-3.6l-1.1-0.5l-2.7-0.8l-1.8,0.2l-1.9,0.9l-0.8,1.1l-0.6,0.2l-5.3-1.5
                l-0.6-0.5l-0.3-1.5l0.1-0.9l1.3-2.7l-0.9-0.5l-2.3-0.5l-2.2,0l-1.2-0.5l-1.8-0.1l-3.8,1.6l-4.3,3l-0.6,1.1l-3.4,1.9l-2,0.4l-1,1.2
                l-1.8,1.5l-1.4,0.5l-1-0.1l-1.9,2.6l-1,0.8l-2.1-0.4l-0.7,0.1l-0.7,1l-1.3,0.9l-0.4-0.2l1.4-3.4l-0.5-2.4l0,0l1.5-2l0.2-0.8
                l-0.2-1.3l-0.7-1.7l0.1-0.7l0.7-1.4l0-0.5l-3.2-2.6l-1.7-3.5l-0.6-3.5l0-1.5l1.2-3.8l1.6-1l0.2-0.4l0.1-0.9l-1.6-0.8l-0.1-0.3
                l0.5-2.5l1.4-1.1l0-0.4l-2.7-2.5l0,0l0-0.9l1.4-2.4l0-1.1l-0.2-0.4l-1.5-0.5L432,276l-0.4-1.3l0.1-2.9l0.2-0.9l2-1.7l1.1-0.4l1-0.7
                l-0.3-0.6l-0.6-0.5l-0.9-0.3l-1,0.2l-0.4-0.3l-0.1-0.5l0.4-1l2-0.8l0.6-0.5l0-0.8l-1.9-1l-3.6-0.7l-1.9-2l-0.2-0.9l0.5-1.2l1-0.7
                l1.5-3.5l0.9-0.6l1-0.3l0.4-0.4l-1.3-1.8l0.2-0.4l3.2-2.6l3.8-2l0.6-0.5l0.2-1l0.7-0.6l0,0l2.3,0l1.4,1.3l0.7,1l0.5,2.2l1.8,2.5
                l4.2,1.9l1.8-0.5l2.1,0.2l0.3-0.2l0.8-1.4l1.1-0.4l0.7-0.9l1.2-0.9l2.2-0.8l1.3,0.2l1.5,0.5l1.4-0.4l3-2.4l3-5.1l0.7-0.9l0.2-0.9
                l0.3-2.6l-0.1-1.2l-2.2-4.4l0-0.9l3.2-1.6l2,0l1.5,0.5l0.4,0.9l0.3,0.2l2.1-0.4l1.4-0.5l1.3,0.4l1.7,1l-0.1,0.6l0.2,0.3l0.8,0.3
                l3-0.1l1.5-0.6l1.7-0.1l0.8-1.2l1.5-1.3l2.7-0.3l0.8-0.4l0.8-1l2.2,0l0.6,0.6l0.3,0.1l1.9-1.8l-0.1-2.4l2-0.4l0.8,0.2l1-0.7
                l1.5-2.3l0.5-1.1l0,0l0.9,0.4l1.2-0.3l2.2,0.3l2.8,1.4l0.4,0.5l-0.1,2.7l0.7,1.9l2.2,0.9l0.3,2l-0.9,1.7l1.8,0.6l1-0.2l0.8-0.9
                l2.9-0.9l0.8-3.6l0.5-0.9l0.8-0.2l0.8,0.5l0.6,0.1l2.4-1l1.1-1.2l0.2-0.6l-0.2-1.4l0.3-0.2l2.2-0.4l0.8-2.1l1.7-0.4l2.5-1.5
                l0.6-0.1l1.5,0.6l0.8,1.4l1.4,0.8l2.4,0.8l3,0.4l1.6,1.1l0.5,1.6l0.4,0.3l0.4-0.1l0.3-1.8l0.4-0.4l1,0l0.5,0.3l0,0.6l-0.6,0.9
                l0.5,0.8l0.5,0.4l2.1,0.9l0,0.9l1.2,3.1l0,0l0.5,1.4l1,0.3l0.5-0.1l1.4,0.8l0.2,0.7l0,0l-0.7,1.2l0.2,2.1l1.3,2.5l2,1.9l0.8,1.1
                l-0.1,3.1l-0.7,1.6l-0.1,0.9l0.8,2.8l-0.3,0.6l-0.7,0.6l-2.5,1l-1.1-0.8l-1.7,0l-0.3,0.3l-0.6,1.6l0,0.8l1,1.6l1.1,0.7l0.5,1.5
                l1.4,1.5l0.2,0.4l-0.1,1.4l-0.3,0.4l0.1,0.4l0.6,0.5l1.9,0.9l0.5,0.7l0.5,0.3l1.5,0.6l2.9-0.9l0,0l0.6,0l0.4,0.4l-4.1,8.8l-3.8,5.2
                l-1,2.3l-5,5.7l-2.5,1.3l-0.9,0l0.3-1.4l0.4-0.1l0.1-1.1l-0.5-1.9l-1.9-0.2l-0.4-1.3l-0.7-0.8l-0.4,0.6l-0.5,2.3l-0.5,0.9l-0.5,0.4
                l-0.6-0.3l-0.5-1.7l0.1-0.3l0.3-0.1l0-0.4l-0.9,1.3l0.9,1.7l0.5,0.2l0.4-0.1l1.2,0.3l-0.5,1.8l-1.2,1.1l-0.2,1.8l-1.2,1l-0.3,0.7
                l-0.2,0.8l0.2,0.9l-1.3-0.4l-0.2,0.4l-0.4,2.5l0.4-0.7l0.8,0.2l0.2,0.5l-0.2,0.4l0.1,0.6l0.3,0.3l0.3,1l-1,2.5l0.6,3.4l-0.5,0.5
                l-0.6-0.2l-0.1,1.1l0.6,0.5l1.2-1.7l-0.5-2.2l0.8-0.6l0.2,0.5l-0.1,1.8l-1,2.6l-0.7,5.4l-0.5,1.8l0.2,2.5l0.8,2.9l0.2,4.5l0.9,6.4
                l1,3.4l-2.2,6.7l-1.3,6.6l-0.5,1l-0.2,2.9l-1.1,3.7l-0.1,2.3l0.4,4.4l0.9,1.7l-2.2,3l-1.8,0.7l-0.9,0.6l-2.7,3.9l-0.6,2l0,0
                l-8.1-5.1L527.5,365.3z M549.8,287.3l-0.2-1.2l0.7-0.4l1.1-1l0.2-0.6l-0.4-0.7l0.8,0.1l0.8,1l0,0.5L549.8,287.3z M547.2,294.2
                l-0.6,0l0-0.7l0.3-0.4l-0.8-0.2l-0.4-0.4l0.1-1.1l1.9-0.1l0.2,0.2l0.1,1.1L547.2,294.2z"/>
              <path id="BR-CE" class="st0" d="M573.8,156.9l-4.9,1.6l-2.6,2l-3.3,6.7l-0.8,1.1l-0.9,0.6l-0.7,1.7l0.2,0.7l-1.4,2.4l-1.5,1.5
                l-0.6,1.1l-1,0.3l-0.8-0.5l-0.5,0.1l-0.8,0.9l-1.4,2.2l-0.3,2l0.6,0.2l0.7-0.4l0,0l-0.1,0.5l-0.8,1.2l-1,3l0.5,1.3l-0.7,1.3
                l-1.1,0.6l-0.2,0.4l-0.1,0.9l0.2,0.4l0.6,0.3l0.3,0.7l0,1.7l1.2,0.9l0.7,0.1l0.3,1.2l-1.6,3.5l-1.2,1.1l0.2,0.2l0,0l-1.6,0.5
                l-1.1,0.8l-1.5,2.3l-1.6,0l-0.6-1.6l-0.8-0.6l-1.4-0.5l-1-1.1l-0.5-1l-1.8-0.8l-2.9-1.9l-3.1-0.4l-1.3,0.1l-2.1,0.8l-1.6,0.2
                l-0.9-0.2l-4.9-0.2l0,0l0.1-1.3l-0.7-0.7l-0.1-1l1.1-3.1l1.5-2.2l0-0.7l-0.8-1.2l-2.6-0.5l-1.3-0.6l-1-2.7l-1-5.2l-0.8-2.6l0.2-0.4
                l-0.9-8.2L516,166l-0.9-0.7l0.8-0.6l0.6-1.6l-0.6-3l-1-2l0.5-1.6l-0.8-3.9l0.7-1.4l0-0.8L514,149l-0.4-1.7l-3.2-6.4l-1.8-4.7
                l-0.3-1.3l0.2-1.5l1.6-2l1.1-1.9l0.1-0.8l0,0l0.2-0.1l0-0.6l-0.4-0.7l0.6-0.8l6.7-0.2l2.9-0.4l2.1-0.7l7.4,0.8l2.1,1.1l1.9,1.5
                l4.1,2.2l1.5,0.5l4.8,3l1.2,0.2l2.2,2.3l3,2.1l2.6,0.7l2.4,2.9l1.2,0.6l3,3.8l4.2,3.1l1.5,2l1,1l1.5,0.8l1.2,0l2.4,0.9l0.8,0.7
                L573.8,156.9z"/>
              <path id="BR-DF" class="st0" d="M416.7,333.9l-14.7,0l-0.5-3.3l0.9-1.8l0.4-3.7l12,0l1.8,2l0.1,2.2l-0.6,1.2l-0.2,2.4L416.7,333.9z
                "/>
              <path id="BR-ES" class="st0" d="M501.6,411.4l-0.3-0.1l0.4-2.3l0.6-1.3l-0.6-3.1l0.2-0.4l1.7-2.1l5.1,0l0.5-0.3l1-3.2l1.1-1.3
                l0.9-2.3l0.2-1.7l0.3-0.4l1.2-0.9l1.7-2.1l0.5-3l-0.3-2.4l-1.3-2.8l-1.1-1.3l-0.5-0.1l-0.5,0.3l-0.8-0.3l-0.5-0.7l0.4-0.5l1.5-0.1
                l0.8,0.4l1,0l0.9-0.2l0.3-0.4l-0.2-1.7l-1.3-0.4l-0.2-0.4l0.3-1.5l0-1.9l-0.5-0.7l-1.3-0.7l-0.3-0.7l1.2-1.9l2.2-1.3l0.3,0l1,0.7
                l1.1,0.1l0-0.8l-1.7-2.3l1.3,0.1l2.2-0.3l0.8-0.6l1.1-0.3l1.6-0.1l2.5,0.8l0.9,0.5l0,0l0,0l0,0l0.3,0.5l8.1,5.1l0,0l-0.9,3.4
                l-0.3,2.9l0.1,3.6l0.7,6.2l-0.3,2.5l-1.5,3.2L531,394l-0.5,0.6l-1.7,3.3l-1,3.8l-0.8,1.5l-0.8-0.7l-0.9-0.1l-0.3,1.3l1.4,0.5
                l-1.9,4.2l-3.5,3.8l-0.2-0.3l-1.9,0.7l-0.8,1.2l-0.1,1.4l-0.4,1.2l-1.6,2l-0.3,1.1l0,0l-1.6-1.1l-3,0.4l-4.3-1l-2.5-1.1l-0.7-4.7
                L501.6,411.4z"/>
              <path id="BR-GO" class="st0" d="M416.7,333.9l-0.9-1l0.2-2.4l0.6-1.2l-0.1-2.2l-1.8-2l-12,0l-0.4,3.7l-0.9,1.8l0.5,3.3L416.7,333.9
                L416.7,333.9L416.7,333.9L416.7,333.9l-0.5,1.5l0.3,1.6l-0.7,1.6l-1,1.2l-0.4,1.5l0.9,1.2l0.9,0.3l1.4,1l1.6,4.6v0.6l-2.3,2.7
                l-3.2,3l-0.4,2.1l1.1,1.1l0.9-0.5l1.6,0.6l0.5,0.6l0.2,1l-0.2,0.7l-0.7,0.9l-0.5,1.9l1.2,3.5l-3.1,2.1l-1.2,0.2l-0.9,0.9l-0.5,1.1
                l-4.9,2.7l-0.7-0.8l-4.4-1.9l-0.5,0.5l-0.6,0.1l-1.8,0l-1.5-0.3l-3.9,0.2l-2-0.9l-4.1,2.6l-2.9,2.9H384l-1.3-1.4l-1.5-0.3l-3.2,1.6
                l-3.7-0.6l-2.5,1l-2.2,0.5l-1.4,2.1l-1.2,2.5l0.1,1l-0.8,1.3l-0.8,0.3l-0.8-0.2l-0.5,0.1l-0.9,0.7l-1.6,2.1l-0.6,1.9l0.6,0.6l0,0.3
                l-0.4,0.2l-0.9-0.5l0,0l-0.2-0.4l-2.7-2.2l-3.1-0.6l-1.6-1.5l-1.9-0.4l-1.5,0l-3.4-1.3l-0.7-0.9l-3.1-1.6l-0.7-0.6l-1.4-0.7
                l-1.1-0.2l-2.7-1.7l-2.8,0.2l-3.8-0.7l-0.1-0.3l0.3-0.8l0.6-1.2l1.3-1.8l-0.1-0.4l-2.2-0.9l-1.1,0.7l-0.7-0.2l-0.3-0.4l-0.2-1.1
                l0.3-2.6L327,366l0,0l-0.9-2.2l-0.2-3.4l-1.4-2.2l-0.2-0.7l0.4-3.4l2.5-3.8l0.4-2.9l0.7-0.8l2-0.8l1.9-1.8l1.2-2l0.3-1.9l0.5-1
                l1.3-1l0.8-1.4l0.1-1.5l1.8-1l1.4-2.6l2.6-0.1l2.7-0.9l0.3-0.3l2-4.2l0.7-1l0.6-4.1l0.4-0.7l1.9-1.9l1.7-1l1.1-0.3l1.2,0.7l1.2-0.7
                l1.4-1.4l0.8-2.9l1.2-2.9l0-0.8l-0.5-1.1l0.6-2.7l1.6-3.1l-0.1-5.2l1.2-0.8l1-2.6l2-3.2l0.4-1.1l-0.2-2.7l0.4-0.9l1-0.9l0.3-1.1
                l-0.1-0.6l0,0l0.3-0.4l0.2-1.9l0.7-1.2l1.8-2.1l0.6-0.3l0,1.7l-1.2,1.9l-0.5,2.3l2.4,0.4l2.2,1l3.9,0.4l3.1,1.7l4.1,1.4l0.6-0.2
                l1.6-5.9l1.5-2.4l1.2-1l1.1,1.3l1,1.9l1.1,3.7l0.6,3.1l1.1,0l0.8-0.8l0.3-1l0.6-0.5l2.8,0.3l4.5-0.5l0.2,0.7l-0.6,1.6l3.1,1.5
                l3.4,0.7l0.7-0.1l0.5-3.3l0.5,0l1.5,2.6l0.5,0.2l1.8-0.9l2.5-2.1l4.5-1h1.5l2.6-1.1l1.7-1.3l5.4-1.4l0,0l2.7,2.5l0,0.4l-1.4,1.1
                l-0.5,2.5l0.1,0.3l1.6,0.8l-0.1,0.9l-0.2,0.4l-1.6,1l-1.2,3.8l0,1.6l0.6,3.5l1.7,3.5l3.2,2.6l0,0.5l-0.7,1.4l-0.1,0.7l0.7,1.7
                l0.2,1.3l-0.2,0.8l-1.5,2l0,0l-1,1l-3.2-0.3l-0.9-1.8l-2.4-1.5L428,314l0.6,3.8l-0.6,0.5l-0.4,0.1l-2.8-1.1l-1.2,0.2l-0.4,0.3
                l-0.7,3.2l0.7,0.1l0.5,1.2l-0.6,0.9l-0.7,0.7l0,1.9l0.4,0.6l0.5,0.2l0.7,4.3l-0.5,0.5l-3.4,0.9l-2.3,1.5L416.7,333.9z"/>
              <path id="BR-MA"  class="st0" d="M458.6,128.9l0,0.3l-2,2.1l-0.4,0.2l0,1.4l2-1.7l0.7-1.2l2.1-1.6l0.9-1.1l1-6.3l1.1-0.7l1.1-0.1
                l1.7-0.9l0.6-0.1l0.5,0.1l0.1,1.6l-0.2,0.4l-1.4,1.9L463,125l0,0.7l0.7,0.1l0.5-0.8l1.1-0.3l0.2,0.8l-0.6,0.5l0.2,0.3l4.2-5
                l0.9,0.3l2.6-0.9l4,0.4l-0.5-2.4l0.6-0.2l2.4,0.1l1.8,0.4l2.6,1.1l1,0.1l2.1,1.4l1.4,0l1.3,1.3l1.9,1.3l2.5,0.6l1.2-0.2l0.6,0.8
                l0.3,0.1l1.6-0.1l2.5,0.7l0.3-0.9l-0.7-0.5l1.7-0.4l0.6,0.1l-0.1,0.4l0,0l-0.5,1.4l0.2,0.7l0.6,0.5L502,129l-1.8,2.3l-4.1,4
                l-3.3,0.5l-0.8-0.2l-2.9,3.4l0,1.8l-0.9,1.8l-1.9,1.9l-0.8,2l-0.6,0.2l-0.8,1.1l0.4,1.9l1.2,0.8l0.4,1.3l-0.3,1.5l-1.1,2.9l1.6,2.8
                l0.5,3l-0.1,2.4l-0.2,0.6l-4.1,4.4l-0.2,2.5l0.1,2.1l0.5,2.3l1.8,2l1.6,0.9l0.2,0.5l0.1,0.8l-0.4,2.2l-0.3,0.4l-0.1,1.2l-0.5,1.5
                l-1.2,1.3l-2.3,0.3L481,187l-0.8,0l-2.4,0.9l-1.5,0.1l-2.5-1.8l-1-0.2l-1.5,0.2l-1,0.6l-1.1,0.3l-0.2-0.3l-0.8,0.2l-0.5,0.2
                l-1.9,1.9l0,0.5l-0.6,0.9l-2,2l-2.3,0.9l-2.9,2.6l-1.3,0.5l-1,0l-1.7,1.5l-1.1,0.4l-2.6,0.3l-2.7,0.8l-2.1,1.9l-1,3l-0.4,3
                l-0.6,2.3l-1.5,2.7l-0.3,0.2l-0.3,0.8l-0.1,1.5l-1.2,2.5l-1.7,1.4l-0.7,2l0.7,1.9l0.5,3.2l1.7,3.1l0,0.5l-0.8,1.4l-0.1,6.4
                l-1.1,2.4l-0.3,1.1l0,1.9l0,0l-1.3-0.7l-0.6-0.8l-0.8-0.4l-1-0.3l-1.8,0.2l-0.7-0.2l-1.4-2l-1-2.9l-2.1-1.6l-0.1-1.6l1.6-1.8
                l-0.1-0.5l-3.9-1.9l-0.6-1.1l-0.1-1.7l-0.8-1.6l-0.9-0.7l-2.1-0.3l-0.2-0.4l0.3-0.7l2.6-2.5l-0.4-1.3l0.7-2.7l0.7-1.4l0.6-0.6
                l1.3-0.5l3.4-0.4l-0.4-1.4l0.6-3.2l-0.1-1.8l-1.8-1.2l-4.3,0.9l-2.1,1.3l-2.3-3l-0.9-0.9l-3-4.1l-0.9,0.1l-0.8-1.4l0.6-1.2
                l-0.1-0.9l-0.2-0.3l-1.4-0.2l-0.4,0.3l-1.9-1.3l0.2-0.7l1.8-0.7l1.8-2l0.2-1.6l-0.3-1l0.3-2.4l1.8-6.2l0-0.4l-0.7-1.1l-0.2-1.1
                l0.2-3.7l-0.6-1.5l-0.5-3.5l-0.8-1l-3.4-1.4l-1.2,0l-0.3-0.3l-0.1-1l-0.6-0.7l-1.7-0.3l-1,0.5l-1.4-0.1l-2.8-1.5l-0.9-0.1l-2,0.5
                l-2.6,1.9l-0.5,0.6l0,0l-0.5-0.1l14.7-11.8l2.4,0.2l1.2-0.9l1.5-2.2l0.4-1.1l1.5-1.2l0.6-2.9l1.1-0.5l2.4-2.3l0.5-1.2l0.3-1.9
                l1.6-4.7l2-1.5l1.5-1.8l0.8-1.4l0.5-1.3l-0.2-1.8l0.8-0.8l-1-1.6l3.7-3.1l-0.1-1.6l0.5-2.4l1.7-1.6l0.4-0.7l0.9-3l0.1-1.6l-0.4-0.5
                l-1,0.2l-0.3-0.5l0-0.6l1.1-0.2l0.6-0.7l0.7-4.4l-0.2-1.4l0.5-1l0.8-0.4l0,0h0.6l0.2-0.2l-0.1-0.5l0.3-0.8l0.6-0.5l1.3,1l0.5,1.8
                l1.2,0.1l0.8-1.4l0.6,2.9l1,0l0.3-0.5l0.1-0.9l0.9,0l1.7,0.5l0.5,0.7l-1.4,1.7l0.7,0.7l0,0.6l1.2-2l0.5-1.5h0.5l0.4,1v0.6l-0.9,0.9
                l-0.3,1l0.1,2.7l0.3,0.3l0.5,0.1l0.6-0.1l1-0.9l-0.5-1l0.1-0.6l1.3-1.4l1-0.2l2.1,0.6l1.5-1.1l0,0.9l-0.4,0.2l-1.1,1.5l0.6,0.6
                l0.1-0.4l1.2-0.6l0.6,0.8l-0.1,1.2l1.6,1.8l0.9-0.4l1.6,0.7l0.8,2.4l-0.1,0.9l-2.2,2.7l-0.4,0.9l0.1,0.7l0.6-1l1.8-1.7l0.8,0
                l0.9,0.8l0.5,1.9l-0.3,1.2l-1,0.2l-1,0.9l-1.2,1.5l0.1,0.8l-1.2,2.3l-0.6,3.5l0.2,0.3L458.6,128.9z M459.1,129.4l-0.1-0.8l0.5-1.5
                l-0.4-1.1l0.1-0.7l0.9-1.2l0.5-0.2l0,2.3l-0.2,1.3l-0.8,0.6L459.1,129.4z M452.8,103.6l-0.4-0.5l-0.1-0.6l0.8-1.1l0.8,0l0.7,0.4
                l0.1,0.3l-0.2,0.4L452.8,103.6z M456.3,107.8l-0.3-0.3l0.1-0.5l1.1-0.9l0.5,0.1l0.5,0.8l-0.1,0.6l-0.4-0.2L456.3,107.8z M442.1,103
                l-0.2-0.1l1-3.6l0.3,0.1l0,0.2l-0.4,3.1L442.1,103z"/>
              <path id="BR-MG" onMouseOver={this.togglemgPopover.bind(this)} onMouseOut={this.togglemgPopover.bind(this)} class="st0" d="M527.5,365.3l-0.9-0.5L524,364l-1.6,0.1l-1.1,0.3l-0.8,0.6l-2.2,0.3l-1.3-0.1l1.7,2.3l0,0.8
                l-1.1-0.1l-1-0.7l-0.3,0l-2.2,1.3l-1.2,1.9l0.3,0.7l1.3,0.7l0.5,0.7l0,1.9l-0.3,1.5l0.2,0.4l1.3,0.4l0.2,1.7L516,379l-0.9,0.2l-1,0
                l-0.8-0.4l-1.5,0.1l-0.4,0.5l0.5,0.7l0.8,0.3l0.5-0.3l0.5,0.1l1.1,1.3l1.3,2.8l0.3,2.4l-0.5,3l-1.7,2.1l-1.2,0.9l-0.3,0.4l-0.2,1.7
                l-0.9,2.3l-1.1,1.3l-1,3.2l-0.5,0.3l-5.1,0l-1.7,2.1l-0.2,0.4l0.6,3.1l-0.6,1.3l-0.4,2.3l0.3,0.1l0,0l-2.5,2.4l-1.2,0.3l-0.6,0.5
                l0.7,0.7l-2.4,6l-1.9,3.7l-0.1,0.8l1.4,0.1l0.3,0.6l-0.2,0.5l-2.5,1l-9.5,4.5l-1.4,0.2l0-0.5l-0.5-0.2l-2.5-0.3l-0.6,0.1l-1.6,0.9
                l-1.2,0.2l-3.2,0.1l-0.4-0.3l-0.5,0.1l-4.1,1.8l-1,0.8l-1.7,0.8l-0.5-0.3l-2.6,0.2l-2.9,1.3l-2.4,0.7l-0.7,0.5l0,0l-1.2,0.7
                l-2.2,0.2l-1.9,1l-1.2,1.2l-1.8,0.7l-2.4,0.2l-2.3-0.1l0-0.5l0.3-0.5l-0.1-0.4l-1.2,0.3l-0.2,2.3l0.2,0.2l0,0.8l-0.8,1l-1.3,0.3
                l-0.6-0.4l-3.6,1l-0.2-0.5l-2,0.2l-1.1-0.2l-2-3.3l1.2-0.7l-0.5-1.7l-1.8-0.8l-1.8-1.2l-0.7-1.6l0.5-1.6l0.2-3.4l-0.3-2.9l1.9-2.7
                l0.7-2.2l0.1-1.3l-0.2-0.4l-3.7-1.3l-1,0.1l-0.3,0.4l-0.8,0.3l-1.6,0l0-0.8l-0.8-2l-1.3-1.8l-0.4-2.2l-0.3-0.6l-0.8-0.7l0.2-1.9
                l1.5-2l0.1-0.6l-0.4-1.6l-1.9-1.2l-0.6-0.6l0.3-2.4l0.6-1l-0.2-1l-3.3-3.4l-1.3,0.3l-0.9,0.6l-1.6-0.8l-2.4,0.1l-0.3,0.6l0.2,0.5
                l-0.4,1l-2.8,0.7l-1.1-0.4l-1.5-1.5l-1,1.2L393,401l-0.5,0.5l-0.5,1.2l0.3,2.5l-0.4,0.4l-1.2-0.5l0-2.8l-0.2-1l-0.4-0.4l-0.6-0.1
                l-0.3,0.2l-0.4,1.1l-0.7,0.9l-0.5,0.3l-0.6,0l-1.2-2.1l-0.3-1.4l0.1-0.9l-1.7-0.8l-2.2-1.4l-2,0.6l-1.6-0.2l-1.7,0.4l-4.3-1
                l-2.6-0.1l-1.7-1.5l-0.8-0.1l-1.5,0.6l-1.4,1.7l-1.9,0.4l-2.1,1.1l-1.2,1.1l0,0l0.3-2.6l-0.6-3.2l1-4.2l0.6-0.2l0,0l0.9,0.5
                l0.4-0.2l0-0.3l-0.6-0.6l0.6-1.9l1.6-2.1l0.9-0.7l0.5-0.1l0.8,0.2l0.8-0.3l0.8-1.3l-0.1-1l1.2-2.5l1.4-2.1l2.2-0.5l2.5-1l3.7,0.6
                l3.2-1.6l1.5,0.3l1.3,1.4h0.3l2.9-2.9l4.1-2.6l2,0.9l3.9-0.2l1.5,0.3l1.8,0l0.6-0.1l0.5-0.5l4.4,1.9l0.7,0.8l4.9-2.7l0.5-1.1
                l0.9-0.9l1.2-0.2l3.1-2.1l-1.2-3.5l0.5-1.9l0.7-0.9l0.2-0.7l-0.2-1l-0.5-0.6l-1.6-0.6l-0.9,0.5l-1.1-1.1l0.4-2.1l3.2-3l2.3-2.7
                v-0.6l-1.6-4.6l-1.4-1l-0.9-0.3l-0.9-1.2l0.4-1.5l1-1.2l0.7-1.6l-0.3-1.6l0.5-1.5l1.3-0.1l2.3-1.5l3.4-0.9l0.5-0.5l-0.7-4.3
                l-0.5-0.2l-0.4-0.6l0-1.9l0.7-0.7l0.6-0.9l-0.5-1.2l-0.7-0.1l0.7-3.2l0.4-0.3l1.2-0.2l2.8,1.1l0.4-0.1l0.6-0.5L428,314l1.2-1.5
                l2.4,1.5l0.9,1.8l3.2,0.3l1-1l0,0l0.5,2.4l-1.4,3.4l0.4,0.2l1.3-0.9l0.7-1l0.7-0.1l2.1,0.4l1-0.8l1.9-2.6l1,0.1l1.4-0.5l1.8-1.5
                l1-1.2l2-0.4l3.4-1.9l0.6-1.1l4.3-3l3.8-1.6l1.8,0.1l1.2,0.5l2.2,0l2.3,0.5l0.9,0.5l-1.3,2.7l-0.1,0.9l0.3,1.5l0.6,0.5l5.3,1.5
                l0.6-0.2l0.8-1.1l1.9-0.9l1.8-0.3l2.7,0.8l1.1,0.5l4.4,3.6l1-0.2l2.2,1.6l2.5,1.2l0.9,0.2l0.5-0.2l1.5,1.2l4.4-1.2l0.4,0.2l6.5,6.2
                l0.4,4l0.6-0.2l2.6,0.5l1.5-0.5l1.3-1l2,0.1l2.7,0.6l2.3,1.1l2.2,0.6l2.1,0l2.8,2.5l0.7,0.4l0.9,0.1l0.8,1l0.4,1.2l-1.5,3.1l-5,4.2
                l-0.1,2.6l0.6,1v0.4l-1,0.7l-2.5-0.2l-0.8,0.9l-0.8,3.3l-0.5,4.4l0.2,0.2l0.3-0.2l0.2-0.4l0.6-0.2l0.6,1.2L523,358l0.2,0.5l4.1,3.1
                l0.5,1l0,0.5l-0.7,1.4L527.5,365.3z"/>
              <path id="BR-MS" class="st0" d="M359,399.8l-0.4,2.5l-0.3,0.4l-1.5,0.8l-1.3,0l-0.5,0.2l-1.2,1l-2,2.7l-0.7,0.6l-1,0.3l-0.6,1.7
                l-0.2,2.9l-2.6,3.6l-1,0.5l-0.2,0.4l0.3,1.9l-0.1,0.9l-1.2,2.3l-2,2.3l0.4,0.9l-0.3,1.2l-1.4,1l-0.4,1.2L339,431l-1.3,2.4l-2,1.9
                l-7.6,4.5L327,441l-0.7,1.5l-0.8,1.1l0,0l-5.4,2.3l-1.1,0.8l-0.9,2.3l0,1.1l-1.5,3.6l-0.7,0.7l-1.7,1l-1.1,0.3l-0.3,0.3l-1.6,7.9
                l-0.4,0.8l-2.4,1.6l0,0l-2.6-2.3l-3.4-1.9l-4,2l-0.7,0.8l-1.7,0.5l-2.6,0.4l-2.4-0.5l-1-0.6l-0.5-4.3l-0.3-0.7l-0.9-1l-0.2-2.5
                l0.5-1.3l-0.6-0.8l-0.1-0.4v-2.9l-0.9-2l-0.6-2.7l0-0.7l0.6-1.4l0-1.6l-1.6-1.1l-0.4-0.8l-0.2-2.2l-1.6-1.8l-2.2-0.3l-1.5,0.3
                l-1.8-0.3l-2.5-2l-0.6-1.5l-1.5,0.3l-2.2,2.7l-1-0.6l-1.7,0.8l-1.1,0.2l-1.7-0.7l-2.6-0.5l-3.5,0.2l-3.7-0.7l-0.5-0.9l-2-0.1
                l-0.9,0.5l-2.5-0.9l0.9-7.3l-0.4-2.4l0.6-1.4l1-1.5l0.6-6l-0.7-2.3l-0.1-1.7l-0.8-1l-0.4-0.1l-0.5,0.7l-0.6-3.3l-0.8-1.9l-1.2-2
                l-0.4-1.8l4-2.3l0.8-0.9l-4.2-3.9l5.4-11.4l1.1-0.1l-0.3-2.1l-0.7-0.1l3.2-10.3l0.7-1.2l-3.1-5.9l0-1.9l0,0l0.7,0.2l1,1.1l2.1,1.5
                l5.6-2l2-2l2-3l0.1-1.2l1.4-1.5l2.6,0l1.6-0.2l0.8,0.3l1.7-0.5l1.4-1.2l2-0.5l1.3,0.2l0.8,1.1l2.8,1.2l2.6,0.5l0.6,0.4l0.3,0.9
                l0.8,1l3,1.2l1.3,1.1l1.2,0.6l4.5-0.6l1.3-0.8l0.5-0.9l1.4-0.6l2.4,0.1l1.6,1l0.3,1.4l0.2,0.2l1,0l2.1-0.9l1,0.1l5.6-6l1.3-0.1
                l-1.2,6.6l-1.8,0.7l-1.7,2.8l-0.2,0.5l0.2,0.5l1.3,0.1l1.7,1.3l4.6,0.3l0.6-0.6l2.7-0.1l0.9,0.1l1.2,0.6l1.5-0.3l0,0l0.2,1.8
                l-0.3,2.6l0.2,1.1l0.3,0.4l0.7,0.2l1.1-0.7l2.2,0.9l0.1,0.4l-1.3,1.8l-0.6,1.2l-0.3,0.8l0.1,0.3l3.8,0.7l2.8-0.2l2.7,1.7l1.1,0.2
                l1.4,0.7l0.7,0.6l3.1,1.6l0.7,0.9l3.4,1.3l1.5,0l1.9,0.4l1.6,1.5l3.1,0.6l2.7,2.2l0.2,0.4l0,0l-0.6,0.2l-1,4.2l0.6,3.2L359,399.8z"
                />
              <path id="BR-MT" class="st0" d="M207,296l1.5-1.4l4.1-2.6l0.5-2.4l1.4-3.8l0.9-1.1l1.6-0.5l0.4-0.4l0.6-0.8l0.5-2.2l1.7-2l0.6-2.2
                l0.1-1.7l-0.6-1.5l0-2.1l-1.5-3.3l-0.9,0l-0.6-0.5l-0.4-3.2l0.1-1.3l1.4-1.1l1.4-1.8l0.2-0.8l-1.4-3.6l-1.1-0.4l-1.1-0.1l-3.5,0.1
                l-1.2-1.1l0.2-0.6l-17.2-0.1l0.9-9l-1.5-2.8l-0.3-1.8l0-0.8l1-3.5l-0.4-1.9l0.9-1.7l-0.8-2.3l-0.2-1.4l-0.3-0.6l-0.8-0.5l-0.1-0.3
                l1.3-5l0.8-1.4l-0.6-1.2l-1.4-1l0,0l47.9-0.3l1.2-0.7l1.2-5.2l1.4-4.2l-0.5-1.9l-0.7-1.4l-0.1-0.8l0.4-0.9l0.9-0.6l1.2-1.8l0.3-1
                l-0.3-0.8l0.1-0.8l0.2-0.6l1.2-1.2l0,0l1.2,1.1l2.4,4.1l0.4,1.8l1.4,4l1.3,1.9l0.8,1.6l-0.2,2.4l0.5,2.4l0.7,2.2l1.5,1.1l3,2.8
                l3.4,1.7l0.2,0.9l0,1.1l0.2,0.5l1,0.4l0.8-0.2l1.6,0.4l0.5,0.7l0.2,1.1l0.4,0.3l1.4-0.4l3.7,1.5l34,2.4l63,3.5l0,0l-0.6,1l-0.6,1.9
                l-1.1,1.2l-0.4,3.1l-0.4,0.9l-0.4,0.3l-1.9,5.3l-0.5,3l0,2.9l-1.9,6l0.1,0.9l1.4,1.3l-0.2,0.9l-0.8,0.9l0,0.5l1.1,2.2l-0.5,3.3
                l0,1.6l0.5,0.6l0.4,3l-0.7,2.8l1,3.3l1,0.5l0.8,0l0,0l0.1,0.6l-0.3,1.1l-1,0.9l-0.4,0.9l0.2,2.7l-0.4,1.1l-2,3.2l-1,2.6l-1.2,0.8
                l0.1,5.2l-1.6,3.1l-0.6,2.7l0.5,1.1l0,0.8l-1.2,2.9l-0.8,2.9l-1.4,1.4l-1.2,0.7l-1.2-0.7l-1.1,0.3l-1.7,1l-1.9,1.9l-0.4,0.7
                l-0.6,4.1l-0.7,1l-2,4.2l-0.3,0.3l-2.7,0.9l-2.6,0.1l-1.4,2.6l-1.8,1l-0.1,1.5l-0.8,1.4l-1.3,1l-0.5,1l-0.3,1.9l-1.2,2l-1.9,1.8
                l-2,0.8l-0.7,0.8l-0.4,2.9l-2.5,3.8l-0.4,3.4l0.2,0.7l1.4,2.2l0.2,3.4l0.9,2.2l0,0l-1.5,0.3l-1.2-0.6l-0.9-0.1l-2.7,0.1l-0.6,0.6
                l-4.6-0.3l-1.7-1.3l-1.3-0.1l-0.2-0.5l0.2-0.5l1.7-2.8l1.8-0.7l1.2-6.6l-1.3,0.1l-5.6,6l-1-0.1l-2.1,0.9l-1,0l-0.2-0.2l-0.3-1.4
                l-1.6-1l-2.4-0.1l-1.4,0.6l-0.5,0.9l-1.3,0.8l-4.5,0.6l-1.2-0.6l-1.3-1.1l-3-1.2l-0.8-1l-0.3-0.9l-0.6-0.4L284,355l-2.8-1.2
                l-0.8-1.1l-1.3-0.2l-2,0.5l-1.4,1.2l-1.7,0.5l-0.8-0.3l-1.6,0.2l-2.6,0l-1.4,1.5l-0.1,1.2l-2,3l-2,2l-5.6,2l-2.1-1.5l-1-1.1
                l-0.7-0.2l0,0l-0.7-0.7l-0.3-0.8l0.5-0.7l-2.4-2.1l-1.3,0.6l-0.5,0l-1.2-0.7l-0.4-0.7l-2-1.4L244,354l-0.3-0.3l-0.3-1.1l-0.2-2.9
                l-0.6-1.8l-0.2-3.7l0.9-1.6l1.1-1.1l0.3-1.8l-0.1-1.9l-1,0.1l-0.4,0.6l-0.4,0.2l-26.9-1.1l-1.1-12.6l-5.4-6.1l4.9-0.1l-0.3-7.5
                l-2.6-5.3l-0.5-2l0.3-1.1l0.6-0.5l0.7-1.3l-1.4-2.9l-3.1-1L207,296z"/>
              <path id="BR-PA" class="st0" d="M371,235.2l-63-3.5l-34-2.4l-3.7-1.5l-1.4,0.4l-0.4-0.3l-0.2-1.1l-0.5-0.7l-1.6-0.4l-0.8,0.2
                l-1-0.4l-0.2-0.5l0-1.1L264,223l-3.4-1.7l-3-2.8l-1.5-1.1l-0.7-2.2l-0.5-2.4l0.2-2.4l-0.8-1.6l-1.3-1.9l-1.4-3.9l-0.4-1.8l-2.4-4.1
                l-1.2-1.1l0,0l-0.6-0.6l-0.1-2l-0.6-1.3l-1.6-1.6l-1.1-0.6l-0.4-0.6l-0.8-2.3l0-1.1l0.6-1l1.4-1.1l0.8-1.3l29.9-64.2l-1.8-1.7
                l0.1-0.8l-0.2-0.5l-1.4,0.3l-0.9,0.6l-1.9-0.8l-1.1-2.2l-2.7-1.4l-1.3-1.3l-0.1-0.6l-1.3-0.8l-1.4-0.2l-2.2-0.7l-2.4-1.3l-1.9-0.7
                l-1.4-1.1l-0.6-0.9l-4.6-2.5l-4.6-3.8l-0.3-0.4l0-0.9l-0.6-1.8l-1.8-1.2l-0.7,0.2l-0.4-0.2l-1.8-2l0.1-2.1l0.4-0.7l-0.2-0.4
                l-1.7-0.8l-0.2-0.7l-0.2-2.3l0.4-1.4l-0.4-4.9l0,0l-1.6-17l0,0l0.8,0.3l0.4,0.6l0,0.5l0.4,0.4l0.9,0.3l1.4-0.7L239,62l2.7,0.2
                l0.8-1l-0.5-1.8l1.8-0.4l1.4-1.6l2.6,1l1.9,0l0.5-1.5l3.3-1.6l2,0.4l1.2-0.1l0.5-0.2l1.2-1.5l0.4-1.4l1.5-1.1l0.9-0.1l0.6,0.5
                l2.1-1.1l0.4,0.1l0.4,1l0.7,0.5l2,0.5l1.6,0.2l0.6-0.6l1.7-0.4l0.8,0.3l0.8,0l0.9-0.3l2.2,0.3l4.7,1.4l1-0.1l1-0.9l0-2.3l-2.1-2.5
                l-1.2-0.6l0.5-1.7l1.3-1.5l0.3-1.1l0.3-0.2l0.7,0.2l0.8,0.8l2.4,1.1l11.6-2.3l1.6,1.7l1,0.1l0.4-0.3l0,0l0.6,0.6l0.4,2.3l-0.6,1.3
                l-0.2,4.5l0.2,1.4l0.3,0.5l1.2,0l1.1-0.3l3.4,0.4l1.3,0.5l1.9,1l0.7,0.8l0.5,1.3l1.2,0.1l1.1,0.8l0.8,1l0.8,0.3l1.3-0.1l0.4-0.4
                l0.9-0.1l2.8,2.7l0.7,0l0.5,1.2l-0.7,0.2l-0.2,0.7l0.8,2.8l2,2.3l2.6,0.7l0.2,0.3l-0.4,3.1l0.2,2.1l0.7,1.5l0.6,0.6l0.6,1.8
                l-0.1,1.6l1.5,3.5l0.8,0.2l0.8-0.4l2.9,3.3l-0.1,2.4l0.4,0.9l1,0.2l0.3,0.5l0.3,1.6l-0.2,0.7l0.1,1.3l1.9,1.4l0.4,1.5l1.5,1
                l2.8,0.7l1.3-0.1l0.8-0.3l0.9,0.1l0,0l-0.5,0.7l0.3,1.6l-0.2,0.5l-1.3,1.1l-1.4,0.2l-0.5-0.2l-0.8-0.8l-0.7-0.2l-3.4,1.6l-0.4,0.5
                l-1.6,0.7l-0.6,0l-1.5,0.6l0,0.6l6.5-1.1l0.9,0.7l0.1,0.5v0.7l-0.6,0.5l0.3,0.4l0.6-0.2l0.9-1l1,0l0.5-0.3l0.6,0.1l1.1-0.4l1.1-0.6
                l3.3-2.3l1.5-0.3l1.8-0.8l3.2-1.8l0.5-1l0.7-0.5l2.1-1l0.7-0.7l-0.2-0.7l0.2-0.4l1.1-0.3l1.1,0.1l0.4,0.2l0.4,3.8l-0.5,2.6l0.3,1.6
                l0.8,1.6l1.3,1.3l0.3,1.6l-0.1,0.5l-1.8,1.8l-0.5,0.1l-0.9-0.5l-1.8-0.5L358,110l-0.9,0.1l-2.1-1l-0.3-1.1l-0.9-0.5l-2.3,3.8
                l-0.6,2.4l0.7,3l0.5,0.6l0.9,0.6l-1-1.1l-0.4-2.7l0.1-0.5l2.6-4.2l2.3,1.2l0.1,0.4l2.5,2.4l-0.2,2l-0.7,0.6l0.4,2.3l0.5,1.1
                l1.1,0.8l0.7,0.2l0.5,0.5l-0.3-0.6l-0.8-0.2l-1.2-1l-0.2-0.7l0.3-4.2l0.8-1.2l1.4,0.7l0.8,1.2l-0.1,0.8l1.2,0.9l0.2-0.3l-1-1.3
                l-0.9-2.1v-0.4l2.3-2.3l1.6,0.1l1.9,0.6l0.3,0.6l-0.6,0.6l0.3,0.3l0.5,0l1-0.2l1.3-0.9l4.1-1l0.8,0.2l1.7,1.1l3.2-0.4l1.1-0.4l1-1
                l1-0.6l1.7-0.5l0.8,0.1l0.1,0.8l-2,4l-0.2,1.5l-0.5,1.1l-0.8,1l0,0.9l0.6,1.4l-0.3,2.5l0.9-1.2l0-1.4l1.5-3l1.1-3.2l1.1-1.6
                l0.3-0.3l0.8-0.1l0.6-0.3l1.8-0.2l0.9-1.3l1.5-1.7l1.4-2.3l0.5-0.5l0.8,0.5l0.6-0.5l1.6,2.3l1.2,0.6l0-0.7l-0.4-0.8l0.6-1l3.5-0.5
                l-1-0.3l-3.5,0.5l-0.3-0.2l0.3-2.4l0.5-0.3l0.7,0.4l1,0.1l1.1-3.1l-0.7-1.4l0-0.7l1.2-1.8l2.8-2.5l0.4-0.1l1.1,1.1l2.9-2.1l0.5-0.1
                l0.4,0.4l0.1,0.6l-0.3,0.7l1.1-0.2l0.8-0.6l0.4-0.8l0.6-0.2l0.3,0.2l0.7,1.4l1.3,1.2l0.2-0.6l-0.1-0.4l-1-0.5l-0.1-0.3l-0.1-1.2
                l0.2-0.4l0.4-0.2l2.3,0.3l1.2,0.7l0.6,1l1.6,0.7l1.6-0.8l0.5,2.1l-0.5,0.3l0,0.6l0.9-0.5l1.2-2.3l0.4,0.2l-0.2,1.3l0.4,0.4l2.5-0.3
                l0.5,0.7l0.5,1.7l-0.6,0.9l0.9-0.5l0.3,0.4l1.9,0.1l3.5-1.2l-0.3,1.6l-0.8,1.8l1.5-0.5l0.6-0.6l0.6,1.6l0,0l-0.8,0.4l-0.5,1
                l0.2,1.4l-0.7,4.4l-0.6,0.7l-1.1,0.2l0,0.6l0.3,0.5l1-0.2l0.4,0.5l-0.1,1.6l-0.9,3l-0.4,0.7l-1.7,1.6l-0.5,2.4l0.1,1.6l-3.7,3.1
                l1,1.6l-0.8,0.8l0.2,1.8l-0.5,1.3l-0.8,1.4l-1.5,1.8l-2,1.5l-1.6,4.7l-0.3,1.9l-0.5,1.2l-2.4,2.3l-1.1,0.5l-0.6,2.9l-1.5,1.2
                l-0.4,1.1l-1.5,2.2l-1.2,0.9l-2.4-0.2L394.1,165l0.5,0.1l0,0l2.3,0.8l1.9-0.2l1.3,0.1l1,1.7l0.4,0.3l0.9,0.1l1.3,1.2l0,0.6
                l-0.4,0.7l-0.7,0.4l-1,0.1l-0.3,0.3l0.1,1.4l1,1.3l-0.9,2.8l-0.6,0.5l-1.5,0.5l-0.2,0.5l0.3,1.2l-0.2,1.3l-1.3,0.1l-1.5,1.3
                l-0.9,1.3l0.1,1.5l-0.2,0.5l-2.5,1.2l-3.4,1.1l-2.5,1.8l-0.2,0.4l0.4,2.2l0.1,2.7l-1.3,2.1l-1.4,1.7l-0.1,0.6l0.2,0.9l0.6,1
                l1.7,1.7l0.8,0.3l0.1,0.3l-0.7,4l-1.8,5.1l-1.1,0.8l-1.7,3.2l-0.2,1l-0.9,1.5l-0.7,0.9l-1,0.2l-1.4,0.9l-1,1.9l-1.2,1.7l-1.2,1.1
                l-1.1,1.6l-1.1,4.5L371,235.2z M392.2,104.9l-4.5,2l-3.4,0.5l-2-0.3l-0.6,1.1l-1.8,1.2l-0.7-0.6l-0.6-1.5l-0.2,0.3l0.4,1.2
                l-0.3,0.8l-0.7,0.4l-3.8-1.6l-0.8,0.7l-4.2,1.3l-3.2-0.6l-0.8-0.6l-0.2-1.8l-0.2-0.4l-1.1-1l-1.1-1.6l-0.2-1.8l0.5-2.8l0.6-0.4
                l1.8,0.4l0.9-0.8l0.1-0.6l-1.3,0.4l-1.2-0.1l-0.9-0.7l-0.2-1.1l0.3-5.1l0.7-0.8l0.4,0.8l1.8,0.6l0.5-0.1l-0.5-0.4l-1.3-0.2
                l-0.9-2.2l0.4-1.7l0.9-2l1.3-1.3l1-0.6l1.3-0.5l1.3-0.1L373,84l7.2,1.8l3.7-0.5L384,85l1.1-0.6l2.2-0.4l2.9,0.3l2.8,0.8l6.5,0.8
                l0.6,0.5l0,1.2l-0.7,0.7l-0.7,1.5l-0.4,2.7l-0.9,3.3l-1.3,0.7l-0.4,0.7l0.2,1.2l-2,2.6l-1.4,3.5L392.2,104.9z M345,104.8l-0.8-0.5
                l1.1-2.2l-0.1-1.8l0.2-0.2l2.3-0.5l0.9-0.9l0.1-0.5l-0.2-2.3l0.9-2.6l1.3-1.5l2.3-1.5l2.9-0.2l0.7,2.4l-1.1,2.2l-0.9,3l-1.1,0.7
                l-1.3,2l-0.8,0.7l-4.9,3.3L345,104.8z M373.5,81.9l-1-0.2l-2.5,0.1l-0.8-0.2l-0.3-0.3l-0.4-1.1l0-1.2l1.5-0.4l1.9,0l2.2-1.3
                l4.8-0.6l0.8,0.3l0.7,0.7l-0.2,1l-2.6,1.4l-1.8,2.5l-1.1,0.5L373.5,81.9z M378.2,81.9l1.9-1.2l2,0l1.6,0.6l0.5,1l0,0.6l-0.9,0.8
                l-2.9,0.3l-1.2,0.3l-2.3-0.9l-0.2-0.5L378.2,81.9z M361.3,86.2l-0.5-0.3l-0.6,0l-1.5-0.7l0.2-1.2l0.6-0.9l1.9-0.4l2.4-0.9l1.3,0.1
                l0.8,0.7l0.1,0.1l-1.9,1.6l-1.1,0.3L361.3,86.2z M370.7,76.6l-0.8-0.3l-0.2-1.7l0.1-0.6l1.2-1.3l2.5-0.9l0.2,0.1l0.5,0.7l0,0.9
                l-2,2L370.7,76.6z M356.9,96.8l-0.1-0.4l0.2-0.9l1-2.8l2.6-1.7l0.7-0.3l0.7,0.1l-0.2,1.8l-1.6,0.4l-1.6,2.2L356.9,96.8z
                M367.8,79.2l-1.2-0.3l-0.3-0.5l0.9-1l0.7-3.9l0.9-1.2l0.3,0.1l0.1,0.3l0,2.5l0.6,1.9l-0.3,0.9l-1,0.4L367.8,79.2z M353,89.4
                l-0.3-0.2l0.1-0.6l0.5-0.4l1.2-1.7l0.8-1.5l0.7-0.7l1.5-0.5l-0.2,1.5l-0.4,1l-1.7,1.7L353,89.4z M366.8,82.4l-0.6-0.2l-0.8-0.7
                l-1-1.6l0-0.4l0.2-0.3l0.8-0.6l1.9,0.8l0.3,0.4l0.2,2.1L366.8,82.4z M361.5,112.9l-0.6-0.1l-0.9-0.5l-0.7,0.5l-0.8-0.3l-1.7-1.7
                v-0.6l1.3,0l3.1,1.3L361.5,112.9z M340.3,107.4l0-1l-0.2-0.3l0.6-1l2.4-0.9l0.8,0.4l0.9,0.9l-0.3,0.3l-1,0.5l-2.5,0.4L340.3,107.4z
                M353,100.7l-0.2,0l0-0.3l0.5-0.9l1.8-1.6l0.4-1.2l0-1.3l0.2-0.3l0.3,0.4v1.5l-0.9,2.7l-0.6,0.7L353,100.7z M359.3,90.8l1.6-3
                l0.9-0.2l0.7,0.2l0.3,0.9l-0.6,0.7L359.3,90.8z M390.7,109.8l-0.8-0.3l-0.3-1.1l1-1.7l1.1-0.3l0.2,0.1l0,0.7l-0.7,2.2L390.7,109.8z
                M357.2,90.3l-0.5-0.2l0.3-2l1.3-1.8l0.2,0l1.3,0.9l0,0.2l-1.5,0.6L357.2,90.3z M359.5,95.3l0.7,0l-0.4,0.4l-0.9,0.3l-0.1,1.1
                l-0.8,0.7l-1.7,0.8l-0.2-0.2l0.3-0.8l0.5-0.6l1.7-1.7L359.5,95.3z M336.9,105.5l-0.5-0.2l-0.1-0.3l0.4-0.4l3.6-1.6l0.5,0.7l0,0.2
                l-1.4,0.7L336.9,105.5z M400.4,100.8l-1-0.2l-0.8-0.8l0.9-1.2l1.3,0l0.2,1.2l-0.1,0.6L400.4,100.8z M363.2,81.9l-2.4,0.7l-0.6,0
                l-0.2-0.2l1-1l1-0.5l1,0.2l0.4,0.3l0.1,0.2l-0.1,0.2L363.2,81.9z M357.9,90.4l-0.1-0.8l0.2-0.5l2.5-1.5l-0.9,2l-0.8,0.6L357.9,90.4
                z M360.1,95l-0.5-0.1l-0.1-0.2l0.8-1.5l1.2,0l-0.1,1.3L360.1,95z M388.1,110.8l-0.3-0.2l0.9-1.6l0.7-0.4l0.2,0.8l0.5,0.4l-0.3,0.5
                l-0.4,0.3l-0.6-0.2L388.1,110.8z M361.4,87.5l-0.9-0.1l0-0.2l2.4-1.8l0,1l-0.2,0.6l-0.2,0.3L361.4,87.5z M428.9,97.7l-0.4-0.5
                l0.2-0.8l1-0.9l0.2,0.2l0.2,1.8L428.9,97.7z"/>
              <path id="BR-PB" class="st0" d="M610.4,199.1l-2.5-2.1l-0.8-0.3l-2.6-0.2l-2.3,1.1l-1.5,1.3l-0.5,1.8l-1.9,1l-2,0.4l-1.1,0v0.6
                l-0.8,0.5l-2.2,0.3l-3.9-0.3l-1.6,0.9l-0.5,0.6l-1.8,0.1l-0.9,0.7l-0.5,0.8l0.4,0.7l0,0.5l-0.4,0.5l-1.8,1.5l-1.3,0.2l-1.7,0.8
                l-1.6-0.8l-1-0.9l-0.4-2.2l0.2-0.5l-0.2-0.3l-1.1-0.3l-0.4,0.3l-1.5,0.2l0-0.4l2.5-2.6l0.2-0.4l-0.7-1.9l0-0.5l0.4-0.8l3-1.1
                l0.1-0.3l-0.5-1.4l-3.5-1.9l-1.5,0.4l-2.2,1.3l-0.9,1.5l-1.5,0.9l-1,0.3l-2.7,1.9l-1.5,1.6l-1.6,0.2l-2.6,1l-0.8-0.3l0-0.7
                l-0.6-1.2l-0.4-0.1l-0.9,0.7l-1.5,0.3l-1-0.3l-1.4-1.9l-0.4-0.1l0,0l-0.2-0.2l1.2-1.1l1.6-3.5l-0.3-1.2l-0.7-0.1l-1.2-0.9l0-1.7
                l-0.3-0.7l-0.6-0.3l-0.2-0.4l0.1-0.9l0.2-0.4l1.1-0.6l0.7-1.3l-0.5-1.3l1-3l0.8-1.2l0.1-0.5l0,0l0.6-0.5l1.9,1.6l0.9,0.4l2.9,0.6
                l1.2-1.1l3.2-1.7l0.8-0.7l0.1-1l0.4-0.9l3.2-1.2l1.4-0.1l3.3-1l0.6,0.1l0.5,0.4l0,1.2l-3,3.2l-0.4,1L571,183l-0.4,0.4l-0.9,0.2
                l-0.2,0.3l-0.1,1.7l3.5,0.5l0.3,0.2l0.3,1.4l0.3,0.2l3.2-1.3l1.8-0.1l2,0.3l0.9,1.2l-0.5,1.5l0.8,0.7l2.3-0.9l0.5-0.7l0.5-1.2
                l-0.5-0.9l-0.2-1.4l0.5-0.6l1,0.3l-0.5-1.5l-0.7-0.8l0.3-1.5l1.8-1.4l1.4-0.1l0.4,0.4l-0.1,1l0.6,0.5l3.4,0.4l0.5,0.4l5.8-0.4
                l2.2,0.3l2.5,1.1l1-0.4l3.5,0.2l0.9-0.2l0,0l0.1,2.4l1.5,4.4l0.1,0.6l-0.2,0.9l-0.4,0.4l0,0.9l0.6-0.7l0.5-1.5l0,2l0.6,1l-0.3,5.2
                l-0.4,0.6L610.4,199.1z"/>
              <path id="BR-PE" class="st0" d="M558.3,227.2l-1.2-3l0-0.9l-2.1-0.9l-0.5-0.4l-0.5-0.8l0.6-0.9l0-0.6l-0.5-0.3l-1,0l-0.4,0.4
                l-0.3,1.7l-0.4,0.2l-0.4-0.3l-0.5-1.6l-1.6-1.1l-3-0.4l-2.4-0.8l-1.3-0.8l-0.8-1.4l-1.5-0.6l-0.6,0.1l-2.5,1.5l-1.7,0.4l-0.8,2
                l-2.2,0.5l-0.3,0.2l0.2,1.4l-0.2,0.6l-1.1,1.2l-2.4,1l-0.6-0.1l-0.8-0.5l-0.8,0.2l-0.5,0.9l-0.8,3.6l-2.9,0.9l-0.8,0.9l-1,0.2
                L519,229l0.9-1.7l-0.3-2l-2.2-0.9l-0.7-1.9l0.1-2.7l-0.4-0.5l-2.8-1.4l-2.2-0.3l-1.2,0.3l-0.9-0.4l0,0l2.6-1.3l2-1.7l0.5-0.9
                l2.3-1.3l1-0.3l3.9-3.9l0.7-1.8l0.1-2.3l-0.3-0.5l-1.6-1.6l0.1-2.2l-0.6-1.1l-0.1-0.9l0.1-0.4l0.5-0.3l1.8-0.2l0,0l4.9,0.2l0.9,0.2
                l1.6-0.2l2.1-0.8l1.3-0.1l3.1,0.4l2.9,1.9l1.8,0.8l0.5,1l1,1.1l1.4,0.5l0.8,0.6l0.6,1.6l1.6,0l1.5-2.3l1.1-0.8l1.6-0.5l0,0l0.4,0.1
                l1.4,1.9l1,0.3l1.5-0.3l0.9-0.7l0.4,0.1l0.6,1.2l0,0.7l0.8,0.3l2.6-1l1.6-0.2l1.5-1.6l2.7-1.9l1-0.3l1.5-0.9l0.9-1.5l2.2-1.3
                l1.5-0.4l3.5,1.9l0.5,1.4l-0.1,0.3l-3,1.1l-0.4,0.8l0,0.5l0.7,1.9l-0.2,0.4l-2.5,2.6l0,0.4l1.5-0.2l0.4-0.3l1.1,0.3l0.2,0.3
                l-0.2,0.5l0.4,2.2l1,0.9l1.6,0.8l1.7-0.8l1.3-0.2l1.8-1.5l0.4-0.5l0-0.5l-0.4-0.7l0.5-0.8l0.9-0.7l1.8-0.1l0.5-0.6l1.6-0.9l3.9,0.3
                l2.2-0.3l0.8-0.5v-0.6l1.1,0l2-0.4l1.9-1l0.5-1.8l1.5-1.3l2.3-1.1l2.6,0.2l0.8,0.3l2.5,2.1l0,0l0.9,0.2l0.5,1.2l-1.3,1.7l-0.1,0.5
                l0,0.5l0.7,0.4l0.3,0.5l0.2,1.1l-2,6.4l-3.2,8.9l0,0l-0.3-0.2l-2.9-0.3l-1.9-0.8l-1.5,0.2l-2.6,1.3l-2.2-0.9l-2,0.2l-1.5,1.2
                l-0.1,0.5l-2.2,2.3l-1.8,1.1l-1.2-0.1l-1.9,1.1l-1.2,0.1l-3.1-0.3l-2.5,0.6l-1.9-0.5l-1.8-1.1l-1.9-2.1l-2.5-2l-1.1,0.1l-1.7-0.3
                l-1-1.2l-0.8,0.6l-0.1,0.7l-2.4,3.3l-2.2,0.8l-0.6,1.1L558.3,227.2z"/>
              <path id="BR-PI" class="st0" d="M505.7,126.9l1.3,0.1l0.7-0.2l2.2,0.5l1,0.9l0.3,0.6l0,0l-0.1,0.8l-1.1,1.9l-1.6,2l-0.2,1.5
                l0.3,1.3l1.8,4.7l3.2,6.4l0.4,1.7l1.4,1.6l0,0.8l-0.7,1.4l0.8,3.9l-0.5,1.6l1,2l0.6,3l-0.6,1.6l-0.8,0.6l0.9,0.7l0.1,0.4l0.9,8.2
                l-0.2,0.4l0.8,2.6l1,5.2l1,2.7l1.3,0.6l2.6,0.5l0.8,1.2l0,0.7l-1.5,2.2l-1.1,3.1l0.1,1l0.7,0.7l-0.1,1.3l0,0l-1.8,0.2l-0.5,0.3
                l-0.1,0.4l0.1,0.9l0.6,1.1l-0.1,2.2l1.6,1.6l0.3,0.5l-0.1,2.3l-0.7,1.8l-3.9,3.9l-1,0.3l-2.3,1.3l-0.5,0.9l-2,1.7l-2.6,1.3l0,0
                l-0.4,1.2l-1.5,2.3l-1.1,0.7l-0.8-0.2l-2,0.4l0.1,2.4l-1.9,1.8l-0.3-0.1l-0.6-0.6l-2.2,0l-0.8,1l-0.8,0.4l-2.7,0.3l-1.5,1.3
                l-0.8,1.2l-1.7,0.1l-1.5,0.6l-3,0.1l-0.8-0.3l-0.2-0.3l0.1-0.7l-1.7-1l-1.3-0.4l-1.4,0.6l-2.1,0.4l-0.3-0.2l-0.4-0.9l-1.5-0.5l-2,0
                l-3.2,1.7l-0.1,0.9l2.2,4.4l0.1,1.1L473,238l-0.2,0.9l-0.7,0.9l-3,5l-3,2.4l-1.4,0.4l-1.5-0.5l-1.3-0.2l-2.2,0.8l-1.2,0.9l-0.7,0.9
                l-1.1,0.4l-0.8,1.4l-0.3,0.2l-2.1-0.2l-1.8,0.5l-4.2-1.9l-1.8-2.5l-0.5-2.2l-0.7-1l-1.4-1.3l-2.3,0l0,0l-2.9-0.2l0,0l0.1-1.9
                l0.3-1.1l1.2-2.4l0.1-6.4l0.8-1.4l0-0.5l-1.7-3.1l-0.5-3.2l-0.7-1.9l0.7-2l1.7-1.4l1.2-2.5l0.1-1.5l0.3-0.8l0.3-0.2l1.4-2.7
                l0.6-2.3l0.4-3l1-3l2.1-1.9l2.7-0.8l2.6-0.3l1.1-0.4l1.7-1.5l1,0l1.3-0.5l2.9-2.6l2.3-0.9l2-2l0.6-0.9v-0.5l1.9-1.9l0.5-0.2
                l0.8-0.2l0.2,0.3l1.1-0.3l1-0.6l1.5-0.2l1,0.2l2.5,1.8l1.5-0.1l2.4-0.9l0.8,0l0.6,0.3l2.3-0.3l1.2-1.3l0.5-1.5l0.1-1.2l0.3-0.4
                l0.4-2.2l-0.1-0.9l-0.2-0.5l-1.6-0.9l-1.8-2l-0.5-2.3l-0.1-2.1l0.2-2.5l4.1-4.4l0.2-0.6l0.1-2.4l-0.5-3l-1.6-2.8l1.1-2.9l0.3-1.5
                l-0.4-1.3l-1.2-0.8l-0.4-1.9l0.8-1.1l0.6-0.2l0.8-2l1.9-1.9l0.9-1.8l0-1.8l2.9-3.4l0.8,0.2l3.3-0.5l4.1-4l1.8-2.3l0.4-1.5l-0.6-0.5
                l-0.2-0.7l0.5-1.4l0,0l0.7,0.1l1.5,0.9l0.5,0.5l-0.1,0.3L505.7,126.9z"/>
              <path id="BR-PR" class="st0" d="M396.8,499.4l-5.2,0l-0.3,0.4l-1.7,0.2l-0.8-0.1l-0.1-0.4l-1.6,0.6l-1.4,1.4l-3.4,2.1l-1.4-0.2
                l-1.2-0.6l-3.1-2.6l-1-0.4l-0.8,0l-2.8,0.7l-2.3,0.2l-0.8,0l-1.4-0.8l-1.1,0.1l-1.5,0.6l-0.6,1.5l-0.7,0.9l-1.9,1l-3.6-0.4l-2,1.1
                l-0.7,0.8l-0.7,1.6l0.9,3l-0.9,0.9l-0.9,0l-0.8,0.4l-0.2,0.5l-1.3-1.9l-4.2,0.1l-3.8-0.4l-1.6-1.2l-0.2-0.4l-1-0.6l-4.3-0.4
                l-5.3-1.7l-2.3,0.6l-2.7,0l-2-1.4l-0.3-0.5l-1.1-0.2l-0.5,0l-0.7,0.4l-3.6,0l0,0l-0.2-1.2l-2.5-4.2l0-2.6l-1-2.9l-3.4-2.4l-1.1,1.1
                l-2.1-0.1l-1.1,0.4l-1.1,0.9l-1.5-1.1l-0.8,0.1l-0.3-2.2l0.7-1.6l1.1-1.3l1.2-2.2l0.1-0.3l-0.5-0.8l0-1.2l0.9-3.6l1.3-3l-0.2-2.3
                l1.3-2.4l-0.2-1l-0.8-0.9L307,468l0.7-1.1l0.8-0.5l0,0l2.4-1.6l0.4-0.8l1.6-7.9l0.3-0.3l1.1-0.3l1.7-1l0.7-0.7l1.5-3.6l0-1.1
                l0.9-2.3l1.1-0.8l5.4-2.3l0,0l3.3-2.3l3.8,0.7l4-0.1l3.3,0.3l1.2-0.6l0.4-0.6l0.7-0.4l1.2,0.3l1.5,0.8l2.5,0.5l2.6,1.1l1.3,0.1
                l1.1-0.5l2.6,0l0.8,1l1.8,0.8l3.5,0.8l0.6,0.5l0.7,1.3l1.7,0.1l0.4-0.3l1.6,0.1l0.7,0.5l5.3,0.1l3-0.9l1.1,1.5l3.6,2.8l0.9,1.8
                l0,2.7l-0.4,0.6l-0.2,0.8l0.4,1.8l1.3,1.5l-0.1,1.8l-0.4,0.6l1.5,3l1.2,1.1l0.7,0.3l0.5,0.7l0,0.9l0.9,2l0.6,0.3l0.2,0.4l-0.2,1.1
                l-1.3,2.1l0.3,2l0.6,0.4l1,0.2l2.4-0.1l3.4-0.5l0.5,0.4l1.6-0.2l1.6,0.1l1.6,1.3l-0.1,0.4l-0.9,0.6l-0.7,3.3l0.4,0.8l1.1,0.5
                l0.7-0.9l1.1-2.8l0.8-0.2l2.1,0.6l-0.4,2l0.7,3.3l1.4,0.6l0.2,1.3l0,0l-1.3,1l-0.6,1.5l-0.5-0.1l0.3-1.9l1.4-0.9l-1.4,0.3l-0.9,0.5
                l-0.9,0l-0.3-0.4l-0.6-0.2l-0.5,0.4l-0.3,0.9l0.5,0.8l-0.7,1l-0.3,0.1l-2.5-0.5l-0.8-0.5l-0.5,0.1l-0.2,0.9l0.3,0.4l1.6,0.6
                l1.4-0.2l2.1,0.6l0.3,0.5l-1,0.7l-1.5,2.8l-1.2,0.8l-2.4,0.6l-0.2,0.5l2.9-0.2l0.2,1.2L396.8,499.4z"/>
              <path id="BR-RJ" class="st0" d="M468.4,450.3l-0.1-0.8l0.4-0.3l3.2,0.2l2.6-0.6l-2.5-1.5l-1.4-0.5l-2.8,0.7l-1.4,1.6l-1,0.3l-0.7,0
                l-1.4-2.1h-0.5l-0.9,0.4l-1,1.1l-3.1,0.8l-0.3,0.9l-0.2,1.9l2.2,0.1l1.1,1l-1.1,1.1l-0.9-0.2l-1.4,0.2l0,0l-0.5-0.3l-1.6-1.7l0-0.4
                l1.1-3.5l1.1-1.1l2.4-1l0.5,0.1l2.8-0.4l1.5-0.7l1.6-2.1l-0.8-1.6l-1.7,0l-3.1,0.5l-1.8-0.4l-1.2-1.6l-0.6-1.2l-1.3-0.5l0,0
                l0.7-0.5l2.4-0.7l2.9-1.3l2.6-0.2l0.5,0.3l1.7-0.8l1-0.8l4.1-1.8l0.5-0.1l0.4,0.3l3.2-0.1l1.2-0.2l1.6-0.9l0.6-0.1l2.5,0.3l0.5,0.2
                l0,0.5l1.4-0.2l9.5-4.5l2.5-1l0.2-0.5l-0.3-0.6l-1.4-0.1l0.1-0.8l1.9-3.7l2.4-6l-0.7-0.7l0.6-0.5l1.2-0.3l2.5-2.4l0,0l1.9,0.8
                l0.7,4.7l2.5,1.1l4.3,1l3-0.4l1.6,1.1l0,0l-0.1,1.3l-0.9,1.6l-0.2,2.1l1.1,6.8l-0.5,0.7l-3.1,1.8l-6,1.9l-2.8,1.7l-3.5,3.7
                l-0.1,1.7l0.3,1.2l-0.2,1.6l-0.7,1.5l-1.8,0.5l-6.5-0.2l-5.8,0.7l-2.4-0.5l-0.5-0.4l-0.1-0.7l1-1.5l0.7-1.5l-0.1-0.6l-0.8-0.2
                l-2.8,1.1l-0.3,0.7l0.3,0.8l1.4,1.2l0.2,1l-0.6,0.5l-1.6,0.5l-1.6,0l-8,1.1L468.4,450.3z M463.2,452.3l-0.7-1l2.1-1.5l1.9,0.9
                l0.4,0.7l-2.5,0.5l-0.4-0.4h-0.5L463.2,452.3z"/>
              <path id="BR-RN" class="st0" d="M609.3,182.9l-0.9,0.2l-3.5-0.2l-1,0.4l-2.5-1.1l-2.2-0.3l-5.8,0.4l-0.5-0.4l-3.4-0.4l-0.6-0.5
                l0.1-1l-0.4-0.4l-1.4,0.1l-1.8,1.4l-0.3,1.5l0.7,0.8l0.5,1.5l-1-0.3l-0.5,0.6l0.2,1.4l0.5,0.9l-0.5,1.2l-0.5,0.7l-2.3,0.9l-0.8-0.7
                l0.5-1.5l-0.9-1.2l-2-0.3l-1.8,0.1l-3.2,1.3l-0.3-0.2l-0.3-1.4l-0.3-0.2l-3.5-0.5l0.1-1.7l0.2-0.3l0.9-0.2l0.4-0.4l0.4-1.7l0.4-1
                l3-3.2l0-1.2l-0.5-0.4l-0.6-0.1l-3.3,1l-1.4,0.1l-3.2,1.2l-0.4,0.9l-0.1,1l-0.8,0.7l-3.2,1.7l-1.2,1.1l-2.9-0.6l-0.9-0.4l-1.9-1.6
                l-0.6,0.5l0,0l-0.7,0.4l-0.6-0.2l0.3-2l1.4-2.2l0.8-0.9l0.5-0.1l0.8,0.5l1-0.3l0.6-1.1l1.5-1.5l1.4-2.4l-0.2-0.7l0.7-1.7l0.9-0.6
                l0.8-1.1l3.3-6.7l2.6-2l4.9-1.6l0,0l0.5,0.8l0.9,0.8l3.1,0l1.2,0.4l1.7,1.8l1.3,0.5l3.3-0.3l2.9,0.5l2.5-0.2l2.3-0.7l4.5,0.9l1.3,0
                l2,0.9l1,0.8l0.6,0.9l2.3,4.7l0.5,1.8l0,0.8l1.5,5.4l0.2,1.7l0.7,0.5l1,2.7L609.3,182.9z"/>
              <path id="BR-RO" class="st0" d="M187.3,291l-1.3-1.5l-0.4-1.9l-1.6-0.5l-0.9,0.3l-1.8,0.1l-4.1-1.8l0-0.4l-0.7-0.6l-1.3,0.6l-2-1.7
                l-1.5-1l-0.9-1.5l-0.3-1.4l-1-0.4l-1.8,1.1l-1.2,0l-1.4-0.8l-0.9-1.3l-3.1-1.6l-2-0.2l-1.1,0.5l-0.9,0.9l-1.4-0.2l-0.3-0.3
                l-2.1-0.5l-3.1-0.3l-0.8-0.4l-1.2-1.2l-0.3-2l-1.6-0.5l-1.9-1l-0.8-1.7l-0.3-0.2l-2.7-0.3l-0.4-0.4l-0.8-3.7l-0.9-0.9l-0.5,1.2
                l-0.7-0.3l-0.3-0.6l0.6-1.5l-0.8-1.5l-1.4-0.4l-1.3-3.5l-0.2-1.7l1.6-2.9l-0.2-1.7l-1.2-2l-0.8-2.2l-0.1-2.3l0.6-0.5l0.4-1.4l1-1
                l0.4-1.5l-0.2-1.5l-0.4-0.9l-0.1-1.5l0.5-1.6l-1.2-2.2l-1-0.4l-1.1,1l-0.1,0.5l-0.8,1h-0.6l-2.2-1l-5.2,0.1l-5.6,1.8l-1.7,0
                l-1.5,0.5l0,0l-3-1.3l0,0l3.3-2.6l1.9-0.6l1.5-1.8l0.2-0.6l-0.2-1.3l4,0.3l2.9-0.3l0.7,0.4l2.3,2.3l0.4,0l1.2-0.8l0.1-0.7l0.4-0.5
                l1.1-0.5l0.8,0.1l1.4-0.4l1.3-1.2l1.8-1l0.4,0.1l0.6,2.3l0.7,0.5l0.7-0.1l2.5-3.2l-0.3-1.8l0.5-0.8l1.8-1.3l0.5,0l0.8,0.5l1.8,0.1
                l1.2-1.1l2.1-0.4l3.9,0.5l-0.2-1.5l0.1-1.8l2.1-1.2l0.8-1.1l0.4-1l-0.2-0.4l-0.9-0.7l0.1-0.7l1.2-1.6l2.5-0.5l0.3-0.2l-0.1-1.2
                l1.7-0.5l1.4-2.6l10.8-0.1l2.1,1.1l0.4,0.5l0.8,1.9l1.3,1.3l2.5,1l1.2,3.3l0.5,0.2l1-0.1l0.6,0.2l0.7,2.9l3.2,1.5l0.7-0.2l0.5-1.7
                l1.9-1.1l1.4,0.3l0.4,0.4l0,0.7l0,0l1.4,1l0.6,1.2l-0.8,1.4l-1.3,5l0.1,0.3l0.8,0.5l0.3,0.6l0.2,1.4l0.8,2.3l-0.9,1.7l0.4,1.9
                l-1,3.5l0,0.8l0.3,1.8l1.5,2.8l-0.9,9l17.2,0.1l-0.2,0.6l1.2,1.1l3.5-0.1l1.1,0.1l1.1,0.4l1.4,3.6l-0.2,0.8l-1.4,1.8l-1.4,1.1
                l-0.1,1.3l0.4,3.2l0.6,0.5l0.9,0l1.5,3.3l0,2.1l0.6,1.5l-0.1,1.7l-0.6,2.2l-1.7,2l-0.5,2.2l-0.6,0.8l-0.4,0.4l-1.6,0.5l-0.9,1.1
                l-1.4,3.8l-0.5,2.4l-4.1,2.6L207,296l0,0l-0.7,0l-2.6-1.6l-1.3-1.4l-7.1,1l-1.3-0.4l-0.2-0.3l-3.4,0.7l-0.5-0.2l-0.5-1.4L187.3,291
                z"/>
              <path id="BR-RR" class="st0" d="M234.6,61.4l1.6,17l0,0l-18.5-0.1l0,0.7l-2.3,3.3l-1.7,3l-0.1,1.1l-1.2,2.6l-0.1,0.7l0.2,0.7
                l1.1,1.4l0,0.5l-0.4,0.4l-2,0.5l-1.4,1.4l-1.2,0.5l-1.8-0.2l-0.9-2.5l-1.9-2l-2.8-0.9l-1.6,0.1l-0.6,0.9l-2.1,0.7l-1.3,0.7l-1,1.3
                l-0.8,3l0.4,1.8l-0.2,1.7l-0.8,2.3l0.4,2.1l-2.4-1l-1.3,0.4l-0.5-0.1l-1-2l-1.5-1.7l-2-1.3l-0.5,0l-5-4.4l0.1-1.1l1.8,0.3l1.1-1.3
                l0.1-0.6l-1.1-4.3l-0.6-0.9l-1.2-0.8l-0.9-2.2l-0.3-5l0.6-1l0.8-3l-0.7-1.6l-0.4-1.6l1.6-1.5l-0.1-0.4l-0.9-1.1l-0.2-2.3l-1.7-4.8
                l-2.7-3.7l0.7-1.5l0.9-3.3l-0.5-0.7l-1.9-1l-1.4-0.1l-0.9,0.2l-1-0.4l-1.2-1.2l-0.1-1L169,48l-1.1,0.4l-0.6,0l-0.8-0.3l-0.7-0.8
                l0,0l0.3-0.5l0.2-2.3l-0.3-0.2l-3.4-0.2l-3.8,0.1l-3.3-0.7l-0.2-0.6l0.8-1.8l0.1-1.5l-0.7-1.8l-1.9-3.5l-0.7-2.1l-0.5-3.7l0.8-1.5
                l-0.1-1l-1.8-2l-2.2-1.1l-2.8-2.8l-1.1-1.8l-0.3-1l-1.1-0.6l-0.2-0.4l0-0.8l0.3-0.3l0.9,0.1l1.1,0.7l0.9,1.7l0.6,0.1l3.4-0.5
                l1.3,0.1l1.9,0.6l0.6,1.2l0.6,1.9l0.5,0.4l1-0.1l0.7-0.6l0.9-0.4l3.2,0.2l0.5,0l1.3,1.2l0.6,0.1l0.5-0.1l0.6-1.6l1.3,0.1l1.4,0.9
                l3.9,4.6l1.1,0.6l0.7,0.1l1.5-0.7l0.7-1.1L176,24l-0.8-2.8l0.4-1.7l0.5-0.3l1.9-0.1l1-1.1l1.6-1.1l0.8,0.1l3.9,1.3l0.6-0.2l0.8-0.7
                l1-0.3l1.1,0.4l1-0.2l1.3-1.3l1-0.2l1.2,0.2l1.1-0.1l0.7-0.9l0.1-1.4l3-1.8l2.3,0.2l2.8-0.4l0.9-1.1l0.1-0.7l0.6-1.1l0.8-0.1
                l1.8-0.9l1.1-1l1.1-1.7l-1.2-3.6l-1.2-0.4l0.6-0.2l1.4,0.1l0.8,0.3l3.6-0.1l1.1-0.9l0.7-0.2l1.4,0.4l0.5,1.3l1,0.8l0.5,1.4
                l-0.7,4.9l-0.7,1.4l-1.2,0.7l0.1,0.8l1.6,0.4l1.6-0.2l2.3,0.8l1.8,1.1l-0.9,1.4l0,1.1l2.3,3.4l0.2,1.6l-1.5,2.9l-1.9,1.4l-0.7,1.5
                l0.3,0.4l0.1,2.2l-1.5,2.4l-0.9,2.8l-0.3,2.6l-0.1,2.7l1,1.9l0.5,1.8l0,1.4l1.6,1l0.9,0.2l0.1,0.7l-0.5,5.1l0.1,0.7l1.8,0.3
                l-0.7,1.2l0.5,0.5l2.1,0.7l1.5,1.9l2.7,2.7L234.6,61.4z"/>
              <path id="BR-RS" class="st0" d="M322,638.5l-0.4,0l-1.9-1.1l-0.2-0.5l0.5-4.3l-0.5-4.2l0.2-1.1l0.6-1.2l2.8-2.4l0.8-1.6l2.3-2.6
                l-1.5-1.7l-1.1-0.6l-2.2-0.6l-2.3-2.2l-1.2-1.6l-0.1-1.6l-0.4-1.2l-1.2-2l-2.1-2.2l-0.7-0.6l-2.4-1.2l-1.4,0.2l-1.9-1.5l-1.5-1.6
                l-1.2-0.8l-0.1-1.7l-1.9-2.2l-3.3-0.5l-0.9-0.5l-1.7-1.7l-1.8,0.1l-2.2-1.4l-1.9-3.7l-1.1-1.5l-2.5-2.1l-0.7,0.1l-0.7,1.8l-2.6,2.2
                l-2.7,0.1l0.1-2.9l0.4-1.3l-0.4-1l-3.7-4.3l-2.4-1.8l-2.2-2.1l-1.8-2.1l-2.7-2.2l-1.9-0.1l-2.1,0.2l-1.1,1.4l-0.1,1l-1,1l-1.1,0
                l-0.6-0.5l-3.2,0.1l-0.6-0.5l-0.2-0.9l-0.5-0.2l0.9-0.2l1-0.7l2.5-2.8l0.1-1.8l0.6-1.2l0.8-0.4l1.3-0.1l0.6-0.3l0.9-1l5.4-6.5
                l0.6-1.4l0.2-1.1l0.8-1.1l1.2-0.8l1.4-0.4l2-4.2l0.1-0.6l0.3-0.4l1.6-0.9l1.6-1.8l0.8-1.2l0.5-1.3l1.3-2.1l0.9-0.3l1.6,0.2l0.6,0.8
                l0.5-1.3l-0.2-0.6l-1.3-0.8l-0.1-0.4l0.4-0.3l1.1-0.4l0.8-1l2.8-1l1-1.1l0.1-0.9l0.8-0.9l2.2-1.2l2.5-0.4l1.8-1.8l0.2-1.1l1.3-2.4
                l0.3,0l0,0.4l0.3,0.2l1.3-0.2l2.4-1.1l2.7-0.6l1.2-0.6l2.1-2.3l0.5,0l0.7-0.4l1-1.3l2.3-0.7l0.3,0.4l0,0l3.5,0.4l3.3-1.2l0.6-0.8
                l1.4,0.6l-0.1,0.9l3.1,0l1.6,1l2.6-0.4l1.9,1.1l2.6-0.4l1.5,0.2l2.8-0.2l2.3,0.9l2.5,1.4l1,2.4l1.6,0.2l0.1-0.5l1.8-0.3l5.6,3.5
                l1.4,1.5l0.7,0l1.6,1l3,3.5l1.6,2.2l0.5,1.5l2.2,2.7l1.2,0.5l6.7,1.1l2.1-0.4l3.4,0.3l0.9,2.3l-2.1,1.6l-1.3,0.4l-0.7,1.9l0.2,2.2
                l-0.4,1.6l-0.5,1.3l-1.2,0.2l-1.3,1.7l0,1.4l1.8,1.2l0.3-0.2l-0.3-1l0-1l1.5-0.8l1.6,0.6l0.7,0.9l1.2,0.8l0.4,0l0,0l-1.4,1.7
                l-3.9,7l-4.2,11.6l-6.3,10.5l-5.4,6.7l-6.5,6l-6.1,3.7l-3.1,4.3l-0.2-0.4l0.6-1.6l0.3-2.2l-1.2-1.7l0.1-0.4l0.9-0.2l1,0.4l0.6,0.6
                l1,0l2.6-1.4l0.8-0.7l2.9-3.7l0.8-0.7l0.4,0.2l1-0.2l1-0.8l0.9-1.3l0.4-1.5l0.2-0.9l-0.2-2.1l0.2-1.4l1.6-0.1l0.7,0.3l0.3,0.8
                l0.4-0.3l-0.3-1.4l0.5-2.1l0.5-0.6l2.7-1.6l0.7-1.1l0.5-2.8l-0.2-2.3l0.6-0.7l0.5,0.8l0.4,0l0.4-0.4l0.5-3.1l-0.1-0.4l-1-1.2
                l-0.7,0l-0.3,0.7l0.2,0.4l-0.1,0.6l-0.4,0.2l-1.5,0l-2,0.4l-0.3,1.6l-0.5,0l-0.8-0.4l0.1-1.7l-0.8-0.9l-1,0.3l-0.6-0.5l-0.7-1.3
                l-0.4-1.4l0.1-0.6l-0.6-0.7l-0.6,2.7l0.4,2.2l1.2,2.1l-0.6,0.8l-0.7,2.5l0.4,3l-0.2,0.8l-0.5-0.4l0.3-0.2l0-0.7l-0.5-1.3l-0.5-0.4
                l-0.5,0.7l0,1.4l-1.4,3.3l0,1.9l0.2,0.5l-0.7,0.7l-1.6,0.6l-0.6,1.6l-4.1,1.6l-0.7,0.5l-0.5,1.6l-0.1,2.3l-1,2.3l-2.4,1.3l-0.1,1.8
                l0.5,0.8l0.2,1l-1,0.6l-0.2,1.5l0.4,0.6l0.7-0.1l1.3,0.6l0.1,0.9l-2.1,1.6l-2.3,4.5l-1.9,6.9l-2,4.5l-1.7,2.5l-7.8,7.5L322,638.5z"
                />
              <path id="BR-SC" class="st0" d="M379.3,558l-0.5,0l-1.2-0.8l-0.7-0.9l-1.6-0.6l-1.5,0.8l0,1l0.3,1l-0.3,0.2l-1.8-1.2l0-1.4l1.3-1.7
                l1.2-0.2l0.5-1.3l0.4-1.5l-0.2-2.2l0.7-1.9l1.3-0.4l2.1-1.6l-0.9-2.3l-3.4-0.3l-2.1,0.4l-6.7-1.1l-1.2-0.5l-2.2-2.7l-0.5-1.5
                l-1.6-2.2l-3-3.5l-1.6-1l-0.7,0l-1.4-1.5l-5.6-3.5l-1.8,0.3l-0.1,0.5l-1.6-0.2l-1-2.4l-2.5-1.4l-2.3-0.9l-2.8,0.2l-1.5-0.2
                l-2.6,0.4l-1.9-1.2l-2.6,0.4l-1.6-1l-3.1,0l0.1-0.9l-1.4-0.6l-0.6,0.8l-3.3,1.2l-3.5-0.4l0,0l1.5-2.9l0.7-2.1l-0.6-3.2l0.4-3.2
                l0-2.5l1-1.8l0,0l3.6,0l0.7-0.4l0.5,0l1.1,0.2l0.3,0.5l2,1.4l2.7,0l2.3-0.6l5.3,1.7l4.3,0.4l1,0.6l0.2,0.4l1.6,1.2l3.8,0.4l4.2-0.1
                l1.3,1.9l0.2-0.5l0.7-0.4l0.9,0l0.9-0.9l-0.9-3l0.7-1.6l0.7-0.8l2-1.1l3.6,0.4l1.9-1l0.7-0.9l0.6-1.5l1.5-0.6l1.1-0.1l1.4,0.8
                l0.8,0l2.3-0.2l2.8-0.7l0.8,0l1,0.4l3.1,2.6l1.2,0.6l1.4,0.2l3.4-2.1l1.4-1.4l1.6-0.6l0.1,0.4l0.8,0.1l1.7-0.2l0.3-0.4l5.2,0l0,0
                l-0.4,1.3l0.5,1.6l-0.1,0.4l-1.5,1.1l-1-0.5l-0.8-2.5l0,1.1l0.6,2.7l1.7,1.7l0.6,1l-1,2.3l-0.2,2.3l0.1,0.4l0.8,0.7l0.3,5.9
                l0.3,0.5l0.4,0.2l0.8-0.5l0.4,0.7l0.1,0.9l-0.5,0.1l-0.4-0.3l-1.2,0.6l-0.3,3.8l0.6,2.6l-0.6,0.8l0.3,1.9l0.9,1.9l-0.9,2.3l-0.5,4
                l-1.7,4.7l-0.3-0.1l-0.4-0.8l0.2-1l-0.9-1.2l-0.3,0.3l0.2,2.4l1.2,0.8l-0.9,1.7l-1.6,0.7l-4.1,3l-2.8,2.6l-3.8,4.4L379.3,558z
                M397.2,531.5L397,531l0.2-1.8l0.8-2.4l-0.4-1.2l0.2-1l1.4-0.8l0.2,0.1l0.7,0.9l-0.5,2.6l-0.8,0.9l-0.6,1.2l0.2,1.2L397.2,531.5z
                M396.7,507.1l-1.9-2l2.5-2.4l0.2,0l0.6,1l-0.9,1.3L396.7,507.1z"/>
              <path id="BR-SE" class="st0" d="M569.9,261.7l-2.9,0.9l-1.5-0.6l-0.5-0.3l-0.5-0.7l-1.9-0.9l-0.6-0.5l-0.1-0.4l0.3-0.4l0.1-1.4
                l-0.1-0.4l-1.4-1.5l-0.5-1.5l-1.1-0.7l-1-1.6l0-0.8l0.6-1.6l0.3-0.3l1.7,0l1.1,0.8l2.5-1l0.7-0.5l0.3-0.6l-0.8-2.8l0.1-0.9l0.7-1.6
                l0.1-3.1l-0.8-1.2l-2-1.9l-1.3-2.5l-0.2-2.1l0.7-1.1l0,0l6.5,3l3.7,0.9l5.4,3.1l1.3,2.4l1.8,1.4l1.7,0.8l0.5,0l3.6,2.2l0.6,1.3l0,0
                l-2.4,0.8l-4.6,2.9l-1,0.9l-1.5,2.2l-0.3,0l0.1-1.1l-1-1.8l-1,0.2l0.3,1.2h1.2l0.2,0.2l-0.5,2l-1.3,2.1l-0.5-0.3l-0.4-0.7l-1-0.2
                l0.2,0.7l1.1,0.6l-0.3,1.8l-0.9,1.1l-0.9,2l-0.8,0.2l-0.3-0.5l0.5-1.4l0.4-0.1l0.3-0.4l0.4-1l-0.8-0.4l0.1,0.7l-0.5,1.1l-0.5,0.5
                l-0.3,1.1l0.3,0.7l-0.3,0.5L569.9,261.7z"/>
              <path id="BR-SP" onMouseOver={this.togglespPopover.bind(this)} onMouseOut={this.togglespPopover.bind(this)} class="st0" d="M404.6,487.9l-0.2-1.3L403,486l-0.7-3.3l0.4-2l-2.1-0.6l-0.8,0.2l-1.1,2.8L398,484l-1.1-0.5
                l-0.4-0.8l0.7-3.3l0.9-0.7l0.1-0.4l-1.6-1.3l-1.6-0.1l-1.6,0.2l-0.5-0.4l-3.4,0.5l-2.4,0.1l-1-0.2l-0.6-0.4l-0.3-2l1.3-2.1l0.2-1
                l-0.2-0.4l-0.6-0.3l-0.9-2l0.1-0.9l-0.5-0.7L384,467l-1.2-1.1l-1.5-3l0.4-0.5l0.1-1.8l-1.3-1.5l-0.4-1.8l0.2-0.8l0.4-0.6l0-2.7
                l-0.9-1.8l-3.6-2.8l-1.1-1.5l-3,0.9l-5.3-0.1l-0.7-0.5l-1.6-0.1l-0.4,0.3l-1.7-0.1l-0.7-1.3l-0.6-0.5l-3.5-0.8l-1.8-0.8l-0.8-1
                l-2.6,0l-1.1,0.5l-1.3-0.1l-2.6-1.1l-2.5-0.5l-1.5-0.8l-1.2-0.3l-0.7,0.4l-0.3,0.6l-1.2,0.6l-3.3-0.3l-4,0.1l-3.8-0.6l-3.2,2.3l0,0
                l0.8-1.1l0.7-1.5l1.3-1.1l7.6-4.5l2-1.9l1.3-2.4l1.7-1.7l0.4-1.2l1.4-1l0.3-1.2l-0.4-0.8l2-2.3l1.2-2.3l0.1-0.9l-0.3-1.9l0.2-0.4
                l1-0.5l2.6-3.6l0.2-2.9l0.6-1.7l1-0.3l0.7-0.6l2-2.7l1.2-1l0.5-0.2l1.3,0l1.5-0.8l0.3-0.4l0.4-2.5l0,0l1.2-1.1l2.1-1.1l1.9-0.4
                l1.4-1.7l1.5-0.6l0.8,0.1l1.7,1.5l2.5,0.1l4.3,1l1.7-0.4l1.6,0.2l2-0.6l2.2,1.4l1.7,0.8l-0.1,0.9l0.3,1.4l1.2,2.1l0.6,0l0.5-0.3
                l0.7-0.9l0.4-1.1l0.3-0.2l0.5,0l0.4,0.4l0.2,1l0,2.8l1.2,0.5l0.4-0.5l-0.3-2.5l0.5-1.2l0.5-0.5l8.2-0.7l1-1.2l1.5,1.5l1.1,0.4
                l2.8-0.7l0.4-1l-0.2-0.5l0.3-0.6l2.4-0.1l1.7,0.8l0.9-0.6l1.3-0.3l3.3,3.4l0.2,1l-0.6,1l-0.3,2.4l0.6,0.6l1.9,1.2l0.4,1.5l-0.1,0.6
                l-1.5,2l-0.2,1.9l0.8,0.7l0.3,0.6l0.4,2.2l1.3,1.8l0.8,2l0,0.9l1.6,0l0.8-0.3l0.3-0.4l1,0l3.7,1.3l0.2,0.4l0,1.3l-0.7,2.2l-1.9,2.7
                l0.3,2.9l-0.2,3.5l-0.5,1.6l0.7,1.6l1.8,1.2l1.8,0.8l0.5,1.7l-1.2,0.7l2,3.3l1.1,0.2l2-0.2l0.2,0.5l3.6-1l0.6,0.4l1.3-0.3l0.8-1
                l0-0.8l-0.2-0.2l0.2-2.3l1.2-0.3l0.1,0.4l-0.4,0.5l0,0.5l2.3,0.1l2.4-0.2l1.8-0.7l1.2-1.2l1.9-1l2.2-0.2l1.2-0.7l0,0l1.3,0.5
                l0.6,1.2l1.2,1.6l1.8,0.4l3.1-0.5l1.7,0l0.8,1.6l-1.6,2.1l-1.5,0.7l-2.8,0.4l-0.5-0.1l-2.4,1l-1.1,1.1l-1.1,3.5l0,0.4l1.6,1.7
                l0.5,0.3l0,0l-0.1,0.2l-1.7,0.4l-1.1-0.8l-1.5,1.1l-0.4,0.6l0.3,0.2l-0.8,0.6l-3.2,1.5l-2.2,0.7l-0.3,0.4l0,1l0.5,1.5l-0.3,0.4
                l-1.4,0.3l-0.7-0.6l-1.3-0.3l-3.1-0.5l-0.9,0.1l-1.3,0.3l-1.7,0.8l-0.5,0.5l-1.2,2.1l-1.3,0.6l-0.5-0.1l0.6-0.5l-0.5-1.2l-1.2-0.9
                l-0.8,1.2l0.3,0.8l-0.3,0.6l-7.6,4.2l-1,0.8l-0.3,1.2l-3.3,3l-3.2,2l-1.9,0.5l-4.5,3.2l-2.3,2l-0.7,0.1l-0.4,0.6l0.9,0.4l0.7,0.1
                l0.9-0.4v0.8l-0.6,1.3l-1.3,0.9L404.6,487.9z M448.9,464.9l-1.5-0.6l-1.2,0.2l-0.5-0.4l0.1-0.8l1-0.6l1.1-1.9l0.7,0.2l0.7,0.6
                l0.1,2.9L448.9,464.9z"/>
              <path id="BR-TO" class="st0" d="M433.4,281.8l-5.4,1.4l-1.7,1.3l-2.6,1.1h-1.5l-4.5,1l-2.5,2.1l-1.8,0.9l-0.5-0.2l-1.5-2.6l-0.5,0
                l-0.5,3.3l-0.7,0.1l-3.4-0.7l-3.1-1.5l0.6-1.6l-0.2-0.7l-4.5,0.5l-2.8-0.3l-0.6,0.5l-0.3,1l-0.8,0.8l-1.1,0l-0.6-3.1l-1.1-3.7
                l-1-1.9l-1.1-1.3l-1.2,1l-1.5,2.4l-1.6,5.9l-0.6,0.2l-4.1-1.4l-3.1-1.7l-3.9-0.4l-2.2-1l-2.4-0.4l0.5-2.3l1.2-1.9l0-1.7l-0.6,0.3
                l-1.8,2.1l-0.7,1.2l-0.2,1.9l-0.3,0.4l0,0l-0.8,0l-1-0.5l-1-3.3l0.7-2.8l-0.4-3l-0.5-0.6l0-1.6l0.5-3.3l-1.1-2.2l0-0.5l0.8-0.9
                l0.2-0.9l-1.4-1.3l-0.1-0.9l1.9-6l0-2.9l0.5-3l1.9-5.3l0.4-0.3l0.4-0.9l0.4-3.1l1.1-1.2l0.6-1.9l0.6-1l0,0l1.6-3.7l1.1-4.4l1.1-1.6
                l1.2-1.1l1.2-1.7l1-1.9l1.4-0.9l1-0.2l0.7-0.9l0.9-1.5l0.2-1l1.7-3.2l1.1-0.8l1.8-5.2l0.7-4l-0.1-0.3l-0.7-0.3l-1.7-1.7l-0.6-0.9
                l-0.2-0.9l0.1-0.6l1.4-1.7l1.3-2.1l-0.1-2.7l-0.4-2.2l0.2-0.3l2.5-1.8l3.4-1.1l2.5-1.2l0.2-0.5l-0.1-1.5l0.9-1.3l1.5-1.3l1.3-0.2
                l0.2-1.3l-0.3-1.2l0.2-0.5l1.5-0.5l0.6-0.5l0.9-2.8l-1-1.3l-0.1-1.4l0.3-0.3l1-0.1l0.7-0.4l0.4-0.7l0-0.6l-1.3-1.2l-0.9-0.1
                l-0.4-0.3l-1-1.7l-1.3-0.1l-1.9,0.2l-2.3-0.8l0,0l0.5-0.6l2.6-1.8l2-0.5l0.9,0.1l2.8,1.5l1.4,0.1l1-0.5l1.7,0.3l0.6,0.7l0.1,1
                l0.3,0.3l1.2,0l3.4,1.4l0.8,1l0.5,3.5l0.6,1.5l-0.2,3.7l0.2,1.1l0.7,1.1l0,0.4l-1.8,6.1l-0.3,2.4l0.3,1l-0.2,1.6l-1.8,2l-1.8,0.7
                l-0.2,0.7l1.9,1.3l0.4-0.3l1.4,0.2l0.2,0.4l0.1,0.9l-0.6,1.2l0.9,1.4l0.9-0.1l3,4.2l0.9,0.8l2.3,3l2.1-1.3l4.3-0.9l1.8,1.2l0.1,1.8
                l-0.6,3.2l0.4,1.3l-3.4,0.4l-1.3,0.5l-0.6,0.6l-0.7,1.4l-0.7,2.7l0.4,1.3l-2.6,2.6l-0.3,0.7l0.2,0.4l2.1,0.3l0.9,0.7l0.7,1.6
                l0.1,1.7l0.6,1l3.9,1.9l0.1,0.5l-1.6,1.8l0.1,1.7l2.1,1.6l1,2.9l1.3,2l0.7,0.2l1.8-0.2l1,0.3l0.8,0.4l0.6,0.8l1.3,0.7l0,0l2.9,0.2
                l0,0l-0.7,0.6l-0.2,1l-0.6,0.5l-3.8,2l-3.2,2.6l-0.2,0.4l1.3,1.8l-0.4,0.4l-1,0.3l-0.9,0.6l-1.5,3.5l-1,0.7l-0.5,1.2l0.2,0.9
                l1.9,1.9l3.6,0.7l1.9,1l0,0.8l-0.6,0.5l-2,0.8l-0.4,1l0.1,0.5l0.4,0.3l1-0.2l0.9,0.3l0.6,0.5l0.3,0.6l-1,0.7l-1.1,0.4l-2,1.7
                l-0.2,0.9l0,2.9l0.3,1.2l0.9,0.7l1.1,0.2l0.4,0.2l0.2,0.4l0.1,1.1l-1.4,2.4L433.4,281.8z"/>
            </g>
          </svg>
        </center>
        <PopoverMap elaboradores="1000" ibge="30" estado="MG" isOpen={this.state.mgPopoverIsOpen} target="BR-MG" />
        <PopoverMap elaboradores="3000" ibge="31" estado="SP" isOpen={this.state.spPopoverIsOpen} target="BR-SP" />
      </div>
    );
  }
}
