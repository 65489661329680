import React from 'react';
import PropTypes from 'prop-types';
import MultiSelect from './inputs/InputMultiSelect';

const MultiSelectSection = ({
  label, description, field, items,
}) => (
  <>
    <div className="card-header bg-white pb-0">
      <h1>{label}</h1>
      <p className="mb-0">{description}</p>
    </div>
    <div className="card-body bg-white card-body-form">
      <MultiSelect
        items={items}
        field={field}
      />
    </div>
  </>
);

MultiSelectSection.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  field: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

MultiSelectSection.defaultProps = {
  description: null,
};

export default MultiSelectSection;
