import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import CircularProgressbar from 'react-circular-progressbar';
import { get, map } from 'lodash';
import axios from 'axios';
import 'react-circular-progressbar/dist/styles.css';
import HomeCard from '../../containers/HomeCard';
import Loading from '../../components/Loading';
import { API_HOST } from '../../consts';
import Ruler from '../../components/Ruler';
import AddButton from '../../components/buttons/AddButton';

import {
  ItemTotal,
  ItemTotalCalibrado,
} from './styles';

const LIMITE_AREAS_CONHECIMENTO = 10;

export default class HomePage extends Component {
  state = {
    itens: [],
    mostrarTodasAreasConhecimento: false,
    fetchingItens: true,
    encomendas: [],
  }

  componentDidMount() {
    this.fetchItens();
    this.fetchEncomendas();
  }

  fetchItens() {
    axios.get(`${API_HOST}/dashboards/itens`)
      .then((response) => {
        this.setState({ itens: response.data, fetchingItens: false });
      });
  }

  fetchEncomendas() {
    axios.get(`${API_HOST}/encomenda/encomenda/dashboard`)
      .then((response) => {
        this.setState({ encomendas: response.data });
      });
  }

  mostrarAreasConhecimento = () => {
    const { mostrarTodasAreasConhecimento } = this.state;
    this.setState({ mostrarTodasAreasConhecimento: !mostrarTodasAreasConhecimento });
  }

  renderItens() {
    const { itens, mostrarTodasAreasConhecimento, fetchingItens } = this.state;
    const exames = get(itens, 'exames');
    const total = get(itens, 'total');
    const calibrados = get(itens, 'calibrados');
    let areas_conhecimento = get(itens, 'areas_conhecimento') || [];

    if (!mostrarTodasAreasConhecimento) {
      areas_conhecimento = areas_conhecimento.slice(0, LIMITE_AREAS_CONHECIMENTO);
    }

    return (
      <HomeCard icon="" title="Itens" ref={(node) => { this.itensCard = node; }}>
        {
          fetchingItens
          ? <Loading />
          : (
            <Fragment>
              <div className="row">
                <div className="col-sm-6">
                  <div className="animated fadeIn">
                    <ItemTotal>
                      <label>Total</label>
                      <h3>{total}</h3>
                    </ItemTotal>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="animated fadeIn">
                    <ItemTotalCalibrado>
                      <label style={{ color: 'white' }}>Calibrados</label>
                      <Ruler />
                      <h3>{calibrados}</h3>
                    </ItemTotalCalibrado>
                  </div>
                </div>
              </div>
              <h3 className="mt-3 mb-12">Áreas de conhecimento</h3>
              <div className="row">
                <div className="col-sm-6 areas-conhecimento">

                  {
                    map(areas_conhecimento, a => (
                      <div key={a.descricao}>
                        <div><p>{a.descricao} <span className="pull-right"><b>{a.itens} itens</b></span></p></div>
                        <div className="progress">
                          <div className="progress-bar" role="progressbar" aria-valuenow={a.porcentagem * 100} aria-valuemin="0" aria-valuemax="100" style={{ width: `${a.porcentagem * 100}%` }} />
                        </div>
                      </div>
                    ))
                  }

                  <AddButton
                    type="button"
                    onClick={this.mostrarAreasConhecimento}
                    className="btn primary-button mt-3"
                    icon={mostrarTodasAreasConhecimento ? 'chevron-up' : 'chevron-down'}
                    title={mostrarTodasAreasConhecimento ? 'Mostrar menos' : 'Mostrar mais'}
                  />

                </div>
              </div>
              <hr />
              <h3 className="mb-3">Exames</h3>
              <div className="row">
                {map(exames, e => (
                  <div key={e.porcentagem} className="col-sm-3">
                    <div className="circle-chart-total">
                      <CircularProgressbar
                        percentage={e.porcentagem * 100}
                        initialAnimation
                        styles={{
                          path: { stroke: '#45C0F5' },
                        }}
                        textForPercentage={() => e.itens}
                      />
                      <p className="text-center">{e.descricao}</p>
                    </div>
                  </div>
                ))}
              </div>
            </Fragment>
          )
        }
      </HomeCard>
    );
  }

  render() {
    const { encomendas } = this.state;

    return (
      <div className="container dashboard-main">
        <div className="row">
          <div className="col-sm-12">
            {this.renderItens()}
          </div>
          {/*
            <Col sm="6">
              <HomeCard icon="users" title="Elaboradores">
                <Row>
                  <Col sm="6">
                    <div className="circle-total-graphic">
                      <h5>4000</h5>
                      <label>Elaboradores</label>
                    </div>
                    <div className="rating">
                      <div className="rating-stars">
                        <h5>2000</h5>
                        <StarRatingComponent
                          name={'ranking1'}
                          value={5}
                          starColor={'#0FA3B6'}
                          emptyStarColor={String}
                          editing={false}
                        />
                      </div>
                      <div className="rating-stars">
                        <h5>1000</h5>
                        <StarRatingComponent
                          name={'ranking1'}
                          value={4}
                          starColor={'#89B218'}
                          emptyStarColor={String}
                          editing={false}
                        />
                      </div>
                      <div className="rating-stars">
                        <h5>1000</h5>
                        <StarRatingComponent
                          name={'ranking1'}
                          value={3}
                          starColor={'#DE6F29'}
                          emptyStarColor={String}
                          editing={false}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col sm="6">
                    <Row>
                      <Col sm="6">
                        <div className="circle-chart-total">
                          <CircularProgressbar
                            percentage={50}
                            initialAnimation={true}
                            styles= {{
                              path: { stroke: "#7726D5"}
                            }}
                            textForPercentage={() => 2000}
                          />
                          <p>Linguagens, códigos e suas tecnologias</p>
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="circle-chart-total">
                          <CircularProgressbar
                            percentage={10}
                            initialAnimation={true}
                            styles= {{
                              path: { stroke: "#BC0074"}
                            }}
                            textForPercentage={() => 400}
                          />
                          <p>Ciências da Natureza e suas tecnologias</p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6">
                        <div className="circle-chart-total">
                          <CircularProgressbar
                            percentage={20}
                            initialAnimation={true}
                            styles= {{
                              path: { stroke: "#0054C7"}
                            }}
                            textForPercentage={() => 800}
                          />
                          <p>Matemática e suas tecnologias</p>
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="circle-chart-total">
                          <CircularProgressbar
                            percentage={20}
                            initialAnimation={true}
                            styles={{
                              path: { stroke: "#F5BF45" },
                            }}
                            textForPercentage={() => 800}
                          />
                          <p>Ciências Humanas e suas tecnologias</p>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </HomeCard>
          </Col>
        */}
          <div className="col-sm-12">
            <HomeCard icon="newspaper-o" title="Encomendas">
              <table className="table">
                <thead>
                  <tr>
                    <th>Título</th>
                    <th>Entregues/Solicitados</th>
                    <th>Data limite</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {encomendas.map(d => (
                    <tr key={d.id}>
                      <td><Link to={`/encomendas?search=${d.id}`}>{d.nome}</Link></td>
                      <td>{d.quantidade}</td>
                      <td>{d.termino_recebimento_item || '-'}</td>
                      <td>{get(d, 'status.descricao') || '-'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/*
                <Row>
                  <Col sm="3">
                    <div className="vertical-total-graph">
                      <div className="vertical-content">
                        <h2>03</h2>
                        <label>
                          Total Vigentes
                        </label>
                      </div>
                    </div>
                  </Col>
                </Row>
              */}
            </HomeCard>
          </div>
          <div className="col-sm-6">
            <HomeCard icon="newspaper-o" title="Editais">
              <p>Nenhum edital.</p>
              {/*
              <Row>
                <Col sm="3">
                  <div className="vertical-total-graph">
                    <div className="vertical-content">
                      <h2>03</h2>
                      <label>
                        Total Vigentes
                      </label>
                    </div>
                  </div>
                </Col>
                <Col sm="9">
                  <Card>
                    <div className="animated fadeIn">
                      <NewsCard />
                    </div>
                  </Card>
                </Col>
              </Row>
            */}
            </HomeCard>
          </div>
          <div className="col-sm-6">
            <HomeCard icon="file-text-o" title="Testes">
              <p>Nenhum teste.</p>
              {/*
                <Row>
                  <Col sm="4">
                    <div className="animated fadeIn">
                      <div className="teste-tag">
                        <h5>Pré-teste ENEM</h5>
                        <label>02/06/2018</label>
                        <p>Faltam 15 dias</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              */}
            </HomeCard>
          </div>
        </div>
      </div>
    );
  }
}
