import React, { Component } from 'react';
import axios from 'axios';
import { API_HOST } from '../../consts';
import StackedBarChart from '../../components/StackedAreaChart';
import ParticipacaoDreColumn from '../../components/ParticipacaoDreColumn';
import HeaderAvalia from '../../components/HeaderAvalia';
import { get } from 'lodash';
import { Nav, NavItem } from 'reactstrap';
import styled from 'styled-components';
import { FooterAvalia } from '../../containers/global/FooterAvalia/styles';
import { ThemeContext } from '../../contexts/ThemeContext';
import Logo from '../../assets/images/logos/Logos';
import AvaliaLogo from '../../assets/images/logos/avalia-logo.png';
import MapGraph from './MapGraph';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';


class StudentAnalysis extends Component {
  static propTypes = {
    className: PropTypes.string
  };

  static defaultProps = {
    className: ''
  };

  state = {
    loading: false,
    boletimData: {}
  };

  componentDidMount() {
    this.fetchBoletimData();
  }
  componentWillUnmount() {
    this.state.loading = true;
  }

  fetchBoletimData = () => {
    const avaliaPk = this.props.match.params.id;

    axios
      .get(`${API_HOST}/avalia/boletim_mt/${avaliaPk}`)
      .then((res) => {
        this.setState({ boletimData: res.data, loading: true });
      })
      .catch((err) => toast.error('Não existem dados para a consulta realizada'));
  };

  render() {
    const { boletimData, boletimData1, loading } = this.state;
    const { className } = this.props;
    return (
      <div className={className}>
        <div className="container">
          <div className="row header-row">
            <div className="col-sm-6 col-md-6">
              <div className="col-sm-6 col-md-6">
                <Nav className="nav-logos">
                  <NavItem>
                    <figure>
                      <ThemeContext.Consumer>
                        {(theme) => (
                          <img
                            className={
                              Logo.client ? 'logo-client' : 'logo-main'
                            }
                            src={AvaliaLogo}
                            height="70"
                            style={{ opacity: '0' }}
                          />
                        )}
                      </ThemeContext.Consumer>
                    </figure>
                  </NavItem>
                </Nav>
              </div>
            </div>
          </div>
        </div>
        <div
          id="nav-expand"
          className="nav-expand"
          style={{ background: '#3C4583' }}
        >
          <Nav
            className="navbar-links"
            style={{ background: '#3C4583', border: 'none' }}
          />
        </div>
        <div style={{ marginTop: '5rem', background: '#fff' }}>
          <div className="container mb-4 pb-4">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Relatório individual do Aluno - nota e proficiência</h1>
                    <hr />
                  </div>
                  <div
                    className="card-body"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}
                  >
                    <HeaderAvalia
                      schoolName={get(boletimData, 'NM_ESCOLA')}
                      schoolYear={get(boletimData, 'NM_ETAPA', '')}
                      dreAndMunicipio={get(boletimData, 'NM_DRE')}
                      student={get(boletimData, 'NM_ALUNO', '')}
                    />
                    {loading && (
                      <StackedBarChart
                        dataDot={boletimData}
                        pontos={true}
                        useBase={false}
                      />
                    )}
                    <ParticipacaoDreColumn boletimaluno={boletimData} />
                  </div>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'right',
                      marginBottom: '1rem',
                      marginLeft: '-1rem'
                    }}
                  >
                    <button
                      className="button1"
                      onClick={() =>
                        window.open(
                          `/aluno-acertos/${this.props.match.params.id}`,
                          '_self'
                        )
                      }
                    >
                      Relatório de Habilidade
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <FooterAvalia />
      </div>
    );
  }
}

export default styled(StudentAnalysis)`
  .button1 {
    height: 40px;
    width: auto;
    border: none;
    font-size: 16px;
    color: #fff;
    border-radius: 8px;
    background: #3c4583;
    &: hover {
      background: #3f6399;
    }
  }
`;
