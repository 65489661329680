import { withRouter } from 'react-router-dom';
import BaseTeste from '../../base/BaseTeste';
import Dados from './Dados';
import Cadernos from './Cadernos';
import Blocos from './Blocos';
import Itens from './Itens';
import Confirmar from './Confirmar';

class Teste extends BaseTeste {
  constructor(props) {
    super(props);
    this.id = props.match.params.id;
  }

  urlPrefix = 'testes';
  verboseName = 'Teste';
  verboseNamePlural = 'Testes';
  itensStep = Itens;

  get steps() {
    const _steps = super.steps;
    _steps[0].component = Dados;
    _steps[1].component = Cadernos;
    _steps[2].component = Blocos;
    _steps[3].component = Confirmar;
    return _steps;
  }
}

export default withRouter(Teste);
