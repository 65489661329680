import React from 'react';
import PropTypes from 'prop-types';

class StatusColor extends React.Component {
  render() {
    const { color, text } = this.props;
    return (
      <div className="d-flex">
        <div className="ball-status mr-2" style={{ background: color }} />
        <span>{text}</span>
      </div>
    );
  }
}

StatusColor.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
};

StatusColor.defaultProps = {
  text: 'não definido',
  color: 'gray',
};

export default StatusColor;
