import React from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { get, debounce } from 'lodash';
import { toast } from 'react-toastify';
import { Form } from 'informed';
import FormContainer from '../../containers/Form';
import AddButton from '../../components/buttons/AddButton';
import DeleteButton from '../../components/DeleteButton';
import { BaseForm } from '../../base';
import {
  InputText,
  InputSpan,
} from '../../components/inputs';
import { API_HOST } from '../../consts';
import Loading from '../../components/Loading';

class ReferenciaBibliografica extends BaseForm {
  constructor(props) {
    super(props);
    this.formApis = {};
    this.add = this.add.bind(this);
    this.remove = this.remove.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.fields = [
      'titulo',
      'autor',
      'capitulo',
      'pagina_inicial',
      'pagina_final',
      'link',
    ];
    this.callApi = false;
  }

  state = {
    bibliografias: [],
    save: this.OK,
    fetchingData: true,
  }

  metadataModels = ['bibliografia']

  componentDidMount() {
    this.fetchData();
    this.fetchMetadata();
  }

  fetchData() {
    this.callApi = false;

    axios.get(`${API_HOST}/itens/bibliografia`, {
      params: {
        item: this.id,
      },
    })
      .then((response) => {
        this.setState({ bibliografias: response.data, fetchingData: false }, () => {
          response.data.forEach((x) => {
            const values = this.getValues(x);
            this.formApis[x.id].setValues(values);
          });
          setTimeout(() => { this.callApi = true; }, 1000);
        });
      });
  }

  add() {
    this.setState({ save: this.SALVANDO });

    axios.post(`${API_HOST}/itens/bibliografia`, {
      item: this.id,
    })
      .then((response) => {
        const { bibliografias } = this.state;
        bibliografias.push(response.data);
        this.setState({ bibliografias, save: this.SALVO });
      })
      .catch(() => {
        this.setState({ save: this.ERRO });
      });
  }

  remove(id) {
    this.setState({ save: this.SALVANDO });

    axios.delete(`${API_HOST}/itens/bibliografia/${id}`)
      .then(() => {
        const { bibliografias } = this.state;
        this.setState({
          bibliografias: bibliografias.filter(x => x.id !== id),
          save: this.SALVO,
        });
      })
      .catch(() => {
        this.setState({ save: this.ERRO });
      });
  }

  onValueChange = debounce((values, id) => {
    this.setState({ save: this.SALVANDO });

    axios.patch(`${API_HOST}/itens/bibliografia/${id}`, values)
      .then(() => {
        this.setState({ save: this.SALVO });
      })
      .catch(() => {
        this.setState({ save: this.ERRO });
      });
  }, 1000)

  onSubmit = (url) => {
    axios.get(`${API_HOST}/itens/item/${this.id}/validate?form=bibliografias`)
      .then(() => {
        const { history } = this.props;
        history.push(`/itens/${this.id}/${url}`);
      })
      .catch((error) => {
        this.onValidateError(error, 'bibliografias');
      });
  }

  onValidateError(error) {
    const status = get(error, 'response.status');

    if (status === 400) {
      const errors = get(error, 'response.data');
      const bibliografiaErrors = get(errors, 'bibliografias');
      const { bibliografias } = this.state;
      bibliografias.forEach((b, i) => {
        const bErrors = get(bibliografiaErrors, i);
        if (bErrors) {
          Object.keys(bErrors).forEach((be) => {
            this.formApis[b.id].setError(be, bErrors[be]);
          });
        }
      });

      this.renderErrorToast(errors);
    } else if (status !== 403) {
      toast.error('Ocorreu um erro inesperado.');
    }
  }

  renderErrorToast(errors) {
    const hasFieldErrors = get(errors, 'bibliografias.length', 0) > 0;
    const nonFieldErrors = get(errors, 'non_field_errors.bibliografias', []);

    toast.error((
      <React.Fragment>
        <ul>
          {hasFieldErrors && <li>Por favor, preencha os campos marcados como obrigatórios.</li>}
          {nonFieldErrors.map(e => <li key={e}>{e}</li>)}
        </ul>
      </React.Fragment>
    ));
  }

  render() {
    const { bibliografias, fetchingData } = this.state;

    if (fetchingData) {
      return <Loading />;
    }

    return (
      <FormContainer
        title="Bases Teóricas"
        onClick={this.onSubmit}
        previous="matrizes-referencia"
        next="conteudo"
      >
        <AddButton title="Adicionar Base Teórica" onClick={this.add} />
        {this.renderSaveStatus()}
        {
          <React.Fragment>
            {bibliografias.map(({ id }) => (
              <React.Fragment key={id}>
                <hr />
                <Form
                  onValueChange={(values) => {
                    if (this.callApi) {
                      this.setState({ save: this.MODIFICADO });
                      this.onValueChange(values, id);
                    }
                  }}
                  getApi={(formApi) => { this.formApis[id] = formApi; }}
                >
                  {() => {
                    if (fetchingData) {
                      return <Loading />;
                    }

                    return (
                      <div className="row">
                        {this.shouldDisplay('bibliografia.titulo') && (
                          <InputText
                            required={this.isRequired('bibliografia.titulo')}
                            col="col-sm-11"
                            label="Título"
                            field="titulo"
                          />
                        )}
                        <DeleteButton style={{ paddingTop: 3 }} onClick={() => this.remove(id)} />
                        {this.shouldDisplay('bibliografia.autor') && (
                          <InputText
                            required={this.isRequired('bibliografia.autor')}
                            col="col-sm-6"
                            label="Autor"
                            field="autor"
                          />
                        )}
                        {this.shouldDisplay('bibliografia.capitulo') && (
                          <InputText
                            required={this.isRequired('bibliografia.capitulo')}
                            col="col-sm-6"
                            label="Capítulo"
                            field="capitulo"
                          />
                        )}
                        {this.shouldDisplay('bibliografia.pagina_inicial') && (
                          <InputSpan
                            required={this.isRequired('bibliografia.pagina_inicial')}
                            col="col-sm-3"
                            label="Página"
                            leftAddon="Inicial"
                            field="pagina_inicial"
                          />
                        )}
                        {this.shouldDisplay('bibliografia.pagina_final') && (
                          <InputSpan
                            required={this.isRequired('bibliografia.pagina_final')}
                            col="col-sm-3"
                            leftAddon="Final"
                            field="pagina_final"
                          />
                        )}
                        {this.shouldDisplay('bibliografia.link') && (
                          <InputText
                            required={this.isRequired('bibliografia.link')}
                            col="col-sm-6"
                            label="Link (e-book)"
                            field="link"
                          />
                        )}
                      </div>
                    );
                  }}
                </Form>
              </React.Fragment>
            ))}
          </React.Fragment>
        }
      </FormContainer>
    );
  }
}

export default withRouter(ReferenciaBibliografica);
