import React from 'react';
import PropTypes from 'prop-types';

const InfoBox = ({ text, children }) => (
  <div className="info-box">
    {text && <h3 className="info-box-title">{text}</h3>}
    <div className="info-box-content">
      {children}
    </div>
  </div>
);

InfoBox.propTypes = {
  text: PropTypes.node,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

InfoBox.defaultProps = {
  text: '',
  children: null,
};

export default InfoBox;
