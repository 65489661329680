import React from 'react';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import Loading from '../components/Loading';
import { API_HOST } from '../consts';

class LoginRedirect extends React.Component {
  componentDidMount() {
    this.login();
  }

  login() {
    const { location, history } = this.props;
    const search = queryString.parse(location.search);
    axios.post(`${API_HOST}/login`, search)
      .then((response) => {
        localStorage.setItem('token', response.data.token);
        history.push('/');
      })
      .catch(() => {
        toast.error('Não foi possível realizar o login.');
        history.push('/login');
      });
  }

  render() {
    return <Loading />;
  }
}

export default withRouter(LoginRedirect);
