import React, { Fragment } from 'react';
import NavBreadCrumb from "../../containers/NavBreadCrumb";
import Select from "react-select";
import {Link} from "react-router-dom";
import ReactTable from "react-table";
import "react-table/react-table.css";
import CriarRecursoModal from "../../containers/modals/CriarRecursoModal";


export default class GerenciamentoResultados extends React.Component {
  constructor(props) {
    super(props);
    this.id = props.match.params.id;
    this.state = {
      openModal: false,
    }
  }

  toggleModal = (name) => {
    const { openModal } = this.state;
    this.setState({ openModal: openModal === name ? null : name });
  };

  renderTable = () => {
    const data =[
      {
        nome: "Maxito Verstappen",
        nota: "32",
        respostas: [
          {
            id: 1,
            resposta: "A",
            color: "success"
          }
        ]
      }]
    ;

    return (
      <ReactTable
        data={data}
        defaultPageSize={10}
        className="-striped -highlight"
        showPageSizeOptions={false}
        columns={[
          {
            Header: "Info",
            columns: [
              {
                Header: "Nome",
                id: 'nome',
                accessor: d=> d.nome
              },
              {
                Header: "Nota",
                id: 'nota',
                accessor: d=> d.nota
              },
            ]
          },
          {
            Header: "Notas",
            columns: [

            ]
          }

        ]}
      />

    )
  };

  render() {
    const pathList = [
      { link: '/instrumentos-cognitivos', label: 'Instrumentos cognitivos' },
      { link: `/gerenciamento-aplicacao/${this.id}`, label: 'Gerenciamento Prova' },
    ];

    const {openModal} = this.state;
    return (
      <Fragment>
        <NavBreadCrumb pathList={pathList} currentPath={`Gerenciamento de Notas ${this.id}`}/>
        <div className="container">
          <div className="row">
            <div className="col-12 mb-4 pb-4">
              <div className="card">
                <div className="card-header">
                  <h1 className="azul">Gerenciamento de notas - Fisiologia Humana I - Prova 1 - Tipo A</h1>
                  <hr/>
                  <ul className="list-inline list-style">
                    <li className="list-inline-item item-bg"><b>Data da prova: </b>09/11/2001</li>
                    <li className="list-inline-item item-bg"><b>Puntos: </b>20</li>
                    <li className="list-inline-item item-bg"><b>Itens: </b>15</li>
                    <li className="list-inline-item item-bg"><b>Itens anulados: </b>1</li>
                    <li className="list-inline-item item-bg"><b>Recursos: </b>1</li>
                  </ul>
                </div>
                <div className="card-body pt-0">
                  <div className="actions-table">
                    <button className="btn btn-white mr-2"><i className="far fa-eye mr-1"/> Ver itens anulados</button>
                    <button className="btn bg-amarelo btn-padrao mr-2"><i className="fas fa-ban mr-1"/> Anular Item</button>
                    <button onClick={()=>this.toggleModal('criarRecurso')} className="btn btn-white mr-2"><i className="fas fa-plus mr-1"/> Criar Recurso</button>
                    <CriarRecursoModal toggle={()=>this.toggleModal('criarRecurso')} isOpen={openModal === 'criarRecurso'}/>
                    <button className="btn-invisible">
                      <Select
                        style={{ fontSize: 13, height: '33px !important' }}
                        // value={ordering}
                        options={[
                          { value: 'criado_em', label: 'Enviar boletim' },
                          { value: '-criado_em', label: 'Enviar Gabarito' },
                        ]}
                        clearable={false}
                        placeholder="Exportar"
                        // onChange={this.handleOrderingChange}
                        searchable={false}
                      />
                    </button>
                    <Link to={`/gerenciamento-resultados/${this.id}/notas`}>
                      <button className="btn bg-padrao btn-padrao mr-2">Ir para notas</button>
                    </Link>
                  </div>
                  <hr/>
                  <div className="row mb-2 pb-2">
                    <div className="col-6">
                      <p>Legenda:
                        <i className="fas fa-circle ml-3 success"/> Correta
                        <i className="fas fa-circle ml-3 wrong"/> Incorreta
                        <i className="fas fa-circle ml-3 azulP"/> Anulada
                      </p>
                    </div>
                    <div className="col-4 text-right">
                      <button className="btn-invisible" style={{verticalAlign: 'bottom', minWidth: '250px'}}>
                        <div className="wrap">
                          <div className="search">
                            <input
                              style={{maxHeight: 33}}
                              className="search-term"
                              placeholder="Pesquisar..."
                              // value={search}
                              // onChange={(event) => this.handleSearch(event.target.value)}
                            />
                          </div>
                        </div>
                      </button>
                    </div>
                    <div className="col-2">
                      <Select
                        style={{ fontSize: 13, height: '33px !important' }}
                        // value={ordering}
                        options={[
                          { value: 'criado_em', label: 'Enviar boletim' },
                          { value: '-criado_em', label: 'Enviar Gabarito' },
                        ]}
                        clearable={false}
                        placeholder="Ações"
                        // onChange={this.handleOrderingChange}
                        searchable={false}
                      />

                    </div>

                  </div>
                  {/*{this.renderTable()}*/}
                </div>
              </div>
            </div>
          </div>


        </div>
      </Fragment>
    )
  }
}
