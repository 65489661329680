import React from 'react';
import { get, map } from 'lodash';
import ListItem from '../../containers/ListItem';
import ListItensHeader from '../../containers/ListItensHeader';
import NavBreadCrumb from '../../containers/NavBreadCrumb';
import BaseList from '../../base/BaseList';
import { Link } from 'react-router-dom';

class BaseListaTestes extends BaseList {

  api = null
  removeConfirmationMessage = () => {}

  renderList() {
    const { data } = this.state;

    return map(data, x => (
      <ListItem
        key={x.id}
        title={get(x, 'nome', '-')}
        small={get(x, 'id', '-')}
        status={{
          label: get(x, 'status.descricao', '-'),
          color: get(x, 'status.cor'),
        }}
        description={[
          { label: 'Total de itens', value: get(x, 'total_itens') },
        ]}
        actions={[
          <Link className="btn btn-white mb-3" key="0" to={`/${this.urlPrefix}/${x.id}`}>
            <i className="fas fa-edit" />
          </Link>,
          <button className="btn btn-white mb-3" key="1" onClick={() => this.handleRemove(x.id)}>
            <i className="fas fa-times" />
          </button>,
        ]}
      />
    ));
  }

  renderBreadcrumb() {
    return <NavBreadCrumb currentPath="Testes" />;
  }

  renderToolbar() {
    return (
      <ListItensHeader
        addButtonLabel="Novo teste"
        addLink="/testes/new"
        onSearch={this.handleSearch}
      />
    )
  }
}

export default BaseListaTestes;
