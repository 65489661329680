import React from 'react';

const TabelaAplicacoesAnalise = ({ aplicacoes }) => (
  <React.Fragment>
    <table className="table table-analise">
      <thead>
        <tr>
          <th />
          <th>Nome</th>
          <th>Data</th>
          <th>Respondentes</th>
        </tr>
      </thead>
      <tbody>
        {aplicacoes.map((a, i) => (
          <tr key={a.id}>
            <td width="5px"/>
            <td className="azul" style={{fontWeight: 600}}>{a.nome}</td>
            <td>{a.data}</td>
            <td>{a.numero_candidatos}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </React.Fragment>
);

TabelaAplicacoesAnalise.defaultProps = {
  aplicacoes: [],
}

export default TabelaAplicacoesAnalise;
