import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import BreadCrumbItem from '../../components/BreadCrumbItem';
import CardReference from '../../components/CardReference';

class CardModal extends React.Component {
  static propTypes = {
    breadcrumb: PropTypes.arrayOf(PropTypes.string),
    cards: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      descricao: PropTypes.string,
    })),
    activeCards: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      descricao: PropTypes.string,
    })),
    name: PropTypes.string.isRequired,
    onSearch: PropTypes.func,
  };

  static defaultProps = {
    breadcrumb: [],
    cards: [],
    activeCards: [],
    onSearch: () => {},
  };

  state = {
    search: '',
  }

  renderBreadcrumb() {
    const { breadcrumb, onSearch } = this.props;
    const { search } = this.state;

    if (breadcrumb.length > 0) {
      return (
        <Breadcrumb className="bg-breadcrumb--modal">
          <div className="col-sm-6">
            {breadcrumb.map(item => <BreadCrumbItem key={item} label={item} />)}
          </div>
          <div className="mt-2 col-sm-6">
            <div className="wrap">
              <div className="search">
                <input
                  className="search-term"
                  placeholder="Pesquisar..."
                  id="search"
                  value={search}
                  onChange={(event) => {
                    const { value } = event.target;
                    this.setState({ search: value });
                    onSearch(value);
                  }}
                />
              </div>
            </div>
          </div>
        </Breadcrumb>
      );
    }

    return null;
  }

  render() {
    const {
      isOpen,
      title,
      onCheckboxClick,
      activeCards,
      toggle,
      nenhumSelecionado,
      children,
    } = this.props;

    let { cards } = this.props;
    if (nenhumSelecionado) {
      cards = cards.filter(x => x.id === null);
    }

    return (
      <Modal className="bgModal" size="lg" isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          {title}
        </ModalHeader>
        {this.renderBreadcrumb()}

        <ModalBody>
          {children}
          {
            cards.length > 0
            ? cards.map((item, index) => (
              <CardReference
                key={item.id}
                {...item}
                onChange={(event) => onCheckboxClick(event, item.id)}
                checked={item.id === null ? nenhumSelecionado : activeCards.find(x => x.id === item.id) !== undefined}
              />
            ))
            : <p>Nenhum resultado encontrado.</p>
          }
        </ModalBody>

        <ModalFooter>
          <button className="btn danger-button" onClick={toggle}>Fechar</button>
        </ModalFooter>

      </Modal>
    );
  }
}

export default CardModal;
