import React from 'react';
import DashboardNavigationItem from './DashboardNavigationItem';

export default class DashboardNavigation extends React.Component {
  render() {
    return (
      <ol className="list-navigation-dashboard ml-2">
        {this.props.steps.map(link => <DashboardNavigationItem key={link.id} {...link} />)}
      </ol>
    );
  }
}
