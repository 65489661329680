import React, { useEffect, useState } from 'react';
import FormGroup from 'reactstrap/lib/FormGroup';
import AddButton from '../buttons/AddButton';
import DeleteButton from '../DeleteButton';
import InputSelect from '../inputs/InputSelect';

const TOTVSCourseDisciplineForm = ({
  courses,
  disciplines,
  courseOptions,
  disciplineOptions,
  addCourse,
  removeCourse
}) => {
  const [selectedCourses, setSelectedCourse] = useState({});

  const onSelectCourse = (value, index) => {
    setSelectedCourse((previousSelectedCourse) => ({
      ...previousSelectedCourse,
      [index]: value
    }));
  };

  const renderEmptyState = () => {
    if (courses.length === 0) {
      return (
        <div>
          <hr />
          <div>
            <p>Este usuário ainda não possui nenhum curso vinculado.</p>
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    if (courseOptions.length > 0) {
      const savedCourse = courses
        .filter((c) => c.curso)
        .reduce(
          (accumulator, current, index) => ({
            ...accumulator,
            [index]: courseOptions.find((o) => o.label === current.curso)
          }),
          {}
        );

      setSelectedCourse(savedCourse);
    }
  }, [courses, courseOptions]);

  return (
    <>
      <div className="card-header bg-white pb-0">
        <h1>Cursos e Disciplina vinculados</h1>
        <p className="mb-0">
          Selecione os cursos e disciplina vinculados a esse usuário.
        </p>
      </div>
      <div
        className="card-body bg-white card-body-form"
        style={{ overflow: 'unset' }}
      >
        <AddButton title="Adicionar Curso" onClick={addCourse} />
        {courses.map((_, index) => (
          <div key={`course_${index}`}>
            <hr />
            <div className="row">
              <InputSelect
                label="Curso"
                col="col-md-5"
                field={`totvs_permissions[${index}].curso`}
                onChange={(value) => onSelectCourse(value, index)}
                options={courseOptions.map((c) => ({
                  value: c.descricao,
                  label: c.label
                }))}
              />
              <InputSelect
                label="Disciplina"
                field={`totvs_permissions[${index}].disciplinas`}
                multi
                col="col-md-5"
                options={
                  selectedCourses[index]
                    ? disciplineOptions.filter(
                        (v) => v.curso === selectedCourses[index].label
                      )
                    : []
                }
              />
              <FormGroup style={{ marginTop: '5px' }}>
                <DeleteButton onClick={() => removeCourse(index)} />
              </FormGroup>
            </div>
          </div>
        ))}
        {renderEmptyState()}
      </div>
    </>
  );
};

export default TOTVSCourseDisciplineForm;
