import React from 'react';
import { Form, Scope } from 'informed';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import { get, map, sortBy, debounce } from 'lodash';
import { toast } from 'react-toastify';
import { API_HOST } from '../../consts';
import FormContainer from '../../containers/Form';
import Loading from '../../components/Loading';
import InputText from '../../components/inputs/InputText';
import InputCurrency from '../../components/inputs/InputCurrency';
import InputSelectAsync from '../../components/inputs/InputSelectAsync';
import BaseCreate from '../../base/BaseCreate';
import InputSelect from '../../components/inputs/InputSelect';
import InputCheckbox from '../../components/inputs/InputCheckbox';

const mapRevisor = (d) => ({ value: d.id, label: d.last_name });

const mapMatrizReferencia = ({ id, nome }) => ({
  value: id,
  label: nome
});

class Revisores extends BaseCreate {
  state = {
    matrizes_referencia: [],
    markedForDeletion: [],
    fetchingData: true,
    instituicao: null,
    aviso_matrizes_referencia: false
  };

  componentDidMount() {
    this.fetchApi({
      link: '/itens/criado_por',
      name: 'revisores',
      label: 'last_name'
    });
    this.fetchData();
    this.fetchEncomenda();
    this.fetchMatrizReferencia();
  }

  fetchEncomenda() {
    axios.get(`${API_HOST}/encomenda/encomenda/${this.id}`).then((response) => {
      if (response.data.instituicao) {
        this.setState({ instituicao: response.data.instituicao.id });
      }
    });
  }

  fetchData() {
    if (this.id !== 'new') {
      axios
        .get(`${API_HOST}/encomenda/revisao`, {
          params: {
            encomenda: this.id,
            ordering: 'posicao'
          }
        })
        .then((response) => {
          this.setState({ fetchingData: false });
          const revisoes = response.data;
          revisoes.forEach((p) => {
            p.revisores = sortBy(p.revisores, ['id']);
            p.revisores.reverse();
          });
          this.formApi.setValues({ revisoes });
        });
    } else {
      this.setState({ fetchingData: false });
    }
  }

  defaultRevisorOptions(revisor, options = []) {
    let selectedOption;
    const usuario = get(revisor, 'usuario');

    if (usuario) {
      const usuario__str = get(revisor, 'usuario__str');
      selectedOption = {
        value: usuario,
        label: usuario__str
      };
    }

    if (selectedOption) {
      const selectedOptionInOptions = options.find(
        (d) => d.value === selectedOption.value
      );
      if (!selectedOptionInOptions) {
        return [selectedOption];
      }
    }
    return [];
  }

  fetchMatrizReferencia = () =>
    axios
      .get(`${API_HOST}/encomenda/encomenda/${this.id}/matriz_referencia`)
      .then((response) =>
        this.setState({
          matrizes_referencia: response.data.map(mapMatrizReferencia),
          aviso_matrizes_referencia: response.data.length === 0
        })
      );

  fetchRevisores = (revisor) =>
    debounce((input, callback) => {
      if (!input) {
        callback(null, { options: this.defaultRevisorOptions(revisor) });
        return;
      }
      const { instituicao } = this.state;
      axios
        .get(`${API_HOST}/login/user`, {
          params: {
            search: input,
            ordering: 'last_name',
            permissions: 'revisoes.change_revisao',
            page_size: 30,
            instituicoes: instituicao
          }
        })
        .then((response) => {
          let options = response.data.results.map(mapRevisor);
          options = [
            ...this.defaultRevisorOptions(revisor, options),
            ...options
          ];
          callback(null, { options });
        });
    }, 1000);

  handleSubmit = (values) => {
    const { markedForDeletion } = this.state;
    this.setState({ submitting: true });
    const data = [];

    values.revisoes.forEach((r) => {
      const revisores = r.revisores || [];
      data.push(
        ...revisores.map((d) => ({
          ...d,
          usuario: get(d, 'usuario.value') || get(d, 'usuario')
        }))
      );
    });

    axios
      .put(`${API_HOST}/encomenda/revisor`, data, {
        params: {
          delete: markedForDeletion.join(',')
        }
      })
      .then(() => {
        const { history } = this.props;
        history.push(this.next);
      })
      .catch((error) => {
        this.setState({ submitting: false });
        const status = get(error.response, 'status');
        if (status === 400) {
          toast.error('Todos os campos devem ser preenchidos.');
          const revisoes = this.formApi.getValue('revisoes');
          let responseIndex = 0;
          revisoes.forEach((r, revisaoIndex) => {
            r.revisores.forEach((rv, revisorIndex) => {
              const errors = get(error.response, `data[${responseIndex}]`);
              this.formApi.setError(
                `revisoes[${revisaoIndex}].revisores[${revisorIndex}].matriz_referencia`,
                get(errors, 'matriz_referencia')
              );
              responseIndex += 1;
            });
          });
        } else {
          toast.error('Ocorreu um erro inesperado.');
        }
      });
  };

  add = (i) => {
    const revisoes = this.formApi.getState().values.revisoes || [];
    const revisores = revisoes[i].revisores || [];
    revisoes[i] = {
      ...revisoes[i],
      revisores: [
        {
          encomenda: revisoes[i].encomenda,
          tipo_revisao: get(revisoes[i], 'tipo_revisao.id')
        },
        ...revisores
      ]
    };
    this.formApi.setValues({ revisoes });
  };

  remove = (i, j) => {
    const revisoes = this.formApi.getState().values.revisoes || [];
    const { revisores, ...rest } = revisoes[i];
    const { id } = revisores[j];
    revisores.splice(j, 1);
    revisoes[i] = {
      revisores: revisores || [],
      ...rest
    };
    this.formApi.setValues({ revisoes });

    const { markedForDeletion } = this.state;
    this.setState({ markedForDeletion: [id, ...markedForDeletion] });
  };

  renderRemoveButton(i, j) {
    return (
      <button
        type="button"
        className="btn btn-outline mt-4"
        onClick={() => this.remove(i, j)}
      >
        <i className="fas fa-times" />
      </button>
    );
  }
  render() {
    const {
      fetchingData,
      submitting,
      matrizes_referencia,
      aviso_matrizes_referencia
    } = this.state;
    const { novoFluxo } = this.props;
    if (aviso_matrizes_referencia && !novoFluxo) {
      return (
        <p>
          Por favor, antes de prosseguir,{' '}
          <Link to="matrizes-referencia">clique aqui</Link> para definir as
          matrizes de referência.
        </p>
      );
    }

    return (
      <Form
        onChange={(formState) => this.setState({ formState })}
        getApi={(formApi) => {
          this.formApi = formApi;
        }}
        onSubmit={this.handleSubmit}
        initialValues={{ revisoes: [] }}
      >
        {({ formState }) => {
          if (fetchingData) {
            return <Loading />;
          }

          return (
            <React.Fragment>
              <FormContainer
                title="Revisores:"
                next="convocar-elaboradores"
                previous="revisao"
                onClick={this.handleNext}
                disabled={submitting}
              >
                {formState.values.revisoes.map((p, i) => (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card mb-3">
                        <div className="card-body">
                          <React.Fragment>
                            <div className="row">
                              <div className="col-md-12 mb-2">
                                <h1>
                                  <strong>
                                    {get(p, 'tipo_revisao.descricao')}
                                  </strong>
                                </h1>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-md-6">
                                <button
                                  type="button"
                                  className="btn primary-button"
                                  onClick={() => this.add(i)}
                                >
                                  <i className="fas fa-plus" /> Adicionar
                                  revisor
                                </button>
                              </div>
                            </div>
                            <Scope scope={`revisoes[${i}]`}>
                              {map(
                                formState.values.revisoes[i].revisores,
                                (revisor, j) => (
                                  <Scope scope={`revisores[${j}]`}>
                                    <hr />

                                    <div
                                      className="row"
                                      style={{
                                        display: 'flex',
                                        alignItems: 'flex-end'
                                      }}
                                    >
                                      <InputSelectAsync
                                        autoload={false}
                                        col="col-md-5 col-sm-12"
                                        label="Revisor"
                                        options={this.defaultRevisorOptions(
                                          revisor
                                        )}
                                        loadOptions={this.fetchRevisores(
                                          revisor
                                        )}
                                        field="usuario"
                                      />

                                      <InputCheckbox
                                        col="col-md-5 col-sm-12"
                                        label="Ocultar o nome do revisor"
                                        field="ocultar_revisor"
                                      />
                                    </div>
                                    <div className="row">
                                      <InputText
                                        col="col-md-6 col-sm-10"
                                        label="Quantidade"
                                        field="quantidade"
                                      />
                                      <InputCurrency
                                        col="col-md-4 col-sm-10"
                                        label="Valor por revisão"
                                        field="valor_revisao"
                                      />
                                      <div className="col-md-1 col-sm-1">
                                        {this.renderRemoveButton(i, j)}
                                      </div>
                                      {!novoFluxo && (
                                        <InputSelect
                                          autoload={false}
                                          required={novoFluxo ? false : true}
                                          col="col-md-12"
                                          label="Matriz de Referência"
                                          field="matriz_referencia"
                                          options={matrizes_referencia}
                                        />
                                      )}
                                    </div>
                                  </Scope>
                                )
                              )}
                            </Scope>
                          </React.Fragment>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </FormContainer>
            </React.Fragment>
          );
        }}
      </Form>
    );
  }
}

export default withRouter(Revisores);
