import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { toast } from 'react-toastify';
import axios from 'axios';
import { get } from 'lodash';
import { API_HOST } from '../consts';

class BaseForm extends React.Component {
  constructor(props) {
    super(props);
    this.fetchApi = this.fetchApi.bind(this);
    this.onValidateError = this.onValidateError.bind(this);
    this.id = get(props, 'match.params.id');
    this.fields = [];
    this.multiFields = [];
    this.metadataModels = [];
    this.ATUALIZADO = 0;
    this.MODIFICADO = 1;
    this.SALVANDO = 2;
    this.SALVO = 3;
    this.ERRO = 4;
  }

  getSaveStatus(status) {
    return {
      [this.ATUALIZADO]: 'O item está atualizado.',
      [this.MODIFICADO]: 'Alterações pendentes.',
      [this.SALVANDO]: 'Salvando alterações...',
      [this.SALVO]: 'Alterações salvas com sucesso.',
      [this.ERRO]: 'Não foi possível salvar a alteração.'
    }[status];
  }

  renderSaveStatus(status, id = 'status') {
    const { save } = this.state;
    status = status === undefined ? save : status;
    const saveStatus = this.getSaveStatus(status);
    let icon;

    switch (status) {
      case this.MODIFICADO:
        icon = <i id={id} className="pull-right fas fa-asterisk text-info" />;
        break;
      case this.SALVANDO:
        icon = (
          <i id={id} className="pull-right fas fa-spinner fa-spin text-info" />
        );
        break;
      case this.ERRO:
        icon = <i id={id} className="pull-right fas fa-times text-danger" />;
        break;
      case this.SALVO:
      case this.OK:
      default:
        icon = <i id={id} className="pull-right fas fa-check text-success" />;
    }

    return (
      <React.Fragment>
        {icon}
        <UncontrolledTooltip placement="top" target={id} delay={0}>
          {saveStatus}
        </UncontrolledTooltip>
      </React.Fragment>
    );
  }

  fetchApi(name, app, labelName = 'descricao') {
    axios.get(`${API_HOST}/${app}/${name}`).then((response) => {
      const data = response.data.results
        ? response.data.results
        : response.data;
      this.setState({
        [name]: data.map((x) => ({ value: x.id, label: x[labelName] }))
      });
    });
  }

  getValues(data) {
    const values = {};
    this.fields.forEach((field) => {
      values[field] = get(data, field) === undefined ? null : get(data, field);
    });
    this.multiFields.forEach((field) => {
      values[field] = get(data, field, []);
    });
    return values;
  }

  setFields(data) {
    const values = {};
    this.fields.forEach((field) => {
      values[field] = get(data, field);
    });
    this.multiFields.forEach((field) => {
      values[field] = get(data, field, []);
    });
    this.formApi.setValues(values);
  }

  getSubmitData(values) {
    const fields = {};
    this.fields.forEach((field) => {
      fields[field] = null;
    });
    this.multiFields.forEach((field) => {
      fields[field] = [];
    });
    return {
      ...fields,
      ...values
    };
  }

  onValidateError(error, name) {
    const status = get(error, 'response.status');

    if (status === 400) {
      const errors = get(error, 'response.data');
      Object.keys(errors).forEach((field) => {
        if (field === 'non_field_errors') {
        } else {
          try {
            this.formApi.setError(field, errors[field]);
          } catch (_) {
            // Essa exceção ocorre quando <field> não existe no form
            // Está aqui só para evitar quebrar a página caso a API retorne o que não deveria
          }
        }
      });

      this.renderErrorToast(errors, name);
    } else if (status !== 403) {
      toast.error('Ocorreu um erro inesperado.');
    }
  }

  renderErrorToast(errors, name) {
    const label = name ? `non_field_errors.${name}` : 'non_field_errors';
    const non_field_errors = get(errors, label, []);
    delete errors.non_field_errors;
    toast.error(
      <React.Fragment>
        {Object.keys(errors).length > 0 && (
          <p>Por favor, preencha os campos marcados como obrigatórios.</p>
        )}
        <ul>
          {non_field_errors.map((e) => (
            <li key={e}>{e}</li>
          ))}
        </ul>
      </React.Fragment>
    );
  }

  formIsDisabled() {
    const { save } = this.state;
    return save === this.SALVANDO || save === this.MODIFICADO;
  }

  mapMetadata(metadata) {
    const mapped = {};
    metadata.forEach((m) => {
      mapped[`${m.model}.${m.name}`] = m.requireness;
    });
    return mapped;
  }

  mapMetadataDefult(metadata) {
    const mapped = {};
    metadata.forEach((m) => {
      mapped[`${m.model}.${m.name}`] = m.default;
    });
    return mapped;
  }

  isRequired(field) {
    const { metadata } = this.state;
    return get(metadata, field) === 2;
  }

  shouldDisplayDefault(field) {
    const { metadataDefault } = this.state;

    return get(metadataDefault, field);
  }

  shouldDisplay(field) {
    const { metadata } = this.state;
    return get(metadata, field) > 0;
  }

  fetchMetadata() {
    this.setState({ fetchingMetadata: true });
    return axios
      .get(`${API_HOST}/params/param?model=${this.metadataModels.join('|')}`)
      .then((response) => {
        this.setState({
          metadata: this.mapMetadata(response.data),
          metadataDefault: this.mapMetadataDefult(response.data),
          fetchingMetadata: false
        });
      });
  }
}

export default BaseForm;
