import React from 'react';
import axios from 'axios';
import { Form } from 'informed';
import { get, debounce, toInteger, size } from 'lodash';
import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { default as FormContainer } from '../../containers/Form';
import FiltroAvancadoItens from './FiltroAvancadoItens';
import { API_HOST } from '../../consts';
import Item from '../../components/Item';
import Loading from '../../components/Loading';
import { toast } from 'react-toastify';
import { getErrorToast } from '../../utils';

class BaseItens extends React.Component {

  initialState = {
    itens: [],
    checked: [],
    itensAdicionados: [],
    todosSelecionados: false,
    total_itens_bloco: 0,
    regua: [],
    curva: [],
  }

  state = {
    ...this.initialState,
  }

  componentDidMount() {
    this.fetchData();
    this.setState({openModal: this.props.location.openModal});
  }

  componentDidUpdate(prevProps) {
    const prevPosicao = get(prevProps, 'match.params.posicao');
    const posicao = get(this.props, 'match.params.posicao');

    if (prevPosicao !== posicao) {
      this.setState(this.initialState);
      this.fetchData();
    }
  }

  toggleModal = (name) => {
    const { openModal } = this.state;
    this.setState({ openModal: openModal === name ? null : name });
  }

  fetchData(loading=true) {
    const { match, history } = this.props;

    if (loading) {
      this.setState({ fetchingData: true });
    }

    return axios.get(`${API_HOST}/testes/bloco`, {
      params: {
        teste: match.params.id,
        posicao: match.params.posicao,
      },
    })
      .then((response) => {
        const bloco = get(response, 'data.results[0]') || get(response, 'data[0]');

        if (!bloco) {
          history.push('/pre-testes');
        }

        const itens = get(bloco, 'itens') || [];

        this.formApi.setValues({
          itens,
        });

        this.setState({
          bloco: get(bloco, 'id'),
          external_id: get(bloco, 'external_id'),
          total_itens_bloco: get(bloco, 'total_itens_bloco'),
          itensAdicionados: itens.map(item => get(item, 'item.id')),
          fetchingData: false
        }, () => this.fetchAnalise(false));
      });
  }

  fetchAnalise() {

  }

  handleResultClick = (event, data) => {
    const { bloco } = this.state;
    const { checked } = event.target;

    if (checked) {
      axios.post(`${API_HOST}/testes/bloco_item`, {
        bloco,
        ...data
      })
        .then((response) => {
          this.fetchData(false);
        })
        .catch((error) => {
          toast.error(getErrorToast(error));
        });
    } else {
      const { itens } = this.state;
      const item = itens.find(i => get(i, 'item.id') === data.item);
      this.removeItem(item);
    }
  }

  handleChange = (index, id, data) => {
    this.patchItem(index, id, data);
  }

  patchItem = debounce((index, id, data) => {
    axios.patch(`${API_HOST}/testes/bloco_item/${id}`, data)
      .then(() => {

      })
      .catch((error) => {
        const status = get(error, 'response.status');
        if (status === 400) {
          const errors = get(error, 'response.data');
          Object.keys(errors).forEach((field) => {
            const fieldName = `itens[${index}].${field}`;
            if (this.formApi.fieldExists(fieldName)) {
              this.formApi.setError(fieldName, errors[field]);
            }
          });
        }
      })
  }, 500)

  removeItem(item) {
    this.setState({ editing: true });

    axios.delete(`${API_HOST}/testes/bloco_item/${item.id}`)
      .then(() => {
        this.fetchData(false).then(() => {
          this.setState({ editing: false });
        })
      })
  }

  alterarPosicao = (item, offset) => {
    this.setState({ editing: true });

    axios.patch(`${API_HOST}/testes/bloco_item/${item.id}`, { posicao: item.posicao + offset })
      .then(() => {
        this.fetchData(false).then(() => {
          this.setState({ editing: false });
        })
      })
  }

  toggleCheckbox = (id) => {
    const { checked } = this.state;
    const index = checked.findIndex(c => c === id);

    if (index !== -1) {
      checked.splice(index, 1);
    } else {
      checked.push(id);
    }

    this.setState({ checked: [...checked] });
  }

  renderItens(formState) {
    const { editing, checked } = this.state;
    const labels = {
      situacao_problema: '  ',
      comando_resposta: '  '
    }
    if (formState.values.itens.length === 0) {
      return <p>Nenhum item adicionado.</p>;
    }

    return formState.values.itens.map((item, index) => (
      <div className="row" id={index+1} key={item.id}>

        <div className="col-12 mb-3">
          <div className="card " key={item.id}>
            <div className="card-header bg-white">
              <label className="container-check-custom pull-left form-check-label ">
                <input
                  type="checkbox"
                  onChange={() => this.toggleCheckbox(item.id)}
                  checked={checked.includes(item.id)}
                />
                <span className="checkmark " />
              </label>

              <div role="group" className="btn-group list-btn pull-right">
                <button className="btn" style={{background: get(item, 'item.status.cor'), color: 'white', fontSize: 13, cursor: 'default'}}>
                  {get(item, 'item.status.descricao')}
                </button>
                {index !== formState.values.itens.length - 1 && (
                  <button
                    className="btn btn-white"
                    onClick={() => this.alterarPosicao(item, 1)}
                    disabled={editing}
                  >

                    <i className="fas fa-arrow-down" />
                  </button>
                )}
                {index > 0 && (
                  <button
                    className="btn btn-white"
                    onClick={() => this.alterarPosicao(item, -1)}
                    disabled={editing}
                  >
                    <i className="fas fa-arrow-up" />
                  </button>
                )}
                <button
                  className="btn btn-white"
                  onClick={() => this.removeItem(item)}
                  disabled={editing}
                >
                  <i className="fas fa-times" />
                </button>
                {editing && <button
                  className="btn btn-white"
                  disabled={editing}
                >
                  <i className="fas fa-spinner fa-spin azul" />
                </button>}


              </div>
              <div className="row">
                <div className="col-sm-12 mb-2">
                  <p style={{margin:0}}>
                  <strong>{item.posicao}.</strong> Item {get(item, 'item.id')}
                  </p>
                  <ul className="lista-pre-teste">
                    <li><span>A: </span>3</li>
                    <li><span>B: </span>2</li>
                    <li><span>C: </span>8</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-body bg-white pt-0">
              <Item labels={labels} item={item.item} />
            </div>
          </div>
        </div>
      </div>
    ));
  }

  moverItens = (bloco) => {
    const { checked } = this.state;

    axios.post(`${API_HOST}/testes/bloco_item/mover`, {
      bloco: bloco,
      bloco_item: checked,
    })
      .then(() => {
        this.fetchData(false);
        this.setState({ todosSelecionados: false, checked: [] });
      })
  }

  selecionarTodos = (formState) => {
    const { todosSelecionados } = this.state;
    let checked;

    if (todosSelecionados) {
      checked = [];
    } else {
      checked = formState.values.itens.map(item => item.id);
    }

    this.setState({ checked, todosSelecionados: !todosSelecionados });
  }

  get next() {
    const { match, step } = this.props;
    const substep = toInteger(match.params.posicao);
    if (substep + 1 > step.substeps.length) {
      return `/pre-testes/${match.params.id}/dados`;
    }
    return `/pre-testes/${match.params.id}/blocos/${substep + 1}`;
  }

  get previous() {
    const { match } = this.props;
    const substep = toInteger(match.params.posicao);

    if (substep - 1 === 0) {
      return `/pre-testes/${match.params.id}/dados`;
    }
    return `/pre-testes/${match.params.id}/blocos/${substep - 1}`;
  }

  renderAnalise() {
    return null;
  }
  renderNumberItens(itens, total) {
    if (itens === total) return <p className="text-right cinza"><i className="fas fa-check-circle right"/> Itens Adicionados: {itens}/{total}</p>;
    if (itens < total) return <p className="text-right cinza"><i className="fas fa-exclamation-circle wrong"/> Itens Adicionados: {itens}/{total}</p>;
  };

  areAllItems = (itens, total) =>{
    if (itens === total) return true;
    if (itens < total) return false;
  };

  render() {
    const { match, step } = this.props;
    const {
      openModal,
      itensAdicionados,
      todosSelecionados,
      bloco,
      checked,
      total_itens_bloco,
      fetchingData,
    } = this.state;

    return (
      <FormContainer
        title={`Bloco ${match.params.posicao}`}
        previous={this.previous}
        next={this.next}
        className="pb-0"

      >
        <Form
          onChange={formState => this.setState({ formState })}
          getApi={(formApi) => { this.formApi = formApi; }}
          initialValues={{ itens: [] }}

        >
          {({ formState }) => {
            if (fetchingData) {
              return <Loading />;
            }

            return (
              <React.Fragment>
                {this.renderAnalise()}
                <hr/>
                <div className="row mt-4 mb-2">
                  <div className="col-1">{size(formState.values.itens) > 0 && (
                    <label className="container-check-custom form-check-label azul">
                      <input
                        type="checkbox"
                        checked={todosSelecionados}
                        onChange={() => this.selecionarTodos(formState)}
                      />
                      <span className="checkmark" />
                    </label>
                  )}
                  </div>
                  <div className="col-7">
                    <button disabled={this.areAllItems(size(formState.values.itens), total_itens_bloco)} className="btn mb-3 ml-3 primary-button" onClick={() => this.toggleModal('modalItens')}>
                    <i className="fas fa-plus" /> Adicionar
                  </button>
                    <UncontrolledDropdown>
                      <DropdownToggle
                        caret
                        className="btn-white"
                        disabled={size(checked) === 0}
                      >
                        Mover para bloco
                      </DropdownToggle>
                      <DropdownMenu>
                        {step.substeps.filter(substep => substep.id !== bloco).map(substep => (
                          <DropdownItem
                            key={substep.id}
                            onClick={() => this.moverItens(substep.id)}
                          >
                            {substep.title}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                  <div className="col-4">
                    {this.renderNumberItens(size(formState.values.itens), total_itens_bloco)}
                  </div>

                </div>
                <hr/>


                {this.renderItens(formState)}
              </React.Fragment>
            );
          }}
        </Form>

        <FiltroAvancadoItens
          isOpen={openModal === 'modalItens'}
          toggle={() => this.toggleModal('modalItens')}
          filter={false}
          onResultClick={this.handleResultClick}
          checked={itensAdicionados}
        />
      </FormContainer>
    );
  }
}

export default BaseItens;
