import React, { Fragment } from 'react';
import { get } from 'lodash';
import Highcharts from 'highcharts';

const formatFloat = (d) => parseFloat(parseFloat(d).toFixed(4));

class CurvaCaracteristica extends React.Component {
  offset = 0.05;

  componentDidMount() {
    const { parametrosCorrigidos } = this.props;
    const shouldRenderChart = get(parametrosCorrigidos, '_row');
    if (shouldRenderChart) {
      this.createChart(this.curva);
    }
  }

  componentDidUpdate(prevProps) {
    const { curva } = this.props;
    const curvaMudou = curva !== prevProps.curva;
    if (curvaMudou) {
      this.createChart(this.curva);
    }
  }

  get curva() {
    const { curva, parametrosCorrigidos } = this.props;
    this.parametro_p = 0;
    this.parametro_b = parametrosCorrigidos.b;

    return curva.map((d) => {
      const theta = formatFloat(d.theta);
      const paramb = formatFloat(this.parametro_b);

      if (theta + this.offset >= paramb && theta - this.offset <= paramb) {
        this.parametro_p = d.p;
      }

      return [formatFloat(d.theta), formatFloat(d.p)];
    });
  }

  createChart(curvaAtual) {
    const { id } = this.props;

    Highcharts.chart(id, {
      legend: {
        itemStyle: {
          color: 'var(--cinza)',
        },
      },
      chart: {
        zoomType: 'xy',
        height: 500,
      },
      title: {
        text: ' ',
      },
      series: [
        {
          type: 'line',
          data: [
            [this.parametro_b, 0],
            [this.parametro_b, this.parametro_p],
          ],
          color: 'var(--pink)',
          showInLegend: false,
          pointWidth: 2,
          lineWidth: 2,
          dashStyle: 'shortdash',
        },
        {
          type: 'line',
          data: [
            [-4, this.parametro_p],
            [this.parametro_b, this.parametro_p],
          ],
          color: 'var(--pink)',
          showInLegend: false,
          lineWidth: 2,
          dashStyle: 'shortdash',
        },
        {
          data: curvaAtual,
          color: 'var(--padrao)',
          name: 'Curva característica',
        },
        {
          type: 'scatter',
          name: 'b',
          data: [[formatFloat(this.parametro_b), 0]],
          marker: {
            symbol: 'circle',
            fillColor: '#FFFFFF',
            lineWidth: 2,
            lineColor: null,
          },
          dataLabels: {
            enabled: true,
            formatter: () => 'b',
            align: 'left',
          },
          showInLegend: false,
        },
      ],
      yAxis: {
        title: {
          text: 'Probabilidade',
        },
        min: 0,
        max: 1,
        tickInterval: 0.1,
      },
      xAxis: {
        title: {
          text: 'Proficiência',
        },
        tickInterval: 1,
      },
      plotOptions: {
        line: {
          marker: {
            enabled: false,
          },
        },
      },
      tooltip: {
        formatter: function () {
          if (this.series.name === 'Curva característica') {
            return `(${this.x}, ${this.y})`;
          }

          if (this.series.name === 'b') {
            return `b: ${this.x}`;
          }

          return false;
        },
      },
    });
  }

  render() {
    const { id } = this.props;

    return (
      <Fragment>
        <p className="azul bg-white mb-0 p-4">
          <b>Curva característica do item</b>
        </p>
        <div id={id} />
      </Fragment>
    );
  }
}

export default CurvaCaracteristica;
