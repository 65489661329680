import { withRouter } from 'react-router-dom';
import BaseFiltroAvancadoItemAvaliacao from '../../base/BaseFiltroAvancadoItemAvaliacao';

class FiltroAvancadoItens extends BaseFiltroAvancadoItemAvaliacao {

  handleCheckboxChange = (event, id) => {
    const { onResultClick } = this.props;
    const data = {
      item: id,
    };
    onResultClick(event, data);
  }
}

export default withRouter(FiltroAvancadoItens);
