import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';

const HeaderRelatorio = ({
  schoolName,
  schoolYear,
  dreAndMunicipio,
  student,
  disableSchoolYear,
  disableDreAndMunicipio,
  disableSchoolName,
  disableStudent
}) => {
  const theme = useContext(ThemeContext);
  return (
    <>
      <div
        className="container__background"
        style={{ background: `${theme.primary}` }}
      >
        <div className="basic__info">
          {disableSchoolName && (
            <div className="input__background">
              <span className="first__info">
                Escola: <span>{schoolName}</span>
              </span>
            </div>
          )}
          {disableSchoolYear && (
            <div className="input__background">
              <span className="first__info">
                Etapa Escolar: <span>{schoolYear}</span>
              </span>
            </div>
          )}
        </div>
        <div className="basic__info">
          {disableDreAndMunicipio && (
            <div className="input__background">
              <span className="first__info">
                {/* Exemplo: DRE Juina | Juina |  */}
                DRE/Município: <span>{dreAndMunicipio}</span>
              </span>
            </div>
          )}
          {disableStudent && (
            <div className="input__background">
              <span className="first__info">
                Aluno: <span>{student}</span>
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

HeaderRelatorio.propTypes = {
  schoolName: PropTypes.string,
  schoolYear: PropTypes.string,
  dreAndMunicipio: PropTypes.string,
  student: PropTypes.string,
  disableSchoolYear: PropTypes.bool,
  disableDreAndMunicipio: PropTypes.bool,
  disableSchoolName: PropTypes.bool,
  disableStudent: PropTypes.bool
};
HeaderRelatorio.defaultProps = {
  disableSchoolYear: true,
  disableDreAndMunicipio: true,
  disableSchoolName: true,
  disableStudent: true
};

export default HeaderRelatorio;
