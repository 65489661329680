import React, { Component, lazy } from 'react';
import { Form } from 'informed';
import NavBreadCrumb from '../../containers/NavBreadCrumb';
import InputText from '../../components/inputs/InputText';
import SwitchButton from '../../components/SwitchButton';

const MultiSelect = lazy(() => import('../../components/inputs/InputMultiSelect'));

class Exame extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      grupos: [
        { id: 0, label: 'Administração' },
        { id: 2, label: 'Agronomia', disabled: true },
        { id: 3, label: 'Aviação' },
      ],

      selectedItemsPermissoes: [],

      messages: {
        searchPlaceholder: 'Pesquisar área de conhecimento...',
        noItemsMessage: 'Selecione um item na coluna esquerda...',
        noneSelectedMessage: 'Nenhum Selecionado',
        selectedMessage: 'selecionada',
        selectAllMessage: 'Selecionar todas',
        clearAllMessage: 'Remover Todas',
        disabledItemsTooltip: 'You can only select 1 file',
      },
    };
  }

  handleChange(selectedItems) {
    this.setState({ selectedItems });
  }

  render() {
    const {
      grupos,
      selectedItemsGrupos,
      messages,
    } = this.state;
    return (
      <div className="form-configuracoes">
        <NavBreadCrumb currentPath="Exame"/>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <Form>
                  <div className="card-header">
                    <div className="row">
                      <div className="col-6">
                        <h1>Exame</h1>
                      </div>
                      <div className="col-6">
                        <SwitchButton/>
                      </div>
                    </div>
                  </div>
                  <div className="card-body card-body-form">
                    <div className="row">
                      <InputText
                        col="col-sm-3"
                        label="Nome do Exame"
                        field="exame"
                      />
                    </div>
                  </div>
                  <div className="card-header">
                    <h1>Áreas de Conhecimento</h1>
                  </div>
                  <div className="card-body card-body-form">
                    <MultiSelect
                      items={grupos}
                      selectedItems={selectedItemsGrupos}
                      onChange={this.handleChange}
                      messages={messages}
                    />
                  </div>
                  <div className="card-footer display-flex">
                    <button className="btn primary-button">Salvar</button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Exame;
