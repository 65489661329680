import React, { useEffect, useState } from 'react'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import ButtonBase from '@material-ui/core/ButtonBase';
import { makeStyles, styled } from '@material-ui/core/styles';
import Axios from 'axios';
import { API_HOST } from '../consts';
import { withRouter } from 'react-router-dom';
import { green } from '@material-ui/core/colors';


const useStyles = makeStyles(theme => ({
  CardRoot: {
    marginTop: theme.spacing(1),
    width: theme.spacing(37)
  },
  CardContentRoot: {
    padding: theme.spacing(2),

    '&:last-child': {
      paddingBottom: theme.spacing(2),
    }
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundColor: green[500],
  },

}));

const CardButton = styled(ButtonBase)(({ theme }) => ({
  width: '100%'
}));

const ProviderSignInCard = ({ provider, history }) => {
  const classes = useStyles();

  return (
    <CardButton onClick={() => history.push(`/external-auth/${provider.name}`)}>
      <Card classes={{root: classes.CardRoot}}>
        <CardContent classes={{root: classes.CardContentRoot}}>
          <div style={{display: 'flex'}}>
            <Avatar alt={provider.name} className={classes.large}>{provider.name[0].toUpperCase()}</Avatar>
            <span style={{ paddingLeft: 30, margin: 'auto 0', textAlign: 'left', fontWeight: 600 }}>Autenticar com {provider.verbose_name}</span>
          </div>
        </CardContent>
      </Card>
    </CardButton>
  )
}

const ExternalAuthSignInActions = ({ history }) => {
  const [providers, setProviders] = useState([])

  useEffect(() => {
    Axios.get(`${API_HOST}/external-auth/`)
      .then(r => setProviders(r.data))
  }, [])

  return (
    <>{providers.map(p => <ProviderSignInCard provider={p} history={history} />)}</>
  )
}

export default withRouter(ExternalAuthSignInActions)
