
import PropTypes from 'prop-types';
import Upload from './Upload';
import { putFileinFormData } from '../utils/upload';

class DiskUpload extends Upload {
  constructor(props) {
    super(props);
    this.file = putFileinFormData(props.file);
    this.headers = { 'Content-Type': props.file.type };
    this.method = 'post';
  }
}

DiskUpload.propTypes = {
  file: PropTypes.instanceOf('File'),
  uploadUrl: PropTypes.string,
  onUploadProgress: PropTypes.func,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};
export default DiskUpload;
