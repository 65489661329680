import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'informed';
import { withRouter, Link } from 'react-router-dom';
import { get } from 'lodash';
import axios from 'axios';
import { toast } from 'react-toastify';
import { InputText } from '../components/inputs';
import { API_HOST } from '../consts';
import Alert from '../components/Alert';
import Logo from '../assets/images/logos/Logos';
import { ThemeContext } from '../contexts/ThemeContext';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import ExternalAuthSignInActions from '../components/ExternalAuthSignInActions';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeForm = this.changeForm.bind(this);
  }

  state = {
    submitting: false,
    errors: [],
    login: true
  };

  handleSubmit(values) {
    this.setState({ submitting: true });

    if (!getCookieConsentValue()) {
      toast.error('Por favor, é necessário aceitar os cookies para continuar.');
      return;
    }

    axios
      .post(`${API_HOST}/login`, values)
      .then((response) => {
        const { history } = this.props;
        localStorage.setItem('token', response.data.token);
        history.push('/');
      })
      .catch((error) => {
        const status = get(error, 'response.status');
        if (status === 400) {
          const errors = get(error, 'response.data', {});
          this.formApi.setError('username', errors.username);
          this.formApi.setError('password', errors.password);
          if (errors.non_field_errors) {
            this.setState({ errors: errors.non_field_errors });
          }
        } else {
          toast.error('Erro ao efetuar login. Tente novamente mais tarde.');
        }

        this.setState({ submitting: false });
      });
  }

  changeForm() {
    this.setState({ login: !this.state.login });
  }

  render() {
    const { submitting, errors, login } = this.state;
    const loginForm = (
      <div>
        <Form
          onSubmit={this.handleSubmit}
          getApi={(formApi) => {
            this.formApi = formApi;
          }}
          initialValues={{ username: '', password: '' }}
        >
          <div className="form-sign">
            {errors.map((error) => (
              <Alert
                key={error}
                category="danger"
                onClick={() =>
                  this.setState({ errors: errors.filter((e) => e !== error) })
                }
              >
                {error}
              </Alert>
            ))}
            <div className="group-input mt-1">
              <i className="fas fa-user" />
              <InputText placeholder="EMAIL" field="username" />
            </div>
            <div className="group-input mb-4">
              <i className="fas fa-lock" />
              <InputText placeholder="SENHA" field="password" type="password" />
            </div>
            <div className="action-login mt-3">
              <button
                type="submit"
                className="btn btn-padrao bg-padrao"
                disabled={submitting}
                style={{ margin: '0 auto' }}
              >
                {submitting ? 'ENTRANDO...' : 'ENTRAR'}
              </button>
            </div>
            <div className="mt-3">
              <Link to="/forgot-password">
                <small>Esqueci minha senha</small>
              </Link>
            </div>
          </div>
        </Form>
      </div>
    );

    const esqueciSenha = (
      <div>
        <Form
          onSubmit={this.handleSubmit}
          getApi={(formApi) => {
            this.formApi = formApi;
          }}
          initialValues={{ username: '', password: '' }}
        >
          <div className="form-sign">
            <p>Ingrese seu email para recuperar sua senha</p>
            {errors.map((error) => (
              <Alert
                key={error}
                category="danger"
                onClick={() =>
                  this.setState({ errors: errors.filter((e) => e !== error) })
                }
              >
                {error}
              </Alert>
            ))}
            <div className="group-input mt-1">
              <i className="fas fa-user" />
              <InputText placeholder="EMAIL" field="username" />
            </div>

            <div className="action-login mt-3">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={submitting}
                style={{ margin: '0 auto' }}
              >
                {submitting ? 'ENVIANDO...' : 'ENVIAR'}
              </button>
            </div>
          </div>
        </Form>
        <div className="text-center recuperar-senha mt-2">
          <button onClick={this.changeForm} rel="noopener noreferrer">
            Voltar
          </button>
        </div>
      </div>
    );

    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <section className="login-bg">
            <div className="container">
              <div className="box-form text-center">
                <figure>
                  <img
                    src={theme.logoImg || Logo.client || Logo.main}
                    className="logo-login"
                    alt="logo-fgv"
                    title="2.0"
                  />
                </figure>
                {login ? loginForm : esqueciSenha}
                <ExternalAuthSignInActions />
              </div>
            </div>
            <CookieConsent buttonText="Aceitar cookies">
              Nosso website coleta informações do seu dispositivo e da sua
              navegação por meio de cookies para permitir e melhorar o
              funcionamento técnico das páginas e garantir métricas de segurança
              dos dados.{' '}
              <a
                style={{ color: '#00bbff' }}
                target="blank"
                href="https://educat.com.br/wp-content/uploads/2021/12/Anexo-3-Politica-de-Privacidade-e-Politica-de-Cookies.pdf"
              >
                Confira nossa Política de Privacidade e Política de Cookies.
              </a>
            </CookieConsent>
          </section>
        )}
      </ThemeContext.Consumer>
    );
  }
}

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withRouter(Login);
