import React, { Component } from 'react';
import axios from 'axios';
import { API_HOST } from '../../consts';
import StackedBarChart from '../../components/StackedAreaChart';
import ParticipacaoDreColumn from '../../components/ParticipacaoDreColumn';
import { get } from 'lodash';
import { Nav, NavItem } from 'reactstrap';
import { FooterAvalia } from '../../containers/global/FooterAvalia/styles';
import { ThemeContext } from '../../contexts/ThemeContext';
import Logo from '../../assets/images/logos/Logos';
import AvaliaLogo from '../../assets/images/logos/avalia-logo.png';
import MapGraph from './MapGraph';
import HeaderDre from '../../components/HeaderDre';


const dre_name = {
  1:'DRE ALTA FLORESTA',
  2:'DRE BARRA DO GARÇAS',
  3:'DRE CONFRESA',
  4:'DRE CUIABÁ',
  5:'DRE CÁCERES',
  6:'DRE DIAMANTINO',
  7:'DRE JUÍNA',
  8:'DRE MATUPÁ',
  9:'DRE PONTES E LACERDA',
  10:'DRE PRIMAVERA DO LESTE',
  11:'DRE QUERÊNCIA',
  12:'DRE RONDONÓPOLIS',
  13:'DRE SINOP',
  14:'DRE TANGARÁ DA SERRA',
  15:'DRE VÁRZEA GRANDE',
}

const etapa_name ={
  2:'EF 2º ano',
  3:'EF 3º ano',
  4:'EF 4º ano',
  5:'EF 5º ano',
  6:'EF 6º ano',
  7:'EF 7º ano',
  8:'EF 8º ano',
  9:'EF 9º ano',
  11:'EM 1ª série',
  12:'EM 2ª série',
  13:'EM 3ª série',
}

const disciplina_name = {
  1:'Linguagens, Códigos e suas Tecnologias',
  2:'Matemática e suas Tecnologias'
}

class DrePage extends Component {
  state = {
    loading: false,
    boletimData: {}
  };

  componentDidMount() {
    this.fetchBoletimData();
  }
  componentWillUnmount() {
    this.state.loading = true;
  }

  fetchBoletimData = () => {
    // const avaliaPk = this.props.match.params.id;
    const cd_disciplina = this.props.match.params.cd_disciplina;

    const nr_etapa = this.props.match.params.nr_etapa;
    const codigo_dre = this.props.match.params.codigo_dre;

    axios
      .get(
        `${API_HOST}/avalia/boletim_mt/grafico_mapa?CD_DISCIPLINA=${cd_disciplina}&NR_ETAPA=${nr_etapa}`
      )
      .then((res) => {
        this.setState({ loading: true, boletimData: res.data });
      })
      .catch((err) => console.error(err));
  };
  render() {
    const { boletimData, loading } = this.state;
    const infoHeader = this.props.match.params;
    return (

      <>
        <div className="container">
          <div className="row header-row">
            <div className="col-sm-6 col-md-6">
              <div className="col-sm-6 col-md-6">
                <Nav className="nav-logos">
                  <NavItem>
                    <figure>
                      <ThemeContext.Consumer>
                        {(theme) => (
                          <img
                            className={
                              Logo.client ? 'logo-client' : 'logo-main'
                            }
                            src={AvaliaLogo}
                            height="70"
                            style={{ opacity: '0' }}
                          />
                        )}
                      </ThemeContext.Consumer>
                    </figure>
                  </NavItem>
                </Nav>
              </div>
            </div>
          </div>
        </div>
        <div
          id="nav-expand"
          className="nav-expand"
          style={{ background: '#3C4583' }}
        >
          <Nav
            className="navbar-links"
            style={{ background: '#3C4583', border: 'none' }}
          />
        </div>

        <div style={{ marginTop: '5rem', background: '#fff' }}>
          <div className="container mb-4 pb-4">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h1 style={{marginTop:'1rem'}}>Relatório de proficiência dos alunos por DRE</h1>
                    <hr />
                  </div>
                  <div>
                  <HeaderDre
                      schoolYear={etapa_name[`${infoHeader.nr_etapa}`]}
                      discipline={disciplina_name[`${infoHeader.cd_disciplina}`]}
                      dreAndMunicipio={dre_name[`${infoHeader.codigoDre}`]}
                    />
                  </div>
                  <div className="card-body">
                    {boletimData && loading && (
                      <MapGraph data={boletimData.dres} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterAvalia />
      </>
    );
  }
}

export default DrePage;
