import React from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { API_HOST } from '../consts';
import Logo from '../assets/images/logos/Logos';
import { ThemeContext } from '../contexts/ThemeContext';

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: '', submit: true };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submit: false });

    const token = get(this.props, 'match.params.token');
    const data = new FormData();
    data.append('password', this.state.value);
    data.append('token', token);

    axios.post(`${API_HOST}/password-reset/confirm/`, data)
      .then(() => {
        const { history } = this.props;
        toast.success('Sua senha foi alterada com sucesso.');
        history.push('/login');
      })
      .catch((error) => {
        const isBadRequest = error.response && error.response.status === 404;
        const { history } = this.props;
        if (isBadRequest) {
          toast.error('Desculpe, esse link já foi utilizado ou expirou.');
          history.push('/forgot-password');
        } else {
          toast.error('Ocorreu um erro e não foi possível redefinir sua senha no momento.');
          history.push('/login');
        }

        this.setState({ submit: true });
      });
  }

  render() {
    const { submit } = this.state;
    return (
      <section className="login-bg">
        <div className="container">
          <div className="box-form text-center">
            <figure>
              <ThemeContext.Consumer>
                {theme => (
                  <img src={theme.logoImg || Logo.client || Logo.main} className="logo-login" alt={Logo.clientText || Logo.mainText} />
                )}
              </ThemeContext.Consumer>
            </figure>
            <div className="group-input mt-1">
              <h6>Insira sua nova senha de acesso ao sistema</h6>
              <form onSubmit={this.handleSubmit}>
                <input className="form-control login-mail" type="password" value={this.state.value} onChange={this.handleChange} placeholder="Nova senha" />
                <div className="action-login mt-3">
                  <button
                    className="btn btn-padrao bg-padrao"
                    type="submit"
                    disabled={this.state.value.length === 0 || submit === false}
                  >
                    {submit ? 'REDEFINIR SENHA' : 'REDEFININDO SENHA...' }

                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default withRouter(ChangePassword);
