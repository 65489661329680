import React from 'react';
import { BasicText, asField } from 'informed';
import { Icon } from 'react-fa';

export default asField(({
  fieldState, field, onClick, ...props
}) => (
  <div className="wrap">
    <div className="search">
      <BasicText
        className="search-term"
        placeholder="Pesquisar..."
        id={field}
        fieldState={fieldState}
        {...props}
      />
      <button className="search-button" onClick={onClick}>
        <Icon name="search" />
      </button>
    </div>
  </div>
));
