import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class DocumentoModal extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
  };

  state = {
    isOpen: false,
  }

  constructor(props) {
    super(props)
    this.state = { isOpen: false }
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  componentWillReceiveProps() {
    this.setState({ isOpen: this.props.isOpen });
  }

  render() {
    return (
      <Modal isOpen={this.state.isOpen} toggle={this.toggle} size="lg">
        <ModalHeader toggle={this.toggle}>
          {this.props.title}
        </ModalHeader>
        <ModalBody>
          <img src={this.props.link} alt={this.props.title} height="95%" width="95%" hspace="20" />
        </ModalBody>
        <ModalFooter>
          <button onClick={() => this.setState({ isOpen: false })} className="btn btn-padrao bg-padrao" size="lg">Fechar</button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default DocumentoModal;
