import React, { Fragment } from 'react';
import { Form } from 'informed';
import axios from 'axios';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import InputSelectAsync from '../../components/inputs/InputSelectAsync';
import InputText from '../../components/inputs/InputText';
import { API_HOST } from '../../consts';

export default class AnadirEstudianteModal extends React.Component {
  state = {
    novoEstudante: false,
    loading: false,
  };

  fetchAlunos(search, callback) {
    axios.get(`${API_HOST}/login/user`, {
      params: {
        search,
        permissions: 'aplicacoes.participar',
      },
    })
    .then((response) => {
      const data = response.data.results || response.data;
      callback(null, {
        options: data.map(d => ({ value: d.id, label: d.last_name })),
      });
    })
    .catch(() => {
    });
  }

  adicionarUsuario = () => {
    this.setState({
      loading: true,
    });
    const { aplicacao, fetchCandidatos } = this.props;
    const { values } = this.state.formState;
    const data = {
      aplicacao: aplicacao.id,
      ...values,
    };
    axios.post(`${API_HOST}/aplicacoes/candidato/user`, data)
      .then(() => {
        this.setState({
          loading: false,
        });
        fetchCandidatos();
        toast.success('Candidato adicionado com sucesso!');
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        toast.error((
          <ul>
            {get(error, 'response.data.username')}
          </ul>
        ));
      });
  }

  adicionarCandidato = () => {
    this.setState({
      loading: true,
    });
    const { aplicacao, fetchCandidatos } = this.props;
    const { values } = this.state.formState;
    const data = {
      aplicacao: aplicacao.id,
      usuario: get(values, 'usuario.value'),
    };
    axios.post(`${API_HOST}/aplicacoes/candidato`, data)
      .then(() => {
        this.setState({
          loading: false,
        });
        fetchCandidatos();
        toast.success('Candidato adicionado com sucesso!');
      })
      .catch((error) => {
        if (get(error, 'response.status' === 400)) {
          toast.error(get(error, 'response.data.detail'));
        } else {
          toast.error('Erro ao adicionar candidato.');
        }
        this.setState({
          loading: false,
        });
      });
  }

  toggleNovo = () => {
    this.setState({ novoEstudante: !this.state.novoEstudante });
  };

  renderNovoCandidato = () => {
    return (
      <Form
        onChange={formState => this.setState({ formState })}
        getApi={(formApi) => { this.formApi = formApi; }}
      >
        {() => (
          <Fragment>
            <div className="row p-4">
              <InputSelectAsync
                isMulti
                label="Alunos"
                col="col-md-12"
                field="usuario"
                loadOptions={this.fetchAlunos}
              />
            </div>
          </Fragment>
        )}
      </Form>
    );
  }

  renderNovoEstudante = () => (
    <Form
      onChange={formState => this.setState({ formState })}
    >
      {({ formApi }) => {
        return (
          <Fragment>
            <div className="row p-4">
              <InputText
                required
                col="col-sm-6"
                label="Nome"
                field="nome"
              />
              <InputText
                required
                col="col-sm-6"
                label="RA"
                field="username"
              />
              <div className="col-12">
                <p className="azul">O novo aluno será adicionado ao sistema e relacionado a essa aplicação.</p>
              </div>
            </div>
          </Fragment>
        );
      }}
    </Form>
  );

  render() {
    const { toggle, isOpen } = this.props;
    const { novoEstudante, loading } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
      >
        <ModalHeader toggle={toggle}>
          Adicionar Estudantes
        </ModalHeader>
        <ModalBody style={{ borderRadius: '0px 0px 5px 5px' }} className="p-0 no-overflow">
          {novoEstudante ? this.renderNovoEstudante() : this.renderNovoCandidato() }
        </ModalBody>
        <ModalFooter>
          {novoEstudante ?
            (
              <Fragment>
                <button onClick={this.toggleNovo} className="btn btn-white">Cancelar</button>
                <button onClick={this.adicionarUsuario} disabled={loading} className="btn btn-padrao bg-verde">{loading ? 'Adicionando...' : 'Adicionar'}</button>
              </Fragment>
            )
          :
              (
                <Fragment>
                  <button onClick={toggle} className="btn btn-white">Cancelar</button>
                  <button onClick={this.toggleNovo} className="btn btn-padrao bg-verde">Novo Aluno</button>
                  <button onClick={this.adicionarCandidato} disabled={loading} className="btn btn-padrao bg-padrao">{loading ? 'Adicionando...' : 'Adicionar'}</button>
                </Fragment>
              )}
        </ModalFooter>
      </Modal>
    );
  }
}
