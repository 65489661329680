import { get, map, size } from 'lodash';
import React from 'react';
import ItemAlternativa from './ItemAlternativa';
import LabelView from './LabelView';
import Video from './Video';
import styled from 'styled-components';
const ItemPageBreak = styled.div`
  width: 100%;
  @media print {
    .conteudo-alternativa {
      page-break-inside: avoid;
    }
  }
`;
class ItemConteudo extends React.Component {
  state = {
    activeTab: 'conteudo',
    matrizes_referencia: [],
    fetchingMatrizesReferencia: true
  };
  static defaultProps = {
    labels: {}
  };

  renderConteudo() {
    const { item, labels } = this.props;
    return (
      <div id={`item-${get(item, 'id')}`}>
        <div className="row">
          <LabelView
            title={labels.situacao_problema || 'Situação Problema'}
            text={get(item, 'situacao_problema', '-')}
            html
            name="situacao_problema"
          />
          <LabelView
            title={labels.comando_resposta || 'Comando Resposta'}
            text={get(item, 'comando_resposta', '-')}
            html
            name="comando_resposta"
          />
          {size(get(item, 'arquivos')) > 0 && (
            <LabelView
              title="Vídeo"
              text={map(get(item, 'arquivos'), (arquivo) => (
                <Video
                  key={arquivo.src}
                  src={arquivo.src}
                  type={arquivo.content_type}
                />
              ))}
              name="arquivos"
            />
          )}
          {this.renderAlternativas()}
        </div>
      </div>
    );
  }

  renderAlternativas() {
    const alternativas = get(this.props, 'item.alternativas', []);

    if (alternativas.length === 0) {
      return (
        <div className="col-md-12">
          <p className="azul">
            <i className="fas fa-exclamation-circle" /> Este item não possui
            alternativas.
          </p>
        </div>
      );
    }

    const gabarito = alternativas.find((x) => x.gabarito);

    return (
      <ItemPageBreak>
        <div className="col-md-12 conteudo-alternativa">
          <ul className="alternatives style-list">
            {alternativas.map((alternativa) => (
              <ItemAlternativa
                showJustificativa
                key={alternativa.id}
                {...alternativa}
              />
            ))}
          </ul>
        </div>
        <div className="row">
          <div className="col-md-12">
            <LabelView
              html
              title="Justificativa do gabarito"
              text={get(gabarito, 'justificativa', '-')}
              name="alternativa"
            />
          </div>
        </div>
      </ItemPageBreak>
    );
  }

  renderDiscursivo() {
    const { item } = this.props;
    return (
      <div id={`item-${get(item, 'id')}`}>
        <div className="row">
          <LabelView
            html
            name="situacao_problema"
            title="Situação Problema"
            text={get(item, 'situacao_problema', '-')}
          />
          <LabelView
            html
            name="expectativa_resposta"
            title="Comando resposta"
            text={get(item, 'comando_resposta', '-')}
          />
          <LabelView
            html
            name="expectativa_resposta"
            title="Expectativa de resposta"
            text={get(item, 'expectativa_resposta', '-')}
          />
          <LabelView
            html
            name="orientacao_correcao"
            title="Orientação de correção"
            text={get(item, 'orientacao_correcao', '-')}
          />
        </div>
        <div className="row">
          <LabelView
            html
            col="col-md-3"
            title="Mínimo de palavras"
            text={get(item, 'min_palavras', '-')}
            name="min_palavras"
          />
          <LabelView
            html
            col="col-md-3"
            title="Máximo de palavras"
            text={get(item, 'min_palavras', '-')}
            name="max_palavras"
          />
          <LabelView
            html
            col="col-md-3"
            title="Mínimo de linhas"
            text={get(item, 'min_linhas', '-')}
            name="min_linhas"
          />
          <LabelView
            html
            col="col-md-3"
            title="Máximo de linhas"
            text={get(item, 'min_linhas', '-')}
            name="max_linhas"
          />
        </div>
      </div>
    );
  }

  render() {
    const { item } = this.props;
    const tipo = get(item, 'tipo.id');
    const tipo_referencia = get(item, 'tipo.referencia', '');

    if (tipo === 6 || tipo_referencia === 6) {
      return this.renderDiscursivo();
    }

    return this.renderConteudo();
  }
}

export default ItemConteudo;
