import React from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Form } from 'informed';
import { get, size } from 'lodash';
import FormContainer from '../../containers/Form';
import { BaseForm } from '../../base';
import Loading from '../../components/Loading';
import Item from '../../components/Item';
import { API_HOST } from '../../consts';
import { InputCheckbox, InputSelect } from '../../components/inputs';

const mapEncomenda = (d) => ({ label: d.nome, value: d.id });
const mapEncomendaVinculada = (d) => ({
  label: get(d, 'encomenda.nome'),
  value: get(d, 'encomenda.id')
});

class Confirmar extends BaseForm {
  state = {
    item: {},
    fetchingData: false,
    fetchingEncomendas: false,
    encomendas: [],
    encomendasVinculadas: [],
    encomendasPedido: [],
    entregas: 0
  };

  componentDidMount() {
    this.fetchData();
    this.fetchEncomendas();
    this.fetchEncomendasVinculadas();
    this.fetchEntregas();
  }

  get shouldEnableEncomendaSelect() {
    return get(this.state, 'formState.values.vincular');
  }

  fetchData() {
    this.setState({ fetchingData: true });

    axios.get(`${API_HOST}/itens/item/${this.id}`).then((response) => {
      this.setState({
        item: response.data,
        fetchingData: false
      });
    });
  }

  fetchEncomendas() {
    this.setState({ fetchingEncomendas: true });
    axios
      .get(`${API_HOST}/encomenda/encomenda`, {
        params: {
          compativel_com: this.id,
          status: 2
        }
      })
      .then((response) => {
        this.setState({
          encomendas: response.data.results.map(mapEncomenda),
          fetchingEncomendas: false
        });
      });
  }

  fetchEncomendasVinculadas() {
    axios
      .get(`${API_HOST}/encomenda/elaborador_item`, {
        params: {
          item: this.id
        }
      })
      .then((response) => {
        this.setState({
          encomendasVinculadas: response.data.map(mapEncomendaVinculada)
        });
      });
  }

  fetchEntregas() {
    axios.get(`${API_HOST}/itens/entrega`, {
      params: {
        item: this.id
      }
    })
      .then((response) => {
        this.setState({
          entregas: response.data.count
        });
      });
  }

  handleSubmitError = (error) => {
    this.setState({ submitting: false });
    const status = get(error, 'response.status');
    if (status === 400) {
      const errorMessages = get(error, 'response.data.non_field_errors', []);
      toast.error(
        <ul>
          {errorMessages.map((d) => (
            <li className="mt-3" key={d}>
              {d}
            </li>
          ))}
        </ul>,
        { autoClose: 10000 }
      );
    }
  };

  handleSubmitSuccess = (response) => {
    this.setState({ submitting: false });
    const messages = get(response, 'data.messages', []);
    if (size(messages) > 0) {
      toast.success(
        <ul>
          {messages.map((d) => (
            <li className="mb-3" key={d}>
              {d}
            </li>
          ))}
        </ul>
      );
    }
    const { history } = this.props;
    history.push('/itens');
  };

  handleSubmit = () => {
    const { formState } = this.state;
    this.setState({ submitting: true });
    axios
      .post(`${API_HOST}/encomenda/finalizar_item/${this.id}`, formState.values) // no codigo da cmmg está  formValues
      .then(() => {
        this.handleSubmitSuccess();
        this.fetchUpdateVersion();
        this.fetchUpdateVersionAlternativa();
      })
      .catch(this.handleSubmitError);
  };

  fetchUpdateVersion() {
    const { formState } = this.state;
    return axios
      .post(
        `${API_HOST}/itens/item/${this.id}/save_log_itens`,
        formState.values
      )
      .then((response) => { })
      .catch((error) => console.error(error));
  }

  fetchUpdateVersionAlternativa() {
    const { formState } = this.state;
    return axios
      .post(
        `${API_HOST}/itens/alternativa/${this.id}/save_log_alternativas`,
        formState.values
      )
      .then((response) => { })
      .catch((error) => console.error(error));
  }

  handleNext = (url) => {
    if (url === '/itens') {
      this.handleSubmit();
    } else {
      const { history } = this.props;
      history.push(url);
    }
  };

  renderEncomendasVinculadas() {
    const { encomendasVinculadas } = this.state;

    if (encomendasVinculadas.length === 0) {
      return null;
    }
    return (
      <p className="form-text text-muted">
        Este item já está vinculado às encomendas:{' '}
        {encomendasVinculadas.map((d) => d.label).join(', ')}
      </p>
    );
  }

  renderEntregas() {
    const { entregas } = this.state;

    if (entregas === 0) {
      return null;
    }

    const inflection = entregas === 1 ? '' : 'es';
    return (
      <p className="form-text text-muted">
        Este item já foi enviado para revisão {entregas} vez{inflection}.
      </p>
    );
  }

  encomendaNaoVinculada = (encomenda) => {
    const { encomendasVinculadas } = this.state;
    return !encomendasVinculadas.find((e) => e.value === encomenda.value);
  };

  render() {
    const {
      item,
      fetchingData,
      encomendas,
      submitting,
      fetchingEncomendas,
    } = this.state;

    if (fetchingData) {
      return <Loading />;
    }

    return (
      <FormContainer
        title="Confirmar"
        previous="pendencias"
        last="/itens"
        onClick={this.handleNext}
        disabled={submitting}
      >
        <div className="row mb-3">
          <div className="col-md-12">
            <Item item={item} hasParametro={false} />
          </div>
          <div className="col-md-12 mt-4">
            <Form onChange={(formState) => this.setState({ formState })}>
              <InputCheckbox
                col="col-md-12"
                field="enviar_revisao"
                label="Enviar para revisão"
                help="Entregue o item, deixando-o disponível para revisão. Não será mais possível editar o item até que ele seja revisado."
              />
              <div className="mb-4" style={{ paddingLeft: 55 }}>
                {this.renderEntregas()}
              </div>
              {item.criado_pela_encomenda ? (
                <>
                  {this.renderEncomendasVinculadas()}
                </>
              ) : (
                <>
                  <InputCheckbox
                    col="col-md-12 mb-3"
                    field="vincular"
                    label="Vincular a uma ou mais encomendas"
                    help="Selecione as encomendas as quais o item deve ser vinculado. São exibidas somente as encomendas com matriz de referência, competência, habilidade e dificuldade presumida com o item criado."
                  />
                  <div style={{ paddingLeft: 55 }}>
                    <InputSelect
                      multi
                      field="encomendas"
                      label="Encomendas"
                      isLoading={fetchingEncomendas}
                      options={encomendas.filter(this.encomendaNaoVinculada)}
                      disabled={!this.shouldEnableEncomendaSelect}
                    />
                  </div>
                </>
              )}
            </Form>
          </div>
        </div>
      </FormContainer>
    );
  }
}

export default withRouter(Confirmar);
