import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { range } from 'lodash'

const PreviewEscalaLinear = ({
  inicio = 0,
  intervalo,
  fim = 1,
  className,
  textoInicial,
  textoFinal
}) => {

  const [ scaleSize, setScaleSize ] = useState([])

  const getArray = () => {

    const arr = range(inicio, parseInt(fim) + 1, intervalo)

    if (arr.length > 25) {
      return toast.error('O limite máximo de intervalos é: 25')
    }

    setScaleSize(arr)
  }
  useEffect(() => {
    getArray()
  }, [inicio, intervalo, fim])

  return (
    <div className={className}>
      <div className="__text">{textoInicial}</div>
      <div className="escala__linear">
        {scaleSize.map(x => (
          <div key={x} className="escala__linear--wrapper">
            <div className="escala__linear--vertical">
              <div className="escala__linear--point" />
              <div className="escala__linear--number">{x}</div>
            </div>
          </div>
        ))}
      </div>
      <hr className="escala__linear--line"/>
      <div className="__text">{textoFinal}</div>
    </div>
  )
}

export default styled(PreviewEscalaLinear)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .__text {
    width: 60px;
    text-align: center;
  }

  .escala__linear {
    width: 75%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .escala__linear--line {
    width: 70%;
    position: absolute;
    right: 15%;
  }

  .escala__linear--vertical {
    margin-top: 35px;
    height: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${props => props.theme.primaryColor};
  }

  .escala__linear--point {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: #e5e5e5;
    flex-wrap: 0;
    z-index: 1;
  }
`
