import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import BarChartSkill from '../../components/BarChartSkill';
import Axios from 'axios';
import { get } from 'lodash';
import { API_HOST } from '../../consts';
import { useEffect } from 'react';
import { ThemeContext } from 'styled-components';
import HeaderRelatorio from '../../components/HeaderRelatorio';

const ReportSkillLevelPage = ({ match, className }) => {
  const [habilidadeData, setHabilidadeData] = useState(null);
  const theme = useContext(ThemeContext);

  const fetchBoletimData = () => {
    Axios.get(
      `${API_HOST}/avalia/habilidade?prova_a_cd_disciplina=${match.params.disciplinaId}&cd_escola=${match.params.escolaId}&serie=${match.params.etapaId}`
    )
      .then((res) => {
        setHabilidadeData(res.data.results);
      })
      .catch((err) => console.error(err));
  };
  useEffect(() => {
    fetchBoletimData();
  }, []);

  const titulo = [
    'Linguagens, Códigos e suas Tecnologias',
    'Matemática e suas Tecnologias'
  ];
  const etapa = {
    2: 'EF 2º ano',
    3: 'EF 3º ano',
    4: 'EF 4º ano',
    5: 'EF 5º ano',
    6: 'EF 6º ano',
    7: 'EF 7º ano',
    8: 'EF 8º ano',
    9: 'EF 9º ano',
    11: 'EM 1ª série',
    12: 'EM 2ª série',
    13: 'EM 3ª série'
  };

  const allKeys = ['AB', 'B', 'AD', 'AV'];
  const colors = {
    AB: '#FFCECE',
    B: '#FFE74F',
    AD: '#BFEEA6',
    AV: '#ABD4EC',
    'Abaixo do básico': '#FFCECE',
    'Abaixo do Básico': '#FFCECE',
    Básico: '#FFE74F',
    Adequado: '#BFEEA6',
    Avançado: '#ABD4EC',
    base: '#F7F7F7',
    Aluno: '#FFFFFF',
    Escola: '#47BEF3',
    Turma: '#737272',
    DRE: '#B972F1',
    Estado: '#001AFF'
  };

  return (
    <div className={className}>
      <div style={{ marginTop: '3rem', background: '#fff' }}>
        <div className="container mb-4 pb-4">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h1>Relátorio diagnóstico de proficiência por habilidade - visão por escola</h1>
                  <hr />
                </div>
                <div
                  className="card-body"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                >
                  <HeaderRelatorio
                    schoolName={
                      habilidadeData ? get(habilidadeData[0], 'nm_escola') : ''
                    }
                    schoolYear={
                      habilidadeData
                        ? etapa[
                            habilidadeData
                              ? get(habilidadeData[0], 'serie')
                              : ''
                          ]
                        : ''
                    }
                    dreAndMunicipio={
                      habilidadeData ? get(habilidadeData[0], 'dre') : ''
                    }
                    disableStudent={false}
                  />

                  <div className="infos__school">
                    <h2>Habilidades a serem desenvolvidas</h2>
                    <span className="example-hability">
                      Os dados abaixo dizem respeito às habilidades de pior
                      desempenho, a partir de informações extraídas do
                      processamento do resultado de todas as escolas do Estado
                      de Mato Grosso até o momento. O gráfico demonstra a
                      porcentagem de acertos do aluno dentro do seu grupo de
                      proficiência por habilidade.
                    </span>
                  </div>

                  <div
                    className="school__material"
                    style={{ color: `${theme.primary}` }}
                  >
                    <span>
                      {
                        titulo[
                          habilidadeData
                            ? get(habilidadeData[0], 'prova_a_cd_disciplina') -
                              1
                            : ''
                        ]
                      }
                    </span>
                  </div>
                  <div
                    className="table-proficiency"
                    style={{
                      backgroundColor: `${theme.primary}`
                    }}
                  >
                    <span
                      style={{
                        color: '#E8E2DF'
                      }}
                    >
                      {
                        etapa[
                          habilidadeData ? get(habilidadeData[0], 'serie') : ''
                        ]
                      }
                    </span>
                  </div>
                  <hr className="pro-hr" />
                  {habilidadeData &&
                    habilidadeData.map((dataHability) => (
                      <div style={{ width: '100%' }}>
                        <div className="proeficiency__infos">
                          <>
                            <div className="axis__infos">
                              <div className="axis__text">
                                <span>
                                  Eixo de Conhecimento:{' '}
                                  <span
                                    style={{
                                      color: `${theme.primary}`,
                                      marginLeft: '1rem',
                                      fontWeight: '500'
                                    }}
                                  >
                                    {get(dataHability, 'competencia', '')}
                                  </span>
                                </span>
                              </div>

                              <div className="habilidade">
                                <span>Habilidade: </span>
                                <span className="skill-text">
                                  {get(dataHability, 'habilidade', '')}
                                </span>
                              </div>
                            </div>
                          </>
                          <div className="graphic">
                            <BarChartSkill
                              data={dataHability.porc_acertos_hab}
                              keys={allKeys}
                              colors={colors}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ReportSkillLevelPage);
