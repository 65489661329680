
import React, { useState } from 'react';
import ModalCreateComentario from './ModalCreateComentario';
import ItemComentario from './ItemComentario';

const ContainerItemComentarios = ({ item, api }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleModal = (value) => {
		setIsModalOpen(value);
	}

	return (
		<div style={{
			width: '30%', transition: 'width 0.3s'
		}}>
			< div style={{
				width: '100%',
			}}><button
				className="btn"
				onClick={() => handleModal(true)}
			>
					<i className="fas fa-comment-dots fa-lg" /></button >
				{item.comentarios && item.comentarios.map((elem) => <ItemComentario key={elem.id} comentario={elem} avaliacaoItem={item} api={api} />)}
			</div >
			<ModalCreateComentario isModalOpen={isModalOpen} handleModal={handleModal} avaliacaoItem={item} api={api} />
		</div >
	);
}

export default ContainerItemComentarios;
