import React from 'react';
import { get, debounce, isEmpty } from 'lodash';
import axios from 'axios';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { API_HOST, TIPOS_ITENS } from '../../consts';
import { BaseForm } from '../../base';
import Loading from '../../components/Loading';
import RawInputSummernote from '../../components/inputs/RawInputSummernote';
import InputFile from '../../components/inputs/InputFile';
import FormContainer from '../../containers/Form';
import PreviewEscalaLinear from '../../components/PreviewEscalaLinear';
import GradeLinearMultipla from '../../components/GradeLinearMultipla';
import swal from 'sweetalert2';
import { Icon } from 'react-fa';

const showDiv = (id) => {
  if (document.getElementById(id).style.display == 'none') {
    document.getElementById(id).style.display = 'flex';
  } else if (document.getElementById(id).style.display == 'flex') {
    document.getElementById(id).style.display = 'none';
  }
};

class ConteudoRevisao extends BaseForm {
  constructor(props) {
    super(props);
    this.onItemChange = this.onItemChange.bind(this);
    this.onAlternativaChange = this.onAlternativaChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.fetchAlternativas = this.fetchAlternativas.bind(this);
    this.patchAlternativa = this.patchAlternativa.bind(this);
    this.fetchGradeLinear = this.fetchGradeLinear.bind(this);
    this.onGradeLinearChange = this.onGradeLinearChange.bind(this);
    this.onGradeLinearMultiplaChange =
      this.onGradeLinearMultiplaChange.bind(this);
    this.onGradeLinearMultiplaExclude =
      this.onGradeLinearMultiplaExclude.bind(this);
    this.uploadArquivo = this.uploadArquivo.bind(this);
    this.uploadArquivoAlternativa = this.uploadArquivoAlternativa.bind(this);
    this.removeArquivo = this.removeArquivo.bind(this);
    this.removeArquivoAlternativa = this.removeArquivoAlternativa.bind(this);
    this.debouncedPatchAlternativa = [];
  }

  state = {
    collapse: {},
    alternativas: [],
    errors: [],
    fetchingData: true,
    arquivos: [],
    save: {},
    progressEvent: {},
    modalConfirmAlternativeRemoval: false,
    selectedAlternative: null,
    videoInputShouldDisplay: false,
    grade_linear: {}
  };

  toggle(index) {
    const collapse = [...this.state.collapse];
    collapse[index] = !collapse[index];
    this.setState({ collapse });
  }

  getAngleIcon(index) {
    if (this.state.collapse[index]) {
      return 'angle-down';
    }

    return 'angle-right';
  }

  componentDidMount() {
    if (this.props.item_id !== 'new') {
      this.fetchArquivos();
      this.fetchMetadata();

      Promise.all([
        this.fetchItem(),
        this.fetchAlternativas(),
        this.fetchGradeLinear()
      ]).then(() => this.setState({ fetchingData: false }));
    }
  }

  fetchItem() {
    return axios
      .get(`${API_HOST}/itens/item/${this.props.item_id}/form`)
      .then((response) => {
        const { tipo, ...rest } = response.data;

        if (tipo === TIPOS_ITENS.DISCURSIVO) {
          this.fields = [
            'situacao_problema',
            'expectativa_resposta',
            'orientacao_correcao',
            'min_palavras',
            'max_palavras',
            'min_caracteres',
            'max_caracteres',
            'comando_resposta'
          ];
        } else {
          this.fields = ['situacao_problema', 'comando_resposta'];
        }

        const values = this.getValues(rest);
        this.setState({ ...values, tipo });
      });
  }

  fetchArquivos() {
    return axios
      .get(`${API_HOST}/itens/item/${this.props.item_id}/arquivos`)
      .then((response) => {
        this.setState({
          arquivos: response.data,
          uploading: false
        });
      });
  }

  fetchMetadata() {
    axios
      .get(`${API_HOST}/params/param?model=item|alternativa`)
      .then((response) => {
        this.setState({ metadata: this.mapMetadata(response.data) });
      });
  }

  patchAlternativa(data, fetchAgain = false) {
    const { save } = this.state;
    save[data.id] = this.SALVANDO;
    this.setState({ save });

    axios.patch(`${API_HOST}/itens/alternativa/${data.id}`, data).then(() => {
      const { save } = this.state;
      save[data.id] = this.SALVO;
      this.setState({ save });

      if (fetchAgain) {
        this.fetchAlternativas();
      }

      // if (atualizarDistratores) {
      //   const { alternativas } = this.state;
      //   alternativas.forEach((alternativa) => {
      //     if (alternativa.id !== data.id) {
      //       alternativa.gabarito = false;
      //     }
      //   });
      //   this.setState({ alternativas });
      // }
    });
  }

  fetchAlternativas() {
    return axios
      .get(`${API_HOST}/itens/alternativa`, {
        params: {
          item: this.props.item_id
        }
      })
      .then((response) => {
        const save = {};
        response.data.forEach(({ id }) => {
          save[id] = this.ATUALIZADO;
        });
        save.item = this.ATUALIZADO;

        this.setState({ save, alternativas: response.data });

        this.debouncedPatchAlternativa = response.data.map(() =>
          debounce(this.patchAlternativa, 1000)
        );
      });
  }

  fetchGradeLinear() {
    return axios
      .get(`${API_HOST}/itens/item/${this.props.item_id}`)
      .then((response) => {
        const jsonResponse = response.data.grade_linear;

        let grade_linear = {};

        if (jsonResponse) {
          grade_linear = JSON.parse(jsonResponse.replace(/'/g, '"'));
        }

        if (grade_linear && grade_linear.column) {
          this.setState({ column: grade_linear.column });
        }

        if (grade_linear && grade_linear.row) {
          this.setState({ row: grade_linear.row });
        }

        this.setState({ grade_linear });
      });
  }

  onItemChange(value, name) {
    const data = { [name]: value };
    const { errors, save } = this.state;
    errors[name] = [];
    save.item = this.MODIFICADO;
    this.setState({ save, errors, ...data });
    this.patchItem({
      ...this.getValues(this.state),
      [name]: value
    });
  }

  onAlternativaChange(value, field, index) {
    const { alternativas, errors } = this.state;
    const alternativa = alternativas[index];
    alternativa[field] = value;

    const { save } = this.state;
    save[alternativa.id] = this.MODIFICADO;
    this.setState({ save });

    if (errors.alternativas && errors.alternativas[index]) {
      errors.alternativas[index][field] = [];
    }

    this.setState({ alternativas, errors });

    const debouncedPatchAlternativa = this.debouncedPatchAlternativa[index];
    // const atualizarDistratores = field === 'gabarito' && value;

    if (debouncedPatchAlternativa) {
      if (field === 'gabarito') {
        this.patchAlternativa(alternativa, true);
      } else {
        debouncedPatchAlternativa(alternativa);
      }
    }
  }

  onGradeLinearChange(value, name) {
    const { errors, save, grade_linear } = this.state;
    const data = {
      grade_linear: {
        ...grade_linear,
        [name]: value
      }
    };

    errors[name] = [];
    save.item = this.MODIFICADO;
    this.setState({ save, errors, ...data });
    this.patchItem({
      ...this.getValues(this.state),
      ...data
    });
  }

  onGradeLinearMultiplaChange(value, name) {
    const { errors, save, grade_linear } = this.state;

    let data = {};

    if (isEmpty(grade_linear[name])) {
      data = {
        grade_linear: {
          ...grade_linear,
          [name]: [value]
        }
      };
    } else {
      if (grade_linear[name].find((x) => x.position === value.position)) {
        grade_linear[name] = grade_linear[name].map((x) => {
          if (x.position === value.position) {
            return { position: x.position, value: value.value };
          } else {
            return x;
          }
        });
      } else {
        grade_linear[name] = [...grade_linear[name], value];
      }
      data = { grade_linear };
    }

    errors[name] = [];
    save.item = this.MODIFICADO;
    this.setState({ save, errors, ...data });
    this.patchItem({
      ...this.getValues(this.state),
      ...data
    });
  }

  onGradeLinearMultiplaExclude(name, position) {
    const { errors, save, grade_linear } = this.state;

    grade_linear[name] = grade_linear[name].filter(
      (x) => x.position !== position
    );

    const data = { grade_linear };

    errors[name] = [];
    save.item = this.MODIFICADO;
    this.setState({ save, errors, ...data });
    this.patchItem({
      ...this.getValues(this.state),
      ...data
    });
  }

  patchItem = debounce((data) => {
    const { save } = this.state;
    save.item = this.SALVANDO;
    this.setState({ save });

    axios
      .patch(`${API_HOST}/itens/item/${this.props.item_id}`, data)
      .then(() => {
        const { save } = this.state;
        save.item = this.SALVO;
        this.setState({ save });
      });
  }, 1000);

  onSubmit(url) {
    axios
      .get(
        `${API_HOST}/itens/item/${this.props.item_id}/validate?form=conteudo`
      )
      .then((res) => {
        const { history } = this.props;
        if (res.data === 'EXCEEDED') {
          swal
            .fire({
              text: 'Este item ultrapassou o limite recomendado, caso deseje prosseguir mesmo assim, clique em "Confirmar"',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#45c0f5',
              cancelButtonColor: '#c8700a',
              confirmButtonText: 'Confirmar',
              cancelButtonText: 'Cancelar'
            })
            .then((result) => {
              if (result.isConfirmed) {
                history.push(`${url}`);
              }
            });
        } else {
          history.push(`${url}`);
        }
      })
      .catch((error) => {
        this.onValidateError(error);
      });
  }

  onValidateError(error) {
    const status = get(error, 'response.status');

    if (status === 400) {
      const errors = get(error, 'response.data');
      this.setState({ errors });
      this.renderErrorToast(errors, 'conteudo');
    }
  }

  formIsDisabled() {
    const { save } = this.state;
    return Object.keys(save).some(
      (key) => save[key] === this.SALVANDO || save[key] === this.MODIFICADO
    );
  }

  renderError(error) {
    return (
      <p key={error} className="text-danger">
        {error}
      </p>
    );
  }

  descerAlternativa(alternativa) {
    axios
      .patch(`${API_HOST}/itens/alternativa/${alternativa.id}`, {
        posicao: alternativa.posicao + 1
      })
      .then(this.fetchAlternativas);
  }

  subirAlternativa(alternativa) {
    axios
      .patch(`${API_HOST}/itens/alternativa/${alternativa.id}`, {
        posicao: alternativa.posicao - 1
      })
      .then(this.fetchAlternativas);
  }

  uploadArquivo(event) {
    const file = get(event, 'target.files.0');

    if (file) {
      if (file.type !== 'video/mp4') {
        toast.error('No momento, somente o formato .mp4 é suportado.');
        return;
      }

      this.setState({ uploading: true });

      const data = new FormData();
      data.append('file', file);
      axios
        .post(`${API_HOST}/itens/item/${this.props.item_id}/arquivos`, data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (progressEvent) => {
            if (progressEvent) {
              this.setState({ progressEvent });
            }
          }
        })
        .then((response) => {
          const { arquivos } = this.state;
          this.setState({
            arquivos: [response.data, ...arquivos],
            uploading: false
          });
        })
        .catch((error) => {
          this.setState({ uploading: false });
          const status = get(error, 'response.status');
          let message =
            'Ocorreu um erro e não foi possível submeter o arquivo.';
          if (status === 400) {
            message = get(error, 'response.data.file.0') || message;
          }
          toast.error(message);
        });
    }
  }

  uploadArquivoAlternativa(event, i) {
    const { alternativas } = this.state;
    const alternativa = alternativas[i];

    const file = get(event, 'target.files.0');

    if (file) {
      if (file.type !== 'video/mp4') {
        toast.error('No momento, somente o formato .mp4 é suportado.');
        return;
      }

      this.setState({ [`uploading_${alternativa.id}`]: true });

      const data = new FormData();
      data.append('file', file);

      axios
        .post(`${API_HOST}/itens/alternativa/${alternativa.id}/arquivo`, data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (progressEvent) => {
            if (progressEvent) {
              this.setState({
                [`progressEvent_${alternativa.id}`]: progressEvent
              });
            }
          }
        })
        .then((response) => {
          const { alternativas } = this.state;
          alternativas[i].arquivo = response.data;

          this.setState({
            alternativas,
            [`uploading_${alternativa.id}`]: false
          });
        })
        .catch((error) => {
          this.setState({ [`uploading_${alternativa.id}`]: false });

          const status = get(error, 'response.status');
          let message =
            'Ocorreu um erro e não foi possível submeter o arquivo.';

          if (status === 400) {
            message = get(error, 'response.data.file.0') || message;
          }

          toast.error(message);
        });
    }
  }

  removeArquivo(file) {
    axios
      .delete(`${API_HOST}/uploads/${file.id}`)
      .then(() => {
        const { arquivos } = this.state;
        this.setState({ arquivos: arquivos.filter((f) => f.id !== file.id) });
        toast.success('Arquivo removido com sucesso.');
      })
      .catch(() => {
        toast.error('Ocorreu um erro e não foi possível remover o arquivo');
      });
  }

  removeArquivoAlternativa(file, i) {
    axios
      .delete(`${API_HOST}/uploads/${file.id}`)
      .then(() => {
        const { alternativas } = this.state;
        alternativas[i].arquivo = null;
        this.setState({ alternativas });
        toast.success('Arquivo removido com sucesso.');
      })
      .catch(() => {
        toast.error('Ocorreu um erro e não foi possível remover o arquivo');
      });
  }

  removeAlternativa() {
    const { selectedAlternative } = this.state;
    this.setState({ modalConfirmAlternativeRemoval: false });
    axios
      .delete(`${API_HOST}/itens/alternativa/${selectedAlternative}`)
      .then(() => {
        this.setState({ selectedAlternative: null });
        this.fetchAlternativas();
      })
      .catch(() =>
        toast.error('Ocorreu um erro e não foi possível remover a alternativa')
      );
  }

  removeHTMLTags(text) {
    if (text) {
      var regex = /(<([^>]+)>)/gi;
      return text.replace(regex, '');
    }
    return '';
  }

  handleRemoveAlternativa(alternativa) {
    this.setState({
      modalConfirmAlternativeRemoval: true,
      selectedAlternative: alternativa.id
    });
  }

  closeModelConfirmAlternativaRemoval() {
    this.setState({
      modalConfirmAlternativeRemoval: false,
      selectedAlternative: null
    });
  }

  createAlternativa() {
    const { alternativas } = this.state;
    const proxima_posicao = alternativas.length;
    const params = {
      item: this.props.item_id,
      posicao: proxima_posicao
    };
    axios
      .post(`${API_HOST}/itens/alternativa`, params)
      .then(() => this.fetchAlternativas())
      .catch(() =>
        toast.error('Ocorreu um erro e não foi possível criar a alternativa')
      );
  }

  shouldDisplayJustificativa(alternativa) {
    const param = alternativa.gabarito
      ? 'justificativa_gabarito'
      : 'justificativa';
    const isEmpty =
      !alternativa.justificativa || alternativa.justificativa === '<p><br></p>';
    return !isEmpty || this.shouldDisplay(`alternativa.${param}`);
  }

  justificativaIsRequired(alternativa) {
    const param = alternativa.gabarito
      ? 'justificativa_gabarito'
      : 'justificativa';
    return this.isRequired(`alternativa.${param}`);
  }

  renderMultiplaEscolha() {
    const {
      alternativas,
      comando_resposta,
      errors,
      situacao_problema,
      save,
      arquivos,
      uploading,
      progressEvent,
      modalConfirmAlternativeRemoval
    } = this.state;

    return (
      <FormContainer
        title="Conteúdo"
        previous={`/revisoes/${this.props.revisao_id}/edit/1`}
        next={`/revisoes/${this.props.revisao_id}/edit/3`}
        onClick={this.onSubmit}
      >
        <div className="col-md-12 mb-3">{this.renderSaveStatus(save.item)}</div>
        <div className="col-md-12 form-group">
          {this.shouldDisplay('item.situacao_problema') && (
            <div
              className={classnames({
                required: this.isRequired('item.situacao_problema')
              })}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: '4px',
                  marginTop: '32px'
                }}
              >
                <label
                  style={{
                    minWidth: 'fit-content'
                  }}
                >
                  Situação problema
                </label>
                <button
                  className="btn btn-white"
                  onMouseOver={(e) => showDiv('situacaoProblemaMulti')}
                  onClick={this.onClickButton}
                  style={{
                    width: ' 30px',
                    height: ' 30px',
                    margin: '0px 5px'
                  }}
                >
                  <Icon name="info" style={{ width: '100%', height: '100%' }} />
                </button>
              </div>
              <div
                id="situacaoProblemaMulti"
                style={{
                  width: '100%',
                  height: 'auto',
                  backgroundColor: '#5B5B5B',
                  color: ' white',
                  padding: '1%',
                  zIndex: '+15',
                  position: 'relative',
                  display: ' none',
                  borderRadius: '6px'
                }}
              >
                Tem o objetivo de “provocar” o aluno, além de contextualizar a
                habilidade a ser avaliada. A contextualização aproxima o aluno
                da prática profissional; por isso, é fundamental que seja
                construída. Pode ser feita por meio de um caso clínico ou da
                apresentação de dados em gráficos, tabelas e figuras. >>> Vale
                ressaltar que, quando trazemos conteúdo de terceiros, devemos
                sempre apresentar a referência de onde esse conteúdo foi
                retirado.
              </div>
              <RawInputSummernote
                value={situacao_problema}
                onChange={(event) =>
                  this.onItemChange(event, 'situacao_problema')
                }
              />
              {get(errors, 'situacao_problema', []).map(this.renderError)}
            </div>
          )}
        </div>
        <div className="col-md-12 form-group">
          {this.shouldDisplay('item.comando_resposta') && (
            <div
              className={classnames({
                required: this.isRequired('item.comando_resposta')
              })}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: '4px',
                  marginTop: '32px'
                }}
              >
                <label
                  className={classnames({
                    'text-danger':
                      get(errors, 'comando_resposta', []).length > 0
                  })}
                  style={{
                    minWidth: 'fit-content'
                  }}
                >
                  Comando Resposta
                </label>
                <button
                  className="btn btn-white"
                  onMouseOver={(e) => showDiv('ComandoResp')}
                  onClick={this.onClickButton}
                  style={{
                    width: ' 30px',
                    height: ' 30px',
                    margin: '0px 5px'
                  }}
                >
                  <Icon name="info" style={{ width: '100%', height: '100%' }} />
                </button>
              </div>
              <div
                id="ComandoResp"
                style={{
                  width: '100%',
                  height: 'auto',
                  backgroundColor: '#5B5B5B',
                  color: ' white',
                  padding: '1%',
                  zIndex: '+15',
                  position: 'relative',
                  display: ' none',
                  borderRadius: '6px'
                }}
              >
                Constitui-se de uma instrução clara o objetiva da tarefa a ser
                realizada pelo estudante, ou seja, o problema a ser resolvido
                considerando a situação-problema descrita. Pode ser expresso por
                meio de uma pergunta, orientação ou frase a ser completada pelas
                alternativas. Não podem ser usados comandos de resposta
                negativos, com construções do tipo “exceto”, “falso” ou
                “incorreto”. O aluno precisa indicar a alternativa correta.
              </div>
              <RawInputSummernote
                value={comando_resposta}
                onChange={(event) =>
                  this.onItemChange(event, 'comando_resposta')
                }
              />
              {get(errors, 'comando_resposta', []).map(this.renderError)}
            </div>
          )}
        </div>
        {this.state.videoInputShouldDisplay ? (
          <InputFile
            label="Vídeo"
            onChange={this.uploadArquivo}
            onDelete={this.removeArquivo}
            files={arquivos}
            uploading={uploading}
            progressEvent={progressEvent}
          />
        ) : null}
        <hr />

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: '4px',
            marginTop: '32px'
          }}
        >
          <label
            style={{
              minWidth: 'fit-content'
            }}
          >
            Alternativas
          </label>
          <button
            className="btn btn-white"
            onMouseOver={(e) => showDiv('alternativText')}
            onClick={this.onClickButton}
            style={{
              width: ' 30px',
              height: ' 30px',
              margin: '0px 5px'
            }}
          >
            <Icon name="info" style={{ width: '100%', height: '100%' }} />
          </button>
        </div>
        <div
          id="alternativText"
          style={{
            width: '100%',
            height: 'auto',
            backgroundColor: '#5B5B5B',
            color: ' white',
            padding: '1%',
            zIndex: '+15',
            position: 'relative',
            display: ' none',
            borderRadius: '6px'
          }}
        >
          Aqui, são apresentadas as possibilidades de resposta, constituídas de
          quatro alternativas, sendo um gabarito (resposta correta) e três
          distratores (respostas incorretas). Os distratores devem atender às
          características de plausibilidade, ou seja, devem parecer corretos
          para aqueles alunos que não adquiriram a habilidade requerida na
          questão. As alternativas não devem ser muito diferentes em relação à
          extensão (e sempre se apresentar do menor conteúdo ao maior conteúdo).
          Ademais, é preciso ter atenção ao paralelismo sintático e semântico,
          ou seja, todas as alternativas devem começar apresentando a mesma
          classe gramatical, por exemplo: substantivos, verbos ou artigo +
          substantivo, com o encadeamento de ideias de forma clara e coerente.
        </div>

        <br />
        {alternativas.map((alternativa, index) => (
          <div className="resposta" key={alternativa.id}>
            <div className="card">
              <div
                className={classnames('card-header bg-white no-overflow', {
                  'success-background': alternativa.gabarito,
                  'danger-background': !alternativa.gabarito
                })}
              >
                <div className="row">
                  <div className="col-3">
                    <p>{`Alternativa ${String.fromCharCode(
                      alternativa.posicao + 65
                    )}`}</p>
                  </div>
                  <div className="col-5">
                    <div
                      className="btn-group pull-right"
                      role="group"
                      aria-label="Selecionar"
                    >
                      <button
                        className={classnames('btn btn-white', {
                          'selected-option': alternativa.gabarito
                        })}
                        onClick={() =>
                          this.onAlternativaChange(true, 'gabarito', index)
                        }
                      >
                        <i className="fas fa-check-circle text-success" />{' '}
                        Gabarito
                      </button>
                      <button
                        className={classnames('btn btn-white', {
                          'selected-option': !alternativa.gabarito
                        })}
                        onClick={() =>
                          this.onAlternativaChange(false, 'gabarito', index)
                        }
                      >
                        <i className="fas fa-times-circle text-danger" />{' '}
                        Distrator
                      </button>
                    </div>
                  </div>
                  <div className="col-2">
                    <div
                      className="btn-group pull-right"
                      role="group"
                      aria-label="Options"
                    >
                      {index !== alternativas.length - 1 && (
                        <button
                          className="btn btn-white"
                          onClick={() => this.descerAlternativa(alternativa)}
                        >
                          <i className="fas fa-arrow-down" />
                        </button>
                      )}
                      {index !== 0 && (
                        <button
                          className="btn btn-white"
                          onClick={() => this.subirAlternativa(alternativa)}
                        >
                          <i className="fas fa-arrow-up" />
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="col-md-1">
                    <button className="btn btn-default btn-nobackgorund">
                      {this.renderSaveStatus(
                        save[alternativa.id],
                        `status${index}`
                      )}
                    </button>
                  </div>
                  <div className="col-md-1">
                    <button
                      className="btn btn-white"
                      onClick={() => this.handleRemoveAlternativa(alternativa)}
                    >
                      <i className="fas fa-times-circle" />
                    </button>
                  </div>
                </div>
              </div>
              <div
                style={{ borderTop: '0.5px solid #d6d6d6' }}
                className="card-body bg-white no-overflow"
              >
                {this.shouldDisplay('alternativa.descricao') && (
                  <div
                    className={classnames('col-md-12 form-group', {
                      required: this.isRequired('alternativa.descricao')
                    })}
                  >
                    <label
                      className={classnames({
                        required: this.isRequired('alternativa.descricao')
                      })}
                    >
                      Descrição
                    </label>
                    <RawInputSummernote
                      required={this.justificativaIsRequired(alternativa)}
                      value={get(alternativas, `[${index}].descricao`)}
                      onChange={(value) =>
                        this.onAlternativaChange(value, 'descricao', index)
                      }
                    />
                    {get(errors, `alternativas.${index}.descricao`, []).map(
                      this.renderError
                    )}
                  </div>
                )}
                {this.shouldDisplayJustificativa(alternativa) && (
                  <div
                    className={classnames('col-md-12 form-group', {
                      required: this.justificativaIsRequired(alternativa),
                      'has-error': get(
                        errors,
                        `alternativas.${index}.justificativa.length`
                      )
                    })}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginBottom: '4px',
                        marginTop: '32px'
                      }}
                    >
                      <label
                        style={{
                          minWidth: 'fit-content'
                        }}
                      >
                        Justificativas
                      </label>
                      <button
                        className="btn btn-white"
                        onMouseOver={(e) => showDiv('justifyText')}
                        onClick={this.onClickButton}
                        style={{
                          width: ' 30px',
                          height: ' 30px',
                          margin: '0px 5px'
                        }}
                      >
                        <Icon
                          name="info"
                          style={{ width: '100%', height: '100%' }}
                        />
                      </button>
                    </div>
                    <div
                      id="justifyText"
                      style={{
                        width: '100%',
                        height: 'auto',
                        backgroundColor: '#5B5B5B',
                        color: ' white',
                        padding: '1%',
                        zIndex: '+15',
                        position: 'relative',
                        display: ' none',
                        borderRadius: '6px'
                      }}
                    >
                      Deve-se elaborar a justificativa da alternativa correta
                      (gabarito) por meio de um pequeno texto que explique o
                      motivo pelo qual aquela é a resposta correta, com
                      embasamento técnico e científico. A justificativa, além de
                      auxiliar no processo de aprendizagem, quando bem
                      elaborada, evita a interposição de recurso pelo aluno. Não
                      deve ser uma cópia da alternativa correta.
                    </div>
                    <RawInputSummernote
                      required={this.justificativaIsRequired(alternativa)}
                      value={get(alternativas, `[${index}].justificativa`)}
                      onChange={(value) =>
                        this.onAlternativaChange(value, 'justificativa', index)
                      }
                    />
                    {get(errors, `alternativas.${index}.justificativa`, []).map(
                      this.renderError
                    )}
                  </div>
                )}

                {this.shouldDisplay('alternativa.arquivo') &&
                  this.state.videoInputShouldDisplay && (
                    <InputFile
                      label="Vídeo"
                      required={this.isRequired('alternativa.arquivo')}
                      onChange={(e) => this.uploadArquivoAlternativa(e, index)}
                      onDelete={(file) =>
                        this.removeArquivoAlternativa(file, index)
                      }
                      files={alternativa.arquivo ? [alternativa.arquivo] : []}
                      uploading={this.state[`uploading_${alternativa.id}`]}
                      progressEvent={
                        this.state[`progressEvent_${alternativa.id}`]
                      }
                    />
                  )}
              </div>
            </div>
          </div>
        ))}
        <div className="col-md-12">
          <button
            type="button"
            className="btn primary-button"
            onClick={() => this.createAlternativa(save.item)}
          >
            Adicionar Alternativa
          </button>
        </div>
        <Modal
          isOpen={modalConfirmAlternativeRemoval}
          toggle={() => this.closeModelConfirmAlternativaRemoval()}
        >
          <ModalHeader
            toggle={() => this.closeModelConfirmAlternativaRemoval()}
          >
            Tem certeza que deseja apagar a alternativa?
          </ModalHeader>
          <ModalBody>
            Ao apagar a alternativa todo o conteúdo dela será perdido.
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.removeAlternativa()}>
              Confirmar
            </Button>
            <Button
              color="secondary"
              onClick={() => this.closeModelConfirmAlternativaRemoval()}
            >
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      </FormContainer>
    );
  }

  renderDiscursivo() {
    const {
      situacao_problema,
      expectativa_resposta,
      orientacao_correcao,
      errors,
      comando_resposta,
      save,
      arquivos,
      uploading,
      progressEvent
    } = this.state;

    return (
      <div>
        <FormContainer
          title="Conteúdo"
          previous={`/revisoes/${this.props.revisao_id}/edit/1`}
          next={`/revisoes/${this.props.revisao_id}/edit/3`}
          onClick={this.onSubmit}
        >
          <div className="col-md-12 mb-3">
            {this.renderSaveStatus(save.item)}
          </div>
          <div className="col-md-12 form-group">
            {this.shouldDisplay('item.situacao_problema') && (
              <div
                className={classnames({
                  required: this.isRequired('item.situacao_problema')
                })}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '4px',
                    marginTop: '32px'
                  }}
                >
                  <label
                    style={{
                      minWidth: 'fit-content'
                    }}
                  >
                    Situação Problema
                  </label>
                  <button
                    className="btn btn-white"
                    onMouseOver={(e) => showDiv('situacaoproblema')}
                    onClick={this.onClickButton}
                    style={{
                      width: ' 30px',
                      height: ' 30px',
                      margin: '0px 5px'
                    }}
                  >
                    <Icon
                      name="info"
                      style={{ width: '100%', height: '100%' }}
                    />
                  </button>
                </div>
                <div
                  id="situacaoproblema"
                  style={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#5B5B5B',
                    color: ' white',
                    padding: '1%',
                    zIndex: '+15',
                    position: 'relative',
                    display: ' none',
                    borderRadius: '6px'
                  }}
                >
                  Tem o objetivo de “provocar” o aluno, além de contextualizar a
                  habilidade a ser avaliada. A contextualização aproxima o aluno
                  da prática profissional; por isso, é fundamental que seja
                  construída. Pode ser feita por meio de um caso clínico ou da
                  apresentação de dados em gráficos, tabelas e figuras. Vale
                  ressaltar que, quando trazemos conteúdo de terceiros, devemos
                  sempre apresentar a referência de onde esse conteúdo foi
                  retirado.
                </div>
                <RawInputSummernote
                  value={situacao_problema || ''}
                  onChange={(event) =>
                    this.onItemChange(event, 'situacao_problema')
                  }
                />
              </div>
            )}
          </div>
          <div className="col-md-12 form-group mt-3">
            {this.shouldDisplay('item.comando_resposta') && (
              <div
                className={classnames({
                  required: this.isRequired('item.comando_resposta')
                })}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '4px',
                    marginTop: '32px'
                  }}
                >
                  <label
                    className={classnames({
                      'text-danger':
                        get(errors, 'comando_resposta', []).length > 0
                    })}
                    style={{
                      minWidth: 'fit-content'
                    }}
                  >
                    Comando Resposta
                  </label>
                  <button
                    className="btn btn-white"
                    onMouseOver={(e) => showDiv('ComandoRespDis')}
                    onClick={this.onClickButton}
                    style={{
                      width: ' 30px',
                      height: ' 30px',
                      margin: '0px 5px'
                    }}
                  >
                    <Icon
                      name="info"
                      style={{ width: '100%', height: '100%' }}
                    />
                  </button>
                </div>
                <div
                  id="ComandoRespDis"
                  style={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#5B5B5B',
                    color: ' white',
                    padding: '1%',
                    zIndex: '+15',
                    position: 'relative',
                    display: ' none',
                    borderRadius: '6px'
                  }}
                >
                  Constitui-se de uma instrução clara o objetiva da tarefa a ser
                  realizada pelo estudante, ou seja, o problema a ser resolvido
                  considerando a situação-problema descrita. É interessante que
                  o item estimule o aluno a elaborar um pequeno texto como
                  resposta, já que a habilidade de dissertar sobre o tema é algo
                  a ser treinado entre as habilidades requeridas. A questão pode
                  ser dividida em subitens, com A, B e C, por exemplo.
                </div>
                <RawInputSummernote
                  value={comando_resposta}
                  onChange={(event) =>
                    this.onItemChange(event, 'comando_resposta')
                  }
                />
                {get(errors, 'comando_resposta', []).map(this.renderError)}
              </div>
            )}
          </div>

          <div className="col-md-12 form-group mt-3">
            {this.shouldDisplay('item.expectativa_resposta') && (
              <div
                className={classnames({
                  required: this.isRequired('item.expectativa_resposta')
                })}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '4px',
                    marginTop: '32px'
                  }}
                >
                  <label
                    style={{
                      minWidth: 'fit-content'
                    }}
                  >
                    Expectativa de Resposta
                  </label>
                  <button
                    className="btn btn-white"
                    onMouseOver={(e) => showDiv('expectativaRespostas')}
                    onClick={this.onClickButton}
                    style={{
                      width: ' 30px',
                      height: ' 30px',
                      margin: '0px 5px'
                    }}
                  >
                    <Icon
                      name="info"
                      style={{ width: '100%', height: '100%' }}
                    />
                  </button>
                </div>
                <div
                  id="expectativaRespostas"
                  style={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#5B5B5B',
                    color: ' white',
                    padding: '1%',
                    zIndex: '+15',
                    position: 'relative',
                    display: ' none',
                    borderRadius: '6px'
                  }}
                >
                  Trata-se da apresentação da melhor resposta a ser formulada
                  pelo aluno. A extensão deve ser de, no máximo, 500 caracteres
                  (para questões dos cursos de Medicina, Enfermagem,
                  Fisioterapia e Odontologia) e 1500 caracteres (para questões
                  dos cursos de Psicologia), pois esse é o espaço-limite que o
                  aluno tem para escrever sua resposta. Vale ressaltar que essa
                  expectativa de resposta é divulgada ao aluno após a aplicação
                  da prova.
                </div>
                <RawInputSummernote
                  value={expectativa_resposta || ''}
                  onChange={(value) =>
                    this.onItemChange(value, 'expectativa_resposta')
                  }
                />
                <div className="pull-right mt-2">
                  <h4 style={{ fontSize: '16px' }}>
                    {this.removeHTMLTags(expectativa_resposta).length}{' '}
                    caracteres
                  </h4>
                </div>
              </div>
            )}
          </div>
          <div className="col-md-12 form-group mt-3">
            {this.shouldDisplay('item.orientacao_correcao') && (
              <div
                className={classnames({
                  required: this.isRequired('item.orientacao_correcao')
                })}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '4px',
                    marginTop: '32px'
                  }}
                >
                  <label
                    style={{
                      minWidth: 'fit-content'
                    }}
                  >
                    Orientação para correção
                  </label>
                  <button
                    className="btn btn-white"
                    onMouseOver={(e) => showDiv('orientacaoCorrecao')}
                    onClick={this.onClickButton}
                    style={{
                      width: ' 30px',
                      height: ' 30px',
                      margin: '0px 5px'
                    }}
                  >
                    <Icon
                      name="info"
                      style={{ width: '100%', height: '100%' }}
                    />
                  </button>
                </div>
                <div
                  id="orientacaoCorrecao"
                  style={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#5B5B5B',
                    color: ' white',
                    padding: '1%',
                    zIndex: '+15',
                    position: 'relative',
                    display: ' none',
                    borderRadius: '6px'
                  }}
                >
                  Trata-se de uma descrição detalhada de como deve ser a
                  correção do item. Nesse campo, deve ser inserido o critério de
                  avaliação, ou seja: se o aluno responder X, ele tira X pontos;
                  se responder XX, ele tira XX pontos, e assim sucessivamente.
                </div>
                <RawInputSummernote
                  value={orientacao_correcao || ''}
                  onChange={(value) =>
                    this.onItemChange(value, 'orientacao_correcao')
                  }
                />
              </div>
            )}
          </div>
          <div className="mt-5">
            <div className="numeros row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 form-group">
                <label>Palavras</label>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <label className="sr-only" />
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">Min.</div>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        value={get(this.state, 'min_palavras') || ''}
                        onChange={(event) =>
                          this.onItemChange(event.target.value, 'min_palavras')
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <label className="sr-only" />
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">Max.</div>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        value={get(this.state, 'max_palavras') || ''}
                        onChange={(event) =>
                          this.onItemChange(event.target.value, 'max_palavras')
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={classnames(
                  'row-lg-6 row-md-6 row-sm-6 row-xs-6 form-group',
                  { required: this.isRequired('item.max_caracteres') }
                )}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '4px',
                    marginTop: '32px'
                  }}
                >
                  <label
                    style={{
                      minWidth: 'fit-content'
                    }}
                  >
                    Caracteres
                  </label>
                  <button
                    className="btn btn-white"
                    onMouseOver={(e) => showDiv('caracteres')}
                    onClick={this.onClickButton}
                    style={{
                      width: ' 30px',
                      height: ' 30px',
                      margin: '0px 5px'
                    }}
                  >
                    <Icon
                      name="info"
                      style={{ width: '100%', height: '100%' }}
                    />
                  </button>
                </div>
                <div
                  id="caracteres"
                  style={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#5B5B5B',
                    color: ' white',
                    padding: '1%',
                    zIndex: '+15',
                    position: 'relative',
                    display: ' none',
                    borderRadius: '6px'
                  }}
                >
                  Trata-se do número máximo de caracteres (letras e espaços)
                  permitido ao aluno para transcrever sua resposta. O campo deve
                  ser preenchido com 500 caracteres para os cursos de Medicina,
                  Enfermagem, Fisioterapia e Odontologia e de 1500 caracteres
                  para o curso de Psicologia.
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <label className="sr-only" />
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">Min.</div>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        value={get(this.state, 'min_caracteres') || ''}
                        onChange={(event) =>
                          this.onItemChange(
                            event.target.value,
                            'min_caracteres'
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <label className="sr-only" />
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">Max.</div>
                      </div>
                      <input
                        required={this.isRequired('item.max_caracteres')}
                        type="text"
                        className="form-control"
                        value={get(this.state, 'max_caracteres') || ''}
                        onChange={(event) =>
                          this.onItemChange(
                            event.target.value,
                            'max_caracteres'
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FormContainer>
      </div>
    );
  }

  renderEscalaLinear() {
    const { situacao_problema, errors, comando_resposta } = this.state;

    return (
      <FormContainer
        title="Conteúdo"
        previous={`/revisoes/${this.props.revisao_id}/edit/1`}
        next={`/revisoes/${this.props.revisao_id}/edit/3`}
        onClick={this.onSubmit}
      >
        <div className="col-md-12 form-group">
          {this.shouldDisplay('item.situacao_problema') && (
            <div
              className={classnames({
                required: this.isRequired('item.situacao_problema')
              })}
            >
              <label>Situação problema</label>
              <RawInputSummernote
                value={situacao_problema}
                onChange={(event) =>
                  this.onItemChange(event, 'situacao_problema')
                }
              />
              {get(errors, 'situacao_problema', []).map(this.renderError)}
            </div>
          )}
        </div>
        <div className="col-md-12 form-group">
          {this.shouldDisplay('item.comando_resposta') && (
            <div
              className={classnames({
                required: this.isRequired('item.comando_resposta')
              })}
            >
              <label
                className={classnames({
                  'text-danger': get(errors, 'comando_resposta', []).length > 0
                })}
              >
                Comando resposta
              </label>
              <RawInputSummernote
                value={comando_resposta}
                onChange={(event) =>
                  this.onItemChange(event, 'comando_resposta')
                }
              />
              {get(errors, 'comando_resposta', []).map(this.renderError)}
            </div>
          )}
          <div className="mt-5">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <label>Texto inicial</label>
                <div className="input-group mb-2">
                  <input
                    type="text"
                    className="form-control"
                    value={get(this.state, 'grade_linear.texto_inicial') || ''}
                    onChange={(event) =>
                      this.onGradeLinearChange(
                        event.target.value,
                        'texto_inicial'
                      )
                    }
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <label>Texto Final</label>
                <div className="input-group mb-2">
                  <input
                    type="text"
                    className="form-control"
                    value={get(this.state, 'grade_linear.texto_final') || ''}
                    onChange={(event) =>
                      this.onGradeLinearChange(
                        event.target.value,
                        'texto_final'
                      )
                    }
                  />
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                <label className="sr-only" />
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Início</div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    value={get(this.state, 'grade_linear.inicio') || ''}
                    onChange={(event) =>
                      this.onGradeLinearChange(event.target.value, 'inicio')
                    }
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                <label className="sr-only" />
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Intervalo</div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    value={get(this.state, 'grade_linear.intervalo') || ''}
                    onChange={(event) =>
                      this.onGradeLinearChange(event.target.value, 'intervalo')
                    }
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                <label className="sr-only" />
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Fim</div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    value={get(this.state, 'grade_linear.fim') || ''}
                    onChange={(event) =>
                      this.onGradeLinearChange(event.target.value, 'fim')
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5">
            <PreviewEscalaLinear
              textoInicial={this.state.grade_linear.texto_inicial}
              textoFinal={this.state.grade_linear.texto_final}
              inicio={this.state.grade_linear.inicio}
              fim={this.state.grade_linear.fim}
              intervalo={this.state.grade_linear.intervalo}
            />
          </div>
        </div>
      </FormContainer>
    );
  }

  renderEscalaLinearMultipla() {
    const { situacao_problema, errors, comando_resposta } = this.state;

    return (
      <FormContainer
        title="Conteúdo"
        previous={`/revisoes/${this.props.revisao_id}/edit/1`}
        next={`/revisoes/${this.props.revisao_id}/edit/3`}
        onClick={this.onSubmit}
      >
        <div className="col-md-12 form-group">
          {this.shouldDisplay('item.situacao_problema') && (
            <div
              className={classnames({
                required: this.isRequired('item.situacao_problema')
              })}
            >
              <label>Situação problema</label>
              <RawInputSummernote
                value={situacao_problema}
                onChange={(event) =>
                  this.onItemChange(event, 'situacao_problema')
                }
              />
              {get(errors, 'situacao_problema', []).map(this.renderError)}
            </div>
          )}
        </div>
        <div className="col-md-12 form-group">
          {this.shouldDisplay('item.comando_resposta') && (
            <div
              className={classnames({
                required: this.isRequired('item.comando_resposta')
              })}
            >
              <label
                className={classnames({
                  'text-danger': get(errors, 'comando_resposta', []).length > 0
                })}
              >
                Comando resposta
              </label>
              <RawInputSummernote
                value={comando_resposta}
                onChange={(event) =>
                  this.onItemChange(event, 'comando_resposta')
                }
              />
              {get(errors, 'comando_resposta', []).map(this.renderError)}
            </div>
          )}
        </div>
        <div className="my-5 ">
          <GradeLinearMultipla
            onChangeItem={this.onGradeLinearMultiplaChange}
            onExcludeItem={this.onGradeLinearMultiplaExclude}
            values={get(this.state, 'grade_linear') || ''}
          />
        </div>
      </FormContainer>
    );
  }

  render() {
    const { fetchingData, tipo } = this.state;

    if (fetchingData) {
      return <Loading />;
    }

    if (
      [
        TIPOS_ITENS.MULTIPLA_ESCOLHA_UNICA,
        TIPOS_ITENS.MULTIPLA_ESCOLHA_MULTIPLA
      ].includes(tipo)
    ) {
      return this.renderMultiplaEscolha();
    }

    if (tipo === TIPOS_ITENS.DISCURSIVO) {
      return this.renderDiscursivo();
    }

    if (tipo === TIPOS_ITENS.ESCALA_LINEAR_UNICA) {
      return this.renderEscalaLinear();
    }

    if (tipo === TIPOS_ITENS.ESCALA_LINEAR_MULTIPLA) {
      return this.renderEscalaLinearMultipla();
    }

    return (
      <p>
        Por favor, antes de prosseguir, <Link to="dados">clique aqui</Link> para
        definir um tipo de item.
      </p>
    );
  }
}

export default withRouter(ConteudoRevisao);
