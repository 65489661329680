import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NavBreadCrumb from '../../containers/NavBreadCrumb';
import Search from '../../components/Search';
import ListTag from '../../components/ListTag';

class ListaExames extends Component {
  static defaultProps = {
    title: 'ENEM 3/28',
    small: '32',
    children: null,
    groupIcon: false,
    actions: [],
    atualizado_em: '03/28',
    versao: '3.28',
    label: 'Ativo',
    color: 'var(--success)'
  };

  renderActions() {
    const actions= [];

    actions.push((
      <Link className="btn btn-white mb-3" key="1" to={`/configuracoes/exame/1`}>
        <i className="fas fa-edit"/>
      </Link>
    ));

    return actions;
  }

  render() {
    const {
      title,
      small,
      status,
      label,
      details,
      color,
    } = this.props;

    return (
      <div className="form-configuracoes">
        <NavBreadCrumb currentPath="Exames" />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card toolbar">
                <div className="card-header">
                  <div className="row">
                    <div className="col-6"><h1>Lista de Exames</h1></div>
                    <div className="col-4">
                      <Search />
                    </div>
                    <div className="col-2 display-flex">
                      <Link to="/configuracoes/exame/new" className="btn primary-button">+ Adicionar Exame</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body card-body-form">
                  <div className="item-competencia">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-1 col-md-1 col-sm-2 col-xs-2 border-right">
                          <p className="number">01</p>
                        </div>

                        <div className="col-lg-8 col-md-8 col-sm-10 col-xs-10">
                          <div className="list-data">
                            <div className="list-data-title">
                              <h3>{title}</h3>
                              {small ? <small className="list-data-text"><p><b>Áreas de Conhecimento: </b>{small}</p>
                              </small> : null}

                            </div>
                          </div>
                        </div>
                        <div style={{ paddingLeft: '50px', marginLeft: '30px' }}
                             className="col-lg-3 col-md-3 col-xs-12 col-sm-12">
                          <ListTag
                            onClickExpand={this.onClickExpand}
                            details={details}
                            label={label}
                            color={color}
                            actions={this.renderActions()}
                            groupIcon={this.props.groupIcon}
                            onClickGroup={this.onClickGroup}
                            {...status}
                          />
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ListaExames;
