import ExcelJS from "exceljs/dist/exceljs";

class ExcelExporter {
  fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  fileExtension = ".xlsx";

  constructor(sheetName = "data") {
    this.workbook = new ExcelJS.Workbook();
    this.worksheet = this.workbook.addWorksheet(sheetName);
  }

  insertData = (data, columns = []) => {
    if (!columns.lenght) {
      const dataKeys = Object.keys(data[0]);
      columns = dataKeys.map((key) => ({ header: key, key }));
    }
    this.worksheet.columns = columns;
    this.worksheet.addRows(data);
  };
  makeExcelFile = async () => {
    const buffer = await this.workbook.xlsx.writeBuffer();
    const fileBlob = new Blob([buffer], { type: this.fileType });
    return fileBlob;
  };

  downloadExcel = (fileBlob, fileName) => {
    const url = URL.createObjectURL(fileBlob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${fileName}${this.fileExtension}`;
    link.click();
  };

  addColors = (colorMap) => {
    colorMap.forEach((item) => {
      const row = this.worksheet.getRow(item.y);
      const cell = row.getCell(item.x);
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: item.color },
        bgColor: { argb: item.color },
      };
    });
  };

  export = async (data, fileName, columns = [], colorMap = []) => {
    this.insertData(data, columns);
    if (colorMap) {
      this.addColors(colorMap);
    }
    const fileBlob = await this.makeExcelFile(data);
    this.downloadExcel(fileBlob, fileName);
  };
}

export default ExcelExporter;
