import React from 'react';
import { Route, withRouter, Redirect, Switch } from 'react-router-dom';
import { get } from 'lodash';
import axios from 'axios';
import NavBreadCrumb from '../../containers/NavBreadCrumb';
import ProgressTracker from '../../containers/ProgressTracker';
import Dados from './Dados';
import Fonte from './Fonte';
import ReferenciaBibliografica from './ReferenciaBibliografica';
import MatrizReferencia from './MatrizReferencia';
import Conteudo from './Conteudo';
import Pendencias from './Pendencias';
import DetectorPlagio from '../revisoes/DetectorPlagio';
import Confirmar from './Confirmar';
import DadosGerais from '../../containers/DadosGerais';
import { API_HOST } from '../../consts';
import Loading from '../../components/Loading';

class Itens extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: {},
      fetchingItem: true
    };
  }

  componentDidMount() {
    this.fetchItem();
  }

  get id() {
    return this.props.match.params.id;
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const changedStep = prevProps.location.pathname !== location.pathname;
    if (changedStep) {
      this.fetchItem();
    }
  }

  get canViewPlagiusDetector() {
    // Habilitado temporariamente somente para super usuários
    const permissions = get(this.props, 'permissions') || {};
    // eslint-disable-next-line
    return permissions.__all__;
    // return permissions.__all__ || permissions['itens.view_plagio'];
  }

  getDetails() {
    return [
      {
        col: '',
        title: 'Tipo',
        text: 'Discursivo'
      }
    ];
  }

  getSteps() {
    const id = get(this.props, 'match.params.id');
    const {
      location: { pathname }
    } = this.props;
    const { item } = this.state;
    const steps = [
      {
        id: 1,
        title: 'Dados do item',
        path: '/itens/:id/dados',
        link: `/itens/${id}/dados`,
        active: pathname === `/itens/${id}/dados`,
        done: this.stepDone(pathname),
        component: Dados,
        disabled: false
      },
      {
        id: 2,
        title: 'Matriz de Referência',
        path: '/itens/:id/matrizes-referencia',
        link: `/itens/${id}/matrizes-referencia`,
        active: pathname === `/itens/${id}/matrizes-referencia`,
        done: this.stepDone(pathname),
        component: () => (
          <MatrizReferencia idItem={id} history={this.props.history} />
        ),
        disabled: id === 'new'
      },
      {
        id: 3,
        title: 'Bases Teóricas',
        path: '/itens/:id/referencias-bibliograficas',
        link: `/itens/${id}/referencias-bibliograficas`,
        active: pathname === `/itens/${id}/referencias-bibliograficas`,
        done: this.stepDone(pathname),
        component: ReferenciaBibliografica,
        disabled: id === 'new'
      },
      {
        id: 4,
        title: 'Conteúdo',
        path: '/itens/:id/conteudo',
        link: `/itens/${id}/conteudo`,
        active: pathname === `/itens/${id}/conteudo`,
        done: this.stepDone(pathname),
        component: Conteudo,
        disabled: id === 'new'
      },
      {
        id: 5,
        title: 'Autoria',
        path: '/itens/:id/fonte',
        link: `/itens/${id}/fonte`,
        active: pathname === `/itens/${id}/fonte`,
        done: this.stepDone(pathname),
        component: () => <Fonte permission={get(this.props, 'permissions')} />,
        disabled: id === 'new'
      },
      {
        id: 6,
        title: 'Verificação',
        path: '/itens/:id/pendencias',
        link: `/itens/${id}/pendencias`,
        active: pathname === `/itens/${id}/pendencias`,
        done: false,
        component: Pendencias,
        disabled: id === 'new'
      },
      {
        id: 7,
        title: 'Detector de plágio',
        path: '/itens/:id/plagio',
        link: `/itens/${id}/plagio`,
        active: pathname === `/itens/${id}/plagio`,
        done: false,
        component: () => <DetectorPlagio item={item.id} />,
        disabled: id === 'new',
        hidden: !this.canViewPlagiusDetector
      },
      {
        id: 8,
        title: 'Confirmar',
        path: '/itens/:id/confirmar',
        link: `/itens/${id}/confirmar`,
        active: pathname === `/itens/${id}/confirmar`,
        done: this.stepDone(pathname),
        component: Confirmar,
        disabled: id === 'new'
      }
    ];

    return steps.filter((d) => !d.hidden);
  }

  getCurrentPath() {
    const id = get(this.props, 'match.params.id');
    return id === 'new' ? 'Novo Item' : `Item ${id}`;
  }

  stepDone() {
    return false;
  }

  fetchItem() {
    if (this.id !== 'new') {
      axios
        .get(`${API_HOST}/itens/item/${this.id}`)
        .then((response) => {
          this.setState({
            item: response.data,
            fetchingItem: false
          });
        })
        .catch(() => {
          this.props.history.push('/itens/404');
        });
    } else {
      this.setState({ fetchingItem: false });
    }
  }

  render() {
    const pathList = [
      {
        label: 'Itens',
        link: '/itens'
      }
    ];
    const steps = this.getSteps();
    const id = get(this.props, 'match.params.id');
    const { item, fetchingItem } = this.state;
    const currentStep = this.props.location.pathname;

    if (fetchingItem) {
      return (
        <div className="p-5">
          <Loading />
        </div>
      );
    }

    return (
      <div>
        <NavBreadCrumb
          pathList={pathList}
          currentPath={this.getCurrentPath()}
        />
        <div className="container">
          <div className="row pb-4">
            <div className="col-3">
              <ProgressTracker steps={steps} />
            </div>
            <div className="col-sm-9">
              {currentStep !== `/itens/${id}/dados` && (
                <DadosGerais {...item} loading={fetchingItem} />
              )}
              <Switch>
                {steps.map((step, index) => (
                  <Route
                    key={step.id}
                    path={step.path}
                    render={() =>
                      step.disabled ? (
                        <Redirect to="/itens/new/dados" />
                      ) : (
                        <step.component step={index} />
                      )
                    }
                  />
                ))}
                <Route
                  path="/itens/new"
                  render={() => <Redirect to="/itens/new/dados" />}
                />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Itens);
